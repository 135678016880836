import axios from 'axios'
import SnackBar from '../../General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue'
import Loading from '../components/loadingBar.vue'
import {
    deleteToken
} from '../../../../store/Funciones/funciones'
export default {
    name: 'adm-convenios',
    components: {
        SnackBar,
        Loading
    },
    props: [],
    data() {
        return {
            valid: true,
            model: [],
            convenios: [],
            beneficiosAagregar: [],
            host: '',
            idConvenio: 0,
            idBeneficio: 0,
            muestraCargando: true,
            textoLoading: "Cargando contenido",
            muestraBarra: false,
            muestraInputBeneficio: false,
            comfirm: false,
            addConvenio: false,
            muestraCalendario: false,
            textoBarra: "",
            colorBarra: "",
            nuevoBeneficio: "",
            empresa: "",
            nombreEmpresa: "",
            fechaVencimiento: "",
            tipo: null,
            comentarios: "",
            file: null,
            imagen: '',
            accion: '',

            // Reglas
            nameRules: [
                v => !!v || 'El nombre de la empresa es requerido',
                v => (v && v.length <= 100) || 'Debe tener menos de 100 carácteres.',
            ],

            dateRules: [
                v => !!v || 'Es requerida una fecha de vencimiento.',
            ]

        }
    },
    computed: {

    },
    created() {
        this.host = this.$store.getters.getHost
    },
    mounted() {
        this.getConvenios()
    },
    methods: {
        getConvenios() {
            let params = {
                Opcion: 3,
                token: localStorage.getItem("token"),
            };
            axios
                .post(
                    `${this.$store.getters.getHost}/Compensaciones/ws-convenios.php`,
                    params
                )
                .then((response) => {
                    if (response.data.status == "OK") {
                        this.convenios = response.data.convenios;
                        this.muestraCargando = false;
                    }
                });
        },
        editEstatus(setTabla, id, estatus) {
            this.muestraCargando = true;
            let estatusEnv = -1;
            if (estatus == 1) {
                estatusEnv = 0
            } else {
                estatusEnv = 1
            }

            let params = {
                Opcion: 2,
                token: localStorage.getItem("token"),
                id: id,
                estatus: estatusEnv,
                setTabla: setTabla
            };

            axios
                .post(
                    `${this.$store.getters.getHost}/Compensaciones/ws-convenios.php`,
                    params
                )
                .then((response) => {
                    if (response.data.status == "OK") {
                        this.colorBarra = "success";

                    } else {
                        this.colorBarra = "error"
                    }
                    this.textoBarra = response.data.detalles;
                    this.muestraBarra = true;
                    this.actualizaContenido();
                });

        },
        actualizaContenido() {
            this.convenios.length = 0;
            this.getConvenios();
        },

        addBeneficio() {
            let params = {
                Opcion: 4,
                token: localStorage.getItem("token"),
                id: this.idConvenio,
                beneficio: this.nuevoBeneficio
            };

            axios
                .post(
                    `${this.$store.getters.getHost}/Compensaciones/ws-convenios.php`,
                    params
                )
                .then((response) => {
                    if (response.data.status == "OK") {
                        this.colorBarra = "success";

                    } else {
                        this.colorBarra = "error"
                    }
                    this.textoBarra = response.data.detalles;
                    this.muestraInputBeneficio = false;
                    this.muestraBarra = true;
                    this.idConvenio = 0;
                    this.empresa = '';
                    this.actualizaContenido();
                });
        },

        deleteConvenio() {
            this.comfirm = false;
            this.muestraCargando = true;
            let params = {
                Opcion: 7,
                token: localStorage.getItem("token"),
                id: this.idConvenio,
                beneficio: this.nuevoBeneficio
            };
            axios
                .post(
                    `${this.$store.getters.getHost}/Compensaciones/ws-convenios.php`,
                    params
                )
                .then((response) => {
                    if (response.data.status == "OK") {
                        this.colorBarra = "success";

                    } else {
                        this.colorBarra = "error"
                    }
                    this.textoBarra = response.data.detalles;
                    this.muestraInputBeneficio = false;
                    this.muestraBarra = true;
                    this.idConvenio = 0;
                    this.empresa = '';
                    this.actualizaContenido();
                });
        },

        openComfirm(convenio) {
            this.idConvenio = convenio.id;
            this.empresa = convenio.empresa;
            this.comfirm = true;
        },

        openDialogAdd(object, tipo) {
            console.log("seg 1")
            switch (tipo) {
                case 'agregar':
                    {
                        this.empresa = object.empresa
                        this.idConvenio = object.id
                        break
                    }
                case 'editaTitulo':
                    {
                        this.idConvenio = object.id
                        this.nuevoBeneficio = object.empresa
                        break
                    }
                case 'editaBenefit':
                    {
                        this.idBeneficio = object.id
                        this.nuevoBeneficio = object.beneficio
                        break
                    }
            }
            this.accion = tipo
            this.muestraInputBeneficio = true;
        },

        updateTitle(esConvenio) {
            let idToSend = 0;
            this.muestraCargando = true;

            if (esConvenio) {
                idToSend = this.idConvenio
            } else {
                idToSend = this.idBeneficio
            }

            let params = {
                Opcion: 8,
                token: localStorage.getItem('token'),
                idRegistro: idToSend,
                nuevoTitle: this.nuevoBeneficio,
                esConvenio: esConvenio
            }

            axios
                .post(
                    `${this.$store.getters.getHost}/Compensaciones/ws-convenios.php`,
                    params
                )
                .then((response) => {
                    if (response.data.status == "OK") {
                        this.colorBarra = "success";
                    } else if (response.data.status == "EXPRESSION") {
                        deleteToken();
                    } else {
                        this.colorBarra = "error"
                    }
                    this.textoBarra = response.data.detalles;
                    this.muestraBarra = true;
                    this.muestraInputBeneficio = false;
                    this.actualizaContenido();
                });
        },

        clear() {
            this.nombreEmpresa = '';
            this.fechaVencimiento = '';
            this.comentarios = '';
            this.file = null;
            this.beneficiosAagregar.length = 0;
            this.addConvenio = false;
        },

        verifyData() {
            if (this.nombreEmpresa != "" && this.fechaVencimiento != "")
                this.muestraCargando = true;
            if (this.file != null) {
                this.uploadFile()
            }
        },

        validate() {
            if (this.$refs.form.validate()) {
                if (this.beneficiosAagregar.length > 0) {
                    this.muestraCargando = true;
                    if (this.file != null) {
                        this.uploadFile();
                    } else {
                        this.saveConvenio();
                    }
                } else {
                    this.textoBarra = "Debes agregar por lo menos 1 beneficio a este convenio."
                    this.colorBarra = "pink";
                    this.muestraBarra = true;
                }
            }
        },

        saveConvenio() {
            let params = {
                Opcion: 5,
                token: localStorage.getItem('token'),
                empresa: this.nombreEmpresa,
                fechaVencimiento: this.fechaVencimiento,
                beneficios: this.beneficiosAagregar,
                imagen: this.imagen,
                tipo: this.tipo
            }

            axios
                .post(
                    `${this.$store.getters.getHost}/Compensaciones/ws-convenios.php`,
                    params
                )
                .then((response) => {
                    if (response.data.status == "OK") {
                        this.colorBarra = "success";
                    } else if (response.data.status == "EXPRESSION") {
                        deleteToken();
                    } else {
                        this.colorBarra = "error"
                    }
                    this.textoBarra = response.data.detalles;
                    this.$refs.form.resetValidation();
                    this.$refs.form.reset();
                    this.muestraBarra = true;
                    this.addConvenio = false;
                    this.actualizaContenido();
                });

        },

        uploadFile() {
            let reader = new FileReader();
            reader.readAsDataURL(this.file);
            reader.onload = () => {
                let params = {
                    Opcion: 6,
                    token: localStorage.getItem("token"),
                    filename: this.file.name,
                    base64: reader.result,
                };
                axios
                    .post(
                        `${this.$store.getters.getHost}/Compensaciones/ws-convenios.php`,
                        params
                    )
                    .then((response) => {
                        if (response.data.trim() == this.file.name) {
                            this.imagen = this.file.name;
                            this.saveConvenio()
                        } else {
                            this.muestraCargando = false;
                            this.textoBarra = "Ocurrio un error al intentar cargar la imagen: " + response.data;
                            this.colorBarra = "pink";
                            this.muestraBarra = true;
                        }
                    })
                    .catch(function(e) {
                        alert("Ocurrió un error: " + e);
                    });
            }
        }
    }
}