<template>
  <v-dialog
    v-model="muestraDialogDptoDL"
    persistent
    max-width="480"
    content-class="fix-border-dialog"
  >
    <v-card class="rounded-xl">
      <v-card-title class="primary">
        <label class="overline white--text font-weight-bold"
          >departamentos descanso laborado</label
        >
        <v-spacer></v-spacer>
        <v-icon dark @click="$emit('cierraDialogDptoDL', null)"
          >mdi-close-thick</v-icon
        >
      </v-card-title>
      <v-card-text>
        <v-form ref="formRegistraDptoDL">
          <label class="overline">departamento</label>
          <v-autocomplete
            v-model="departamentoSeleccionado"
            class="rounded-lg"
            dense
            outlined
            required
            :rules="rules"
            :loading="loadingDepartamentos"
            loading-text="Cargando departamentos..."
            no-data-text="No se obtubieron resultados."
            no-search-results="No se obtubieron resultados."
            :items="departamentos"
            :disabled="update"
            item-text="DESCR"
            item-value="DEPTID"
            placeholder="Selecciona el departamento"
          ></v-autocomplete>

          <label class="overline">aut. primario</label>
          <v-autocomplete
            v-model="autPrimarioSeleccionado"
            class="rounded-lg"
            dense
            outlined
            required
            :rules="rules"
            :loading="loadingEmpleados"
            loading-text="Cargando empleados..."
            no-data-text="No se obtubieron resultados."
            no-search-results="No se obtubieron resultados."
            :items="empleados"
            item-text="EMP_NOMEMP"
            item-value="EMP_KEYEMP"
            placeholder="Selecciona al colaborador"
          ></v-autocomplete>

          <label class="overline">aut. secundario</label>
          <v-autocomplete
            v-model="autSecundarioSeleccionado"
            class="rounded-lg"
            dense
            outlined
            required
            :rules="rules"
            :loading="loadingEmpleados"
            loading-text="Cargando empleados..."
            no-data-text="No se obtubieron resultados."
            no-search-results="No se obtubieron resultados."
            :items="empleados"
            item-text="EMP_NOMEMP"
            item-value="EMP_KEYEMP"
            placeholder="Selecciona al colaborador"
          ></v-autocomplete>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="pa-0">
        <v-btn
          class="rounded-xl rounded-t-0 font-weight-bold"
          text
          color="teal darken-2"
          block
          x-large
          depressed
          @click="validaFormulario"
        >
          guardar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
import { deleteToken } from "../../../../../store/Funciones/funciones";

export default {
  props: ["muestraDialogDptoDL", "update", "dataDpto"],
  components: {},
  data() {
    return {
      departamentos: [],
      empleados: [],
      rules: [(v) => !!v || "Campo obligatorio."],
      departamentoSeleccionado: null,
      autPrimarioSeleccionado: null,
      autSecundarioSeleccionado: null,
      loadingDepartamentos: true,
      loadingEmpleados: true,
    };
  },
  async mounted() {
    this.departamentos = await this.getDepartamentos();
    if (this.update) {
      const departamento = this.departamentos.find(
        (d) => d.DEPTID === this.dataDpto.id_departamento_zn
      );
      if (departamento) {
        this.departamentoSeleccionado = departamento.DEPTID;
        console.log("hola", this.departamentoSeleccionado)
      }
    }
    this.empleados = await this.getEmpleados();
    if (this.update) {
      const autorizadorPrimario = this.empleados.find(
        (e) => e.EMP_KEYEMP == this.dataDpto.autorizador_primario
      );
      if (autorizadorPrimario) {
        this.autPrimarioSeleccionado = autorizadorPrimario.EMP_KEYEMP;
      }

      const autorizadorSecundario = this.empleados.find(
        (e) => e.EMP_KEYEMP == this.dataDpto.autorizador_secundario
      );
      if (autorizadorSecundario) {
        this.autSecundarioSeleccionado = autorizadorSecundario.EMP_KEYEMP;
      }
    }
  },
  methods: {
    getDepartamentos() {
      const headers = {
        Authorization: localStorage.getItem("token"),
      };

      return new Promise((res) => {
        axios
          .get(
            `${this.$store.getters.getHostNode}/api/descanso-laborado/departamentos`,
            {
              headers,
            }
          )
          .then((response) => {
            if (response.data.status == "EXPSESSION") {
              deleteToken();
            } else if (response.data.status == "OK") {
              res(response.data.data);
            } else {
              res([]);
            }
          })
          .finally(() => {
            this.loadingDepartamentos = false;
          });
      });
    },

    getEmpleados() {
      const headers = {
        Authorization: localStorage.getItem("token"),
      };

      return new Promise((res) => {
        axios
          .get(
            `${this.$store.getters.getHostNode}/api/descanso-laborado/empleados`,
            {
              headers,
            }
          )
          .then((response) => {
            if (response.data.status == "EXPSESSION") {
              deleteToken();
            } else if (response.data.status == "OK") {
              res(response.data.data);
            } else {
              res([]);
            }
          })
          .finally(() => {
            this.loadingEmpleados = false;
          });
      });
    },

    validaFormulario() {
      if (this.$refs.formRegistraDptoDL.validate()) {
        this.$emit("cierraDialogDptoDL", {
          update: this.update,
          departamento: this.departamentoSeleccionado,
          autPrimario: this.autPrimarioSeleccionado,
          autSecundario: this.autSecundarioSeleccionado,
        });
      }
    },
  },
};
</script>
