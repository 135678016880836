import axios from 'axios'
import { deleteToken } from '../../../../../store/Funciones/funciones'
import ReportePorAreasDL from "./components/ReportePorAreasDL.vue"
export default {
    name: 'reporte-costo-descanso-laborado',
    components: { ReportePorAreasDL },
    props: [],
    data() {
        return {
            acceso: {},
            loadingData: true
        }
    },
    computed: {

    },
    async mounted() {
        this.acceso = await this.validaAccesoReporteDeCostos()
    },
    methods: {
        validaAccesoReporteDeCostos() {
            const headers = {
                Authorization: localStorage.getItem("token"),
            };

            return new Promise((res) => {
                axios
                    .get(`${this.$store.getters.getHostNode}/api/descanso-laborado/reporte-de-costos/valida-acceso`, {
                        headers,
                    })
                    .then((response) => {
                        if (response.data.status == "EXPSESSION") {
                            deleteToken();
                        } else {
                            res(response.data);
                        }
                    })
                    .finally(() => {
                        this.loadingData = false;
                    });
            });
        },
    }
}