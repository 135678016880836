import { render, staticRenderFns } from "./ComunicadosInternos.html?vue&type=template&id=3f55e738&scoped=true&"
import script from "./ComunicadosInternos.js?vue&type=script&lang=js&"
export * from "./ComunicadosInternos.js?vue&type=script&lang=js&"
import style0 from "./ComunicadosInternos.scss?vue&type=style&index=0&id=3f55e738&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3f55e738",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VContainer})
