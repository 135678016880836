import axios from 'axios'
import { deleteToken } from '../../../../../store/Funciones/funciones';
import SnackBarErrorIncidencias from "../../../General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue"
export default {
  name: 'solicitud-capacitaciones',
  components: { SnackBarErrorIncidencias },
  props: [],
  data() {
    return {
      textoBarra: '',
      colorBarra: '',
      muestraBarra: false,
      timeout: 2000,

      colaboradorSelected: [],
      capacitacionSelected: [],
      cargando: true,
      singleSelect: true,
      headersSubordinados: [
        { text: 'Emplid', value: 'emplid', align: 'center', sortable: true },
        { text: 'Nombre', value: 'nombre', align: 'center', sortable: true },
      ],
      subordinados: [],

      cargandoCapacitacionesTabla: true,
      headersCapacitaciones: [
        { text: 'ID', value: 'id', align: 'center', sortable: true },
        { text: 'Certificación', value: 'nombre', align: 'center', sortable: true },
        { text: 'Nivel', value: 'nivel', align: 'center', sortable: true },
        { text: 'Disponibilidad', value: 'estatus', align: 'center', sortable: true },
      ],
      capacitaciones: [],
      emplid: [],
      idCapacitacion: '',

      expanded: [],
      solicitudestHeaders: [
        { text: 'solicitud', value: 'id', align: 'center', sortable: true },
        { text: 'Certificación', value: 'Nombre', align: 'center', sortable: true },
        { text: 'Solixitante', value: 'solicitante', align: 'center', sortable: true },
        { text: 'Estatus', value: 'estatus', align: 'center', sortable: true },
        { text: 'Fecha solicitud', value: 'FechaActualiza', align: 'center', sortable: true },
        { text: '', value: 'data-table-expand' },
      ],
      solicitudesCapacitaciones: [],
      singleExpand: false,
      dialogForm: false,
      loading: false,
      buscar:'',
      comentarioSolicitud:'',
    }
  },
  computed: {

  },
  mounted() {
    this.getSubordinados();
    this.getCapacitaciones();
    this.getSolicitudesCapacitacion();
  },
  methods: {
    guardar() {
      this.colaboradorSelected.map((element) => { this.emplid.push(element.emplid) });
      this.capacitacionSelected.map((element) => {
        if (element.estatus == true) {
          this.idCapacitacion = element.id;
        }
      });
      this.loading = true;
      console.log(this.loading);
      if (this.colaboradorSelected.length > 0 && this.capacitacionSelected.length > 0 && this.idCapacitacion) {
        let params = {
          Opcion: 4,
          subordinados: this.emplid,
          idCapacitacion: this.idCapacitacion,
          comentarioSolicitud: this.comentarioSolicitud,
          token: localStorage.getItem('token')
        }
        console.log(this.loading);
        axios
          .post(`${this.$store.getters.getHost}/General/SolicitudCapacitaciones/ws-solicitud-capacitaciones.php`, params)
          .then((response) => {
            if (response.data.status == "EXPSESSION") {
              deleteToken();
            } else if (response.data.status == "OK") {
              this.textoBarra = 'Solicitud realizada correctamente';
              this.colorBarra = 'success';
              this.muestraBarra = true;
              setTimeout(() => {
                this.close();
                this.getSubordinados();
                this.getCapacitaciones();
                this.getSolicitudesCapacitacion();
              }, 1500);
            } else {
              this.textoBarra = 'Algo sucedió, intente más tarde por favor';
              this.colorBarra = 'error';
              this.muestraBarra = true;

            }
          })
      } else {
        this.textoBarra = 'Seleccione uno o más subordinados y una capacitación habilitada para continuar';
        this.colorBarra = 'primary';
        this.muestraBarra = true;
      }
      this.loading = false;
      console.log(this.loading);
    },

    close() {
      this.subordinados = [];
      this.capacitaciones = [];
      this.cargando = true;
      this.emplid = [];
      this.colaboradorSelected = [];
      this.cargandoCapacitacionesTabla = true;
      this.idCapacitacion = [];
      this.capacitacionSelected = [];
      this.solicitudesCapacitaciones = [];
      this.dialogForm = false;
      this.loading = false;
    },

    getSubordinados() {
      let params = {
        Opcion: 1,
        token: localStorage.getItem('token')
      }
      axios
        .post(`${this.$store.getters.getHost}/General/SolicitudCapacitaciones/ws-solicitud-capacitaciones.php`, params)
        .then((response) => {
          if (response.data.status == "EXPSESSION") {
            deleteToken();
          } else {
            var data = response.data.subordinados;
            this.subordinados = data;
            this.cargando = false;
          }
        })
    },

    getCapacitaciones() {
      let params = {
        Opcion: 2,
        token: localStorage.getItem('token')
      }
      axios
        .post(`${this.$store.getters.getHost}/General/SolicitudCapacitaciones/ws-solicitud-capacitaciones.php`, params)
        .then((response) => {
          if (response.data.status == "EXPSESSION") {
            deleteToken();
          } else {
            var data = response.data.capacitaciones;
            if (data) {
              this.capacitaciones = data;
            }
            this.cargandoCapacitacionesTabla = false;
          }
        })
    },
    getSolicitudesCapacitacion() {
      let params = {
        Opcion: 5,
        admin: 1, // no es admin
        token: localStorage.getItem('token')
      }
      axios
        .post(`${this.$store.getters.getHost}/General/SolicitudCapacitaciones/ws-solicitud-capacitaciones.php`, params)
        .then((response) => {
          if (response.data.status == "EXPSESSION") {
            deleteToken();
          } else {
            var data = response.data.respuesta.solicitudes;
            if (data) {
              this.solicitudesCapacitaciones = data;
            }
            this.cargando = false;
          }
        })
    },
  }
}


