var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"admon-up-as"},[_c('v-container',{staticClass:"mt-5"},[_c('v-card',{staticClass:"rounded"},[_c('v-card-title',{staticClass:"px-0 pt-0"},[_c('v-tabs',{staticClass:"rounded-xl rounded-b-0",attrs:{"fixed-tabs":"","background-color":"blue accent-3","dark":"","show-arrows":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',[_vm._v(" Administración UPAS ")]),_c('v-tab',[_vm._v(" Relación UPAs - Certificaciones ")])],1)],1),_c('v-card-text',[_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.upas,"search":_vm.buscar,"mobile-breakpoint":"0","footer-props":{  'items-per-page-text': 'Elementos por página',},"no-data-text":"No se encontraron registros","dense":"","loading":_vm.loadingInfo,"loading-text":"Cargando Upas"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"dense":"","rounded":"","color":"blue accent-3"}},[_c('v-text-field',{staticClass:"mt-7 rounded-lg",attrs:{"label":_vm.$t('DH360.findTextBox'),"dense":"","clearable":"","flat":"","solo-inverted":"","dark":"","prepend-inner-icon":"mdi-magnify"},model:{value:(_vm.buscar),callback:function ($$v) {_vm.buscar=$$v},expression:"buscar"}}),_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"600px","persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"small":"","color":"teal darken-1","dark":""}},'v-btn',attrs,false),on),[_vm._v(" Nueva UPA ")])]}}]),model:{value:(_vm.dialogForm),callback:function ($$v) {_vm.dialogForm=$$v},expression:"dialogForm"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-subtitle-2 primary white--text"},[_vm._v(" "+_vm._s(_vm.formTitle)+" "),_c('v-spacer'),_c('v-icon',{attrs:{"small":"","dark":""},on:{"click":_vm.close}},[_vm._v("mdi-close")])],1),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","xl":"12","lg":"12","md":"12","sm":"12","xs":"12"}},[_c('v-text-field',{attrs:{"label":"Nombre UPA","outlined":"","shaped":"","dense":"","counter":"","maxlength":"50","rules":[_vm.rulesArticulo.required, _vm.rulesArticulo.counter]},model:{value:(_vm.editedItem.Descripcion),callback:function ($$v) {_vm.$set(_vm.editedItem, "Descripcion", $$v)},expression:"editedItem.Descripcion"}})],1)],1)],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),(_vm.editedItem.Descripcion)?_c('v-btn',{staticClass:"rounded-lg elevation-0",attrs:{"color":"teal darken-1","dark":"","loading":_vm.loading},on:{"click":function($event){return _vm.validar()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-check-circle-outline")]),_vm._v(" Guardar ")],1):_c('v-btn',{staticClass:"rounded-lg elevation-0",attrs:{"disabled":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-check-circle-outline")]),_vm._v(" Guardar ")],1)],1)],1)],1)],1)]},proxy:true},{key:"item.Descripcion",fn:function(ref){
var item = ref.item;
return [_c('div',{attrs:{"align":"left"}},[_vm._v(_vm._s(item.Descripcion))])]}},{key:"item.habilitado",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"x-small":"","dark":"","color":item.habilitado === 1 ? 'green' : 'pink darken-1'},on:{"click":function($event){return _vm.openEstatusDialog(item)}}},[_vm._v(" "+_vm._s(item.habilitado === 1 ? "Habilitado" : "Inhabilitado")+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center"},[_c('v-chip-group',{attrs:{"column":""}},[_c('v-btn',{attrs:{"x-small":"","rounded":"","elevation":"1","color":"teal darken-1","dark":""},on:{"click":function($event){return _vm.editItem(item)}}},[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("mdi mdi-pencil")])],1)],1)],1)]}}])}),_c('v-dialog',{attrs:{"max-width":"300px","persistent":""},model:{value:(_vm.dialogEstatus),callback:function ($$v) {_vm.dialogEstatus=$$v},expression:"dialogEstatus"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-subtitle-2 primary white--text"},[_vm._v(" UPA Estatus "),_c('v-spacer'),_c('v-icon',{attrs:{"small":"","dark":""},on:{"click":function($event){_vm.dialogEstatus = false}}},[_vm._v("mdi mdi-close ")])],1),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","xl":"8","lg":"8","md":"8","sm":"12","xs":"12"}},[(_vm.habilitado == false)?_c('div',[_vm._v("Inhabilitado")]):(_vm.habilitado == true)?_c('div',[_vm._v("Habilitado")]):_vm._e(),_c('v-switch',{attrs:{"color":"primary","hide-details":""},model:{value:(_vm.habilitado),callback:function ($$v) {_vm.habilitado=$$v},expression:"habilitado"}})],1)],1)],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"rounded-lg elevation-0",attrs:{"color":"teal darken-1","dark":"","loading":_vm.loading},on:{"click":function($event){return _vm.cambioEstatus()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-check-circle-outline")]),_vm._v(" Guardar ")],1)],1)],1)],1)],1),_c('v-tab-item',[_c('admonRelacionUPACertificaciones')],1)],1)],1)],1),(_vm.muestraBarra)?_c('SnackBar',{attrs:{"muestraBarra":_vm.muestraBarra,"texto":_vm.textoBarra,"color":_vm.colorBarra},on:{"cierraBarra":function($event){_vm.muestraBarra = false}}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }