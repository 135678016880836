var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"rounded-xl elevation-0 pa-3"},[_c('div',{staticStyle:{"display":"flex","justify-content":"space-between"}},[_c('div',{staticClass:"ml-2 "},[_c('label',{staticClass:"overline deep-purple--text text--lighten-3"},[_vm._v("selecciona el día de pre-nómina")]),_c('v-dialog',{ref:"dialogFechaPrenomina",attrs:{"return-value":_vm.fechaSelected,"width":"290px","content-class":"fix-border-dialog"},on:{"update:returnValue":function($event){_vm.fechaSelected=$event},"update:return-value":function($event){_vm.fechaSelected=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"rounded-pill rounded-tl-lg",staticStyle:{"max-width":"350px"},attrs:{"label":"Día de prenomina","prepend-inner-icon":"mdi-calendar","placeholder":"Selecciona la fecha de prenomina...","required":"","dense":"","outlined":"","readonly":""},on:{"change":function($event){_vm.loadingRepGeneral = true;
              _vm.updateReporte();}},model:{value:(_vm.fechaSelected),callback:function ($$v) {_vm.fechaSelected=$$v},expression:"fechaSelected"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.modalFechaPrenom),callback:function ($$v) {_vm.modalFechaPrenom=$$v},expression:"modalFechaPrenom"}},[_c('v-date-picker',{staticClass:"rounded-xl",attrs:{"scrollable":""},on:{"input":function($event){_vm.$refs.dialogFechaPrenomina.save(_vm.fechaSelected);
            _vm.modalFechaVisita = false;
            _vm.loadingRepGeneral = true;
            _vm.updateReporte();}},model:{value:(_vm.fechaSelected),callback:function ($$v) {_vm.fechaSelected=$$v},expression:"fechaSelected"}})],1)],1),_c('div',[_c('vue-json-to-csv',{attrs:{"json-data":_vm.report_to_export,"labels":_vm.headers_report_to_export,"csv-title":'reporte-abonos-' + _vm.fechaInicial}},[_c('v-btn',{staticClass:"rounded-xl mr-1",attrs:{"color":"green darken-4","depressed":"","dark":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-microsoft-excel ")]),_vm._v(" Exportar ")],1)],1),_c('v-tooltip',{attrs:{"left":"","color":"blue lighten-4"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"small":"","fab":"","depressed":""},on:{"click":function($event){_vm.loadingData = true;
              _vm.updateReporte();}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-reload")])],1)]}}])},[_c('label',{staticClass:"text-uppercase font-weight-bold primary--text"},[_vm._v("Actualizar tabla")])])],1)]),_c('v-data-table',{attrs:{"dense":"","headers":_vm.headers,"items":_vm.reporte,"loading":_vm.loadingData,"search":_vm.search},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticStyle:{"display":"flex","justify-content":"end"}},[_c('v-text-field',{staticClass:"rounded-xl",staticStyle:{"max-width":"350px"},attrs:{"outlined":"","dense":"","prepend-inner-icon":"mdi-table-search","placeholder":"Buscar en tabla..."},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]},proxy:true},{key:"item.CANTIDAD",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatMXN(parseFloat(item.CANTIDAD)))+" ")]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }