import MuestraReporte from './components/MuestraReportes.vue'

export default {
  name: 'reportes',
  components: { MuestraReporte },

  props: [],
  data() {
    return {

    }
  },
  computed: {

  },
  mounted() {

  },
  methods: {

  }
}


