<template>
  <v-dialog
    v-model="verConvenios"
    scrollable
    persistent
    transition="dialog-bottom-transition"
    max-width="1000"
  >
    <v-card>
      <v-toolbar color="primary" dark>
        <h3>Convenios</h3>
        <v-spacer> </v-spacer>
        <v-btn text dark @click="$emit('cierraConvenios')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="px-0">
        <v-slide-group
          v-model="model"
          class="px-0 my-4"
          mandatory
          center-active
          show-arrows
          mobileBreakpoint="sm"
        >
          <v-slide-item v-for="n in convenios" :key="n.id">
            <!-- :color="active ? 'primary' : 'blue lighten-5'" -->
            <v-card
              class="ma-4 back-card rounded-xl"
              elevation="0"
              max-height="380"
              max-width="190"
            >
              <div
                v-show="n.imagen != null || n.imagen != ''"
                style="display:flex; justify-content:center;margin-top: 15px;"
              >
                <v-img
                  :src="host + '/ArchivosCargadosEnPortal2/Compensaciones/Convenios/' + n.imagen"
                  max-height="150"
                  max-width="135"
                ></v-img>
              </div>
              <br>
              <div style="display:flex; justify-content:center;">
                <p
                  class="text-center"
                  style="font-weight:bolder;font-size: 1.1em;"
                >
                  {{ n.empresa }}
                </p>
              </div>
              <div
                style="display:flex; justify-content:center;margin-top: 5px;margin-left: 10px;margin-right: 10px;"
                v-for="beneficio in n.beneficios"
                :key="beneficio.id"
              >
                <p class="text-center" style="font-size: 1.1em;">
                  {{ beneficio.beneficio }}
                </p>
              </div>
            </v-card>
          </v-slide-item>
        </v-slide-group>

        <div class="text-center">
          <p style="color:darkslategray;font-weight:bolder;">
            <strong style="color:darkred;">*</strong>Todos los descuentos aquí
            mencionados pueden sufrir cambios sin previo aviso por parte del
            proveedor.
          </p>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from "axios";

export default {
  name: "ver-convenios",
  data: () => ({
    model: [],
    convenios: [],
    host: ''
  }),
  components: {},
  created() {
    this.host = this.$store.getters.getHost
  },
  mounted() {
    this.getConvenios();
  },
  props: ["verConvenios"],
  methods: {
    getConvenios() {
      let params = {
        Opcion: 1,
        token: localStorage.getItem("token"),
      };
      axios
        .post(
          `${this.$store.getters.getHost}/Compensaciones/ws-convenios.php`,
          params
        )
        .then((response) => {
          // this.loadingInfoTablaSolicitudesColab = false;
          if (response.data.status == "OK") {
            this.convenios = response.data.convenios;
            // this.muestraLoading = false;
          }
        });
    },
  },
};
</script>

<style>
.back-card {
  background-color: #f5f5ff;
  background-image: linear-gradient(0deg, #f5f5ff 0%, #eaf0f0 100%);
}
</style>
