<template>
    <div class="pa-4">
      <v-data-table
        :headers="headers"
        :items="ofertasPendientes"
        :search="search"
        :loading="loadingData"
        height="100%"
        loading-text="Cargando contentenido..."
        :footer-props="{
          'items-per-page-options': [10, 20, 30, 100],
          'items-per-page-text': 'Elementos por página',
        }"
        no-data-text="No hay registros"
        :header-props="{
          'short-by-text': 'Ordenar por',
        }"
        class="elevation-10 background-glass-effect-new-positions-request rounded-lg"
        item-key="id"
        dense
      >
        <template v-slot:item.actions="{ item }">
          <v-tooltip top small color="green darken-2">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mr-1"
                
                depressed
                color="cyan darken-2"
                small
                dark
                v-bind="attrs"
                v-on="on"
                to="../Nominas/Compensaciones/ofertasEconomicas"
              >
                crear oferta [{{ item.id }}]
                <v-icon right> mdi-arrow-right </v-icon>
              </v-btn>
            </template>
            <p class="text-uppercase mb-0 font-weight-bold body-2">
              ir
            </p>
          </v-tooltip>
        </template>
      </v-data-table>
  
      <SnackBar
        v-if="muestraBarra"
        :muestraBarra="muestraBarra"
        :color="colorBarra"
        :texto="textoBarra"
        @cierraBarra="muestraBarra = false"
      />
    </div>
  </template>

<script>
import axios from "axios";
// import { deleteToken } from "../../../../store/Funciones/funciones";
// import ConfirmDialogReclutamiento from "./ConfirmDialogReclutamiento.vue";
import SnackBar from "../../General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue";
export default {
  name: "muestra-candidatos-pendientes-de-oferta",
  components: { SnackBar },
  data() {
    return {
      search: "",
      headers: [
        {
          text: "ID",
          value: "id",
          class: "heder-requests-custom rounded-tl-lg",
        },
        {
          text: "NOMBRE",
          align: "start",
          value: "Nombre",
          class: "primary white--text text-uppercase font-weight-bold",
        },
        {
          text: "Tipo",
          align: "center",
          value: "TipoDesc",
          class: "primary white--text text-uppercase font-weight-bold",
        },
        {
          text: "APEGO A PERFIL",
          align: "start",
          value: "Apegoperfil",
          class: "primary white--text text-uppercase font-weight-bold",
        },
        {
          text: "ACCIONES",
          align: "center",
          value: "actions",
          class:
            "primary white--text text-uppercase font-weight-bold rounded-tr-lg",
        },
      ],
      ofertasPendientes: [],
      loadingData: true,
      muestraBarra: false, 
      colorBarra: "",
      textoBarra: "",
    }
  },
  async mounted() {
    this.ofertasPendientes = await this.getCandidatosConOfertaPendiente();
  },
  methods: {
    muestraSnackBar(text, color) {
      this.colorBarra = color;
      this.textoBarra = text;
      this.muestraBarra = true;
    },
    getCandidatosConOfertaPendiente() {
    //   this.conocimientos.length = 0;
      const params = {
        Opcion: 47,
        token: localStorage.getItem("token"),
      };
      return new Promise((res) => {
        axios
        .post(
          `${this.$store.getters.getHost}/Reclutamiento/ws-admin-reclu.php`,
          params
        )
        .then((response) => {
          this.loadingData = false;
          if (response.data.status == "OK") {
            res(response.data.respuesta)
          } else {
            res([])
          }
        })
        .finally(() => {
            this.loadingData = false
        })
      })
      
    },
  },
};
</script>