import ReporteDescansoPorFechas from "./components/ReporteDescansoPorFechas.vue"
export default {
    name: 'reporte-descanso-laborado',
    components: { ReporteDescansoPorFechas },
    props: [],
    data() {
        return {

        }
    },
    computed: {

    },
    mounted() {

    },
    methods: {

    }
}