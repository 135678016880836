import axios from 'axios'
import SnackBarErrorIncidencias from "../../../General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue"
export default {
    name: 'registro-nuevos-candidatos',
    components: { SnackBarErrorIncidencias },
    props: [],
    data: () => ({
        ultimoRegistro: false,
        textoBarra: '',
        colorBarra: '',
        muestraBarra: false,
        valid: true,
        dialogLista: false,
        fecha: '',
        nombre: '',
        reclutamiento: '',
        sindicato: '',
        supervisorEntrevista: '',
        medico: '',
        habilidad: '',
        documentos: '',
        fechaIngreso: '',
        comentario: '',
        planta: '',
        departamento: '',
        turno: '',
        supervisor: '',
        telefono: '',
        area: '',
        comentarioMedico: '',
        medioReclutamiento: '',
        comentarioSindicato: '',
        comentarioSupervisor: '',
        personaQueRecomienda: '',
        comentarioReclutamiento: '',

        plantas: [],
        medioReclutamientoRules: [
            'Facebook',
            'Modulo',
            'Recomendado',
            'Volanteo',
            'Feria',
            'SNF',
            'Otros'
        ],

        areas: [
            'CD', 'MFA',
            'DH', 'MKT',
            'VN', 'FI'
        ],

        turnos: [
            'Turno 1',
            'Turno 2',
            'Turno 3',
            'Grupo 1',
            'Grupo 2',
            'Grupo 3',
            'Grupo 4',
            'Grupo especial',
            'Turno mixto',
            'Turno mixto especial'
        ],
        reclutamientos: [
            'Contratable',
            'No contratable'
        ],
        sindicatos: [
            'Contratable',
            'No contratable'
        ],
        supervisorEntrevistas: [
            'Contratable',
            'No contratable'
        ],
        medicos: [
            'Contratable',
            'No contratable'
        ],
        habilidads: [
            'Grupo 1',
            'Grupo 2',
            'Grupo 3',
            'N/A'
        ],
        documentoss: [
            'Sin documentos',
            'En proceso',
            'Completados'
        ],

        nameRules: [
            v => !!v || 'Nombre es requerido',
            v => v.length <= 50 || 'El nombre debe tener menos de 50 caracteres',
        ],
        telefonoRules: [
            v => !!v || 'Teléfono es requerido',
            v => v.length <= 10 || 'El teléfono debe tener menos de 10 caracteres',
        ],
        personaQueRecomiendaRules: [
            v => !!v || 'Nombre es requerido',
            v => v.length <= 50 || 'El nombre debe tener menos de 50 caracteres',
        ],
        departamentoRules: [
            v => !!v || 'Departamento es requerido',
            v => v.length <= 20 || 'El departamento debe tener menos de 20 caracteres',
        ],
        supervisorRules: [
            v => !!v || 'Supervisor es requerido',
            v => v.length <= 50 || 'El supervisor debe tener menos de 50 caracteres',
        ],
        comentarioRules: [
            v => !!v || 'Comentario es requerido',
            v => v.length <= 100 || 'El comentario debe tener menos de 100 caracteres',
        ],
        comentarioReclutamientoRules: [
            v => !!v || 'Comentario es requerido',
            v => v.length <= 100 || 'El comentario debe tener menos de 100 caracteres',
        ],
        comentarioSindicatoRules: [
            v => !!v || 'Comentario es requerido',
            v => v.length <= 100 || 'El comentario debe tener menos de 100 caracteres',
        ],
        comentarioSupervisorRules: [
            v => !!v || 'Comentario es requerido',
            v => v.length <= 100 || 'El comentario debe tener menos de 100 caracteres',
        ],
        comentarioMedicoRules: [
            v => !!v || 'Comentario es requerido',
            v => v.length <= 100 || 'El comentario debe tener menos de 100 caracteres',
        ],

    }),
    computed: {

    },
    mounted() {

    },
    methods: {
        plantasPorArea() {
            if (this.area == 'CD') {
                this.plantas = ['Embarques', 'Surtido', 'exportaciones'];
            } else if (this.area == 'MFA') {
                this.plantas = ['Planta 1', 'Planta 2', 'Planta 3', 'Soporte'];
            } else {
                this.plantas = ['Planta 1', 'Planta 2', 'Planta 3', 'Planta 4', 'Planta 5', 'Almacen MFA'];
            }
        },

        submit() {
            let params = {
                Opcion: 1,
                opc: 2, // para el SP de AltaCandidatos 
                fecha: this.fecha,
                nombre: this.nombre,
                telefono: this.telefono,
                medioReclutamiento: this.medioReclutamiento,
                personaQueRecomienda: this.personaQueRecomienda,
                area: this.area,
                planta: this.planta,
                departamento: this.departamento,
                turno: this.turno,
                supervisor: this.supervisor,
                reclutamiento: this.reclutamiento,
                sindicato: this.sindicato,
                supervisorEntrevista: this.supervisorEntrevista,
                medico: this.medico,
                habilidad: this.habilidad,
                documentos: this.documentos,
                fechaIngreso: this.fechaIngreso,
                comentario: this.comentario,
                comentarioReclutamiento: this.comentarioReclutamiento,
                comentarioSindicato: this.comentarioSindicato,
                comentarioSupervisor: this.comentarioSupervisor,
                comentarioMedico: this.comentarioMedico,
            };

            if (params.nombre == '' || params.fecha == '' || params.telefono == '' || params.medioReclutamiento == '' ||
                params.personaQueRecomienda == '' || params.area == '' || params.planta == '' || params.departamento == '' ||
                params.turno == '' || params.supervisor == '' || params.reclutamiento == '' || params.sindicato == '' ||
                params.supervisorEntrevista == '' || params.medico == '' || params.habilidad == '' || params.documentos == '' ||
                params.fechaIngreso == '' || params.comentario == '' || params.comentarioReclutamiento == '' ||
                params.comentarioSindicato == '' || params.comentarioSupervisor == '' || params.comentarioMedico == '') {

                this.textoBarra = "Para continuar con el registro, por favor complete el formulario de nuevos candidatos";
                this.colorBarra = 'primary';
                this.muestraBarra = true;
            } else {

                axios.post(`${this.$store.getters.getHost}/OnBoarding/ws-OnBoarding.php`, params).then((response) => {
                    if (response.data.status == 'OK') {
                        this.textoBarra = "Se ha registrado el candidato correctamente.";
                        this.colorBarra = 'success';
                        this.muestraBarra = true;
                    }

                    if (response.data.status == 'noNumero') { // revisar despues        
                        this.textoBarra = "El campo de telefono unicamente acepta caracteres numericos";
                        this.colorBarra = 'primary';
                        this.muestraBarra = true;
                    }

                    if (response.data.status == 'error') {
                        this.textoBarra = "Para continuar con el registro, por favor complete con el formulario de nuevos candidatos";
                        this.colorBarra = 'primary';
                        this.muestraBarra = true;
                    }
                    console.log(response.data);
                })
            }

        },

        editar() {
            this.ultimoRegistro = true;
        },

        clear() {
            this.ultimoRegistro = true;
            // this.$v.$reset()
            this.fecha = ''
            this.nombre = ''
            this.telefono = ''
            this.medioReclutamiento = ''
            this.personaQueRecomienda = ''
            this.area = ''
            this.planta = ''
            this.departamento = ''
            this.turno = ''
            this.supervisor = ''
            this.reclutamiento = ''
            this.sindicato = ''
            this.supervisorEntrevista = ''
            this.medico = ''
            this.habilidad = ''
            this.documentos = ''
            this.fechaIngreso = ''
            this.comentario = ''
            this.comentarioReclutamiento = ''
            this.comentarioSindicato = ''
            this.comentarioSupervisor = ''
            this.comentarioMedico = ''
        },
    }
}