<template>
    <LineChartComponent
      :chart-options="chartOptions"
      :chart-data="chartData"
      :chart-id="chartId"
      :dataset-id-key="datasetIdKey"
      :plugins="plugins"
      :css-classes="cssClasses"
      :styles="styles"
      :width="width"
      :height="height"
    />
  </template>
  
  <script>
  import { Line as LineChartComponent } from "vue-chartjs/legacy";
  import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    BarElement,
    CategoryScale,
    LinearScale,
  } from "chart.js";
  
  ChartJS.register(
    Title,
    Tooltip,
    Legend,
    BarElement,
    CategoryScale,
    LinearScale
  );
  
  export default {
    name: "LineChart",
    components: {
      LineChartComponent,
    },
    props: {
      chartId: {
        type: String,
        default: "line-chart",
      },
      datasetIdKey: {
        type: String,
        default: "label",
      },
      width: {
        type: Number,
        default: 400,
      },
      height: {
        type: Number,
        default: 161,
      },
      cssClasses: {
        default: "",
        type: String,
      },
      styles: {
        type: Object,
        default: () => {},
      },
      plugins: {
        type: Array,
        default: () => [],
      },
      labelsDataset: {
        type: Array,
        value: [],
      },
      dataDataset: {
        type: Array,
        default: () => [],
      },
      title: {
        type: String,
        default: "Título"
      }
    },
    data() {
      return {
        chartData: {
          labels: this.labelsDataset,
          datasets: [
            {
              label: this.title,
              backgroundColor: "#0078B4",
              data: this.dataDataset,
            },
          ],
        },
        chartOptions: {
          responsive: false,
          maintainAspectRatio: false,
        },
        abiertas: [],
        cerradas: [],
      };
    },
  
    mounted() {
    },
  
    methods: {},
  };
  </script>
  