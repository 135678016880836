<template>
  <v-card class="rounded-lg pa-2" elevation="3">
    <div class="mb-2">
      <v-progress-linear
        v-model="porcentajeAvance"
        class="rounded-pill"
        :color="getColor()"
        height="25"
      >
        <template v-slot:default="{ value }">
          <strong style="color:white">{{ value.toFixed(2) }}%</strong>
        </template>
      </v-progress-linear>
    </div>
    <div>
      <p class="text-center font-weight-bold my-1" :color="getColor()" style="font-size:1.05rem;">
        {{ avance }} {{ $t("DH360.of") }} {{ avance + faltantes }}
      </p>
    </div>
    <v-data-table
      :headers="headers"
      :items="participantes"
      :items-per-page="itemsPerPage"
      :footer-props="{
        'items-per-page-options': [5, 10, 50, 100],
        'items-per-page-text': 'Elementos por página',
      }"
      dense
      no-data-text="No hay registros"
      :header-props="headerProps"
      :loading="loadingTabla"
      :search="search"
      item-key="Evaluador"
      class="elevation-2"
      :single-expand="singleExpand"
      :expanded.sync="expanded"
      show-expand
    >
      <template v-slot:top>
        <v-toolbar flat color="primary" class="rounded-lg rounded-b-0">
          <v-text-field
            v-model="search"
            :label="$t('DH360.findTextBox')"
            :loading="loadingTabla"
            class="mt-7"
            dense
            clearable
            flat
            solo-inverted
            dark
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-divider
            class="mx-6"
            inset
            vertical
            style="border-color:lightslategrey"
          ></v-divider>
          <v-btn
            text
            color="white"
            dark
            class="mb-1 mr-1 font-weight-bold rounded-lg"
            :loading="loadingTabla"
            @click="showSurveysPostDate = true"
          >
            <v-icon left>mdi-clipboard-text-multiple-outline</v-icon>
            {{ $t("DH360.uploadClients") }}
          </v-btn>
          <vue-json-to-csv
            :json-data="participantes"
            :labels="headers_report_to_export"
            :csv-title="nameFile"
          >
            <v-btn
              color="green darken-1"
              dark
              class="mb-1 font-weight-bold rounded-lg"
              :loading="loadingTabla"
            >
              <v-icon left>mdi-microsoft-excel</v-icon>
              {{ $t("DH360.export") }}
            </v-btn>
          </vue-json-to-csv>
        </v-toolbar>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <MuestraEvaluacionesPendientesDetalle
            :idProyecto="idProyecto"
            :empleado="item.Evaluador"
          />
        </td>
      </template>
      <template v-slot:item.ESTATUS="{ item }">
        <p>{{ item.Contestadas }} {{ $t("DH360.of") }} {{ item.Totales }}</p>
      </template>
      <template v-slot:item.ACTIONS="{ item }">
        <v-icon class="ml-1" small @click="openSendMail(item.Correo)">
          mdi-email
        </v-icon>
      </template>
    </v-data-table>

    <PersonaDialogSendMail
      v-if="sendMail"
      :showSendMail="sendMail"
      :defaultMsg="textMsgMail"
      :receptor="mailSelected"
      @hideSendMail="closeSendMail"
    />

    <SnackBar
      :muestraBarra="muestraBarra"
      :texto="textoBarra"
      :color="colorBarra"
      @cierraBarra="muestraBarra = false"
    />
    <AdmonEncuestasClientesPorGenerar
      v-if="showSurveysPostDate"
      :showSurveysPostDate="showSurveysPostDate"
      :idProyecto="idProyecto"
      @hideSurveysPostDate="cierraEncuestasPorGenerar"
    />

    <AsignaPuntosPorPregunta v-if="modalTicket" :abrirModalTicket="modalTicket" @cerrarTicket="modalTicket = false"></AsignaPuntosPorPregunta>

  </v-card>
</template>

<script>
import axios from "axios";
import { deleteToken } from "../../../../../../store/Funciones/funciones";
import VueJsonToCsv from "vue-json-to-csv";
import MuestraEvaluacionesPendientesDetalle from "./MuestraEvaluacionesPendientesDetalle.vue";
import PersonaDialogSendMail from "../../../../../../components/PersonaDialogSendMail.vue";
import SnackBar from "../../../../General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue";
import AdmonEncuestasClientesPorGenerar from "./AdmonEncuestasClientesPorGenerar.vue";
import AsignaPuntosPorPregunta from '../../../../General/MiEmpresa/MiEvaluacionDeDesempenio/components/AsignaPuntosPorPregunta.vue'

export default {
  name: "reporte-evaluaciones-respondidas",
  components: {
    VueJsonToCsv,
    MuestraEvaluacionesPendientesDetalle,
    PersonaDialogSendMail,
    SnackBar,
    AdmonEncuestasClientesPorGenerar,
    AsignaPuntosPorPregunta
  },
  props: ["idProyecto","compania"],
  data() {
    return {
      participantes: [],
      headers: [
        { value: "Evaluador", text: "EMPLID" },
        { value: "NOMBRE", text: "" },
        { value: "Abreviatura", text: "" },
        { value: "ESTATUS", text: "", align: "center" },
        { value: "ACTIONS", text: "", align: "center" },
      ],
      headers_report_to_export: {
        Evaluador: {
          title: "EMPLID",
        },
        NOMBRE: {
          title: "Nombre",
        },
        Contestadas: {
          title: "Contestadas",
        },
        Totales: {
          title: "Totales",
        },
      },
      headerProps: {
        sortByText: "Ordenar por",
      },
      itemsPerPage: 10,
      loadingTabla: true,
      sendMail: false,
      search: "",
      nameFile: "",
      mailSelected: "",
      textMsgMail: "",
      expanded: [],
      singleExpand: false,
      muestraBarra: false,
      showSurveysPostDate: false,
      modalTicket: false,
      textoBarra: "",
      colorBarra: "",
      avance: 0,
      faltantes: 0,
      porcentajeAvance: 0
    };
  },
  computed: {},
  mounted() {
    this.textMsgMail = this.$t("DH360.txtMsgMailToAnsweredAssasment");
    this.headers[1].text = this.$t("DH360.nameTextHeader");
    this.headers[2].text = this.$t("DH360.areaTextHeader");
    this.headers[3].text = this.$t("DH360.statusTextHeader");
    this.headers[4].text = this.$t("DH360.actionsTextHeader");
    let ahora = new Date();
    this.nameFile =
      "evRespondidas" +
      ahora.getDate().toString() +
      ahora.getMonth().toString() +
      ahora.getUTCFullYear().toString() +
      ahora.getUTCHours().toString() +
      ahora.getUTCMinutes().toString() +
      ahora.getUTCMilliseconds().toString();
    this.getRepEvaluacionesRespondidas();
  },
  methods: {
    getRepEvaluacionesRespondidas() {
      let params = {
        Opcion: 30,
        token: localStorage.getItem("token"),
        idProyecto: this.idProyecto,
        compania:this.compania
      };
      axios
        .post(
          `${this.$store.getters.getHost}/RelacionesLaborales/AdmonEvaluacionesDesempenio/ws-admon-360.php`,
          params
        )
        .then((response) => {
          if (response.data.status == "EXPRESSION") {
            deleteToken();
          } else if (response.data.status == "OK") {
            this.participantes = response.data.respuesta;
            this.loadingTabla = false;
            this.muestraAvance()
          }
        });
    },

    openSendMail(mailTo) {
      this.mailSelected = mailTo;
      this.sendMail = true;
    },

    closeSendMail(type) {
      switch (type) {
        case -1: {
          this.sendMail = false;
          break;
        }
        case 0: {
          this.sendMail = false;
          this.colorBarra = "error";
          this.textoBarra = this.$t("DH360.errorMessaggeDB");
          this.muestraBarra = true;
          break;
        }
        case 1: {
          this.sendMail = false;
          this.colorBarra = "success";
          this.textoBarra = "enviado";
          this.muestraBarra = true;
          break;
        }
      }
    },

    cierraEncuestasPorGenerar(type){
      this.showSurveysPostDate = false
      if(type >= 1){
        this.colorBarra = "success"
        this.textoBarra = this.$t('DH360.successfullyMessageAddParticipants')
        this.muestraBarra = true
      } else if(type == 0){
        this.colorBarra = "error"
        this.textoBarra = this.$t('DH360.errorMessaggeDB')
        this.muestraBarra = true
      }
    },

    muestraAvance(){
      let params = {
        Opcion: 37,
        token: localStorage.getItem("token"),
        idProyecto: this.idProyecto,
      };
      axios
        .post(
          `${this.$store.getters.getHost}/RelacionesLaborales/AdmonEvaluacionesDesempenio/ws-admon-360.php`,
          params
        )
        .then((response) => {
          if (response.data.status == "EXPRESSION") {
            deleteToken();
          } else if (response.data.status == "OK") {
            this.avance = response.data.respuesta.contestadas;
            this.faltantes = response.data.respuesta.faltantes;
            this.porcentajeAvance = (this.avance * 100) / (this.avance + this.faltantes)
          }
        });
    },

    getColor() {
      if (this.porcentajeAvance == 100) return "green darken-3";
      else if (this.porcentajeAvance < 100 && this.porcentajeAvance > 60) return "amber";
      else return "red darken-3";
    },
  },
};
</script>
