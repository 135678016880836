var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.cuadroBase,"search":_vm.buscar,"loading":_vm.loadingInfo,"single-expand":_vm.singleExpand,"expanded":_vm.expanded,"item-key":"id","show-expand":"","loading-text":"Cargando cuadro base","dense":"","mobile-breakpoint":"0","footer-props":{ 'items-per-page-text': 'Elementos por página' },"no-data-text":"No se encontraron registros"},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{staticClass:"rounded-lg rounded-b-0 rounded-t-0",attrs:{"flat":""}},[_c('v-text-field',{staticClass:"mt-7 rounded-lg",attrs:{"label":_vm.$t('DH360.findTextBox'),"dense":"","clearable":"","outlined":"","solo-inverted":"","prepend-inner-icon":"mdi-table-search"},model:{value:(_vm.buscar),callback:function ($$v) {_vm.buscar=$$v},expression:"buscar"}}),_c('v-spacer'),_c('v-btn',{staticClass:"mb-1 font-weight-bold rounded-lg",attrs:{"color":"teal darken-2","dark":"","small":""},on:{"click":function($event){return _vm.abrirFormulario('Nuevo')}}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v("mdi-plus")]),_vm._v(" Nuevo ")],1)],1)]},proxy:true},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[(!item.puesto && !_vm.loadingInfo)?_c('div',{staticClass:"overline mx-auto pa-5",attrs:{"align":"center"}},[_c('v-icon',[_vm._v("mdi-alert")]),_vm._v(" No se han asignado puestos al cuadro base "),_c('br'),_vm._v(" Departamento: "),_c('b',[_vm._v(" "+_vm._s(item.DESCR)+" ["+_vm._s(item.depto)+"] ")]),_vm._v(" "),_c('br'),_vm._v(" Turno: "),_c('b',[_vm._v(" "+_vm._s(item.Descripcion))])],1):(item.puesto && !_vm.loadingInfo)?_c('v-row',{staticClass:"d-flex justify-space-around mb-6 pa-8"},[_c('v-col',{staticClass:"py-0 overline d-flex justify-center",attrs:{"cols":"12","xl":"12","lg":"12","md":"12","sm":"12","xs":"12"}},[_vm._v("Lista de puestos: "+_vm._s(item.puesto.length))]),_c('v-divider'),_vm._l((item.puesto),function(i,index){return _c('v-col',{key:index,staticClass:"py-0",attrs:{"cols":"12","xl":"3","lg":"4","md":"4","sm":"6","xs":"12"}},[_c('v-card',{staticClass:"rounded-lg mb-5",attrs:{"elevation":"2"}},[_c('v-card-title',{staticClass:"text-subtitle-2 primary white--text"},[_vm._v(" ["+_vm._s(i.JOBCODE)+"] "+_vm._s(i.DESCR)+" ")]),_c('div',{staticClass:"pa-5 overline d-flex justify-center"},[_vm._v(" Numero de puestos: "),_c('b',[_vm._v(_vm._s(i.numPuestos))])])],1)],1)})],2):_vm._e()],1)]}},{key:"item.departamentoDescr",fn:function(ref){
var item = ref.item;
return [_c('div',{attrs:{"align":"left"}},[_vm._v(_vm._s(item.departamentoDescr))])]}},{key:"item.Descripcion",fn:function(ref){
var item = ref.item;
return [_c('div',{attrs:{"align":"left"}},[_vm._v(_vm._s(item.Descripcion))])]}},{key:"item.tipoOp",fn:function(ref){
var item = ref.item;
return [_c('div',{attrs:{"align":"left"}},[_vm._v(_vm._s(item.tipoOp))])]}},{key:"item.plantaNombre",fn:function(ref){
var item = ref.item;
return [_c('div',{attrs:{"align":"left"}},[_vm._v("["+_vm._s(item.planta)+"] "+_vm._s(item.plantaNombre))])]}},{key:"item.estatus",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":"","color":"blue lighten-4"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"blue darken-4"},on:{"click":function($event){return _vm.abrirFormulario(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil-box-outline ")])]}}],null,true)},[_c('label',{staticClass:"text-uppercase font-weight-bold primary--text"},[_vm._v("Editar")])]),_c('v-tooltip',{attrs:{"top":"","color":"pink lighten-4"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"pink darken-4"},on:{"click":function($event){return _vm.openDialogRemove(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete-empty-outline ")])]}}],null,true)},[_c('label',{staticClass:"text-uppercase font-weight-bold pink--text"},[_vm._v("Remover")])])]}}])}),(_vm.dialogForm)?_c('form-cuadro-base',{attrs:{"dialog":_vm.dialogForm,"item":_vm.item},on:{"cierraForm":_vm.cerrarFormulario}}):_vm._e(),(_vm.dialogRemove)?_c('delete-cuadro-base',{attrs:{"dialog":_vm.dialogRemove,"item":_vm.item},on:{"cierraDialogDelete":_vm.cerrarDialog}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }