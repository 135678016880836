<template>
  <div>
    <v-sheet v-if="loadingData">
      <v-row class="fill-height py-5" align-content="center" justify="center">
        <v-col class="subtitle-1 text-center" cols="12">
          Obteninendo información...
        </v-col>
        <v-col cols="6" class="center-item-in-div">
          <v-progress-circular
            :size="70"
            :width="8"
            color="primary"
            indeterminate
          ></v-progress-circular>
        </v-col>
      </v-row>
    </v-sheet>
    <v-card
      class="backgroud-color-card-reclutamiento rounded-lg rounded-t-0 pa-5 center-item-in-div-column"
      v-else
    >
      <v-sheet
        min-height="50"
        width="100%"
        class="neumorphism-card-reclutamiento center-item-in-div-column rounded-xl pa-2 mb-2"
      >
        <div class="text-uppercase text-center font-weight-bold">
          entrevistadores ({{ entrevistadoresConCafNum }}/{{ entrevistadoresNum }})
        </div>
        <v-row v-if="apego" dense>
          <v-col
            v-for="(entrevistador, index) in apego.entrevistadores"
            :key="index"
            style="display: flex; justify-content: center; align-items: center;"
          >
            <v-chip
              small
              v-if="entrevistador.calificacionAgregada"
              color="teal darken-1"
              dark
            >
              <v-icon left small>mdi-check-circle</v-icon>
              {{ entrevistador.EMP_NOMEMP }}
            </v-chip>
            <v-chip small v-else color="pink darken-3" dark>
              <v-icon left small>mdi-close-circle</v-icon>
              {{ entrevistador.EMP_NOMEMP }}
            </v-chip>
          </v-col>
        </v-row>
      </v-sheet>
      <v-sheet
        min-height="50"
        width="100%"
        class="neumorphism-card-reclutamiento center-item-in-div-column rounded-xl pa-0"
      >
        <!-- {{apego.escolaridad.calificaciones.length}} -->
        <v-simple-table
          dense
          style="width: 100%; background-color: transparent;"
          :hide-default-header="true"
        >
          <thead>
            <tr>
              <th
                :colspan="columnas"
                class="main-header subtitle-1 text-center text-uppercase white--text font-weight-bold"
              >
                Apego a perfil
              </th>
            </tr>
            <tr>
              <th
                :colspan="columnas"
                class="text-subheader text-center text-uppercase subtitle-2"
              >
                Escolaridad
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style="width: 50% !important;">
                {{ apego.escolaridad.esc + " (" + apego.escolaridad.carrera + ")" }}
              </td>
              <td
                v-for="(item, index) in apego.escolaridad.calificaciones"
                :key="index"
                class="text-center"
              >
                <div
                  class="caption text-capitalize blue-grey--text text--darken-2"
                >
                  {{ getNombreEntrevistador(item.NombreEntrevistador) }}
                </div>
                <div class="font-weight-bold ">{{ item.Calificacion }}</div>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
        <v-simple-table
          dense
          style="width: 100%; background-color: transparent;"
          outlined
        >
          <thead>
            <tr>
              <th
                :colspan="columnas"
                class="text-subheader text-center text-uppercase subtitle-2"
              >
                Conocimientos
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(conocimiento, i) in apego.conocimientos" :key="i">
              <td style="width: 50% !important;">{{ conocimiento.descr }}</td>
              <td
                v-for="(item, j) in conocimiento.calificaciones"
                :key="j"
                class="text-center"
              >
                <div
                  class="caption text-capitalize blue-grey--text text--darken-2"
                >
                  {{ getNombreEntrevistador(item.NombreEntrevistador) }}
                </div>
                <div class="font-weight-bold ">{{ item.Calificacion }}</div>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
        <v-simple-table
          dense
          style="width: 100%; background-color: transparent;"
          outlined
        >
          <thead>
            <tr>
              <th
                :colspan="columnas"
                class="text-subheader text-center text-uppercase subtitle-2"
              >
                Comentarios
              </th>
            </tr>
            <tr>
              <th class="font-weight-bold subtitle-2">Fortalezas</th>
              <th class="font-weight-bold subtitle-2">Oportunidades</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(comentario, i) in apego.comentarios" :key="i">
              <td>{{ comentario.fortalezas }}</td>
              <td>{{ comentario.oportunidades }}</td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-sheet>
      <v-sheet
        min-height="50"
        width="200px"
        class="neumorphism-card-reclutamiento center-item-in-div-column rounded-xl pa-0 mt-2"
      >
        <div
          v-if="
            entrevistadoresNum == entrevistadoresConCafNum &&
              calificacionFinal >= 80 
          "
        >
          Calificacion Aprobatoria
        </div>
        <div
          v-else-if="
            entrevistadoresNum == entrevistadoresConCafNum &&
              calificacionFinal < 80
          "
        >
          Calificacion No Aprobatoria
        </div>
        <div v-else>Calificacion Actual</div>
        <div
          class="font-weight-bold text-h4 teal--text text--darken-1"
          v-if="
            entrevistadoresNum == entrevistadoresConCafNum &&
              calificacionFinal >= 80
          "
        >
          {{ calificacionFinal.toFixed(2) }}
        </div>
        <div
          class="font-weight-bold text-h4 pink--text text--darken-3"
          v-else-if="
            entrevistadoresNum == entrevistadoresConCafNum &&
              calificacionFinal < 80
          "
        >
          {{ calificacionFinal.toFixed(2) }}
        </div>
        <div class="font-weight-bold text-h4" v-else>
          {{ calificacionFinal.toFixed(2) }}
        </div>
        <!-- {{ calificacionFinal }} {{ apego.candidato.tipo }} {{ entrevistadoresNum }} -->
        <v-btn
          v-if="
            entrevistadoresNum == entrevistadoresConCafNum &&
              ((calificacionFinal >= 80 && apego.candidato.tipo != 'Interno')  || apego.candidato.tipo == 'Interno') &&
              apego.candidato.estatusId === 6 &&
              !enviadoSeleccion 
          "
          small
          dark
          class="rounded-pill elevation-0 mb-2"
          color="teal darken-1"
          :loading="loadingSelection"
          @click="loadingSelection = true; enviaSeleccion()"
        >
          enviar a selección 
        </v-btn>
      </v-sheet>
    </v-card>
    <SnackBar
      v-if="muestraBarra"
      :muestaBarra="muestraBarra"
      :texto="textoBarra"
      :color="colorBarra"
      @cierraBarra="muestraBarra = false"
    />
  </div>
</template>

<script>
import axios from "axios";
import { deleteToken } from "../../../../../store/Funciones/funciones";
import SnackBar from "../../../General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias";
export default {
  name: "informacion-vacante-candidato",
  props: ["idCandidato"],
  components: { SnackBar },
  data() {
    return {
      loadingData: true,
      apego: {},
      muestraBarra: false,
      loadingSelection: false,
      enviadoSeleccion: false,
      columnas: 1,
      textoBarra: "",
      colorBarra: "",
      calificacionFinal: 0,
      entrevistadoresNum: 0,
      entrevistadoresConCafNum: 0,
    };
  },
  computed: {
    isSmallDisplay() {
      return this.$vuetify.breakpoint.name == "xs" ||
        this.$vuetify.breakpoint.name == "xs"
        ? true
        : false;
    },
  },
  mounted() {
    this.getApegoCandidato();
  },
  methods: {
    getApegoCandidato() {
      let params = {
        Opcion: 5,
        token: localStorage.getItem("token"),
        idCandidato: this.idCandidato,
      };
      axios
        .post(
          `${this.$store.getters.getHost}/Reclutamiento/ws-admin-candidatos.php`,
          params
        )
        .then((response) => {
          this.loadingData = false;
          if (response.data.status != "EXPSESSION") {
            this.apego = response.data.candidato;
            this.columnas += this.apego.entrevistadores.length;
            this.calificacionFinal = this.getCalificacionFinal();
          } else {
            deleteToken();
          }
        })
        .catch((e) => {
          this.colorBarra = "error";
          this.textoBarra = e;
          this.muestaBarra = true;
        });
    },
    getNombreEntrevistador(cadena) {
      let arrayName = cadena.split("/");
      if (arrayName.length > 1) {
        return arrayName[2].toLowerCase();
      } else {
        return arrayName[0].toLowerCase();
      }
    },
    getCalificacionFinal() {
      let sumatoriaCalificaciones = 0;
      this.entrevistadoresNum = this.apego.entrevistadores.length;
      let findEntrevistadoresConCalificacion = this.apego.entrevistadores.filter(
        (e) => {
          return e.calificacionAgregada;
        }
      );
      this.entrevistadoresConCafNum = findEntrevistadoresConCalificacion.length;
      this.apego.escolaridad.calificaciones.forEach((calificacion) => {
        sumatoriaCalificaciones += calificacion.Calificacion;
      });
      this.apego.conocimientos.forEach((conocimiento) => {
        conocimiento.calificaciones.forEach((calificacion) => {
          sumatoriaCalificaciones += calificacion.Calificacion;
        });
      });
      return sumatoriaCalificaciones / this.entrevistadoresNum;
    },
    enviaSeleccion(){
      const params = {
        Opcion: 14,
        token: localStorage.getItem('token'),
        idCandidato: this.idCandidato,
        apego: this.calificacionFinal.toFixed(0)
      }
      axios
        .post(
          `${this.$store.getters.getHost}/Reclutamiento/ws-admin-candidatos.php`,
          params
        )
        .then((response) => {
          this.loadingSelection = false;
          if (response.data.status != "EXPSESSION") {
            if(response.data.candidato.status == "OK"){
              this.enviadoSeleccion = true;
              this.colorBarra = "primary";
              this.textoBarra = response.data.candidato.message;
              this.muestaBarra = true;
            } else {
              this.colorBarra = "error";
              this.textoBarra = response.data.candidato.message;
              this.muestaBarra = true;
            }
          } else {
            deleteToken();
          }
        })
        .catch((e) => {
          this.colorBarra = "error";
          this.textoBarra = e;
          this.muestaBarra = true;
        });
    }
  },
};
</script>

<style>
.main-header {
  background-color: rgb(0, 120, 195);
  border-radius: 20px 20px 0px 0px !important;
}

.text-subheader {
  background-color: #82b1ff;
}
</style>
