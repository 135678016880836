<template>
    <Bar :chart-options="chartOptions" :chart-data="chartData" :chart-id="chartId" :dataset-id-key="datasetIdKey"
        :plugins="plugins" :css-classes="cssClasses" :styles="styles" :width="width" :height="height" />
</template>
  
<script>
import { Bar } from "vue-chartjs/legacy";
import axios from "axios";
import moment from "moment/moment";
// import { METHODS } from "http";
import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    BarElement,
    CategoryScale,
    LinearScale,
} from "chart.js";

ChartJS.register(
    Title,
    Tooltip,
    Legend,
    BarElement,
    CategoryScale,
    LinearScale
);

export default {
    name: "BarChart",
    components: {
        Bar,
    },
    props: {
        chartId: {
            type: String,
            default: "bar-chart",
        },
        datasetIdKey: {
            type: String,
            default: "label",
        },
        width: {
            type: Number,
            default: 400,
        },
        height: {
            type: Number,
            default: 160,
        },
        cssClasses: {
            default: "",
            type: String,
        },
        styles: {
            type: Object,
            default: () => { },
        },
        plugins: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            chartData: {
                labels: ["GUDH","Desarrollo Humano","Logistica","Operaciones","Innovacion y competitividad"],
                datasets: [],
            },
            chartOptions: {
                responsive: true,
                maintainAspectRatio: false,
            },
            datosreal:[],
            cero: null,
            uno: null,
            seis: null,
            siete:null,
            once:null,
        };
    },

    mounted() {
        this.getDatacostobar()
    },

    methods: {
        getDatacostobar() {
            const yearactual = moment().year();
            let params = {
                Opcion: 11,
                año: yearactual,
                token: localStorage.getItem("token"),
            };
            axios
                .post(
                    `${this.$store.getters.getHost}/general/ws-TiempoExtra.php`,
                    params
                )
                .then((response) => {
                    if (response.data.status != "EXPSESSION") {

                        //this.chartData.labels.push(yearactual)

                        this.cero = response.data.cero.costototalconceptocero;

                        this.uno = response.data.uno.costototalconceptouno;

                        this.seis = response.data.seis.costototalconceptoseis;

                        this.siete = response.data.siete.costototalconceptosiete;

                        this.once = response.data.once.costototalconceptoonce;

                        this.datosreal.push(this.cero,this.uno,this.seis,this.siete,this.once);

                        this.fillDatasets();

                    }
                });
        },
        fillDatasets() {
            const yearactual = moment().year();
            this.chartData.datasets.push({
                label: yearactual,
                backgroundColor:["#3e95cd", "#BBDEFB","#4DB6AC","#00B0FF","#FFF59D"],// "#0078B4",
                data: this.datosreal,
            });
            // this.chartData.datasets.push({
            //     label: "Desarrollo Humano",
            //     backgroundColor: "#BBDEFB",
            //     data: this.uno,
            // });
            // this.chartData.datasets.push({
            //     label: "Logistica",
            //     backgroundColor: "#4DB6AC",
            //     data: this.seis,
            // });
            // this.chartData.datasets.push({
            //     label: "Operaciones",
            //     backgroundColor: "#00B0FF",
            //     data: this.siete,
            // });
            // this.chartData.datasets.push({
            //     label: "Innovacion y competitividad",
            //     backgroundColor: "#FFF59D",
            //     data: this.once,
            // });
        },
    },
};
</script>