var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"rounded-lg elevation-0",attrs:{"width":"100%"}},[_c('v-data-table',{staticStyle:{"background-color":"#ECEFF1"},attrs:{"headers":_vm.headers,"items":_vm.pases,"search":_vm.search,"no-data-text":"No se han registrado pases de salida","loading-text":"Cargando información...","loading":_vm.loadingPasses,"footer-props":{
    'items-per-page-text': 'Elementos por página',
    'pageText': '{0}-{1} de {2}',
  },"single-expand":true,"expanded":_vm.expanded,"show-expand":""},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticStyle:{"display":"flex","justify-content":"space-between"}},[_c('v-text-field',{staticClass:"rounded-xl",staticStyle:{"max-width":"300px"},attrs:{"outlined":"","dense":"","clearable":"","prepend-inner-icon":"mdi-table-search","placeholder":"Buscar pase..."},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('div',[_c('v-tooltip',{attrs:{"bottom":"","color":"blue lighten-4"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"color":"blue lighten-4","small":"","fab":"","depressed":""},on:{"click":function($event){return _vm.getPasesPorEmpleado()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-reload")])],1)]}}])},[_c('label',{staticClass:"text-uppercase font-weight-bold primary--text"},[_vm._v("Actualizar tabla")])])],1)],1)]},proxy:true},{key:"expanded-item",fn:function(ref){
  var headers = ref.headers;
  var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-card',{staticClass:"rounded-lg pa-5 my-2"},[_c('div',{staticClass:"pt-1"},[_c('label',{staticClass:"overline"},[_c('strong',[_vm._v("unidad de medida: ")]),_vm._v(" "+_vm._s(item.descMedida))])]),_c('v-simple-table',{attrs:{"dense":""}},[_c('thead',[_c('tr',[_c('th',{staticClass:"header-material-table overline font-weight-bold white--text"},[_vm._v("cantidad")]),_c('th',{staticClass:"header-material-table overline font-weight-bold white--text"},[_vm._v("unidad")]),_c('th',{staticClass:"header-material-table overline font-weight-bold white--text"},[_vm._v("codigo")]),_c('th',{staticClass:"header-material-table overline font-weight-bold white--text"},[_vm._v("descripción")]),_c('th',{staticClass:"header-material-table overline font-weight-bold white--text"},[_vm._v("referencia")])])]),_c('tbody',_vm._l((item.material),function(m,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(m.cantidad))]),_c('td',[_vm._v(_vm._s(m.unidad))]),_c('td',[_vm._v(_vm._s(m.codigo))]),_c('td',[_vm._v(_vm._s(m.descripcion))]),_c('td',[_vm._v(_vm._s(m.num_referencia_OT_WMS))])])}),0)])],1)],1)]}},{key:"item.estatus",fn:function(ref){
  var item = ref.item;
return [_c('v-chip',{class:_vm.getClass(item.estatus),attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.getEstatusText(item.estatus))+" ")])]}},{key:"item.fecha_solicitud",fn:function(ref){
  var item = ref.item;
return [_vm._v(" "+_vm._s(![null, undefined].includes(item.fecha_solicitud) ? _vm.fixDate(item.fecha_solicitud.date.substring(0, 10)) + ' ' + item.fecha_solicitud.date.substring(11, 16) : '')+" ")]}},{key:"item.actions",fn:function(ref){
  var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":"","color":"cyan darken-1"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [(item.estatus === 0)?_c('v-btn',_vm._g(_vm._b({staticClass:"rounded-pill",attrs:{"small":"","color":"cyan darken-1","dark":"","loading":item.id == _vm.idAux && _vm.loadingBtnNofify ? true : false},on:{"click":function($event){_vm.idAux = item.id; _vm.loadingBtnNofify = true; _vm.sendMail(item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v(" mdi-bell-ring ")]),_vm._v(" Notificar ")],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Reenviar notificación")])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }