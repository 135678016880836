var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._l((_vm.niveles),function(item){return _c('v-row',{key:item.id,staticClass:"px-5 align-center"},[_c('label',{staticClass:"pl-5 pb-13 ma-0 font-weight-bold text--disabled align-start",staticStyle:{"position":"absolute"}},[_vm._v("Nivel:"+_vm._s(item.nivel))]),_vm._l((_vm.nivelesReverse),function(item2){return _c('v-col',{key:item2.id,staticClass:"pa-0",attrs:{"cols":_vm.medidasCols}},[(item.nivel == item2.nivel && item2.id_area)?_c('v-card',{staticClass:"pb-1",attrs:{"elevation":"3"}},[_c('div',{staticClass:"color-bar"}),_c('p',{staticClass:"my-1 px-2 text-center"},[_vm._v(" Nivel "+_vm._s(item.nivel)+" "),(_vm.rol)?_c('v-tooltip',{attrs:{"top":"","color":"pink lighten-2"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"pink","text":""},on:{"click":function($event){return _vm.confirmItem(item)}}},'v-icon',attrs,false),on),[_vm._v("mdi-delete-empty-outline")])]}}],null,true)},[_c('span',[_vm._v(" Borrar nivel")])]):_vm._e()],1),_c('v-divider'),_c('div',{staticClass:"mx-5 px-3 mb-3 pb-2 scroll-card",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.openListaPuestos(item)}}},[(item.puestos)?_c('div',_vm._l((item.puestos),function(i,index){return _c('li',{key:index,staticStyle:{"font-size":"0.8em"}},[_vm._v(" "+_vm._s(i.puesto)+" "+_vm._s(i.numPuestosReal)+" / "+_vm._s(i.numPuestosPlan)+" ")])}),0):_c('div',[_vm._v("Vacio")])])],1):(item.nivel == item2.nivel + 1)?_c('v-card',{staticClass:"mx-5",attrs:{"elevation":"0"}},[(_vm.rol)?_c('v-tooltip',{attrs:{"top":"","color":"blue lighten-2"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-5",attrs:{"color":"primary","text":"","block":""},on:{"click":function($event){return _vm.confirmNivelBetween(item)}}},'v-btn',attrs,false),on),[(
                  _vm.$vuetify.breakpoint.name == 'xs' ||
                  _vm.$vuetify.breakpoint.name == 'sm'
                )?_c('v-icon',[_vm._v("mdi-arrow-up")]):_c('v-icon',[_vm._v("mdi-arrow-up-right")])],1)]}}],null,true)},[_c('span',[_vm._v(" Añadir nivel")])]):_vm._e()],1):_vm._e()],1)})],2)}),_c('v-row',[(_vm.rol)?_c('v-btn',{attrs:{"color":"cyan","text":"","block":"","loading":_vm.loading},on:{"click":_vm.nuevoNivel}},[_vm._v(" Nuevo Nivel ")]):_vm._e()],1),(_vm.openDetalles)?_c('lista-perfil-puestos',{attrs:{"dialog":_vm.openDetalles,"item":_vm.item,"depto":_vm.depto},on:{"cierraDialog":_vm.close}}):_vm._e(),(_vm.showConfirm)?_c('ConfirmDialogReclutamientoVue',{attrs:{"showConfirm":_vm.showConfirm,"question":_vm.txtQuestion},on:{"cierraConfirm":_vm.deleteItem}}):_vm._e(),(_vm.showConfirmAdd)?_c('ConfirmDialogReclutamientoVue',{attrs:{"showConfirm":_vm.showConfirmAdd,"question":_vm.txtQuestion},on:{"cierraConfirm":_vm.addNivelBetween}}):_vm._e(),(_vm.muestraBarra)?_c('SnackBarErrorIncidencias',{attrs:{"muestraBarra":_vm.muestraBarra,"texto":_vm.textoBarra,"color":_vm.colorBarra},on:{"cierraBarra":function($event){_vm.muestraBarra = false}}}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }