<template>
  <v-container>
    <div class="center-item-in-div">
      <v-btn
        v-if="acceso"
        class="rounded-lg"
        depressed
        x-large
        color="teal darken-2"
        :loading="loadingAcceso"
        dark
        @click="openRegistro = true"
      >
        <v-icon left>
          mdi-plus-thick
        </v-icon>
        crear solicitud
      </v-btn>
      <div v-else class="center-item-in-div-column">
        <label
          :class="'text-' + $vuetify.breakpoint.name + '-h2'"
          class=" blue-grey--text text--darken-2 mb-2 mt-4"
        >
          ¡Sin acceso!
        </label>
        <label
          class="subtitle-1 text-uppercase font-weight-bold blue-grey--text text--darken-1"
          >Parece ser que no tienes acceso para solicitar descanso
          laborado</label
        >
        <label class="body-1  blue-grey--text text--lighten-1"
          >Para tener acceso debes solicitarlo con tu generalista.</label
        >
      </div>

      <RegistraDescansoLaborado
        v-if="openRegistro"
        :openRegistro="openRegistro"
        @cierraRegistro="cierraRegistro"
      />

      <SnackBar
            v-if="muestraBarra"
            :muestraBarra="muestraBarra"
            :color="colorBarra"
            :texto="textoBarra"
            @cierraBarra="muestraBarra = false"
          />
    </div>
  </v-container>
</template>

<script>
import axios from "axios";
import { deleteToken } from "../../../../../store/Funciones/funciones";
import RegistraDescansoLaborado from "./RegistraDescansoLaborado";
import SnackBar from "../../../General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue"
export default {
  components: { RegistraDescansoLaborado, SnackBar },
  data() {
    return {
      openRegistro: false,
      acceso: true,
      loadingAcceso: true,
      muestraBarra: false,
      colorBarra: "",
      textoBarra: "",
    };
  },
  async mounted() {
    const response = await this.getAccesos();
    if (response.total <= 0) {
      this.acceso = false;
    }
  },
  methods: {
    showSnackBar(texto, color = "error") {
      this.textoBarra = texto;
      this.colorBarra = color;
      this.muestraBarra = true;
    },
    cierraRegistro(respuesta){
      this.openRegistro = false;
      if(respuesta){
        this.showSnackBar("Incidencia registrada con éxito.", "teal darken-2");
        this.$emit('actualizaSolicitudes')
      }
    },
    getAccesos() {
      const headers = {
        Authorization: localStorage.getItem("token"),
      };

      return new Promise((res) => {
        axios
          .get(
            `${this.$store.getters.getHostNode}/api/descanso-laborado/solicitantes/validacion`,
            {
              headers,
            }
          )
          .then((response) => {
            if (response.data.status == "EXPSESSION") {
              deleteToken();
            } else {
              res(response.data.data);
            }
          })
          .finally(() => {
            this.loadingAcceso = false;
          });
      });
    },
  },
};
</script>
