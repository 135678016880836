import DescargaRecibosPorPeriodo from './components/DescargaRecibosPorPeriodo.vue'
import DescargaPrerecibosZentric from './components/DescargaPrerecibosZentric.vue'
export default {
    name: 'nominas-general',
    components: { DescargaRecibosPorPeriodo, DescargaPrerecibosZentric },
    props: [],
    data() {
        return {

        }
    },
    computed: {

    },
    mounted() {

    },
    methods: {

    }
}