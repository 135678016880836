<template>
    <v-card class="rounded-xl mt-5 elevation-0 pa-5" width="100%" color="blue-grey lighten-5">
        <div v-if="loadingData" class="center-item-in-div-column">
            <v-progress-circular :size="180" :width="12" color="blue-grey darken-2" indeterminate>
                <div class="overline">cargando pases...</div>
            </v-progress-circular>
        </div>
        <div v-else>
            <div class="pb-2">
                <v-btn class="rounded-lg mr-1" depressed dark
                    :color="estatus === 1 ? 'new_primary' : 'blue-grey darken-1'"
                    @click="estatus = 1; loadingData = true; updateTable();">autorizados</v-btn>
                <v-btn class="rounded-lg mr-1" depressed dark
                    :color="estatus === 2 ? 'new_primary' : 'blue-grey darken-1'"
                    @click="estatus = 2; loadingData = true; updateTable();">rechazados</v-btn>
                <v-btn class="rounded-lg mr-1" depressed dark
                    :color="estatus === 0 ? 'new_primary' : 'blue-grey darken-1'"
                    @click="estatus = 0; loadingData = true; updateTable();">pendientes</v-btn>
                <v-btn class="rounded-lg mr-1" depressed dark
                    :color="estatus === 3 ? 'new_primary' : 'blue-grey darken-1'"
                    @click="estatus = 3; loadingData = true; updateTable();">liberados</v-btn>
                <!-- <v-btn class="rounded-lg mr-1" depressed dark
                    :color="estatus === 4 ? 'new_primary' : 'blue-grey darken-1'"
                    @click="estatus = 4; loadingData = true; updateTable();">Sin salida</v-btn> -->
            </div>
            <v-data-table :headers="headers" :items="pases" :search="search"
                no-data-text="No se han registrado pases de ingreso" loading-text="Cargando información..."
                :loading="loadingData" :footer-props="{
            'items-per-page-text': 'Elementos por página',
            pageText: '{0}-{1} de {2}',
        }" style="background-color:#ECEFF1; border: solid 3px #CFD8DC" class="rounded-lg" :single-expand="true"
                :expanded.sync="expanded" show-expand>
                <template v-slot:top>
                    <div style="display: flex; justify-content: end;"
                        class="primary pt-4  rounded-l-t-header-pases-admin  rounded-r-t-header-pases-admin">
                        <v-text-field v-model="search" label="Buscar..." dense flat solo-inverted dark clearable
                            append-icon="mdi-magnify" style="max-height: 42px; max-width: 300px;"
                            class="mx-4 rounded-lg"></v-text-field>
                    </div>
                </template>
                <template v-slot:expanded-item="{ headers, item }">
                    <td :colspan="headers.length">
                        <!-- {{ item.material }} -->
                        <v-row>
                            <v-col :cols="['xs', 'sm'].includes($vuetify.breakpoint.name) ? 6 : 3">
                                <v-card class="rounded-lg elevation-0 pa-5 my-2">
                                    {{ item.compania }}
                                </v-card>
                            </v-col>
                            <v-col :cols="['xs', 'sm'].includes($vuetify.breakpoint.name) ? 6 : 3">
                                <v-card class="rounded-lg elevation-0 pa-5 my-2">
                                    {{ item.Area }}: {{ item.descAccion }}
                                </v-card>
                            </v-col>
                        </v-row>
                        <v-card class="rounded-lg pa-5 my-2">
                            <div class="pt-1">
                                <label class="overline"> <strong>unidad de medida: </strong> {{ item.descMedida
                                    }}</label>
                            </div>
                            <v-simple-table dense>
                                <thead>
                                    <tr>
                                        <th class="header-material-table overline font-weight-bold white--text">
                                            No. Serie
                                        </th>
                                        <th class="header-material-table overline font-weight-bold white--text">
                                            Cantidad
                                        </th>
                                        <th class="header-material-table overline font-weight-bold white--text">
                                            descripción
                                        </th>
                                        <th class="header-material-table overline font-weight-bold white--text">
                                            orden Compra
                                        </th>
                                        <th class="header-material-table overline font-weight-bold white--text">
                                            No Factura
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(m, index) in item.material" :key="index">
                                        <td>{{ m.codigo }}</td>
                                        <td>{{ mcantidad }}</td>
                                        <td>{{ m.descripcion }}</td>
                                        <td>{{ m.orden_compra }}</td>
                                        <td>{{ m.factura }}</td>
                                    </tr>
                                </tbody>
                            </v-simple-table>
                        </v-card>
                    </td>
                </template>
                <template v-slot:item.solicitante="{ item }">
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <span v-bind="attrs" v-on="on" class="overline font-weight-bold">{{ item.solicitante
                                }}</span>
                        </template>
                        <span>{{ item.EMP_NOMEMP }}</span>
                    </v-tooltip>
                </template>
                <template v-slot:item.actions="{ item }">
                    <v-tooltip top color="cyan darken-1">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn small class="rounded-pill" v-if="item.estatus === 0" color="cyan darken-1" dark
                                :loading="item.id == idAux && loadingBtnNofify ? true : false" v-bind="attrs" v-on="on"
                                @click="idAux = item.id; loadingBtnNofify = true; sendMail(item.id);">
                                <v-icon left small>
                                    mdi-bell-ring
                                </v-icon>
                                Notificar
                            </v-btn>
                        </template>
                        <span>Reenviar notificación</span>
                    </v-tooltip>
                    <v-tooltip top color="cyan darken-1">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn small class="rounded-pill" v-if="[1, 4].includes(item.estatus)" color="cyan darken-1"
                                dark :loading="item.id == idAux && loadingBtnNofify ? true : false" v-bind="attrs"
                                v-on="on" @click="idAux = item.id; showConfirm = true;">
                                <v-icon left small>
                                    mdi-arrow-right-bold
                                </v-icon>
                                Liberar
                            </v-btn>
                        </template>
                        <span>Liberar pase de ingreso</span>
                    </v-tooltip>
                </template>
            </v-data-table>

            <Confirm :showConfirm="showConfirm" question="¿Desas modificar el estatus de este pase de ingreso?"
                @cierraConfirm="validaRespuesta" />

            <SnackBarErrorIncidencias v-if="muestraBarra" :muestraBarra="muestraBarra" :color="colorBarra"
                :texto="textoBarra" @cierraBarra="muestraBarra = false" />
        </div>
    </v-card>
</template>

<script>
import axios from "axios";
import { formatDate } from "../../../../../store/Funciones/funciones";
import Confirm from "../../../AdmnVacantes/components/ConfirmDialogReclutamiento.vue"
import SnackBarErrorIncidencias from "../../../General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue";
export default {
    name: "muestra-pases",
    components: { Confirm, SnackBarErrorIncidencias },
    data() {
        return {
            loadingData: true,
            pases: [],
            expanded: [],
            loadingBtnNofify: false,
            showConfirm: false,
            muestraBarra: false,
            textoBarra: "",
            colorBarra: "",
            search: "",
            idAux: 0,
            estatus: 1,
            headers: [
                {
                    text: "ID",
                    value: "id",
                    class:
                        "overline font-weight-bold primary white--text",
                },
                {
                    text: "Nombre",
                    value: "nombre",
                    class: "overline font-weight-bold primary white--text",
                },
                {
                    text: "Teléfono",
                    value: "telefono",
                    class: "overline font-weight-bold primary white--text",
                },
                {
                    text: "Solicitante",
                    value: "solicitante",
                    class: "overline font-weight-bold primary white--text",
                },
                {
                    text: "Acciones",
                    value: "actions",
                    align: "center",
                    sortable: false,
                    class: "overline font-weight-bold primary white--text",
                },
                {
                    text: "",
                    value: "data-table-expand",
                    class:
                        "overline font-weight-bold primary white--text",
                },
            ],
        };
    },
    computed: {},
    async mounted() {
        this.pases = await this.getPasesDeIngreso();
    },
    methods: {
        openSnackBar(texto, color = "pink darken-3") {
            this.colorBarra = color;
            this.textoBarra = texto;
            console.log("si llega")
            this.muestraBarra = true;
        },
        async getPasesDeIngreso() {
            let params = {
                Opcion: 15,
                token: localStorage.getItem("token"),
                estatus: this.estatus
            };
            return new Promise((resolve, reject) => {
                axios
                    .post(
                        `${this.$store.getters.getHost}/SeguridadPatrimonial/ws-pases-de-ingreso.php`,
                        params
                    )
                    .then((response) => {
                        if (response.data.status == "OK") {
                            resolve(response.data.passes);
                        }
                    })
                    .catch((error) => {
                        reject([{ nombre: error }]);
                    })
                    .finally(() => {
                        this.loadingData = false;
                    });
            });
        },
        async updateTable() {
            this.pases.length = 0;
            this.pases = await this.getPasesDeIngreso();
        },
        getClass(estatus) {
            if (estatus === 0) {
                return "yellow accent-4 black--text";
            } else if (estatus === 1) {
                return "teal darken-1 white--text";
            } else if (estatus === 2) {
                return "pink darken-3 white--text";
            } else {
                return "grey";
            }
        },
        sendMail(idPase) {
            let params = {
                Opcion: 7,
                token: localStorage.getItem("token"),
                idPase: idPase,
            };
            axios
                .post(
                    `${this.$store.getters.getHost}/SeguridadPatrimonial/ws-pases-de-ingreso.php`,
                    params
                )
                .then((response) => {
                    if (response.data.status == "OK") {
                        if (response.data.respuesta.status_tran == "OK") {
                            alert(response.data.respuesta.message);
                        } else {
                            alert(response.data.respuesta.message);
                        }
                    }
                })
                .catch((error) => {
                    alert(error);
                })
                .finally(() => {
                    this.loadingBtnNofify = false;
                });
        },
        fixDate(date) {
            return formatDate(date)
        },
        validaRespuesta(respuesta) {
            if (respuesta) {
                this.showConfirm = false;

                const params = {
                    Opcion: 16,
                    token: localStorage.getItem('token'),
                    idPase: this.idAux
                }
                axios
                    .post(
                        `${this.$store.getters.getHost}/SeguridadPatrimonial/ws-pases-de-ingreso.php`,
                        params
                    )
                    .then((response) => {
                        if (response.data.status == "OK") {
                            if (response.data.respuesta.status_tran == "OK") {
                                this.loadingData = true;
                                this.openSnackBar("Estatus modificado con éxito.", "teal darken-1");
                                this.updateTable();
                            } else {
                                this.openSnackBar(response.data.respuesta.message);
                            }
                        }
                    })
                    .catch((error) => {
                        this.openSnackBar(error);
                    })

            } else {
                this.showConfirm = false
            }
        }
    },
};
</script>

<style>
.rounded-l-t-header-pases-admin {
    border-top-left-radius: 6px !important;
}

.rounded-r-t-header-pases-admin {
    border-top-right-radius: 6px !important;
}

.header-material-table {
    background-color: #A8A8A7;
}
</style>