import axios from "axios";
import VueJsonToCsv from 'vue-json-to-csv'
import AdmFormularioTurnos from "../Components/AdmFormularioTurnos.vue"
import SnackBarErrorIncidencias from "../../../General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue"
import { deleteToken } from "../../../../../store/Funciones/funciones";
export default {
    name: 'adm-turnos',
    components: { SnackBarErrorIncidencias, VueJsonToCsv, AdmFormularioTurnos },
    props: [],
    data() {
        return {
            textoBarra: '',
            colorBarra: '',
            muestraBarra: false,
            timeout: 2000,
            //----------------------------------------------------------------
            cargando: true,
            buscar: '',
            nameFile: '',
            tab: null,
            items: [
                { tab: 'Administración de turnos empleados', content: 'ATE' },
                { tab: 'Administración de turnos', content: 'AT' },
                { tab: 'Administración turnos - grupos', content: 'ATG' },
            ],
            colaboradores: [],
            turnos: [],
            dialogForm: '',
            editedIndex: -1,
            editedItem: {
                DESCR: '',
                puesto: '',
                grupo: '',
            },
            loading: false,
            vistapreviaimg: "https://community.f5.com/legacyfs/online/avatars/0051T000008uOPOQA2.png",
            headers: [
                { text: 'Folio', value: 'EMPLID', align: 'center', sortable: true },
                { text: 'Nombre', value: 'NAME', align: 'center', sortable: true },
                { text: 'Puesto', value: 'DESCR', align: 'center', sortable: true },
                { text: 'Grupo', value: 'Descripcion', align: 'center', sortable: true },
                { text: 'Acciones', value: 'actions', align: 'center', sortable: true },
            ],
            headers_report_to_export: {
                EMPLID: { title: "Folio" },
                NAME: { title: "Nombre" },
                DESCR: { title: "Puesto" },
                Descripcion: { title: "Grupo" },
            },
        }
    },
    computed: {
        formTitle() {
            return this.editedIndex === -1 ? 'Nuevo' : 'Editar'
        },
    },
    mounted() {
        this.getColaboradoresActivos();
        this.getTurnos();
        //  window.document.title = "DH:URREA - Turnos.";
    },
    // watch: {
    //   menu(val) {
    //     val && setTimeout(() => (this.activePicker = 'YEAR'))
    //   },
    // },
    methods: {
        guardarTurno() {
            let params = {
                Opcion: 3,
                item: this.editedItem,
                token: localStorage.getItem("token"),
            };
            this.loading = true;
            axios.post(`${this.$store.getters.getHost}/nominas/Turnos/ws-turnos.php`, params).then((response) => {
                    if (response.data.status.trim() == "EXPSESSION") {
                        deleteToken();
                    } else if (response.data.status.trim() == "OK") {
                        this.textoBarra = 'Turno editado correctamente ';
                        this.colorBarra = 'success';
                        this.muestraBarra = true;
                        this.dialogForm = false;
                        this.colaboradores = [];
                        this.cargando = true;
                        this.getColaboradoresActivos();
                    } else {
                        this.textoBarra = 'Algo salió mal';
                        this.colorBarra = 'error';
                        this.muestraBarra = true;
                    }
                    this.loading = false;
                })
                .catch(function(e) {
                    let result = e.toString().includes("401");
                    if (result) {
                        window.location.reload();
                    } else {
                        alert(e);
                    }
                });
        },

        openDialogForm() {
            this.dialogForm = true;
        },

        close() {
            this.dialogForm = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },

        editItem(item) {
            this.editedIndex = this.colaboradores.indexOf(item);
            this.editedItem = Object.assign({}, item);
            if (this.editedItem["foto"]) {
                this.editedItem["foto"] = this.$store.getters.getHost + "/ArchivosCargadosEnPortal2/FotosPerfilUsuarios/" + this.editedItem["foto"];
            } else {
                this.editedItem["foto"] = this.vistapreviaimg;
            }
            this.dialogForm = true;
        },

        fixName() {
            let numero_aleatorio =
                Math.floor(Math.random() * (99999 - 10000 + 1)) + 10000;
            this.nameFile = "AdministracionDeTurnos_" + numero_aleatorio.toString();
        },

        getColaboradoresActivos() {
            let params = {
                Opcion: 1,
                token: localStorage.getItem("token"),
            };
            axios.post(`${this.$store.getters.getHost}/nominas/Turnos/ws-turnos.php`, params).then((response) => {
                    if (response.data.status.trim() == "EXPSESSION") {
                        deleteToken();
                    } else {
                        var data = response.data.data;
                        if (data) {
                            this.colaboradores = data;
                        }
                    }
                    this.cargando = false;
                })
                .catch(function(e) {
                    let result = e.toString().includes("401");
                    if (result) {
                        window.location.reload();
                    } else {
                        alert(e);
                    }
                });
        },

        getTurnos() {
            let params = {
                Opcion: 2,
                token: localStorage.getItem("token"),
            };
            axios.post(`${this.$store.getters.getHost}/nominas/Turnos/ws-turnos.php`, params).then((response) => {
                    if (response.data.status.trim() == "EXPSESSION") {
                        deleteToken();
                    } else {
                        var data = response.data.data;
                        if (data) {
                            this.turnos = data;
                        }
                    }
                    this.cargando = false;
                })
                .catch(function(e) {
                    let result = e.toString().includes("401");
                    if (result) {
                        window.location.reload();
                    } else {
                        alert(e);
                    }
                });
        },
    }
}