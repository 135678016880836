import axios from 'axios'
import SnackBar from '../../General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue'
import MantenimientoModulo from "../../../../components/MantenimientoModulo.vue";
import {
    deleteToken
} from '../../../../store/Funciones/funciones'
export default {
    name: 'admon-usuarios',
    components: {
        SnackBar, MantenimientoModulo
    },
    props: [],
    data() {
        return {
            loadingUsers: true,
            updateUsers: false,
            valid: true,
            loadingPerfiles: true,
            loadingSistemas: true,
            loadingCompanias: true,
            loadingResponsable: true,
            habilitaBtn: true,
            muestraBarra: false,
            confrimDelete: false,
            loadingEliminando: false,
            disabledFields: false,
            requiredPass: true,
            itemsPerPage: 15,
            minLength: 12,
            folioSelected: 0,
            tipoUpdate: null,
            validChars: 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789@$*%#',
            nombre: null,
            correo: "",
            perfil: null,
            sistema: null,
            compania: null,
            folio: null,
            emplidResponsable: null,
            nameSelected: "",
            errorPasword: "",
            nickname: "",
            password: "",
            responsable: "",
            colorBarra: "",
            textoBarra: "",
            search: "",
            usuarios: [],
            perfiles: [],
            sistemas: [],
            companias: [],
            colaboradores: [],
            moduloError: false,
            headers: [{
                text: "EMPLID",
                align: "start",
                sortable: false,
                value: "folio",
            },
            {
                text: "Nombre",
                value: "nombre"
            },
            {
                text: "Correo",
                value: "correo"
            },
            {
                text: "Username",
                value: "nickname"
            },
            {
                text: "Perfil",
                value: "Perfil"
            },
            {
                text: "Compañia",
                value: "Compania"
            },
            {
                text: "Estatus",
                value: "EMP_STATUS"
            },
            {
                text: "Acciones",
                value: "actions",
                sortable: false
            },
            ],
            headerProps: {
                sortByText: "Ordenar por",
            },
            // reglas de campos del form
            nameRules: [
                v => !!v || 'Nombre es requerido.',
                v => (v && v.length <= 60) || 'Debe tener menos de 60 caracteres.',
            ],
            emailRules: [
                v => !v || /.+@.+\..+/.test(v) || 'Correo inválido',
                // v => (v && v.length < 50) || 'Debe tener menos de 50 caracteres',
            ],
            nicknameRules: [
                v => !!v || 'NickName es requerido.',
            ],
            passwordRules: [
                v => (this.tipoUpdate === 2 || v.length >= 12) || 'El texto debe tener más de 12 caracteres',
                v => (this.tipoUpdate === 2 || /^[a-zA-Z][a-zA-Z0-9@$*%#]*$/.test(v)) || 'El texto debe comenzar con una letra',
                v => (this.tipoUpdate === 2 || /^[a-zA-Z0-9@$*%#]*$/.test(v)) || 'El texto no debe contener caracteres especiales',
                v => (this.tipoUpdate === 2 || !/^\d*$/.test(v)) || 'El texto no debe tener solo números',
                v => (this.tipoUpdate === 2 || !/^[a-z]*$/.test(v)) || 'El texto no debe tener solo minúsculas',
                v => (this.tipoUpdate === 2 || !/^[A-Z]*$/.test(v)) || 'El texto no debe tener solo mayúsculas',
                v => (this.tipoUpdate === 2 || /[@$*%#]/.test(v)) || 'El texto debe tener al menos un caracter especial: @, $, *, % o #',
                v => (this.tipoUpdate === 2 || /[0-9]/.test(v)) || 'El texto debe contener al menos un número'
            ],
            selectRules: [
                v => !!v || 'Campo requerido.',
            ],
            emplidRules: [
                v => /^[0-9]+$/.test(v) || 'Solo se permiten números enteros'
            ]
        }
    },
    computed: {},
    mounted() {
        this.getUsuarios();
        //  window.document.title = "DH:URREA - Administración de usuarios.";
    },
    methods: {
        getUsuarios() {
            this.usuarios.length = 0
            let params = {
                Opcion: 1,
                token: localStorage.getItem("token")
            };
            axios
                .post(
                    `${this.$store.getters.getHost}/General/AministracionSistema/ws-usuarios.php`,
                    params
                )
                .then((response) => {
                    this.loadingUsers = false;
                    if (response.data.status == "EXPRESSION") {
                        deleteToken();
                    } else if (response.data.status == "OK") {
                        this.usuarios = response.data.usuarios;
                        // this.getPerfiles()
                    } else if (response.data.status == "ERROR" || !response.data.status) {
                        this.moduloError = true;
                    }
                });
        },
        generateText() {
            let text = '';
            let hasSpecialChar = false;
            let hasNumber = false;

            // Generar un texto aleatorio con caracteres alfanuméricos y especiales
            while (text.length < this.minLength || !hasSpecialChar || !hasNumber || !/^[a-zA-Z]/.test(text)) {
                text = '';
                hasSpecialChar = false;
                hasNumber = false;
                for (let i = 0; i < this.minLength; i++) {
                    const char = this.validChars.charAt(Math.floor(Math.random() * this.validChars.length));
                    text += char;
                    if (!hasSpecialChar && '@$*%#'.includes(char)) {
                        hasSpecialChar = true;
                    }
                    if (!hasNumber && '0123456789'.includes(char)) {
                        hasNumber = true;
                    }
                }
            }

            // Asignar el texto generado a un modelo de Vue o hacer algo más con él
            this.password = text;
        },
        getPerfiles() {
            if (this.perfiles.length == 0 || this.tipoUpdate == 2) {
                this.perfiles.length == 0
                let params = {
                    Opcion: 2,
                    token: localStorage.getItem("token")
                };
                axios
                    .post(
                        `${this.$store.getters.getHost}/General/AministracionSistema/ws-usuarios.php`,
                        params
                    )
                    .then((response) => {
                        this.loadingPerfiles = false
                        this.loadingUsers = false;
                        if (response.data.status == "EXPRESSION") {
                            deleteToken();
                        } else if (response.data.status == "OK") {
                            this.perfiles = response.data.usuarios;
                            this.getSistemas()
                        }
                    });
            }

        },
        getSistemas() {
            this.sistemas.length = 0
            let params = {
                Opcion: 3,
                token: localStorage.getItem("token")
            };
            axios
                .post(
                    `${this.$store.getters.getHost}/General/AministracionSistema/ws-usuarios.php`,
                    params
                )
                .then((response) => {
                    this.loadingSistemas = false
                    this.loadingUsers = false;
                    if (response.data.status == "EXPRESSION") {
                        deleteToken();
                    } else if (response.data.status == "OK") {
                        this.sistemas = response.data.usuarios;
                        this.getCompanias()
                    }
                });
        },
        getCompanias() {
            this.companias.length = 0
            let params = {
                Opcion: 4,
                token: localStorage.getItem("token")
            };
            axios
                .post(
                    `${this.$store.getters.getHost}/General/AministracionSistema/ws-usuarios.php`,
                    params
                )
                .then((response) => {
                    this.loadingCompanias = false
                    this.loadingUsers = false;
                    if (response.data.status == "EXPRESSION") {
                        deleteToken();
                    } else if (response.data.status == "OK") {
                        this.companias = response.data.usuarios;
                        this.getColaboradores()
                    }
                });
        },
        getColaboradores() {
            this.colaboradores.length = 0
            let params = {
                Opcion: 5,
                token: localStorage.getItem("token")
            };
            axios
                .post(
                    `${this.$store.getters.getHost}/General/AministracionSistema/ws-usuarios.php`,
                    params
                )
                .then((response) => {
                    this.loadingResponsable = false
                    this.loadingUsers = false;
                    if (response.data.status == "EXPRESSION") {
                        deleteToken();
                    } else if (response.data.status == "OK") {
                        this.colaboradores = response.data.usuarios;
                        if (this.tipoUpdate == 2) {
                            let buscaResp = this.colaboradores.filter((r) => {
                                return r.EMPLID == this.emplidResponsable
                            })
                            if (buscaResp.length > 0)
                                this.responsable = buscaResp[0].nombreMostrar
                        }
                    }
                });
        },
        validaForm() {
            if (this.$refs.form.validate()) {
                if (this.tipoUpdate != 2) {
                    let perfilSelected = this.perfiles.filter((p) => {
                        return p.Perfil == this.perfil
                    });
                    let sistemaAux = null
                    if (this.sistema != null && this.sistema != '') {
                        let sistemaSelected = this.sistemas.filter((p) => {
                            return p.Sistema == this.sistema
                        });
                        sistemaAux = sistemaSelected[0].IdSistema
                    }
                    let companiaSelected = this.companias.filter((p) => {
                        return p.Compania == this.compania
                    });
                    let respSelected = this.colaboradores.filter((p) => {
                        return p.nombreMostrar == this.responsable
                    });
                    // console.log(respSelected)
                    let params = {
                        Opcion: 6,
                        token: localStorage.getItem('token'),
                        nombre: this.nombre,
                        correo: this.correo,
                        nickname: this.nickname,
                        password: this.password,
                        perfil: perfilSelected[0].IdPerfil,
                        sistema: sistemaAux,
                        compania: companiaSelected[0].CIA_JDE,
                        folio: this.folio,
                        responsable: respSelected[0].EMPLID
                    }
                    axios
                        .post(
                            `${this.$store.getters.getHost}/General/AministracionSistema/ws-usuarios.php`,
                            params
                        )
                        .then((response) => {
                            this.loadingCompanias = false
                            this.loadingUsers = false;
                            if (response.data.status == "EXPRESSION") {
                                deleteToken();
                            } else if (response.data.status == "OK") {
                                if (response.data.usuarios.tipo == 0 || response.data.usuarios.tipo == 1 || response.data.usuarios.tipo == 3) {
                                    this.colorBarra = "error";
                                    this.textoBarra = response.data.usuarios.mensaje
                                    this.muestraBarra = true
                                } else {
                                    this.colorBarra = "success";
                                    this.textoBarra = response.data.usuarios.mensaje
                                    this.muestraBarra = true
                                    this.closeUpdateUsers()
                                }
                            }
                        });
                } else {
                    let perfilSelected = this.perfiles.filter((p) => {
                        return p.Perfil == this.perfil
                    });
                    let sistemaAux = null
                    if (this.sistema != null && this.sistema != '') {
                        let sistemaSelected = this.sistemas.filter((p) => {
                            return p.Sistema == this.sistema
                        });
                        sistemaAux = sistemaSelected[0].IdSistema
                    }
                    let companiaSelected = this.companias.filter((p) => {
                        return p.Compania == this.compania
                    });
                    let respSelected = this.colaboradores.filter((p) => {
                        return p.nombreMostrar == this.responsable
                    });
                    let params = {
                        Opcion: 8,
                        token: localStorage.getItem('token'),
                        nombre: this.nombre,
                        correo: this.correo,
                        nickname: this.nickname,
                        password: this.password,
                        perfil: perfilSelected[0].IdPerfil,
                        sistema: sistemaAux,
                        compania: companiaSelected[0].CIA_JDE,
                        folio: this.folio,
                        responsable: respSelected[0].EMPLID
                    }
                    axios
                        .post(
                            `${this.$store.getters.getHost}/General/AministracionSistema/ws-usuarios.php`,
                            params
                        )
                        .then((response) => {
                            this.loadingCompanias = false
                            this.loadingUsers = false;
                            if (response.data.status == "EXPRESSION") {
                                deleteToken();
                            } else if (response.data.status == "OK") {
                                if (response.data.usuarios.tipo == 0 || response.data.usuarios.tipo == 3) {
                                    this.colorBarra = "error";
                                    this.textoBarra = response.data.usuarios.mensaje
                                    this.muestraBarra = true
                                } else {
                                    this.colorBarra = "success";
                                    this.textoBarra = response.data.usuarios.mensaje
                                    this.muestraBarra = true
                                    this.closeUpdateUsers()
                                }
                            }
                        });
                }
            }
        },
        closeUpdateUsers() {
            this.$refs.form.reset()
            this.$refs.form.resetValidation()
            this.requiredPass = true;
            this.updateUsers = false;
        },
        selectEmplidToDelete(usuario) {
            this.nameSelected = usuario.nombre
            this.folioSelected = usuario.folio
            this.confrimDelete = true
        },
        deleteUser() {
            let params = {
                Opcion: 7,
                token: localStorage.getItem("token"),
                folio: this.folioSelected
            };
            axios
                .post(
                    `${this.$store.getters.getHost}/General/AministracionSistema/ws-usuarios.php`,
                    params
                )
                .then((response) => {
                    this.loadingEliminando = false
                    this.confrimDelete = false
                    if (response.data.usuarios == 0) {
                        this.colorBarra = "error";
                        this.textoBarra = "Ocurrió un error, no se pudo eliminar."
                        this.muestraBarra = true
                    } else {
                        this.colorBarra = "success";
                        this.textoBarra = "Eliminad@ exitosamente."
                        this.muestraBarra = true
                        this.loadingUsers = true
                        this.getUsuarios()
                    }
                });
        },
        openUpdateUsers(usuario = null) {
            this.updateUsers = true;
            if (this.tipoUpdate == 2) {
                this.emplidResponsable = usuario.Responsable
                this.nombre = usuario.nombre
                this.correo = usuario.correo
                this.nickname = usuario.nickname
                this.perfil = usuario.Perfil
                this.folio = usuario.folio
                this.compania = usuario.Compania
                this.requiredPass = false;
                this.disabledFields = true
            } else {
                this.disabledFields = false
            }
        }
    }
}