import axios from "axios";
import SnackBarErrorIncidencias from "../../../General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue"
export default {
    name: 'adm-depositos',
    components: { SnackBarErrorIncidencias },
    props: [],
    data() {
        return {
            textoBarra: '',
            colorBarra: '',
            muestraBarra: false,
            buscar: '',
            depositos: [],
            cargando: true,
            openComentarioDialg: false,
            comentario: '',
            usuarioMovimiento: '',
            fechaFinal: '',
            headers1: [
                { text: 'ID', align: 'start', sortable: false, value: 'ID', },
                { text: 'Num. empleado', value: 'EMPLID' },
                { text: 'Nombre', value: 'NOMBRE' },
                { text: 'Deposito', value: 'CANTIDAD' },
                { text: 'Estatus', value: 'estatus' },
                { text: 'Comentarios', value: 'comentario' },
                { text: 'Acciones', value: 'actions' },
            ],
            colorsTema: [{
                    color: 'green',
                    tema: true
                },
                {
                    color: 'red',
                    tema: true
                },
                {
                    color: 'blue-grey lighten-2',
                    tema: true
                }
            ],
            estatusPagos: ['Recibido', 'No recibido', 'Pendiente'],
        }
    },
    computed: {

    },
    mounted() {
        //  window.document.title = "DH:URREA - Admón. de depósitos.";
        this.getdepositos();
    },
    methods: {
        openComentarioDialgo(params) {
            this.id_movimiento = params.id_movimiento;
            this.accion = params.accion;
            this.fechaFinal = params.fechaFinal;
            this.usuarioMovimiento = params.usuarioMovimiento;
            this.openComentarioDialg = true;
        },
        acciones() {
            let params = {
                Opcion: 2,
                token: localStorage.getItem("token"),
                id_movimiento: this.id_movimiento,
                comentario: this.comentario,
                fechaFinal: this.fechaFinal,
                usuarioMovimiento: this.usuarioMovimiento,
                accion: this.accion
            };
            axios.post(`${this.$store.getters.getHost}/Nominas/AdmCajaAhorro/ws-AdmDepositos.php`, params).then((response) => {
                if (response.data.status == 'OK') {
                    this.textoBarra = 'Se han guardado los cambios correctamente';
                    this.colorBarra = 'success';
                    this.muestraBarra = true;
                    this.openComentarioDialg = false;
                    this.depositos = [];
                    this.cargando = true;
                    this.getdepositos();
                }
                if (response.data.status == 'error_notificacion') {
                    this.textoBarra = 'Se guardaron los cambios, notificación pendiente';
                    this.colorBarra = 'error';
                    this.muestraBarra = true;
                    this.openComentarioDialg = false;
                    this.depositos = [];
                    this.cargando = true;
                    this.getdepositos();
                }
                if (response.data.status == 'error') {
                    this.textoBarra = 'Se ha producido un error, estamos trabajando en ello';
                    this.colorBarra = 'error';
                    this.muestraBarra = true;
                }
                if (response.data.status == 'existente') {
                    this.textoBarra = 'Ya se ha realizado una acción con este registro';
                    this.colorBarra = 'primary';
                    this.muestraBarra = true;
                }
            })
        },
        getdepositos() {
            let params = {
                Opcion: 1,
                token: localStorage.getItem("token")
            };
            axios.post(`${this.$store.getters.getHost}/Nominas/AdmCajaAhorro/ws-AdmDepositos.php`, params).then((response) => {
                if (response.data.status == 'OK') {
                    this.depositos = response.data.data;
                }
                this.cargando = false;
            })

        },
    }
}