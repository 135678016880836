<template>
  <div>
    <v-dialog v-model="dialog" max-width="700px" persistent scrollable>
      <v-card class="rounded-lg" :loading="muestraProgreso">
        <v-card-title class="primary white--text subtitle-2">
          <span>Registro de Teletrabajo</span>
          <v-spacer></v-spacer>
          <v-icon dark @click="$emit('cierra', 0)">mdi-close</v-icon>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pb-0">
          <v-container>
            <div v-show="!muestraProgreso">
              <div v-if="!tienePermiso">
                <p style="font-weight: bolder">
                  Para poder solicitar el teletrabajo debes tener la
                  autorización por dirección y gerencia de tu área y enviar un
                  correo a tu generalista.
                </p>
              </div>
              <div v-else>
                <v-alert
                  :value="alert"
                  :color="colorAlert"
                  dark
                  border="top"
                  :icon="iconAlert"
                  transition="scale-transition"
                >
                  <v-row align="center">
                    <v-col class="grow">
                      {{ respuestaApiGuardaIncidencia.message }}
                    </v-col>
                    <v-col class="shrink">
                      <v-row align="center">
                        <v-col cols="12" sm="6">
                          <v-btn small @click="muestraAlertDetalles()">
                            {{ textButtonAlert }}
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-alert>
                <p class="font-weight-bold my-1">
                  Ingresa los datos necesarios.
                </p>
                <v-row
                  v-if="esAdmin != true"
                  align="center"
                  style="height: 87px"
                >
                  <v-col sm="12">
                    <v-select
                      :items="limitesSemanas.limites"
                      v-model="semanaSelect"
                      label="Selecciona la semana en la que se registrará la incidencia."
                      outlined
                      dense
                      :rules="rules.semanaSeleccionada"
                      :value="limitesSemanas.semana"
                      :loading="loadingSemanas"
                      :disabled="loadingSemanas"
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row v-else align="center" style="height: 87px">
                  <v-col cols="12" sm="12" xl="12">
                    <v-menu
                      v-model="selSemana"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="fechaSeleccionadaAdmin"
                          dense
                          outlined
                          label="Seleccion de semana"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="fechaSeleccionadaAdmin"
                        @input="getIncioFinSemana"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <div v-show="fechaMostrarAdmin != '' && esAdmin === true">
                  <p class="text-center">{{ fechaMostrarAdmin }}</p>
                </div>
                <v-row>
                  <v-col cols="12">
                    <p>¿Seleccionarás toda la semana?</p>
                    <v-radio-group v-model="allWeek" mandatory row>
                      <v-radio label="Sí" value="si"></v-radio>
                      <v-radio label="No" value="no"></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
                <v-row v-show="allWeek == 'no'" align="center">
                  <v-col cols="12" sm="12">
                    <v-combobox
                      v-model="diasSeleccionados"
                      :items="diasSemana"
                      :rules="rules.diasSeleccionadosRules"
                      label="Selecciona los días de la semana a registrar"
                      multiple
                      outlined
                      dense
                      chips
                    >
                      <template v-slot:selection="data">
                        <v-chip
                          :key="JSON.stringify(data.item)"
                          v-bind="data.attrs"
                          :input-value="data.selected"
                          :disabled="data.disabled"
                          close
                          @click:close="data.parent.selectItem(data.item)"
                        >
                          <v-avatar
                            class="accent white--text"
                            left
                            v-text="data.item.slice(0, 1).toUpperCase()"
                          ></v-avatar>
                          {{ data.item }}
                        </v-chip>
                      </template>
                    </v-combobox>
                  </v-col>
                </v-row>
                <v-text-field
                  outlined
                  dense
                  v-model="comentario"
                  label="Agrega un comentario"
                  :rules="rules.comentario"
                  :disabled="loadingBotonGuardar"
                ></v-text-field>
                <!-- <label>{{ diasSeleccionados }}</label>
              <label>{{ semanaSelect }}</label> -->
              </div>
            </div>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="rounded-lg"
            color="teal darken-1"
            dark
            elevation="2"
            @click="guardarInformacion()"
            :loading="loadingBotonGuardar"
          >
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-sheet id="muestra-incidencias-cargadas">
      <muestra-incidencias-cargadas
        :muestraDetalles="muestraDetalles"
        :datosTabla="respuestaApiGuardaIncidencia.data"
        :alertErrorDosDias="alertErrorDosDias"
        @cierraTabla="muestraDetalles = false"
      />
    </v-sheet>
    <v-sheet id="snack-bar-error-incidencias">
      <snack-bar-error-incidencias
        :muestraBarra="muestraBarra"
        :texto="textoBarra"
        :color="colorBarra"
        @cierraBarra="muestraBarra = false"
      />
    </v-sheet>
  </div>
</template>

<style scoped>
.v-progress-circular {
  margin: 1rem;
}
</style>

<script>
import axios from "axios";
import MuestraIncidenciasCargadas from "./componentsExtra/MuestraIncidenciasCargadas.vue";
import SnackBarErrorIncidencias from "./componentsExtra/SnackBarErrorIncidencias.vue";
import { formatDate } from "../../../../../store/Funciones/funciones";

export default {
  name: "incidencia-teletrabajo",
  data: () => ({
    diasSemana: ["Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"],
    proxSemanas: [],
    limitesSemanas: {
      limites: [],
      fechas: [],
    },
    loadingBotonGuardar: false,
    semanaSelect: 0,
    diasSeleccionados: [],
    respuestaApiGuardaIncidencia: [],
    allWeek: null,
    permiso: 0,
    tienePermiso: null,
    muestraProgreso: true,
    dialogCargandoInf: true,
    alert: false,
    textAlert: "",
    colorAlert: "pink",
    textButtonAlert: "cerrar",
    iconAlert: "mdi-alert-circle",
    alertErrorDosDias: false,
    muestraBarra: false,
    textoBarra: "",
    colorBarra: "",
    muestraDetalles: false,
    loadingSemanas: true,
    fechaSeleccionadaAdmin: "",
    selSemana: false,
    comentario: "",
    fechaMostrarAdmin: "",
    rules: {
      comentario: [
        (val) =>
          (val || "").length > 0 || "Es necesario que escribas un comentario.",
      ],
      semanaSeleccionada: [
        (val) => (val || "").length > 0 || "Selecciona una semana.",
      ],
      diasSeleccionadosRules: [
        (val) => (val || "").length > 0 || "Selecciona los días a registrar.",
      ],
    },
  }),
  components: {
    MuestraIncidenciasCargadas,
    SnackBarErrorIncidencias,
  },
  props: ["dialog", "esAdmin", "empleado"],
  mounted() {},
  created() {
    this.getAccesoTeletrabajo();
    this.getSemanasProx();
  },
  methods: {
    getIncioFinSemana() {
      this.selSemana = false;
      var date = this.fechaSeleccionadaAdmin;
      var part = date.split("-");
      var dia = parseInt(part[2]);
      var mes = parseInt(part[1]) - 1;
      var anio = parseInt(part[0]);
      var curr = new Date(anio, mes, dia);

      var diaSemana = curr.getDay();
      var diferenciaLunes = 1 - diaSemana;
      var diferenciaViernes = 5 - diaSemana;
      var lunesSemana = new Date(curr);

      lunesSemana.setDate(curr.getDate() + diferenciaLunes);

      var viernesSemana = new Date(curr);

      viernesSemana.setDate(curr.getDate() + diferenciaViernes);

      // Formatear las fechas
      
      var lunesFormatted = lunesSemana.toISOString().slice(0,10);

      var viernesFormatted = viernesSemana.toISOString().slice(0, 10);
      this.fechaSeleccionada =
        lunesFormatted + "," + viernesFormatted;
      this.fechaMostrarAdmin =
        "Del " +
        formatDate(lunesFormatted) +
        " al " +
        formatDate(viernesFormatted);
    },
    muestraAlertDetalles() {
      if (this.textButtonAlert == "Detalles") {
        this.alertErrorDosDias = false;
        this.muestraDetalles = true;
      } else if (this.textButtonAlert == "Ver más") {
        this.muestraDetalles = true;
      } else if (this.textButtonAlert == "Cerrar") {
        this.alert = false;
      }
    },
    remove(item) {
      this.diasSeleccionados.splice(this.diasSeleccionados.indexOf(item), 1);
      this.diasSeleccionados = [...this.diasSeleccionados];
    },
    getSemanasProx() {
      let params = {
        Opcion: 1,
        token: localStorage.getItem("token"),
      };
      axios
        .post(
          `${this.$store.getters.getHost}/Incidencias/ws-incidencias-home-office.php`,
          params
        )
        .then((response) => {
          var data = response.data.proxTresSemanas;
          this.proxSemanas = data;
          this.concatenaFechas();
        })
        .catch(function (e) {
          let result = e.toString().includes("401");
          if (result) {
            window.location.reload();
          } else {
            alert(e);
          }
        });
    },
    concatenaFechas() {
      this.proxSemanas.forEach((element) => {
        this.limitesSemanas.limites.push(element.fli + " - " + element.flf);
        this.limitesSemanas.fechas.push(element.inicio + "," + element.fin);
        this.loadingSemanas = false;
      });
    },
    getAccesoTeletrabajo() {
      const params = {
        Opcion: 4,
        token: localStorage.getItem("token"),
        esAdmin: this.esAdmin,
        empleado: this.empleado,
      };
      axios
        .post(
          `${this.$store.getters.getHost}/Incidencias/ws-incidencias-home-office.php`,
          params
        )
        .then((response) => {
          var data = response.data.permisoTeletrabajo;
          this.permiso = data;
          this.getModificaPermiso();
        })
        .catch(function (e) {
          let result = e.toString().includes("401");
          if (result) {
            window.location.reload();
          } else {
            alert(e);
          }
        });
    },
    getModificaPermiso() {
      if (this.permiso[0]["Permiso"] == 0) {
        this.tienePermiso = false;
      }
      if (this.permiso[0]["Permiso"] > 0) {
        this.tienePermiso = true;
      }
      this.muestraProgreso = false;
    },
    guardarInformacion() {
      if (
        (this.semanaSelect == "" && this.esAdmin != true) ||
        (this.diasSeleccionados.length == 0 && this.allWeek == "no") ||
        this.comentario == ""
      ) {
        this.textoBarra = "Tienes que llenar todos los campos";
        this.muestraBarra = true;
      } else {
        this.loadingBotonGuardar = true;
        // this.textButtonAlert = "Ver más";
        if (this.esAdmin != true) {
          for (var i = 0; i < this.limitesSemanas.limites.length; i++) {
            if (this.limitesSemanas.limites[i] == this.semanaSelect) {
              this.fechaSeleccionada = this.limitesSemanas.fechas[i];
            }
          }
        }

        if (this.diasSeleccionados.length == 0 && this.allWeek == "si") {
          this.diasSeleccionados.push("all");
        }

        const params = {
          Opcion: 3,
          token: localStorage.getItem("token"),
          semana: this.fechaSeleccionada,
          dias: this.diasSeleccionados,
          comentario: this.comentario,
          tipo: 11,
          esAdmin: this.esAdmin,
          empleado: this.empleado,
        };

        axios
          .post(
            `${this.$store.getters.getHost}/Incidencias/ws-incidencias-home-office.php`,
            params
          )
          .then((response) => {
            var data = response.data.response;
            this.respuestaApiGuardaIncidencia = data;
            this.evaluaRespuesta();
          })
          .catch(function (e) {
            let result = e.toString().includes("401");
            if (result) {
              window.location.reload();
            } else {
              alert(e);
            }
          });
      }
    },
    evaluaRespuesta() {
      if (this.respuestaApiGuardaIncidencia["opcion"] == 1) {
        // this.textAlert = this.respuestaApiGuardaIncidencia["message"];
        this.alert = true;
        this.loadingBotonGuardar = false;
        this.textButtonAlert = "Ver más";
      }
      if (this.respuestaApiGuardaIncidencia["opcion"] == 5) {
        // this.textAlert = this.respuestaApiGuardaIncidencia["message"];
        this.colorAlert = "green";
        this.iconAlert = "mdi-content-save-check-outline";
        this.textButtonAlert = "Detalles";
        this.alert = true;
        for (var i = 0; i < this.diasSeleccionados.length; i++) {
          this.diasSeleccionados.pop();
        }
        this.semanaSelect = "";
        this.comentario = "";
        this.loadingBotonGuardar = false;
        // this.textoBarra = "Incidencia registrada con éxito.";
        // this.colorBarra = "primary";
        // this.muestraBarra = true;
        this.$emit("cierra", 1);
      } else {
        // this.textAlert = this.respuestaApiGuardaIncidencia["message"];
        this.colorAlert = "pink";
        this.iconAlert = "mdi-alert-circle";
        this.textButtonAlert = "Cerrar";
      }
    },
  },
};
</script>
