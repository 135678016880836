<template>
  <v-card class="rounded-lg" elevation="10" transition="scroll-x-transition">
    <div class="text-right">
      <v-btn
        v-if="compania == '00100'"
        small
        depressed
        class="rounded-lg mx-1 my-3"
        color="blue accent-1"
        :disabled="comisionistasEliminados"
        @click="removeComissionistAgents"
      >
        <v-icon left> mdi-account-multiple-remove </v-icon>
        {{ $t("DH360.btnTextRemoveComissionAgents") }}
      </v-btn>
      <v-btn
        small
        depressed
        class="rounded-lg mx-1 my-3"
        color="teal"
        dark
        :loading="muestraLoading"
        @click="confirmSaveParticipants = true"
      >
        <v-icon left> mdi-account-group </v-icon>
        {{ $t("DH360.btnTextSaveParticipants") }}
      </v-btn>
      <v-btn
        small
        depressed
        class="rounded-lg ml-1 my-3 mr-3"
        color="green darken-2"
        dark
        :loading="loadingColaboradores"
        @click="
          loadingColaboradores = true;
          getColaboradoresUHP();
        "
      >
        <v-icon left> mdi-plus </v-icon>
        {{ $t("DH360.btnTextAddParticipant") }}
      </v-btn>
    </div>
    <v-text-field
      class="mx-2"
      v-model="search"
      append-icon="mdi-magnify"
      :label="$t('DH360.findTextBox')"
      single-line
      hide-details
    ></v-text-field>
    <v-data-table
      class="mx-2"
      :headers="headers"
      :items="participantes"
      :items-per-page="itemsPerPage"
      :footer-props="{ 'items-per-page-text': 'Elementos por página' }"
      no-data-text="No hay registros"
      :header-props="headerProps"
      :loading="loadingTabla"
      :search="search"
      loading-text="Cargando contenido..."
      :expanded.sync="expanded"
      :single-expand="singleExpand"
      item-key="emplid"
      show-expand
      dense
    >
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length" class="px-0 mx-0">
          <v-row dense>
            <v-col cols="12" xs="6" sm="6" md="1">
              <v-subheader
                class="tam-subheader py-0 mt-0 blue-grey--text text--darken-1 font-weight-bold"
              >
                JOBCODE
              </v-subheader>
              <v-subheader class="tam-subheader py-0 mt-0">
                {{ item.jobcode }}
              </v-subheader>
            </v-col>
            <v-col cols="12" xs="6" sm="6" md="4">
              <v-subheader
                class="tam-subheader py-0 mt-0 blue-grey--text text--darken-1 font-weight-bold"
              >
                Depto.
              </v-subheader>
              <v-subheader class="tam-subheader py-0 mt-0">
                {{ item.descr1 }}
              </v-subheader>
            </v-col>
            <v-col cols="12" xs="6" sm="6" md="3">
              <v-subheader
                class="tam-subheader py-0 mt-0 blue-grey--text text--darken-1 font-weight-bold"
              >
                Área
              </v-subheader>
              <v-subheader class="tam-subheader py-0 mt-0">
                {{ item.Area }}
              </v-subheader>
            </v-col>
            <v-col cols="12" xs="6" sm="6" md="1">
              <v-subheader
                class="tam-subheader py-0 mt-0 blue-grey--text text--darken-1 font-weight-bold"
              >
                OBJs
              </v-subheader>
              <v-subheader class="tam-subheader py-0 mt-0">
                {{ item.obj }}
              </v-subheader>
            </v-col>
            <v-col cols="12" xs="6" sm="6" md="3">
              <v-subheader
                class="tam-subheader py-0 mt-0 blue-grey--text text--darken-1 font-weight-bold"
              >
                Ingreso
              </v-subheader>
              <v-subheader class="tam-subheader py-0 mt-0">
                {{ item.EMP_FECING }}
              </v-subheader>
            </v-col>
          </v-row>
        </td>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="deleteParticipant(item, 1)">
          mdi-minus-circle
        </v-icon>
      </template>
    </v-data-table>
    <v-dialog v-model="confirm" max-width="450">
      <v-card>
        <v-card-text>
          <p
            class="text-center pt-8"
            style="font-size: 1.1rem; font-weight:600;"
          >
            {{ $t("DH360.confirmRemove") }}
          </p>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions style="display:flex; justify-content:center;">
          <v-btn
            depressed
            class="rounded-lg"
            dark
            color="pink darken-3"
            @click="closeDelete"
          >
            {{ $t("DH360.cancel") }}</v-btn
          >
          <v-btn
            depressed
            class="rounded-lg"
            dark
            color="teal"
            @click="deleteParticipantConfirm"
          >
            {{ $t("DH360.confirm") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="confirmDontParticipate"
      max-width="450"
      scrollable
      persistent
    >
      <v-card class="rounded-lg">
        <p
          class="text-center pt-4 px-2"
          style="font-size: 1.1rem; font-weight:600;"
        >
          {{ $t("DH360.alertTextPositionsToBeDeleted") }}
        </p>
        <v-divider></v-divider>
        <v-card-text style="height: 300px;">
          <v-list dense>
            <div v-for="item in userDontParticipate" :key="item.emplid">
              <v-divider></v-divider>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title v-html="item.name"></v-list-item-title>
                  <v-list-item-subtitle
                    v-html="item.descr"
                  ></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </div>
          </v-list>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions style="display:flex; justify-content: center;">
          <v-btn
            large
            depressed
            color="blue darken-2"
            dark
            @click="
              loadingConfirmRemuvePositions = true;
              removePositionsDontParticipant();
            "
          >
            {{ $t("DH360.confirm") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="findNewParticipant"
      max-width="450"
      scrollable
      persistent
    >
      <v-card class="rounded-lg">
        <p
          class="text-center pt-4 px-2"
          style="font-size: 1.1rem; font-weight:600;"
        >
          {{ $t("DH360.btnTextAddParticipant") }}
        </p>
        <v-divider></v-divider>
        <v-card-text>
          <v-combobox
            class="mt-10"
            v-model="participantSelected"
            :items="colaboradores"
            dense
            hide-selected
            :label="$t('DH360.findEmployeeTextCombobox')"
            item-text="nombreMostrar"
            item-value="nombreMostrar"
            multiple
            small-chips
            clearable
            prepend-icon="mdi-filter-variant"
            x-small
          >
            <template v-slot:selection="{ attrs, item, select, selected }">
              <v-chip
                v-bind="attrs"
                :input-value="selected"
                close
                small
                @click="select"
                @click:close="removeToComboBox(item)"
              >
                <span>{{ item.nombreMostrar }}</span>
              </v-chip>
            </template>
          </v-combobox>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions style="display:flex; justify-content: center;">
          <v-btn
            class="rounded-pill mx-1"
            dark
            depressed
            color="pink darken-3"
            @click="closeAddParticipant()"
          >
            {{ $t("DH360.cancel") }}
          </v-btn>
          <v-btn
            class="rounded-pill mx-1"
            dark
            depressed
            color="teal"
            @click="
              loadingSaveButton = true;
              virtualAddParticipants();
            "
          >
            {{ $t("DH360.save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="confirmSaveParticipants"
      max-width="450"
      scrollable
      persistent
    >
      <v-card class="rounded-lg">
        <p
          class="text-center pt-4 px-2"
          style="font-size: 1.1rem; font-weight:600;"
        >
          {{ $t("DH360.confirmSaveTextTitle") }}
        </p>
        <v-card-actions style="display:flex; justify-content: center;">
          <v-btn
            class="rounded-pill mx-1"
            dark
            depressed
            color="pink darken-3"
            @click="confirmSaveParticipants = false"
          >
            {{ $t("DH360.cancel") }}
          </v-btn>
          <v-btn
            class="rounded-pill mx-1"
            dark
            depressed
            color="teal"
            @click="
              confirmSaveParticipants = false;
              muestraLoading = true;
              saveParticipants();
            "
          >
            {{ $t("DH360.confirm") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="showSuccessMessage"
      max-width="450"
      scrollable
      persistent
    >
      <v-card class="rounded-lg">
        <p
          class="text-center pt-4 px-2"
          style="font-size: 1.1rem; font-weight:600;"
        >
          {{ $t("DH360.successfullyMessageAddParticipants") }} <br>
          {{ responseApiSaveData.total }} {{ $t("DH360.of") }} {{ responseApiSaveData.efectivos }}. <br>
          {{ $t("DH360.errors") }} {{ responseApiSaveData.errors }}
        </p> 
        <v-card-actions style="display:flex; justify-content: center;">
          <v-btn
            class="rounded-pill mx-1"
            dark
            depressed
            color="teal"
            @click="
              showSuccessMessage = false;
              $emit('updateEstatus', 'RESPONSABLE');
            "
          >
            {{ $t("DH360.confirm") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <loadingBar
      :text="$t('DH360.loadingTextSavingParticipants')"
      :dialog="muestraLoading"
    />
  </v-card>
</template>

<script>
import axios from "axios";
import { deleteToken } from "../../../../../../store/Funciones/funciones";
import loadingBar from "../../../../DesarrolloOrganizacional/components/loadingBar";
export default {
  name: "admon-participantes",
  components: { loadingBar },
  props: ["participantes", "estatus", "idProyecto","compania"],
  data() {
    return {
      itemsPerPage: 15,
      editedIndex: -1,
      search: "",
      textoLoading: "",
      participantSelected: [],
      headerProps: {
        sortByText: "Ordenar por",
      },
      editedItem: {
        emplid: "",
        name: "",
        jobcode: "",
        descr: "",
        descr1: "",
        Area: "",
        obj: 0,
        EMP_FECING: "",
      },
      defaultItem: {
        emplid: "",
        name: "",
        jobcode: "",
        descr: "",
        descr1: "",
        Area: "",
        obj: 0,
        EMP_FECING: "",
      },
      responseApiSaveData: {
        "total": 0,
        "efectivos": 0,
        "errors": 0
      },
      jobcodesComissionist: [
        '00470',
        '00109',
        '00108',
        '00082',
        '00027',
        '00103',
        '00118',
        '00028',
        '00111',
        '00022',
        '00142',
        '00055',
        '00040',
        '00137',
        '00120',
        '00098',
        '00024',
        '00050',
        '00149',
        '00025',
        '00143',
        '00123',
        '00110',
        '00106',
        '00114',
        '00057',
        '00075',
        '00085',
        '00116',
        '00141',
        '01231',
      ],
      userDontParticipate: [],
      colaboradores: [],
      loadingTabla: false,
      singleExpand: true,
      confirm: false,
      confirmDontParticipate: false,
      loadingConfirmRemuvePositions: false,
      findNewParticipant: false,
      loadingColaboradores: false,
      loadingSaveButton: false,
      comisionistasEliminados: false,
      muestraLoading: false,
      confirmSaveParticipants: false,
      showSuccessMessage: false,
      expanded: [],
      headers: [
        { value: "emplid", text: "EMPLID" },
        { value: "name", text: "" },
        { value: "descr", text: "" },
        { value: "actions", text: "", align: "center" },
      ],
    };
  },
  computed: {},
  mounted() {
    // this.sortObjects()
    this.headers[1].text = this.$t("DH360.nameTextHeader");
    this.headers[2].text = this.$t("DH360.positionTextHeader");
    this.headers[3].text = this.$t("DH360.actionsTextHeader");
  },
  methods: {
    removeComissionistAgents() {
      this.userDontParticipate.length = 0;
      let commissionistAgents = [];
      this.participantes.map((participante) => {
        let jobcodeIn = this.findJobcode(participante.jobcode);
        if (jobcodeIn) {
          commissionistAgents.push(participante);
        }
      });
      this.userDontParticipate = commissionistAgents;
      this.confirmDontParticipate = true;
    },

    removePositionsDontParticipant() {
      this.comisionistasEliminados = true;
      this.userDontParticipate.forEach((item) => {
        this.deleteParticipant(item, 2);
      });
      this.loadingConfirmRemuvePositions = false;
      this.confirmDontParticipate = false;
    },

    deleteParticipant(item, opc) {
      this.editedIndex = this.participantes.indexOf(item);
      // this.editedItem = Object.assign({}, item);
      if (opc === 1) this.confirm = true;
      else if (opc === 2) {
        this.participantes.splice(this.editedIndex, 1);
      }
    },

    deleteParticipantConfirm() {
      this.participantes.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    closeDelete() {
      this.confirm = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    getColaboradoresUHP() {
      this.colaboradores.length = 0;
      let params = {
        Opcion: 8,
        token: localStorage.getItem("token"),
        compania:this.compania
      };
      axios
        .post(
          `${this.$store.getters.getHost}/RelacionesLaborales/AdmonEvaluacionesDesempenio/ws-admon-360.php`,
          params
        )
        .then((response) => {
          if (response.data.status == "EXPRESSION") {
            deleteToken();
          } else if (response.data.status == "OK") {
            this.colaboradores = response.data.colaboradores;
            this.loadingColaboradores = false;
            this.findNewParticipant = true;
          } else {
            alert("error");
          }
        });
    },

    virtualAddParticipants() {
      this.participantSelected.map((p) => {
        let findParticipant = this.findParticipant(p.EMPLID);
        if (!findParticipant) {
          this.participantes.push({
            emplid: p.EMPLID,
            name: p.NAME,
            jobcode: p.JOBCODE,
            descr: p.DESCR,
            descr1: p.DESCR1,
            Area: p.Area,
            obj: p.obj,
            EMP_FECING: p.EMP_FECING,
          });
        }
      });
      this.loadingSaveButton = false;
      this.findNewParticipant = false;
    },

    findParticipant(val) {
      let bandera = false;

      this.participantes.map((valor) => {
        if (valor.emplid == val) {
          bandera = true;
        }
      });

      return bandera;
    },

    findJobcode(val) {
      let bandera = false;

      this.jobcodesComissionist.map((valor) => {
        if (valor == val) {
          bandera = true;
        }
      });

      return bandera;
    },

    removeToComboBox(item) {
      this.participantSelected.splice(
        this.participantSelected.indexOf(item),
        1
      );
    },

    closeAddParticipant() {
      this.participantSelected.length = 0;
      this.findNewParticipant = false;
    },

    saveParticipants() {
      let params = {
        Opcion: 9,
        token: localStorage.getItem("token"),
        participantes: this.participantes,
        idProyecto: this.idProyecto,
      };

      axios
        .post(
          `${this.$store.getters.getHost}/RelacionesLaborales/AdmonEvaluacionesDesempenio/ws-admon-360.php`,
          params
        )
        .then((response) => {
          if (response.data.status == "EXPRESSION") {
            deleteToken();
          } else if (response.data.status == "OK") {
            this.responseApiSaveData = response.data.resultado;
            this.muestraLoading = false;
            this.showSuccessMessage = true;
          } else {
            alert("error");
          }
        });
    },
  },
};
</script>

<style>
.tam-subheader {
  height: 25px;
  max-height: 75px;
}
</style>
