var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticStyle:{"display":"flex","justify-content":"center"},attrs:{"cols":"12"}},[_c('v-card',{staticClass:"rounded-lg mt-4",staticStyle:{"border":"1px solid #1565C0"},attrs:{"elevation":"0","max-width":"650"}},[_c('v-card-title',{staticClass:"primary white--text subtitle-1 pa-4"},[_vm._v(" Selecciona las fechas entre las que se mostrará la asistencia de tu equipo. ")]),_c('v-card-text',{staticClass:"pb-0",staticStyle:{"display":"flex","justify-content":"center"}},[_c('v-text-field',{staticStyle:{"max-width":"350px"},attrs:{"placeholder":"Selecciona el rango de fechas","label":"Selecciona el rango de fechas","prepend-icon":"mdi-calendar","readonly":""},on:{"click":function($event){_vm.dialogFechas = true}},model:{value:(_vm.dateRangeText),callback:function ($$v) {_vm.dateRangeText=$$v},expression:"dateRangeText"}})],1),_c('v-divider'),_c('v-card-actions',{staticClass:"pa-0"},[_c('v-btn',{staticClass:"rounded-lg rounded-t-0 font-weight-bold",attrs:{"depressed":"","color":"primary","text":"","x-large":"","block":"","dark":""},on:{"click":function($event){return _vm.filtrar()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-filter ")]),_vm._v(" Filtrar ")],1)],1)],1)],1)],1),_c('v-card',{staticClass:"mt-3 mx-10 rounded-lg elevation-0",staticStyle:{"border":"1px solid #1565C0"}},[_c('v-data-table',{attrs:{"dense":"","items":_vm.solicitudes,"headers":_vm.headers,"loading":_vm.loadingData,"search":_vm.search,"footer-props":{
        'items-per-page-text': 'Elementos por página',
        pageText: '{0}-{1} de {2}',
      },"no-data-text":"No hay registros","loading-text":"Cargando información...","no-results-text":'No se encontró ningun resultado que coincida con ' + _vm.search,"header-props":_vm.headerProps,"mobile-breakpoint":0,"item-key":"id_independiente"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"primary pa-3 rounded-lg rounded-b-0",staticStyle:{"display":"flex","justify-content":"space-between"}},[_c('v-text-field',{staticClass:"rounded-pill",attrs:{"dense":"","outlined":"","dark":"","prepend-inner-icon":"mdi-magnify","placeholder":"Buscar..."},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('div',[_c('vue-json-to-csv',{attrs:{"json-data":_vm.solicitudes,"labels":_vm.headers_to_export_zn,"csv-title":'ReporteSolicitudesNominasZentric'}},[_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"rounded-pill mr-2",attrs:{"depressed":"","color":"white","dark":"","fab":"","small":"","outlined":"","loading":_vm.loadingData}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-alpha-z")])],1)]}}])},[_c('span',[_vm._v("Exportar CSV zentric")])])],1),_c('vue-json-to-csv',{attrs:{"json-data":_vm.solicitudes,"labels":_vm.headers_to_export,"csv-title":'ReporteSolicitudesNominas'}},[_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"rounded-pill mr-2",attrs:{"depressed":"","color":"white","dark":"","fab":"","small":"","outlined":"","loading":_vm.loadingData}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-microsoft-excel")])],1)]}}])},[_c('span',[_vm._v("Exportar CSV")])])],1),_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"rounded-pill mr-2",attrs:{"depressed":"","color":"white","dark":"","fab":"","small":"","outlined":"","loading":_vm.loadingData},on:{"click":_vm.actualizarTabla}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-reload")])],1)]}}])},[_c('span',[_vm._v("Refrescar")])])],1)],1)]},proxy:true},{key:"item.fecha",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.setFormatDate(item.fecha))+" ")]}},{key:"item.estatus",fn:function(ref){
      var item = ref.item;
return [_c('v-chip',{staticClass:"font-weight-bold",attrs:{"small":"","depressed":"","color":_vm.getColorEstatus(item.estatus)}},[_vm._v(" "+_vm._s(_vm.setEstatusText(item.estatus))+" ")])]}},{key:"item.fecha_registro",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.setFormatDateTime(item.fecha_registro))+" ")]}},{key:"item.sueldo_diario",fn:function(ref){
      var item = ref.item;
return [_c('label',{staticClass:"font-italic"},[_vm._v(_vm._s(_vm.formatMXN(item.sueldo_diario)))])]}},{key:"item.monto",fn:function(ref){
      var item = ref.item;
return [_c('label',{staticClass:"font-weight-bold font-italic"},[_vm._v(_vm._s(_vm.formatMXN(item.monto)))])]}}])})],1),_c('v-dialog',{attrs:{"max-width":"450px"},model:{value:(_vm.dialogFechas),callback:function ($$v) {_vm.dialogFechas=$$v},expression:"dialogFechas"}},[_c('v-date-picker',{attrs:{"max-width":"450px","selected-items-text":"Seleccionados","range":"","elevation":"1","locale":"es-mx"},on:{"change":function($event){return _vm.cierraDialog()}},model:{value:(_vm.dates),callback:function ($$v) {_vm.dates=$$v},expression:"dates"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }