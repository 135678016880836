<template>
  <v-row justify="center">
    <v-dialog
      v-model="verFamilias"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title>{{ $t("DH360.families") }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              dark
              class="font-weight-bold"
              text
              @click="$emit('cerrarVerFamilias')"
            >
              {{ $t("DH360.close") }}
              <v-icon right> mdi-close </v-icon>
            </v-btn>
          </v-toolbar-items>

          <template v-slot:extension>
            <v-tabs v-model="tab" align-with-title>
              <v-tabs-slider color="white"></v-tabs-slider>

              <v-tab> Relacion emp - resp </v-tab>
              <v-tab> Familias asignadas </v-tab>
            </v-tabs>
          </template>
        </v-toolbar>

        <v-alert
          v-model="showSnack"
          :color="colorSnack"
          class="ma-2"
          border="top"
          dark
        >
          <v-row align="center">
            <v-col class="grow">
              {{ textSnack }}
            </v-col>
            <v-col class="shrink">
              <v-btn
                class="font-weight-bold"
                color="white"
                text
                dark
                @click="showSnack = false"
                >{{ $t("DH360.close") }}</v-btn
              >
            </v-col>
          </v-row>
        </v-alert>

        <v-tabs-items v-model="tab">
          <v-tab-item>
            <v-card class="rounded-lg mx-2 mt-2" elevation="10">
              <v-data-iterator
                :items="famRelEmpResp"
                item-key="liderName"
                :items-per-page.sync="itemsPerPage"
                :page.sync="page"
                :search="search"
                :sort-by="sortBy.toLowerCase()"
                :sort-desc="sortDesc"
                :loading="loadingFamRelEmp"
                :loading-text="$t('DH360.gettingFamilies')"
                hide-default-footer
              >
                <template v-slot:header>
                  <v-toolbar
                    class="mb-1 rounded-lg rounded-b-0"
                    dark
                    color="primary"
                  >
                    <v-text-field
                      v-model="search"
                      clearable
                      flat
                      dense
                      solo-inverted
                      hide-details
                      prepend-inner-icon="mdi-magnify"
                      :label="$t('DH360.findTextBox')"
                    ></v-text-field>
                    <v-btn
                      class="ml-4 animated-btn font-weight-bold"
                      text
                      color="white"
                      dark
                      @click="confirmMigration = true"
                    >
                      <v-icon left> mdi-account-multiple-plus </v-icon>
                      {{ $t("DH360.migrate") }} {{ $t("DH360.families") }}
                    </v-btn>
                    <v-btn
                      class="ml-4 animated-btn font-weight-bold"
                      text
                      color="white"
                      dark
                      @click="
                        OpenModifyRelEmpRes = true;
                        loadingMembers = true;
                        getParticipantes();
                      "
                    >
                      <v-icon left> mdi-pencil </v-icon>
                      {{ $t("DH360.edit") }} {{ $t("DH360.relations") }}
                    </v-btn>
                    <template v-if="$vuetify.breakpoint.mdAndUp">
                      <v-spacer></v-spacer>
                      <v-btn-toggle v-model="sortDesc" mandatory>
                        <v-btn small depressed color="blue" :value="false">
                          <v-icon>mdi-arrow-up</v-icon>
                        </v-btn>
                        <v-btn small depressed color="blue" :value="true">
                          <v-icon>mdi-arrow-down</v-icon>
                        </v-btn>
                      </v-btn-toggle>
                    </template>
                  </v-toolbar>
                </template>
                <template v-slot:default="{ items }">
                  <v-row class="mx-2">
                    <v-col
                      v-for="item in items"
                      :key="item.lider.Responsable"
                      cols="12"
                      xs="12"
                      sm="6"
                      md="3"
                      lg="2"
                      scrollable
                    >
                      <v-card
                        class="overflow-y-auto rounded-lg"
                        scrollable
                        min-height="200"
                        v-scroll.self="onScroll"
                        max-height="200"
                      >
                        <p
                          class="text-center mt-3 font-weight-bold blue-grey--text text--darken-1"
                        >
                          {{ item.lider.Nombre }}
                        </p>

                        <v-divider></v-divider>
                        <v-card-text class="pa-1">
                          <p
                            class="pa-0 ma-1"
                            v-for="sub in item.equipo"
                            :key="sub.Nombre"
                          >
                            <strong>
                              <v-icon x-small> mdi-hand-pointing-right </v-icon>
                            </strong>
                            {{ sub.Nombre.toUpperCase() }}
                          </p>
                          <v-card-actions
                            style="display: flex; justify-content: center"
                          >
                          </v-card-actions>
                        </v-card-text>
                      </v-card>
                      <div class="text-center">
                        <v-btn
                          class="back-btn-save rounded-lg rounded-t-0"
                          dark
                          @click="openCreateFamily(item)"
                        >
                          {{ $t("DH360.confirm") }} {{ $t("DH360.family") }}
                        </v-btn>
                      </div>
                    </v-col>
                  </v-row>
                </template>
                <template v-slot:footer>
                  <v-row class="ma-2" align="center" justify="center">
                    <span class="grey--text">{{
                      $t("DH360.itemsPerPage")
                    }}</span>
                    <v-menu offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          dark
                          text
                          color="primary"
                          class="ml-2"
                          v-bind="attrs"
                          v-on="on"
                        >
                          {{ itemsPerPage }}
                          <v-icon>mdi-chevron-down</v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item
                          v-for="(number, index) in itemsPerPageArray"
                          :key="index"
                          @click="updateItemsPerPage(number)"
                        >
                          <v-list-item-title>{{ number }}</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>

                    <v-spacer></v-spacer>

                    <span class="mr-4 grey--text">
                      {{ $t("DH360.page") }} {{ page }} {{ $t("DH360.of") }}
                      {{ numberOfPages }}
                    </span>
                    <v-btn
                      fab
                      x-small
                      dark
                      color="primary"
                      class="mr-1"
                      @click="formerPage"
                    >
                      <v-icon>mdi-chevron-left</v-icon>
                    </v-btn>
                    <v-btn
                      fab
                      x-small
                      dark
                      color="primary"
                      class="ml-1"
                      @click="nextPage"
                    >
                      <v-icon>mdi-chevron-right</v-icon>
                    </v-btn>
                  </v-row>
                </template>
              </v-data-iterator>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card class="rounded-lg mx-2 mt-2" elevation="10">
              <v-data-iterator
                :items="familiesCreated"
                item-key="Descripcion"
                :items-per-page.sync="itemsPerPageFam"
                :page.sync="page"
                :search="searchFamily"
                :sort-by="sortBy.toLowerCase()"
                :sort-desc="sortDesc"
                :loading-text="$t('DH360.gettingFamilies')"
                :loading="loadingFamilies"
                :no-data-text="$t('DH360.noDataText')"
                hide-default-footer
              >
                <template v-slot:header>
                  <v-toolbar
                    class="mb-1 rounded-lg rounded-b-0"
                    dark
                    color="#0078c3"
                  >
                    <v-text-field
                      v-model="searchFamily"
                      clearable
                      flat
                      dense
                      solo-inverted
                      hide-details
                      prepend-inner-icon="mdi-magnify"
                      :label="$t('DH360.findTextBox')"
                    ></v-text-field>
                    <template v-if="$vuetify.breakpoint.mdAndUp">
                      <v-spacer></v-spacer>
                      <v-btn-toggle v-model="sortDesc" mandatory>
                        <v-btn small depressed color="#0078c3" :value="false">
                          <v-icon>mdi-arrow-up</v-icon>
                        </v-btn>
                        <v-btn small depressed color="#0078c3" :value="true">
                          <v-icon>mdi-arrow-down</v-icon>
                        </v-btn>
                      </v-btn-toggle>
                    </template>
                  </v-toolbar>
                </template>
                <template v-slot:default="{ items }">
                  <v-row class="mx-2">
                    <v-col
                      v-for="item in items"
                      :key="item.id"
                      cols="12"
                      xs="12"
                      sm="6"
                      md="3"
                      lg="2"
                      scrollable
                    >
                      <v-card
                        class="rounded-lg dark"
                        min-height="300"
                        max-height="300"
                      >
                        <v-toolbar
                          color="primary elevation-0 pa-0"
                          flat
                          dense
                          dark
                        >
                          <p class="font-weight-bold pr-1 my-2">
                            {{ item.Descripcion }}
                          </p>
                          <v-spacer></v-spacer>
                          <div class="pa-0">
                            <v-btn
                              class="pa-0 ma-0 rounded-pill"
                              small
                              icon
                              @click="openDeleteFamily(item)"
                            >
                              <v-icon>mdi-delete-circle-outline</v-icon>
                            </v-btn>
                          </div>
                        </v-toolbar>

                        <!-- <v-divider style="border-color:#91c1e7;"></v-divider> -->
                        <v-card-text class="pa-1">
                          <div class="scroll-container">
                            <p
                              class="pa-0 ma-1"
                              v-for="mem in item.miembros"
                              :key="mem.Empleado"
                            >
                              <strong>
                                <v-icon x-small>
                                  mdi-hand-pointing-right
                                </v-icon>
                              </strong>
                              {{ mem.Nombre.toUpperCase() }}
                            </p>
                          </div>
                        </v-card-text>
                        <v-divider style="border-color: #91c1e7"></v-divider>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            class="rounded-lg"
                            small
                            dark
                            color="teal"
                            @click="openEditFamily(item)"
                          >
                            <v-icon left>mdi-pencil</v-icon>
                            {{ $t("DH360.edit").toUpperCase() }}
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-col>
                  </v-row>
                </template>
                <template v-slot:footer>
                  <v-row class="ma-2" align="center" justify="center">
                    <span class="grey--text">{{
                      $t("DH360.itemsPerPage")
                    }}</span>
                    <v-menu offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          dark
                          text
                          color="#91c1e7"
                          class="ml-2"
                          v-bind="attrs"
                          v-on="on"
                        >
                          {{ itemsPerPageFam }}
                          <v-icon>mdi-chevron-down</v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item
                          v-for="(number, index) in itemsPerPageArray"
                          :key="index"
                          @click="updateItemsPerPageFam(number)"
                        >
                          <v-list-item-title>{{ number }}</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>

                    <v-spacer></v-spacer>

                    <span class="mr-4 grey--text">
                      {{ $t("DH360.page") }} {{ pageFam }} {{ $t("DH360.of") }}
                      {{ numberOfPagesFamilies }}
                    </span>
                    <v-btn
                      fab
                      x-small
                      dark
                      color="#91c1e7"
                      class="mr-1"
                      @click="formerPageFam"
                    >
                      <v-icon>mdi-chevron-left</v-icon>
                    </v-btn>
                    <v-btn
                      fab
                      x-small
                      dark
                      color="#91c1e7"
                      class="ml-1"
                      @click="nextPageFam"
                    >
                      <v-icon>mdi-chevron-right</v-icon>
                    </v-btn>
                  </v-row>
                </template>
              </v-data-iterator>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-dialog>

    <v-dialog v-model="createNewFamily" persistent scrollable width="500">
      <v-card class="rounded-lg">
        <v-card-text>
          <p
            class="mt-4 text-center font-weight-bold blue--text text--accent-4"
            style="font-size: 1.15rem"
          >
            {{ $t("DH360.dialogTitleAddFamily") }}
          </p>
          <v-divider class="mb-4"></v-divider>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-text-field
              v-model="familyName"
              :label="$t('DH360.familyName')"
              outlined
              dense
              required
              :rules="nameRules"
            >
            </v-text-field>
          </v-form>

          <div class="text-right">
            <v-btn
              class="rounded-pill mb-2"
              depressed
              outlined
              small
              dark
              color="green darken-2"
              @click="
                loadingMembers = true;
                showFindNewMembers = true;
                getParticipantes();
              "
            >
              <v-icon left> mdi-plus </v-icon>
              {{ $t("DH360.add") }} {{ $t("DH360.members") }}
            </v-btn>
          </div>

          <v-simple-table dense>
            <template v-slot:default>
              <tbody>
                <tr>
                  <td>
                    {{ familyAux.liderName }}
                    <strong>({{ $t("DH360.leader") }})</strong>
                  </td>
                  <td></td>
                </tr>
                <tr v-for="item in familyAux.equipo" :key="item.Empleado">
                  <td>{{ item.Nombre }}</td>
                  <td>
                    <v-icon
                      small
                      class="mr-2"
                      color="pink darken-3"
                      @click="removeMember(item)"
                    >
                      mdi-minus-circle
                    </v-icon>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
        <v-card-actions style="display: flex; justify-content: space-around">
          <v-btn
            class="rounded-lg"
            depressed
            dark
            width="180"
            color="pink darken-3"
            @click="closeCreateFamily"
          >
            {{ $t("DH360.close") }}
          </v-btn>
          <v-btn
            class="rounded-lg"
            depressed
            dark
            width="180"
            color="teal"
            @click="saveFamily"
          >
            {{ $t("DH360.save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showFindNewMembers" persistent max-width="450">
      <v-card class="rounded-lg" color="primary">
        <v-card-title class="text-subtitle-2 blue lighten-1 white--text">
          {{ $t("DH360.add") }} {{ $t("DH360.members") }}
          <v-spacer></v-spacer>
          <v-icon small dark @click="showFindNewMembers = false"
            >mdi-close</v-icon
          >
        </v-card-title>
        <v-card-text>
          <v-autocomplete
            v-model="newMember"
            class="white--text"
            required
            :disabled="loadingGuardar"
            :loading="loadingMembers"
            :items="colaboradores"
            item-text="NombreMostrar"
            color="white"
            dark
            counter="200"
            :label="$t('DH360.inputNameFamilyText')"
          ></v-autocomplete>
        </v-card-text>
        <v-divider class="white"> </v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="rounded-lg primary--text white"
            small
            depressed
            :loading="loadingGuardar"
            @click="
              loadingGuardar = true;
              saveMember();
            "
          >
            {{ $t("DH360.save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="confirmDelete" max-width="450" scrollable persistent>
      <v-card class="rounded-lg">
        <p
          class="text-center pt-4 px-2"
          style="font-size: 1.1rem; font-weight: 600; color: darkslategray"
        >
          {{ $t("DH360.confirmTextDeleteFamily") }}
          {{ familyNameTolDelete }}?
        </p>
        <v-card-actions style="display: flex; justify-content: space-around">
          <v-btn
            class="rounded-lg"
            large
            depressed
            color="pink darken-3"
            width="180"
            :disabled="loadButtonDelFam"
            dark
            @click="closeDeleteFamily"
          >
            {{ $t("DH360.cancel") }}
          </v-btn>
          <v-btn
            class="rounded-lg"
            large
            depressed
            color="teal"
            width="180"
            :loading="loadButtonDelFam"
            dark
            @click="
              loadButtonDelFam = true;
              deleteFamily();
            "
          >
            {{ $t("DH360.confirm") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="confirmMigration" max-width="450" scrollable persistent>
      <v-card class="rounded-lg">
        <p
          class="text-center pt-4 px-2"
          style="font-size: 1.1rem; font-weight: 600; color: darkslategray"
        >
          {{ $t("DH360.textTitleDialogConfirmReloadQuestion") }}
        </p>
        <v-card-actions style="display: flex; justify-content: space-around">
          <v-btn
            class="rounded-lg"
            large
            depressed
            color="pink darken-3"
            width="180"
            dark
            @click="confirmMigration = false"
          >
            {{ $t("DH360.cancel") }}
          </v-btn>
          <v-btn
            class="rounded-lg"
            large
            depressed
            color="teal"
            width="180"
            dark
            @click="
              confirmMigration = false;
              showDialogLoading = true;
              createFamilyFromRelEmpLead();
            "
          >
            {{ $t("DH360.confirm") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showEdit" persistent scrollable width="500">
      <v-card class="rounded-lg">
        <v-card-text>
          <p
            class="mt-4 text-center font-weight-bold blue--text text--accent-4"
            style="font-size: 1.15rem"
          >
            {{ $t("DH360.dialogTitleAddFamily") }}
          </p>
          <v-divider class="mb-4"></v-divider>
          <v-form ref="formEdit" v-model="validEdit" lazy-validation>
            <v-text-field
              v-model="familyNameEdit"
              :label="$t('DH360.familyName')"
              :value="emptyFamily.Descripcion"
              :disabled="loadingEditFamily"
              outlined
              dense
              required
              :rules="nameRules"
            >
            </v-text-field>
          </v-form>

          <div class="text-right">
            <v-btn
              class="rounded-pill mb-2"
              depressed
              outlined
              small
              dark
              color="green darken-2"
              :disabled="loadingEditFamily"
              @click="
                loadingMembers = true;
                showFindNewMembers = true;
                getParticipantes();
              "
            >
              <v-icon left> mdi-plus </v-icon>
              {{ $t("DH360.add") }} {{ $t("DH360.members") }}
            </v-btn>
          </div>

          <v-simple-table dense>
            <template v-slot:default>
              <tbody>
                <tr v-for="item in emptyFamily.miembros" :key="item.Empleado">
                  <td>{{ item.Nombre }}</td>
                  <td>
                    <v-icon
                      small
                      class="mr-2"
                      color="pink darken-3"
                      :disabled="loadingEditFamily"
                      @click="removeFamilyMember(item)"
                    >
                      mdi-minus-circle
                    </v-icon>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
        <v-card-actions style="display: flex; justify-content: space-around">
          <v-btn
            class="rounded-lg"
            depressed
            dark
            width="180"
            color="pink darken-3"
            :disabled="loadingEditFamily"
            @click="closeEditFamily"
          >
            {{ $t("DH360.close") }}
          </v-btn>
          <v-btn
            class="rounded-lg"
            depressed
            dark
            width="180"
            color="teal"
            :loading="loadingEditFamily"
            @click="
              loadingEditFamily = true;
              saveFamilyEdited();
            "
          >
            {{ $t("DH360.save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="OpenModifyRelEmpRes"
      persistent
      scrollable
      max-width="500"
    >
      <v-card class="rounded-lg" elevation="24">
        <v-card-title class="py-0 primary">
          <p class="font-weight-bold white--text mt-3">
            {{ $t("DH360.edit") }} {{ $t("DH360.relations") }}
          </p>
        </v-card-title>
        <v-divider style="border-color: #91c1e7"></v-divider>
        <v-card-text>
          <v-autocomplete
            v-model="findedUser"
            required
            :disabled="loadingGuardar"
            :loading="loadingMembers"
            :items="colaboradores"
            v-on:change="
              ldnBtnGetRelEr = true;
              getRelEmpRespIndividual();
            "
            item-text="NombreMostrar"
            counter="200"
            :label="$t('DH360.inputNameFamilyText')"
          ></v-autocomplete>

          <div v-if="ldnBtnGetRelEr">
            <div class="text-center">
              <v-progress-circular indeterminate color="primary" :size="50">
              </v-progress-circular>
            </div>
          </div>
          <div
            v-else-if="
              !ldnBtnGetRelEr &&
              ((relEmpRespInd.Responsable != null &&
                relEmpRespInd.Responsable.Nombre != '') ||
                relEmpRespInd.Subs.length > 0)
            "
          >
            <p class="mb-1 font-weight-bold blue-grey--text text--darken-1">
              {{ $t("DH360.leader") }}
            </p>
            <v-simple-table
              v-show="relEmpRespInd.Responsable != null"
              dense
              style="background-color: transparent"
            >
              <thead>
                <tr>
                  <th class="text-left">EMPLID</th>
                  <th class="text-left">
                    {{ $t("DH360.nameTextHeader") }}
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{ relEmpRespInd.Responsable.emplid }}</td>
                  <td>{{ relEmpRespInd.Responsable.Nombre }}</td>
                  <td>
                    <v-btn
                      x-small
                      rounded
                      outlined
                      color="teal"
                      @click="
                        esResponsable = true;
                        loadingMembers = true;
                        showFindNewMembers = true;
                        getParticipantes();
                      "
                    >
                      <v-icon left small>mdi-pencil</v-icon>
                      {{ $t("DH360.replace") }}
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
            <p
              class="my-1 font-weight-bold blue-grey--text text--darken-1"
              v-show="relEmpRespInd.Subs.length > 0"
            >
              {{ $t("DH360.subs") }}
            </p>
            <v-simple-table
              v-show="relEmpRespInd.Subs.length > 0"
              dense
              style="background-color: transparent"
            >
              <thead>
                <tr>
                  <th class="text-left">EMPLID</th>
                  <th class="text-left">
                    {{ $t("DH360.nameTextHeader") }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in relEmpRespInd.Subs" :key="index">
                  <td>{{ item.emplid }}</td>
                  <td>{{ item.Nombre }}</td>
                </tr>
                <tr>
                  <td colspan="2">
                    <v-btn
                      class="my-1"
                      rounded
                      outlined
                      block
                      small
                      color="teal"
                      @click="
                        loadingMembers = true;
                        showFindNewMembers = true;
                        getParticipantes();
                      "
                    >
                      <v-icon small left>mdi-plus</v-icon>
                      {{ $t("DH360.add") }} {{ $t("DH360.subs") }}
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </div>
        </v-card-text>
        <v-divider style="border-color: #91c1e7"></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="rounded-pill font-weight-bold"
            text
            depressed
            color="pink darken-3"
            :loading="ldnBtnGetRelEr"
            @click="closeEditRelEmp()"
          >
            {{ $t("DH360.close") }}
          </v-btn>
          <v-btn
            class="rounded-pill font-weight-bold"
            text
            depressed
            color="teal"
            :loading="ldnBtnGetRelEr"
            @click="
              ldnBtnGetRelEr = true;
              saveRelEmpRespIndividual();
            "
          >
            {{ $t("DH360.save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <LoadingBar :dialog="showDialogLoading" :text="textDialogLoading" />
  </v-row>
</template>

<script>
import axios from "axios";
import { deleteToken } from "../../../../../../store/Funciones/funciones";
import LoadingBar from "../../../../DesarrolloOrganizacional/components/loadingBar";

export default {
  name: "mostrar-familias-360",
  components: { LoadingBar },
  props: ["verFamilias", "idProyecto", "compania"],
  data() {
    return {
      tab: null,
      scrollInvoked: 0,
      itemsPerPageArray: [4, 8, 12],
      search: "",
      searchFamily: "",
      newMember: "",
      familyName: "",
      colorSnack: "",
      textSnack: "",
      textDialogLoading: " ... ",
      familyNameEdit: "",
      findedUser: "",
      filter: {},
      itemOriginal: null,
      familyAux: {
        liderName: "",
        lider: {
          Responsable: 0,
          Nombre: "",
          NumSubs: 0,
        },
        equipo: [],
      },
      emptyFamily: {
        id: 0,
        Descripcion: "",
        miembros: [],
      },
      relEmpRespInd: {
        Responsable: {
          emplid: 0,
          Nombre: "",
        },
        Subs: [],
      },
      sortDesc: false,
      familyIdTolDelete: 0,
      familyNameTolDelete: "",
      esResponsable: false,
      page: 1,
      pageFam: 1,
      itemsPerPage: 8,
      itemsPerPageFam: 8,
      emplidEditRelEmp: -1,
      sortBy: "name",
      loadingFamRelEmp: true,
      loadingFamilies: true,
      createNewFamily: false,
      showFindNewMembers: false,
      loadingMembers: false,
      valid: true,
      validEdit: true,
      loadingGuardar: false,
      showSnack: false,
      confirmDelete: false,
      loadButtonDelFam: false,
      showDialogLoading: false,
      confirmMigration: false,
      showEdit: false,
      loadingEditFamily: false,
      OpenModifyRelEmpRes: false,
      ldnBtnGetRelEr: false,
      famRelEmpResp: [],
      familiesCreated: [],
      colaboradores: [],
      nameRules: [
        (v) => !!v || this.$t("DH360.emptyFieldValidationRule"),
        (v) => (v && v.length <= 100) || this.$t("DH360.rulesNameProjectField"),
      ],
    };
  },

  mounted() {
    this.getFamRelEmpResp();
    this.getAsignedFamilies();
  },
  computed: {
    numberOfPages() {
      return Math.ceil(this.famRelEmpResp.length / this.itemsPerPage);
    },
    numberOfPagesFamilies() {
      return Math.ceil(this.familiesCreated.length / this.itemsPerPage);
    },
    filteredKeys() {
      return this.keys.filter((key) => key !== "Name");
    },
  },
  methods: {
    nextPage() {
      if (this.page + 1 <= this.numberOfPages) this.page += 1;
    },
    formerPage() {
      if (this.page - 1 >= 1) this.page -= 1;
    },
    updateItemsPerPage(number) {
      this.itemsPerPage = number;
    },

    nextPageFam() {
      if (this.pageFam + 1 <= this.numberOfPagesFamilies) this.pageFam += 1;
    },
    formerPageFam() {
      if (this.pageFam - 1 >= 1) this.pageFam -= 1;
    },
    updateItemsPerPageFam(number) {
      this.itemsPerPageFam = number;
    },

    onScroll() {
      this.scrollInvoked++;
    },

    getFamRelEmpResp() {
      this.famRelEmpResp.length = 0;
      let params = {
        Opcion: 13,
        token: localStorage.getItem("token"),
        idProyecto: this.idProyecto,
      };
      axios
        .post(
          `${this.$store.getters.getHost}/RelacionesLaborales/AdmonEvaluacionesDesempenio/ws-admon-360.php`,
          params
        )
        .then((response) => {
          if (response.data.status == "EXPRESSION") {
            deleteToken();
          } else if (response.data.status == "OK") {
            this.famRelEmpResp = response.data.relaciones;
            this.loadingFamRelEmp = false;
          }
        });
    },

    getAsignedFamilies() {
      this.familiesCreated.length = 0;
      let params = {
        Opcion: 14,
        token: localStorage.getItem("token"),
        idProyecto: this.idProyecto,
        compania:this.compania
      };
      axios
        .post(
          `${this.$store.getters.getHost}/RelacionesLaborales/AdmonEvaluacionesDesempenio/ws-admon-360.php`,
          params
        )
        .then((response) => {
          if (response.data.status == "EXPRESSION") {
            deleteToken();
          } else if (response.data.status == "OK") {
            this.familiesCreated = response.data.relaciones;
            this.loadingFamilies = false;
          } else {
            this.loadingFamilies = false;
          }
        });
    },

    openCreateFamily(item) {
      this.familyAux = item;
      this.itemOriginal = item;
      this.createNewFamily = true;
    },

    closeCreateFamily() {
      this.familyAux = {
        liderName: "",
        lider: {
          Responsable: 0,
          Nombre: "",
          NumSubs: 0,
        },
        equipo: [],
      };
      this.loadingFamRelEmp = true;
      this.getFamRelEmpResp();
      this.getAsignedFamilies();
      this.createNewFamily = false;
    },

    removeMember(item) {
      let editedIndex = this.familyAux.equipo.indexOf(item);
      this.familyAux.equipo.splice(editedIndex, 1);
    },

    removeFamilyMember(item) {
      let editedIndex = this.emptyFamily.miembros.indexOf(item);
      this.emptyFamily.miembros.splice(editedIndex, 1);
    },

    saveMember() {
      let selectedMemder = this.colaboradores.filter(
        (p) => p.NombreMostrar === this.newMember
      );

      if (this.familyAux.equipo.length >= 1) {
        this.familyAux.equipo.push({
          Empleado: selectedMemder[0].ID_EMPLEADO,
          Nombre: selectedMemder[0].NAME,
        });
      } else if (this.emptyFamily.miembros.length >= 1) {
        this.emptyFamily.miembros.push({
          Empleado: selectedMemder[0].ID_EMPLEADO,
          Nombre: selectedMemder[0].NAME,
          Familia: 0,
        });
      } else if (
        (this.relEmpRespInd.Responsable.Nombre != "" &&
          this.relEmpRespInd.Responsable != null) ||
        this.relEmpRespInd.Subs.length > 0
      ) {
        if (this.esResponsable) {
          this.relEmpRespInd.Responsable.Nombre = selectedMemder[0].NAME;
          this.relEmpRespInd.Responsable.emplid = selectedMemder[0].ID_EMPLEADO;
          this.esResponsable = false;
        } else {
          this.relEmpRespInd.Subs.push({
            Nombre: selectedMemder[0].NAME,
            emplid: selectedMemder[0].ID_EMPLEADO,
          });
        }
      }
      this.loadingGuardar = false;
      this.showFindNewMembers = false;
    },

    getParticipantes() {
      if (this.newMember != "") {
        this.newMember = "";
      }
      if (this.colaboradores.length == 0) {
        let params = {
          Opcion: 11,
          token: localStorage.getItem("token"),
          idProyecto: this.idProyecto,
          compania: this.compania,
          editarRelaciones: 1,
        };
        axios
          .post(
            `${this.$store.getters.getHost}/RelacionesLaborales/AdmonEvaluacionesDesempenio/ws-admon-360.php`,
            params
          )
          .then((response) => {
            if (response.data.status == "EXPRESSION") {
              deleteToken();
            } else if (response.data.status == "OK") {
              this.colaboradores = response.data.participantes;
              this.loadingMembers = false;
            } else {
              this.loadingMembers = false;
            }
          });
      } else {
        this.loadingMembers = false;
      }
    },

    saveFamily() {
      let members = [];
      members.push({ Empleado: this.familyAux.lider.Responsable });
      this.familyAux.equipo.map((f) => {
        members.push({ Empleado: f.Empleado });
      });
      let params = {
        Opcion: 15,
        token: localStorage.getItem("token"),
        idProject: this.idProyecto,
        familyName: this.familyName,
        members: members,
      };
      axios
        .post(
          `${this.$store.getters.getHost}/RelacionesLaborales/AdmonEvaluacionesDesempenio/ws-admon-360.php`,
          params
        )
        .then((response) => {
          if (response.data.status == "EXPRESSION") {
            deleteToken();
          } else if (
            response.data.status == "OK" ||
            response.data.status == "ERROR"
          ) {
            this.validaRespuesta(response.data.type);
          }
        });
    },

    validaRespuesta(type) {
      this.colorSnack = "pink darken-3";
      switch (type) {
        case 1: {
          this.textSnack = this.$t("DH360.error1MsgFamCreated");
          break;
        }
        case 2: {
          this.textSnack = this.$t("DH360.succesMsgFamCreated");
          this.colorSnack = "success";
          this.closeCreateFamily();
          break;
        }
        case 3: {
          this.textSnack = this.$t("DH360.error3MsgFamCreated");
          break;
        }
        case 4: {
          this.textSnack = this.$t("DH360.error4MsgFamCreated");
          break;
        }
      }
      this.showSnack = true;
    },

    openDeleteFamily(family) {
      this.familyIdTolDelete = family.id;
      this.familyNameTolDelete = family.Descripcion;
      this.emptyFamily = family;
      this.confirmDelete = true;
    },

    closeDeleteFamily() {
      this.familyIdTolDelete = 0;
      this.familyNameTolDelete = "";
      this.confirmDelete = false;
    },

    deleteFamily() {
      let params = {
        Opcion: 17,
        token: localStorage.getItem("token"),
        idProyecto: this.idProyecto,
        idFamily: this.familyIdTolDelete,
      };
      axios
        .post(
          `${this.$store.getters.getHost}/RelacionesLaborales/AdmonEvaluacionesDesempenio/ws-admon-360.php`,
          params
        )
        .then((response) => {
          this.loadButtonDelFam = false;
          if (response.data.status == "EXPRESSION") {
            deleteToken();
          } else if (
            response.data.status == "OK" ||
            response.data.status == "ERROR"
          ) {
            if (response.data.respuesta.Familia > 0) {
              this.closeDeleteFamily();
              this.colorSnack = "success";
              this.textSnack =
                this.$t("DH360.successfullyMessageDeleteParticipants") +
                ". " +
                this.$t("DH360.family") +
                ": " +
                response.data.respuesta.Familia +
                ". " +
                this.$t("DH360.members") +
                "s: " +
                response.data.respuesta.Miembros;
              let editedFamily = this.familiesCreated.indexOf(this.emptyFamily);
              this.familiesCreated.splice(editedFamily, 1);
              this.emptyFamily = {
                id: 0,
                Descripcion: "",
                miembros: [],
              };
              this.loadingFamilies = true;
              this.getAsignedFamilies();
            } else {
              this.colorSnack = "pink darken-4";
              this.textSnack = this.$t("DH360.errorMessaggeDB");
            }
            this.showSnack = true;
          }
        });
    },

    createFamilyFromRelEmpLead() {
      this.confirmMigration = false;
      let text =
        this.$t("DH360.creating") + " " + this.$t("DH360.families") + "...";
      let success = 0,
        errors = 0;

      const promises = this.famRelEmpResp.map(async (f) => {
        let memb = [];
        memb.push({ Empleado: f.lider.Responsable });
        f.equipo.forEach((f) => {
          memb.push({ Empleado: f.Empleado });
        });
        let result = await this.saveFamilyMassive(memb, f.liderName);

        if (result == 2) success++;
        else errors++;

        this.textDialogLoading =
          text +
          this.$t("DH360.errors") +
          errors +
          ". " +
          this.$t("DH360.created") +
          success;
      });

      Promise.all(promises).then(() => {
        this.cloaseLoadingMigrations();
      });
      //
    },

    async saveFamilyMassive(members, name) {
      let params = {
        Opcion: 15,
        token: localStorage.getItem("token"),
        idProject: this.idProyecto,
        familyName: name,
        members: members,
      };

      return new Promise((resolve, reject) => {
        axios
          .post(
            `${this.$store.getters.getHost}/RelacionesLaborales/AdmonEvaluacionesDesempenio/ws-admon-360.php`,
            params
          )
          .then((response) => {
            if (response.data.status == "EXPRESSION") {
              deleteToken();
            } else if (
              response.data.status == "OK" ||
              response.data.status == "ERROR"
            ) {
              resolve(response.data.type);
            } else {
              reject(0);
            }
          });
      });
    },

    cloaseLoadingMigrations() {
      this.showDialogLoading = false;
      this.loadingFamilies = true;
      this.getAsignedFamilies();
    },

    openEditFamily(family) {
      this.emptyFamily = family;
      this.familyNameEdit = family.Descripcion;
      this.showEdit = true;
    },

    closeEditFamily() {
      this.emptyFamily = {
        id: 0,
        Descripcion: "",
        miembros: [],
      };
      this.$refs.formEdit.reset();
      this.$refs.formEdit.resetValidation();
      this.familyNameEdit = "";
      this.showEdit = false;
      this.loadingEditFamily = false;
      this.loadingFamilies = true;
      this.getAsignedFamilies();
    },

    saveFamilyEdited() {
      this.emptyFamily.Descripcion = this.familyNameEdit;
      let params = {
        Opcion: 22,
        token: localStorage.getItem("token"),
        idProyecto: this.idProyecto,
        idFamily: this.emptyFamily.id,
        familia: this.emptyFamily,
      };

      axios
        .post(
          `${this.$store.getters.getHost}/RelacionesLaborales/AdmonEvaluacionesDesempenio/ws-admon-360.php`,
          params
        )
        .then((response) => {
          if (response.data.status == "EXPRESSION") {
            deleteToken();
          } else if (
            response.data.status == "OK" ||
            response.data.status == "ERROR"
          ) {
            switch (response.data.message) {
              case -1: {
                this.colorSnack = "success";
                this.textSnack = this.$t("DH360.succesMsgFamCreated");
                break;
              }
              case 0: {
                this.colorSnack = "pink darken-3";
                this.textSnack = "DB ERROR. Contanct to support";
                break;
              }
              case 1: {
                this.colorSnack = "pink darken-3";
                this.textSnack = this.$t(
                  "DH360.textErrorDontEliminateToLeader"
                );
                break;
              }
              case 3: {
                this.colorSnack = "pink darken-3";
                this.textSnack = "ERROR. Type 3";
                break;
              }
              case 4: {
                this.colorSnack = "pink darken-3";
                this.textSnack = "ERROR. Type 4";
                break;
              }
              case 5: {
                this.colorSnack = "success";
                this.textSnack = "ERROR. Type 5";
                break;
              }
            }
            this.showSnack = true;
            this.closeEditFamily();
          }
        });
    },

    closeEditRelEmp() {
      this.relEmpRespInd = {
        Responsable: {
          emplid: 0,
          Nombre: "",
        },
        Subs: [],
      };
      this.findedUser = "";
      this.OpenModifyRelEmpRes = false;
      this.loadingFamRelEmp = true;
      this.getFamRelEmpResp();
    },

    getRelEmpRespIndividual() {
      this.relEmpRespInd = {
        Responsable: {
          emplid: 0,
          Nombre: "",
        },
        Subs: [],
      };
      let selectedMemder = this.colaboradores.filter(
        (p) => p.NombreMostrar === this.findedUser
      );
      this.emplidEditRelEmp = selectedMemder[0].ID_EMPLEADO;
      let params = {
        Opcion: 23,
        token: localStorage.getItem("token"),
        idProyecto: this.idProyecto,
        empleado: selectedMemder[0].ID_EMPLEADO,
      };
      axios
        .post(
          `${this.$store.getters.getHost}/RelacionesLaborales/AdmonEvaluacionesDesempenio/ws-admon-360.php`,
          params
        )
        .then((response) => {
          this.ldnBtnGetRelEr = false;
          if (response.data.status == "EXPRESSION") {
            deleteToken();
          } else if (response.data.status == "OK") {
            this.relEmpRespInd = response.data.respuesta;
          }
        })
        .catch(function (e) {
          alert(e);
        });
    },

    saveRelEmpRespIndividual() {
      let params = {
        Opcion: 24,
        token: localStorage.getItem("token"),
        idProyecto: this.idProyecto,
        empleado: this.emplidEditRelEmp,
        newRelation: this.relEmpRespInd,
      };
      axios
        .post(
          `${this.$store.getters.getHost}/RelacionesLaborales/AdmonEvaluacionesDesempenio/ws-admon-360.php`,
          params
        )
        .then((response) => {
          this.ldnBtnGetRelEr = false;
          if (response.data.status == "EXPRESSION") {
            deleteToken();
          } else if (
            response.data.status == "OK" ||
            response.data.status == "ERROR"
          ) {
            this.colorSnack = "success";
            if (
              response.data.respuesta.resp >= 1 &&
              response.data.respuesta.subs >= 1
            ) {
              this.textSnack =
                this.$t("DH360.textModifyRespAdv") +
                " " +
                this.$t("DH360.textModifySubsAdv2") +
                " " +
                response.data.respuesta.subs +
                " " +
                this.$t("DH360.textModifySubsAdv");
            } else if (
              response.data.respuesta.resp >= 1 &&
              response.data.respuesta.subs <= 0
            ) {
              this.textSnack = this.$t("DH360.textModifyRespAdv");
            } else if (
              response.data.respuesta.resp <= 0 &&
              response.data.respuesta.subs >= 1
            ) {
              this.textSnack =
                this.$t("DH360.textModifySubsAdv2") +
                " " +
                response.data.respuesta.subs +
                " " +
                this.$t("DH360.textModifySubsAdv");
            } else if (
              response.data.respuesta.resp <= 0 &&
              response.data.respuesta.subs <= 0
            ) {
              this.colorSnack = "pink darken-4";
              this.textSnack = this.$t("DH360.errorMessaggeDB");
            }
            this.closeEditRelEmp();
            this.showSnack = true;
          } else {
            console.log(response.data);
          }
        })
        .catch(function (e) {
          alert(e);
        });
    },
  },
};
</script>

<style>
.back-btn-save {
  background: rgb(6, 133, 20) !important;
  background: linear-gradient(
    90deg,
    rgba(6, 133, 20, 1) 22%,
    rgba(3, 183, 27, 1) 72%
  );
}

.scroll-container {
  height: 200px !important;
  overflow-y: scroll;
  background-color: transparent;
  border-radius: 10px !important;
  overflow-y: auto;
}

.scroll-container::-webkit-scrollbar {
  width: 10px;
  background-color: blue;
}

.scroll-container::-webkit-scrollbar-thumb {
  background-color: red;
  border-radius: 10px;
}

.scroll-container::-moz-scrollbar {
  width: 10px;
  background-color: rgba(240, 240, 240, 0.5);
}

.scroll-container::-moz-scrollbar-thumb {
  background-color: red;
  border-radius: 10px;
}

.scroll-container::scrollbar {
  width: 10px;
  background-color: blue;
}

.scroll-container::scrollbar-thumb {
  background-color: red;
  border-radius: 10px;
}

.back-card-families {
  background: rgb(0, 120, 195) !important;
  background: linear-gradient(
    180deg,
    rgba(0, 120, 195, 1) 22%,
    rgba(145, 193, 231, 1) 72%
  ) !important;
}

.back-card-rel-emp-res {
  background: rgb(0, 120, 195) !important;
  background: linear-gradient(
    183deg,
    rgba(0, 120, 195, 1) 0%,
    rgba(104, 14, 173, 1) 100%
  ) !important;
}

.animated-btn {
  transition: all 0.3s ease;
}

.animated-btn:hover {
  transform: scale(1.1);
}
</style>
