<template>
  <v-dialog
    v-model="verApego"
    persistent
    scrollable
    transition="dialog-bottom-transition"
    max-width="1100"
  >
    <v-card class="rounded-lg">
      <v-toolbar color="primary" dark
        >Apego a perfil de {{ nombrePuesto }}
        <v-spacer></v-spacer>
        <v-btn
          small
          rounded
          color="red darken-3"
          elevation="2"
          @click="$emit('cierraApego')"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
      <v-row v-if="loadingContent" class="mt-2 fill-height" align-content="center" justify="center">
        <v-col class="text-subtitle-1 text-center" cols="12">
          Cargando contenido
        </v-col>
        <v-col cols="6">
          <v-progress-linear
            color="primary"
            indeterminate
            rounded
            height="6"
          ></v-progress-linear>
        </v-col>
      </v-row>
      <v-row class="ma-0" v-else>
        <v-col cols="12" xl="4" lg="4" md="4" sm="12" xs="12">
          <p class="text-center ml-2 blue-grey--text text--lighten-2">
            Apego a perfil
            <!-- <v-icon
              v-show="
                datosPuesto.escolaridad.id != 0 &&
                  datosPuesto.conocimientos.length > 0
              "
              color="blue-grey lighten-2"
              small
              rigth
              @click="openEditAPP()"
            >
              mdi-pencil
            </v-icon> -->
          </p>
          <!-- <p>{{datosPuesto.escolaridad.id}} {{datosPuesto.conocimientos.length}}</p> -->
          <v-expand-x-transition>
            <v-card
              v-if="
                datosPuesto.escolaridad.id != 0 &&
                  datosPuesto.conocimientos.length > 0
              "
              class="rounded-xl background-card-edited"
              elevation="0"
            >
              <v-simple-table style="background-color:transparent;">
                <thead>
                  <tr>
                    <th class="text-center">
                      ID
                    </th>
                    <th class="text-center">
                      Escolaridad
                    </th>
                    <th class="text-center">
                      Ponderación
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="text-center">
                      {{ datosPuesto.escolaridad.id }}
                    </td>
                    <td class="text-center">
                      {{ datosPuesto.escolaridad.esc }} ({{
                        datosPuesto.escolaridad.carrera
                      }})
                    </td>
                    <td class="text-center">
                      {{ datosPuesto.escolaridad.Ponderacion }}
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
              <v-simple-table style="background-color:transparent;">
                <thead>
                  <tr>
                    <th class="text-center">
                      ID
                    </th>
                    <th class="text-center">
                      Conocimiento
                    </th>
                    <th class="text-center">
                      Ponderación
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in datosPuesto.conocimientos" :key="item.id">
                    <td class="text-center">
                      {{ item.id }}
                    </td>
                    <td class="text-center">
                      {{ item.descr }}
                    </td>
                    <td class="text-center">
                      {{ item.Ponderacion }}
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-card>
            <div v-else style="display:flex; justify-content: center;">
              <v-card class="shadow-md rounded-xl" max-height="250">
                <v-card-text>
                  <div class="text-center">
                    <v-btn
                      depressed
                      fab
                      x-large
                      dark
                      color="success"
                      @click="openEditAPP()"
                    >
                      <v-icon x-large>mdi-plus</v-icon>
                    </v-btn>
                  </div>
                  <div class="text-center">
                    <v-chip class="ma-2" @click="openEditAPP()">
                      Agregar apego a perfil
                    </v-chip>
                  </div>
                </v-card-text>
              </v-card>
            </div>
          </v-expand-x-transition>
        </v-col>
        <v-col cols="12" xl="8" lg="8" md="8" sm="12" xs="12">
          <p class="text-center ml-2 blue-grey--text text--lighten-2">
            Preguntas clave
          </p>
          <v-expand-x-transition>
            <v-card
              v-if="datosPuesto.preguntasClave.length > 0"
              class="rounded-xl background-card-edited"
              elevation="0"
              min-height="383"
            >
              <v-card-text class="py-15">
                <div v-for="item in datosPuesto.preguntasClave" :key="item.id">
                  <p class="pa-0 mb-0 ml-3 mr-3 text-left font-weight-bold">
                    {{ item.pregunta }}
                  </p>
                  <p
                    class="pa-0 mb-0 ml-3 mr-3 text-justify blue-grey--text text--lighten-2"
                  >
                    {{ item.Respuesta }}
                  </p>
                </div>
              </v-card-text>
              <!-- <div class="text-center">
                <v-btn
                  depressed
                  rounded
                  small
                  class="my-2 background-card-edited"
                  @click="openEditaPreguntas()"
                >
                  <v-icon left> mdi-pencil </v-icon>
                  editar preguntas
                </v-btn>
              </div> -->
            </v-card>
            <div v-else style="display:flex; justify-content: center;">
              <v-card class="shadow-md rounded-xl" max-height="250">
                <v-card-text>
                  <div class="text-center">
                    <v-btn
                      depressed
                      fab
                      x-large
                      dark
                      color="success"
                      @click="openEditaPreguntas()"
                    >
                      <v-icon x-large>mdi-plus</v-icon>
                    </v-btn>
                  </div>
                  <div class="text-center">
                    <v-chip class="ma-2" @click="openEditaPreguntas()">
                      Agregar preguntas clave
                    </v-chip>
                  </div>
                </v-card-text>
              </v-card>
            </div>
          </v-expand-x-transition>
        </v-col>
      </v-row>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn rounded elevation="2" color="teal darken-2" dark>
            guardar
        </v-btn>
    </v-card-actions>
    </v-card>
    <UpdatePreguntasClave 
        v-if="muestraUptPC"
        :muestraEdicionPreguntas="muestraUptPC"
        :preguntasPuesto="datosPuesto.preguntasClave"
        :primeraVez="true"
        @cierraVentanaPreguntasEdit="savePC"
    />
  </v-dialog>
</template>

<script>
import UpdatePreguntasClave from "./componentesSolicitudDePuesto/UpdatePreguntasClave.vue";
import axios from "axios";
export default {
  name: "ver-apego-existente",
  components: { UpdatePreguntasClave },
  data() {
    return {
      nombrePuesto: "",
      loadingContent: true,
      muestraUptPC: false,
      datosPuesto: {
        nombre: "",
        escolaridad: {
          idEscolaridad: 0,
          esc: "",
          carrera: "",
          idCarrera: 0,
          Ponderacion: 0,
        },
        conocimientos: [],
        preguntasClave: [],
      },
    };
  },
  props: ["verApego", "idPuesto"],
  mounted() {
    this.getdatosPuesto();
  },
  methods: {
    getdatosPuesto() {
      let params = {
        Opcion: 2,
        token: localStorage.getItem("token"),
        jobcode: this.idPuesto,
      };
      axios
        .post(
          `${this.$store.getters.getHost}/Reclutamiento/ws-control-de-puestos.php`,
          params
        )
        .then((response) => {
          if (response.data.status != "EXPSESSION") {
            this.datosPuesto = response.data.datosPuesto;
            this.nombrePuesto = this.primeraLetra(this.datosPuesto.nombre);
            this.loadingContent = false
          }
        });
    },
    primeraLetra(str) {
      return str[0].toUpperCase() + str.slice(1);
    },
    openEditaPreguntas() {
        this.muestraUptPC = true
    },
    savePC(preguntasCreadas){
        console.log(preguntasCreadas)
        this.datosPuesto.preguntasClave = preguntasCreadas
        this.muestraUptPC = false
    }
  },
};
</script>
