import axios from 'axios'
import {
    deleteToken
} from '../../../../store/Funciones/funciones'
import SnackBarErrors from '../../General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue';

export default {
    name: 'perfiles-puestos',
    components: {
        SnackBarErrors
    },
    props: [],
    data() {
        return {
            itemsPerPage: 10,
            headersProductivity: [{
                    text: 'ID',
                    align: 'start',
                    sortable: false,
                    value: 'id',
                },
                {
                    text: 'Productivity',
                    value: 'idProductivity'
                },
                {
                    text: 'Jobcode',
                    value: 'idUrrea'
                },
                {
                    text: 'Puesto',
                    value: 'nomPuesto'
                },
                {
                    text: 'Descripción',
                    value: 'descPuesto'
                },
                {
                    text: 'Exp. Requerida',
                    value: 'experiencia'
                },
                {
                    text: 'Salario min.',
                    value: 'salMin'
                },
                {
                    text: 'Salario prom.',
                    value: 'salProm'
                },
                {
                    text: 'Salario max.',
                    value: 'salMax'
                },
                {
                    text: 'Actions',
                    value: 'actions',
                    sortable: false
                },
            ],
            headersMercer: [{
                    text: 'ID',
                    align: 'start',
                    sortable: false,
                    value: 'id',
                },
                {
                    text: 'Mercer',
                    value: 'idMercer'
                },
                {
                    text: 'Jobcode',
                    value: 'idUrrea'
                },
                {
                    text: 'Puesto',
                    value: 'nomPuesto'
                },
                {
                    text: 'Descripción',
                    value: 'descPuesto'
                },
                {
                    text: 'Exp. Requerida',
                    value: 'experiencia'
                },
                {
                    text: 'Salario min.',
                    value: 'salMin'
                },
                {
                    text: 'Salario prom.',
                    value: 'salProm'
                },
                {
                    text: 'Salario max.',
                    value: 'salMax'
                },
                {
                    text: 'Actions',
                    value: 'actions',
                    sortable: false
                },
            ],
            productivity: [],
            mercer: [],
            itemEdit: {
                id: 0,
                nomPuesto: "",
                idProductivity: "",
                idUrrea: "",
                descPuesto: "",
                experiencia: "",
                salMin: "",
                salProm: "",
                salMax: ""
            },

            dialogEdit: false,
            dialogAdd: false,
            loadingProductivity: true,
            loadingMercer: true,
            dialog: false,
            dialogMercer: false,
            loadButton: false,
            searchProductivity: "",
            searchMercer: "",

            idProdEdit: "",
            idMercEdit: "",
            jobcodEdit: "",
            puestoEdit: "",
            desPueEdit: "",
            expReqEdit: "",
            SalMinEdit: "",
            salProEdit: "",
            salMaxEdit: "",

            idProdAdd: "",
            idMercAdd: "",
            jobcodAdd: "",
            puestoAdd: "",
            desPueAdd: "",
            expReqAdd: "",
            SalMinAdd: "",
            salProAdd: "",
            salMaxAdd: "",

            respuestaApi: "",

            tabla: "",

            muestraBarra: false,
            textoBarra: "",
            colorBarra: "blue",

            jobcodeRules: [
                v => !!v || 'Jobcode es requerido',
                v => (v && v.length > 5) || 'Se requieren 5 carácteres.',
                v => (v && v.length < 5) || 'Se requieren 5 carácteres.',
            ],
        }
    },
    computed: {

    },
    mounted() {
        this.getProductivity();
    },
    methods: {

        getProductivity() {
            let params = {
                Opcion: 1,
                token: localStorage.getItem('token')
            }
            axios
                .post(`${this.$store.getters.getHost}/Compensaciones/PerfilesPuestos/ws-perfiles-puestos.php`, params)
                .then((response) => {
                    this.loadingProductivity = false;
                    if (response.data.status == "EXPSESSION") {
                        deleteToken();
                    } else {
                        this.productivity = response.data.perfilesPuestos
                        this.getMercer();
                    }

                })
        },
        getMercer() {
            let params = {
                Opcion: 2,
                token: localStorage.getItem('token')
            }
            axios
                .post(`${this.$store.getters.getHost}/Compensaciones/PerfilesPuestos/ws-perfiles-puestos.php`, params)
                .then((response) => {
                    this.loadingMercer = false;
                    if (response.data.status == "EXPSESSION") {
                        deleteToken();
                    } else {
                        this.mercer = response.data.perfilesPuestos
                    }

                })
        },
        openEdit(item, opc) {
            // console.log(opc)
            this.itemEdit = item;
            if (opc == 1) {
                this.tabla = "productivity";
                this.idProdEdit = item.idProductivity;
            } else {
                this.tabla = "mercer";
                // console.log(this.tabla)
                this.idMercEdit = item.idMercer;
            }
            // console.log(this.itemEdit)
            this.jobcodEdit = item.idUrrea;
            this.puestoEdit = item.nomPuesto;
            this.desPueEdit = item.descPuesto;
            this.expReqEdit = item.experiencia;
            this.SalMinEdit = item.salMin;
            this.salProEdit = item.salProm;
            this.salMaxEdit = item.salMax;
            this.dialogEdit = true;
        },
        cerrarEdit() {
            // this.itemEdit = item;
            this.idMercEdit = "";
            this.idProdEdit = "";
            this.jobcodEdit = "";
            this.puestoEdit = "";
            this.desPueEdit = "";
            this.expReqEdit = "";
            this.SalMinEdit = "";
            this.salProEdit = "";
            this.salMaxEdit = "";
            this.tabla = "";
            this.dialogEdit = false;
        },
        guardarCambios() {
            let params = null
            this.loadButton = true;
            if (this.tabla == 'productivity') {
                params = {
                    Opcion: 3,
                    token: localStorage.getItem('token'),
                    id: this.itemEdit.id,
                    idProductivity: this.idProdEdit,
                    idUrrea: this.jobcodEdit,
                    nomPue: this.puestoEdit,
                    descPue: this.desPueEdit,
                    experiencia: this.expReqEdit,
                    salMin: this.SalMinEdit,
                    salProm: this.salProEdit,
                    salMax: this.salMaxEdit,
                }
            } else {
                params = {
                    Opcion: 4,
                    token: localStorage.getItem('token'),
                    id: this.itemEdit.id,
                    idMercer: this.idMercEdit,
                    idUrrea: this.jobcodEdit,
                    nomPue: this.puestoEdit,
                    descPue: this.desPueEdit,
                    experiencia: this.expReqEdit,
                    salMin: this.SalMinEdit,
                    salProm: this.salProEdit,
                    salMax: this.salMaxEdit,
                }
            }

            axios
                .post(`${this.$store.getters.getHost}/Compensaciones/PerfilesPuestos/ws-perfiles-puestos.php`, params)
                .then((response) => {
                    this.loadingMercer = false;
                    if (response.data.status == "EXPSESSION") {
                        deleteToken();
                    } else {
                        // this.mercer = response.data.perfilesPuestos
                        this.respuestaApi = response.data.perfilesPuestos;

                        this.validaRespuesta();
                    }

                })
        },
        validaRespuesta() {
            this.loadButton = false;
            this.cerrarEdit();
            if (this.respuestaApi == 1) {
                this.colorBarra = 'success';
                this.textoBarra = "Registro modificado con éxito.";
                this.muestraBarra = true;
                this.productivity.length = 0;
                this.mercer.length = 0;
                this.loadingMercer = true;
                this.loadingProductivity = true;
                this.getProductivity();
            } else {
                this.colorBarra = 'error';
                this.textoBarra = "No se pudo modificar el registro.";
                this.muestraBarra = true;
            }
        },

        openAgregar(opc) {
            if (opc == 1) {
                this.tabla = "productivity";
            } else {
                this.tabla = "mercer";
            }
            this.dialogAdd = true;
        },

        cerrarAdd() {
            this.tabla = "";
            this.dialogAdd = false;
        },

        guardarRegistro() {
            if (
                (this.tabla == 'productivity' && this.idProdAdd == "") ||
                (this.tabla == 'mercer' && this.idMercAdd == "") ||
                this.puestoAdd == "" ||
                this.expReqAdd == "" ||
                this.SalMinAdd == "" ||
                this.salProAdd == "" ||
                this.salMaxAdd == ""
            ) {

                this.colorBarra = 'error';
                this.textoBarra = 'falta información por agregar';
                this.muestraBarra = true;

            } else {
                this.loadButton = true;
                let params = null;
                if (this.tabla == 'productivity') {
                    params = {
                        Opcion: 5,
                        token: localStorage.getItem('token'),
                        idProductivity: this.idProdAdd,
                        idUrrea: this.jobcodAdd,
                        nomPue: this.puestoAdd,
                        descPue: this.desPueAdd,
                        experiencia: this.expReqAdd,
                        salMin: this.SalMinAdd,
                        salProm: this.salProAdd,
                        salMax: this.salMaxAdd,
                    }
                } else {
                    params = {
                        Opcion: 6,
                        token: localStorage.getItem('token'),
                        idMercer: this.idMercAdd,
                        idUrrea: this.jobcodAdd,
                        nomPue: this.puestoAdd,
                        descPue: this.desPueAdd,
                        experiencia: this.expReqAdd,
                        salMin: this.SalMinAdd,
                        salProm: this.salProAdd,
                        salMax: this.salMaxAdd,
                    }
                }

                axios
                    .post(`${this.$store.getters.getHost}/Compensaciones/PerfilesPuestos/ws-perfiles-puestos.php`, params)
                    .then((response) => {
                        // this.loadingMercer = false;

                        if (response.data.status == "EXPSESSION") {
                            deleteToken();
                        } else {
                            // this.mercer = response.data.perfilesPuestos
                            this.respuestaApi = response.data.perfilesPuestos;

                            this.validaRespuestaApiAdd();
                        }

                    })
            }
        },
        validaRespuestaApiAdd() {
            this.loadButton = false;
            if (this.respuestaApi == 1) {
                this.cerrarAdd();
                this.colorBarra = 'success';
                this.textoBarra = "Registro agregado con éxito.";
                this.muestraBarra = true;
                this.productivity.length = 0;
                this.mercer.length = 0;
                this.loadingMercer = true;
                this.loadingProductivity = true;
                this.getProductivity();
            } else {
                this.colorBarra = 'error';
                this.textoBarra = "No se pudo agregar el registro.";
                this.muestraBarra = true;
            }
        },
    }
}