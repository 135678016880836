<template>
  <div>
    <v-dialog
      v-model="verDialogAbonosAhorro"
      transition="dialog-bottom-transition"
      content-class="fix-border-dialog"
      max-width="850"
      persistent
      scrollable
    >
      <v-card class="rounded-xl">
        <v-toolbar color="primary" dark>
          <label class="overline font-weight-bold">
            Abonos a ahorro [{{ emplid }}]
          </label>
          <v-spacer></v-spacer>
          <v-icon dark @click="$emit('cierraDialogAbonosAhorro')"
            >mdi-close</v-icon
          >
        </v-toolbar>
        <v-card-text class="pt-3">
          <v-data-table
            dense
            :items="abonos"
            :headers="headers"
            :search="search"
            :footer-props="{
              'items-per-page-text': 'Elementos por página',
              pageText: '{0}-{1} de {2}',
            }"
            :loading="loadingData"
            loading-text="Cargando montos..."
            no-data-text="No hay registros"
          >
            <template v-slot:top>
              <div style="display: flex; justify-content: space-between;">
                <v-text-field
                  v-model="search"
                  dense
                  outlined
                  class="rounded-pill"
                  prepend-inner-icon="mdi-magnify"
                  placeholder="Buscar..."
                ></v-text-field>
                <v-spacer></v-spacer>
                <div>
                  <v-tooltip bottom color="blue darken-2">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        depressed
                        class="rounded-pill mr-2"
                        color="blue darken-2"
                        dark
                        fab
                        small
                        outlined
                        :loading="loadingData"
                        @click="dialogAgregaConcepto = true"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon>mdi-table-plus</v-icon>
                      </v-btn>
                    </template>
                    <span>Añadir concepto</span>
                  </v-tooltip>
                  <vue-json-to-csv
                    :json-data="abonos"
                    :labels="headers_to_export"
                    :csv-title="'ReporteAbonosAhorro_' + emplid"
                  >
                    <v-tooltip bottom color="green darken-2">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          depressed
                          class="rounded-pill mr-2"
                          color="green darken-2"
                          dark
                          fab
                          small
                          outlined
                          :loading="loadingData"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon>mdi-microsoft-excel</v-icon>
                        </v-btn>
                      </template>
                      <span>Exportar CSV</span>
                    </v-tooltip>
                  </vue-json-to-csv>
                </div>
              </div>
            </template>

            <template v-slot:item.SLICE_BGN_DT="{ item }">
              {{ setFormatDate(item.SLICE_BGN_DT) }}
            </template>
            <template v-slot:item.SLICE_END_DT="{ item }">
              {{ setFormatDate(item.SLICE_END_DT) }}
            </template>
            <template v-slot:item.AMOUNT="{ item }">
              <v-btn
                text
                small
                color="blue darken-2"
                class="text-decoration-underline"
                @click="
                  conceptoAux = item;
                  muestraIngresaCantidad = true;
                "
              >
                {{ formatMXN(item.AMOUNT) }}
              </v-btn>
            </template>
            <!-- -->
            <template v-slot:item.actions="{ item }">
              <v-btn
                color="pink darken-2"
                @click="
                  conceptoAux = item;
                  showConfirm = true;
                "
                depressed
                dark
                x-small
                class="rounded-pill"
              >
                remover
                <v-icon right x-small>mdi-minus-circle</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-card-text>

        <v-dialog
          v-model="dialogAgregaConcepto"
          transition="dialog-bottom-transition"
          max-width="500"
          persistent
          content-class="fix-border-dialog"
        >
          <v-card class="rounded-xl">
            <v-toolbar color="primary" class="overline font-weight-bold" dark>
              Registra concepto de ahorro
              <v-spacer></v-spacer>
              <v-icon dark @click="dialogAgregaConcepto = false"
                >mdi-close</v-icon
              >
            </v-toolbar>
            <v-card-text class="pt-5">
              <v-form ref="formInsertConcept">
                <v-row dense>
                  <v-col cols="3">
                    <label class="overline">Periodo</label>
                    <v-text-field
                      v-model="periodoSeleccionado"
                      placeholder="000"
                      dense
                      outlined
                      required
                      :rules="reglasPeriodo"
                      class="rounded-lg"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <label class="overline">Concepto</label>
                    <v-select
                      v-model="conceptoSeleccionado"
                      :items="conseptosCaja"
                      required
                      :rules="reglaInput"
                      placeholder="selecciona el concepto"
                      dense
                      outlined
                      class="rounded-lg"
                    ></v-select>
                  </v-col>
                  <v-col cols="3">
                    <label class="overline">Monto</label>
                    <v-text-field
                      v-model="montoIngresado"
                      dense
                      outlined
                      required
                      :rules="reglaInput"
                      type="number"
                      placeholder="0.00"
                      :prefix="conceptoNegativo"
                      class="rounded-lg"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <label class="overline">Fechas del periodo</label>
                    <v-text-field
                      v-model="dateRangeText"
                      dense
                      outlined
                      placeholder="Selecciona el rango de fechas"
                      class="rounded-lg mt-2"
                      label="Selecciona el rango de fechas"
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      @click="dialogFechas = true"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions class="pa-0">
              <v-btn
                block
                class="rounded-xl rounded-t-0 font-weight-bold"
                x-large
                color="teal darken-2"
                text
                @click="setConceptoAhorro"
              >
                <v-icon left>mdi-content-save</v-icon>
                Guardar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialogFechas" max-width="450px">
          <v-date-picker
            v-model="dates"
            max-width="450px"
            v-on:change="cierraDialog()"
            selected-items-text="Seleccionados"
            range
            elevation="1"
            locale="es-mx"
          ></v-date-picker>
        </v-dialog>

        <SnackBar
          v-if="muestraBarra"
          :muestraBarra="muestraBarra"
          :texto="textoBarra"
          :color="colorBarra"
          @cierraBarra="muestraBarra = false"
        />
      </v-card>

      <IngresaCantidad
        :muestraIngresaCantidad="muestraIngresaCantidad"
        titulo="Ingresa el nuevo monto"
        @retornaCantidad="retornaCantidad"
      />

      <ConfirmDialogReclutamiento
        :showConfirm="showConfirm"
        :question="
          `¿Realmente deseas borrar el concepto 
            ${conceptoAux.PIN_NM}
             del periodo ${conceptoAux.CAL_ID} del empleado ${emplid} por el monto ${conceptoAux.AMOUNT}?
        `
        "
        @cierraConfirm="validateDeletePayment"
      />
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import {
  deleteToken,
  formatDate,
} from "../../../../../../../store/Funciones/funciones";
import IngresaCantidad from "./IngresaCantidad.vue";
import SnackBar from "../../../../../General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue";
import VueJsonToCsv from "vue-json-to-csv";
import ConfirmDialogReclutamiento from "../../../../../AdmnVacantes/components/ConfirmDialogReclutamiento.vue";

export default {
  props: ["verDialogAbonosAhorro", "emplid"],
  components: {
    IngresaCantidad,
    SnackBar,
    VueJsonToCsv,
    ConfirmDialogReclutamiento,
  },
  data() {
    return {
      abonos: [],
      dates: [],
      reglasPeriodo: [
        (v) => !!v || "Campo requerido",
        (v) => /^\d{3}$/.test(v) || "Ingresa un periodo valido, como '024'",
      ],
      reglaInput: [(v) => !!v || "Campo requerido"],
      headers: [
        { value: "EMPLID", text: "emplid", class: "overline" },
        {
          value: "GP_PAYGROUP",
          text: "proceso",
          class: "overline",
          align: "center",
        },
        { value: "CAL_ID", text: "periodo", class: "overline" },
        { value: "SLICE_BGN_DT", text: "inicio", class: "overline" },
        { value: "SLICE_END_DT", text: "fin", class: "overline" },
        {
          value: "PIN_NM",
          text: "concepto",
          class: "overline",
          align: "center",
        },
        { value: "AMOUNT", text: "monto", class: "overline", align: "center" },
        { value: "actions", text: "", class: "overline" },
      ],
      conseptosCaja: ["O83", "D106", "D108", "P124"],
      headers_to_export: {
        EMPLID: { title: "EMPLID" },
        CAL_RUN_ID: { title: "CAL_RUN_ID" },
        EMPL_RCD: { title: "EMPL_RCD" },
        GP_PAYGROUP: { title: "GP_PAYGROUP" },
        CAL_ID: { title: "CAL_ID" },
        ORIG_CAL_RUN_ID: { title: "ORIG_CAL_RUN_ID" },
        RSLT_SEG_NUM: { title: "RSLT_SEG_NUM" },
        CAL_PRD_ID: { title: "CAL_PRD_ID" },
        SLICE_BGN_DT: { title: "SLICE_BGN_DT" },
        SLICE_END_DT: { title: "SLICE_END_DT" },
        PIN_NM: { title: "PIN_NM" },
        AMOUNT: { title: "AMOUNT" },
      },
      conceptoAux: {},
      search: "",
      textoBarra: "",
      colorBarra: "",
      datosPrestamoAux: {},
      muestraBarra: false,
      loadingData: true,
      showConfirm: false,
      muestraIngresaCantidad: false,
      esAbono: false,
      dialogAgregaConcepto: false,
      dialogFechas: false,
      conceptoSeleccionado: null,
      periodoSeleccionado: null,
      montoIngresado: null,
    };
  },
  computed: {
    conceptoNegativo() {
      return ["O83", "D108"].includes(this.conceptoSeleccionado) ? "-$" : "$";
    },
    dateRangeText() {
      let inicio = "";
      let fin = "";
      if (this.dates[0] != null) {
        inicio = formatDate(this.dates[0]);
      }
      if (this.dates[1] != null) {
        fin = formatDate(this.dates[1]);
      }

      if (this.dates.length == 0) {
        return "";
      }
      return "Del " + inicio + " al " + fin; //this.dates.join(' al ')
    },
  },
  async mounted() {
    this.abonos = await this.getAbonosAhorro();
  },
  methods: {
    showSnackBar(texto, color = "error") {
      this.textoBarra = texto;
      this.colorBarra = color;
      this.muestraBarra = true;
    },
    getAbonosAhorro() {
      const headers = {
        Authorization: localStorage.getItem("token"),
      };

      return new Promise((res) => {
        axios
          .get(
            `${this.$store.getters.getHostNode}/api/empleados/caja/ahorro/datos-ahorros/${this.emplid}`,
            {
              headers,
            }
          )
          .then((response) => {
            if (response.data.status == "EXPSESSION") {
              deleteToken();
            } else if (response.data.status == "OK") {
              res(response.data.data);
            } else {
              res([]);
            }
          })
          .finally(() => {
            this.loadingData = false;
          });
      });
    },
    formatMXN(cadena) {
      return cadena.toLocaleString("es-MX", {
        style: "currency",
        currency: "MXN",
      });
    },
    cierraDialog() {
      if (this.dates.length == 2) {
        this.dialogFechas = false;
      }
    },
    setFormatDate(date) {
      let fecha = new Date(date);

      let diaUTC = fecha.getUTCDate();
      let mesUTC = fecha.getUTCMonth() + 1;
      let anioUTC = fecha.getUTCFullYear();

      diaUTC = diaUTC < 10 ? "0" + diaUTC : diaUTC;
      mesUTC = mesUTC < 10 ? "0" + mesUTC : mesUTC;

      return diaUTC + "/" + mesUTC + "/" + anioUTC;
    },
    async retornaCantidad(respuesta) {
      this.muestraIngresaCantidad = false;
      if (respuesta !== null) {
        const response = await this.putConceptoAhorro(respuesta);
        if (response > 0) {
          this.showSnackBar(
            "Éxito. Se modificó el concepto seleccionado.",
            "success"
          );
          this.loadingData = true;
          this.abonos = await this.getAbonosAhorro();
        } else {
          this.showSnackBar(
            "Error. No se pudo editar el concepto, intente más tarde."
          );
        }
      }
    },
    setConceptoAhorro() {
      if (this.$refs.formInsertConcept.validate()) {
        const tipo = ["10", "9", "8", "1"].includes(
          this.$store.getters.getUser.GrupoPago
        )
          ? "semana"
          : "quincena";
        const params = {
          emplid: this.emplid,
          periodo: this.periodoSeleccionado.substring(1, 3),
          start_date: this.dates[0],
          end_date: this.dates[1],
          concepto: this.conceptoSeleccionado,
          monto: ["O83", "D108"].includes(this.conceptoSeleccionado)
            ? this.montoIngresado * -1
            : this.montoIngresado,
          tipo: tipo,
          usrRegistra: this.$store.getters.getUser.Folio,
        };

        const headers = {
          Authorization: localStorage.getItem("token"),
        };

        axios
          .post(
            `${this.$store.getters.getHostNode}/api/empleados/caja/ahorro/datos-ahorros-historico`,
            params,
            {
              headers,
            }
          )
          .then(async (response) => {
            if (response.data.status == "EXPSESSION") {
              deleteToken();
            } else if (response.data.status == "OK") {
              this.showSnackBar("Insertado con éxito.", "success");
              this.dialogAgregaConcepto = false;
              this.loadingData = true;
              this.abonos = await this.getAbonosAhorro();
            } else {
              this.showSnackBar(
                "Error. No se pudo agregar el nuevo concepto, intente más tarde."
              );
            }
          });
      } else {
        alert("error");
      }
    },
    putConceptoAhorro(nCantidad) {
      const params = {
        montoAnt: this.conceptoAux.AMOUNT,
        monto: nCantidad,
        userMod: this.$store.getters.getUser.Folio,
        periodo: this.conceptoAux.CAL_ID,
        concepto: this.conceptoAux.PIN_NM,
        type: 3,
      };

      const headers = {
        Authorization: localStorage.getItem("token"),
      };

      return new Promise((res) => {
        axios
          .put(
            `${this.$store.getters.getHostNode}/api/empleados/caja/ahorro/datos-ahorros-historico/${this.emplid}`,
            params,
            {
              headers,
            }
          )
          .then(async (response) => {
            if (response.data.status == "EXPSESSION") {
              deleteToken();
            } else if (response.data.status == "OK") {
              res(response.data.data);
            } else {
              res(response.data.data);
            }
          });
      });
    },
    async validateDeletePayment(confirmacion) {
      this.showConfirm = false;
      if (confirmacion) {
        const response = await this.deleteConceptoAhorro();
        if (response > 0) {
          this.showSnackBar(
            "Éxito. Se eliminó el concepto seleccionado.",
            "success"
          );
          this.loadingData = true;
          this.abonos = await this.getAbonosAhorro();
        } else {
          this.showSnackBar(
            "Error. No se pudo eliminar el concepto, intente más tarde."
          );
        }
      }
    },
    deleteConceptoAhorro() {
      const params = {
        emplidRegistra: this.$store.getters.getUser.Folio,
        emplid: this.emplid,
        periodo: this.conceptoAux.CAL_ID,
        montoActual: this.conceptoAux.AMOUNT,
        concepto: this.conceptoAux.PIN_NM,
      };
      const headers = {
        Authorization: localStorage.getItem("token"),
      };

      return new Promise((res) => {
        axios
          .delete(
            `${this.$store.getters.getHostNode}/api/empleados/caja/ahorro/datos-ahorros-historico/`,
            {
              data: params,
              headers: headers,
            }
          )
          .then((response) => {
            if (response.data.status == "EXPSESSION") {
              deleteToken();
            } else if (response.data.status == "OK") {
              res(response.data.data);
            } else {
              res(0);
            }
          });
      });
    },
  },
};
</script>
