var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","xl":"10","lg":"10","md":"10","sm":"12","xs":"12"}},[_c('v-text-field',{staticClass:"mb-5",attrs:{"append-icon":"mdi-magnify","label":"Busca por nombre o número de empleado","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{staticClass:"d-flex justify-center align-center",attrs:{"cols":"12","xl":"2","lg":"2","md":"2","sm":"12","xs":"12"}},[(_vm.Demandacolaboradores.datos)?_c('vue-json-to-csv',{attrs:{"json-data":_vm.Demandacolaboradores.datos,"labels":_vm.headers_report_to_export,"csv-title":_vm.nameFile}},[_c('v-btn',{staticClass:"mb-1 font-weight-bold rounded-lg",attrs:{"color":"green darken-2","dark":"","small":""},on:{"click":_vm.fixName}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v("mdi-microsoft-excel")]),_vm._v(" "+_vm._s(_vm.$t("DH360.export"))+" ")],1)],1):_vm._e()],1)],1),_c('v-data-table',{attrs:{"dense":"","loading":_vm.loadingContent,"headers":_vm.headers,"items":_vm.Demandacolaboradores.datos,"sort-by-text":"Ordenar por","no-data-text":"No hay registros.","items-per-page-all-text":"Todos","items-per-page-text":"Elementos por página","show-current-page":false,"show-first-last-page":false,"search":_vm.search,"loading-text":"Cargando contenido...","footer-props":{
      'items-per-page-options': [5, 10, 15, 20, 25],
      'items-per-page-text': 'Elementos por página',
    }},scopedSlots:_vm._u([{key:"item.NAME",fn:function(ref){
    var item = ref.item;
return [_c('div',{attrs:{"align":"left"}},[_vm._v(" "+_vm._s(item.NAME)+" ")])]}},{key:"item.totalDocs",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.totalDocs)+" / "+_vm._s(_vm.Demandacolaboradores.maxDocs)+" ")]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":"","color":"cyan lighten-4"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"cyan darken-4"},on:{"click":function($event){return _vm.openAdmFiles(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-text-box-multiple-outline ")])]}}],null,true)},[_c('label',{staticClass:"text-uppercase font-weight-bold primary--text"},[_vm._v("Consultar documentación")])]),_c('v-tooltip',{attrs:{"top":"","color":"blue lighten-4"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary"},on:{"click":function($event){_vm.idEmpleadoAux = item.EMPLID;
              _vm.muestraDialogNominas = true;}}},'v-icon',attrs,false),on),[_vm._v(" mdi-download-multiple ")])]}}],null,true)},[_c('label',{staticClass:"text-uppercase font-weight-bold primary--text"},[_vm._v("Descargar nominas")])]),(_vm.muestraDialogNominas)?_c('descargaMasivaDeNominas',{attrs:{"activador":_vm.muestraDialogNominas,"emplid":_vm.idEmpleadoAux},on:{"cierraDialog":function($event){_vm.muestraDialogNominas = false}}}):_vm._e()]}}],null,true)}),(_vm.muestraCargaArchivos)?_c('CargaArchivos',{attrs:{"dialogCargaArchivos":_vm.muestraCargaArchivos,"emplid":_vm.emplid,"estatusColaborador":3,"esAdmin":"1"},on:{"cierraVentana":function($event){return _vm.reloadInformation()}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }