var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"transition":"dialog-top-transition","max-width":"600","scrollable":""},model:{value:(_vm.activador),callback:function ($$v) {_vm.activador=$$v},expression:"activador"}},[_c('v-card',{staticClass:"rounded-lg"},[_c('v-toolbar',{attrs:{"color":"primary","dark":""}},[_c('label',{staticClass:"overline font-weight-bold"},[_vm._v("Selecciona las fechas")]),_c('v-spacer'),_c('v-icon',{on:{"click":function($event){return _vm.$emit('cierraDialog')}}},[_vm._v("mdi-close")])],1),_c('v-card-text',{staticClass:"pt-5"},[_c('v-row',[_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.name === 'xs' ? 12 : 6}},[_c('v-dialog',{ref:"dialogInicio",attrs:{"return-value":_vm.fechaInicio,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){_vm.fechaInicio=$event},"update:return-value":function($event){_vm.fechaInicio=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"rounded-lg",attrs:{"label":"Fecha de inicio","dense":"","outlined":"","prepend-inner-icon":"mdi-calendar","readonly":"","loading":_vm.loadingFechaIngreso},model:{value:(_vm.fechaInicio),callback:function ($$v) {_vm.fechaInicio=$$v},expression:"fechaInicio"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.fechaInicioModal),callback:function ($$v) {_vm.fechaInicioModal=$$v},expression:"fechaInicioModal"}},[_c('v-date-picker',{staticClass:"rounded-xl",attrs:{"min":_vm.fechaIngreso,"max":_vm.fechaActual,"scrollable":""},on:{"input":function($event){_vm.$refs.dialogInicio.save(_vm.fechaInicio);
                  _vm.fechaInicioModal = false;}},model:{value:(_vm.fechaInicio),callback:function ($$v) {_vm.fechaInicio=$$v},expression:"fechaInicio"}})],1)],1),_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.name === 'xs' ? 12 : 6}},[_c('v-dialog',{ref:"dialogFin",attrs:{"return-value":_vm.fechaFin,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){_vm.fechaFin=$event},"update:return-value":function($event){_vm.fechaFin=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"rounded-lg",attrs:{"label":"Fecha fin","dense":"","outlined":"","prepend-inner-icon":"mdi-calendar","readonly":""},model:{value:(_vm.fechaFin),callback:function ($$v) {_vm.fechaFin=$$v},expression:"fechaFin"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.fechaFinModal),callback:function ($$v) {_vm.fechaFinModal=$$v},expression:"fechaFinModal"}},[_c('v-date-picker',{staticClass:"rounded-xl",attrs:{"min":_vm.fechaInicio,"max":_vm.fechaActual,"scrollable":""},on:{"input":function($event){_vm.$refs.dialogFin.save(_vm.fechaFin);
                  _vm.fechaFinModal = false;}},model:{value:(_vm.fechaFin),callback:function ($$v) {_vm.fechaFin=$$v},expression:"fechaFin"}})],1)],1)],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.muestraErrores),expression:"muestraErrores"}]},[_c('label',{staticClass:"overline font-weight-bold"},[_vm._v("Se presentaron los siguientes errores en descarga: ")]),_vm._l((_vm.erroresEnDescarga),function(item,index){return _c('div',{key:index},[_c('label',{staticClass:"font-weight-thin"},[_vm._v(_vm._s(item))]),_c('br')])})],2)],1),_c('v-divider'),_c('v-card-actions',{staticClass:"justify-center"},[_c('v-btn',{attrs:{"large":"","depressed":"","color":"teal darken-1","loading":_vm.loadingBtn,"dark":""},on:{"click":_vm.getZip}},[_vm._v(" Obtener ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }