<template>
  <v-sheet
    color="white"
    class="rounded-xl pa-5"
    style="display: flex; justify-content: space-between; align-items: center;"
  >
    <label class="subtitle-1 text-uppercase primary--text font-weight-bold"
      >reportes generales</label
    >
    <div>
      <vue-json-to-csv
        :json-data="prestamos"
        :labels="headers_report_to_export_prestamos"
        csv-title="Reporte de préstamos"
      >
        <v-btn
          color="blue darken-2"
          outlined
          class="ml-2 mb-1 rounded-lg"
          depressed
          :loading="loadingPrestamos"
        >
          <v-icon left>mdi-microsoft-excel</v-icon>
          préstamos
        </v-btn>
      </vue-json-to-csv>
      <vue-json-to-csv
        :json-data="saldos"
        :labels="headers_report_to_export_saldos"
        csv-title="Reporte de saldos"
      >
        <v-btn
          color="blue darken-2"
          outlined
          class="ml-2 mb-1 rounded-lg"
          depressed
          :loading="loadingSaldos"
        >
          <v-icon left>mdi-microsoft-excel</v-icon>
          saldos
        </v-btn>
      </vue-json-to-csv>
    </div>
  </v-sheet>
</template>

<script>
import axios from "axios";
import { deleteToken } from "../../../../../../store/Funciones/funciones";
import VueJsonToCsv from "vue-json-to-csv";

export default {
  name: "genera-reportes-caja",
  components: { VueJsonToCsv },
  data() {
    return {
      prestamos: [],
      saldos: [],
      headers_report_to_export_prestamos: {
        id: { title: "id" },
        emplid: { title: "emplid" },
        monto: { title: "monto" },
        abonado: { title: "abonado" },
        restante: { title: "restante" },
        interes: { title: "interes" },
        fecha_inicio: { title: "fecha_inicio" },
        fecha_registro: { title: "fecha_registro" },
        usuario_mod_id: { title: "usuario_mod_id" },
        abono_normal: { title: "abono_normal" },
      },
      headers_report_to_export_saldos: {
        emplid: { value: "emplid" },
        id_prestamo: { value: "id_prestamo" },
        prestado: { value: "prestado" },
        abonado: { value: "abonado" },
        ahorro: { value: "ahorro" },
      },
      loadingPrestamos: true,
      loadingSaldos: true,
    };
  },
  async mounted() {
    this.prestamos = await this.getPrestamos();
    this.saldos = await this.getSaldos();
  },
  methods: {
    getPrestamos() {
      const headers = {
        Authorization: localStorage.getItem("token"),
      };

      return new Promise((res) => {
        axios
          .get(
            `${this.$store.getters.getHostNode}/api/empleados/caja/reportes/prestamos`,
            {
              headers,
            }
          )
          .then((response) => {
            if (response.data.status == "EXPSESSION") {
              deleteToken();
            } else {
              res(response.data.data);
            }
          })
          .finally(() => {
            this.loadingPrestamos = false;
          });
      });
    },
    getSaldos() {
      const headers = {
        Authorization: localStorage.getItem("token"),
      };

      return new Promise((res) => {
        axios
          .get(
            `${this.$store.getters.getHostNode}/api/empleados/caja/reportes/saldos`,
            {
              headers,
            }
          )
          .then((response) => {
            if (response.data.status == "EXPSESSION") {
              deleteToken();
            } else {
              res(response.data.data);
            }
          })
          .finally(() => {
            this.loadingSaldos = false;
          });
      });
    },
  },
};
</script>
