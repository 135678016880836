<template>
  <v-card
    class="rounded-lg elevation-0"
    height="100%"
    width="100%"
    color="blue-grey lighten-5"
  >
    <v-card-title class="py-1 center-item-in-div primary">
      <label class="overline font-weight-bold white--text"
        >empleado - responsable</label
      >
    </v-card-title>
    <v-data-table
      dense
      style="background-color: transparent;"
      :items-per-page="13"
      :headers="headers"
      :search="search"
      :items="relacionEmpleadoResponsable"
      :loading="loadingData"
      no-data-text="No se han registrado relaciones."
      :footer-props="{
        'items-per-page-text': 'Elementos por página',
        pageText: '{0}-{1} de {2}',
      }"
      loading-text="Cargando responsables..."
    >
      <template v-slot:top>
        <div
          style="display: flex; justify-content: space-between;"
          class="px-3 py-2"
        >
          <v-text-field
            v-model="search"
            dense
            outlined
            class="rounded-lg"
            placeholder="Buscar..."
            prepend-inner-icon="mdi-magnify"
            style="max-width: 300px;"
          ></v-text-field>
          <div>
            <vue-json-to-csv
              :json-data="report_to_export"
              :labels="headers_report_to_export"
              csv-title="reporteEmpleadoResponsable"
            >
              <v-btn color="green darken-3" dark depressed class="rounded-lg" :loading="loadingReport">
                <v-icon left>mdi-microsoft-excel</v-icon>
                exportar
              </v-btn>
            </vue-json-to-csv>
          </div>
        </div>
      </template>
      <template v-slot:item.EMP_STATUS="{ item }">
        <v-chip
          :color="
            item.EMP_STATUS === 'A'
              ? 'teal darken-1'
              : item.EMP_STATUS === null
              ? 'grey'
              : 'pink darken-3'
          "
          small
          dark
        >
          {{
            item.EMP_STATUS === "A"
              ? "Activo"
              : item.EMP_STATUS === null
              ? "Desconocido"
              : "Inactivo"
          }}
        </v-chip>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn
          small
          class="rounded-pill"
          depressed
          color="primary"
          @click="
            responsable = item.Responsable;
            verEmpleados = true;
          "
          dark
        >
          ver personal a cargo
        </v-btn>
      </template>
    </v-data-table>

    <VerEmpleadosPorResponsable
      v-if="verEmpleados"
      :verEmpleados="verEmpleados"
      :idEmpleado="responsable"
      @cierraVerSubordinados="updateData"
    />
  </v-card>
</template>

<script>
import axios from "axios";
import VerEmpleadosPorResponsable from "./VerEmpleadosPorResponsable.vue";
import VueJsonToCsv from "vue-json-to-csv";

export default {
  name: "ver-relacion-empleado-responsable",
  components: { VerEmpleadosPorResponsable, VueJsonToCsv },
  data() {
    return {
      relacionEmpleadoResponsable: [],
      headers: [
        {
          value: "Responsable",
          text: "ID",
          class: "text-uppercase font-weight-bold",
        },
        {
          value: "EMP_NOMEMP",
          text: "Nombre",
          class: "text-uppercase font-weight-bold",
        },
        {
          value: "EMP_STATUS",
          align: "center",
          text: "Estatus",
          class: "text-uppercase font-weight-bold",
        },
        {
          value: "subordinados",
          align: "center",
          text: "Personal",
          class: "text-uppercase font-weight-bold",
        },
        {
          value: "actions",
          text: "Acciones",
          align: "center",
          class: "text-uppercase font-weight-bold",
        },
      ],
      headers_report_to_export: {
        numResponsable: { title: "Núm. Responsable" },
        nombreResponsable: { title: "Responsable" },
        numEmpleado: { title: "Núm. Empleado" },
        nombreEmpleado: { title: "Empleado" },
      },
      report_to_export: [],
      verEmpleados: false,
      loadingData: true,
      loadingReport: true,
      responsable: -1,
      search: "",
    };
  },
  async mounted() {
    this.relacionEmpleadoResponsable = await this.getRelacionEmpleadoResponsable();
    this.report_to_export = await this.getReporteEmpleadoResponable();
  },
  methods: {
    async getRelacionEmpleadoResponsable() {
      const params = {
        Opcion: 10,
        token: localStorage.getItem("token"),
      };
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${this.$store.getters.getHost}/RelacionesLaborales/RelacionEmpleadoResponsable/ws-empleado-responsable.php`,
            params
          )
          .then((response) => {
            if (response.data.status == "OK") {
              resolve(response.data.respuesta);
            }
          })
          .catch((error) => {
            reject(error);
          })
          .finally(() => {
            this.loadingData = false;
          });
      });
    },
    async getReporteEmpleadoResponable() {
      const params = {
        Opcion: 18,
        token: localStorage.getItem("token"),
      };
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${this.$store.getters.getHost}/RelacionesLaborales/RelacionEmpleadoResponsable/ws-empleado-responsable.php`,
            params
          )
          .then((response) => {
            if (response.data.status == "OK") {
              resolve(response.data.respuesta);
            }
          })
          .catch((error) => {
            reject(error);
          })
          .finally(() => {
            this.loadingReport = false;
          });
      });
    },
    async updateData(respuesta) {
      if (respuesta === 0) {
        this.verEmpleados = false;
      } else if (respuesta >= 1) {
        this.$emit("updateBitacora", 1);
        this.verEmpleados = false;
        this.relacionEmpleadoResponsable.length = 0;
        this.relacionEmpleadoResponsable = await this.getRelacionEmpleadoResponsable();
      }
    },
  },
};
</script>
