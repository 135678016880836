<template>
  <v-card elevation="2">
    <v-card-text class="mt-5">
      <v-row>
        <v-col cols="12" xs="12" sm="12" md="7" lg="9" xl="9">
          <v-data-table
            dense
            elevation="1"
            :headers="headers"
            :search="search"
            :items="encuestas"
            no-data-text="No hay encuestas creadas para esta evaluación"
            loading-text="Cargando encuestas..."
            :loading="loadingData"
          >
            <template v-slot:item.NAME="{ item }">
              <div align="left">{{ item.NAME }}</div>
            </template>
            <template v-slot:item.tipo="{ item }">
              <div align="left">{{ item.tipo }}</div>
            </template>
            <template v-slot:item.Puntos="{ item }">
              <v-btn
                x-small
                rounded
                elevation="1"
                color="teal lighten-2"
                dark
                v-if="item.estatus == 1"
              >
                {{ item.Puntos }}
              </v-btn>
              <v-btn
                x-small
                rounded
                elevation="1"
                color="red lighten-2"
                dark
                v-else
              >
                {{ item.Puntos }}
              </v-btn>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-tooltip left color="cyan lighten-4">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="cyan darken-4"
                    v-bind="attrs"
                    v-on="on"
                    @click="openDialogDelete(item)"
                    v-if="item.estatus == 0"
                  >
                    mdi-account-minus
                  </v-icon>
                </template>
                <label class="text-uppercase font-weight-bold cyan--text"
                  >Remover encuesta</label
                >
              </v-tooltip>

              <v-tooltip top color="pink lighten-4">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="pink darken-4"
                    v-bind="attrs"
                    v-on="on"
                    @click="openDialogChange(item)"
                    v-if="item.estatus == 0"
                  >
                    mdi-account-convert
                  </v-icon>
                </template>
                <label class="text-uppercase font-weight-bold pink--text"
                  >Cambiar de colaborador</label
                >
              </v-tooltip>

              <v-tooltip right color="blue lighten-4">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="blue darken-4"
                    v-bind="attrs"
                    v-on="on"
                    @click="notificarPorCorreo(item)"
                    v-if="item.estatus == 0 && !loadingSend"
                  >
                    mdi-email-fast-outline
                  </v-icon>
                  <v-icon
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                    v-else-if="item.estatus == 0 && loadingSend"
                  >
                    mdi-email-sync-outline
                  </v-icon>
                </template>
                <label class="text-uppercase font-weight-bold blue--text"
                  >Enviar correo</label
                >
              </v-tooltip>
            </template>
          </v-data-table>
        </v-col>
        <v-col
          cols="12"
          xs="12"
          sm="12"
          md="5"
          lg="3"
          xl="3"
          class="d-flex align-center"
          v-if="completos < total && loadingData == false"
        >
          <v-alert
            icon="mdi-alert-outline"
            prominent
            color="grey lighten-1"
            dark
            dense
            elevation="1"
          >
            La encuesta aun no ha finalizado
            <br />
            Encuestas finalizadas {{ completos }} / {{ total }}
          </v-alert>
        </v-col>
        <v-col
          cols="12"
          xs="12"
          sm="12"
          md="5"
          lg="3"
          xl="3"
          v-else-if="completos == total && loadingData == false"
        >
          <v-card>
            <v-card-title class="text-subtitle-2 primary white--text">
              Encuestas finalizadas {{ completos }} / {{ total }}
              <v-spacer></v-spacer>
            </v-card-title>
            <v-card-text class="mt-5" v-if="infoEvaluado.estatus > 3">
              <b>Resultados: </b>{{ infoEvaluado.calificacion }} / 100 <br />
              <div class="d-flex align-center">
                <b>Nivel: </b>
                <v-slider
                  class="ml-5"
                  v-model="infoEvaluado.nivel"
                  step="1"
                  max="6"
                  min="1"
                  disabled
                  :thumb-size="24"
                  thumb-label="always"
                  ticks="always"
                  tick-size="4"
                  :tick-labels="ticksLabels"
                ></v-slider>
              </div>
              <v-divider></v-divider>
              <div class="text-justify">
                <b>Autoevaluación:</b> {{ infoEvaluado.auto_calificacion }} /
                100<br />
              </div>
              <div class="d-flex align-center">
                <b>Nivel: </b>
                <v-slider
                  class="ml-5"
                  v-model="infoEvaluado.auto_nivel"
                  step="1"
                  max="6"
                  min="1"
                  disabled
                  :thumb-size="24"
                  thumb-label="always"
                  ticks="always"
                  tick-size="4"
                  :tick-labels="ticksLabels"
                ></v-slider>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        v-if="
          completos == total &&
          loadingData == false &&
          infoEvaluado.estatus == 4
        "
        class="rounded-lg elevation-0"
        color="teal darken-1"
        dark
        :loading="loading"
        @click="notificar()"
      >
        <v-icon left>mdi-check-circle-outline</v-icon> Notificar y terminar
        proceso
      </v-btn>
      <v-btn
        v-else-if="
          completos == total &&
          loadingData == false &&
          infoEvaluado.estatus == 3
        "
        class="rounded-lg elevation-0"
        color="teal darken-1"
        dark
        :loading="loading"
        @click="calcular()"
      >
        <v-icon left>mdi-check-circle-outline</v-icon> Calcular calificaciones
      </v-btn>
    </v-card-actions>
    <v-dialog v-model="dialogDelete" max-width="600px" persistent>
      <v-card>
        <v-card-title class="text-subtitle-2 primary white--text">
          Remover encuesta
          <v-spacer></v-spacer>
          <v-icon small dark @click="dialogDelete = false"
            >mdi mdi-close
          </v-icon>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                class="pb-0"
                cols="12"
                xl="12"
                lg="12"
                md="12"
                sm="12"
                xs="12"
              >
                ¿Estas seguro de remover la encuesta de de
                <b>{{ item.evaluador }} {{ item.NAME }}</b
                >? <br />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="rounded-lg elevation-0"
            color="teal darken-1"
            dark
            :loading="loading"
            @click="removerEncuesta()"
          >
            <v-icon left>mdi-account-minus</v-icon> Remover
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <selecciona-colaborador
      v-if="dialogChange"
      :dialog="dialogChange"
      :idEvaluacion="idEvaluacion"
      :infoEncuesta="item"
      :fuente="fuente"
      @cierraDialogSeleccion="closeDialogChange"
    ></selecciona-colaborador>
    <SnackBar
      v-if="muestraBarra"
      :muestraBarra="muestraBarra"
      :texto="textoBarra"
      :color="colorBarra"
      @cierraBarra="muestraBarra = false"
    />
  </v-card>
</template>



<script>
import axios from "axios";
import { deleteToken } from "../../../../../../store/Funciones/funciones";
import SnackBar from "../../../../General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue";
import seleccionaColaborador from "./seleccionaColaborador.vue";
export default {
  name: "lista-encuestas",
  components: { SnackBar, seleccionaColaborador },
  props: ["idEvaluacion", "infoEvaluado"],
  data: () => ({
    textoBarra: "",
    colorBarra: "",
    muestraBarra: false,

    headers: [
      { text: "Emplid", value: "evaluador", align: "center" },
      { text: "Evaluador", value: "NAME", align: "center" },
      { text: "Tipo encuesta", value: "tipo", align: "center" },
      { text: "Puntos", value: "Puntos", align: "center" },
      { text: "Estatus", value: "estadoActual", align: "center" },
      { text: "Acciones", value: "actions", align: "center" },
    ],
    encuestas: [],
    search: "",
    item: "",
    total: 0,
    completos: 0,
    loadingData: true,
    loading: false,
    dialogDelete: false,
    dialogChange: false,
    loadingSend: false,
    ticksLabels: [1, 2, 3, 4, 5, 6],
    fuente: "cambiaEncuestador",
  }),
  created() {},
  mounted() {
    this.getEncuestas();
  },

  methods: {
    getEncuestas() {
      let params = {
        Opcion: 9,
        idEvaluacion: this.idEvaluacion,
        token: localStorage.getItem("token"),
      };
      axios
        .post(
          `${this.$store.getters.getHost}/General/PersonalTemporal/ws-personal-temporal.php`,
          params
        )
        .then((response) => {
          this.loadingData = false;
          if (response.data.status == "OK") {
            this.encuestas = response.data.result["encuestas"];
            this.total = response.data.result["total"];
            this.completos = response.data.result["completados"];
          } else if (response.data.status == "VACIO") {
            console.log("Vacio");
          } else if (response.data.status == "EXPRESSION") {
            deleteToken();
          }
        });
    },

    removerEncuesta() {
      let params = {
        Opcion: 10,
        item: this.item,
        token: localStorage.getItem("token"),
      };
      axios
        .post(
          `${this.$store.getters.getHost}/General/PersonalTemporal/ws-personal-temporal.php`,
          params
        )
        .then((response) => {
          this.loadingData = false;
          if (response.data.status == "OK") {
            this.textoBarra = "Encuesta removida correctamente";
            this.colorBarra = "success";
            this.muestraBarra = true;
            this.clean();
            this.getEncuestas();
            return new Promise((resolve) => {
              setTimeout(() => {
                if (this.total == this.completos) {
                  resolve(
                    this.$emit("cerrarEvaluacion", { estatus: "HabilitarBtn" })
                  );
                }
              }, 2000);
            });
          } else if (response.data.status == "ERROR") {
            this.textoBarra = "Ocurrió un error";
            this.colorBarra = "Error";
            this.muestraBarra = true;
          } else if (response.data.status == "EXPRESSION") {
            deleteToken();
          }
        });
    },

    notificar() {
      let params = {
        Opcion: 12,
        idEvaluacion: this.idEvaluacion,
        token: localStorage.getItem("token"),
      };
      this.loading = true;
      axios
        .post(
          `${this.$store.getters.getHost}/General/PersonalTemporal/ws-personal-temporal.php`,
          params
        )
        .then((response) => {
          this.loading = false;
          if (response.data.status == "OK") {
            this.$emit("cerrarEvaluacion", { estatus: "OK" });
          } else if (response.data.status == "ERROR") {
            this.textoBarra = "Ocurrió un error";
            this.colorBarra = "Error";
            this.muestraBarra = true;
          } else if (response.data.status == "EXPRESSION") {
            deleteToken();
          }
        });
    },

    calcular() {
      let params = {
        Opcion: 11,
        idEvaluacion: this.idEvaluacion,
        token: localStorage.getItem("token"),
      };
      this.loading = true;
      axios
        .post(
          `${this.$store.getters.getHost}/General/PersonalTemporal/ws-personal-temporal.php`,
          params
        )
        .then((response) => {
          this.loading = false;
          if (response.data.status == "OK") {
            this.$emit("cerrarEvaluacion", { estatus: "calculados" });
          } else if (response.data.status == "ERROR") {
            this.textoBarra = "Ocurrió un error";
            this.colorBarra = "Error";
            this.muestraBarra = true;
          } else if (response.data.status == "EXPRESSION") {
            deleteToken();
          }
        });
    },

    clean() {
      this.item = [];
      this.dialogDelete = false;
      this.loadingData = true;
      this.encuestas = [];
      this.total = 0;
      this.completos = 0;
      this.completos = 0;
    },

    openDialogDelete(item) {
      this.item = item;
      this.dialogDelete = true;
    },

    openDialogChange(item) {
      this.item = item;
      this.dialogChange = true;
    },

    closeDialogChange(item) {
      if (item.estatus == "OK") {
        this.textoBarra = "Se ha cambiado una encuesta a un nuevo colaborador";
        this.colorBarra = "success";
        this.muestraBarra = true;
        this.clean();
        this.getEncuestas();
      }
      //this.item = item;
      this.dialogChange = false;
    },

    notificarPorCorreo(item) {
      let params = {
        Opcion: 18,
        item: item,
        token: localStorage.getItem("token"),
      };
      this.loadingSend = true;
      axios
        .post(
          `${this.$store.getters.getHost}/General/PersonalTemporal/ws-personal-temporal.php`,
          params
        )
        .then((response) => {
          this.loadingSend = false;
          if (response.data.status == "OK") {
            this.textoBarra = "Correo enviado, notificación realizada";
            this.colorBarra = "success";
            this.muestraBarra = true;
          } else if (response.data.status == "SINCORREO") {
            this.textoBarra = "Colaborador no cuenta con un correo registrado";
            this.colorBarra = "error";
            this.muestraBarra = true;
          } else if (response.data.status == "ERROR") {
            this.textoBarra = "Ocurrió un error";
            this.colorBarra = "error";
            this.muestraBarra = true;
          } else if (response.data.status == "EXPRESSION") {
            deleteToken();
          }
        });
    },
  },
};
</script>