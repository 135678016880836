import ListaMapaDeTalento from "./Components/ListaMapaDeTalento.vue";
export default {
  name: 'mapa-de-talento',
  components: {
    ListaMapaDeTalento
  },
  props: [],
  data () {
    return {

    }
  },
  computed: {

  },
  mounted () {

  },
  methods: {

  }
}


