<template>
  <div>
    <v-dialog
      v-model="showSendPsychometrics"
      max-width="550"
      persistent
      content-class="fix-border-dialog"
    >
      <v-card class="rounded-xl">
        <v-card-title class="primary white--text subtitle-1">
          Envia psicometrías
          <v-spacer></v-spacer>
          <v-icon
            dark
            small
            @click="$emit('hideSendPsychometrics', null, null)"
          >
            mdi-close
          </v-icon>
        </v-card-title>
        <v-card-text>
          <p class="my-3">
            Ingresa el enlace para realizar las psicometrias o selecciona una
            opcion predefinida
          </p>
          <v-form ref="formSendPhysicometrics" v-model="valid">
            <v-select
              v-model="TypeSelected"
              class="rounded-lg"
              outlined
              dense
              :rules="rules"
              required
              placeholder="Selecciona el tipo de psicometrías."
              v-on:change="getLinkPsico()"
              :items="psicometriasTipos"
              item-text="desc"
            >
            </v-select>
            <v-text-field
              v-model="linkPsicometrias"
              class="rounded-lg"
              outlined
              dense
              :rules="rules"
              required
            ></v-text-field>
          </v-form>
          <label
            >Un correo será enviado de manera automatica al candidato.</label
          >
        </v-card-text>
        <v-card-actions class="center-item-in-div">
          <v-btn
            small
            depressed
            class="rounded-lg font-weight-bold"
            dark
            color="cyan darken-2"
            :loading="sendingMail"
            @click="
              sendingMail = true;
              sendEmail();
            "
          >
            enviar
            <v-icon right small>mdi-send</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <SnackBar
      :muestraBarra="muestraBarra"
      :color="colorBarra"
      :texto="textoBarra"
      @cierraBarra="muestraBarra = false"
    />
  </div>
</template>

<script>
import axios from "axios";
import { deleteToken } from "../../../../../store/Funciones/funciones";
import SnackBar from "../../../General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue";

export default {
  name: "envia-psicometrias",
  props: ["showSendPsychometrics", "candidato"],
  components: { SnackBar },
  data() {
    return {
      psicometriasTipos: [
        { value: 1, desc: "Gerente" },
        { value: 2, desc: "Administrativas" },
        { value: 3, desc: "Gn" },
        { value: 4, desc: "Gerente de ventas" },
        { value: 5, desc: "Líderes" },
      ],
      rules: [(v) => !!v || "Este campo es obligatorio"],
      linkPsicometrias: "",
      TypeSelected: "",
      colorBarra: "",
      textoBarra: "",
      muestraBarra: false,
      sendingMail: false,
      valid: true,
    };
  },
  methods: {
    muestraSnackBar(text, color) {
      this.colorBarra = color;
      this.textoBarra = text;
      this.muestraBarra = true;
    },
    getLinkPsico() {
      switch (this.TypeSelected) {
        case 1:
          this.linkPsicometrias =
            "https://psicotest.mx/ligapsicometria/192105_23055";
          break;
        case 2:
          this.linkPsicometrias =
            "https://psicotest.mx/ligapsicometria/190958_23055";
          break;
        case 3:
          this.linkPsicometrias =
            "https://psicotest.mx/ligapsicometria/131809_23055";
          break;
        case 4:
          this.linkPsicometrias =
            "https://psicotest.mx/ligapsicometria/190955_23055";
          break;
        case 5:
          this.linkPsicometrias =
            "https://psicotest.mx/ligapsicometria/185693_23055";
          break;
        default:
          this.linkPsicometrias = "";
          break;
      }
    },
    sendEmail() {
      if (this.$refs.formSendPhysicometrics.validate()) {
        const params = {
          Opcion: 7,
          token: localStorage.getItem("token"),
          idCandidato: this.candidato.id,
          url: this.linkPsicometrias,
          mail: this.candidato.Mail,
          nombre: this.candidato.nombre,
          vacante: this.candidato.vacante,
          estatus: this.candidato.statusId,
        };
        axios
          .post(
            `${this.$store.getters.getHost}/Reclutamiento/ws-admin-candidatos.php`,
            params
          )
          .then((response) => {
            this.sendingMail = false;
            if (response.data.status != "EXPSESSION") {
              if (response.data.candidato === 1) {
                this.muestraSnackBar("Correo enviado", "primary");
                this.$emit("hideSendPsychometrics", this.candidato, 2);
              } else {
                this.muestraSnackBar("Correo no enviado", "pink darken-2");
              }
            } else {
              deleteToken();
            }
          })
          .catch((e) => {
            this.muestraSnackBar(e, "pink darken-2");
          });
      } else {
        this.sendingMail = false;
      }
    },
  },
};
</script>
