<template>
    <Radar
      :chart-options="chartOptions"
      :chart-data="chartData"
      :chart-id="chartId"
      :dataset-id-key="datasetIdKey"
      :plugins="plugins"
      :css-classes="cssClasses"
      :styles="styles"
      :width="width"
      :height="height"
    />
  </template>
  
  <script>
  import { Radar } from "vue-chartjs/legacy";
  
  import {
    Chart as ChartJS,
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend
  } from 'chart.js'
  
  ChartJS.register(
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend
  )
  export default {
    name: "RadarChart",
    components: {
      Radar,
    },
    props: {
      etiquetas: {
        type: Array,
        required: true
      },
      valores: {
        type: Array,
        required: true
      },
      chartId: {
        type: String,
        default: "radar-chart",
      },
      datasetIdKey: {
        type: String,
        default: "label",
      },
      width: {
        type: Number,
        default: 100,
      },
      height: {
        type: Number,
        default: 500,
      },
      cssClasses: {
        default: "",
        type: String,
      },
      styles: {
        type: Object,
        default: () => {},
      },
      plugins: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
        chartData: {
          labels: this.etiquetas,
          datasets: [
            {
              label: "Evaluación actual",
              data: this.valores,
              fill: true,
              backgroundColor: "rgba(0,120,195, 0.2)",
              borderColor: "rgb(0,120,195)",
              pointBackgroundColor: "rgb(0,120,195)",
              pointBorderColor: "#fff",
              pointHoverBackgroundColor: "#fff",
              pointHoverBorderColor: "rgb(0,120,195)",
            },
          ],
        },
        chartOptions: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            r: {
              suggestedMin: 0,
              suggestedMax: 100
            }
          }
        },
      };
    },
  
    mounted() {
    },
  
    methods: {
    },
  };
  </script>
  