<template>
  <v-card class="rounded-xl elevation-0 pa-3">
    <div style="display: flex; justify-content: space-between; ">
      <div class="ml-2 ">
        <label class="overline deep-purple--text text--lighten-3"
          >selecciona el día de pre-nómina</label
        >
        <v-dialog
          ref="dialogFechaPrenomina"
          v-model="modalFechaPrenom"
          :return-value.sync="fechaSelected"
          width="290px"
          content-class="fix-border-dialog"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="fechaSelected"
              label="Día de prenomina"
              prepend-inner-icon="mdi-calendar"
              style="max-width: 350px"
              placeholder="Selecciona la fecha de prenomina..."
              v-on:change="
                loadingRepGeneral = true;
                updateReporte();
              "
              required
              dense
              outlined
              class="rounded-pill rounded-tl-lg"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="fechaSelected"
            class="rounded-xl"
            scrollable
            @input="
              $refs.dialogFechaPrenomina.save(fechaSelected);
              modalFechaVisita = false;
              loadingRepGeneral = true;
              updateReporte();
            "
          >
          </v-date-picker>
        </v-dialog>
      </div>
      <div>
        <vue-json-to-csv
          :json-data="report_to_export"
          :labels="headers_report_to_export"
          :csv-title="'reporte-general-' + fechaInicial"
        >
          <v-btn color="green darken-4" class="rounded-xl mr-1" depressed dark :loading="loadingBtnExportReport">
            <v-icon left> mdi-microsoft-excel </v-icon>
            Exportar
          </v-btn>
        </vue-json-to-csv>
        <v-tooltip left color="blue lighten-4">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              small
              fab
              depressed
              class="ml-2"
              v-bind="attrs"
              v-on="on"
              @click="
                loadingRepGeneral = true;
                updateReporte();
              "
            >
              <v-icon>mdi-reload</v-icon>
            </v-btn>
          </template>
          <label class="text-uppercase font-weight-bold primary--text"
            >Actualizar tabla</label
          >
        </v-tooltip>
      </div>
    </div>
    <v-data-table
      dense
      :headers="headersRepGeneral"
      :items="reporteGenearlMovimientos"
      :loading="loadingRepGeneral"
      :search="search"
      :single-expand="true"
      :expanded.sync="expanded"
      item-key="ID"
      show-expand
    >
      <template v-slot:top>
        <div style="display: flex; justify-content: end;">
          <v-text-field
            v-model="search"
            outlined
            dense
            class="rounded-xl"
            style="max-width: 350px;"
            prepend-inner-icon="mdi-table-search"
            placeholder="Buscar en tabla..."
          ></v-text-field>
        </div>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length" class="px-5 py-5 blue-grey lighten-5">
          <v-card
            class="rounded-xl elevation-0 pa-3"
            color="cyan lighten-5"
            style="border: 2.3px solid #78909C !important;"
          >
            <v-row dense>
              <v-col :cols="!smallScreen ? 3 : 12">
                <label
                  class="overline blue-grey--text text--darken-1 font-weight-bold"
                  >Fecha de registro</label
                >
                <div class="body-2">
                  {{ fixFormatDate(item.FECHA_ACTUAL.date.substring(0, 10)) }}
                </div>
              </v-col>
              <v-col :cols="!smallScreen ? 3 : 12">
                <label
                  class="overline blue-grey--text text--darken-1 font-weight-bold"
                  >Fecha de pre-nómina</label
                >
                <div class="body-2">
                  {{ fixFormatDate(item.FECHA_PRENOM.date.substring(0, 10)) }}
                </div>
              </v-col>
              <v-col :cols="!smallScreen ? 3 : 12">
                <label
                  class="overline blue-grey--text text--darken-1 font-weight-bold"
                  >Fecha de nómina</label
                >
                <div class="body-2">
                  {{ fixFormatDate(item.FECHA_NOM.date.substring(0, 10)) }}
                </div>
              </v-col>
              <v-col :cols="!smallScreen ? 3 : 12">
                <label
                  class="overline blue-grey--text text--darken-1 font-weight-bold"
                  >Hora de registro</label
                >
                <div class="body-2">
                  {{ item.FECHA_DETAL.date.substring(11, 19) }}
                </div>
              </v-col>
              <v-col :cols="!smallScreen ? 3 : 12">
                <label
                  class="overline blue-grey--text text--darken-1 font-weight-bold"
                  >Proceso</label
                >
                <div class="body-2">{{ item.GRUPO_PAGO }}</div>
              </v-col>
              <v-col
                :cols="!smallScreen ? 3 : 12"
                v-show="item.RAZON_MOVIMIENTO == 27"
              >
                <label
                  class="overline blue-grey--text text--darken-1 font-weight-bold"
                  >Descripción</label
                >
                <div class="body-2">{{ item.DESCRIPCION }}</div>
              </v-col>
              <v-col
                :cols="!smallScreen ? 3 : 12"
                v-show="item.RAZON_MOVIMIENTO == 27"
              >
                <label
                  class="overline blue-grey--text text--darken-1 font-weight-bold"
                  >Periodo de inicio</label
                >
                <div class="body-2" v-if="item.FECHA_INICIO_PERIODO !== null">
                  {{
                    fixFormatDate(
                      item.FECHA_INICIO_PERIODO.date.substring(0, 10)
                    )
                  }}
                </div>
              </v-col>
              <v-col
                :cols="!smallScreen ? 3 : 12"
                v-show="item.RAZON_MOVIMIENTO == 27"
              >
                <label
                  class="overline blue-grey--text text--darken-1 font-weight-bold"
                  >Plazo</label
                >
                <div class="body-2">{{ item.PLAZO }} semanas</div>
              </v-col>
              <v-col
                :cols="!smallScreen ? 3 : 12"
                v-show="item.RAZON_MOVIMIENTO == 27"
              >
                <label
                  class="overline blue-grey--text text--darken-1 font-weight-bold"
                  >Importe / abono</label
                >
                <div class="body-2">
                  {{ formatMXN(parseFloat(item.IMPORTE)) }}
                </div>
              </v-col>
              <v-col
                :cols="!smallScreen ? 3 : 12"
                v-show="item.RAZON_MOVIMIENTO == 27"
              >
                <label
                  class="overline blue-grey--text text--darken-1 font-weight-bold"
                  >Intéres actual</label
                >
                <div class="body-2" v-if="item.INTERES_ACTUAL !== null">
                  {{ item.INTERES_ACTUAL.toFixed(2) }}
                </div>
              </v-col>
            </v-row>
            <v-card-actions class="pa-0">
              <v-spacer></v-spacer>
              <v-btn
                small
                depressed
                class="rounded-pill"
                color="cyan darken-3"
                dark
                :loading="loadingDataEmployee"
                @click="
                  loadingDataEmployee = true;
                  getDataEmployee(item.EMPLID);
                "
              >
                <v-icon left> mdi-account-details-outline </v-icon>
                Obtener información
              </v-btn>
            </v-card-actions>
          </v-card>
          <!-- {{ item }} -->
        </td>
      </template>
      <template v-slot:item.MOV_CANCELADO="{ item }">
        <v-chip
          v-if="item.MOV_CANCELADO == 0"
          x-small
          color="teal darken-1"
          dark
        >
          Activo
        </v-chip>
        <v-chip v-else x-small color="pink darken-3" dark> Cancelado </v-chip>
      </template>
      <template v-slot:item.CANTIDAD="{ item }">
        {{ formatMXN(parseFloat(item.CANTIDAD)) }}
      </template>
    </v-data-table>

    <v-dialog
      v-model="showDataEmployee"
      max-width="650"
      content-class="fix-border-dialog"
    >
      <v-card class="rounded-xl">
        <v-card-title class="py-2 pr-3">
          <div class="subtitle-1">{{ dataEmployee.name }}</div>
          <v-spacer></v-spacer>
          <v-icon color="pink darken-3" @click="showDataEmployee = false">
            mdi-close
          </v-icon>
        </v-card-title>
        <v-card-text v-if="dataEmployee">
          <!-- {{ dataEmployee }} -->
          <v-row dense>
            <v-col cols="4">
              <div class="overline">Ahorro nóminal</div>
              <div class="body-1">
                {{
                  dataEmployee.monto > 0 && dataEmployee.monto !== null
                    ? formatMXN(dataEmployee.monto)
                    : formatMXN(0)
                }}
              </div>
            </v-col>
            <v-col cols="4">
              <div class="overline">Saldo en caja</div>
              <div class="body-1">
                {{
                  dataEmployee.total > 0 && dataEmployee.total !== null
                    ? formatMXN(dataEmployee.total)
                    : formatMXN(0)
                }}
              </div>
            </v-col>
            <v-col cols="4">
              <div class="overline">Parte social</div>
              <div class="body-1">
                {{
                  dataEmployee.pS > 0 && dataEmployee.pS !== null
                    ? formatMXN(dataEmployee.pS)
                    : formatMXN(0)
                }}
              </div>
            </v-col>
            <v-col cols="6">
              <div class="overline">Adeudo</div>
              <div class="body-1">
                {{
                  dataEmployee.restante > 0 && dataEmployee.restante !== null
                    ? formatMXN(dataEmployee.restante)
                    : formatMXN(0)
                }}
              </div>
            </v-col>
            <v-col cols="6">
              <div class="overline">Monto máximo a prestar</div>
              <div class="body-1">
                {{
                  dataEmployee.cantidadPrestamo > 0 &&
                  dataEmployee.cantidadPrestamo !== null
                    ? formatMXN(dataEmployee.cantidadPrestamo)
                    : formatMXN(0)
                }}
              </div>
            </v-col>
            <!-- <v-col cols="6">
              <div class="overline">Sueldo Diario Integrado</div>
              <div class="body-1">{{ formatMXN(dataEmployee.SDI) }}</div>
            </v-col> -->
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { formatDate } from "../../../../../../store/Funciones/funciones.js";
import VueJsonToCsv from "vue-json-to-csv";
import axios from "axios";
export default {
  name: "ofertas-economicas-pendientes",
  props: ["estatus"],
  components: {VueJsonToCsv},
  data() {
    return {
      fechasPrenomina: [],
      reporteGenearlMovimientos: [],
      expanded: [],
      headersRepGeneral: [
        { value: "ID", text: "ID", align: "left" },
        { value: "EMPLID", text: "EMPLID", align: "left" },
        { value: "CONCEPTO", text: "CONCEPTO", align: "left" },
        { value: "NOMBRE", text: "NOMBRE", align: "left" },
        { value: "TIPO_MOVIMIENTO", text: "MOVIMIENTO", align: "left" },
        { value: "MOV_CANCELADO", text: "ESTATUS", align: "center" },
        { value: "CANTIDAD", text: "MONTO", align: "center" },
      ],
      report_to_export: [],
      headers_report_to_export: {
        ID: { title: "ID" },
        EMPLID: { title: "EMPLID" },
        CONCEPTO: { title: "CONCEPTO" },
        NOMBRE: { title: "NOMBRE" },
        FECHA_ACTUAL: { title: "FECHA_ACTUAL" },
        FECHA_PRENOM: { title: "FECHA_PRENOM" },
        FECHA_NOM: { title: "FECHA_NOM" },
        GRUPO_PAGO: { title: "GRUPO_PAGO" },
        CANTIDAD: { title: "CANTIDAD" },
        TIPO_MOVIMIENTO: { title: "TIPO_MOVIMIENTO" },
        RAZON_MOVIMIENTO: { title: "RAZON_MOVIMIENTO" },
        DESCRIPCION: { title: "DESCRIPCION" },
        FECHA_INICIO_PERIODO: { title: "FECHA_INICIO_PERIODO" },
        PLAZO: { title: "PLAZO" },
        IMPORTE: { title: "IMPORTE" },
        PERIODO_SIGUIENTE: { title: "PERIODO_SIGUIENTE" },
        IMPORTE_PRESTAMO: { title: "IMPORTE_PRESTAMO" },
        INTERES_ACTUAL: { title: "INTERES_ACTUAL" },
        MOV_CANCELADO: { title: "MOV_CANCELADO" },
        FECHA_DETAL: { title: "FECHA_DETALLE" },
      },
      dataEmployee: {},
      loadingDates: true,
      loadingRepGeneral: true,
      modalFechaPrenom: false,
      showDataEmployee: false,
      loadingDataEmployee: false,
      loadingBtnExportReport: false,
      fechaInicial: "",
      search: "",
      fechaSelected: "",
    };
  },
  computed: {
    smallScreen() {
      return ["xs", "sm"].includes(this.$vuetify.breakpoint.name)
        ? true
        : false;
    },
  },
  async mounted() {
    this.fechasPrenomina = await this.getFechasPrenomina();
    this.fechaInicial = this.fechasPrenomina[0].FCH_NOM.date.substring(0, 10);
    this.reporteGenearlMovimientos = await this.getReporteGeneral(
      this.fechaInicial
    );
  },
  methods: {
    async getFechasPrenomina() {
      let params = {
        Opcion: 2,
        token: localStorage.getItem("token"),
      };
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${this.$store.getters.getHost}/Nominas/AdmCajaAhorro/ws-reportes.php`,
            params
          )
          .then((response) => {
            if (response.data.status == "OK") {
              resolve(response.data.fechas);
            }
          })
          .catch((error) => {
            reject({
              error: error,
            });
          })
          .finally(() => {
            this.loadingDates = false;
          });
      });
    },
    async getReporteGeneral(fecha) {
      let params = {
        Opcion: 1,
        token: localStorage.getItem("token"),
        fechaPrenomina: fecha,
      };
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${this.$store.getters.getHost}/Nominas/AdmCajaAhorro/ws-reportes.php`,
            params
          )
          .then((response) => {
            if (response.data.status == "OK") {
              resolve(response.data.reporte);
            }
          })
          .catch((error) => {
            reject({
              error: error,
            });
          })
          .finally(() => {
            this.loadingRepGeneral = false;
            this.loadingBtnExportReport = true;
            this.getReportToExport();
          });
      });
    },
    formatItemText(item) {
      return this.formatDatePrenom(item);
    },
    formatDatePrenom(item) {
      return `${formatDate(item.FCH_NOM.date.substring(0, 10))}`;
    },
    fixFormatDate(date) {
      return formatDate(date);
    },
    formatMXN(cadena) {
      return cadena.toLocaleString("es-MX", {
        style: "currency",
        currency: "MXN",
      });
    },
    async updateReporte() {
      this.reporteGenearlMovimientos = await this.getReporteGeneral(
        this.fechaSelected
      );
    },
    getDataEmployee(empleado) {
      let params = {
        Opcion: 1,
        token: localStorage.getItem("token"),
        empleado: empleado,
      };
      axios
        .post(
          `${this.$store.getters.getHost}/Nominas/AdmCajaAhorro/ws-caja-alu.php`,
          params
        )
        .then((response) => {
          if (response.data.status == "OK") {
            this.dataEmployee = response.data.caja;
            this.showDataEmployee = true;
          }
        })
        .catch((error) => {
          alert({
            error: error,
          });
        })
        .finally(() => {
          this.loadingDataEmployee = false;
        });
    },
    getReportToExport() {
      if (this.reporteGenearlMovimientos.length) {
        this.report_to_export.length = 0;
        this.reporteGenearlMovimientos.map((r) => {
          this.report_to_export.push({
            ID: r.ID,
            EMPLID: r.EMPLID,
            CONCEPTO: r.CONCEPTO,
            NOMBRE: r.NOMBRE,
            FECHA_ACTUAL: ![null, undefined, ''].includes(r.FECHA_ACTUAL) ? r.FECHA_ACTUAL.date.substring(0,10) : '',
            FECHA_PRENOM: ![null, undefined, ''].includes(r.FECHA_PRENOM) ? r.FECHA_PRENOM.date.substring(0,10) : '',
            FECHA_NOM: ![null, undefined, ''].includes(r.FECHA_NOM) ? r.FECHA_NOM.date.substring(0,10) : '',
            GRUPO_PAGO: r.GRUPO_PAGO,
            CANTIDAD: r.CANTIDAD,
            TIPO_MOVIMIENTO: r.TIPO_MOVIMIENTO,
            RAZON_MOVIMIENTO: r.RAZON_MOVIMIENTO,
            DESCRIPCION: r.DESCRIPCION === null ? '' : r.DESCRIPCION,
            FECHA_INICIO_PERIODO: r.FECHA_INICIO_PERIODO === null ? '' : r.FECHA_INICIO_PERIODO,
            PLAZO: r.PLAZO === null ? '' : r.PLAZO,
            IMPORTE: r.IMPORTE === null ? '' : r.IMPORTE,
            PERIODO_SIGUIENTE: r.PERIODO_SIGUIENTE === null ? '' : r.PERIODO_SIGUIENTE,
            IMPORTE_PRESTAMO: r.IMPORTE_PRESTAMO === null ? '' : r.IMPORTE_PRESTAMO,
            INTERES_ACTUAL: r.INTERES_ACTUAL === null ? '' : r.INTERES_ACTUAL,
            MOV_CANCELADO: r.MOV_CANCELADO,
            FECHA_DETAL: ![null, undefined, ''].includes(r.FECHA_DETAL) ? r.FECHA_DETAL.date.substring(0,10) + ' ' + r.FECHA_DETAL.date.substring(11,16) : '',
          });
        });
        this.loadingBtnExportReport = false;
      } else {
        this.loadingBtnExportReport = false;
      }
    },
  },
};
</script>
