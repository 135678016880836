<template>
  <v-container>
    <v-row>
      <v-col cols="12" xl="12" lg="12" md="12" sm="12" xs="12">
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          class="mb-5"
          label="Buscar"
          single-line
          hide-details
        ></v-text-field>
      </v-col>
    </v-row>
    <v-spacer></v-spacer>
    <v-data-table
      dense
      :loading="loadingContent"
      :headers="headers"
      :items="upas"
      sort-by-text="Ordenar por"
      no-data-text="No hay registros."
      items-per-page-all-text="Todos"
      items-per-page-text="Elementos por página"
      :show-current-page="false"
      :show-first-last-page="false"
      :search="search"
      loading-text="Cargando contenido..."
      :footer-props="{
        'items-per-page-options': [5, 10, 15, 20, 25],
        'items-per-page-text': 'Elementos por página',
      }"
      :single-expand="singleExpand"
      :expanded.sync="expanded"
      item-key="id"
      show-expand
      class="elevation-1"
    >
      <template v-slot:item.Descripcion="{ item }">
        <div align="left">{{ item.Descripcion }}</div>
      </template>
      <template v-slot:item.actions="{ item }">
        <div class="d-flex justify-center">
          <v-tooltip top color="teal lighten-2">
            <template v-slot:activator="{ on, attrs }">
              <v-chip-group column>
                <v-btn
                  x-small
                  rounded
                  elevation="1"
                  color="teal darken-1"
                  dark
                  @click="editItem(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon x-small>mdi mdi-plus</v-icon>
                </v-btn>
              </v-chip-group>
            </template>
            <span>Añadir</span>
          </v-tooltip>
          <v-tooltip top color="red accent-1">
            <template v-slot:activator="{ on, attrs }">
              <v-chip-group column>
                <v-btn
                  x-small
                  rounded
                  elevation="1"
                  color="red darken-1"
                  dark
                  v-bind="attrs"
                  v-on="on"
                  @click="confirmarDelete(item, 'masivo')"
                >
                  <v-icon x-small>mdi mdi-delete</v-icon>
                </v-btn>
              </v-chip-group>
            </template>
            <span>Remover</span>
          </v-tooltip>
        </div>
      </template>

      <template v-slot:item.habilitado="{ item }">
        <v-chip
          x-small
          dark
          :color="item.habilitado === 1 ? 'green' : 'pink darken-1'"
        >
          {{ item.habilitado === 1 ? "Habilitado" : "Inhabilitado" }}
        </v-chip>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length" class="px-10 py-5">
          <!-- {{headers}} -->
          <!-- <v-col v-for="i in item" :key="i.id" class="d-flex justify-center align-center">
            <v-card color="blue accent-3"> hola</v-card> hola
          </v-col> -->
          <v-row v-if="item.certificaciones">
            <v-col
              cols="12"
              xl="3"
              lg="3"
              md="3"
              sm="12"
              xs="12"
              v-for="i in item.certificaciones"
              :key="i.id"
              class="d-flex justify-center align-center"
            >
              <v-card
                class="rounded-xl pa-5 card-effect d-flex justify-center align-center"
                width="200"
                max-height="60"
                color="blue-grey lighten-4"
                elevation="2"
              >
                <p class="text-center font-weight-bold">
                  {{ i.nombre }}
                </p>
                <v-btn
                  @click="confirmarDelete(i, 'individual')"
                  color="pink"
                  fab
                  dark
                  x-small
                  absolute
                  top
                  right
                >
                  <v-icon>mdi-minus</v-icon>
                </v-btn>
              </v-card>
            </v-col>
          </v-row>
          <v-row v-else class="pa-0">
            <v-col cols="12" color="blue-grey lighten-4 pa-0">
              <v-card>
                <v-card-text
                  class="d-flex justify-center subtitle-1 font-italic"
                  >Sin certificaciones asignadas</v-card-text
                >
              </v-card>
            </v-col>
          </v-row>
          <!-- {{ item.certificaciones }} -->
        </td>
      </template>
    </v-data-table>
    <v-dialog v-model="dialogConfirm" persistent width="400">
      <v-card>
        <v-card-title class="text-subtitle-2 primary white--text">
          Remover certificaciones
          <v-spacer></v-spacer>
          <v-icon small dark @click="close()">mdi-close</v-icon>
        </v-card-title>
        <v-card-text> ¿Esta seguro de remover esta certificacion? </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="rounded-lg elevation-0"
            color="teal darken-1"
            dark
            :loading="loading"
            @click="borraItem()"
          >
            <v-icon left>mdi-check-circle-outline</v-icon> Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <FormRelacionUpaCertificacion
      v-if="dialogForm == true"
      :dialogForm="dialogForm"
      :upa="editedItem"
      @cerrarTicket="
        dialogForm = false;
        getUpasXCertificaciones();
      "
    />

    <SnackBarError
      v-if="muestraBarra"
      :muestraBarra="muestraBarra"
      :texto="textoBarra"
      :color="colorBarra"
      @cierraBarra="muestraBarra = false"
    />
  </v-container>
</template>

<script>
import axios from "axios";
import { deleteToken } from "../../../../../store/Funciones/funciones";
// import VueJsonToCsv from "vue-json-to-csv";
//   import ModificaTiposDocumentos from "./ModificaTiposDocumentos.vue";
import SnackBarError from "../../../General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue";
import FormRelacionUpaCertificacion from "./FormRelacionUpaCertificacion.vue";
export default {
  name: "admon-relacion-UPA-Certificaciones",
  components: { FormRelacionUpaCertificacion, SnackBarError },
  props: [],
  data() {
    return {
      textoBarra: "",
      colorBarra: "",
      muestraBarra: false,

      upas: [],
      search: "",
      loadingContent: true,
      loading: false,
      expanded: [],
      singleExpand: true,
      editedIndex: -1,
      headers: [
        { text: "ID", value: "id", align: "center", sortable: true },
        { text: "UPA", value: "Descripcion", align: "center", sortable: true },
        {
          text: "Estatus",
          value: "habilitado",
          align: "center",
          sortable: true,
        },
        { text: "Acciones", value: "actions", align: "center", sortable: true },
        { text: "", value: "data-table-expand" },
      ],
      defaultItem: {
        id: "",
        nombre: "",
        estatus: "",
        DEPTID: "",
        nivel: "",
        // estatus: true,
      },
      editedItem: {
        id: "",
        nombre: "",
        estatus: "",
        DEPTID: "",
        nivel: "",
        // estatus: true,
      },
      dialogForm: false,
      dialogConfirm: false,
      idCertificacion: "",
      idUpa: "",
      tipo: "",
    };
  },
  computed: {},
  mounted() {
    this.getUpasXCertificaciones();
  },
  methods: {
    getUpasXCertificaciones() {
      this.upas = [];
      let params = {
        Opcion: 1,
        token: localStorage.getItem("token"),
      };
      this.loadingContent = true;
      axios
        .post(
          `${this.$store.getters.getHost}/SeguridadMedioAmbiental/UPA/ws-admonUpas.php`,
          params
        )
        .then((response) => {
          if (response.data.status == "OK") {
            this.upas = response.data.upas;
          } else if (response.data.status == "VACIO") {
            this.textoBarra = "No se encontraron registros.";
            this.colorBarra = "info";
            this.muestraBarra = true;
          } else if (response.data.status == "EXPRESSION") {
            deleteToken();
          }
        })
        .catch(function (e) {
          alert("Ocurrió un error: " + e);
        });
      this.loadingContent = false;
    },

    editItem(item) {
      this.editedIndex = this.upas.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogForm = true;
    },

    confirmarDelete(item, tipo) {
      this.dialogConfirm = true;
      this.tipo = tipo;
      if (tipo == "masivo") {
        this.idUpa = item.id;
      } else {
        this.idCertificacion = item.id;
      }
    },
    borraItem() {
      let params = {
        Opcion: 7,
        tipo: this.tipo,
        idUpa: this.idUpa,
        idCertificacion: this.idCertificacion,
        token: localStorage.getItem("token"),
      };
      axios
        .post(
          `${this.$store.getters.getHost}/SeguridadMedioAmbiental/UPA/ws-admonUpas.php`,
          params
        )
        .then((response) => {
          this.loading = false;
          if (response.data.status == "OK") {
            this.textoBarra = "Certificación removida correctamente.";
            this.colorBarra = "success";
            this.muestraBarra = true;
            this.close();
            this.getUpasXCertificaciones();
          } else if (response.data.status == "ERROR") {
            this.textoBarra = "Algo salió mal.";
            this.colorBarra = "error";
            this.muestraBarra = true;
          } else if (response.data.status == "EXPRESSION") {
            deleteToken();
          }
          this.loading = false;
        })
        .catch(function (e) {
          alert("Ocurrió un error: " + e);
        });
      this.loadingContent = false;
    },

    close() {
      this.dialogForm = false;
      this.dialogConfirm = false;
      this.idCertificacion = "";
      this.idUpa = "";
      this.tipo = "";
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
  },
};
</script>