<template>
  <v-dialog v-model="activador" transition="dialog-top-transition" max-width="600" scrollable>
    <!-- <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="primary"
        depressed
        :large="!isAdmin"
        :x-small="isAdmin"
        :class="!isAdmin ? 'rounded-lg' : 'rounded-pill'"
        v-bind="attrs"
        v-on="on"
      >
        <v-icon :left="!isAdmin" :small="isAdmin">mdi-download-multiple</v-icon>
        {{!isAdmin ? 'Descarga masiva' : ''}}
      </v-btn>
    </template> -->
    <!-- <template v-slot:default="dialog"> -->
      <v-card class="rounded-lg">
        <v-toolbar color="primary" dark>
          <label class="overline font-weight-bold">Selecciona las fechas</label>
          <v-spacer></v-spacer>
          <v-icon @click="$emit('cierraDialog')">mdi-close</v-icon>
        </v-toolbar>
        <v-card-text class="pt-5">
          <v-row>
            <v-col :cols="$vuetify.breakpoint.name === 'xs' ? 12 : 6">
              <v-dialog
                ref="dialogInicio"
                v-model="fechaInicioModal"
                :return-value.sync="fechaInicio"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="fechaInicio"
                    label="Fecha de inicio"
                    dense
                    outlined
                    class="rounded-lg"
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    :loading="loadingFechaIngreso"
                  ></v-text-field>
                </template>
                <v-date-picker
                  class="rounded-xl"
                  v-model="fechaInicio"
                  :min="fechaIngreso"
                  :max="fechaActual"
                  scrollable
                  @input="
                    $refs.dialogInicio.save(fechaInicio);
                    fechaInicioModal = false;
                  "
                >
                </v-date-picker>
              </v-dialog>
            </v-col>
            <v-col :cols="$vuetify.breakpoint.name === 'xs' ? 12 : 6">
              <v-dialog
                ref="dialogFin"
                v-model="fechaFinModal"
                :return-value.sync="fechaFin"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="fechaFin"
                    label="Fecha fin"
                    dense
                    outlined
                    class="rounded-lg"
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  class="rounded-xl"
                  v-model="fechaFin"
                  :min="fechaInicio"
                  :max="fechaActual"
                  scrollable
                  @input="
                    $refs.dialogFin.save(fechaFin);
                    fechaFinModal = false;
                  "
                >
                </v-date-picker>
              </v-dialog>
            </v-col>
          </v-row>
          <div v-show="muestraErrores">
            <label class="overline font-weight-bold"
              >Se presentaron los siguientes errores en descarga:
            </label>
            <div v-for="(item, index) in erroresEnDescarga" :key="index">
              <label class="font-weight-thin">{{ item }}</label>
              <br />
            </div>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="justify-center">
          <v-btn
            large
            depressed
            color="teal darken-1"
            :loading="loadingBtn"
            dark
            @click="getZip"
          >
            Obtener
          </v-btn>
        </v-card-actions>
      </v-card>
    <!-- </template> -->
  </v-dialog>
</template>

<script>
import axios from "axios";
import JSZip from "jszip";
import FileSaver from "file-saver";
import { deleteToken } from "../../../../../../store/Funciones/funciones";
export default {
  name: "desacarga-masiva-de-nominas",
  props: ["activador","emplid"],
  data() {
    return {
      fechaInicio: "",
      fechaFin: "",
      fechaActual: "",
      fechaIngreso: "",
      fechaInicioModal: false,
      fechaFinModal: false,
      loadingFechaIngreso: true,
      loadingBtn: false,
      muestraErrores: false,
      idEmpleado: -1,
      datosNominas: [],
      erroresEnDescarga: [],
    };
  },
  async mounted() {
    this.idEmpleado = [null, undefined, 0].includes(this.emplid) ? this.$store.getters.getUser['Folio'] : this.emplid;
    this.fechaInicio = await this.getFechaIngreso();
    this.fechaIngreso = this.fechaInicio;
    this.fechaActual = this.getFechaActual();
    this.fechaFin = this.fechaActual;
  },
  methods: {
    getFechaIngreso() {
      let paramsEstatusInc = {
        Opcion: 3,
        token: localStorage.getItem("token"),
        emplid: this.idEmpleado,
      };
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${this.$store.getters.getHost}/Compensaciones/ws-mi-nomina-timbrada.php`,
            paramsEstatusInc
          )
          .then((response) => {
            if (response.data.status == "EXPSESSION") {
              deleteToken();
            } else if (response.data.status == "OK") {
              resolve(response.data.respuesta.date.substring(0, 10));
            } else {
              resolve("");
            }
          })
          .catch(function(e) {
            reject(e);
          })
          .finally(() => {
            this.loadingFechaIngreso = false;
          });
      });
    },
    getFechaActual() {
      const fecha = new Date();

      const anio = fecha.getFullYear();
      let mes = fecha.getMonth() + 1;
      let dia = fecha.getDate();
      mes = mes < 10 ? `0${mes}` : mes;
      dia = dia < 10 ? `0${dia}` : dia;

      const fechaFormateada = `${anio}-${mes}-${dia}`;

      return fechaFormateada;
    },
    getNominasCFDIs() {
      let paramsEstatusInc = {
        Opcion: 2,
        token: localStorage.getItem("token"),
        inicio: this.fechaInicio,
        fin: this.fechaFin,
        emplid: this.idEmpleado,
      };
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${this.$store.getters.getHost}/Compensaciones/ws-mi-nomina-timbrada.php`,
            paramsEstatusInc
          )
          .then((response) => {
            if (response.data.status == "EXPSESSION") {
              deleteToken();
            } else if (response.data.status == "OK") {
              resolve(response.data.respuesta);
            } else {
              resolve("");
            }
          })
          .catch(function(e) {
            reject(e);
          });
      });
    },
    async getZip() {
      this.loadingBtn = true;
      this.datosNominas = await this.getNominasCFDIs();
      if (this.datosNominas) {
        if (this.datosNominas.length > 0) {
          this.downloadPDFsAsZip(this.datosNominas);
        } else {
          alert("No se encontraron documentos");
        }
      } else {
        alert("No se encontraron documentos");
      }
    },
    downloadPDFsAsZip(pdfFiles) {
      const zip = new JSZip();
      const promises = [];
      pdfFiles.forEach((pdfUrl) => {
        const promise = axios
          .get(pdfUrl.archivo_pdf, { responseType: "blob" })
          .then((response) => {
            zip.file(
              pdfUrl.archivo_pdf.substring(
                pdfUrl.archivo_pdf.lastIndexOf("/") + 1
              ),
              response.data,
              { binary: true }
            );
          })
          .catch(() => {
            this.erroresEnDescarga.push(
              `Error al descargar el PDF desde ${pdfUrl.archivo_pdf}`
            );
          });

        promises.push(promise);
      });

      // Esperar a que todas las descargas se completen antes de generar el zip
      Promise.all(promises)

        .then(() => {
          zip.generateAsync({ type: "blob" }).then((content) => {
            FileSaver.saveAs(
              content,
              "nominasTimbradas" +
                pdfFiles.length +
                "_" +
                this.idEmpleado +
                ".zip"
            );
          });
          this.loadingBtn = false;
          if (this.erroresEnDescarga) {
            if (this.erroresEnDescarga.length > 0) {
              this.muestraErrores = true;
            }
          }
        })
        .catch((error) => {
          console.error("Error al descargar uno o más PDFs", error);
          this.loadingBtn = false;
        });
    },
  },
};
</script>
