<template>
  <v-dialog
    v-model="verDataTable"
    scrollable
    persistent
    transition="dialog-bottom-transition"
    max-width="600"
  >
    <v-card class="rounded-lg">
      <v-toolbar color="primary" dark>
        <h3>{{ titulo }}</h3>
        <v-spacer> </v-spacer>
        <v-btn text dark @click="$emit('cierraTabla')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <div v-if="opcion == 1">
          <div style="margin-top: 20px;
              font-weight: bolder;
              color: rgb(0, 120, 180);
              font-size: 1.3em;
            "
          >
            Este beneficio te ofrece:
          </div>
          <div style="font-weight: bold; color: rgb(120, 120, 120)">
            {{ mensaje }}.
          </div>
        </div>
        <div v-else-if="opcion == 2">
          <div v-if="mensaje.compensacionesData.length > 0">
            <p class="text-center text-uppercase text-h6 primary--text">
              <strong>compensación total: </strong>
              {{ mensaje.compensacionTotal }}
            </p>
            <v-simple-table dense>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Compensación</th>
                    <th class="text-left">Acumulado</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="item in mensaje.compensacionesData"
                    :key="item.name"
                  >
                    <td>{{ item.con_descon }}</td>
                    <td>{{ item.importe }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>
          <div v-else>
            <p class="text-center text-uppercase subtitle-1">
              Sin información registrada.
            </p>
          </div>
        </div>
        <div v-else-if="opcion == 3">
          <div
            v-if="mensaje != 'Disponible'"
            style="font-weight: bold; color: rgb(120, 120, 120)"
          >
            {{ mensaje }}
          </div>
          <div v-else style="margin-top: 5px;">
            <v-sheet>
              <solicitud-apoyo-lentes />
            </v-sheet>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
// import axios from "axios";
import SolicitudApoyoLentes from "./SolicitudApoyoLentes.vue";
export default {
  name: "detalles-beneficios",
  data: () => ({
    titulo: "",
    mensajeBloqueo: "",
    bloqueoLentes: false,
  }),
  components: {
    SolicitudApoyoLentes,
  },
  created() {},
  mounted() {
    if (this.opcion == 1) {
      this.titulo = "Detalles beneficio";
    } else if (this.opcion == 2) {
      this.titulo = "Compensaciones Urrea";
    } else if (this.opcion == 3) {
      this.titulo = "Ayuda para lentes";
    }
  },
  props: ["verDataTable", "opcion", "mensaje"],
  methods: {},
};
</script>
