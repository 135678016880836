<template>
  <v-card class="rounded-xl elevation-0 pa-3">
    <v-data-table
      dense
      :headers="headers"
      :search="search"
      :items="ofertas"
      no-data-text="No hay registros para este estatus"
      loading-text="Cargando ofertas..."
      :loading="loadingOffers"
      :show-expand="estatus == 2 ? true : false"
      :expanded.sync="expanded"
    >
      <template v-slot:top>
        <div style="display: flex; justify-content: end;">
          <v-text-field
            v-model="search"
            outlined
            dense
            class="rounded-xl"
            style="max-width: 350px;"
            prepend-inner-icon="mdi-table-search"
            placeholder="Buscar en tabla..."
          ></v-text-field>
          <v-tooltip left color="blue lighten-4">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                small
                fab
                depressed
                class="ml-2"
                v-bind="attrs"
                v-on="on"
                @click="
                  loadingOffers = true;
                  getOfertas();
                "
              >
                <v-icon>mdi-reload</v-icon>
              </v-btn>
            </template>
            <label class="text-uppercase font-weight-bold primary--text"
              >Actualizar tabla</label
            >
          </v-tooltip>
        </div>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length" class="px-5 py-5 blue-grey lighten-5">
          <v-card class="px-4 py-1 elevation-0 rounded-xl" width="400" style="border: 2px solid #000000; ">
            <label class="overline"> Justificación del rechazo </label>
            <p>{{ item.Comentarios }}</p>
          </v-card>
        </td>
      </template>
      <template v-slot:item.sueldoActual="{ item }">
        {{
          formatMXN(
            isNaN(parseFloat(item.sueldoActual))
              ? 0
              : parseFloat(item.sueldoActual)
          )
        }}
      </template>
      <template v-slot:item.Salario="{ item }">
        {{
          formatMXN(
            isNaN(parseFloat(item.Salario)) ? 0 : parseFloat(item.Salario)
          )
        }}
      </template>
      <template v-slot:item.incremento="{ item }">
        {{
          !Number.isFinite(((item.Salario - item.sueldoActual) / item.sueldoActual) * 100) ? (100).toFixed(2) :
            (((item.Salario - item.sueldoActual) / item.sueldoActual) * 100).toFixed(2)
        }}%
      </template>
      <template v-slot:item.autorizador="{ item }">
        {{ item.nomAutorizador }}
      </template>

      <template v-slot:item.acciones="{ item }">
        <v-tooltip left color="blue lighten-4" v-if="estatus == 0">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              @click="getDataToSendMail(item)"
              color="blue darken-4"
              v-bind="attrs"
              v-on="on"
            >
              mdi-email-fast-outline
            </v-icon>
          </template>
          <label class="text-uppercase font-weight-bold primary--text"
            >Notificar</label
          >
        </v-tooltip>
        <v-tooltip left color="green lighten-4" v-else-if="estatus == 1">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              @click="
                itemAux = item;
                verVistaPrevia = true;
              "
              color="green darken-4"
              v-bind="attrs"
              v-on="on"
            >
              mdi-file-document-plus-outline
            </v-icon>
          </template>
          <label
            class="text-uppercase font-weight-bold green--text text--darken-4"
            >Crear oferta</label
          >
        </v-tooltip>
        <v-tooltip left color="pink lighten-4" v-else-if="estatus == 2">
          <template v-slot:activator="{ on, attrs }">
            <router-link
              :to="'/Nominas/Compensaciones/ofertasEconomicas/' + item.id"
            >
              <v-icon color="pink darken-4" v-bind="attrs" v-on="on"
                >mdi-pencil</v-icon
              >
            </router-link>
          </template>
          <label
            class="text-uppercase font-weight-bold pink--text text--darken-4"
            >Modificar oferta</label
          >
        </v-tooltip>
      </template>
    </v-data-table>

    <PersonaDialogSendMail
      v-if="showSendMail"
      :showSendMail="showSendMail"
      :defaultMsg="msgMail"
      :receptor="receptor"
      title="Oferta económica pendiente de autorización"
      @hideSendMail="hideSendMail"
    />

    <SnackBar
      v-if="muestraBarra"
      :muestraBarra="muestraBarra"
      :texto="textoBarra"
      :color="colorBarra"
      @cierraBarra="muestraBarra = false"
    />

    <DialogVistaPreviaDocumento
      v-if="verVistaPrevia"
      :verVistaPrevia="verVistaPrevia"
      :empleado="itemAux"
      @ocultaVistaPrevia="verVistaPrevia = false"
    />
  </v-card>
</template>

<script>
import { deleteToken } from "../../../../../../store/Funciones/funciones";
import PersonaDialogSendMail from "../../../../../../components/PersonaDialogSendMail";
import SnackBar from "../../../../General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias";
import DialogVistaPreviaDocumento from "./DialogVistaPreviaDocumento.vue";

import axios from "axios";
export default {
  name: "ofertas-economicas-pendientes",
  props: ["estatus"],
  components: {
    PersonaDialogSendMail,
    SnackBar,
    DialogVistaPreviaDocumento,
  },
  data() {
    return {
      loadingOffers: true,
      showSendMail: false,
      muestraBarra: false,
      verVistaPrevia: false,
      colorBarra: "",
      textoBarra: "",
      msgMail: "",
      receptor: "",
      search: "",
      headers: [
        {
          text: "ID",
          value: "id",
          class:
            "text-uppercase font-weight-bold blue-grey--text text--darken-2",
        },
        {
          text: "Empleado/Candidato",
          value: "Empleado",
          class:
            "text-uppercase font-weight-bold blue-grey--text text--darken-2",
        },
        {
          text: "E",
          value: "Estatus",
          class:
            "text-uppercase font-weight-bold blue-grey--text text--darken-2",
        },
        {
          text: "Puesto actual",
          value: "puestoActual",
          class:
            "text-uppercase font-weight-bold blue-grey--text text--darken-2",
        },
        {
          text: "Puesto nuevo",
          value: "puestoPromocion",
          class:
            "text-uppercase font-weight-bold blue-grey--text text--darken-2",
        },
        {
          text: "Sueldo Actal",
          value: "sueldoActual",
          sortable: false,
          class:
            "text-uppercase font-weight-bold blue-grey--text text--darken-2",
        },
        {
          text: "Sueldo nuevo",
          value: "Salario",
          sortable: false,
          class:
            "text-uppercase font-weight-bold blue-grey--text text--darken-2",
        },
        {
          text: "Incremento",
          value: "incremento",
          sortable: false,
          class:
            "text-uppercase font-weight-bold blue-grey--text text--darken-2",
        },
      ],
      ofertas: [],
      expanded: [],
      itemAux: {},
    };
  },
  mounted() {
    if (this.estatus == 0) {
      this.headers.push(
        {
          text: "Autorizador",
          value: "autorizador",
          class:
            "text-uppercase font-weight-bold blue-grey--text text--darken-2",
        },
        {
          text: "",
          value: "acciones",
          sortable: false,
          class:
            "text-uppercase font-weight-bold blue-grey--text text--darken-2",
        }
      );
    } else if (this.estatus == 1) {
      this.headers.push({
        text: "",
        value: "acciones",
        sortable: false,
        class: "text-uppercase font-weight-bold blue-grey--text text--darken-2",
      });
    } else if (this.estatus == 2) {
      this.headers.push({
        text: "",
        value: "acciones",
        sortable: false,
        class: "text-uppercase font-weight-bold blue-grey--text text--darken-2",
      });
    }
    this.getOfertas();
  },
  methods: {
    muestraSnackBar(texto, color) {
      this.colorBarra = color;
      this.textoBarra = texto;
      this.muestraBarra = true;
    },
    getOfertas() {
      this.ofertas.length = 0;
      const params = {
        Opcion: 19,
        token: localStorage.getItem("token"),
        estatus: this.estatus,
      };
      axios
        .post(
          `${this.$store.getters.getHost}/Nominas/Compensaciones/ws-ofertasEconomicas.php`,
          params
        )
        .then((response) => {
          this.loadingOffers = false;
          if (response.data.status == "EXPRESSION") {
            deleteToken();
          } else {
            if (response.data.status == "OK") {
              this.ofertas = response.data.ofertas;
            }
          }
        })
        .catch((error) => {
          this.muestraSnackBar(error, "pink darken-3");
        });
    },
    formatMXN(cadena) {
      return cadena.toLocaleString("es-MX", {
        style: "currency",
        currency: "MXN",
      });
    },

    async getDataToSendMail(item) {
      let autData = await this.getDataAutorizador(item);
      this.receptor = autData.mail;
      this.openSendMail(item, autData);
    },

    openSendMail(item, autData) {
      this.msgMail =
        "Estimad@ colaborador(a)<br><br>" +
        "Le informamos que hay una nueva oferta económica pendiente de su autorización.<br>" +
        "<center>" +
        '<table style="border: 0.8px solid #CFD8DC !important;">' +
        "<thead>" +
        '    <tr style="background-color: rgb(0,120,195); color: white;">' +
        "        <th>Tipo</th> " +
        "        <th>Nombre</th> " +
        "        <th>Puesto</th> " +
        "        <th>Salario aprobado</th> " +
        "        <th>Autorizar</th>" +
        "        <th>Rechazar</th>" +
        "    </tr>" +
        "</thead>" +
        "<tbody>" +
        "    <tr>" +
        '        <td style="text-align: center; padding-left: 5px; padding-right: 5px;">' +
        item.tipoDesc +
        "</td> " +
        '        <td style="text-align: center; padding-left: 5px; padding-right: 5px;">' +
        item.Empleado +
        "</td> " +
        '        <td style="text-align: center; padding-left: 5px; padding-right: 5px;">' +
        item.puestoPromocion +
        "</td> " +
        '        <td style="text-align: center; padding-left: 5px; padding-right: 5px;">' +
        item.Salario +
        "</td> " +
        '        <td style="text-align: center;">' +
        '            <a href="' +
        "https://desarrollohumano.urrea.com/#/AutorizaOferta/" +
        autData.token +
        "/" +
        item.id +
        '/1">' +
        '                <img src="http://dh2.urrea.com/IMAGENES/aceptada.png" style="width:20px;height:20px;">' +
        "            </a>" +
        "        </td>" +
        '        <td style="text-align: center;">' +
        '            <a href="' +
        "http://dh2.urrea.com/#/AutorizaOferta/" +
        autData.token +
        "/" +
        item.id +
        '/2">' +
        '                <img src="https://desarrollohumano.urrea.com/IMAGENES/delete.png" style="width:20px;height:20px;">' +
        "            </a>" +
        "        </td>" +
        "    </tr>" +
        "</tbody>" +
        "</center>";
      this.showSendMail = true;
    },

    async getDataAutorizador(item) {
      const params = {
        Opcion: 20,
        token: localStorage.getItem("token"),
        empleado: item.autorizador,
        idOferta: item.id,
      };

      return new Promise((resolve, reject) => {
        axios
          .post(
            `${this.$store.getters.getHost}/Nominas/Compensaciones/ws-ofertasEconomicas.php`,
            params
          )
          .then((response) => {
            this.loadingOffers = false;
            if (response.data.status == "EXPRESSION") {
              deleteToken();
            } else {
              if (response.data.status == "OK") {
                resolve(response.data.autData);
              }
            }
          })
          .catch((error) => {
            reject({ error: error });
          });
      });
    },

    hideSendMail(respuesta) {
      if (respuesta == 0) {
        this.showSendMail = false;
      } else if (respuesta == 1) {
        this.showSendMail = false;
        this.muestraSnackBar("Correo enviado.");
      } else {
        this.showSendMail = false;
        this.muestraSnackBar("No se pudo enviar la noticicación.");
      }
    },
  },
};
</script>
