<template>
  <div>
    <v-row justify="center">
      <v-dialog
        v-model="muestraSolicitaPuestoExistente"
        fullscreen
        hide-overlay
        scrolleable
        transition="dialog-bottom-transition"
      >
        <v-card>
          <v-toolbar dark color="primary">
            <v-toolbar-title>Solcitud de puesto existente</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-icon dark @click="$emit('cierraSolicitud', 0)">mdi-close</v-icon>
          </v-toolbar>
          <v-card-text>
            <v-form ref="form" v-model="valid" lazy-validation>
              <div style="display: flex; justify-content: center">
                <v-text-field
                  v-model="nombrePuesto"
                  require
                  :counter="250"
                  :rules="nombreRegla"
                  class="pa-0 text-center mt-6 rounded-lg font-weight-bold my-text-field"
                  style="max-width: 300px; color: rgb(0, 120, 195)"
                  label="Nombre"
                  dense
                  outlined
                  placeholder="Nombre del puesto"
                >
                </v-text-field>
              </div>

              <v-divider class="mt-1"></v-divider>
              <div>
                <p class="font-weight-bold blue-grey--text text--darken-2">
                  Descripción del puesto.
                </p>
                <v-textarea
                  class="body-2 rounded-lg"
                  :value="descPuestoTextArea"
                  outlined
                  row-height="15"
                  dense
                  disabled
                  rows="3"
                ></v-textarea>
                <v-sheet
                  class="mb-5"
                  style="display: flex; justify-content: space-around"
                >
                  <v-card class="card-salary-range" width="180px">
                    <p
                      class="caption my-0 pl-3 text-uppercase font-weight-bold"
                    >
                      Sueldo de contratación
                    </p>
                    <p
                      class="text-h6 mb-0 pl-3 font-weight-light cyan--text text--darken-2"
                      v-if="descripcionPuesto.contratacion"
                    >
                      {{
                        descripcionPuesto.contratacion.toLocaleString("es-MX", {
                          style: "currency",
                          currency: "MXN",
                        })
                      }}
                    </p>
                  </v-card>
                </v-sheet>
              </div>
              <v-divider class="mt-1"></v-divider>

              <v-row>
                <v-col cols="12" xl="6" lg="6" md="6" sm="12" xs="12">
                  <v-select
                    v-model="reasonSelected"
                    dense
                    class="pa-0 my-1"
                    :loading="loadingRazones"
                    :items="razones"
                    require
                    :rules="reasonRules"
                    item-text="descripcion"
                    filled
                    label="Selecciona una razón"
                  >
                  </v-select>
                </v-col>
                <v-col cols="12" xl="6" lg="6" md="6" sm="12" xs="12">
                  <v-select
                    v-model="typeSelected"
                    dense
                    class="pa-0 my-1"
                    :loading="loadingTipos"
                    :items="tipos"
                    :rules="typeRules"
                    require
                    item-text="descripcion"
                    filled
                    label="Selecciona un tipo de puesto"
                  >
                  </v-select>
                </v-col>
              </v-row>

              <v-row>
                <v-col
                  class="pa-0"
                  cols="12"
                  xl="6"
                  lg="6"
                  md="6"
                  sm="12"
                  xs="12"
                >
                  <v-textarea
                    v-model="comentario_solicitud"
                    class="pa-0 mx-2"
                    dense
                    filled
                    rows="3"
                    v-if="
                      reasonSelected == 'Inc de plantilla' ||
                      reasonSelected == 'Reestructura'
                    "
                    placeholder="Describe la razón de la solicitud"
                  >
                  </v-textarea>
                  <v-autocomplete
                    v-model="colaboradorInvolucrado"
                    placeholder="Busca por nombre o número de empleado..."
                    label="Colaborador involucrado"
                    dense
                    filled
                    :items="colaboradores"
                    item-text="nombreMostrar"
                    :loading="loadingEmpl"
                    class="pa-0 mx-3"
                    v-else-if="
                      reasonSelected == 'Rotacion' ||
                      reasonSelected == 'Confidenciales' ||
                      reasonSelected == 'Inc de responsabilidad' ||
                      reasonSelected == 'Promocion' ||
                      reasonSelected == 'Rescisión de contrato' ||
                      reasonSelected == 'Defunción'
                    "
                  >
                  </v-autocomplete>
                  <p class="text-left ml-4 mb-4 pb-0">
                    Información de candidato recomendado (si se tiene uno).
                  </p>
                  <v-row class="text-center mx-1">
                    <v-col
                      class="py-0 mb-0"
                      cols="12"
                      xl="6"
                      lg="6"
                      md="6"
                      sm="12"
                      xs="12"
                    >
                      <v-text-field
                        v-model="nombreReferido"
                        class="text-center mb-0"
                        outlined
                        dense
                        label="Nombre:"
                        placeholder="Ingresa el nombre..."
                        small
                      ></v-text-field>
                    </v-col>
                    <v-col
                      class="py-0 mb-0"
                      cols="12"
                      xl="6"
                      lg="6"
                      md="6"
                      sm="12"
                      xs="12"
                    >
                      <v-text-field
                        v-model="emailReferido"
                        class="text-center mb-0"
                        outlined
                        dense
                        label="Correo electrónico"
                        placeholder="Ingresa el correo..."
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <label class="text-left ml-4"
                    >Seleccione el curso de universidad urrea</label
                  >
                  <v-autocomplete
                    v-model="courseSelected"
                    class="rounded-lg mx-4"
                    dense
                    outlined
                    placeholder="Escribe el nombre del curso..."
                    required
                    :rules="rulesCursoUU"
                    :loading="loadingCourses"
                    :items="cursos"
                    item-text="fullname"
                  ></v-autocomplete>
                </v-col>
                <v-col
                  class="pa-0"
                  cols="12"
                  xl="6"
                  lg="6"
                  md="6"
                  sm="12"
                  xs="12"
                >
                  <div
                    v-if="
                      typeSelected == 'Coordinacion' ||
                      typeSelected == 'Gerencia' ||
                      typeSelected == 'Direccion'
                    "
                  >
                    <p class="text-left pa-0 ml-2">Comité involucrado</p>
                    <v-combobox
                      v-model="empComiteSelected"
                      class="pa-0 mx-2"
                      :items="colaboradores"
                      item-text="nombreMostrar"
                      chips
                      clearable
                      label="Selecciona a los entrevistadores"
                      multiple
                      prepend-icon="mdi-filter-variant"
                      dense
                      filled
                      x-small
                    >
                      <template
                        v-slot:selection="{ attrs, item, select, selected }"
                      >
                        <v-chip
                          v-bind="attrs"
                          :input-value="selected"
                          close
                          small
                          @click="select"
                          @click:close="remove(item)"
                        >
                          <strong>{{ item.NAME }}</strong
                          >&nbsp;
                          <span>({{ item.EMPLID }})</span>
                        </v-chip>
                      </template>
                    </v-combobox>
                    <v-textarea
                      v-model="casoPractico"
                      class="pa-0 mx-2"
                      small
                      dense
                      filled
                      rows="1"
                      placeholder="Describe el tema del caso práctico"
                    >
                    </v-textarea>
                  </div>
                  <div v-show="typeSelected == 'Ventas'">
                    <div calss="text-left">
                      <v-switch
                        v-model="banderaComisiones"
                        class="pa-0 ml-5 mt-0"
                        color="pink darken-4"
                        small
                        label="¿Recibirá comisiones?"
                      ></v-switch>
                    </div>
                    <v-row class="pa-0 mx-2">
                      <v-col cols="12" xl="4" lg="4" md="4" sm="6" xs="12">
                        <v-text-field
                          v-model="comision"
                          type="number"
                          outlined
                          dense
                          small
                          label="Comisión"
                          prepend-inner-icon="mdi-cash-multiple"
                          :disabled="!banderaComisiones"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" xl="4" lg="4" md="4" sm="6" xs="12">
                        <v-text-field
                          v-model="garantia"
                          type="number"
                          outlined
                          dense
                          small
                          label="Garantía"
                          prepend-inner-icon="mdi-check-decagram-outline"
                          :disabled="!banderaComisiones"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" xl="4" lg="4" md="4" sm="12" xs="12">
                        <v-text-field
                          v-model="meses"
                          type="number"
                          outlined
                          dense
                          small
                          label="Meses"
                          prepend-inner-icon="mdi-calendar-month"
                          :disabled="!banderaComisiones"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </div>
                  <p class="text-left pa-0 ml-2">Entrevistadores</p>
                  <v-combobox
                    v-model="empSelected"
                    class="pa-0 mx-2"
                    :items="colaboradores"
                    item-text="nombreMostrar"
                    small-chips
                    clearable
                    label="Selecciona a los entrevistadores"
                    multiple
                    prepend-icon="mdi-filter-variant"
                    dense
                    filled
                    x-small
                    require
                    :rules="[requerido]"
                  >
                    <template
                      v-slot:selection="{ attrs, item, select, selected }"
                    >
                      <v-chip
                        v-bind="attrs"
                        :input-value="selected"
                        close
                        @click="select"
                        @click:close="remove(item)"
                      >
                        <strong>{{ item.NAME }}</strong
                        >&nbsp;
                        <span>({{ item.EMPLID }})</span>
                      </v-chip>
                    </template>
                  </v-combobox>
                </v-col>
              </v-row>

              <v-divider class="mt-2 mb-5"></v-divider>

              <v-row>
                <v-col cols="12" xl="4" lg="4" md="4" sm="12" xs="12">
                  <p class="text-left ml-2 blue-grey--text text--lighten-2">
                    Apego a perfil
                    <v-icon
                      v-show="
                        escolaridadApego.id != 0 &&
                        conocimientosApego.length > 0
                      "
                      color="blue-grey lighten-2"
                      small
                      rigth
                      @click="openEditAPP()"
                    >
                      mdi-pencil
                    </v-icon>
                  </p>
                  <!-- <p>{{datosPuesto.escolaridad.id}} {{datosPuesto.conocimientos.length}}</p> -->
                  <v-expand-x-transition>
                    <v-card
                      v-if="
                        escolaridadApego.id != 0 &&
                        conocimientosApego.length > 0
                      "
                      class="rounded-xl background-card-edited"
                      elevation="0"
                    >
                      <v-simple-table style="background-color: transparent">
                        <thead>
                          <tr>
                            <th class="text-center">ID</th>
                            <th class="text-left">Escolaridad</th>
                            <!-- <th class="text-center">
                              Ponderación
                            </th> -->
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td class="text-center">
                              {{ escolaridadApego.id }}
                            </td>
                            <td class="text-left">
                              {{ escolaridadApego.esc }} ({{
                                escolaridadApego.carrera
                              }})
                            </td>
                            <!-- <td class="text-center">
                              {{ escolaridadApego.Ponderacion }}
                            </td> -->
                          </tr>
                        </tbody>
                      </v-simple-table>
                      <v-simple-table style="background-color: transparent">
                        <thead>
                          <tr>
                            <th class="text-center">ID</th>
                            <th class="text-center">Conocimiento</th>
                            <th class="text-center">Ponderación</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="item in conocimientosApego" :key="item.id">
                            <td class="text-center">
                              {{ item.id }}
                            </td>
                            <td class="text-center">
                              {{ item.descr }}
                            </td>
                            <td class="text-center">
                              {{ item.Ponderacion }}
                            </td>
                          </tr>
                        </tbody>
                      </v-simple-table>
                    </v-card>
                    <div v-else style="display: flex; justify-content: center">
                      <v-card class="shadow-md rounded-xl" max-height="250">
                        <v-card-text>
                          <div class="text-center">
                            <v-btn
                              depressed
                              fab
                              x-large
                              dark
                              color="success"
                              @click="openEditAPP()"
                            >
                              <v-icon x-large>mdi-plus</v-icon>
                            </v-btn>
                          </div>
                          <div class="text-center">
                            <v-chip class="ma-2" @click="openEditAPP()">
                              Agregar apego a perfil
                            </v-chip>
                          </div>
                        </v-card-text>
                      </v-card>
                    </div>
                  </v-expand-x-transition>
                </v-col>
                <!-- <v-col cols="12" xl="4" lg="4" md="4" sm="12" xs="12">
                  <p class="text-left ml-2 blue-grey--text text--lighten-2">
                    Apego a perfil
                    <v-icon
                      v-show="form.id"
                      color="blue-grey lighten-2"
                      small
                      rigth
                      @click="openEditAPP()"
                    >
                      mdi-pencil
                    </v-icon>
                  </p>
                  <v-expand-x-transition>
                    <v-card
                      v-if="form.id"
                      class="rounded-xl background-card-edited scroll-container"
                      elevation="0"
                    >
                      <v-simple-table style="background-color: transparent">
                        <thead>
                          <tr>
                            <th class="text-center">ID</th>
                            <th class="text-left">Escolaridad</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td class="text-center">
                              {{ form.escolaridad }}
                            </td>
                            <td class="text-left">
                              {{ form.descr }} ({{ form.escolaridad }})
                            </td>
                          </tr>
                        </tbody>
                      </v-simple-table>
                      <v-simple-table style="background-color: transparent">
                        <thead>
                          <tr>
                            <th class="text-center">ID</th>
                            <th class="text-left">
                              Conocimientos indispensables
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="item in form.conocimientosIndispensables"
                            :key="item.id"
                          >
                            <td class="text-center">
                              {{ item.id_conocimiento }}
                            </td>
                            <td class="text-left">
                              {{ item.descr }}
                            </td>
                          </tr>
                        </tbody>
                      </v-simple-table>
                      <v-simple-table
                        style="background-color: transparent"
                        v-show="form.conocimientosDeseables.length > 0"
                      >
                        <thead>
                          <tr>
                            <th class="text-center">ID</th>
                            <th class="text-left">Conocimientos deseables</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="item in form.conocimientosDeseables"
                            :key="item.id"
                          >
                            <td class="text-center">
                              {{ item.id_conocimiento }}
                            </td>
                            <td class="text-left">
                              {{ item.descr }}
                            </td>
                          </tr>
                        </tbody>
                      </v-simple-table>
                      <v-simple-table style="background-color: transparent">
                        <thead>
                          <tr>
                            <th class="text-center">ID</th>
                            <th class="text-left">
                              Competencias indispensables
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="item in form.competenciasIndispensables"
                            :key="item.id"
                          >
                            <td class="text-center">
                              {{ item.id_competencia }}
                            </td>
                            <td class="text-left">
                              {{ item.competencia }}
                            </td>
                          </tr>
                        </tbody>
                      </v-simple-table>
                      <v-simple-table
                        style="background-color: transparent"
                        v-show="form.competenciasDeseable.length > 0"
                      >
                        <thead>
                          <tr>
                            <th class="text-center">ID</th>
                            <th class="text-left">Competencias deseables</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="item in form.competenciasDeseable"
                            :key="item.id"
                          >
                            <td class="text-center">
                              {{ item.id_competencia }}
                            </td>
                            <td class="text-left">
                              {{ item.competencia }}
                            </td>
                          </tr>
                        </tbody>
                      </v-simple-table>
                    </v-card>
                    <div v-else style="display: flex; justify-content: center">
                      <v-card class="shadow-md rounded-xl" max-height="250">
                        <v-card-text>
                          <div class="text-center">
                            <v-btn
                              depressed
                              fab
                              x-large
                              dark
                              color="success"
                              @click="openEditAPP()"
                            >
                              <v-icon x-large>mdi-plus</v-icon>
                            </v-btn>
                          </div>
                          <div class="text-center">
                            <v-chip class="ma-2" @click="openEditAPP()">
                              Agregar apego a perfil
                            </v-chip>
                          </div>
                        </v-card-text>
                      </v-card>
                    </div>
                  </v-expand-x-transition>
                </v-col> -->
                <v-col cols="12" xl="8" lg="8" md="8" sm="12" xs="12">
                  <p class="text-left ml-2 blue-grey--text text--lighten-2">
                    Preguntas clave
                  </p>
                  <v-expand-x-transition>
                    <v-card
                      v-if="preguntasClaveIngresadas.length > 0"
                      class="rounded-xl background-card-edited scroll-container"
                      elevation="0"
                      min-height="383"
                    >
                      <v-card-text class="py-15">
                        <div
                          v-for="(item, index) in preguntasClaveIngresadas"
                          :key="index"
                        >
                          <p
                            class="pa-0 mb-0 ml-3 mr-3 text-left font-weight-bold"
                          >
                            {{ item.pregunta }}
                          </p>
                          <p
                            class="pa-0 mb-0 ml-3 mr-3 text-justify blue-grey--text text--lighten-2"
                          >
                            {{ item.Respuesta }}
                          </p>
                        </div>
                      </v-card-text>
                      <div class="text-center">
                        <v-btn
                          depressed
                          rounded
                          small
                          class="my-2 background-card-edited"
                          @click="openEditaPreguntas(true)"
                        >
                          <v-icon left> mdi-pencil </v-icon>
                          editar preguntas
                        </v-btn>
                      </div>
                    </v-card>
                    <div v-else style="display: flex; justify-content: center">
                      <v-card class="shadow-md rounded-xl" max-height="250">
                        <v-card-text>
                          <div class="text-center">
                            <v-btn
                              depressed
                              fab
                              x-large
                              dark
                              color="success"
                              @click="openEditaPreguntas(false)"
                            >
                              <v-icon x-large>mdi-plus</v-icon>
                            </v-btn>
                          </div>
                          <div class="text-center">
                            <v-chip
                              class="ma-2"
                              @click="openEditaPreguntas(false)"
                            >
                              Agregar preguntas clave
                            </v-chip>
                          </div>
                        </v-card-text>
                      </v-card>
                    </div>
                  </v-expand-x-transition>
                </v-col>
              </v-row>

              <v-divider class="mt-5 mb-5"></v-divider>
              <v-row class="py-0">
                <v-col
                  class="py-0 my-1"
                  cols="12"
                  xl="4"
                  lg="4"
                  md="4"
                  sm="6"
                  xs="12"
                  style="display: flex; justify-content: center"
                >
                  <v-text-field
                    v-model="numVacantes"
                    style="max-width: 300px"
                    type="number"
                    outlined
                    small
                    dense
                    placeholder="Ingresa el número de vacantes"
                    label="Número de vacantes"
                    require
                    :rules="positionsNumber"
                  >
                  </v-text-field>
                </v-col>
                <v-col
                  class="py-0 my-1"
                  cols="12"
                  xl="4"
                  lg="4"
                  md="4"
                  sm="6"
                  xs="12"
                  style="display: flex; justify-content: center"
                >
                  <v-select
                    style="max-width: 300px"
                    v-model="lugarDeTradajo"
                    :items="lugaresDeTrabajo"
                    outlined
                    hide-selected
                    label="Lugar de trabajo"
                    dense
                    require
                    :rules="[
                      (v) =>
                        !!v ||
                        'Es necesario que selecciones el lugar de trabajo.',
                    ]"
                  >
                  </v-select>
                </v-col>
                <v-col
                  class="py-0 my-1"
                  cols="12"
                  xl="4"
                  lg="4"
                  md="4"
                  sm="12"
                  xs="12"
                  style="display: flex; justify-content: center"
                >
                  <v-combobox
                    style="max-width: 300px"
                    v-model="EquipoDeTrabajo"
                    :items="EquiposDeTrabajo"
                    outlined
                    hide-selected
                    multiple
                    label="Equipo de trabajo"
                    dense
                    small-chips
                  >
                    <template
                      v-slot:selection="{ attrs, item, select, selected }"
                    >
                      <v-chip
                        v-bind="attrs"
                        :input-value="selected"
                        color="pink lighten-5"
                        close
                        small
                        @click="select"
                        @click:close="removeFromWorkEquipment(item)"
                      >
                        <span>{{ item }}</span>
                      </v-chip>
                    </template>
                  </v-combobox>
                </v-col>
              </v-row>
            </v-form>

            <v-snackbar
              class="text-center font-weight-bold"
              v-model="muestraMensaje"
              rounded="lg"
              border="top"
              :color="colorBarra"
              :timeout="timeout"
              dark
            >
              {{ textoBarra }}
              <template v-slot:action="{ attrs }">
                <v-btn
                  color="gray"
                  text
                  v-bind="attrs"
                  @click="muestraMensaje = false"
                >
                  Close
                </v-btn>
              </template>
            </v-snackbar>

            <div class="text-center">
              <v-btn
                class="rounded-lg"
                dark
                color="cyan darken-4"
                large
                depressed
                :disabled="muestraMensaje"
                @click="validate"
              >
                <v-icon left>mdi-briefcase-plus-outline</v-icon>
                {{
                  typeof idSolicitud === "number"
                    ? "modificar solicitud"
                    : "generar solicitud"
                }}
              </v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>

    <v-dialog
      v-model="showAlertVacante"
      class="rounded-lg"
      persistent
      max-width="450"
    >
      <v-card class="rounded-lg">
        <v-card-title style="display: flex; justify-content: center">
          <h4><strong>¡AVISO!</strong></h4>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <!-- <div class="card-text"> -->
          <p class="text-justify">
            Existe(n) {{ vacantesExistentes }} vacante(s) creada(s) con el mismo
            nombre "{{ nombrePuesto }}" de diferentes requisitores.
            <br />
            De generar tu solitud con ese nombre,
            <strong class="pink--text text--darken-4">
              se encolaría y será atendida
            </strong>
            una vez que las que estén adelante se cierren.
          </p>

          <p class="text-center">
            <strong> ¿Te gustaría cambiar el nombre? </strong>
          </p>

          <!-- </div> -->
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions style="min-height: 60px">
          <v-row class="pa-0">
            <v-col class="text-center" cols="6">
              <v-btn color="pink darken-4" text block rounded @click="saveData">
                Continuar sin <br />
                editar nombre
              </v-btn>
            </v-col>
            <v-divider vertical></v-divider>
            <v-col class="text-center" cols="6">
              <v-btn
                color="primary"
                text
                block
                rounded
                @click="showAlertVacante = false"
              >
                Editar nombre
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <UpdateApego
      v-if="abreEditaApego"
      :muestraEdicionApego="abreEditaApego"
      :idPuesto="idPuesto"
      :conocimientosPuesto="datosPuesto.conocimientos"
      :escolaridadPuestos="datosPuesto.escolaridad"
      :apegoEditado="esApegoEditado"
      @cierraVentana="saveAPP"
    />
    <!-- <form-perfil-puestos
      v-if="abreEditaApego"
      :dialog="abreEditaApego"
      :item="itemPuesto"
      @cierraForm="closeForm"
    >
    </form-perfil-puestos> -->

    <UpdatePreguntas
      v-if="abreEditaPreguntas"
      :muestraEdicionPreguntas="abreEditaPreguntas"
      :preguntasPuesto="datosPuesto.preguntasClave"
      :primeraVez="primeraVez"
      @cierraVentanaPreguntasEdit="savePC"
    />

    <LoadingBar :text="textoLoading" :dialog="loadingContent" />
  </div>
</template>

<script>
import axios from "axios";
import { deleteToken } from "../../../../../../store/Funciones/funciones";

import UpdateApego from "./componentesSolicitudDePuesto/UpdateApegoPerfil.vue";
import UpdatePreguntas from "./componentesSolicitudDePuesto/UpdatePreguntasClave.vue";
import LoadingBar from "../../../../DesarrolloOrganizacional/components/loadingBar";
// import FormPerfilPuestos from "../../EscalerasDeCrecimiento/componentsPerfilPuestos/FormPerfilPuestos.vue";

export default {
  name: "solicita-puesto-existente",
  props: ["muestraSolicitaPuestoExistente", "idPuesto", "idSolicitud"],
  components: { UpdateApego, UpdatePreguntas, LoadingBar },
  data() {
    return {
      valid: true,
      esApegoEditado: false,
      empSelected: [],
      empComiteSelected: [],
      lugaresDeTrabajo: ["El Salto", "Américas", "Tabasco", "Foraneo"],
      EquiposDeTrabajo: ["Laptop", "Celular", "EPP"],
      EquipoDeTrabajo: [],
      cursos: [],
      courseSelected: "",
      reasonSelected: "",
      typeSelected: "",
      nombreReferido: "",
      emailReferido: "",
      colaboradorInvolucrado: "",
      jobcodeEdit: "",
      lugarDeTradajo: "",
      nombrePuesto: "",
      comentario_solicitud: "",
      casoPractico: "",
      descPuestoTextArea: "",
      codigoPuesto: "",
      textoLoading: "Cargando contenido...",
      comision: 0,
      garantia: 0,
      meses: 0,
      numVacantes: 0,
      timeout: 2000,
      datosPuesto: {
        nombre: "",
        escolaridad: {
          idEscolaridad: 0,
          esc: "",
          carrera: "",
          idCarrera: 0,
          Ponderacion: 0,
        },
        conocimientos: [],
        preguntasClave: [],
      },
      rules: {
        required: (value) => !!value || "Requerido.",
        counter: (value) => value.length <= 20 || "Max 20 characters",
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Sintaxis invalida.";
        },
      },
      razones: [],
      tipos: [],
      colaboradores: [],
      escolaridadApego: { id: 0, esc: "", carrera: "", Ponderacion: 0 },
      paramsRequest: {},
      descripcionPuesto: {
        contratacion: 0,
        maximo: 0,
        minimo: 0,
        descMercer: "",
        descProductivity: "",
      },
      conocimientosApego: [],
      preguntasClaveIngresadas: [],
      interviewersRequest: [],
      loadingRazones: true,
      loadingTipos: true,
      loadingEmpl: true,
      banderaComisiones: false,
      abreEditaApego: false,
      abreEditaPreguntas: false,
      primeraVez: false,
      showAlertVacante: false,
      loadingContent: false,
      loadingCourses: true,
      idReason: null,
      idTipoPuesto: null,
      idColaboradorInvolucrado: null,
      vacantesExistentes: 0,

      // Reglas Formulario
      nombreRegla: [
        (v) => !!v || "Este campo es requerido.",
        (v) =>
          (v && v.length <= 250) ||
          "El nombre debe tener menos de 250 caracteres.",
      ],
      reasonRules: [(v) => !!v || "Este campo es requerido."],
      typeRules: [(v) => !!v || "Este campo es requerido."],
      rulesCursoUU: [(v) => !!v || "Es necesario que selecciones un curso."],
      positionsNumber: [
        (v) => !!v || "Este campo es requerido.",
        (v) => parseInt(v, 10) > 0 || "Debes solicitar al menos una posición.",
      ],
      rulesInterviwers: [
        (v) => !!v || "Es necesario que agregues al menos un entrevistador.",
      ],

      // Variables snack bar
      textoBarra: "",
      colorBarra: "",
      muestraMensaje: false,
      itemPuesto: [],
      form: [],
    };
  },
  created() {
    if (this.idPuesto != null) {
      this.codigoPuesto = this.idPuesto;
    }
  },
  mounted() {
    console.log(this.idSolicitud);
    if (this.idSolicitud == undefined) {
      // this.getdatosPuesto();
      this.getdatosPuestoNode();
      // this.getDescPuestoMercer();
      this.getDescPuestoMercerNode();
      // this.getCursos();
      this.getCursosNode();
    } else {
      // console.log("idSolicitud: " + this.idSolicitud);
      this.setDataFields();
    }
    // this.getPerfilPuestoDetalles();
  },
  methods: {
    // getPerfilPuestoDetalles() {
    //   const headers = {
    //     Authorization: localStorage.getItem("token"),
    //   };

    //   return new Promise((res) => {
    //     axios
    //       .get(
    //         `${this.$store.getters.getHostNode}/api/get-perfil_puestos/${
    //           this.idPuesto
    //         }/${2}`,
    //         {
    //           headers: headers,
    //         }
    //       )
    //       .then((response) => {
    //         if (response.data.status == "EXPRESSION") {
    //           deleteToken();
    //         } else if (response.data.status == "OK") {
    //           res(response.data.data);
    //           this.form = response.data.data;
    //         } else if (response.data.status == "VACIO") {
    //           res(response.data.data);
    //           console.log("vacio");
    //         } else {
    //           res([]);
    //         }
    //       })
    //       .finally(() => {
    //         this.loadingForm = false;
    //       });
    //   });
    // },

    closeForm(response) {
      this.abreEditaApego = false;
      if (response == "OK") {
        this.form = [];
        this.getPerfilPuestoDetalles();
      }
    },

    setDataFields() {
      let params = {
        Opcion: 16,
        token: localStorage.getItem("token"),
        solicitud: this.idSolicitud,
      };
      axios
        .post(
          `${this.$store.getters.getHost}/Reclutamiento/ws-admin-reclu.php`,
          params
        )
        .then((response) => {
          if (response.data.status != "EXPSESSION") {
            this.nombrePuesto = response.data.solicitud.nombre;
            this.numVacantes = response.data.solicitud.num_vacantes;
            this.codigoPuesto = response.data.solicitud.idPuesto_asociado;
            this.idReason = response.data.solicitud.razon_principal;
            this.idTipoPuesto = response.data.solicitud.tipo;
            this.preguntasClaveIngresadas = response.data.solicitud.preguntas;
            this.conocimientosApego = response.data.solicitud.conocimientos;
            this.escolaridadApego = response.data.solicitud.escolaridad;
            this.lugarDeTradajo = response.data.solicitud.lugar;
            this.interviewersRequest = response.data.solicitud.entrevistadores;
            this.casoPractico = response.data.solicitud.tema_caso_practico;
            this.comision = response.data.solicitud.comisionval;
            this.garantia = response.data.solicitud.comisiongar;
            this.meses = response.data.solicitud.comisionmes;
            this.insertWorkEquipmentToRequest(
              response.data.solicitud.equipoDeTrabajo
            );
            if (
              response.data.solicitud.razon_principal == 1 ||
              response.data.solicitud.razon_principal == 5
            ) {
              this.comentario_solicitud =
                response.data.solicitud.razon_solicitud;
            }
            this.idColaboradorInvolucrado =
              response.data.solicitud.colaborador_involucrado;
            // this.getdatosPuesto();
            this.getdatosPuestoNode();
            this.getDescPuestoMercerNode();
            // this.getDescPuestoMercer();
          } else {
            deleteToken();
          }
        })
        .catch((e) => {
          alert("Ocurrió un error. Desc:" + e);
        });
    },

    insertWorkEquipmentToRequest(equipment) {
      if (equipment.laptop === 1) {
        this.EquipoDeTrabajo.push("Laptop");
      }
      if (equipment.celular === 1) {
        this.EquipoDeTrabajo.push("Celular");
      }
      if (equipment.epp === 1) {
        this.EquipoDeTrabajo.push("EPP");
      }
    },
    // getDescPuestoMercer() {
    //   let params = {
    //     Opcion: 15,
    //     token: localStorage.getItem("token"),
    //     idPuesto: this.codigoPuesto,
    //   };
    //   axios
    //     .post(
    //       `${this.$store.getters.getHost}/Reclutamiento/ws-control-de-puestos.php`,
    //       params
    //     )
    //     .then((response) => {
    //       if (response.data.status != "EXPSESSION") {
    //         this.descripcionPuesto = response.data.desc;
    //         if (
    //           this.descripcionPuesto.descMercer != "no data" &&
    //           this.descripcionPuesto.descProductivity != "no data"
    //         ) {
    //           this.descPuestoTextArea =
    //             this.descripcionPuesto.descMercer +
    //             ". " +
    //             this.descripcionPuesto.descProductivity;
    //         } else if (
    //           this.descripcionPuesto.descMercer == "no data" &&
    //           this.descripcionPuesto.descProductivity != "no data"
    //         ) {
    //           this.descPuestoTextArea = this.descripcionPuesto.descProductivity;
    //         } else if (
    //           this.descripcionPuesto.descMercer != "no data" &&
    //           this.descripcionPuesto.descProductivity == "no data"
    //         ) {
    //           this.descPuestoTextArea = this.descripcionPuesto.descMercer;
    //         }
    //       } else {
    //         deleteToken();
    //       }
    //     })
    //     .catch((e) => {
    //       alert("Ocurrió un error. Desc:" + e);
    //     });
    // },

    getDescPuestoMercerNode() {
      // console.log(this.codigoPuesto);
      const headers = { Authorization: localStorage.getItem("token") };
      return new Promise((res) => {
        axios
          .get(
            `${this.$store.getters.getHostNode}/api/get-descr-puestos-mercer/${this.codigoPuesto}`,
            {
              headers,
            }
          )
          .then((response) => {
            if (response.data.status != "EXPSESSION") {
              res(response.data.desc);
              this.descripcionPuesto = response.data.desc;
              if (
                this.descripcionPuesto.descMercer != "no data" &&
                this.descripcionPuesto.descProductivity != "no data"
              ) {
                this.descPuestoTextArea =
                  this.descripcionPuesto.descMercer +
                  ". " +
                  this.descripcionPuesto.descProductivity;
              } else if (
                this.descripcionPuesto.descMercer == "no data" &&
                this.descripcionPuesto.descProductivity != "no data"
              ) {
                this.descPuestoTextArea =
                  this.descripcionPuesto.descProductivity;
              } else if (
                this.descripcionPuesto.descMercer != "no data" &&
                this.descripcionPuesto.descProductivity == "no data"
              ) {
                this.descPuestoTextArea = this.descripcionPuesto.descMercer;
              }
            } else {
              deleteToken();
            }
          })
          .finally(() => {
            // this.loadingCourses = false;
          });
      });
    },

    // getCursos() {
    //   let params = {
    //     Opcion: 24,
    //     token: localStorage.getItem("token"),
    //     idPuesto: this.codigoPuesto,
    //   };
    //   axios
    //     .post(
    //       `${this.$store.getters.getHost}/Reclutamiento/ws-control-de-puestos.php`,
    //       params
    //     )
    //     .then((response) => {
    //       this.loadingCourses = false;
    //       if (response.data.status != "EXPSESSION") {
    //         this.cursos = response.data.cursos;
    //       } else {
    //         deleteToken();
    //       }
    //     })
    //     .catch((e) => {
    //       alert("Ocurrió un error. Desc:" + e);
    //     });
    // },

    getCursosNode() {
      const headers = { Authorization: localStorage.getItem("token") };
      return new Promise((res) => {
        axios
          .get(`${this.$store.getters.getHostNode}/api/get-cursos-uu2`, {
            headers,
          })
          .then((response) => {
            if (response.data.status == "OK") {
              res(response.data.data);
              this.cursos = response.data.data;
            } else if (response.data.status == "EXPRESSION") {
              deleteToken();
            } else {
              res([]);
            }
          })
          .finally(() => {
            this.loadingCourses = false;
          });
      });
    },

    getRazonesNode() {
      const headers = { Authorization: localStorage.getItem("token") };
      return new Promise((res) => {
        axios
          .get(
            `${this.$store.getters.getHostNode}/api/get-razones-solicitud-puesto`,
            {
              headers,
            }
          )
          .then((response) => {
            if (response.data.status != "EXPSESSION") {
              if (response.data.razones.length > 0) {
                res(response.data.data);
                response.data.razones.map((tipo) => {
                  if (tipo.Tipo == 1) {
                    this.razones.push(tipo);
                  } else {
                    this.tipos.push(tipo);
                  }
                });
                if (this.idSolicitud != undefined) {
                  let findReason = this.razones.filter((r) => {
                    return this.idReason === r.TipoValor;
                  });
                  this.reasonSelected = findReason[0].descripcion;
                  let findType = this.tipos.filter((t) => {
                    return this.idTipoPuesto === t.TipoValor;
                  });
                  this.typeSelected = findType[0].descripcion;
                }
              }

              // this.razones = response.data.razones;

              this.loadingRazones = false;
              this.loadingTipos = false;
              this.getColaboradores();
            } else {
              deleteToken();
            }
          })
          .catch((e) => {
            alert("Ocurrió un error. Descripcion: " + e);
          });
      });
    },

    // getRazones() {
    //   let params = {
    //     Opcion: 3,
    //     token: localStorage.getItem("token"),
    //   };
    //   axios
    //     .post(
    //       `${this.$store.getters.getHost}/Reclutamiento/ws-control-de-puestos.php`,
    //       params
    //     )
    //     .then((response) => {
    //       if (response.data.status != "EXPSESSION") {
    //         if (response.data.razones.length > 0) {
    //           response.data.razones.map((tipo) => {
    //             if (tipo.Tipo == 1) {
    //               this.razones.push(tipo);
    //             } else {
    //               this.tipos.push(tipo);
    //             }
    //           });
    //           if (this.idSolicitud != undefined) {
    //             let findReason = this.razones.filter((r) => {
    //               return this.idReason === r.TipoValor;
    //             });
    //             this.reasonSelected = findReason[0].descripcion;
    //             // console.log("idTipoPuesto: " + this.idTipoPuesto);
    //             // console.log(this.tipos);
    //             let findType = this.tipos.filter((t) => {
    //               return this.idTipoPuesto === t.TipoValor;
    //             });
    //             this.typeSelected = findType[0].descripcion;
    //           }
    //           // console.log()
    //         }

    //         this.razones = response.data.razones;

    //         this.loadingRazones = false;
    //         this.loadingTipos = false;
    //         this.getColaboradores();
    //       } else {
    //         deleteToken();
    //       }
    //     })
    //     .catch((e) => {
    //       alert("Ocurrió un error. Descripcion: " + e);
    //     });
    // },

    // getdatosPuesto() {
    //   let params = {
    //     Opcion: 2,
    //     token: localStorage.getItem("token"),
    //     jobcode: this.codigoPuesto,
    //   };
    //   axios
    //     .post(
    //       `${this.$store.getters.getHost}/Reclutamiento/ws-control-de-puestos.php`,
    //       params
    //     )
    //     .then((response) => {
    //       if (response.data.status != "EXPSESSION") {
    //         this.datosPuesto = response.data.datosPuesto;
    //         if (this.idSolicitud == undefined)
    //           this.nombrePuesto = this.datosPuesto.nombre;
    //         // this.getRazonesNode();
    //         this.getRazones();
    //       }
    //     });
    // },

    getdatosPuestoNode() {
      const headers = { Authorization: localStorage.getItem("token") };
      return new Promise((res) => {
        axios
          .get(
            `${this.$store.getters.getHostNode}/api/get-datos-puestos/${this.codigoPuesto}`,
            {
              headers,
            }
          )
          .then((response) => {
            if (response.data.status != "EXPSESSION") {
              res(response.data.data);
              console.log(response.data);
              this.datosPuesto = response.data.data;
              if (this.idSolicitud == undefined)
                this.nombrePuesto = this.datosPuesto.nombre;
              this.getRazonesNode();
              // this.getRazones();
            }
          });
      }).finally(() => {
        this.loadingCourses = false;
      });
    },

    // getColaboradores() {
    //   let params = {
    //     Opcion: 3,
    //     token: localStorage.getItem("token"),
    //   };
    //   axios
    //     .post(
    //       `${this.$store.getters.getHost}/RelacionesLaborales/Reportes/ws-reporte-asistencias.php `,
    //       params
    //     )
    //     .then((response) => {
    //       this.loadingEmpl = false;
    //       if (response.data.status == "EXPSESSION") {
    //         deleteToken();
    //       } else {
    //         var data = response.data.colaboradores;
    //         this.colaboradores = data;

    //         if (this.idSolicitud != undefined) {
    //           if (
    //             this.idReason != 1 &&
    //             this.idReason != 5 &&
    //             this.idReason != null
    //           ) {
    //             let findColaboradorSolicitud = this.colaboradores.filter(
    //               (c) => {
    //                 return c.EMPLID == this.idColaboradorInvolucrado;
    //               }
    //             );
    //             this.colaboradorInvolucrado =
    //               findColaboradorSolicitud[0].nombreMostrar;
    //           }
    //           this.interviewersRequest.map((interviewer) => {
    //             if (interviewer.es_comite == 1) {
    //               let findInterviewerCommittee = this.colaboradores.filter(
    //                 (c) => {
    //                   return c.EMPLID == interviewer.entrevistador;
    //                 }
    //               );
    //               this.empComiteSelected.push(findInterviewerCommittee[0]);
    //             } else {
    //               let findInterviewer = this.colaboradores.filter((c) => {
    //                 return c.EMPLID == interviewer.entrevistador;
    //               });
    //               this.empSelected.push(findInterviewer[0]);
    //             }
    //           });
    //         }
    //         let findRequisitor = this.colaboradores.filter((r) => {
    //           return r.EMPLID == this.$store.getters.getUser["Folio"];
    //         });
    //         if (findRequisitor.length > 0) {
    //           this.empSelected.push(findRequisitor[0]);
    //         }
    //       }
    //     });
    // },

    async getColaboradores() {
      //node
      const headers = { Authorization: localStorage.getItem("token") };
      return new Promise((res) => {
        axios
          .get(`${this.$store.getters.getHostNode}/api/get-all-colaboradores`, {
            headers,
          })
          .then((response) => {
            this.loadingEmpl = false;
            if (response.data.status == "EXPSESSION") {
              deleteToken();
            } else {
              res(response.data.colaboradores);
              var data = response.data.colaboradores;
              this.colaboradores = data;

              if (this.idSolicitud != undefined) {
                if (
                  this.idReason != 1 &&
                  this.idReason != 5 &&
                  this.idReason != null
                ) {
                  let findColaboradorSolicitud = this.colaboradores.filter(
                    (c) => {
                      return c.EMPLID == this.idColaboradorInvolucrado;
                    }
                  );
                  this.colaboradorInvolucrado =
                    findColaboradorSolicitud[0].nombreMostrar;
                }
                this.interviewersRequest.map((interviewer) => {
                  if (interviewer.es_comite == 1) {
                    let findInterviewerCommittee = this.colaboradores.filter(
                      (c) => {
                        return c.EMPLID == interviewer.entrevistador;
                      }
                    );
                    this.empComiteSelected.push(findInterviewerCommittee[0]);
                  } else {
                    let findInterviewer = this.colaboradores.filter((c) => {
                      return c.EMPLID == interviewer.entrevistador;
                    });
                    this.empSelected.push(findInterviewer[0]);
                  }
                });
              }
              let findRequisitor = this.colaboradores.filter((r) => {
                return r.EMPLID == this.$store.getters.getUser["Folio"];
              });
              if (findRequisitor.length > 0) {
                this.empSelected.push(findRequisitor[0]);
              }
            }
          });
      }).finally(() => {
        this.loadingEmpl = false;
      });
    },

    remove(item) {
      this.empSelected.splice(this.empSelected.indexOf(item), 1);
    },

    removeFromWorkEquipment(item) {
      this.EquipoDeTrabajo.splice(this.EquipoDeTrabajo.indexOf(item), 1);
    },

    openEditAPP() {
      this.itemPuesto = { id: this.idPuesto, descr: this.nombrePuesto };
      if (
        this.escolaridadApego.id != 0 &&
        this.conocimientosApego.length != 0 &&
        this.idSolicitud != undefined
      ) {
        this.datosPuesto.conocimientos = this.conocimientosApego;
        this.datosPuesto.escolaridad = this.escolaridadApego;
        this.esApegoEditado = true;
        this.abreEditaApego = true;
      } else {
        this.abreEditaApego = true;
      }
    },

    openEditaPreguntas(edicion) {
      if (this.idSolicitud != undefined) {
        this.datosPuesto.preguntasClave = this.preguntasClaveIngresadas;
        this.primeraVez = edicion;
        this.abreEditaPreguntas = true;
      } else {
        this.primeraVez = edicion;
        this.abreEditaPreguntas = true;
      }
    },

    saveAPP(escolaridad, conocimientos, modo) {
      if (modo == 1) {
        this.escolaridadApego = escolaridad;
        this.conocimientosApego = conocimientos;
      }
      this.abreEditaApego = false;
      this.esApegoEditado = true;
    },

    savePC(preguntasCreadas) {
      this.preguntasClaveIngresadas = preguntasCreadas;
      this.datosPuesto.preguntasClave = preguntasCreadas;
      this.abreEditaPreguntas = false;
    },

    validate() {
      if (this.$refs.form.validate()) {
        let error = 0;
        if (
          (this.reasonSelected == "Inc de plantilla" ||
            this.reasonSelected == "Reestructura") &&
          this.comentario_solicitud == ""
        ) {
          this.showError(
            "Error. Debes agregar el comentario de la razón de ser Inc. de plantilla o Reestructura."
          );
          error = 1;
        } else if (
          this.reasonSelected != "Inc de plantilla" &&
          this.reasonSelected != "Reestructura" &&
          this.colaboradorInvolucrado == ""
        ) {
          this.showError("Error. Debes agregar el un colaborador involucrado.");
          error = 1;
        }
        if (this.emailReferido != "") {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          if (!pattern.test(this.emailReferido)) {
            this.showError(
              "El correo de tu referido tiene una sintaxis erronea."
            );
            error = 1;
          }
        }
        if (
          this.escolaridadApego.id == 0 &&
          this.conocimientosApego.length <= 0
        ) {
          this.showError("Error. Es necesario que agregues el apego a perfil.");
          error = 1;
        }
        if (this.preguntasClaveIngresadas.length != 5) {
          this.showError(
            "Error. Es necesario que agregues exactamente 5 preguntas clave."
          );
          error = 1;
        }
        if (
          this.typeSelected == "Coordinacion" ||
          this.typeSelected == "Gerencia" ||
          this.typeSelected == "Direccion"
        ) {
          if (this.empComiteSelected == "") {
            this.showError("Error. Debes elegir al comité entrevistador.");
            error = 1;
          } else if (this.casoPractico == "") {
            this.showError("Error. Debes ingresar un caso práctico.");
            error = 1;
          }
        }
        if (
          this.typeSelected == "Ventas" &&
          this.banderaComisiones &&
          this.comision == 0
        ) {
          this.showError("Error. Agrega una comisión mayor a cero.");
        }
        if (error == 0) {
          this.textoLoading = "Validando nombre...";
          this.loadingContent = true;
          this.validaNombre();
        }
      }
    },

    validaNombre() {
      let params = {
        Opcion: 6,
        token: localStorage.getItem("token"),
        nombre: this.nombrePuesto,
      };

      axios
        .post(
          `${this.$store.getters.getHost}/Reclutamiento/ws-control-de-puestos.php`,
          params
        )
        .then((response) => {
          if (response.data.status != "EXPSESSION") {
            if (response.data.status == "OK") {
              this.loadingContent = false;
              this.textoLoading = "Cargando Solicitud...";
              if (response.data.respuesta == 0) {
                this.saveData();
              } else {
                this.vacantesExistentes = response.data.respuesta;
                this.showAlertVacante = true;
              }
            }
          } else {
            deleteToken();
          }
        });
    },

    // async validaNombre() {
    //   let folio = this.$store.getters.getUser["Folio"];
    //   const headers = { Authorization: localStorage.getItem("token") };
    //   return new Promise((res) => {
    //     axios
    //       .get(
    //         `${this.$store.getters.getHostNode}/api/validar-nombre-solicitud/${this.nombrePuesto}/${folio}`,
    //         {
    //           headers,
    //         }
    //       )
    //       .then((response) => {
    //         if (response.data.status != "EXPSESSION") {
    //           if (response.data.status == "OK") {
    //             res(response.data.respuesta);
    //             this.loadingContent = false;
    //             this.textoLoading = "Cargando Solicitud...";
    //             if (response.data.respuesta == 0) {
    //               this.saveData();
    //             } else {
    //               this.vacantesExistentes = response.data.respuesta;
    //               this.showAlertVacante = true;
    //             }
    //           }
    //         } else {
    //           deleteToken();
    //         }
    //       });
    //   }).finally(() => {
    //     // this.loadingContent = false;
    //   });
    // },

    buscaIdRespSelected(val) {
      return val.nombreMostrar.trim() == this.colaboradorInvolucrado.trim();
    },

    // saveData() {
    //   if (this.showAlertVacante) {
    //     this.showAlertVacante = false;
    //   }
    //   this.loadingContent = true;
    //   let involucradoAux = "";
    //   if (this.colaboradorInvolucrado != "") {
    //     involucradoAux = this.colaboradores.filter(this.buscaIdRespSelected);
    //   }
    //   let folio = this.$store.getters.getUser["Folio"];
    //   let opcion = typeof this.idSolicitud == "number" ? 16 : 5;
    //   this.paramsRequest = {
    //     Opcion: opcion,
    //     folio: folio,
    //     nombre: this.nombrePuesto,
    //     numVacantes: this.numVacantes,
    //     jobcode: this.codigoPuesto,
    //     razonPrincipal: this.reasonSelected,
    //     tipo: this.typeSelected,
    //     razonSolicitud: this.comentario_solicitud,
    //     colaboradorInvolucrado: involucradoAux,
    //     casoPractico: this.casoPractico,
    //     tieneComision: this.banderaComisiones,
    //     comision: this.comision,
    //     garantia: this.garantia,
    //     meses: this.meses,
    //     entrevistadores: this.empSelected,
    //     comite: this.empComiteSelected,
    //     escolaridad: this.escolaridadApego,
    //     conocimientos: this.conocimientosApego,
    //     preguntasClave: this.preguntasClaveIngresadas,
    //     equipoTrabajo: this.EquipoDeTrabajo,
    //     lugarDeTradajo: this.lugarDeTradajo,
    //     solicitud: this.idSolicitud,
    //     nombreReferido: this.nombreReferido,
    //     mailReferido: this.emailReferido,
    //     courseSelected: this.courseSelected,
    //   };
    //   const headers = {
    //     Authorization: localStorage.getItem("token"),
    //   };
    //   console.log(this.paramsRequest);
    //   return new Promise((res) => {
    //     axios
    //       .post(
    //         `${this.$store.getters.getHostNode}/api/set-solicitud-puesto`,
    //         this.paramsRequest,
    //         { headers }
    //       )
    //       .then((response) => {
    //         if (response.data.status == "OK") {
    //           res(response.data.status);
    //           this.$refs.form.reset();
    //           this.$emit("cierraSolicitud", 1);
    //         } else {
    //           this.$emit("cierraSolicitud", 2);
    //         }
    //       })
    //       .finally(() => {
    //         this.loadingContent = false;
    //       });
    //   });
    // },

    saveData() {
      if (this.showAlertVacante) {
        this.showAlertVacante = false;
      }
      this.loadingContent = true;
      let involucradoAux = "";
      if (this.colaboradorInvolucrado != "") {
        involucradoAux = this.colaboradores.filter(this.buscaIdRespSelected);
      }
      let opcion = typeof this.idSolicitud == "number" ? 16 : 5;
      this.paramsRequest = {
        Opcion: opcion,
        token: localStorage.getItem("token"),
        nombre: this.nombrePuesto,
        numVacantes: this.numVacantes,
        jobcode: this.codigoPuesto,
        razonPrincipal: this.reasonSelected,
        tipo: this.typeSelected,
        razonSolicitud: this.comentario_solicitud,
        colaboradorInvolucrado: involucradoAux,
        casoPractico: this.casoPractico,
        tieneComision: this.banderaComisiones,
        comision: this.comision,
        garantia: this.garantia,
        meses: this.meses,
        entrevistadores: this.empSelected,
        comite: this.empComiteSelected,
        escolaridad: this.escolaridadApego,
        conocimientos: this.conocimientosApego,
        preguntasClave: this.preguntasClaveIngresadas,
        equipoTrabajo: this.EquipoDeTrabajo,
        lugarDeTradajo: this.lugarDeTradajo,
        solicitud: this.idSolicitud,
        nombreReferido: this.nombreReferido,
        mailReferido: this.emailReferido,
        courseSelected: this.courseSelected,
      };
      axios
        .post(
          `${this.$store.getters.getHost}/Reclutamiento/ws-control-de-puestos.php`,
          this.paramsRequest
        )
        .then((response) => {
          this.loadingContent = false;
          if (response.data.status != "EXPSESSION") {
            if (response.data.status == "OK") {
              this.$refs.form.reset();
              this.$emit("cierraSolicitud", 1);
            } else {
              this.$emit("cierraSolicitud", 2);
            }
          } else {
            deleteToken();
          }
        });
    },

    cierraSnackBar() {
      this.muestraMensaje = false;
    },

    showError(text) {
      this.textoBarra = text;
      this.colorBarra = "pink";
      this.muestraMensaje = true;
    },

    requerido(value) {
      if (value instanceof Array && value.length == 0) {
        return "Es necesario que agregues al menos un entrevistador.";
      }
      return !!value || "Es necesario que agregues al menos un entrevistador.";
    },
  },
};
</script>

<style>
.shadow-md {
  box-shadow: 9px 9px 18px #bebebe, -18px -18px 36px #fff0f8 !important;
  background: linear-gradient(145deg, #ffff, #e6e6e6);
}

.background-card-edited {
  background: rgb(230, 230, 230);
  background: radial-gradient(
    circle,
    rgba(230, 230, 230, 1) 17%,
    rgba(248, 252, 251, 1) 100%
  );
}

.card-salary-range {
  border-radius: 0.65rem !important;
  background: rgb(250, 250, 250) !important;
  background: linear-gradient(
    267deg,
    rgba(250, 250, 250, 1) 0%,
    rgba(240, 240, 240, 1) 62%,
    rgba(255, 255, 255, 1) 99%
  );
  box-shadow: 10px 10px 9px #c5c5c5, -10px -10px 9px #ffffff !important;
  transition: box-shadow 0.7s ease;
}

.scroll-container {
  height: 400px;
  overflow-y: scroll;
  background-color: transparent;
  border-radius: 10px !important;
  overflow-y: auto;
}
</style>
