import axios from 'axios'

export default {
    name: 'inicio-presentacion-view',
    components: {},
    props: [],
    data() {
        return {
            model: null,
            benefits: [{
                    titulo: "prestaciones",
                    subtitulo: "soperiores a la ley",
                    icono: "mdi-hand-coin-outline",
                    elementos: [
                        "Fondo de ahorro",
                        "Caja de ahorro",
                        "Vales de despensa",
                        "Comedor subsidiado",
                        "Seguro de vida",
                        "Transporte",
                        "Bonos",
                        "Servicio médico",
                    ]
                },
                {
                    titulo: "Horarios",
                    subtitulo: "felxibles",
                    icono: "mdi-timer-sync-outline",
                    elementos: [
                        "Flextime: posibilidad de cambiar tus horarios de entrada y salida.",
                        "Tiempo por tiempo: posibilidad descansar días laborales trabajando días de descanso."
                    ]
                },
                {
                    titulo: "permisos especiales",
                    subtitulo: "para estar en momentos importantes",
                    icono: "mdi-nature-people-outline",
                    elementos: [
                        "Permiso por nacimiento de hijos",
                        "Permiso por matrimonio",
                        "Permiso por defunción",
                        "Permiso por adopción",
                        "Permiso por aborto",
                        "Permiso por complicación de nacimiento",
                    ]
                },
                {
                    titulo: "Apoyos económicos",
                    subtitulo: "nos importa tu bienestar",
                    icono: "mdi-cash-check",
                    elementos: [
                        "Apoyo del 65% o hasta $2,700 del costo de tus lentes graduados",
                        "Apoyo económico por matrimonio",
                        "Apoyo económico por nacimiento de hijos",
                        "Convenios y descuentos con otras empresas",
                    ]
                },
            ],
            incidencias: [{
                    titulo: "Teletrabajo",
                    descripcion: "Pensando en la seguridad de nuestros colaboradores que no puden salir de casa",
                    icono: "mdi-home-account"
                },
                {
                    titulo: "Home office",
                    descripcion: "Para que puedas disfrutar de hasta 4 días de trabajo desde casa al mes",
                    icono: "mdi-home"
                },
                {
                    titulo: "Viernes corto",
                    descripcion: "Porque los viernes ya son fin de semana. Este día podrás salir dos horas antes. ",
                    icono: "mdi-door-open"
                },
                {
                    titulo: "Llegada tarde",
                    descripcion: "Comprendemos que no siempre se puede llegar temprano",
                    icono: "mdi-clock-alert-outline"
                },
            ],
            convenios: []
        }
    },
    computed: {
        isSmallScreen() {
            return this.$vuetify.breakpoint.name === 'xs' ? true : false;
        }
    },
    mounted() {
        this.getConvenios();
    },
    methods: {
        getConvenios() {
            let params = {
                Opcion: 1,
                token: localStorage.getItem("token"),
            };
            axios
                .post(
                    `${this.$store.getters.getHost}/Compensaciones/ws-convenios.php`,
                    params
                )
                .then((response) => {
                    if (response.data.status == "OK") {
                        this.convenios = response.data.convenios;
                    }
                });
        },
    }
}