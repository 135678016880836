import axios from "axios";
import VueJsonToCsv from 'vue-json-to-csv'
import { deleteToken } from "../../../../../store/Funciones/funciones";
import SnackBarErrorIncidencias from "../../../General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue"
export default {
    name: 'admon-colaborador-generalista',
    components: { VueJsonToCsv, SnackBarErrorIncidencias },
    props: [],
    data() {
        return {
            textoBarra: '',
            colorBarra: '',
            muestraBarra: false,
            headers: [
                { text: 'ID Area', value: 'IdArea', align: 'center', sortable: true },
                { text: 'Dirección', value: 'Area', align: 'center', sortable: true },
                { text: 'Generalista', value: 'nombreGeneralista', align: 'center', sortable: true },
                { text: 'Correo', value: 'correoGeneralista', align: 'center', sortable: true },
                { text: 'Telefono', value: 'telefonoGeneralista', align: 'center', sortable: true },
                { text: 'Acciones', value: 'actions', align: 'center', sortable: false },
            ],
            colaboradores: [],
            generalistas: [],
            headers_report_to_export: {
                IdArea: { title: "IdArea" },
                Area: { title: "Dirección" },
                nombreGeneralista: { title: "Generalista" },
                correoGeneralista: { title: "Correo" },
                telefonoGeneralista: { title: "Telefono" },
            },
            cargando: true,
            buscar: '',
            nameFile: '',
            dialogForm: false,
            loading: false,
            editedIndex: -1,
            editedItem: {
                IdArea: '',
                Area: '',
                nombreGeneralista: '',
                correoGeneralista: '',
                telefonoGeneralista: '',
                folioGeneralista: '',
            },
        }
    },
    computed: {
        formTitle() {
            return this.editedIndex === -1 ? 'Nuevo generalista' : 'Editar generalista'
        },
    },
    mounted() {
        this.getRelacionColaboradoresGeneralistas();
        //  window.document.title = "DH:URREA - Asignacion de generalistas.";
    },
    methods: {
        guardarGeneralista() {
            console.log(this.editedItem);
            let params = {
                Opcion: 2,
                token: localStorage.getItem("token"),
                formulario: this.editedItem
            };
            axios.post(`${this.$store.getters.getHost}/RelacionesLaborales/AsignacionGeneralistas/ws-asignacionGeneralistas.php`, params).then((response) => {
                    if (response.data.status.trim() == "EXPSESSION") {
                        deleteToken();
                    } else if (response.data.status.trim() == "OK") {
                        console.log(response.data);
                        this.textoBarra = 'Generalista asignado correctamente';
                        this.colorBarra = 'success';
                        this.muestraBarra = true;
                        this.dialogForm = false;
                        this.cargando = true;
                        this.colaboradores = [];
                        this.getRelacionColaboradoresGeneralistas();

                    } else {
                        this.textoBarra = 'Ocurrió un error';
                        this.colorBarra = 'error';
                        this.muestraBarra = true;
                    }
                    this.cargando = false;
                })
                .catch(function(e) {
                    let result = e.toString().includes("401");
                    if (result) {
                        window.location.reload();
                    } else {
                        alert(e);
                    }
                });
        },


        addCorreo() {
            if (this.editedItem.nombreGeneralista) {
                let resultdo = this.generalistas.filter((dato) => dato["EMPLID"] == this.editedItem.nombreGeneralista.EMPLID);
                this.editedItem.correoGeneralista = resultdo[0]['correo'];
                this.editedItem.nombreGeneralista = resultdo[0]['NAME'];
                this.editedItem.folioGeneralista = resultdo[0]['EMPLID'];
            } else {
                this.editedItem.correoGeneralista = '';
                this.editedItem.folioGeneralista = '';
            }
            // this.muestra = true;
            // this.getDataEmpleado(this.numeroEmpleado);
        },

        getColaboradoresActivos() {
            if (this.generalistas.length == 0) {
                let params = {
                    Opcion: 11,
                    token: localStorage.getItem("token"),
                };
                axios.post(`${this.$store.getters.getHost}/General/ws-general.php`, params).then((response) => {
                        if (response.data.status.trim() == "EXPSESSION") {
                            deleteToken();
                        } else {
                            var data = response.data.colaboradores;
                            if (data) {
                                this.generalistas = data;
                            }
                        }
                        this.cargando = false;
                    })
                    .catch(function(e) {
                        let result = e.toString().includes("401");
                        if (result) {
                            window.location.reload();
                        } else {
                            alert(e);
                        }
                    });
            }
        },
        close() {
            this.dialogForm = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },

        editItem(item) {
            this.editedIndex = this.colaboradores.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.dialogForm = true;
            this.getColaboradoresActivos();
        },

        getRelacionColaboradoresGeneralistas() {
            let params = {
                Opcion: 1,
                token: localStorage.getItem("token"),
            };
            axios.post(`${this.$store.getters.getHost}/RelacionesLaborales/AsignacionGeneralistas/ws-asignacionGeneralistas.php`, params).then((response) => {
                    if (response.data.status.trim() == "EXPSESSION") {
                        deleteToken();
                    } else {
                        var data = response.data.data;
                        if (data) {
                            this.colaboradores = data;
                        }
                    }
                    this.cargando = false;
                })
                .catch(function(e) {
                    let result = e.toString().includes("401");
                    if (result) {
                        window.location.reload();
                    } else {
                        alert(e);
                    }
                });
        },

        fixName() {
            let numero_aleatorio =
                Math.floor(Math.random() * (99999 - 10000 + 1)) + 10000;
            this.nameFile = "AsignacionDeGeneralistas_" + numero_aleatorio.toString();
        },
    }
}