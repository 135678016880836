import axios from 'axios'
import {
    deleteToken
} from '../../../../store/Funciones/funciones'
export default {
    name: 'adm-accesos-reclu',
    components: {},
    props: [],
    data() {
        return {
            accesos: [],
            accesos_ti: [],
            accesos_gen: [],
            accesos_admin: [],
            accesos_reclu: [],
            accesos_compensaciones: [],
            colaboradores: [],
            usuarioEnLinea: {
                id: 0,
                emplid: 0,
                EMP_NOMEMP: "",
                EMP_STATUS: "",
                correo: "",
                descSistema: "",
                rol: ""
            },
            headers: [{
                    text: "ID",
                    align: "start",
                    value: "id",
                    class: 'text-uppercase font-weight-bold'
                },
                {
                    text: "# de empleado",
                    align: "center",
                    value: "emplid",
                    class: 'text-uppercase font-weight-bold'
                },
                {
                    text: "Nombre",
                    align: "center",
                    value: "EMP_NOMEMP",
                    class: 'text-uppercase font-weight-bold'
                },
                {
                    text: "Aciones",
                    align: "center",
                    value: "actions",
                    class: 'text-uppercase font-weight-bold'
                },
            ],
            userRol: "",
            emplSelected: "",
            textoAlert: "",
            colorAlert: "",
            muestraCargaUsuario: false,
            alert: false,
            dialogRemover: false,
            loadingColabs: true,
            loadingGuardar: false,
            idRemove: 0
        }
    },
    computed: {

    },
    created() {
        this.getAccesos();
    },
    mounted() {
        //  window.document.title = "DH:URREA - Admón de accesos reclutamiento.";
        this.getColaboradores();
    },
    methods: {
        getColaboradores() {
            let params = {
                Opcion: 3,
                token: localStorage.getItem('token')
            }
            axios
                .post(`${ this.$store.getters.getHost }/RelacionesLaborales/Reportes/ws-reporte-asistencias.php `, params)
                .then((response) => {
                    this.loadingColabs = false;
                    if (response.data.status == "EXPSESSION") {
                        deleteToken();
                    } else {
                        var data = response.data.colaboradores;
                        this.colaboradores = data;
                        this.loadingColabs = false;
                    }
                })
        },
        getAccesos() {
            let params = {
                Opcion: 5,
                token: localStorage.getItem('token')
            }
            axios.post(
                `${this.$store.getters.getHost}/Reclutamiento/ws-admin-reclu.php`,
                params
            ).then((response) => {
                if (response.data.status == "EXPSESSION") {
                    deleteToken();
                } else {
                    var data = response.data.accesos;
                    data.map((acc) => {
                        if (acc.rol == 'ADMINTI') {
                            this.accesos_ti.push(acc);
                        } else if (acc.rol == 'ADMINGEN') {
                            this.accesos_gen.push(acc);
                        } else if (acc.rol == 'ADMIN') {
                            this.accesos_admin.push(acc);
                        } else if (acc.rol == 'RECLUTADOR') {
                            this.accesos_reclu.push(acc);
                        } else if (acc.rol == "COMPENSACIONES") {
                            this.accesos_compensaciones.push(acc);
                        }
                    })

                    this.accesos.push({
                        title: 'Admin. TI',
                        content: this.accesos_ti
                    })

                    this.accesos.push({
                        title: 'Admin. General',
                        content: this.accesos_gen
                    })

                    this.accesos.push({
                        title: 'Admin.',
                        content: this.accesos_admin
                    })

                    this.accesos.push({
                        title: 'Reclutadoras(es)',
                        content: this.accesos_reclu
                    })

                    this.accesos.push({
                        title: 'Compensaciones',
                        content: this.accesos_compensaciones
                    })


                    this.usuarioEnLinea = data.filter(u => { return u.emplid == this.$store.getters.getUser['Folio'] });
                    if (this.usuarioEnLinea.length > 0) {
                        this.userRol = this.usuarioEnLinea[0].rol;
                    }
                    // console.log(this.usuarioEnLinea)

                }
            });
        },
        buscaUsuario(valor) {
            return valor.emplid == this.$store.getters.getUser['Folio']
        },
        buscaEmpNum(valor) {
            return valor.nombreMostrar == this.emplSelected;
        },

        mustraDialigCargaUsuario(titulo) {
            if (titulo.trim() == "Admin. TI") {
                this.perfilAsignar = "ADMINTI"
            } else if (titulo.trim() == "Admin. General") {
                this.perfilAsignar = "ADMINGEN"
            } else if (titulo.trim() == "Admin.") {
                this.perfilAsignar = "ADMIN"
            } else if (titulo.trim() == "Reclutadoras(es)") {
                this.perfilAsignar = "RECLUTADOR"
            } else if (titulo.trim() == "Compensaciones") {
                this.perfilAsignar = "COMPENSACIONES"
            }

            this.muestraCargaUsuario = true;

        },

        guardarUsuario() {
            let user = this.colaboradores.filter(this.buscaEmpNum);
            console.log(user)
            this.loadingGuardar = true;
            let params = {
                Opcion: 6,
                token: localStorage.getItem('token'),
                perfil: this.perfilAsignar,
                empleado: user[0].EMPLID
            }
            console.log(params)
            axios
                .post(`${ this.$store.getters.getHost }/Reclutamiento/ws-admin-reclu.php`, params)
                .then((response) => {
                    if (response.data.status == "EXPSESSION") {
                        deleteToken();
                    } else {
                        var data = response.data.respuesta;
                        this.validaGuardar(data)
                    }
                })
        },

        validaGuardar(data) {
            this.loadingGuardar = false;
            this.muestraCargaUsuario = false;
            if (data == "success") {
                this.textoAlert = "Registro actualizado con éxito."
                this.colorAlert = "success";
                this.alert = true;
                this.accesos.length = 0;
                this.accesos_ti.length = 0;
                this.accesos_admin.length = 0;
                this.accesos_gen.length = 0;
                this.accesos_reclu.length = 0;
                this.getAccesos();
            } else {
                this.textoAlert = "Ocurrió un error al intentar modificar el registro." +
                    "(" + data + ")"
                this.colorAlert = "error";
                this.alert = true;
            }
        },

        confirmaRemover(id) {
            this.idRemove = id;
            this.dialogRemover = true;
        },

        removerUsuario() {
            this.loadingGuardar = true;
            let params = {
                Opcion: 7,
                token: localStorage.getItem('token'),
                id: this.idRemove
            }
            console.log(params)
            axios
                .post(`${ this.$store.getters.getHost }/Reclutamiento/ws-admin-reclu.php`, params)
                .then((response) => {
                    if (response.data.status == "EXPSESSION") {
                        deleteToken();
                    } else {
                        var data = response.data.respuesta;
                        this.validaGuardar(data)
                    }
                    this.idRemove = 0;
                    this.dialogRemover = false;
                })
        },

    }
}