<template>
  <v-dialog
    v-model="dialog"
    max-width="600px"
    transition="dialog-bottom-transition"
    persistent
  >
    <v-card>
      <v-card-title class="text-subtitle-2 primary white--text">
        Nuevo Motivo
        <v-spacer></v-spacer>
        <v-icon
          small
          dark
          @click="$emit('cierraFormMotivosInconformidades', 'cancelar')"
          >mdi-close</v-icon
        >
      </v-card-title>
      <v-card-text class="pt-5">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row>
            <v-col cols="12" xl="12" lg="12" md="12" sm="12" xs="12">
              <v-text-field
                v-model="form.nombre"
                label="Nombre de nuevo motivo"
                maxlength="100"
                type="text"
                required
                outlined
                counter
                dense
                background-color="grey lighten-2"
                color="cyan"
              ></v-text-field>
            </v-col>
            <v-col
              class="py-0"
              cols="12"
              xl="12"
              lg="12"
              md="12"
              sm="12"
              xs="12"
            >
              <v-combobox
                v-model="form.departamento"
                :items="departamentos"
                :loading="loadingDeptos"
                label="Departamento"
                :disabled="!relacionarDepto"
                hide-selected
                outlined
                dense
                small-chips
                item-text="deptoMostrar"
                item-value="id"
                background-color="grey lighten-2"
                color="cyan"
                clearable
              >
                <template v-slot:selection="data">
                  <v-chip
                    :key="JSON.stringify(data.item)"
                    v-bind="data.attrs"
                    :input-value="data.selected"
                    :disabled="data.disabled"
                    @click:close="data.parent.selectItem(data.item)"
                  >
                    <v-avatar class="cyan darken-4 white--text" left></v-avatar>
                    {{ data.item.deptoMostrar }}
                  </v-chip>
                </template>
              </v-combobox>
            </v-col>
            <v-col
              class="py-0"
              cols="12"
              xl="12"
              lg="12"
              md="12"
              sm="12"
              xs="12"
            >
              {{ form.departamento }}
              <v-checkbox
                v-model="relacionarDepto"
                class="my-0"
                @click="
                  form.departamento = !relacionarDepto ? '' : form.departamento
                "
              >
                <template v-slot:label>
                  <div>
                    Este motivo estará relacionado con un departamento
                    <v-tooltip top color="primary">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          style="cursor: pointer"
                          color="primary"
                          v-bind="attrs"
                          v-on="on"
                        >
                          mdi-chat-question
                        </v-icon>
                      </template>
                      <label class="text-uppercase text-center white--text"
                        >Si no selecciona un departamento <br />
                        le harán llegar las inconformidades de éste a: <br />
                        <b>bzepeda@urrea.net</b></label
                      >
                    </v-tooltip>
                  </div>
                </template></v-checkbox
              >
            </v-col>
            <v-col
              class="d-flex align-center"
              cols="12"
              xl="12"
              lg="12"
              md="12"
              sm="12"
              xs="12"
            >
              <v-radio-group
                class="mt-0"
                v-model="form.estatus"
                :row="
                  $vuetify.breakpoint.name === 'sm' ||
                  $vuetify.breakpoint.name === 'xs'
                    ? false
                    : true
                "
              >
                <v-radio value="1" color="green darken-4">
                  <template v-slot:label>
                    Habilitado
                    <v-icon color="green darken-4" large> mdi-menu-up </v-icon>
                  </template>
                </v-radio>
                <v-radio value="0" color="red darken-4">
                  <template v-slot:label>
                    Inhabilitado<v-icon color="red darken-4" large>
                      mdi-menu-down
                    </v-icon>
                  </template>
                </v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <div>
          <v-btn
            v-if="form.nombre && form.estatus"
            class="rounded-lg elevation-0"
            color="teal darken-1"
            :loading="loading"
            text
            small
            @click="validar"
          >
            <v-icon left>mdi-content-save-check-outline</v-icon> Guardar
          </v-btn>
          <v-btn class="rounded-lg elevation-0" v-else small disabled>
            <v-icon left>mdi-content-save-check-outline</v-icon> Guardar
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
    <snack-bar-error-incidencias
      v-if="muestraBarra"
      :muestraBarra="muestraBarra"
      :texto="textoBarra"
      :color="colorBarra"
      @cierraBarra="muestraBarra = false"
    >
    </snack-bar-error-incidencias>
  </v-dialog>
</template>

<script>
import axios from "axios";
import { deleteToken } from "../../store/Funciones/funciones";
import SnackBarErrorIncidencias from "../../views/generated/General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue";

export default {
  name: "inconformidades-form-motivos",
  components: { SnackBarErrorIncidencias },
  props: ["dialog"],
  data: () => ({
    textoBarra: "",
    colorBarra: "",
    muestraBarra: false,

    valid: true,
    form: {
      nombre: "",
      departamento: "",
      estatus: null,
    },
    departamentos: [],
    loading: false,
    loadingDeptos: true,
    relacionarDepto: false,
  }),
  created() {},
  mounted() {
    this.getDepartamentos();
  },
  methods: {
    getDepartamentos() {
      let params = {
        Opcion: 4,
        token: localStorage.getItem("token"),
      };
      axios
        .post(
          `${this.$store.getters.getHost}/Nominas/AdmInconformidades/ws-inconformidades.php`,
          params
        )
        .then((response) => {
          this.loadingDeptos = false;
          if (response.data.status == "EXPSESSION") {
            deleteToken();
          } else {
            this.departamentos = response.data.departamentos;
          }
        });
    },

    validar() {
      if (this.relacionarDepto) {
        if (this.form.departamento) {
          this.guardar();
        } else {
          this.textoBarra =
            "Seleccione un departamento o no marque la casilla.";
          this.colorBarra = "primary";
          this.muestraBarra = true;
        }
      } else {
        this.guardar();
      }
    },
    guardar() {
      let params = {
        Opcion: 5,
        token: localStorage.getItem("token"),
        form: this.form,
      };
      console.log(params);
      this.loading = true;
      axios
        .post(
          `${this.$store.getters.getHost}/Nominas/AdmInconformidades/ws-inconformidades.php`,
          params
        )
        .then((response) => {
          this.loading = false;
          if (response.data.status == "OK") {
            this.textoBarra = "Nuevo motivo registrado.";
            this.colorBarra = "success";
            this.muestraBarra = true;
            this.clean();
            return new Promise((resolve) => {
              setTimeout(() => {
                resolve(this.$emit("cierraFormMotivosInconformidades", "OK"));
              }, 2000);
            });
          } else if (response.data.status == "EXISTENTE") {
            this.textoBarra = "Ya se encuentra un motivo registrado.";
            this.colorBarra = "info";
            this.muestraBarra = true;
          } else if (response.data.status == "ERROR") {
            this.textoBarra = "Se ha producido un error al registrar.";
            this.colorBarra = "error";
            this.muestraBarra = true;
          } else if (response.data.status == "EXPRESSION") {
            deleteToken();
          }
        });
    },

    clean() {
      this.form.nombre = "";
      this.form.departamento = "";
      this.form.estatus = null;
    },
  },
};
</script>
