<template>
  <div>
    <v-dialog
      v-model="verCandidatos"
      persistent
      scrollable
      max-width="900"
      content-class="fix-border-dialog"
    >
      <v-card class="rounded-xl back-card-candidatos">
        <v-card-title class="primary white--text subtitle-1">
          Candidatos postulados
          <v-spacer></v-spacer>
          <v-icon dark @click="$emit('cerrarVerCandidatos')">
            mdi-close
          </v-icon>
        </v-card-title>
        <v-card-text class="pa-1">
          <div v-if="loadingContent" class="center-item-in-div-column">
            <div class="text-subtitle-1 text-center">
              Cargando candidatos...
            </div>
            <div style="max-width:300px !important;">
              <v-progress-linear
                color="primary"
                indeterminate
                rounded
                height="6"
              ></v-progress-linear>
            </div>
          </div>
          <div v-else>
            <v-data-table
              :headers="headers"
              :items="candidatos"
              dense
              loading-text="Cargando contentenido..."
              :footer-props="{
                'items-per-page-options': [10, 20, 30, 100],
                'items-per-page-text': 'Elementos por página',
              }"
              no-data-text="No hay registros"
              :header-props="{
                'short-by-text': 'Ordenar por',
              }"
              class="elevation-0 back-glass rounded-xl"
            >
              <template v-slot:item.Candidato="{ item }">
                <v-btn
                  class="text-uppercase caption font-weight-bold light-blue--text text--darken-1 text-decoration-underline"
                  text
                  small
                  @click="
                    idCandidatoSeleccionado = item.id;
                    muestraDetallesCandidato = true;
                  "
                >
                  {{ item.Candidato }}
                </v-btn>
              </template>
              <template v-slot:item.Descr="{ item }">
                {{ getEstatus(item.Descr) }}
              </template>
              <template v-slot:item.Postulacion="{ item }">
                {{ fixFormatDate(item.Postulacion.date.substring(0, 10)) }}
              </template>
              <template v-slot:item.actions="{ item }">
                <v-tooltip top color="cyan darken-2">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="mr-1"
                      v-if="item.Descr == 'Seleccion'"
                      small
                      rounded
                      depressed
                      color="cyan darken-2"
                      dark
                      v-bind="attrs"
                      v-on="on"
                      @click="openConfirm(item, 1)"
                    >
                      <v-icon small>mdi-account-check</v-icon>
                    </v-btn>
                  </template>
                  <div class="caption font-weight-bold">Seleccionar</div>
                </v-tooltip>
                <v-tooltip top color="pink darken-3">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-if="item.Descr != 'Oferta_Economica'"
                      small
                      rounded
                      depressed
                      color="pink darken-3"
                      dark
                      v-bind="attrs"
                      v-on="on"
                      @click="openConfirm(item, 2)"
                    >
                      <v-icon small>mdi-briefcase-clock-outline</v-icon>
                    </v-btn>
                  </template>
                  <div class="caption font-weight-bold">Enviar a bolsa</div>
                </v-tooltip>
              </template>

              <template v-slot:item.info="{ item }">
                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      small
                      color="blue-grey lighten-1"
                      v-bind="attrs"
                      v-on="on"
                      >mdi-information-outline</v-icon
                    >
                  </template>
                  <div>
                    <div v-if="item.Descr == 'Seleccion'">
                      <strong>"Seleccionar" </strong> el candidato <br />
                      seleccionado será enviado a oferta económica.<br />
                      <br />
                    </div>
                    <div>
                      <strong>"Enviar a bolsa" </strong> el candidato <br />
                      será enviado a bolsa de trabajo y no se tomará <br />
                      en cuenta.
                      <br />
                    </div>
                  </div>
                </v-tooltip>
              </template>
            </v-data-table>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <ConfirmDialogReclutamiento
      v-if="showConfirm"
      :showConfirm="showConfirm"
      :question="question"
      @cierraConfirm="closeConfirm"
    />
    <DetallesCandidato
      v-if="muestraDetallesCandidato"
      :muestraDetallesCandidato="muestraDetallesCandidato"
      :idCandidato="idCandidatoSeleccionado"
      @cierraDetallesCandidato="muestraDetallesCandidato = false"
    />
    <SnackBar
      v-if="muestraBarra"
      :muestraBarra="muestraBarra"
      :color="colorBarra"
      :texto="textoBarra"
      @cierraBarra="muestraBarra = false"
    />

    <DialogComentarioRechazo :commentReject="muestraDialogComentario" :isCandidate="true" @ocultaDialogComentario="validateComment"/>

  </div>
  
</template>

<script>
import axios from "axios";
import ConfirmDialogReclutamiento from "../../../../AdmnVacantes/components/ConfirmDialogReclutamiento.vue";
import DetallesCandidato from "../../../../AdmnVacantes/components/DetallesCandidato.vue";
import DialogComentarioRechazo from "../../../../AdmnVacantes/components/DialogComentarioRechazo.vue";
import SnackBar from "../../../MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue";

import {
  deleteToken,
  formatDate,
} from "../../../../../../store/Funciones/funciones";

export default {
  name: "ver-candidatos-postulados",
  props: ["verCandidatos", "idVacante"],
  components: { ConfirmDialogReclutamiento, DetallesCandidato, DialogComentarioRechazo, SnackBar },
  data() {
    return {
      loadingContent: true,
      showConfirm: false,
      muestraDetallesCandidato: false,
      muestraBarra: false,
      muestraDialogComentario: false,
      colorBarra: "",
      textoBarra: "",
      question: "",
      idCandidatoSeleccionado: 0,
      tipoAccion: 0,
      candidatoTemporal: {},
      candidatos: [],
      headers: [
        {
          text: "ID",
          align: "center",
          class:
            "font-weight-bold blue-grey--text text--darke-3 text-uppercase",
          value: "id",
        },
        {
          text: "Candidato",
          class:
            "font-weight-bold blue-grey--text text--darke-3 text-uppercase",
          value: "Candidato",
        },
        {
          text: "Estatus",
          align: "center",
          class:
            "font-weight-bold blue-grey--text text--darke-3 text-uppercase",
          value: "Descr",
        },
        {
          text: "Fecha de postulación",
          align: "center",
          class:
            "font-weight-bold blue-grey--text text--darke-3 text-uppercase",
          value: "Postulacion",
        },
        {
          text: "Acciones",
          align: "center",
          class:
            "font-weight-bold blue-grey--text text--darke-3 text-uppercase",
          value: "actions",
        },
        {
          text: "",
          align: "center",
          class:
            "font-weight-bold blue-grey--text text--darke-3 text-uppercase",
          value: "info",
        },
      ],
    };
  },
  created() {},
  mounted() {
    this.getCandidatos();
  },
  methods: {
    muestraSnackBar(text, color) {
      this.colorBarra = color;
      this.textoBarra = text;
      this.muestraBarra = true;
    },
    getCandidatos() {
      this.candidatos.length = 0;
      let params = {
        Opcion: 13,
        token: localStorage.getItem("token"),
        idVacante: this.idVacante,
      };
      axios
        .post(
          `${this.$store.getters.getHost}/Reclutamiento/ws-control-de-puestos.php`,
          params
        )
        .then((response) => {
          this.loadingContent = false;
          if (response.data.status != "EXPSESSION") {
            this.candidatos = response.data.candidatos;
          } else {
            deleteToken();
          }
        });
    },

    getEstatus(estatus) {
      if (estatus == "Validacion_Jefe_directo") {
        return "En validación con jefe directo";
      } else if (estatus == "Psicometrias_y_Examenes_tecnicos") {
        return "Psicométrias y exámenes técnicos";
      } else if (estatus == "Entrevista_Reclutamiento") {
        return "Entrevistas con reclutamiento";
      } else if (estatus == "Apego_perfil") {
        return "Llenado de apego a perfil";
      } else if (estatus == "Agenda") {
        return "Agenda";
      } else if (estatus == "Entrevistas") {
        return "Entrevistas";
      } else if (estatus == "Agenda_comite") {
        return "Agenda comité";
      } else if (estatus == "Espera comité") {
        return "Espera comité";
      } else if (estatus == "Comité") {
        return "Comité";
      } else if (estatus == "Seleccion") {
        return "Selección";
      } else if (estatus == "Oferta_Economica") {
        return "Oferta económica";
      } else if (estatus == "Completado") {
        return "Completado";
      } else if (estatus == "Bolsa_de_Trabajo") {
        return "Bolsa de trabajo";
      } else if (estatus == "Rechazado") {
        return "Rechazado";
      } else {
        return "Sin estatus";
      }
    },

    openConfirm(item, accion) {
      this.candidatoTemporal = item;
      this.tipoAccion = accion;
      if (accion === 1) {
        this.question =
          "¿Realmente deseas enviar la solicitud de carta de oferta económica para " +
          item.Candidato +
          "? Esta acción ocupará la posición.";
           this.showConfirm = true;
      } else {
        this.question =
          "¿Realmente deseas enviar a " +
          item.Candidato +
          " a la bolsa de trabajo?";
          this.muestraDialogComentario = true;
      }
     
    },

    closeConfirm(respuesta){
      if(!respuesta){
        this.showConfirm = false;
      } else {
        this.showConfirm = false;
        if(this.tipoAccion === 1){
          this.updateStatusCandidate(11);
        }
      }
    },

    validateComment(restuesta){
      if(restuesta !== null){
        this.closeCommentReject(restuesta)
      }
      this.muestraDialogComentario = false;
    },

    closeCommentReject(comment) {
      if (comment !== null) {
        const params = {
          Opcion: 21,
          token: localStorage.getItem("token"),
          idCandidato: this.candidatoTemporal.id,
          comentario: comment,
        };
        axios
          .post(
            `${this.$store.getters.getHost}/Reclutamiento/ws-admin-candidatos.php`,
            params
          )
          .then((response) => {
            if (response.data.status != "EXPSESSION") {
              if (response.data.status == "OK") {
                if (response.data.respuesta.status_tran == "OK") {
                  this.muestraSnackBar(
                    response.data.respuesta.message,
                    "teal darken-1"
                  );
                  this.loadingContent = true;
                  this.getCandidatos();
                } else {
                  this.muestraSnackBar(
                    response.data.respuesta.message,
                    "pink darken-3"
                  );
                }
              } else {
                this.muestraSnackBar("Ocurrio un error", "pink darken-3");
              }
            } else {
              deleteToken();
            }
          })
          .catch((e) => {
            this.muestraSnackBar(e, "pink darken-3");
          });
      }
    },

    updateStatusCandidate(newStatus){
      const params = {
        Opcion: 20,
        token: localStorage.getItem('token'),
        nuevoEstatus: newStatus,
        estatus: this.candidatoTemporal.Estatus,
        idCandidato: this.candidatoTemporal.id,
        nombre: this.candidatoTemporal.Candidato,
        correo: this.candidatoTemporal.Mail,
      }
      axios
        .post(
          `${this.$store.getters.getHost}/Reclutamiento/ws-admin-candidatos.php`,
          params
        ).then((response) => {
          if(response.data.status === "EXPRESSION"){
            deleteToken();
          } else {
            if(response.data.respuesta.tipo === 2){
              this.muestraSnackBar(response.data.respuesta.mensaje, "success");
              this.loadingContent = true;
              this.getCandidatos();
            } else {
              this.muestraSnackBar(response.data.respuesta.mensaje, "error")
            }
          }
        }).catch(error => {
          this.muestraSnackBar(error, "error")
        })
    },

    fixFormatDate(date) {
      return formatDate(date);
    },
  },
};
</script>

<style>
.back-card-candidatos {
  background: rgb(224, 243, 255);
  background: linear-gradient(
    126deg,
    rgba(224, 243, 255, 1) 4%,
    rgba(250, 250, 250, 1) 74%,
    rgba(235, 235, 235, 1) 100%
  );
}

.text-title {
  font-weight: bolder !important;
  font-size: 1.5rem !important;
  letter-spacing: normal !important;
  color: rgb(0, 120, 195);
}

.back-glass {
  background: rgba(230, 230, 230, 0.25) !important;
  border-radius: 8px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);
}
</style>
