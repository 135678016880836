<template>
  <v-card class="rounded-lg pa-2" elevation="3">
    <v-data-table
      :headers="headers"
      :items="participantes"
      :items-per-page="itemsPerPage"
      :footer-props="{
        'items-per-page-options': [5, 10, 50, 100],
        'items-per-page-text': 'Elementos por página',
      }"
      dense
      no-data-text="No hay registros"
      :header-props="headerProps"
      :loading="loadingTabla"
      :search="search"
      item-key="ID_EMPLEADO"
      class="elevation-2"
    >
      <template v-slot:top>
        <v-toolbar flat color="primary" class="rounded-lg rounded-b-0">
          <v-text-field
            v-model="search"
            :label="$t('DH360.findTextBox')"
            :loading="loadingTabla"
            class="mt-7"
            dense
            clearable
            flat
            solo-inverted
            dark
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-divider
            class="mx-6"
            inset
            vertical
            style="border-color:lightslategrey"
          ></v-divider>
          <vue-json-to-csv
            :json-data="participantes"
            :labels="headers_report_to_export"
            :csv-title="nameFile"
          >
            <v-btn
              color="green darken-1"
              dark
              class="mb-1 font-weight-bold rounded-lg"
              :loading="loadingTabla"
            >
              <v-icon left>mdi-microsoft-excel</v-icon>
              {{ $t("DH360.export") }}
            </v-btn>
          </vue-json-to-csv>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small @click="openDelete(item)">
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>

    <v-dialog v-model="confirmDelete" max-width="450" scrollable persistent>
      <v-card class="rounded-lg">
        <p
          class="text-center pt-4 px-2"
          style="font-size: 1.1rem; font-weight:600; color:darkslategray"
        >
          {{ $t("DH360.confirmTextDeleteParticipant") }}
          {{ participantToDelete }} ?
        </p>
        <v-card-actions style="display:flex; justify-content: space-around;">
          <v-btn
            class="rounded-lg"
            large
            depressed
            color="pink darken-3"
            width="180"
            :disabled="loadButtonDelPart"
            dark
            @click="
              closeDelete();
            "
          >
            {{ $t("DH360.cancel") }}
          </v-btn>
          <v-btn
            class="rounded-lg"
            large
            depressed
            color="teal"
            width="180"
            :loading="loadButtonDelPart"
            dark
            @click="
              loadButtonDelPart = true;
              delParticipant(itemToDelete);
            "
          >
            {{ $t("DH360.confirm") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <SnackBar
      :muestraBarra="muestraBarra"
      :color="colorBarra"
      :texto="textoBarra"
      @cierraBarra="muestraBarra = false"
    />
  </v-card>
</template>

<script>
import axios from "axios";
import { deleteToken } from "../../../../../../store/Funciones/funciones";
import VueJsonToCsv from "vue-json-to-csv";
import SnackBar from "../../../../General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue";

export default {
  name: "reporte-participantes-360",
  components: { VueJsonToCsv, SnackBar },
  props: ["idProyecto", "compania"],
  data() {
    return {
      participantes: [],
      headers: [
        { value: "ID_EMPLEADO", text: "EMPLID" },
        { value: "NAME", text: "" },
        { value: "PUESTO", text: "" },
        { value: "DEPTO", text: "" },
        { value: "Area", text: "" },
        { value: "EMP_STATUS", align: "center", text: "" },
        { value: "actions", align: "center", text: "" },
      ],
      headers_report_to_export: {
        ID_EMPLEADO: {
          title: "EMPLID",
        },
        NAME: {
          title: "Nombre",
        },
        PUESTO: {
          title: "Puesto",
        },
        DEPTO: {
          title: "Departamento",
        },
        Area: {
          title: "Área",
        },
        EMP_STATUS: {
          title: "Estatus",
        },
      },
      headerProps: {
        sortByText: "Ordenar por",
      },
      itemToDelete: {
        ID_EMPLEADO: 0,
        NAME: "",
        PUESTO: "",
        DEPTO: "",
        Area: "",
        EMP_STATUS: "",
        NombreMostrar: "",
        Status: 0,
      },
      itemsPerPage: 10,
      loadingTabla: true,
      muestraBarra: false,
      confirmDelete: false,
      loadButtonDelPart: false,
      colorBarra: "",
      textoBarra: "",
      search: "",
      nameFile: "",
      participantToDelete: ""
    };
  },
  computed: {},
  mounted() {
    this.headers[1].text = this.$t("DH360.nameTextHeader");
    this.headers[2].text = this.$t("DH360.positionTextHeader");
    this.headers[3].text = this.$t("DH360.depTextHeader");
    this.headers[4].text = this.$t("DH360.areaTextHeader");
    this.headers[5].text = this.$t("DH360.statusTextHeader");
    this.headers[6].text = this.$t("DH360.actionsTextHeader");
    let ahora = new Date();
    this.nameFile =
      "participantes" +
      ahora.getDate().toString() +
      ahora.getMonth().toString() +
      ahora.getUTCFullYear().toString() +
      ahora.getUTCHours().toString() +
      ahora.getUTCMinutes().toString() +
      ahora.getUTCMilliseconds().toString();
    this.getParticipantes();
  },
  methods: {
    getParticipantes() {
      let params = {
        Opcion: 11,
        token: localStorage.getItem("token"),
        idProyecto: this.idProyecto,
        compania:this.compania,
        editarRelaciones: 1,
      };
      axios
        .post(
          `${this.$store.getters.getHost}/RelacionesLaborales/AdmonEvaluacionesDesempenio/ws-admon-360.php`,
          params
        )
        .then((response) => {
          if (response.data.status == "EXPRESSION") {
            deleteToken();
          } else if (response.data.status == "OK") {
            this.participantes = response.data.participantes;
            this.loadingTabla = false;
          }
        });
    },

    openDelete(item) {
      this.participantToDelete = item.NAME;
      this.itemToDelete = item;
      this.confirmDelete = true;
    },

    closeDelete() {
      this.confirmDelete = false;
      this.participantToDelete = "";
      this.itemToDelete = {
        ID_EMPLEADO: 0,
        NAME: "",
        PUESTO: "",
        DEPTO: "",
        Area: "",
        EMP_STATUS: "",
        NombreMostrar: "",
        Status: 0,
      };
    },

    delParticipant(item) {
      let editedIndex = this.participantes.indexOf(item);
      this.participantes.splice(editedIndex, 1);

      let params = {
        Opcion: 16,
        token: localStorage.getItem("token"),
        idProyecto: this.idProyecto,
        empleado: item.ID_EMPLEADO,
        status: item.Status,
      };
      axios
        .post(
          `${this.$store.getters.getHost}/RelacionesLaborales/AdmonEvaluacionesDesempenio/ws-admon-360.php`,
          params
        )
        .then((response) => {
          this.loadButtonDelPart = false;
          if (response.data.status == "EXPRESSION") {
            deleteToken();
          } else if (response.data.status == "OK") {
            this.colorBarra = "success";
            this.textoBarra = this.$t(
              "DH360.successfullyMessageDeleteParticipants"
            );
            this.muestraBarra = true;
            this.closeDelete();
          } else {
            this.colorBarra = "pink darken-3";
            this.textoBarra = this.$t("DH360.errorMessaggeDB") + ". :(";
            this.muestraBarra = true;
          }
        });
    },
  },
};
</script>
