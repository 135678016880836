<template>
  <v-container>
    <v-row>
      <v-col cols="12" xl="12" lg="12" md="12" sm="12" xs="12">
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          class="mb-5"
          label="Buscar"
          single-line
          hide-details
        ></v-text-field>
      </v-col>
    </v-row>
    <v-spacer></v-spacer>
    <v-data-table
      dense
      :loading="loadingContent"
      :headers="headers"
      :items="eppPorCertificaciones"
      sort-by-text="Ordenar por"
      no-data-text="No hay registros."
      items-per-page-all-text="Todos"
      items-per-page-text="Elementos por página"
      :show-current-page="false"
      :show-first-last-page="false"
      :search="search"
      loading-text="Cargando contenido..."
      :footer-props="{
        'items-per-page-options': [5, 10, 15, 20, 25],
        'items-per-page-text': 'Elementos por página',
      }"
      :single-expand="singleExpand"
      :expanded.sync="expanded"
      item-key="id"
      show-expand
      class="elevation-1"
    >
      <!-- <template v-slot:item.actions="{ item }">
        <div class="d-flex justify-center">
          <v-tooltip top color="teal lighten-2">
            <template v-slot:activator="{ on, attrs }">
              <v-chip-group column>
                <v-btn
                  x-small
                  rounded
                  elevation="1"
                  color="teal darken-1"
                  dark
                  @click="editItem(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon x-small>mdi mdi-plus</v-icon>
                </v-btn>
              </v-chip-group>
            </template>
            <span>Añadir</span>
          </v-tooltip>
          <v-tooltip top color="red accent-1">
            <template v-slot:activator="{ on, attrs }">
              <v-chip-group column>
                <v-btn
                  x-small
                  rounded
                  elevation="1"
                  color="red darken-1"
                  dark
                  v-bind="attrs"
                  v-on="on"
                  @click="confirmarDelete(item, 'masivo')"
                >
                  <v-icon x-small>mdi mdi-delete</v-icon>
                </v-btn>
              </v-chip-group>
            </template>
            <span>Remover</span>
          </v-tooltip>
        </div>
      </template> -->

      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length" class="px-10 py-5">
          <v-row v-if="item.operaciones">
            <v-col
              cols="12"
              xl="12"
              lg="12"
              md="12"
              sm="12"
              xs="12"
              class="d-flex justify-center align-center"
            >
              <v-list dense>
                <v-subheader>Certificaciones</v-subheader>
                <v-list-item-group color="primary">
                  <v-list-item v-for="(item, i) in item.operaciones" :key="i.id">
                    <v-list-item-icon>
                      <!-- <v-icon v-text="item.icon"></v-icon> -->
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>{{item.nombre}}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-col>
          </v-row>
          <v-row v-else class="pa-0">
            <v-col cols="12" color="blue-grey lighten-4 pa-0">
              <v-card>
                <v-card-text
                  class="d-flex justify-center subtitle-1 font-italic"
                  >Sin EPP asignado</v-card-text
                >
              </v-card>
            </v-col>
          </v-row>
        </td>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import axios from "axios";
import { deleteToken } from "../../../../../store/Funciones/funciones";
export default {
  name: "admon-upas-por-certificaciones",
  components: {},
  props: [],
  data() {
    return {
      //   textoBarra: "",
      //   colorBarra: "",
      //   muestraBarra: false,

      eppPorCertificaciones: [],
      search: "",
      loadingContent: true,
      //   loading: false,
      expanded: [],
      singleExpand: true,
      //   editedIndex: -1,
      headers: [
        { text: "ID", value: "id", align: "center", sortable: true },
        { text: "UPA", value: "Descripcion", align: "center", sortable: true },
        { text: "", value: "data-table-expand" },
      ],
      //   defaultItem: {
      //     id: "",
      //     nombre: "",
      //     estatus: "",
      //     DEPTID: "",
      //     nivel: "",
      //     // estatus: true,
      //   },
      //   editedItem: {
      //     id: "",
      //     nombre: "",
      //     estatus: "",
      //     DEPTID: "",
      //     nivel: "",
      //     // estatus: true,
      //   },
      //   dialogForm: false,
      //   dialogConfirm: false,
      //   idCertificacion: "",
      //   idUpa: "",
      //   tipo: "",
    };
  },
  computed: {},
  mounted() {
    this.getUpas();
  },
  methods: {
    getUpas() {
      this.eppPorCertificaciones = [];
      let params = {
        Opcion: 9,
        token: localStorage.getItem("token"),
      };
      this.loadingContent = true;
      axios
        .post(
          `${this.$store.getters.getHost}/General/EPP/ws-admon-epp.php`,
          params
        )
        .then((response) => {
          if (response.data.status == "OK") {
            this.eppPorCertificaciones = response.data.upas;
          } else if (response.data.status == "VACIO") {
            this.textoBarra = "No se encontraron registros.";
            this.colorBarra = "info";
            this.muestraBarra = true;
          } else if (response.data.status == "EXPRESSION") {
            deleteToken();
          }
        })
        .catch(function (e) {
          alert("Ocurrió un error: " + e);
        });
      this.loadingContent = false;
    },

    // editItem(item) {
    //   this.editedIndex = this.upas.indexOf(item);
    //   this.editedItem = Object.assign({}, item);
    //   this.dialogForm = true;
    // },

    // confirmarDelete(item, tipo) {
    //   this.dialogConfirm = true;
    //   this.tipo = tipo;
    //   if (tipo == "masivo") {
    //     this.idUpa = item.id;
    //   } else {
    //     this.idCertificacion = item.id;
    //   }
    // },
    // borraItem() {
    //   let params = {
    //     Opcion: 7,
    //     tipo: this.tipo,
    //     idUpa: this.idUpa,
    //     idCertificacion: this.idCertificacion,
    //     token: localStorage.getItem("token"),
    //   };
    //   axios
    //     .post(
    //       `${this.$store.getters.getHost}/SeguridadMedioAmbiental/UPA/ws-admonUpas.php`,
    //       params
    //     )
    //     .then((response) => {
    //       this.loading = false;
    //       if (response.data.status == "OK") {
    //         this.textoBarra = "Certificación removida correctamente.";
    //         this.colorBarra = "success";
    //         this.muestraBarra = true;
    //         this.close();
    //         this.getUpasXCertificaciones();
    //       } else if (response.data.status == "ERROR") {
    //         this.textoBarra = "Algo salió mal.";
    //         this.colorBarra = "error";
    //         this.muestraBarra = true;
    //       } else if (response.data.status == "EXPRESSION") {
    //         deleteToken();
    //       }
    //       this.loading = false;
    //     })
    //     .catch(function (e) {
    //       alert("Ocurrió un error: " + e);
    //     });
    //   this.loadingContent = false;
    // },

    // close() {
    //   this.dialogForm = false;
    //   this.dialogConfirm = false;
    //   this.idCertificacion = "";
    //   this.idUpa = "";
    //   this.tipo = "";
    //   this.$nextTick(() => {
    //     this.editedItem = Object.assign({}, this.defaultItem);
    //     this.editedIndex = -1;
    //   });
    // },
  },
};
</script>