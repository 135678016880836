<!-- TablasConTabs.vue -->
<template class="catalogo-sueldos pa-1">
    <v-card elevation="4" shaped class="pa-4 mx-auto">
        <v-tabs v-model="activeTab">
            <v-tab>Catalogo Productivity</v-tab>
            <v-tab>Catalogo Mercer</v-tab>
            <v-tab>Catalogo URREA</v-tab>

            <v-tab-item>
                <catalogoProductivity />
            </v-tab-item>
            <v-tab-item>
                <catalogoMercer />
            </v-tab-item>
            <v-tab-item>
                <catalogoUrrea />
            </v-tab-item>
        </v-tabs>
    </v-card>
</template>

<script>
import catalogoMercer from './catalogoMercer.vue'
import catalogoProductivity from './catalogoProductivity.vue'
import catalogoUrrea from './catalogoUrrea.vue'

export default {
    data() {
        return {
            activeTab: 0
        };
    },
    components: { catalogoMercer, catalogoProductivity, catalogoUrrea },
};
</script>