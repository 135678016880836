import axios from "axios";
import VueJsonToCsv from 'vue-json-to-csv'
import SnackBarErrorIncidencias from "../../../General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue"
import { deleteToken, formatDate } from "../../../../../store/Funciones/funciones";
export default {
    name: 'adm-aclaraciones-nominas',
    components: { SnackBarErrorIncidencias, VueJsonToCsv },
    props: [],
    data() {
        return {
            textoBarra: '',
            colorBarra: '',
            muestraBarra: false,
            cargando: true,
            buscar: '',
            headers: [
                { text: 'ID', value: 'ID', align: 'center', sortable: true },
                { text: 'N.P.', value: 'Emplid', align: 'center', sortable: true },
                { text: 'Colaborador', value: 'NombreColaborador', align: 'left', sortable: true },
                { text: 'Registro', value: 'fechaRegistro', align: 'center', sortable: true },
                { text: 'Cierre', value: 'fechaCierre', align: 'center', sortable: true },
                { text: 'Periodo', value: 'FIN_CADPER', align: 'center', sortable: true },
                { text: 'Motivo', value: 'motivo', align: 'left', sortable: true },
                { text: 'Grupo Pago', value: 'grupoPago', align: 'left', sortable: true },
                { text: 'Direccion', value: 'direccion', align: 'left', sortable: true },
                { text: 'Nivel de satisfacción', value: 'nivelSatisfaccion', align: 'center', sortable: true },
                { text: 'Estatus', value: 'estatus', align: 'center', sortable: true },
                { text: 'Comentarios', value: 'comentarios', align: 'center', sortable: false },
            ],
            headers_report_to_export: {
                Emplid: { title: "N.P." },
                NombreColaborador: { title: "Colaborador" },
                npResponsable: { title: "N.P. Responsable" },
                nombreResponsable: { title: "Jefe responsable" },
                fechaRegistro: { title: "Fecha de Registro" },
                fechaCierre: { title: "Fecha de Cierre" },
                periodo: { title: "Periodo" },
                motivo: { title: "Motivo" },
                grupoPago: { title: "Grupo Pago" },
                nivelSatisfaccion: { title: "Nivel de satisfacción" },
                estatus: { title: "Estatus" },
                procedetext: { title: "Procede" },
                procedeTipoText: { title: "Procede tipo" },
                comentarioGral: { title: "Comentario general" },
            },
            aclaraciones: [],
            dialogComentarios: false,
            comentarioGral: '',
            comentario: '',
            ID: '',
            NombreColaborador: '',
            fechaBloqueo: null,
            estatus: '',
            estatusAclaracion: '',

            nameFile: '',
            procede: false,
            radioGroup: '',
            loading: false,
            valorRadioGroup: false,
            dialogFiltro: false,
            dates: [],
            dialogFechas: false,
        }
    },
    computed: {
        dateRangeText() {
            return this.dates.join('~')
        },
    },
    mounted() {
        this.getAclaraciones();
        //  window.document.title = "DH:URREA - Aclaraciones.";
    },
    methods: {
        fixName() {
            let numero_aleatorio =
                Math.floor(Math.random() * (99999 - 10000 + 1)) + 10000;
            this.nameFile = "ReporteAclaraciones_" + numero_aleatorio.toString();
        },

        guardarComentario() {
            let params = {
                Opcion: 12,
                token: localStorage.getItem("token"),
                comentario: this.comentario,
                estatus: 3, //en proceso // se pasa directo a tres por el tipo de proceso que se agregó
                ID: this.ID,
                EmplidColaborador: this.EmplidColaborador,
                procede: this.procede,
                procedeTipo: this.radioGroup,
            }
            this.loading = true;
            axios.post(`${this.$store.getters.getHost}/nominas/ws-nominas.php`, params).then((response) => {
                if (response.data.status.trim() == "EXPSESSION") {
                    deleteToken();
                } else if (response.data.status.trim() == "OK") {
                    this.textoBarra = 'Respuesta registrada, su estatus se encuentra en proceso';
                    this.colorBarra = 'success';
                    this.muestraBarra = true;
                    this.comentario = '';
                    this.radioGroup = '';
                    this.procede = false;
                    this.dialogComentarios = false;
                    this.aclaraciones = [];
                    this.cargando = true;
                    this.getAclaraciones();
                } else {
                    this.textoBarra = 'Algo salió mal';
                    this.colorBarra = 'error';
                    this.muestraBarra = true;
                }
                this.loading = false;
            })
                .catch(function (e) {
                    let result = e.toString().includes("401");
                    if (result) {
                        window.location.reload();
                    } else {
                        alert(e);
                    }
                });
        },

        editItem(item) {
            // this.comentarioGral = "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eveniet reprehenderit provident vel delectus aliquam aperiam voluptas obcaecati modi. Libero quod autem obcaecati et inventore rerum sint molestiae repellat animi recusandae.";
            this.comentarioGral = item.comentarioGral;
            this.estatus = item.estatus;
            if (item.estatus != 'Abierta') {
                this.comentario = item.comentarioNominas;
                this.procede = item.procede;
                this.radioGroup = item.procedeTipo;
                this.valorRadioGroup = true;
            } else {
                this.valorRadioGroup = false;
            }
            this.ID = item.ID;
            this.EmplidColaborador = item.Emplid;
            this.dialogComentarios = true;
        },

        getAclaraciones() {
            this.aclaraciones = [];
            let params = {
                Opcion: 11,
                periodo: this.dates,
                token: localStorage.getItem("token"),
                perfil: 'Nominas',
            };
            this.loading = true;
            axios.post(`${this.$store.getters.getHost}/nominas/ws-nominas.php`, params).then((response) => {
                if (response.data.status.trim() == "EXPSESSION") {
                    deleteToken();
                } else {
                    var data = response.data.data;

                    if (data) {
                        this.aclaraciones = data;
                    }
                }
                this.cargando = false;
                this.dialogFiltro = false;
            })
                .catch(function (e) {
                    let result = e.toString().includes("401");
                    if (result) {
                        window.location.reload();
                    } else {
                        alert(e);
                    }
                });
            this.loading = false;
        },

        fixFormatDate(date) {
            return formatDate(date)
        },

        cierraDialog() {
            if (this.dates.length == 2) {
                if (this.dates[0] > this.dates[1]) {
                    this.dates.pop();
                    this.colorBarra = "error";
                    this.textoBarra =
                        "La fecha final no puede ser menor a la inicial";
                    this.muestraBarra = true;
                } else {
                    this.dialogFechas = false;
                }
            }
        },
    }
}