var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","fullscreen":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-subtitle-2 primary white--text"},[_vm._v(" Captura de clientes y pares "),_c('v-spacer'),_c('v-icon',{attrs:{"small":"","dark":""},on:{"click":function($event){return _vm.$emit('cierraDialgoCaptura', { result: 'Cancelar' })}}},[_vm._v("mdi-close")])],1),_c('v-card-text',{staticClass:"mt-5"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"12","md":"6","lg":"6","xl":"6"}},[_c('v-alert',{attrs:{"icon":"mdi-account-group-outline","prominent":"","color":"blue darken-1","dark":"","dense":""}},[_vm._v(" Por favor, selecciona maximo tres colaboradores como sus pares ")]),_c('v-card',{attrs:{"shaped":""}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headersSelect,"items":_vm.pares,"single-select":_vm.singleSelect,"search":_vm.buscarPar,"item-key":"Emplid","show-select":"","dense":"","mobile-breakpoint":"0","footer-props":{
                'items-per-page-options': [5, 10],
                'items-per-page-text': 'Elementos por página',
              },"items-per-page":_vm.itemsPerPage,"no-data-text":"No se encontraron pares registrados","loading-text":"Buscando pares...","loading":_vm.loadingPares},on:{"input":_vm.manejarSeleccionPares},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{staticClass:"rounded-lg rounded-b-0 rounded-t-0",attrs:{"flat":"","color":"blue darken-1","dark":""}},[_c('div',[_vm._v("Pares ("+_vm._s(_vm.paresSeleccionados.length)+"/3)")]),_c('v-spacer'),_c('v-text-field',{staticClass:"mt-7 rounded-lg",attrs:{"label":_vm.$t('DH360.findTextBox'),"dense":"","clearable":"","flat":"","solo-inverted":"","dark":"","prepend-inner-icon":"mdi-magnify"},model:{value:(_vm.buscarPar),callback:function ($$v) {_vm.buscarPar=$$v},expression:"buscarPar"}}),_c('v-spacer'),(_vm.selectClientesBtn)?_c('v-btn',{attrs:{"small":"","color":"teal darken-4"},on:{"click":function($event){_vm.dialogChange = true}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-account-check")]),_vm._v(" Agregar ")],1):_vm._e()],1)]},proxy:true},{key:"item.nombre",fn:function(ref){
              var item = ref.item;
return [_c('div',{attrs:{"align":"left"}},[_vm._v(_vm._s(item.nombre))])]}}]),model:{value:(_vm.paresSeleccionados),callback:function ($$v) {_vm.paresSeleccionados=$$v},expression:"paresSeleccionados"}})],1)],1),_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"12","md":"6","lg":"6","xl":"6"}},[_c('v-alert',{attrs:{"icon":"mdi-account-group-outline","prominent":"","color":"blue darken-1","dark":"","dense":""}},[_vm._v(" Por favor, selecciona maximo tres colaboradores como sus clientes ")]),_c('v-card',{attrs:{"shaped":""}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headersSelectClientes,"items":_vm.colaboradores,"single-select":_vm.singleSelect,"search":_vm.buscarCliente,"item-key":"Id_Usuario","show-select":"","dense":"","mobile-breakpoint":"0","footer-props":{
                'items-per-page-options': [5, 10],
                'items-per-page-text': 'Elementos por página',
              },"items-per-page":_vm.itemsPerPage,"no-data-text":"No se encontraron clientes para seleccionar","loading-text":"Buscando posibles clientes...","loading":_vm.loadingClientes},on:{"input":_vm.manejarSeleccionClientes},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{staticClass:"rounded-lg rounded-b-0 rounded-t-0",attrs:{"flat":"","color":"blue darken-1","dark":""}},[_c('div',[_vm._v("Clientes ("+_vm._s(_vm.clientesSeleccionados.length)+"/3)")]),_c('v-spacer'),_c('v-text-field',{staticClass:"mt-7 rounded-lg",attrs:{"label":_vm.$t('DH360.findTextBox'),"dense":"","clearable":"","flat":"","solo-inverted":"","dark":"","prepend-inner-icon":"mdi-magnify"},model:{value:(_vm.buscarCliente),callback:function ($$v) {_vm.buscarCliente=$$v},expression:"buscarCliente"}}),_c('v-spacer')],1)]},proxy:true},{key:"item.NAME",fn:function(ref){
              var item = ref.item;
return [_c('div',{attrs:{"align":"left"}},[_vm._v(_vm._s(item.NAME))])]}}]),model:{value:(_vm.clientesSeleccionados),callback:function ($$v) {_vm.clientesSeleccionados=$$v},expression:"clientesSeleccionados"}})],1)],1)],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),(
          _vm.clientesSeleccionados.length == 0 || _vm.paresSeleccionados.length == 0
        )?_c('v-btn',{attrs:{"small":"","disabled":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-check-circle-outline")]),_vm._v(" Guardar ")],1):_c('v-btn',{staticClass:"rounded-lg elevation-0",attrs:{"color":"teal darken-1","dark":"","small":"","loading":_vm.loading},on:{"click":function($event){return _vm.ordenarArrays()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-check-circle-outline")]),_vm._v(" Guardar ")],1)],1)],1),(_vm.dialogChange)?_c('selecciona-colaborador',{attrs:{"dialog":_vm.dialogChange,"idEvaluacion":_vm.evaluado.id,"infoEncuesta":_vm.evaluado,"fuente":_vm.fuente},on:{"cierraDialogSeleccion":_vm.closeDialogChange}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }