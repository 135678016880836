<template>
  <div>
    <v-data-table
      dense
      :headers="headers"
      :items="empleados"
      :loading="loadingData"
      :items-per-page="10"
      :search="search"
      :footer-props="{
        'items-per-page-text': 'Elementos por página',
        pageText: '{0}-{1} de {2}',
      }"
      no-data-text="No hay registros"
      loading-text="Cargando información..."
      class="elevation-0"
      style="background-color: transparent;"
    >
      <template v-slot:top>
        <div style="display: flex; justify-content: start;" class="py-3">
          <v-btn
            depressed
            class="rounded-pill mr-2"
            small
            :color="opcSelected === 1 ? 'primary' : 'blue-grey lighten-5'"
            @click="opcSelected = 1"
          >
            <v-icon left>mdi-account-group</v-icon>
            activos
          </v-btn>
          <v-btn
            depressed
            class="rounded-pill mr-2"
            small
            :color="opcSelected === 2 ? 'primary' : 'blue-grey lighten-5'"
            @click="opcSelected = 2"
          >
            <v-icon left>mdi-account-multiple-minus</v-icon>
            inactivos
          </v-btn>
        </div>
        <div style="display: flex; justify-content: space-between;">
          <v-text-field
            v-model="search"
            dense
            outlined
            class="rounded-pill"
            prepend-inner-icon="mdi-magnify"
            placeholder="Buscar..."
          ></v-text-field>
          <v-spacer></v-spacer>
          <div>
            <vue-json-to-csv
              :json-data="empleados"
              :labels="headers_to_export"
              csv-title="ReporteEmpleadosCajaAhorro"
            >
              <v-tooltip bottom color="green darken-2">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    depressed
                    outlined
                    fab
                    small
                    v-bind="attrs"
                    v-on="on"
                    class="rounded-pill mr-2"
                    color="green darken-2"
                    dark
                    :loading="loadingData"
                  >
                    <v-icon>mdi-microsoft-excel</v-icon>
                  </v-btn>
                </template>
                <span>Exportar CSV</span>
              </v-tooltip>
            </vue-json-to-csv>
            <v-tooltip bottom color="blue darken-2">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  depressed
                  fab
                  small
                  outlined
                  v-bind="attrs"
                  v-on="on"
                  class=" mr-2"
                  color="blue darken-2"
                  :loading="loadingData"
                  @click="showFindNewMembers = true"
                >
                  <v-icon>mdi-account-plus</v-icon>
                </v-btn>
              </template>
              <span>Inscribir colaborador</span>
            </v-tooltip>
            <v-tooltip bottom color="blue-grey darken-2">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  depressed
                  fab
                  small
                  outlined
                  class="mr-2"
                  v-bind="attrs"
                  v-on="on"
                  color="blue-grey darken-2"
                  :loading="loadingData"
                  @click="actualizarTabla"
                >
                  <v-icon>mdi-reload</v-icon>
                </v-btn>
              </template>
              <span>Actualizar tabla</span>
            </v-tooltip>
          </div>
        </div>
      </template>
      <template v-slot:item.monto="{ item }">
        {{ item.monto != null ? formatMXN(item.monto) : formatMXN(0) }}
      </template>
      <template v-slot:item.saldo="{ item }">
        {{ item.saldo != null ? formatMXN(item.saldo) : formatMXN(0) }}
      </template>
      <template v-slot:item.prestamo="{ item }">
        <v-chip
          x-small
          dark
          class="elevation-0"
          :color="item.prestamo == 0 ? 'pink darken-3' : 'teal darken-1'"
        >
          {{ item.prestamo == 0 ? "Inactivo" : "Activo" }}
        </v-chip>
      </template>
      <template v-slot:item.accion="{ item }">
        <div class="center-item-in-div">
          <v-icon
            small
            color="cyan darken-1"
            @click="muestraDetalles(item.emplid)"
            >mdi-information</v-icon
          >
          <v-icon
            small
            color="purple darken-2"
            @click="
              employee = item.emplid;
              emplAux = item;
              showDataEmployee = true;
            "
            >mdi-eye-circle</v-icon
          >
          <v-icon
            small
            color="primary"
            @click="
              employee = item.emplid;
              mostrarAlu = true;
            "
            >mdi-chat</v-icon
          >
        </div>
      </template>
    </v-data-table>
    <DetallesPorUsuario
      v-if="verDerallesPorUsuario"
      :verDeralles="verDerallesPorUsuario"
      :idUser="emplidSelected"
      @cierraDialogDetalles="verDerallesPorUsuario = false"
    />

    <DetalleGeneralCajaColaborador
      v-if="showDataEmployee"
      :showDataEmployee="showDataEmployee"
      :empleado="employee"
      :dataEmpl="emplAux"
      @hideDataEmployee="showDataEmployee = false"
    />

    <v-dialog v-model="mostrarAlu" max-width="500">
      <Alu
        v-if="mostrarAlu"
        :admin="true"
        :empleado="employee"
        @cierraCA="mostrarAlu = false"
      />
    </v-dialog>

    <BuscaColaboradoresActivos
      v-if="showFindNewMembers"
      :showFindNewMembers="showFindNewMembers"
      @returnColaborrador="returnColaborrador"
    />

    <SnackBarErrorIncidencias
      v-if="muestraBarra"
      :muestraBarra="muestraBarra"
      :color="colorBarra"
      :texto="textoBarra"
      @cierraBarra="muestraBarra = false"
    />

    <IngresaCantidad :muestraIngresaCantidad="muestraIngresaCantidad" :titulo="'Ingresa la cantidad'" tipo="monto" @retornaCantidad="retornaCantidad"/>
  </div>
</template>

<script>
import axios from "axios";
import { deleteToken } from "../../../../../../store/Funciones/funciones";
import DetallesPorUsuario from "./actions/DetallesPorUsuario";
import DetalleGeneralCajaColaborador from "./actions/DetalleGeneralCajaColaborador.vue";
import VueJsonToCsv from "vue-json-to-csv";
import Alu from "./actions/Alu.vue";
import BuscaColaboradoresActivos from "../../../../../../components/BuscaColaboradoresActivos.vue";
import SnackBarErrorIncidencias from "../../../../General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue";
import IngresaCantidad from "./actions/IngresaCantidad.vue";

export default {
  name: "tabla-empleados-caja",
  components: {
    DetallesPorUsuario,
    DetalleGeneralCajaColaborador,
    VueJsonToCsv,
    Alu,
    BuscaColaboradoresActivos,
    SnackBarErrorIncidencias,
    IngresaCantidad,
  },
  data() {
    return {
      headers: [
        { value: "ID", text: "ID" },
        { value: "emplid", text: "EMPLID" },
        { value: "EMP_NOMEMP", text: "NOMBRE" },
        { value: "monto", text: "MONTO NÓMINAL", align: "center" },
        { value: "saldo", text: "SALDO", align: "center" },
        { value: "prestamo", text: "PRÉSTAMO", align: "center" },
        { value: "accion", text: "" },
      ],
      headers_to_export: {
        ID: { title: "ID" },
        emplid: { title: "emplid" },
        EMP_NOMEMP: { title: "EMP_NOMEMP" },
        monto: { title: "monto" },
        saldo: { title: "saldo" },
        prestamo: { title: "prestamo" },
      },
      empleados: [],
      emplAux: {},
      opcSelected: 1,
      search: "",
      loadingData: true,
      verDerallesPorUsuario: false,
      showDataEmployee: false,
      mostrarAlu: false,
      showFindNewMembers: false,
      muestraIngresaCantidad: false,
      muestraBarra: false,
      colorBarra: "",
      textoBarra: "",
      employee: null,
    };
  },
  async mounted() {
    this.empleados = await this.getParaPrenominas();
  },
  methods: {
    showSnackBar(texto, color = "error"){
      this.textoBarra = texto;
      this.colorBarra = color;
      this.muestraBarra = true;
    },
    async actualizarTabla() {
      this.loadingData = true;
      this.empleados = await this.getParaPrenominas();
    },
    getParaPrenominas() {
      const headers = {
        Authorization: localStorage.getItem("token"),
      };

      return new Promise((res) => {
        axios
          .get(`${this.$store.getters.getHostNode}/api/empleados/caja`, {
            headers,
          })
          .then((response) => {
            if (response.data.status == "EXPSESSION") {
              deleteToken();
            } else {
              res(response.data.data);
            }
          })
          .finally(() => {
            this.loadingData = false;
          });
      });
    },
    formatMXN(cadena) {
      return cadena.toLocaleString("es-MX", {
        style: "currency",
        currency: "MXN",
      });
    },
    muestraDetalles(emplid) {
      this.emplidSelected = emplid;
      this.verDerallesPorUsuario = true;
    },
    returnColaborrador(colaborador) {
      this.showFindNewMembers = false;
      if(colaborador !== null){
        this.emplAux = colaborador;
        this.muestraIngresaCantidad = true;
      }
    },
    async retornaCantidad(monto){
      this.muestraIngresaCantidad = false;
      if(monto !== null){
        const response = await this.setUser(monto);
        if(response.status === "OK" && response.data >= 1){
          this.showSnackBar("Insertado correctamente.", "success")
        } else if(response.status === "OK" && response.data == 0){
          this.showSnackBar("El colaborador ya es parte de caja de ahorro.")
        } else {
          this.showSnackBar("ERROR. No se inscribió al colaborador, intente más tarde.")
        }
      }
      
    },
    setUser(monto) {
      const headers = {
        Authorization: localStorage.getItem("token"),
      };
      const params = {
        emplid: this.emplAux.EMPLID,
        monto: monto
      };

      return new Promise((res) => {
        axios
          .post(
            `${this.$store.getters.getHostNode}/api/empleados/caja`,
            params,
            {
              headers,
            }
          )
          .then(async (response) => {
            if (response.data.status == "EXPSESSION") {
              deleteToken();
            } else if (response.data.status == "OK") {
              res(response.data);
            } else {
              res(response.data);
            }
          });
      });
    },
  },
};
</script>
