<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <v-card>
      <v-card-title class="text-subtitle-2 primary white--text">
        Nueva SubPolitica
        <v-spacer></v-spacer>
        <v-icon
          small
          dark
          @click="$emit('cierraFormSubReglas', { result: 'Cancelar' })"
          >mdi-close</v-icon
        >
      </v-card-title>
      <v-card-text class="mt-5">
        <v-row>
          <v-col cols="12" xl="6" lg="6" md="6" sm="12" xs="12">
            <v-textarea
              v-model="reglaLbl"
              background-color="grey lighten-2"
              color="cyan"
              label="Nombre Politica"
              rows="1"
              row-height="1"
              counter
              disabled
              prepend-inner-icon="mdi-comment"
              dense
              outlined
            ></v-textarea>
          </v-col>
          <v-col cols="12" xl="6" lg="6" md="6" sm="12" xs="12">
            <v-textarea
              v-model="form.nombreSubRegla"
              background-color="grey lighten-2"
              color="cyan"
              label="Nombre Sub-Politica"
              rows="1"
              row-height="1"
              counter
              :rules="selectRules"
              maxlength="50"
              prepend-inner-icon="mdi-comment"
              dense
              outlined
            ></v-textarea>
          </v-col>
          <v-col cols="12" xl="12" lg="12" md="12" sm="12" xs="12">
            <v-textarea
              v-model="form.texto"
              background-color="grey lighten-2"
              color="cyan"
              label="Comentario (Opcional)"
              rows="2"
              row-height="2"
              counter
              maxlength="100"
              prepend-inner-icon="mdi-comment"
              dense
              outlined
            ></v-textarea>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          v-if="form.nombreSubRegla"
          small
          color="teal darken-4"
          dark
          :loading="loading"
          @click="guardar()"
          >Nueva Regla</v-btn
        >
        <v-btn v-else small disabled>Nueva Regla</v-btn>
      </v-card-actions>
    </v-card>
    <SnackBar
      v-if="muestraBarra"
      :muestraBarra="muestraBarra"
      :texto="textoBarra"
      :color="colorBarra"
      @cierraBarra="muestraBarra = false"
    />
  </v-dialog>
</template>



<script>
import axios from "axios";
import { deleteToken } from "../../../../../../store/Funciones/funciones";
import SnackBar from "../../../../General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue";
export default {
  name: "form-politicas-sub-reglas",
  components: {SnackBar},
  props: ["dialog", "regla"],
  data: () => ({
    textoBarra: "",
    colorBarra: "",
    muestraBarra: false,

    reglaLbl: "",
    form: {
      idRegla1: "",
      nombreSubRegla: "",
      texto: "",
    },
    loading: false,
    selectRules: [(v) => !!v || "Campo requerido."],
  }),
  created() {},
  mounted() {
    this.reglaLbl = this.regla.Regla;
    this.form.idRegla1 = this.regla.id;
  },

  methods: {
    guardar() {
      let params = {
        Opcion: 6,
        form: this.form,
        token: localStorage.getItem("token"),
      };
      console.log(params);
      this.loading = true;
      axios
        .post(
          `${this.$store.getters.getHost}/General/PoliticasUHM/ws-politicasUHM.php`,
          params
        )
        .then((response) => {
          this.loading = false;
          console.log(response.data);
          if (response.data.status == "OK") {
            this.textoBarra = "Subpolitica registrada correctamente";
            this.colorBarra = "success";
            this.muestraBarra = true;
            this.clean();
            return new Promise((resolve) => {
              setTimeout(() => {
                resolve(this.$emit("cierraFormSubReglas", { estatus: "OK" }));
              }, 1500);
            });
          } else if (response.data.status == "ERROR") {
            this.textoBarra = "Se ha producido un error al registrar";
            this.colorBarra = "error";
            this.muestraBarra = true;
          } else if (response.data.status == "EXPSESSION") {
            deleteToken();
          }
        });
    },
    clean() {
      this.form.nombreSubRegla = "";
      this.form.texto = "";
    },
  },
};
</script>