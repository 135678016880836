<template>
  <v-dialog
    v-model="insertDeposito"
    persistent
    content-class="fix-border-dialog"
    max-width="550"
  >
    <v-card class="rounded-lg">
      <v-card-title class="pt-2 pb-3 pr-3 primary">
        <div class="subtitle-1 font-weight-bold white--text">
          Agrega depósito externo
        </div>
        <v-spacer></v-spacer>
        <v-icon dark @click="$emit('hideInsertDoposit', null)">
          mdi-close
        </v-icon>
      </v-card-title>
      <v-card-text>
        <v-form ref="formDeposito">
          <label class="overline">Número de empleado</label>
          <v-text-field
            v-model="empleado"
            outlined
            class="rounded-lg"
            dense
            placeholder="Ingresa el número de empleado"
            required
            :rules="rules"
            type="number"
            prefix="#"
          ></v-text-field>
          <label class="overline">Cantidad</label>
          <v-text-field
            v-model="monto"
            outlined
            class="rounded-lg"
            dense
            placeholder="Ingresa el monto a depósitar"
            required
            :rules="rules"
            type="number"
            prefix="$"
          ></v-text-field>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn
          block
          text
          class="rounded-pill rounded-t-0 font-weight-bold"
          color="primary"
          @click="save"
        >
          aceptar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ["insertDeposito"],
  data() {
    return {
      empleado: null,
      monto: null,
      rules: [
        (v) => !!v || "Campo requerido.",
        (v) =>
          /^(?!0\d)\d+(\.\d{1,2})?$/.test(String(v)) ||
          "Ingresa una cantidad valida.",
      ],
    };
  },
  methods: {
    async save() {
      if (this.$refs.formDeposito.validate()) {
        this.$emit("hideInsertDoposit", {
          id: await this.generarNumeroAleatorio(),
          empleado: this.empleado,
          monto: parseFloat(this.monto),
        });
      }
    },
    async generarNumeroAleatorio() {
      return Math.floor(Math.random() * (10001 - 5000)) + 5000;
    },
  },
};
</script>
