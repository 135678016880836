<template>
  <v-container fluid>
    <v-card v-if="!moduloError">
      <v-data-table
        :headers="headers"
        :items="motivos"
        :search="buscar"
        :loading="loadingInfo"
        class="elevation-2"
        dense
        mobile-breakpoint="0"
        :footer-props="{ 'items-per-page-text': 'Elementos por página' }"
        no-data-text="No se encontraron motivos"
        item-key="id"
      >
        <template v-slot:top>
          <v-toolbar flat class="rounded-lg rounded-b-0 rounded-t-0">
            <v-text-field
              v-model="buscar"
              :label="$t('DH360.findTextBox')"
              class="mt-7 rounded-lg"
              dense
              clearable
              outlined
              solo-inverted
              prepend-inner-icon="mdi-table-search"
            ></v-text-field>
            <v-spacer></v-spacer>
            <v-btn
              class="rounded-lg elevation-0"
              color="teal darken-1"
              small
              dark
              @click="openFormMotivos"
            >
              <v-icon left>mdi-plus</v-icon> Nuevo motivo
            </v-btn>
          </v-toolbar>
        </template>

        <template v-slot:item.motivo="{ item }">
          <div align="left">{{ item.motivo }}</div>
        </template>
        <template v-slot:item.departamento="{ item }">
          <div v-if="item.departamento == '[] '" align="left">
            Sin departamento
          </div>
          <div v-else align="left">{{ item.departamento }}</div>
        </template>
        <template v-slot:item.estatus="{ item }">
          <v-tooltip top color="green darken-2" v-if="item.estatus == 1">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                style="cursor: pointer"
                color="green darken-4"
                v-bind="attrs"
                v-on="on"
                large
                @click="confirmarCambioEstatusInactivo(item.id)"
              >
                mdi-menu-up
              </v-icon>
            </template>
            <label
              class="text-uppercase text-center font-weight-bold white--text"
              >Activo <br />
              Cambiar estatus
            </label>
          </v-tooltip>
          <v-tooltip top color="red darken-2" v-else-if="item.estatus == 0">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                style="cursor: pointer"
                color="red darken-4"
                v-bind="attrs"
                v-on="on"
                large
                @click="confirmarCambioEstatus(item.id)"
              >
                mdi-menu-down
              </v-icon>
            </template>
            <label
              class="text-uppercase text-center font-weight-bold white--text"
              >Inactivo <br />
              Cambiar estatus</label
            >
          </v-tooltip>
        </template>
      </v-data-table>
      <SnackBar
        v-if="muestraBarra"
        :muestraBarra="muestraBarra"
        :texto="textoBarra"
        :color="colorBarra"
        @cierraBarra="muestraBarra = false"
      />
      <inconformidades-form-motivos
        v-if="agregarMotivo"
        :dialog="agregarMotivo"
        @cierraFormMotivosInconformidades="closeFormMotivos"
      >
      </inconformidades-form-motivos>
    </v-card>
    <div v-else>
      <mantenimiento-modulo></mantenimiento-modulo>
    </div>
  </v-container>
</template>

<script>
import axios from "axios";
import SnackBar from "../../views/generated/General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue";
import { deleteToken } from "../../store/Funciones/funciones";
import InconformidadesFormMotivos from "./InconformidadesFormMotivos.vue";
import MantenimientoModulo from "../MantenimientoModulo.vue";
export default {
  name: "inconformidades-lista-motivos",
  components: { InconformidadesFormMotivos, SnackBar, MantenimientoModulo },
  props: ["dialog"],
  data: () => ({
    textoBarra: "",
    colorBarra: "",
    muestraBarra: false,

    headers: [
      { text: "ID", value: "id", align: "center", sortable: true },
      { text: "Motivo", value: "motivo", align: "center", sortable: true },
      {
        text: "Departamento",
        value: "departamento",
        align: "center",
        sortable: true,
      },
      {
        text: "Fecha registro",
        value: "fechaRegistro",
        align: "center",
        sortable: true,
      },
      { text: "Estatus", value: "estatus", align: "center", sortable: true },
    ],
    motivos: [],
    buscar: "",
    loadingInfo: true,
    agregarMotivo: false,
    moduloError: false,
  }),
  created() {},
  mounted() {
    this.getMotivosInconformidades();
  },
  methods: {
    getMotivosInconformidades() {
      //   this.loading = true;
      let params = {
        Opcion: 3,
        ruta: "lista",
        token: localStorage.getItem("token"),
      };
      console.log(params);
      axios
        .post(
          `${this.$store.getters.getHost}/Nominas/AdmInconformidades/ws-inconformidades.php`,
          params
        )
        .then((response) => {
          this.loadingInfo = false;
          if (response.data.status == "OK") {
            this.motivos = response.data.motivos;
          } else if (response.data.status == "VACIO") {
            console.log("vacio");
          } else if (response.data.status == "EXPRESSION") {
            deleteToken();
          } else if (response.data.status == "ERROR" || !response.data.status) {
            this.moduloError = true;
          }
        });
    },
    openFormMotivos() {
      this.agregarMotivo = true;
    },
    closeFormMotivos(response) {
      console.log(response);
      if (response == "OK") {
        this.motivos = [];
        this.loadingInfo = true;
        this.getMotivosInconformidades();
      }
      this.agregarMotivo = false;
    },
    confirmarCambioEstatus(id) {
      let estatus = 1;
      if (
        window.confirm(
          "¿Esta seguro de cambiar su estatus a Activo? Aparecerán en las opciones de inconformidades"
        )
      ) {
        this.cambiarEstatus(estatus, id);
      } else {
        console.log("cancelado");
      }
    },
    confirmarCambioEstatusInactivo(id) {
      let estatus = 0;
      if (
        window.confirm(
          "¿Esta seguro de cambiar su estatus a Inactivo? No se podrá seleccionar esta inconformidad"
        )
      ) {
        this.cambiarEstatus(estatus, id);
      } else {
        console.log("cancelado");
      }
    },
    cambiarEstatus(Estatus, id) {
      let params = {
        Opcion: 6,
        id: id,
        estatus: Estatus,
        token: localStorage.getItem("token"),
      };
      console.log(params);
      axios
        .post(
          `${this.$store.getters.getHost}/Nominas/AdmInconformidades/ws-inconformidades.php`,
          params
        )
        .then((response) => {
          if (response.data.status == "OK") {
            this.textoBarra = "Estatus modificado correctamente";
            this.colorBarra = "success";
            this.muestraBarra = true;
            this.motivos = [];
            this.loadingInfo = true;
            this.getMotivosInconformidades();
          } else if (response.data.status == "ERROR") {
            this.textoBarra = "Error al cambiar estatus";
            this.colorBarra = "error";
            this.muestraBarra = true;
          } else if (response.data.status == "EXPRESSION") {
            deleteToken();
          }
        });
    },
  },
};
</script>
