import { render, staticRenderFns } from "./AdmSolicitantesDescansoLaborado.html?vue&type=template&id=6243e737&scoped=true&"
import script from "./AdmSolicitantesDescansoLaborado.js?vue&type=script&lang=js&"
export * from "./AdmSolicitantesDescansoLaborado.js?vue&type=script&lang=js&"
import style0 from "./AdmSolicitantesDescansoLaborado.scss?vue&type=style&index=0&id=6243e737&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6243e737",
  null
  
)

export default component.exports