<template>
  <v-sheet v-if="loadingData">
    <v-row class="fill-height py-5" align-content="center" justify="center">
      <v-col class="subtitle-1 text-center" cols="12">
        Obteninendo información...
      </v-col>
      <v-col cols="6" class="center-item-in-div">
        <v-progress-circular
          :size="70"
          :width="8"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </v-col>
    </v-row>
  </v-sheet>
  <v-card v-else class="backgroud-color-card-reclutamiento rounded-lg rounded-t-0">
    <v-row no-gutters class="pt-3 px-2 pb-2">
      <v-col cols="12" class="pa-3">
        <v-sheet
          min-height="50"
          width="100%"
          class="neumorphism-card-reclutamiento center-item-in-div-column"
        >
          <v-btn text v-if="candidato" @click="getActionBtnDoc(candidato.Url)">
            <v-icon left>mdi-file-document-multiple</v-icon>
            <div class="font-weight-bold text-decoration-underline">
              {{
                candidato.Url != null
                  ? "ver documentación"
                  : "agregar documentación"
              }}
            </div>
          </v-btn>

          <v-card
            v-show="muestraAgregarDoc"
            class="my-2 pa-2 rounded-lg elevation-3"
            color="transparent"
            max-width="700px"
            min-width="100px"
            min-height="40px"
          >
            <v-row dense>
              <v-col cols="12">
                <v-form ref="formDocumentation">
                  <v-text-field
                    v-model="urlDocumentation"
                    dense
                    outlined
                    :disabled="loadingAddDoc"
                    :rules="[v => !!v || 'Name is required',]"
                    required
                    class="rounded-lg"
                    style="height:46px !important"
                    placeholder="Agrega la URL..."
                  ></v-text-field>
                </v-form>
              </v-col>
              <v-col cols="12">
                <v-btn class="backgroun-degraded-reclutamientos elevation-0" block :loading="loadingAddDoc" @click="loadingAddDoc = true; addDocumentation()">
                  <v-icon left>mdi-plus-circle</v-icon>
                  Agregar
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-sheet>
      </v-col>
      <v-col :cols="isSmallDisplay ? 12 : 6" class="pa-3">
        <v-sheet
          min-height="200"
          height="100%"
          width="100%"
          class="neumorphism-card-reclutamiento"
        >
          <div class="py-2 px-5">
            <div
              class="font-weight-bold subtitle-1 blue-grey--text text--darken-3"
            >
              Datos personales.
            </div>
            <v-sheet class="py-2" color="transparent" v-if="candidato">
              <v-row dense v-if="candidato.Tipo == 1">
                <v-col :cols="isSmallDisplay ? 12 : 6">
                  <p class="my-0">
                    <strong class="text-uppercase"> CURP: </strong>
                    {{ candidato.Curp }}
                  </p>
                </v-col>
                <v-col :cols="isSmallDisplay ? 12 : 6">
                  <p class="my-0">
                    <strong class="text-uppercase"> Estudios: </strong>
                    {{ candidato.Estudios }}
                  </p>
                </v-col>
                <v-col :cols="isSmallDisplay ? 12 : 6">
                  <p class="my-0">
                    <strong class="text-uppercase"> Telefono: </strong>
                    {{ candidato.Telefono }}
                  </p>
                </v-col>
                <v-col :cols="isSmallDisplay ? 12 : 6">
                  <p class="my-0">
                    <strong class="text-uppercase"> E-Mail: </strong>
                    {{ candidato.Mail }}
                  </p>
                </v-col>
                <v-col :cols="isSmallDisplay ? 12 : 6">
                  <p class="my-0">
                    <strong class="text-uppercase">
                      Tiempo de Traslado:
                    </strong>
                    {{ candidato.Traslado }}
                  </p>
                </v-col>
                <v-col :cols="isSmallDisplay ? 12 : 6">
                  <p class="my-0">
                    <strong class="text-uppercase"> Último empleo: </strong>
                    {{ candidato.UltimoEmp }}
                  </p>
                </v-col>
                <v-col :cols="isSmallDisplay ? 12 : 6">
                  <p class="my-0">
                    <strong class="text-uppercase">
                      Penúltimo empleo:
                    </strong>
                    {{ candidato.PenultimoEmp }}
                  </p>
                </v-col>
                <v-col :cols="isSmallDisplay ? 12 : 6">
                  <p class="my-0">
                    <strong class="text-uppercase"> Salario actual: </strong>
                    {{ candidato.SalarioAct }}
                  </p>
                </v-col>
                <v-col :cols="isSmallDisplay ? 12 : 6">
                  <p class="my-0">
                    <strong class="text-uppercase"> Salario deseado: </strong>
                    {{ candidato.SalarioDes }}
                  </p>
                </v-col>
                <v-col :cols="isSmallDisplay ? 12 : 6">
                  <p class="my-0">
                    <strong class="text-uppercase"> Residencia: </strong>
                    {{ candidato.Experiencia }}
                  </p>
                </v-col>
              </v-row>
              <v-row dense v-else>
                <v-col :cols="isSmallDisplay ? 12 : 6">
                  <p class="my-0">
                    <strong class="text-uppercase"> Ingreso: </strong>
                    {{ ![null, undefined].includes(candidato.EMP_FECING) ? fixFormatDate(candidato.EMP_FECING.date.substring(0, 10)) : '' }}
                  </p>
                </v-col>
                <v-col :cols="isSmallDisplay ? 12 : 6">
                  <p class="my-0">
                    <strong class="text-uppercase"> Puesto: </strong>
                    {{ candidato.Puesto }}
                  </p>
                </v-col>
                <v-col :cols="isSmallDisplay ? 12 : 6">
                  <p class="my-0">
                    <strong class="text-uppercase"> Departamento: </strong>
                    {{ candidato.Depto }}
                  </p>
                </v-col>
                <v-col :cols="isSmallDisplay ? 12 : 6">
                  <p class="my-0">
                    <strong class="text-uppercase"> Tipo de puesto: </strong>
                    {{ candidato.TipoPuestoActual }}
                  </p>
                </v-col>
              </v-row>
            </v-sheet>
          </div>
        </v-sheet>
      </v-col>
      <v-col :cols="isSmallDisplay ? 12 : 6" class="pa-3">
        <v-sheet
          min-height="200"
          width="100%"
          height="100%"
          class="neumorphism-card-reclutamiento"
          style="position: relative;"
        >
          <div class="pt-2 pb-9 px-5">
            <div
              class="font-weight-bold subtitle-1 blue-grey--text text--darken-3"
              v-if="candidato"
            >
              Preguntas clave.
            </div>
            <div v-if="candidato.Pregunta1 != null">
              <v-sheet color="transparent">
                <p class="my-0">
                  <strong class="blue-grey--text text--darken-2">
                    {{ candidato.preguntas[0] != undefined ? candidato.preguntas[0].pregunta : '' }}
                  </strong>
                  {{ decodeURIComponent(candidato.Pregunta1) }}
                </p>
              </v-sheet>
              <v-sheet color="transparent">
                <p class="my-0">
                  <strong class="blue-grey--text text--darken-2">
                    {{ candidato.preguntas[1] != undefined ? candidato.preguntas[1].pregunta : '' }}
                  </strong>
                  {{ candidato.Pregunta2 }}
                </p>
              </v-sheet>
              <v-sheet color="transparent">
                <p class="my-0">
                  <strong class="blue-grey--text text--darken-2">
                    {{ candidato.preguntas[2] != undefined ? candidato.preguntas[2].pregunta : '' }}
                  </strong>
                  {{ candidato.Pregunta3 }}
                </p>
              </v-sheet>
              <v-sheet color="transparent">
                <p class="my-0">
                  <strong class="blue-grey--text text--darken-2">
                    {{ candidato.preguntas[3] != undefined ? candidato.preguntas[3].pregunta : '' }}
                  </strong>
                  {{ candidato.Pregunta4 }}
                </p>
              </v-sheet>
              <v-sheet color="transparent">
                <p class="my-0">
                  <strong class="blue-grey--text text--darken-2">
                    {{ candidato.preguntas[4] != undefined ? candidato.preguntas[4].pregunta : '' }}
                  </strong>
                  {{ candidato.Pregunta5 }}
                </p>
              </v-sheet>
              <v-sheet color="transparent" v-show="candidato.Tipo === 1">
                <p class="my-0">
                  <strong class="blue-grey--text text--darken-2">
                    ¿Alguna vez has trabajado en algúna compañía o has sido
                    distibuidor en el giro ferretero?
                  </strong>
                  {{ candidato.Ferretero1 }}
                </p>
              </v-sheet>
              <v-sheet color="transparent" v-show="candidato.Tipo === 1">
                <p class="my-0">
                  <strong class="blue-grey--text text--darken-2">
                    ¿Tienes familiares que sean dueños o trabajen en algún
                    negocio o compañía del giro ferretero?
                  </strong>
                  {{ candidato.Ferretero2 }}
                </p>
              </v-sheet>
            </div>
          </div>
          <div
            style="position: absolute; bottom: 0; width: 100%;"
            class="center-item-in-div pa-1"
          >
            <v-btn
              class="neumorphism-card-reclutamiento rounded-pill elevation-0 text-decoration-underline font-weight-bold"
              block
              small
              @click="verPreguntasRequisitor = true"
            >
              ver respuestas del requisitor
            </v-btn>
          </div>
        </v-sheet>
      </v-col>
    </v-row>
    <SnackBar
      :muestaBarra="muestraBarra"
      :texto="textoBarra"
      :color="colorBarra"
      @cierraBarra="muestraBarra = false"
    />

    <TablaDetalles
      v-if="candidato && verPreguntasRequisitor"
      :verDataTable="verPreguntasRequisitor"
      :esApegoPerfil="false"
      :items="candidato.preguntas"
      :headers="headers"
      titulo="Preguntas del requisitor."
      @cierraTabla="verPreguntasRequisitor = false"
    />
  </v-card>
</template>

<script>
import axios from "axios";
import SnackBar from "../../../General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias";
import TablaDetalles from "../TablaDetalles.vue";
import { deleteToken, formatDate } from "../../../../../store/Funciones/funciones";

export default {
  name: "infromacion-personal-candidato",
  props: ["idCandidato"],
  components: { SnackBar, TablaDetalles },
  data() {
    return {
      loadingData: true,
      candidato: {},
      muestraBarra: false,
      verPreguntasRequisitor: false,
      muestraAgregarDoc: false,
      loadingAddDoc:false,
      textoBarra: "",
      colorBarra: "",
      urlDocumentation: "",
      headers: [
        {
          text: "Pregunta",
          value: "pregunta",
          align: "left",
          sorteable: false,
          class: "",
        },
        {
          text: "Respuesta",
          value: "Respuesta",
          align: "left",
          sorteable: false,
          class: "",
        },
      ],
    };
  },
  computed: {
    isSmallDisplay() {
      return this.$vuetify.breakpoint.name == "xs" ||
        this.$vuetify.breakpoint.name == "xs"
        ? true
        : false;
    },
  },
  created() {
    this.getDetalleCandidato();
  },
  methods: {
    getDetalleCandidato() {
      let params = {
        Opcion: 3,
        token: localStorage.getItem("token"),
        idCandidato: this.idCandidato,
      };
      axios
        .post(
          `${this.$store.getters.getHost}/Reclutamiento/ws-admin-candidatos.php`,
          params
        )
        .then((response) => {
          // alert("Responde bien.")
          if (response.data.status != "EXPSESSION") {
            this.candidato = response.data.candidato;
          } else {
            deleteToken()
          }
        })
        .catch((e) => {
          this.colorBarra = "error";
          this.textoBarra = e;
          this.muestaBarra = true;
        }).finally(() => {
          this.loadingData = false;
        });
    },

    getActionBtnDoc(url) {
      console.log("shegó: " + this.muestraAgregarDoc);
      if (url == null) {
        this.muestraAgregarDoc = !this.muestraAgregarDoc;
      } else {
        window.open(url, "_blank");
      }
    },

    addDocumentation(){
      if(this.$refs.formDocumentation.validate()){
        this.candidato.Url = this.urlDocumentation
        this.muestraAgregarDoc = false;
        this.loadingAddDoc = false
      }
    },

    fixFormatDate(cadena){
      return formatDate(cadena)
    }
  },
};
</script>

<style>
/* .backgroud-color-card-reclutamiento {
  background-color: #f5f5f5 !important;
}
.neumorphism-card-reclutamiento {
  border-radius: 22px;
  background: linear-gradient(225deg, #dddddd, #ffffff);
  box-shadow: -20px 20px 60px #d0d0d0, 20px -20px 60px #ffffff !important;
}
.backgroun-degraded-reclutamientos {
  border-radius: 22px;
  background: linear-gradient(225deg, #dddddd, #ffffff);
} */
</style>
