<template>
  <v-dialog
    v-model="verDialogAbonosPrestamo"
    transition="dialog-bottom-transition"
    content-class="fix-border-dialog"
    max-width="750"
    persistent
  >
    <v-card class="rounded-xl">
      <v-toolbar color="primary" dark>
        <label class="overline font-weight-bold">
          Abonos de préstamo [{{ idPrestamo }}]
        </label>
        <v-spacer></v-spacer>
        <v-icon dark @click="$emit('cierraDialogAbonosPrestamo')"
          >mdi-close</v-icon
        >
      </v-toolbar>
      <v-card-text class="pt-3">
        <v-data-table
          dense
          :items="abonos"
          :headers="headers"
          :search="search"
          :footer-props="{
            'items-per-page-text': 'Elementos por página',
            pageText: '{0}-{1} de {2}',
          }"
          :loading="loadingData"
          loading-text="Cargando montos..."
          no-data-text="No hay registros"
        >
          <template v-slot:top>
            <div style="display: flex; justify-content: space-between;">
              <v-text-field
                v-model="search"
                dense
                outlined
                class="rounded-pill"
                prepend-inner-icon="mdi-magnify"
                placeholder="Buscar..."
              ></v-text-field>
              <v-spacer></v-spacer>
              <vue-json-to-csv
                :json-data="abonos"
                :labels="headers_to_export"
                :csv-title="'ReporteAbonosPrestamo' + idPrestamo"
              >
                <v-btn
                  depressed
                  class="rounded-pill mr-2"
                  color="green darken-2"
                  dark
                  :loading="loadingData"
                >
                  <v-icon left>mdi-microsoft-excel</v-icon>
                  exportar
                </v-btn>
              </vue-json-to-csv>
            </div>
          </template>

          <template v-slot:item.monto="{ item }">
            <v-btn
              x-small
              text
              color="blue darken-1"
              @click="
                datosPrestamoAux = item;
                muestraIngresaCantidad = true;
                esAbono = true;
              "
            >
              {{ formatMXN(item.monto) }}
              <v-icon x-small>mdi-pencil</v-icon>
            </v-btn>
          </template>
          <template v-slot:item.interes="{ item }">
            <v-btn
              x-small
              text
              color="blue darken-1"
              @click="
                datosPrestamoAux = item;
                muestraIngresaCantidad = true;
                esAbono = false;
              "
            >
              {{ formatMXN(item.interes) }}
              <v-icon x-small>mdi-pencil</v-icon>
            </v-btn>
          </template>
          <template v-slot:item.fecha_registro="{ item }">
            {{ setFormatDate(item.fecha_registro) }}
          </template>
          <template v-slot:item.actions="{ item }">
            <v-btn
              color="pink darken-2"
              @click="
                datosPrestamoAux = item;
                showConfirm = true;
              "
              depressed
              dark
              x-small
              class="rounded-pill"
            >
              remover
              <v-icon right x-small>mdi-minus-circle</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <IngresaCantidad
      :muestraIngresaCantidad="muestraIngresaCantidad"
      titulo="Ingresa el nuevo monto"
      @retornaCantidad="retornaCantidad"
    />

    <SnackBar
      v-if="muestraBarra"
      :muestraBarra="muestraBarra"
      :texto="textoBarra"
      :color="colorBarra"
      @cierraBarra="muestraBarra = false"
    />

    <ConfirmDialogReclutamiento
      :showConfirm="showConfirm"
      question="¿Realmente deseas eliminar este abono registrado?"
      @cierraConfirm="validateDeletePayment"
    />
  </v-dialog>
</template>

<script>
import axios from "axios";
import { deleteToken } from "../../../../../../../store/Funciones/funciones";
import IngresaCantidad from "./IngresaCantidad.vue";
import SnackBar from "../../../../../General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue";
import VueJsonToCsv from "vue-json-to-csv";
import ConfirmDialogReclutamiento from "../../../../../AdmnVacantes/components/ConfirmDialogReclutamiento.vue";

export default {
  props: ["verDialogAbonosPrestamo", "idPrestamo"],
  components: {
    IngresaCantidad,
    SnackBar,
    VueJsonToCsv,
    ConfirmDialogReclutamiento,
  },
  data() {
    return {
      abonos: [],
      headers: [
        { value: "id", text: "id", class: "overline" },
        { value: "monto", text: "monto", class: "overline" },
        { value: "interes", text: "interes", class: "overline" },
        { value: "num_abono", text: "# abono", class: "overline" },
        { value: "fecha_registro", text: "fecha registro", class: "overline" },
        { value: "actions", text: "", class: "overline" },
      ],
      headers_to_export: {
        id: { title: "id" },
        monto: { title: "monto" },
        interes: { title: "interes" },
        num_abono: { title: "num_abono" },
        fecha_registro: { title: "fecha_registro" },
      },
      search: "",
      textoBarra: "",
      colorBarra: "",
      datosPrestamoAux: {},
      muestraBarra: false,
      loadingData: true,
      showConfirm: false,
      muestraIngresaCantidad: false,
      esAbono: false,
    };
  },
  async mounted() {
    this.abonos = await this.getDatosAbonosPrestamo();
  },
  methods: {
    showSnackBar(texto, color = "error") {
      this.textoBarra = texto;
      this.colorBarra = color;
      this.muestraBarra = true;
    },
    getDatosAbonosPrestamo() {
      const headers = {
        Authorization: localStorage.getItem("token"),
      };

      return new Promise((res) => {
        axios
          .get(
            `${this.$store.getters.getHostNode}/api/empleados/caja/prestamos/abonos/${this.idPrestamo}`,
            {
              headers,
            }
          )
          .then((response) => {
            if (response.data.status == "EXPSESSION") {
              deleteToken();
            } else if (response.data.status == "OK") {
              res(response.data.data);
            } else {
              res([]);
            }
          })
          .finally(() => {
            this.loadingData = false;
          });
      });
    },
    formatMXN(cadena) {
      return cadena.toLocaleString("es-MX", {
        style: "currency",
        currency: "MXN",
      });
    },
    setFormatDate(date) {
      let fecha = new Date(date);

      let diaUTC = fecha.getUTCDate();
      let mesUTC = fecha.getUTCMonth() + 1;
      let anioUTC = fecha.getUTCFullYear();

      diaUTC = diaUTC < 10 ? "0" + diaUTC : diaUTC;
      mesUTC = mesUTC < 10 ? "0" + mesUTC : mesUTC;

      let hora = fecha.getUTCHours();
      let minutos = fecha.getUTCMinutes();
      let segundos = fecha.getUTCSeconds();

      let horaFormateada = (hora < 10 ? "0" : "") + hora;
      let minutosFormateados = (minutos < 10 ? "0" : "") + minutos;
      let segundosFormateados = (segundos < 10 ? "0" : "") + segundos;

      return (
        diaUTC +
        "/" +
        mesUTC +
        "/" +
        anioUTC +
        " " +
        horaFormateada +
        ":" +
        minutosFormateados +
        ":" +
        segundosFormateados
      );
    },
    async retornaCantidad(respuesta) {
      this.muestraIngresaCantidad = false;
      if (respuesta !== null) {
        let response = await this.putAbonoPrestamo(respuesta);
        if (response == 1) {
          this.showSnackBar("Editado con éxito.", "success");
          this.loadingData = true;
          this.abonos = await this.getDatosAbonosPrestamo();
        } else {
          this.showSnackBar("Ocurrio un error, el registro no se editó.");
        }
      }
    },
    putAbonoPrestamo(nCantidad) {
      const params = {
        montoActual: this.esAbono
          ? this.datosPrestamoAux.monto
          : this.datosPrestamoAux.interes,
        montoNuevo: parseFloat(nCantidad),
        idPrestamo: this.datosPrestamoAux.id_prestamo,
        idAbono: this.datosPrestamoAux.id,
        emplid: this.$store.getters.getUser["Folio"],
        esAbono: this.esAbono,
      };
      const headers = {
        Authorization: localStorage.getItem("token"),
      };

      return new Promise((res) => {
        axios
          .put(
            `${this.$store.getters.getHostNode}/api/empleados/caja/prestamos/datos-abonos`,
            params,
            {
              headers,
            }
          )
          .then((response) => {
            if (response.data.status == "EXPSESSION") {
              deleteToken();
            } else if (response.data.status == "OK") {
              res(response.data.data);
            } else {
              res([]);
            }
          })
          .finally(() => {
            this.loadingMovimientos = false;
          });
      });
    },
    async validateDeletePayment(response) {
      this.showConfirm = false;
      if (response === true) {
        let response = await this.deleteAbonoPrestamo();
        if (response == 1) {
          this.showSnackBar("Eliminado con éxito.", "success");
          this.loadingData = true;
          this.abonos = await this.getDatosAbonosPrestamo();
        } else {
          this.showSnackBar("Ocurrio un error, el registro no se eliminó.");
        }
      }
    },
    deleteAbonoPrestamo() {
      const params = {
        montoActual: this.datosPrestamoAux.monto,
        montoNuevo: this.datosPrestamoAux.monto,
        idPrestamo: this.datosPrestamoAux.id_prestamo,
        idAbono: this.datosPrestamoAux.id,
        emplid: this.$store.getters.getUser["Folio"],
      };
      const headers = {
        Authorization: localStorage.getItem("token"),
      };

      return new Promise((res) => {
        axios
          .delete(
            `${this.$store.getters.getHostNode}/api/empleados/caja/prestamos/datos-abonos`,
            { headers, data: params }
          )
          .then((response) => {
            if (response.data.status == "EXPSESSION") {
              deleteToken();
            } else if (response.data.status == "OK") {
              res(response.data.data);
            } else {
              res([]);
            }
          })
          .finally(() => {
            this.loadingMovimientos = false;
          });
      });
    },
  },
};
</script>
