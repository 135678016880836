import MuestraReporte from './components/MuestraReporte.vue'

export default {
  name: 'reporte-de-principios',
  components: { MuestraReporte },

  props: [],
  data() {
    return {

    }
  },
  computed: {

  },
  mounted() {

  },
  methods: {

  }
}


