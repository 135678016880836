<!-- DataDialog.vue -->
<template>
  <v-dialog v-model="dialog" persistent max-width="90%" scrollable>
    <v-card class="rounded-xl">
      <v-card-title class="primary">
        <div class="overline font-weight-bold white--text">
          pase de ingreso con material o equipo
        </div>
        <v-spacer></v-spacer>
        <v-icon dark @click="closeDialog">mdi-close</v-icon>
      </v-card-title>
      <v-card-text class="pt-1 blue-grey lighten-5">
        <v-sheet class="rounded-0">
          <div class="pa-5">
            <v-img :src="require('@/assets/img/grupo_urrea_azul_gris.png')" max-height="70" width="250"></v-img>
          </div>
          <div class="px-5 py-0" style="display: flex; justify-content: center;">
            <v-radio-group v-model="tipo" row>
              <v-radio label="Colaborador" value="1"></v-radio>
              <v-radio label="Proveedor" value="2"></v-radio>
            </v-radio-group>
          </div>
          <v-form ref="form" v-model="valid">
            <v-row class="mx-0 px-0">
              <v-col class="px-2 py-0 my-0" :cols="getCols(6)">
                <v-autocomplete v-if="tipo === '1'" v-model="nombre" :items="itemsColaboradores"
                  item-text="nombreMostrar" :rules="[v => !!v || 'Nombre es requerido']" label="Nombre Colaborador"
                  required clearable outlined dense rounded @change="updateNoPersonal"></v-autocomplete>
                <v-text-field v-else v-model="nombre" :rules="[v => !!v || 'Nombre es requerido']" counter="180"
                  label="Nombre Proveedor" required outlined dense rounded></v-text-field>
              </v-col>

              <v-col class="px-2 py-0 my-0" :cols="getCols(3)">
                <v-text-field v-model="noPersonal" label="No personal" required outlined dense rounded
                  disabled></v-text-field>
              </v-col>

              <v-col class="px-2 py-0 my-0" :cols="getCols(3)">
                <v-text-field v-model="noProveedor" label="No proveedor" required outlined dense rounded
                  disabled></v-text-field>
              </v-col>

              <v-col class="px-2 py-0 my-0" :cols="getCols(5)">
                <v-autocomplete v-if="tipo === '2'" v-model="proveedor" item-text="NOMBRE" :items="itemsProveedores"
                  :rules="[v => !!v || 'Proveedor es requerido']" label="Proveedor" required clearable outlined dense
                  return-object :search-input.sync="search" rounded hide-no-data @change="onChange"
                  :loading="loadingNombreCompania"></v-autocomplete>
                <v-text-field v-else v-model="proveedor" :disabled="tipo === '1'" label="Proveedor" required outlined
                  dense rounded></v-text-field>
              </v-col>

              <v-col class="px-2 py-0 my-0" :cols="getCols(4)">
                <v-text-field v-model="domicilio" :rules="[v => !!v || 'Domicilio es requerido']" label="Domicilio"
                  counter="180" required outlined dense rounded :disabled="tipo === '1'"></v-text-field>
              </v-col>

              <v-col class="px-2 py-0 my-0" :cols="getCols(3)">
                <v-text-field v-model="telefono" counter="15" :rules="[v => !!v || 'Teléfono es requerido']"
                  label="Teléfono" required outlined dense rounded :disabled="tipo === '1'"></v-text-field>
              </v-col>
            </v-row>
            <v-row class="mx-1 px-0 py-1">
              <v-col class="px-2 py-0 my-0" :cols="getCols(12)">
                <v-card class="rounded-lg elevation-1" height="100%">
                  <v-card-title class="primary py-1">
                    <div class="overline font-weight-bold white--text">
                      Datos vehiculo:
                    </div>
                  </v-card-title>
                  <v-card-text class="center-item-in-div pt-5">
                    <v-row class="mx-0 px-0">
                      <v-col class="px-2 py-0 my-0" :cols="getCols(5)">
                        <v-text-field v-model="nombreAuto" :rules="[v => !!v || 'Nombre es requerido']" counter="180"
                          label="Nombre Conductor" required outlined dense rounded></v-text-field>
                      </v-col>

                      <v-col class="px-2 py-0 my-0" :cols="getCols(4)">
                        <v-text-field v-model="modeloAuto" label="Marca - Modelo"
                          :rules="[v => !!v || 'Modelo de vehiculo es requerido']" required outlined dense
                          rounded></v-text-field>
                      </v-col>

                      <v-col class="px-2 py-0 my-0" :cols="getCols(3)">
                        <v-text-field v-model="placaAuto" label="Placas" :rules="[v => !!v || 'Placas es requerido']"
                          required outlined dense rounded></v-text-field>
                      </v-col>
                    </v-row>

                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-row class="mx-1 px-0  py-1">
              <v-col class="px-2 py-0 my-0" :cols="getCols(6)">
                <v-card class="rounded-lg elevation-1" height="100%">
                  <v-card-title class="primary py-1">
                    <div class="overline font-weight-bold white--text">
                      Area a la que ingresa:
                    </div>
                  </v-card-title>
                  <v-card-text class="center-item-in-div pt-5">
                    <v-sheet v-if="loadingAreas" class="center-item-in-div" height="100%">
                      <v-progress-circular indeterminate color="primary"></v-progress-circular>
                    </v-sheet>
                    <v-select v-else v-model="area" class="rounded-lg"
                      v-on:change="loadingAcciones = true; getAccionesPorArea();" dense outlined :items="areas"
                      item-text="Area" item-value="id" placeholder="Selecciona el área..."></v-select>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col class="px-2 py-0 my-0" :cols="getCols(6)">
                <v-card class="rounded-lg elevation-1" height="100%">
                  <v-card-title class="primary py-1">
                    <div class="overline font-weight-bold white--text">
                      Justificación:
                    </div>
                  </v-card-title>
                  <v-card-text class="pt-5">
                    <label v-if="acciones.length === 0 && !loadingAcciones" class="text-center overline">
                      Seleccione un área para elegir la justificación.
                    </label>
                    <v-select v-else-if="acciones.length > 0 && !loadingAcciones" v-model="accion" class="rounded-lg"
                      dense outlined :items="acciones" item-text="descripcion" item-value="id"
                      placeholder="Selecciona la acción..."></v-select>
                    <v-sheet v-else class="center-item-in-div" height="100%">
                      <v-progress-linear indeterminate></v-progress-linear>
                    </v-sheet>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-row class="mx-1 px-0  py-1">
              <v-col class="px-2 py-0 my-0" :cols="getCols(6)">
                <v-switch v-model="tieneOT" :label="`Son herramientas para CPT? ${tieneOT ? 'Sí' : 'No'}`">
                </v-switch>
              </v-col>
              <v-col class="px-2 py-0 my-0" :cols="getCols(6)">
                <v-select v-model="unidadSeleccionada" :items="unidadesDeMedida" item-text="descripcion" item-value="id"
                  dense outlined :loading="loadingUnidadesDeMedida" label="Unidad de medida" required
                  placeholder="Selecciona la unidad de medida..."
                  :rules="[v => !!v || 'Tipo de medida es requerido']"></v-select>
              </v-col>
            </v-row>
            <v-simple-table dense>
              <thead>
                <tr class="primary">
                  <th class="overline white--text text-center">No. Serie</th>
                  <th class="overline white--text text-center">Cantidad</th>
                  <th class="overline white--text text-center" cols="2">descripción</th>
                  <th class="overline white--text text-center">orden Compra</th>
                  <th class="overline white--text text-center">No Factura</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in materiales" :key="index">
                  <td style="border: 0.7px solid #A8A8A7; max-width: 100px;">
                    <input v-model="item.noSerie" type="text" style="width: 100%;" />
                  </td>
                  <td style="border: 0.7px solid #A8A8A7; max-width: 60px;">
                    <input v-model="item.cantidad" type="number" style="width: 100%;" />
                  </td>
                  <td style="border: 0.7px solid #A8A8A7;">
                    <input v-model="item.descripcion" type="text" style="width: 100%;" />
                  </td>
                  <td style="border: 0.7px solid #A8A8A7; max-width: 100px;">
                    <input v-model="item.ordenCompra" type="text" style="width: 100%;" />
                  </td>
                  <td style="border: 0.7px solid #A8A8A7;">
                    <input v-model="item.noFactura" type="text" style="width: 100%;" />
                  </td>
                </tr>
              </tbody>
            </v-simple-table>

            <div class="text-right px-6 mt-3">
              <v-btn fab x-small class="mx-1 elevation-1" dark @click="removeMaterial()" color="pink darken-2"
                v-show="materiales.length > 1"> <v-icon>mdi-minus</v-icon>
              </v-btn>
              <v-btn fab x-small class="mx-1 elevation-1" dark color="teal darken-1" @click="addMaterial">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </div>
          </v-form>
          <div v-if="tieneOT" class="px-5 mb-3 mt-3">
            <v-card class="rounded-lg elevation-1">
              <v-card-title style="display: flex; justify-content: center; align-items:top;"
                class="primary py-1 overline font-weight-bold white--text">
                Buscador de herramientas para Centro de Servicio Master
              </v-card-title>
              <v-card-text class="pa-1">
                <div style="display: flex; justify-content: center; align-items:top;">
                  <!-- `<v-autocomplete v-model="herramientasSelect" chips deletable-chips multiple :items="herramientasList"
                    class="rounded-pill rounded-r-0" dense outlined item-text="sku" @input="onInputChange" hide-no-data
                    return-object :search-input.sync="searchHerramienta" :loading="loadingHerramientas"
                    placeholder="Busca las herramientas..."></v-autocomplete>` -->
                  <v-text-field v-model="herramientasSelect" class="rounded-pill rounded-r-0" dense outlined
                    placeholder="Busca las herramientas..."
                    hint="Por favor, separe por , (comas) los sku."></v-text-field>
                  <v-btn color="primary" class="rounded-pill rounded-l-0" depressed dark @click="buscaHerramientas"
                    :loading="loadingBuscaHerramienta">
                    <v-icon>mdi-magnify</v-icon>
                  </v-btn>
                </div>
                <div>
                  <v-data-table v-show="itemsHerramientasInfo" dense :headers="headers" :items="itemsHerramientas"
                    :loading="loadingBuscaHerramienta" class="elevation-1"
                    no-data-text="Ningún elemento coincide con el criterio de búsqueda"
                    :footer-props="{ 'items-per-page-text': 'Elementos por página', }"
                    :header-props="{ 'short-by-text': 'Ordenar por', }" loading-text="Cargando contenido...">
                  </v-data-table>
                </div>
              </v-card-text>
            </v-card>
          </div>
        </v-sheet>

      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="rounded-xl" color="teal darken-1" :loading="loadingBtnSavePasse" dark
          @click="loadingBtnSavePasse = true; savePasse();">
          <v-icon left>mdi-text-box-plus</v-icon>
          crear
        </v-btn>
      </v-card-actions>
      <SnackBarErrorIncidencias v-if="muestraBarra" :muestraBarra="muestraBarra" :color="colorBarra" :texto="textoBarra"
        @cierraBarra="muestraBarra = false" />
    </v-card>
  </v-dialog>
</template>

<script>
import SnackBarErrorIncidencias from "../../../General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue";
import { deleteToken } from "../../../../../store/Funciones/funciones.js";
import axios from "axios";

export default {
  props: {
    dialog: Boolean,
  },
  components: { SnackBarErrorIncidencias },

  data() {
    return {
      muestraBarra: false,
      //
      valid: false,
      tipo: '1',
      nombre: '',
      noPersonal: '',
      noProveedor: '',
      proveedor: null,
      domicilio: '',
      telefono: '',
      loadingBtnSavePasse: false,
      itemsColaboradores: [],
      itemsProveedores: [],
      loadingNombreCompania: false,
      search: '',
      searchText: '',
      //
      loadingAreas: false,
      area: '',
      areas: [],
      acciones: [],
      accion: null,
      loadingAcciones: false,
      //
      tieneOT: false,
      unidadSeleccionada: null,
      unidadesDeMedida: [],
      loadingUnidadesDeMedida: false,
      materiales: [{ noSerie: '', cantidad: 1, descripcion: '', ordenCompra: '', noFactura: '' },],
      //
      herramientasList: [],
      searchHerramienta: '',
      searchSKU: '',
      herramientasSelect: null,
      loadingHerramientas: false,
      loadingBuscaHerramienta: false,
      headers: [
        { text: 'Id Producto CC', value: 'sku', class: "text-uppercase font-weight-bold blue-grey--text text--darken-2", },
        { text: 'Descripción', value: 'descripcion', class: "text-uppercase font-weight-bold blue-grey--text text--darken-2", },
      ],
      itemsHerramientas: [],
      itemsHerramientasInfo: false,
      //
      nombreAuto: '',
      modeloAuto: '',
      placaAuto: '',
    };
  },
  mounted() {
    this.getColaboradores();
    this.getAreas();
    this.getUnidadesDeMedida();
  },
  methods: {
    openSnackBar(texto, color = "pink darken-3") {
      this.colorBarra = color;
      this.textoBarra = texto;
      this.muestraBarra = true;
    },
    closeDialog() {
      this.$emit('close');
    },
    savePasse() {
      if ((this.isValid() && this.materiales.length > 0) || this.itemsHerramientas.length > 0) {

        if (this.$refs.form.validate()) {
          let params = {
            Opcion: 2,
            token: localStorage.getItem("token"),
            nombre: this.nombre,
            noPersonal: this.noPersonal,
            noProveedor: this.noProveedor,
            proveedor: this.proveedor,
            domicilio: this.domicilio,
            telefono: this.telefono,
            nombreAuto: this.nombreAuto,
            modeloAuto: this.modeloAuto,
            placaAuto: this.placaAuto,
            area: this.area,
            accion: this.accion,
            unidadSeleccionada: this.unidadSeleccionada,
            materialesOT: this.tieneOT ? this.itemsHerramientas : null,
            materiales: this.materiales,
            tipo: this.tipo,
          };
          axios
            .post(
              `${this.$store.getters.getHost}/SeguridadPatrimonial/ws-pases-de-ingreso.php`,
              params
            )
            .then((response) => {
              this.loadingData = false;
              if (response.data.status == "OK") {
                this.openSnackBar(
                  response.data.respuesta.message,
                  "teal darken-1"
                );
                this.$emit('save', true);
              } else if (response.data.status == "EXPRESSION") {
                deleteToken();
              }
            })
            .catch((e) => {
              this.openSnackBar(e);
            })
            .finally(() => {
              this.loadingBtnSavePasse = false;
            });
        } else { this.loadingBtnSavePasse = false; }
      } else {
        this.loadingBtnSavePasse = false;
        alert('Por favor, complete todos los campos de materiales en al menos una fila.');
      }
  },
  addMaterial() {
    if (this.isValid()) {
      this.materiales.push({ noSerie: '', cantidad: '', descripcion: '', ordenCompra: '', noFactura: '' });
    } else {
      alert('Por favor, complete todos los campos en la fila actual antes de agregar una nueva fila.');
    }
  },
  removeMaterial() {
    if (this.items.length > 0) {
      this.items.pop();
    }
  },
  isValid() {
    return this.materiales.every(item => item.noSerie && item.cantidad && item.descripcion && item.ordenCompra && item.noFactura);
  },
  getColaboradores() {
    const params = {
      Opcion: 11,
      token: localStorage.getItem("token"),
    };
    axios
      .post(
        `${this.$store.getters.getHost}/SeguridadPatrimonial/ws-pases-de-salida.php`,
        params
      )
      .then((response) => {
        if (response.data.status == "OK") {
          this.itemsColaboradores = response.data.respuesta;
        } else if (response.data.status == "EXPRESSION") {
          deleteToken();
        }
      })
      .catch((e) => {
        this.openSnackBar(e);
      })
      .finally(() => {
        this.loadingColaboradores = false;
      });
  },
  getUnidadesDeMedida() {
    const params = {
      Opcion: 17,
      token: localStorage.getItem("token"),
    };
    this.loadingUnidadesDeMedida = true;

    axios
      .post(
        `${this.$store.getters.getHost}/SeguridadPatrimonial/ws-pases-de-salida.php`,
        params
      )
      .then((response) => {
        if (response.data.status == "OK") {
          this.unidadesDeMedida = response.data.respuesta;
        } else if (response.data.status == "EXPRESSION") {
          deleteToken();
        }
      })
      .catch((e) => {
        this.openSnackBar(e);
      })
      .finally(() => {
        this.loadingUnidadesDeMedida = false;
      });
  },
  getAreas() {
    const params = {
      Opcion: 3,
      token: localStorage.getItem("token"),
    };
    this.loadingAreas = true;

    axios
      .post(
        `${this.$store.getters.getHost}/SeguridadPatrimonial/ws-pases-de-salida.php`,
        params
      )
      .then((response) => {
        if (response.data.status == "OK") {
          this.areas = response.data.areas;
        } else if (response.data.status == "EXPRESSION") {
          deleteToken();
        }
      })
      .catch((e) => {
        this.openSnackBar(e);
      })
      .finally(() => {
        this.loadingAreas = false;
      });
  },
  getAccionesPorArea() {
    this.loadingAcciones = true;
    const params = {
      Opcion: 14,
      idArea: this.area,
      token: localStorage.getItem("token"),
    };
    axios
      .post(
        `${this.$store.getters.getHost}/SeguridadPatrimonial/ws-pases-de-ingreso.php`,
        params
      )
      .then((response) => {
        if (response.data.status == "OK") {
          this.acciones = response.data.respuesta;
        } else if (response.data.status == "EXPRESSION") {
          deleteToken();
        }
      })
      .catch((e) => {
        this.openSnackBar(e);
      })
      .finally(() => {
        this.loadingAcciones = false;
      });
  },
  async getProveedores() {
    this.loadingNombreCompania = true;
    let params = {
      Opcion: 5,
      token: localStorage.getItem("token"),
      nombre: this.searchText,
    };
    try {
      const response = await axios.post(
        `${this.$store.getters.getHost}/SeguridadPatrimonial/ws-RegistroVisitantes.php`,
        params
      );
      if (response.data.status == "OK") {
        this.itemsProveedores = response.data.proveedores;
      }
    } catch (error) {
      this.openSnackBar(error);
    } finally {
      this.loadingNombreCompania = false;
    }
  },
  updateNoPersonal(selectedItem) {
    if (selectedItem && this.tipo === '1') {
      const selectedColaborador = this.itemsColaboradores.find(item => item.nombreMostrar === selectedItem);
      if (selectedColaborador) {
        this.noPersonal = selectedColaborador.EMP_KEYEMP;
        this.domicilio = selectedColaborador.domicilio;
        this.telefono = selectedColaborador.telefono;
        this.proveedor = selectedColaborador.nombreCompania;
        this.noProveedor = selectedColaborador.Compania;
      } else {
        this.noPersonal = '';
        this.noPersonal = '';
        this.domicilio = '';
        this.proveedor = '';
        this.noProveedor = '';
        this.telefono = '';
      }
    }
  },
  onChange(value) {
    if (value && this.tipo === '2') {
      if (value) {
        this.noProveedor = value.NO_PROVEEDOR;
      } else {
        this.noProveedor = '';
      }
    }
  },
  getHerramientasList() {
    const params = {
      Opcion: 19,
      token: localStorage.getItem("token"),
      herramienta: this.searchSKU
    };
    this.loadingHerramientas = true;

    axios
      .post(
        `${this.$store.getters.getHost}/SeguridadPatrimonial/ws-pases-de-ingreso.php`,
        params
      )
      .then((response) => {
        if (response.data.status == "OK") {
          this.herramientasList = response.data.herramientas;
        } else if (response.data.status == "EXPRESSION") {
          deleteToken();
          throw new Error('Token expired');
        } else {
          throw new Error('Failed to load Herramientas list');
        }
      })
      .catch((error) => {
        this.openSnackBar(error);
        console.error('Error loading herramientas List:', error);
      })
      .finally(() => {
        this.loadingHerramientas = false;
      });
  },
  buscaHerramientas() {
    this.loadingBuscaHerramienta = true;
    const params = {
      Opcion: 20,
      token: localStorage.getItem("token"),
      herramientas: this.herramientasSelect,
    };
    axios
      .post(
        `${this.$store.getters.getHost}/SeguridadPatrimonial/ws-pases-de-ingreso.php`,
        params
      )
      .then((response) => {
        if (response.data.status == "OK") {
          this.itemsHerramientas = response.data.herramientas;
          this.itemsHerramientasInfo = true;
        } else if (response.data.status == "EXPRESSION") {
          deleteToken();
          throw new Error('Token expired');
        } else {
          this.itemsHerramientasInfo = false;
          throw new Error('Failed to load pedidos');
        }
      })
      .catch((error) => {
        this.openSnackBar(error);
        console.error('Error loading pedidosList:', error);
      })
      .finally(() => {
        this.loadingBuscaHerramienta = false;
      });
  },
},
watch: {
  dialog(val) {
    if (!val) {
      this.tipo = '1';
      this.nombre = '';
      this.noPersonal = '';
      this.proveedor = '';
      this.noProveedor = '';
      this.domicilio = '';
      this.telefono = '';
      this.accion = null;
      this.area = null;
      this.$refs.form.reset();
      this.tieneOT = false;
      this.nombreAuto = '';
      this.modeloAuto = '';
      this.placaAuto = '';
      this.loadingBtnSavePasse = false;
      this.materiales = [{ noSerie: '', cantidad: 1, descripcion: '', ordenCompra: '', noFactura: '' }];
    }
  },
  search(value) {
    this.searchText = value;
    if (value && value.length >= 2) {
      this.getProveedores();
    }
  },

  searchHerramienta(value) {
    this.searchSKU = value;
    if (value && value.length >= 2) {
      this.getHerramientasList();
    }
  }
},
computed: {
  getCols() {
    return (defaultCols) => {
      return this.$vuetify.breakpoint.xsOnly ? 12 : defaultCols;
    };
  }
},
};
</script>