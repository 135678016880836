import axios from 'axios'
// import { deleteToken } from '../../../../../store/Funciones/funciones'
import AdmonUpasPorCertificaciones from "../Components/AdmonUpasPorCertificaciones.vue";
import AdmonEppPorCertificaciones from "../Components/AdmonEppPorCertificaciones.vue";
import SnackBarError from "../../../General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue";
import FormCargaEppPorCertificaciones from "../Components/FormCargaEppPorCertificaciones.vue";
import MantenimientoModulo from "../../../../../components/MantenimientoModulo.vue";
export default {
  name: 'admon-matriz-epp',
  components: { SnackBarError, AdmonUpasPorCertificaciones, AdmonEppPorCertificaciones, FormCargaEppPorCertificaciones, MantenimientoModulo },
  props: [],
  data() {
    return {
      textoBarra: "",
      colorBarra: "",
      muestraBarra: false,

      max25chars: v => v.length <= 25 || 'Input too long!',
      pagination: {},
      headers: [
        { text: 'ID', value: 'id' },
        { text: 'UPAS', value: 'Descripcion' },
        { text: 'Acciones', value: 'actions', align: 'center' },
        { text: '', value: 'data-table-expand' },
      ],
      headersOperaciones: [
        { text: 'ID', value: 'Id_operacion' },
        { text: 'UPAS', value: 'nombreOperacion' },
      ],
      upas: [],
      expanded: [],
      singleExpand: true,
      dialogFiltro: false,
      loading: true,
      id_upas: [],
      epp: [],
      dialogAsignacion: false,
      id_upa: '',
      operaciones: [],
      selectedItem: 1,
      tab: null,
      dialogConsultaEPP: false,
      tipo: "Masivo",
      editedItem: [],
      moduloError: false,
    }
  },
  computed: {

  },
  mounted() {
    this.getUpas();
  },
  methods: {
    getUpas() {
      this.loading = true;
      let params = {
        Opcion: 9,
        upas: this.id_upas,
        token: localStorage.getItem("token"),
      };
      axios
        .post(`${this.$store.getters.getHost}/General/EPP/ws-admon-epp.php`, params)
        .then((response) => {
          var data = response.data.upas;
          if (data) {
            this.upas = data;
            this.dialogFiltro = false;
          } else if (response.data.status == "error" || !response.data.status) {
            this.moduloError = true;
          }
        });
      this.loading = false;
    },

    dialogAsignacionEPP(item) {
      console.log(item);
      if (item.operaciones) {
        this.editedItem = item;
        this.operaciones = [];
        item.operaciones.map((element) => {
          this.operaciones.push(element.idCertificacion);
        });
        this.dialogAsignacion = true;
      } else {
        this.textoBarra = "Para cargar EPP necesita asociar certifiaciones a la UPA seleccionada.";
        this.colorBarra = "error";
        this.muestraBarra = true;
      }
    },

    consultaEPP(item) {
      console.log(item);
      this.id_upa = item.id;
      this.dialogConsultaEPP = true;
    },

    open(item) {
      console.log(item.nombre);
    },
  }
}




