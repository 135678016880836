<template>
  <v-card
    class="rounded-lg elevation-0"
    height="100%"
    width="100%"
    color="blue-grey lighten-5"
  >
    <v-card-title
      class="py-1 center-item-in-div primary rounded-lg rounded-b-0"
    >
      <label class="overline font-weight-bold white--text">
        bitácora de cambios
      </label>
    </v-card-title>
    <v-data-table
      dense
      no-data-text="No hay registros"
      loading-text="Cargando bitácora..."
      :items-per-page="4"
      :loading="loadingBitacora"
      style="background-color: transparent;"
      :items="bitacora"
      :headers="headers"
      :footer-props="{
        'items-per-page-text': 'Elementos por página',
        pageText: '{0}-{1} de {2}',
      }"
    >
    <template v-slot:item.descripcion="{item}">
      <v-chip x-small dark :color="getColor(item.id_action)">
        {{ item.descripcion }}
      </v-chip>
    </template>
    </v-data-table>
  </v-card>
</template>

<script>
import axios from "axios";
export default {
  name: "ver-bitacora-de-cambios-relacion-empleado-responsable",
  data() {
    return {
      loadingBitacora: true,
      headers: [
        {
          value: "id",
          text: "ID",
          class: "text-uppercase font-weight-bold",
        },
        {
          value: "EMP_NOMEMP",
          text: "Emplead@",
          class: "text-uppercase font-weight-bold",
        },
        {
          value: "descripcion",
          text: "Acciones",
          align: "center",
          class: "text-uppercase font-weight-bold",
        },
      ],
      bitacora: [],
    };
  },
  async mounted() {
    this.bitacora = await this.getBitacora();
  },
  methods: {
    getBitacora() {
      const params = {
        Opcion: 13,
        token: localStorage.getItem("token"),
      };

      return new Promise((resolve, reject) => {
        axios
          .post(
            `${this.$store.getters.getHost}/RelacionesLaborales/RelacionEmpleadoResponsable/ws-empleado-responsable.php`,
            params
          )
          .then((response) => {
            if (response.data.status === "OK") {
              resolve(response.data.respuesta);
            } else {
              resolve([]);
            }
          })
          .catch((e) => {
            reject(e);
          })
          .finally(() => {
            this.loadingBitacora = false;
          });
      });
    },
    getColor(id_action){
      if(id_action == 1){
        return 'teal darken-1'
      } else if(id_action == 2){
        return 'pink darken-3'
      } else {
        return 'primary'
      }
    }
  },
};
</script>
