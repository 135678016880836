import axios from "axios";
import VueJsonToCsv from 'vue-json-to-csv'
import SnackBarErrorIncidencias from "../../../General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue"
import PersonaMiKardexLaboral from "../../../../../components/PersonaMiKardexLaboral.vue";
import { deleteToken } from '../../../../../store/Funciones/funciones';
export default {
    name: 'lista-asistencia',
    components: {
        SnackBarErrorIncidencias,
        PersonaMiKardexLaboral,
        VueJsonToCsv
    },
    props: [],
    data() {
        return {
            loadingInfo: true,
            headers: [
                { text: 'Folio', value: 'num_empleado', align: 'center', sortable: true },
                { text: 'Nombre', value: 'nombre', align: 'center', sortable: true },
                { text: 'Fecha ingreso', value: 'fecha_ingreso', align: 'center', sortable: true },
                { text: 'Area', value: 'area', align: 'center', sortable: true },
                // { text: 'Departamento', value: 'departamento', align: 'center',sortable: true   },
                { text: 'Responsable', value: 'jefe_directo_nombre', align: 'center', sortable: true },
                { text: 'Dias transcurridos', value: 'dias_laborando', align: 'center', sortable: true },
                { text: 'Certificado por', value: 'usr_aut_certificacion', align: 'center', sortable: true },
                { text: 'Fecha certificado', value: 'fecha_certificacion', align: 'center', sortable: true },
                // { text: 'Autoriza jefe', value: 'usr_aut_jefe_directo', align: 'center',sortable: true   },
                { text: 'Fecha autorizó responsable', value: 'fecha_jefe_directo', align: 'center', sortable: true },
                // { text: 'Dias faltantes', value: 'dias_faltantes', align: 'center',sortable: true   },
                { text: 'Termino de contrato', value: 'fecha_termino_contrato', align: 'center', sortable: true },
                // { text: 'Grupo pago', value: 'grupo_pago', align: 'center',sortable: true   },
                { text: 'Certificación', value: 'certificacion', align: 'center', sortable: true },
                { text: 'Kardex', value: 'kardex', align: 'center', sortable: true },
                { text: 'Proceso', value: 'estatus', align: 'center', sortable: true },
            ],
            headers_report_to_export: {
                num_empleado: { title: "Folio" },
                nombre: { title: "Nombre" },
                Fecha_ingreso: { title: "Fecha de ingreso" },
                area: { title: "Area" },
                jefe_directo_nombre: { title: "Responsable" },
                dias_laborando: { title: "Dias transcurridos" },
                usr_aut_certificacion: { title: "Certificado por" },
                fecha_certificacion: { title: "Fecha certificado" },
                fecha_jefe_directo: { title: "Fecha autorizó responsable" },
                fecha_termino_contrato: { title: "Termino de contrato" },
                certificacion: { title: "Certificación" },
                usuarioPlantaAsignada: { title: "Usuario planta asignada" },
                fechaPlantaAsignada: { title: "Fecha planta asignada" },
            },
            colorsTema: [
                // {color: 'yellow lighten-3',tema: false},
                { color: 'yellow accent-4', tema: false },
                { color: 'blue', tema: true },
                { color: 'teal darken-1', tema: true },
                { color: 'green darken-2', tema: true },
            ],
            colorsTema1: [
                { color: 'teal darken-1', tema: true },
                { color: 'blue darken-4', tema: true },
                { color: 'red', tema: true },
            ],
            estatusValidaciones: [
                // 'Kardex',
                'Certificación',
                'Jefe inmediato',
                'Firma de contrato',
                'Asignación de planta',
            ],
            validacionesJefeInmediato: [
                'Firma de contrato',
                'Extención de contrato',
                'Termino de contrato',
            ],
            plantasOperativas: [],
            validacionesFinales: ["Firma de contrato", "Extensión de contrato", "Termino de contrato"],
            cargando: true,
            buscar: '',
            folio_trabajador: '',
            id_plantaOperativa: '',
            diasLaborando: '',
            diasContrato: '',
            nuevoEstatus: '',
            tituloDialog: '',
            validacionFinal: '',
            comentario: '',
            dias: '',
            folioColaborador: '',
            nombreColaborador: '',
            openKardex: false,
            dialogConfirmKardex: false,
            dialogContrato: false,
            dialogExtenderContrato: false,
            // esJefeDirecto: false,
            esJefeDirecto: '',

            numCertificacion: [],
            titleDialogContrato: '',
            openCertificacion: false,
            //---------------------------------
            textoBarra: '',
            colorBarra: '',
            muestraBarra: false,

            // CERTIFICACIONES //
            certificaciones: [],
            certificacionesPorColaborador: [],
            loading: false,
            nameFile: '',
            itemsPerPage: 3,
        }
    },
    computed: {},
    mounted() {
        this.revisaJefeDirecto();
        this.getCertificaciones();
        //  window.document.title = "DH:URREA - Plantas Operativas.";
    },
    methods: {

        validaFormulario() {
            if (this.comentario && this.validacionFinal) {
                if (this.validacionFinal == "Firma de contrato") {
                    this.nuevoEstatus = 6;
                    this.dias = '';
                    this.confirmarValidacionKardex();
                } else if (this.validacionFinal == "Extensión de contrato") {
                    if (this.dias) {
                        this.extenderContrato();
                    } else {
                        this.textoBarra = 'Complete los campos necesarios';
                        this.colorBarra = 'primary';
                        this.muestraBarra = true;
                    }
                } else if (this.validacionFinal == "Termino de contrato") {
                    this.nuevoEstatus = 5;
                    this.dias = '';
                    this.confirmarValidacionKardex();
                }
            } else {
                this.textoBarra = 'Complete los campos necesarios';
                this.colorBarra = 'primary';
                this.muestraBarra = true;
            }
        },
        openDValidacionContrato(item) {
            this.dias = '';
            this.comentario = '';
            this.validacionFinal = '';
            this.dialogContrato = true;
            this.id_plantaOperativa = item.id;
            this.diasLaborando = item.dias_contrato - item.dias_laborando;
            this.diasContrato = item.dias_contrato;
            this.folioColaborador = item.num_empleado;
            this.nombreColaborador = item.nombre;
        },

        openDValidacionKardex(item) {
            this.id_plantaOperativa = item.id;
            this.diasLaborando = item.dias_contrato - item.dias_laborando;
            this.diasContrato = item.dias_contrato;
            this.nuevoEstatus = item.estatus;
            if (this.nuevoEstatus == 2) {
                this.tituloDialog = "Confirmar Kardex";
                this.folioColaborador = item.num_empleado;
                this.nombreColaborador = item.nombre;
            } else if (this.nuevoEstatus == 3) {
                this.tituloDialog = "Confirmar certificación";
                this.folioColaborador = item.num_empleado;
                this.nombreColaborador = item.nombre;
            } else if (this.nuevoEstatus == 4) {
                this.tituloDialog = "Confirmar jefe inmediato";
                this.folioColaborador = item.num_empleado;
                this.nombreColaborador = item.nombre;
            } else if (this.nuevoEstatus == 7) {
                this.tituloDialog = "Confirmar asignación de planta";
                this.folioColaborador = item.num_empleado;
                this.nombreColaborador = item.nombre;
            }
            this.dialogConfirmKardex = true;
        },
        extenderContratoDialog() {
            this.dialogExtenderContrato = true;
            this.nuevoEstatus = 2;
        },
        extenderContrato() {
            let params = {
                Opcion: 3,
                id: this.id_plantaOperativa,
                dias: this.dias,
                dias_contrato: this.diasContrato,
                token: localStorage.getItem("token"),
                comentario: this.comentario,
                nuevoEstatus: this.nuevoEstatus,
                folioColaborador: this.folioColaborador,
                nombreColaborador: this.nombreColaborador
            }
            axios.post(`${this.$store.getters.getHost}/RelacionesLaborales/PlantasOperativas/ws-plantasOperativas.php`, params).then((response) => {
                if (response.data.status == 'OK') {
                    this.id_plantaOperativa = '';
                    this.dias = '';
                    this.dialogConfirmKardex = false;
                    this.dialogExtenderContrato = false;
                    this.comentario = '';
                    this.diasContrato = '';
                    this.cargando = true;
                    this.textoBarra = 'Extrensión de contrato actualizada, favor de tener sus fechas termino de contrato en orden';
                    this.colorBarra = 'success';
                    this.muestraBarra = true;
                    this.dialogContrato = false;
                    this.plantasOperativas = [];
                    this.revisaJefeDirecto();
                }
                if (response.data.status == 'error') {
                    this.textoBarra = 'Se produjo un error';
                    this.colorBarra = 'error';
                    this.muestraBarra = true;
                }
            })

        },
        confirmarValidacionKardex() {
            let params = {
                Opcion: 2,
                id: this.id_plantaOperativa,
                dias: this.dias,
                token: localStorage.getItem("token"),
                estatus: this.nuevoEstatus,
                comentario: this.comentario,
                folioColaborador: this.folioColaborador,
                nombreColaborador: this.nombreColaborador,
                numCertificacion: this.numCertificacion
            }
            this.loading = true;
            axios.post(`${this.$store.getters.getHost}/RelacionesLaborales/PlantasOperativas/ws-plantasOperativas.php`, params).then((response) => {
                if (response.data.status == 'OK') {
                    this.id_plantaOperativa = '';
                    this.plantasOperativas = [];
                    this.dialogConfirmKardex = false;
                    this.dialogContrato = false;
                    this.dias = '';
                    this.comentario = '';
                    this.validacionFinal = '';
                    this.numCertificacion = [];
                    this.cargando = true;
                    this.textoBarra = 'Se ha confirmado exitosamente';
                    this.colorBarra = 'success';
                    this.muestraBarra = true;
                    this.revisaJefeDirecto();
                } else if (response.data.status == 'error') {
                    this.textoBarra = 'Se produjo un error';
                    this.colorBarra = 'error';
                    this.muestraBarra = true;
                } else if (response.data.status == 'noAceptada') {
                    this.textoBarra = 'Para esta validacion debe ser su jefe directo';
                    this.colorBarra = 'error';
                    this.muestraBarra = true;
                } else if (response.data.status == 'VACIO') {
                    this.textoBarra = 'Complete el campo de certificación para continuar';
                    this.colorBarra = 'error';
                    this.muestraBarra = true;
                }
            })
            this.loading = false;

        },
        openKardexLista(folio) {
            this.openKardex = true;
            this.folio_trabajador = folio;
            this.getKardex(this.folio_trabajador);
        },
        revisaJefeDirecto() {
            let params = {
                Opcion: 4,
                token: localStorage.getItem("token"),
                // NPResponsable:12026
            }
            axios.post(`${this.$store.getters.getHost}/RelacionesLaborales/PlantasOperativas/ws-plantasOperativas.php`, params).then((response) => {
                if (response.data.status != 'noAccess') {
                    // this.esJefeDirecto = true;
                    this.esJefeDirecto = response.data.status;
                    this.getEmpleadosSinPlanta();
                } else {
                    this.esJefeDirecto = response.data.status;
                }
                this.loadingInfo = false;

            })
        },
        getEmpleadosSinPlanta() {
            let params = {
                Opcion: 1,
                token: localStorage.getItem("token"),
                esJefeDirecto: this.esJefeDirecto,
                // NPResponsable:14249
            }
            axios.post(`${this.$store.getters.getHost}/RelacionesLaborales/PlantasOperativas/ws-plantasOperativas.php`, params).then((response) => {
                if (response.data.status == 'OK') {
                    if (response.data.data) {
                        this.plantasOperativas = response.data.data;
                    }
                }
                this.cargando = false;
            })
        },

        getCertificaciones() {
            let params = {
                Opcion: 2,
                token: localStorage.getItem('token')
            }
            axios
                .post(`${this.$store.getters.getHost}/General/SolicitudCapacitaciones/ws-solicitud-capacitaciones.php`, params)
                .then((response) => {
                    if (response.data.status == "EXPSESSION") {
                        deleteToken();
                    } else {
                        var data = response.data.capacitaciones;
                        if (data) {
                            this.certificaciones = data;
                        }
                    }
                })
        },

        fixName() {
            let numero_aleatorio =
                Math.floor(Math.random() * (99999 - 10000 + 1)) + 10000;
            this.nameFile = "plantasOperativas_" + numero_aleatorio.toString();
        },

        openDialog(item) {
            this.openCertificacion = true;
            this.numCertificacion = item.certificacion
            console.log(item);
            let emplid = item.num_empleado;
            let params = {
                Opcion: 5,
                token: localStorage.getItem("token"),
                emplid: emplid
            }
            axios.post(`${this.$store.getters.getHost}/RelacionesLaborales/PlantasOperativas/ws-plantasOperativas.php`, params).then((response) => {
                if (response.data.status == 'OK') {
                    this.certificacionesPorColaborador = response.data.certificaciones;
                } else {
                    this.textoBarra = 'No se encuentran las certificaciones cargadas';
                    this.colorBarra = 'error';
                    this.muestraBarra = true;
                }
                this.cargando = false;
            })
        },
    }
}