<template>
  <Doughnut
    :chart-options="chartOptions"
    :chart-data="chartData"
    :chart-id="chartId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :width="width"
    :height="height"
  />
</template>

<script>
import axios from "axios";
import { Doughnut } from "vue-chartjs/legacy";
import { deleteToken } from "../../../../store/Funciones/funciones";

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale,
} from "chart.js";

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale);

export default {
  name: "DoughnutChart",
  components: {
    Doughnut,
  },
  props: {
    chartId: {
      type: String,
      default: "doughnut-chart",
    },
    datasetIdKey: {
      type: String,
      default: "label",
    },
    width: {
      type: Number,
      default: 400,
    },
    height: {
      type: Number,
      default: 160,
    },
    cssClasses: {
      default: "",
      type: String,
    },
    styles: {
      type: Object,
      default: () => {},
    },
    plugins: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      chartData: {
        labels: [],
        datasets: [
          {
            backgroundColor: [
              "#42A5F5",
              "#90A4AE",
              "#66BB6A",
              "#C2185B",
              "#6A1B9A",
            ],
            data: [],
          },
        ],
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: "bottom",
          },
          title: {
            display: false,
            text: "Ejemplo de Chart Doughnut",
          },
          labels: {
            font: {
              family: "Titillium",
              size: 14,
            },
          },
        },
      },
    };
  },

  mounted() {
    this.getDataReclu();
  },

  methods: {
    getDataReclu() {
      const headers = {
        Authorization: localStorage.getItem("token"),
      };

      return new Promise((res) => {
        axios
          .get(`${this.$store.getters.getHostNode}/api/get-data-reclu-grafica-dona`, {
            headers: headers,
          })
          .then((response) => {
            res(response.data.abiertas);
            if (response.data.status != "EXPSESSION") {
              response.data.abiertas.map((item) => {
                if (item.descr == "Aut DirectorArea") {
                  this.chartData.labels.push("Aut. Dir. área");
                } else if (item.descr == "Aut JefeDirecto") {
                  this.chartData.labels.push("Aut. Jefe");
                } else {
                  this.chartData.labels.push(item.descr);
                }

                this.chartData.datasets[0].data.push(item.total);
              });
            } else {
              deleteToken();
            }
          })
          .finally(() => {
            console.log("finally function reporte");
          });
      });
    },
    // getDataReclu() {
    //   let params = {
    //     Opcion: 3,
    //     token: localStorage.getItem("token"),
    //   };
    //   axios
    //     .post(
    //       `${this.$store.getters.getHost}/Reclutamiento/ws-admin-reclu.php`,
    //       params
    //     )
    //     .then((response) => {
    //       if (response.data.status != "EXPSESSION") {
    //         response.data.abiertas.map((item) => {
    //           if (item.descr == "Aut DirectorArea") {
    //             this.chartData.labels.push("Aut. Dir. área");
    //           } else if (item.descr == "Aut JefeDirecto") {
    //             this.chartData.labels.push("Aut. Jefe");
    //           } else {
    //             this.chartData.labels.push(item.descr);
    //           }

    //           this.chartData.datasets[0].data.push(item.total);
    //         });
    //       } else {
    //         deleteToken();
    //       }
    //     });
    // },
  },
};
</script>
