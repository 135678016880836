<template>
  <v-sheet style="display:flex; justify-content: end;">
    <vue-json-to-csv
      :json-data="checadasBiometricos"
      :labels="headers_report_to_export_biometricos"
      csv-title="ReporteChecadasBiometricos"
    >
      <v-btn
        class="ml-2 rounded-lg elevation-1"
        color="green darken-4"
        large
        dark
        :loading="loadingChecadasBiometricos"
      >
        <v-icon left>mdi-microsoft-excel</v-icon>
        Exportar checadas biométricos
      </v-btn>
    </vue-json-to-csv>
    <vue-json-to-csv
      :json-data="checadasTarjetas"
      :labels="headers_report_to_export_tarjetas"
      csv-title="ReporteChecadasTarjetas"
    >
      <v-btn
        class="ml-2 rounded-lg elevation-1"
        color="green darken-4"
        large
        dark
        :loading="loadingChecadasTarjetas"
      >
        <v-icon left>mdi-microsoft-excel</v-icon>
        Exportar checadas tarjetas
      </v-btn>
    </vue-json-to-csv>
  </v-sheet>
</template>

<script>
import VueJsonToCsv from "vue-json-to-csv";
import axios from "axios";
import { deleteToken } from "../../../../../../store/Funciones/funciones";

export default {
  name: "exportar-reportes-checadas",
  props: ["inicio", "fin"],
  components: { VueJsonToCsv },
  data() {
    return {
      loadingChecadasBiometricos: true,
      loadingChecadasTarjetas: true,
      checadasBiometricos: [],
      checadasTarjetas: [],
      headers_report_to_export_tarjetas: {
        tidentification: {title: 'Emplid'},
        EMP_NOMEMP: {title: 'Nombre'},
        IdReader: {title: 'Id lector'},
        tDesc: {title: 'Nombre lector'},
        dtEventReal: {title: 'Fecha'},
        estatusEmpleado: {title: 'Estatus empleado'},
      },
      headers_report_to_export_biometricos: {
        idEmpleado: {title: 'Emplid'},
        EMP_NOMEMP: {title: 'Nombre'},
        numeroSerie: {title: 'Id lector'},
        nombreDispositivo: {title: 'Nombre lector'},
        fechaHora: {title: 'Fecha'},
        estatusEmpleado: {title: 'Estatus empleado'},
      },
    };
  },
  async mounted(){
    this.checadasTarjetas = await this.getChecadasTarjetas();
    this.checadasBiometricos = await this.getChecadasBiometricos();
  },
  methods: {
    async getReport(opc) {
      switch (opc) {
        case 1: {
          this.loadingChecadasTarjetas = true;
          this.checadasTarjetas.length = 0;
          this.checadasTarjetas = await this.getChecadasTarjetas();
          break;
        }
      }
    },

    getChecadasTarjetas() {
      const headers = {
        Authorization: localStorage.getItem("token"),
      };

      return new Promise((res, rej) => {
        axios
          .get(
            `${this.$store.getters.getHostNode}/api/get-reporte-checadas-tarjetas/${this.inicio}/${this.fin}`,
            {
              headers,
            }
          )
          .then((response) => {
            if (response.data.status == "OK") {
              res(response.data.data);
            } else if (response.data.status == "EXPRESSION") {
              deleteToken();
            } else {
              alert(response.data.message);
              res([]);
            }
          })
          .catch((e) => {
            rej(e);
          })
          .finally(() => {
            this.loadingChecadasTarjetas = false;
          });
      });
    },

    getChecadasBiometricos() {
      const headers = {
        Authorization: localStorage.getItem("token"),
      };

      return new Promise((res, rej) => {
        axios
          .get(
            `${this.$store.getters.getHostNode}/api/get-reporte-checadas-biometricos/${this.inicio}/${this.fin}`,
            {
              headers,
            }
          )
          .then((response) => {
            if (response.data.status == "OK") {
              res(response.data.data);
            } else if (response.data.status == "EXPRESSION") {
              deleteToken();
            } else {
              alert(response.data.message);
              res([]);
            }
          })
          .catch((e) => {
            rej(e);
          })
          .finally(() => {
            this.loadingChecadasBiometricos = false;
          });
      });
    },
  },
};
</script>
