<template>
  <v-dialog
    v-model="muestraColaboradores"
    persistent
    max-width="700"
    content-class="fix-border-dialog"
  >
    <v-card class="rounded-xl">
      <v-card-title class="primary">
        <label class="overline font-weight-bold white--text"
          >COLABORADORES de la solicitud [{{ idSolicitud }}]</label
        >
        <v-spacer></v-spacer>
        <v-icon dark @click="$emit('ocultaColaboradores')">mdi-close</v-icon>
      </v-card-title>
      <v-card-text>
        <v-data-table
          dense
          :items="colaboradores"
          :headers="headers"
          :loading="loadingData"
          :search="search"
          :footer-props="{
            'items-per-page-text': 'Elementos por página',
            pageText: '{0}-{1} de {2}',
          }"
          no-data-text="No hay registros"
          loading-text="Cargando información..."
          :no-results-text="
            'No se encontró ningun resultado que coincida con ' + search
          "
          :header-props="headerProps"
          :mobile-breakpoint="0"
        >
          <template v-slot:item.total="{ item }">
            <label class="font-weight-bold font-italic">{{
              formatMXN(item.total)
            }}</label>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
import { deleteToken } from "../../../../../../store/Funciones/funciones";

export default {
  name: "ver-colaboradores-por-solicitud",
  components: {},
  props: ["muestraColaboradores", "idSolicitud"],
  data() {
    return {
      headers: [
        {
          value: "id",
          text: "ID",
          class: "text-uppercase font-weight-bold rounded-tl-lg",
        },
        {
          value: "emplid",
          text: "emplid",
          class: "text-uppercase font-weight-bold",
        },
        {
          value: "EMP_NOMEMP",
          text: "nombre",
          class: "text-uppercase font-weight-bold",
        },
        {
          value: "total",
          text: "pago por descando laborado",
          align: "center",
          class: "text-uppercase font-weight-bold",
        },
      ],
      headerProps: {
        sortByText: "Ordenar por",
      },
      colaboradores: [],
      search: "",
      loadingData: true,
      muestraBarra: false,
      colorBarra: "",
      textoBarra: "",
    };
  },
  async mounted() {
    this.colaboradores = await this.getColaboradoresPorSolicitud();
  },
  methods: {
    showSnackBar(texto, color = "error") {
      this.textoBarra = texto;
      this.colorBarra = color;
      this.muestraBarra = true;
    },
    async actualizarTabla() {
      this.loadingData = true;
      this.colaboradores = await this.getColaboradoresPorSolicitud();
    },
    getColaboradoresPorSolicitud() {
      const headers = {
        Authorization: localStorage.getItem("token"),
      };

      return new Promise((res) => {
        axios
          .get(
            `${this.$store.getters.getHostNode}/api/descanso-laborado/colaboradores-por-solicitud/${this.idSolicitud}`,
            {
              headers,
            }
          )
          .then((response) => {
            if (response.data.status == "EXPSESSION") {
              deleteToken();
            } else {
              res(response.data.data);
            }
          })
          .finally(() => {
            this.loadingData = false;
          });
      });
    },
    formatMXN(cadena) {
      return cadena.toLocaleString("es-MX", {
        style: "currency",
        currency: "MXN",
      });
    },
  },
};
</script>
