var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"persistent":"","scrollable":"","max-width":"900","content-class":"fix-border-dialog"},model:{value:(_vm.verCandidatos),callback:function ($$v) {_vm.verCandidatos=$$v},expression:"verCandidatos"}},[_c('v-card',{staticClass:"rounded-xl back-card-candidatos"},[_c('v-card-title',{staticClass:"primary white--text subtitle-1"},[_vm._v(" Candidatos postulados "),_c('v-spacer'),_c('v-icon',{attrs:{"dark":""},on:{"click":function($event){return _vm.$emit('cerrarVerCandidatos')}}},[_vm._v(" mdi-close ")])],1),_c('v-card-text',{staticClass:"pa-1"},[(_vm.loadingContent)?_c('div',{staticClass:"center-item-in-div-column"},[_c('div',{staticClass:"text-subtitle-1 text-center"},[_vm._v(" Cargando candidatos... ")]),_c('div',{staticStyle:{"max-width":"300px !important"}},[_c('v-progress-linear',{attrs:{"color":"primary","indeterminate":"","rounded":"","height":"6"}})],1)]):_c('div',[_c('v-data-table',{staticClass:"elevation-0 back-glass rounded-xl",attrs:{"headers":_vm.headers,"items":_vm.candidatos,"dense":"","loading-text":"Cargando contentenido...","footer-props":{
              'items-per-page-options': [10, 20, 30, 100],
              'items-per-page-text': 'Elementos por página',
            },"no-data-text":"No hay registros","header-props":{
              'short-by-text': 'Ordenar por',
            }},scopedSlots:_vm._u([{key:"item.Candidato",fn:function(ref){
            var item = ref.item;
return [_c('v-btn',{staticClass:"text-uppercase caption font-weight-bold light-blue--text text--darken-1 text-decoration-underline",attrs:{"text":"","small":""},on:{"click":function($event){_vm.idCandidatoSeleccionado = item.id;
                  _vm.muestraDetallesCandidato = true;}}},[_vm._v(" "+_vm._s(item.Candidato)+" ")])]}},{key:"item.Descr",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getEstatus(item.Descr))+" ")]}},{key:"item.Postulacion",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.fixFormatDate(item.Postulacion.date.substring(0, 10)))+" ")]}},{key:"item.actions",fn:function(ref){
                  var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":"","color":"cyan darken-2"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [(item.Descr == 'Seleccion')?_c('v-btn',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"small":"","rounded":"","depressed":"","color":"cyan darken-2","dark":""},on:{"click":function($event){return _vm.openConfirm(item, 1)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-account-check")])],1):_vm._e()]}}],null,true)},[_c('div',{staticClass:"caption font-weight-bold"},[_vm._v("Seleccionar")])]),_c('v-tooltip',{attrs:{"top":"","color":"pink darken-3"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [(item.Descr != 'Oferta_Economica')?_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","rounded":"","depressed":"","color":"pink darken-3","dark":""},on:{"click":function($event){return _vm.openConfirm(item, 2)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-briefcase-clock-outline")])],1):_vm._e()]}}],null,true)},[_c('div',{staticClass:"caption font-weight-bold"},[_vm._v("Enviar a bolsa")])])]}},{key:"item.info",fn:function(ref){
                  var item = ref.item;
return [_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":"","color":"blue-grey lighten-1"}},'v-icon',attrs,false),on),[_vm._v("mdi-information-outline")])]}}],null,true)},[_c('div',[(item.Descr == 'Seleccion')?_c('div',[_c('strong',[_vm._v("\"Seleccionar\" ")]),_vm._v(" el candidato "),_c('br'),_vm._v(" seleccionado será enviado a oferta económica."),_c('br'),_c('br')]):_vm._e(),_c('div',[_c('strong',[_vm._v("\"Enviar a bolsa\" ")]),_vm._v(" el candidato "),_c('br'),_vm._v(" será enviado a bolsa de trabajo y no se tomará "),_c('br'),_vm._v(" en cuenta. "),_c('br')])])])]}}])})],1)])],1)],1),(_vm.showConfirm)?_c('ConfirmDialogReclutamiento',{attrs:{"showConfirm":_vm.showConfirm,"question":_vm.question},on:{"cierraConfirm":_vm.closeConfirm}}):_vm._e(),(_vm.muestraDetallesCandidato)?_c('DetallesCandidato',{attrs:{"muestraDetallesCandidato":_vm.muestraDetallesCandidato,"idCandidato":_vm.idCandidatoSeleccionado},on:{"cierraDetallesCandidato":function($event){_vm.muestraDetallesCandidato = false}}}):_vm._e(),(_vm.muestraBarra)?_c('SnackBar',{attrs:{"muestraBarra":_vm.muestraBarra,"color":_vm.colorBarra,"texto":_vm.textoBarra},on:{"cierraBarra":function($event){_vm.muestraBarra = false}}}):_vm._e(),_c('DialogComentarioRechazo',{attrs:{"commentReject":_vm.muestraDialogComentario,"isCandidate":true},on:{"ocultaDialogComentario":_vm.validateComment}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }