<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <v-card>
      <v-card-title class="text-subtitle-2 primary white--text">
        Confirmar planta administrativa
        <v-spacer></v-spacer>
        <v-icon
          small
          dark
          @click="
            $emit('cierraDialgoConfirmaCompensaciones', { result: 'Cancelar' })
          "
          >mdi-close</v-icon
        >
      </v-card-title>
      <v-card-text class="mt-5">
        <v-row>
          <v-col
            class="d-flex align-center pb-0"
            cols="12"
            xl="12"
            lg="12"
            md="12"
            sm="12"
            xs="12"
          >
            <v-card class="elevation-2 rounded-xl mb-2" width="100%">
              <v-list-item>
                <v-list-item-avatar>
                  <v-icon class="grey lighten-2" :color="'teal darken-4'" dark
                    >mdi-account-star-outline</v-icon
                  >
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title>{{ form.title }}</v-list-item-title>

                  <v-list-item-subtitle>
                    <b> {{ form.type }} </b>
                    - {{ form.subtitle }} {{ formTitle }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </v-col>
          <p class="mx-auto mb-0 font-italic text--disabled">
            Se le notificará al colaborador con este aviso desde su perfil, en
            el portal DH
          </p>
          <v-col
            class="d-flex align-center"
            cols="12"
            xl="12"
            lg="12"
            md="12"
            sm="12"
            xs="12"
          >
            <v-menu
              v-model="menu2"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="form.nomina"
                  label="Inicio de asignación de planta"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="form.nomina"
                @input="menu2 = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn v-if="form.nomina.length < 7" small disabled>
          <v-icon left>mdi-check-circle-outline</v-icon> Confirmar
        </v-btn>
        <v-btn
          v-else
          class="rounded-lg elevation-0"
          color="teal darken-1"
          dark
          small
          :loading="loading"
          @click="confirmarPlanta"
        >
          <v-icon left>mdi-check-circle-outline</v-icon> Confirmar
        </v-btn>
      </v-card-actions>
    </v-card>
    <SnackBar
      v-if="muestraBarra"
      :muestraBarra="muestraBarra"
      :texto="textoBarra"
      :color="colorBarra"
      @cierraBarra="muestraBarra = false"
    />
  </v-dialog>
</template>



<script>
import axios from "axios";
import SnackBar from "../../../MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue";
import { deleteToken } from "../../../../../../store/Funciones/funciones";
export default {
  name: "confirma-compensaciones",
  components: { SnackBar },
  props: ["dialog", "evaluado"],
  data: () => ({
    textoBarra: "",
    colorBarra: "",
    muestraBarra: false,

    loading: false,
    form: {
      title: "¡Felicitaciones!",
      type: "Planta administrativa adquirida",
      subtitle: "Se reflejará en la nomina ",
      nomina: "",
    },
    menu2: false,
  }),
  created() {},
  mounted() {
    this.getDate();
    console.log(this.form);
    console.log(this.evaluado);
  },
  computed: {
    formTitle() {
      return this.form.nomina === "" ? "AAAA-MM-DD" : this.form.nomina;
    },
  },

  methods: {
    getDate() {
      var fechaHoy = new Date();

      // Formatear la fecha como AAAA-MM-DD
      var formatoAnio = fechaHoy.getFullYear();
      var formatoMes = ("0" + (fechaHoy.getMonth() + 1)).slice(-2); // Sumar 1 porque los meses comienzan desde 0
      var formatoDia = ("0" + fechaHoy.getDate()).slice(-2);

      var fechaFormateada = formatoAnio + "-" + formatoMes + "-" + formatoDia;
      //this.form.nomina = fechaFormateada;

      console.log(fechaFormateada);
    },
    confirmarPlanta() {
      let params = {
        Opcion: 16,
        token: localStorage.getItem("token"),
        form: this.form,
        item: this.evaluado,
      };
      console.log(params);
      this.loading = true;
      axios
        .post(
          `${this.$store.getters.getHost}/General/PersonalTemporal/ws-personal-temporal.php`,
          params
        )
        .then((response) => {
          this.loading = false;
          if (response.data.status == "OK") {
            this.textoBarra = "Gracias por confirmar.";
            this.colorBarra = "success";
            this.muestraBarra = true;
            this.form.nomina = "";
            return new Promise((resolve) => {
              setTimeout(() => {
                resolve(
                  this.$emit("cierraDialgoConfirmaCompensaciones", {
                    result: "OK",
                  })
                );
              }, 2000);
            });
          } else if (response.data.status == "ERROR") {
            this.textoBarra = "Se ha producido un error al registrar .";
            this.colorBarra = "error";
            this.muestraBarra = true;
          } else if (response.data.status == "EXPRESSION") {
            deleteToken();
          }
        });
    },
  },
};
</script>