<template>
    <v-dialog v-model="verDetalleEmpleado" persistent content-class="fix-border-dialog">
        <v-card class="rounded-xl">
            <v-card-title class="primary white--text overline font-weight-bold">
                detalles de {{ emplid }} del día {{ dia }}
                <v-spacer></v-spacer>
                <v-icon @click="$emit('cierraDetalles')" dark>mdi-close</v-icon>
            </v-card-title>
            <v-card-text class="pt-5">
                <v-row>
                    <v-col :cols="smallScreen ? 12 : 6">
                        <v-skeleton-loader v-if="loadingData" width="100%" height="100%" type="image" class="rounded-lg"
                        ></v-skeleton-loader>
                        <v-card v-else color="blue-grey lighten-5" class="rounded-lg elevation-0 px-5 pb-5 pt-1" width="100%" height="100%">
                            <div class="text-center overline font-weight-bold blue-grey--text text--darken-3">
                                biométricos
                            </div>
                            <div v-if="detallesChecadas.checadasBiometricos.datos.length === 0" class="text-center overline blue-grey--text text--darken-2">
                                Sin información registrada
                            </div>
                            <v-simple-table v-else dense style="background-color: transparent;">
                                <thead>
                                    <tr>
                                        <th class="text-center">Dispositivo</th>
                                        <th class="text-center">Hora</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item, index) in detallesChecadas.checadasBiometricos.datos" :key="index">
                                        <td class="text-center">{{ item.nombreDispositivo }}</td>
                                        <td class="text-center">{{ fixDate(item.fechaHora.substring(0, 10)) + ' ' + item.fechaHora.substring(11, 19) }}</td>
                                    </tr>
                                </tbody>
                            </v-simple-table>
                        </v-card>
                    </v-col>
                    <v-col :cols="smallScreen ? 12 : 6">
                        <v-skeleton-loader v-if="loadingData" width="100%" height="100%" type="image" class="rounded-lg"
                        ></v-skeleton-loader>
                        <v-card v-else color="blue-grey lighten-5" class="rounded-lg elevation-0 px-5 pb-5 pt-1" width="100%" height="100%">
                            <div class="text-center overline font-weight-bold blue-grey--text text--darken-3">
                                lectores de tarjetas
                            </div>
                            <div v-if="detallesChecadas.checadasLectores.datos.length === 0" class="text-center overline blue-grey--text text--darken-2">
                                Sin información registrada
                            </div>
                            <v-simple-table v-else dense style="background-color: transparent;">
                                <thead>
                                    <tr>
                                        <th class="text-center">Dispositivo</th>
                                        <th class="text-center">Hora</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item, index) in detallesChecadas.checadasLectores.datos" :key="index">
                                        <td class="text-center">{{ item.tDesc }}</td>
                                        <td class="text-center">{{ fixDate(item.dtEventReal.substring(0, 10)) + ' ' + item.dtEventReal.substring(11, 19) }}</td>
                                    </tr>
                                </tbody>
                            </v-simple-table>
                        </v-card>
                    </v-col>
                    <v-col :cols="12">
                        <v-skeleton-loader v-if="loadingData" width="100%" height="100%" type="image" class="rounded-lg"
                        ></v-skeleton-loader>
                        <v-card v-else color="blue-grey lighten-5" class="rounded-lg elevation-0 pa-5" width="100%" height="100%">
                            <div class="text-center overline font-weight-bold blue-grey--text text--darken-3">
                                incidencias
                            </div>
                            <div v-if="detallesChecadas.incidencias.datos.length === 0" class="text-center overline blue-grey--text text--darken-2">
                                Sin información registrada
                            </div>
                            <v-simple-table v-else dense style="background-color: transparent;">
                                <thead>
                                    <tr>
                                        <th class="text-center">Incidencia</th>
                                        <th class="text-center">Estatus</th>
                                        <th class="text-center">Fecha inicial</th>
                                        <th class="text-center">Fecha final</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item, index) in detallesChecadas.incidencias.datos" :key="index">
                                        <td class="text-center">{{ item.IncDescripcion }}</td>
                                        <td class="text-center">{{ item.EstDescripcion }}</td>
                                        <td class="text-center">{{ fixDate(item.FechaInicialDes.substring(0, 10)) + ' ' + item.FechaInicialDes.substring(11, 19) }}</td>
                                        <td class="text-center">{{ fixDate(item.FechaFinalDes.substring(0, 10)) + ' ' + item.FechaFinalDes.substring(11, 19) }}</td>
                                    </tr>
                                </tbody>
                            </v-simple-table>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import axios from 'axios'
import { deleteToken,formatDate } from '../../../../../../store/Funciones/funciones';
export default {
    name: 'detalles-checadas-por-empleado',
    props: ["verDetalleEmpleado", "dia", "emplid"],
    data() {
        return {
            detallesChecadas: {},
            loadingData: true
        }
    },
    computed: {
        smallScreen(){
            return ['xs', 'sm'].includes(this.$vuetify.breakpoint.name) ? true : false
        }
    },
    async mounted(){
        this.detallesChecadas = await this.getDetalleChecadas()
    },
    methods: {
        getDetalleChecadas() {
            const headers = {
                Authorization: localStorage.getItem("token"),
            };

            return new Promise((res, rej) => {
                axios.get(`${this.$store.getters.getHostNode}/api/get-detalles-checadas-empleado/${this.dia}/${this.emplid}`, {
                        headers
                    })
                    .then((response) => {
                        if (response.data.status == "OK") {
                            res(response.data.data);
                        } else if (response.data.status == "EXPRESSION") {
                            deleteToken();
                        } else {
                            alert(response.data.message)
                            res([]);
                        }
                    })
                    .catch((e) => {
                        rej(e)
                    })
                    .finally(() => {
                        this.loadingData = false;
                    })
            })

        },
        fixDate(date){
            return formatDate(date)
        }
    }
}

</script>