var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","xl":"10","lg":"10","md":"10","sm":"12","xs":"12"}},[_c('v-text-field',{staticClass:"mb-5",attrs:{"append-icon":"mdi-magnify","label":"Busca por nombre, número de empleado o actualización de datos","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{staticClass:"d-flex justify-center align-center",attrs:{"cols":"12","xl":"2","lg":"2","md":"2","sm":"12","xs":"12"}},[_c('v-menu',{attrs:{"bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-1 font-weight-bold rounded-lg",attrs:{"color":"green darken-2","dark":"","small":"","loading":_vm.loadingExcel}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v("mdi-microsoft-excel")]),_vm._v(" "+_vm._s(_vm.$t("DH360.export"))+" ")],1)]}}])},[_c('v-list',[(_vm.infoReporte)?_c('vue-json-to-csv',{attrs:{"json-data":_vm.infoReporte,"labels":_vm.headers_report_to_export,"csv-title":_vm.nameFile}},[_c('v-list-item',{on:{"click":_vm.fixName}},[_c('v-list-item-title',[_vm._v("Información colaborador")])],1)],1):_vm._e(),(_vm.infoReporteFamilias)?_c('vue-json-to-csv',{attrs:{"json-data":_vm.infoReporteFamilias,"labels":_vm.headers_report_to_export_Families,"csv-title":_vm.nameFile2}},[_c('v-list-item',{on:{"click":_vm.fixName2}},[_c('v-list-item-title',[_vm._v("Familias de colaboradores")])],1)],1):_vm._e()],1)],1)],1)],1),_c('v-spacer'),_c('v-data-table',{attrs:{"dense":"","loading":_vm.loadingContent,"headers":_vm.headers,"items":_vm.colaboradores.datos,"sort-by-text":"Ordenar por","no-data-text":"No hay registros.","items-per-page-all-text":"Todos","items-per-page-text":"Elementos por página","show-current-page":false,"show-first-last-page":false,"search":_vm.search,"loading-text":"Cargando contenido...","footer-props":{
      'items-per-page-options': [5, 10, 15, 20, 25],
      'items-per-page-text': 'Elementos por página',
    }},scopedSlots:_vm._u([{key:"item.datosActualizados",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticStyle:{"display":"grid","place-content":"center"}},[_c('v-chip-group',{attrs:{"column":""}},[(item.datosActualizados == 'Pendiente')?_c('v-btn',{staticClass:"mb-2",attrs:{"x-small":"","rounded":"","elevation":"1","color":"red darken-1","dark":""}},[_vm._v(" "+_vm._s(item.datosActualizados)+" ")]):(item.datosActualizados == 'Actualizado')?_c('v-btn',{staticClass:"mb-2",attrs:{"x-small":"","rounded":"","elevation":"1","color":"teal darken-1","dark":""}},[_vm._v(" "+_vm._s(item.datosActualizados)+" ")]):_c('v-btn',{staticClass:"mb-2",attrs:{"x-small":"","rounded":"","elevation":"1","color":"yellow darken-1"}},[_vm._v(" "+_vm._s(item.datosActualizados)+" ")])],1)],1)]}},{key:"item.totalDocs",fn:function(ref){
    var item = ref.item;
return [(item.numDocs)?_c('div',[_vm._v(_vm._s(item.totalDocs)+" / "+_vm._s(item.numDocs))]):_c('div',[_vm._v(_vm._s(item.totalDocs)+" / "+_vm._s(_vm.colaboradores.maxDocs))])]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":"","color":"cyan lighten-4"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"cyan darken-4"},on:{"click":function($event){return _vm.openAdmFiles(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-text-box-multiple-outline ")])]}}],null,true)},[_c('label',{staticClass:"text-uppercase font-weight-bold primary--text"},[_vm._v("Cargar documentos")])])]}}],null,true)}),(_vm.muestraCargaArchivos)?_c('CargaArchivos',{attrs:{"dialogCargaArchivos":_vm.muestraCargaArchivos,"emplid":_vm.emplid,"estatusColaborador":1,"esAdmin":"1"},on:{"cierraVentana":function($event){return _vm.reloadInformation()}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }