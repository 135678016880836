<template>
  <v-card class="rounded-xl elevation-0 pa-3">
    <v-data-table
      dense
      :headers="headers"
      :search="search"
      :items="beneficios"
      :items-per-page="15"
      no-data-text="No hay registros para este estatus"
      loading-text="Cargando contenido..."
      :loading="loadingBenefits"
      show-expand
      :expanded.sync="expanded"
    >
      <template v-slot:top>
        <div style="display: flex; justify-content: space-between;">
          <v-text-field
            v-model="search"
            outlined
            dense
            class="rounded-xl"
            style="max-width: 350px;"
            prepend-inner-icon="mdi-table-search"
            placeholder="Buscar en tabla..."
          ></v-text-field>
          <div>
            <v-btn
              depressed
              class="rounded-pill ml-2"
              color="teal"
              dark
              @click="openAgregaBeneficio(null)"
            >
              <v-icon left> mdi-plus </v-icon>
              Añadir beneficio
            </v-btn>
            <v-tooltip bottom color="blue lighten-4">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  small
                  fab
                  depressed
                  class="ml-2"
                  v-bind="attrs"
                  v-on="on"
                  @click="colseAgregaBeneficio(1)"
                >
                  <v-icon>mdi-reload</v-icon>
                </v-btn>
              </template>
              <label class="text-uppercase font-weight-bold primary--text"
                >Actualizar tabla</label
              >
            </v-tooltip>
          </div>
        </div>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length" class="px-5 py-5 blue-grey lighten-5">
          <div class="center-item-in-div">
            <v-card
              class="px-4 mx-2 py-1 elevation-0 rounded-xl"
              width="300"
              style="border: 2px solid #000000; "
            >
              <label class="overline"> Descripción </label>
              <p>{{ item.descBeneficio }}</p>
            </v-card>
            <v-card
              class="px-4 mx-2 py-1 elevation-0 rounded-xl"
              width="300"
              style="border: 2px solid #000000; "
            >
              <label class="overline"> Grupos de pago participantes </label>
              <p>{{ item.beneficiados }}</p>
            </v-card>
          </div>
        </td>
      </template>
      <template v-slot:item.montoAyuda="{ item }">
        {{
          parseFloat(item.montoAyuda) == 0
            ? ""
            : formatMXN(parseFloat(item.montoAyuda))
        }}
      </template>
      <template v-slot:item.manual="{ item }">
        <v-btn
          v-show="item.tiene_manual !== 0"
          text
          small
          color="cyan darken-1"
          :href= "$store.getters.getHost + '/ArchivosCargadosEnPortal2/Compensaciones/BeneficiosUrrea/' + item.manual"
          target="_blank"
        >
          <v-icon left> mdi-file-eye-outline </v-icon>
          <div class="text-decoration-underline font-weight-bold">
            {{ item.manual }}
          </div>
        </v-btn>
      </template>
      <template v-slot:item.estatus="{ item }">
        {{ item.estatus == 1 ? "Activo" : "Inactivo" }}
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon color="cyan darken-2" small @click="openAgregaBeneficio(item)">
          mdi-pencil
        </v-icon>
        <v-icon color="pink darken-2" small @click="hideBenefit(item.id, item.estatus)">
          {{ item.estatus == 1 ? 'mdi-eye-off' : 'mdi-eye'}}
        </v-icon>
      </template>
    </v-data-table>

    <AgregaBeneficio
      v-if="muestraAgregaBenficio"
      :muestraAgregaBenficio="muestraAgregaBenficio"
      :beneficio="beneficioAux"
      @ocultaAgregaBeneficio="colseAgregaBeneficio"
    />

    <SnackBar
      v-if="muestraBarra"
      :muestraBarra="muestraBarra"
      :color="colorBarra"
      :texto="textoBarra"
      @cierraBarra="muestraBarra = false"
    />
  </v-card>
</template>

<script>
import axios from "axios";
import AgregaBeneficio from "./AgregaBeneficio";
import SnackBar from "../../../General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue";

export default {
  name: "muestra-beneficios",
  components: { AgregaBeneficio, SnackBar },
  data() {
    return {
      headers: [
        {
          text: "ID",
          value: "id",
          class:
            "text-uppercase font-weight-bold blue-grey--text text--darken-2",
        },
        {
          text: "Beneficio",
          value: "beneficio",
          class:
            "text-uppercase font-weight-bold blue-grey--text text--darken-2",
        },
        {
          text: "Monto ayuda",
          value: "montoAyuda",
          class:
            "text-uppercase font-weight-bold blue-grey--text text--darken-2",
        },
        {
          text: "Manual",
          value: "manual",
          sortable: false,
          class:
            "text-uppercase font-weight-bold blue-grey--text text--darken-2",
        },
        {
          text: "Estatus",
          value: "estatus",
          sortable: false,
          class:
            "text-uppercase font-weight-bold blue-grey--text text--darken-2",
        },
        {
          text: "Acciones",
          value: "actions",
          sortable: false,
          align: "center",
          class:
            "text-uppercase font-weight-bold blue-grey--text text--darken-2",
        },
      ],
      beneficios: [],
      expanded: [],
      beneficioAux: {},
      loadingBenefits: true,
      muestraAgregaBenficio: false,
      muestraBarra: false,
      textoBarra: "",
      colorBarra: "",
      search: "",
    };
  },
  async mounted() {
    this.beneficios = await this.getBenefits();
  },
  methods: {
    muestraSnackBar(color, texto) {
      this.colorBarra = color;
      this.textoBarra = texto;
      this.muestraBarra = true;
    },
    async getBenefits() {
      let params = {
        Opcion: 5,
        token: localStorage.getItem("token"),
      };
      return new Promise((resolve, reject) => {
        // this.beneficios.length = 0
        axios
          .post(
            `${this.$store.getters.getHost}/Compensaciones/ws-beneficios.php`,
            params
          )
          .then((response) => {
            if (response.data.status == "OK") {
              resolve(response.data.beneficios);
            }
          })
          .catch((error) => {
            reject({
              error: error,
            });
          })
          .finally(() => {
            this.loadingBenefits = false;
          });
      });
    },
    openAgregaBeneficio(beneficio) {
      this.beneficioAux = beneficio;
      this.muestraAgregaBenficio = true;
    },
    async colseAgregaBeneficio(type) {
      if (type == 1) {
        this.loadingBenefits = true;
        this.beneficios.length = 0;
        this.beneficios = await this.getBenefits();
      }
      this.muestraAgregaBenficio = false;
    },
    formatMXN(cadena) {
      return cadena.toLocaleString("es-MX", {
        style: "currency",
        currency: "MXN",
      });
    },
    hideBenefit(id, estatus) {
      let params = {
        Opcion: 8,
        token: localStorage.getItem("token"),
        idBeneficio: id,
        estatus: estatus
      };
      axios
        .post(
          `${this.$store.getters.getHost}/Compensaciones/ws-beneficios.php`,
          params
        )
        .then((response) => {
          if (response.data.status == "OK") {
            this.muestraSnackBar("teal darken-1", "Modificado con éxito.");
            this.colseAgregaBeneficio(1)
          }
        })
        .catch((error) => {
          this.muestraSnackBar("pink darken-1", error);
        })
    },
  },
};
</script>
