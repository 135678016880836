<template>
  <v-dialog v-model="dialog" max-width="600px" persistent>
    <v-card>
      <v-card-title class="text-subtitle-2 primary white--text">
        Eliminar cuadro base
        <v-spacer></v-spacer>
        <v-icon small dark @click="$emit('cierraDialogDelete', 'Cancelar')"
          >mdi mdi-close
        </v-icon>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col
              class="pb-0"
              cols="12"
              xl="12"
              lg="12"
              md="12"
              sm="12"
              xs="12"
            >
              <!-- {{item}} -->
              <b class="red--text">Aviso:</b> al confirmar acción, los puestos
              asignados tambien serán removidos <br />
              <br />
              <b>Departamento:</b> {{ item.DESCR }} <br />
              <b>Turno:</b> {{ item.Descripcion }} <br />
              <b>Planta:</b> {{ item.plantaNombre }} <br />
              <b>Número de puestos:</b>
              <label v-if="item.puesto">{{ item.puesto.length }}</label>
              <label v-else>N/A</label> <br />
              <br />
              ¿Esta seguro de continuar? <br />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          class="rounded-lg elevation-0"
          color="teal darken-1"
          dark
          small
          :loading="loading"
          @click="borrar"
        >
          <v-icon left>mdi-check-circle-outline</v-icon> Confirmar
        </v-btn>
      </v-card-actions>
    </v-card>
    <SnackBarErrorIncidencias
      v-if="muestraBarra"
      :muestraBarra="muestraBarra"
      :texto="textoBarra"
      :color="colorBarra"
      @cierraBarra="muestraBarra = false"
    />
  </v-dialog>
</template>

<script>
import axios from "axios";
import SnackBarErrorIncidencias from "../../../General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue";
export default {
  name: "delete-cuadro-base",
  components: { SnackBarErrorIncidencias },
  props: ["dialog", "item"],
  data: () => ({
    textoBarra: "",
    colorBarra: "",
    muestraBarra: false,

    loading: false,
  }),
  created() {},
  computed: {},
  mounted() {},

  methods: {
    borrar() {
      const headers = {
        Authorization: localStorage.getItem("token"),
      };
      const params = {
        correo: localStorage.getItem("correo"),
        item: this.item,
        action: "borrar",
      };
      return new Promise((res) => {
        axios
          .post(
            `${this.$store.getters.getHostNode}/api/delete-cuadro-base`,
            params,
            {
              headers: headers,
            }
          )
          .then((response) => {
            this.loading = false;
            if (response.data.status == true) {
              res(response.data);
              this.textoBarra = response.data.message;
              this.colorBarra = "success";
              this.muestraBarra = true;
              return new Promise((resolve) => {
                setTimeout(() => {
                  resolve(this.$emit("cierraDialogDelete", "OK"));
                }, 1500);
              });
            } else if (response.data.status == false) {
              res(response.data);
              this.textoBarra = response.data.message;
              this.colorBarra = "error";
              this.muestraBarra = true;
            } else if (response.data.status == "EXPRESSION") {
              // deleteToken();
              console.log("deleteToken");
            }
          });
      });
    },
  },
};
</script>