import catalogos from './Componentes/tabsController.vue'

export default {
  name: 'catalogo-sueldos',
  components: { catalogos },
  props: [],
  data() {
    return {
    }
  },
  computed: {

  },
  mounted() {

  },
  methods: {

  }
}


