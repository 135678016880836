import axios from 'axios'
import {
    deleteToken
} from '../../../../../store/Funciones/funciones'
import CargaArchivos from './components/MuestraArchivosPorCargar.vue'
import formularioInfoPersonal from './../MiDocumentacion/components/formularioInfoPersonal.vue'
import CargaDocumentosPersonales from "./components/CargaDocumentosPersonales.vue";


export default {
    name: 'mi-documentacion',
    components: {
        CargaArchivos,
        formularioInfoPersonal,
        CargaDocumentosPersonales
    },
    props: [],
    data() {
        return {
            
            loadingInfo: true,
            registroCandidato: false,
            compania: '',
            bloqueo: false,
            dialogCargaDocumento: false,
            openAlertaDocumentos: false,
            mensaje: '',
            mensajeBtn: 'Actualizar',
            // datosInfo: [{FormCompletado:0,Completado:0,Pendiente:8}],
            datosInfo: '',
        }
    },

    mounted() {
        this.fakePromise();
    },
    methods: {

        async getCompania() {
            console.log(this.$store.getters.getUser["compania"]);
            if (["00100", "CANDIDATO"].includes(this.$store.getters.getUser["compania"].toString())) {
                this.getRegistroCandidato();
            } else {
                this.bloqueo = true;
            }
        },
        getRegistroCandidato() {
            let params = {
                Opcion: 10,
                token: localStorage.getItem("token"),
            };
            console.log(this.$store.getters.getUser["compania"]);
            axios
                .post(
                    `${this.$store.getters.getHost}/RelacionesLaborales/DocumentacionEmpleados/ws-carga-documentos.php`,
                    params
                )
                .then((response) => {
                    this.loadingInfo = false;
                    if (response.data.status == "VACIO") {
                        console.log("vacio");
                        this.openAlertaDocumentos = true;
                    } else if (response.data.status == "OK") {
                        this.registroCandidato = true;
                        this.dialogCargaDocumento = true;
                    } else if (response.data.status == "EXPRESSION") {
                        deleteToken();
                    }
                })
        },

        fakePromise() {
            return new Promise((resolve) => {
                setTimeout(() => {
                    resolve(
                        this.getCompania(),
                    );
                }, 2000);
            });
        },
    }
}