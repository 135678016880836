import { render, staticRenderFns } from "./EmpleadosActivos.html?vue&type=template&id=2ec06da3&scoped=true&"
import script from "./EmpleadosActivos.js?vue&type=script&lang=js&"
export * from "./EmpleadosActivos.js?vue&type=script&lang=js&"
import style0 from "./EmpleadosActivos.scss?vue&type=style&index=0&id=2ec06da3&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ec06da3",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
installComponents(component, {VAutocomplete,VCard,VCardText,VCardTitle,VCol,VContainer,VRow,VSheet})
