<template>
    <div>



        <v-data-table :headers="proveedorHeaders" :items="proveedores" :expanded.sync="expanded" item-key="No_proveedor"
            :search="search" :footer-props="{
                'items-per-page-text': 'Elementos por página',
                'page-text': '{0}-{1} de {2}',
            }" no-data-text="No hay registros" :no-results-text="'No se encontraron coincidencias para: ' + search"
            show-expand class="elevation-1">
            <template v-slot:top>
                <div style="display: flex; justify-content: space-between; align-items: center;"
                    class="primary pt-4 rounded-l-t-header-pases-admin rounded-r-t-header-pases-admin rounded-lg rounded-b-0">

                    <v-text-field v-model="search" label="Buscar..." dense flat solo-inverted dark clearable
                        append-icon="mdi-magnify" style="max-height: 42px; max-width: 400px;"
                        class="mx-4 rounded-lg"></v-text-field>

                    <v-btn color="success" @click="abrirDialogo(true)" class="mr-4">Agregar</v-btn>
                </div>
            </template>

            <template v-slot:item.Password="{ item }">
                <v-btn rounded @click="abrirDialogo(false, item)" color="teal" elevation="2" small dark>Generar</v-btn>
            </template>

            <template v-slot:item.Estatus="{ item }">
                <v-chip :color="item.Estatus === 'Validado' ? 'green' : 'red'" dark>
                    {{ item.Estatus }}
                </v-chip>
            </template>

            <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length" class="pa-0">
                    <v-card class="pa-0 ma-0">
                        <v-data-table :headers="contratistaHeaders" :items="item.contratistas"
                            class="elevation-1 pa-0 ma-0" dense :footer-props="{
                                'items-per-page-text': 'Elementos por página',
                                'page-text': '{0}-{1} de {2}',
                            }" no-data-text="No hay registros"
                            :no-results-text="'No se encontraron coincidencias para: ' + search">
                            <template v-slot:item.estatus="{ item }">
                                <v-chip :color="item.estatus === 'Validado' ? 'green' : 'red'" dark>
                                    {{ item.estatus }}
                                </v-chip>
                            </template>
                        </v-data-table>
                    </v-card>
                </td>
            </template>
        </v-data-table>

        <v-progress-linear v-if="loading" indeterminate color="blue" class="mb-4"></v-progress-linear>

        <DialogComponent :showDialog.sync="showDialog" :providerData="selectedProvider" :isAgregar="isAgregar"
            :proveedores="proveedores" @showSnackbar="showSnackbar" @reloadProveedores="getProveedores()" />


        <div class="text-center ma-2">
            <v-snackbar v-model="snackbar" color="#0071ba">
                {{ text }}
                <template v-slot:action="{ attrs }">
                    <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
                        Cerrar
                    </v-btn>
                </template>
            </v-snackbar>
        </div>

    </div>
</template>

<script>
import axios from 'axios';
import DialogComponent from './DialogPassword.vue';

export default {
    components: {
        DialogComponent
    },
    mounted() {
        this.getProveedores();
        this.getColaboradores();
    },
    data() {
        return {
            snackbar: false,
            text: "",
            loading: false,
            search: '',
            expanded: [],
            showDialog: false,
            isAgregar: false,
            selectedProvider: {},
            proveedorHeaders: [
                { text: '', value: 'data-table-expand', class: "overline font-weight-bold primary white--text rounded-right-top-table-lg" },
                { text: 'NO.PROVEEDOR', value: 'No_proveedor', class: 'font-weight-bold primary white--text' },
                { text: 'NOMBRE', value: 'Nombre', class: 'font-weight-bold primary white--text' },
                { text: 'CORREO', value: 'Correo', class: 'font-weight-bold primary white--text' },
                { text: 'RFC', value: 'Rfc', class: 'font-weight-bold primary white--text' },
                { text: 'NICKNAME', value: 'Nickname', class: 'font-weight-bold primary white--text' },
                { text: 'PASSWORD', value: 'Password', class: 'font-weight-bold primary white--text' },
                { text: 'ESTATUS', value: 'Estatus', class: 'font-weight-bold primary white--text' }
            ],
            contratistaHeaders: [
                { text: 'NOMBRE', value: 'nombre', class: 'font-weight-bold primary white--text' },
                { text: 'TELEFONO', value: 'telefono', class: 'font-weight-bold primary white--text' },
                { text: 'FUNCIÓN', value: 'funcion', class: 'font-weight-bold primary white--text' },
                {
                    text: 'ESTATUS', value: 'estatus', class: 'font-weight-bold primary white--text',
                    formatter: (estatus) => estatus === 1 ? 'Validado' : 'Rechazado'
                }
            ],
            proveedores: [],
            colaboradoresPorProveedor: {}
        };
    },
    methods: {
        showSnackbar(message) {
            this.text = message;
            this.snackbar = true;
        },
        async getProveedores() {
            this.loading = true;
            try {
                const response = await axios.get(`${this.$store.getters.getHostNode}/api/getProveedores`, {
                    headers: {
                        Authorization: localStorage.getItem("token"),
                    },
                });
                this.proveedores = response.data.data.map(proveedor => ({
                    id: proveedor.id,
                    Nombre: proveedor.Nombre,
                    No_proveedor: proveedor.No_Proveedor,
                    Rfc: proveedor.Rfc,
                    Correo: proveedor.Correo,
                    Nickname: proveedor.Nickname,
                    Password: proveedor.Password,
                    Estatus: proveedor.Estatus,
                    contratistas: this.colaboradoresPorProveedor[proveedor.No_Proveedor] || []
                }));
            } catch (error) {
                console.error('Error al cargar proveedores:', error);
            } finally {
                this.loading = false;
            }
        },
        async getColaboradores() {
            this.loading = true;
            try {
                const response = await axios.get(`${this.$store.getters.getHostNode}/api/getColaboradoresTabla`, {
                    headers: {
                        Authorization: localStorage.getItem("token"),
                    },
                });

                const colaboradoresAgrupados = response.data.data.reduce((acc, colaborador) => {
                    const noProveedor = colaborador.No_proveedor;
                    if (!acc[noProveedor]) {
                        acc[noProveedor] = [];
                    }
                    acc[noProveedor].push({
                        nombre: colaborador.Nombre,
                        telefono: colaborador.Telefono,
                        funcion: colaborador.Funcion,
                        estatus: colaborador.Estatus === 1 ? 'Validado' : 'Rechazado'
                    });

                    return acc;
                }, {});

                this.colaboradoresPorProveedor = colaboradoresAgrupados;
                this.getProveedores();

            } catch (error) {
                console.error('Error al cargar colaboradores:', error);
            } finally {
                this.loading = false;
            }
        },
        abrirDialogo(isAgregar, item = {}) {
            this.isAgregar = isAgregar;
            this.selectedProvider = { ...item };
            this.showDialog = true;
        }

    }
};
</script>
