var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"rounded-lg elevation-0",attrs:{"height":"100%","width":"100%","color":"blue-grey lighten-5"}},[_c('v-card-title',{staticClass:"py-1 center-item-in-div primary"},[_c('label',{staticClass:"overline font-weight-bold white--text"},[_vm._v("empleado - responsable")])]),_c('v-data-table',{staticStyle:{"background-color":"transparent"},attrs:{"dense":"","items-per-page":13,"headers":_vm.headers,"search":_vm.search,"items":_vm.relacionEmpleadoResponsable,"loading":_vm.loadingData,"no-data-text":"No se han registrado relaciones.","footer-props":{
      'items-per-page-text': 'Elementos por página',
      pageText: '{0}-{1} de {2}',
    },"loading-text":"Cargando responsables..."},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"px-3 py-2",staticStyle:{"display":"flex","justify-content":"space-between"}},[_c('v-text-field',{staticClass:"rounded-lg",staticStyle:{"max-width":"300px"},attrs:{"dense":"","outlined":"","placeholder":"Buscar...","prepend-inner-icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('div',[_c('vue-json-to-csv',{attrs:{"json-data":_vm.report_to_export,"labels":_vm.headers_report_to_export,"csv-title":"reporteEmpleadoResponsable"}},[_c('v-btn',{staticClass:"rounded-lg",attrs:{"color":"green darken-3","dark":"","depressed":"","loading":_vm.loadingReport}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-microsoft-excel")]),_vm._v(" exportar ")],1)],1)],1)],1)]},proxy:true},{key:"item.EMP_STATUS",fn:function(ref){
    var item = ref.item;
return [_c('v-chip',{attrs:{"color":item.EMP_STATUS === 'A'
            ? 'teal darken-1'
            : item.EMP_STATUS === null
            ? 'grey'
            : 'pink darken-3',"small":"","dark":""}},[_vm._v(" "+_vm._s(item.EMP_STATUS === "A" ? "Activo" : item.EMP_STATUS === null ? "Desconocido" : "Inactivo")+" ")])]}},{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [_c('v-btn',{staticClass:"rounded-pill",attrs:{"small":"","depressed":"","color":"primary","dark":""},on:{"click":function($event){_vm.responsable = item.Responsable;
          _vm.verEmpleados = true;}}},[_vm._v(" ver personal a cargo ")])]}}])}),(_vm.verEmpleados)?_c('VerEmpleadosPorResponsable',{attrs:{"verEmpleados":_vm.verEmpleados,"idEmpleado":_vm.responsable},on:{"cierraVerSubordinados":_vm.updateData}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }