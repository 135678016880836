<template>
  <v-container>
    <v-dialog v-model="dialogForm" persistent>
      <v-card>
        <v-card-title class="text-subtitle-2 primary white--text">
          Certificaciones
          <v-spacer></v-spacer>
          <v-icon small dark @click="$emit('cerrarTicket', 0)"
            >mdi-close</v-icon
          >
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" xl="5" lg="5" md="5" sm="12" xs="12">
                <v-card class="rounded-lg elevation-1 mb-5">
                  <v-data-iterator
                    :items="cert"
                    item-key="id"
                    :search="search"
                    :items-per-page="cert.length"
                    mobile-breakpoint="0"
                    hide-default-footer
                  >
                    <template v-slot:header>
                      <v-toolbar dark color="teal darken-1" class="mb-1">
                        <v-card-title class="teal darken-1 white--text"
                          >Certificaciones</v-card-title
                        >
                        <v-spacer></v-spacer>
                      </v-toolbar>
                    </template>
                    <template v-slot:default="{ items }">
                      <v-card>
                        <div class="scroll-container-certificaciones">
                          <draggable
                            v-model="cert"
                            :group="group2"
                            class="list"
                          >
                            <v-list
                              v-for="item in items"
                              :key="item.id"
                              dense
                              class="rounded"
                              color="blue-grey lighten-5"
                            >
                              <v-list-item color="grey">
                                <v-list-item-content class="align-end">
                                  {{ item.id }} {{ item.nombre }}
                                </v-list-item-content>
                              </v-list-item>
                            </v-list>
                          </draggable>
                        </div>
                      </v-card>
                    </template>
                  </v-data-iterator>
                </v-card>
              </v-col>
              <v-col
                cols="12"
                xl="2"
                lg="2"
                md="2"
                sm="12"
                xs="12"
                class="d-flex justify-center align-center"
              >
                <v-icon>mdi mdi-arrow-right-bold</v-icon>
              </v-col>
              <v-col cols="12" xl="5" lg="5" md="5" sm="12" xs="12">
                <v-card class="rounded-lg elevation-1 mb-5 card-mod no-drop">
                  <v-card-title class="teal darken-1 white--text">
                    Seleccionados
                  </v-card-title>
                  <v-divider></v-divider>
                  <div class="scroll-container-certificaciones">
                    <draggable
                      v-model="upasContenedor"
                      :group="group2"
                      class="list"
                    >
                      <v-list
                        v-for="item in upasContenedor"
                        :key="item.id"
                        class="card"
                        color="blue-grey lighten-5"
                      >
                        <v-list-item color="grey">
                          <v-list-item-content class="align-end">
                            {{ item.id }} {{ item.nombre }}
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </draggable>
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="rounded-lg elevation-0"
            color="teal darken-1"
            dark
            :loading="loading"
            @click="validar()"
          >
            <v-icon left>mdi-check-circle-outline</v-icon> Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <SnackBarError
      v-if="muestraBarra"
      :muestraBarra="muestraBarra"
      :texto="textoBarra"
      :color="colorBarra"
      @cierraBarra="muestraBarra = false"
    />
  </v-container>
</template>

<script>
import axios from "axios";
import draggable from "vuedraggable";
import { deleteToken } from "../../../../../store/Funciones/funciones";
import SnackBarError from "../../../General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue";
export default {
  name: "form-relacion-upa-certificacion",
  components: { draggable, SnackBarError },
  props: ["dialogForm", "upa"],
  data() {
    return {
      textoBarra: "",
      colorBarra: "",
      muestraBarra: false,

      group2: "my-group",
      search: "",
      loading: false,
      editedIndex: -1,
      upasContenedor: [],
      cert: [],
    };
  },
  computed: {},
  mounted() {
    this.getCertificaciones();
  },
  methods: {
    validar() {
      let idCertificaciones = [];
      this.upasContenedor.map((element) => {
        idCertificaciones.push(element.id);
      });
      if (idCertificaciones.length > 0) {
        this.guardar(idCertificaciones);
      } else {
        this.textoBarra =
          "Selecciona una o más certificaciones para continuar.";
        this.colorBarra = "error";
        this.muestraBarra = true;
      }
    },

    guardar(idCertificaciones) {
      let params = {
        Opcion: 6,
        upa: this.upa.id,
        certificaciones: idCertificaciones,
        token: localStorage.getItem("token"),
      };
      this.loading = true;
      axios
        .post(
          `${this.$store.getters.getHost}/SeguridadMedioAmbiental/UPA/ws-admonUpas.php`,
          params
        )
        .then((response) => {
          this.loadingInfo = false;
          if (response.data.status == "OK") {
            this.textoBarra = "Articulo registrado correctamente.";
            this.colorBarra = "success";
            this.muestraBarra = true;
            this.$emit("cerrarTicket", response.data.response);
          } else if (response.data.status == "ERROR") {
            this.textoBarra = "Algo salió mal.";
            this.colorBarra = "error";
            this.muestraBarra = true;
          } else if (response.data.status == "EXPRESSION") {
            deleteToken();
          }
          this.loading = false;
        })
        .catch(function (e) {
          alert("Ocurrió un error: " + e);
        });
    },

    getCertificaciones() {
      this.cert = [];
      let params = {
        Opcion: 2,
        idUpa: this.upa.id,
        token: localStorage.getItem("token"),
      };
      axios
        .post(
          `${this.$store.getters.getHost}/General/SolicitudCapacitaciones/ws-solicitud-capacitaciones.php`,
          params
        )
        .then((response) => {
          this.loadingInfo = false;
          if (response.data.status == "OK") {
            this.cert = response.data.capacitaciones;
          } else if (response.data.status == "VACIO") {
            this.textoBarra = "No se encontraron registros.";
            this.colorBarra = "info";
            this.muestraBarra = true;
          } else if (response.data.status == "EXPRESSION") {
            deleteToken();
          }
        })
        .catch(function (e) {
          alert("Ocurrió un error: " + e);
        });
    },
  },
};
</script>

<style lang="sass">
.card-mod
  min-height: 200px

.scroll-container-certificaciones
  height: 180px
  overflow-y: scroll
  background-color: transparent
  border-radius: 10px !important
  overflow-y: auto
</style>
