<template>
  <div>
    <v-dialog v-model="verAddProject" persistent scrollable max-width="350">
      <v-card class="rounded-lg">
        <v-toolbar class="elevation-0" color="white">
          <v-toolbar-title>{{ $t("DH360.addProyect") }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            fab
            x-small
            color="pink darken-3"
            dark
            @click="$emit('cierraAddProject', 0)"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider class="mb-2"></v-divider>
        <v-card-text>
          <div
            class="text-center"
            v-for="(item, index) in companies"
            :key="index"
          >
            <v-btn
              class="rounded-lg my-1"
              large
              dark
              color="teal"
              :disabled="item.Disabled"
              @click="openOpt(item)"
            >
              <strong>{{ item.Compania }}</strong> ({{ item.CIA_JDE }})
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="addName" persistent max-width="450">
      <v-card class="rounded-lg" color="primary">
        <v-card-title class="text-subtitle-2 blue lighten-1 white--text">
          {{ $t("DH360.addProyectTo") }} {{ companySelected }}
          <v-spacer></v-spacer>
          <v-icon
            small
            dark
            @click="
              addName = false;
              cleanVar();
            "
            >mdi-close</v-icon
          >
        </v-card-title>
        <v-card-text>
          <v-alert
            v-model="showError"
            class="mt-3"
            color="pink"
            dark
            border="top"
            icon="mdi-alert"
            transition="scale-transition"
          >
            <v-row align="center">
              <v-col class="grow">
                {{ $t("DH360.unknownError") }}
              </v-col>
              <v-col class="shrink">
                <v-btn text depressed small dark @click="showError = false">{{
                  $t("DH360.close")
                }}</v-btn>
              </v-col>
            </v-row>
          </v-alert>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-text-field
              v-model="title"
              class="white--text"
              :rules="rules"
              required
              :disabled="loadingGuardar"
              color="white"
              dark
              counter="25"
              :label="$t('DH360.inputNameProjectText')"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-divider class="white"> </v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="rounded-lg primary--text white"
            small
            depressed
            :loading="loadingGuardar"
            @click="validate"
          >
            {{ $t("DH360.save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import { deleteToken } from "../../../../../../store/Funciones/funciones";

export default {
  name: "add-new-proyect-360",
  components: {},
  props: ["verAddProject", "companies"],
  data() {
    return {
      title: "",
      companySelected: "",
      valid: true,
      addName: false,
      showError: false,
      loadingGuardar: false,
      rules: [
        (v) => !!v || this.$t("DH360.emptyFieldValidationRule"),
        (v) => (v && v.length <= 100) || this.$t("DH360.rulesNameProjectField"),
      ],
    };
  },
  computed: {},
  mounted() {
    this.companies.map((item) => {
      item.openOptions = false;
    });
  },
  methods: {
    openOpt(item) {
      this.companySelected = item.Compania;
      this.addName = true;
    },

    cleanVar() {
      this.companySelected = "";
      this.title = "";
    },

    validaRespuesta(respuesta) {
      if (respuesta > 0) {
        this.addName = false;
        this.cleanVar();
        this.$emit("cierraAddProject", 1);
      } else {
        this.showError = true;
      }
    },

    validate() {
      if (this.$refs.form.validate()) {
        if (window.confirm(this.$t("DH360.confirmCreateProyect"))) {
          this.loadingGuardar = true;

          const compBuscada = this.companies.reduce((result, objSelected) => {
            if (objSelected.Compania.toString().trim() == this.companySelected) {
              result.push(objSelected);
            }
            return result;
          }, []);

          let params = {
            Opcion: 5,
            token: localStorage.getItem("token"),
            compania: compBuscada[0].CIA_JDE,
            name: this.title,
          };
          axios
            .post(
              `${this.$store.getters.getHost}/RelacionesLaborales/AdmonEvaluacionesDesempenio/ws-admon-360.php`,
              params
            )
            .then((response) => {
              if (response.data.status == "EXPRESSION") {
                deleteToken();
              } else if (response.data.status == "OK") {
                this.validaRespuesta(response.data.respuesta);
              } else {
                this.showError = true;
              }
            });
        }
      }
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
  },
};
</script>
