<template>
  <div>
    <v-sheet v-if="loadingData">
      <v-row class="fill-height py-5" align-content="center" justify="center">
        <v-col class="subtitle-1 text-center" cols="12">
          Obteninendo información...
        </v-col>
        <v-col cols="6" class="center-item-in-div">
          <v-progress-circular
            :size="70"
            :width="8"
            color="primary"
            indeterminate
          ></v-progress-circular>
        </v-col>
      </v-row>
    </v-sheet>
    <v-card
      class="backgroud-color-card-reclutamiento rounded-lg rounded-t-0 pa-5 center-item-in-div-column"
      v-else
    >
      <v-sheet
        min-height="50"
        width="100%"
        class="neumorphism-card-reclutamiento rounded-xl pa-2 mb-2"
        v-for="(psico, index) in candidato" :key="index"
      >
      <div class="px-4 font-weight-bold blue-grey--text text-darken-2 text-capitalize subtitle-1">{{ psico.name }}</div>
      <RadarChart :etiquetas="Object.keys(psico.data)" :valores="Object.values(psico.data)" />
    </v-sheet>
    </v-card>
    <SnackBar
      :muestaBarra="muestraBarra"
      :texto="textoBarra"
      :color="colorBarra"
      @cierraBarra="muestraBarra = false"
    />
  </div>
</template>

<script>
import axios from "axios";
import { deleteToken } from "../../../../../store/Funciones/funciones";
import SnackBar from "../../../General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias";
import RadarChart from "./RadarChart.vue";
export default {
  name: "informacion-psicometrias-candidato",
  props: ["idCandidato"],
  components: { SnackBar,RadarChart },
  data() {
    return {
      loadingData: true,
      candidato: [],
      muestraBarra: false,
      columnas: 1,
      textoBarra: "",
      colorBarra: "",
      calificacionFinal: 0,
      entrevistadoresNum: 0,
      entrevistadoresConCafNum: 0,
    };
  },
  computed: {
    isSmallDisplay() {
      return this.$vuetify.breakpoint.name == "xs" ||
        this.$vuetify.breakpoint.name == "xs"
        ? true
        : false;
    },
  },
  mounted() {
    this.getPsicometriasCandidato();
  },
  methods: {
    getPsicometriasCandidato() {
      let params = {
        Opcion: 6,
        token: localStorage.getItem("token"),
        idCandidato: this.idCandidato,
      };
      axios
        .post(
          `${this.$store.getters.getHost}/Reclutamiento/ws-admin-candidatos.php`,
          params
        )
        .then((response) => {
          this.loadingData = false;
          if (response.data.status != "EXPSESSION") {
            this.candidato = response.data.candidato;
          } else {
            deleteToken();
          }
        })
        .catch((e) => {
          this.colorBarra = "error";
          this.textoBarra = e;
          this.muestaBarra = true;
        });
    },
  },
};
</script>
