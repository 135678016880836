<template>
  <v-card>
    <v-data-table :headers="headers" :items="incidencias" :search="buscar" :footer-props="{  'items-per-page-text': 'Elementos por página',}"
      :loading="loading" class="elevation-1" dense mobile-breakpoint="0" no-data-text="No se encuentran incidencias para estas fechas"
      loading-text="Cargando contentenido..."
    >
      <template v-slot:top>
        <v-toolbar flat color="primary" class="rounded-lg rounded-b-0 rounded-t-0">
          <v-text-field v-model="buscar" :label="$t('DH360.findTextBox')" class="mt-7 rounded-lg" dense
            clearable flat solo-inverted dark prepend-inner-icon="mdi-magnify"></v-text-field>
          <v-spacer></v-spacer>
          <v-tooltip top color="primary">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" small color="teal darken-2" dark @click="cambiarPeriodo()">Cambiar periodo</v-btn>
            </template>
            <span class="caption center-item-in-div-column">
              Periodo: <br> 
              <strong>{{fechaInicial}} - {{fechaFinal}}</strong>
            </span>
          </v-tooltip>
        </v-toolbar>
      </template>
      <template v-slot:[`item.TipoIncidencia`]="{item}">
        <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon small v-bind="attrs" v-on="on" @click="detallesIncidencia(item)">
                <v-icon color="primary">mdi-timer-alert-outline</v-icon>
              </v-btn>
            </template>
            <span class="caption center-item-in-div-column">[{{item.TipoIncidencia}}] Llegada tarde
            <strong>Ver detalles</strong>
            </span>
        </v-tooltip>
    </template>
    </v-data-table>
    <detalles-colaborador v-if="dialogDetalles" :dialog="dialogDetalles" :item="item" :TipoIncidencia="3" @cierraDetalle="dialogDetalles = false" ></detalles-colaborador>
    <filtro-reporte v-if="dialogPeriodo" :dialog="dialogPeriodo" :fecha1="fechaInicial" :fecha2="fechaFinal" @cierraFiltro="actualizaFechas" ></filtro-reporte>
  </v-card>
</template>
<script>
import axios from 'axios'
import FiltroReporte from './FiltroReporte.vue';
import DetallesColaborador from './DetallesColaborador.vue';
import { deleteToken } from '../../../../../../store/Funciones/funciones';
export default {
  name: "reporte-llegadas-tarde",
  components: {DetallesColaborador, FiltroReporte },
  props: [],
  data() {
    return {
      buscar: '',
      loading: true,
      incidencias: [],
      headers: [
        { text: 'Folio', value: 'NumeroPersonal', align: 'center'},
        { text: 'Colaborador ', value: 'Nombre' },
        { text: 'Fecha Inicio / Final', value: 'FechaInicio', align: 'center' },
        // { text: 'Fecha Final', value: 'FechaFinal', align: 'center' },
        { text: 'Usuario Autoriza', value: 'UsuarioAutoriza', },
        { text: 'Fecha Autorización ', value: 'FechaAutoriza', align: 'center' },
        { text: 'Estatus', value: 'EstatusDesc', },
        { text: 'Detalles ', value: 'TipoIncidencia', align: 'center' },
      ],
      dialogDetalles: false,
      item: [],
      fechaFinal: '',
      fechaInicial: '',
      dialogPeriodo: false,
    };
  },
  computed: { },
  mounted() { 
    this.getFechas();
    this.getIncidenciasOlvidoGafete();

  },
  methods: {
    getIncidenciasOlvidoGafete() {
      let params = {
        Opcion: 5,
        token: localStorage.getItem('token'),
        fechaInicial:this.fechaInicial,
        fechaFinal:this.fechaFinal,
        tipoReporte:3,
      }
      axios.post(`${this.$store.getters.getHost}/RelacionesLaborales/Reportes/ws-IncidenciasPorFechas.php`, params).then(async (response) => {
        if (response.data.status == "OK") {
          this.loading = false;
          this.incidencias = response.data.incidencias;
        } else if (response.data.status == "VACIO"){
          this.loading = false;
        } else if (response.data.status == "EXPSESSION") {
          deleteToken();
        }
      })
    },
    
    getFechas() {
      var fechaActual = new Date();

      // Obtener los componentes de la fecha
      var year = fechaActual.getFullYear();
      var month = String(fechaActual.getMonth() + 1).padStart(2, '0');
      var day = String(fechaActual.getDate()).padStart(2, '0');

      this.fechaFinal = `${year}-${month}-${day}`;

      // Obtener la fecha de hace un mes
      var fechaHaceUnMes = new Date();
      fechaHaceUnMes.setMonth(fechaHaceUnMes.getMonth() - 1);

      // Obtener los componentes de la fecha de hace un mes
      var yearMesAnterior = fechaHaceUnMes.getFullYear();
      var monthMesAnterior = String(fechaHaceUnMes.getMonth() + 1).padStart(2, '0');
      var dayMesAnterior = String(fechaHaceUnMes.getDate()).padStart(2, '0');

      this.fechaInicial = `${yearMesAnterior}-${monthMesAnterior}-${dayMesAnterior}`;

    },

    detallesIncidencia(item) {
      this.item = item; 
      this.dialogDetalles = true;
    },

    cambiarPeriodo() {
        this.dialogPeriodo = true;
    },

    actualizaFechas(nuevasFechas) {
      // Maneja las nuevas fechas aquí
      if(this.fechaInicial != nuevasFechas.fechaInicial || this.fechaFinal != nuevasFechas.fechaFinal){
        this.fechaInicial = nuevasFechas.fechaInicial;
        this.fechaFinal = nuevasFechas.fechaFinal;
        this.clean();
        this.getIncidenciasOlvidoGafete();
      }
      this.dialogPeriodo = false;
    },
    
    clean() {
      this.loading = true;
      this.incidencias = [];
    },
  },
};
</script>
