var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('div',{staticClass:"text-right"},[_c('v-btn',{staticClass:"mb-4",attrs:{"depressed":"","outlined":"","rounded":"","color":"teal","small":""},on:{"click":function($event){return _vm.openEdicion(1)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-plus-circle-outline ")]),_vm._v(" agregar documento ")],1)],1),_c('v-data-table',{attrs:{"dense":"","loading":_vm.loadingContent,"headers":_vm.headers,"items":_vm.tiposDocs,"sort-by-text":"Ordenar por","no-data-text":"No hay registros.","items-per-page-all-text":"Todos","items-per-page-text":"Elementos por página","show-current-page":true,"show-first-last-page":true,"loading-text":"Cargando contenido...","footer-props":{
      'items-per-page-options': [5, 10, 15, 20, 25],
      'items-per-page-text': 'Elementos por página',
    }},scopedSlots:_vm._u([{key:"item.documento",fn:function(ref){
    var item = ref.item;
return [_c('div',{attrs:{"align":"left"}},[_vm._v(_vm._s(item.documento))])]}},{key:"item.usrModifica",fn:function(ref){
    var item = ref.item;
return [_c('div',{attrs:{"align":"left"}},[_vm._v(_vm._s(item.usrModifica))])]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":"","color":"cyan lighten-4"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"cyan darken-4"},on:{"click":function($event){return _vm.openEdicion(2, item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil-outline ")])]}}],null,true)},[_c('label',{staticClass:"text-uppercase font-weight-bold primary--text"},[_vm._v("Editar tipo documento")])]),_c('v-tooltip',{attrs:{"top":"","color":"pink lighten-4"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"pink darken-4"},on:{"click":function($event){return _vm.editaItem(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete-outline ")])]}}],null,true)},[_c('label',{staticClass:"text-uppercase font-weight-bold primary--text"},[_vm._v("Remover tipo documento")])])]}}],null,true)}),(_vm.openEdit)?_c('ModificaTiposDocumentos',{attrs:{"openNuevoTipoDocumento":_vm.openEdit,"opcion":_vm.opcionEdit,"item":_vm.itemEdit},on:{"cierraVentana":_vm.cierraEdit}}):_vm._e(),_c('SnackBarError',{attrs:{"muestraBarra":_vm.muestraMensaje,"texto":_vm.textoMensaje,"color":_vm.colorMensaje},on:{"cierraBarra":function($event){_vm.muestraMensaje = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }