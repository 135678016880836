import IncidenciaDerechoUsuario from './components/IncidenciasDerechoASolicitar.vue'
import SnackBarErrorIncidencias from './components/componentsExtra/SnackBarErrorIncidencias.vue'
import {
    deleteToken
} from '../../../../store/Funciones/funciones'
import axios from 'axios'
export default {
    name: 'src-views-mis-incidencias',
    components: {
        IncidenciaDerechoUsuario,
        SnackBarErrorIncidencias,
    },
    data: () => ({
        today: new Date().toISOString().substring(0, 10),
        focus: new Date().toISOString().substring(0, 10),
        loadingBotonCancelar: false,
        colorBarra: "red accent-2",
        textoBarra: "",
        muestraBarra: false,
        type: 'month',
        types: ['mes', 'semana', 'día', '4día'],
        mode: 'stack',
        modes: ['stack', 'column'],
        weekday: [0, 1, 2, 3, 4, 5, 6],
        weekdays: [{
                text: 'Dom - Sab',
                value: [0, 1, 2, 3, 4, 5, 6]
            },
            {
                text: 'Lun - Dom',
                value: [1, 2, 3, 4, 5, 6, 0]
            },
            {
                text: 'Lun - Vie',
                value: [1, 2, 3, 4, 5]
            },
            {
                text: 'Lun, Mie, Vie',
                value: [1, 3, 5]
            },
        ],
        value: '',
        page_tittle: 'Calendario de checadas e incidencias',
        events: [],
        secondarray: [],
        secondarray2: [],
        festivos: [],
        incidencias: [],
        estatusIncidencias: [],
        respuestaIncidenciaCancelada: [],
        respuestaDetalles: [],
        // tiposIncidenciasArray: [],
        colors: [
            'yellow lighten-3',
            'green',
            'red',
            'red darken-4',
            'green darken-4',
            'grey darken-1',
            // 'yellow accent-4',
            'deep-purple lighten-1',
            'blue',
            'blue darken-4',
            'blue darken-2',
            'blue-grey lighten-3'
        ],
        colorsTema: [{
                color: 'yellow lighten-3',
                tema: false
            },
            {
                color: 'green',
                tema: true
            },
            {
                color: 'red',
                tema: true
            },
            {
                color: 'red darken-4',
                tema: true
            },
            {
                color: 'green darken-4',
                tema: true
            },
            {
                color: 'grey darken-1',
                tema: true
            },
            {
                color: 'deep-purple lighten-1',
                tema: true
            },
            {
                color: 'blue',
                tema: true
            },
            {
                color: 'blue darken-4',
                tema: true
            },
            {
                color: 'blue darken-2',
                tema: true
            },
            {
                color: 'blue-grey lighten-3',
                tema: true
            },
        ],
        colorsText: [
            'yellow--text text--lighten-3',
            'green--text text--',
            'red--text text--',
            'red--text text--darken-4',
            'green--text text--darken-4',
            'grey--text text--darken-1',
            'yellow--text text--accent-4',
            'blue--text text--',
            'blue--text text--darken-4',
            'blue--text text--darken-2',
            'blue-grey--text text--lighten-3',
            'black--text',
            'white--text'
        ],
        colorCheck: '',
        colorIncidences: '',
        colorHoliday: '',
        checkIn: '',
        checkOut: '',
        names: ['Meeting', 'Holiday', 'PTO', 'Travel', 'Event', 'Birthday', 'Conference', 'Party', 'blue-grey lighten-3'],
        textoCargando: 'Cargando ',
        selectedOpen: false,
        selectedElement: null,
        selectedEvent: {},
        dialogCargaIncidencia: false,
        loadingButtonDetails: false,
        windowSize: {
            x: 0,
            y: 0,
        },
        dialogSimbologiaColores: false,
        compania: '',
        bloqueo: false,
    }),
    computed: {
        dialogCargandoInf: function() {
            return this.textoCargando != 'Cargando '
        },
    },
    created() {
        //  window.document.title = "DH:URREA - Mis incidencias.";
        this.events = [];
        this.saveMonitoreo();
    },
    mounted() {
        this.getCompania();
        // this.getStatusIncedence();
        // this.onResize();


    },
    methods: {
        imprimeDia({ date }) {
            console.log(date)
            this.dialogCargaIncidencia = true;
        },
        saveMonitoreo() {
            let params = {
                Opcion: 10,
                pantalla: "Incidencias",
                token: localStorage.getItem('token')
            }
            axios
                .post(`${this.$store.getters.getHost}/General/ws-general.php`, params)
                .then((response) => {
                    console.log(response.data)
                })
        },
        onResize() {
            this.windowSize = {
                x: window.innerWidth,
                y: window.innerHeight
            }
        },

        getEvents() {
            // const headers = {
            //     headers: {
            //         'X-AUTH-TOKEN': localStorage.getItem('token')
            //     }
            // };
            var params = {
                Opcion: 1,
                token: localStorage.getItem('token')
            }
            var paramsIncidencias = {
                Opcion: 2,
                token: localStorage.getItem('token')
            }
            var paramsHoliday = {
                Opcion: 3,
                token: localStorage.getItem('token')
            }
            this.textoCargando += 'checadas...'
            axios.post(`${this.$store.getters.getHost}/Incidencias/ws-incidencias-calendario.php`, params)
                .then((response) => {
                    // console.log(response.data)
                    if (response.data.checadas != null) {
                        var data = response.data.checadas.map(checada => {
                            this.checkIn = new Date(checada['ENTRADA']);
                            this.checkOut = new Date(checada['SALIDA']);
                            this.colorCheck = this.getColor(11);
                            this.secondarray.push({
                                name: 'Llegada',
                                start: this.checkIn,
                                formatoHora: this.modifyHours(this.checkIn.getHours()) + ':' + this.modifyMinutes(this.checkIn.getMinutes()),
                                color: this.colorCheck,
                                timed: true,
                                'event-text-color': this.colorsText[8]
                            })
                            if (this.checkIn.getHours() != this.checkOut.getHours()) {
                                this.secondarray.push({
                                    name: 'Salida',
                                    start: this.checkOut,
                                    formatoHora: this.modifyHours(this.checkOut.getHours()) + ':' + this.modifyMinutes(this.checkOut.getMinutes()),
                                    color: this.colorCheck,
                                    timed: true,
                                    'event-text-color': this.colorsText[8]

                                })
                            }
                            return checada;
                        });
                        this.secondarray2 = data;

                    }
                    this.textoCargando = this.textoCargando.replace('checadas...', '')
                })
                .catch(function(e) {

                    let result = e.toString().includes("401");
                    if (result) {
                        window.location.reload();
                    } else {
                        alert(e);
                    }
                });
            this.textoCargando += 'incidencias...'
            axios.post(`${this.$store.getters.getHost}/Incidencias/ws-incidencias-calendario.php`, paramsIncidencias)
                .then((response) => {
                    // //(response.data.incidencias)
                    if (response.data.incidencias != null && response.data.incidencias.length > 0) {
                        var data = response.data.incidencias.map(incidencia => {
                            var opc = incidencia['Estatus'];

                            var textColor = this.colorsText[12];
                            var descripocionIncidencia = '';
                            var icono = '';
                            var detalleEstatus = "Estatus: " + this.estatusIncidencias[opc - 1]['EstDescripcion'];


                            if (incidencia['TipoIncidencia'] == 2) {
                                icono = 'mdi-door-open'
                            } else if (incidencia['TipoIncidencia'] == 6) {
                                icono = 'mdi-arrow-decision'
                            } else if (incidencia['TipoIncidencia'] == 4) {
                                icono = 'mdi-account-child-circle'
                            } else if (incidencia['TipoIncidencia'] == 1) {
                                icono = 'mdi-beach'
                            } else if (incidencia['TipoIncidencia'] == 7 || incidencia['TipoIncidencia'] == 11) {
                                icono = 'mdi-home'
                            } else if (incidencia['TipoIncidencia'] == 12) {
                                icono = 'mdi-calendar-alert-outline'
                            }

                            if (incidencia['TipoIncidencia'] == 4) {
                                descripocionIncidencia = incidencia['IncDescripcion'] + ": " + incidencia['PermDescripcion']
                            } else {
                                descripocionIncidencia = incidencia['IncDescripcion']
                            }

                            this.colorIncidences = this.getColor(opc);
                            if (opc == 1 || opc == 2) {
                                textColor = this.colorsText[11];
                            }

                            if ((incidencia['FechaInicialDes'] == incidencia['FechaFinalDes']) || incidencia['FechaFinalDes'] == null) {
                                var fecha_i = new Date(incidencia['FechaInicialDes']);

                                if (incidencia['TipoIncidencia'] == 2) {
                                    this.secondarray.push({
                                        icon: icono,
                                        name: descripocionIncidencia,
                                        formatoHora: this.modifyHours(fecha_i.getHours()) + ":" + this.modifyMinutes(fecha_i.getMinutes()),
                                        start: fecha_i,
                                        color: this.colorIncidences,
                                        'event-text-color': textColor,
                                        details: detalleEstatus,
                                        estatus: incidencia['Estatus'],
                                        idIncidencia: incidencia['ID']

                                    })
                                } else {
                                    this.secondarray.push({
                                        icon: icono,
                                        name: descripocionIncidencia,
                                        start: fecha_i,
                                        color: this.colorIncidences,
                                        'event-text-color': textColor,
                                        details: detalleEstatus,
                                        estatus: incidencia['Estatus'],
                                        idIncidencia: incidencia['ID']

                                    })
                                }
                            } else {
                                var i = new Date(incidencia['FechaInicialDes']);
                                var f = new Date(incidencia['FechaFinalDes']);
                                this.secondarray.push({
                                    icon: icono,
                                    name: descripocionIncidencia,
                                    start: i,
                                    end: f,
                                    color: this.colorIncidences,
                                    'event-text-color': textColor,
                                    details: detalleEstatus,
                                    estatus: incidencia['Estatus'],
                                    idIncidencia: incidencia['ID']
                                })
                            }
                            return incidencia;
                        });
                        this.incidencias = data;
                    }
                    this.textoCargando = this.textoCargando.replace('incidencias...', '')
                })
                .catch(function(e) {

                    let result = e.toString().includes("401");
                    if (result) {
                        window.location.reload();
                    } else {
                        alert(e);
                    }
                });
            this.textoCargando += 'días festivos...'
            axios.post(`${this.$store.getters.getHost}/Incidencias/ws-incidencias-calendario.php`, paramsHoliday)
                .then((response) => {
                    var data = response.data.festivos.map(incidencia => {
                        var i = new Date(incidencia['dca_fecdia']);

                        this.colorHoliday = this.getColor(3);
                        this.secondarray.push({
                            name: incidencia['DESCR'],
                            start: i,
                            color: this.colorHoliday,
                            timed: false,
                            'event-text-color': ''
                        })
                        return incidencia;
                    });
                    this.festivos = data;
                    this.$nextTick(function() {
                        this.textoCargando = this.textoCargando.replace('días festivos...', '')
                    });
                })
                .catch(function(e) {

                    let result = e.toString().includes("401");
                    if (result) {
                        window.location.reload();
                    } else {
                        alert(e);
                    }
                });

            this.events = this.secondarray;


        },
        getColor(opc) {
            let color = '';

            switch (opc) {
                case 1:
                    color = this.colors[opc - 1];
                    break;
                case 2:
                    color = this.colors[opc - 1];
                    break;
                case 3:
                    color = this.colors[opc - 1];
                    break;
                case 4:
                    color = this.colors[opc - 1];
                    break;
                case 5:
                    color = this.colors[opc - 1];
                    break;
                case 6:
                    color = this.colors[opc - 1];
                    break;
                case 7:
                    color = this.colors[opc - 1];
                    break;
                case 8:
                    color = this.colors[opc - 1];
                    break;
                case 9:
                    color = this.colors[opc - 1];
                    break;
                case 10:
                    color = this.colors[opc - 1];
                    break;
                case 11:
                    color = this.colors[opc - 1];
                    break;
                default:
                    color = 'white';
                    break;
            }

            return color;
        },

        modifyMinutes(minutes) {
            if (minutes < 10) {
                return "0" + minutes.toString();
            }

            return minutes.toString();
        },

        modifyHours(hours) {
            if (hours < 10) {
                return "0" + hours.toString();
            }

            return hours.toString();
        },
        showEvent({
            nativeEvent,
            event
        }) {
            const open = () => {
                this.selectedEvent = event
                this.selectedElement = nativeEvent.target
                this.respuestaDetalles.length = 0
                requestAnimationFrame(() => requestAnimationFrame(() => this.selectedOpen = true))
            }

            if (this.selectedOpen) {
                this.selectedOpen = false
                this.respuestaDetalles.length = 0
                    // console.log("prueba")
                requestAnimationFrame(() => requestAnimationFrame(() => open()))
            } else {
                open()
            }

            nativeEvent.stopPropagation()
        },
        async getStatusIncedence() {
            let paramsEstatusInc = {
                Opcion: 4,
                token: localStorage.getItem('token')
            };
            //(paramsEstatusInc)
            this.textoCargando += 'estatus...'
            axios.post(`${this.$store.getters.getHost}/Incidencias/ws-incidencias-calendario.php`, paramsEstatusInc)
                .then((response) => {
                    if (response.data.status == "EXPSESSION") {
                        deleteToken()
                    } else {
                        var data = response.data.estatusIncidencias;
                        this.estatusIncidencias = data;
                        this.textoCargando = this.textoCargando.replace('estatus...', '')
                            // this.tiposIncidencias();
                        this.getEvents();
                    }

                })
                .catch(function(e) {

                    let result = e.toString().includes("401");
                    if (result) {
                        window.location.reload();
                    } else {
                        alert(e);
                    }
                });

        },
        getCompania() {
            let paramsEstatusInc = {
                Opcion: 28,
                token: localStorage.getItem('token')
            };
            axios.post(`${this.$store.getters.getHost}/General/ws-general.php`, paramsEstatusInc)
                .then((response) => {
                    if (response.data.status == "EXPSESSION") {
                        deleteToken()
                    } else {
                        this.compania = response.data.compania;
                        if (this.compania == '00100') {
                            this.getStatusIncedence();
                        } else {
                            this.bloqueo = true;
                        }
                        console.log(this.compania);
                    }
                })
                .catch(function(e) {
                    let result = e.toString().includes("401");
                    if (result) {
                        window.location.reload();
                    } else {
                        alert(e);
                    }
                });

        },
        cancelaIncidencia: function(id) {
            var params = {
                Opcion: 6,
                idIncidencia: id,
                token: localStorage.getItem('token')
            };

            if (window.confirm("¿Deseas cancelar esta incidencia?")) {
                this.loadingBotonCancelar = true;
                axios.post(`${this.$store.getters.getHost}/Incidencias/ws-incidencias-autorizacion.php`, params)
                    .then((response) => {
                        var data = response.data.incidencias;
                        this.respuestaIncidenciaCancelada = data;
                        this.validaRespuestaCancelacion()
                    })
                    .catch(function(e) {

                        let result = e.toString().includes("401");
                        if (result) {
                            window.location.reload();
                        } else {
                            alert(e);
                        }
                    });

            }
        },
        validaRespuestaCancelacion() {
            this.loadingBotonCancelar = false;
            if (this.respuestaIncidenciaCancelada == 1) {
                this.selectedOpen = false;
                this.textoBarra = "Incidencia cancelada con éxito";
                this.colorBarra = "primary";
                this.muestraBarra = true;
            } else {
                this.textoBarra = "Ocurrió un error, no se cancelo la incidencia";
                this.colorBarra = "red accent-2";
                this.muestraBarra = true;
            }

            Array.prototype.remove =
                Array.prototype.remove ||
                function() {
                    this.splice(0, this.length);
                };

            this.events.remove();
            this.secondarray.remove();
            this.secondarray2.remove();
            this.festivos.remove();
            this.incidencias.remove();

            this.getEvents();
        },

        cierraSeleccionIncidencia(metodo) {
            if (metodo === null) {
                this.dialogCargaIncidencia = false;
            } else {
                if (metodo == 1) {
                    this.textoBarra = "Incidencia registrada con éxito. :)"
                    this.colorBarra = "success"
                    this.muestraBarra = true
                }
                this.dialogCargaIncidencia = false;

                Array.prototype.remove =
                    Array.prototype.remove ||
                    function() {
                        this.splice(0, this.length);
                    };


                this.events.remove();
                this.secondarray.remove();
                this.secondarray2.remove();
                this.festivos.remove();
                this.incidencias.remove();
                this.getStatusIncedence();
            }
        },

        muestraDetalles(id) {
            this.respuestaDetalles.length = 0;
            var params = {
                Opcion: 7,
                id: id,
                token: localStorage.getItem('token')
            };
            axios.post(`${this.$store.getters.getHost}/Incidencias/ws-incidencias-calendario.php`, params)
                .then((response) => {
                    var data = response.data.detalleIncidencia;
                    this.respuestaDetalles = data;
                    this.loadingButtonDetails = false
                })
                .catch(function(e) {

                    let result = e.toString().includes("401");
                    if (result) {
                        window.location.reload();
                    } else {
                        alert(e);
                    }
                });


        }
    },
}