  <template>
    <div class="v-lazy pa-4" style="min-height: 52px">
      <div>
        <v-btn outlined color="primary" style="margin-bottom: 30px;" @click="cerrarForm">
          <v-icon dark>mdi-arrow-left-circle</v-icon>
        </v-btn>
      </div>

      <div style="background-color: #00599f; margin-bottom: 20px; color:white; border-radius: 8px; font-weight: bold"
        class="text-center pa-2">Datos de contratista de {{ empresaNombre }}</div>


      <v-form ref="form" v-model="valid" lazy-validation>
        <v-text-field label="Nombre del contratista del proveedor" outlined dense required v-model="nombreColaborador"
          :rules="nombreRules" :disabled="localIsView"></v-text-field>

        <v-text-field label="Telefono de Colaborador" outlined dense required v-model="telefono" :rules="telefonoRules"
          :disabled="localIsView"></v-text-field>

        <v-autocomplete outlined label="Función a desempeñar" v-model="funcionSeleccionada" :items="funciones"
          item-text="text" item-value="value" :disabled="localIsView"></v-autocomplete>

        <v-text-field label="Salario Mensual" outlined dense required v-model="salario" :rules="salarioRules"
          :disabled="localIsView"></v-text-field>

        <v-text-field label="Contacto del Responsable" outlined dense required v-model="contacto" :rules="contactoRules"
          :disabled="localIsView"></v-text-field>

        <div style="background-color: #00599f; margin-bottom: 20px; color:white; border-radius: 8px; font-weight: bold"
          class="text-center pa-2">Datos STPS</div>

        <v-text-field label="Número de Prestador de Servicio" outlined dense required v-model="numPrestador"
          :disabled="true"></v-text-field>

        <v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y
          min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="date" label="Vigencia del Permiso" outlined dense readonly v-bind="attrs" v-on="on"
              append-icon="mdi-calendar" :disabled="true"></v-text-field>
          </template>
          <v-date-picker v-model="date" @input="menu2 = false"></v-date-picker>
        </v-menu>

        <div class="text-center pa-2">
          <v-btn style="font-weight: bold;" :disabled="!valid || localIsView" color="success" class="mr-4"
            @click="guardarInformacion">
            Guardar Información
          </v-btn>
        </div>
        <div
          style="background-color: #00599f; margin-bottom: 20px; margin-top:20px; color:white; border-radius: 8px; font-weight: bold"
          class="text-center pa-2">Archivos PDF</div>
        <div>
          <v-simple-table>
            <tbody>
              <tr v-for="(file, index) in documentos" :key="index">
                <td>{{ file.name }}</td>
                <td>
                  <div style="display: flex; justify-content: flex-end; gap: 0;">
                    <v-btn color="cyan" style="width: 80px; margin-right: 4px;" dark class="rounded-lg"
                      @click="viewFile(file)" outlined>
                      <v-icon left>mdi-eye-outline</v-icon>Ver
                    </v-btn>
                    <v-btn :color="file.enviado ? 'teal darken-2' : 'primary'" dark class="rounded-lg"
                      @click="dialogUpload = true; auxFileName = file.name; auxFile = file" style="width: 100px;">
                      {{ file.enviado ? 'Editar' : 'Cargar' }}
                      <v-icon right>mdi-cloud-upload</v-icon>
                    </v-btn>
                  </div>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </div>
      </v-form>
      <cargaArchivoREPSE :dialogUpload="dialogUpload" :fileName="auxFileName" :fileType="'CLB'"
        :colaboradorId="currentColaboradorId" @cierraUpload="cierraUpload" ref="uploadComponent" />


      <div class="text-center ma-2">
        <v-snackbar v-model="snackbar" color="#0071ba">
          {{ text }}
          <template v-slot:action="{ attrs }">
            <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
              Cerrar
            </v-btn>
          </template>
        </v-snackbar>
      </div>
    </div>
  </template>

<script>
import axios from 'axios';
import cargaArchivoREPSE from './cargaArchivoREPSE.vue';

export default {
  components: { cargaArchivoREPSE },
  props: {
    colaborador: {
      type: Object,
      default: () => ({})
    },
    colaboradorId: {
      type: Number,
      default: null
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    isView: {
      type: Boolean,
      default: false
    }
  },
  created() {

    this.numeroProveedor = this.$store.getters.getUser.Folio;
    this.getProveedor();



  },
  data() {

    return {
      localColaboradorId: null,
      fileType: 'CLB',
      numeroProveedor: '',
      dialogUpload: false,
      auxFileName: '',
      auxFile: null,
      funcionSeleccionada: '',
      funciones: [],
      documentos: [
        { id: 1, name: 'Contrato laboral', file: null, enviado: false },
        { id: 2, name: 'Alta IMSS', file: null, enviado: false },
        { id: 3, name: 'Cedula de determinacion de cuotas SUA Colaborador', file: null, enviado: false },
        { id: 4, name: 'Resumen de liquidacion SUA', file: null, enviado: false },
        { id: 5, name: 'Formato para pago de cuotas obrero patronales, aportaciones y amortizaciones', file: null, enviado: false },
      ],
      valid: false,
      snackbar: false,
      text: "",
      nombreColaborador: '',
      nombreRules: [v => !!v || 'El Nombre de Colaborador es requerido', v => /^[a-zA-Z\s]+$/.test(v) || 'Ingrese solo letras'],
      telefono: '',
      telefonoRules: [v => !!v || 'El teléfono es requerido', v => /^\d+$/.test(v) || 'Ingrese solo números'],
      funcionD: '',
      rulesFuncion: [v => !!v || 'La función es requerida'],
      salario: '',
      salarioRules: [v => !!v || 'El salario es requerido', v => /^\d+$/.test(v) || 'Ingrese una cantidad'],
      contacto: '',
      contactoRules: [v => !!v || 'El Nombre de Colaborador es requerido', v => /^[a-zA-Z\s]+$/.test(v) || 'Ingrese solo letras'],
      numTrabajadores: '',
      numTrabajadorRules: [v => !!v || 'El Número de Trabajadores es requerido', v => /^\d+$/.test(v) || 'Ingrese solo números'],
      numPrestador: '',
      numPrestadorRules: [v => !!v || 'El Número de Prestador es requerido', v => /^\d+$/.test(v) || 'Ingrese solo números'],
      objetoSocial: '',
      objetoRules: [v => !!v || 'El Objeto Social es requerido'],
      date: '',
      menu2: false,
      datuRules: [v => !!v || 'La Fecha de Vigencia es requerida'],
      localIsEdit: this.isEdit,
      localIsView: this.isView,
      empresaNombre: '',
    }
  },
  computed: {
    currentColaboradorId() {
      return this.localColaboradorId || this.colaboradorId;
    }
  },

  watch: {
    isEdit(newVal) {
      this.localIsEdit = newVal;
    },
    isView(newVal) {
      this.localIsView = newVal;
    }
  },
  mounted() {
    this.getFunciones().then(() => {
      if (this.localIsEdit || this.localIsView) {
        this.existeDocumento();
        this.loadColaboradorData();
      }
    });

  },

  methods: {

    async getProveedor() {

      try {
        const response = await axios.get(`${this.$store.getters.getHostNode}/api/getProveedor/${this.$store.getters.getUser.Folio}`, {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        });

        const proveedorData = response.data.data[0];
        this.empresaNombre = proveedorData.Nombre || '';
        this.numPrestador = proveedorData.No_STPS || '';
        this.date = proveedorData.Vigencia.split('T')[0] || '';

      } catch (error) {
        this.showSnackbar('Error al obtener la información del proveedor');
        console.error(error);
      }
    },
    loadColaboradorData() {
      this.resetForm();
      this.nombreColaborador = this.colaborador.Nombre;
      this.telefono = this.colaborador.Telefono;
      this.funcionSeleccionada = this.funciones.find(funcion => funcion.text === this.colaborador.Funcion)?.value || '';
      this.salario = this.colaborador.Sueldo || '';
      this.contacto = this.colaborador.Contacto || '';
      this.numPrestador = this.colaborador.No_STPS || '';
      this.date = this.colaborador.vigencia ? this.colaborador.vigencia.split('T')[0] : '';
      this.validate();
    },
    guardarInformacion() {
      this.$refs.form.validate();
      if (this.valid) {
        if (this.localIsEdit) {
          this.editarColaborador();
        } else {
          this.cargarInfoColaborador();
        }
      }
    },

    async cargarInfoColaborador() {
  try {
    const colaboradorData = {
      numProv: this.$store.getters.getUser.Folio,
      id: this.colaboradorId,
      nombreColaborador: this.nombreColaborador,
      telefono: this.telefono,
      funcion: this.funcionSeleccionada,
      salario: this.salario,
      contacto: this.contacto
    };

    const response = await axios.post(`${this.$store.getters.getHostNode}/api/cargarInfoColaborador`, colaboradorData, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });

    if (response.data.status) {
      this.localColaboradorId = response.data.data; // Solo al crear se asigna el ID local
      this.showSnackbar('Información del colaborador guardada con éxito');
      this.resetForm();
    } else {
      this.showSnackbar('Error al guardar la información del colaborador: ' + response.data.message);
    }
  } catch (error) {
    console.error('Error al cargar la información del colaborador:', error);
    this.showSnackbar('Error al guardar la información del colaborador');
  }
},
    async getFunciones() {
      try {
        const response = await axios.get(`${this.$store.getters.getHostNode}/api/getFunciones`, {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        });
        if (Array.isArray(response.data.data)) {
          this.funciones = response.data.data.map(item => ({ text: item.nomPuesto, value: item.id }));
        } else {
          console.error('Error: La data no es un array');
        }
      } catch (error) {
        console.error('Error al cargar funciones:', error);
      }
    },
    showSnackbar(message) {
      this.text = message;
      this.snackbar = true;
    },
    cierraUpload(response) {
      if (response !== null && this.auxFile) {
        this.auxFile.enviado = response;
        this.dialogUpload = false;
        this.showSnackbar('Archivo agregado con éxito');
      }
      this.dialogUpload = false;
    },
    async editarColaborador() {
      try {
        const colaboradorData = {
          id: this.colaboradorId,
          nombreColaborador: this.nombreColaborador,
          telefono: this.telefono,
          funcion: this.funcionSeleccionada,
          salario: this.salario,
          contacto: this.contacto
        };

        const response = await axios.post(`${this.$store.getters.getHostNode}/api/editarColaborador`, colaboradorData, {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        });
        if (response.data.status) {

          this.showSnackbar('Se ha editado con éxito');
          this.resetForm();
        } else {
          this.showSnackbar('Error al guardar la información del colaborador: ' + response.data.message);
        }
      } catch (error) {
        console.error('Error al cargar la información del colaborador:', error);
        this.showSnackbar('Error al guardar la información del colaborador');
      }
    },
    async viewFile(file) {
      const colaboradorIdView = `C${this.currentColaboradorId}`;
      console.log(colaboradorIdView);
      try {
        const response = await axios.get(`${this.$store.getters.getHostNode}/api/descargarArchivo/${file.name}/${colaboradorIdView}`, {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        });
        const { metadata, file: base64File } = response.data;

        const iv = new Uint8Array(JSON.parse(metadata.iv));

        const key = await this.importKey(JSON.parse(metadata.key));
        const encryptedData = Uint8Array.from(atob(base64File), c => c.charCodeAt(0)).buffer;
        const decryptedArrayBuffer = await crypto.subtle.decrypt(
          {
            name: 'AES-GCM',
            iv: iv,
          },
          key,
          encryptedData
        );
        const decryptedBlob = new Blob([new Uint8Array(decryptedArrayBuffer)], { type: 'application/pdf' });
        const decryptedUrl = URL.createObjectURL(decryptedBlob);
        window.open(decryptedUrl);
      } catch (error) {
        this.showSnackbar('Primero carga un archivo');
      }
    },
    cerrarForm() {
      this.resetForm();
      this.$refs.form.resetValidation();
      this.$refs.form.reset();
      this.localIsEdit = false;
      this.localIsView = false;
      this.$emit('volver');
    },
    async importKey(jwk) {
      const key = await crypto.subtle.importKey(
        'jwk',
        jwk,
        {
          name: 'AES-GCM',
        },
        true,
        ['encrypt', 'decrypt']
      );
      return key;
    },
    resetForm() {
      this.nombreColaborador = '';
      this.telefono = '';
      this.funcionSeleccionada = '';
      this.salario = '';
      this.contacto = '';
      this.$refs.form.resetValidation();
    },
    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    async existeDocumento() {
      try {
        const colaboradorIdView = `C${this.currentColaboradorId}`;
        const documentosPromises = this.documentos.map(async (documento) => {
          const response = await axios.get(`${this.$store.getters.getHostNode}/api/existeDocumento/${documento.name}/${colaboradorIdView}`, {
            headers: {
              Authorization: localStorage.getItem("token"),
            },
          });
          
          if (response.data.status === 'OK') {
            documento.enviado = true;
          }
        });
        await Promise.all(documentosPromises);
      } catch (error) {
        console.error('Error al verificar los documentos:', error);
      }
    },
  },
};
</script>


