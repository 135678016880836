<template>
  <v-container fluid>
    <div v-show="!loadingData" class="text-center">
      <v-btn
        class="rounded-pill mb-1"
        small
        outlined
        @click="
          muestraEdicionDeEncuestas = true;
          getFamilias();
        "
      >
        <v-icon left>mdi-pencil</v-icon>
        {{ $t("DH360.edit") }} {{ $t("DH360.family") }}
      </v-btn>
    </div>
    <v-row
      v-if="loadingData"
      class="fill-height"
      align-content="center"
      justify="center"
    >
      <v-col class="text-subtitle-1 text-center" cols="12">
        {{ $t('DH360.gettingSurveys') }}
      </v-col>
      <v-col cols="6">
        <v-progress-linear
          color="primary"
          indeterminate
          rounded
          height="6"
        ></v-progress-linear>
      </v-col>
    </v-row>
    <v-row class="fill-height" dense v-else>
      <v-col
        cols="12"
        xs="12"
        sm="6"
        md="4"
        v-for="(item, index) in participantes"
        :key="index"
      >
        <v-card
          class="elevation-0 rounded-lg"
          style="border: 0.8px solid #232323;"
          height="200"
        >
          <v-card-subtitle class="font-weight-bold" style="font-size: 1.1rem;">
            {{ item[0].Descripcion }}
          </v-card-subtitle>
          <v-card-text>
            <div class="scroll-container-rep-enc">
              <v-simple-table dense>
                <tbody>
                  <tr v-for="(evaluado, indice) in item" :key="indice">
                    <td>{{ evaluado.Nombre }}</td>
                    <td v-if="evaluado.Status == 0">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon small v-bind="attrs" v-on="on">
                            mdi-clock-alert-outline
                          </v-icon>
                        </template>
                        <span>{{ $t("DH360.pending") }}</span>
                      </v-tooltip>
                    </td>
                    <td v-else>
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon small v-bind="attrs" v-on="on">
                            mdi-checkbox-marked-circle-outline
                          </v-icon>
                        </template>
                        <span>{{ $t("DH360.finished") }}</span>
                      </v-tooltip>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="muestraEdicionDeEncuestas" max-width="500" persistent>
      <v-card class="rounded-lg">
        <v-card-title class="text-subtitle-2 primary white--text">
          {{ $t("DH360.edit") }} {{ $t("DH360.family").toLowerCase() }}
          <v-spacer></v-spacer>
          <v-icon dark @click="muestraEdicionDeEncuestas = false"
            >mdi-close</v-icon
          >
        </v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-autocomplete
              v-model="newFamily"
              class="mt-2"
              required
              dense
              outlined
              :disabled="loadingGuardar"
              :loading="loadingFamilies"
              :items="familias"
              :rules="nameRules"
              item-text="Descripcion"
              counter="200"
              :label="$t('DH360.inputFamilyText')"
            ></v-autocomplete>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn small class="rounded-lg" depressed color="teal darken-1" dark @click="confirmDelete = true">
            {{ $t("DH360.save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="confirmDelete" max-width="450" scrollable persistent>
      <v-card class="rounded-lg">
        <p
          class="text-center pt-4 px-2"
          style="font-size: 1.1rem; font-weight:600; color:darkslategray"
        >
          {{ $t("DH360.textTitleDialogConfirmReloadQuestion") }}
        </p>
        <v-card-actions style="display:flex; justify-content: space-around;">
          <v-btn
            class="rounded-lg"
            large
            depressed
            color="pink darken-3"
            width="180"
            :disabled="loadButtonDelPart"
            dark
            @click="
              confirmDelete = false;
            "
          >
            {{ $t("DH360.cancel") }}
          </v-btn>
          <v-btn
            class="rounded-lg"
            large
            depressed
            color="teal"
            width="180"
            :loading="loadButtonDelPart"
            dark
            @click="
              replaceSurveys()
            "
          >
            {{ $t("DH360.confirm") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from "axios";
import { deleteToken } from "../../../../../../store/Funciones/funciones";

export default {
  name: "muestra-evaluaciones-pendientes-detalle",
  components: {},
  props: ["idProyecto", "empleado"],
  data() {
    return {
      participantes: [],
      familias: [],
      loadingData: true,
      muestraEdicionDeEncuestas: false,
      loadingFamilies: true,
      valid: true,
      loadingGuardar: false,
      confirmDelete: false,
      loadButtonDelPart: false,
      newFamily: "",
      nameRules: [(v) => !!v || this.$t("DH360.emptyFieldValidationRule")],
    };
  },
  computed: {},
  mounted() {
    this.getDetailsPerEmployee();
  },
  methods: {
    getDetailsPerEmployee() {
      this.participantes.length = 0;
      let params = {
        Opcion: 31,
        token: localStorage.getItem("token"),
        idProyecto: this.idProyecto,
        empleado: this.empleado,
      };
      axios
        .post(
          `${this.$store.getters.getHost}/RelacionesLaborales/AdmonEvaluacionesDesempenio/ws-admon-360.php`,
          params
        )
        .then((response) => {
          if (response.data.status == "EXPRESSION") {
            deleteToken();
          } else if (response.data.status == "OK") {
            this.participantes = response.data.respuesta;
            this.loadingData = false;
          }
        });
    },
    getFamilias() {
      if (this.familias.length == 0) {
        let params = {
          Opcion: 32,
          token: localStorage.getItem("token"),
          idProyecto: this.idProyecto,
        };
        axios
          .post(
            `${this.$store.getters.getHost}/RelacionesLaborales/AdmonEvaluacionesDesempenio/ws-admon-360.php`,
            params
          )
          .then((response) => {
            if (response.data.status == "EXPRESSION") {
              deleteToken();
            } else if (response.data.status == "OK") {
              this.familias = response.data.respuesta;
              this.loadingFamilies = false;
            }
          });
      }
    },

    replaceSurveys(){
      let result = this.familias.filter((fam) => {
        return fam.Descripcion === this.newFamily
      })

      let params = {
        Opcion: 36,
        token: localStorage.getItem("token"),
        idProyecto: this.idProyecto,
        empleado: this.empleado,
        idFamilia: result[0].id
      };
      axios
        .post(
          `${this.$store.getters.getHost}/RelacionesLaborales/AdmonEvaluacionesDesempenio/ws-admon-360.php`,
          params
        )
        .then((response) => {
          if (response.data.status == "EXPRESSION") {
            deleteToken();
          } else if (response.data.status == "OK") {
            console.log(response.data)
            this.confirmDelete = false
            this.muestraEdicionDeEncuestas = false
            this.loadingData = true;
            this.getDetailsPerEmployee()
          } else if (response.data.status == "ERROR"){
            console.log(response.data)
          } else {
            console.log(response.data)
          }
        });
    }
  },
};
</script>

<style>
.scroll-container-rep-enc {
  height: 130px !important;
  overflow-y: scroll;
  background-color: transparent;
  border-radius: 10px !important;
  overflow-y: auto;
}

.scroll-container-rep-enc::-webkit-scrollbar {
  width: 10px;
  background-color: blue;
}

.scroll-container-rep-enc::-webkit-scrollbar-thumb {
  background-color: red;
  border-radius: 10px;
}

.scroll-container-rep-enc::-moz-scrollbar {
  width: 10px;
  background-color: rgba(240, 240, 240, 0.5);
}

.scroll-container-rep-enc::-moz-scrollbar-thumb {
  background-color: red;
  border-radius: 10px;
}

.scroll-container-rep-enc::scrollbar {
  width: 10px;
  background-color: blue;
}

.scroll-container-rep-enc::scrollbar-thumb {
  background-color: red;
  border-radius: 10px;
}
</style>
