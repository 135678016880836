var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"rounded-lg pa-2 elevation-0"},[_c('div',{staticStyle:{"display":"flex","justify-content":"space-between"}},[_c('label',{staticClass:"text-h6 font-weight-black blue-grey--text text--lighten-1"},[_vm._v(" Mis visitas registradas ")]),_c('v-text-field',{staticClass:"rounded-lg",staticStyle:{"max-width":"350px"},attrs:{"outlined":"","dense":"","prepend-inner-icon":"mdi-magnify","label":"Buscar..."},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"dense":"","headers":_vm.headers,"items":_vm.visitas,"search":_vm.search,"items-per-page":5,"no-data-text":"No se han registrado visitas","loading-text":"Cargando información...","loading":_vm.loadingData,"footer-props":{
      'items-per-page-text': 'Elementos por página',
      pageText: '{0}-{1} de {2}',
    },"single-expand":true,"expanded":_vm.expanded,"item-key":"id","show-expand":""},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"expanded-item",fn:function(ref){
    var headers = ref.headers;
    var item = ref.item;
return [_c('td',{staticStyle:{"max-width":"100% !important"},attrs:{"colspan":headers.length}},[_c('div',{staticClass:"text-center pt-3 pb-0"},[_c('label',{staticClass:"text-h6 font-weight-black blue-grey--text text--lighten-3"},[_vm._v(" Información completa de la visita ")])]),_c('v-card',{staticClass:"py-3 px-4 mx-2 mb-2 mt-0 rounded-xl elevation-0",staticStyle:{"display":"flex","justify-content":"space-between","flex-wrap":"wrap"},attrs:{"color":"blue-grey lighten-2"}},_vm._l((Object.keys(item)),function(element,index){return _c('div',{key:index,staticClass:"ma-1"},[_c('v-chip',{directives:[{name:"show",rawName:"v-show",value:(![null, undefined, ''].includes(item[element])),expression:"![null, undefined, ''].includes(item[element])"}],staticClass:"rounded-lg"},[_c('strong',{staticClass:"text-uppercase overline blue-grey--text text--darken-2"},[_vm._v(_vm._s(element)+": ")]),_vm._v(_vm._s(_vm.getData(element, item[element]))+" ")])],1)}),0),(_vm.expanded.includes(item))?_c('v-card',{staticClass:"py-2 px-4 mx-2 mb-2 mt-1 rounded-xl elevation-0",attrs:{"color":"blue-grey lighten-2"}},[_c('MuestraVisitantesPorGrupo',{attrs:{"idVisita":item.id}})],1):_vm._e()],1)]}},{key:"item.id",fn:function(ref){
    var item = ref.item;
return [_c('label',{staticClass:"caption font-weight-bold"},[_vm._v(_vm._s(item.id))])]}},{key:"item.fechas",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getCadenaFechas(item))+" ")]}},{key:"item.duracion",fn:function(ref){
    var item = ref.item;
return [_c('label',{staticClass:"caption font-weight-bold"},[_vm._v(" "+_vm._s(item.Entrada.date.substring(11, 16) + " a " + item.Salida.date.substring(11, 16))+" ")])]}},{key:"item.Nombre",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.Nombre + " " + item.Paterno + " " + item.Materno)+" ")]}},{key:"item.id_tipo",fn:function(ref){
    var item = ref.item;
return [_c('label',{staticClass:"caption"},[_vm._v(" "+_vm._s(_vm.getTipo(item.id_tipo)))])]}},{key:"item.estatus",fn:function(ref){
    var item = ref.item;
return [_c('v-chip',{class:_vm.getClass(item.estatus),attrs:{"small":""}},[_vm._v(_vm._s(_vm.getTextEstatus(item.estatus)))])]}},{key:"item.grupo",fn:function(ref){
    var item = ref.item;
return [_c('v-chip',{class:item.grupo === 1 ? 'cyan' : 'purple',attrs:{"small":"","dark":""}},[_vm._v(" "+_vm._s(item.grupo === 1 ? "Individual" : "Grupal")+" ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }