var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"rounded-xl elevation-0 pa-3"},[_c('v-data-table',{attrs:{"dense":"","headers":_vm.headers,"search":_vm.search,"items":_vm.personal,"no-data-text":"No hay registros para este estatus","loading-text":"Cargando personal...","loading":_vm.loadingData,"expanded":_vm.expanded,"show-expand":""},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticStyle:{"display":"flex","justify-content":"end"}},[_c('v-text-field',{staticClass:"rounded-xl",staticStyle:{"max-width":"350px"},attrs:{"outlined":"","dense":"","prepend-inner-icon":"mdi-table-search","placeholder":"Buscar en tabla..."},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-tooltip',{attrs:{"left":"","color":"blue lighten-4"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"small":"","fab":"","depressed":""},on:{"click":function($event){_vm.loadingData = true; _vm.updateTable()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-reload")])],1)]}}])},[_c('label',{staticClass:"text-uppercase font-weight-bold primary--text"},[_vm._v("Actualizar tabla")])])],1)]},proxy:true},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_vm._v(" "+_vm._s(item.comentarios)+" ")])]}},{key:"item.fecha_inicial",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.fixDate(item.fecha_inicial.date.substr(0, 10)))+" ")]}},{key:"item.fecha_aplicacion",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.fixDate(item.fecha_aplicacion.date.substr(0, 10)))+" ")]}},{key:"item.diasTranscurridos",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-center font-weight-bold",class:_vm.getColorDiasTranscurridos(item.diasTranscurridos, item.estatus)},[_vm._v(" "+_vm._s(item.diasTranscurridos)+" ")])]}},{key:"item.acciones",fn:function(ref){
var item = ref.item;
return [(item.estatus === 1)?_c('v-tooltip',{attrs:{"left":"","color":"blue lighten-4"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"blue darken-4"},on:{"click":function($event){return _vm.openConfirm(item.id, item.NAME)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-account-reactivate-outline ")])]}}],null,true)},[_c('label',{staticClass:"text-uppercase font-weight-bold primary--text"},[_vm._v("Activar")])]):_vm._e(),(item.estatus === 2)?_c('v-tooltip',{attrs:{"left":"","color":"green lighten-4"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"green darken-4"}},'v-icon',attrs,false),on),[_vm._v(" mdi-file-document-edit-outline ")])]}}],null,true)},[_c('label',{staticClass:"text-uppercase font-weight-bold green--text"},[_vm._v("Capturar evaluadores")])]):_vm._e(),(item.estatus === 3)?_c('v-tooltip',{attrs:{"left":"","color":"cyan lighten-4"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"cyan darken-4"}},'v-icon',attrs,false),on),[_vm._v(" mdi-file-eye-outline ")])]}}],null,true)},[_c('label',{staticClass:"text-uppercase font-weight-bold cyan--text"},[_vm._v("Ver proceso")])]):_vm._e(),([2, 3].includes(item.estatus))?_c('v-tooltip',{attrs:{"left":"","color":"pink lighten-4"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"pink darken-4"}},'v-icon',attrs,false),on),[_vm._v(" mdi-minus-circle-outline ")])]}}],null,true)},[_c('label',{staticClass:"text-uppercase font-weight-bold pink--text"},[_vm._v("Cancelar")])]):_vm._e()]}}])}),_c('ConfirmDialogReclutamiento',{attrs:{"showConfirm":_vm.muestraConfirm,"question":_vm.question},on:{"cierraConfirm":_vm.cierraConfirm}}),(_vm.muestraBarra)?_c('SnackBar',{attrs:{"muestraBarra":_vm.muestraBarra,"texto":_vm.textoBarra,"color":_vm.colorBarra},on:{"cierraBarra":function($event){_vm.muestraBarra = false}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }