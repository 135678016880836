<template>
  <v-container class="pt-5">
    <v-card
      class="pa-5 backgroun-degraded-reclutamientos elevation-0 rounded-xl"
    >
      <v-data-table
        :headers="headers"
        :items="usuarios"
        :search="search"
        :loading="loadingTabla"
        no-data-text="No hay registros"
        loading-text="Cargando contenido..."
        :footer-props="{
          'items-per-page-text': 'Elementos por página',
        }"
        style="background-color: transparent"
      >
        <template v-slot:top>
          <v-toolbar flat color="transparent" class="rounded-xl rounded-b-0">
            <v-text-field
              v-model="search"
              :label="$t('DH360.findTextBox')"
              class="mt-7"
              dense
              clearable
              flat
              prepend-inner-icon="mdi-magnify"
            ></v-text-field>
            <v-spacer></v-spacer>
            <v-divider
              class="mx-6"
              inset
              vertical
              style="border-color:lightslategrey"
            ></v-divider>
            <v-btn
              color="blue-grey darken-1"
              dark
              class="mb-1 font-weight-bold rounded-pill"
              @click="muestraBuscador = true"
            >
              <v-icon left>mdi-plus</v-icon>
              crear usuario
            </v-btn>
          </v-toolbar>
        </template>

        <template v-slot:item.ultimoAcceso="{ item }">
          {{ fixFormatDate(item.ultimoAcceso.date.substring(0, 10)) }}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip top color="pink darken-3">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="pink darken-3"
                class="mx-1"
                v-bind="attrs"
                v-on="on"
                @click="openConfirm(item, 2)"
              >
                mdi-trash-can
              </v-icon>
            </template>
            <span>Eliminar</span>
          </v-tooltip>
          <v-tooltip top color="deep-purple accent-2">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="deep-purple accent-2"
                class="mx-1"
                v-bind="attrs"
                v-on="on"
                @click="openConfirm(item, 1)"
              >
                mdi-lock-reset
              </v-icon>
            </template>
            <span>Restablecer contraseña</span>
          </v-tooltip>
          <v-tooltip top color="blue accent-2">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="blue accent-2"
                class="mx-1"
                v-bind="attrs"
                v-on="on"
                @click="emplid = item.Folio; muestraCargaArchivos = true;"
              >
                mdi-file-document-multiple-outline
              </v-icon>
            </template>
            <span>Ver expediente</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>

    <BuscadorUsuarios
      v-if="muestraBuscador"
      :muestraBuscador="muestraBuscador"
      @ocultaBuscador="cierraBuscador"
    />
    <ConfirmDialogReclutamiento
      v-if="showConfirm"
      :showConfirm="showConfirm"
      :question="question"
      @cierraConfirm="cierraConfirm"
    />
    <SnackBar
      v-if="muestraBarra"
      :muestraBarra="muestraBarra"
      :color="colorBarra"
      :texto="textoBarra"
      @cierraBarra="muestraBarra = false"
    />
    <MuestraArchivos
    v-if="muestraCargaArchivos"
      :dialogCargaArchivos="muestraCargaArchivos"
      :emplid="emplid"
      @cierraVentana="muestraCargaArchivos = false"
      />
  </v-container>
</template>

<script>
import axios from "axios";
import {
  deleteToken,
  formatDate,
} from "../../../../../store/Funciones/funciones";
import BuscadorUsuarios from "./BuscadorCandidatosUsuariosReclu.vue";
import SnackBar from "../../../General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue";
import ConfirmDialogReclutamiento from "../../../AdmnVacantes/components/ConfirmDialogReclutamiento.vue";
import MuestraArchivos from "../../../General/MiEmpresa/MiDocumentacion/components/MuestraArchivosPorCargar.vue";


export default {
  name: "tabla-usuarios-reclu",
  components: { BuscadorUsuarios, SnackBar, ConfirmDialogReclutamiento, MuestraArchivos },
  data() {
    return {
      headers: [
        {
          text: "ID",
          value: "Id_Usuario",
          class:
            "font-weight-bold blue-grey--text text--darken-2 text-uppercase",
        },
        {
          text: "ID DH",
          value: "Folio",
          align: "center",
          class:
            "font-weight-bold blue-grey--text text--darken-2 text-uppercase",
        },
        {
          text: "NOMBRE",
          value: "Nombre",
          class:
            "font-weight-bold blue-grey--text text--darken-2 text-uppercase",
        },
        {
          text: "USUARIO",
          value: "Nickname",
          align: "center",
          class:
            "font-weight-bold blue-grey--text text--darken-2 text-uppercase",
        },
        {
          text: "FECHA DE CREACIÓN",
          value: "ultimoAcceso",
          align: "center",
          class:
            "font-weight-bold blue-grey--text text--darken-2 text-uppercase",
        },
        {
          text: "ACCIONES",
          value: "actions",
          align: "center",
          sortable: false,
          class:
            "font-weight-bold blue-grey--text text--darken-2 text-uppercase",
        },
      ],
      usuarios: [],
      userTemporal: {},
      loadingTabla: true,
      muestraBuscador: false,
      muestraBarra: false,
      showConfirm: false,
      muestraCargaArchivos: false,
      tipoAccion: 0,
      emplid: 0,
      colorBarra: "",
      textoBarra: "",
      search: "",
      question: "",
    };
  },
  mounted() {
    this.getUsuarios();
  },
  methods: {
    muestraSnackBar(text, color) {
      this.colorBarra = color;
      this.textoBarra = text;
      this.muestraBarra = true;
    },
    getUsuarios() {
      let params = {
        Opcion: 15,
        token: localStorage.getItem("token"),
      };
      axios
        .post(
          `${this.$store.getters.getHost}/Reclutamiento/ws-admin-candidatos.php`,
          params
        )
        .then((response) => {
          if (response.data.status == "EXPRESSION") {
            deleteToken();
          } else if (response.data.status == "OK") {
            this.usuarios = response.data.users;
            this.loadingTabla = false;
          }
        });
    },
    fixFormatDate(date) {
      return formatDate(date);
    },
    openConfirm(item, type) {
      this.tipoAccion = type;
      if (type === 1) {
        this.question =
          "¿Realmente deseas restablecer la contraseña? Se enviará un correo al candidato.";
      } else if (type === 2) {
        this.question =
          "¿Realmente deseas eliminar a este usuario " + item.Nickname + "? ";
      }
      this.userTemporal = item;
      this.showConfirm = true;
    },
    cierraConfirm(respuesta) {
      if (respuesta) {
        this.showConfirm = false;
        this.loadingTabla = true;

        if (this.tipoAccion === 1) {
          this.resetPassword();
        } else if (this.tipoAccion === 2) {
          this.deleteUser();
        }
      } else {
        this.showConfirm = false;
      }
    },
    cierraBuscador(respuesta) {
      if (respuesta) {
        this.muestraBuscador = false;
        this.loadingTabla = true;
        this.getUsuarios();
      } else {
        this.muestraBuscador = false;
      }
    },
    resetPassword() {
      let params = {
        Opcion: 18,
        token: localStorage.getItem("token"),
        idUser: this.userTemporal.Id_Usuario,
        correo: this.userTemporal.Correo,
        nickname: this.userTemporal.Nickname,
        folio: this.userTemporal.Folio,
      };
      axios
        .post(
          `${this.$store.getters.getHost}/Reclutamiento/ws-admin-candidatos.php`,
          params
        )
        .then((response) => {
          this.loadingTabla = false;
          if (response.data.status == "EXPRESSION") {
            deleteToken();
          } else if (response.data.status == "OK") {
            if (
              response.data.users.tipo == 0 ||
              response.data.users.tipo == 1
            ) {
              this.muestraSnackBar(response.data.users.mensaje, "error");
            } else {
              this.muestraSnackBar(response.data.users.mensaje, "success");
            }
          }
        })
        .catch((error) => {
          this.muestraSnackBar(error, "error");
        });
    },

    deleteUser() {
      let params = {
        Opcion: 19,
        token: localStorage.getItem("token"),
        idUser: this.userTemporal.Id_Usuario,
      };
      axios
        .post(
          `${this.$store.getters.getHost}/Reclutamiento/ws-admin-candidatos.php`,
          params
        )
        .then((response) => {
          this.loadingTabla = false;
          if (response.data.status == "EXPRESSION") {
            deleteToken();
          } else if (response.data.status == "OK") {
            if (
              response.data.users.tipo == 0 ||
              response.data.users.tipo == 1
            ) {
              this.muestraSnackBar(response.data.users.mensaje, "error");
            } else {
              this.muestraSnackBar(response.data.users.mensaje, "success");
              this.loadingTabla = true;
              this.getUsuarios();
            }
          }
        })
        .catch((error) => {
          this.muestraSnackBar(error, "error");
        });
    },
  },
};
</script>

<style scope></style>
