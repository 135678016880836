
import InconformidadesListaMotivos from "../../../../../components/componentsInconformidades/InconformidadesListaMotivos.vue";
export default {
  name: 'admon-motivos-inconformidad',
  components: { InconformidadesListaMotivos },
  props: [],
  data() {
    return {
      verMotivos: true,
    }
  },
  computed: {

  },
  mounted() {

  },
  methods: {
    consultarMotivos() {
      this.verMotivos = true;
    },
    cerrarLista(response) {
      console.log(response);
      this.verMotivos = false;
    },
  }
}


