import axios from "axios";
import VueJsonToCsv from 'vue-json-to-csv'
export default {
    name: 'reporte-lista-asistencia',
    components: { VueJsonToCsv },
    props: [],
    data() {
        return {
            headers: [
                { text: 'ID', align: 'center', value: 'id' },
                { text: 'Curso', value: 'nombre' },
                { text: 'Instructor', value: 'instructor' },
                { text: 'Fecha creación', value: 'fechaRegistro', align: 'center', },
                { text: 'Duración', value: 'duracion', align: 'center', },
                { text: 'Impartido a proveedor', value: 'cursoProveedor', align: 'center', },
                { text: 'Acciones', value: 'actions', sortable: false, align: 'center', },
                { text: '', value: 'data-table-expand' },
            ],
            headers_report_to_export: {
                id_curso: { title: "ID Curso" },
                curso: { title: "Nombre Curso" },
                instructor: { title: "Instructor" },
                folio: { title: "Folio" },
                nombre_integrante: { title: "Integrante" },
                duracion: { title: "Duración" },
            },
            headers_report_to_export_general: {
                id: { title: "ID Curso" },
                curso: { title: "Nombre Curso" },
                instructor: { title: "Instructor" },
                fechaRegistro: { title: "Fecha de creación" },
                cursoProveedor: { title: "Impartido a proveedor" },
                folio: { title: "Folio" },
                nombre_integrante: { title: "Integrante" },
                duracion: { title: "Duración" },
            },
            cursos: [],
            asistentesPorCurso: [],
            expanded: [],
            saveItem: [],
            cargando: true,
            loadingInfo: true,
            openDialogExport: false,
            singleExpand: true,
            buscar: '',

            nameFile: '',
            nameFileGeneral: '',

        }
    },
    computed: {

    },
    mounted() {
        this.getCursos();
        this.getAsistentesPorCurso();
        //  window.document.title = "DH:URREA - Reporte lista de asistencia.";
    },
    methods: {
        closeDialogExport() {
            this.openDialogExport = false;
            this.nombreCursoIndividual = '';
            this.saveItem = [];
        },
        exportarExcel(item) {
            this.openDialogExport = true;
            this.nombreCursoIndividual = item.nombre;
            this.fixName();
            this.saveItem = item.asistentes;
            console.log(this.saveItem);
            console.log(this.nombreCursoIndividual);
        },
        getCursos() {
            let params = {
                Opcion: 3,
                token: localStorage.getItem("token")
            };
            axios.post(`${this.$store.getters.getHost}/UniversidadUrrea/ws-ListaAsistencia.php`, params).then((response) => {
                if (response.data.status == 'OK') {
                    this.cursos = response.data.data;
                }
                this.cargando = false;
                this.loadingInfo = false;
            })
        },

        fixName() {
            this.nameFile = "curso_" + this.nombreCursoIndividual;
        },

        fixNameGeneral() {
            let numero_aleatorio =
                Math.floor(Math.random() * (99999 - 10000 + 1)) + 10000;
            this.nameFileGeneral = "cursos" + numero_aleatorio.toString();
        },

        getAsistentesPorCurso(){
            let params = {
                Opcion: 4,
                token: localStorage.getItem("token")
            };
            axios.post(`${this.$store.getters.getHost}/UniversidadUrrea/ws-ListaAsistencia.php`, params).then((response) => {
                if (response.data.status == 'OK') {
                    this.asistentesPorCurso = response.data.data;
                }
                this.cargando = false;
                this.loadingInfo = false;
            })
        },
    }
}