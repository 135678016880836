<template>
  <div>
    <v-dialog
      class="rounded-xl"
      v-model="muestraEdicionPreguntas"
      persistent
      scrollable
      width="800"
    >
      <v-card class="rounded-xl">
        <v-card-title>
          <v-row>
            <v-col cols="11">
              <p style="color:#0068ac;" class="text-left font-weight-bold">
                Preguntas clave
              </p>
            </v-col>
            <v-col class="pa-0" cols="1">
              <div class="text-right">
                <v-icon
                  class="mt-0"
                  color="pink darken-4"
                  @click="cierraVentanaEnviaParams()"
                >
                  mdi-close-circle
                </v-icon>
              </div>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <div v-for="(p, index) in preguntasClaveEditadas" :key="index">
            <v-text-field
              v-model="p.pregunta"
              placeholder="Escribe la pregunta..."
              small
              dense
              outlined
            >
            </v-text-field>
            <v-textarea
              v-model="p.Respuesta"
              placeholder="Escribe la respuesta..."
              rows="2"
              small
              dense
              outlined
            >
            </v-textarea>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="pa-0"
            color="indigo darken-1"
            dark
            depressed
            rounded
            text
            block
            @click="cierraVentanaEnviaParams()"
          >
            guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="tienePerfilExistente"
      persistent
      class="rounded-lg"
      scrollable
      height="800"
      width="450"
    >
      <v-card class="rounded-lg">
        <v-card-title style="display:flex; justify-content:center;">
          <h3>¡Aviso!</h3>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="text-center my-5">
          <h4 class="font-weight-bold grey--text text--darken-3">
            Éxisten preguntas creadas para este puesto.
          </h4>
          <br />
          <h3>¿Te gustaría utilizarlas?</h3>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <!-- <div> -->
          <v-row class="my-0 py-0">
            <v-col cols="6" class="py-0">
              <v-btn
                text
                rounded
                depressed
                block
                color="primary"
                @click="noUsarPreguntasExistentes"
              >
                crear nuevas
              </v-btn>
            </v-col>
            <v-divider class="pa-0 " vertical></v-divider>
            <v-col cols="6" class="py-0">
              <v-btn
                text
                rounded
                depressed
                block
                color="primary"
                @click="usarPreguntasExistentes"
              >
                usar
              </v-btn>
            </v-col>
          </v-row>
          <!-- </div> -->
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// import axios from "axios";
// import { deleteToken } from "../../../../../../../store/Funciones/funciones";
export default {
  name: "update-preguntas-clave",
  props: ["muestraEdicionPreguntas", "preguntasPuesto", "primeraVez"],
  data() {
    return {
      preguntasClaveEditadas: [],
      tienePerfilExistente: false,
    };
  },
  mounted() {
    console.log(this.preguntasPuesto.length + this.primeraVez)
    if (this.preguntasPuesto.length > 0 && !this.primeraVez) {
      this.tienePerfilExistente = true;
    } 
    // else if(this.preguntasPuesto.length > 0 && this.primeraVez){
    //   this.usarPreguntasExistentes();
    // }
    else {
      this.noUsarPreguntasExistentes();
    }
  },
  methods: {
    usarPreguntasExistentes() {
      this.tienePerfilExistente = false;
      this.preguntasClaveEditadas = this.preguntasPuesto;
    },
    noUsarPreguntasExistentes() {
      for (let i = 0; i < 5; i++) {
        if(i < this.preguntasPuesto.length) {
          this.preguntasClaveEditadas.push(this.preguntasPuesto[i]);
        } else {
          this.preguntasClaveEditadas.push({
            id: 0,
            pregunta: "",
            Respuesta: "",
          });
        }
      }
    },
    cierraVentanaEnviaParams() {
      let preguntasCreadas = [];
      this.preguntasClaveEditadas.map((p) => {
        if (p.pregunta != "" || p.Respuesta != "") {
          preguntasCreadas.push(p);
        }
      });
      this.$emit("cierraVentanaPreguntasEdit", preguntasCreadas);
    },
  },
};
</script>
