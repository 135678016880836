<template>
    <v-card class="rounded-xl elevation-0 pa-4 ">
        <v-row align="center">
            <v-col cols="12" sm="6" md="3">
                <v-select dense clearable v-model="tipoReporte" :items="listaReportes" label="Reporte"
                    item-text="nombre" item-value="id" @change="consultarDatos"></v-select>
            </v-col>
            <!-- <v-col v-if="tipoReporte != 16 && tipoReporte !=18" cols="12" sm="6" md="2">
                <v-select dense clearable v-model="estiloReporte" :items="listaEstilo" label="Estilo" item-text="nombre"
                    item-value="id"></v-select>
            </v-col> -->
            <v-col cols="12" sm="6" md="2"
                v-show="tipoReporte != 1 && tipoReporte != 17 && tipoReporte != 2 && tipoReporte && tipoReporte != 19">
                <v-select dense clearable v-model="gPReporte" :items="listaGP" label="Grupo pago" item-text="nombre"
                    item-value="id" @change="consultarDatos"></v-select>
            </v-col>
            <v-col cols="12" sm="6" md="2"
                v-show="tipoReporte != 1 && tipoReporte != 2 && tipoReporte && tipoReporte != 7 && tipoReporte != 17 && tipoReporte != 19 && tipoReporte != 10">
                <v-select dense clearable v-model="yearReporte" :items="yearsList" label="Año" item-text="year"
                    item-value="id" @change="clearSelectedMonths"></v-select>
            </v-col>
            <v-col cols="12" sm="6" md="2"
                v-show="tipoReporte != 1 && tipoReporte != 2 && tipoReporte && tipoReporte != 9 && tipoReporte != 7 && tipoReporte != 19 && tipoReporte != 16 && tipoReporte != 11 && tipoReporte != 17 && tipoReporte != 10">
                <v-select dense clearable v-model="selectedMonths" :items="months" label="Meses" multiple
                    :menu-props="{ closeOnContentClick: false }" item-text="monthName" item-value="month"
                    @change="updateSelectedMonths"></v-select>
            </v-col>
            <v-col cols="12" sm="6" md="2"
                v-show="tipoReporte != 1 && tipoReporte != 2 && tipoReporte && tipoReporte != 10 && tipoReporte != 17 && tipoReporte != 19 && tipoReporte != 11 && tipoReporte != 9">
                <v-select dense clearable v-model="periodoReporte" :items="periodos" label="Periodo" item-text="period"
                    item-value="id" :disabled="periodosResponse" :loading="periodosResponseloading"></v-select>
            </v-col>
            <v-col cols="12" sm="6" md="2" v-show="tipoReporte === 11 || tipoReporte === 9 || tipoReporte == 10">
                <v-select dense clearable v-model="periodoReporte" :items="periodos" label="Periodo" item-text="period"
                    item-value="id" multiple :disabled="periodosResponse" :loading="periodosResponseloading"></v-select>
            </v-col>
            <v-col cols="12" sm="6" md="2"
                v-show="tipoReporte != 1 && tipoReporte != 2 && tipoReporte != 11 && tipoReporte != 9 && tipoReporte != 19 && tipoReporte != 10 && tipoReporte && tipoReporte != 16 && tipoReporte != 17 && tipoReporte != 7 && tipoReporte != 10">
                <v-menu v-model="menu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
                    offset-y min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="dateRange" label="Rango" prepend-icon="mdi-calendar" readonly
                            v-bind="attrs" v-on="on"></v-text-field>
                    </template>
                    <v-date-picker v-model="dateRange" @change="menu = false" range locale="mx-es" :max="maxDate"
                        min="2020-01" type="month" scrollable></v-date-picker>
                </v-menu>
            </v-col>

            <v-col cols="12" sm="3" md="2" class="ma-0">
                <v-btn @click="Reportes()" :loading="loadingAsignacion" dark small :disabled="!tipoReporte"
                    class="ma-0 font-weight-bold rounded-lg" color="primary">
                    <v-icon left small>mdi-magnify</v-icon>
                    {{ loadingAsignacion ? 'Cargando...' : 'Filtrar' }}
                </v-btn>
            </v-col>
            <v-col cols="12">
                <v-alert v-if="loadingAsignacion && tipoReporte !== 10" type="info" class="mt-4">
                    El tiempo promedio estimado para la carga y procesamiento de la información es de aproximadamente 4
                    minutos.
                </v-alert>
                <v-alert v-if="loadingAsignacion && tipoReporte === 10" type="info" class="mt-4">
                    El tiempo promedio estimado para la carga y procesamiento de la información es de aproximadamente 14
                    minutos.
                    Solo considera periodos cerrados de los meses seleccionados.
                </v-alert>
            </v-col>
            <v-col cols="12" sm="3" md="2" class="ma-0" v-if="habilitarexcel && itemsreportes.length > 0">
                <vue-json-to-csv :json-data="itemsreportes" :labels="headers_report_to_export" :csv-title="title"
                    class="ma-0">
                    <v-btn color="green darken-2" dark small class="ma-0 font-weight-bold rounded-lg"
                        @click="exportToCsv()">
                        <v-icon left small>mdi-microsoft-excel</v-icon>
                        Exportar
                    </v-btn>
                </vue-json-to-csv>
            </v-col>
        </v-row>
        <v-data-table dense :headers="headersreportes" :items="itemsreportes"
            no-data-text="Ningún elemento coincide con el criterio de búsqueda" class="ma-3" :footer-props="{
                    'items-per-page-text': 'Elementos por página',
                }" :header-props="{ 'short-by-text': 'Ordenar por', }" loading-text="Cargando contenido..."
            :loading="loadingAsignacion">
            <template v-slot:item.total="{ item }">
                {{
                    parseFloat(item.total) == 0
                        ? ""
                        : formatMXN(parseFloat(item.total))
                }}
            </template>
        </v-data-table>
    </v-card>
</template>

<script>
import axios from "axios";
import VueJsonToCsv from 'vue-json-to-csv'

export default {
    name: "muestra-reporte",
    components: { VueJsonToCsv },

    data() {
        return {

            headers16: [
                {
                    text: "Tipo",
                    value: "type",
                    class: "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                },
                {
                    text: "Concepto",
                    value: "key",
                    class: "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                },
                {
                    text: "Descripción",
                    value: "descr",
                    class: "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                },
                {
                    text: "Cantidad",
                    value: "counts",
                    class: "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                },
                {
                    text: "Monto",
                    value: "total",
                    class: "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                }
            ],
            headers18: [
                {
                    text: "Tipo",
                    value: "type",
                    class: "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                },
                {
                    text: "Concepto",
                    value: "key",
                    class: "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                },
                {
                    text: "Descripción",
                    value: "descr",
                    class: "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                },
                {
                    text: "Cantidad",
                    value: "counts",
                    class: "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                },
                {
                    text: "Monto",
                    value: "total",
                    class: "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                }
            ],
            headers7: [
                {
                    text: "Folio",
                    value: "folio",
                    class: "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                },
                {
                    text: "Nombre",
                    value: "name",
                    class: "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                },
                {
                    text: "Grupo Pago",
                    value: "gPago",
                    class: "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                },
                {
                    text: "Periodo",
                    value: "period",
                    class: "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                },
                {
                    text: "Monto",
                    value: "total",
                    class: "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                }
            ],
            headers10: [
                {
                    text: "Folio",
                    value: "clave",
                    class: "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                },
                {
                    text: "Nombre",
                    value: "name",
                    class: "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                },
                {
                    text: "Grupo Pago",
                    value: "gPago",
                    class: "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                },
                {
                    text: "Departamento",
                    value: "depto",
                    class: "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                },
                {
                    text: "Año",
                    value: "year",
                    class: "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                },
                {
                    text: "Periodo",
                    value: "periodo",
                    class: "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                },
                {
                    text: "Concepto",
                    value: "concepto",
                    class: "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                },
                {
                    text: "Monto",
                    value: "monto",
                    class: "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                }
            ],
            headers9: [
                {
                    text: "Folio",
                    value: "clave",
                    class: "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                },
                {
                    text: "Nombre",
                    value: "name",
                    class: "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                },
                {
                    text: "Año",
                    value: "year",
                    class: "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                },
                {
                    text: "Concepto",
                    value: "concepto",
                    class: "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                },
                {
                    text: "Monto",
                    value: "monto",
                    class: "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                }
            ],
            headers1: [],
            headersGeneral: [
                {
                    text: "Folio",
                    value: "EMPLID",
                    class: "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                },
                {
                    text: "Nombre",
                    value: "NAME",
                    class: "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                },
                {
                    text: "Grupo Pago",
                    value: "DESCRSHORT",
                    class: "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                },
                {
                    text: "Departamento",
                    value: "DESCR1",
                    class: "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                },
                {
                    text: "Posicion",
                    value: "DESCR",
                    class: "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                },
                {
                    text: "Distrito",
                    value: "district",
                    class: "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                },
                {
                    text: "Division",
                    value: "division",
                    class: "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                },
                {
                    text: "Fecha Contratación",
                    value: "EMP_FECING",
                    class: "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                },
                {
                    text: "Posicion",
                    value: "DESCR",
                    class: "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                },
                {
                    text: "Id Dpto",
                    value: "DEPTID",
                    class: "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                },
                {
                    text: "Grupo",
                    value: "GROUP",
                    class: "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                },
                {
                    text: "Registro Patronal",
                    value: "RegistroPatronal",
                    class: "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                },
                {
                    text: "IMSS",
                    value: "SSN",
                    class: "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                },
                {
                    text: "RFC",
                    value: "NATIONAL_ID_FORMAT",
                    class: "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                },
                {
                    text: "CURP",
                    value: "NATIONAL_ID_MSK",
                    class: "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                },
                {
                    text: "Banco",
                    value: "BANK_NAME",
                    class: "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                },
                {
                    text: "Tarjeta",
                    value: "CARD",
                    class: "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                },
                {
                    text: "Nº Cuenta",
                    value: "ACCOUNT",
                    class: "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                },
                {
                    text: "Clabe",
                    value: "CLABE",
                    class: "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                },
                {
                    text: "Correo",
                    value: "EMAIL",
                    class: "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                },
                {
                    text: "Domicilio",
                    value: "DOMICILIO",
                    class: "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                },
                {
                    text: "Genero",
                    value: "SEX",
                    class: "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                },
                {
                    text: "Fecha Nacimiento",
                    value: "BORN",
                    class: "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                },
                {
                    text: "Telefono",
                    value: "PHONE",
                    class: "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                },
                {
                    text: "Estudios",
                    value: " ESTUDIOS",
                    class: "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                }
            ],
            headersMoney: [
                {
                    text: "Folio",
                    value: "EMPLID",
                    class: "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                },
                {
                    text: "Nombre",
                    value: "NAME",
                    class: "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                },
                {
                    text: "Grupo Pago",
                    value: "DESCRSHORT",
                    class: "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                },
                {
                    text: "Departamento",
                    value: "DESCR1",
                    class: "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                },
                {
                    text: "Posicion",
                    value: "DESCR",
                    class: "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                },
                {
                    text: "Distrito",
                    value: "district",
                    class: "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                },
                {
                    text: "Division",
                    value: "division",
                    class: "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                },
                {
                    text: "Fecha Contratación",
                    value: "EMP_FECING",
                    class: "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                },
                {
                    text: "Posicion",
                    value: "DESCR",
                    class: "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                },
                {
                    text: "Id Dpto",
                    value: "DEPTID",
                    class: "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                },
                {
                    text: "Grupo",
                    value: "GROUP",
                    class: "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                },
                {
                    text: "Registro Patronal",
                    value: "RegistroPatronal",
                    class: "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                },
                {
                    text: "IMSS",
                    value: "SSN",
                    class: "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                },
                {
                    text: "RFC",
                    value: "NATIONAL_ID_FORMAT",
                    class: "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                },
                {
                    text: "CURP",
                    value: "NATIONAL_ID_MSK",
                    class: "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                },
                {
                    text: "Sal/Día",
                    value: "DAILY_RT",
                    class: "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                },
                {
                    text: "SDI Tot",
                    value: "GPMX_SDI_TOT",
                    class: "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                },
                {
                    text: "Banco",
                    value: "BANK_NAME",
                    class: "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                },
                {
                    text: "Tarjeta",
                    value: "CARD",
                    class: "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                },
                {
                    text: "Nº Cuenta",
                    value: "ACCOUNT",
                    class: "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                },
                {
                    text: "Clabe",
                    value: "CLABE",
                    class: "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                },
                {
                    text: "Correo",
                    value: "EMAIL",
                    class: "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                },
                {
                    text: "Domicilio",
                    value: "DOMICILIO",
                    class: "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                },
                {
                    text: "Genero",
                    value: "SEX",
                    class: "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                },
                {
                    text: "Fecha Nacimiento",
                    value: "BORN",
                    class: "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                },
                {
                    text: "Telefono",
                    value: "PHONE",
                    class: "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                },
                {
                    text: "Estudios",
                    value: " ESTUDIOS",
                    class: "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                }
            ],
            headers17: [


            ],
            headers2: [
                {
                    text: "# Zentric",
                    value: "id",
                    class: "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                },
                {
                    text: "# Dh",
                    value: "employee_id",
                    class: "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                },
                {
                    text: "Colaborador",
                    value: "emp_full_name",
                    class: "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                },
                {
                    text: "Nómina",
                    value: "payroll",
                    class: "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                },
                {
                    text: "Tipo de crédito",
                    value: "credit_type",
                    class: "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                },
                {
                    text: "Nº Prest",
                    value: "credit_id",
                    class: "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                },
                {
                    text: "Inicio",
                    value: "start_date",
                    class: "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                },

                {
                    text: "Monto",
                    value: "amount",
                    class: "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                },
                {
                    text: "Descuento",
                    value: "discount",
                    class: "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                },
                {
                    text: "Saldo",
                    value: "balance",
                    class: "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                }

            ],
            headers11: [
                {
                    text: "Folio",
                    value: "clave",
                    class: "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                },
                {
                    text: "Nombre",
                    value: "name",
                    class: "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                },
                {
                    text: "Grupo Pago",
                    value: "gPago",
                    class: "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                },
                {
                    text: "No. IMSS",
                    value: "numIMSS",
                    class: "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                }, {
                    text: "Registro Patronal",
                    value: "employerRegistry",
                    class: "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                },
                {
                    text: "Año",
                    value: "year",
                    class: "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                },
                {
                    text: "Concepto",
                    value: "concepto",
                    class: "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                },
                {
                    text: "Monto",
                    value: "monto",
                    class: "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                }
            ],
            headers19: [
                {
                    text: "Folio",
                    value: "EMPLID",
                    class: "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                },
                {
                    text: "Nombre",
                    value: "NAME",
                    class: "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                },
                {
                    text: "Grupo Pago",
                    value: "DESCRSHORT",
                    class: "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                },
                {
                    text: "Ingreso",
                    value: "HIRE_DT",
                    class: "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                }, {
                    text: "Salario Diario",
                    value: "DAILY_RT",
                    class: "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                },
                {
                    text: "Aguinaldo",
                    value: "christmas_days",
                    class: "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                },
                {
                    text: "Prima Vacacional",
                    value: "vacation_allowances",
                    class: "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                },
                {
                    text: "Vacaciones",
                    value: "vacation_days",
                    class: "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                },
                {
                    text: "FI",
                    value: "integrating_factor",
                    class: "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                }
            ],
            //
            headers_report_to_export11: {
                clave: { title: "Folio" },
                name: { title: "Nombre" },
                gPago: { title: "Grupo Pago" },
                numIMSS: { title: "No. IMSS" },
                employerRegistry: { title: "Registro Patronal" },
                year: { title: "Año" },
                concepto: { title: "Concepto" },
                monto: { title: "Monto" },
            },
            headers_report_to_export2: {
                id: { title: "# Zentric" },
                employee_id: { title: "# Dh" },
                emp_full_name: { title: "Colaborador" },
                payroll: { title: "Grupo Pago" },
                credit_type: { title: "Tipo de crédito" },
                credit_id: { title: "Nº Prest" },
                start_date: { title: "Fecha inicio" },
                amount: { title: "Monto" },
                discount: { title: "Descuento" },
                balance: { title: "Saldo" },
            },
            headers_report_to_export1: {},
            headers_report_to_exportMoney: {
                EMPLID: { title: "Folio" },
                NAME: { title: "Nombre" },
                DESCRSHORT: { title: "Grupo Pago" },
                DESCR1: { title: "Departamento" },
                DESCR: { title: "Posicion" },
                district: { title: "Distrito" },
                division: { title: "Division" },
                EMP_FECING: { title: "Fecha Contratación" },
                DEPTID: { title: "Id Dpto" },
                GROUP: { title: "Grupo" },
                RegistroPatronal: { title: "Registro Patronal" },
                SSN: { title: "IMSS" },
                NATIONAL_ID_FORMAT: { title: "RFC" },
                NATIONAL_ID_MSK: { title: "CURP" },
                DAILY_RT: { title: "Sal/Día" },
                GPMX_SDI_TOT: { title: "SDI Tot" },
                BANK_NAME: { title: "Banco" },
                CARD: { title: "Tarjeta" },
                ACCOUNT: { title: "Nº Cuenta" },
                CLABE: { title: "Clabe" },
                EMAIL: { title: "Correo" },
                DOMICILIO: { title: "Domicilio" },
                SEX: { title: "Genero" },
                BORN: { title: "Fecha Nacimiento" },
                PHONE: { title: "Telefono" },
                ESTUDIOS: { title: "Estudios" },
            },
            headers_report_to_exportGeneral: {
                EMPLID: { title: "Folio" },
                NAME: { title: "Nombre" },
                DESCRSHORT: { title: "Grupo Pago" },
                DESCR1: { title: "Departamento" },
                DESCR: { title: "Posicion" },
                district: { title: "Distrito" },
                division: { title: "Division" },
                EMP_FECING: { title: "Fecha Contratación" },
                DEPTID: { title: "Id Dpto" },
                GROUP: { title: "Grupo" },
                RegistroPatronal: { title: "Registro Patronal" },
                SSN: { title: "IMSS" },
                NATIONAL_ID_FORMAT: { title: "RFC" },
                NATIONAL_ID_MSK: { title: "CURP" },
                BANK_NAME: { title: "Banco" },
                CARD: { title: "Tarjeta" },
                ACCOUNT: { title: "Nº Cuenta" },
                CLABE: { title: "Clabe" },
                EMAIL: { title: "Correo" },
                DOMICILIO: { title: "Domicilio" },
                SEX: { title: "Genero" },
                BORN: { title: "Fecha Nacimiento" },
                PHONE: { title: "Telefono" },
                ESTUDIOS: { title: "Estudios" },
            },
            headers_report_to_export10: {
                clave: { title: "Folio" },
                name: { title: "Nombre" },
                gPago: { title: "Grupo Pago" },
                depto: { title: "Departamento" },
                year: { title: "Año" },
                periodo: { title: "Periodo" },
                concepto: { title: "Concepto" },
                monto: { title: "Monto" },
            },
            headers_report_to_export9: {
                clave: { title: "Folio" },
                name: { title: "Nombre" },
                year: { title: "Año" },
                concepto: { title: "Concepto" },
                monto: { title: "Monto" },
            },
            headers_report_to_export16: {
                type: { title: "Tipo" },
                key: { title: "Concepto" },
                descr: { title: "Descripción" },
                counts: { title: "Cantidad" },
                total: { title: "Monto" },
            },
            headers_report_to_export18: {
                type: { title: "Tipo" },
                key: { title: "Concepto" },
                descr: { title: "Descripción" },
                counts: { title: "Cantidad" },
                total: { title: "Monto" },
            },
            headers_report_to_export7: {
                folio: { title: "Folio" },
                name: { title: "Nombre" },
                gPago: { title: "Grupo Pago" },
                period: { title: "Periodo" },
                total: { title: "Monto" },
            },
            headers_report_to_export19: {
                EMPLID: { title: "Folio" },
                NAME: { title: "Nombre" },
                DESCRSHORT: { title: "Grupo Pago" },
                HIRE_DT: { title: "Ingreso" },
                DAILY_RT: { title: "Salario Diario" },
                christmas_days: { title: "Aguinaldo" },
                vacation_allowances: { title: "Prima Vacacional" },
                vacation_days: { title: "Vacaciones" },
                integrating_factor: { title: "FI" },
            },
            headers_report_to_export: {},
            title: 'Reporte de Zentric',
            //
            headersreportes: [],
            itemsreportes: [],
            //
            listaReportes: [],
            listaReportesGeneral: [
                { id: 1, nombre: 'Resumen Empleados' },
                // { id: 3, nombre: 'Bajas empleados' },
                // { id: 4, nombre: 'Cifras quincenales' },
                // { id: 5, nombre: 'Cifras semanales' },
                //{ id: 6, nombre: 'Cifras totales' },
                // { id: 8, nombre: 'Registro de timbrados' },
                // { id: 12, nombre: 'Caratura resumen polizas' },
                //  { id: 13, nombre: 'Intereses caja' },
                // { id: 14, nombre: 'Movimientos caja' },
                // { id: 15, nombre: 'Revision nominas' },
                //  { id: 17, nombre: 'Resumen Empleados' },
                //{ id: 18, nombre: 'Resumen conceptos' },
            ],
            listaReportesComReclu: [
                { id: 1, nombre: 'Resumen Empleados' },
                { id: 19, nombre: 'Beneficios Colaboradores' },
            ],
            listaReportesNom: [
                { id: 16, nombre: 'Resumen conceptos' },
                { id: 1, nombre: 'Resumen Empleados' },
                { id: 2, nombre: 'Saldos prestamos' },
                { id: 7, nombre: 'Vales de despensa' },
                { id: 9, nombre: 'Acumulado ISR' },
                { id: 10, nombre: 'Pago bonos' },
                { id: 11, nombre: 'Auditores IMSS Infonavit' },
                { id: 19, nombre: 'Beneficios Colaboradores' },
            ],
            tipoReporte: null,
            //
            listaEstilo: [{ id: 1, nombre: 'Acomulado', },
            { id: 2, nombre: 'Individual' }],
            estiloReporte: null,
            //
            listaGP: [{ id: 5704, nombre: 'Semanal' },
            { id: 5709, nombre: 'Quincenal' },
            { id: 8240, nombre: 'Comisionistas' }],
            gPReporte: null,
            //
            periodosResponse: true,
            periodosResponseloading: false,
            periodos: [],
            periodoReporte: [],
            //
            yearReporte: new Date().getFullYear(), // Inicializa con el año actual
            //
            habilitarexcel: false,
            loadingAsignacion: false,
            //fecha
            menu: false,
            dateRange: [],
            maxDate: '',
            minDate: '',
            selectedMonths: [],
            months: [
                { month: 1, monthName: 'Enero' },
                { month: 2, monthName: 'Febrero' },
                { month: 3, monthName: 'Marzo' },
                { month: 4, monthName: 'Abril' },
                { month: 5, monthName: 'Mayo' },
                { month: 6, monthName: 'Junio' },
                { month: 7, monthName: 'Julio' },
                { month: 8, monthName: 'Agosto' },
                { month: 9, monthName: 'Septiembre' },
                { month: 10, monthName: 'Octubre' },
                { month: 11, monthName: 'Noviembre' },
                { month: 12, monthName: 'Diciembre' },
            ],
            selectedMonthsFormatted: [],
            // extras
            peticionesConceptos: 0,
            tokenZentric: null,
            resultArrays: [],

        };
    },
    computed: {
        yearsList() {
            const currentYear = new Date().getFullYear();
            const startYear = 2023;
            const years = [];
            for (let year = currentYear; year >= startYear; year--) {
                years.push({ id: year, year: year });
            }
            return years;
        },
    },
    async mounted() {
        const today = new Date();
        this.maxDate = today.toISOString().substr(0, 7) // Formato YYYY-MM
        this.getPuesto();
    },
    methods: {
        splitArray(array, chunkSize) {
            const chunks = [];
            for (let i = 0; i < array.length; i += chunkSize) {
                chunks.push(array.slice(i, i + chunkSize));
            }
            return chunks;
        },
        async getReportes7() {
            this.loadingAsignacion = true;
            this.habilitarexcel = false;
            let params;


            params = {
                Opcion: 0,
                grupoPago: this.gPReporte,
                periodo: [this.periodoReporte],
                years: [this.yearReporte],
            };

            try {
                const response = await axios.post(`${this.$store.getters.getHostNode}/api/getReporte`, params, {
                    headers: {
                        Authorization: localStorage.getItem("token")
                    },
                    timeout: 0
                });

                if (response.data.status == "OK") {
                    this.tokenZentric = response.data.token;
                    this.typePeriodo = response.data.typePeriodo;

                    if (this.gPReporte === 5709) {
                        this.gPReporte = [5709, 5710, 5711, 5712, 5713];
                    } else if (this.gPReporte === 5704) {
                        this.gPReporte = [5704, 5705, 5706, 5707, 5708];
                    } else if (this.gPReporte === 8240) {
                        this.gPReporte = [8240, 8241, 8242, 8243, 8244];
                    }

                    let promises = [];

                    for (let i = 0; i < 5; i++) {
                        let params = {
                            timeout: 0,
                            payrolls: [this.gPReporte[i]],
                            years: [this.yearReporte],
                            periods: [this.periodoReporte],
                            is_calculation: this.typePeriodo
                        };

                        let promise = axios.post(`https://api.zentric.mx/reports/scenario-report/data/`, params, {
                            headers: {
                                Authorization: this.tokenZentric
                            },
                            timeout: 0
                        })
                            .then((response) => {
                                if (response.data.status === "success") {
                                    if (response.data.data[0].data && response.data.data[0].data.length > 0) {
                                        this.resultArrays.push(response.data.data[0]);

                                    }

                                }
                            })
                            .catch((error) => {
                                console.error(error);
                            });

                        promises.push(promise);
                    }

                    await Promise.all(promises);
                    // console.log(this.resultArrays);
                    params = {
                        Opcion: 6,
                        arrayConceptos: this.resultArrays
                    }
                }
            } catch (error) {
                console.error(error);
            }
            const chunkSize = 100; // Ajusta el tamaño del fragmento según sea necesario
            const chunks = this.splitArray(this.resultArrays[0].data, chunkSize);

            // Crear un objeto params con múltiples propiedades para los fragmentos
            const promises = chunks.map((chunk, index) => {
                const params = {
                    Opcion: 7,
                    chunk: chunk,
                    title: this.resultArrays[0].headers,
                    index: index
                };

                return axios.post(`${this.$store.getters.getHostNode}/api/getReporte`, params, {
                    headers: {
                        Authorization: localStorage.getItem("token")
                    },
                    timeout: 0
                });
            });

            Promise.all(promises)
                .then((responses) => {
                    const allData = responses.flatMap(response => response.data.dataReporte);

                    this.itemsreportes = allData;

                    this.headersreportes = this.headers7;
                    this.headers_report_to_export = this.headers_report_to_export7;

                    //return Object.values(combinedData);
                })
                .catch((error) => {
                    console.error('Error sending data:', error);
                })
                .finally(() => {
                    this.habilitarexcel = true;
                    this.loadingAsignacion = false;
                });
        },
        async getReportes11() {
            this.loadingAsignacion = true;
            this.habilitarexcel = false;
            let params;


            params = {
                Opcion: 0,
                grupoPago: this.gPReporte,
                years: [this.yearReporte],
            };

            try {
                const response = await axios.post(`${this.$store.getters.getHostNode}/api/getReporte`, params, {
                    headers: {
                        Authorization: localStorage.getItem("token")
                    },
                    timeout: 0
                });

                if (response.data.status == "OK") {
                    this.tokenZentric = response.data.token;
                    this.typePeriodo = response.data.typePeriodo;

                    if (this.gPReporte === 5709) {
                        this.gPReporte = [5709, 5710, 5711, 5712, 5713];
                    } else if (this.gPReporte === 5704) {
                        this.gPReporte = [5704, 5705, 5706, 5707, 5708];
                    } else if (this.gPReporte === 8240) {
                        this.gPReporte = [8240, 8241, 8242, 8243, 8244];
                    }

                    let promises = [];

                    let params = {
                        timeout: 0,
                        payrolls: this.gPReporte,
                        periods: this.periodoReporte,
                        years: [this.yearReporte],
                        is_calculation: 0
                    };

                    let promise = axios.post(`https://api.zentric.mx/reports/scenario-report/data/`, params, {
                        headers: {
                            Authorization: this.tokenZentric
                        },
                        timeout: 0
                    })
                        .then((response) => {
                            if (response.data.status === "success") {
                                if (response.data.data[0].data && response.data.data[0].data.length > 0) {
                                    this.resultArrays.push(response.data.data[0]);

                                }

                            }
                        })
                        .catch((error) => {
                            console.error(error);
                        });

                    promises.push(promise);


                    await Promise.all(promises);
                    // console.log(this.resultArrays);
                    params = {
                        Opcion: 11,
                        arrayConceptos: this.resultArrays
                    }
                }
            } catch (error) {
                console.error(error);
            }
            const chunkSize = 19900; // Ajusta el tamaño del fragmento según sea necesario
            const chunks = this.splitArray(this.resultArrays[0].data, chunkSize);

            // Crear un objeto params con múltiples propiedades para los fragmentos
            const promises = chunks.map((chunk, index) => {
                const params = {
                    Opcion: 11,
                    chunk: chunk,
                    title: this.resultArrays[0].headers,
                    index: index
                };

                return axios.post(`${this.$store.getters.getHostNode}/api/getReporte`, params, {
                    headers: {
                        Authorization: localStorage.getItem("token")
                    },
                    timeout: 0
                });
            });

            Promise.all(promises)
                .then((responses) => {
                    const allData = responses.flatMap(response => response.data.dataReporte);

                    this.itemsreportes = allData;

                    this.headersreportes = this.headers11;
                    this.headers_report_to_export = this.headers_report_to_export11;

                    //return Object.values(combinedData);
                })
                .catch((error) => {
                    console.error('Error sending data:', error);
                })
                .finally(() => {
                    this.habilitarexcel = true;
                    this.loadingAsignacion = false;
                });
        },
        async getReportes10() {
            this.loadingAsignacion = true;
            this.habilitarexcel = false;
            let params;


            params = {
                Opcion: 0,
                grupoPago: this.gPReporte,
                years: [this.yearReporte],
            };

            try {
                const response = await axios.post(`${this.$store.getters.getHostNode}/api/getReporte`, params, {
                    headers: {
                        Authorization: localStorage.getItem("token")
                    },
                    timeout: 0
                });

                if (response.data.status == "OK") {
                    this.tokenZentric = response.data.token;
                    this.typePeriodo = response.data.typePeriodo;

                    if (this.gPReporte === 5709) {
                        this.gPReporte = [5709, 5710, 5711, 5712, 5713];
                    } else if (this.gPReporte === 5704) {
                        this.gPReporte = [5704, 5705, 5706, 5707, 5708];
                    } else if (this.gPReporte === 8240) {
                        this.gPReporte = [8240, 8241, 8242, 8243, 8244];
                    }

                    let promises = [];

                    let params = {
                        timeout: 0,
                        payrolls: this.gPReporte,
                        periods: this.periodoReporte,
                        years: [this.yearReporte],
                        is_calculation: 0
                    };

                    let promise = axios.post(`https://api.zentric.mx/reports/scenario-report/data/`, params, {
                        headers: {
                            Authorization: this.tokenZentric
                        },
                        timeout: 0
                    })
                        .then((response) => {
                            if (response.data.status === "success") {
                                if (response.data.data[0].data && response.data.data[0].data.length > 0) {
                                    this.resultArrays.push(response.data.data[0]);

                                }

                            }
                        })
                        .catch((error) => {
                            console.error(error);
                        });

                    promises.push(promise);


                    await Promise.all(promises);
                    // console.log(this.resultArrays);
                    params = {
                        Opcion: 11,
                        arrayConceptos: this.resultArrays
                    }
                }
            } catch (error) {
                console.error(error);
            }
            const chunkSize = 19900; // Ajusta el tamaño del fragmento según sea necesario
            const chunks = this.splitArray(this.resultArrays[0].data, chunkSize);

            // Crear un objeto params con múltiples propiedades para los fragmentos
            const promises = chunks.map((chunk, index) => {
                const params = {
                    Opcion: 10,
                    chunk: chunk,
                    title: this.resultArrays[0].headers,
                    index: index
                };

                return axios.post(`${this.$store.getters.getHostNode}/api/getReporte`, params, {
                    headers: {
                        Authorization: localStorage.getItem("token")
                    },
                    timeout: 0
                });
            });

            Promise.all(promises)
                .then((responses) => {
                    const allData = responses.flatMap(response => response.data.dataReporte);

                    this.itemsreportes = allData;

                    this.headersreportes = this.headers10;
                    this.headers_report_to_export = this.headers_report_to_export10;

                    //return Object.values(combinedData);
                })
                .catch((error) => {
                    console.error('Error sending data:', error);
                })
                .finally(() => {
                    this.habilitarexcel = true;
                    this.loadingAsignacion = false;
                });
        },
        async getReportes9() {
            this.loadingAsignacion = true;
            this.habilitarexcel = false;
            let params;


            params = {
                Opcion: 0,
                grupoPago: this.gPReporte,
                years: [this.yearReporte],
            };

            try {
                const response = await axios.post(`${this.$store.getters.getHostNode}/api/getReporte`, params, {
                    headers: {
                        Authorization: localStorage.getItem("token")
                    },
                    timeout: 0
                });

                if (response.data.status == "OK") {
                    this.tokenZentric = response.data.token;
                    this.typePeriodo = response.data.typePeriodo;

                    if (this.gPReporte === 5709) {
                        this.gPReporte = [5709, 5710, 5711, 5712, 5713];
                    } else if (this.gPReporte === 5704) {
                        this.gPReporte = [5704, 5705, 5706, 5707, 5708];
                    } else if (this.gPReporte === 8240) {
                        this.gPReporte = [8240, 8241, 8242, 8243, 8244];
                    }

                    let promises = [];

                    let params = {
                        timeout: 0,
                        payrolls: this.gPReporte,
                        periods: this.periodoReporte,
                        years: [this.yearReporte],
                        is_calculation: 0
                    };

                    let promise = axios.post(`https://api.zentric.mx/reports/scenario-report/data/`, params, {
                        headers: {
                            Authorization: this.tokenZentric
                        },
                        timeout: 0
                    })
                        .then((response) => {
                            if (response.data.status === "success") {
                                if (response.data.data[0].data && response.data.data[0].data.length > 0) {
                                    this.resultArrays.push(response.data.data[0]);

                                }

                            }
                        })
                        .catch((error) => {
                            console.error(error);
                        });

                    promises.push(promise);


                    await Promise.all(promises);
                    // console.log(this.resultArrays);
                    params = {
                        Opcion: 9,
                        arrayConceptos: this.resultArrays
                    }
                }
            } catch (error) {
                console.error(error);
            }
            const chunkSize = 20000; // Ajusta el tamaño del fragmento según sea necesario
            const chunks = this.splitArray(this.resultArrays[0].data, chunkSize);

            // Crear un objeto params con múltiples propiedades para los fragmentos
            const promises = chunks.map((chunk, index) => {
                const params = {
                    Opcion: 9,
                    chunk: chunk,
                    title: this.resultArrays[0].headers,
                    index: index
                };

                return axios.post(`${this.$store.getters.getHostNode}/api/getReporte`, params, {
                    headers: {
                        Authorization: localStorage.getItem("token")
                    },
                    timeout: 0
                });
            });

            Promise.all(promises)
                .then((responses) => {
                    const allData = responses.flatMap(response => response.data.dataReporte);

                    this.itemsreportes = allData;

                    this.headersreportes = this.headers9;
                    this.headers_report_to_export = this.headers_report_to_export9;

                    //return Object.values(combinedData);
                })
                .catch((error) => {
                    console.error('Error sending data:', error);
                })
                .finally(() => {
                    this.habilitarexcel = true;
                    this.loadingAsignacion = false;
                });
        },
        async getReportes16() {
            this.loadingAsignacion = true;
            this.habilitarexcel = false;
            let params;


            params = {
                Opcion: 0,
                grupoPago: this.gPReporte,
                periodo: [this.periodoReporte],
                years: [this.yearReporte],
            };

            try {
                const response = await axios.post(`${this.$store.getters.getHostNode}/api/getReporte`, params, {
                    headers: {
                        Authorization: localStorage.getItem("token")
                    },
                    timeout: 0
                });

                if (response.data.status == "OK") {
                    this.tokenZentric = response.data.token;
                    this.typePeriodo = response.data.typePeriodo;

                    if (this.gPReporte === 5709) {
                        this.gPReporte = [5709, 5710, 5711, 5712, 5713];
                    } else if (this.gPReporte === 5704) {
                        this.gPReporte = [5704, 5705, 5706, 5707, 5708];
                    } else if (this.gPReporte === 8240) {
                        this.gPReporte = [8240, 8241, 8242, 8243, 8244];
                    }

                    let promises = [];

                    for (let i = 0; i < 5; i++) {
                        let params = {
                            timeout: 0,
                            payrolls: [this.gPReporte[i]],
                            years: [this.yearReporte],
                            periods: [this.periodoReporte],
                            is_calculation: this.typePeriodo
                        };

                        let promise = axios.post(`https://api.zentric.mx/reports/scenario-report/data/`, params, {
                            headers: {
                                Authorization: this.tokenZentric
                            },
                            timeout: 0
                        })
                            .then((response) => {
                                if (response.data.status === "success") {
                                    if (response.data.data[0].data && response.data.data[0].data.length > 0) {
                                        this.resultArrays.push(response.data.data[0]);

                                    }

                                }
                            })
                            .catch((error) => {
                                console.error(error);
                            });

                        promises.push(promise);
                    }

                    await Promise.all(promises);
                    // console.log(this.resultArrays);
                    params = {
                        Opcion: 16,
                        arrayConceptos: this.resultArrays
                    }
                }
            } catch (error) {
                console.error(error);
            }
            const chunkSize = 100; // Ajusta el tamaño del fragmento según sea necesario
            const chunks = this.splitArray(this.resultArrays[0].data, chunkSize);

            // Crear un objeto params con múltiples propiedades para los fragmentos
            const promises = chunks.map((chunk, index) => {
                const params = {
                    Opcion: 16,
                    chunk: chunk,
                    title: this.resultArrays[0].headers,
                    index: index
                };

                return axios.post(`${this.$store.getters.getHostNode}/api/getReporte`, params, {
                    headers: {
                        Authorization: localStorage.getItem("token")
                    },
                    timeout: 0
                });
            });

            Promise.all(promises)
                .then((responses) => {
                    const allData = responses.flatMap(response => response.data.dataReporte);
                    let combinedData = {};
                    allData.forEach(item => {
                        if (combinedData[item.key]) {
                            combinedData[item.key].total += item.total;
                            combinedData[item.key].counts += item.counts;
                        } else {
                            combinedData[item.key] = { ...item };
                        }
                    });

                    // Convertir el objeto combinado en un array si es necesario
                    const recomposedArray = Object.values(combinedData);
                    // Convertir el objeto combinado en un array
                    this.itemsreportes = recomposedArray;

                    this.headersreportes = this.headers16;
                    this.headers_report_to_export = this.headers_report_to_export16;

                    //return Object.values(combinedData);
                })
                .catch((error) => {
                    console.error('Error sending data:', error);
                })
                .finally(() => {
                    this.habilitarexcel = true;
                    this.loadingAsignacion = false;
                });
        },
        async getReportes2() {
            this.loadingAsignacion = true;
            this.habilitarexcel = false;
            let params;


            params = {
                Opcion: 0,
                grupoPago: this.gPReporte,
            };

            try {
                const response = await axios.post(`${this.$store.getters.getHostNode}/api/getReporte`, params, {
                    headers: {
                        Authorization: localStorage.getItem("token")
                    },
                    timeout: 0
                });

                if (response.data.status == "OK") {
                    this.tokenZentric = response.data.token;
                    this.typePeriodo = response.data.typePeriodo;

                    this.gPReporte = [5704, 5705, 5706, 5707, 5708, 5709, 5710, 5711, 5712, 5713, 8240, 8241, 8242, 8243, 8244];

                    let promises = [];

                    let params = {
                        timeout: 0,
                        payrolls: this.gPReporte,
                    };

                    let promise = axios.post(`https://api.zentric.mx/reports/credits/filter/`, params, {
                        headers: {
                            Authorization: this.tokenZentric
                        },
                        timeout: 0
                    })
                        .then((response) => {
                            if (response.data.status === "success") {
                                if (response.data.data.data && response.data.data.data.length > 0) {
                                    this.itemsreportes = response.data.data.data;
                                    this.headersreportes = this.headers2;
                                    this.headers_report_to_export = this.headers_report_to_export2;
                                }

                            }
                        })
                        .catch((error) => {
                            console.error(error);
                        })
                        .finally(() => {
                            this.habilitarexcel = true;
                            this.loadingAsignacion = false;
                        });

                    promises.push(promise);

                    await Promise.all(promises);
                    // console.log(this.resultArrays);

                }
            } catch (error) {
                console.error(error);
            }


        },
        async getReportes() {
            this.loadingAsignacion = true;
            this.habilitarexcel = false;
            let params;

            if (this.tipoReporte === 10) {
                params = {
                    Opcion: this.tipoReporte,
                    fechainicial: this.dateRange[0],
                    fechafinal: this.dateRange[1],
                    grupoPago: this.gPReporte,
                    months: this.selectedMonthsFormatted,
                    years: [this.yearReporte],
                };
            } else if (this.tipoReporte === 1) {
                params = {
                    Opcion: this.tipoReporte,
                };
            } else if (this.tipoReporte === 17) {
                params = {
                    Opcion: this.tipoReporte,
                };
            } else if (this.tipoReporte === 19) {
                params = {
                    Opcion: this.tipoReporte,
                };
            } else {
                params = {
                    Opcion: this.tipoReporte,
                    fechainicial: this.dateRange[0],
                    fechafinal: this.dateRange[1],
                    grupoPago: this.gPReporte,
                    periodo: [this.periodoReporte],
                    months: this.selectedMonthsFormatted,
                    years: [this.yearReporte],
                };
            }
            axios.post(`${this.$store.getters.getHostNode}/api/getReporte`, params, {
                headers: {
                    Authorization: localStorage.getItem("token")
                },
                timeout: 0
            })
                .then((response) => {
                    if (response.data.status == "OK") {
                        this.itemsreportes = response.data.dataReporte;
                        const numero = response.data.reporte;
                        if (numero === 2) {
                            this.headersreportes = this.headers2;
                            this.headers_report_to_export = this.headers_report_to_export2;
                        } else if (numero === 16) {
                            this.headersreportes = this.headers16;
                            this.headers_report_to_export = this.headers_report_to_export16;
                        } else if (numero === 7) {
                            this.headersreportes = this.headers7;
                            this.headers_report_to_export = this.headers_report_to_export7;
                        } else if (numero === 10) {
                            this.headersreportes = this.headers10;
                            this.headers_report_to_export = this.headers_report_to_export10;
                        } else if (numero === 1) {
                            this.headersreportes = this.headers1;
                            this.headers_report_to_export = this.headers_report_to_export1;
                        } else if (numero === 11) {
                            this.headersreportes = this.headers11;
                            this.headers_report_to_export = this.headers_report_to_export11;
                        } else if (numero === 18) {
                            this.headersreportes = this.headers18;
                            this.headers_report_to_export = this.headers_report_to_export18;
                        } else if (numero === 17) {
                            this.headersreportes = this.headers17;
                            this.headers_report_to_export = this.headers_report_to_export17;
                        } else if (numero === 19) {
                            this.headersreportes = this.headers19;
                            this.headers_report_to_export = this.headers_report_to_export19;
                        } else {
                            // Manejar el caso cuando el número no coincide con ninguna opción
                            console.error("Número de reporte no válido:", numero);
                        }
                        if (response.data.extra.length > 0) {
                            this.extras = response.data.extra;
                            //console.log(this.extras);
                        }
                    } else {
                        this.itemsreportes = [];
                    }
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    this.habilitarexcel = true;
                    this.loadingAsignacion = false;
                });
        },
        Reportes() {
            if (this.tipoReporte === 16) {
                this.getReportes16();
            } else if (this.tipoReporte === 2) {
                this.getReportes2();
            } else if (this.tipoReporte === 7) {
                this.getReportes7();
            } else if (this.tipoReporte === 10) {
                this.getReportes10();
            } else if (this.tipoReporte === 11) {
                this.getReportes11();
            } else if (this.tipoReporte === 9) {
                this.getReportes9();
            } else {
                this.getReportes();
            }
        },

        consultarDatos() {
            if (this.gPReporte !== null) {
                // Realizar la consulta con this.gPReporte como parámetro
                this.periodosResponse = true;
                this.periodosResponseloading = true;
                this.periodoReporte = null;

                let params = {
                    grupoPago: this.gPReporte,
                    Opcion: this.tipoReporte,

                };
                axios.post(`${this.$store.getters.getHostNode}/api/getPeriodosGp`, params)
                    .then((response) => {
                        if (response.data.status == "OK") {
                            this.periodos = response.data.Periodos;

                        } else {
                            this.periodos = ['Periodos no disponibles'];
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                    })
                    .finally(() => {
                        this.periodosResponse = false;
                        this.periodosResponseloading = false;
                    });
            }
        },
        exportToCsv() {
            // Exportar datos a CSV
            if (this.itemsreportes.length > 0) {
                this.$refs.exporter.exportCSV();
            } else {
                alert('No hay datos para exportar');
            }
        },
        formatMXN(cadena) {
            return cadena.toLocaleString("es-MX", {
                style: "currency",
                currency: "MXN",
            });
        },
        shouldFormat(item) {
            return item.type !== "Total conceptos";
        },
        clearSelectedMonths() {
            this.selectedMonths = [];
            this.selectedMonthsFormatted = [];
        },
        updateSelectedMonths() {
            this.selectedMonthsFormatted = this.selectedMonths.map(month => {
                return { year: this.yearReporte, month: month };
            });
        },
        async getPuesto() {

            axios.get(`${this.$store.getters.getHostNode}/api/get-puesto`, {
                headers: {
                    Authorization: localStorage.getItem("token")
                },
                timeout: 0
            })
                .then((response) => {
                    if (response.data.status == "OK") {
                        let JOBCODE = parseInt(response.data.data.JOBCODE, 10);
                        console.log(JOBCODE);
                        const jobcodesNominas = [9394, 9698, 9700, 9702, 9703, 12713, 9721, 12717]; //
                        const jobcodeComReclu = [9268, 9273, 9686, 9687, 9688];
                        if (jobcodesNominas.includes(JOBCODE)) {
                            this.listaReportes = this.listaReportesNom;
                            this.headers_report_to_export1 = this.headers_report_to_exportMoney;
                            this.headers1 = this.headersMoney;
                        } else if (jobcodeComReclu.includes(JOBCODE)) {
                            this.listaReportes = this.listaReportesComReclu;
                            this.headers_report_to_export1 = this.headers_report_to_exportMoney;
                            this.headers1 = this.headersMoney;
                        } else  {
                            this.listaReportes = this.listaReportesGeneral;
                            this.headers_report_to_export1 = this.headers_report_to_exportGeneral;
                            this.headers1 = this.headersGeneral;
                        }
                    } else {
                        this.listaReportes = [];
                    }
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    this.habilitarexcel = true;
                    this.loadingAsignacion = false;
                });
        },

    },
};
</script>
