import axios from 'axios'

import SalidaAnticipada from '../../../General/MisIncidencias/components/IncidenciaAltaSalidaAnticipada.vue'
import TiempoxTiempo from '../../../General/MisIncidencias/components/IncidenciaTiempoPorTiempo.vue'
import LlegadaTarde from '../../../General/MisIncidencias/components/IncidenciaAltaLllegadaTarde.vue'
import FlexTime from '../../../General/MisIncidencias/components/IncidenciaFlexTime.vue'
import HomeOffice from '../../../General/MisIncidencias/components/IncidenciaHomeOffice.vue'
import Teletrabajo from '../../../General/MisIncidencias/components/IncidenciaTeletrabajo.vue'
import ViernesCorto from '../../../General/MisIncidencias/components/IncidenciaViernesCorto.vue'
import Vacaciones from '../../../General/MisIncidencias/components/IncidenciaVacaciones.vue'
import PermisoEspecial from '../../../General/MisIncidencias/components/IncidenciaPermisoEspecial.vue'
import SnackBar from '../../../General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue'
import MuestraCalendario from './components/MuestraCalendario.vue'

import {
    deleteToken
} from '../../../../../store/Funciones/funciones'

export default {
    name: 'alta-incidencia',
    components: {
        SalidaAnticipada,
        LlegadaTarde,
        TiempoxTiempo,
        FlexTime,
        HomeOffice,
        Teletrabajo,
        ViernesCorto,
        Vacaciones,
        PermisoEspecial,
        SnackBar,
        MuestraCalendario
    },
    props: [],
    data() {
        return {
            search: '',
            itemsPerPage: 5,
            key: 0,
            keyLlegadaTarde: 0,
            keyTiempoxTiempo: 0,
            keyHomeOffice: 0,
            keyTeletrabajo: 0,
            keyFlexTime: 0,
            keyViernesCorto: 0,
            keyVacaciones: 0,
            keyPermisoEspecial: 0,
            loadContent: true,
            empleado: null,
            nombre: "",

            muestraSalidaAnticipada: false,
            muestraLlegadaTarde: false,
            muestraTiempoxTiempo: false,
            muestraHomeOffice: false,
            muestraTeletrabajo: false,
            muestraFlexTime: false,
            muestraViernesCorto: false,
            muestraVacaciones: false,
            muestraPermisoEspecial: false,
            muestraAdvertencia: false,
            muestraCalendario: false,

            muestraBarra: false,
            textoBarra: "",
            colorBarra: "error",

            toggle_exclusive: undefined,
            colaboradores: [],
            headers: [{
                    text: '# Empleado',
                    align: 'start',
                    value: 'EMPLID',
                },
                {
                    text: 'Nombre',
                    value: 'NAME'
                },
                {
                    text: 'Incidencias',
                    value: 'actions',
                    sortable: false,
                    align: 'center'
                },
            ],
            TiposIncidencias: [{
                    idTipo: 1,
                    desc: "Vacaciones",
                    semanal: true,
                    icono: "mdi-beach"
                },
                {
                    idTipo: 3,
                    desc: "Tiempo por tiempo",
                    semanal: true,
                    icono: "mdi-clipboard-text-clock"
                },
                {
                    idTipo: 2,
                    desc: "Salida anticipada",
                    semanal: true,
                    icono: "mdi-door-open"
                },
                {
                    idTipo: 5,
                    desc: "Llegada tarde",
                    semanal: true,
                    icono: "mdi-timer-alert-outline"
                },
                {
                    idTipo: 4,
                    desc: "Permiso especial",
                    semanal: true,
                    icono: "mdi-account-child-circle"
                },
                {
                    idTipo: 6,
                    desc: "Flex time",
                    semanal: false,
                    icono: "mdi-arrow-decision"
                },
                {
                    idTipo: 7,
                    desc: "Home Office",
                    semanal: false,
                    icono: "mdi-home"
                },
                {
                    idTipo: 11,
                    desc: "Teletrabajo",
                    semanal: false,
                    icono: "mdi-shield-home"
                },
                {
                    idTipo: 12,
                    desc: "Viernes Corto",
                    semanal: false,
                    icono: "mdi-alpha-v-circle"
                },
                {
                    idTipo: 101,
                    desc: "Calendario",
                    semanal: false,
                    icono: "mdi-calendar-month-outline"
                }
            ],
            headerProps: {
                sortByText: "Ordenar por",
            },
        }
    },
    computed: {

    },
    mounted() {
        //  window.document.title = "DH:URREA - Altas de incidencias.";
        this.getColaboradores();
    },
    methods: {
        getColaboradores() {
            let params = {
                Opcion: 11,
                token: localStorage.getItem('token')
            }
            axios
                .post(`${ this.$store.getters.getHost }/General/ws-general.php `, params)
                .then((response) => {
                    // this.loadingColabs = false;
                    if (response.data.status == "EXPSESSION") {
                        deleteToken();
                    } else {
                        var data = response.data.colaboradores;
                        this.colaboradores = data;
                        // console.log(this.colaboradores)

                    }
                }).finally(() => {
                    this.loadContent = false;
                })
        },
        mayusPrimeraLetraDeOracion(oracion) {
            let palabras = oracion.split(" ").map(palabra => {
                return palabra[0].toUpperCase() + palabra.slice(1);
            })
            return palabras.join(" ");
        },
        abreAltaIncidencia(opc, empleado, proceso, nombre, entro = false) {
            const opcOriginal = opc;
            if ((proceso < 2 || proceso > 7) && (opc == 6 || opc == 7 || opc == 11 || opc == 12) && !entro) {
                opc = 100;
            }
            this.empleado = empleado;
            this.nombre = nombre
            switch (opc) {
                case 1:
                    {
                        this.keyVacaciones++;
                        this.muestraVacaciones = true;
                        break;
                    }
                case 2:
                    {
                        this.key++;
                        this.muestraSalidaAnticipada = true;
                        break;
                    }
                case 3:
                    {
                        this.muestraTiempoxTiempo = true;
                        break;
                    }
                case 4:
                    {
                        this.keyPermisoEspecial++;
                        this.muestraPermisoEspecial = true;
                        break;
                    }
                case 5:
                    {
                        this.keyLlegadaTarde++;
                        this.muestraLlegadaTarde = true;
                        break;
                    }
                case 6:
                    {
                        this.keyFlexTime++;
                        this.muestraFlexTime = true;
                        break;
                    }
                case 7:
                    {
                        this.keyHomeOffice++;
                        this.muestraHomeOffice = true;
                        break;
                    }
                case 11:
                    {
                        this.keyTeletrabajo++;
                        this.muestraTeletrabajo = true;
                        break;
                    }
                case 12:
                    {
                        this.keyViernesCorto++;
                        this.muestraViernesCorto = true;
                        break;
                    }
                case 100:
                    {
                        this.muestraAdvertencia = true;
                        this.abreAltaIncidencia(opcOriginal, empleado, proceso, nombre, true);
                        break;
                    }
                case 101:
                    {
                        this.muestraCalendario = true;
                        break;
                    }
            }
        },

        cierraVentanaIncidencias(metodo) {
            if (this.muestraSalidaAnticipada) {
                this.muestraSalidaAnticipada = false
            } else if (this.muestraLlegadaTarde) {
                this.muestraLlegadaTarde = false
            } else if (this.muestraTiempoxTiempo) {
                this.muestraTiempoxTiempo = false
            } else if (this.muestraHomeOffice) {
                this.muestraHomeOffice = false
            } else if (this.muestraTeletrabajo) {
                this.muestraTeletrabajo = false
            } else if (this.muestraFlexTime) {
                this.muestraFlexTime = false
            } else if (this.muestraViernesCorto) {
                this.muestraViernesCorto = false
            } else if (this.muestraVacaciones) {
                this.muestraVacaciones = false
            } else if (this.muestraPermisoEspecial) {
                this.muestraPermisoEspecial = false
            }

            if (metodo == 1) {
                this.textoBarra = "Incidencia registrada con éxito. :)"
                this.colorBarra = "success"
                this.muestraBarra = true
            }
        }
    }
}