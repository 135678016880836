import OfertasEconomicasPendiestes from './components/OfertasEconomicasPendientes.vue'
import OfertasCreadasPorMes from './components/OfertasCreadasPorMes.vue'
import OfertasPorEstatus from './components/OfertasPorEstatus.vue'
export default {
    name: 'admin-ofertas-economicas',
    components: {
        OfertasEconomicasPendiestes,
        OfertasCreadasPorMes,
        OfertasPorEstatus
    },
    props: [],
    data() {
        return {
            estatus: 0,
            key: 0,
        }
    },
    computed: {

    },
    created() {},
    methods: {}
}