var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"rounded-xl mt-5 elevation-0 pa-5",attrs:{"width":"100%","color":"blue-grey lighten-5"}},[(_vm.loadingData)?_c('div',{staticClass:"center-item-in-div-column"},[_c('v-progress-circular',{attrs:{"size":180,"width":12,"color":"blue-grey darken-2","indeterminate":""}},[_c('div',{staticClass:"overline"},[_vm._v("cargando pases...")])])],1):_c('div',[_c('div',{staticClass:"pb-2"},[_c('v-btn',{staticClass:"rounded-lg mr-1",attrs:{"depressed":"","dark":"","color":_vm.estatus === 1 ? 'new_primary' : 'blue-grey darken-1'},on:{"click":function($event){_vm.estatus = 1; _vm.loadingData = true; _vm.updateTable();}}},[_vm._v("autorizados")]),_c('v-btn',{staticClass:"rounded-lg mr-1",attrs:{"depressed":"","dark":"","color":_vm.estatus === 2 ? 'new_primary' : 'blue-grey darken-1'},on:{"click":function($event){_vm.estatus = 2; _vm.loadingData = true; _vm.updateTable();}}},[_vm._v("rechazados")]),_c('v-btn',{staticClass:"rounded-lg mr-1",attrs:{"depressed":"","dark":"","color":_vm.estatus === 0 ? 'new_primary' : 'blue-grey darken-1'},on:{"click":function($event){_vm.estatus = 0; _vm.loadingData = true; _vm.updateTable();}}},[_vm._v("pendientes")]),_c('v-btn',{staticClass:"rounded-lg mr-1",attrs:{"depressed":"","dark":"","color":_vm.estatus === 3 ? 'new_primary' : 'blue-grey darken-1'},on:{"click":function($event){_vm.estatus = 3; _vm.loadingData = true; _vm.updateTable();}}},[_vm._v("liberados")])],1),_c('v-data-table',{staticClass:"rounded-lg",staticStyle:{"background-color":"#ECEFF1","border":"solid 3px #CFD8DC"},attrs:{"headers":_vm.headers,"items":_vm.pases,"search":_vm.search,"no-data-text":"No se han registrado pases de ingreso","loading-text":"Cargando información...","loading":_vm.loadingData,"footer-props":{
        'items-per-page-text': 'Elementos por página',
        pageText: '{0}-{1} de {2}',
    },"single-expand":true,"expanded":_vm.expanded,"show-expand":""},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"primary pt-4  rounded-l-t-header-pases-admin  rounded-r-t-header-pases-admin",staticStyle:{"display":"flex","justify-content":"end"}},[_c('v-text-field',{staticClass:"mx-4 rounded-lg",staticStyle:{"max-height":"42px","max-width":"300px"},attrs:{"label":"Buscar...","dense":"","flat":"","solo-inverted":"","dark":"","clearable":"","append-icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]},proxy:true},{key:"expanded-item",fn:function(ref){
    var headers = ref.headers;
    var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-row',[_c('v-col',{attrs:{"cols":['xs', 'sm'].includes(_vm.$vuetify.breakpoint.name) ? 6 : 3}},[_c('v-card',{staticClass:"rounded-lg elevation-0 pa-5 my-2"},[_vm._v(" "+_vm._s(item.compania)+" ")])],1),_c('v-col',{attrs:{"cols":['xs', 'sm'].includes(_vm.$vuetify.breakpoint.name) ? 6 : 3}},[_c('v-card',{staticClass:"rounded-lg elevation-0 pa-5 my-2"},[_vm._v(" "+_vm._s(item.Area)+": "+_vm._s(item.descAccion)+" ")])],1)],1),_c('v-card',{staticClass:"rounded-lg pa-5 my-2"},[_c('div',{staticClass:"pt-1"},[_c('label',{staticClass:"overline"},[_c('strong',[_vm._v("unidad de medida: ")]),_vm._v(" "+_vm._s(item.descMedida))])]),_c('v-simple-table',{attrs:{"dense":""}},[_c('thead',[_c('tr',[_c('th',{staticClass:"header-material-table overline font-weight-bold white--text"},[_vm._v(" No. Serie ")]),_c('th',{staticClass:"header-material-table overline font-weight-bold white--text"},[_vm._v(" Cantidad ")]),_c('th',{staticClass:"header-material-table overline font-weight-bold white--text"},[_vm._v(" descripción ")]),_c('th',{staticClass:"header-material-table overline font-weight-bold white--text"},[_vm._v(" orden Compra ")]),_c('th',{staticClass:"header-material-table overline font-weight-bold white--text"},[_vm._v(" No Factura ")])])]),_c('tbody',_vm._l((item.material),function(m,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(m.codigo))]),_c('td',[_vm._v(_vm._s(_vm.mcantidad))]),_c('td',[_vm._v(_vm._s(m.descripcion))]),_c('td',[_vm._v(_vm._s(m.orden_compra))]),_c('td',[_vm._v(_vm._s(m.factura))])])}),0)])],1)],1)]}},{key:"item.solicitante",fn:function(ref){
    var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"overline font-weight-bold"},'span',attrs,false),on),[_vm._v(_vm._s(item.solicitante))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.EMP_NOMEMP))])])]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":"","color":"cyan darken-1"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [(item.estatus === 0)?_c('v-btn',_vm._g(_vm._b({staticClass:"rounded-pill",attrs:{"small":"","color":"cyan darken-1","dark":"","loading":item.id == _vm.idAux && _vm.loadingBtnNofify ? true : false},on:{"click":function($event){_vm.idAux = item.id; _vm.loadingBtnNofify = true; _vm.sendMail(item.id);}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v(" mdi-bell-ring ")]),_vm._v(" Notificar ")],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Reenviar notificación")])]),_c('v-tooltip',{attrs:{"top":"","color":"cyan darken-1"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [([1, 4].includes(item.estatus))?_c('v-btn',_vm._g(_vm._b({staticClass:"rounded-pill",attrs:{"small":"","color":"cyan darken-1","dark":"","loading":item.id == _vm.idAux && _vm.loadingBtnNofify ? true : false},on:{"click":function($event){_vm.idAux = item.id; _vm.showConfirm = true;}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v(" mdi-arrow-right-bold ")]),_vm._v(" Liberar ")],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Liberar pase de ingreso")])])]}}])}),_c('Confirm',{attrs:{"showConfirm":_vm.showConfirm,"question":"¿Desas modificar el estatus de este pase de ingreso?"},on:{"cierraConfirm":_vm.validaRespuesta}}),(_vm.muestraBarra)?_c('SnackBarErrorIncidencias',{attrs:{"muestraBarra":_vm.muestraBarra,"color":_vm.colorBarra,"texto":_vm.textoBarra},on:{"cierraBarra":function($event){_vm.muestraBarra = false}}}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }