var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('div',{staticClass:"mt-4",staticStyle:{"display":"flex","justify-content":"start","align-items":"start"}},[_c('v-btn',{staticClass:"rounded-pill mr-2 font-weight-bold",attrs:{"small":"","depressed":"","outlined":_vm.tab === 1 ? false : true,"color":"primary"},on:{"click":function($event){_vm.tab = 1;
        _vm.updateTable();}}},[_vm._v("Desarrollo humano")]),_c('v-btn',{staticClass:"rounded-pill mr-2 font-weight-bold",attrs:{"small":"","depressed":"","outlined":_vm.tab === 6 ? false : true,"color":"primary"},on:{"click":function($event){_vm.tab = 6;
        _vm.updateTable();}}},[_vm._v("Logística")]),_c('v-btn',{staticClass:"rounded-pill mr-2 font-weight-bold",attrs:{"small":"","depressed":"","outlined":_vm.tab === 7 ? false : true,"color":"primary"},on:{"click":function($event){_vm.tab = 7;
        _vm.updateTable();}}},[_vm._v("Operaciones")]),_c('v-btn',{staticClass:"rounded-pill mr-2 font-weight-bold",attrs:{"small":"","depressed":"","outlined":_vm.tab === 11 ? false : true,"color":"primary"},on:{"click":function($event){_vm.tab = 11;
        _vm.updateTable();}}},[_vm._v("Innovación y competitividad")])],1),_c('v-card',{staticClass:"mt-4 pa-5 rounded-xl elevation-0",attrs:{"color":"blue-grey lighten-5"}},[_c('v-data-table',{staticStyle:{"background-color":"transparent"},attrs:{"items":_vm.departamentos,"headers":_vm.headers,"loading":_vm.loadingData,"dense":"","footer-props":{
        'items-per-page-text': 'Elementos por página',
        pageText: '{0}-{1} de {2}',
      },"no-data-text":"No hay registros","loading-text":"Cargando registros..."},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticStyle:{"display":"flex","justify-content":"space-between"}},[_c('v-text-field',{staticClass:"rounded-pill",attrs:{"dense":"","outlined":"","prepend-inner-icon":"mdi-magnify","placeholder":"Buscar..."},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('div',[_c('vue-json-to-csv',{attrs:{"json-data":_vm.departamentos,"labels":_vm.headers_to_export,"csv-title":'ReporteAutolizadoresPorDepartamento_' + _vm.tab}},[_c('v-tooltip',{attrs:{"bottom":"","color":"green darken-2"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"rounded-pill mr-2",attrs:{"depressed":"","outlined":"","fab":"","small":"","color":"green darken-2","dark":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-microsoft-excel")])],1)]}}])},[_c('span',[_vm._v("Exportar CSV")])])],1),_c('v-tooltip',{attrs:{"bottom":"","color":"blue darken-2"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"depressed":"","fab":"","small":"","outlined":"","color":"blue darken-2"},on:{"click":function($event){_vm.updateDpto = false;
                    _vm.muestraDialogDptoDL = true;}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-office-building-plus-outline")])],1)]}}])},[_c('span',[_vm._v("Agregar departamento")])]),_c('v-tooltip',{attrs:{"bottom":"","color":"blue-grey darken-2"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"depressed":"","fab":"","small":"","outlined":"","color":"blue-grey darken-2"},on:{"click":_vm.updateTable}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-reload")])],1)]}}])},[_c('span',[_vm._v("Actualizar tabla")])])],1)],1)]},proxy:true},{key:"item.DESCR",fn:function(ref){
                    var item = ref.item;
return [_c('label',{staticClass:"text-uppercase font-weight-bold"},[_vm._v(_vm._s(item.DESCR))])]}},{key:"item.estatus",fn:function(ref){
                    var item = ref.item;
return [_c('v-chip',{attrs:{"color":item.estatus == 1 ? 'teal' : 'pink',"dark":"","small":""}},[_vm._v(" Activo ")])]}},{key:"item.accion",fn:function(ref){
                    var item = ref.item;
return [_c('v-icon',{attrs:{"color":item.estatus == 1 ? 'blue darken-2' : 'pink',"dark":""},on:{"click":function($event){_vm.updateDpto = true;
            _vm.auxDpto = item;
            _vm.muestraDialogDptoDL = true;}}},[_vm._v(" mdi-pencil-circle ")]),_c('v-icon',{staticClass:"ml-1",attrs:{"color":"cyan lighten-1","dark":""},on:{"click":function($event){_vm.deptidSelected = item.id_departamento_ps;
            _vm.idRegistro = item.id;
            _vm.muestraSolicitantes = true;}}},[_vm._v(" mdi-dots-horizontal-circle ")])]}}])})],1),(_vm.muestraSolicitantes)?_c('MuestraSolicitantesPorDepartamento',{attrs:{"muestraSolicitantes":_vm.muestraSolicitantes,"deptid":_vm.deptidSelected,"idRegistro":_vm.idRegistro},on:{"ocultaSolicitantes":function($event){_vm.muestraSolicitantes = false}}}):_vm._e(),(_vm.muestraDialogDptoDL)?_c('ModificaDepartamentoDL',{attrs:{"muestraDialogDptoDL":_vm.muestraDialogDptoDL,"update":_vm.updateDpto,"dataDpto":_vm.auxDpto},on:{"cierraDialogDptoDL":_vm.cierraDialogDptoDL}}):_vm._e(),(_vm.muestraBarra)?_c('SnackBar',{attrs:{"muestraBarra":_vm.muestraBarra,"color":_vm.colorBarra,"texto":_vm.textoBarra},on:{"cierraBarra":function($event){_vm.muestraBarra = false}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }