<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <v-card>
      <v-card-title class="text-subtitle-2 primary white--text">
        Nueva Politica
        <v-spacer></v-spacer>
        <v-icon
          small
          dark
          @click="$emit('cierraFormReglas', { result: 'Cancelar' })"
          >mdi-close</v-icon
        >
      </v-card-title>
      <v-card-text class="mt-5">
        <v-row>
          <v-col cols="12" xl="8" lg="8" md="8" sm="12" xs="12">
            <v-textarea
              v-model="form.nombreRegla"
              background-color="grey lighten-2"
              color="cyan"
              label="Nombre de nueva politica"
              rows="1"
              row-height="1"
              counter
              :rules="selectRules"
              maxlength="50"
              prepend-inner-icon="mdi-comment"
              dense
              outlined
            ></v-textarea>
          </v-col>
          <v-col cols="12" xl="4" lg="4" md="4" sm="12" xs="12">
            <v-select
              v-model="form.compania"
              :items="companias"
              item-text="Compania"
              item-value="CIA_JDE"
              :loading="loadingCompanias"
              required
              :rules="selectRules"
              prepend-inner-icon="mdi-office-building"
              dense
              outlined
              class="rounded-lg py-0 my-0"
              label="Compañia"
            >
            </v-select>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          v-if="form.compania && form.nombreRegla"
          small
          color="teal darken-4"
          dark
          :loading="loading"
          @click="guardar()"
          >Nueva Regla</v-btn
        >
        <v-btn v-else small disabled>Nueva Regla</v-btn>
      </v-card-actions>
    </v-card>
    <SnackBar
      v-if="muestraBarra"
      :muestraBarra="muestraBarra"
      :texto="textoBarra"
      :color="colorBarra"
      @cierraBarra="muestraBarra = false"
    />
  </v-dialog>
</template>



<script>
import axios from "axios";
import { deleteToken } from "../../../../../../store/Funciones/funciones";
import SnackBar from "../../../../General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue";
export default {
  name: "form-politicas-reglas",
  components: { SnackBar },
  props: ["dialog"],
  data: () => ({
    textoBarra: "",
    colorBarra: "",
    muestraBarra: false,

    form: {
      nombreRegla: "",
      compania: "",
    },
    companias: [],
    loadingCompanias: true,
    loading: false,
    selectRules: [(v) => !!v || "Campo requerido."],
  }),
  created() {},
  mounted() {
    this.getCompanias();
  },

  methods: {
    getCompanias() {
      this.companias.length = 0;
      let params = {
        Opcion: 4,
        token: localStorage.getItem("token"),
      };
      axios
        .post(
          `${this.$store.getters.getHost}/General/AministracionSistema/ws-usuarios.php`,
          params
        )
        .then((response) => {
          this.loadingCompanias = false;
          if (response.data.status == "EXPRESSION") {
            deleteToken();
          } else if (response.data.status == "OK") {
            this.companias = response.data.usuarios;
          }
        });
    },

    guardar() {
      let params = {
        Opcion: 5,
        form: this.form,
        token: localStorage.getItem("token"),
      };
      console.log(params);
      this.loading = true;
      axios
        .post(
          `${this.$store.getters.getHost}/General/PoliticasUHM/ws-politicasUHM.php`,
          params
        )
        .then((response) => {
          this.loading = false;

          console.log(response.data);
          if (response.data.status == "OK") {
            this.textoBarra = "Politica registrada correctamente";
            this.colorBarra = "success";
            this.muestraBarra = true;
            this.clean();
            return new Promise((resolve) => {
              setTimeout(() => {
                resolve(this.$emit("cierraFormReglas", { estatus: "OK" }));
              }, 1500);
            });
          } else if (response.data.status == "ERROR") {
            this.textoBarra = "Se ha producido un error al registrar";
            this.colorBarra = "error";
            this.muestraBarra = true;
          } else if (response.data.status == "EXPSESSION") {
            deleteToken();
          }
        });
    },

    clean() {
      this.form.nombreRegla = "";
      this.form.compania = "";
    },
  },
};
</script>