var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"transition":"dialog-bottom-transition","content-class":"fix-border-dialog","max-width":"750","persistent":""},model:{value:(_vm.verDialogAbonosPrestamo),callback:function ($$v) {_vm.verDialogAbonosPrestamo=$$v},expression:"verDialogAbonosPrestamo"}},[_c('v-card',{staticClass:"rounded-xl"},[_c('v-toolbar',{attrs:{"color":"primary","dark":""}},[_c('label',{staticClass:"overline font-weight-bold"},[_vm._v(" Abonos de préstamo ["+_vm._s(_vm.idPrestamo)+"] ")]),_c('v-spacer'),_c('v-icon',{attrs:{"dark":""},on:{"click":function($event){return _vm.$emit('cierraDialogAbonosPrestamo')}}},[_vm._v("mdi-close")])],1),_c('v-card-text',{staticClass:"pt-3"},[_c('v-data-table',{attrs:{"dense":"","items":_vm.abonos,"headers":_vm.headers,"search":_vm.search,"footer-props":{
          'items-per-page-text': 'Elementos por página',
          pageText: '{0}-{1} de {2}',
        },"loading":_vm.loadingData,"loading-text":"Cargando montos...","no-data-text":"No hay registros"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticStyle:{"display":"flex","justify-content":"space-between"}},[_c('v-text-field',{staticClass:"rounded-pill",attrs:{"dense":"","outlined":"","prepend-inner-icon":"mdi-magnify","placeholder":"Buscar..."},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('vue-json-to-csv',{attrs:{"json-data":_vm.abonos,"labels":_vm.headers_to_export,"csv-title":'ReporteAbonosPrestamo' + _vm.idPrestamo}},[_c('v-btn',{staticClass:"rounded-pill mr-2",attrs:{"depressed":"","color":"green darken-2","dark":"","loading":_vm.loadingData}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-microsoft-excel")]),_vm._v(" exportar ")],1)],1)],1)]},proxy:true},{key:"item.monto",fn:function(ref){
        var item = ref.item;
return [_c('v-btn',{attrs:{"x-small":"","text":"","color":"blue darken-1"},on:{"click":function($event){_vm.datosPrestamoAux = item;
              _vm.muestraIngresaCantidad = true;
              _vm.esAbono = true;}}},[_vm._v(" "+_vm._s(_vm.formatMXN(item.monto))+" "),_c('v-icon',{attrs:{"x-small":""}},[_vm._v("mdi-pencil")])],1)]}},{key:"item.interes",fn:function(ref){
              var item = ref.item;
return [_c('v-btn',{attrs:{"x-small":"","text":"","color":"blue darken-1"},on:{"click":function($event){_vm.datosPrestamoAux = item;
              _vm.muestraIngresaCantidad = true;
              _vm.esAbono = false;}}},[_vm._v(" "+_vm._s(_vm.formatMXN(item.interes))+" "),_c('v-icon',{attrs:{"x-small":""}},[_vm._v("mdi-pencil")])],1)]}},{key:"item.fecha_registro",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.setFormatDate(item.fecha_registro))+" ")]}},{key:"item.actions",fn:function(ref){
              var item = ref.item;
return [_c('v-btn',{staticClass:"rounded-pill",attrs:{"color":"pink darken-2","depressed":"","dark":"","x-small":""},on:{"click":function($event){_vm.datosPrestamoAux = item;
              _vm.showConfirm = true;}}},[_vm._v(" remover "),_c('v-icon',{attrs:{"right":"","x-small":""}},[_vm._v("mdi-minus-circle")])],1)]}}])})],1)],1),_c('IngresaCantidad',{attrs:{"muestraIngresaCantidad":_vm.muestraIngresaCantidad,"titulo":"Ingresa el nuevo monto"},on:{"retornaCantidad":_vm.retornaCantidad}}),(_vm.muestraBarra)?_c('SnackBar',{attrs:{"muestraBarra":_vm.muestraBarra,"texto":_vm.textoBarra,"color":_vm.colorBarra},on:{"cierraBarra":function($event){_vm.muestraBarra = false}}}):_vm._e(),_c('ConfirmDialogReclutamiento',{attrs:{"showConfirm":_vm.showConfirm,"question":"¿Realmente deseas eliminar este abono registrado?"},on:{"cierraConfirm":_vm.validateDeletePayment}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }