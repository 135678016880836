<template>
  <v-card class="rounded-xl pa-4 back-card-objectives elevation-15" min-height="250">
    <div v-show="loadingTable" class="center-item-in-div-column">
        <v-progress-circular
        :size="150"
        :width="15"
        color="primary"
        indeterminate
        ></v-progress-circular>
        <label class="overline font-weight-bold">Cargando información...</label>
    </div>
    <v-card class="rounded-lg pb-4 elevation-0" v-show="!loadingTable">
      <v-simple-table dense style="background-color: transparent;">
        <thead>
          <tr>
            <th class="primary rounded-simple-table-head-l" colspan="7"></th>
            <th
              class="primary white--text text-uppercase font-weight-bold text-center"
              colspan="4"
            >
              Valor real último trimestre
            </th>
            <th class="primary rounded-simple-table-head-r" colspan="2"></th>
          </tr>
          <tr>
            <th
              class="primary white--text text-uppercase font-weight-bold text-center"
            >
              ID
            </th>
            <th
              class="primary white--text text-uppercase font-weight-bold text-center"
            >
              Objetivo
            </th>
            <th
              class="primary white--text text-uppercase font-weight-bold text-center"
            >
              VO
            </th>
            <th
              class="primary white--text text-uppercase font-weight-bold text-center"
            >
              LE
            </th>
            <th
              class="primary white--text text-uppercase font-weight-bold text-center"
            >
              Ponderación
            </th>
            <th
              class="primary white--text text-uppercase font-weight-bold text-center"
            >
              Tipo
            </th>
            <th
              class="primary white--text text-uppercase font-weight-bold text-center"
            >
              Tipo de calculo
            </th>
            <th
              class="primary white--text text-uppercase font-weight-bold text-center"
            >
              Mes 1
            </th>
            <th
              class="primary white--text text-uppercase font-weight-bold text-center"
            >
              Mes 2
            </th>
            <th
              class="primary white--text text-uppercase font-weight-bold text-center"
            >
              Mes 3
            </th>
            <th
              class="primary white--text text-uppercase font-weight-bold text-center"
            >
              Resultado
            </th>
            <th
              class="primary white--text text-uppercase font-weight-bold text-center"
            >
              Desempeño
            </th>
            <th
              class="primary white--text text-uppercase font-weight-bold text-center"
            >
              Puntos
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in objetivos" :key="index">
            <td class="text-center">{{ item.Id_Indicador }}</td>
            <td class="text-center">{{ item.DescObjetivo }}</td>
            <td class="text-center">{{ item.VO }}</td>
            <td class="text-center">{{ item.LE }}</td>
            <td class="text-center">{{ item.Ponderacion }}</td>
            <td class="text-center">{{ item.Tipo }}</td>
            <td class="text-center">{{ item.TipoCalculo }}</td>
            <td class="text-center">{{ item.ValorPrimer }}</td>
            <td class="text-center">{{ item.ValorSegundo }}</td>
            <td class="text-center">{{ item.ValorTercero }}</td>
            <td class="text-center">
              <v-chip
                small
                :class="
                  getColorClass(
                    getResultado(
                      2,
                      item.TipoCalculo,
                      item.ValorPrimer,
                      item.ValorSegundo,
                      item.ValorTercero,
                      item.VO,
                      item.LE
                    )
                  )
                "
              >
                {{
                  getResultado(
                    1,
                    item.TipoCalculo,
                    item.ValorPrimer,
                    item.ValorSegundo,
                    item.ValorTercero,
                    item.VO,
                    item.LE
                  )
                }}
              </v-chip>
            </td>
            <td class="text-center">
              {{
                getResultado(
                  2,
                  item.TipoCalculo,
                  item.ValorPrimer,
                  item.ValorSegundo,
                  item.ValorTercero,
                  item.VO,
                  item.LE
                )
              }}
            </td>
            <td class="text-center">
              {{
                getPuntos(
                  item.TipoCalculo,
                  item.ValorPrimer,
                  item.ValorSegundo,
                  item.ValorTercero,
                  item.VO,
                  item.LE,
                  item.Ponderacion
                )
              }}
            </td>
          </tr>
        </tbody>
      </v-simple-table>
      <div v-show="sumatoria > 0" class="text-right pt-4">
        <label class="font-weight-bold mr-3">
          Calificación del trimestre: {{ sumatoria.toFixed(2) }}</label
        >
      </div>
    </v-card>
  </v-card>
</template>

<script>
import axios from "axios";
export default {
  name: "ver-objetivos",
  data() {
    return {
      objetivos: [],
      sumatoria: 0,
      loadingTable: true
    };
  },
  async mounted() {
    this.objetivos = await this.getObjectives();
    this.calculateSumatoria();
  },
  computed: {},
  methods: {
    getObjectives() {
      return new Promise((resolve) => {
        axios
          .get("http://localhost:3080/api/ver-objetivos/15689/3/2023")
          .then((response) => {
            resolve(response.data.data);
          })
          .finally(() => {
            this.loadingTable = false
          });
      });
    },
    getResultado(
      opc,
      tipoCalculo,
      valorPrimer,
      valorSegundo,
      valorTercero,
      valorObjetivo,
      limiteEspecifico
    ) {
      let real = 0;
      if (["Promedio", "", null].includes(tipoCalculo)) {
        real = (valorPrimer + valorSegundo + valorTercero) / 3;
      } else {
        if (valorPrimer != 0) real = valorPrimer;
        else if (valorSegundo != 0) real = valorSegundo;
        else if (valorTercero != 0) real = valorTercero;
      }
      if (opc === 1) {
        return real.toFixed(2);
      }

      const desempenio =
        ((limiteEspecifico - real) / (limiteEspecifico - valorObjetivo)) * 100;
      if (opc === 2) {
        return desempenio > 100
          ? 100
          : desempenio > 100 < 0
          ? 0
          : desempenio.toFixed(2);
      }
      return 0;
    },
    getPuntos(
      tipoCalculo,
      valorPrimer,
      valorSegundo,
      valorTercero,
      valorObjetivo,
      limiteEspecifico,
      ponderacion
    ) {
      let real = 0;
      if (["Promedio", "", null].includes(tipoCalculo)) {
        real = (valorPrimer + valorSegundo + valorTercero) / 3;
      } else {
        if (valorPrimer != 0) real = valorPrimer;
        else if (valorSegundo != 0) real = valorSegundo;
        else if (valorTercero != 0) real = valorTercero;
      }
      let desempenio =
        ((limiteEspecifico - real) / (limiteEspecifico - valorObjetivo)) * 100;
      desempenio = desempenio > 100 ? 100 : desempenio < 0 ? 0 : desempenio;
      let puntos = (desempenio * ponderacion) / 100;

      return puntos.toFixed(2);
    },
    calculateSumatoria() {
      this.sumatoria = 0;
      for (const item of this.objetivos) {
        this.sumatoria += parseFloat(
          this.getPuntos(
            item.TipoCalculo,
            item.ValorPrimer,
            item.ValorSegundo,
            item.ValorTercero,
            item.VO,
            item.LE,
            item.Ponderacion
          )
        );
      }
    },
    getColorClass(puntos) {
      return puntos === 100
        ? "teal darken-1 white--text"
        : puntos === 0
        ? "pink darken-3 white--text"
        : "amber black--text";
    },
  },
};
</script>

<style>
.rounded-simple-table-head-l {
  border-top-left-radius: 22px !important;
}
.rounded-simple-table-head-r {
  border-top-right-radius: 22px !important;
}

.back-card-objectives {
  background: rgb(255, 255, 255) !important;
  background: linear-gradient(
    301deg,
    rgba(255, 255, 255, 1) 15%,
    rgba(201, 78, 148, 0.7344071417629552) 45%,
    rgba(255, 255, 255, 1) 100%
  ) !important;
}
</style>
