<template>
  <v-container>
    <v-card class="rounded-xl mb-2 elevation-0" color="blue-grey lighten-5">
      <v-card-title style="display: flex; justify-content: space-between;">
        <v-sheet
          class="text-uppercase subtitle-1 font-weight-bold blue-grey--text text--darken-3"
          color="transparent"
        >
          Reporte costo de tiempo extra.
        </v-sheet>
        <label class="overline">
          Semana actual:
          <strong class="primary--text">{{ numsemana }}</strong>
        </label>
      </v-card-title>
      <v-card-text>
        <v-expansion-panels popout class="my-5" flat v-model="panel">
          <v-expansion-panel class="rounded-xl">
            <v-expansion-panel-header
              class="font-weight-bold text-uppercase blue-grey--text text--lighten-1"
            >
              Analítica del año actual {{ numanio }}</v-expansion-panel-header
            >
            <v-expansion-panel-content>
              <v-card
                class="pa-2 background-glass-effect-graph"
                elevation="0"
                max-height="180px"
              >
                <Bar />
              </v-card>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card-text>
    </v-card>

    <v-divider class="mx-15"></v-divider>

    <v-card class="rounded-xl mt-2 elevation-0" color="blue-grey lighten-5">
      <div class="px-5 pt-5 pb-1">
        <!--  -->
        <v-sheet width="100%" color="transparent">
          <label class="text-uppercase subtitle-2font-weight-bold">
            <v-icon left>mdi-filter</v-icon>
            filtros
          </label>
        </v-sheet>
        <div>
          <v-row dense>
            <v-col>
              <label class="overline ml-4">Año</label>
              <v-text-field
                v-model="numanio"
                class="rounded-pill mx-1"
                dense
                outlined
                placeholder="Selecciona el año..."
                readonly
              ></v-text-field>
            </v-col>
            <v-col>
              <label class="overline ml-4">Semana</label>
              <v-select
                :items="semana"
                class="rounded-pill mx-1"
                dense
                outlined
                :menu-props="{ bottom: true, offsetY: true }"
                v-model="selectsemana"
                v-on:change="
                  tipoFiltro = 'semana';
                  updateReporteDeCostos();
                "
                item-text="Numero de la semana:"
                placeholder="Selecciona el número de la semana."
              >
              </v-select>
            </v-col>
            <v-col>
              <label class="overline ml-4">Mes</label>
              <v-select
                class="rounded-pill mx-1"
                :items="meses"
                item-text="descripcion"
                item-value="value"
                dense
                outlined
                v-model="mesSeleccionado"
                v-on:change="
                  tipoFiltro = 'mes';
                  updateReporteDeCostos();
                "
                label="Selecciona el mes."
              >
              </v-select>
            </v-col>
          </v-row>
        </div>

        <div style="display: flex; justify-content: space-around;">
          <!-- v-if="reportecosto.length > 0" -->
          <label
            class="font-italic subtitle-2 text-uppercase blue-grey--text text--darken-3"
            >{{ reportecosto.length }} registros</label
          >
          <label
            v-show="tipoFiltro === 'semana'"
            class="font-italic subtitle-2 text-uppercase blue-grey--text text--darken-3"
            >semana del {{ inicionom }} al {{ finnom }}</label
          >
          <label
            v-show="tipoFiltro === 'mes'"
            class="font-italic subtitle-2 text-uppercase blue-grey--text text--darken-3"
            >Mes seleccionado: {{ mesDesc }}</label
          >
          <label
            class="font-italic subtitle-2 text-uppercase blue-grey--text text--darken-3"
            >Costo total de la selección:
            <strong>{{ costoreporte }}</strong></label
          >
        </div>
      </div>
      <v-card-text>
        <div
          style="display: flex; justify-content: start; align-items: center;"
          class="py-2"
        >
          <div v-for="(item, index) in tiposReportes" :key="index">
            <v-btn
              class="rounded-pill mr-2"
              small
              color="blue darken-1"
              :depressed="!tipoReporte === item.value"
              :outlined="tipoReporte !== item.value"
              dark
              @click="tipoReporte = item.value"
            >
              {{ item.descripcion }}
            </v-btn>
          </div>
        </div>

        <v-data-table
          v-if="tipoReporte === 1"
          :headers="headerCostoSolis"
          :items="reportecosto"
          :loading="loading"
          class="elevation-0 rounded-lg"
          no-data-text="No hay reporte."
          :footer-props="{
            'items-per-page-text': 'Elementos por página',
            pageText: '{0}-{1} de {2}',
          }"
        >
          <template v-slot:item.costototal="{ item }">
            <label class="font-italic font-weight-bold">{{
              item.costototal
            }}</label>
          </template>
        </v-data-table>

        <MuestraReporteSolicitudes
          v-else-if="tipoReporte === 2"
          :tipoFiltro="tipoFiltro"
          :semana="selectsemana"
          :mes="mesSeleccionado"
          :anio="numanio"
        />

        <MustraPorcentajeCumplimiento v-else-if="tipoReporte === 3" />
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import axios from "axios";
import { deleteToken } from "../../../../../../store/Funciones/funciones";
import moment from "moment/moment";
import Bar from "./BarBarrasTE.vue";
import MuestraReporteSolicitudes from "./MuestraReporteSolicitudes.vue";
import MustraPorcentajeCumplimiento from "./MustraPorcentajeCumplimiento.vue";
export default {
  name: "reporte-costo-de-tiempo-extra",
  components: {
    Bar,
    MuestraReporteSolicitudes,
    MustraPorcentajeCumplimiento,
  },
  props: [],
  data() {
    return {
      loading: true,
      numsemana: 0,
      semanareporte: 0,
      tipoReporte: 1,
      selectsemana: null,
      numanio: null,
      inicionom: null,
      finnom: null,
      costoreporte: null,
      mesSeleccionado: null,
      mesDesc: "",
      tipoFiltro: "semana",
      reportecosto: [],
      tiposReportes: [
        { value: 1, descripcion: "costos" },
        { value: 2, descripcion: "solicitudes" },
        { value: 3, descripcion: "cumplimiento" },
      ],
      meses: [
        { value: 1, descripcion: "Enero" },
        { value: 2, descripcion: "Febrero" },
        { value: 3, descripcion: "Marzo" },
        { value: 4, descripcion: "Abril" },
        { value: 5, descripcion: "Mayo" },
        { value: 6, descripcion: "Junio" },
        { value: 7, descripcion: "Julio" },
        { value: 8, descripcion: "Agosto" },
        { value: 9, descripcion: "Septiembre" },
        { value: 10, descripcion: "Octubre" },
        { value: 11, descripcion: "Noviembre" },
        { value: 12, descripcion: "Diciembre" },
      ],
      semana: [],
      panel: [0, 1],
      headerCostoSolis: [
        {
          text: "EMPLEADO",
          value: "numemplid",
          align: "center",
          class: "primary white--text overline font-weight-bold rounded-tl-lg",
        },
        {
          text: "NOMBRE",
          value: "nombre",
          align: "center",
          class: "primary white--text overline font-weight-bold",
        },
        {
          text: "AREA",
          value: "areaname",
          align: "center",
          class: "primary white--text overline font-weight-bold",
        },
        {
          text: "TURNO",
          value: "turno",
          align: "center",
          class: "primary white--text overline font-weight-bold",
        },
        {
          text: "SUELDO DIARIO",
          value: "sueldodiario",
          align: "center",
          class: "primary white--text overline font-weight-bold",
        },
        {
          text: "HORAS",
          value: "totalhoras",
          align: "center",
          class: "primary white--text overline font-weight-bold",
        },
        {
          text: "$ PAGADO",
          value: "costototal",
          align: "center",
          class: "primary white--text overline font-weight-bold rounded-tr-lg",
        },
      ],
    };
  },
  computed: {},
  async mounted() {
    this.semana = await this.getdatosfecha();
    this.selectsemana = await this.semana[0];
    await this.updateSemana();
    this.reportecosto = await this.getReporteCosto();
  },
  methods: {
    updateSemana() {
      this.getSemanaActual(this.numanio, this.selectsemana);
    },
    async updateReporteDeCostos() {
      if (this.tipoFiltro === "semana") {
        this.updateSemana();
      } else {
        this.mesDesc = this.meses[this.mesSeleccionado - 1].descripcion;
      }
      this.loading = true;
      this.reportecosto = await this.getReporteCosto();
    },
    getReporteCosto() {
      this.loading = true;
      let semanaCadena =
        this.selectsemana < 10
          ? "0" + this.selectsemana.toString()
          : this.selectsemana.toString();

      console.log(this.tipoFiltro);
      let params = {
        Opcion: 9,
        año: this.numanio,
        semana: this.tipoFiltro === "semana" ? semanaCadena : null,
        mes: this.tipoFiltro === "mes" ? this.mesSeleccionado : null,
        token: localStorage.getItem("token"),
      };
      return new Promise((res) => {
        axios
          .post(
            `${this.$store.getters.getHost}/general/ws-TiempoExtra.php`,
            params
          )
          .then((response) => {
            if (response.data.status.trim() == "EXPSESSION") {
              deleteToken();
            } else {
              var data = response.data.costote.costotiempoextra;
              this.costoreporte = response.data.costote.costototalconcepto;
              this.costoreporte = this.costoreporte.toLocaleString("es-MX", {
                style: "currency",
                currency: "MXN",
              });
              if (data) {
                res(data);
              } else {
                res([]);
              }
            }
          })
          .finally(() => {
            this.loading = false;
          });
      });
    },
    getdatosfecha() {
      const weekNumber = moment().week();
      const currentMonthNumber = moment().month();
      this.numsemana = weekNumber;
      this.selectsemana = weekNumber;

      const yearactual = moment().year();
      this.numanio = yearactual;

      const weeks = [];
      for (let i = weekNumber - 1; i >= 1; i--) {
        weeks.push(i);
      }

      let counter = this.meses.length;
      while (counter > currentMonthNumber + 1) {
        this.meses.pop();
        counter--;
      }

      return weeks;
    },
    getSemanaActual(anio, semana) {
      var startOfWeek = moment()
        .year(anio)
        .week(semana)
        .startOf("week")
        .add(1, "days");
      var endOfWeek = moment()
        .year(anio)
        .week(semana)
        .endOf("week")
        .add(2, "days");
      startOfWeek.locale("es");
      endOfWeek.locale("es");

      startOfWeek.format("dd MM yyyy");
      endOfWeek.format("dd MM yyyy");

      var inicioDate = startOfWeek.format("dddd, D MMMM yyyy");
      var finalDate = endOfWeek.format("dddd, D MMMM yyyy");

      this.inicionom = inicioDate;
      this.finnom = finalDate;
    },
  },
};
</script>
