<template>
  <v-dialog
    v-model="dialog"
    max-width="60%"
    transition="dialog-bottom-transition"
    persistent
  >
    <v-card>
      <v-card-title class="text-subtitle-2 primary white--text">
        Nueva planta
        <v-spacer></v-spacer>
        <v-icon small dark @click="$emit('cierraForm', 'Cancel')"
          >mdi-close</v-icon
        >
      </v-card-title>
      <v-card-text>
        <v-form class="mt-5" ref="form" v-model="valid" lazy-validation>
          <v-card class="pa-5">
            <v-row>
              <v-col cols="12" xs="12" sm="12" md="7" lg="7" xl="7">
                <v-row>
                  <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-text-field
                      v-model="form.descripcion"
                      :rules="rulesRequired"
                      :loading="loadingForm"
                      label="Nombre"
                      hint="Escribe el nuevo nombre de planta"
                      outlined
                      dense
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-autocomplete
                      v-model="form.habilitado"
                      :rules="rulesRequired"
                      :items="habilitado"
                      :loading="loadingForm"
                      label="Estatus"
                      hint="Selecciona un estatus"
                      outlined
                      dense
                      hide-selected
                      item-value="id"
                      item-text="descripcion"
                      background-color="grey lighten-2"
                      color="cyan"
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-combobox
                      v-model="form.departamentos"
                      :rules="rulesRequired"
                      :items="departamentos"
                      :loading="loadingForm"
                      :menu-props="{ top: true, offsetY: true }"
                      label="Departamentos"
                      hint="Selecciona los departamentos asignados"
                      multiple
                      outlined
                      dense
                      hide-selected
                      item-text="descripcionMostrar"
                      background-color="grey lighten-2"
                      color="cyan"
                    >
                      <template v-slot:selection="{ item, index }">
                        <v-chip v-if="index === 0">
                          <span>{{ item.descripcionMostrar }}</span>
                        </v-chip>
                        <span
                          v-if="index === 1"
                          class="grey--text text-caption"
                        >
                          (+{{ form.departamentos.length - 1 }} más)
                        </span>
                      </template>
                    </v-combobox>
                  </v-col>
                </v-row>
              </v-col>
              <v-divider vertical></v-divider>
              <v-col
                cols="12"
                xs="12"
                sm="12"
                md="5"
                lg="5"
                xl="5"
                class="d-flex align-center"
              >
                <v-row>
                  <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-card-title
                      class="text-subtitle-2 primary white--text"
                      width="300px"
                      v-if="!form.departamentos"
                    >
                      Departamentos seleccionados: 0</v-card-title
                    >
                    <div class="scrollable-div" v-if="form.departamentos">
                      <v-card-title class="text-subtitle-2 primary white--text">
                        Departamentos seleccionados:
                        {{ form.departamentos.length }}</v-card-title
                      >
                      <v-tooltip
                        top
                        color="grey lighten-4"
                        v-for="(item, index) in form.departamentos"
                        :key="index"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            rounded
                            color="grey lighten-3"
                            class="pa-2 my-1 truncate-text"
                            small
                            v-bind="attrs"
                            v-on="on"
                            @click="popDepartamentos(index)"
                          >
                            {{ item.descripcionMostrar }}
                            <v-icon small right>mdi-close</v-icon>
                          </v-btn>
                        </template>
                        <label
                          class="text-uppercase font-weight-bold pink--text"
                          >Clic para remover
                          <v-icon small right color="pink"
                            >mdi-close</v-icon
                          ></label
                        >
                      </v-tooltip>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          class="rounded-lg"
          color="teal"
          dark
          :loading="loading"
          @click="validar"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-card>
    <SnackBarErrorIncidencias
      v-if="muestraBarra"
      :muestraBarra="muestraBarra"
      :texto="textoBarra"
      :color="colorBarra"
      @cierraBarra="muestraBarra = false"
    />
  </v-dialog>
</template>

<script>
import axios from "axios";
import { deleteToken } from "../../../../../store/Funciones/funciones";
import SnackBarErrorIncidencias from "../../../General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue";

export default {
  name: "form-admon-plantas",
  props: ["dialog", "item"],
  components: {
    SnackBarErrorIncidencias,
  },
  data() {
    return {
      textoBarra: "",
      colorBarra: "",
      muestraBarra: false,

      rulesRequired: [
        (value) => !!value || "Este campo es requerido", // Validación de campo requerido
      ],
      rulesValoresNumericos: [
        (value) => !isNaN(value) || "Solo se permiten números",
      ],
      form: {
        id: "",
        descripcion: "",
        habilitado: 1,
        departamentos: [],
      },
      habilitado: [
        { id: 1, descripcion: "Activo" },
        { id: 2, descripcion: "Inactivo" },
      ],
      valid: true,
      loading: false,
      loadingForm: false,
      departamentos: [],
    };
  },
  created() {},
  mounted() {
    this.getDepartamentos();
    if (this.item != "Nuevo") {
      this.form = Object.assign({}, this.item);
      console.log(this.form);
    }
  },

  computed: {},

  methods: {
    validar() {
      this.loading = true;
      const validar = this.$refs.form.validate();
      if (validar) {
        if (this.form.id) {
          this.editar();
        } else {
          this.guardar();
        }
      } else {
        this.textoBarra = "Por favor, complete los datos requeridos";
        this.colorBarra = "error";
        this.muestraBarra = true;
        this.loading = false;
      }
    },

    guardar() {
      const headers = { Authorization: localStorage.getItem("token") };
      const params = {
        form: this.form,
        folio: localStorage.getItem("folio"),
      };
      return new Promise((res) => {
        axios
          .post(`${this.$store.getters.getHostNode}/api/set-plantas`, params, {
            headers,
          })
          .then((response) => {
            if (response.data.status == "OK") {
              res(response.data.data);
              this.textoBarra = response.data.message;
              this.colorBarra = "success";
              this.muestraBarra = true;
              this.clean();
              return new Promise((resolve) => {
                setTimeout(() => {
                  resolve(this.$emit("cierraForm", "OK"));
                }, 1500);
              });
            } else if (response.data.status == "EXPRESSION") {
              deleteToken();
            } else {
              res([]);
            }
          })
          .finally(() => {
            this.loading = false;
          });
      });
    },

    editar() {
      const headers = { Authorization: localStorage.getItem("token") };
      const params = {
        form: this.form,
        folio: localStorage.getItem("folio"),
      };
      return new Promise((res) => {
        axios
          .post(
            `${this.$store.getters.getHostNode}/api/update-plantas`,
            params,
            { headers }
          )
          .then((response) => {
            if (response.data.status == "OK") {
              res(response.data.data);
              this.textoBarra = response.data.message;
              this.colorBarra = "success";
              this.muestraBarra = true;
              this.clean();
              return new Promise((resolve) => {
                setTimeout(() => {
                  resolve(this.$emit("cierraForm", "OK"));
                }, 1500);
              });
            } else if (response.data.status == "EXPRESSION") {
              deleteToken();
            } else {
              res([]);
            }
          })
          .finally(() => {
            this.loading = false;
          });
      });
    },

    clean() {
      this.$nextTick(() => {
        this.form = Object.assign({}, this.defaultItem);
      });
    },

    getDepartamentos() {
      const headers = {
        Authorization: localStorage.getItem("token"),
      };

      return new Promise((res) => {
        axios
          .get(`${this.$store.getters.getHostNode}/api/get-departamentos`, {
            headers: headers,
          })
          .then((response) => {
            if (response.data.status == "EXPRESSION") {
              deleteToken();
            } else if (response.data.status == "OK") {
              res(response.data.data);
              this.departamentos = response.data.data;
            } else {
              res([]);
            }
          })
          .finally(() => {
            this.loadingForm = false;
          });
      });
    },

    popDepartamentos(index) {
      console.log(index);
      this.form.departamentos.splice(index, 1);

      this.textoBarra = "Departamento removido correctamente.";
      this.colorBarra = "primary";
      this.muestraBarra = true;
    },
  },
};
</script>
<style scoped>
.scrollable-div {
  /* width: 300px; Ancho fijo */
  height: 200px; /* Alto fijo */
  overflow-y: auto; /* Permitir scroll vertical si es necesario */
  overflow-x: hidden; /* Ocultar el scroll horizontal */
  border: 1px solid #ccc; /* Solo para visualización */
  /* padding: 10px; */
}
</style>