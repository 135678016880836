import ReporteGeneral from "./components/ReporteGeneral.vue"
import RetirosDeCaja from "./components/RetirosDeCaja.vue"
import ReportePrestamos from "./components/ReportePrestamos.vue"
import ReporteAbonos from "./components/ReporteAbonos.vue"
import CuadreMensual from "./components/CuadreMensual.vue"
export default {
    name: 'reportes-de-caja',
    components: { ReporteGeneral, RetirosDeCaja, ReportePrestamos, ReporteAbonos, CuadreMensual },
    props: [],
    data() {
        return {
            opcion: "general"
        }
    },
    computed: {

    },
    mounted() {

    },
    methods: {

    }
}