var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',{attrs:{"color":"transparent","elevation":"0"}},[_c('v-card',{staticClass:"text-center rounded-lg elevation-0 pt-4 pb-2 px-0 mb-2"},[_c('label',{staticClass:"subtitle-1 text-uppercase"},[_vm._v("Cumplimiento al plan de producción")]),_c('div',{staticClass:"px-3"},[_c('v-progress-linear',{staticClass:"rounded-pill",attrs:{"color":_vm.getColorProgress(_vm.planproduccionporcentaje),"value":_vm.planproduccionporcentaje,"height":"25"}},[_c('strong',[_vm._v(_vm._s(isNaN(_vm.planproduccionporcentaje) ? 0 : _vm.planproduccionporcentaje)+"%")])])],1),_c('v-card-text',{staticClass:"px-2 pb-2"},[_c('v-data-table',{staticClass:" pa-3 elevation-0",attrs:{"dense":"","item-key":"ID","headers":_vm.headerplanproduccion,"items":_vm.planproduccion,"footer-props":{
          'items-per-page-text': 'Elementos por página',
          pageText: '{0}-{1} de {2}',
        }},model:{value:(_vm.planproduccion),callback:function ($$v) {_vm.planproduccion=$$v},expression:"planproduccion"}})],1)],1),_c('v-card',{staticClass:"text-center rounded-lg elevation-0 pt-4 pb-2 px-0 mb-2"},[_c('label',{staticClass:"subtitle-1 text-uppercase"},[_vm._v("Pedidos estraordinarios")]),_c('div',{staticClass:"px-3"},[_c('v-progress-linear',{staticClass:"rounded-pill",attrs:{"value":_vm.pedidoextraordinarioporcentaje,"color":_vm.getColorProgress(_vm.pedidoextraordinarioporcentaje),"height":"25"}},[_c('strong',[_vm._v(_vm._s(isNaN(_vm.pedidoextraordinarioporcentaje) ? 0 : _vm.pedidoextraordinarioporcentaje)+"%")])])],1),_c('v-card-text',{staticClass:"px-2 pb-2"},[_c('v-data-table',{staticClass:" pa-3 elevation-0",attrs:{"dense":"","item-key":"ID","headers":_vm.headerpedidoextraordinario,"items":_vm.pedidoextraordinario,"footer-props":{
          'items-per-page-text': 'Elementos por página',
          pageText: '{0}-{1} de {2}',
        }},model:{value:(_vm.pedidoextraordinario),callback:function ($$v) {_vm.pedidoextraordinario=$$v},expression:"pedidoextraordinario"}})],1)],1),_c('v-card',{staticClass:"text-center rounded-lg elevation-0 pt-4 pb-2 px-0 mb-2"},[_c('label',{staticClass:"subtitle-1 text-uppercase"},[_vm._v("Mantenimiento y/o limpieza")]),_c('div',{staticClass:"px-3"},[_c('v-progress-linear',{staticClass:"rounded-pill",attrs:{"value":_vm.mantenimientoporcentaje,"color":_vm.getColorProgress(_vm.mantenimientoporcentaje),"height":"25"}},[_c('strong',[_vm._v(_vm._s(_vm.mantenimientoporcentaje)+"%")])])],1),_c('v-card-text',{staticClass:"px-2 pb-2"},[_c('v-data-table',{staticClass:" pa-3 elevation-0",attrs:{"dense":"","item-key":"ID","headers":_vm.headermantenimiento,"items":_vm.mantenimiento,"footer-props":{
          'items-per-page-text': 'Elementos por página',
          pageText: '{0}-{1} de {2}',
        }},model:{value:(_vm.mantenimiento),callback:function ($$v) {_vm.mantenimiento=$$v},expression:"mantenimiento"}})],1)],1),_c('v-card',{staticClass:"text-center rounded-lg elevation-0 pt-4 pb-2 px-0 mb-2"},[_c('label',{staticClass:"subtitle-1 text-uppercase"},[_vm._v("Impacto en equipo caido")]),_c('div',{staticClass:"px-3"},[_c('v-progress-linear',{staticClass:"rounded-pill",attrs:{"value":_vm.impactoecporcentaje,"color":_vm.getColorProgress(_vm.impactoecporcentaje),"height":"25"}},[_c('strong',[_vm._v(_vm._s(_vm.impactoecporcentaje)+"%")])])],1),_c('v-card-text',{staticClass:"px-2 pb-2"},[_c('v-data-table',{staticClass:" pa-3 elevation-0",attrs:{"dense":"","item-key":"ID","headers":_vm.headerimpactoec,"items":_vm.impactoec,"footer-props":{
          'items-per-page-text': 'Elementos por página',
          pageText: '{0}-{1} de {2}',
        }},model:{value:(_vm.impactoec),callback:function ($$v) {_vm.impactoec=$$v},expression:"impactoec"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }