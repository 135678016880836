var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.files,"item-key":"id","search":_vm.search,"footer-props":{
        'items-per-page-text': 'Elementos por página',
        'page-text': '{0}-{1} de {2}',
      },"no-data-text":"No hay registros","no-results-text":'No se encontraron coincidencias para: ' + _vm.search},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"primary pt-4 rounded-l-t-header-pases-admin rounded-r-t-header-pases-admin rounded-lg rounded-b-0",staticStyle:{"display":"flex","justify-content":"space-between"}},[_c('v-text-field',{staticClass:"mx-4 rounded-lg",staticStyle:{"max-height":"42px","max-width":"400px"},attrs:{"label":"Buscar...","dense":"","flat":"","solo-inverted":"","dark":"","clearable":"","append-icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]},proxy:true},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',{staticClass:"mr-1",attrs:{"outlined":"","icon":"","color":"green"},on:{"click":function($event){return _vm.validar(item.id, item.documento, item.numeroProv)}}},[_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-check")])],1)]}}],null,true)},[_c('span',[_vm._v("Validar")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',{staticClass:"mr-1",attrs:{"outlined":"","icon":"","color":"blue"},on:{"click":function($event){return _vm.viewFile(item.nombreOriginal)}}},[_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-eye")])],1)]}}],null,true)},[_c('span',[_vm._v("Ver Documento")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',{staticClass:"mr-1",attrs:{"outlined":"","icon":"","color":"red"},on:{"click":function($event){return _vm.rechazar(item.id, item.documento, item.numeroProv)}}},[_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-close")])],1)]}}],null,true)},[_c('span',[_vm._v("Rechazar")])])]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }