<template>
  <div>
    <v-dialog
      v-model="muestraSolicitudes"
      persistent
      max-width="900"
      scrollable
    >
      <v-card class="shadow-card-mod">
        <v-card-title class="text-subtitle-2 primary white--text">
          Solicitudes de asignación de políticas.
          <v-spacer></v-spacer>
          <v-icon small dark @click="$emit('cierraSolicitudes', 0)"
            >mdi-close</v-icon
          >
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-0">
          <v-data-table
            dense
            :items="solicitudes"
            :headers="headers"
            :expanded.sync="expanded"
            :mobile-breakpoint="0"
            show-expand
          >
            <template v-slot:expanded-item="{ headers, item }" class="px-1">
              <td :colspan="headers.length" class="pa-4 mx-0">
                <v-card
                  class="backgroun-degraded-reclutamientos pa-3 rounded-xl"
                  elevation="15"
                >
                  <v-form ref="form" v-model="valid" lazy-validation>
                    <v-row class="pa-5">
                      <v-col :cols="numberOfColumns" class="py-0">
                        <label> ID PRODUCTIVITY </label>
                        <v-text-field
                          v-model="item.idproductivity"
                          required
                          :rules="rules"
                          class="rounded-lg"
                          dense
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col :cols="numberOfColumns" class="py-0">
                        <label> ID MERCER </label>
                        <v-text-field
                          v-model="item.idmercer"
                          required
                          :rules="rules"
                          class="rounded-lg"
                          dense
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col :cols="numberOfColumns" class="py-0">
                        <label> SUELDO DE CONTRATACIÓN </label>
                        <v-text-field
                          v-model="item.Contratacion"
                          required
                          :rules="rulesMoneda"
                          class="rounded-lg"
                          dense
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col :cols="numberOfColumns" class="py-0">
                        <label> SUELDO MÁXIMO </label>
                        <v-text-field
                          v-model="item.Maximo"
                          required
                          :rules="rulesMoneda"
                          v-on:change="obtenPromedio(item)"
                          class="rounded-lg"
                          dense
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col :cols="numberOfColumns" class="py-0">
                        <label> SUELDO MÍNIMO </label>
                        <v-text-field
                          v-model="item.Minimo"
                          required
                          :rules="rulesMoneda"
                          v-on:change="obtenPromedio(item)"
                          class="rounded-lg"
                          dense
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col :cols="numberOfColumns" class="py-0">
                        <label> SUELDO PROMEDIO </label>
                        <v-text-field
                          v-model="item.Promedio"
                          required
                          :rules="rulesMoneda"
                          class="rounded-lg"
                          dense
                          outlined
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-form>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      depressed
                      dark
                      class="rounded-lg mr-3"
                      color="teal darken-1"
                      style="min-width: 200px;"
                      :loading="loadingButton"
                      @click="
                        loadingButton = true;
                        guardaPoliticas(item);
                      "
                    >
                      <v-icon left>mdi-content-save</v-icon>
                      guardar
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </td>
            </template>
            <template v-slot:item.fecha_de_solicitud="{ item }">
              <div>
                {{ fixFormatDate(item.fecha_de_solicitud.date.slice(0, 10)) }}
              </div>
            </template>
            <template v-slot:item.actions="{ item }">
              <div class="text-center" @click="deleteItem(item)">
                <v-icon>mdi-delete</v-icon>
              </div>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
    <SnackBar
      :muestraBarra="muestraBarra"
      :color="colorBarra"
      :texto="textoBarra"
      @cierraBarra="muestraBarra = false"
    />
  </div>
</template>

<script>
import axios from "axios";
import { formatDate, deleteToken } from "../../../../store/Funciones/funciones";
import SnackBar from "../../General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue";

export default {
  name: "solicitudes-asignacion-de-politicas",
  props: ["muestraSolicitudes", "puestos"],
  components: { SnackBar },
  data() {
    return {
      expanded: [],
      solicitudes: [],
      colorBarra: "",
      textoBarra: "",
      muestraBarra: false,
      loadingButton: false,
      valid: true,
      rules: [(v) => !!v || "Este campo no puede estar vacío."],
      rulesMoneda: [
        (v) => !!v || "Campo requiredo",
        (v) => /^-?\d+(?:\.\d+)?$/.test(v) || "Formato invalido",
      ],
    };
  },
  mounted() {
    this.solicitudes = this.puestos;
  },
  computed: {
    numberOfColumns() {
      if (this.$vuetify.breakpoint.name === "xs") {
        return 12;
      } else if (this.$vuetify.breakpoint.name === "sm") {
        return 6;
      } else {
        return 4;
      }
    },
    headers() {
      return [
        { value: "puesto", text: "JOBCODE", sortable: false, class: "" },
        { value: "DESCR", text: "PUESTO", sortable: false, class: "" },
        {
          value: "fecha_de_solicitud",
          text: "FECHA",
          sortable: false,
          class: "",
        },
        {
          value: "actions",
          align: "center",
          text: "",
          sortable: false,
          class: "",
        },
      ];
    },
  },
  methods: {
    fixFormatDate(string) {
      return formatDate(string);
    },
    obtenPromedio(item) {
      if (
        item.Maximo != 0 &&
        item.Maximo != null &&
        item.Minimo != 0 &&
        item.Minimo != null
      ) {
        let index = this.solicitudes.indexOf(item);
        this.solicitudes[index].Promedio =
          (parseInt(item.Maximo) + parseInt(item.Minimo)) / 2;
      }
    },
    guardaPoliticas(item) {
        // this.console.log()
      if (this.$refs.form.validate()) {
        let params = {
          Opcion: 19,
          token: localStorage.getItem("token"),
          id: item.id,
          jobcode: item.puesto,
          idMercer: item.idmercer,
          idProductivity: item.idproductivity,
          contratacion: item.Contratacion,
          maximo: item.Maximo,
          minimo: item.Minimo,
          promedio: item.Promedio,
        };
        axios
          .post(
            `${this.$store.getters.getHost}/Reclutamiento/ws-control-de-puestos.php`,
            params
          )
          .then((response) => {
            this.loadingButton = false;
            if (response.data.status != "EXPSESSION") {
              if (response.data.status == "OK") {
                if (response.data.respuesta.status_transacsion == "MODIFIED"){
                    this.muestraBarraSnackBar( "teal darken-1", response.data.respuesta.message);
                    this.$emit('cierraSolicitudes', 1)
                } else
                    this.muestraBarraSnackBar( "pink darken-3", response.data.respuesta.message);
              } else {
                this.muestraBarraSnackBar("pink darken-3","Ocurrió un error no se actualizó el registro");
              }
            } else {
              deleteToken();
            }
          })
          .catch((e) => {
            this.muestraBarraSnackBar("pink darken-3", e);
          });
      }
    },
    muestraBarraSnackBar(color, texto) {
      this.colorBarra = color;
      this.textoBarra = texto;
      this.muestraBarra = true;
    },
  },
};
</script>

<style></style>
