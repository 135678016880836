<template>
  <v-card
    class="rounded-lg"
    min-height="200px"
    elevation="12"
    v-resize="onResize"
  >
    <div v-if="windowSize.x > 600">
      <div class="text-center">
        <v-btn
          class="rounded-lg mx-1 my-3 font-weight-bold"
          color="red darken-3"
          dark
          x-large
          @click="
            tipoCarga = 2;
            getPassword = true;
          "
        >
          <v-icon left>mdi-upload</v-icon>
          <!-- {{ $t("DH360.textBtnShowFamilies") }} -->
          cargar puntos <br />
          GL y MC
        </v-btn>
        <v-btn
          class="rounded-lg mx-1 my-3 font-weight-bold blue--text text--darken-4"
          color="#F1E549"
          x-large
          @click="
            loadingTabla = true;
            reloadRanking();
          "
        >
          <v-icon left color="blue darken-4">mdi-podium</v-icon>
          <!-- {{ $t("DH360.textBtnStartAssasment") }} -->
          recalcular ranking
        </v-btn>
        <v-btn
          class="rounded-lg mx-1 my-3 font-weight-bold"
          color="primary"
          dark
          x-large
          @click="confirmCloseProject = true"
        >
          <v-icon left>mdi-clipboard-check</v-icon>
          <!-- {{ $t("DH360.btnGenerarRanking") }} -->
          finalizar proyecto
        </v-btn>
      </div>

      <v-data-table
        :headers="headers"
        :items="rankings"
        :items-per-page="itemsPerPage"
        :footer-props="{
          'items-per-page-text': 'Elementos por página',
        }"
        dense
        no-data-text="No hay registros"
        :header-props="headerProps"
        :loading="loadingTabla"
        :search="search"
        item-key="NumeroPersonal"
        class="elevation-2"
      >
        <template v-slot:top>
          <v-row
            dense
            class="mx-0"
            style="max-height: 60px; background-color: rgb(0,120,195);"
          >
            <v-col cols="8">
              <p
                class="ma-0 mb-1 ml-5 caption grey--text text--lighten-2 font-weight-bold"
              >
                Simbología:
              </p>
              <div class="ml-3">
                <v-chip
                  class="elevation-3 mr-1 grey--text text--darken-4"
                  small
                  color="grey lighten-4"
                >
                  <v-icon left small color="amber">
                    mdi-circle
                  </v-icon>
                  No iniciada
                </v-chip>
                <v-chip
                  class="elevation-3 mr-1 grey--text text--darken-4"
                  small
                  color="grey lighten-4"
                >
                  <v-icon left small color="teal darken-1">
                    mdi-circle
                  </v-icon>
                  Aceptada
                </v-chip>
                <v-chip
                  class="elevation-3 mr-1 grey--text text--darken-4"
                  small
                  color="grey lighten-4"
                >
                  <v-icon left small color="pink darken-3">
                    mdi-circle
                  </v-icon>
                  No aceptada
                </v-chip>
              </div>
            </v-col>
            <v-col cols="4">
              <v-select
                v-model="categoriaFilterValue"
                class="mr-3 mb-0 mt-2 rounded-lg"
                style="max-height: 40px;"
                dense
                flat
                solo-inverted
                dark
                label="Filtrar por categoría"
                :items="categorias"
              ></v-select>
            </v-col>
          </v-row>

          <v-toolbar
            flat
            color="primary"
            class="rounded-lg rounded-b-0 rounded-t-0"
          >
            <v-text-field
              v-model="search"
              :label="$t('DH360.findTextBox')"
              class="mt-7 rounded-lg"
              dense
              clearable
              flat
              solo-inverted
              dark
              prepend-inner-icon="mdi-magnify"
            ></v-text-field>
            <v-spacer></v-spacer>
            <v-btn
              color="light-blue darken-1"
              dark
              class="mb-1 font-weight-bold rounded-lg"
              @click="muestraFormIncremento = true"
            >
              Añadir % de incremento
            </v-btn>
            <v-divider
              class="mx-6"
              inset
              vertical
              style="border-color:lightslategrey"
            ></v-divider>
            <vue-json-to-csv
              :json-data="rankings"
              :labels="headers_report_to_export"
              :csv-title="nameFile"
            >
              <v-btn
                color="green darken-1"
                dark
                class="mb-1 font-weight-bold rounded-lg"
                :loading="loadingTabla"
                @click="fixName"
              >
                <v-icon left>mdi-microsoft-excel</v-icon>
                {{ $t("DH360.export") }}
              </v-btn>
            </vue-json-to-csv>
          </v-toolbar>
        </template>
        <template v-slot:item.Estatus="{ item }">
          <v-chip
            v-if="item.Estatus == 1"
            class="rounded-circle elevation-4"
            small
            color="amber"
            elevation="1"
          ></v-chip>
          <v-tooltip v-else-if="item.Estatus == 3" top color="teal darken-1">
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                class="rounded-circle elevation-4"
                small
                color="teal darken-1"
                elevation="1"
                v-bind="attrs"
                v-on="on"
                @click="
                  loadingComentario = true;
                  showComments = true;
                  getComentario(item);
                "
              ></v-chip>
            </template>
            <span
              >Clic para <br />
              ver detalles</span
            >
          </v-tooltip>
          <v-tooltip v-else-if="item.Estatus == 2" top color="pink darken-3">
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                class="rounded-circle elevation-4"
                small
                color="pink darken-3"
                elevation="1"
                v-bind="attrs"
                v-on="on"
                @click="
                  loadingComentario = true;
                  showComments = true;
                  getComentario(item);
                "
              ></v-chip>
            </template>
            <span
              >Clic para <br />
              ver detalles</span
            >
          </v-tooltip>
        </template>
        <template v-slot:item.FechaActualizacion="{ item }">
          <p class="my-2">{{ item.FechaActualizacion }}</p>
        </template>
        <template v-slot:item.PorcentajeIncremento="{ item }">
          <p class="my-2 font-weight-bold primary--text">
            {{ parseFloat(item.PorcentajeIncremento).toFixed(4) }}
          </p>
        </template>
        <template v-slot:item.PuntosEval="{ item }">
          <p class="my-2 font-weight-bold">{{ item.PuntosEval.toFixed(3) }}</p>
        </template>
        <template v-slot:item.PuntosMejoras="{ item }">
          <p class="my-2">{{ item.PuntosMejoras.toFixed(2) }}</p>
        </template>
        <template v-slot:item.PuntosObjetivos="{ item }">
          <p v-if="item.PuntosObjetivos > 0" class="my-2">
            {{ item.PuntosObjetivos.toFixed(3) }}
          </p>
          <v-btn
            v-else
            class="my-2 text-decoration-underline"
            text
            small
            color="blue darken-1"
            @click="openUpdatePuntosObjetivos(item)"
          >
            {{ item.PuntosObjetivos.toFixed(1) }}
            <v-icon right small>mdi-pencil</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </div>

    <div v-else>
      <div class="text-center">
        <v-btn
          x-small
          class="rounded-lg mx-1 my-3 font-weight-bold"
          color="red darken-3"
          dark
          style="min-height: 30px !important;"
          @click="
            tipoCarga = 2;
            getPassword = true;
          "
        >
          <v-icon class="px-0 mr-1" left x-small>mdi-upload</v-icon>
          cargar puntos <br />
          GL y MC
        </v-btn>
        <v-btn
          class="rounded-lg mx-1 my-3 font-weight-bold blue--text text--darken-4"
          color="#F1E549"
          x-small
          dark
          style="min-height: 30px !important;"
          @click="
            loadingTabla = true;
            reloadRanking();
          "
        >
          <v-icon class="px-0 mr-1" left x-small color="blue darken-4"
            >mdi-podium</v-icon
          >
          <!-- {{ $t("DH360.textBtnStartAssasment") }} -->
          recalcular<br />ranking
        </v-btn>
        <v-btn
          class="rounded-lg mx-1 my-3 font-weight-bold"
          color="primary"
          dark
          x-small
          style="min-height: 30px !important;"
          @click="confirmCloseProject = true"
        >
          <v-icon class="px-0 mr-1" left x-small>mdi-clipboard-check</v-icon>
          <!-- {{ $t("DH360.btnGenerarRanking") }} -->
          finalizar <br />
          encuestas
        </v-btn>
      </div>
      <p class="caption grey--text text--darken-5 text-center mb-0">
        Simbología
      </p>
      <div class="text-center">
        <v-chip
          class="elevation-3 mr-1 grey--text text--darken-4 caption"
          x-small
          color="grey lighten-4"
        >
          <v-icon left x-small color="amber">
            mdi-circle
          </v-icon>
          No iniciada
        </v-chip>
        <v-chip
          class="elevation-3 mr-1 grey--text text--darken-4 caption"
          x-small
          color="grey lighten-4"
        >
          <v-icon left x-small color="success">
            mdi-circle
          </v-icon>
          Aceptada
        </v-chip>
        <v-chip
          class="elevation-3 mr-1 grey--text text--darken-4 caption"
          x-small
          color="grey lighten-4"
        >
          <v-icon left x-small color="red darken-3">
            mdi-circle
          </v-icon>
          No aceptada
        </v-chip>
      </div>

      <v-row dense>
        <v-col cols="8">
          <v-select
            v-model="categoriaFilterValue"
            class="mr-3 mb-0 mt-2 ml-2 rounded-lg"
            style="max-height: 40px;"
            dense
            solo
            label="Filtrar por categoría"
            :items="categorias"
          ></v-select>
        </v-col>
        <v-col cols="4">
          <v-btn
            x-small
            style="min-height: 30px !important;"
            class="rounded-lg mx-1 my-3 font-weight-bold"
            color="cyan darken-2"
            dark
            @click="muestraFormIncremento = true"
          >
            Añadir % <br />
            de incremento
          </v-btn>
        </v-col>
      </v-row>

      <v-data-table
        :headers="headers_mobile"
        :items="rankings"
        :items-per-page="itemsPerPage"
        :footer-props="{
          'items-per-page-text': 'Elementos por página',
        }"
        mobile-breakpoint="0"
        dense
        no-data-text="No hay registros"
        :header-props="headerProps"
        :loading="loadingTabla"
        :search="search"
        item-key="NumeroPersonal"
        :expanded.sync="expanded"
        show-expand
        class="elevation-2"
      >
        <template v-slot:top>
          <v-toolbar
            flat
            dense
            color="primary"
            class="rounded-lg rounded-b-0 mt-1 px-0"
          >
            <v-text-field
              v-model="search"
              :label="$t('DH360.findTextBox')"
              class="mt-7 rounded-lg subtitle-2 mr-2"
              dense
              x-small
              dark
              prepend-inner-icon="mdi-magnify"
            ></v-text-field>
            <v-spacer></v-spacer>
            <vue-json-to-csv
              :json-data="rankings"
              :labels="headers_report_to_export"
              :csv-title="nameFile"
            >
              <v-btn
                color="green darken-2"
                dark
                small
                class="mb-1 font-weight-bold rounded-lg"
                @click="fixName"
              >
                <v-icon left small>mdi-microsoft-excel</v-icon>
                {{ $t("DH360.export") }}
              </v-btn>
            </vue-json-to-csv>
          </v-toolbar>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <v-row dense>
              <v-col cols="6">
                <v-card
                  class="rounded-lg elevation-0 my-1"
                  style="border: 0.8px solid #232323;"
                  height="120"
                >
                  <v-card-title class="subtitle-2 font-weight-bold py-1">
                    Puntos obtenidos.
                  </v-card-title>
                  <v-card-text>
                    <v-row dense>
                      <v-col cols="6"
                        ><p class="caption mb-1 text-left">
                          <strong>Mejoras: </strong> {{ item.PuntosMejoras }}
                        </p></v-col
                      >
                      <v-col cols="6"
                        ><p class="caption mb-1 text-left">
                          <strong>Logors: </strong> {{ item.PuntosLogros }}
                        </p></v-col
                      >
                      <v-col cols="6"
                        ><p class="caption mb-1 text-left">
                          <strong>Objetivos: </strong>
                          {{ item.PuntosObjetivos.toFixed(3) }}
                        </p></v-col
                      >
                      <v-col cols="6"
                        ><p class="caption mb-1 text-left">
                          <strong>360: </strong> {{ item.Puntos360 }}
                        </p></v-col
                      >
                      <v-col cols="12"
                        ><p class="caption mb-1 text-center primary--text">
                          <strong>Total: </strong>
                          {{ item.PuntosEval.toFixed(3) }}
                        </p></v-col
                      >
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="6">
                <v-card
                  class="rounded-lg elevation-0 my-1"
                  style="border: 0.8px solid #232323;"
                  height="120"
                >
                  <v-card-title class="subtitle-2 font-weight-bold py-1">
                    Posiciones.
                  </v-card-title>
                  <v-card-text>
                    <v-row dense>
                      <v-col cols="12"
                        ><p class="caption mb-1 text-left">
                          <strong>Ranking general: </strong> {{ item.Ranking }}
                        </p></v-col
                      >
                      <v-col cols="12"
                        ><p class="caption mb-1 text-left">
                          <strong>Ranking {{ item.Categoria }}: </strong>
                          {{ item.RankingFinal }}
                        </p></v-col
                      >
                      <v-col cols="12"
                        ><p class="caption mb-1 text-left">
                          <strong>Incremento: </strong>
                          {{
                            parseFloat(item.PorcentajeIncremento).toFixed(2)
                          }}%
                        </p></v-col
                      >
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </td>
        </template>
        <template v-slot:item.Nombre="{ item }">
          <p class="my-2 caption">
            <strong>[{{ item.NumeroPersonal }}]</strong>{{ item.Nombre }}
          </p>
        </template>
        <template v-slot:item.Estatus="{ item }">
          <v-icon
            v-if="item.Estatus == 1"
            class="rounded-circle elevation-4"
            x-small
            color="amber"
          >
            mdi-circle
          </v-icon>
        </template>
        <template v-slot:item.PuntosEval="{ item }">
          <p class="my-2 caption">{{ item.PuntosEval.toFixed(2) }}</p>
        </template>
        <template v-slot:item.RankingFinal="{ item }">
          <p class="my-2 caption">{{ item.RankingFinal }}</p>
        </template>
      </v-data-table>
    </div>

    <v-dialog v-model="dialogCargaArchivo" persistent max-width="600">
      <v-card class="rounded-lg">
        <v-card-title class="subtitle-1 white--text primary">
          Cargar archivo de puntos.
          <v-spacer></v-spacer>
          <v-icon dark @click="dialogCargaArchivo = false"> mdi-close </v-icon>
        </v-card-title>
        <v-card-text>
          <v-file-input
            v-model="file"
            show-size
            label="Clic para seleccionar archivo"
            truncate-length="15"
            accept=".csv"
          ></v-file-input>
        </v-card-text>
        <v-card-actions v-show="file != null">
          <v-spacer></v-spacer>
          <v-btn color="cyan" class="rounded-lg" dark @click="submitFile()">
            <v-icon left> mdi-cloud-upload</v-icon>
            {{ $t("DH360.save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="muestraFormIncremento" persistent max-width="350">
      <v-card class="rounded-lg">
        <v-card-title class="subtitle-1 white--text primary">
          Agrega porcentaje de incremento.
          <v-spacer></v-spacer>
          <v-icon dark @click="muestraFormIncremento = false">
            mdi-close
          </v-icon>
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="porcentaje"
            class="mt-3 mx-2 rounded-lg"
            dense
            clearable
            outlined
            prefix="%"
            :rules="porcentajeRules"
            label="Porcentaje"
          ></v-text-field>
        </v-card-text>
        <v-card-actions v-show="/^-?\d+(\.\d{1,2})?$/.test(porcentaje)">
          <v-spacer></v-spacer>
          <v-btn
            color="teal darken-1"
            dark
            class="font-weight-bold rounded-lg"
            @click="updatePorcentaje()"
          >
            {{ $t("DH360.save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogUpdatePuntos" persistent max-width="450">
      <v-card class="rounded-lg">
        <v-card-title class="subtitle-1 white--text primary">
          Atualiza puntos de objetivos de <br />
          {{ nombreSelected }}
          <v-spacer></v-spacer>
          <v-icon dark @click="closeUpdatePuntosObjetivos">
            mdi-close
          </v-icon>
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="puntosObj"
            class="mt-3 mx-2 rounded-lg"
            dense
            clearable
            outlined
            :disabled="loadingGuardarPtosObj"
            :rules="porcentajeRules"
            label="Puntos objetivos"
          ></v-text-field>
        </v-card-text>
        <v-card-actions v-show="/^-?\d+(\.\d{1,2})?$/.test(porcentaje)">
          <v-spacer></v-spacer>
          <v-btn
            color="teal darken-1"
            dark
            :loading="loadingGuardarPtosObj"
            class="font-weight-bold rounded-lg"
            @click="
              loadingGuardarPtosObj = true;
              updatePuntosObjetivos();
            "
          >
            {{ $t("DH360.save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="getPassword" persistent max-width="450">
      <v-card class="rounded-lg">
        <v-card-title class="primary white--text subtitle-1">
          Ingresa contraseña
          <v-spacer></v-spacer>
          <v-icon dark @click="getPassword = false"> mdi-close </v-icon>
        </v-card-title>
        <v-card-text class="pt-2">
          <v-otp-input
            v-model="otpPassword"
            :disabled="loadingPassword"
            @finish="
              loadingPassword = true;
              validaPass();
            "
          ></v-otp-input>
          <v-overlay absolute :value="loadingPassword">
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </v-overlay>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showComments" max-width="450">
      <v-card class="rounded-lg">
        <v-card-title class="primary white--text subtitle-1">
          Comentarios.
          <v-spacer></v-spacer>
          <v-icon dark @click="closeComentarios">mdi-close</v-icon>
        </v-card-title>
        <v-card-text class="pt-4">
          <v-row
            v-if="loadingComentario"
            class="fill-height"
            align-content="center"
            justify="center"
          >
            <v-col class="text-subtitle-1 text-center" cols="12">
              Obteniendo información...
            </v-col>
            <v-col cols="6">
              <v-progress-linear
                color="primary"
                indeterminate
                rounded
                height="6"
              ></v-progress-linear>
            </v-col>
          </v-row>
          <div v-else>
            <p class="text-uppercase mb-1">
              <strong>fecha: </strong>
              {{ respuestaComentario.fechaRegistro }}
            </p>
            <p
              class="text-uppercase mb-1 pink--text text-darken-3"
              v-if="respuestaComentario.estatus == 2"
            >
              <strong>estatus: </strong> rechazada
            </p>
            <p
              class="text-uppercase teal--text text-darken-1"
              v-else-if="respuestaComentario.estatus == 3"
            >
              <strong>estatus: </strong> aceptada
            </p>
            <p class="text-uppercase" v-if="respuestaComentario.estatus == 2">
              <strong>comentario: </strong> {{ respuestaComentario.comentario }}
            </p>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="confirmCloseProject" persistent max-width="450">
      <v-card class="rounded-lg">
        <v-card-text>
          <p class="subtitle-1 font-weight-bold text-center pt-5">
            {{ $t("DH360.titleConfirmCloseProject") }}
          </p>
        </v-card-text>
        <v-card-actions style="display:flex; justify-content: center;">
          <v-btn
            class="rounded-lg mr-2"
            color="pink darken-1"
            dark
            width="170px"
            @click="confirmCloseProject = false"
          >
            {{ $t("DH360.cancel") }}
          </v-btn>
          <v-btn
            class="rounded-lg"
            color="teal darken-1"
            dark
            width="170px"
            @click="$emit('updateEstatus', 'TERMINADO')"
          >
            {{ $t("DH360.confirm") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <SnackBar
      :muestraBarra="muestraBarra"
      :color="colorBarra"
      :texto="textoBarra"
      @cierraBarra="muestraBarra = false"
    />
  </v-card>
</template>

<script>
import { formatDate } from "../../../../../../store/Funciones/funciones";
import { deleteToken } from "../../../../../../store/Funciones/funciones";
import axios from "axios";
import SnackBar from "../../../../General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue";
import VueJsonToCsv from "vue-json-to-csv";

export default {
  name: "admon-ranking",
  components: { SnackBar, VueJsonToCsv },
  props: ["idProyecto", "evaluacionDesempenio"],
  data() {
    return {
      rankings: [],
      expanded: [],
      headers: [
        { value: "NumeroPersonal", align: "center", text: "NumeroPersonal" },
        { value: "Nombre", align: "center", text: "Nombre" },
        { value: "Estatus", align: "center", text: "Estatus" },
        {
          value: "FechaActualizacion",
          align: "center",
          text: "FechaActualizacion",
        },
        { value: "PuntosEval", align: "center", text: "Total" },
        { value: "PuntosObjetivos", align: "center", text: "Objetivos" },
        { value: "PuntosLogros", align: "center", text: "Logros" },
        { value: "Puntos360", align: "center", text: "360" },
        { value: "PuntosMejoras", align: "center", text: "Mejoras" },
        {
          value: "PorcentajeIncremento",
          align: "center",
          text: "Incremento",
        },
        { value: "Ranking", align: "center", text: "Ranking" },
        { value: "RankingFinal", align: "center", text: "RankingFinal" },
        {
          value: "Categoria",
          align: "center",
          text: "Categoria",
          filter: this.categoriaFilter,
        },
      ],
      respuestaComentario: {
        ID: 0,
        estatus: 0,
        fechaRegistro: "",
        comentario: "",
      },
      headers_report_to_export: {
        NumeroPersonal: { title: "EMPLID" },
        Nombre: { title: "Nombre" },
        FechaActualizacion: { title: "Fecha" },
        PuntosEval: { title: "Total" },
        PuntosObjetivos: { title: "Objetivos" },
        PuntosLogros: { title: "Logros" },
        Puntos360: { title: "360" },
        PuntosMejoras: { title: "Mejoras" },
        PorcentajeIncremento: { title: "Porcentaje" },
        Ranking: { title: "Ranging general" },
        RankingFinal: { title: "Ranking categoria" },
        Categoria: { title: "Categoría" },
      },
      headers_mobile: [
        { value: "Nombre", align: "left", text: "Nombre" },
        { value: "Estatus", align: "center", text: "Estatus" },
        { value: "PuntosEval", align: "center", text: "Puntos" },
        { value: "Ranking", align: "center", text: "Ranking" },
        {
          value: "Categoria",
          align: "center",
          text: "Categoria",
          filter: this.categoriaFilter,
        },
      ],
      categorias: [
        "Todos",
        "Comite",
        "Gerencia",
        "Responsable",
        "Administrativo",
        "Servicios"
      ],
      porcentajeRules: [
        (v) => !!v || "Porcentaje is required",
        (v) => /^-?\d+(\.\d{1,5})?$/.test(v) || "Porcentaje invalido",
      ],
      itemsPerPage: 10,
      tipoCarga: 0,
      empleadoSelected: -1,
      puntosObj: 0.0,
      porcentaje: 0.0,
      nombreSelected: "",
      search: "",
      categoriaFilterValue: "",
      nameFile: "",
      colorBarra: "",
      textoBarra: "",
      otpPassword: "",
      muestraBarra: false,
      loadingTabla: false,
      muestraFormIncremento: false,
      dialogCargaArchivo: false,
      dialogUpdatePuntos: false,
      loadingGuardarPtosObj: false,
      getPassword: false,
      showPass: false,
      loadingPassword: false,
      confirmCloseProject: false,
      showComments: false,
      loadingComentario: true,
      file: null,
      headerProps: {
        sortByText: "Ordenar por",
      },
      windowSize: {
        x: 0,
        y: 0,
      },
    };
  },
  computed: {},
  mounted() {
    this.onResize();
    this.rankings = this.evaluacionDesempenio;
  },
  methods: {
    onResize() {
      this.windowSize = {
        x: window.innerWidth,
        y: window.innerHeight,
      };
    },
    fixName() {
      let numero_aleatorio =
        Math.floor(Math.random() * (99999 - 10000 + 1)) + 10000;
      this.nameFile = "ranking_" + numero_aleatorio.toString();
    },
    fixFormatDate(fecha) {
      if (fecha != null || fecha != "") {
        return formatDate(fecha.substring(0, 10));
      }

      return "";
    },

    closeComentarios() {
      this.respuestaComentario = {
        ID: 0,
        estatus: 0,
        fechaRegistro: {
          date: "",
          timezone_type: 0,
          timezone: "0",
        },
        comentario: "",
      };
      this.showComments = false;
    },

    getComentario(empleado) {
      let params = {
        Opcion: 47,
        token: localStorage.getItem("token"),
        empleado: empleado.NumeroPersonal,
        idProyecto: this.idProyecto,
      };
      axios
        .post(
          `${this.$store.getters.getHost}/RelacionesLaborales/AdmonEvaluacionesDesempenio/ws-admon-360.php`,
          params
        )
        .then((response) => {
          this.loadingComentario = false;
          console.log(response.data);
          if (response.data.status == "OK") {
            this.respuestaComentario = response.data.respuesta;
          } else if (response.data.status == "EXPRESSION") {
            deleteToken();
          } else {
            this.colorBarra = "error";
            this.textoBarra = "ocurrió un error";
            this.muestraBarra = true;
          }
        })
        .catch(function(e) {
          alert("Ocurrió un error: " + e);
        });
    },

    validaPass() {
      let params = {
        Opcion: 45,
        token: localStorage.getItem("token"),
        password: encodeURIComponent(this.otpPassword),
      };
      axios
        .post(
          `${this.$store.getters.getHost}/RelacionesLaborales/AdmonEvaluacionesDesempenio/ws-admon-360.php`,
          params
        )
        .then((response) => {
          this.loadingPassword = false;
          if (response.data.status == "OK") {
            this.dialogCargaArchivo = true;
            this.getPassword = false;
            this.otpPassword = "";
          } else if (response.data.status == "EXPRESSION") {
            deleteToken();
          } else {
            this.colorBarra = "error";
            this.textoBarra = "contraseña incorrecta";
            this.muestraBarra = true;
          }
        })
        .catch(function(e) {
          alert("Ocurrió un error: " + e);
        });
    },

    openUpdatePuntosObjetivos(item) {
      this.nombreSelected = item.Nombre;
      this.empleadoSelected = item.NumeroPersonal;
      this.dialogUpdatePuntos = true;
    },

    closeUpdatePuntosObjetivos() {
      this.nombreSelected = "";
      this.empleadoSelected = -1;
      this.puntosObj = 0.0;
      this.dialogUpdatePuntos = false;
      this.loadingGuardarPtosObj = false;
    },

    categoriaFilter(value) {
      if (!this.categoriaFilterValue) {
        return true;
      }
      if (this.categoriaFilterValue == "Todos") {
        return true;
      }
      return value
        .toLowerCase()
        .includes(this.categoriaFilterValue.toLowerCase());
    },

    submitFile() {
      let filename = "";
      if (this.tipoCarga === 2) {
        filename = "puntos_mejora_gl.csv";
      } else {
        filename = "puntos.csv";
      }

      if (this.tipoCarga == 2) {
        let reader = new FileReader();
        reader.readAsDataURL(this.file);
        reader.onload = () => {
          let params = {
            Opcion: 8,
            token: localStorage.getItem("token"),
            filename: filename,
            base64: reader.result,
          };
          axios
            .post(
              `${this.$store.getters.getHost}/General/ws-general.php`,
              params
            )
            .then((response) => {
              if (response.data == filename) {
                this.colorBarra = "success";
                this.textoBarra = "cargado con éxito. :)";
                this.muestraBarra = true;
                this.file = null;
                this.dialogCargaArchivo = false;
                this.loadingTabla = true;
                this.updatePuntosMegorasGrandesLogros();
              } else if (response.data.status == "EXPRESSION") {
                deleteToken();
              } else {
                this.colorBarra = "error";
                this.textoBarra = this.$t("DH360.unknownError");
                this.muestraBarra = true;
              }
            })
            .catch(function(e) {
              alert("Ocurrió un error: " + e);
            });
        };
      }
    },

    updatePorcentaje() {
      let params = {
        Opcion: 43,
        token: localStorage.getItem("token"),
        idProyecto: this.idProyecto,
        porcentaje: this.porcentaje,
      };
      axios
        .post(
          `${this.$store.getters.getHost}/RelacionesLaborales/AdmonEvaluacionesDesempenio/ws-admon-360.php`,
          params
        )
        .then((response) => {
          if (response.data.status == "OK") {
            this.colorBarra = "success";
            this.textoBarra = "porcentajes actualizados";
            this.muestraBarra = true;
            if (!this.loadingTabla) {
              this.loadingTabla = true;
            }
            if (this.muestraFormIncremento) {
              this.muestraFormIncremento = false;
            }
            this.getRankings();
          } else if (response.data.status == "EXPRESSION") {
            deleteToken();
          } else {
            this.colorBarra = "error";
            this.textoBarra = this.$t("DH360.unknownError");
            this.muestraBarra = true;
          }
        })
        .catch(function(e) {
          alert("Ocurrió un error: " + e);
        });
    },

    updatePuntosMegorasGrandesLogros() {
      let params = {
        Opcion: 40,
        token: localStorage.getItem("token"),
        idProyecto: this.idProyecto,
      };
      axios
        .post(
          `${this.$store.getters.getHost}/RelacionesLaborales/AdmonEvaluacionesDesempenio/ws-admon-360.php`,
          params
        )
        .then((response) => {
          if (
            response.data.status == "OK" &&
            response.data.respuesta.errores == 0
          ) {
            this.colorBarra = "success";
            this.textoBarra = "puntos actualizados";
            this.muestraBarra = true;
            this.reloadRanking();
          } else if (response.data.status == "EXPRESSION") {
            deleteToken();
          } else {
            this.colorBarra = "error";
            this.textoBarra = this.$t("DH360.unknownError");
            this.muestraBarra = true;
          }
        })
        .catch(function(e) {
          alert("Ocurrió un error: " + e);
        });
    },

    reloadRanking() {
      let params = {
        Opcion: 42,
        token: localStorage.getItem("token"),
        idProyecto: this.idProyecto,
      };
      axios
        .post(
          `${this.$store.getters.getHost}/RelacionesLaborales/AdmonEvaluacionesDesempenio/ws-admon-360.php`,
          params
        )
        .then((response) => {
          if (response.data.status == "OK") {
            this.colorBarra = "success";
            this.textoBarra = "ranking actualizado";
            this.muestraBarra = true;
            this.loadingTabla = true;
            this.updatePorcentaje();
          } else if (response.data.status == "EXPRESSION") {
            deleteToken();
          } else {
            this.colorBarra = "error";
            this.textoBarra = this.$t("DH360.unknownError");
            this.muestraBarra = true;
          }
        })
        .catch(function(e) {
          alert("Ocurrió un error: " + e);
        });
    },

    getRankings() {
      if (this.rankings.length > 0) {
        this.rankings.length = 0;
      }
      let params = {
        Opcion: 39,
        token: localStorage.getItem("token"),
        idProyecto: this.idProyecto,
      };
      axios
        .post(
          `${this.$store.getters.getHost}/RelacionesLaborales/AdmonEvaluacionesDesempenio/ws-admon-360.php`,
          params
        )
        .then((response) => {
          this.loadingTabla = false;
          if (response.data.status == "EXPRESSION") {
            deleteToken();
          } else if (response.data.status == "OK") {
            // this.rankings = response.data.respuesta;
            response.data.respuesta.map((r) => {
              this.rankings.push({
                NumeroPersonal: r.NumeroPersonal,
                Nombre: r.Nombre,
                Estatus: r.Estatus,
                FechaActualizacion: this.fixFormatDate(
                  r.FechaActualizacion.date
                ),
                PuntosEval: r.PuntosEval,
                PuntosObjetivos: r.PuntosObjetivos,
                PuntosLogros: r.PuntosLogros,
                Puntos360: r.Puntos360,
                PuntosMejoras: r.PuntosMejoras,
                AnioEvaluacion: r.AnioEvaluacion,
                PorcentajeIncremento: r.PorcentajeIncremento,
                Ranking: r.Ranking,
                RankingFinal: r.RankingFinal,
                Categoria: r.Categoria,
              });
            });
            this.loadingTabla = false;
          }
        });
    },

    updatePuntosObjetivos() {
      let params = {
        Opcion: 44,
        token: localStorage.getItem("token"),
        idProyecto: this.idProyecto,
        empleado: this.empleadoSelected,
        puntos: this.puntosObj,
      };

      axios
        .post(
          `${this.$store.getters.getHost}/RelacionesLaborales/AdmonEvaluacionesDesempenio/ws-admon-360.php`,
          params
        )
        .then((response) => {
          if (response.data.status == "OK") {
            this.colorBarra = "success";
            this.textoBarra = "porcentajes actualizados";
            this.reloadRanking();
            this.closeUpdatePuntosObjetivos();
          } else if (response.data.status == "EXPRESSION") {
            deleteToken();
          } else {
            this.colorBarra = "error";
            this.textoBarra = this.$t("DH360.unknownError");
            this.muestraBarra = true;
          }
        })
        .catch((e) => {
          alert(e);
        });
    },
  },
};
</script>
