<template>
  <v-card class="v-lazy pa-4" style="min-height: 52px">
    <div style="background-color: #00599f; margin-bottom: 20px; color:white; border-radius: 8px; font-weight: bold"
      class="text-center pa-2">Registro Patronal</div>

 
    <v-progress-linear 
      v-if="loading" 
      indeterminate 
      color="blue"
      class="mb-4"
    ></v-progress-linear>

    <v-form v-if="proveedorData && !loading" ref="form" v-model="valid" lazy-validation>
      <v-text-field label="Número de Proveedor" outlined dense required v-model="numeroProveedor" :rules="numeroRules"
        :disabled="true"></v-text-field>

      <v-text-field label="Razón Social" outlined dense required v-model="razonSocial" :rules="razonRules"
        :disabled="true"></v-text-field>

      <v-text-field label="Domicilio Particular" outlined dense required v-model="domicilio"
        :rules="domicilioRules"></v-text-field>

      <v-text-field label="Contacto del Responsable" outlined dense required v-model="contacto"></v-text-field>

      <v-text-field label="Correo" outlined dense required v-model="email" :rules="emailRules"></v-text-field>

      <v-text-field label="Número de Trabajadores" outlined dense type="number" min="1" max="100" required
        v-model="numTrabajadores" :rules="numTrabajadorRules"></v-text-field>

      <div style="background-color: #00599f; margin-bottom: 20px; color:white; border-radius: 8px; font-weight: bold"
        class="text-center pa-2">Datos STPS</div>

      <v-text-field label="Número de Prestador de Servicio" outlined dense required v-model="numPrestador"
        :rules="numPrestadorRules"></v-text-field>

      <v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y
        min-width="auto">
        <template v-slot:activator="{ on, attrs }">
          <v-text-field v-model="date" label="Vigencia del Permiso" outlined dense readonly v-bind="attrs" v-on="on"
            append-icon="mdi-calendar" :rules="datuRules"></v-text-field>
        </template>
        <v-date-picker v-model="date" @input="menu2 = false"></v-date-picker>
      </v-menu>
      <v-textarea outlined name="input-7-4" label="Objeto Social" required v-model="objetoSocial"
        :rules="objetoRules"></v-textarea>
      <div class="text-center pa-2">
        <v-btn style="font-weight: bold;" :disabled="!valid" color="success" class="mr-4" @click="guardarInformacion">
          Guardar Información
        </v-btn>
      </div>
      <div
        style="background-color: #00599f; margin-bottom: 20px; margin-top:20px; color:white; border-radius: 8px; font-weight: bold"
        class="text-center pa-2">Archivos PDF</div>
      <div>
        <v-simple-table>
          <tbody>
            <tr v-for="(file, index) in documentos" :key="index">
              <td>{{ file.name }}</td>
              <td>
                <div style="display: flex; justify-content: flex-end; gap: 0;">
                  <v-btn color="cyan" style="width: 80px; margin-right: 4px;" dark class="rounded-lg"
                    @click="viewFile(file)" outlined>
                    <v-icon left>mdi-eye-outline</v-icon>Ver
                  </v-btn>
                  <v-btn :color="file.enviado ? 'teal darken-2' : 'primary'" dark class="rounded-lg"
                    @click="dialogUpload = true; auxFileName = file.name; auxFile = file" style="width: 100px;">
                    {{ file.enviado ? 'Editar' : 'Cargar' }}
                    <v-icon right>mdi-cloud-upload</v-icon>
                  </v-btn>
                </div>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </div>

    </v-form>
    <cargaArchivoREPSE :dialogUpload="dialogUpload" :fileName="auxFileName" :fileType="'PTR'"
      @cierraUpload="cierraUpload" ref="uploadComponent" />

    <div class="text-center ma-2">
      <v-snackbar v-model="snackbar" color="#0071ba">
        {{ text }}
        <template v-slot:action="{ attrs }">
          <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
            Cerrar
          </v-btn>
        </template>
      </v-snackbar>
    </div>
  </v-card>
</template>

<script>
import axios from 'axios';
import cargaArchivoREPSE from './cargaArchivoREPSE.vue';
export default {
  components: { cargaArchivoREPSE },
  data: () => ({
    loading: false, 
    fileType: 'PTR',
    dialogUpload: false,
    auxFileName: '',
    auxFile: null,
    proveedorData: '',
    documentos: [
      { id: 1, name: 'Acta Constitutiva', file: null, enviado: false },
      { id: 2, name: 'Comprobante de domicilio', file: null, enviado: false },
      { id: 3, name: 'INE Representante Legal', file: null, enviado: false },
      { id: 4, name: 'Permiso de STPS', file: null, enviado: false },
      { id: 5, name: 'Registro Patronal ante el IMSS', file: null, enviado: false },
      { id: 6, name: 'Formato de Autorizacion ante el SAT', file: null, enviado: false },
    ],
    valid: true,
    snackbar: false,
    text: "",
    numeroProveedor: '',
    numeroRules: [v => !!v || 'El Número de Proveedor es requerido', v => /^\d+$/.test(v) || 'Ingrese solo números'],
    razonSocial: '',
    razonRules: [v => !!v || 'La Razón Social es requerida'],
    domicilio: '',
    domicilioRules: [v => !!v || 'El Domicilio es requerido'],
    contacto: '',
    contactoRules: [v => !!v || 'El Contacto es requerido', v => /.+@.+\..+/.test(v) || 'Contacto Inválido'],
    numTrabajadores: '',
    numTrabajadorRules: [v => !!v || 'El Número de Trabajadores es requerido', v => /^\d+$/.test(v) || 'Ingrese solo números'],
    numPrestador: '',
    numPrestadorRules: [v => !!v || 'El Número de Prestador es requerido', v => /^\d+$/.test(v) || 'Ingrese solo números'],
    objetoSocial: '',
    objetoRules: [v => !!v || 'El Objeto Social es requerido'],
    emailRules: [
      v => !!v || 'E-mail es requerido',
      v => v === 'Sin correo' || /.+@.+\..+/.test(v) || 'Ingrese un correo válido',
    ],
    date: '',
    menu2: false,
    datuRules: [v => !!v || 'La Fecha de Vigencia es requerida'],
  }),
  computed: {
    userId() {
      return this.$store.getters.getUser.Folio;
    }
  },
  watch: {
    userId(newVal) {
      if (newVal) {
        this.getProveedor();
      }
    }
  },
  mounted() {
    if (this.userId && this.userId !== 'Folio') {
      this.getProveedor();
    }
  },

  methods: {
    showSnackbar(message) {
      this.text = message;
      this.snackbar = true;
    },
    async getProveedor() {
      this.loading = true;
      try {
        const response = await axios.get(`${this.$store.getters.getHostNode}/api/getProveedor/${this.userId}`, {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        });

        this.proveedorData = response.data.data[0];
        this.numeroProveedor = this.userId || '';
        this.email = this.proveedorData.Correo || '',
        this.razonSocial = this.proveedorData.Nombre || '';
        this.domicilio = this.proveedorData.Domicilio || '';
        this.contacto = this.proveedorData.Contacto || '';
        this.numTrabajadores = this.proveedorData.No_trabajadores || '';
        this.numPrestador = this.proveedorData.No_STPS || '';
        this.objetoSocial = this.proveedorData.Objeto_social || '';
        this.date = this.proveedorData.Vigencia?.split('T')[0] || '';

        if (!this.numeroProveedor || !this.razonSocial) {
          this.showSnackbar('Error: El proveedor no tiene número o razón social');
          return;
        }

        await this.existeDocumento();

      } catch (error) {
        this.showSnackbar('Error al obtener la información del proveedor');
        console.error(error);
      } finally {
        this.loading = false; 
      }
    },

    cierraUpload(response) {
      if (response !== null && this.auxFile) {
        this.auxFile.enviado = response;
        this.dialogUpload = false;
      }
      this.dialogUpload = false;
    },
    async viewFile(file) {
      try {
        const response = await axios.get(`${this.$store.getters.getHostNode}/api/descargarArchivo/${file.name}/${this.userId}`, {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        });

        const { metadata, file: base64File } = response.data;

        const iv = new Uint8Array(JSON.parse(metadata.iv));

        const key = await this.importKey(JSON.parse(metadata.key));
        const encryptedData = Uint8Array.from(atob(base64File), c => c.charCodeAt(0)).buffer;
        const decryptedArrayBuffer = await crypto.subtle.decrypt(
          {
            name: 'AES-GCM',
            iv: iv,
          },
          key,
          encryptedData
        );
        const decryptedBlob = new Blob([new Uint8Array(decryptedArrayBuffer)], { type: 'application/pdf' });
        const decryptedUrl = URL.createObjectURL(decryptedBlob);
        window.open(decryptedUrl);
      } catch (error) {
        this.showSnackbar('Primero carga un archivo');
      }
    },
    async existeDocumento() {
      try {
        const documentosPromises = this.documentos.map(async (documento) => {
          const response = await axios.get(`${this.$store.getters.getHostNode}/api/existeDocumento/${documento.name}/${this.userId}`, {
            headers: {
              Authorization: localStorage.getItem("token"),
            },
          });
          
          if (response.data.status === 'OK') {
            documento.enviado = true;
          }
        });
        await Promise.all(documentosPromises);
      } catch (error) {
        console.error('Error al verificar los documentos:', error);
      }
    },
    async importKey(jwk) {
      const key = await crypto.subtle.importKey(
        'jwk',
        jwk,
        {
          name: 'AES-GCM',
        },
        true,
        ['encrypt', 'decrypt']
      );
      return key;
    },
    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    async guardarInformacion() {
      const datosPatronales = {
        numeroProveedor: this.userId,
        domicilio: this.domicilio,
        contacto: this.contacto,
        numTrabajadores: this.numTrabajadores,
        numPrestador: this.numPrestador,
        objetoSocial: this.objetoSocial,
        vigencia: this.date,
        email: this.email
      };

      try {
        const response = await axios.post(`${this.$store.getters.getHostNode}/api/guardarDatosPatronales`, datosPatronales, {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        });
        if(response.data.status){
          this.showSnackbar('Información guardada con éxito');
        }else{
          this.showSnackbar('La información no ha sido guardada');
        }
       
      } catch (error) {
        this.showSnackbar('Error al guardar la información');
        console.error("Error en la solicitud:", error);
      }
    },

  },
};
</script>
