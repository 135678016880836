<template>
  <v-dialog
    v-model="dialogMustraCalendario"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar dark color="primary">
        <v-toolbar-title
          >Calendario de {{ name }} - {{ emplid }}</v-toolbar-title
        >
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn dark icon @click="closeCalendar()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text>
        <v-sheet height="600">
          <v-container fluid>
            <v-card class="rounded-lg">
              <v-sheet tile height="54" class="d-flex rounded-lg">
                <v-btn icon class="ma-2" @click="$refs.calendar.prev()">
                  <v-icon>mdi-chevron-left</v-icon>
                </v-btn>

                <v-btn icon class="ma-2" @click="$refs.calendar.next()">
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
                <v-toolbar-title v-if="$refs.calendar" style="margin-top: 8px;">
                  {{ $refs.calendar.title }}
                </v-toolbar-title>
                <v-spacer></v-spacer>
              </v-sheet>
              <v-calendar
                class="rounded-lg"
                ref="calendar"
                v-model="value"
                :weekdays="weekday"
                :type="type"
                :events="events"
                :event-overlap-mode="mode"
                :event-overlap-threshold="30"
                @click:event="showEvent"
              >
                <template v-slot:event="{ event }">
                  <div :class="event['event-text-color']" elevation="3" class="rounded-pill">
                    <v-icon
                      small
                      :color="event['event-text-color'].replace('--text', '')"
                    >
                      {{ event.icon }}
                    </v-icon>
                    <span style="font-weight: bold;">{{
                      event.formatoHora
                    }}</span>
                    {{ event.name }}
                  </div>
                </template>
              </v-calendar>
            </v-card>
          </v-container>

          <v-menu
            v-model="selectedOpen"
            :close-on-content-click="false"
            :activator="selectedElement"
            offset-x
            max-width="100%"
            min-height="150px"
            transition="scale-transition"
            rounded="lg"
          >
            <v-card
              elevation="24"
              color="grey lighten-4"
              max-width="100%"
              min-height="150px"
              flat
            >
              <v-toolbar :color="selectedEvent.color" dense>
                <v-toolbar-title
                  v-html="selectedEvent.name"
                  :class="selectedEvent['event-text-color']"
                ></v-toolbar-title>
                <v-spacer></v-spacer>
              </v-toolbar>
              <v-card-text>
                <span
                  v-show="
                    respuestaDetalles.length == 0 && !loadingButtonDetails
                  "
                  v-html="selectedEvent.details"
                ></span>
                <!-- <span v-html="selectedEvent"></span> -->
                <!-- <div class="text-center" > -->
                <div
                  v-if="loadingButtonDetails && respuestaDetalles.length == 0"
                  style="display:flex; justify-content:center;"
                >
                  <!-- <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular> -->
                  <div class="loader"></div>
                </div>
                <div
                  v-else-if="
                    !loadingButtonDetails && respuestaDetalles.length > 0
                  "
                >
                  <div v-for="(item, index) in respuestaDetalles" :key="index">
                    <p class="mb-0">
                      <strong>Solicitada el día: </strong>{{ item.FLARGA }}
                    </p>
                    <p
                      class="mb-0"
                      v-if="item.descIncidencia != 'Tiempo por tiempo'"
                    >
                      <strong>Del día: </strong>{{ item.fechaInIncidencia }}
                      <strong> al </strong>{{ item.fechaFiIncidencia }}.
                    </p>
                    <p class="mb-0" v-else>
                      <strong>Descansa el día: </strong
                      >{{ item.fechaInIncidencia }}.
                      <strong> Por el dia trabajado: </strong
                      >{{ item.fechaFiIncidencia }}.
                    </p>
                  </div>
                </div>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  v-show="respuestaDetalles.length == 0"
                  class="rounded-lg"
                  color="teal darken-2"
                  small
                  dark
                  :loading="loadingButtonDetails"
                  @click="
                    muestraDetalles(selectedEvent.idIncidencia);
                    loadingButtonDetails = true;
                  "
                >
                  Detalles
                </v-btn>
                <v-btn
                  class="rounded-lg"
                  v-show="selectedEvent.estatus == 1"
                  small
                  dark
                  color="red darken-2"
                  :loading="loadingBotonCancelar"
                  v-on:click="cancelaIncidencia(selectedEvent.idIncidencia)"
                >
                  Cancelar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>
        </v-sheet>
      </v-card-text>
    </v-card>

    <v-dialog v-model="dialogCargandoInf" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          {{ textoCargando }}
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>
<script>
import axios from "axios";
import { deleteToken } from "../../../../../../store/Funciones/funciones";
export default {
  name: "acumulados-vacaciones",
  components: {},
  props: ["dialogMustraCalendario", "emplid", "name"],
  data() {
    return {
      type: "month",
      mode: "stack",
      textoCargando: "Cargando ",
      value: "",
      modes: ["stack", "column"],
      types: ["mes", "semana", "día", "4día"],
      events: [],
      secondarray: [],
      secondarray2: [],
      festivos: [],
      incidencias: [],
      estatusIncidencias: [],
      respuestaIncidenciaCancelada: [],
      respuestaDetalles: [],
      weekday: [0, 1, 2, 3, 4, 5, 6],
      weekdays: [
        {
          text: "Dom - Sab",
          value: [0, 1, 2, 3, 4, 5, 6],
        },
        {
          text: "Lun - Dom",
          value: [1, 2, 3, 4, 5, 6, 0],
        },
        {
          text: "Lun - Vie",
          value: [1, 2, 3, 4, 5],
        },
        {
          text: "Lun, Mie, Vie",
          value: [1, 3, 5],
        },
      ],
      colors: [
        "yellow lighten-3",
        "green",
        "red",
        "red darken-4",
        "green darken-4",
        "grey darken-1",
        // 'yellow accent-4',
        "deep-purple lighten-1",
        "blue",
        "blue darken-4",
        "blue darken-2",
        "blue-grey lighten-3",
      ],
      colorsTema: [
        {
          color: "yellow lighten-3",
          tema: false,
        },
        {
          color: "green",
          tema: true,
        },
        {
          color: "red",
          tema: true,
        },
        {
          color: "red darken-4",
          tema: true,
        },
        {
          color: "green darken-4",
          tema: true,
        },
        {
          color: "grey darken-1",
          tema: true,
        },
        {
          color: "deep-purple lighten-1",
          tema: true,
        },
        {
          color: "blue",
          tema: true,
        },
        {
          color: "blue darken-4",
          tema: true,
        },
        {
          color: "blue darken-2",
          tema: true,
        },
        {
          color: "blue-grey lighten-3",
          tema: true,
        },
      ],
      colorsText: [
        "yellow--text text--lighten-3",
        "green--text text--",
        "red--text text--",
        "red--text text--darken-4",
        "green--text text--darken-4",
        "grey--text text--darken-1",
        "yellow--text text--accent-4",
        "blue--text text--",
        "blue--text text--darken-4",
        "blue--text text--darken-2",
        "blue-grey--text text--lighten-3",
        "black--text",
        "white--text",
      ],
      selectedElement: null,
      selectedOpen: false,
      loadingButtonDetails: false,
      loadingBotonCancelar: false,
      selectedEvent: {},
    };
  },
  computed: {
    dialogCargandoInf: function() {
      return this.textoCargando != "Cargando ";
    },
    
  },
  mounted() {
    this.getStatusIncedence();
  },
  methods: {
    validaComentario(comentario){
      return [null, undefined, ''].includes(comentario) ? "Sin comentario" : comentario;
    },
    getEvents() {
      var params = {
        Opcion: 1,
        token: localStorage.getItem("token"),
        esAdmin: true,
        empleado: this.emplid,
      };
      var paramsIncidencias = {
        Opcion: 2,
        token: localStorage.getItem("token"),
        esAdmin: true,
        empleado: this.emplid,
      };
      var paramsHoliday = {
        Opcion: 3,
        token: localStorage.getItem("token"),
        esAdmin: true,
        empleado: this.emplid,
      };
      this.textoCargando += "checadas...";
      axios
        .post(
          `${this.$store.getters.getHost}/Incidencias/ws-incidencias-calendario.php`,
          params
        )
        .then((response) => {
          if (response.data.checadas != null) {
            var data = response.data.checadas.map((checada) => {
              this.checkIn = new Date(checada["ENTRADA"]);
              this.checkOut = new Date(checada["SALIDA"]);
              this.colorCheck = this.getColor(11);
              this.secondarray.push({
                name: "Llegada",
                start: this.checkIn,
                formatoHora:
                  this.modifyHours(this.checkIn.getHours()) +
                  ":" +
                  this.modifyMinutes(this.checkIn.getMinutes()),
                color: this.colorCheck,
                timed: true,
                "event-text-color": this.colorsText[8],
              });
              if (this.checkIn.getHours() != this.checkOut.getHours()) {
                this.secondarray.push({
                  name: "Salida",
                  start: this.checkOut,
                  formatoHora:
                    this.modifyHours(this.checkOut.getHours()) +
                    ":" +
                    this.modifyMinutes(this.checkOut.getMinutes()),
                  color: this.colorCheck,
                  timed: true,
                  "event-text-color": this.colorsText[8],
                });
              }
              return checada;
            });
            this.secondarray2 = data;
          }
          this.textoCargando = this.textoCargando.replace("checadas...", "");
        })
        .catch(function(e) {
          let result = e.toString().includes("401");
          if (result) {
            window.location.reload();
          } else {
            alert(e);
          }
        });
      this.textoCargando += "incidencias...";
      axios
        .post(
          `${this.$store.getters.getHost}/Incidencias/ws-incidencias-calendario.php`,
          paramsIncidencias
        )
        .then((response) => {
          // //(response.data.incidencias)
          var data = response.data.incidencias.map((incidencia) => {
            var opc = incidencia["Estatus"];

            var textColor = this.colorsText[12];
            var descripocionIncidencia = "";
            var icono = "";
            var detalleEstatus =
              "Estatus: " + this.estatusIncidencias[opc - 1]["EstDescripcion"];

            if (incidencia["TipoIncidencia"] == 2) {
              icono = "mdi-door-open";
            } else if (incidencia["TipoIncidencia"] == 6) {
              icono = "mdi-arrow-decision";
            } else if (incidencia["TipoIncidencia"] == 5) {
              icono = "mdi-clock-alert-outline";
            } else if (incidencia["TipoIncidencia"] == 4) {
              icono = "mdi-account-child-circle";
            } else if (incidencia["TipoIncidencia"] == 1) {
              icono = "mdi-beach";
            } else if (
              incidencia["TipoIncidencia"] == 7 ||
              incidencia["TipoIncidencia"] == 11
            ) {
              icono = "mdi-home";
            }

            if (incidencia["TipoIncidencia"] == 4 && incidencia["FechaInicialDes"] != incidencia["FechaFinalDes"]) {
              descripocionIncidencia =
                incidencia["IncDescripcion"] +
                ": " +
                incidencia["PermDescripcion"] + ". " + this.validaComentario(incidencia["Comentario"]);
            } else if (incidencia["TipoIncidencia"] == 4 && incidencia["FechaInicialDes"] == incidencia["FechaFinalDes"]) {
              descripocionIncidencia =
                incidencia["PermDescripcion"] + ". " + this.validaComentario(incidencia["Comentario"]);
            } else if ([7, 11].includes(incidencia["TipoIncidencia"])) {
              descripocionIncidencia =
              incidencia["IncDescripcion"] + ". " + this.validaComentario(incidencia["Comentario"]);
            } else {
              descripocionIncidencia = incidencia["IncDescripcion"];
            }

            this.colorIncidences = this.getColor(opc);
            if (opc == 1 || opc == 2 || opc == 7) {
              textColor = this.colorsText[11];
            }

            if (
              incidencia["FechaInicialDes"] == incidencia["FechaFinalDes"] ||
              incidencia["FechaFinalDes"] == null
            ) {
              var fecha_i = new Date(incidencia["FechaInicialDes"]);

              if (incidencia["TipoIncidencia"] == 2 ) {
                this.secondarray.push({
                  icon: icono,
                  name: 'S. ant.' + ' '+incidencia["Comentario"]+'.',
                  start: fecha_i,
                  color: this.colorIncidences,
                  "event-text-color": textColor,
                  details: detalleEstatus,
                  estatus: incidencia["Estatus"],
                  idIncidencia: incidencia["ID"],
                });
              } else if (incidencia["TipoIncidencia"] == 5 ) {
                this.secondarray.push({
                  icon: icono,
                  name: 'Lleg. tard.' + ' '+ this.validaComentario(incidencia["Comentario"])+'.',
                  start: fecha_i,
                  color: this.colorIncidences,
                  "event-text-color": textColor,
                  details: detalleEstatus,
                  estatus: incidencia["Estatus"],
                  idIncidencia: incidencia["ID"],
                });
              } else {
                this.secondarray.push({
                  icon: icono,
                  name: descripocionIncidencia,
                  start: fecha_i,
                  color: this.colorIncidences,
                  "event-text-color": textColor,
                  details: detalleEstatus,
                  estatus: incidencia["Estatus"],
                  idIncidencia: incidencia["ID"],
                });
              }
            } else {
              var i = new Date(incidencia["FechaInicialDes"]);
              var f = new Date(incidencia["FechaFinalDes"]);
              this.secondarray.push({
                icon: icono,
                name: descripocionIncidencia,
                start: i,
                end: f,
                color: this.colorIncidences,
                "event-text-color": textColor,
                details: detalleEstatus,
                estatus: incidencia["Estatus"],
                idIncidencia: incidencia["ID"],
              });
            }
            return incidencia;
          });
          this.incidencias = data;
          this.textoCargando = this.textoCargando.replace("incidencias...", "");
        })
        .catch(function(e) {
          let result = e.toString().includes("401");
          if (result) {
            window.location.reload();
          } else {
            alert(e);
          }
        });
      this.textoCargando += "días festivos...";
      axios
        .post(
          `${this.$store.getters.getHost}/Incidencias/ws-incidencias-calendario.php`,
          paramsHoliday
        )
        .then((response) => {
          var data = response.data.festivos.map((incidencia) => {
            var i = new Date(incidencia["dca_fecdia"]);

            this.colorHoliday = this.getColor(3);
            this.secondarray.push({
              name: incidencia["DESCR"],
              start: i,
              color: this.colorHoliday,
              timed: false,
              "event-text-color": "",
            });
            return incidencia;
          });
          this.festivos = data;
          this.$nextTick(function() {
            this.textoCargando = this.textoCargando.replace(
              "días festivos...",
              ""
            );
          });
        })
        .catch(function(e) {
          let result = e.toString().includes("401");
          if (result) {
            window.location.reload();
          } else {
            alert(e);
          }
        });

      this.events = this.secondarray;
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        this.respuestaDetalles.length = 0;
        requestAnimationFrame(() =>
          requestAnimationFrame(() => (this.selectedOpen = true))
        );
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        this.respuestaDetalles.length = 0;
        // console.log("prueba")
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
    muestraDetalles(id) {
      this.respuestaDetalles.length = 0;
      var params = {
        Opcion: 7,
        id: id,
        token: localStorage.getItem("token"),
      };
      axios
        .post(
          `${this.$store.getters.getHost}/Incidencias/ws-incidencias-calendario.php`,
          params
        )
        .then((response) => {
          var data = response.data.detalleIncidencia;
          this.respuestaDetalles = data;
          this.loadingButtonDetails = false;
        })
        .catch(function(e) {
          let result = e.toString().includes("401");
          if (result) {
            window.location.reload();
          } else {
            alert(e);
          }
        });
    },
    cancelaIncidencia: function(id) {
      var params = {
        Opcion: 6,
        idIncidencia: id,
        token: localStorage.getItem("token"),
      };

      if (window.confirm("¿Deseas cancelar esta incidencia?")) {
        this.loadingBotonCancelar = true;
        axios
          .post(
            `${this.$store.getters.getHost}/Incidencias/ws-incidencias-autorizacion.php`,
            params
          )
          .then((response) => {
            var data = response.data.incidencias;
            this.respuestaIncidenciaCancelada = data;
            this.validaRespuestaCancelacion();
          })
          .catch(function(e) {
            let result = e.toString().includes("401");
            if (result) {
              window.location.reload();
            } else {
              alert(e);
            }
          });
      }
    },
    getColor(opc) {
      let color = "";

      switch (opc) {
        case 1:
          color = this.colors[opc - 1];
          break;
        case 2:
          color = this.colors[opc - 1];
          break;
        case 3:
          color = this.colors[opc - 1];
          break;
        case 4:
          color = this.colors[opc - 1];
          break;
        case 5:
          color = this.colors[opc - 1];
          break;
        case 6:
          color = this.colors[opc - 1];
          break;
        case 7:
          color = this.colors[opc - 1];
          break;
        case 8:
          color = this.colors[opc - 1];
          break;
        case 9:
          color = this.colors[opc - 1];
          break;
        case 10:
          color = this.colors[opc - 1];
          break;
        case 11:
          color = this.colors[opc - 1];
          break;
        default:
          color = "white";
          break;
      }

      return color;
    },
    getStatusIncedence() {
      let paramsEstatusInc = {
        Opcion: 4,
        token: localStorage.getItem("token"),
      };
      //(paramsEstatusInc)
      this.textoCargando += "estatus...";
      axios
        .post(
          `${this.$store.getters.getHost}/Incidencias/ws-incidencias-calendario.php`,
          paramsEstatusInc
        )
        .then((response) => {
          if (response.data.status == "EXPSESSION") {
            deleteToken();
          } else {
            var data = response.data.estatusIncidencias;
            this.estatusIncidencias = data;
            this.textoCargando = this.textoCargando.replace("estatus...", "");
            // this.tiposIncidencias();
            this.getEvents();
          }
        })
        .catch(function(e) {
          let result = e.toString().includes("401");
          if (result) {
            window.location.reload();
          } else {
            alert(e);
          }
        });
    },
    modifyHours(hours) {
      if (hours < 10) {
        return "0" + hours.toString();
      }

      return hours.toString();
    },
    modifyMinutes(minutes) {
      if (minutes < 10) {
        return "0" + minutes.toString();
      }

      return minutes.toString();
    },
    closeCalendar() {
      this.events = [];
      this.secondarray = [];
      this.secondarray2 = [];
      this.festivos = [];
      this.incidencias = [];
      this.estatusIncidencias = [];
      this.respuestaIncidenciaCancelada = [];
      this.respuestaDetalles = [];
      this.$emit("cierraCalendario");
    },
    validaRespuestaCancelacion() {
      this.loadingBotonCancelar = false;
      if (this.respuestaIncidenciaCancelada == 1) {
        this.selectedOpen = false;
        this.textoBarra = "Incidencia cancelada con éxito";
        this.colorBarra = "primary";
        this.muestraBarra = true;
      } else {
        this.textoBarra = "Ocurrió un error, no se cancelo la incidencia";
        this.colorBarra = "red accent-2";
        this.muestraBarra = true;
      }

      Array.prototype.remove =
        Array.prototype.remove ||
        function() {
          this.splice(0, this.length);
        };

      this.events.remove();
      this.secondarray.remove();
      this.secondarray2.remove();
      this.festivos.remove();
      this.incidencias.remove();

      this.getEvents();
    },
  },
};
</script>
