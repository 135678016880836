import axios from 'axios'
import SnackBar from '../../General/MisIncidencias/components/componentsExtra/MuestraCalendarioDiasSeleccionados.vue'

export default {
    name: 'insignias',
    components: {
        SnackBar
    },
    props: [],
    data() {
        return {
            headers: [{
                text: 'Puesto',
                sortable: false,
                value: 'Puesto',
            },
            {
                text: 'Valorado',
                sortable: false,
                value: 'Valorado'
            },
            {
                text: '# Empleado',
                sortable: false,
                value: 'NoPersonalValorado'
            },
            {
                text: 'Principio 1',
                sortable: false,
                value: 'Principio 1'
            },
            {
                text: 'Principio 2',
                sortable: false,
                value: 'Principio 2'
            },
            {
                text: 'Principio 3',
                sortable: false,
                value: 'Principio 3'
            },
            {
                text: 'Principio 4',
                sortable: false,
                value: 'Principio 4'
            },
            {
                text: 'Principio 5',
                sortable: false,
                value: 'Principio 5'
            },
            {
                text: 'Principio 6',
                sortable: false,
                value: 'Principio 6'
            },
            {
                text: 'Total',
                sortable: false,
                value: 'total'
            },
            ],
            colaboradores: [],
            loadingData: true,
            muestraBarra: false,
            textoBarra: "",
            colorBarra: "pink",
            search: "",

        }
    },
    computed: {
    },
    mounted() {
        this.getRanking();
    },
    methods: {
        getRanking() {
            this.loadingData = true;

            let params = {
                Opcion: 6,
                token: localStorage.getItem('token')
            }
            axios.post(`${this.$store.getters.getHost}/Valores/ws-valores.php`, params).then((response) => {
                var data = response.data.datosRanking;
                if (data) {
                    this.colaboradores = data;
                }
                this.loadingData = false;
            })
                .catch(function (e) {

                    let result = e.toString().includes("401");
                    if (result) {
                        window.location.reload();
                    } else {
                        alert(e);
                    }
                });

        }

    }
}