<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <v-card>
      <v-card-title class="text-subtitle-2 primary white--text">
        Detalles
        <v-spacer></v-spacer>
        <v-icon small dark @click="$emit('cierraDialog', 'Cancelar')"
          >mdi-close</v-icon
        >
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-card elevation="2" class="pa-5">
            <v-row class="d-flex justify-center align-center">
              <v-col
                align="center"
                cols="12"
                xl="4"
                lg="4"
                md="4"
                sm="12"
                xs="12"
              >
                <v-avatar size="150" tile>
                  <img
                    v-if="fotoPerfil"
                    alt=""
                    :src="fotoPerfil"
                    aling="absmiddle"
                    width="200px"
                  />
                  <img
                    v-else
                    alt=""
                    :src="'https://community.f5.com/legacyfs/online/avatars/0051T000008uOPOQA2.png'"
                    aling="absmiddle"
                    width="200px"
                  />
                </v-avatar>
              </v-col>
              <v-col
                class="align-center black--text"
                align="left"
                cols="12"
                xl="8"
                lg="8"
                md="8"
                sm="12"
                xs="12"
              >
                <b>EMPLID</b> {{ item.NPBaja }} <br />
                <b>Nombre</b> {{ item.Nombre }} <br />
                <b>Puesto</b> {{ item.Puesto }} <br />
                <b>Departamento</b> [{{ item.NumDepto }}]
                {{ item.Departamento }} <br />
                <b>Proceso</b> {{ item.Proceso }} <br />
                <v-card-actions>
                  <v-btn
                    v-if="item.Estatus < 2"
                    color="teal darken-4"
                    dark
                    small
                    block
                    @click="openForm"
                  >
                    <v-icon small>mdi-pencil</v-icon> Modificar baja</v-btn
                  >
                  <div
                    v-else
                    class="font-italic text-center mx-auto text-decoration-underline text--secondary"
                  >
                    Ya se ha modificado una vez este registro
                  </div>
                </v-card-actions>
              </v-col>
            </v-row>
            <v-divider class="my-2"></v-divider>
            <v-row class="align-center">
              <v-col
                class="black--text"
                align="left"
                cols="12"
                xl="6"
                lg="6"
                md="6"
                sm="12"
                xs="12"
              >
                <b>Domicilio</b> {{ item.Domicilio }} <br />
                <b>Colonia</b> {{ item.Colonia }} <br />
                <b>Ciudad</b> {{ item.Ciudad }} <br />
                <b>Entidad Federativa:</b> {{ item.EntidadFed }} <br />
                <b>Telefono:</b> {{ item.Telefono }} <br />
              </v-col>
              <v-col
                class="black--text"
                align="left"
                cols="12"
                xl="6"
                lg="6"
                md="6"
                sm="12"
                xs="12"
              >
                <b>Fecha de ingreso:</b> {{ item.FechaIngreso }} <br />
                <b>Antiguedad:</b> {{ item.Meses }} <br />
                <b>Fecha de despido:</b> {{ item.UltimoDiaLaboral }} <br />
                <b>Fecha de baja:</b> {{ item.FechaEBaja }} <br />
              </v-col>
            </v-row>
            <v-divider class="my-2"></v-divider>
            <v-row class="align-center">
              <v-col
                class="black--text"
                align="left"
                cols="12"
                xl="12"
                lg="12"
                md="12"
                sm="12"
                xs="12"
              >
                <b>Motivo General:</b> {{ item.MotivoBajaNominas }} <br />
                <b>Motivo 1:</b> {{ item.DetalleMotivoBaja }} <br />
                <b>Motivo 2:</b> {{ item.DetalleMotivoBaja2 }} <br />
                <div v-if="item.Estatus == 0" class="font-italic mx-auto text-decoration-underline text--secondary">
                  <b>Estatus:</b> Tienes dos oportunidades para modificar este
                  registro (0 de 2)
                </div>
                <div v-else-if="item.Estatus == 1" class="font-italic mx-auto text-decoration-underline text--secondary">
                  <b>Estatus:</b> Aún tienes una oporutnidad para modificar este
                  registro (1 de 2)
                </div>
                <div v-else-if="item.Estatus == 2" class="font-italic mx-auto text-decoration-underline text--secondary">
                  <b>Estatus:</b> Ya no puedes modificar este registro (2 de 2)
                </div>
                <v-divider class="my-2"></v-divider>
                <b>Comentario:</b> {{ item.ComentarioBaja }} <br />
              </v-col>
            </v-row>
          </v-card>
        </v-container>
      </v-card-text>
    </v-card>
    <form-bajas-de-empleados
      v-if="dialogBajas"
      :dialog="dialogBajas"
      :colaborador="colaborador"
      @cierraDialogBajas="cierraDialog"
    ></form-bajas-de-empleados>
  </v-dialog>
</template>

<script>
import axios from "axios";
import FormBajasDeEmpleados from "../../../BajasDeEmpleados/EmpleadosActivos/components/FormBajasDeEmpleados.vue";
export default {
  name: "reporte-bajas-detalles",
  components: { FormBajasDeEmpleados },
  props: ["dialog", "item"],
  data() {
    return {
      dialogBajas: false,
      colaborador: [],
      fotoPerfil: "",
    };
  },
  computed: {},
  mounted() {
    this.getPerfilColaborador();
  },
  methods: {
    getPerfilColaborador() {
      let params = {
        Opcion: 34,
        NPcliente1: this.item["NPBaja"],
        token: localStorage.getItem("token"),
      };
      axios
        .post(`${this.$store.getters.getHost}/General/ws-general.php`, params)
        .then((response) => {
          if (response.data.status == "OK") {
            this.fotoPerfil =
              // this.$store.getters.getHost +
              "https://apidh.urrea.com/ArchivosCargadosEnPortal2/FotosPerfilUsuarios/" +
              response.data.data["foto"];
          }
        });
    },
    openForm() {
      this.dialogBajas = true;
      // this.colaborador = this.item;
      this.colaborador = {
        dateFechaDespido: this.item["UltimoDiaLaboral"],
        dateFechaBaja: this.item["FechaEBaja"],
        motivoGeneral: this.item["IDBajaNominas"],
        motivo1: this.item["IDTipoBaja"],
        motivo2: this.item["IDTipoBaja2"],
        estatus: this.item["Recontratable"],
        comentario: this.item["ComentarioBaja"],
        EMPLID: this.item["NPBaja"],
        tipoColaborador: this.item["Proceso"],
        accion: "editar",
        IDBaja: this.item["IDBaja"],
        numEdicion: this.item["Estatus"],
      };
    },

    cierraDialog(estatus) {
      this.dialogBajas = false;
      if (estatus.estatus == "reload") {
        this.$emit("cierraDialog", "OK");
      }
    },
  },
};
</script>
