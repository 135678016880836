<template>
  <div class="pa-4">
    <div style="display: flex; justify-content: space-between;">
      <v-text-field
        v-model="search"
        class="ml-2"
        style="max-width: 450px;"
        dense
        prepend-inner-icon="mdi-clipboard-text-search-outline"
        placeholder="buscar..."
      ></v-text-field>
      <div class="text-center">
        <div v-if="politicas">
          <v-badge
            v-show="politicas.length > 0"
            :content="politicas.length"
            :value="politicas.length"
            color="purple darken-2"
            overlap
            bordered
            left
          >
            <v-btn
              class="back-btn-update font-weight-bold mb-2 rounded-pill mr-2"
              small
              depressed
              @click="muestraSolicitudesAsignacionDePoliticas = true"
            >
              <v-icon left> mdi-clipboard-alert-outline </v-icon>
              asignación de politicas
            </v-btn>
          </v-badge>
        </div>
        <v-btn
          class="back-btn-update font-weight-bold mb-2 rounded-pill mr-2"
          small
          depressed
          @click="
            puestosNuevosSolicitudesLoader = true;
            getSolicitudesNuevosPuestos();
          "
        >
          <v-icon left> mdi-update </v-icon>
          actualizar
        </v-btn>
      </div>
    </div>
    <v-data-table
      :loading="puestosNuevosSolicitudesLoader"
      :headers="headers_solicitudes"
      :items="puestosNuevosSolicitudes"
      :expanded.sync="expanded"
      :search="search"
      height="100%"
      loading-text="Cargando contentenido..."
      :footer-props="{
        'items-per-page-options': [10, 20, 30, 100],
        'items-per-page-text': 'Elementos por página',
      }"
      no-data-text="No hay registros"
      :header-props="{
        'short-by-text': 'Ordenar por',
      }"
      class="elevation-10 background-glass-effect-new-positions-request rounded-lg"
      item-key="id"
      show-expand
      dense
    >
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length" class="px-0 mx-0">
          <v-row no-gutters>
            <v-col cols="12" style="display:flex; justify-content:center;">
              <div class="rounded-lg rounded-t-0 back-titulo-detalles sombra">
                <p style="color:white; font-weight:bold; font-size: 1.1rem;">
                  Detalle de solicitud
                </p>
              </div>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="12" lg="12">
              <v-subheader
                class="tam-subheader py-0 mt-0 blue-grey--text text--darken-3 font-weight-bold"
              >
                Actividades
              </v-subheader>
              <p class="px-4 mb-0 text-justify">
                {{ item.actividades }}
              </p>
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="3" lg="2">
              <v-subheader
                class="tam-subheader py-0 mt-0 blue-grey--text text--darken-3 font-weight-bold"
              >
                Sueldo mínimo
              </v-subheader>
              <v-subheader class="tam-subheader py-0 mt-0">
                {{ formatMXmoney(item.salario_min) }}
              </v-subheader>
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="3" lg="2">
              <v-subheader
                class="tam-subheader py-0 mt-0 blue-grey--text text--darken-3 font-weight-bold"
              >
                Sueldo máximo
              </v-subheader>
              <v-subheader class="tam-subheader py-0 mt-0">
                {{ formatMXmoney(item.salario_max) }}
              </v-subheader>
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="3" lg="2">
              <v-subheader
                class="tam-subheader py-0 mt-0 blue-grey--text text--darken-3 font-weight-bold"
              >
                Puesto URREA relacionado.
              </v-subheader>
              <v-subheader class="tam-subheader py-0 mt-0">
                {{ item.puestoRelacionado }} ({{ item.Jobcode }})
              </v-subheader>
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="3" lg="2">
              <v-subheader
                class="tam-subheader py-0 mt-0 blue-grey--text text--darken-3 font-weight-bold"
              >
                ID Mercer.
              </v-subheader>
              <v-subheader class="tam-subheader py-0 mt-0">
                {{ item.idmercer == null ? "sin datos." : item.idmercer }}
              </v-subheader>
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="3" lg="2">
              <v-subheader
                class="tam-subheader py-0 mt-0 blue-grey--text text--darken-3 font-weight-bold"
              >
                ID Productivity.
              </v-subheader>
              <v-subheader class="tam-subheader py-0 mt-0">
                {{
                  item.idproductivity == null
                    ? "sin datos."
                    : item.idproductivity
                }}
              </v-subheader>
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="3" lg="2">
              <v-subheader
                class="tam-subheader py-0 mt-0 blue-grey--text text--darken-3 font-weight-bold"
              >
                Fecha de solicitud.
              </v-subheader>
              <v-subheader class="tam-subheader py-0 mt-0">
                {{ formatDate(item.fecha_alta.date.slice(0, 10)) }}
              </v-subheader>
            </v-col>
          </v-row>
        </td>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn
          small
          color="blue-grey lighten-5"
          depressed
          class="teal--text text--darken-1 rounded-pill font-weight-bold"
          @click="openNuevoPuesto(item.id)"
        >
          <v-icon left>mdi-plus</v-icon>
          crear puesto {{ item.Nombre }}
        </v-btn>
      </template>
    </v-data-table>

    <CreaPuestoDialog
      v-if="showCreatePositionDialog"
      :idNuevoPuesto="idNuevoPuestoSel"
      :showCreatePositionDialog="showCreatePositionDialog"
      @hideCreatePositionDialog="showCreatePositionDialog = false"
    />
    <SolicitudesAsignacionDePoliticas
      v-if="muestraSolicitudesAsignacionDePoliticas"
      :muestraSolicitudes="muestraSolicitudesAsignacionDePoliticas"
      :puestos="politicas"
      @cierraSolicitudes="cierraDialogSolicitudesPoliticas"
    />
  </div>
</template>

<script>
import axios from "axios";
import { deleteToken, formatDate } from "../../../../store/Funciones/funciones";
import CreaPuestoDialog from "./CreaPuestoDialog.vue";
import SolicitudesAsignacionDePoliticas from "./SolicitudesAsignacionDePoliticas.vue";
//   import TablaDetalles from "./TablaDetalles.vue";

export default {
  name: "solicitudes-pendientes",
  components: {
    CreaPuestoDialog,
    SolicitudesAsignacionDePoliticas,
  },
  props: [],
  data() {
    return {
      puestosNuevosSolicitudesLoader: true,
      showCreatePositionDialog: false,
      muestraSolicitudesAsignacionDePoliticas: false,
      idNuevoPuestoSel: 0,
      search: "",
      puestosNuevosSolicitudes: [],
      expanded: [],
      politicas: [],
    };
  },
  computed: {
    headers_solicitudes() {
      return [
        {
          text: "",
          value: "data-table-expand",
          class: "heder-requests-custom rounded-tl-lg",
        },
        {
          text: "ID",
          align: "start",
          value: "id",
          class: "primary white--text text-uppercase font-weight-bold",
        },
        {
          text: "# de posiciones",
          align: "center",
          value: "Contratacion",
          class: "primary white--text text-uppercase font-weight-bold",
        },
        {
          text: "Puesto",
          align: "start",
          value: "Nombre",
          class: "primary white--text text-uppercase font-weight-bold",
        },
        {
          text: "Razón de solicitud",
          align: "start",
          value: "razon",
          class: "primary white--text text-uppercase font-weight-bold",
        },
        {
          text: "Tipo de puesto",
          align: "start",
          value: "tipo",
          class: "primary white--text text-uppercase font-weight-bold",
        },
        {
          text: "Acciones",
          align: "center",
          value: "actions",
          class:
            "primary white--text text-uppercase font-weight-bold rounded-tr-lg",
        },
      ];
    },
  },
  mounted() {
    this.getSolicitudesNuevosPuestos();
  },
  methods: {
    cierraDialogSolicitudesPoliticas(type) {
      if (type != 0) {
        this.getSolicitudesPoliticas();
      }
      this.muestraSolicitudesAsignacionDePoliticas = false;
    },
    getSolicitudesNuevosPuestos() {
      if (this.puestosNuevosSolicitudes.length > 0) {
        this.puestosNuevosSolicitudes.length = 0;
      }
      let params = {
        Opcion: 14,
        token: localStorage.getItem("token"),
      };
      axios
        .post(
          `${this.$store.getters.getHost}/Reclutamiento/ws-admin-reclu.php`,
          params
        )
        .then((response) => {
          this.puestosNuevosSolicitudesLoader = false;
          if (response.data.status == "EXPSESSION") {
            deleteToken();
          } else {
            var data = response.data.solicitudes;
            this.puestosNuevosSolicitudes = data;
            this.getSolicitudesPoliticas();
          }
        });
    },
    getSolicitudesPoliticas() {
      if (this.politicas.length > 0) {
        this.politicas.length = 0;
      }
      let params = {
        Opcion: 18,
        token: localStorage.getItem("token"),
      };
      axios
        .post(
          `${this.$store.getters.getHost}/Reclutamiento/ws-control-de-puestos.php`,
          params
        )
        .then((response) => {
          this.puestosNuevosSolicitudesLoader = false;
          if (response.data.status == "EXPSESSION") {
            deleteToken();
          } else {
            this.politicas = response.data.respuesta;
          }
        });
    },
    formatMXmoney(valor) {
      return valor.toLocaleString("es-MX", {
        style: "currency",
        currency: "MXN",
      });
    },
    formatDate(date) {
      return formatDate(date);
    },
    openNuevoPuesto(id) {
      this.idNuevoPuestoSel = id;
      this.showCreatePositionDialog = true;
    },
  },
};
</script>

<style>
.tam-subheader {
  height: 25px;
  max-height: 75px;
}

.sombra {
  box-shadow: 4px 6px 28px -9px rgba(0, 0, 0, 0.53);
  -webkit-box-shadow: 4px 6px 28px -9px rgba(0, 0, 0, 0.53);
  -moz-box-shadow: 4px 6px 28px -9px rgba(0, 0, 0, 0.53);
}

.back-titulo-detalles {
  width: 98%;
  height: 30px;
  margin-bottom: 4px;
  background: rgb(64, 157, 215);
  background: linear-gradient(
    230deg,
    rgba(64, 157, 215, 1) 0%,
    rgba(150, 71, 251, 1) 47%,
    rgba(200, 107, 184, 1) 99%
  );
  display: flex;
  justify-content: center;
}

.background-glass-effect-new-positions-request {
  background: rgb(255, 255, 255) !important;
  background: linear-gradient(
    60deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(228, 228, 228, 1) 45%,
    rgba(255, 255, 255, 1) 100%
  );
  border: 1.2px solid rgba(220, 220, 220, 1) !important;
}

.heder-requests-custom {
  color: white !important;
  font-weight: 700 !important;
  text-transform: uppercase;
}
</style>
