import axios from 'axios'
import {
    deleteToken,
    // formatDate
} from '../../../store/Funciones/funciones'
import Bar from './components/BarBarras.vue'
import Doughnut from './components/BarDoughnut.vue'
import BarLine from './components/BarLine.vue'
import DetallesVacante from './components/DetallesVacante.vue'
import SolicitudesPendientes from './components/SolicitudesPendientesAdmon.vue'
import EnviaFormularioConfidencial from './components/EnviaFormularioCandidatoConfidencial.vue'

import AdmCompensaciones from './components/AdmCompensaciones.vue'
import VerCandidatos from './components/VerCandidatosPostulados.vue'
import BolsaDeTrabajo from './components/MuestraBolsaDeTrabajo.vue'

import VueJsonToCsv from "vue-json-to-csv";

export default {
    name: 'src-views-admn-vacantes',
    components: {
        Bar,
        Doughnut,
        BarLine,
        DetallesVacante,
        SolicitudesPendientes,
        VerCandidatos,
        BolsaDeTrabajo,
        AdmCompensaciones,
        VueJsonToCsv,
        EnviaFormularioConfidencial
    },
    props: [],

    data() {
        return {
            searchVacante: "",
            Nombre: "",
            rol: "",
            vacantes: [],
            valuesExternos: [],
            keysExternos: [],
            valuesInternos: [],
            keysInternos: [],
            report_to_export: [],
            headers_report_to_export: {
                id: { title: "ID" },
                nombre: { title: "NOMBRE DE VACANTE" },
                requisitor: { title: "REQUISITOR" },
                area: { title: "ÁREA" },
                fecha_alta: { title: "FECHA DE REQUISICÓN" },
                fecha_publicacion: { title: "FECHA DE PUBLICACIÓN" },
                diasPublicada: { title: "TIEMPO ABIERTA" },
                candidatosPostulados: { title: "CANDIDATOS POSTULADOS" },
                candidatosRechazados: { title: "CANDIDATOS RECHAZADOS" },
                estatus: { title: "ESTATUS" },
                comentarios: { title: "COMENTARIOS" },
            },
            panel: [0, 1],
            ciclos: {
                candidatos: 0,
                vacantes: 0
            },
            tiempos: {},
            loadInformationVacantes: true,
            muestraDetallesVacante: false,
            muestraCandidatosPostulados: false,
            showSendInvitation: false,
            notAcces: false,
            loadingExport: true,
            idVacanteInfo: 0,
            tabs_vacantes: null,
            vacanteSelected: null,
            cantidatosTabs: null,

            estatusList: [{
                text: "Mostrar todas",
                value: null
            },
            {
                text: "Aut. Compensaciones",
                value: 'Aut Compensaciones'
            },
            {
                text: "Aut. Jefe directo",
                value: 'Aut JefeDirecto'
            },
            {
                text: "Aut. Director de área",
                value: 'Aut DirectorArea'
            },
            {
                text: "Aut. Director general",
                value: 'Aut DirectorGeneral'
            },
            {
                text: "Publicada",
                value: 'Publicada'
            },
            {
                text: "En proceso",
                value: 'En Proceso'
            },
            {
                text: "Completada",
                value: 'Completada'
            },
            {
                text: "Rechazada",
                value: 'Rechazada'
            },
            {
                text: "Bolsa de trabajo",
                value: 'Bolsa de trabajo'
            },
            {
                text: "Aut. Ventas",
                value: 'Aut Ventas'
            },
            ],

            statusFilterValue: '',

            // Filter models.
            dessertFilterValue: '',
            areaFilterValue: '',
            depFilterValue: ''
        }
    },
    computed: {
        headers() {
            return [{
                text: "ID",
                align: "start",
                value: "id",
                class: "primary white--text font-weight-bold text-uppercase rounded-tl-xl"
            },
            {
                text: "Vacante",
                value: "Nombre",
                class: "primary white--text font-weight-bold text-uppercase",
                filter: this.nameFilter,
            },
            {
                text: "Requisitor",
                align: "center",
                value: "EMP_NOMEMP",
                class: "primary white--text font-weight-bold text-uppercase",
            },
            {
                text: "Estatus",
                align: "center",
                value: "descr",
                class: "primary white--text font-weight-bold text-uppercase",
                filter: this.statusFilter,
            },
            {
                text: "Fecha de alta",
                align: "center",
                value: "Fecha_alta",
                class: "primary white--text font-weight-bold text-uppercase",
            },
            {
                text: "Área",
                align: "center",
                value: "Area",
                class: "primary white--text font-weight-bold text-uppercase",
                filter: this.areaFilter,
            },
            {
                text: "Dep.",
                align: "center",
                value: "dept",
                class: "primary white--text font-weight-bold text-uppercase",
                filter: this.depFilter,
            },
            {
                text: "Razón",
                align: "center",
                value: "razonDesc",
                class: "primary white--text font-weight-bold text-uppercase",
            },
            {
                text: "Acciones",
                align: "center",
                value: "actions",
                class: "primary white--text font-weight-bold text-uppercase rounded-tr-xl",
            }
            ]
        },
    },
    async mounted() {
        this.getPerfil();
        this.report_to_export = await this.fillReport();
        this.loadingExport = false;
    },
    methods: {
        setFormatDate(date) {
            let fecha = new Date(date);

            let diaUTC = fecha.getUTCDate();
            let mesUTC = fecha.getUTCMonth() + 1;
            let anioUTC = fecha.getUTCFullYear();

            diaUTC = diaUTC < 10 ? "0" + diaUTC : diaUTC;
            mesUTC = mesUTC < 10 ? "0" + mesUTC : mesUTC;

            return anioUTC + "-" + mesUTC + "-" + diaUTC;
        },
        filtraNombre(item) {
            return item.Nombre.toLowerCase().includes(this.Nombre.toLowerCase());
        },

        getPerfil() {
            const headers = {
                Authorization: localStorage.getItem("token"),
            };

            return new Promise((res) => {
                axios
                    .get(`${this.$store.getters.getHostNode}/api/get-accesos-reclu-empleados`, {
                        headers: headers,
                    })
                    .then((response) => {
                        if (response.data.status == "EXPSESSION") {
                            deleteToken();
                        } else if (response.data.status == "ERROR") {
                            this.notAcces = true
                        } else {
                            if (response.data.rol == 'NOT ACCESS') {
                                this.notAcces = true
                            } else {
                                res(response.data.rol);
                                localStorage.setItem('rol', response.data.rol);
                                this.rol = response.data.rol
                                this.getTiemposDeCiclo()
                                this.getTiemposPorEstatusCandidatos()
                                this.getVacantes()
                            }
                        }
                    })
                    .finally(() => {
                        console.log("finally function");
                    });
            });
        },

        getTiemposPorEstatusCandidatos() {
            const headers = {
                Authorization: localStorage.getItem("token"),
            };

            return new Promise((res) => {
                axios
                    .get(`${this.$store.getters.getHostNode}/api/get-tiempos-por-estatus-candidatos`, {
                        headers: headers,
                    })
                    .then((response) => {
                        if (response.data.status == "EXPSESSION") {
                            deleteToken();
                        } else if (response.data.status == "OK") {
                            res(response.data.tiempos);
                            this.tiempos = response.data.tiempos;
                            this.valuesExternos = Object.values(this.tiempos.externos)
                            this.keysExternos = Object.keys(this.tiempos.externos)
                            this.valuesInternos = Object.values(this.tiempos.internos)
                            this.keysInternos = Object.keys(this.tiempos.internos)
                        } else if (response.data.status == "VACIO") {
                            console.log("Vacio");
                        }
                    })
                    .finally(() => {
                        console.log("finally function tiempos");
                    });
            });
        },

        getTiemposDeCiclo() {
            const headers = {
                Authorization: localStorage.getItem("token"),
            };

            return new Promise((res) => {
                axios
                    .get(`${this.$store.getters.getHostNode}/api/get-tiempos-de-ciclo`, {
                        headers: headers,
                    })
                    .then((response) => {
                        if (response.data.status == "EXPSESSION") {
                            deleteToken();
                        } else if (response.data.status == "OK") {
                            res(response.data.ciclos);
                            this.ciclos = response.data.ciclos;
                        } else if (response.data.status == "VACIO") {
                            console.log("Vacio");
                        }
                    })
                    .finally(() => {
                        console.log("finally function ciclos");
                    });
            });
        },
        capitalizeText(cadena) {
            if (!cadena) return ''
            cadena = cadena.toString()
            cadena = cadena.toLowerCase()
            return cadena.charAt(0).toUpperCase() + cadena.slice(1)
        },

        getVacantes() {
            const headers = {
                Authorization: localStorage.getItem("token"),
            };

            return new Promise((res) => {
                axios
                    .get(`${this.$store.getters.getHostNode}/api/get-vacantes`, {
                        headers: headers,
                    })
                    .then((response) => {
                        res(response.data.vacantes);
                        if (response.data.status == "EXPSESSION") {
                            deleteToken();
                        } else {
                            var data = response.data.vacantes;
                            this.vacantes = data;
                            this.loadInformationVacantes = false;
                            // this.getSolicitudesPendientes()
                            // this.createJSONtoExport();
                        }
                    })
                    .finally(() => {
                        console.log("finally function ciclos");
                    });
            });
        },

        nameFilter(value) {
            if (!this.dessertFilterValue) {
                return true;
            }
            return value.toLowerCase().includes(this.dessertFilterValue.toLowerCase());
        },
        statusFilter(value) {
            if (!this.statusFilterValue) {
                return true;
            }
            return value === this.statusFilterValue;
        },
        areaFilter(value) {
            if (!this.areaFilterValue) {
                return true;
            }
            return value.toLowerCase().includes(this.areaFilterValue.toLowerCase());
        },
        depFilter(value) {
            if (!this.depFilterValue) {
                return true;
            }
            return value.toLowerCase().includes(this.depFilterValue.toLowerCase());
        },

        detallesVacante(id) {
            this.idVacanteInfo = id;
            this.muestraDetallesVacante = true;
        },
        getColorEstatus(value) {
            if (value == 'Aut Compensaciones') {
                return 'indigo darken-1'
            } else if (value == 'Aut JefeDirecto') {
                return 'cyan darken-1'
            } else if (value == 'Aut DirectorArea') {
                return 'light-blue darken-2'
            } else if (value == 'Aut DirectorGeneral') {
                return 'blue-grey darken-1'
            } else if (value == 'Publicada') {
                return 'green darken-3'
            } else if (value == 'En Proceso') {
                return 'green darken-1'
            } else if (value == 'Completada') {
                return 'green darken-4'
            } else if (value == 'Rechazada') {
                return 'pink darken-3'
            } else if (value == 'Bolsa de trabajo') {
                return 'red darken-4'
            } else if (value == 'Aut Ventas') {
                return 'purple darken-2'
            } else if (value == 'Pausada') {
                return '#c2aac7'
            }
        },
        getColorIndicadorCandidatos(valor) {
            if (valor > 25 && valor < 40) {
                return 'yellow--text text--accent-4'
            } else if (valor <= 25) {
                return 'teal--text text--darken-1'
            } else {
                return 'pink--text text--darken-3'
            }
        },
        openShowCandidates(vacante) {
            this.vacanteSelected = vacante.id
            this.muestraCandidatosPostulados = true
        },

        async fillReport() {
            let reporte = await this.getReporteGeneralService();
            let reporteAux = [];
            reporte.map((item) => {
                reporteAux.push({
                    id: item.id,
                    nombre: item.nombre,
                    requisitor: item.requisitor,
                    area: item.Area,
                    fecha_alta: item.Fecha_alta.substring(0, 10),
                    fecha_publicacion: item.fecha_publicacion != null ? item.fecha_publicacion.substring(0, 10) : "",
                    diasPublicada: item.diasPublicada,
                    candidatosPostulados: item.candidatosPostulados,
                    candidatosRechazados: item.candidatosRechazados,
                    estatus: item.Estatus,
                    comentarios: item.Comentarios,
                })
            })

            return reporteAux;
        },

        async getReporteGeneralService() {
            const headers = {
                Authorization: localStorage.getItem("token"),
            };

            return new Promise((res) => {
                axios
                    .get(`${this.$store.getters.getHostNode}/api/get-reporte-general-service`, {
                        headers: headers,
                    })
                    .then((response) => {
                        res(response.data.reporte);
                        if (response.data.status == "EXPSESSION") {
                            deleteToken();
                        } else if (response.data.status == "OK") {
                            res(response.data.reporte);
                        }
                    })
                    .finally(() => {
                        console.log("finally function reporte");
                    });
            });
        }

        // getPerfil() {
        //     let params = {
        //         Opcion: 32,
        //         token: localStorage.getItem("token")
        //     };
        //     axios
        //         .post(
        //             `${this.$store.getters.getHost}/Reclutamiento/ws-admin-reclu.php`,
        //             params
        //         )
        //         .then((response) => {
        //             if (response.data.status == "EXPSESSION") {
        //                 deleteToken();
        //             } else if (response.data.status == "ERROR") {
        //                 this.notAcces = true
        //             } else {
        //                 if (response.data.rol == 'NOT ACCESS') {
        //                     this.notAcces = true
        //                 } else {
        //                     localStorage.setItem('rol', response.data.rol);
        //                     this.rol = response.data.rol
        //                     this.getTiemposDeCiclo()
        //                     this.getTiemposPorEstatusCandidatos()
        //                     this.getVacantes()
        //                 }
        //             }
        //         });
        // },

        // getTiemposPorEstatusCandidatos() {
        //     let params = {
        //         Opcion: 36,
        //         token: localStorage.getItem("token")
        //     };
        //     axios
        //         .post(
        //             `${this.$store.getters.getHost}/Reclutamiento/ws-admin-reclu.php`,
        //             params
        //         )
        //         .then((response) => {
        //             if (response.data.status == "EXPSESSION") {
        //                 deleteToken();
        //             } else if (response.data.status == "OK") {
        //                 this.tiempos = response.data.tiempos;
        //                 this.valuesExternos = Object.values(this.tiempos.externos)
        //                 this.keysExternos = Object.keys(this.tiempos.externos)
        //                 this.valuesInternos = Object.values(this.tiempos.internos)
        //                 this.keysInternos = Object.keys(this.tiempos.internos)
        //             }
        //         });
        // },

        // getTiemposDeCiclo() {
        //     const params = {
        //         Opcion: 35,
        //         token: localStorage.getItem("token")
        //     };
        //     axios
        //         .post(
        //             `${this.$store.getters.getHost}/Reclutamiento/ws-admin-reclu.php`,
        //             params
        //         )
        //         .then((response) => {
        //             if (response.data.status == "EXPSESSION") {
        //                 deleteToken();
        //             } else if (response.data.status == "OK") {
        //                 this.ciclos = response.data.ciclos;
        //             }
        //         });
        // },

        // getVacantes() {
        //     let params = {
        //         Opcion: 1,
        //         token: localStorage.getItem("token")
        //     };
        //     axios
        //         .post(
        //             `${this.$store.getters.getHost}/Reclutamiento/ws-admin-reclu.php`,
        //             params
        //         )
        //         .then((response) => {
        //             if (response.data.status == "EXPSESSION") {
        //                 deleteToken();
        //             } else {
        //                 var data = response.data.vacantes;
        //                 this.vacantes = data;
        //                 this.loadInformationVacantes = false;
        //                 // this.getSolicitudesPendientes()
        //                 // this.createJSONtoExport();
        //             }
        //         });
        // },

        // async getReporteGeneralService() {
        //     const params = {
        //         Opcion: 39,
        //         token: localStorage.getItem('token')
        //     }
        //     return new Promise((resolve, reject) => {
        //         axios
        //             .post(`${this.$store.getters.getHost}/Reclutamiento/ws-admin-reclu.php`, params)
        //             .then((response) => {
        //                 if (response.data.status == "EXPSESSION") {
        //                     deleteToken();
        //                 } else if (response.data.status == "OK") {
        //                     resolve(response.data.reporte);
        //                 }
        //             }).catch((error) => {
        //                 reject({ error: error })
        //             })
        //     })

        // }
    }

}