<template>
  <v-dialog
    v-model="dialogVariablesEdit"
    width="500"
    content-class="fix-border-dialog"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        color="primary"
        class="rounded-pill elevation-0 mb-3"
        block
        :loading="loadingVariables"
        @click="
          loadingVariables = true;
          loadVariables();
        "
      >
        <v-icon left> mdi-pencil </v-icon>
        editar variables
      </v-btn>
    </template>

    <v-card class="rounded-xl">
      <v-card-title class="overline font-weight-bold white--text primary">
        Variables de cálculo de topes de préstamo e intereses
      </v-card-title>

      <v-card-text class="pt-5">
        <div v-if="loadingVariables" class="center-item-in-div">
          <v-progress-circular
            :size="100"
            :width="10"
            color="primary"
            indeterminate
          ></v-progress-circular>
        </div>
        <div v-else>
          <v-simple-table dense>
            <thead>
              <tr>
                <td
                  class="text-center font-wheight-bold blue-grey--text text--darken-2"
                >
                  ID
                </td>
                <td
                  class="text-center font-wheight-bold blue-grey--text text--darken-2"
                >
                  NOMBRE
                </td>
                <td
                  class="text-center font-wheight-bold blue-grey--text text--darken-2"
                >
                  VALOR
                </td>
                <td
                  class="text-center font-wheight-bold blue-grey--text text--darken-2"
                >
                  ACCIONES
                </td>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in variablesDeCaja" :key="index">
                <td class="text-center">{{ item.ID }}</td>
                <td class="text-center">{{ item.NAME }}</td>
                <td class="text-center">{{ item.VALUE }}</td>
                <td class="text-center">
                  <v-btn color="blue accent-2" icon>
                    <v-icon
                      @click="
                        variableSeleccionada = item.NAME;
                        idVariableSeleccionada = item.ID;
                        muestraIngresaCantidad = true;
                      "
                      >mdi-pencil</v-icon
                    >
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </div>
      </v-card-text>
    </v-card>

    <IngresaCantidad
      v-if="muestraIngresaCantidad"
      :muestraIngresaCantidad="muestraIngresaCantidad"
      :titulo="'Nuevo valor de variable ' + variableSeleccionada"
      :tipo="'variable'"
      @retornaCantidad="getCantidad"
    />

    <SnackBar
      v-if="muestraBarra"
      :muestraBarra="muestraBarra"
      :texto="textoBarra"
      :color="colorBarra"
      @cierraBarra="muestraBarra = false"
    />
  </v-dialog>
</template>

<script>
import axios from "axios";
import { deleteToken } from "../../../../../../../store/Funciones/funciones";
import IngresaCantidad from "./IngresaCantidad.vue";
import SnackBar from "../../../../../General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue";

export default {
  components: { IngresaCantidad, SnackBar },
  data() {
    return {
      loadingVariables: false,
      variablesDeCaja: [],
      dialogVariablesEdit: false,
      variableSeleccionada: "",
      idVariableSeleccionada: null,
      muestraIngresaCantidad: false,
      muestraBarra: false,
      textoBarra: "",
      colorBarra: "",
    };
  },
  async mounted() {},
  methods: {
    showSnackBar(texto, color = "pink darken-3") {
      this.textoBarra = texto;
      this.colorBarra = color;
      this.muestraBarra = true;
    },
    async loadVariables() {
      this.variablesDeCaja = await this.getVariables();
    },
    getVariables() {
      const headers = {
        Authorization: localStorage.getItem("token"),
      };

      return new Promise((res) => {
        axios
          .get(`${this.$store.getters.getHostNode}/api/get-variables-caja`, {
            headers,
          })
          .then((response) => {
            if (response.data.status == "EXPSESSION") {
              deleteToken();
            } else {
              res(response.data.data);
            }
          })
          .finally(() => {
            this.loadingVariables = false;
            this.dialogVariablesEdit = true;
          });
      });
    },
    async getCantidad(cantidad) {
      if (cantidad != null) {
        await this.setVariable(cantidad)
        .then((response) => {
          if(response == 1){
            this.showSnackBar("Variable modificada con éxito", "success");
          }
        })
        .catch(error => {
          this.showSnackBar(error)
        });
      }
      this.muestraIngresaCantidad = false;
    },
    setVariable(cantidad) {
      const params = {
        monto: cantidad,
      };

      const headers = {
        Authorization: localStorage.getItem("token"),
      };

      return new Promise((res) => {
        axios
          .put(
            `${this.$store.getters.getHostNode}/api/empleados/caja/variables/${this.idVariableSeleccionada}`,
            params,
            {
              headers,
            }
          )
          .then((response) => {
            if (response.data.status == "EXPSESSION") {
              deleteToken();
            } else if (response.data.status == "OK") {
              this.showSnackBar(response.data.message, "success");
              this.loadingVariables = true;
              this.loadVariables();
              res(response.data);
            } else {
              this.showSnackBar(response.message)
              res([]);
            }
          })
          .finally(() => {
            this.loadingChangeSavingAmount = false;
          });
      });
    },
  },
};
</script>
