<template>
  <v-sheet color="transparent" elevation="0">
    <v-card class="text-center rounded-lg elevation-0 pt-4 pb-2 px-0 mb-2">
      <label class="subtitle-1 text-uppercase"
        >Cumplimiento al plan de producción</label
      >
      <div class="px-3">
        <v-progress-linear
          :color="getColorProgress(planproduccionporcentaje)"
          class="rounded-pill"
          :value="planproduccionporcentaje"
          height="25"
          ><strong
            >{{
              isNaN(planproduccionporcentaje) ? 0 : planproduccionporcentaje
            }}%</strong
          ></v-progress-linear
        >
      </div>
      <v-card-text class="px-2 pb-2">
        <v-data-table
          v-model="planproduccion"
          dense
          item-key="ID"
          :headers="headerplanproduccion"
          :items="planproduccion"
          class=" pa-3 elevation-0"
          :footer-props="{
            'items-per-page-text': 'Elementos por página',
            pageText: '{0}-{1} de {2}',
          }"
        >
        </v-data-table>
      </v-card-text>
    </v-card>
    <v-card class="text-center rounded-lg elevation-0 pt-4 pb-2 px-0 mb-2">
      <label class="subtitle-1 text-uppercase">Pedidos estraordinarios</label>
      <div class="px-3">
        <v-progress-linear
          :value="pedidoextraordinarioporcentaje"
          class="rounded-pill"
          :color="getColorProgress(pedidoextraordinarioporcentaje)"
          height="25"
          ><strong
            >{{
              isNaN(pedidoextraordinarioporcentaje)
                ? 0
                : pedidoextraordinarioporcentaje
            }}%</strong
          ></v-progress-linear
        >
      </div>
      <v-card-text class="px-2 pb-2">
        <v-data-table
          v-model="pedidoextraordinario"
          dense
          item-key="ID"
          :headers="headerpedidoextraordinario"
          :items="pedidoextraordinario"
          class=" pa-3 elevation-0"
          :footer-props="{
            'items-per-page-text': 'Elementos por página',
            pageText: '{0}-{1} de {2}',
          }"
        >
        </v-data-table>
      </v-card-text>
    </v-card>
    <v-card class="text-center rounded-lg elevation-0 pt-4 pb-2 px-0 mb-2">
      <label class="subtitle-1 text-uppercase"
        >Mantenimiento y/o limpieza</label
      >
      <div class="px-3">
        <v-progress-linear
          :value="mantenimientoporcentaje"
          class="rounded-pill"
          :color="getColorProgress(mantenimientoporcentaje)"
          height="25"
          ><strong>{{ mantenimientoporcentaje }}%</strong></v-progress-linear
        >
      </div>
      <v-card-text class="px-2 pb-2">
        <v-data-table
          v-model="mantenimiento"
          dense
          item-key="ID"
          :headers="headermantenimiento"
          :items="mantenimiento"
          class=" pa-3 elevation-0"
          :footer-props="{
            'items-per-page-text': 'Elementos por página',
            pageText: '{0}-{1} de {2}',
          }"
        >
        </v-data-table>
      </v-card-text>
    </v-card>
    <v-card class="text-center rounded-lg elevation-0 pt-4 pb-2 px-0 mb-2">
      <label class="subtitle-1 text-uppercase">Impacto en equipo caido</label>
      <div class="px-3">
        <v-progress-linear
          :value="impactoecporcentaje"
          class="rounded-pill"
          :color="getColorProgress(impactoecporcentaje)"
          height="25"
          ><strong>{{ impactoecporcentaje }}%</strong></v-progress-linear
        >
      </div>
      <v-card-text class="px-2 pb-2">
        <v-data-table
          v-model="impactoec"
          dense
          item-key="ID"
          :headers="headerimpactoec"
          :items="impactoec"
          class=" pa-3 elevation-0"
          :footer-props="{
            'items-per-page-text': 'Elementos por página',
            pageText: '{0}-{1} de {2}',
          }"
        >
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-sheet>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      planproduccion: [],
      pedidoextraordinario: [],
      mantenimiento: [],
      impactoec: [],
      tabs: null,
      planproduccionporcentaje: "",
      pedidoextraordinarioporcentaje: "",
      mantenimientoporcentaje: "",
      impactoecporcentaje: "",
      planproduccioncolor: "",
      pedidoextraordinariocolor: "",
      mantenimientocolor: "",
      impactoeccolor: "",
      dactcumplimiento: false,
      idregistro: 0,
      cumplimientoupdt: "",

      headerplanproduccion: [
        {
          text: "ID",
          value: "ID",
          align: "center",
          class: "primary white--text overline font-weight-bold",
        },
        {
          text: "SOLICITUD",
          value: "ID_SOLICITUD",
          align: "center",
          class: "primary white--text overline font-weight-bold",
        },
        {
          text: "FIGURAS TOTALES",
          value: "FIG_TOTALES",
          align: "center",
          class: "primary white--text overline font-weight-bold",
        },
        {
          text: "OBJETIVO",
          value: "OBJETIVO",
          align: "center",
          class: "primary white--text overline font-weight-bold",
        },
        {
          text: "EMPLID",
          value: "USUARIO_ACTUALIZA",
          align: "center",
          class: "primary white--text overline font-weight-bold",
        },
        {
          text: "NOMBRE",
          value: "Nombre",
          align: "center",
          class: "primary white--text overline font-weight-bold",
        },
        {
          text: "FECHA REGISTRO",
          value: "FECHA_ACTUALIZA",
          align: "center",
          class: "primary white--text overline font-weight-bold",
        },
      ],
      headerpedidoextraordinario: [
        {
          text: "ID",
          value: "ID",
          align: "center",
          class: "primary white--text overline font-weight-bold",
        },
        {
          text: "SOLICITUD",
          value: "ID_SOLICITUD",
          align: "center",
          class: "primary white--text overline font-weight-bold",
        },
        {
          text: "MONTO TOTAL",
          value: "MONTO_TOTAL",
          align: "center",
          class: "primary white--text overline font-weight-bold",
        },
        {
          text: "OBJETIVO",
          value: "OBJETIVO",
          align: "center",
          class: "primary white--text overline font-weight-bold",
        },
        {
          text: "EMPLID",
          value: "USUARIO_ACTUALIZA",
          align: "center",
          class: "primary white--text overline font-weight-bold",
        },
        {
          text: "NOMBRE",
          value: "Nombre",
          align: "center",
          class: "primary white--text overline font-weight-bold",
        },
        {
          text: "FECHA REGISTRO",
          value: "FECHA_ACTUALIZA",
          align: "center",
          class: "primary white--text overline font-weight-bold",
        },
      ],
      headermantenimiento: [
        {
          text: "ID",
          value: "ID",
          align: "center",
          class: "primary white--text overline font-weight-bold",
        },
        {
          text: "SOLICITUD",
          value: "ID_SOLICITUD",
          align: "center",
          class: "primary white--text overline font-weight-bold",
        },
        {
          text: "COMENTARIO",
          value: "COM_MANT",
          align: "center",
          class: "primary white--text overline font-weight-bold",
        },
        {
          text: "OBJETIVO",
          value: "OBJETIVO",
          align: "center",
          class: "primary white--text overline font-weight-bold",
        },
        {
          text: "EMPLID",
          value: "USUARIO_ACTUALIZA",
          align: "center",
          class: "primary white--text overline font-weight-bold",
        },
        {
          text: "NOMBRE",
          value: "Nombre",
          align: "center",
          class: "primary white--text overline font-weight-bold",
        },
        {
          text: "FECHA REGISTRO",
          value: "FECHA_ACTUALIZA",
          align: "center",
          class: "primary white--text overline font-weight-bold",
        },
        {
          text: "ACCIONES",
          value: "actions",
          align: "center",
          class: "primary white--text overline font-weight-bold",
        },
      ],
      headerimpactoec: [
        {
          text: "ID",
          value: "ID",
          align: "center",
          class: "primary white--text overline font-weight-bold",
        },
        {
          text: "SOLICITUD",
          value: "ID_SOLICITUD",
          align: "center",
          class: "primary white--text overline font-weight-bold",
        },
        {
          text: "COMENTARIO",
          value: "COM_IMPEC",
          align: "center",
          class: "primary white--text overline font-weight-bold",
        },
        {
          text: "OBJETIVO",
          value: "OBJETIVO",
          align: "center",
          class: "primary white--text overline font-weight-bold",
        },
        {
          text: "EMPLID",
          value: "USUARIO_ACTUALIZA",
          align: "center",
          class: "primary white--text overline font-weight-bold",
        },
        {
          text: "NOMBRE",
          value: "Nombre",
          align: "center",
          class: "primary white--text overline font-weight-bold",
        },
        {
          text: "FECHA REGISTRO",
          value: "FECHA_ACTUALIZA",
          align: "center",
          class: "primary white--text overline font-weight-bold",
        },
        {
          text: "ACCIONES",
          value: "actions2",
          align: "center",
          class: "primary white--text overline font-weight-bold",
        },
      ],
    };
  },
  mounted() {
    this.getReporteCumplimiento();
  },
  methods: {
    getReporteCumplimiento() {
      let params = {
        Opcion: 17,
        token: localStorage.getItem("token"),
      };
      axios
        .post(
          `${this.$store.getters.getHost}/general/ws-TiempoExtra.php`,
          params
        )
        .then((response) => {
          if (response.data.status != "EXPSESSION") {
            if (response.data.plan.plan) {
              this.planproduccion = response.data.plan.plan;
            }

            if (response.data.pedido.pedido) {
              this.pedidoextraordinario = response.data.pedido.pedido;
            }

            if (response.data.mantenimiento.mantenimiento) {
              this.mantenimiento = response.data.mantenimiento.mantenimiento;
            }

            if (response.data.impacto.impec) {
              this.impactoec = response.data.impacto.impec;
            }
            // Calcular el porcentaje de cumplimiento
            if (this.planproduccion != []) {
              this.planproduccionporcentaje = this.calcularPorcentajeCumplimiento(
                this.planproduccion
              );
            }
            if (this.pedidoextraordinario != []) {
              this.pedidoextraordinarioporcentaje = this.calcularPorcentajeCumplimiento(
                this.pedidoextraordinario
              );
              //this.pedidoextraordinariocolor = this.getColorporcentaje(this.pedidoextraordinarioporcentaje);
            }
            if (this.mantenimiento) {
              this.mantenimientoporcentaje = this.calcularPorcentajeCumplimiento(
                this.mantenimiento
              );
            }
            if (this.impactoec != []) {
              this.impactoecporcentaje = this.calcularPorcentajeCumplimiento(
                this.impactoec
              );
            }
          }
        });
    },
    calcularPorcentajeCumplimiento(datos) {
      let totalCumplidos = 0;

      // Recorrer el arreglo y contar cuántos elementos cumplen la condición en la posición objetivo
      datos.forEach((elemento) => {
        if (elemento.OBJETIVO === "Cumplido") {
          totalCumplidos++;
        }
      });

      // Calcular el porcentaje de cumplimiento
      const porcentajeCumplimiento = (totalCumplidos / datos.length) * 100;

      return porcentajeCumplimiento.toFixed(2); // Limitar a dos decimales
    },
    getColorProgress(porcentaje) {
      if (parseFloat(porcentaje) < 30 || porcentaje == "NaN") {
        return "red darken-2"; // Color rojo para porcentajes menores al 30%
      } else if (parseFloat(porcentaje) < 70) {
        return "amber"; // Color amarillo para porcentajes entre 30% y 70%
      } else {
        return "teal"; // Color verde para porcentajes iguales o mayores al 70%
      }
    },
  },
};
</script>
