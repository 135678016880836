import ListaCuadroBase from "./components/ListaCuadroBase.vue";
export default {
  name: 'cuadro-base',
  components: { ListaCuadroBase },
  props: [],
  data() {
    return {

    }
  },
  computed: {

  },
  mounted() {

  },
  methods: {

  }
}


