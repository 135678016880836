import axios from "axios";
import SnackBarError from "../../General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue";
import { deleteToken } from '../../../../store/Funciones/funciones'
export default {
    name: 'lista-asistencia',
    components: { SnackBarError },
    props: [],
    data() {
        return {
            textoBarra: "",
            colorBarra: "",
            muestraBarra: false,
            nombreCurso: '',
            duracion: '',
            instructor: '',
            openDialog: false,
            openDialogInstructor: false,
            numEmpleado: '',
            loading: false,
            tags: [],
            numInstructor: '',
            nombreInstructor: '',
            compania: '',
            bloqueo: false,
            tipoCurso: false,
            colaboradores: [],
            colaboradoresNombre: [],
            loadingColaboradores: true,
            nombre: '',
            nombreIntegrante: '',
            masivo: false,
            loadingBotonGuardar: false,
            file: '',
            responseApi: "",
        }
    },
    computed: {
        esProveedor() {
            return this.tipoCurso === true ? 'Si' : 'No'
        },
    },
    mounted() {
        this.getCompania();
    },
    methods: {
        getCompania() {
            let paramsEstatusInc = {
                Opcion: 28,
                token: localStorage.getItem('token')
            };
            axios.post(`${this.$store.getters.getHost}/General/ws-general.php`, paramsEstatusInc)
                .then((response) => {
                    if (response.data.status == "EXPSESSION") {
                        deleteToken()
                    } else {
                        this.compania = response.data.compania;
                        if (this.compania != '00100') {
                            this.bloqueo = true;
                        }
                    }
                })
                .catch(function (e) {
                    let result = e.toString().includes("401");
                    if (result) {
                        window.location.reload();
                    } else {
                        alert(e);
                    }
                });

        },

        vaciaVariables() {
            this.nombreInstructor = '';
            this.instructor = '';
        },

        splitIntegrante(tag) {
            let puntero = '';
            this.tags.forEach(function (element, index) {
                if (element.numEmpleado == tag.numEmpleado && element.numEmpleado) {
                    puntero = index;
                } else if (element.nombreBuscador == tag.nombreBuscador && element.numEmpleado == "") {
                    puntero = index;
                }
            });
            this.tags.splice(puntero, 1);
        },

        clean() {
            this.nombreCurso = '';
            this.duracion = '';
            this.instructor = '';
            this.nombreInstructor = '';
            this.tags = [];
        },

        validar() {
            if (this.nombreCurso && this.duracion && this.instructor && this.tags) {

                if (this.masivo == true) {
                    if (!this.file) {
                        this.textoBarra = 'No se ha cargado aún un archivo .CSV para la carga masiva';
                        this.colorBarra = 'error';
                        this.muestraBarra = true;
                    } else {
                        this.guardar();
                        // console.log(2);
                    }
                } else {
                    this.guardar();
                    // console.log(1);
                }
            } else {
                this.textoBarra = 'Complete el formulario';
                this.colorBarra = 'primary';
                this.muestraBarra = true;
            }

        },

        guardar(){
            let params = {
                Opcion: 2,
                token: localStorage.getItem("token"),
                nombreCurso: this.nombreCurso,
                duracion: this.duracion,
                instructor: this.instructor,
                asistentes: this.tags,
                esProveedor: this.tipoCurso,
                nombreInstructor: this.nombreInstructor,
                masivo: this.masivo,
            }
            axios.post(`${this.$store.getters.getHost}/UniversidadUrrea/ws-ListaAsistencia.php`, params).then((response) => {
                if (response.data.status == 'OK') {
                    this.textoBarra = 'Curso registrado';
                    this.colorBarra = 'success';
                    this.muestraBarra = true;
                    this.clean();
                } else if (response.data.status == 'error') {
                    this.textoBarra = 'Se ha producido un error, estamos trabajando en ello';
                    this.colorBarra = 'error';
                    this.muestraBarra = true;
                } else if (response.data.status == 'sinAsistentes') {
                    this.textoBarra = 'Curso registrado, hubo un problema al registrar a los integrantes del curso';
                    this.colorBarra = 'primary';
                    this.muestraBarra = true;
                    this.clean();
                }
            })
        },

        async buscarInstructor() {
            this.loading = true;
            if (this.numInstructor) {
                let params = {
                    Opcion: 1,
                    numEmpleado: this.numInstructor,
                    token: localStorage.getItem("token")
                };
                axios.post(`${this.$store.getters.getHost}/UniversidadUrrea/ws-ListaAsistencia.php`, params).then((response) => {
                    if (response.data.status == 'OK' && response.data.data) {
                        this.nombreInstructor = response.data.data[0]["nombre"];
                        this.instructor = this.numInstructor;
                        this.numInstructor = '';
                    } else {
                        this.textoBarra = 'Usuario no ha sido encontrado, verifique que el numero de empleado sea el correcto';
                        this.colorBarra = 'primary';
                        this.muestraBarra = true;
                    }
                    this.loading = false;
                })
            } else {
                this.textoBarra = 'Para continuar con la busqueda ingrese un numero de empleado';
                this.colorBarra = 'primary';
                this.muestraBarra = true;
                this.loading = false;
            }
        },

        agregar() {
            this.loading = true;
            let bandera = 0;
            if (this.nombreIntegrante) {
                this.tags.forEach(element => {
                    if (element.nombreBuscador == this.nombreIntegrante) {
                        bandera++;
                    }
                });
                if (bandera == 0) {
                    this.tags.push({ 'numEmpleado': '', 'Nombre': this.nombreIntegrante, "nombreBuscador": this.nombreIntegrante });
                    this.nombreIntegrante = "";
                } else {
                    this.textoBarra = 'Usuario ya ha sido seleccionado';
                    this.colorBarra = 'primary';
                    this.muestraBarra = true;
                }
            } else {
                this.textoBarra = 'Escriba un nombre para poder registrar al integrante';
                this.colorBarra = 'primary';
                this.muestraBarra = true;
            }
            this.loading = false;
        },

        buscarNumeroEmpleado() {
            let bandera = 0;
            if (this.nombre) {
                let resultdo = this.colaboradores.filter((dato) => '[' + dato["EMPLID"] + '] ' + dato['NAME'] == this.nombre);
                this.tags.forEach(element => {
                    if (element.nombreBuscador == this.nombre) {
                        bandera++;
                    }
                });
                if (bandera == 0) {
                    this.tags.push({ 'numEmpleado': resultdo[0]["EMPLID"], 'Nombre': resultdo[0]["NAME"], "nombreBuscador": '[' + resultdo[0]["EMPLID"] + '] ' + resultdo[0]['NAME'] });
                    this.nombre = "";
                } else {
                    this.textoBarra = 'Usuario ya ha sido seleccionado';
                    this.colorBarra = 'primary';
                    this.muestraBarra = true;
                }
            }
        },

        dialogAsignacion(item) {
            if (item == 'manual') {
                if (!this.tipoCurso) {
                    this.getColaboradores();
                }
                this.masivo = false;
            } else {
                this.masivo = true;
            }
            this.openDialog = true;
            this.numEmpleado = '';

        },

        getColaboradores() {
            if (this.colaboradores.length == 0) {
                let params = {
                    Opcion: 11,
                    token: localStorage.getItem("token"),
                };
                axios.post(`${this.$store.getters.getHost}/General/ws-general.php`, params).then((response) => {
                    var data = response.data.colaboradores;
                    if (data) {
                        this.colaboradores = data;
                        this.colaboradores.map((elemento) => {
                            this.colaboradoresNombre.push('[' + elemento["EMPLID"] + '] ' + elemento["NAME"]);
                        });
                        this.loadingColaboradores = false;
                    }
                });
            }
        },

        handleFileUpload() {
            this.file = this.$refs.file.files[0];
            console.log(this.file);
        },

        async submitFile() {
            this.loadingBotonGuardar = true;
            if (this.file.type == "application/vnd.ms-excel") {
                var reader = new FileReader()
                reader.readAsDataURL(this.file)
                reader.onload = () => {
                    var params = {
                        Opcion: 5,
                        filename: "listaAsistentes.csv",
                        base64: reader.result,
                        token: localStorage.getItem('token')
                    }
                    console.log(params);
                    axios.post(`${this.$store.getters.getHost}/UniversidadUrrea/ws-ListaAsistencia.php`, params).then((response) => {
                        this.responseApi = response.data;
                        console.log(this.responseApi);
                        this.openDialog = false;
                        this.textoBarra = 'Relación registrada correctamente, continue para guardar curso';
                        this.colorBarra = 'success';
                        this.muestraBarra = true;
                    })
                };
            } else {
                this.textoBarra = 'Error. El archivo no es tipo CSV.';
                this.colorBarra = 'error';
                this.muestraBarra = true;
            }
            this.loadingBotonGuardar = false;
        },
    }
}