import MuestraAreasDescansoLaborado from './components/MuestraAreasDescansoLaborado.vue'
export default {
    name: 'adm-solicitantes-descanso-laborado',
    components: { MuestraAreasDescansoLaborado },
    props: [],
    data() {
        return {

        }
    },
    computed: {

    },
    mounted() {

    },
    methods: {

    }
}