import axios from 'axios'
import SnackBarError from '../../General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue'
import LoadingBar from '../../DesarrolloOrganizacional/components/loadingBar.vue'

export default {
    name: 'administracion-apoyo-lentes',
    components: {
        SnackBarError,
        LoadingBar
    },
    props: [],
    data() {
        return {
            solicitudes: [],
            expanded: [],
            headers: [{
                    text: 'Emplid',
                    sortable: false,
                    value: 'emplid'
                },
                {
                    text: 'Nombre',
                    sortable: false,
                    value: 'NAME'
                },
                {
                    text: 'Tipo',
                    sortable: false,
                    value: 'tipo'
                },
                {
                    text: 'Acción',
                    sortable: false,
                    value: 'action'
                }
            ],
            rules: [v => v.length <= 250 || 'Max. 250 caracteres'],
            motivoRechazo: false,
            idRechazo: null,
            comentarioRechazo: "",
            host: this.$store.getters.getHost,
            muestraBarra: false,
            muestraLoading: false,
            loadingContent: true,
            textoLoading: "",
            textoBarra: "",
            colorBarra: "",
            emplidRechazado: null,
        }
    },
    computed: {

    },
    mounted() {
        this.getSolicitudes();
    },
    methods: {
        getSolicitudes() {
            let params = {
                Opcion: 8,
                token: localStorage.getItem('token')
            }
            axios.post(`${this.$store.getters.getHost}/Compensaciones/ws-ayuda-lentes.php`, params).then(response => {
                this.loadingContent = false;
                if (response.data.status == 'OK') {
                    this.solicitudes = response.data.ayudaLentes;
                }
            })
        },
        muestraImagen(url) {
            alert(url)
            var win = window.open(url.trim(), '_blank');
            win.focus();
        },
        abreModalComentarioRechazo(id, emplid) {
            this.idRechazo = id;
            this.emplidRechazado = emplid;
            this.motivoRechazo = true;
        },
        cierraModalComentarioRechazo() {
            this.idRechazo = null;
            this.emplidRechazado = null;
            this.motivoRechazo = false;
            this.comentarioRechazo = ""
        },
        modificarSolicitud(idSolicitud, estatusSolicitud, empleado) {
            this.textoLoading = "Cargando...";
            this.muestraLoading = true;

            // console.log(id)
            let params = {
                Opcion: 9,
                token: localStorage.getItem('token'),
                id: idSolicitud,
                comentario: this.comentarioRechazo,
                estatus: estatusSolicitud,
                empleado: empleado
            }
            axios.post(`${this.$store.getters.getHost}/Compensaciones/ws-ayuda-lentes.php`, params).then(response => {
                if (response.data.status == 'OK') {
                    this.validaOperacion(response.data.ayudaLentes);
                }
            })
        },
        validaOperacion(result) {
            if (this.motivoRechazo) {
                this.cierraModalComentarioRechazo();
            }
            this.muestraLoading = false;
            if (result == 1) {
                this.textoBarra = "Registro modificado con éxito.";
                this.colorBarra = "primary";
                this.muestraBarra = true;
                this.solicitudes.length = 0;
                this.loadingContent = true;
                this.getSolicitudes();
            } else {
                this.textoBarra = "Error. No se pudo modificar el registro.";
                this.colorBarra = "error";
                this.muestraBarra = true;
            }
        }
    }
}