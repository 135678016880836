<template>
  <v-dialog v-model="dialog" max-width="500" scrollable>
    <v-card class="rounded-lg">
      <v-card-title class="primary white--text subtitle-1">
        Detalle de incidencia
        <v-spacer></v-spacer>
        <v-icon dark @click="$emit('cierraDetalle','Cancelar')"> mdi-close </v-icon>
      </v-card-title>
      <v-card-text class="pt-4">
        <v-sheet class="rounded-lg elevation-1 pa-3 mb-3">
          <label
            class="blue-grey--text text--darken-1 font-weight-bold caption text-uppercase"
          >
            Datos de empleado
          </label>
          <v-divider class="mt-1 mb-3 mr-10"></v-divider>
          <v-row dense>
            <v-col cols="12">
              <div class="subtitle-2">
                <strong>Nombre: </strong>{{ item.Nombre }}
              </div>
            </v-col>
            <v-col cols="4">
              <div class="subtitle-2">
                <strong>No. de empleado: </strong>{{ item.NumeroPersonal }}
              </div>
            </v-col>
            <v-col cols="8">
              <div class="subtitle-2">
                <strong>Departamento: </strong>{{ item.Departamento }}
              </div>
            </v-col>
          </v-row>
        </v-sheet>
        <v-sheet v-if="TipoIncidencia == 4" class="rounded-lg elevation-1 pa-3">
          <label
            class="blue-grey--text text--darken-1 font-weight-bold caption text-uppercase"
          >
            Datos de incidencia
          </label>
          <v-divider class="mt-1 mb-3 mr-10"></v-divider>
          <div class="text-center font-weight-bold">
            {{
              item.GoceSueldo === 0
                ? "Sin goce de sueldo"
                : "Con goce de sueldo"
            }}
          </div>
          <div class="text-center">
            <strong>Tipo de permiso: </strong>{{ item.PermDescripcion }}
          </div>
          <div style="display: flex; justify-content: space-around">
            <div class="text-center">
              <strong>Fecha de inicio: </strong>{{ item.FechaInicio }}
            </div>
            <div class="text-center">
              <strong>Fecha fin: </strong>{{ item.FechaFinal }}
            </div>
          </div>
        </v-sheet>
        <v-sheet
          v-else-if="TipoIncidencia == 2"
          class="rounded-lg elevation-1 pa-3"
        >
          <label
            class="blue-grey--text text--darken-1 font-weight-bold caption text-uppercase"
          >
            Datos de incidencia
          </label>
          <v-divider class="mt-1 mb-3 mr-10"></v-divider>
          <div style="display: flex; justify-content: space-around">
            <div class="text-center">
              <strong>Fecha: </strong>{{ item.FechaInicio }}
            </div>
            <div class="text-center">
              <strong>Hora: </strong>{{ getHour(item.HoraInicio) }}
            </div>
          </div>
          <div style="display: flex; justify-content: space-around">
            <div class="text-center">
              <strong>{{
                item.Regresa === 1 ? "Si regresa" : "No regresa"
              }}</strong>
            </div>
            <div class="text-center" v-show="item.Regresa === 1">
              <strong>Hora de regreso: </strong>{{ getHour(item.HoraFinal) }}
            </div>
          </div>
          <div
            style="display: flex; justify-content: space-around"
            v-if="
              item.FechaHoraRealSalida != null &&
              item.FechaHoraRealRegreso != null
            "
          >
            <div class="text-center" v-if="item.FechaHoraRealSalida != null">
              <strong>Hora real salida: </strong
              >{{ getHour(item.FechaHoraRealSalida.date) }}
            </div>
            <div class="text-center" v-if="item.FechaHoraRealRegreso != null">
              <strong>Hora real regreso: </strong
              >{{ getHour(item.FechaHoraRealRegreso.date) }}
            </div>
          </div>
          <div class="text-center">
            <strong>Motivo: </strong>{{ item.DescOtro }}
          </div>
        </v-sheet>
        <v-sheet
          v-else-if="TipoIncidencia == 3"
          class="rounded-lg elevation-1 pa-3"
        >
          <label
            class="blue-grey--text text--darken-1 font-weight-bold caption text-uppercase"
          >
            Datos de incidencia
          </label>
          <v-divider class="mt-1 mb-3 mr-10"></v-divider>
          <div style="display: flex; justify-content: space-around">
            <div class="text-center">
              <strong>Fecha: </strong>{{ item.FechaInicio }}
            </div>
            <div class="text-center">
              <strong>Hora: </strong>{{ getHour(item.HoraInicio) }}
            </div>
          </div>
          <div class="text-center">
            <strong>Motivo: </strong>{{ item.DescOtro }}
          </div>
        </v-sheet>
        <v-sheet class="rounded-lg elevation-1 pa-3 mt-3">
          <label
            class="blue-grey--text text--darken-1 font-weight-bold caption text-uppercase"
          >
            Comentarios
          </label>
          <v-divider class="mt-1 mb-3 mr-10"></v-divider>
          <v-row dense>
            <v-col cols="12">
              <div class="subtitle-2">
                <strong>Comentario: </strong>{{ item.Comentario }}
              </div>
            </v-col>
            <v-col
              cols="12"
              v-if="
                item.ComentarioAutorizacion != '' &&
                item.ComentarioAutorizacion != null
              "
            >
              <div class="subtitle-2">
                <strong>Comentario aut.: </strong
                >{{ item.ComentarioAutorizacion }}
              </div>
            </v-col>
          </v-row>
        </v-sheet>
      </v-card-text>
      <v-card-actions class="d-flex justify-end">
        <v-btn
          v-if="TipoIncidencia == 4"
          color="primary"
          small
          dark
          :loading="loading"
          @click="openDialogGafete()"
          >Imprimir gafete</v-btn
        >
        <v-btn
          v-if="TipoIncidencia == 4 && item.EstatusActual == 9"
          color="teal darken-4"
          small
          dark
          :loading="loading"
          @click="autorizar"
          >Autorizar</v-btn
        >
      </v-card-actions>
    </v-card>
    <snack-bar-error-incidencias
      v-if="muestraBarra"
      :muestraBarra="muestraBarra"
      :texto="textoBarra"
      :color="colorBarra"
      @cierraBarra="muestraBarra = false"
    >
    </snack-bar-error-incidencias>
    <gafeteColaborador
      v-if="dialogGafete"
      :dialog="dialogGafete"
      :colaborador="colaborador"
      @cierraImpresionGafete="dialogGafete = false"
    />
  </v-dialog>
</template>

<script>
import axios from "axios";
import { formatDate } from "../../../../../../store/Funciones/funciones";
import gafeteColaborador from "../../../BajasDeEmpleados/EmpleadosActivos/components/GafeteColaborador.vue";
import SnackBarErrorIncidencias from "../../../../General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue";
export default {
  name: "detalles-colaborador",
  components: { gafeteColaborador, SnackBarErrorIncidencias },
  props: ["dialog", "item", "TipoIncidencia"],
  data() {
    return {
      textoBarra: "",
      colorBarra: "",
      muestraBarra: false,

      colaborador: [],
      dialogGafete: false,
      loading: true,
    };
  },
  computed: {},
  mounted() {
    this.getPerfilColaborador();
    console.log(this.item);
  },
  methods: {
    openDialogGafete() {
      this.dialogGafete = true;
    },

    armarColaboradores(item) {
      if (item.foto) {
        this.colaborador = {
          linkCompleto:
            "https://apidh.urrea.com//ArchivosCargadosEnPortal2/FotosPerfilUsuarios/" +
            item.foto,
          EMPLID: this.item.NumeroPersonal,
          NAME: item.nombre,
          DESCR: item.puesto,
          DESCR1: item.depto,
        };
      } else {
        this.colaborador = {
          linkCompleto:
            "https://community.f5.com/legacyfs/online/avatars/0051T000008uOPOQA2.png",
          EMPLID: this.item.NumeroPersonal,
          NAME: item.nombre,
          DESCR: item.puesto,
          DESCR1: item.depto,
        };
      }
    },

    getPerfilColaborador() {
      let params = {
        Opcion: 19,
        token: localStorage.getItem("token"),
        NPcliente1: this.item.NumeroPersonal,
      };
      axios
        .post(`${this.$store.getters.getHost}/General/ws-general.php`, params)
        .then((response) => {
          if (response.data.status == "OK") {
            this.armarColaboradores(response.data.data);
            this.loading = false;
          }
        });
    },

    fixFormatDate(date) {
      return formatDate(date);
    },

    autorizar() {
      let params = {
        Opcion: 6,
        item: this.item,
        token: localStorage.getItem("token"),
      };
      this.loading = true;
      axios
        .post(
          `${this.$store.getters.getHost}/RelacionesLaborales/Reportes/ws-IncidenciasPorFechas.php`,
          params
        )
        .then((response) => {
            this.loading = false;
          if (response.data.status == "OK") {
            this.textoBarra = "Incidencia autorizada correctamente.";
            this.colorBarra = "success";
            this.muestraBarra = true;
            return new Promise((resolve) => {
              setTimeout(() => {
                resolve(this.$emit("cierraDetalle", "OK"));
              }, 2000);
            });
          }
        });
    },

    getHour(hora) {
      let date = new Date(hora);
      let hours = date.getHours();
      let minutes = date.getMinutes();
      let ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12;
      hours = hours ? hours : 12;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      const time = hours + ":" + minutes + " " + ampm;
      return time;
    },
  },
};
</script>
