<template>
  <div>
    <v-dialog v-model="muestraAgregaBenficio" content-class="fix-border-dialog" max-width="700" scrollable>
      <v-card class="rounded-xl">
        <v-card-title class="primary rounded-xl rounded-b-0 white--text subtitle-1 font-weight-bold">
          Aministrar beneficio
          <v-spacer></v-spacer>
          <v-btn icon dark @click="$emit('ocultaAgregaBeneficio', 0)">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-form ref="formAddBeneficio">
            <v-row dense>
              <v-col :cols="['xs', 'sm'].includes($vuetify.breakpoint.name) ? 6 : 6">
                <label class="overline"> Rango </label>
                <v-text-field v-model="rango" dense class="rounded-lg" outlined required
                  placeholder="Rango que aplica..."></v-text-field>
              </v-col>
              <v-col :cols="['xs', 'sm'].includes($vuetify.breakpoint.name) ? 6 : 6">
                <label class="overline"> No. Prestación </label>
                <v-text-field v-model="noPrestacion" type="number" dense class="rounded-lg" outlined required
                  placeholder="Prestación..."></v-text-field>
              </v-col>
              <v-col :cols="['xs', 'sm'].includes($vuetify.breakpoint.name) ? 6 : 6">
                <label class="overline"> Monto </label>
                <v-text-field prefix="$" type="number" v-model="monto" dense class="rounded-lg" :rows="2" required
                  outlined placeholder="Ingresa el monto..."></v-text-field>
              </v-col>
              <v-col :cols="['xs', 'sm'].includes($vuetify.breakpoint.name) ? 6 : 6">
                <label class="overline"> % Prestación </label>
                <v-text-field v-model="prestacion" type="number" dense class="rounded-lg" required outlined
                  placeholder="% de prestación..." item-text="text" value="value"></v-text-field>
              </v-col>
              <v-col :cols="['xs', 'sm'].includes($vuetify.breakpoint.name) ? 6 : 6">
                <label class="overline"> Empresa </label>
                <v-text-field prefix="$" type="number" v-model="empresa" dense class="rounded-lg" required outlined
                  placeholder="Monto de la empresa..." item-text="text" readonly value="value"></v-text-field>
              </v-col>
              <v-col :cols="['xs', 'sm'].includes($vuetify.breakpoint.name) ? 6 : 6">
                <label class="overline"> Empleado </label>
                <v-text-field prefix="$" type="number" v-model="empleado" dense class="rounded-lg" required outlined
                  maxlength="50" placeholder="Monto paga el usuario al 4to año..." item-text="text" readonly
                  value="value"></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="pa-0">
          <v-btn class="rounded-xl rounded-t-0" block text color="teal darken-1" x-large :loading="loadingSave"
            @click="loadingSave = true; saveBeneficio()">
            guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <SnackBar v-if="muestraBarra" :muestraBarra="muestraBarra" :color="colorBarra" :texto="textoBarra"
      @cierraBarra="muestraBarra = false" />
  </div>
</template>
  
<script>
import axios from "axios";
import SnackBar from "../../../General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue";

export default {
  name: "agrega-beneficio",
  props: ["muestraAgregaBenficio", "beneficio"],
  components: { SnackBar },
  data() {
    return {
      itemscolaboradores: [],
      rango: "",
      noPrestacion: "",
      monto: "",
      prestacion: "",
      empresa: "",
      empleado: "",
      loadingnames: false,
      loadingSave: false,
      muestraBarra: false,
      textoBarra: "",
      colorBarra: "",

    };
  },
  watch: {
    monto() {
      this.calculate();
    },
    prestacion() {
      this.calculate();
    }
  },
  mounted() {
    if (this.beneficio != null) {
      this.rango = this.beneficio.rango;
      this.noPrestacion = this.beneficio.noPrestacion;
      this.monto = this.beneficio.monto;
      this.prestacion = this.beneficio.prestacion;
      this.empresa = this.beneficio.empresa;
      this.empleado = this.beneficio.empleado;
    }
  },
  methods: {
    muestraSnackBar(color, texto) {
      this.colorBarra = color;
      this.textoBarra = texto;
      this.muestraBarra = true;
    },
    async saveBeneficio() {
      if (this.$refs.formAddBeneficio.validate()) {
        let opc = this.beneficio === null ? 2 : 3;
        const params = {
          Opcion: opc,
          token: localStorage.getItem("token"),
          rango: this.rango,
          monto: this.monto,
          noPrestacion: this.noPrestacion,
          prestacion: this.prestacion,
          empresa: this.empresa,
          empleado: this.empleado,
          idBeneficio: this.beneficio != null ? this.beneficio.id : 0,
        };
        axios
          .post(
            `${this.$store.getters.getHost}/Compensaciones/ws-tabulador-beneficios-flexibles.php`,
            params
          )
          .then((response) => {
            if (response.data.status == "OK") {
              this.$refs.formAddBeneficio.reset();
              this.$refs.formAddBeneficio.resetValidation();
              this.muestraSnackBar(
                "teal darken-1",
                response.data.message
              );
              this.$emit('ocultaAgregaBeneficio', 1)
            } else {
              this.muestraSnackBar(
                "pink darken-3",
                response.data.message
              );
            }
          })
          .catch((error) => {
            this.muestraSnackBar("pink darken-3", error);
          })
          .finally(() => {
            this.loadingSave = false;
          });
      }
    },
    calculate() {
      if (this.monto && this.prestacion) {
        const total = this.monto;
        const empresa = total * (this.prestacion / 100);
        const empleado = total - empresa;
        this.empresa = empresa.toFixed(0);
        this.empleado = empleado.toFixed(0);
      } else {
        this.empresa = null;
        this.empleado = null;
      }
    }
  },
};
</script>
  