<template>
  <div>
    <v-row justify="center">
      <v-dialog
        v-model="muestraSolicitudesPendientesComite"
        fullscreen
        hide-overlay
        scrolleable
        transition="dialog-bottom-transition"
      >
        <v-card>
          <v-toolbar dark color="primary">
            <v-toolbar-title>Mis solicitudes pendientes</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn
              color="pink darken-4"
              dark
              rounded
              small
              @click="$emit('cierraSolicitudesPendientesComite')"
              elevation="2"
            >
              cerrar
              <v-icon right>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <v-container class="center-item-in-div">
              <v-card
                class="rounded-xl back-card-img pa-6 mx-2"
                max-width="300"
                height="200px"
              >
                <v-card
                  class="rounded-lg back-card-glass"
                  elevation="1"
                  width="100%"
                  height="100%"
                >
                  <v-card-title>
                    <v-icon large left dark>
                      mdi-receipt-text-plus
                    </v-icon>
                    <span class="title-h6-card white--text font-weight-bold">
                      Solicitudes generadas</span
                    >
                  </v-card-title>
                  <v-card-text style="display:flex; justify-content:center;">
                    <div class="text-center" v-if="loadingContent">
                      <v-progress-circular
                        :size="50"
                        indeterminate
                        color="white"
                      ></v-progress-circular>
                    </div>
                    <p
                      v-else
                      class="title-h2-card white--text font-weight-bold"
                    >
                      {{ datosNumeriodVacantes.solicitudesEnviadas }}
                    </p>
                  </v-card-text>
                </v-card>
              </v-card>
              <v-card
                class="rounded-xl back-card-img pa-6 mx-2"
                width="300px"
                height="200px"
              >
                <v-card
                  class="rounded-lg back-card-glass"
                  elevation="1"
                  whidth="100%"
                  height="100%"
                >
                  <v-card-title>
                    <v-icon large left dark>
                      mdi-account-plus
                    </v-icon>
                    <span class="title-h6-card white--text font-weight-bold">
                      Vacantes procesadas
                    </span>
                  </v-card-title>
                  <v-card-text style="display:flex; justify-content:center;">
                    <div class="text-center" v-if="loadingContent">
                      <v-progress-circular
                        :size="50"
                        indeterminate
                        color="white"
                      ></v-progress-circular>
                    </div>
                    <p
                      v-else
                      class="title-h2-card white--text font-weight-bold"
                    >
                      {{
                        datosNumeriodVacantes.vacantesPub +
                          datosNumeriodVacantes.nuevosPuestosSol
                      }}
                    </p>
                  </v-card-text>
                </v-card>
              </v-card>
            </v-container>
            <v-card class="mt-5 rounded-xl" width="100%" min-height="400px">
              <v-tabs
                v-model="tabs"
                class="rounded-xl rounded-b-0"
                fixed-tabs
                center-active
                background-color="primary"
                dark
              >
                <v-tabs-slider color="cyan"></v-tabs-slider>
                <v-tab>
                  Solicitudes generadas
                </v-tab>
                <v-tab>
                  Vacantes solicitadas
                </v-tab>
                <v-tab>
                  Puestos nuevos solicitados
                </v-tab>
              </v-tabs>

              <v-tabs-items v-model="tabs">
                <v-tab-item>
                  <v-data-table
                    :loading="loadingContent"
                    :headers="headers"
                    :items="solicitudesPendientes"
                    :expanded.sync="expanded"
                    height="100%"
                    loading-text="Cargando contentenido..."
                    :footer-props="{
                      'items-per-page-options': [10, 20, 30, 100],
                      'items-per-page-text': 'Elementos por página',
                    }"
                    no-data-text="No hay registros"
                    :header-props="{
                      'short-by-text': 'Ordenar por',
                    }"
                    class="elevation-0"
                    item-key="id"
                    show-expand
                    dense
                  >
                    <template v-slot:item.estatus_desc="{ item }">
                      <v-chip
                        :color="getColor(item.estatus_desc)"
                        dark
                        elevation="1"
                        small
                      >
                        {{ item.estatus_desc }}
                      </v-chip>
                    </template>
                    <template v-slot:item.actions="{ item }">
                      <v-chip
                        color="teal darken-2"
                        dark
                        small
                        v-if="item.estatus_desc == 'Pendiente'"
                      >
                        <v-icon left dark @click="getDetalles(item)" small>
                          mdi-email-fast
                        </v-icon>
                        Notificar
                      </v-chip>
                      <v-chip
                        color="pink darken-3"
                        dark
                        small
                        @click="openSolicitudDeVacante(item)"
                        v-else-if="item.estatus_desc == 'Rechazada'"
                      >
                        <v-icon left dark small>
                          mdi-pencil
                        </v-icon>
                        Editar
                      </v-chip>
                    </template>
                    <template v-slot:expanded-item="{ headers, item }">
                      <td
                        :colspan="headers.length"
                        v-if="item.estatus_desc == 'Pendiente'"
                        class="px-0 mx-0"
                      >
                        <v-row class="my-2" no-gutters>
                          <v-col cols="12" lg="2" md="3" sm="6" xs="12">
                            <v-subheader
                              class="py-0 mt-0 blue-grey--text text--darken-1 font-weight-bold"
                              style="height: 25px;"
                            >
                              Posiciónes solicitadas:
                            </v-subheader>
                            <v-subheader
                              class="py-0 my-0 text-left"
                              style="height: 25px;"
                            >
                              {{ item.num_vacantes }}
                            </v-subheader>
                          </v-col>
                          <v-col cols="12" lg="2" md="3" sm="6" xs="12">
                            <v-subheader
                              class="py-0 mt-0 blue-grey--text text--darken-1 font-weight-bold"
                              style="height: 25px;"
                            >
                              Razón de solicitud:
                            </v-subheader>
                            <v-subheader
                              class="py-0 my-0 text-left"
                              style="height: 25px;"
                            >
                              {{ item.razonDesc }}
                            </v-subheader>
                          </v-col>
                          <v-col cols="12" lg="2" md="3" sm="6" xs="12">
                            <v-subheader
                              class="py-0 mt-0 blue-grey--text text--darken-1 font-weight-bold"
                              style="height: 25px;"
                            >
                              Tipo de puesto:
                            </v-subheader>
                            <v-subheader
                              class="py-0 my-0 text-left"
                              style="height: 25px;"
                            >
                              {{ item.tipoDesc }}
                            </v-subheader>
                          </v-col>
                          <v-col cols="12" lg="3" md="3" sm="6" xs="12">
                            <div
                              v-if="
                                item.colaborador_involucrado == null ||
                                  item.colaborador_involucrado == '' ||
                                  item.colaborador_involucrado == 0
                              "
                            >
                              <v-subheader
                                class="py-0 mt-0 blue-grey--text text--darken-1 font-weight-bold"
                                style="height: 25px;"
                              >
                                Razón de solicitud:
                              </v-subheader>
                              <v-subheader
                                class="text-justify"
                                style="min-height: 25px;"
                              >
                                {{ item.razon_solicitud }}
                              </v-subheader>
                            </div>
                            <div v-else>
                              <v-subheader
                                class="py-0 mt-0 blue-grey--text text--darken-1 font-weight-bold"
                                style="height: 25px;"
                              >
                                Colaborador involucrado:
                              </v-subheader>
                              <v-subheader
                                class="text-center"
                                style="height: 25px; max-height: 50px;"
                              >
                                {{ item.colaborador_involucrado }} -
                                {{ item.EMP_NOMEMP }}
                              </v-subheader>
                            </div>
                          </v-col>
                          <v-col cols="12" lg="1" md="3" sm="6" xs="12">
                            <v-subheader
                              class="py-0 mt-0 blue-grey--text text--darken-1 font-weight-bold"
                              style="height: 25px"
                            >
                              JOBCODE:
                            </v-subheader>
                            <v-subheader class="text-left" style="height: 25px">
                              {{ item.idPuesto_asociado }}
                            </v-subheader>
                          </v-col>
                          <v-col cols="12" lg="2" md="3" sm="6" xs="12">
                            <v-subheader
                              class="py-0 mt-0 blue-grey--text text--darken-1 font-weight-bold"
                              style="height: 25px"
                            >
                              Equipo requerido:
                            </v-subheader>
                            <ul style="margin-left: 4px;">
                              <li v-if="item.req_laptop == 1">Laptop</li>
                              <li v-if="item.req_celular == 1">Celular</li>
                              <li v-if="item.req_epp == 1">EPP</li>
                            </ul>
                          </v-col>
                          <v-col cols="12" lg="3" md="3" sm="6" xs="12">
                            <v-subheader
                              class="py-0 mt-0 blue-grey--text text--darken-1 font-weight-bold"
                              style="height: 25px"
                            >
                              Sueldo de contratación:
                            </v-subheader>
                            <v-subheader class="text-left" style="height: 25px">
                              {{
                                Intl.NumberFormat("es-MX", {
                                  style: "currency",
                                  currency: "MXN",
                                }).format(Number.parseFloat(item.Contratacion))
                              }}
                            </v-subheader>
                          </v-col>
                          <v-col cols="12" lg="3" md="3" sm="6" xs="12">
                            <v-subheader
                              class="py-0 mt-0 blue-grey--text text--darken-1 font-weight-bold"
                              style="height: 25px"
                            >
                              Puesto contratación:
                            </v-subheader>
                            <v-subheader class="text-left" style="height: 25px">
                              {{ item.puestoUrrea }}
                            </v-subheader>
                          </v-col>
                          <v-col cols="12" lg="3" md="3" sm="6" xs="12">
                            <v-subheader
                              class="py-0 mt-0 blue-grey--text text--darken-1 font-weight-bold"
                              style="height: 25px"
                            >
                              Lugar de trabajo:
                            </v-subheader>
                            <v-subheader class="text-left" style="height: 25px">
                              {{ item.lugar }}
                            </v-subheader>
                          </v-col>
                          <v-col
                            v-if="item.comision == 1"
                            cols="12"
                            lg="3"
                            md="3"
                            sm="6"
                            xs="12"
                          >
                            <v-subheader
                              class="py-0 mt-0 blue-grey--text text--darken-1 font-weight-bold"
                              style="height: 25px"
                            >
                              Comisión:
                            </v-subheader>
                            <v-subheader class="text-left" style="height: 25px">
                              Valor: {{ item.comisionval }}. Garantía:
                              {{ item.comisiongar }}. Meses:
                              {{ item.comisionmes }}.
                            </v-subheader>
                          </v-col>
                        </v-row>
                      </td>
                      <td
                        :colspan="headers.length"
                        v-if="
                          item.estatus_desc == 'Rechazada' ||
                            item.estatus_desc == 'Aceptada'
                        "
                        class="px-0 mx-0"
                      >
                        <v-row class="my-2" no-gutters>
                          <v-col cols="12" lg="6" md="6" sm="12" xs="12">
                            <v-subheader
                              class="py-0 mt-0 blue-grey--text text--darken-1 font-weight-bold"
                              style="height: 25px;"
                            >
                              Comentario:
                            </v-subheader>
                            <v-subheader
                              class="py-0 my-0 text-justify"
                              style="height: 25px; max-height: 75px;"
                            >
                              {{ item.comentario_reclutamiento }}
                            </v-subheader>
                          </v-col>
                          <v-col cols="12" lg="3" md="3" sm="6" xs="12">
                            <v-subheader
                              class="py-0 mt-0 blue-grey--text text--darken-1 font-weight-bold"
                              style="height: 25px;"
                            >
                              Fecha de mofificación:
                            </v-subheader>
                            <v-subheader
                              class="py-0 my-0 text-justify"
                              style="height: 25px;"
                            >
                              {{ item.fecha_modifica }}
                            </v-subheader>
                          </v-col>
                          <v-col cols="12" lg="3" md="3" sm="6" xs="12">
                            <v-subheader
                              class="py-0 mt-0 blue-grey--text text--darken-1 font-weight-bold"
                              style="height: 25px;"
                            >
                              Reclutador:
                            </v-subheader>
                            <v-subheader
                              class="py-0 my-0 text-justify"
                              style="height: 25px;"
                            >
                              {{ item.NOM_RECLUT }}
                            </v-subheader>
                          </v-col>
                        </v-row>
                      </td>
                    </template>
                  </v-data-table>
                </v-tab-item>
                <v-tab-item>
                  <v-data-table
                    :loading="loadingContent"
                    :headers="headers_vacantesPub"
                    :items="vacantesPublicadas"
                    :expanded.sync="expanded_vacantesPub"
                    height="100%"
                    loading-text="Cargando contentenido..."
                    :footer-props="{
                      'items-per-page-options': [10, 20, 30, 100],
                      'items-per-page-text': 'Elementos por página',
                    }"
                    no-data-text="No hay registros"
                    :header-props="{
                      'short-by-text': 'Ordenar por',
                    }"
                    class="elevation-0"
                    item-key="id"
                    show-expand
                    dense
                  >
                    <template v-slot:item.descr="{ item }">
                      <v-chip
                        :color="getColorEstatusVacante(item.descr)"
                        dark
                        elevation="1"
                        small
                        @click="
                          getDetalleEstatus(
                            item.descr,
                            item.candidatosPostulados
                          )
                        "
                      >
                        {{
                          item.descr == "Publicada" &&
                          item.candidatosPostulados > 0
                            ? "En proceso de selección"
                            : item.descr
                        }}
                        <v-icon right small>
                          mdi-information-slab-circle-outline
                        </v-icon>
                      </v-chip>
                    </template>
                    <template v-slot:item.actions="{ item }">
                      <v-chip
                        color="teal darken-2"
                        dark
                        small
                        v-if="item.descr == 'Publicada'"
                        @click="openCandidatosPostulados(item)"
                      >
                        <v-icon left dark small>
                          mdi-account
                        </v-icon>
                        Ver candidatos
                      </v-chip>
                    </template>
                    <template v-slot:expanded-item="{ headers, item }">
                      <td :colspan="headers.length" class="px-0 mx-0">
                        <v-row class="my-2" no-gutters>
                          <v-col cols="12" lg="3" md="3" sm="6" xs="12">
                            <v-subheader
                              class="py-0 mt-0 blue-grey--text text--darken-1 font-weight-bold"
                              style="height: 25px;"
                            >
                              Fecha de alta:
                            </v-subheader>
                            <v-subheader
                              class="py-0 my-0 text-left"
                              style="height: 25px;"
                            >
                              {{ item.Fecha_alta }}
                            </v-subheader>
                          </v-col>
                          <v-col cols="12" lg="3" md="3" sm="6" xs="12">
                            <v-subheader
                              class="py-0 mt-0 blue-grey--text text--darken-1 font-weight-bold"
                              style="height: 25px;"
                            >
                              Fecha de aprobación por compensaciones:
                            </v-subheader>
                            <v-subheader
                              class="py-0 my-0 text-left"
                              style="height: 25px;"
                            >
                              {{ item.fecha_publicacion }}
                            </v-subheader>
                          </v-col>
                          <v-col cols="12" lg="3" md="3" sm="6" xs="12">
                            <v-subheader
                              class="py-0 mt-0 blue-grey--text text--darken-1 font-weight-bold"
                              style="height: 25px;"
                            >
                              Tipo de puesto:
                            </v-subheader>
                            <v-subheader
                              class="py-0 my-0 text-left"
                              style="height: 25px;"
                            >
                              {{ item.tipoDesc }}
                            </v-subheader>
                          </v-col>
                          <v-col cols="12" lg="3" md="3" sm="6" xs="12">
                            <v-subheader
                              class="py-0 mt-0 blue-grey--text text--darken-1 font-weight-bold"
                              style="height: 25px;"
                            >
                              Razón de solicitud:
                            </v-subheader>
                            <v-subheader
                              class="py-0 my-0 text-left"
                              style="height: 25px;"
                            >
                              {{ item.razonDesc }}
                            </v-subheader>
                          </v-col>
                          <v-col cols="12" lg="3" md="3" sm="6" xs="12">
                            <v-subheader
                              class="py-0 mt-0 blue-grey--text text--darken-1 font-weight-bold"
                              style="height: 25px;"
                            >
                              Complemento o colaborador involucrado:
                            </v-subheader>
                            <v-subheader
                              class="py-0 my-0 text-justify"
                              style="height: 25px;"
                            >
                              {{ item.complemento }}.
                            </v-subheader>
                          </v-col>
                          <v-col
                            cols="12"
                            lg="3"
                            md="3"
                            sm="6"
                            xs="12"
                            v-if="item.comision == 1"
                          >
                            <v-subheader
                              class="py-0 mt-0 blue-grey--text text--darken-1 font-weight-bold"
                              style="height: 25px;"
                            >
                              Detalles de comisión:
                            </v-subheader>
                            <v-subheader
                              class="py-0 my-0 text-left"
                              style="height: 25px;"
                            >
                              Valor: {{ item.comisionval }}. Garantía:
                              {{ item.comisiongar }}. Meses:
                              {{ item.comisionmes }}
                            </v-subheader>
                          </v-col>
                        </v-row>
                      </td>
                    </template>
                  </v-data-table>
                </v-tab-item>
                <v-tab-item>
                  <v-data-table
                    :loading="loadingContent"
                    :headers="headers_nuevosPuestosSol"
                    :items="puestosNuevosSolicitados"
                    :expanded.sync="expanded_puestosNuevosSol"
                    height="100%"
                    loading-text="Cargando contentenido..."
                    :footer-props="{
                      'items-per-page-options': [10, 20, 30, 100],
                      'items-per-page-text': 'Elementos por página',
                    }"
                    no-data-text="No hay registros"
                    :header-props="{
                      'short-by-text': 'Ordenar por',
                    }"
                    class="elevation-0"
                    item-key="id"
                    show-expand
                    dense
                  >
                    <template v-slot:item.descr="{ item }">
                      <v-chip
                        :color="getColorEstatusVacante(item.descr)"
                        dark
                        elevation="1"
                        small
                      >
                        {{ item.descr }}
                      </v-chip>
                    </template>
                    <template v-slot:expanded-item="{ headers, item }">
                      <td :colspan="headers.length" class="px-0 mx-0">
                        <v-row class="my-2" no-gutters>
                          <v-col cols="12" lg="3" md="3" sm="6" xs="12">
                            <v-subheader
                              class="py-0 mt-0 blue-grey--text text--darken-1 font-weight-bold"
                              style="height: 25px;"
                            >
                              Fecha de alta:
                            </v-subheader>
                            <v-subheader
                              class="py-0 my-0 text-left"
                              style="height: 25px;"
                            >
                              {{ item.Fecha_alta }}
                            </v-subheader>
                          </v-col>
                          <v-col cols="12" lg="3" md="3" sm="6" xs="12">
                            <v-subheader
                              class="py-0 mt-0 blue-grey--text text--darken-1 font-weight-bold"
                              style="height: 25px;"
                            >
                              Fecha de aprobación por compensaciones:
                            </v-subheader>
                            <v-subheader
                              class="py-0 my-0 text-left"
                              style="height: 25px;"
                            >
                              {{ item.fecha_publicacion }}
                            </v-subheader>
                          </v-col>
                          <v-col cols="12" lg="3" md="3" sm="6" xs="12">
                            <v-subheader
                              class="py-0 mt-0 blue-grey--text text--darken-1 font-weight-bold"
                              style="height: 25px;"
                            >
                              Tipo de puesto:
                            </v-subheader>
                            <v-subheader
                              class="py-0 my-0 text-left"
                              style="height: 25px;"
                            >
                              {{ item.tipoDesc }}
                            </v-subheader>
                          </v-col>
                          <v-col cols="12" lg="3" md="3" sm="6" xs="12">
                            <v-subheader
                              class="py-0 mt-0 blue-grey--text text--darken-1 font-weight-bold"
                              style="height: 25px;"
                            >
                              Razón de solicitud:
                            </v-subheader>
                            <v-subheader
                              class="py-0 my-0 text-left"
                              style="height: 25px;"
                            >
                              {{ item.razonDesc }}
                            </v-subheader>
                          </v-col>
                          <v-col
                            cols="12"
                            lg="3"
                            md="3"
                            sm="6"
                            xs="12"
                            v-if="item.comision == 1"
                          >
                            <v-subheader
                              class="py-0 mt-0 blue-grey--text text--darken-1 font-weight-bold"
                              style="height: 25px;"
                            >
                              Detalles de comisión:
                            </v-subheader>
                            <v-subheader
                              class="py-0 my-0 text-left"
                              style="height: 25px;"
                            >
                              Valor: {{ item.comisionval }}. Garantía:
                              {{ item.comisiongar }}. Meses:
                              {{ item.comisionmes }}
                            </v-subheader>
                          </v-col>
                        </v-row>
                      </td>
                    </template>
                  </v-data-table>
                </v-tab-item>
              </v-tabs-items>
            </v-card>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
    <VerCandidatos
      v-if="abreVerCandidatos"
      :verCandidatos="abreVerCandidatos"
      :idVacante="idVacante"
      @cerrarVerCandidatos="abreVerCandidatos = false"
    />

    <SolicitaPuestoExistente
      v-if="muestraSolicitudDeVacante"
      :muestraSolicitaPuestoExistente="muestraSolicitudDeVacante"
      :idPuesto="null"
      :idSolicitud="idSolicitud"
      @cierraSolicitud="muestraSolicitudDeVacante = false"
    />

    <v-dialog
      v-model="dialogDescEstatus"
      width="300"
      content-class="fix-border-dialog"
      persistent
    >
      <v-card class="rounded-xl">
        <v-card-title class="subtitle-1 grey lighten-2">
          Descripción de estatus
        </v-card-title>

        <v-card-text class="pt-5">
          {{ descEstatus }}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            class="font-weight-bold small"
            text
            @click="dialogDescEstatus = false"
          >
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
// import { deleteToken } from "../../../../../../store/Funciones/funciones";

import VerCandidatos from "./VerCandidatosPostulados.vue";
import SolicitaPuestoExistente from "./SolicitaPuestoExistente.vue";
import { deleteToken } from "../../../../../../store/Funciones/funciones";

export default {
  name: "solicitudes-pendientes-vista-comite",
  props: ["muestraSolicitudesPendientesComite", "idArea"],
  components: { VerCandidatos, SolicitaPuestoExistente },
  data() {
    return {
      tabs: null,
      idSolicitud: null,
      solicitudesPendientes: [],
      vacantesPublicadas: [],
      puestosNuevosSolicitados: [],
      expanded: [],
      expanded_vacantesPub: [],
      expanded_puestosNuevosSol: [],
      headers: [
        {
          text: "ID",
          align: "center",
          value: "id",
        },
        {
          text: "Puesto",
          align: "center",
          value: "nombre",
        },
        {
          text: "Solicitante",
          value: "nombreSolicitante",
        },
        {
          text: "Estatus",
          value: "estatus_desc",
          align: "center",
        },
        //   {
        //     text: "Acciones",
        //     align: "center",
        //     value: "actions",
        //   },
      ],
      headers_vacantesPub: [
        {
          text: "ID",
          align: "center",
          value: "id",
        },
        {
          text: "Puesto",
          align: "center",
          value: "Nombre",
        },
        {
          text: "Solicitante",
          align: "center",
          value: "nombreSolicitante",
        },
        {
          text: "Estatus",
          value: "descr",
          align: "center",
        },
        {
          text: "Acciones",
          align: "center",
          value: "actions",
        },
      ],
      headers_nuevosPuestosSol: [
        {
          text: "ID",
          align: "center",
          value: "id",
        },
        {
          text: "Puesto",
          align: "center",
          value: "Nombre",
        },
        {
          text: "Estatus",
          value: "descr",
          align: "center",
        },
        {
          text: "Acciones",
          align: "center",
          value: "actions",
        },
      ],
      loadingContent: true,
      abreVerCandidatos: false,
      muestraSolicitudDeVacante: false,
      dialogDescEstatus: false,
      datosNumeriodVacantes: {
        vacantesPub: 0,
        nuevosPuestosSol: 0,
        solicitudesEnviadas: 0,
      },
      descEstatus: "",
      idVacante: 0,
    };
  },
  created() {},
  mounted() {
    this.getSolicitudesPendientes();
  },
  methods: {
    getSolicitudesPendientes() {
      let params = {
        Opcion: 21,
        token: localStorage.getItem("token"),
        area: this.idArea,
      };
      axios
        .post(
          `${this.$store.getters.getHost}/Reclutamiento/ws-control-de-puestos.php`,
          params
        )
        .then((response) => {
          if (response.data.status != "EXPSESSION") {
            if (response.data.status !== "ERROR") {
              this.solicitudesPendientes = response.data.solicitudesPendientes;
              this.datosNumeriodVacantes = response.data.numeros;
            }
          } else {
            deleteToken();
          }
        })
        .finally(() => {
          this.getVacantesPublicadas();
        });
    },
    getVacantesPublicadas() {
      let params = {
        Opcion: 22,
        token: localStorage.getItem("token"),
        area: this.idArea,
      };
      axios
        .post(
          `${this.$store.getters.getHost}/Reclutamiento/ws-control-de-puestos.php`,
          params
        )
        .then((response) => {
          if (response.data.status != "EXPSESSION") {
            this.vacantesPublicadas = response.data.vacantes;
          }
        })
        .finally(() => {
          this.getPuestosNuevosSolicitados();
        });
    },
    getPuestosNuevosSolicitados() {
      let params = {
        Opcion: 23,
        token: localStorage.getItem("token"),
        area: this.idArea,
      };
      axios
        .post(
          `${this.$store.getters.getHost}/Reclutamiento/ws-control-de-puestos.php`,
          params
        )
        .then((response) => {
          if (response.data.status != "EXPSESSION") {
            this.puestosNuevosSolicitados = response.data.nuevosPuestos;
          }
        })
        .finally(() => {
          console.log("llega a finally");
          this.loadingContent = false;
        });
    },
    getColor(estatus) {
      if (estatus == "Rechazada") return "red darken-4";
      else if (estatus == "Aceptada") return "green darken-2";
      else return "cyan";
    },
    getColorEstatusVacante(estatus) {
      if (estatus == "Completada") return "green darken-4";
      else if (estatus == "Publicada") return "green darken-1";
      else if (estatus == "Rechazada") return "red darken-4";
      else return "gray";
    },
    openEdita(item) {
      console.log(item.id);
    },
    openCandidatosPostulados(item) {
      this.idVacante = item.id;
      this.abreVerCandidatos = true;
    },
    openSolicitudDeVacante(item) {
      this.idSolicitud = item.id;
      this.muestraSolicitudDeVacante = true;
    },
    getDetalleEstatus(descr, candidatosPostulados) {
      switch (descr) {
        case "Aut Compensaciones": {
          this.descEstatus =
            "En espera de que el departamento de compensaciónes valide información de mercado.";
          break;
        }
        case "Aut JefeDirecto": {
          this.descEstatus =
            "En espera de que tu jefe directo autorice la creación de la vacante.";
          break;
        }
        case "Aut DirectorArea": {
          this.descEstatus =
            "En espera de que tu director de área autorice la creación de la vacante.";
          break;
        }
        case "Aut DirectorGeneral": {
          this.descEstatus =
            "En espera de que el CEO autorice la creación de la vacante.";
          break;
        }
        case "Publicada": {
          if (candidatosPostulados === 0)
            this.descEstatus =
              "En espera de que se postulen candidatos a la vacante.";
          else this.descEstatus = "En proceso de selección de candidatos.";
          break;
        }
        case "Completada": {
          this.descEstatus = "La vacante se completo con éxito.";
          break;
        }
        case "Rechazada": {
          this.descEstatus = "La vacante se rechazó.";
          break;
        }
        default: {
          this.descEstatus =
            "No se encontró ninguna descripción para este estatus.";
          break;
        }
      }
      this.dialogDescEstatus = true;
    },
  },
};
</script>

<style>
.back-card-img {
  background-image: url("../../../../../../assets/img/wave-haikei.svg");
}

.back-card-glass {
  background: rgba(180, 180, 180, 0.1) !important;
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(6.6px);
  -webkit-backdrop-filter: blur(6.6px);
  border: 1px solid rgba(180, 180, 180, 0.43) !important;
}

.title-h6-card {
  font-weight: 500;
  font-size: 1.25rem;
  letter-spacing: 0.0125em;
}

.title-h2-card {
  font-weight: 300;
  font-size: 3.75rem;
  letter-spacing: -0.0083333333em;
}

.alinear-vertical-izq {
  display: flex;
  justify-content: center;
  text-align: center;
}
</style>
