var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-0",staticStyle:{"background-color":"transparent"},attrs:{"dense":"","headers":_vm.headers,"items":_vm.mapaDeTalento,"loading":_vm.loadingData,"items-per-page":10,"search":_vm.search,"footer-props":{
        'items-per-page-text': 'Elementos por página',
        pageText: '{0}-{1} de {2}',
      },"expanded":_vm.expanded,"item-key":"id","show-expand":"","single-expand":"","no-data-text":"No hay registros","loading-text":"Cargando información..."},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticStyle:{"display":"flex","justify-content":"space-between"}},[_c('v-text-field',{staticClass:"rounded-pill",attrs:{"dense":"","outlined":"","prepend-inner-icon":"mdi-magnify","placeholder":"Buscar..."},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('div',[_c('vue-json-to-csv',{attrs:{"json-data":_vm.mapaDeTalento,"labels":_vm.headers_to_export,"csv-title":"ReporteMapaDeTalento"}},[_c('v-tooltip',{attrs:{"bottom":"","color":"green darken-2"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"rounded-pill mr-2",attrs:{"depressed":"","outlined":"","fab":"","small":"","color":"green darken-2","dark":"","loading":_vm.loadingData}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-microsoft-excel")])],1)]}}])},[_c('span',[_vm._v("Exportar CSV")])])],1),_c('v-tooltip',{attrs:{"bottom":"","color":"blue-grey darken-2"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"depressed":"","fab":"","small":"","outlined":"","color":"blue-grey darken-2","loading":_vm.loadingData},on:{"click":_vm.actualizarTabla}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-reload")])],1)]}}])},[_c('span',[_vm._v("Actualizar tabla")])]),_c('v-tooltip',{attrs:{"bottom":"","color":"cyan darken-2"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"depressed":"","fab":"","small":"","outlined":"","color":"cyan darken-2","loading":_vm.loadingData},on:{"click":_vm.dialogCargarArchivo}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-upload")])],1)]}}])},[_c('span',[_vm._v("Subir archivo")])])],1)],1)]},proxy:true},{key:"item.talento",fn:function(ref){
      var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":"","color":"teal darken-2"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.openDialogCuadrante(item)}}},'div',attrs,false),on),[_vm._v(" "+_vm._s(item.talento)+" "),_c('v-icon',{attrs:{"color":"teal darken-4"}},[_vm._v(" mdi-crop-square ")])],1)]}}],null,true)},[_c('label',{staticClass:"text-uppercase font-weight-bold white--text"},[_vm._v("Ver Cuadrante")])])]}},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":"","color":"blue lighten-4"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"blue darken-4"},on:{"click":function($event){return _vm.abrirFormulario(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil-box-outline ")])]}}],null,true)},[_c('label',{staticClass:"text-uppercase font-weight-bold primary--text"},[_vm._v("Editar")])]),_c('v-tooltip',{attrs:{"top":"","color":"pink lighten-4"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"pink darken-4"},on:{"click":function($event){return _vm.openDialogRemove(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete-empty-outline ")])]}}],null,true)},[_c('label',{staticClass:"text-uppercase font-weight-bold pink--text"},[_vm._v("Remover")])])]}},{key:"expanded-item",fn:function(ref){
      var headers = ref.headers;
      var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-simple-table',{staticClass:"px-5",scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("GPTW")]),_c('th',{staticClass:"text-left"},[_vm._v("Porcentaje Satisfacción")]),_c('th',{staticClass:"text-left"},[_vm._v("360")]),_c('th',{staticClass:"text-left"},[_vm._v("Cultura")]),_c('th',{staticClass:"text-left"},[_vm._v("Puntos Eval.")]),_c('th',{staticClass:"text-left"},[_vm._v("Año")])])]),_c('tbody',[_c('tr',[_c('td',[_vm._v(_vm._s(item.gptw))]),_c('td',[_vm._v(_vm._s(item.porcentajeSatisfaccion))]),_c('td',[_vm._v(_vm._s(item.puntos360))]),_c('td',[_vm._v(_vm._s(item.cultura))]),_c('td',[_vm._v(_vm._s(item.puntosEval))]),_c('td',[_vm._v(_vm._s(item.anio))])])])]},proxy:true}],null,true)})],1)]}}])}),(_vm.openCargaArchivo)?_c('cargar-mapa-de-talento',{attrs:{"dialog":_vm.openCargaArchivo},on:{"cierraDialog":_vm.cierraDialog}}):_vm._e()],1),_c('confirm-dialog-reclutamiento',{attrs:{"showConfirm":_vm.dialogRemove,"question":_vm.question},on:{"cierraConfirm":_vm.cierraConfirm}}),(_vm.dialogForm)?_c('form-mapa-de-talento',{attrs:{"dialog":_vm.dialogForm,"item":_vm.item},on:{"cierraForm":_vm.cierraForm}}):_vm._e(),(_vm.dialogCuadrante)?_c('cuadrante-mapa-de-talento',{attrs:{"dialog":_vm.dialogCuadrante,"item":_vm.item},on:{"cierraCuadrante":_vm.cierraCuadrante}}):_vm._e(),(_vm.muestraBarra)?_c('SnackBarErrorIncidencias',{attrs:{"muestraBarra":_vm.muestraBarra,"texto":_vm.textoBarra,"color":_vm.colorBarra},on:{"cierraBarra":function($event){_vm.muestraBarra = false}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }