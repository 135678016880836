<template>
  <v-dialog
    v-model="muestraDetalles"
    transition="dialog-bottom-transition"
    scrollable
  >
    <div v-if="loadContenido">
      <div class="container-circle">
        <div class="circle"></div>
        <div class="circle"></div>
        <div class="circle"></div>
      </div>
    </div>
    <div v-else>
      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title>Detalles por usuario</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark text @click="$emit('cierraDialog')">
              Cerrar
            </v-btn>
          </v-toolbar-items>
          <template v-slot:extension>
            <v-tabs v-model="tab" background-color="primary" dark>
              <v-tab>
                Dias solicitados por mes y año
              </v-tab>
              <v-tab>
                Incidencias de vacaciones registradas
              </v-tab>
              <v-tab>
                Días otorgados por antigüedad
              </v-tab>
            </v-tabs>
          </template>
        </v-toolbar>

        <v-tabs-items v-model="tab">
          <v-tab-item>
            <v-container>
                <div class="text-right">
                <vue-json-to-csv
                  :json-data="acumulado"
                  :labels="headers_acumulados_vacaciones"
                  :csv-title="'VacacionesAcumuladas_' + empleado"
                >
                  <v-btn small elevation="2" color="success">
                    Exportar
                    <v-icon right small> mdi-microsoft-excel </v-icon>
                  </v-btn>
                </vue-json-to-csv>
              </div>
              <br>
              <v-card elevation="2">
                <v-simple-table height="450px" dense>
                  <template v-slot:default>
                    <thead style="background-color:rgb(0,120,180); color:white; font-weight:bold;" dark>
                      <tr>
                        <th class="text-center" style="color:white; font-weight:bold;">
                          Emp.
                        </th>
                        <th class="text-center" style="color:white; font-weight:bold;">
                          Proc.
                        </th>
                        <th class="text-center" style="color:white; font-weight:bold;">
                          ENE
                        </th>
                        <th class="text-center" style="color:white; font-weight:bold;">
                          FEB
                        </th>
                        <th class="text-center" style="color:white; font-weight:bold;">
                          MAR
                        </th>
                        <th class="text-center" style="color:white; font-weight:bold;">
                          ABR
                        </th>
                        <th class="text-center" style="color:white; font-weight:bold;">
                          MAY
                        </th>
                        <th class="text-center" style="color:white; font-weight:bold;">
                          JUN
                        </th>
                        <th class="text-center" style="color:white; font-weight:bold;">
                          JUL
                        </th>
                        <th class="text-center" style="color:white; font-weight:bold;">
                          AGO
                        </th>
                        <th class="text-center" style="color:white; font-weight:bold;">
                          SEP
                        </th>
                        <th class="text-center" style="color:white; font-weight:bold;">
                          OCT
                        </th>
                        <th class="text-center" style="color:white; font-weight:bold;">
                          NOV
                        </th>
                        <th class="text-center" style="color:white; font-weight:bold;">
                          DIC
                        </th>
                        <th class="text-center" style="color:white; font-weight:bold;">
                          ACUM ANT.
                        </th>
                        <th class="text-center" style="color:white; font-weight:bold;">
                          AÑO
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in acumulado" :key="item.acu_anio">
                        <td class="text-center">{{ item.acu_keyemp }}</td>
                        <td class="text-center">{{ item.acu_keypro }}</td>
                        <td class="text-center">{{ item.acu_uniuno }}</td>
                        <td class="text-center">{{ item.acu_unidos }}</td>
                        <td class="text-center">{{ item.acu_unitre }}</td>
                        <td class="text-center">{{ item.acu_unicua }}</td>
                        <td class="text-center">{{ item.acu_unicin }}</td>
                        <td class="text-center">{{ item.acu_unisei }}</td>
                        <td class="text-center">{{ item.acu_unisie }}</td>
                        <td class="text-center">{{ item.acu_unioch }}</td>
                        <td class="text-center">{{ item.acu_uninue }}</td>
                        <td class="text-center">{{ item.acu_unidie }}</td>
                        <td class="text-center">{{ item.acu_unionc }}</td>
                        <td class="text-center">{{ item.acu_unidoc }}</td>
                        <td class="text-center">{{ item.acu_uniqui }}</td>
                        <td class="text-center">{{ item.acu_anio }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card>
            </v-container>
          </v-tab-item>
          <v-tab-item>
            <v-container>
              <div class="text-right">
                <vue-json-to-csv
                  :json-data="vacaciones"
                  :labels="headers_incidence_saved"
                  :csv-title="'IncidenciasRegistradasVac_' + empleado"
                >
                  <v-btn small elevation="2" color="success">
                    Exportar
                    <v-icon right small> mdi-microsoft-excel </v-icon>
                  </v-btn>
                </vue-json-to-csv>
              </div>
              <br>
              <v-card elevation="2">
                <v-simple-table height="600px" dense elevation="2">
                  <template v-slot:default>
                    <thead style="background-color:rgb(0,120,180); color:white; font-weight:bold;" dark>
                      <tr>
                        <th class="text-center" style="color:white; font-weight:bold;">
                          Emp.
                        </th>
                        <th class="text-center" style="color:white; font-weight:bold;">
                          Inc.
                        </th>
                        <th class="text-center" style="color:white; font-weight:bold;">
                          Est.
                        </th>
                        <th class="text-center" style="color:white; font-weight:bold;">
                          Inicio
                        </th>
                        <th class="text-center" style="color:white; font-weight:bold;">
                          Fin
                        </th>
                        <th class="text-center" style="color:white; font-weight:bold;">
                          Pend.
                        </th>
                        <th class="text-center" style="color:white; font-weight:bold;">
                          Sol.
                        </th>
                        <th class="text-center" style="color:white; font-weight:bold;">
                          Tot.
                        </th>
                        <th class="text-center" style="color:white; font-weight:bold;">
                          Año P.
                        </th>
                        <th class="text-center" style="color:white; font-weight:bold;">
                          Mes P.
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in vacaciones" :key="item.ID">
                        <td class="text-center">{{ item.NumeroPersonal }}</td>
                        <td class="text-center">{{ item.IncDescripcion }}</td>
                        <td class="text-center">{{ item.EstDescripcion }}</td>
                        <td class="text-center">{{ item.FechaInicialDes }}</td>
                        <td class="text-center">{{ item.FechaFinalDes }}</td>
                        <td class="text-center">{{ item.DiasPendientes }}</td>
                        <td class="text-center">{{ item.DiasSolicitados }}</td>
                        <td class="text-center">{{ item.DiasTotales }}</td>
                        <td class="text-center">{{ item.AnioPago }}</td>
                        <td class="text-center">{{ item.MesPago }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card>
            </v-container>
          </v-tab-item>
          <v-tab-item>
            <v-container>
              <div class="text-right">
                <vue-json-to-csv
                  :json-data="vacPorAnio"
                  :labels="headers_table_vac_per_year"
                  :csv-title="'vacOtorgadas_' + empleado"
                >
                  <v-btn small elevation="2" color="success">
                    Exportar
                    <v-icon right small> mdi-microsoft-excel </v-icon>
                  </v-btn>
                </vue-json-to-csv>
              </div>
              <br>
              <v-card elevation="2">
                <v-simple-table height="400px" dense elevation="2">
                  <template v-slot:default>
                    <thead style="background-color:rgb(0,120,180); color:white; font-weight:bold;" dark>
                      <tr>
                        <th class="text-center" style="color:white; font-weight:bold;">
                          Año de antigüedad
                        </th>
                        <th class="text-center" style="color:white; font-weight:bold;">
                          Año
                        </th>
                        <th class="text-center" style="color:white; font-weight:bold;">
                          Días
                        </th>
                        <th class="text-center" style="color:white; font-weight:bold;">
                          Acumulado
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in vacPorAnio" :key="item.ID">
                        <td class="text-center">{{ item.id }}</td>
                        <td class="text-center">{{ item.anio }}</td>
                        <td class="text-center">{{ item.dias }}</td>
                        <td class="text-center">{{ item.acumulado }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card>
            </v-container>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </div>

    <!-- <LoadingBar :dialog="loadContenido" :texto="Cargando contenido." /> -->
  </v-dialog>
</template>

<script>
import axios from "axios";
import VueJsonToCsv from "vue-json-to-csv";
// import LoadingBar from '../../../../DesarrolloOrganizacional/components/loadingBar.vue'
import { deleteToken } from "../../../../../../store/Funciones/funciones";
export default {
  name: "muestra-detalles-vacaciones",
  components: {
    // LoadingBar
    VueJsonToCsv,
  },
  props: ["muestraDetalles", "empleado", "tab"],
  data() {
    return {
      acumulado: [],
      vacaciones: [],
      vacPorAnio: [],
      headers_acumulados_vacaciones: {
        acu_keyemp: {title: 'Empleado'}, 
        acu_keypro: {title: 'Proceso'}, 
        acu_uniuno: {title: 'ENE'}, 
        acu_unidos: {title: 'FEB'}, 
        acu_unitre: {title: 'MAR'}, 
        acu_unicua: {title: 'ABR'}, 
        acu_unicin: {title: 'MAY'}, 
        acu_unisei: {title: 'JUN'}, 
        acu_unisie: {title: 'JUL'}, 
        acu_unioch: {title: 'AGO'}, 
        acu_uninue: {title: 'SEP'}, 
        acu_unidie: {title: 'OCT'}, 
        acu_unionc: {title: 'NOV'}, 
        acu_unidoc: {title: 'DIC'}, 
        acu_uniqui: {title: 'Acumulado'}, 
        acu_anio: {title: 'Año'}
      },
      headers_incidence_saved: {
        NumeroPersonal: { title: "Numero Personal" },
        IncDescripcion: { title: "Tipo" },
        EstDescripcion: { title: "Estatus" },
        FechaInicialDes: { title: "Fecha Inicial" },
        FechaFinalDes: { title: "Fecha Final" },
        DiasPendientes: { title: "Dias Pendientes" },
        DiasSolicitados: { title: "Dias Solicitados" },
        DiasTotales: { title: "Dias Totales" },
        AnioPago: { title: "Año Pago" },
        MesPago: { title: "Mes Pago" },
      },
      headers_table_vac_per_year: {
        id: { title: "id" },
        anio: { title: "anio" },
        dias: { title: "dias" },
        acumulado: { title: "acumulado" },
      },
      loadContenido: true,
      // tab: null,
    };
  },
  mounted() {
    // if (this.muestraDetalles) {
    this.getAcumulados();
    // }
  },
  methods: {
    getAcumulados() {
      let params = {
        Opcion: 5,
        token: localStorage.getItem("token"),
        empleado: this.empleado,
      };
      axios
        .post(
          `${this.$store.getters.getHost}/Incidencias/ws-incidencias-vacaciones.php `,
          params
        )
        .then((response) => {
          // this.loadingColabs = false;
          if (response.data.status == "EXPSESSION") {
            deleteToken();
          } else {
            var data = response.data.reporte;
            this.acumulado = data;
            this.getIncidencias();
          }
        });
    },
    getIncidencias() {
      let params = {
        Opcion: 6,
        token: localStorage.getItem("token"),
        empleado: this.empleado,
      };
      axios
        .post(
          `${this.$store.getters.getHost}/Incidencias/ws-incidencias-vacaciones.php `,
          params
        )
        .then((response) => {
          // this.loadingColabs = false;
          if (response.data.status == "EXPSESSION") {
            deleteToken();
          } else {
            var data = response.data.reporte;
            this.vacaciones = data;
            this.getVacOtorgadas();
          }
        });
    },
    getVacOtorgadas() {
      let params = {
        Opcion: 7,
        token: localStorage.getItem("token"),
        empleado: this.empleado,
      };
      axios
        .post(
          `${this.$store.getters.getHost}/Incidencias/ws-incidencias-vacaciones.php `,
          params
        )
        .then((response) => {
          // this.loadingColabs = false;
          if (response.data.status == "EXPSESSION") {
            deleteToken();
          } else {
            var data = response.data.reporte;
            this.vacPorAnio = data;
            this.loadContenido = false;
          }
        });
    },
  },
};
</script>

<style>
.container-circle {
  width: 200px;
  height: 60px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.circle {
  width: 20px;
  height: 20px;
  position: absolute;
  border-radius: 50%;
  background-color: rgb(0, 120, 180);
  left: 15%;
  transform-origin: 50%;
  animation: circle 0.5s alternate infinite ease;
}

@keyframes circle {
  0% {
    top: 60px;
    height: 5px;
    border-radius: 50px 50px 25px 25px;
    transform: scaleX(1.7);
  }
  40% {
    top: 20px;
    border-radius: 50%;
    transform: scaleX(1);
  }
  100% {
    top: 0%;
  }
}

.circle:nth-child(2) {
  left: 50%;
  animation-delay: 0.2s;
}

.circle:nth-child(3) {
  left: auto;
  right: 15%;
  animation-delay: 0.3s;
}

.shadow {
  width: 20px;
  height: 4px;
  border-radius: 50%;
  background-color: grey;
  position: absolute;
  top: 62%;
  transform-origin: 50%;
  z-index: -1;
  left: 15%;
  filter: blur(1px);
  animation: shadow 0.5s alternate infinite ease;
}

@keyframes shadow {
  0% {
    transform: scaleX(1.5);
  }
  40% {
    transform: scaleX(1);
    opacity: 0.7;
  }
  100% {
    transform: scaleX(0.2);
    opacity: 0.4;
  }
}

.shadow:nth-child(4) {
  left: 45%;
  animation-delay: 0.2s;
}

.shadow:nth-child(5) {
  left: auto;
  right: 15%;
  animation-delay: 0.3s;
}
</style>
