<template>
  <div>
    <v-dialog v-model="dialogCargaIncidencia" persistent max-width="600" content-class="fix-border-dialog">
      <v-card class="rounded-xl">
        <v-toolbar color="primary" dark>
          Selecciona un tipo de incidencia
          <v-spacer></v-spacer>
          <v-btn small class="mr-1" icon @click="$emit('cierra', null)">
            <v-icon dark>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-container>
          <v-row no-gutters>
            <template v-for="(typeInc, indice) in tiposIncidenciasArray" >
              <v-col :key="indice"  style="text-align: center">
                <div>
                  <div>
                    <v-btn
                      class="rounded-lg"
                      x-large
                      color="primary"
                      dark
                      elevation="2"
                      @click="
                        opcionIncidenciaMostrar = indice;
                        dialog = true;
                      "
                    >
                      <v-icon x-large dark>
                        {{ typeInc.icono }}
                      </v-icon>
                    </v-btn>
                  </div>
                  <div>
                    <p class="caption text-uppercase mb-0 primary--text">
                      {{ typeInc.IncDescripcion }}
                    </p>
                  </div>
                </div>
              </v-col>
              <v-responsive
                v-if="indice === 4"
                :key="`width-${indice}`"
                width="100%"
              ></v-responsive>
            </template>
          </v-row>
        </v-container>
        <!-- <v-card-actions class="justify-end">
          <v-btn elevation="2" color="red" dark @click="$emit('cierra')"
            >Cerrar</v-btn
          >
        </v-card-actions> -->
      </v-card>
    </v-dialog>
    <!-- funciones Incidencias: opcion Incidencia a Mostrar -->
    <v-sheet id="salidaAnticipada">
      <component
        :is="funcionesIncidencias[opcionIncidenciaMostrar]"
        :dialog="dialog"
        @cierra="cierraVentanaInc"
      >
      </component>
    </v-sheet>

    <v-sheet id="snack-bar-error-incidencias">
      <snack-bar-error-incidencias
        :muestraBarra="muestraBarra"
        :texto="textoBarra"
        :color="colorBarra"
        @cierraBarra="muestraBarra = false"
      />
    </v-sheet>
  </div>
</template>




<script>
import axios from "axios";
import IncidenciaAltaSalidaAnticipada from "./IncidenciaAltaSalidaAnticipada.vue";
import IncidenciaFlexTime from "./IncidenciaFlexTime.vue";
import IncidenciaTiempoPorTiempo from "./IncidenciaTiempoPorTiempo.vue";
import IncidenciaHomeOffice from "./IncidenciaHomeOffice.vue";
import IncidenciaAltaLlegadaTarde from "./IncidenciaAltaLllegadaTarde.vue";
import IncidenciaTeletrabajo from "./IncidenciaTeletrabajo.vue";
import IncidenciaPermisoEspecial from "./IncidenciaPermisoEspecial.vue";
import IncidenciaVacaciones from "./IncidenciaVacaciones.vue";
import IncidenciaViernesCorto from "./IncidenciaViernesCorto.vue";
import SnackBarErrorIncidencias from "./componentsExtra/SnackBarErrorIncidencias.vue";
// import { defineComponent } from '@vue/composition-api';

export default {
  name: "incidencia-derecho-usuario",
  data: () => ({
    colorBarra: "red accent-2",
    textoBarra: "",
    muestraBarra: false,
    tiposIncidenciasArray: [],
    dialog: false,
    funcionesIncidencias: {
      0: "incidencia-vacaciones",
      1: "incidencia-alta-salida-anticipada",
      2: "incidencia-tiempo-por-tiempo",
      3: "incidencia-permiso-especial",
      4: "incidencia-alta-llegada-tarde",
      5: "incidencia-flex-time",
      6: "incidencia-home-office",
      7: "incidencia-viernes-corto",
      8: "incidencia-teletrabajo",
    },
    opcionIncidenciaMostrar: "",
  }),
  components: {
    IncidenciaAltaSalidaAnticipada,
    IncidenciaFlexTime,
    IncidenciaTiempoPorTiempo,
    IncidenciaHomeOffice,
    IncidenciaAltaLlegadaTarde,
    IncidenciaTeletrabajo,
    IncidenciaPermisoEspecial,
    IncidenciaVacaciones,
    IncidenciaViernesCorto,
    SnackBarErrorIncidencias,
  },
  mounted() {
    this.tiposIncidencias();
  },
  props: ["dialogCargaIncidencia"],
  methods: {
    tiposIncidencias() {
      let params = {
        Opcion : 5,
        token : localStorage.getItem('token')
      }
      axios
        .post(`${this.$store.getters.getHost}/Incidencias/ws-incidencias-calendario.php`,params)
        .then((response) => {
          var data = response.data.tiposIncidencia;
          this.tiposIncidenciasArray = data;
        })
        .catch(function (e) {
          
          let result = e.toString().includes("401");
          if (result) {
            window.location.reload();
          } else {
            alert(e);
          }
        });
    },
    cierraVentanaInc(metodo){
      this.dialog = false;
      if(metodo == 1){
        this.$emit('cierra', 1)
      } else {
        this.$emit('cierra', 0)
      }
    }
  },
};
</script>
