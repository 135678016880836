import axios from "axios";
import { deleteToken } from "../../../../../store/Funciones/funciones";
import SnackBarErrorIncidencias from "../../../General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue"
import moment from "moment/moment";
import VueJsonToCsv from "vue-json-to-csv";
import checadasDeComedorPorDiaEmpleado from "./components/checadasDeComedorPorDiaEmpleado.vue"

export default {
    name: 'reporte-checadas-comedor',
    components: {
        SnackBarErrorIncidencias,
        VueJsonToCsv,
        checadasDeComedorPorDiaEmpleado
    },
    props: [],
    data() {
        return {
            pickerdatechecadas: false,
            dreporteDetalle: false,
            datechecadascomedor: [],
            reportetres: [],
            reportedos: [],
            reporteuno: [],
            checadasPorDiaEmpleado: [],
            checadasReporteCompleto: [],
            loading: false,
            search: '',
            matriz: [],
            turnoreal: 0,
            resultadohead: "",
            loadingExcel: true,
            loadingExcelgen: true,
            loadingdates: false,
            loadingInfo: false,
            diareport: 0,
            emplidSelected: null,

            loadingButtonTable: false,
            openModalChecadas: false,
            loadingBtnReporteCompleto: true,

            headerReporteTres: [

                { text: 'Empleado', value: 'EMP_KEYEMP', align: 'center', class: 'primary white--text overline font-weight-bold' },
                { text: 'Nombre', value: 'EMP_NOMEMP', align: 'center', class: 'primary white--text overline font-weight-bold' },
                { text: 'Turno', value: 'DESCRIPCION', align: 'center', class: 'primary white--text overline font-weight-bold' },
                { text: 'Fecha', value: 'FECHAEVENTO', align: 'center', class: 'primary white--text overline font-weight-bold' },
                { text: 'Area', value: 'AREA', align: 'center', class: 'primary white--text overline font-weight-bold' },
                { text: 'Proceso', value: 'Proceso', align: 'center', class: 'primary white--text overline font-weight-bold' },

            ],

            nameFile: "1",

            headers_report_to_export: {
                EMP_KEYEMP: { title: "EMPLEADO" },
                EMP_NOMEMP: { title: "NOMBRE" },
                DESCRIPCION: { title: "TURNO" },
                FECHAEVENTO: { title: "FECHA" },
                AREA: { title: "AREA" },
                Proceso: { title: "PROCESO" },
                TiempoExtra: { title: "TIEMPO EXTRA" }
            },

            headers_reporte_completo: {
                idAutoEvents: { title: "FOLIO" },
                FechaEvento: { title: "FECHA" },
                Estatus: { title: "ESTATUS" },
                NameAxtrax: { title: "NOMBRE" },
                Folio: { title: "ID EMPLEADO" },
                ID_EMPLEADO: { title: "TIENE T. E." },
                HORAS: { title: "HORAS" }
            },

            //reporte tiempo extra por día
            dExportTE: false,
            pickertedia: false,
            datediaselec: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            reportedia: [],

            headers_report_to_export_dia: {
                num: { title: "Numero" },
                ID_EMPLEADO: { title: "N° EMPLEADO" },
                Nombre: { title: "NOMBRE" },
                HORAS: { title: "HORAS" },
            },
            nameFile2: "1",


            // ----------------------- SNACKBAR------------------------
            textoBarra: '',
            colorBarra: '',
            muestraBarra: false,
            timeout: 3000,

        }
    },
    computed: {

    },
    async mounted() {

    },
    methods: {
        mostrarAlerta(index, dia, fila) {
            this.turnoreal = 0;
            this.reportetres = [];
            // { { matriz[1][6] } }
            // { { matriz[1][0] } }
            // { { matriz[0][6] } }
            //alert(`Posición presionada: Fila ${index}, dia ${dia} , resultado ${fila}`);
            //obtener el turno de la fila 
            var turno;
            var diar = dia;
            this.diareport = diar;
            turno = this.matriz[index][0];
            console.log(turno);

            if (turno === "Turno Mixto L-V 08:30 a 17:30") {
                this.turnoreal = 422;

            }
            if (turno === "1er.Turno L - S 07:00 A 15:00") {
                this.turnoreal = 466;

            }
            if (turno === "2do.Turno L - S 15:00 A 22:30") {
                this.turnoreal = 467;

            }
            if (turno === "3er. Turno L - V 22:30 A 07:00 ") {
                this.turnoreal = 468;

            }
            if (turno === "Otros(Default)") {
                this.turnoreal = 488;

            }
            if (turno === "GRUPO 1 07:00 A 19:00 L-J") {
                this.turnoreal = 593;

            }
            if (turno === "GRUPO 2  19:00 A 07:00 L-J") {
                this.turnoreal = 594;

            }
            if (turno === "GRUPO 4 19:00 A 07:00 J-D") {
                this.turnoreal = 596;

            }
            if (turno === "Admivo L-V 07:00 a 16:00") {
                this.turnoreal = 599;

            }
            if (turno === "Administrativos Americas L-V 08:00 a 17:30") {
                this.turnoreal = 604;

            }
            if (turno === "CD TURNO MIXTO D-J 08:30- 17:30") {
                this.turnoreal = 605;

            }
            if (turno === "MULTIHABILIDAD 24HRS") {
                this.turnoreal = 607;

            }
            if (turno === "MIXTO 2DO TURNO L-V 14:00 A 22:30") {
                this.turnoreal = 608;

            }
            if (turno === "TOTALES") {
                this.turnoreal = 1000;

            }
            if (fila == 0) {
                this.textoBarra = 'No hay Registros el dia ' + diar;
                this.colorBarra = 'error';
                this.muestraBarra = true;
            } else {
                this.getDetalleTres(diar, this.turnoreal);
            }


        },

        async getReportes() {
            this.loadingInfo = true;
            this.loadingdates = true;
            this.matriz = [];
            this.reportedos = await this.getTurnosDos();
            this.reporteuno = await this.getRegistroUno();
            this.generaMatrizprueba();
            this.checadasReporteCompleto = await this.getReporteCompleto();

        },

        generaMatriz() {

            let dias = [];
            dias.push("Turnos");
            dias = [...dias, ...Object.keys(this.reporteuno)];

            this.matriz.push(dias);

            for (let x = 0; x < this.reportedos.length; x++) {
                let fila = [];
                fila.push(this.reportedos[x].DESCRIPCION);
                for (let y = 1; y < dias.length; y++) {
                    let valor = this.reporteuno[dias[y]][this.reportedos[x].ID.toString()];
                    valor = ![undefined, null].includes(valor) ? valor : 0;
                    fila.push(valor);
                }
                this.matriz.push(fila);
            }
        },

        generaMatrizprueba() {
            let dias = [];
            dias.push("Turnos");
            dias = [...dias, ...Object.keys(this.reporteuno)];
            dias.push("Total"); // Agregar encabezado de la nueva columna
            this.matriz.push(dias);
            for (let x = 0; x < this.reportedos.length; x++) {
                let fila = [];
                fila.push(this.reportedos[x].DESCRIPCION);
                let totalTurno = 0; // Inicializar variable para el total por turno
                for (let y = 1; y < dias.length - 1; y++) {
                    // console.log(this.reportedos[x].ID.toString());
                    let valor = this.reporteuno[dias[y]][this.reportedos[x].ID.toString()];
                    valor = ![undefined, null].includes(valor) ? valor : 0;
                    fila.push(valor);
                    totalTurno += valor; // Calcular el total por turno
                }
                fila.push(totalTurno); // Agregar el total por turno a la fila
                this.matriz.push(fila);
            }
            // console.log(this.matriz);
        },

        //Obtiene los dias registrados por turno.
        getRegistroUno() {
            const diaPosicionCero = this.datechecadascomedor[0].split("-")[2];
            const diaPosicionUno = this.datechecadascomedor[1].split("-")[2];
            const mesNumero = this.datechecadascomedor[0].split("-")[1];
            const año = this.datechecadascomedor[0].split("-")[0];

            require('moment/locale/es');
            const nombreMes = moment().month(mesNumero - 1).format('MMMM');
            this.resultadohead = `Registros de checadas en el comedor del día ${diaPosicionCero} al ${diaPosicionUno} de ${nombreMes} del ${año}`;

            let params = {
                Opcion: 1,
                mes: mesNumero,
                anio: año,
                opc: 1,
                diaINICIO: diaPosicionCero,
                diaFIN: diaPosicionUno,
                turno: 0,
                token: localStorage.getItem("token"),
            };
            return new Promise((resolve, reject) => {
                axios.post(`${this.$store.getters.getHost}/general/ws-ReporteChecadasComedor.php`, params).then((response) => {
                        if (response.data.status.trim() == "EXPSESSION") {
                            deleteToken();
                        } else {
                            var data = response.data.Reporte;
                            resolve(data);
                            this.loadingExcelgen = false;
                            this.loadingdates = false;
                            this.loadingInfo = false;
                        }
                    })
                    .catch(function(e) {
                        let result = e.toString().includes("401");
                        if (result) {
                            window.location.reload();
                        } else {
                            reject(e);
                        }
                    });
            });
        },

        //Obtiene Los turnos registrados.
        getTurnosDos() {
            const diaPosicionCero = this.datechecadascomedor[0].split("-")[2];
            const diaPosicionUno = this.datechecadascomedor[1].split("-")[2];
            const año = this.datechecadascomedor[1].split("-")[0];
            const mesNumero = this.datechecadascomedor[1].split("-")[1];

            let params = {
                Opcion: 2,
                mes: mesNumero,
                anio: año,
                opc: 2,
                diaINICIO: diaPosicionCero,
                diaFIN: diaPosicionUno,
                turno: 0,
                token: localStorage.getItem("token"),
            };
            return new Promise((resolve, reject) => {
                axios.post(`${this.$store.getters.getHost}/general/ws-ReporteChecadasComedor.php`, params).then((response) => {
                        if (response.data.status.trim() == "EXPSESSION") {
                            deleteToken();
                        } else {
                            var data = response.data.Reporte;
                            console.log(response.data);
                            resolve(data);
                        }
                    })
                    .catch(function(e) {
                        let result = e.toString().includes("401");
                        if (result) {
                            window.location.reload();
                        } else {
                            reject(e);
                        }
                    });
            });

        },

        //Obtiene el detalle de las checadas del día seleccionado.
        getDetalleTres(dia, turno) {
            this.textoBarra = 'Buscando';
            this.colorBarra = 'success';
            this.muestraBarra = true;

            this.dreporteDetalle = true;
            var diaPosicionCero = parseInt(this.datechecadascomedor[0].split("-")[2]);
            //const diaPosicionUno = this.datechecadascomedor[1].split("-")[2];
            var diareal = 0;
            if (dia == 1) {
                diareal = diaPosicionCero;
            } else {
                diareal = (diaPosicionCero + dia) - 1;
            }


            const año = this.datechecadascomedor[1].split("-")[0];
            const mesNumero = this.datechecadascomedor[1].split("-")[1];

            this.loading = true;
            let params = {
                Opcion: 3,
                mes: mesNumero,
                anio: año,
                opc: 3,
                diaINICIO: diareal,
                diaFIN: diareal,
                turno: turno,
                token: localStorage.getItem("token"),
            };
            axios.post(`${this.$store.getters.getHost}/general/ws-ReporteChecadasComedor.php`, params).then((response) => {
                    if (response.data.status.trim() == "EXPSESSION") {
                        deleteToken();
                    } else {
                        var data = response.data.Reporte;
                        this.reportetres = data;
                        this.loading = false;
                        this.loadingExcel = false;
                    }
                })
                .catch(function(e) {
                    let result = e.toString().includes("401");
                    if (result) {
                        window.location.reload();
                    } else {
                        alert(e);
                    }
                });
        },

        fixName() {
            const mesNumero = this.datechecadascomedor[0].split("-")[1];
            const año = this.datechecadascomedor[0].split("-")[0];

            this.nameFile = "ChecadasComedor" + año + mesNumero + this.diareport;
        },
        fixNameGeneral() {
            const mesNumero = this.datechecadascomedor[0].split("-")[1];
            const año = this.datechecadascomedor[0].split("-")[0];

            this.nameFile = "ChecadasComedorGeneral" + año + mesNumero + this.diareport;
        },

        getColorTE(TE) {
            if (TE == "Tiempo Extra") {
                return '#0091EA' //RED LIGHTEN -1
            }
        },

        getDiaS() {
            let params = {
                Opcion: 4,
                dia: this.datediaselec,
                token: localStorage.getItem("token"),
            };
            axios.post(`${this.$store.getters.getHost}/general/ws-ReporteChecadasComedor.php`, params).then((response) => {
                    if (response.data.status.trim() == "EXPSESSION") {
                        deleteToken();
                    } else {
                        var data = response.data.Reporte;
                        this.reportedia = data;
                    }
                })
                .catch(function(e) {
                    let result = e.toString().includes("401");
                    if (result) {
                        window.location.reload();
                    } else {
                        alert(e);
                    }
                });
        },
        fixNameRdia() {
            this.nameFile2 = "Reporte Tiempo Extra" + this.datediaselec;
        },

        async showTable(fecha) {
            this.checadasPorDiaEmpleado = await this.getChecadasPorDiaEmpleado(fecha);
            this.openModalChecadas = true
        },

        getChecadasPorDiaEmpleado(fecha) {
            let params = {
                Opcion: 5,
                token: localStorage.getItem("token"),
                fecha: fecha,
                empleado: this.emplidSelected

            };

            return new Promise((res, rej) => {
                axios.post(`${this.$store.getters.getHost}/general/ws-ReporteChecadasComedor.php`, params)
                    .then((response) => {
                        if (response.data.status.trim() == "EXPSESSION") {
                            deleteToken();
                        } else {
                            res(response.data.response)
                        }
                    })
                    .catch(function(e) {
                        rej(e);
                    })
                    .finally(() => {
                        this.loadingButtonTable = false;
                        this.emplidSelected = null;
                    });
            })

        },

        getReporteCompleto() {
            let params = {
                Opcion: 6,
                token: localStorage.getItem("token"),
                fechaInicio: this.datechecadascomedor[0],
                fechaFin: this.datechecadascomedor[1]

            };

            return new Promise((res, rej) => {
                axios.post(`${this.$store.getters.getHost}/general/ws-ReporteChecadasComedor.php`, params)
                    .then((response) => {
                        if (response.data.status.trim() == "EXPSESSION") {
                            deleteToken();
                        } else {
                            res(response.data.response)
                        }
                    })
                    .catch(function(e) {
                        rej(e);
                    })
                    .finally(() => {
                        this.loadingBtnReporteCompleto = false;
                        this.emplidSelected = null;
                    });
            })

        },

    }
}