<template>
  <v-dialog v-model="verDataTable" max-width="1000px" persistent>
    <v-card>
      <v-card-title>
        <span class="text-h5">Compensación salarial</span>
        <v-spacer></v-spacer>
        <v-btn color="red darken-1" rounded dark @click="$emit('cierraTabla')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <center>
          <v-btn
            text
            to="/DHGeneral/MiCompensacion/BeneficiosUrrea"
            color="primary"
            >Mis beneficios en Urrea</v-btn
          >
          <v-row>
            <v-col cols="1"></v-col>
            <v-col cols="10">
              <p
                style="font-weight: bold; font-size: 0.9em; font-align: center"
              >
                {{ beneficiosCadena }}
              </p>
            </v-col>
            <v-col cols="1"></v-col>
          </v-row>
          <v-btn-toggle
            v-model="modalidad"
            rounded
            color="blue"
            small
            style="margin-top: 5px"
          >
            <v-btn small> DIARIO </v-btn>
            <v-btn small> MENSUAL </v-btn>
            <v-btn small> ANUAL </v-btn>
          </v-btn-toggle>
        </center>
        <v-container>
          <div
            v-if="modalidad == 0"
            style="display: flex; justify-content: center"
          >
            <p
              v-if="totales_diario >= 0"
              style="font-weight: bolder; color: green; font-size: 1.2em"
            >
              Diferencia: {{ totales_diario_text }}
            </p>
            <p
              v-else
              style="font-weight: bolder; color: darkred; font-size: 1.2em"
            >
              Diferencia: {{ totales_diario_text }}
            </p>
          </div>
          <v-data-table
            v-if="modalidad == 0"
            :headers="headers"
            :items="itemsPeriodoReal"
            class="elevation-1"
            :loading="cargaInfo"
            :hide-default-footer="true"
            :disable-filtering="true"
            :disable-sort="true"
            no-data-text="No hay registros"
          >
            <template v-slot:[`item.estatus`]="{ item }">
              <div v-if="getColor(item.diferencia) == 'red'">
                <v-img
                  :src="require('@/assets/icons/desconcertado.png')"
                  height="29px"
                  width="29px"
                />
              </div>
              <div v-else-if="getColor(item.diferencia) == 'green'">
                <v-img
                  :src="require('@/assets/icons/frio.png')"
                  height="29px"
                  width="29px"
                />
              </div>
              <div v-else>
                <v-img
                  :src="require('@/assets/icons/feliz.png')"
                  height="29px"
                  width="29px"
                />
              </div>
            </template>
          </v-data-table>
          <div v-else-if="modalidad == 1">
            <div style="display: flex; justify-content: center">
              <p
                v-if="totales_mes >= 0"
                style="font-weight: bolder; color: green; font-size: 1.2em"
              >
                Diferencia: {{ totales_mes_text }}
              </p>
              <p
                v-else
                style="font-weight: bolder; color: darkred; font-size: 1.2em"
              >
                Diferencia: {{ totales_mes_text }}
              </p>
            </div>
            <v-data-table
              :headers="headers"
              :items="itemsPeriodoMes"
              class="elevation-1"
              :loading="cargaInfo"
              :hide-default-footer="true"
              :disable-filtering="true"
              :disable-sort="true"
              no-data-text="No hay registros"
            >
              <template v-slot:[`item.estatus`]="{ item }">
                <div v-if="getColor(item.diferencia) == 'red'">
                  <v-img
                    :src="require('@/assets/icons/desconcertado.png')"
                    height="29px"
                    width="29px"
                  />
                </div>
                <div v-else-if="getColor(item.diferencia) == 'green'">
                  <v-img
                    :src="require('@/assets/icons/frio.png')"
                    height="29px"
                    width="29px"
                  />
                </div>
                <div v-else>
                  <v-img
                    :src="require('@/assets/icons/feliz.png')"
                    height="29px"
                    width="29px"
                  />
                </div>
              </template>
            </v-data-table>
          </div>

          <div v-else-if="modalidad == 2">
            <div style="display: flex; justify-content: center">
              <p
                v-if="totales_anual >= 0"
                style="font-weight: bolder; color: green; font-size: 1.2em"
              >
                Diferencia: {{ totales_anual_text }}
              </p>
              <p
                v-else
                style="font-weight: bolder; color: darkred; font-size: 1.2em"
              >
                Diferencia: {{ totales_anual_text }}
              </p>
            </div>
            <v-data-table
              :headers="headers"
              :items="itemsPeriodoAnual"
              class="elevation-1"
              :loading="cargaInfo"
              :hide-default-footer="true"
              :disable-filtering="true"
              :disable-sort="true"
              no-data-text="No hay registros"
            >
              <template v-slot:[`item.estatus`]="{ item }">
                <div v-if="getColor(item.diferencia) == 'red'">
                  <v-img
                    :src="require('@/assets/icons/desconcertado.png')"
                    height="29px"
                    width="29px"
                  />
                </div>
                <div v-else-if="getColor(item.diferencia) == 'green'">
                  <v-img
                    :src="require('@/assets/icons/frio.png')"
                    height="29px"
                    width="29px"
                  />
                </div>
                <div v-else>
                  <v-img
                    :src="require('@/assets/icons/feliz.png')"
                    height="29px"
                    width="29px"
                  />
                </div>
              </template>
            </v-data-table>
          </div>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
import { deleteToken } from "../../../../../../store/Funciones/funciones";
export default {
  name: "simulador-muestra-comparacion",
  data: () => ({
    cargaInfo: false,
    modalidad: 0,
    empresaGanadora: "",
    beneficiosCadena: "",
    monto_total: 0,
    monto_total_mes: 0,
    monto_total_anio: 0,
    totales_diario: 0,
    totales_mes: 0,
    totales_anual: 0,
    totales_diario_text: "",
    totales_mes_text: "",
    totales_anual_text: "",
    urreaGana: true,
    headers: [
      {
        text: "Prestacion",
        align: "start",
        sortable: false,
        value: "nombre",
      },
      {
        text: "Monto en Urrea",
        sortable: false,
        value: "valor_urrea",
      },
      {
        text: "Monto en competencia",
        sortable: false,
        value: "valor_competencia",
      },
      {
        text: "Diferencia",
        sortable: false,
        value: "diferencia",
      },
      {
        text: "Estatus",
        value: "estatus",
      },
    ],
    itemsPeriodo: [],
    itemsPeriodoMes: [],
    itemsPeriodoAnual: [],
    itemsPeriodoReal: [],
  }),
  components: {},
  created() {
    this.itemsPeriodo = this.items;
  },
  mounted() {
    this.cambiaTipoCalculo();
    this.getBeneficiosCadena();
    // this.itemsPeriodo = this.items;
    this.getTotales(this.items);
  },
  props: ["verDataTable", "items"],
  methods: {
    getColor(calories) {
      let nuevo = "";
      if (typeof calories == "string") {
        nuevo = calories.replace(/[$,]/g, "");
      }
      if (Number(nuevo) < 0) return "red";
      else if (Number(nuevo) > 0) return "green";
      else return "blue";
    },
    cambiaTipoCalculo() {
      this.itemsPeriodo.map((item) => {
        if (item["nombre"] == "Vacaciones") {
          this.itemsPeriodoReal.push({
            nombre: item["nombre"],
            valor_urrea: item["valor_urrea"],
            valor_competencia: item["valor_competencia"],
            diferencia: item["diferencia"],
          });
        } else {
          this.itemsPeriodoReal.push({
            nombre: item["nombre"],
            valor_urrea: new Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(Number.parseFloat(item["valor_urrea"])),
            valor_competencia: new Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(Number.parseFloat(item["valor_competencia"])),
            diferencia: new Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(Number.parseFloat(item["diferencia"])),
          });
        }
      });
      this.itemsPeriodo.map((item) => {
        if (item["nombre"] == "Vacaciones") {
          this.itemsPeriodoMes.push({
            nombre: item["nombre"],
            valor_urrea: item["valor_urrea"],
            valor_competencia: item["valor_competencia"],
            diferencia: item["diferencia"],
          });
        } else {
          this.itemsPeriodoMes.push({
            nombre: item["nombre"],
            valor_urrea: new Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(Number.parseFloat((item["valor_urrea"] * 365) / 12)),
            valor_competencia: new Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(
              Number.parseFloat((item["valor_competencia"] * 365) / 12)
            ),
            diferencia: new Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(Number.parseFloat((item["diferencia"] * 365) / 12)),
          });
        }
      });

      this.itemsPeriodo.map((item) => {
        if (item["nombre"] == "Vacaciones") {
          this.itemsPeriodoAnual.push({
            nombre: item["nombre"],
            valor_urrea: item["valor_urrea"],
            valor_competencia: item["valor_competencia"],
            diferencia: item["diferencia"],
          });
        } else {
          this.itemsPeriodoAnual.push({
            nombre: item["nombre"],
            valor_urrea: new Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(Number.parseFloat(item["valor_urrea"] * 365)),
            valor_competencia: new Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(Number.parseFloat(item["valor_competencia"] * 365)),
            diferencia: new Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(Number.parseFloat(item["diferencia"] * 365)),
          });
        }
      });
    },
    getBeneficiosCadena() {
      let params = {
        Opcion: 3,
        token: localStorage.getItem("token"),
      };
      axios
        .post(`${this.$store.getters.getHost}/compensaciones/ws-beneficios.php`, params)
        .then((response) => {
          if (response.data.status == "EXPSESSION") {
            deleteToken();
          } else {
            var data = response.data.beneficios;
            this.beneficiosCadena = data;
          }
        });
    },
    goToBenefits() {
      this.$router.push();
    },
    getTotales(totales) {
      if (totales != undefined) {
        totales.map((item) => {
          this.totales_diario += item["diferencia"];
        });
        this.totales_mes = (this.totales_diario * 365) / 12;
        this.totales_anual = this.totales_diario * 365;
        // console.log(this.totales_diario)
        // console.log(this.totales_mes)
        // console.log(this.totales_anual)
        this.totales_diario_text = new Intl.NumberFormat("es-MX", {
          style: "currency",
          currency: "MXN",
        }).format(this.totales_diario);
        this.totales_mes_text = new Intl.NumberFormat("es-MX", {
          style: "currency",
          currency: "MXN",
        }).format(this.totales_mes);
        this.totales_anual_text = new Intl.NumberFormat("es-MX", {
          style: "currency",
          currency: "MXN",
        }).format(this.totales_anual);
      } else {
        alert("error");
      }
    },
  },
};
</script>