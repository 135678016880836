import axios from "axios";
import {
    deleteToken
} from "../../../../../store/Funciones/funciones";
import SnackBarErrorIncidencias from "../../../General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue"
import moment from "moment/moment";
import MuestraInfoSolicitudTE from "../ReporteCostoDeTiempoExtra/components/MuestraInfoSolicitudTE.vue";

export default {
    name: 'solicitud-tiempo-extra',
    components: {
        SnackBarErrorIncidencias,
        MuestraInfoSolicitudTE
    },
    props: [],
    data() {
        return {
            oculto: false, // se oculta seccion donde se selecciona lo de turno 2 o turno 3 en el segundo paso de registro 
            //---------------ARREGLOS------------

            colaboradores: [],
            diaselec: [], //arreglo de fechas del paso 1
            motivos: [],
            repitefecha: 0,
            cargacolab: [],
            seleccion: [],
            infosolis: [],
            loadingsolis: false,
            registroarmadodecolaborador: [], //arreglo que se obtiene en el paso 2 para registrar las horas
            registroarmadodegrupo: [], //arreglo que se obtiene al cargarle fecha y hora al grupo.
            datesolis: [],
            date: [(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)],
            empleadoregistrar: [],
            fechagrupospick: "",
            colaborssicumplen: [],
            colaborsnocumplen: [],
            fechasrevisarindividual: [],
            fechasrevisargrupal: [],
            expanded: [],

            // ------------FIGURAS------------------
            figurasSeleccionadas: null,
            loadingFiguras: false,
            alertFigurasAgregadas: false,
            idsFifuras: [],
            figurasEncontradas: [],
            searchFiguras: "",

            //-------------VARIABLES----------------

            acceso: [],
            semanaactual: "",
            search: '',
            searchgrupo: '',
            searchsolis: '',
            motivoseleccionado: "",
            justificacion: "",
            horaselec: "",
            emplid: "",
            e1: 1,
            //noselecciononada: false, //dialog si no selecciono ninguna fecha
            dregistro: false, //abre el dialog principal para registrar las horas
            gruporegistro: false, //dialog para el registro de grupo completo
            grupomotivos: false, //dialog para guardar motivos y el registro del grupo
            dreglas: false,
            dfechasindividual: false,
            dfechasgrupal: false,
            dvalidafechasindividual: false,
            dvalidafechasgrupal: false,
            dreglas1: false,
            nombreempleado: "",
            turno: "",
            turnogrupo: 0,
            menu: false,
            menugrupo: false,
            nextDomingo: "",
            anteriorlunes: "",
            landscape: true,
            areaemplid: "",
            opcregistro: 0,
            igualfecha: 0,
            errorfecha: 0,
            loading: false,
            tabs: null,
            pickersolicitudes: false,
            cargando: true,
            valuesrespmotivo: null,
            responsablemotivo: [],
            switchIndvT2: false,
            switchIndvT3: false,
            switchGrupoT2: false,
            switchGrupoT3: false,

            //---------------- Pantalla de solicitudes info --------------
            dinfosolicitud: false,

            AREA: 0,
            EMPLID: 0,
            ESTADO: "",
            ESTATUSGEMPTE: 0,
            ESTATUSSTE: "",
            FECHA: "",
            FECHA_REGISTRO: "",
            HORA_REGISTRO: "",
            GRUPO: 0,
            HORAS: "",
            HORAS_REALES: "",
            INGRESO: "",
            JUSTIFICACION: "",
            MOTIVO_RECHAZO: "",
            NOMBRE: "",
            SALIDA: "",
            SEMANA: 0,
            SOLICITUD: "",
            TIPO_EMPLEADO: "",
            TIPO_JUSTIFICACION: 0,
            FIG_MAX: "",
            FIG_MIN: "",
            MONTO: "",
            AREA_MANT: "",
            COMENTARIO: "",

            //--------------motivos-------------------
            motivo1: "",
            motivo2: "",
            motivo3: "",
            motivo4: "",
            motivo5: "",
            monto: 0.0,
            maximo: 0,
            minimo: 0,
            areamotivo: 0,
            areaseleccionada: "",
            areas: ['GUDH', 'Desarrollo Humano', 'Administracion y Finanzas', 'Logistica', 'Operaciones',
                'Ventas Nacionales', 'Mercadotecnia', 'Ventas Internacionales', 'Innovacion y competitividad'
            ],
            comentario: "",

            // ----------------------- SNACKBAR------------------------
            textoBarra: '',
            colorBarra: '',
            muestraBarra: false,
            timeout: 3000,


            //-----------------ENCABEZADOS DE DATA-TABLES----------------

            actionfecha: [{
                    text: 'Numero de empleado',
                    value: 'emplid',
                    align: 'center',
                    class: 'primary white--text overline font-weight-bold'
                },

                {
                    text: 'Fechas',
                    value: 'fecha',
                    align: 'center',
                    class: 'primary white--text overline font-weight-bold'
                },

                {
                    text: 'Horas',
                    value: 'horas',
                    align: 'center',
                    class: 'primary white--text overline font-weight-bold'
                },
            ],

            headeremplcargo: [{
                    text: '',
                    value: 'data-table-select',
                    class: 'primary white--text overline font-weight-bold rounded-tl-lg'
                },
                {
                    text: 'Num',
                    value: 'codigo',
                    align: 'center',
                    class: 'primary white--text overline font-weight-bold'
                },

                {
                    text: 'Nombre',
                    value: 'nombre',
                    align: 'center',
                    class: 'primary white--text overline font-weight-bold'
                },
                {
                    text: 'Hrs',
                    value: 'horasPrevias',
                    align: 'center',
                    class: 'primary white--text overline font-weight-bold rounded-tr-lg'
                },


            ],
            headercolaboradoresnocumplen: [{
                    text: 'Num.Empleado',
                    value: 'codigo',
                    align: 'center',
                    class: 'primary white--text overline font-weight-bold'
                },

                {
                    text: 'Nombre',
                    value: 'nombre',
                    align: 'center',
                    class: 'primary white--text overline font-weight-bold'
                },

                {
                    text: 'Estado',
                    value: 'estado',
                    align: 'center',
                    class: 'primary white--text overline font-weight-bold'
                },

                {
                    text: 'Area',
                    value: 'idarea',
                    align: 'center',
                    class: 'primary white--text overline font-weight-bold'
                },
            ],

            headeremplcargaextras: [

                {
                    text: 'Num.Empleado',
                    value: 'codigo',
                    align: 'center',
                    class: 'primary white--text overline font-weight-bold rounded-tl-lg'
                },

                {
                    text: 'Nombre',
                    value: 'nombre',
                    align: 'center',
                    class: 'primary white--text overline font-weight-bold'
                },

                {
                    text: 'Acciones',
                    value: 'actions',
                    align: 'center',
                    class: 'primary white--text overline font-weight-bold rounded-tr-lg'
                },

            ],

            headersoliscitudes: [
                { text: '', value: 'data-table-expand', class: "primary" },
                {
                    text: '#SOLICITUD',
                    value: 'ID',
                    align: 'center',
                    class: 'primary white--text overline font-weight-bold'
                },
                {
                    text: 'JUSTIFICACION',
                    value: 'JUSTIFICACION',
                    align: 'center',
                    class: 'primary white--text overline font-weight-bold'
                },
                {
                    text: 'FECHA DE REGISTRO',
                    value: 'FECHA_CREACION',
                    align: 'center',
                    class: 'primary white--text overline font-weight-bold'
                },
                {
                    text: 'HORA DE REGISTRO',
                    value: 'HORA_CREACION',
                    align: 'center',
                    class: 'primary white--text overline font-weight-bold'
                },
                {
                    text: 'ESTADO DE LA SOLICITUD',
                    value: 'ESTATUS',
                    align: 'center',
                    class: 'primary white--text overline font-weight-bold'
                },

            ],

            tablaregistrogrupo: [

                {
                    text: 'Num.Empleado',
                    value: 'codigo',
                    align: 'center',
                    class: 'primary white--text overline font-weight-bold'
                },

                {
                    text: 'Nombre',
                    value: 'nombre',
                    align: 'center',
                    class: 'primary white--text overline font-weight-bold'
                },

                {
                    text: 'Fecha',
                    value: 'fecha',
                    align: 'center',
                    class: 'primary white--text overline font-weight-bold'
                },

                {
                    text: 'Hora',
                    value: 'hora',
                    align: 'center',
                    class: 'primary white--text overline font-weight-bold'
                },

            ],

            headerfecharepetidaindividual: [{
                text: 'Fecha',
                value: 'fechas',
                align: 'center',
                class: 'primary white--text overline font-weight-bold'
            }, ],

            headerfecharepetidagrupal: [{
                text: 'Numero de empleado',
                value: 'numemplid',
                align: 'center',
                class: 'primary white--text overline font-weight-bold'
            }, ],

            // revission de faltas mostrar dias
            kardexcolaborador: [],
            dkardexfalta: false,
            kardemplid: 'a',
            ADMING: 0,


        }
    },
    computed: {
        totalHoras() {
            const initialTotal = Number(this.totalHorasPrevias) || 0;
            return this.empleadoregistrar.reduce((sum, item) => sum + Number(item.horas || 0), initialTotal);
        },

        isHorasValid() {
            return this.horaselec > 0 && this.horaselec <= 3;
        },
    },
    watch: {
        searchFiguras(val) {
            this.filterItems(val);
        },
    },
    mounted() {
        this.getPerfilAcceso();
    },
    methods: {
        getColor(ESTATUSSTE) {
            if (ESTATUSSTE == "En proceso de validación") return '#006064'
            if (ESTATUSSTE == "Rechazado") return '#EF5350' //RED LIGHTEN -1
            if (ESTATUSSTE == "Autorizado") return '#66BB6A' //GREEN LIGHTEN -1
            if (ESTATUSSTE == "Enviado a proceso de pago") return '#FFCA28' // AMBER LIGHTEN -1
            if (ESTATUSSTE == "Rechazado el proceso de pago") return '#FFA726' //OREANGE LIGHTEN -1
            if (ESTATUSSTE == "finalizado") return '#29B6F6' //LIGHT-BLUE-LIGHTEN -1
            if (ESTATUSSTE == "Terminado") return '#29B6F6' //LIGHT-BLUE-LIGHTEN -1 
        },

        getColorestadodh(ESTADODH, horasPrevias) {
            if (ESTADODH == 0 || horasPrevias >= 9) return '#EF5350' //RED LIGHTEN -1
                // if (ESTADODH == 1) return '#E8F5E' 
        },

        //Obtiene los perfiles del usuario que acceso.
        getPerfilAcceso() {
            let params = {
                Opcion: 7,
                token: localStorage.getItem("token"),
            };
            axios.post(`${this.$store.getters.getHost}/general/ws-TiempoExtra.php`, params).then((response) => {
                    if (response.data.status.trim() == "EXPSESSION") {
                        deleteToken();
                    } else {
                        var data = response.data.perfil;
                        if (data) {
                            this.acceso = data;
                            this.acceso.map((Element) => {
                                if (Element.id == 0 || Element.id == 1 || Element.id == 2) {
                                    this.ADMING = 1;
                                    this.getColaboradores();
                                }
                            });
                            if (this.ADMING == 0) {
                                this.getColaboradores();
                            }

                            // console.log(this.ADMING);
                        }
                    }
                })
                .catch(function(e) {
                    let result = e.toString().includes("401");
                    if (result) {
                        window.location.reload();
                    } else {
                        alert(e);
                    }
                });
        },

        //Obtiene los empleados a cargo del empleado responsable.
        getColaboradores() {
            this.loading = true;
            let params = {
                Opcion: 1,
                token: localStorage.getItem("token"),
            };
            axios.post(`${this.$store.getters.getHost}/general/ws-TiempoExtra.php`, params).then((response) => {
                    if (response.data.status.trim() == "EXPSESSION") {
                        deleteToken();
                    } else {
                        var data = response.data.colabors.colabors;
                        //console.table(response.data);
                        this.semanaactual = response.data.colabors.fechaactual;
                        if (data) {
                            this.colaboradores = data;
                            if (this.colaboradores.length > 0) {
                                this.getSolicitudesTE();
                                this.colaboradores.map((Element) => {
                                    if (Element.ESTATUS == 0 || Element.horasPrevias >= 9) {
                                        this.colaborsnocumplen.push({
                                            codigo: Element.codigo,
                                            nombre: Element.nombre,
                                            estado: Element.ESTADO,
                                            idarea: Element.Id_Area
                                        });
                                    } else if (Element.ESTATUS == 1) {
                                        this.colaborssicumplen.push({
                                            codigo: Element.codigo,
                                            nombre: Element.nombre,
                                            estado: Element.ESTADO,
                                            idarea: Element.Id_Area,
                                            turno: Element.IdTurno
                                        });
                                    }
                                });
                                if (!this.colaboradores) {
                                    this.loading = false;
                                }
                            }
                            this.loading = false;
                        } else {
                            this.loading = false;
                            this.textoBarra = 'No hay colaboradores que mostrar.';
                            this.colorBarra = 'error';
                            this.muestraBarra = true;
                        }
                    }
                })
                .catch(function(e) {
                    let result = e.toString().includes("401");
                    if (result) {
                        window.location.reload();
                    } else {
                        alert(e);
                    }
                });
        },

        getSolicitudes() {

            this.infosolis = [];
            this.loadingsolis = true;

            if (this.datesolis.length > 1) {

                var fecha1 = new Date(this.datesolis[0]);
                var fecha2 = new Date(this.datesolis[1]);

                if (fecha1 < fecha2) {

                    // console.log("La primera fecha es menor que la segunda fecha.");
                    // var inicial = 0;
                    // var final = 0;

                    // inicial = this.datesolis[0] += "T00:00:00.000";
                    // final = this.datesolis[1] += "T23:59:59.00";
                    const yearNumber = moment().year()

                    let params = {
                        Opcion: 5,
                        // fechainicial: inicial,
                        // fechafinal: final,
                        semana: this.semanaactual,
                        mes: null,
                        anio: yearNumber,
                        pantalla: 1,
                        token: localStorage.getItem("token"),
                    };
                    axios.post(`${this.$store.getters.getHost}/general/ws-TiempoExtra.php`, params).then((response) => {
                            var data = response.data.infosolis;
                            if (data) {

                                this.infosolis = data;
                                // console.log(this.infosolis);
                                this.loadingsolis = false;

                            } else {
                                this.loadingsolis = false;
                            }
                        })
                        .catch(function(e) {
                            let result = e.toString().includes("401");
                            if (result) {
                                window.location.reload();
                            } else {
                                alert(e);
                            }
                        });

                    this.datesolis = [];

                } else if (fecha1 > fecha2) {
                    // console.log("La primera fecha es mayor que la segunda fecha.");
                    this.textoBarra = 'La primera fecha es mayor que la segunda fecha.';
                    this.colorBarra = 'error';
                    this.muestraBarra = true;
                    this.loadingsolis = false;
                    this.datesolis = [];
                }

            } else {
                // console.log("solo selecciono un dia.");
                this.textoBarra = 'Solicitud registrada correctamente';
                this.colorBarra = 'success';
                this.muestraBarra = true;
                this.loadingsolis = false;
                this.datesolis = [];
            }
        },
        getSolicitudesTE() {

            this.infosolis = [];
            this.loadingsolis = true;
            this.mostrarsemana(this.semanaactual);
            let params = {
                Opcion: 5,
                // fechainicial: this.anteriorlunes,
                // fechafinal: this.nextDomingo,
                semana: this.semanaactual - 1,
                mes: null,
                anio: moment().year(),
                pantalla: 1,
                token: localStorage.getItem("token"),
            };
            axios.post(`${this.$store.getters.getHost}/general/ws-TiempoExtra.php`, params).then((response) => {
                    var data = response.data.infosolis;
                    if (data) {

                        this.infosolis = data;
                        // console.log(this.infosolis);
                        this.loadingsolis = false;

                    } else {
                        this.loadingsolis = false;
                    }
                })
                .catch(function(e) {
                    let result = e.toString().includes("401");
                    if (result) {
                        window.location.reload();
                    } else {
                        alert(e);
                    }
                });

        },
        //Obtiene los motivos de la solicitud de TE.
        getMotivos() {
            let params = {
                Opcion: 2,
                token: localStorage.getItem("token"),
            };
            axios.post(`${this.$store.getters.getHost}/general/ws-TiempoExtra.php`, params).then((response) => {
                    if (response.data.status.trim() == "EXPSESSION") {
                        deleteToken();
                    } else {
                        var data = response.data.motivos;
                        if (data) {
                            this.motivos = data;
                        }
                    }
                    this.cargando = false;
                })
                .catch(function(e) {
                    let result = e.toString().includes("401");
                    if (result) {
                        window.location.reload();
                    } else {
                        alert(e);
                    }
                });
        },

        //Obtiene el saldo por hora del colbrorador.
        getResponsablesMotivos() {
            let params = {
                Opcion: 10,
                token: localStorage.getItem("token"),
            };
            axios.post(`${this.$store.getters.getHost}/general/ws-TiempoExtra.php`, params).then((response) => {
                    if (response.data.status.trim() == "EXPSESSION") {
                        deleteToken();
                    } else {
                        this.responsablemotivo = response.data.respmotivos;
                        // console.log(this.responsablemotivo);

                    }
                })
                .catch(function(e) {
                    let result = e.toString().includes("401");
                    if (result) {
                        window.location.reload();
                    } else {
                        alert(e);
                    }
                });
        },

        getfechas(fechas, numemplid, opc) {
            let params = {
                Opcion: 4,
                emplid: numemplid,
                fecha: fechas,
                token: localStorage.getItem("token"),
            };
            axios.post(
                    `${this.$store.getters.getHost}/general/ws-TiempoExtra.php`,
                    params
                )
                .then((response) => {

                    this.loading = false;

                    this.repitefecha = response.data.fechas;
                    if (opc == 1) {
                        if (response.data.status == "ERROR") {
                            this.errorfecha = 1;
                            // console.log(this.errorfecha);
                        } else if (response.data.status == "OK") {
                            this.igualfecha = 1;
                            this.fechasrevisarindividual.push({
                                fechas
                            });
                            // console.log(this.igualfecha);
                        }
                    } else if (opc == 2) {
                        if (response.data.status == "ERROR") {
                            this.errorfecha = 1;
                            // console.log(this.errorfecha);
                        } else if (response.data.status == "OK") {
                            this.igualfecha = 1;
                            this.fechasrevisargrupal.push({
                                numemplid
                            });
                            // console.log(this.igualfecha);
                        }
                    }


                })
                .catch(function(e) {
                    let result = e.toString().includes("401");
                    if (result) {
                        window.location.reload();
                    } else {
                        alert(e);
                    }
                });
        },

        saveTEindividual() {
            var fecha = new Date();
            var horaactual = fecha.getHours();
            var minutos = fecha.getMinutes();
            var horareal = horaactual + ":" + minutos;

            let params = {
                    Opcion: 3,
                    hora: horareal,
                    area: this.areaemplid,
                    descjustificacion: this.justificacion,
                    tipojst: this.motivoseleccionado,
                    figmax: this.maximo,
                    figmin: this.minimo,
                    monto: this.monto,
                    areamant: this.areaseleccionada,
                    comentario: this.comentario,
                    empcarga: this.empleadoregistrar,
                    empgrupo: this.registroarmadodegrupo,
                    opcregistro: this.opcregistro,
                    respmotivo: this.valuesrespmotivo,
                    token: localStorage.getItem('token'),
                }
                // console.log(params);

            axios
                .post(
                    `${this.$store.getters.getHost}/general/ws-TiempoExtra.php`, params
                )
                .then((response) => {
                    // console.log(response.data);
                    if (response.data.status == "error") {
                        // console.log("error");
                        this.textoBarra = 'Error no se registro la solicitud.';
                        this.colorBarra = 'error';
                        this.muestraBarra = true;
                    } else if (response.data.status == "OK") {
                        // console.log("success");
                        this.textoBarra = 'Solicitud registrada correctamente';
                        this.colorBarra = 'success';
                        this.muestraBarra = true;
                        this.opcregistro = 0;
                        this.getSolicitudesTE();
                        this.getColaboradores()
                    } else if (response.data.status == "EXPRESSION") {
                        deleteToken();
                    }
                })
                .catch(function(e) {
                    alert("Ocurrió un error: " + e);
                });

        },

        saveTEgrupo() {
            this.validarmotivo();
        },

        gomotivosgrupo() {
            this.getMotivos();
            this.getResponsablesMotivos();

            // console.log(this.fechasrevisargrupal);
            if (this.errorfecha == 1 && this.igualfecha == 0) {

                this.colaborssicumplen.map((Element) => {
                    Element.fecha = this.fechagrupospick;
                    Element.horas = this.horaselec;
                    this.registroarmadodegrupo.push({
                        emplid: Element.codigo,
                        fecha: this.fechagrupospick,
                        horas: this.horaselec
                    });
                });

                // console.log(this.registroarmadodegrupo);
                this.opcregistro = 2;
                this.gruporegistro = false;
                this.grupomotivos = true;
                this.errorfecha = 0;
                this.igualfecha = 0;
                this.dvalidafechasgrupal = false;

            } else if (this.fechasrevisargrupal.length > 0) {
                this.dfechasgrupal = true;
                this.fechagrupospick = "";
                this.horaselec = "";
                this.errorfecha = 0;
                this.igualfecha = 0;
                this.dvalidafechasgrupal = false;
            }
        },

        mostrarsemana(semana) {
            const ahora = moment();
            let primerdia = moment().isoWeek(semana).startOf("isoweek");
            let ultimodia = moment().isoWeek(semana).endOf('isoweek').subtract(1, 'days');
            let LUNES = primerdia.format('YYYY-MM-DD');
            let DOMINGO = ultimodia.format('YYYY-MM-DD');

            //comprobar si es lunes.
            if (ahora.day() == 1) {
                //comprobar si es antes de las 11:10.
                const horalimite = moment('11:10 AM', 'h:mm A');
                if (ahora.isAfter(horalimite)) {
                    this.anteriorlunes = LUNES;
                    this.nextDomingo = DOMINGO;
                } else {
                    //Mostrar la semana anterior
                    let lunesAnterior = moment(this.LUNES).subtract(7, 'days').format('YYYY-MM-DD');
                    this.anteriorlunes = lunesAnterior;
                    // console.log(lunesAnterior);
                }
            } else {
                this.anteriorlunes = LUNES;
                this.nextDomingo = DOMINGO;
            }


        },

        abrirregistroteindividual(item) {
            if (item.ESTATUS == 1) {
                this.mostrarsemana(this.semanaactual);
                // console.log(item);
                this.dregistro = true;
                this.emplid = item.codigo;
                this.areaemplid = item.Id_Area;
                this.nombreempleado = item.nombre;
                this.turno = item.IdTurno;
                this.opcregistro = 1;
                // console.log(this.emplid);
            } else {
                this.textoBarra = 'No cumple con las reglas de DH...';
                this.colorBarra = 'error';
                this.muestraBarra = true;
                this.kardemplid = item.codigo;
                this.getKardex(item.codigo);

            }
        },

        abrirInformacionSolicitudes(item) {
            // console.log(item);
            // this.dregistro = true;
            this.EMPLID = item.EMPLID;
            this.AREA = item.AREA;
            this.ESTADO = item.ESTADO;
            this.ESTATUSGEMPTE = item.ESTATUSGEMPTE;
            this.ESTATUSSTE = item.ESTATUSSTE;
            this.FECHA = item.FECHA;
            this.FECHA_REGISTRO = item.FECHA_REGISTRO;
            this.HORA_REGISTRO = item.HORA_REGISTRO;
            this.GRUPO = item.GRUPO;
            this.HORAS = item.HORAS;
            this.HORAS_REALES = item.HORAS_REALES;
            this.INGRESO = item.INGRESO;
            this.JUSTIFICACION = item.JUSTIFICACION;
            this.MOTIVO_RECHAZO = item.MOTIVO_RECHAZO;
            this.NOMBRE = item.NOMBRE;
            this.SALIDA = item.SALIDA;
            this.SEMANA = item.SEMANA;
            this.SOLICITUD = item.SOLICITUD;
            this.TIPO_EMPLEADO = item.TIPO_EMPLEADO;
            this.TIPO_JUSTIFICACION = item.TIPO_JUSTIFICACION;
            this.FIG_MAX = item.FIG_MAX;
            this.FIG_MIN = item.FIG_MIN;
            this.MONTO = item.MONTO;
            this.COMENTARIO = item.COMENTARIO;
            var opc = item.AREA_MANTENIMIENTO;
            // console.log(opc);
            if (this.MOTIVO_RECHAZO == "-") {
                this.MOTIVO_RECHAZO = "SIN MOTIVO";
            }

            if (this.ESTATUSGEMPTE == 0) {
                this.ESTATUSGEMPTE = "SIN ESTADO";
            }

            if (this.HORAS_REALES == .00) {
                this.ESTADO = "SIN REGISTRO AUN";
            }

            switch (opc) {
                case 0:
                    this.AREA_MANT = "";
                    break;
                case 1:
                    this.AREA_MANT = "Desarrollo Humano";
                    break;
                case 2:
                    this.AREA_MANT = "Administracion y Finanzas";
                    break;
                case 6:
                    this.AREA_MANT = "Logistica";
                    break;
                case 7:
                    this.AREA_MANT = "Operaciones";
                    break;
                case 9:
                    this.AREA_MANT = "Ventas Nacionales";
                    break;
                case 10:
                    this.AREA_MANT = "Mercadotecnia";
                    break;
                case 12:
                    this.AREA_MANT = "Ventas Internacionales";
                    break;
                case 11:
                    this.AREA_MANT = "Innovacion y competitividad";
                    break;
            }

            this.dinfosolicitud = true;

            // console.log(this.EMPLID);
        },

        validarfechas(opc) {
            if (opc == 1) {
                this.textoBarra = 'Selecciona una fecha que no este registrada...';
                this.colorBarra = 'error';
                this.muestraBarra = true;
                this.e1 = 1;
                this.dfechasindividual = false;
                this.fechasrevisarindividual = [];

            } else if (opc == 2) {
                this.textoBarra = 'Selecciona una fecha que no este registrada...';
                this.colorBarra = 'error';
                this.muestraBarra = true;
                this.dfechasgrupal = false;
                this.fechasrevisargrupal = [];
            }

        },

        fechaanterior(opc) {
            var diaActual = moment();
            var actual = diaActual.format('YYYY-MM-DD');
            switch (opc) {
                case 1:
                    var fechasAnteriores = this.diaselec.filter(function(fecha) {
                        return new Date(fecha) < new Date();
                    });

                    if (fechasAnteriores.length > 0) {
                        this.textoBarra = 'Las siguientes fechas son anteriores al dia de hoy ' + fechasAnteriores + " El tiempo extra registrado se vera reflejado en la sguiente nomina.";
                        this.colorBarra = 'error';
                        this.muestraBarra = true;

                    } else {
                        // console.log('El arreglo no contiene fechas anteriores al día actual.');
                    }
                    break;

                case 2:
                    // console.log(actual);
                    if (this.fechagrupospick < actual) {
                        this.textoBarra = 'La fecha seleccionada es anterior al dia de hoy ' + this.fechagrupospick + " El tiempo extra registrado se vera reflejado en la sguiente nomina.";
                        this.colorBarra = 'error';
                        this.muestraBarra = true;
                    } else {
                        // console.log('El arreglo no contiene fechas anteriores al día actual.');
                    }
                    break;
            }

        },

        igualarfechas(opc) {

            // this.fechaanterior(opc);

            if (opc == 1) { //iguala fechas individual

                if (this.diaselec.length > 0) {
                    this.dvalidafechasindividual = true;
                    this.getHorasColaborador(this.emplid, this.anteriorlunes, this.nextDomingo);
                    for (let x = 0; x < this.diaselec.length; x++) {
                        this.getfechas(this.diaselec[x], this.emplid, 1);
                    }

                    // console.log(this.fechasrevisarindividual);
                } else {

                    this.textoBarra = 'Selecciona una fecha antes de continuar...';
                    this.colorBarra = 'error';
                    this.muestraBarra = true;

                }
            } else if (opc == 2) { //iguala fechas grupal.
                //recorre el arreglo buscando si todos los turnos son iguales o no.

                let turnoaux = this.colaborssicumplen[0].turno;
                let arrturno = this.colaborssicumplen.filter((Element) => {
                    return Element.turno === turnoaux;
                });

                if (arrturno.length === this.colaborssicumplen.length) {
                    // console.log("si es el mismo turno  " + turnoaux);
                    this.turnogrupo = turnoaux;

                    if (this.switchGrupoT2 == false && this.switchGrupoT3 == false) {

                        //solo puede cargar horas completas. cualquier turno

                        if (this.horaselec == 1 || this.horaselec == 2 || this.horaselec == 3 || this.horaselec == 4 || this.horaselec == 5 || this.horaselec == 6 ||
                            this.horaselec == 7 || this.horaselec == 8 || this.horaselec == 9 || this.horaselec == 10 || this.horaselec == 11 || this.horaselec == 12) {
                            // console.log("El número ingresado si es entero.");
                            this.dvalidafechasgrupal = true;

                            this.colaborssicumplen.map((Element) => {
                                this.getfechas(this.fechagrupospick, Element.codigo, 2);
                            });

                        } else {
                            if (this.horaselec == 0 || this.horaselec > 12) {
                                this.textoBarra = 'No es posible registrar con la hora seleccionada';
                                this.colorBarra = 'error';
                                this.muestraBarra = true;
                            }
                        }
                    } else {
                        if (this.switchGrupoT2 == true) {

                            if (this.horaselec == 8.30) {
                                // console.log("grupo turno 7 horas 8.30.");
                                this.dvalidafechasgrupal = true;

                                this.colaborssicumplen.map((Element) => {
                                    this.getfechas(this.fechagrupospick, Element.codigo, 2);
                                });
                            } else {
                                // console.log("El número ingresado no es entero.");
                                this.textoBarra = 'El turno general no permite registrar horas incompletas...';
                                this.colorBarra = 'error';
                                this.muestraBarra = true;
                            }

                        }
                        if (this.switchGrupoT3 == true) {

                            if (this.horaselec == 7.30) {
                                // console.log("grupo turno 8 horas 7.30.");
                                this.dvalidafechasgrupal = true;

                                this.colaborssicumplen.map((Element) => {
                                    this.getfechas(this.fechagrupospick, Element.codigo, 2);
                                });
                            } else {
                                // console.log("El número ingresado no es entero.");
                                this.textoBarra = 'El turno general no permite registrar horas incompletas...';
                                this.colorBarra = 'error';
                                this.muestraBarra = true;
                            }
                        }
                    }

                } else {
                    // console.log("no tienen el mismo turno");
                    //los dos switch estan activos.
                    if (this.switchGrupoT2 == true && this.switchGrupoT3 == true) {
                        this.textoBarra = 'Los selectores de turnos estan activos debes de activar solo un turno...';
                        this.colorBarra = 'error';
                        this.muestraBarra = true;
                    }

                    //Algun switch esta activo.
                    if (this.switchGrupoT2 == true && this.switchGrupoT3 == false) {
                        if (this.horaselec == 7.30) {
                            // console.log("grupo turno 8 horas 7.30.");
                            this.dvalidafechasgrupal = true;

                            this.colaborssicumplen.map((Element) => {
                                this.getfechas(this.fechagrupospick, Element.codigo, 2);
                            });
                        } else {
                            // console.log("El número ingresado no es entero.");
                            this.textoBarra = 'turno 8 El turno general no permite registrar horas incompletas...';
                            this.colorBarra = 'error';
                            this.muestraBarra = true;
                        }
                    }

                    if (this.switchGrupoT3 == true && this.switchGrupoT2 == false) {
                        if (this.horaselec == 8.30) {
                            // console.log("grupo turno 7 horas 8.30.");
                            this.dvalidafechasgrupal = true;

                            this.colaborssicumplen.map((Element) => {
                                this.getfechas(this.fechagrupospick, Element.codigo, 2);
                            });
                        } else {
                            // console.log("El número ingresado no es entero.");
                            this.textoBarra = 'turno 7El turno general no permite registrar horas incompletas...';
                            this.colorBarra = 'error';
                            this.muestraBarra = true;
                        }
                    }

                    //si los switch estan apagados.
                    if (this.switchGrupoT2 == false && this.switchGrupoT3 == false) {
                        var horaentera = 0;
                        if (this.horaselec == 1 || this.horaselec == 2 || this.horaselec == 3 || this.horaselec == 4 || this.horaselec == 5 || this.horaselec == 6 ||
                            this.horaselec == 7 || this.horaselec == 8 || this.horaselec == 9 || this.horaselec == 10 || this.horaselec == 11 || this.horaselec == 12) {
                            // console.log("El número ingresado si es entero.");
                            horaentera = 1;
                        }
                        if (this.horaselec == 0 || this.horaselec > 12) {
                            this.textoBarra = 'No es posible registrar con la hora seleccionada';
                            this.colorBarra = 'error';
                            this.muestraBarra = true;

                        }
                        if (this.fechagrupospick != "" && horaentera == 1) {
                            this.dvalidafechasgrupal = true;

                            this.colaborssicumplen.map((Element) => {
                                this.getfechas(this.fechagrupospick, Element.codigo, 2);
                            });

                        } else {
                            this.textoBarra = 'Selecciona una fecha o hora antes de continuar...';
                            this.colorBarra = 'error';
                            this.muestraBarra = true;

                        }
                    }
                }
            }
        },

        //-------------- arma un arreglo con los datos del empleado y las fechas seleccionadas-------
        crearFechas() {
            //revisa si el arreglo de fechas no esta vacio
            if (this.diaselec.length > 0) {

                if (this.errorfecha == 1 && this.igualfecha == 0) {

                    //recorre el arreglo de fechas y las va registrando en el arreglo 
                    this.diaselec.map((Element) => {
                        this.registroarmadodecolaborador.push({
                            emplid: this.emplid,
                            fecha: Element,
                            horas: ""
                        });
                        //console.log(Element);
                    });
                    //empleadoaregistrar contiene el arreglo con el que se trabajara el registro.
                    this.empleadoregistrar = this.registroarmadodecolaborador;

                    //console.log(this.empleadoregistrar);
                    this.registroarmadodecolaborador = [];
                    this.fechasrevisarindividual = [];

                    this.e1 = 2;
                    //validar el turno para mostrar los campos.

                    this.errorfecha = 0;
                    this.igualfecha = 0;
                    this.dvalidafechasindividual = false;

                } else if (this.igualfecha == 1 && this.errorfecha == 1 || this.igualfecha == 1 && this.errorfecha == 0) {
                    this.dfechasindividual = true;
                    this.diaselec = [];
                    this.errorfecha = 0;
                    this.igualfecha = 0;
                    this.dvalidafechasindividual = false;
                }

            } else {

                this.textoBarra = 'Selecciona una fecha antes de continuar...';
                this.colorBarra = 'error';
                this.muestraBarra = true;

            }
        },

        validarmotivo() {
            if (this.motivoseleccionado == 0) {
                this.textoBarra = 'para avanzar asegurese de seleccionar algun motivo valido...';
                this.colorBarra = 'error';
                this.muestraBarra = true;
            } else {

                if (this.motivoseleccionado == 1) {
                    this.motivo1 = "Cuadro Base Completo";
                    this.justificacion = this.motivo1;
                    this.motivo2 = "";
                    this.motivo3 = "";
                    this.motivo4 = "";
                    this.motivo5 = "";
                    this.saveTEindividual();

                    this.clean();

                }
                if (this.motivoseleccionado == 2) {
                    if (this.maximo == "" || this.minimo == "") {
                        this.textoBarra = 'los campos de maximo y minimo estan vacios...';
                        this.colorBarra = 'error';
                        this.muestraBarra = true;
                    }
                    if (this.maximo <= this.minimo) {
                        this.textoBarra = 'el maximo de figuras no puede ser menor que el minimo...';
                        this.colorBarra = 'error';
                        this.muestraBarra = true;

                    } else {
                        if (this.valuesrespmotivo == null) {
                            this.textoBarra = 'no seleccionaste un responsable...';
                            this.colorBarra = 'error';
                            this.muestraBarra = true;
                        } else {
                            this.motivo2 = "Cumplimiento al plan de prduccion";
                            this.justificacion = this.motivo2;
                            this.motivo1 = "";
                            this.motivo3 = "";
                            this.motivo4 = "";
                            this.motivo5 = "";
                            this.saveTEindividual();

                            this.clean();
                        }

                    }
                }
                if (this.motivoseleccionado == 3) {
                    if (this.monto == "") {
                        this.textoBarra = 'El campo de monto contiene letras ó esta vacio...';
                        this.colorBarra = 'error';
                        this.muestraBarra = true;
                    } else {
                        if (this.valuesrespmotivo == null) {
                            this.textoBarra = 'no seleccionaste un responsable...';
                            this.colorBarra = 'error';
                            this.muestraBarra = true;
                        } else {
                            this.motivo3 = "Pedidos extraordinarios";
                            this.justificacion = this.motivo3;

                            this.motivo1 = "";
                            this.motivo2 = "";
                            this.motivo4 = "";
                            this.motivo5 = "";
                            this.saveTEindividual();

                            this.clean();
                        }

                    }
                }
                if (this.motivoseleccionado == 4) {
                    if (this.areamotivo == "") {
                        this.textoBarra = 'Seleccione un area de mantenimiento y/o limpieza...';
                        this.colorBarra = 'error';
                        this.muestraBarra = true;
                    } else {
                        if (this.valuesrespmotivo == null) {
                            this.textoBarra = 'no seleccionaste un responsable...';
                            this.colorBarra = 'error';
                            this.muestraBarra = true;
                        } else {
                            this.motivo4 = "Mantenimento y/o limpieza";
                            this.justificacion = this.motivo4;
                            this.validararea();

                            this.motivo1 = "";
                            this.motivo2 = "";
                            this.motivo3 = "";
                            this.motivo5 = "";
                            this.saveTEindividual();

                            this.clean();
                        }

                    }
                }
                if (this.motivoseleccionado == 5) {
                    if (this.comentario == "") {
                        this.textoBarra = 'El campo de comentario esta vacio...';
                        this.colorBarra = 'error';
                        this.muestraBarra = true;
                    } else {
                        if (this.valuesrespmotivo == null) {
                            this.textoBarra = 'no seleccionaste un responsable...';
                            this.colorBarra = 'error';
                            this.muestraBarra = true;
                        } else {
                            this.motivo5 = "Impacto en equipo caido";
                            this.justificacion = this.motivo5;

                            this.motivo1 = "";
                            this.motivo2 = "";
                            this.motivo3 = "";
                            this.motivo4 = "";
                            this.saveTEindividual();

                            this.clean();
                        }
                    }
                }

            }

        },

        clean() {
            this.empleadoregistrar = [];
            this.registroarmadodecolaborador = [];
            this.diaselec = [];
            this.horaselec = "";
            this.fechagrupospick = "";
            this.motivoseleccionado = "";
            this.cuadrobc = "";
            this.monto = 0;
            this.maximo = 0;
            this.minimo = 0;
            this.areamotivo = "";
            this.comentario = "";

            this.dregistro = false;
            this.gruporegistro = false;
            this.grupomotivos = false;
            this.horaselec = "";
            this.seleccion = [];
            this.e1 = 1;
            this.valuesrespmotivo = null;
            this.switchIndvT2 = false;
            this.switchIndvT3 = false;
            this.switchGrupoT2 = false;
            this.switchGrupoT3 = false;
        },

        validarhora() {
            var hcero = 0;
            var excesodehrs = 0;
            var noesint = 0;
            var switchactivos = 0;
            var switchturno2 = 0;
            var switchturno3 = 0;

            if (this.empleadoregistrar.length > 0) {
                this.getResponsablesMotivos();
                //Si no es el turno 2 y 3 puede cargar horas de ese turno.
                if (this.turno != 7 && this.turno != 8) {
                    this.empleadoregistrar.map((Element) => {
                        // Verifica si el número es entero
                        if (Element.horas == 1 || Element.horas == 2 || Element.horas == 3 || Element.horas == 4 || Element.horas == 5 || Element.horas == 6 ||
                            Element.horas == 7 || Element.horas == 8 || Element.horas == 9 || Element.horas == 10 || Element.horas == 11 || Element.horas == 12) {
                            // console.log("El número ingresado si es entero.");
                        } else {

                            if (this.switchIndvT2 == true) {
                                // console.log("Prueba de turno 2 -> turno 8 -> 7.30hrs");
                                if (Element.horas != 7.30) {
                                    switchturno2++;
                                }
                            } else if (this.switchIndvT3 == true) {
                                // console.log("Prueba de turno 3 -> turno 7 -> 8.30hrs");
                                if (Element.horas != 8.30) {
                                    switchturno3++;
                                    // console.log("Prueba de turno 3 -> turno 7 -> 8.30hrs ok");
                                }
                            } else {
                                noesint++;
                            }

                            if (this.switchIndvT2 == true && this.switchIndvT3 == true) {
                                switchactivos++;
                            }

                        }
                        if (Element.horas == 0) {

                            hcero++;
                            // console.log(hcero);

                        } else if (Element.horas > 12) {
                            excesodehrs++;
                        }
                    });
                    if (switchactivos > 0) {
                        this.textoBarra = 'Los selectores de turnos estan activos debes de activar solo un turno...';
                        this.colorBarra = 'error';
                        this.muestraBarra = true;

                    } else if (hcero > 0) {
                        this.textoBarra = 'No puedes continuar si no has cargado horas extras al empleado...';
                        this.colorBarra = 'error';
                        this.muestraBarra = true;

                    } else if (excesodehrs > 0) {
                        this.textoBarra = 'Revisa las horas algun registro contiene exceso de horas...';
                        this.colorBarra = 'error';
                        this.muestraBarra = true;

                    } else if (noesint > 0) {
                        this.textoBarra = 'Tu turno no permite registrar horas incompletas...';
                        this.colorBarra = 'error';
                        this.muestraBarra = true;
                        noesint = 0;

                    } else if (switchturno2 > 0) {
                        this.textoBarra = 'las horas correspondientes al turno 2 son 7.30 hrs...';
                        this.colorBarra = 'error';
                        this.muestraBarra = true;

                    } else if (switchturno3 > 0) {
                        this.textoBarra = 'las horas correspondientes al turno 3 son 8.30 hrs...';
                        this.colorBarra = 'error';
                        this.muestraBarra = true;

                    } else {

                        this.getMotivos();
                        this.e1 = 3;

                    }

                } else {
                    //si no, es por que es el turno 2 o tres y solo podra seleccionar entre 1 y 12 hrs.
                    this.empleadoregistrar.map((Element) => {
                        // Verifica si el número es entero revisando cualquier otro turno.
                        if (Element.horas == 1 || Element.horas == 2 || Element.horas == 3 || Element.horas == 4 || Element.horas == 5 || Element.horas == 6 ||
                            Element.horas == 7 || Element.horas == 8 || Element.horas == 9 || Element.horas == 10 || Element.horas == 11 || Element.horas == 12) {
                            console.log("El número ingresado si es entero.");
                        } else {
                            console.log("El número ingresado no es entero.");
                            noesint++;
                        }
                        if (Element.horas == 0) {

                            hcero++;
                            // console.log(hcero);

                        } else if (Element.horas > 12) {
                            excesodehrs++;
                        }
                    });
                    if (hcero > 0) {
                        this.textoBarra = 'No puedes continuar si no has cargado horas extras al empleado...';
                        this.colorBarra = 'error';
                        this.muestraBarra = true;

                    } else if (excesodehrs > 0) {
                        this.textoBarra = 'Algun registro contiene exceso de horas 12 es el limite...';
                        this.colorBarra = 'error';
                        this.muestraBarra = true;

                    } else if (noesint > 0) {
                        this.textoBarra = 'Tu turno no permite registrar horas incompletas...';
                        this.colorBarra = 'error';
                        this.muestraBarra = true;
                    } else {

                        this.getMotivos();
                        this.e1 = 3;
                    }
                }
            }
        },

        cerrarregistro() {
            this.clean();
        },

        registrargrupo() {
            this.mostrarsemana(this.semanaactual);
            // if (this.seleccion.length != this.colaboradores.length) {
            //     this.textoBarra = 'Seleccione todos los colaboradores para poder registrar el grupo completo...';
            //     this.colorBarra = 'danger';
            //     this.muestraBarra = true;

            // } else {
            var nocumplen = 0;
            this.seleccion = this.colaboradores
            this.seleccion.map((Element) => {
                if (Element.ESTATUS == 0 || Element.horasPrevias >= 9) {
                    nocumplen++;
                }
            });

            if (nocumplen == 0) {
                this.gruporegistro = true;
            } else {
                this.dreglas = true;
            }
            // }

        },

        validararea() {

            if (this.areamotivo == "GUDH") {
                this.areaseleccionada = 0;
            } else if (this.areamotivo == "Desarrollo Humano") {
                this.areaseleccionada = 1;
            } else if (this.areamotivo == "Administracion y Finanzas") {
                this.areaseleccionada = 2;
            } else if (this.areamotivo == "Logistica") {
                this.areaseleccionada = 6;
            } else if (this.areamotivo == "Operaciones") {
                this.areaseleccionada = 7;
            } else if (this.areamotivo == "Ventas Nacionales") {
                this.areaseleccionada = 9;
            } else if (this.areamotivo == "Mercadotecnia") {
                this.areaseleccionada = 10;
            } else if (this.areamotivo == "Ventas Internacionales") {
                this.areaseleccionada = 12;
            } else if (this.areamotivo == "Innovacion y competitividad") {
                this.areaseleccionada = 11;
            }
        },

        luneshoralimite(valor, item) {
            const ahora = moment();
            //comprobar si es lunes.
            if (ahora.day() == 1) {
                //comprobar si es antes de las 12:30.
                const horalimite = moment('11:10 AM', 'h:mm A');
                if (ahora.isAfter(horalimite)) {
                    //si se pueden cargar horas extras.
                    if (valor == 1) {
                        //cargar horas extras individuales.
                        this.textoBarra = 'Las horas cargadas en lunes despues de medio dia no se veran reflejadas en la nomia actual....';
                        this.colorBarra = 'danger';
                        this.muestraBarra = true;
                        this.abrirregistroteindividual(item);
                    }
                    if (valor == 2) {
                        //cargar horas extras grupal.
                        this.textoBarra = 'Las horas cargadas en lunes despues de medio dia no se veran reflejadas en la nomia actual....';
                        this.colorBarra = 'danger';
                        this.muestraBarra = true;
                        this.registrargrupo();
                    }
                } else {

                    //carga de horas normal en lunes.
                    if (valor == 1) {
                        //cargar horas extras individuales.
                        this.abrirregistroteindividual(item);
                    }
                    if (valor == 2) {
                        //cargar horas extras grupal.
                        this.registrargrupo();
                    }
                }
            } else {
                //carga de horas normal otro dia que no sea lunes.
                if (valor == 1) {
                    //cargar horas extras individuales.
                    this.abrirregistroteindividual(item);
                }
                if (valor == 2) {
                    //cargar horas extras grupal.
                    this.registrargrupo();
                }
            }

        },

        cerrarreglas() {
            this.gruporegistro = true;
            this.dreglas = false;
        },

        getKardex(emplidcolaborador) {

            let params = {
                Opcion: 15,
                emplid: emplidcolaborador,
                token: localStorage.getItem("token"),
            };
            axios.post(`${this.$store.getters.getHost}/general/ws-TiempoExtra.php`, params).then((response) => {
                    if (response.data.status.trim() == "EXPSESSION") {
                        deleteToken();
                    } else {
                        var data = response.data.kardex;
                        if (data) {
                            //this.kardexcolaborador = data;
                            this.kardexcolaborador = [];
                            this.dkardexfalta = true;
                            const registroFechaMasReciente = this.obtenerFechaMasReciente(data);
                            // console.log("Registro con la fecha más reciente:");
                            // console.log(registroFechaMasReciente);
                            this.kardexcolaborador = registroFechaMasReciente;
                            // console.log(this.kardexcolaborador);
                        }
                    }
                })
                .catch(function(e) {
                    let result = e.toString().includes("401");
                    if (result) {
                        window.location.reload();
                    } else {
                        alert(e);
                    }
                });
        },

        obtenerFechaMasReciente(kardex) {
            let fechaMasReciente = null;
            let fechaMasRecientearr = null;

            kardex.forEach((registro) => {
                const fechaActual = moment(registro.Fecha, "DD/MMM/YYYY");
                if (!fechaMasReciente || fechaActual > fechaMasReciente) {
                    fechaMasReciente = fechaActual;
                    fechaMasRecientearr = registro;
                }
            });

            return [fechaMasRecientearr];
        },

        //DAVID VAZQUEZ 
        formatDates(dates) {
            return dates.join(', ');
        },
        validateHoras(item) {
            if (item.horas > 9) {
                item.horas = 9;
            }
            if (item.horas < 0) {
                item.horas = 0;
            }
        },
        getHorasColaborador(emplid, anteriorlunes, nextDomingo) {
            let params = {
                Opcion: 19,
                emplid: emplid,
                token: localStorage.getItem("token"),
                inicioFecha: anteriorlunes,
                finFecha: nextDomingo
            };
            axios.post(`${this.$store.getters.getHost}/general/ws-TiempoExtra.php`, params).then((response) => {
                    if (response.data.status.trim() == "EXPSESSION") {
                        deleteToken();
                    } else {
                        if (response.data.status === 'OK') {
                            this.totalHorasPrevias = response.data.totalHoras || 0;
                        }
                    }
                })
                .catch(function(e) {
                    let result = e.toString().includes("401");
                    if (result) {
                        window.location.reload();
                    } else {
                        alert(e);
                    }
                });
        },
        async filterItems(search) {
            if (search) {
                if (search.length > 4) {
                    this.loadingFiguras = true;
                    this.figurasEncontradas = await this.getFigurasJde(search);
                }
            } else {
                this.figurasEncontradas = [];
            }
        },
        async setFigura() {
            const indice = await this.figurasEncontradas.findIndex(
                (objeto) => objeto.item === this.figurasSeleccionadas
            );
            this.idsFifuras.push(this.figurasEncontradas[indice]);
            this.searchFiguras = "";
            this.figurasEncontradas = [];
        },
        async removeFigura(figura) {
            const indice = await this.idsFifuras.findIndex(
                (objeto) => objeto === figura
            );
            this.idsFifuras.splice(indice, 1);
        },
        getFigurasJde(entrada) {
            const headers = {
                Authorization: localStorage.getItem("token"),
            };

            return new Promise((res) => {
                axios
                    .get(
                        `${this.$store.getters.getHostNode}/api/descanso-laborado/solicitantes/figuras-jde/${entrada}`, {
                            headers,
                        }
                    )
                    .then((response) => {
                        if (response.data.status == "EXPSESSION") {
                            deleteToken();
                        } else {
                            res(response.data.data);
                        }
                    })
                    .finally(() => {
                        this.loadingFiguras = false;
                    });
            });
        },

    }
}