<template>
    <v-card class="elevation-0 pa-0">
        <v-data-table dense :headers="headers" :search="search" :items="comunicados"
            no-data-text="No hay registros de beneficiarios" :footer-props="{
            'items-per-page-text': 'Elementos por página',
        }" :header-props="{ 'short-by-text': 'Ordenar por', }" loading-text="Cargando contenido..."
            :loading="loadingcomunicados">
            <template v-slot:top>
                <div style="display: flex; justify-content: space-between;">
                    <v-text-field v-model="search" outlined dense class="rounded-xl" style="max-width: 350px;"
                        prepend-inner-icon="mdi-table-search" placeholder="Buscar en tabla..."></v-text-field>
                    <div>

                        <v-btn outlined depressed class="rounded-pill ml-2" color="teal" dark
                            @click="openAgregaContenido()">
                            <v-icon left> mdi-plus </v-icon>
                            <label class="text-uppercase font-weight-bold ">Contenido</label>
                        </v-btn>
                        <vue-json-to-csv :json-data="itemsExcel" :labels="headers_report_to_export" :csv-title="title"
                            class="ma-0">
                            <v-tooltip bottom color="blue lighten-4">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn outlined depressed class="rounded-pill ml-2" dark color="green darken-2"
                                        v-bind="attrs" v-on="on" @click="exportToCsv">
                                        <v-icon fab>mdi-microsoft-excel</v-icon>
                                    </v-btn>
                                </template>
                                <span class="text-uppercase font-weight-bold primary--text">Descarga la info mostrada en
                                    la tabla</span>
                            </v-tooltip>
                        </vue-json-to-csv>
                        <v-tooltip bottom color="blue lighten-4">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn outlined small fab depressed class="ml-2" v-bind="attrs" v-on="on"
                                    color="primary" @click="getComunicados()">
                                    <v-icon>mdi-reload</v-icon>
                                </v-btn>
                            </template>
                            <label class="text-uppercase font-weight-bold primary--text">Actualizar tabla</label>
                        </v-tooltip>
                    </div>
                </div>
            </template>
            <template v-slot:item.estado="{ item }">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on" :color="getEstadoColor(item)">
                            mdi-monitor-share
                        </v-icon>
                    </template>
                    <span>{{ getEstadoText(item) }}</span>
                </v-tooltip>
            </template>
            <template v-slot:item.actions="{ item }" class="">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon small color="cyan darken-2" v-bind="attrs" v-on="on" class="mx-2"
                            @click="getImagen(item.Id, item.Link)">
                            mdi-panorama-outline
                        </v-icon>
                    </template>
                    <span>Ver</span>
                </v-tooltip>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon small color="cyan darken-2" v-bind="attrs" v-on="on"
                            @click="editItem(item)">mdi-pencil</v-icon>
                    </template>
                    <span>Editar</span>
                </v-tooltip>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon small color="cyan darken-2" v-bind="attrs" v-on="on" class="mx-2"
                            @click="deleteOpen(item)">mdi-delete</v-icon>
                    </template>
                    <span>Eliminar</span>
                </v-tooltip>
            </template>
            <template v-slot:item.Link="{ item }">
                <a :href="item.link" target="_blank">{{ truncateLink(item.Link) }}</a>
            </template>
        </v-data-table>
        <v-dialog v-model="muestraAgregaComunicado" content-class="fix-border-dialog" max-width="400" scrollable
            persistent>
            <v-card class="rounded-xl">
                <v-card-title class="primary rounded-xl rounded-b-0 white--text subtitle-1 font-weight-bold">
                    {{ editedItem.Id ? 'Editar Comunicado' : 'Nuevo Comunicado' }}
                    <v-spacer></v-spacer>
                    <v-btn icon dark @click="muestraAgregaComunicado = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text>
                    <v-form ref="form" v-model="valid">
                        <v-text-field v-model="editedItem.Titulo" :rules="[v => !!v || 'Campo requerido']"
                            label="Título" required></v-text-field>
                        <v-dialog ref="dateDialog" v-model="dateDialog" width="290px">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field v-model="editedItem.FechaInicio" label="Fecha Inicio" readonly
                                    v-bind="attrs" v-on="on"></v-text-field>
                            </template>
                            <v-date-picker v-model="editedItem.FechaInicio"
                                @change="dateDialog = false"></v-date-picker>
                        </v-dialog>
                        <v-dialog ref="dateDialogEnd" v-model="dateDialogEnd" width="290px">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field v-model="editedItem.FechaFin" label="Fecha Fin" readonly v-bind="attrs"
                                    v-on="on"></v-text-field>
                            </template>
                            <v-date-picker v-model="editedItem.FechaFin"
                                @change="dateDialogEnd = false"></v-date-picker>
                        </v-dialog>
                        <v-file-input v-if="editedItem.Id === null" accept="image/png, image/jpeg, image/bmp"
                            v-model="file" show-size
                            :rules="[v => editedItem.Id === null ? !!v || 'Campo requerido' : true, value => !value || value.size < 2000000 || 'Debe ser menor a 2 MB!']"
                            prepend-icon="mdi-camera" label="Imagen" @change="handleFileChange"></v-file-input>
                        <v-file-input v-else accept="image/png, image/jpeg, image/bmp,  image/jpg" v-model="file"
                            show-size :rules="[value => !value || value.size < 2000000 || 'Debe ser menor a 2 MB!']"
                            prepend-icon="mdi-camera" label="Imagen" @change="handleFileChange"></v-file-input>
                        <v-text-field v-model="editedItem.Link" label="Link (opcional)"></v-text-field>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="save">Guardar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialogImagen" max-width="600">
            <v-card>
                <v-container fluid>
                    <v-row align="center" justify="center">
                        <v-img :src="imagenUrl" contain max-width="100%"></v-img>
                    </v-row>
                </v-container>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="350">
            <v-card class="rounded-lg">
                <v-card-text class="pt-5">
                    <div style="display: flex; justify-content: center;">
                        <v-img class="text-center" :src="require('@/assets/icons/advertencia.png')"
                            max-width="80"></v-img>
                    </div>
                    <p class="subtitle-2 text-uppercase font-weight-bold text-center mb-3">¡Advertencia!</p>
                    <p class="subtitle-2 text-uppercase  text-center mb-3">
                        ¿Realmente deseas eliminar el comunicado {{ nameComunicado }}?
                    </p>
                </v-card-text>
                <v-card-actions style="display: flex; justify-content: space-around;">
                    <v-btn depressed class="rounded-lg" style="width:45%" dark color="pink darken-3"
                        @click="dialogDelete = false">cancelar</v-btn>
                    <v-btn depressed class="rounded-lg" style="width:45%" dark color="teal darken-1"
                        @click="deleteItem">confirmar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-snackbar v-model="snackbar.visible" :color="snackbar.color">
            {{ snackbar.text }}
            <v-btn color="white" text @click="snackbar.visible = false">Cerrar</v-btn>
        </v-snackbar>
    </v-card>
</template>

<script>
import axios from "axios";
import VueJsonToCsv from 'vue-json-to-csv';

export default {
    name: "muestra-contenidos",
    components: { VueJsonToCsv },
    data() {
        return {
            headers: [
                {
                    text: "ID",
                    value: "Id",
                    class: "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                },
                {
                    text: 'Título',
                    value: 'Titulo',
                    class: "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                },
                {
                    text: 'Url',
                    value: 'Link',
                    class: "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                },
                {
                    text: 'Inicio',
                    value: 'FechaInicio',
                    class: "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                },
                {
                    text: 'Fin',
                    value: 'FechaFin',
                    class: "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                },
                {
                    text: 'Estado',
                    value: 'estado',
                    align: "center",
                    class: "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                },
                {
                    text: 'Visualizaciones',
                    value: 'visualizacion',
                    align: "center",
                    class: "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                },
                {
                    text: 'Interacciones',
                    value: 'visitas',
                    align: "center",
                    class: "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                },
                {
                    text: "Acciones",
                    value: "actions",
                    sortable: false,
                    align: "center",
                    class:
                        "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                },
            ],
            comunicados: [],
            loadingcomunicados: false,
            search: "",
            //
            dateDialog: false,
            dateDialogEnd: false,
            valid: false,
            editedItem: {
                Id: null,
                Titulo: '',
                FechaInicio: null,
                FechaFin: null,
                imagen: '',
                file: '',
                Link: '',
            },
            defaultItem: {
                Id: null,
                Titulo: '',
                FechaInicio: null,
                FechaFin: null,
                imagen: '',
                file: '',
                Link: '',
            },
            file: null,
            muestraAgregaComunicado: false,
            //snackbar
            snackbar: {
                visible: false,
                text: '',
                color: ''
            },
            //dialog imagen
            imagenUrl: '',
            dialogImagen: false,
            //
            dialogDelete: false,
            nameComunicado: '',
            idComunicado: null,
            //Excel
            itemsExcel: [],
            title: 'Reporte de Comunicados DH',
            headers_report_to_export: {
                Id: { title: "Id Comunicado" },
                Titulo: { title: "Titulo" },
                FechaInicio: { title: "Fecha Inicio" },
                FechaFin: { title: "Fecha Fin" },
                Link: { title: "Link" },
                visualizacion: { title: "Visualizaciones" },
                visitas: { title: "Interacciones" },
            },

        };
    },
    async mounted() {
        this.getComunicados();
    },
    computed: {
        formTitle() {
            return this.editedItem.id ? 'Editar Comunicado' : 'Nuevo Comunicado';
        },
    },
    methods: {
        muestraSnackBar(color, texto) {
            this.colorBarra = color;
            this.textoBarra = texto;
            this.muestraBarra = true;
        },
        parseFecha(fecha) {
            const partes = fecha.split('/');
            return `${partes[2]}-${partes[1]}-${partes[0]}`;
        },
        getEstadoText(item) {
            const today = new Date();
            const startDate = new Date(this.parseFecha(item.FechaInicio));
            const endDate = new Date(this.parseFecha(item.FechaFin));

            if (today < startDate) {
                return 'Pendiente';
            } else if (today > endDate) {
                return 'Vencido';
            } else {
                return 'Publicado';
            }
        },
        getEstadoColor(item) {
            const today = new Date();
            const startDate = new Date(this.parseFecha(item.FechaInicio));
            const endDate = new Date(this.parseFecha(item.FechaFin));

            if (today < startDate) {
                return 'grey';
            } else if (today > endDate) {
                return 'cyan darken-2';
            } else {
                return 'green';
            }
        },
        truncateLink(link) {
            const maxLength = 20;
            if (link.length > maxLength) {
                return link.slice(0, maxLength) + '...';
            }
            return link;
        },
        openAgregaContenido() {
            this.muestraAgregaComunicado = true;
            this.editedItem = Object.assign({}, this.defaultItem);
        },
        onDateRangeChange(dates) {
            this.editedItem.fechaInicio = dates.start;
            this.editedItem.fechaFin = dates.end;
        },
        handleFileChange(file) {
            if (file) {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => {
                    this.editedItem.imagen = reader.result;
                };
            }
        },
        save() {
            if (this.$refs.form.validate()) {
                const { fechaInicio, fechaFin, imagen, ...rest } = this.editedItem;
                const dataToSend = {
                    ...rest,
                    fechaInicio,
                    fechaFin,
                    imagen,
                };
                axios.put(`${this.$store.getters.getHostNode}/api/setComunicados`, dataToSend, {
                    headers: {
                        authorization: `${localStorage.getItem("token")}`,
                    },
                })
                    .then(response => {
                        // Lógica después de guardar
                        if (response.data.status == 'OK') {
                            this.file = null;
                            this.muestraAgregaComunicado = false;
                            this.getComunicados();
                            this.snackbar = {
                                visible: true,
                                text: 'Solicitud procesada correctamente',
                                color: 'success'
                            };
                        } else {
                            this.snackbar = {
                                visible: true,
                                text: 'Error al procesar la solicitud',
                                color: 'error'
                            };
                        }
                    })
                    .catch(error => {
                        console.error('Error al guardar:', error);
                        this.snackbar = {
                            visible: true,
                            text: 'Error de servidor: ' + error.message,
                            color: 'error'
                        };
                    });
            }
        },
        editItem(item) {
            this.editedItem = {
                ...item,
                FechaInicio: this.convertirFecha(item.FechaInicio),
                FechaFin: this.convertirFecha(item.FechaFin),
            };
            this.muestraAgregaComunicado = true;
        },
        convertirFecha(fecha) {
            if (!fecha) return null; // Si no hay fecha, retorna null
            const [dia, mes, año] = fecha.split('/');
            return `${año}-${mes}-${dia}`;
        },
        deleteItem() {
            axios.delete(`${this.$store.getters.getHostNode}/api/deleteComunicado/${this.idComunicado}`, {
                headers: {
                    authorization: `${localStorage.getItem("token")}`,
                },
            })
                .then(response => {
                    if (response.data.status == 'OK') {
                        this.file = null;
                        this.muestraAgregaComunicado = false;
                        this.getComunicados();
                        this.snackbar = {
                            visible: true,
                            text: 'Eliminado correctamente el comunicado',
                            color: 'success'
                        };
                    } else {
                        this.snackbar = {
                            visible: true,
                            text: 'Error al procesar el archivo',
                            color: 'error'
                        };
                    }
                    this.dialogDelete = false;
                })
                .catch(error => {
                    console.error('Error al eliminar:', error);
                    this.snackbar = {
                        visible: true,
                        text: 'Error de servidor: ' + error.message,
                        color: 'error'
                    };
                });
        },
        deleteOpen(item) {
            this.dialogDelete = true;
            this.nameComunicado = item.Titulo;
            this.idComunicado = item.Id;
        },
        getComunicados() {
            this.loadingcomunicados = true;

            axios
                .get(`${this.$store.getters.getHostNode}/api/getComunicados`, {
                    headers: {
                        authorization: `${localStorage.getItem("token")}`,
                    },
                })
                .then((response) => {
                    if (response.data.status == "OK") {
                        this.comunicados = response.data.data;
                        this.itemsExcel = response.data.data;
                    } else {
                        this.comunicados = [];
                        this.itemsExcel = [];

                    }
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    this.loadingcomunicados = false;
                });
        },
        getImagen(id, url) {
            console.log(id, url);

            axios.get(`${this.$store.getters.getHostNode}/api/getComunicadosImage`, {
                params: {
                    id: id,
                },
                headers: {
                    authorization: `${localStorage.getItem("token")}`,
                    responseType: 'blob'
                },
            })
                .then(response => {
                    // Verificar el tipo de contenido y la imagen en base64
                    const { mimeType, base64Image } = response.data;

                    if (base64Image) {
                        // Crear una URL de datos para la imagen recibida en base64
                        const imageUrl = `data:${mimeType};base64,${base64Image}`;

                        // Mostrar el diálogo con la imagen
                        this.imagenUrl = imageUrl;
                        this.dialogImagen = true;
                    } else {
                        console.error('La respuesta no contiene una imagen válida en base64.');
                    }
                })
                .catch(error => {
                    console.error('Error imagen', error);
                });
        },
        exportToCsv() {
            // Exportar datos a CSV
            if (this.itemsExcel.length > 0) {
                this.$refs.exporter.exportCSV();
            } else {
                alert('No hay datos para exportar');
            }
        },

    },
};
</script>
