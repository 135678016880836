<template>
  <v-container
    class="pt-5"
    :fluid="['md', 'sm', 'xs'].includes($vuetify.breakpoint.name)"
  >
    <v-row dense>
      <v-col :cols="widthLargeColumn">
        <div v-show="accesos.type === 'general'">
          <label class="overline">área</label>
          <div
            style="display:flex; justify-content: start; align-items: center;"
          >
            <v-btn
              color="primary"
              class="rounded-lg mr-1"
              :outlined="area != 'all'"
              @click="area = 'all'"
            >
              todos
            </v-btn>
            <v-btn
              color="primary"
              class="rounded-lg mr-1"
              :outlined="area != 1"
              @click="area = 1"
            >
              Desarrollo Humano
            </v-btn>
            <v-btn
              color="primary"
              class="rounded-lg mr-1"
              :outlined="area != 6"
              @click="area = 6"
            >
              Logística
            </v-btn>
            <v-btn
              color="primary"
              class="rounded-lg mr-1"
              :outlined="area != 7"
              @click="area = 7"
            >
              Operaciones
            </v-btn>
            <v-btn
              color="primary"
              class="rounded-lg mr-1"
              :outlined="area != 11"
              @click="area = 11"
            >
              Inovación y competitividad
            </v-btn>
          </div>
        </div>
      </v-col>
      <v-col :cols="widthSmallColumn">
        <div>
          <label class="overline">mes</label>
          <v-select
            v-model="mesSeleccionado"
            class="rounded-lg"
            dense
            outlined
            :items="meses"
            @change="updateTable"
            item-text="mes"
            item-value="value"
            placeholder="Selecciona el mes"
            no
          ></v-select>
        </div>
      </v-col>
    </v-row>

    <v-row dense>
      <v-col :cols="widthSmallColumn">
        <v-card
          width="100%"
          height="100%"
          class="pa-8 rounded-xl elevation-0 center-item-in-div-column"
          style="border: 2px solid #1565C0;"
        >
          <label class="font-weight-bold overline subtitle-1 primary--text">
            {{
              meses.length > 0
                ? meses[mesSeleccionado == null ? 0 : mesSeleccionado - 1].mes
                : ""
            }}
          </label>
          <label class="font-weight-bold text-h4 primary--text">
            {{ formatMXN(getSumatoriaCosto) }}
          </label>
        </v-card>
      </v-col>
      <v-col :cols="widthLargeColumn">
        <v-card
          width="100%"
          height="100%"
          class="pa-2 rounded-xl elevation-0"
          style="border: 2px solid #1565C0;"
        >
          <ChartBarrasCostosDL />
        </v-card>
      </v-col>
    </v-row>

    <v-card
      class="mt-5 rounded-xl elevation-0"
      style="border: 2px solid #1565C0;"
    >
      <v-data-table
        :headers="headers"
        :items="solicitudes"
        :loading="loadingData"
        :search="search"
        :footer-props="{
          'items-per-page-text': 'Elementos por página',
          pageText: '{0}-{1} de {2}',
        }"
        no-data-text="No hay registros"
        loading-text="Cargando información..."
        :no-results-text="
          'No se encontró ningun resultado que coincida con ' + search
        "
        :header-props="headerProps"
        :mobile-breakpoint="0"
        dense
      >
        <template v-slot:top>
          <div
            style="display: flex; justify-content: space-between;"
            class="white pt-3 px-3 pb-0 rounded-xl rounded-b-0"
          >
            <v-text-field
              v-model="search"
              dense
              outlined
              class="rounded-pill"
              prepend-inner-icon="mdi-magnify"
              placeholder="Buscar..."
            ></v-text-field>
            <v-spacer></v-spacer>
            <div>
              <vue-json-to-csv
                :json-data="solicitudes"
                :labels="headers_to_export"
                :csv-title="'ReporteSolicitudesMensual'"
              >
                <v-tooltip bottom color="primary">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      depressed
                      class="rounded-pill mr-2"
                      color="primary"
                      dark
                      fab
                      small
                      outlined
                      :loading="loadingData"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>mdi-microsoft-excel</v-icon>
                    </v-btn>
                  </template>
                  <span>Exportar CSV</span>
                </v-tooltip>
              </vue-json-to-csv>
              <v-tooltip bottom color="primary">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    depressed
                    class="rounded-pill mr-2"
                    color="primary"
                    dark
                    fab
                    small
                    outlined
                    :loading="loadingData"
                    v-bind="attrs"
                    v-on="on"
                    @click="updateTable"
                  >
                    <v-icon>mdi-reload</v-icon>
                  </v-btn>
                </template>
                <span>Refrescar</span>
              </v-tooltip>
            </div>
          </div>
        </template>
        <template v-slot:item.costo="{ item }">
          <label class="font-weight-bold font-italic">
            {{ formatMXN(item.costo) }}
          </label>
        </template>
        <template v-slot:item.solicitante="{ item }">
          {{ `(${item.solicitante}) ${item.nomEmp}` }}
        </template>
        <template v-slot:item.fecha="{ item }">
          {{ setFormatDate(item.fecha) }}
        </template>
        <template v-slot:item.estatus="{ item }">
          <v-chip
            small
            depressed
            :color="getColorEstatus(item.estatus)"
            class="font-weight-bold"
          >
            {{ setEstatusText(item.estatus) }}
          </v-chip>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import axios from "axios";
import { deleteToken } from "../../../../../../store/Funciones/funciones";
import ChartBarrasCostosDL from "./ChartBarrasCostosDL";
import VueJsonToCsv from "vue-json-to-csv";

export default {
  props: ["accesos"],
  components: { ChartBarrasCostosDL, VueJsonToCsv },
  data() {
    return {
      area: "all",
      search: "",
      solicitudes: [],
      headers: [
        {
          value: "id",
          text: "id",
          align: "center",
          class: "primary--text overline font-weight-bold",
        },
        {
          value: "costo",
          text: "costo",
          align: "center",
          class: "primary--text overline font-weight-bold",
        },
        {
          value: "solicitante",
          text: "solicitante",
          class: "primary--text overline font-weight-bold",
        },
        {
          value: "depName",
          text: "depto.",
          class: "primary--text overline font-weight-bold",
        },
        {
          value: "fecha",
          text: "fecha",
          align: "center",
          class: "primary--text overline font-weight-bold",
        },
        {
          value: "descAreaSolicitante",
          text: "área",
          align: "center",
          class: "primary--text overline font-weight-bold",
        },
        {
          value: "estatus",
          text: "estatus",
          align: "center",
          class: "primary--text overline font-weight-bold",
        },
      ],
      headers_to_export: {
        id: { title: "id" },
        costo: { title: "costo" },
        solicitante: { title: "solicitante" },
        nomEmp: { title: "nomEmp" },
        depName: { title: "depName" },
        fecha: { title: "fecha" },
        fecha_autorizacion: { title: "fecha_autorizacion" },
        estatus: { title: "estatus" },
        area: { title: "area" },
        Area: { title: "Area" },
        idAreaSolicitante: { title: "idAreaSolicitante" },
        descAreaSolicitante: { title: "descAreaSolicitante" },
      },
      mesSeleccionado: null,
      meses: [
        { value: 1, mes: "Enero" },
        { value: 2, mes: "Febrero" },
        { value: 3, mes: "Marzo" },
        { value: 4, mes: "Abril" },
        { value: 5, mes: "Mayo" },
        { value: 6, mes: "Junio" },
        { value: 7, mes: "Julio" },
        { value: 8, mes: "Agosto" },
        { value: 9, mes: "Septiembre" },
        { value: 10, mes: "Octubre" },
        { value: 11, mes: "Noviembre" },
        { value: 12, mes: "Diciembre" },
      ],
      loadingData: true,
      headerProps: {
        sortByText: "Ordenar por",
      },
    };
  },
  computed: {
    getSumatoriaCosto() {
      return this.solicitudes.length === 0
        ? 0
        : this.solicitudes.reduce((accumulator, item) => {
            return accumulator + item.costo;
          }, 0);
    },
    widthLargeColumn() {
      return this.$vuetify.breakpoint.width < 1100 ? 12 : 9;
    },
    widthSmallColumn() {
      return this.$vuetify.breakpoint.width < 1100 ? 12 : 3;
    },
  },
  async mounted() {
    const today = new Date();
    const currentMonth = today.getMonth() + 1;
    this.mesSeleccionado = currentMonth;
    this.area =
      (await this.accesos.type) == "general"
        ? "all"
        : this.accesos.type[0].area;
    this.solicitudes = await this.getReporteSolicitudesGeneral();
  },
  methods: {
    async updateTable() {
      this.loadingData = true;
      this.solicitudes = await this.getReporteSolicitudesGeneral();
    },
    getReporteSolicitudesGeneral() {
      const headers = {
        Authorization: localStorage.getItem("token"),
      };

      return new Promise((res) => {
        axios
          .get(
            `${this.$store.getters.getHostNode}/api/descanso-laborado/reporte-de-costos/solicitudes/${this.mesSeleccionado}/${this.area}`,
            {
              headers,
            }
          )
          .then((response) => {
            if (response.data.status == "EXPSESSION") {
              deleteToken();
            } else if (response.data.status == "OK") {
              res(response.data.data);
            } else {
              res([]);
            }
          })
          .finally(() => {
            this.loadingData = false;
          });
      });
    },
    formatMXN(cadena) {
      return cadena.toLocaleString("es-MX", {
        style: "currency",
        currency: "MXN",
      });
    },
    setFormatDate(date) {
      let fecha = new Date(date);

      let diaUTC = fecha.getUTCDate();
      let mesUTC = fecha.getUTCMonth() + 1;
      let anioUTC = fecha.getUTCFullYear();

      diaUTC = diaUTC < 10 ? "0" + diaUTC : diaUTC;
      mesUTC = mesUTC < 10 ? "0" + mesUTC : mesUTC;

      return diaUTC + "/" + mesUTC + "/" + anioUTC;
    },
    setEstatusText(estatus) {
      switch (estatus) {
        case 0:
          return "Abierta";
        case 2:
          return "Autorizada";
        case 3:
          return "Rechazada";
        default:
          return "No disponible";
      }
    },
    getColorEstatus(estatus) {
      switch (estatus) {
        case 0:
          return "yellow lighten-4";
        case 2:
          return "green lighten-3";
        case 3:
          return "red lighten-3";
        default:
          return "blue-grey lighten-3";
      }
    },
  },
};
</script>
