<template>
    <v-card class="rounded-xl elevation-0 pa-3">

        <v-data-table :headers="headers" :search="search" :items="solicitudes"
            no-data-text="No hay registros de debeficiados" :footer-props="{
            'items-per-page-text': 'Elementos por página',
        }" :header-props="{ 'short-by-text': 'Ordenar por', }" loading-text="Cargando contenido..."
            :loading="loadingBenefits" show-expand single-expand>

            <template v-slot:top>
                <div style="display: flex; justify-content: space-between;">
                    <v-text-field v-model="search" outlined dense class="rounded-xl" style="max-width: 350px;"
                        prepend-inner-icon="mdi-table-search" placeholder="Buscar en tabla..."></v-text-field>
                    <div>
                        <v-tooltip bottom color="blue lighten-4">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn small fab depressed class="ml-2" v-bind="attrs" v-on="on"
                                    @click="colseAgregaBeneficio(1)">
                                    <v-icon>mdi-reload</v-icon>
                                </v-btn>
                            </template>
                            <label class="text-uppercase font-weight-bold primary--text">Actualizar tabla</label>
                        </v-tooltip>
                    </div>
                </div>
            </template>

            <template v-slot:item.estatus="{ item }">
                <v-chip small :color="getColor(item.autorizado)" dark>
                    {{ getText(item.autorizado) }}
                </v-chip>
            </template>

            <template v-slot:item.actions="{ item }">
                <v-tooltip bottom v-if="item.autorizado === null">
                    <template v-slot:activator="{ on }">
                        <v-icon v-on="on" color="cyan darken-2" @click="dialogSolicitud(item.id, item.solicitante, 2)">
                            mdi-check-circle-outline
                        </v-icon>
                    </template>
                    <span>Autorizar</span>
                </v-tooltip>

                <v-tooltip bottom v-if="item.autorizado === null">

                    <template v-slot:activator="{ on }">
                        <v-icon v-on="on" color="pink accent-3" @click="dialogSolicitud(item.id, item.solicitante, 3)">
                            mdi-cancel
                        </v-icon>
                    </template>
                    <span>Rechazar</span>
                </v-tooltip>
            </template>


            <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length">
                    <v-row>
                        <v-col cols="4">
                            <v-text-field dense v-model="item.duracion" class="rounded-lg ma-2 mt-3" outlined
                                label="Duración de beneficio" readonly></v-text-field>
                        </v-col>
                        <v-col cols="3">
                            <v-text-field prefix="$" v-model="item.monto" dense class="rounded-lg mt-3" outlined
                                label="Monto" readonly>
                                {{ formatMXN(item.monto) }}
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col v-if="item.fechaAutorizado !== null" cols="6">
                            <v-text-field v-model="item.fechaAutorizado" dense label="Autorizado"
                                class="rounded-lg mt-0" outlined readonly></v-text-field>
                        </v-col>
                        <v-col v-if="item.userAutoriza !== null" cols="6">
                            <v-text-field v-model="item.userAutoriza" dense label="Responsable" class="rounded-lg ma-2"
                                outlined readonly></v-text-field>
                        </v-col>

                    </v-row>
                    <v-row>
                        <v-col v-if="item.fechaRechazado !== null" cols="6">
                            <v-text-field v-model="item.fechaRechazado" dense label="Rechazado" class="rounded-lg mt-0"
                                outlined readonly></v-text-field>
                        </v-col>
                        <v-col v-if="item.userRechazado !== null" cols="6">
                            <v-text-field v-model="item.userRechazado" dense label="Responsable" class="rounded-lg mt-0"
                                outlined readonly></v-text-field>
                        </v-col>
                        <v-col v-if="item.comentarioRechazado !== null" cols="12">
                            <v-textarea rows="3" v-model="item.comentarioRechazado" label="Comentario" auto-grow dense
                                class="rounded-lg mt-0" outlined readonly></v-textarea>
                        </v-col>
                    </v-row>
                </td>
            </template>
        </v-data-table>

        <v-dialog v-model="dialogConfirmar" content-class="fix-border-dialog" persistent max-width="600">
            <v-card class="rounded-xl">
                <v-card-title class="primary rounded-xl rounded-b-0 white--text subtitle-1 font-weight-bold">
                    Confirmación de solicitud
                    <v-spacer></v-spacer>
                    <v-btn icon dark @click="dialogConfirmar = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text class="pa-2 mb-0 text-overline">
                    {{ mensaje }}
                    <br>
                    <label v-if="(this.opcion === 3)" class="overline"> Comentario </label>
                    <v-textarea v-if="(this.opcion === 3)" rows="3" v-model="comentarioRechazado" auto-grow dense
                        class="rounded-lg" outlined></v-textarea>
                </v-card-text>
                <v-card-actions class="pa-3 mt-0">
                    <v-spacer></v-spacer>
                    <v-btn v-if="(this.opcion === 2)" dark color="cyan darken-2" @click="setSolicitud">Confirmar</v-btn>
                    <v-btn v-else color="pink accent-3" dark @click="setSolicitud">Rechazar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-snackbar v-model="snackbar" :color="color" :timeout="timeout">
            {{ text }}

            <template v-slot:action="{ attrs }">
                <v-btn text v-bind="attrs" @click="snackbar = false">
                    Cerrar
                </v-btn>
            </template>
        </v-snackbar>
    </v-card>
</template>

<script>
import axios from "axios";

export default {
    name: "muestra-beneficios",
    components: {},
    data() {
        return {
            headers: [
                {
                    text: "ID",
                    value: "id",
                    class:
                        "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                },
                {
                    text: "Solicitante",
                    value: "solicitante",
                    class:
                        "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                },
                {
                    text: "Beneficio",
                    value: "beneficio",
                    class:
                        "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                },
                {
                    text: "Solicitado",
                    value: "fechaSolicitud",
                    sortable: false,
                    class:
                        "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                },
                {
                    text: "Estatus",
                    value: "estatus",
                    sortable: false,
                    class:
                        "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                },
                {
                    text: "Acciones",
                    value: "actions",
                    sortable: false,
                    align: "center",
                    class:
                        "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                },
            ],
            solicitudes: [],
            loadingBenefits: true,
            search: "",
            dialogConfirmar: false,
            opcion: null,
            mensaje: '',
            idsolicitud: null,
            solicitante: '',
            comentarioRechazado: '',
            snackbar: false,
            text: '',
            color: '',
            timeout: 4000,
        };
    },
    async mounted() {
        this.solicitudes = await this.getBenefits();
    },
    methods: {
        async getBenefits() {
            let params = {
                Opcion: 1,
                token: localStorage.getItem("token"),
            };
            return new Promise((resolve, reject) => {
                axios
                    .post(
                        `${this.$store.getters.getHost}/Compensaciones/ws-solicitud-beneficios-flexibles.php`,
                        params
                    )
                    .then((response) => {
                        if (response.data.status == "OK") {
                            resolve(response.data.solicitudes);
                        }
                    })
                    .catch((error) => {
                        reject({
                            error: error,
                        });
                    })
                    .finally(() => {
                        this.loadingBenefits = false;
                    });
            });
        },
        async colseAgregaBeneficio(type) {
            if (type == 1) {
                this.loadingBenefits = true;
                this.solicitudes.length = 0;
                this.solicitudes = await this.getBenefits();
            }
        },
        getColor(autorizado) {
            if (autorizado === null) {
                return 'yellow'; // Color para 'pendiente'
            } else if (autorizado === 0) {
                return 'red'; // Color para 'rechazado'
            } else {
                return 'green'; // Color para 'autorizado'
            }
        },
        getText(autorizado) {
            if (autorizado === null) {
                return 'Pendiente';
            } else if (autorizado === 0) {
                return 'Rechazado';
            } else {
                return 'Autorizado';
            }
        },
        formatMXN(cadena) {
            return cadena.toLocaleString("es-MX", {
                style: "currency",
                currency: "MXN",
            });
        },
        dialogSolicitud(item, name, opc) {
            this.dialogConfirmar = true;
            this.idsolicitud = item;
            this.solicitante = name;
            this.opcion = opc;
            if (this.opcion === 2) {
                this.mensaje = 'La siguiente solicitud de ' + this.solicitante + ' se aprobará';
            } else {
                this.mensaje = 'La siguiente solicitud de ' + this.solicitante + ' se rechazará';
            }
        },
        setSolicitud() {
            let params = {
                Opcion: this.opcion,
                token: localStorage.getItem("token"),
                comentario: this.comentarioRechazado,
                idrow: this.idsolicitud,
            };
            axios
                .post(
                    `${this.$store.getters.getHost}/Compensaciones/ws-solicitud-beneficios-flexibles.php`,
                    params
                )
                .then((response) => {
                    if (response.data.status == "OK") {
                        if (this.opcion === 2) {
                            this.text = 'La solicitud fue aceptada.';
                            this.color = 'cyan darken-2';
                        } else {
                            this.text = 'La solicitud fue rechazada.';
                            this.color = 'pink accent-3';
                        }
                    }
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(async () => {
                    this.solicitudes = await this.getBenefits();
                    this.dialogConfirmar = false;
                    this.snackbar = true;
                });
        }
    },
};
</script>