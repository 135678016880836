import axios from 'axios'
import MuestraEvaluaciones from './components/MuestraEvaluaciones.vue'
import AddNewProyect360 from './components/AddNewProyect360.vue'
import {
    deleteToken,
    saveMonitoreo
} from '../../../../../store/Funciones/funciones'
import SnackBar from "../../../General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue";


export default {
    name: 'admon-evaluaciones',
    components: {
        MuestraEvaluaciones,
        AddNewProyect360,
        SnackBar
    },
    props: [],
    data() {
        return {
            loadingItems: true,
            addProject: false,
            muestraBarra: false,
            textoBarra: "",
            colorBarra: "",
            keyEval: 0,
            companySelected: "",
            companias: [],
            companiaUsr: {
                Compania: "",
                avr: ""
            }
        }
    },
    computed: {

    },
    mounted() {
        saveMonitoreo("Administración de evaluaciones 360 test")
        this.getCompanias()
    },
    methods: {
        async getCompanias() {
            let params = {
                Opcion: 1,
                token: localStorage.getItem('token')
            }

            axios
                .post(
                    `${this.$store.getters.getHost}/RelacionesLaborales/AdmonEvaluacionesDesempenio/ws-admon-360.php`,
                    params
                )
                .then((response) => {
                    if (response.data.status == "EXPRESSION") {
                        deleteToken();
                    } else if (response.data.status == "OK") {
                        this.companias = response.data.companias;
                    }
                });
            let result = await this.getCompaniaUsr()
            this.companiaUsr = result[0]
            this.companySelected = this.companiaUsr.avr
            this.loadingItems = false;
        },

        getCompaniaUsr() {
            let params = {
                Opcion: 2,
                token: localStorage.getItem('token')
            }
            return new Promise((resolve, reject) => {
                axios
                    .post(
                        `${this.$store.getters.getHost}/RelacionesLaborales/AdmonEvaluacionesDesempenio/ws-admon-360.php`,
                        params
                    )
                    .then((response) => {
                        if (response.data.status == "EXPRESSION") {
                            deleteToken();
                        } else if (response.data.status == "OK") {
                            resolve(response.data.compania)
                        } else {
                            reject("error")
                        }
                    })
            })
        },

        actualizaCompania() {
            let company = this.companias.filter((item) => item.Compania == this.companySelected)
            this.companiaUsr.Compania = company[0].CIA_JDE
            this.companiaUsr.avr = company[0].Compania
            this.keyEval++;
        },

        closeAddProject(result) {
            if (result == 0) {
                this.addProject = false;
            } else if (result == 1) {
                this.companias.length = 0
                this.companiaUsr = {
                    Compania: "",
                    avr: ""
                }
                this.loadingItems = true;
                this.textoBarra = this.$t('DH360.successInsertMessage');
                this.addProject = false;
                this.colorBarra = "success";
                this.muestraBarra = true;
                this.getCompanias();
            }
        }
    }
}