<!-- DataTable.vue -->
<template>
    <v-card class="pa-3 rounded-xl elevation-0" color="blue-grey lighten-5">
        <v-data-table :headers="headers" :items="pases" class="rounded-xl" :search="search"
            no-data-text="No se han registrado pases de ingreso" loading-text="Cargando información..."
            :loading="loadingPasses" :footer-props="{
            'items-per-page-text': 'Elementos por página',
            'pageText': '{0}-{1} de {2}',
        }" :single-expand="true" :expanded.sync="expanded" show-expand>
            <template v-slot:top>
                <div class="pa-2" style="display: flex; justify-content: space-between;">
                    <v-text-field v-model="search" outlined dense clearable class="rounded-xl" style="max-width: 300px;"
                        prepend-inner-icon="mdi-table-search" placeholder="Buscar pase..."></v-text-field>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom color="blue lighten-4">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn color="blue lighten-4" small fab depressed class="mx-2" v-bind="attrs" v-on="on"
                                @click="getPasesPorEmpleado()">
                                <v-icon>mdi-reload</v-icon>
                            </v-btn>
                        </template>
                        <label class="text-uppercase font-weight-bold primary--text">Actualizar tabla</label>
                    </v-tooltip>
                    <v-btn color="primary" @click="addIngreso" class="rounded-xl">Agregar</v-btn>
                </div>
            </template>
            <template v-slot:item.fecha_solicitud="{ item }">
                {{ ![null, undefined].includes(item.fecha_solicitud) ? fixDate(item.fecha_solicitud.date.substring(0,
            10)) + ' '
            +
            item.fecha_solicitud.date.substring(11, 16) : '' }}
            </template>
            <template v-slot:item.estatus="{ item }">
                <v-chip small :class="getClass(item.estatus)">
                    {{ getEstatusText(item.estatus) }}
                </v-chip>
            </template>
            <template v-slot:item.actions="{ item }">
                <v-tooltip top color="cyan darken-1">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn small class="rounded-pill" v-if="item.estatus === 0" color="cyan darken-1" dark
                            :loading="loadingBtnNotify[item.id] || false" v-bind="attrs" v-on="on"
                            @click="notify(item.id)">
                            <v-icon small>
                                mdi-bell-ring
                            </v-icon>
                        </v-btn>
                    </template>
                    <span>Reenviar notificación</span>
                </v-tooltip>
            </template>
            <template v-slot:[`expanded-item`]="{ item }">
                <td :colspan="headers.length">
                    <v-card flat>
                        <v-card-text>
                            <v-row>
                                <v-col cols="12" sm="6">
                                    <strong>Domicilio:</strong> {{ item.domicilio }}
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <strong>Área de Pases:</strong> {{ item.Area }}
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <strong>Acción de Pases:</strong> {{ item.accion }}
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <strong>Autoriza:</strong> {{ item.nombre_auto }}
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <strong>Unidad de Medida:</strong> {{ item.descMedida }}
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <strong>Modelo del Auto:</strong> {{ item.modelo_auto }}
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <strong>Placa del Auto:</strong> {{ item.placa_auto }}
                                </v-col>
                            </v-row>
                            <v-data-table :headers="materialHeaders" :items="item.material" class="elevation-1"
                                hide-default-footer>
                                <template v-slot:item.cantidad="{ item }">
                                    <v-chip>{{ item.cantidad }} {{ item.descMedida }}</v-chip>
                                </template>
                                <template v-slot:item.fecha_modificacion="{ item }">
                                    {{ ![null, undefined].includes(item.fecha_modificacion)
            ? fixDate(item.fecha_modificacion.date.substring(0, 10)) + ' ' +
            item.fecha_modificacion.date.substring(11, 16) : '' }}
                                </template>
                            </v-data-table>
                        </v-card-text>
                    </v-card>
                </td>
            </template>
        </v-data-table>
        <NuevoPaseIngreso :dialog="dialog" @save="handleSave" @close="dialog = false" />
        <SnackBarErrorIncidencias v-if="muestraBarra" :muestraBarra="muestraBarra" :color="colorBarra"
            :texto="textoBarra" @cierraBarra="muestraBarra = false" />
    </v-card>
</template>

<script>
import NuevoPaseIngreso from './nuevoPaseIngreso.vue';
import axios from "axios";
import { formatDate } from "../../../../../store/Funciones/funciones";
import SnackBarErrorIncidencias from "../../../General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue";


export default {
    components: { NuevoPaseIngreso, SnackBarErrorIncidencias },
    data() {
        return {
            dialog: false,
            pases: [],
            search: null,
            expanded: [],
            loadingPasses: true,
            headers: [
                { text: 'ID', value: 'id', class: "overline font-weight-bold primary white--text rounded-left-top-table-lg" },
                { text: 'Nombre', value: 'nombre', class: "overline font-weight-bold primary white--text" },
                { text: 'Compañía', value: 'compania', class: "overline font-weight-bold primary white--text" },
                { text: 'Teléfono', value: 'telefono', class: "overline font-weight-bold primary white--text" },
                { text: 'Fecha de Solicitud', value: 'fecha_solicitud', class: "overline font-weight-bold primary white--text" },
                { text: 'Estatus', value: 'estatus', class: "overline font-weight-bold primary white--text" },
                { text: "Acciones", value: "actions", align: "center", class: "overline font-weight-bold primary white--text", },
                { text: '', value: 'data-table-expand', class: "overline font-weight-bold primary white--text rounded-right-top-table-lg", },
            ],
            materialHeaders: [
                { text: 'Código', value: 'codigo', class: "overline font-weight-bold primary white--text rounded-left-top-table-lg" },
                { text: 'Descripción', value: 'descripcion', class: "overline font-weight-bold primary white--text" },
                { text: 'Cantidad', value: 'cantidad', class: "overline font-weight-bold primary white--text" },
                { text: 'Número de Referencia', value: 'num_referencia_OT_WMS', class: "overline font-weight-bold primary white--text" },
                { text: 'Orden de Compra', value: 'orden_compra', class: "overline font-weight-bold primary white--text" },
                { text: 'Factura', value: 'factura', class: "overline font-weight-bold primary white--text" },
                { text: 'Fecha de Modificación', value: 'fecha_modificacion', class: "overline font-weight-bold primary white--text rounded-right-top-table-lg", },
            ],
            loadingBtnNotify: {},
            //
            muestraBarra: false,
            colorBarra: "",
            textoBarra: "",
        };
    },
    methods: {
        addIngreso() {
            this.dialog = true;
        },
        handleSave(saved) {
            if (saved) {
                this.getPasesPorEmpleado(); // Método para actualizar los datos de la tabla
            }
            this.dialog = false;
        },
        getPasesPorEmpleado() {
            this.loadingPasses = true;
            this.pases = [];

            let params = {
                Opcion: 1,
                token: localStorage.getItem("token"),
            };
            axios
                .post(
                    `${this.$store.getters.getHost}/SeguridadPatrimonial/ws-pases-de-ingreso.php`,
                    params
                )
                .then((response) => {
                    if (response.data.status == "OK") {
                        this.pases = response.data.passes;
                    }
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    this.loadingPasses = false;
                });
        },
        fixDate(dateStr) {
            return formatDate(dateStr)
        },
        getClass(estatus) {
            if (estatus === 0) {
                return "yellow accent-4 black--text";
            } else if (estatus === 1) {
                return "teal darken-1 white--text";
            } else if (estatus === 2) {
                return "pink darken-1 white--text";
            } else if (estatus === 3) {
                return "light-green darken-1 white--text";
            } else if (estatus === 4) {
                return "indigo darken-1 white--text";
            } else {
                return "grey";
            }
        },
        getEstatusText(estatus) {
            if (estatus === 0) {
                return "Pendiente";
            } else if (estatus === 1) {
                return "Autorizado";
            } else if (estatus === 2) {
                return "Rechazado";
            } else if (estatus === 3) {
                return "Liberado";
            } else if (estatus === 4) {
                return "En espera";
            } else {
                return "No definido";
            }
        },
        async notify(id) {
            this.$set(this.loadingBtnNotify, id, true); // Solo este botón se pone en carga

            try {
                await this.sendMail(id); // Suponiendo que sendMail es una función asíncrona
            } catch (error) {
                // Maneja el error si es necesario
            } finally {
                this.$set(this.loadingBtnNotify, id, false); // Restablece el estado de carga
            }
        },
        async sendMail(idPase) {
            let params = {
                Opcion: 7,
                token: localStorage.getItem("token"),
                idPase: idPase,
            };

            try {
                const response = await axios.post(
                    `${this.$store.getters.getHost}/SeguridadPatrimonial/ws-pases-de-ingreso.php`,
                    params
                );

                if (response.data.status === "OK") {
                    if (response.data.respuesta.status_tran === "OK") {
                        this.openSnackBar(response.data.respuesta.message, "teal darken-1");
                    } else {
                        this.openSnackBar(response.data.respuesta.message);
                    }
                }
            } catch (error) {
                this.openSnackBar(error.message);
            }
        },
        openSnackBar(texto, color = "pink darken-3") {
            this.colorBarra = color;
            this.textoBarra = texto;
            this.muestraBarra = true;
        },
    },
    created() {
        this.getPasesPorEmpleado(); // Cargar los datos iniciales al crear el componente
    },
};
</script>