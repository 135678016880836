var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-4"},[_c('v-data-table',{staticClass:"elevation-10 background-glass-effect-new-positions-request rounded-lg",attrs:{"headers":_vm.headers,"items":_vm.conocimientos,"search":_vm.search,"loading":_vm.loadingData,"height":"100%","loading-text":"Cargando contentenido...","footer-props":{
      'items-per-page-options': [10, 20, 30, 100],
      'items-per-page-text': 'Elementos por página',
    },"no-data-text":"No hay registros","header-props":{
      'short-by-text': 'Ordenar por',
    },"item-key":"id","dense":""},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":"","small":"","color":"green darken-2"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"icon":"","depressed":"","color":"green darken-2","small":"","dark":""},on:{"click":function($event){return _vm.openConfirmDialog(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-check-bold ")])],1)]}}],null,true)},[_c('p',{staticClass:"text-uppercase mb-0 font-weight-bold body-2"},[_vm._v(" Autorizar ")])]),_c('v-tooltip',{attrs:{"top":"","small":"","color":"red darken-2"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"depressed":"","icon":"","color":"red darken-2","small":""},on:{"click":function($event){return _vm.openAddComments(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-close-thick ")])],1)]}}],null,true)},[_c('p',{staticClass:"text-uppercase mb-0 font-weight-bold body-2 pink--text text--lighten-5"},[_vm._v(" Rechazar ")])])]}}])}),_c('v-dialog',{attrs:{"persistent":"","max-width":"600"},model:{value:(_vm.commentReject),callback:function ($$v) {_vm.commentReject=$$v},expression:"commentReject"}},[_c('v-card',{staticClass:"rounded-lg"},[_c('v-card-title',{staticClass:"pt-2 primary white--text"},[_vm._v(" Justifica la acción. "),_c('v-spacer'),_c('v-icon',{attrs:{"dark":""},on:{"click":function($event){_vm.commentReject = false}}},[_vm._v(" mdi-close-thick ")])],1),_c('v-card-subtitle',{staticClass:"primary pb-2 blue-grey--text text--lighten-3 text-uppercase"},[_vm._v(" "+_vm._s(_vm.requestName)+" ("+_vm._s(_vm.requestId)+") ")]),_c('v-card-text',{staticClass:"pt-2 pb-0"},[_c('v-form',{ref:"formComments",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-textarea',{staticClass:"rounded-lg",attrs:{"outlined":"","rows":1,"rules":_vm.commentRejectRules,"disabled":_vm.loadingBtnRejectRequest,"required":"","placeholder":"Escribe el comentario de rechazo.","prepend-inner-icon":"mdi-comment","counter":"250"},model:{value:(_vm.commentRejectText),callback:function ($$v) {_vm.commentRejectText=$$v},expression:"commentRejectText"}})],1)],1),_c('v-card-actions',[_c('v-btn',{staticClass:"rounded-lg elevation-0",attrs:{"block":"","color":"primary","loading":_vm.loadingBtnRejectRequest},on:{"click":function($event){_vm.loadingBtnRejectRequest = true;
            _vm.updateEstatus();}}},[_vm._v(" Rechazar ")])],1)],1)],1),(_vm.muestraBarra)?_c('SnackBar',{attrs:{"muestraBarra":_vm.muestraBarra,"color":_vm.colorBarra,"texto":_vm.textoBarra},on:{"cierraBarra":function($event){_vm.muestraBarra = false}}}):_vm._e(),_c('ConfirmDialogReclutamiento',{attrs:{"showConfirm":_vm.openConfirm,"question":_vm.question},on:{"cierraConfirm":_vm.verifyAnswer}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }