<template>
  <v-dialog max-width="800" v-model="dialog" persistent class="rounded-xxl">
    <v-card>
      <v-card-title class="text-subtitle-2 primary white--text">
        Solicitar baja
        <v-spacer></v-spacer>
        <v-icon small dark @click="$emit('cierraDialogBajas', { estatus: '' })">mdi-close</v-icon>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" xl="6" lg="6" md="6" sm="12" xs="12">
              <v-menu v-model="menuFechaDespido" :close-on-content-click="false" :nudge-right="40"
                transition="scale-transition" offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="form.dateFechaDespido" label="Fecha de despido" prepend-icon="mdi-calendar"
                    readonly v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-date-picker v-model="form.dateFechaDespido" @input="menuFechaDespido = false"></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" xl="6" lg="6" md="6" sm="12" xs="12">
              <v-menu v-model="menuFechaBaja" :close-on-content-click="false" :nudge-right="40"
                transition="scale-transition" offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="form.dateFechaBaja" label="Fecha de baja" prepend-icon="mdi-calendar" readonly
                    v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-date-picker v-model="form.dateFechaBaja" @input="menuFechaBaja = false"></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" xl="4" lg="4" md="4" sm="4" xs="12">
              <v-autocomplete v-on:change="buscarMotivo1()" v-model="form.motivoGeneral" label="Motivo General"
                item-text="MotivoBajaNominas" item-value="IDBajaNominas" :loading="loadingMotivosGenerales"
                :items="motivosGenerales" clearable></v-autocomplete>
            </v-col>
            <v-col cols="12" xl="4" lg="4" md="4" sm="4" xs="12">
              <v-autocomplete v-if="!form.motivoGeneral" label="Motivo 1" disabled></v-autocomplete>
              <v-autocomplete v-else v-on:change="buscarMotivo2()" v-model="form.motivo1" label="Motivo 1"
                :loading="loadingMotivo1" :items="motivos1" item-text="DetalleMotivoBaja" item-value="IDTipoBaja"
                clearable></v-autocomplete>
            </v-col>
            <v-col cols="12" xl="4" lg="4" md="4" sm="4" xs="12">
              <v-autocomplete v-if="!form.motivo1" label="Motivo 1" disabled></v-autocomplete>
              <v-autocomplete v-else v-on:change="agregarMotivo2()" v-model="form.motivo2" label="Motivo 2"
                :loading="loadingMotivo2" :items="motivos2" item-text="DetalleMotivoBaja2" item-value="IDTipoBaja2"
                clearable></v-autocomplete>
            </v-col>
          </v-row>
          <v-row class="align-center">
            <v-col cols="12" xl="4" lg="4" md="4" sm="4" xs="12">
              <v-autocomplete v-model="form.estatus" label="Selecciona Estatus" :items="estatusItems"></v-autocomplete>
              <v-autocomplete v-if="colaborador['accion'] == 'editar'" v-model="form.tipoColaborador"
                label="Tipo de colaborador" item-value="tipoColaborador" item-text="muestra"
                :loading="loadingMotivosGenerales" :items="tipoColaboradores"></v-autocomplete>
            </v-col>
            <v-col cols="12" xl="8" lg="8" md="8" sm="8" xs="12">
              <v-textarea rows="4" v-model="form.comentario" label="Escribe un comentario" required></v-textarea>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="rounded-lg elevation-0" color="teal darken-1" dark small @click="submit" :loading="loadingSubmit">
          <v-icon small>mdi-check-circle-outline</v-icon> Aceptar
        </v-btn>
      </v-card-actions>
    </v-card>
    <SnackBar v-if="muestraBarra" :muestraBarra="muestraBarra" :texto="textoBarra" :color="colorBarra"
      @cierraBarra="muestraBarra = false" />
  </v-dialog>
</template>

<script>
import axios from "axios";
import SnackBar from "../../../../General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue";
export default {
  name: "form-bajas-de-empleado",
  components: { SnackBar },
  props: ["dialog", "colaborador"],
  data() {
    return {
      textoBarra: "",
      colorBarra: "",
      muestraBarra: false,

      menuFechaDespido: false,
      dateFechaDespido: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),
      menuFechaBaja: false,
      dateFechaBaja: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),

      motivosGenerales: [],
      motivos1: [],
      motivos2: [],
      loadingMotivosGenerales: true,
      loadingMotivo1: true,
      loadingMotivo2: true,
      loadingSubmit: false,
      IDBajaNominas: "",
      IDTipoBaja: "",
      IDTipoBaja2: "",
      estatusItems: ["Verde", "Amarillo", "Rojo"],
      form: {
        dateFechaDespido: "",
        dateFechaBaja: "",
        motivoGeneral: "",
        motivo1: "",
        motivo2: "",
        estatus: "",
        comentario: "",
      },
      tipoColaboradores: [
        { tipoColaborador: 1, muestra: "[1] Sindicalizado" },
        { tipoColaborador: "1 TMP", muestra: "[1 TMP] Sindicalizado" },
        { tipoColaborador: 2, muestra: "[2] Administrativo" },
        { tipoColaborador: "2 TMP", muestra: "[2 TMP] Administrativo" },
        { tipoColaborador: 3, muestra: "[3] Otro" },
        { tipoColaborador: 4, muestra: "[4] Otro" },
        { tipoColaborador: 5, muestra: "[5] Administrativo" },
        { tipoColaborador: "5 TMP", muestra: "[5 TMP] Administrativo" },
        { tipoColaborador: 6, muestra: "[6] Otros Operativos" },
        { tipoColaborador: "6 TMP", muestra: "[6 TMP] Otros Operativos" },
        { tipoColaborador: 8, muestra: "[8] Almacenistas" },
        { tipoColaborador: "8 TMP", muestra: "[8 TMP] Almacenistas" },
        { tipoColaborador: 10, muestra: "[10] Otros Operativos" },
        { tipoColaborador: "10 TMP", muestra: "[10 TMP] Otros Operativos" },
      ],
      tipoColaboradorActual: "",
    };
  },
  computed: {},
  mounted() {
    // this.getTiposColaboradores();
    this.cargarInfo();
    this.getMotivoGeneral();
  },
  methods: {
    getFechasIniciales() {
      let fechaActual = new Date();

      // Restar una semana (7 días) a la fecha actual
      let fechaHaceUnaSemana = new Date(fechaActual);
      fechaHaceUnaSemana.setDate(fechaActual.getDate());

      let anio = fechaHaceUnaSemana.getFullYear();
      let mes = String(fechaHaceUnaSemana.getMonth() + 1).padStart(2, "0");
      let dia = String(fechaHaceUnaSemana.getDate()).padStart(2, "0");

      // Formatear la fecha en formato "YYYY-mm-dd"
      this.form.dateFechaDespido = `${anio}-${mes}-${dia}`;
      this.form.dateFechaBaja = `${anio}-${mes}-${dia}`;
    },

    getTiposColaboradores() {
      let params = {
        Opcion: 6,
        token: localStorage.getItem("token"),
      };
      axios
        .post(
          `${this.$store.getters.getHost}/RelacionesLaborales/BajasDeEmpleados/ws-bajasDeEmpleados.php`,
          params
        )
        .then((response) => {
          var data = response.data.tipoColaboradores;
          this.tipoColaboradores = data;
        });
    },

    getMotivoGeneral() {
      let params = {
        Opcion: 1,
        token: localStorage.getItem("token"),
      };
      axios
        .post(
          `${this.$store.getters.getHost}/RelacionesLaborales/BajasDeEmpleados/ws-bajasDeEmpleados.php`,
          params
        )
        .then((response) => {
          this.loadingMotivosGenerales = false;
          var data = response.data.motivos;
          this.motivosGenerales = data;
        });
    },

    buscarMotivo1() {
      if (this.form.motivoGeneral) {
        if (this.motivosGenerales) {
          this.IDBajaNominas = this.form.motivoGeneral;
          this.getMotivo1(this.IDBajaNominas);
        }
      } else {
        this.motivo1 = "";
        this.motivo2 = "";
      }
    },

    getMotivo1(IDBajaNominas) {
      this.motivos1.length = 0;
      let paramsData = {
        Opcion: 2,
        IDBajaNominas: IDBajaNominas,
        token: localStorage.getItem("token"),
      };
      axios
        .post(
          `${this.$store.getters.getHost}/RelacionesLaborales/BajasDeEmpleados/ws-bajasDeEmpleados.php`,
          paramsData
        )
        .then((responseData) => {
          this.loadingMotivo1 = false;
          this.motivos1 = responseData.data.motivo1;
        });
    },

    buscarMotivo2() {
      if (this.form.motivo1) {
        this.IDTipoBaja = this.form.motivo1;
        this.getMotivo2(this.IDTipoBaja);
      }
    },

    getMotivo2(IDTipoBaja) {
      this.motivos2.length = 0;
      let paramsData = {
        Opcion: 3,
        IDTipoBaja: IDTipoBaja,
        token: localStorage.getItem("token"),
      };
      axios
        .post(
          `${this.$store.getters.getHost}/RelacionesLaborales/BajasDeEmpleados/ws-bajasDeEmpleados.php`,
          paramsData
        )
        .then((responseData) => {
          this.loadingMotivo2 = false;
          this.motivos2 = responseData.data.motivo2;
        });
    },

    agregarMotivo2() {
      if (this.motivo2) {
        let resultdo1 = this.motivos2.filter(
          (dato) => dato["DetalleMotivoBaja2"] == this.motivo2
        );
        this.IDTipoBaja2 = resultdo1[0]["IDTipoBaja2"];
      }
    },

    submit() {
      if (this.colaborador["accion"] != "editar") {
        this.emplid = this.colaborador["EMPLID"];
        let params = {
          Opcion: 4,
          token: localStorage.getItem("token"),
          emplidBaja: this.colaborador["EMPLID"],
          fechaDespido: this.form.dateFechaDespido,
          fechaBaja: this.form.dateFechaBaja,
          motivoGeneral: this.form.motivoGeneral,
          motivo1: this.form.motivo1,
          motivo2: this.form.motivo2,
          estatus: this.form.estatus,
          comentario: this.form.comentario,
        };

        if (
          !this.form.dateFechaDespido ||
          !this.form.dateFechaBaja ||
          !this.form.motivoGeneral ||
          !this.form.motivo1 ||
          !this.form.motivo2 ||
          !this.form.comentario
        ) {
          this.textoBarra = "Complete los campos solicitados";
          this.colorBarra = "error";
          this.muestraBarra = true;
        } else {
          this.loadingSubmit = true;
          axios
            .post(
              `${this.$store.getters.getHost}/RelacionesLaborales/BajasDeEmpleados/ws-bajasDeEmpleados.php`,
              params
            )
            .then((response) => {
              this.loadingSubmit = false;
              if (response.data.status == "OK") {
                this.textoBarra = "Solicitud realizada correctamente";
                this.colorBarra = "success";
                this.muestraBarra = true;
                this.clear();
                return new Promise((resolve) => {
                  setTimeout(() => {
                    resolve(
                      this.$emit("cierraDialogBajas", { estatus: "reload" })
                    );
                  }, 2000);
                });
              }

              if (response.data.status == "error") {
                this.textoBarra = "Se ha producido un error";
                this.colorBarra = "error";
                this.muestraBarra = true;
              }
            });
        }
      } else {
        this.editar();
      }
    },

    editar() {
      this.emplid = this.colaborador["EMPLID"];
      let params = {
        Opcion: 5,
        token: localStorage.getItem("token"),
        form: this.form,
        tipoColaboradorActual: this.tipoColaboradorActual,
      };

      if (
        !this.form.dateFechaDespido ||
        !this.form.dateFechaBaja ||
        !this.form.motivoGeneral ||
        !this.form.motivo1 ||
        !this.form.motivo2 ||
        !this.form.comentario
      ) {
        this.textoBarra = "Complete los campos solicitados";
        this.colorBarra = "error";
        this.muestraBarra = true;
      } else {
        this.loadingSubmit = true;
        axios
          .post(
            `${this.$store.getters.getHost}/RelacionesLaborales/BajasDeEmpleados/ws-bajasDeEmpleados.php`,
            params
          )
          .then((response) => {
            this.loadingSubmit = false;
            if (response.data.status == "OK") {
              this.textoBarra = "Solicitud realizada correctamente";
              this.colorBarra = "success";
              this.muestraBarra = true;
              this.clear();
              return new Promise((resolve) => {
                setTimeout(() => {
                  resolve(
                    this.$emit("cierraDialogBajas", { estatus: "reload" })
                  );
                }, 2000);
              });
            }

            if (response.data.status == "error") {
              this.textoBarra = "Se ha producido un error";
              this.colorBarra = "error";
              this.muestraBarra = true;
            }
          });
      }
    },

    clear() {
      this.form.dateFechaDespido = "";
      this.form.dateFechaBaja = "";
      this.form.motivoGeneral = "";
      this.form.motivo1 = "";
      this.form.motivo2 = "";
      this.form.comentario = "";
      this.form.estatus = "";
    },

    cargarInfo() {
      this.getFechasIniciales();
      if (this.colaborador["accion"] == "editar") {
        this.form = Object.assign({}, this.colaborador);
        this.tipoColaboradorActual = this.colaborador["tipoColaborador"];
        this.form.tipoColaborador = this.colaborador["tipoColaborador"];
        this.buscarMotivo1();
        this.buscarMotivo2();
      } else {
        this.getFechasIniciales();
      }
    },
  },
};
</script>
