import axios from 'axios'
import {
    deleteToken
} from '../../../../store/Funciones/funciones';
import SnackBarErrorIncidencias from "../MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue"
export default {
    name: 'cambio-pass',
    components: {
        SnackBarErrorIncidencias
    },
    props: [],
    data() {
        return {
            textoBarra: '',
            colorBarra: '',
            muestraBarra: false,
            validaUsuario: false,
            validacionesPass: false,
            showPass: false,
            valid: true,
            passwordActual: "",
            loadingButton: false,
            loadingButtonValidate: false,
            passwordActualRules: [
                v => !!v || 'Contraseña requerida',
            ],

            nuevoPassword: '',
            // nuevoPasswordRules: [
            //     v => !!v || 'Contraseña requerida',
            //     v => v.length >= 12 || 'Su contraseña debe tener al menos 12 caracteres.',
            // ],

            confirmarPassword: '',
            confirmarPasswordRules: [
                v => !!v || 'Contraseña requerida',
                v => v.length >= 12 || 'Su contraseña debe tener al menos 12 caracteres.',
                v => this.nuevoPassword == v || 'Contraseña no coinciden',
            ],

            passwordRules: [
                (v) => this.tipoUpdate === 2 || v.length >= 12 || "",
                (v) =>
                this.tipoUpdate === 2 || /^[a-zA-Z][a-zA-Z0-9@$*%#]*$/.test(v) || "",
                (v) => this.tipoUpdate === 2 || /^[a-zA-Z0-9@$*%#]*$/.test(v) || "",
                (v) => this.tipoUpdate === 2 || !/^\d*$/.test(v) || "",
                (v) => this.tipoUpdate === 2 || !/urrea/.test(v) || "",
                (v) => this.tipoUpdate === 2 || !/^[a-z]*$/.test(v) || "",
                (v) => this.tipoUpdate === 2 || !/^[A-Z]*$/.test(v) || "",
                (v) => this.tipoUpdate === 2 || /[@$*%#]/.test(v) || "",
                (v) => this.tipoUpdate === 2 || /[0-9]/.test(v) || "",
            ],
        }
    },
    computed: {

    },
    mounted() {
        //  window.document.title = "DH:URREA - Cambio de contraseña.";
        this.saveMonitoreo();
    },
    methods: {
        saveMonitoreo() {
            let params = {
                Opcion: 10,
                pantalla: "Cambio Contrasena",
                token: localStorage.getItem('token')
            }
            axios
                .post(`${this.$store.getters.getHost}/General/ws-general.php`, params)
                .then((response) => {
                    console.log(response.data)
                })
        },
        validarPass() {
            let params = {
                Opcion: 1,
                passwordActual: this.passwordActual,
                token: localStorage.getItem('token')
            };

            if (params.passwordActual == '') {
                this.textoBarra = "Para continuar, por favor ingrese su contraseña correctamente";
                this.colorBarra = 'primary';
                this.muestraBarra = true;
            } else {

                axios.post(`${this.$store.getters.getHost}//General/ws-cambioPass.php`, params).then((response) => {
                        if (response.data.data == true) {
                            //continuo con el cambio de contraseña
                            this.validaUsuario = true;
                        }

                        if (response.data.data == false) {
                            this.textoBarra = "Su contraseña no es correcta, verifique que esté bien escrita";
                            this.colorBarra = 'primary';
                            this.muestraBarra = true;
                        }

                        if (response.data.status == "EXPSESSION") {
                            deleteToken();
                        }
                    })
                    .finally(() => {
                        this.loadingButtonValidate = false;
                    })
            }

        },

        async guardar() {
            // formCambiaContrasena
            if (this.$refs.formCambiaContrasena.validate()) {
                let responseOnPhp = await this.setPasswordCurrentSystem().catch((e) => {
                    this.textoBarra = e;
                    this.colorBarra = 'error';
                    this.muestraBarra = true;
                });

                console.log(JSON.stringify(responseOnPhp, null, 2))

                if (responseOnPhp.data) {
                    let responseOnNode = await this.setPasswordNewSystem().catch(e => {
                        console.log(e)
                    })



                    console.log(responseOnNode)
                    this.textoBarra = "Contraseña modificada con éxito.";
                    this.colorBarra = 'success';
                    this.muestraBarra = true;

                    this.nuevoPassword = '';
                    this.confirmarPassword = '';
                    let path = this.$store.getters.isMultisistema ? "/DHGeneral/Inicio" : "Inicio";
                    this.$router.push(path);

                } else {
                    this.textoBarra = responseOnPhp.msg;
                    this.colorBarra = 'error';
                    this.muestraBarra = true;
                }

                this.loadingButton = false;

            } else {
                this.textoBarra = "Las contraseñas ingresadas no cumplen con uno o más reuisitos.";
                this.colorBarra = 'error';
                this.muestraBarra = true;
                this.loadingButton = false;
            }

        },

        setPasswordCurrentSystem() {
            let params = {
                Opcion: 2,
                passwordActual: this.passwordActual,
                nuevoPassword: this.nuevoPassword,
                confirmarPassword: this.confirmarPassword,
                token: localStorage.getItem('token')
            };

            return new Promise((res, rej) => {
                axios.post(`${ this.$store.getters.getHost }/General/ws-cambioPass.php`, params).then((response) => {
                        if (response.data.status == "OK") {

                            res(response.data)

                        } else {
                            res(response.data)
                        }
                    })
                    .catch(e => {
                        rej(e)
                    })
            })


        },

        setPasswordNewSystem() {
            let params = {
                folio: this.$store.getters.getUser['Folio'],
                password: this.nuevoPassword,
            };

            return new Promise((res, rej) => {
                axios.post(`${ this.$store.getters.getHostNode }/api/set-current-password`, params).then((response) => {
                        if (response.data.status == "OK") {
                            res(response.data)
                        } else {
                            res(response.data)
                        }
                    })
                    .catch(e => {
                        rej(e)
                    })
            })


        }
    }
}