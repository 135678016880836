var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"reporte-solicitud-epp"},[_c('v-container',{directives:[{name:"show",rawName:"v-show",value:(!_vm.moduloError),expression:"!moduloError"}]},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.Headers,"items":_vm.solicitudes,"expanded":_vm.expanded,"loading":_vm.cargando,"loading-text":"Cargando solicitudes","item-key":"id","show-expand":"","dense":""},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{staticClass:"rounded-lg rounded-b-0 rounded-t-0 white--text",attrs:{"flat":"","color":"primary"}},[_c('v-toolbar-title',[_vm._v("Solicitudes registradas")]),_c('v-spacer'),_c('vue-json-to-csv',{attrs:{"json-data":_vm.artXsolicitudes,"labels":_vm.headers_report_to_export,"csv-title":_vm.nameFile}},[_c('v-btn',{staticClass:"mb-1 font-weight-bold rounded-lg",attrs:{"color":"green darken-2","dark":"","small":""},on:{"click":_vm.fixName}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v("mdi-microsoft-excel")]),_vm._v(" "+_vm._s(_vm.$t("DH360.export"))+" ")],1)],1)],1)]},proxy:true},{key:"item.nombre",fn:function(ref){
var item = ref.item;
return [_c('div',{attrs:{"align":"left"}},[_vm._v(_vm._s(item.nombre))])]}},{key:"item.turno",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v("Turno "+_vm._s(item.turno)+" ")])]}},{key:"item.NuevoIngreso",fn:function(ref){
var item = ref.item;
return [(item.NuevoIngreso == 1)?_c('div',[_vm._v("Si ")]):_c('div',[_vm._v("No ")])]}},{key:"item.fecha",fn:function(ref){
var item = ref.item;
return [_c('div',{attrs:{"align":"left"}},[_vm._v(_vm._s(item.fechaRegistro))])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-list',{attrs:{"flat":"","subheader":""}},[_c('v-subheader',[_vm._v("Articulos solicitados")]),_vm._l((item.articulos),function(citem){return _c('v-list-item',{key:citem.id},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"pink"}},[_vm._v(" mdi-tools ")])],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(citem.codigo)}}),_c('v-list-item-subtitle',[_vm._v(_vm._s(citem.equipo)+" | "+_vm._s(citem.repuesto)+" | "+_vm._s(citem.enTiempo)+" ")]),_c('v-list-item-subtitle',[_vm._v(_vm._s(citem.motivo)+" | "+_vm._s(citem.comentario)+" ")]),(item.comentarioOtraActividad)?_c('v-list-item-subtitle',[_vm._v("Comentario por cambio de actividad: "+_vm._s(item.comentarioOtraActividad)+" ")]):_vm._e(),_c('v-divider')],1),_c('v-list-item-action',{staticClass:"mr-5"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-list-item-subtitle',[_vm._v("Cantidad"),_c('br'),_vm._v(_vm._s(citem.cantidad))])],1)]}}],null,true)},[_c('span',[_vm._v("Cantidad solicitada")])])],1)],1)})],2)],1)]}}])})],1),_c('v-container',{directives:[{name:"show",rawName:"v-show",value:(_vm.moduloError),expression:"moduloError"}]},[_c('mantenimiento-modulo')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }