import axios from "axios";
import SnackBarErrorIncidencias from "../../../../General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue"
import { deleteToken } from "../../../../../../store/Funciones/funciones";
export default {
    name: 'adm-motivos-aclaraciones',
    components: { SnackBarErrorIncidencias },
    props: [],
    data() {
        return {
            textoBarra: '',
            colorBarra: '',
            muestraBarra: false,

            cargando: true,
            dialogDeleteMotivo: false,
            buscar: '',
            headers: [
                { text: 'ID', value: 'ID', align: 'center', sortable: false },
                { text: 'Motivo', value: 'nombre', align: 'left', sortable: false },
                { text: 'Descripción', value: 'Descripcion', align: 'left', sortable: false },
                { text: 'Num. veces utilizado', value: 'numMotivos', align: 'left', sortable: false },
                { text: 'Acciones', value: 'acciones', align: 'center', sortable: false },
            ],
            motivosAclaraciones: [],
            dialogFormAclaraciones: '',
            editedItem: {
                ID: '',
                nombre: '',
                Descripcion: '',
            },
            defaultItem: {
                nombre: '',
                Descripcion: '',
            },
            editedIndex: -1,
            loading: false,
        }
    },
    computed: {

        formTitle() {
            return this.editedIndex === -1 ? 'Nuevo motivo' : 'Editar motivo'
        },
    },
    mounted() {
        this.getMotivosAclaraciones();
        //  window.document.title = "DH:URREA - Motivos de aclaraciones.";
    },
    methods: {
        guardarMotivo() {
            let opc = 0;
            this.editedIndex === -1 ? opc = 13 : opc = 14;
            let params = {
                Opcion: opc,
                token: localStorage.getItem("token"),
                form: this.editedItem,
            }
            this.loading = true;
            axios.post(`${this.$store.getters.getHost}/nominas/ws-nominas.php`, params).then((response) => {
                    if (response.data.status.trim() == "EXPSESSION") {
                        deleteToken();
                    } else if (response.data.status.trim() == "OK") {
                        this.textoBarra = 'Solicitud registrada correctamente';
                        this.colorBarra = 'success';
                        this.muestraBarra = true;
                        this.close();
                        this.motivosAclaraciones = [];
                        this.cargando = true;
                        this.getMotivosAclaraciones();
                    } else {
                        this.textoBarra = 'Algo salió mal';
                        this.colorBarra = 'error';
                        this.muestraBarra = true;
                    }
                    this.loading = false;
                })
                .catch(function(e) {
                    let result = e.toString().includes("401");
                    if (result) {
                        window.location.reload();
                    } else {
                        alert(e);
                    }
                });
        },
        eliminarMotivo() {
            let params = {
                Opcion: 15,
                token: localStorage.getItem("token"),
                form: this.editedItem,
            }
            this.loading = true;
            axios.post(`${this.$store.getters.getHost}/nominas/ws-nominas.php`, params).then((response) => {
                    if (response.data.status.trim() == "EXPSESSION") {
                        deleteToken();
                    } else if (response.data.status.trim() == "OK") {
                        this.textoBarra = 'Solicitud ejecutada correctamente';
                        this.colorBarra = 'success';
                        this.muestraBarra = true;
                        this.close();
                        this.motivosAclaraciones = [];
                        this.cargando = true;
                        this.getMotivosAclaraciones();
                    } else {
                        this.textoBarra = 'Algo salió mal';
                        this.colorBarra = 'error';
                        this.muestraBarra = true;
                    }
                    this.loading = false;
                })
                .catch(function(e) {
                    let result = e.toString().includes("401");
                    if (result) {
                        window.location.reload();
                    } else {
                        alert(e);
                    }
                });
        },
        close() {
            this.dialogFormAclaraciones = false;
            this.dialogDeleteMotivo = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },
        getMotivosAclaraciones() {
            let params = {
                Opcion: 9,
                token: localStorage.getItem("token"),
            }
            axios.post(`${this.$store.getters.getHost}/Nominas/ws-nominas.php`, params).then((response) => {
                if (response.data.status == 'OK') {
                    this.motivosAclaraciones = response.data.data;
                }
                this.cargando = false;
            })
        },

        editItem(item) {
            this.editedIndex = this.motivosAclaraciones.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.dialogFormAclaraciones = true;
        },

        deleteItem(item) {
            this.editedIndex = this.motivosAclaraciones.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialogDeleteMotivo = true
        },
    }
}