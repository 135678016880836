<template>
  <div>
    <v-dialog
      v-model="verDeralles"
      persistent
      transition="dialog-bottom-transition"
      max-width="650"
      scrollable
      content-class="fix-border-dialog"
    >
      <v-card class="rounded-lg">
        <v-toolbar color="primary" dark>
          <label class="overline font-weight-bold">
            Detalles de caja de ahorro, usuario [{{ idUser }}]
          </label>
          <v-spacer></v-spacer>
          <v-icon dark @click="$emit('cierraDialogDetalles')">mdi-close</v-icon>
        </v-toolbar>
        <v-card-text class="pt-5">
          <v-sheet class="rounded-lg pa-4 mb-3" color="blue-grey lighten-5">
            <div
              style="display: flex; justify-content: space-between; align-items: center;"
              class="py-2"
            >
              <label class="overline font-weight-bold">Préstamos</label>
              <v-btn class="rounded-pill" small depressed dark color="teal" @click="verDialogCreaPrestamo = true;">
                <v-icon left>mdi-plus</v-icon>
                Agregar prestamo
              </v-btn>
            </div>

            <div v-if="loadingPrestamos" class="text-center">
              <v-progress-circular
                :size="50"
                color="primary"
                indeterminate
              ></v-progress-circular>
            </div>

            <div
              v-else-if="!loadingPrestamos && prestamos.length === 0"
              class="text-center my-2"
            >
              Sin préstamos registrados.
            </div>

            <v-simple-table v-else dense color="transparent">
              <thead>
                <tr>
                  <th>Monto</th>
                  <th>Plazo</th>
                  <th class="text-center">Abono</th>
                  <th>Abonado</th>
                  <th>Restante</th>
                  <th>Estatus</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(p, index) in prestamos" :key="index">
                  <td>{{ formatMXN(p.monto) }}</td>
                  <td>{{ p.plazo }}</td>
                  <td class="text-center">
                    <v-btn
                      x-small
                      text
                      color="blue darken-1"
                      @click="
                        datosPrestamoAux = p;
                        muestraIngresaCantidad = true;
                      "
                    >
                      {{ formatMXN(p.abono_normal) }}
                      <v-icon x-small>mdi-pencil</v-icon>
                    </v-btn>
                  </td>
                  <td>{{ formatMXN(p.abonado) }}</td>
                  <td>{{ formatMXN(p.restante) }}</td>
                  <td>Activo</td>
                  <td>
                    <v-tooltip bottom color="blue darken-1">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          small
                          color="blue darken-1"
                          v-bind="attrs"
                          v-on="on"
                          @click="
                            idPrestamoAux = p.id;
                            verDialogAbonosPrestamo = true;
                          "
                          >mdi-information-slab-circle</v-icon
                        >
                      </template>
                      <span>Información de abonos a préstamo</span>
                    </v-tooltip>
                    <v-tooltip bottom color="pink darken-1">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          small
                          color="pink darken-1"
                          v-bind="attrs"
                          v-on="on"
                          @click="
                            resranteAux = p.restante;
                            datosPrestamoAux = p;
                            showConfirm = true;
                          "
                          >mdi-delete-circle</v-icon
                        >
                      </template>
                      <span>Remover préstamo</span>
                    </v-tooltip>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-sheet>

          <v-sheet class="rounded-lg pa-4 mb-3" color="blue-grey lighten-5">
            <label class="overline font-weight-bold">Ahorro</label>

            <div v-if="loadingAhorro" class="text-center">
              <v-progress-circular
                :size="50"
                color="primary"
                indeterminate
              ></v-progress-circular>
            </div>

            <v-simple-table v-else dense>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Monto</th>
                  <th>Saldo</th>
                  <th class="text-center"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(a, index) in ahorro" :key="index">
                  <td>{{ a.id }}</td>
                  <td>{{ formatMXN(a.monto) }}</td>
                  <td>{{ formatMXN(a.saldo) }}</td>
                  <td class="text-center">
                    <v-tooltip bottom color="blue darken-1">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          small
                          color="blue darken-1"
                          v-bind="attrs"
                          v-on="on"
                          @click="verDialogAbonosAhorro = true"
                          >mdi-information-slab-circle</v-icon
                        >
                      </template>
                      <span>Información de abonos al ahorro</span>
                    </v-tooltip>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-sheet>

          <v-sheet class="rounded-lg pa-4" color="blue-grey lighten-5">
            <label class="overline font-weight-bold">Movimientos en alu</label>
            <div style="display:flex; justify-content: end;">
              <v-btn
                class="rounded-pill"
                color="primary"
                small
                depressed
                :loading="loadingMovimientos"
                @click="verDialogHistorialMovimientos = true"
              >
                <v-icon left>mdi-table-eye</v-icon>
                ver historial
              </v-btn>
            </div>

            <div v-if="loadingMovimientos" class="text-center">
              <v-progress-circular
                :size="50"
                color="primary"
                indeterminate
              ></v-progress-circular>
            </div>

            <div
              v-else-if="!loadingMovimientos && movimientos.length == 0"
              class="text-center my-2"
            >
              Sin movimientos registrados.
            </div>

            <v-simple-table v-else dense color="transparent">
              <thead>
                <tr>
                  <th>Concepto</th>
                  <th>Proceso</th>
                  <th>Cantidad</th>
                  <th>Tipo</th>
                  <th>Estatus</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(m, index) in movimientos" :key="index">
                  <td>{{ m.CONCEPTO }}</td>
                  <td>{{ m.GRUPO_PAGO }}</td>
                  <td>{{ m.CANTIDAD }}</td>
                  <td>{{ m.TIPO_MOVIMIENTO }}</td>
                  <td>Activo</td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-sheet>
        </v-card-text>

        <SnackBar
          v-if="muestraBarra"
          :muestraBarra="muestraBarra"
          :texto="textoBarra"
          :color="colorBarra"
          @cierraBarra="muestraBarra = false"
        />
      </v-card>

      <IngresaCantidad
        :muestraIngresaCantidad="muestraIngresaCantidad"
        titulo="Ingresa el nuevo monto"
        @retornaCantidad="retornaCantidad"
      />
    </v-dialog>

    <CreaPrestamoPorUsuario
      v-if="verDialogCreaPrestamo"
      :verDialogCreaPrestamo="verDialogCreaPrestamo"
      :idUser="idUser"
      @cierraDialogCreaPrestamo="cierraDialogCreaPrestamo"
    />

    <AbonosPorPrestamoActivo
      v-if="verDialogAbonosPrestamo"
      :verDialogAbonosPrestamo="verDialogAbonosPrestamo"
      :idPrestamo="idPrestamoAux"
      @cierraDialogAbonosPrestamo="verDialogAbonosPrestamo = false"
    />

    <AbonosAhorroPorEmpleado
      v-if="verDialogAbonosAhorro"
      :verDialogAbonosAhorro="verDialogAbonosAhorro"
      :emplid="idUser"
      @cierraDialogAbonosAhorro="verDialogAbonosAhorro = false"
    />

    <MovimientosAluHistorialPorColaborador
      v-if="verDialogHistorialMovimientos"
      :verDialogHistorialMovimientos="verDialogHistorialMovimientos"
      :emplid="idUser"
      @cierraDialogHistorialMovimientos="verDialogHistorialMovimientos = false"
    />

    <DialogComentarioRechazo
      :commentReject="muestraDialogComentario"
      :isCandidate="false"
      @ocultaDialogComentario="ocultaDialogComentario"
    />

    <ConfirmDialogReclutamiento
      :showConfirm="showConfirm"
      :question="
        '¿Realmente deseas remover el préstamo de ' +
          idUser +
          ' con un restante de ' +
          formatMXN(resranteAux) +
          '?'
      "
      @cierraConfirm="cierraConfirm"
    />
  </div>
</template>

<script>
import axios from "axios";
import { deleteToken } from "../../../../../../../store/Funciones/funciones";
import AbonosPorPrestamoActivo from "./AbonosPorPrestamoActivo.vue";
import AbonosAhorroPorEmpleado from "./AbonosAhorroPorEmpleado.vue";
import IngresaCantidad from "./IngresaCantidad.vue";
import SnackBar from "../../../../../General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue";
import MovimientosAluHistorialPorColaborador from "./MovimientosAluHistorialPorColaborador.vue";
import DialogComentarioRechazo from "../../../../../AdmnVacantes/components/DialogComentarioRechazo.vue";
import ConfirmDialogReclutamiento from "../../../../../AdmnVacantes/components/ConfirmDialogReclutamiento.vue";
import CreaPrestamoPorUsuario from "./CreaPrestamoPorUsuario.vue";

export default {
  props: ["verDeralles", "idUser"],
  components: {
    AbonosPorPrestamoActivo,
    AbonosAhorroPorEmpleado,
    IngresaCantidad,
    SnackBar,
    MovimientosAluHistorialPorColaborador,
    DialogComentarioRechazo,
    ConfirmDialogReclutamiento,
    CreaPrestamoPorUsuario,
  },
  data() {
    return {
      prestamos: [],
      ahorro: [],
      movimientos: [],
      datosPrestamoAux: {},
      loadingPrestamos: true,
      loadingAhorro: true,
      loadingMovimientos: true,
      verDialogAbonosPrestamo: false,
      muestraIngresaCantidad: false,
      muestraBarra: false,
      verDialogAbonosAhorro: false,
      verDialogHistorialMovimientos: false,
      muestraDialogComentario: false,
      showConfirm: false,
      verDialogCreaPrestamo: false,
      colorBarra: "",
      textoBarra: "",
      idPrestamoAux: null,
      resranteAux: 0,
    };
  },
  async mounted() {
    this.prestamos = await this.getDatosPrestamos();
    this.ahorro = await this.getDatosAhorro();
    this.movimientos = await this.getDatosMovimientos();
  },
  methods: {
    showSnackBar(texto, color = "error") {
      this.textoBarra = texto;
      this.colorBarra = color;
      this.muestraBarra = true;
    },
    getDatosPrestamos() {
      const headers = {
        Authorization: localStorage.getItem("token"),
      };

      return new Promise((res) => {
        axios
          .get(
            `${this.$store.getters.getHostNode}/api/empleados/caja/prestamos/datos-prestamo/${this.idUser}`,
            {
              headers,
            }
          )
          .then((response) => {
            if (response.data.status == "EXPSESSION") {
              deleteToken();
            } else if (response.data.status == "OK") {
              res(response.data.data);
            } else {
              res([]);
            }
          })
          .finally(() => {
            this.loadingPrestamos = false;
          });
      });
    },
    getDatosAhorro() {
      const headers = {
        Authorization: localStorage.getItem("token"),
      };

      return new Promise((res) => {
        axios
          .get(
            `${this.$store.getters.getHostNode}/api/empleados/caja/ahorro/datos-ahorro/${this.idUser}`,
            {
              headers,
            }
          )
          .then((response) => {
            if (response.data.status == "EXPSESSION") {
              deleteToken();
            } else if (response.data.status == "OK") {
              res(response.data.data);
            } else {
              res([]);
            }
          })
          .finally(() => {
            this.loadingAhorro = false;
          });
      });
    },
    getDatosMovimientos() {
      const headers = {
        Authorization: localStorage.getItem("token"),
      };

      return new Promise((res) => {
        axios
          .get(
            `${this.$store.getters.getHostNode}/api/empleados/caja/movimientos/datos-movimientos/${this.idUser}`,
            {
              headers,
            }
          )
          .then((response) => {
            if (response.data.status == "EXPSESSION") {
              deleteToken();
            } else if (response.data.status == "OK") {
              res(response.data.data);
            } else {
              res([]);
            }
          })
          .finally(() => {
            this.loadingMovimientos = false;
          });
      });
    },
    formatMXN(cadena) {
      return cadena.toLocaleString("es-MX", {
        style: "currency",
        currency: "MXN",
      });
    },
    async retornaCantidad(respuesta) {
      this.muestraIngresaCantidad = false;
      if (respuesta !== null) {
        let response = await this.putAbonoPrestamo(respuesta);
        console.log(JSON.stringify(response, null, 2));
        if (response == 1) {
          this.showSnackBar("Editado con éxito.", "success");
          this.loadingPrestamos = true;
          this.prestamos = await this.getDatosPrestamos();
        } else {
          this.showSnackBar("Ocurrio un error, el registro no se editó.");
        }
      }
    },
    putAbonoPrestamo(nCantidad) {
      const params = {
        montoActual: this.datosPrestamoAux.abono_normal,
        montoNuevo: parseFloat(nCantidad),
        idPrestamo: this.datosPrestamoAux.id,
        emplid: this.$store.getters.getUser["Folio"],
      };
      const headers = {
        Authorization: localStorage.getItem("token"),
      };

      return new Promise((res) => {
        axios
          .put(
            `${this.$store.getters.getHostNode}/api/empleados/caja/prestamos/datos-prestamo`,
            params,
            {
              headers,
            }
          )
          .then((response) => {
            if (response.data.status == "EXPSESSION") {
              deleteToken();
            } else if (response.data.status == "OK") {
              res(response.data.data);
            } else {
              res([]);
            }
          })
          .finally(() => {
            this.loadingMovimientos = false;
          });
      });
    },
    async ocultaDialogComentario(comentario) {
      this.muestraDialogComentario = false;
      if (comentario !== null) {
        const response = await this.deleteLoanByUser(comentario);
        console.log(JSON.stringify(response, null, 2));
        if (response > 0) {
          this.showSnackBar(
            "Éxito. Se modificó el préstamo seleccionado.",
            "success"
          );
          this.loadingPrestamos = true;
          this.prestamos = await this.getDatosPrestamos();
        } else {
          this.showSnackBar(
            "Error. No se pudo editar el préstamo, intente más tarde."
          );
        }
      }
    },
    cierraConfirm(respuesta) {
      this.showConfirm = false;
      this.muestraDialogComentario = respuesta;
    },
    deleteLoanByUser(comentario) {
      const headers = {
        Authorization: localStorage.getItem("token"),
      };
      const params = {
        emplid: this.idUser,
        idPrestamo: this.datosPrestamoAux.id,
        userMod: this.$store.getters.getUser.Folio,
        monto: this.datosPrestamoAux.restante,
        comentario: comentario,
      };

      return new Promise((res) => {
        axios
          .put(
            `${this.$store.getters.getHostNode}/api/empleados/caja/caja/prestamos`,
            params,
            {
              headers,
            }
          )
          .then(async (response) => {
            if (response.data.status == "EXPSESSION") {
              deleteToken();
            } else if (response.data.status == "OK") {
              res(response.data.data);
            } else {
              res(response.data.data);
            }
          });
      });
    },
    async cierraDialogCreaPrestamo(response){
      this.verDialogCreaPrestamo = false;
      if(response){
        this.loadingPrestamos = true;
        this.prestamos = await this.getDatosPrestamos();
      }
    }
  },
};
</script>
