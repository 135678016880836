<template>
  <v-dialog v-model="muestraCalendario" max-width="370px">
    <v-card>
      <v-card-title>
        <span class="text-h5">Días seleccionados</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" sm="12">
              <v-date-picker v-model="fechas" elevation="2" disabled multiple></v-date-picker>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="$emit('cierraCalendario')">
          de acuerdo 
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "muestra-calendario-dias-seleccionados",
  data: () => ({
    // timeout:25200,
  }),
  props: ["muestraCalendario", "fechas"],
  computed: {},
};
</script>