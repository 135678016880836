<template>
  <v-card class="rounded-xl mt-5 elevation-0 pa-10" width="100%" color="blue-grey lighten-5">
    <div v-if="loadingAreas" class="center-item-in-div-column">
      <v-progress-circular :size="180" :width="12" color="purple" indeterminate></v-progress-circular>
      <div class="overline">cargando áreas...</div>
    </div>
    <div v-else>
      <div class="text-center mb-3 text-h5 text-uppercase blue-grey--text text--darken-2 font-weight-black">
        Selecciona un área
      </div>
      <div class="container-cards">
        <v-card v-for="(area, index) in areas" :key="index" :color="index % 2 === 0 ? 'primary' : '#a8a8a7'"
          :width="widthCards" height="100" class="rounded-xl pa-5" @click="
      loadingBtn = true;
    indexAux = index;
    areaAux = area.id;
    getAutorizadores();
    ">
          <v-sheet height="100%" color="transparent"
            class="center-item-in-div text-uppercase font-weight-bold white--text" :class="['xs', 'sm'].includes($vuetify.breakpoint.name)
        ? 'subtitle-1'
        : 'text-xs'
      ">
            <div v-if="loadingBtn && index == indexAux">
              <v-progress-circular :size="50" :width="8" color="white" indeterminate></v-progress-circular>
            </div>
            <div v-else>{{ area.Area }}</div>
          </v-sheet>
        </v-card>

        <!-- {{ autorizadores }} -->
      </div>
    </div>

    <v-dialog v-model="muestraAutorizadores" max-width="750" persistent>
      <v-card class="rounded-lg">
        <v-card-title class="primary white--text overline font-weight-bold">
          autorizadores.
          <v-spacer></v-spacer>
          <v-icon dark @click="muestraAutorizadores = false">mdi-close</v-icon>
        </v-card-title>
        <v-card-text>
          <v-data-table dense locale="es-MX" :headers="headers" :items="autorizadores"
            no-data-text="No se han registrado autorizadores en esta área." loading-text="Cargando información..."
            :loading="loadingAuth" :footer-props="{
      'items-per-page-text': 'Elementos por página',
      'pageText': '{0}-{1} de {2}',
    }">
            <template v-slot:top>
              <div style="display: flex; justify-content: end;" class="my-2">
                <v-btn class="rounded-lg" dark depressed small color="teal darken-1" @click="showFindNewMembers = true">
                  <v-icon left>mdi-account-plus</v-icon>
                  Añadir autorizador
                </v-btn>
              </div>
            </template>
            <template v-slot:item.actions="{ item }">
              <!-- {{ item }} -->
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn class="rounded-lg pa-0" depressed x-small color="pink darken-3" dark @click="
      autorizadorAux = item.id;
    showConfirm = true;
    " v-bind="attrs" v-on="on">
                    <v-icon small>mdi-minus</v-icon>
                  </v-btn>
                </template>
                <span>Eliminar</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>

      <BuscaColaboradoresActivos v-if="showFindNewMembers" :showFindNewMembers="showFindNewMembers"
        @returnColaborrador="validaColaborador" />

      <ConfirmDialog v-if="showConfirm" :showConfirm="showConfirm" question="¿Realmente deseas eliminar este registro?"
        @cierraConfirm="validaConfirm" />

      <SnackBarErrorIncidencias v-if="muestraBarra" :muestraBarra="muestraBarra" :color="colorBarra" :texto="textoBarra"
        @cierraBarra="muestraBarra = false" />
    </v-dialog>
  </v-card>
</template>

<script>
import axios from "axios";
import { deleteToken } from "../../../../../store/Funciones/funciones";
import BuscaColaboradoresActivos from "../../../../../components/BuscaColaboradoresActivos";
import ConfirmDialog from "../../../AdmnVacantes/components/ConfirmDialogReclutamiento";
import SnackBarErrorIncidencias from "../../../General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue";
export default {
  name: "pases-de-salida-creados",
  components: { BuscaColaboradoresActivos, ConfirmDialog, SnackBarErrorIncidencias },
  data() {
    return {
      areas: [],
      autorizadores: [],
      headers: [
        { text: "ID", value: "id", class: " font-weight-bold blue-grey--text" },
        {
          text: "EMPLID",
          value: "autorizador",
          class: " font-weight-bold blue-grey--text",
        },
        {
          text: "NOMBRE",
          value: "EMP_NOMEMP",
          class: " font-weight-bold blue-grey--text",
        },
        {
          text: "PUESTO",
          value: "puesto",
          class: " font-weight-bold blue-grey--text",
        },
        {
          text: "ACCIONES",
          align: "center",
          sortable: false,
          value: "actions",
          class: " font-weight-bold blue-grey--text",
        },
      ],
      indexAux: -1,
      autorizadorAux: null,
      areaAux: null,
      colorBarra: "",
      textoBarra: "",
      muestraBarra: false,
      muestraAutorizadores: false,
      loadingBtn: false,
      loadingAreas: true,
      loadingAuth: false,
      showFindNewMembers: false,
      loadingBtnSaveAuth: false,
      showConfirm: false,
    };

  },
  computed: {
    widthCards() {
      return this.$vuetify.breakpoint.name === "xs"
        ? "310px"
        : this.$vuetify.breakpoint.name === "sm"
          ? "220px"
          : "350px";
    },
  },
  async mounted() {
    this.areas = await this.getAreas();
  },
  methods: {
    openSnackBar(texto, color = "pink darken-3") {
      this.colorBarra = color;
      this.textoBarra = texto
      this.muestraBarra = true;
    },
    async getAreas() {
      let params = {
        Opcion: 19,
        token: localStorage.getItem("token"),
      };
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${this.$store.getters.getHost}/SeguridadPatrimonial/ws-pases-de-salida.php`,
            params
          )
          .then((response) => {
            if (response.data.status == "OK") {
              resolve(response.data.areas);
            } else if (response.data.status == "EXPSESSION") {
              deleteToken()
            }
          })
          .catch((error) => {
            reject([{ nombre: error }]);
          })
          .finally(() => {
            this.loadingAreas = false;
          });
      });
    },
    async getAutorizadores() {
      let params = {
        Opcion: 4,
        token: localStorage.getItem("token"),
        idArea: this.areaAux,
      };
      axios
        .post(
          `${this.$store.getters.getHost}/SeguridadPatrimonial/ws-pases-de-salida.php`,
          params
        )
        .then((response) => {
          if (response.data.status == "OK") {
            this.muestraAutorizadores = true;
            this.autorizadores = response.data.areas;
          }
        })
        .catch((error) => {
          this.openSnackBar(error);
        })
        .finally(() => {
          this.loadingBtn = false;
          this.loadingAuth = false;
        });
    },
    async deleteAuthorizer() {
      let params = {
        Opcion: 5,
        token: localStorage.getItem("token"),
        idAutorizador: this.autorizadorAux,
      };
      axios
        .post(
          `${this.$store.getters.getHost}/SeguridadPatrimonial/ws-pases-de-salida.php`,
          params
        )
        .then((response) => {
          if (response.data.status == "OK") {
            if (response.data.respuesta.status_tran == "OK") {
              this.openSnackBar(response.data.respuesta.message, "teal darken-1")
              this.loadingAuth = true;
              this.getAutorizadores();
            } else {
              this.openSnackBar(response.data.respuesta.message)
            }
          } else {
            this.openSnackBar("Ocurrió un error.")
          }
        })
        .catch((error) => {
          this.openSnackBar(error);
        })
        .finally(() => {
          this.loadingBtn = false;
        });
    },
    saveAuthorizer(emplid) {
      let params = {
        Opcion: 6,
        token: localStorage.getItem("token"),
        idAutorizador: emplid,
        idArea: this.areaAux,
      };
      axios
        .post(
          `${this.$store.getters.getHost}/SeguridadPatrimonial/ws-pases-de-salida.php`,
          params
        )
        .then((response) => {
          if (response.data.status == "OK") {
            if (response.data.respuesta.status_tran == "OK") {
              this.openSnackBar(response.data.respuesta.message, "teal darken-1")
              this.loadingAuth = true;
              this.getAutorizadores();
            } else {
              this.openSnackBar(response.data.respuesta.message)
            }
          } else {
            this.openSnackBar("Ocurrió un error.")
          }
        })
        .catch((error) => {
          this.openSnackBar(error);
        })
        .finally(() => {
          this.loadingBtnSaveAuth = false;
        });
    },
    getClass(estatus) {
      if (estatus === 0) {
        return "yellow accent-4 black--text";
      } else if (estatus === 1) {
        return "teal darken-1 white--text";
      } else if (estatus === 2) {
        return "pink darken-3 white--text";
      } else {
        return "grey";
      }
    },
    validaColaborador(user) {
      this.showFindNewMembers = false;
      if (user !== null) {
        console.log(user);
        if (this.objetoExiste(user)) {
          this.openSnackBar("El usuario ya es autorizador");
        } else {
          this.loadingBtnSaveAuth = true;
          this.saveAuthorizer(user.EMPLID)
        }
      }
    },
    objetoExiste(empleado) {
      return this.autorizadores.some((autorizador) =>
        this.sonObjetosIguales(empleado, autorizador)
      );
    },
    sonObjetosIguales(objetoRecibido, objetoAutorizador) {
      if (objetoRecibido.EMPLID == objetoAutorizador.autorizador) {
        return true;
      }

      return false;
    },
    validaConfirm(response) {
      this.showConfirm = false;
      if (response) {
        this.deleteAuthorizer()
      }
    },
  },
};
</script>

<style>
.container-cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  gap: 10px;
}

@media only screen and (max-width: 575.98px) {
  .container-cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(310px, 1fr));
    gap: 10px;
  }
}

/* Estilos para pantallas sm */
@media only screen and (min-width: 576px) and (max-width: 900px) {
  .container-cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
    gap: 10px;
  }
}
</style>
