var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"rounded-xl elevation-0 pa-3"},[_c('v-data-table',{attrs:{"dense":"","headers":_vm.headers,"search":_vm.search,"items":_vm.ofertas,"no-data-text":"No hay registros para este estatus","loading-text":"Cargando ofertas...","loading":_vm.loadingOffers,"show-expand":_vm.estatus == 2 ? true : false,"expanded":_vm.expanded},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticStyle:{"display":"flex","justify-content":"end"}},[_c('v-text-field',{staticClass:"rounded-xl",staticStyle:{"max-width":"350px"},attrs:{"outlined":"","dense":"","prepend-inner-icon":"mdi-table-search","placeholder":"Buscar en tabla..."},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-tooltip',{attrs:{"left":"","color":"blue lighten-4"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"small":"","fab":"","depressed":""},on:{"click":function($event){_vm.loadingOffers = true;
                _vm.getOfertas();}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-reload")])],1)]}}])},[_c('label',{staticClass:"text-uppercase font-weight-bold primary--text"},[_vm._v("Actualizar tabla")])])],1)]},proxy:true},{key:"expanded-item",fn:function(ref){
                var headers = ref.headers;
                var item = ref.item;
return [_c('td',{staticClass:"px-5 py-5 blue-grey lighten-5",attrs:{"colspan":headers.length}},[_c('v-card',{staticClass:"px-4 py-1 elevation-0 rounded-xl",staticStyle:{"border":"2px solid #000000"},attrs:{"width":"400"}},[_c('label',{staticClass:"overline"},[_vm._v(" Justificación del rechazo ")]),_c('p',[_vm._v(_vm._s(item.Comentarios))])])],1)]}},{key:"item.sueldoActual",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatMXN( isNaN(parseFloat(item.sueldoActual)) ? 0 : parseFloat(item.sueldoActual) ))+" ")]}},{key:"item.Salario",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatMXN( isNaN(parseFloat(item.Salario)) ? 0 : parseFloat(item.Salario) ))+" ")]}},{key:"item.incremento",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(!Number.isFinite(((item.Salario - item.sueldoActual) / item.sueldoActual) * 100) ? (100).toFixed(2) : (((item.Salario - item.sueldoActual) / item.sueldoActual) * 100).toFixed(2))+"% ")]}},{key:"item.autorizador",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(item.nomAutorizador)+" ")]}},{key:"item.acciones",fn:function(ref){
                var item = ref.item;
return [(_vm.estatus == 0)?_c('v-tooltip',{attrs:{"left":"","color":"blue lighten-4"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"blue darken-4"},on:{"click":function($event){return _vm.getDataToSendMail(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-email-fast-outline ")])]}}],null,true)},[_c('label',{staticClass:"text-uppercase font-weight-bold primary--text"},[_vm._v("Notificar")])]):(_vm.estatus == 1)?_c('v-tooltip',{attrs:{"left":"","color":"green lighten-4"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"green darken-4"},on:{"click":function($event){_vm.itemAux = item;
              _vm.verVistaPrevia = true;}}},'v-icon',attrs,false),on),[_vm._v(" mdi-file-document-plus-outline ")])]}}],null,true)},[_c('label',{staticClass:"text-uppercase font-weight-bold green--text text--darken-4"},[_vm._v("Crear oferta")])]):(_vm.estatus == 2)?_c('v-tooltip',{attrs:{"left":"","color":"pink lighten-4"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('router-link',{attrs:{"to":'/Nominas/Compensaciones/ofertasEconomicas/' + item.id}},[_c('v-icon',_vm._g(_vm._b({attrs:{"color":"pink darken-4"}},'v-icon',attrs,false),on),[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('label',{staticClass:"text-uppercase font-weight-bold pink--text text--darken-4"},[_vm._v("Modificar oferta")])]):_vm._e()]}}])}),(_vm.showSendMail)?_c('PersonaDialogSendMail',{attrs:{"showSendMail":_vm.showSendMail,"defaultMsg":_vm.msgMail,"receptor":_vm.receptor,"title":"Oferta económica pendiente de autorización"},on:{"hideSendMail":_vm.hideSendMail}}):_vm._e(),(_vm.muestraBarra)?_c('SnackBar',{attrs:{"muestraBarra":_vm.muestraBarra,"texto":_vm.textoBarra,"color":_vm.colorBarra},on:{"cierraBarra":function($event){_vm.muestraBarra = false}}}):_vm._e(),(_vm.verVistaPrevia)?_c('DialogVistaPreviaDocumento',{attrs:{"verVistaPrevia":_vm.verVistaPrevia,"empleado":_vm.itemAux},on:{"ocultaVistaPrevia":function($event){_vm.verVistaPrevia = false}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }