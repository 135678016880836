<template>
  <v-row justify="center">
    <v-dialog v-model="muestraModal" scrollable max-width="900px">
      <v-card>
        <v-card-title class="primary white--text overline font-weight-bold"
          >Checadas de comedor.

          <v-spacer></v-spacer>
          <v-icon dark @click="$emit('cierraModal')">mdi-close</v-icon>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 500px;">
          <!-- {{ datos }} -->
          <v-simple-table>
            <thead>
              <tr>
                <th class="text-center blue-grey--text text--darken-2 font-weight-bold">EMPLID</th>
                <th class="text-center blue-grey--text text--darken-2 font-weight-bold">FECHA</th>
                <th class="text-center blue-grey--text text--darken-2 font-weight-bold">ESTATUS</th>
                <th class="text-center blue-grey--text text--darken-2 font-weight-bold">NOMBRE</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in datos" :key="item.idAutoEvents">
                <td>{{ item.idAutoEvents }}</td>
                <td>{{ fixDate(item.FechaEvento.date.substring(0, 10)) + ' ' + item.FechaEvento.date.substring(11, 16)}}</td>
                <td>{{ item.Estatus }}</td>
                <td>{{ item.NameAxtrax }}</td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-card-text>
        <!-- <v-divider></v-divider> -->
        <v-card-actions> </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { formatDate } from '../../../../../../store/Funciones/funciones';
export default {
  props: ["muestraModal", "datos"],
  data() {
    return {};
  },
  methods:{
    fixDate(date) {
            return formatDate(date)
        },
  }
};
</script>
