var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"solicitud-capacitaciones"},[_c('v-container',{attrs:{"fluid":""}},[_c('v-card',[_c('v-card-title',[_c('v-spacer')],1),_c('v-divider'),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","xl":"12","lg":"12","md":"12","sm":"12","xs":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.solicitudestHeaders,"items":_vm.solicitudesCapacitaciones,"single-expand":_vm.singleExpand,"expanded":_vm.expanded,"item-key":"id","show-expand":"","loadign":_vm.cargando,"mobile-breakpoint":"0","footer-props":{  'items-per-page-text': 'Elementos por página',},"no-data-text":"No ha registrado una solicitud"},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"primary"}},[_c('v-toolbar-title',{staticClass:"white--text"},[_vm._v("Solicitudes registradas")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"teal darken-1","dark":""},on:{"click":function($event){_vm.dialogForm = true}}},[_vm._v(" Nueva solicitud ")])],1)]},proxy:true},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-list',_vm._l((item.involucrados),function(citem){return _c('v-list-item',{key:citem.emplid},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"pink"}},[_vm._v(" mdi-account ")])],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(citem.emplid)}}),_c('v-list-item-subtitle',[_vm._v(_vm._s(citem.nombre))])],1),_c('v-list-item-avatar',[_c('v-img',{attrs:{"src":citem.avatar}})],1)],1)}),1)],1)]}},{key:"item.Nombre",fn:function(ref){
var item = ref.item;
return [_c('div',{attrs:{"align":"left"}},[_vm._v(_vm._s(item.Nombre))])]}},{key:"item.solicitante",fn:function(ref){
var item = ref.item;
return [_c('div',{attrs:{"align":"left"}},[_vm._v(_vm._s(item.solicitante))])]}},{key:"item.estatus",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"display":"grid","place-content":"center"}},[_c('v-chip-group',{attrs:{"column":""}},[(item.estatus == 1)?_c('v-btn',{staticClass:"mb-2",attrs:{"x-small":"","rounded":"","elevation":"1","color":"red darken-1","dark":""}},[_vm._v(" Abierta ")]):(item.estatus == 2)?_c('v-btn',{staticClass:"mb-2",attrs:{"x-small":"","rounded":"","elevation":"1","color":"yellow darken-1"}},[_vm._v(" En proceso ")]):_c('v-btn',{staticClass:"mb-2",attrs:{"x-small":"","rounded":"","elevation":"1","color":"teal darken-1","dark":""}},[_vm._v(" Cerrada ")])],1)],1)]}}],null,true)})],1)],1),_c('v-dialog',{attrs:{"fullscreen":"","hide-overlay":"","transition":"dialog-bottom-transition","persistent":""},model:{value:(_vm.dialogForm),callback:function ($$v) {_vm.dialogForm=$$v},expression:"dialogForm"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-subtitle-2 primary white--text"},[_vm._v(" Nueva solicitud de certificación "),_c('v-spacer'),_c('v-icon',{attrs:{"small":"","dark":""},on:{"click":function($event){_vm.dialogForm = false}}},[_vm._v("mdi-close")])],1),_c('v-card-text',[_c('v-card',{staticClass:"pa-5 pb-0"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","xl":"6","lg":"6","md":"6","sm":"6","xs":"12"}},[_c('v-card',{staticClass:"rounded-lg"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headersSubordinados,"items":_vm.subordinados,"item-key":"nombre","mobile-breakpoint":"0","show-select":"","footer-props":{  'items-per-page-text': 'Elementos por página',},"no-data-text":"No hay registros","dense":"","loading":_vm.cargando},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"primary"}},[_c('v-toolbar-title',{staticClass:"white--text"},[_vm._v("Mis subordinados")])],1)]},proxy:true},{key:"item.nombre",fn:function(ref){
var item = ref.item;
return [_c('div',{attrs:{"align":"left"}},[_vm._v(_vm._s(item.nombre))])]}}]),model:{value:(_vm.colaboradorSelected),callback:function ($$v) {_vm.colaboradorSelected=$$v},expression:"colaboradorSelected"}})],1)],1),_c('v-col',{attrs:{"cols":"12","xl":"6","lg":"6","md":"6","sm":"6","xs":"12"}},[_c('v-card',{staticClass:"rounded-lg"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headersCapacitaciones,"search":_vm.buscar,"items":_vm.capacitaciones,"mobile-breakpoint":"0","show-select":"","single-select":"","footer-props":{  'items-per-page-text': 'Elementos por página',},"dense":"","items-per-page":5,"no-data-text":"No hay registros","loading":_vm.cargandoCapacitacionesTabla},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"primary"}},[_c('v-toolbar-title',{staticClass:"white--text"},[_vm._v("Certificaciones")]),_c('v-text-field',{staticClass:"mt-7 rounded-lg",attrs:{"label":_vm.$t('DH360.findTextBox'),"dense":"","clearable":"","flat":"","solo-inverted":"","dark":"","prepend-inner-icon":"mdi-magnify"},model:{value:(_vm.buscar),callback:function ($$v) {_vm.buscar=$$v},expression:"buscar"}})],1)]},proxy:true},{key:"item.nombre",fn:function(ref){
var item = ref.item;
return [_c('div',{attrs:{"align":"left"}},[_vm._v(_vm._s(item.nombre))])]}},{key:"item.estatus",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"display":"grid","place-content":"center"}},[_c('v-chip-group',{attrs:{"column":""}},[(item.estatus == false)?_c('v-btn',{staticClass:"mb-2",attrs:{"x-small":"","rounded":"","elevation":"1","color":"red darken-1","dark":""}},[_vm._v(" Inhabilitado ")]):_c('v-btn',{staticClass:"mb-2",attrs:{"x-small":"","rounded":"","elevation":"1","color":"teal darken-1","dark":""}},[_vm._v(" Habilitado ")])],1)],1)]}}]),model:{value:(_vm.capacitacionSelected),callback:function ($$v) {_vm.capacitacionSelected=$$v},expression:"capacitacionSelected"}})],1)],1),_c('v-col',{attrs:{"cols":"12","xl":"12","lg":"12","md":"12","sm":"12","xs":"12"}},[_c('v-textarea',{attrs:{"auto-grow":"","filled":"","color":"deep-purple","label":"¿Te gustaria escribir un comentario?","rows":"2","counter":"","maxlength":"200"},model:{value:(_vm.comentarioSolicitud),callback:function ($$v) {_vm.comentarioSolicitud=$$v},expression:"comentarioSolicitud"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"teal darken-1","dark":"","loading":_vm.loading},on:{"click":_vm.guardar}},[_vm._v(" Guardar ")])],1)],1)],1)],1),_c('v-card-actions',{staticClass:"d-flex justify-end"})],1),_c('v-snackbar',{attrs:{"color":_vm.colorBarra,"rounded":"lg","timeout":_vm.timeout,"elevation":"24"},model:{value:(_vm.muestraBarra),callback:function ($$v) {_vm.muestraBarra=$$v},expression:"muestraBarra"}},[_c('div',{staticClass:"caption font-weight-bold mb-2"},[_vm._v(_vm._s(_vm.textoBarra))])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }