
export default {
  name: 'mi-curriculum',
  components: {},
  props: [],
  data() {
    return {
      tab: null,
      cargando: false,
      buscar: '',
      headers: [
        { text: 'ID', value: 'id', align: 'center', sortable: true },
        { text: 'Curso', value: 'curso', align: 'center', sortable: true },
        { text: 'Periodo', value: 'periodo', align: 'center', sortable: true },
        { text: 'Fecha Curso', value: 'fechaCurso', align: 'center', sortable: true },
        { text: 'Acciones', value: 'actions', align: 'center', sortable: true },
      ],
      cursos: [
        { id: '1', curso: 'Scrum Master - 2023', periodo: '2022', fechaCurso: '2022-ENE-10 - 2022-JUL-10' },
        { id: '2', curso: 'Contenedores AWS', periodo: '2023', fechaCurso: '2023-OCT-06 - 2023-OCT-10' },
      ],
    }
  },
  computed: {

  },
  mounted() {

  },
  methods: {

  }
}


