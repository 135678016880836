<template>
  <v-dialog v-model="dialog" persistent fullscreen>
    <v-card>
      <v-card-title class="text-subtitle-2 primary white--text">
        Encuestas de evaluación
        <v-spacer></v-spacer>
        <v-icon small dark @click="$emit('cierraDialgoConsultaEncuestas', {result: 'Cancelar'})">mdi-close</v-icon>
      </v-card-title>
      <v-card-text class="mt-5">
        <v-row>
          <v-col cols="12" xl="8" lg="8" md="8" sm="12" xs="12">
            <v-hover v-slot="{ hover }">
              <v-card
                class="mx-auto"
                color="grey lighten-4"
                max-width="600"
              >
                <div>
                  <v-card-title class="text-subtitle-2 primary white--text">
                    Información del evaluado 
                    <v-spacer></v-spacer>
                  </v-card-title>
                  <v-expand-transition>
                    <div
                      v-if="hover"
                      class="d-flex transition-fast-in-fast-out blue-grey lighten-5 v-card--reveal text-h2 white--text"
                      style="height: 100%;"
                    >
                      <v-card-text>
                        <v-row>
                          <v-col class="d-flex align-center" align="left" xl="4" lg="4" md="4" sm="12" xs="12">
                            <v-avatar size="150" tile>
                            <img
                                  v-if="evaluado['foto']"
                                  alt=""
                                  :src="'https://apidh.urrea.com//ArchivosCargadosEnPortal2/FotosPerfilUsuarios/' + evaluado['foto']"
                                  aling="absmiddle"
                                  width="200px"
                              />
                              <img
                                  v-else
                                  alt=""
                                  :src="'https://community.f5.com/legacyfs/online/avatars/0051T000008uOPOQA2.png'"
                                  aling="absmiddle"
                                  width="200px"
                              />
                            </v-avatar>
                          </v-col>
                          <v-col class="d-flex align-center black--text" align="left" xl="8" lg="8" md="8" sm="12" xs="12">
                            <v-card-text class="pt-0">
                              <div align="center">
                                  <img
                                  alt=""
                                  :src="require('@/assets/img/grupo-urrea-logo-bn.png')"
                                  aling="absmiddle"
                                  width="200px"
                                  />
                              </div>
                              <b>EMPLID</b> {{ evaluado.emplid }} <br />
                              <b>Nombre</b> {{ evaluado.nombre }} <br />
                              <b>Puesto</b> {{ evaluado.DESCR }} <br />
                              <b>Departamento</b> {{ evaluado.descr1 }}
                            </v-card-text>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </div>
                  </v-expand-transition>
                  <v-card-text class="pt-6" style="position: relative;">
                    <v-btn absolute color="primary" class="white--text" fab large right top>
                      <v-icon>mdi-account</v-icon>
                    </v-btn>
                  </v-card-text>
                </div>
              </v-card>
            </v-hover>
          </v-col>
          <v-col cols="12" xl="4" lg="4" md="4" sm="12" xs="12">
            <v-card class="rounded-lg">
              <v-alert v-if="evaluado.estatus == 4" icon="mdi-card-account-details-star" prominent color="blue darken-1" dark dense elevation="1">
                Encuesta terminada
              </v-alert>
              <v-alert v-else-if="evaluado.estatus == 5" icon="mdi-account-check" prominent color="indigo darken-4" dark dense elevation="1">
                Terminada
              </v-alert>
              <v-alert v-else-if="evaluado.estatus == 6" icon="mdi-account-star-outline" prominent color="pink" dark dense elevation="1">
                Planta asignada
              </v-alert>
            </v-card>
          </v-col>
        </v-row>
        
        <v-card class="rounded-lg mt-5" v-if="loadingPreguntas">
          <v-row class="fill-height" align-content="center" justify="center">
            <v-col class="text-subtitle-1 text-center" cols="12">
              Cargando Evaluación
            </v-col>
            <v-col cols="8">
              <v-progress-linear color="deep-purple accent-4" indeterminate rounded height="8"></v-progress-linear>
            </v-col>
          </v-row>
        </v-card>
        <v-card class="rounded-lg mt-5" v-else>
          <v-stepper v-model="step">
            <v-stepper-header>
              <div v-for="cualidad in cualidades" :key="cualidad.id">
                <v-stepper-step :complete="step > cualidad.id" :step="cualidad.id" editable>
                  <div class="caption">{{ cualidad.nombre }}</div>
                </v-stepper-step>
              </div>
            </v-stepper-header>

            <v-stepper-items>
              <v-stepper-content step="1">
                <lista-encuestas :idEvaluacion="evaluado['id']" :infoEvaluado="evaluado" @cerrarEvaluacion="cierraEvaluacion"></lista-encuestas>
              </v-stepper-content>

              <v-stepper-content step="2">
                <lista-encuestas :idEvaluacion="evaluado['id']" :infoEvaluado="evaluado" @cerrarEvaluacion="cierraEvaluacion"></lista-encuestas>
              </v-stepper-content>

              <v-stepper-content step="3">
                <lista-encuestas :idEvaluacion="evaluado['id']" :infoEvaluado="evaluado" @cerrarEvaluacion="cierraEvaluacion"></lista-encuestas>
              </v-stepper-content>

              <v-stepper-content step="4">
                <lista-encuestas :idEvaluacion="evaluado['id']" :infoEvaluado="evaluado" @cerrarEvaluacion="cierraEvaluacion"></lista-encuestas>
              </v-stepper-content>

              <v-stepper-content step="5">
                <lista-encuestas :idEvaluacion="evaluado['id']" :infoEvaluado="evaluado" @cerrarEvaluacion="cierraEvaluacion"></lista-encuestas>
              </v-stepper-content>

            </v-stepper-items>
          </v-stepper>
        </v-card>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>



<script>
import axios from 'axios'
import { deleteToken } from '../../../../../../store/Funciones/funciones'
import ListaEncuestas from './ListaEncuestas.vue';
export default {
  name: "consulta-encuestas-admin",
  components: { ListaEncuestas },
  props: ["dialog", "evaluado"],
  data: () => ({
    loadingPreguntas:true,
    cualidades:[],
    step:1,
  }),
  created() {},
  mounted() {
    this.getCategorias();
  },

  methods: {
    getCategorias() {
      let params = {
        Opcion: 5,
        idEncuesta:this.evaluado["id"],
        token: localStorage.getItem('token'),
      }
      axios.post(`${this.$store.getters.getHost}/General/PersonalTemporal/ws-personal-temporal.php`, params).then((response) => {
        this.loadingPreguntas = false;
        if (response.data.status == "OK") {
          this.cualidades = response.data.categorias;
        } else if (response.data.status == "VACIO") {
          console.log("Vacio");
        } else if (response.data.status == "EXPRESSION") {
          deleteToken();
        }
      })
    },

    cierraEvaluacion(step) {
      if (step.estatus == "OK"){
        this.$emit("cierraDialgoConsultaEncuestas", {result: "OK"})
      } else if (step.estatus == "HabilitarBtn"){
        this.$emit("cierraDialgoConsultaEncuestas", {result: "HabilitarBtn"})
      } else if (step.estatus == "calculados"){
        this.$emit("cierraDialgoConsultaEncuestas", {result: "calculado"})
      }
    },
  },
};
</script>