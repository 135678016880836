export default {
    name: 'admon-rankings',
    components: {},
    props: [],
    data() {
        return {

        }
    },
    computed: {

    },
    mounted() {},
    methods: {
        parallax() {
            const scrollY = window.pageYOffset
            const parallaxEl = document.querySelector('.v-parallax__content')
            parallaxEl.style.transform = `translate3d(0, ${scrollY * 0.4}px, 0)`
        },
    },
}