import ValidarArchivos from '../RepseUsuarios/components/ValidarArchivos.vue'
export default {
  name: 'validacion-de-documentos-repse',
  components: {ValidarArchivos},
  props: [],
  data () {
    return {

    }
  },
  computed: {

  },
  mounted () {

  },
  methods: {

  }
}


