import ListaPersonalTemporal from "./Components/ListaPersonalTemporal.vue"
export default {
  name: 'personal-temporal',
  components: { ListaPersonalTemporal },
  props: [],
  data() {
    return {
      ruta: this.$route.path,
    }
  },
  computed: {

  },
  mounted() {
    
  },
  // watch: {
  //   '$route'(to, from) {
  //     // Este watcher se activará cuando cambies de ruta
  //     const desde = to.path.split("/");
  //     const hacia = from.path.split("/");
  //     console.log(desde[2]);
  //     console.log(hacia[2]);
  //     // console.log(to);
  //     // console.log(from);
  //   }
  // },
  methods: {
    actualizarRuta(nuevaRuta) {
      // Método para actualizar la ruta en el componente padre
      this.ruta = nuevaRuta;
    }
  }
}


