<template>
  <v-card class="elevation-0 pa-4">
    <v-data-table dense :headers="headers" :search="search" :items="items" no-data-text="No hay registros de salarios"
      :footer-props="{
        'items-per-page-text': 'Elementos por página',
      }" :header-props="{ 'short-by-text': 'Ordenar por', }" loading-text="Cargando contenido..." :loading="loading">
      <template v-slot:top>
        <div style="display: flex; justify-content: space-between;">
          <v-text-field v-model="search" outlined dense class="rounded-xl" style="max-width: 350px;"
            prepend-inner-icon="mdi-table-search" placeholder="Buscar en tabla..."></v-text-field>
          <div>
            <v-tooltip bottom color="blue lighten-4">
              <template v-slot:activator="{ on, attrs }">
                <v-btn outlined small fab depressed class="ml-2" v-bind="attrs" v-on="on" color="primary"
                  @click="modificarSueldos()">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </template>
              <label class="text-uppercase font-weight-bold primary--text">Nuevo Puesto</label>
            </v-tooltip>
            <vue-json-to-csv :json-data="itemsExcel" :labels="headers_report_to_export" :csv-title="title" class="ma-0">
              <v-tooltip bottom color="blue lighten-4">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn outlined depressed class="rounded-pill ml-2" dark color="green darken-2" v-bind="attrs"
                    v-on="on" @click="exportToCsv">
                    <v-icon fab>mdi-microsoft-excel</v-icon>
                  </v-btn>
                </template>
                <span class="text-uppercase font-weight-bold primary--text">Descarga la info mostrada en
                  la tabla</span>
              </v-tooltip>
            </vue-json-to-csv>
            <v-tooltip bottom color="blue lighten-4">
              <template v-slot:activator="{ on, attrs }">
                <v-btn outlined small fab depressed class="ml-2" v-bind="attrs" v-on="on" color="primary"
                  @click="getPuestos()">
                  <v-icon>mdi-reload</v-icon>
                </v-btn>
              </template>
              <label class="text-uppercase font-weight-bold primary--text">Actualizar tabla</label>
            </v-tooltip>
          </div>
        </div>
      </template>
      <template v-slot:item.actions="{ item }" class="">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon small color="cyan darken-2" v-bind="attrs" v-on="on"
              @click="modificarSueldos(item)">mdi-pencil</v-icon>
          </template>
          <span>Editar</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon small color="cyan darken-2" v-bind="attrs" v-on="on" class="mx-2"
              @click="deleteOpen(item)">mdi-delete</v-icon>
          </template>
          <span>Eliminar</span>
        </v-tooltip>
      </template>
    </v-data-table>

    <v-dialog v-model="dialog" max-width="90%" scrollable persistent content-class="fix-border-dialog">
      <v-card class="rounded-xl">
        <v-card-title class="primary rounded-xl rounded-b-0 white--text subtitle-1 font-weight-bold">
          <span class="headline">{{ editedItem.id ? 'Editar Puesto' : 'Nuevo Puesto' }}</span>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeDialog()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-form ref="form" v-model="formValid">
          <v-card-text class="pt-6">
            <v-row class="mx-0 px-0">
              <v-col class="px-2 py-0 my-0" :cols="getCols(4)">
                <v-text-field v-model="editedItem.idProductivity" label="Id del puesto Productivity"
                  :rules="[v => !!v || 'El id es obligatorio']" required outlined dense rounded></v-text-field>
              </v-col>

              <v-col class="px-2 py-0 my-0" :cols="getCols(8)">
                <v-text-field v-model="editedItem.nomPuesto" label="Nombre del Puesto"
                  :rules="[v => !!v || 'El nombre es obligatorio']" required outlined dense rounded></v-text-field>
              </v-col>

              <v-col class="px-2 py-0 my-0" :cols="getCols(12)">
                <v-textarea v-model="editedItem.descPuesto" label="Descripción del Puesto"
                  :rules="[v => !!v || 'La descripción es obligatoria']" required outlined rounded
                  auto-grow></v-textarea>
              </v-col>

              <v-col class="px-2 py-0 my-0" :cols="getCols(6)">
                <v-text-field v-model="editedItem.experiencia" label="Experiencia (en años)" type="number"
                  :rules="[v => !!v || 'Los años de experiencia son obligatorios']" required outlined dense
                  rounded></v-text-field>
              </v-col>

              <v-col class="px-2 py-0 my-0" :cols="getCols(6)">
                <v-text-field v-model="editedItem.idUrrea" label="Jobcode Urrea" type="text"
                  :rules="[v => !!v || 'El Jobcode es obligatorio']" required outlined dense rounded></v-text-field>
              </v-col>

              <v-col class="px-2 py-0 my-0" :cols="getCols(4)">
                <v-text-field v-model="editedItem.salMin" prefix="$" label="Salario Mínimo" type="number"
                  :rules="[v => !!v || 'El salario mínimo es obligatorio']" required outlined dense
                  rounded></v-text-field>
              </v-col>

              <v-col class="px-2 py-0 my-0" :cols="getCols(4)">
                <v-text-field v-model="editedItem.salProm" prefix="$" label="Salario Promedio" type="number"
                  :rules="[v => !!v || 'El salario promedio es obligatorio']" required outlined dense
                  rounded></v-text-field>
              </v-col>

              <v-col class="px-2 py-0 my-0" :cols="getCols(4)">
                <v-text-field v-model="editedItem.salMax" prefix="$" label="Salario Máximo" type="number"
                  :rules="[v => !!v || 'El salario máximo es obligatorio']" required outlined dense
                  rounded></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn outlined rounded color="success" :loading="loadingsave" @click="saveSueldo()">Guardar</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDelete" max-width="350">
      <v-card class="rounded-lg">
        <v-card-text class="pt-5">
          <div style="display: flex; justify-content: center;">
            <v-img class="text-center" :src="require('@/assets/icons/advertencia.png')" max-width="80"></v-img>
          </div>
          <p class="subtitle-2 text-uppercase font-weight-bold text-center mb-3">¡Advertencia!</p>
          <p class="subtitle-2 text-uppercase  text-center mb-3">
            ¿Realmente deseas eliminar el puesto {{ namePuesto }}?
          </p>
        </v-card-text>
        <v-card-actions style="display: flex; justify-content: space-around;">
          <v-btn depressed class="rounded-lg" style="width:45%" dark color="pink darken-3"
            @click="dialogDelete = false">cancelar</v-btn>
          <v-btn depressed class="rounded-lg" style="width:45%" dark color="teal darken-1" @click="deleteItem"
            :loading="loadingdelete">confirmar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar.visible" :color="snackbar.color">
      {{ snackbar.text }}
      <v-btn color="white" text @click="snackbar.visible = false">Cerrar</v-btn>
    </v-snackbar>
  </v-card>
</template>

<script>
import axios from "axios";
import VueJsonToCsv from 'vue-json-to-csv';

export default {
  components: { VueJsonToCsv },
  data() {
    return {
      headers: [
        { text: 'Puesto', value: 'nomPuesto', class: "text-uppercase font-weight-bold blue-grey--text text--darken-2", },
        { text: 'id Productivity', value: 'idProductivity', class: "text-uppercase font-weight-bold blue-grey--text text--darken-2", },
        { text: 'Jobcode', value: 'idUrrea', class: "text-uppercase font-weight-bold blue-grey--text text--darken-2", },
        { text: "Acciones", value: "actions", sortable: false, align: "center", class: "text-uppercase font-weight-bold blue-grey--text text--darken-2", },
      ],
      items: [],
      loading: false,
      search: "",
      //snackbar
      snackbar: {
        visible: false,
        text: '',
        color: ''
      },
      //Excel
      itemsExcel: [],
      title: 'Reporte de Puestos Productivity',
      headers_report_to_export: {
        idProductivity: { title: "ID de Productivity" },
        idUrrea: { title: "ID Urrea" },
        nomPuesto: { title: "Nombre del Puesto" },
        descPuesto: { title: "Descripción del Puesto" },
        experiencia: { title: "Experiencia" },
        salMax: { title: "Salario Máximo" },
        salMin: { title: "Salario Mínimo" },
        salProm: { title: "Salario Promedio" },
      },
      //
      dialog: false,
      formValid: false,
      loadingsave: false,
      editedItem: {
        id: null,
        idProductivity: '',
        idUrrea: "0",
        experiencia: 0,
        nomPuesto: '',
        descPuesto: '',
        salMin: '',
        salProm: '',
        salMax: ''
      },
      defaultItem: {
        id: null,
        idProductivity: '',
        idUrrea: "0",
        experiencia: 0,
        nomPuesto: '',
        descPuesto: '',
        salMin: '',
        salProm: '',
        salMax: ''
      },
      //
      dialogDelete: false,
      namePuesto: '',
      idPuesto: null,
      loadingdelete: false,
    };
  },
  mounted() {
    this.getPuestos();
  },
  methods: {
    getPuestos() {
      this.loading = true;
      axios.get(`${this.$store.getters.getHostNode}/api/getPuestosProductivity`, {
        headers: {
          authorization: `${localStorage.getItem("token")}`,
        },
      })
        .then(response => {
          if (response.data.status) {
            this.items = response.data.data;
            this.itemsExcel = response.data.data;
          } else {
            this.items = [];
            this.itemsExcel = [];
          }
        })
        .catch(error => {
          this.items = [];
          console.error('Error al obtener info:', error);
          this.snackbar = {
            visible: true,
            text: 'Error de servidor: ' + error.message,
            color: 'error'
          };
        })
        .finally(() => {
          this.loading = false;
        });
    },
    modificarSueldos(item) {
      if (item) {
        this.editedItem = Object.assign({}, item);
      } else {
        this.editedItem = Object.assign({}, this.defaultItem);
      }
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
      this.$refs.form.resetValidation(); // Resetear validaciones
    },
    saveSueldo() {
      this.loadingsave = true;

      if (this.$refs.form.validate()) {
        const { idProductivity, idUrrea, experiencia, nomPuesto, descPuesto, salMin, salProm, salMax, ...rest } = this.editedItem;

        const dataToSend = {
          ...rest,
          idProductivity,
          idUrrea,
          experiencia,
          nomPuesto,
          descPuesto,
          salMin,
          salProm,
          salMax,
        };

        const url = this.editedItem.id
          ? `${this.$store.getters.getHostNode}/api/updatePuestoProductivity/${this.editedItem.id}`  // Update
          : `${this.$store.getters.getHostNode}/api/addPuestoProductivity`;  // Nuevo sueldo

        axios({
          method: this.editedItem.id ? 'put' : 'post',
          url,
          data: dataToSend,
          headers: {
            authorization: `${localStorage.getItem("token")}`,
          },
        })
          .then(response => {
            if (response.data.status) {
              this.snackbar = {
                visible: true,
                text: 'Solicitud procesada correctamente',
                color: 'success'
              };
            } else {
              this.snackbar = {
                visible: true,
                text: 'Error al procesar la solicitud',
                color: 'error'
              };
            }
          })
          .catch(error => {
            console.error('Error al guardar:', error);
            this.snackbar = {
              visible: true,
              text: 'Error de servidor: ' + error.message,
              color: 'error'
            };
          })
          .finally(() => {
            this.loadingsave = false;
            this.closeDialog();
            this.getPuestos();
          });
      } else {
        this.loadingsave = false;
      }
    },
    deleteOpen(item) {
      this.dialogDelete = true;
      this.namePuesto = item.nomPuesto;
      this.idPuesto = item.id;
    },
    deleteItem() {
      this.loadingdelete = true;
      axios.delete(`${this.$store.getters.getHostNode}/api/deletePuestoProductivity/${this.idPuesto}`, {
        headers: {
          authorization: `${localStorage.getItem("token")}`,
        },
      })
        .then(response => {
          if (response.data.status) {
            this.dialogDelete = false;
            this.snackbar = {
              visible: true,
              text: 'Eliminado correctamente el puesto',
              color: 'success'
            };
          } else {
            this.snackbar = {
              visible: true,
              text: 'Error al procesar la eliminacion',
              color: 'error'
            };
          }
        })
        .catch(error => {
          console.error('Error al eliminar:', error);
          this.snackbar = {
            visible: true,
            text: 'Error de servidor: ' + error.message,
            color: 'error'
          };
        }).finally(() => {
          this.getPuestos();
          this.loadingdelete = false;
        });
    },
    exportToCsv() {
      // Exportar datos a CSV
      if (this.itemsExcel.length > 0) {
        this.$refs.exporter.exportCSV();
      } else {
        alert('No hay datos para exportar');
      }
    },
  },
  computed: {
    getCols() {
      return (defaultCols) => {
        return this.$vuetify.breakpoint.xsOnly ? 12 : defaultCols;
      };
    }
  },

};
</script>