<template>
  <div>
    <v-dialog
      v-model="showUploadPsychometrics"
      max-width="550"
      persistent
      content-class="fix-border-dialog"
      scrollable
    >
      <v-card class="rounded-xl">
        <v-card-title class="primary white--text subtitle-1">
          Envia psicometrías
          <v-spacer></v-spacer>
          <v-icon
            dark
            small
            @click="$emit('hideUploadPsychometrics', null, null)"
          >
            mdi-close
          </v-icon>
        </v-card-title>
        <v-card-text class="px-1 pt-2">
          <v-card class="rounded-lg">
            <v-tabs v-model="psico">
              <v-tab>Personales</v-tab>
              <v-tab>Laborales</v-tab>
              <v-tab>Líderes</v-tab>
              <v-tab>Ventas</v-tab>
            </v-tabs>
            <v-tabs-items v-model="psico">
              <v-tab-item class="pa-3">
                <v-row
                  dense
                  v-for="(item, index) in valoresPersonales"
                  :key="index"
                >
                  <v-col
                    cols="7"
                    style="display: flex; justify-content: end; align-items: center; max-height: 40px;"
                  >
                    <label class="mr-2 text-right">{{ item.desc }}</label>
                  </v-col>
                  <v-col
                    cols="5"
                    class="pt-1"
                    style="display: flex; justify-content: start; align-items: center; max-height: 40px;"
                  >
                    <v-text-field
                      v-model="item.normalValue"
                      class="rounded-lg mb-0 fix-height-text-psycho"
                      style="max-height: 36px; max-width: 90px;"
                      dense
                      
                      type="number"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-tab-item>
              <v-tab-item class="pa-3">
                <v-row
                  dense
                  v-for="(item, index) in valoresLaborales"
                  :key="index"
                >
                  <v-col
                    cols="7"
                    style="display: flex; justify-content: end; align-items: center; max-height: 40px;"
                  >
                    <label class="mr-2 text-right">{{ item.desc }}</label>
                  </v-col>
                  <v-col
                    cols="5"
                    class="pt-1"
                    style="display: flex; justify-content: start; align-items: center; max-height: 40px;"
                  >
                    <v-text-field
                      v-model="item.normalValue"
                      class="rounded-lg mb-0 fix-height-text-psycho"
                      style="max-height: 36px; max-width: 90px;"
                      dense
                      type="number"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-tab-item>
              <v-tab-item class="pa-3">
                <v-row dense>
                  <v-col
                    cols="6"
                    style="display: flex; justify-content: end; align-items: center; max-height: 40px;"
                  ></v-col>
                  <v-col
                    cols="3"
                    class="pt-1"
                    style="display: flex; justify-content: center; align-items: center; max-height: 40px;"
                  >
                    <div class="text-uppercase caption">Normal</div>
                  </v-col>
                  <v-col
                    cols="3"
                    class="pt-1"
                    style="display: flex; justify-content: center; align-items: center; max-height: 40px;"
                  >
                    <div class="text-uppercase caption">Bajo presión</div>
                  </v-col>
                </v-row>
                <v-row
                  dense
                  v-for="(item, index) in valoresLideres"
                  :key="index"
                >
                  <v-col
                    cols="6"
                    style="display: flex; justify-content: end; align-items: center; max-height: 40px;"
                  >
                    <label class="mr-2 text-right">{{ item.desc }}</label>
                  </v-col>
                  <v-col
                    cols="3"
                    class="pt-1"
                    style="display: flex; justify-content: center; align-items: center; max-height: 40px;"
                  >
                    <v-text-field
                      v-model="item.normalValue"
                      class="rounded-lg mb-0 fix-height-text-psycho"
                      style="max-height: 36px; max-width: 90px;"
                      dense
                      type="number"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="3"
                    class="pt-1"
                    style="display: flex; justify-content: center; align-items: center; max-height: 40px;"
                  >
                    <v-text-field
                      v-model="item.bajoPresion"
                      class="rounded-lg mb-0 fix-height-text-psycho"
                      style="max-height: 36px; max-width: 90px;"
                      dense
                      type="number"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-tab-item>
              <v-tab-item class="pa-3">
                <v-row
                  dense
                  v-for="(item, index) in valoresVentas"
                  :key="index"
                >
                  <v-col
                    cols="7"
                    style="display: flex; justify-content: end; align-items: center; max-height: 40px;"
                  >
                    <label class="mr-2 text-right">{{ item.desc }}</label>
                  </v-col>
                  <v-col
                    cols="5"
                    class="pt-1"
                    style="display: flex; justify-content: start; align-items: center; max-height: 40px;"
                  >
                    <v-text-field
                      v-model="item.normalValue"
                      class="rounded-lg mb-0 fix-height-text-psycho"
                      style="max-height: 36px; max-width: 90px;"
                      dense
                      type="number"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-tab-item>
            </v-tabs-items>
          </v-card>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="center-item-in-div">
          <v-btn
            small
            depressed
            class="rounded-lg font-weight-bold"
            dark
            color="cyan darken-2"
            :loading="savingData"
            @click="savingData = true; savePsicometrias()"
          >
            enviar
            <v-icon right small>mdi-send</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <SnackBar
      v-if="muestraBarra"
      :muestraBarra="muestraBarra"
      :color="colorBarra"
      :texto="textoBarra"
      @cierraBarra="muestraBarra = false"
    />
  </div>
</template>

<script>
import axios from "axios";
import { deleteToken } from "../../../../../store/Funciones/funciones";
import SnackBar from "../../../General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue";

export default {
  name: "carga-psicometrias",
  props: ["showUploadPsychometrics", "idCandidato"],
  components: { SnackBar },
  data() {
    return {
      colorBarra: "",
      textoBarra: "",
      muestraBarra: false,
      savingData: false,
      psico: null,
      valoresPersonales: [
        { desc: "Reaccion ante Conflictos", normalValue: 0 },
        { desc: "Requiere Supervision", normalValue: 0 },
        { desc: "Apego a las Normas y Reglas", normalValue: 0 },
        { desc: "Compromiso por concluir una actividad", normalValue: 0 },
        { desc: "Realizar Tareas Exhaustivas", normalValue: 0 },
        { desc: "Deseos de Sobresalir", normalValue: 0 },
        { desc: "Actitud de Influencia", normalValue: 0 },
        { desc: "Gusto de tener Subordinados", normalValue: 0 },
        { desc: "Seguridad por Decidir", normalValue: 0 },
        { desc: "Dinamico", normalValue: 0 },
        { desc: "Energico", normalValue: 0 },
        { desc: "Gusto por Resaltar", normalValue: 0 },
        { desc: "Relaciones Sociales", normalValue: 0 },
        { desc: "Aceptación del Grupo", normalValue: 0 },
        { desc: "Calidad Afectiva", normalValue: 0 },
        { desc: "Abstracto", normalValue: 0 },
        { desc: "Minucioso", normalValue: 0 },
        { desc: "Metodico", normalValue: 0 },
        { desc: "Constancia", normalValue: 0 },
        { desc: "Estabilidad Anímica", normalValue: 0 },
      ],
      valoresLaborales: [
        { desc: "Analisis de Problemas", normalValue: 0 },
        { desc: "Toma de Decisiones", normalValue: 0 },
        { desc: "Juicio", normalValue: 0 },
        { desc: "Comunicacion", normalValue: 0 },
        { desc: "Liderazgo", normalValue: 0 },
        { desc: "Delegacion", normalValue: 0 },
        { desc: "Desarrollo de Colaboradores", normalValue: 0 },
        { desc: "Trabajo en Equipo", normalValue: 0 },
        { desc: "Inteligencia Emocional", normalValue: 0 },
        { desc: "Tolerancia a la Presion", normalValue: 0 },
        { desc: "Actitud de servicio", normalValue: 0 },
        { desc: "Seguimiento y Control", normalValue: 0 },
        { desc: "Planeación y Organización", normalValue: 0 },
        { desc: "Enfoque a Resultados", normalValue: 0 },
      ],
      valoresLideres: [
        { desc: "Capacidad de Comunicacion", normalValue: 0, bajoPresion: 0 },
        { desc: "Inteligencia Emocional", normalValue: 0, bajoPresion: 0 },
        { desc: "Toma de Decisiones", normalValue: 0, bajoPresion: 0 },
        { desc: "Orientacion a Resultados", normalValue: 0, bajoPresion: 0 },
        { desc: "Habilidades de Negociacion", normalValue: 0, bajoPresion: 0 },
        { desc: "Capacidad de Mando", normalValue: 0, bajoPresion: 0 },
        { desc: "Capacidad de Planeacion", normalValue: 0, bajoPresion: 0 },
        {
          desc: "Conciencia de sus Fortalezas",
          normalValue: 0,
          bajoPresion: 0,
        },
        {
          desc: "Desarrollo Personal y Equipo",
          normalValue: 0,
          bajoPresion: 0,
        },
        {
          desc: "Carisma y Habilidades Sociales",
          normalValue: 0,
          bajoPresion: 0,
        },
        { desc: "Conciencia Social", normalValue: 0, bajoPresion: 0 },
        { desc: "Creatividad e Innovacion", normalValue: 0, bajoPresion: 0 },
        { desc: "Responsabilidad", normalValue: 0, bajoPresion: 0 },
        { desc: "Habilidad de Informacion", normalValue: 0, bajoPresion: 0 },
        { desc: "Adaptacion al Cambio", normalValue: 0, bajoPresion: 0 },
      ],
      valoresVentas: [
        { desc: "Tolerancia al Rechazo", normalValue: 0 },
        { desc: "Orientación a Resultados", normalValue: 0 },
        { desc: "Habilidades de Comunicación", normalValue: 0 },
        { desc: "Capacidad de Negociación", normalValue: 0 },
        { desc: "Empatía", normalValue: 0 },
        { desc: "Gusto por la Venta", normalValue: 0 },
      ],
    };
  },
  methods: {
    muestraSnackBar(text, color) {
      this.colorBarra = color;
      this.textoBarra = text;
      this.muestraBarra = true;
    },
    savePsicometrias() {
      const params = {
        Opcion: 8,
        token: localStorage.getItem("token"),
        idCandidato: this.idCandidato,
        valoresPersonales: this.valoresPersonales,
        valoresLaborales: this.valoresLaborales,
        valoresLideres: this.valoresLideres,
        valoresVentas: this.valoresVentas,
      };
      axios
        .post(
          `${this.$store.getters.getHost}/Reclutamiento/ws-admin-candidatos.php`,
          params
        )
        .then((response) => {
          this.savingData = false;
          if (response.data.status != "EXPSESSION") {
            if (response.data.candidato.status_tran === 'OK') {
              this.muestraSnackBar(response.data.candidato.logs, "primary");
              this.$emit("hideUploadPsychometrics", this.idCandidato, 4);
            } else {
              this.muestraSnackBar(response.data.candidato.logs, "pink darken-2");
            }
          } else {
            deleteToken();
          }
        })
        .catch((e) => {
          this.muestraSnackBar(e, "pink darken-2");
        });
    },
  },
};
</script>

<style scoped>
.fix-height-text-psycho
  .v-text-field--filled.v-input--dense.v-text-field--single-line
  > .v-input__control
  > .v-input__slot,
.v-text-field--filled.v-input--dense.v-text-field--outlined
  > .v-input__control
  > .v-input__slot,
.v-text-field--filled.v-input--dense.v-text-field--outlined.v-text-field--filled
  > .v-input__control
  > .v-input__slot,
.v-text-field--full-width.v-input--dense.v-text-field--single-line
  > .v-input__control
  > .v-input__slot,
.v-text-field--full-width.v-input--dense.v-text-field--outlined
  > .v-input__control
  > .v-input__slot,
.v-text-field--full-width.v-input--dense.v-text-field--outlined.v-text-field--filled
  > .v-input__control
  > .v-input__slot,
.v-text-field--outlined.v-input--dense.v-text-field--single-line
  > .v-input__control
  > .v-input__slot,
.v-text-field--outlined.v-input--dense.v-text-field--outlined
  > .v-input__control
  > .v-input__slot,
.v-text-field--outlined.v-input--dense.v-text-field--outlined.v-text-field--filled
  > .v-input__control
  > .v-input__slot {
  min-height: 30px !important;
}
</style>
