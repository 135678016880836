import MuestraBeneficios from './components/MuestraBeneficios.vue'
export default {
    name: 'admon-beneficios',
    components: { MuestraBeneficios },
    props: [],
    data() {
        return {

        }
    },
    computed: {

    },
    mounted() {

    },
    methods: {

    }
}