<template>
  <v-card class="rounded-lg">
    <v-data-table
      dense
      :items="usuarios"
      :headers="headers"
      :loading="loadingData"
      :search="search"
      loading-text="Cargando..."
      no-data-text="No hay registros"
      :no-results-text="'No se encontraron coincidencias para: ' + search"
      :footer-props="{
        'items-per-page-text': 'Elementos por página',
        pageText: '{0}-{1} de {2}',
      }"
    >
      <template v-slot:top>
        <div
          style="display: flex; justify-content: space-between;"
          class="primary pt-4  rounded-l-t-header-pases-admin  rounded-r-t-header-pases-admin rounded-lg rounded-b-0"
        >
          <v-text-field
            v-model="search"
            label="Buscar..."
            dense
            flat
            solo-inverted
            dark
            clearable
            append-icon="mdi-magnify"
            style="max-height: 42px; max-width: 400px;"
            class="mx-4 rounded-lg"
          ></v-text-field>
          <v-btn
            class="rounded-lg mr-3"
            color="green lighten-1"
            :loading="loadingBtnAdd"
            dark
            @click="showFindNewMembers = true"
          >
            <v-icon left>mdi-plus</v-icon>
            agregar
          </v-btn>
        </div>
      </template>
      <template v-slot:item.fecha_modificacion="{ item }">
        {{
          fixDate(item.fecha_modificacion.date.substring(0, 10)) +
            " " +
            item.fecha_modificacion.date.substring(11, 16)
        }}
      </template>
      <template v-slot:item.acciones="{ item }">
        <v-btn
          x-small
          class="rounded-lg"
          color="pink darken-3"
          dark
          @click="
            idAux = item.id;
            showConfirm = true;
          "
        >
          <v-icon>mdi-minus-thick</v-icon>
        </v-btn>
      </template>
    </v-data-table>

    <BuscaColaboradoresActivos
      v-if="showFindNewMembers"
      :showFindNewMembers="showFindNewMembers"
      @returnColaborrador="returnColaborrador"
    />

    <Confirm
      v-if="showConfirm"
      :showConfirm="showConfirm"
      question="¿Realmente deseas ejecutar está acción?"
      @cierraConfirm="validaRespuesta"
    />
  </v-card>
</template>

<script>
import axios from "axios";
import {
  deleteToken,
  formatDate,
} from "../../../../../store/Funciones/funciones.js";
import BuscaColaboradoresActivos from "../../../../../components/BuscaColaboradoresActivos";
import Confirm from "../../../AdmnVacantes/components/ConfirmDialogReclutamiento";

export default {
  name: "muestra-usuarios-privilegios-visitas",
  components: { BuscaColaboradoresActivos, Confirm },
  data() {
    return {
      headers: [
        {
          text: "EMPLID",
          value: "emplid",
          class: "font-weight-bold primary white--text",
        },
        {
          text: "NOMBRE",
          value: "nombre",
          class: "font-weight-bold primary white--text",
        },
        {
          text: "PUESTO",
          value: "puesto",
          class: "font-weight-bold primary white--text",
        },
        {
          text: "COMPAÑÍA",
          value: "compania",
          class: "font-weight-bold primary white--text",
        },
        {
          text: "ACTUALIZACIÓN",
          value: "fecha_modificacion",
          class: "font-weight-bold primary white--text",
        },
        {
          text: "ACCIONES",
          value: "acciones",
          class: "font-weight-bold primary white--text",
        },
      ],
      usuarios: [],
      loadingData: true,
      showFindNewMembers: false,
      loadingBtnAdd: false,
      showConfirm: false,
      idAux: 0,
      search: "",
    };
  },
  async mounted() {
    this.usuarios = await this.getColaboradores();
  },
  methods: {
    getColaboradores() {
      const params = {
        Opcion: 49,
        token: localStorage.getItem("token"),
      };

      return new Promise((resolve, reject) => {
        axios
          .post(
            `${this.$store.getters.getHost}/SeguridadPatrimonial/ws-RegistroVisitantes.php`,
            params
          )
          .then((response) => {
            this.loadingData = false;
            if (response.data.status == "OK") {
              resolve(response.data.respuesta);
            } else if (response.data.status == "EXPRESSION") {
              deleteToken();
            } else {
              resolve([]);
            }
          })
          .catch((e) => {
            // this.openSnackBar(e);
            reject(e);
          })
          .finally(() => {
            this.loadingData = false;
          });
      });
    },
    setUsuario(empleado) {
      const params = {
        Opcion: 50,
        token: localStorage.getItem("token"),
        empleado: empleado,
      };
      axios
        .post(
          `${this.$store.getters.getHost}/SeguridadPatrimonial/ws-RegistroVisitantes.php`,
          params
        )
        .then(async (response) => {
          this.loadingData = false;
          if (response.data.status == "OK") {
            if (response.data.respuesta.status_tran == "OK") {
              alert(response.data.respuesta.message);
              this.loadingData = true;
              this.usuarios = await this.getColaboradores();
            } else {
              alert(response.data.respuesta.message);
            }
          } else if (response.data.status == "EXPRESSION") {
            deleteToken();
          }
        })
        .catch((e) => {
          // this.openSnackBar(e);
          alert(e);
        })
        .finally(() => {
          this.loadingBtnAdd = false;
        });
    },
    fixDate(date) {
      return formatDate(date);
    },
    returnColaborrador(empleado) {
      if (empleado != null) {
        this.loadingBtnAdd = true;
        this.setUsuario(empleado.EMPLID);
      }
      this.showFindNewMembers = false;
    },
    validaRespuesta(respuesta) {
      if (respuesta) {
        const params = {
          Opcion: 51,
          token: localStorage.getItem("token"),
          id: this.idAux,
        };
        axios
          .post(
            `${this.$store.getters.getHost}/SeguridadPatrimonial/ws-RegistroVisitantes.php`,
            params
          )
          .then(async (response) => {
            this.loadingData = false;
            if (response.data.status == "OK") {
              if (response.data.respuesta.status_tran == "OK") {
                alert(response.data.respuesta.message);
                this.loadingData = true;
                this.usuarios = await this.getColaboradores();
              } else {
                alert(response.data.respuesta.message);
              }
            } else if (response.data.status == "EXPRESSION") {
              deleteToken();
            }
          })
          .catch((e) => {
            // this.openSnackBar(e);
            alert(e);
          })
          .finally(() => {
            this.loadingBtnAdd = false;
          });
      }
      this.showConfirm = false;
    },
  },
};
</script>
