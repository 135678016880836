import SnackBarErrorIncidencias from "../../General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue"
import FormularioRegistroVisitantes from './components/FormularioRegistroDeVisitas.vue'
import MuestraVisitasRegistradasPorUsuario from './components/MuestraVisitasRegistradasPorUsuario.vue'
export default {
    name: 'registro-nuevos-candidatos',
    components: {
        FormularioRegistroVisitantes,
        SnackBarErrorIncidencias,
        MuestraVisitasRegistradasPorUsuario
    },
    props: [],
    data: () => ({
        muestraFormulario: false,
        muestraBarra: false,
        colorBarra: "",
        textoBarra: "",
        key: 0
    }),
    computed: {

    },
    async mounted() {},
    methods: {
        validaCierreForm(respuesta) {
            if (respuesta) {
                this.colorBarra = "teal darken-1",
                    this.textoBarra = "Visita registrada con éxito."
                this.muestraBarra = true;
                this.key++;
            }
            this.muestraFormulario = false;
        }
    }
}