<template>
  <v-container>
    <v-row dense>
      <v-col
        cols="12"
        xs="12"
        sm="6"
        :md="columnsNumMD"
        v-for="evaluado in evaluadosComentarios"
        :key="evaluado.Evaluado"
      >
        <v-card class="rounded-lg ma-3" elevation="10">
          <v-card-title class="subtitle-2 primary white--text">
            {{ evaluado.Nombre }}
          </v-card-title>
          <v-card-text class="mt-2">
            <v-form ref="formComentarios" v-model="valid" lazy-validation>
              <v-textarea
                v-model="evaluado.fortalezas"
                label="Fortalezas"
                :placeholder="
                  'Ingresa las fortalezas de ' + evaluado.NombreCorto
                "
                rows="2"
                counter
                outlined
                dense
                required
                :rules="commentRules"
              ></v-textarea>
              <v-textarea
                v-model="evaluado.oportunidades"
                label="Oportunidades"
                :placeholder="
                  'Ingresa las oportunidades de ' + evaluado.NombreCorto
                "
                rows="2"
                counter
                outlined
                dense
                required
                :rules="commentRules"
              ></v-textarea>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <div class="text-center mt-2">
      <v-btn
        color="success"
        :small="$vuetify.breakpoint.smAndDown"
        :block="$vuetify.breakpoint.smAndDown"
        :large="$vuetify.breakpoint.mdAndUp"
        class="rounded-pill"
        @click="enviaComentarios"
      >
        <v-icon left>mdi-send</v-icon>
        {{ $t("DH360.textBtnPostComments") }}
      </v-btn>
    </div>

    <SnackBar :muestraBarra="muestraBarra" :texto="textoBarra" :color="colorBarra" @cierraBarra="muestraBarra = false"/>
  </v-container>
</template>

<script>
import SnackBar from '../../../MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue';
export default {
  name: "muestra-asignacion-de-comentarios",
  components: {SnackBar},
  data() {
    return {
      columnsNumMD: 3,
      evaluadosComentarios: [],
      valid: true,
      commentRules: [
        (v) => !!v || "Campo requerido",
        (v) =>
          (v && v.length <= 1000) || "Name must be less than 1000 characters",
      ],
      muestraBarra: false,
      textoBarra: "",
      colorBarra: ""
    };
  },
  props: ["evaluados"],
  computed: {},
  mounted() {
    if (this.evaluados.length <= 2) {
      this.columnsNumMD = 6;
    } else if (this.evaluados.length == 3) {
      this.columnsNumMD = 4;
    } else {
      this.columnsNumMD = 3;
    }
    this.evaluadosComentarios = this.evaluados;
  },
  methods: {
    enviaComentarios() {
      if (this.validaComentariosRespondidos()) {
        this.$emit("comentariosValidados", this.evaluadosComentarios);
      } else {
        this.colorBarra="error"
        this.textoBarra = "debes responder todos los comentarios."
        this.muestraBarra = true
      }
    },

    validaComentariosRespondidos() {
      let todosComentariosRespondidos = true; // inicializar en verdadero
      this.evaluadosComentarios.map((ev) => {
        if (ev.fortalezas == "" || ev.oportunidades == "") {
          todosComentariosRespondidos = false; // marcar como falso si algún comentario no cumple con la validación
        }
      });
      return todosComentariosRespondidos; // devolver verdadero o falso en función del resultado
    },
  },
};
</script>
