<template>
    <v-dialog
      v-model="muestraFiguras"
      persistent
      max-width="700"
      content-class="fix-border-dialog"
    >
      <v-card class="rounded-xl">
        <v-card-title class="primary">
          <label class="overline font-weight-bold white--text"
            >COLABORADORES de la solicitud [{{ idSolicitud }}]</label
          >
          <v-spacer></v-spacer>
          <v-icon dark @click="$emit('ocultaFiguras')">mdi-close</v-icon>
        </v-card-title>
        <v-card-text>
          <v-data-table
            dense
            :items="figuras"
            :headers="headers"
            :loading="loadingData"
            :search="search"
            :footer-props="{
              'items-per-page-text': 'Elementos por página',
              pageText: '{0}-{1} de {2}',
            }"
            no-data-text="No hay registros"
            loading-text="Cargando información..."
            :no-results-text="
              'No se encontró ningun resultado que coincida con ' + search
            "
            :header-props="headerProps"
            :mobile-breakpoint="0"
          >
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
  </template>
  
  <script>
  import axios from "axios";
  import { deleteToken } from "../../../../../../store/Funciones/funciones";
  
  export default {
    name: "ver-figuras-por-solicitud",
    components: {},
    props: ["muestraFiguras", "idSolicitud"],
    data() {
      return {
        headers: [
          {
            value: "item",
            text: "item",
            class: "text-uppercase font-weight-bold rounded-tl-lg",
          },
          {
            value: "descripcion_larga",
            text: "descripción",
            class: "text-uppercase font-weight-bold",
          },
        ],
        headerProps: {
          sortByText: "Ordenar por",
        },
        figuras: [],
        search: "",
        loadingData: true,
        muestraBarra: false,
        colorBarra: "",
        textoBarra: "",
      };
    },
    async mounted() {
      this.figuras = await this.getFigurasPorSolicitud();
    },
    methods: {
      showSnackBar(texto, color = "error") {
        this.textoBarra = texto;
        this.colorBarra = color;
        this.muestraBarra = true;
      },
      async actualizarTabla() {
        this.loadingData = true;
        this.figuras = await this.getFigurasPorSolicitud();
      },
      getFigurasPorSolicitud() {
        const headers = {
          Authorization: localStorage.getItem("token"),
        };
  
        return new Promise((res) => {
          axios
            .get(
              `${this.$store.getters.getHostNode}/api/descanso-laborado/figuras-por-solicitud/${this.idSolicitud}`,
              {
                headers,
              }
            )
            .then((response) => {
              if (response.data.status == "EXPSESSION") {
                deleteToken();
              } else {
                res(response.data.data);
              }
            })
            .finally(() => {
              this.loadingData = false;
            });
        });
      },
      formatMXN(cadena) {
        return cadena.toLocaleString("es-MX", {
          style: "currency",
          currency: "MXN",
        });
      },
    },
  };
  </script>
  