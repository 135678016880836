import MuestraPasesIngreso from './Componentes/mostrarPaseIngreso.vue'
export default {
  name: 'pase-de-ingreso',
  components: {MuestraPasesIngreso},
  props: [],
  data () {
    return {

    }
  },
  computed: {

  },
  mounted () {

  },
  methods: {

  }
}


