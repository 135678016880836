<template>
  <div>
    <v-dialog
      class="rounded-xl"
      v-model="muestraEdicionApego"
      content-class="fix-border-dialog"
      persistent
      scrollable
      width="500"
    >
      <v-card class="rounded-xl">
        <v-card-title>
          <v-row>
            <v-col cols="11">
              <p style="color: #0068ac" class="text-left font-weight-bold">
                Apego a perfil
              </p>
            </v-col>
            <v-col class="pa-0" cols="1">
              <div class="text-right">
                <v-icon
                  class="mt-0"
                  color="pink darken-4"
                  @click="cierraVentanaNoEnviaParms()"
                >
                  mdi-close-circle
                </v-icon>
              </div>
            </v-col>
          </v-row>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pb-0 mb-0" style="height: 500px">
          <p class="py-0 mt-2 mb-2 ml-2">Información de escolaridad.</p>
          <v-autocomplete
            v-model="escolaridad.esc"
            class="py-0 rounded-lg"
            :loading="loadingData"
            :items="datosPerfiles.escolaridad"
            item-text="descr"
            dense
            outlined
            label="Escolaridad"
            placeholder="selecciona la escolaridad"
          ></v-autocomplete>
          <v-autocomplete
            v-model="escolaridad.carrera"
            v-show="
              escolaridad.esc == 'Licenciatura' ||
              escolaridad.esc == 'Ingeniería' ||
              escolaridad.esc == 'Técnico'
            "
            class="py-0 rounded-lg"
            :loading="loadingData"
            :items="datosPerfiles.carreras"
            item-text="descr"
            dense
            outlined
            label="Carrera"
            placeholder="selecciona la carrera"
          ></v-autocomplete>
          <div style="display: flex; justify-content: center">
            <v-text-field
              v-show="false"
              v-model="escolaridad.Ponderacion"
              class="rounded-lg"
              type="number"
              width="100"
              label="Ponderación"
              placeholder="Asigna la ponderación"
              v-on:change="updatePondApego"
            ></v-text-field>
          </div>
          <p class="py-0 ml-2 mt-2 mb-2">Información de conocimientos.</p>
          <div class="text-center">
            <v-btn
              class="py-0 my-0"
              color="pink lighten-5"
              small
              rounded
              depressed
              @click="addConocimiento(1)"
            >
              <v-icon left small> mdi-lightbulb </v-icon>
              Solicitar un nuevo conocimiento
            </v-btn>
          </div>
          <div class="text-center">
            <v-btn
              class="py-0 my-5"
              color="success"
              small
              rounded
              depressed
              @click="addConocimiento(2)"
            >
              <v-icon left small> mdi-plus </v-icon>
              Agregar conocimiento
            </v-btn>
          </div>
          <div v-show="conocimientos.length > 0">
            <v-row v-for="(n, index) in conocimientos" :key="index">
              <v-col cols="7" class="pa-0 mt-1">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-autocomplete
                      v-if="n.id != -1"
                      v-model="n.descr"
                      :items="datosPerfiles.conocimientos"
                      :loading="loadingData"
                      item-text="descr"
                      class="py-0 rounded-lg"
                      dense
                      outlined
                      small
                      label="Conocimiento"
                      placeholder="selecciona un conocimiento"
                      v-bind="attrs"
                      v-on="on"
                    >
                    </v-autocomplete>
                    <v-text-field
                      v-else
                      v-model="n.descr"
                      class="py-0"
                      dense
                      outlined
                      small
                      label="Conocimiento"
                      disabled
                      v-bind="attrs"
                      v-on="on"
                    >
                    </v-text-field>
                  </template>
                  <span>{{ n.descr }}</span>
                </v-tooltip>
              </v-col>
              <v-col cols="3" class="py-0 mt-1">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="n.Ponderacion"
                      type="number"
                      class="py-0"
                      label="Ponderación"
                      placeholder="Asigna la ponderación"
                      v-on:change="updatePondApego"
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <span>Ponderación</span>
                </v-tooltip>
              </v-col>
              <v-col cols="2" class="py-0 mt-1">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="n.exp"
                      type="number"
                      class="py-0"
                      label="Exp. años"
                      placeholder="Experiencia"
                      v-on:change="updatePondApego"
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <span>Experiencia en años</span>
                </v-tooltip>
              </v-col>
            </v-row>
            <div class="text-center">
              <v-btn
                class="py-0"
                color="pink darken-4"
                small
                rounded
                depressed
                dark
                @click="removeConocimiento()"
              >
                <v-icon left small> mdi-minus </v-icon>
                Remover conocimiento
              </v-btn>
            </div>
          </div>
          <div class="text-center mt-5">
            <p
              v-if="ponderacionTotal == 100"
              class="green--text text--darken-4 font-weight-bold"
            >
              Calificación máxima {{ ponderacionTotal }} de 100
            </p>
            <p v-else class="red--text text--darken-4 font-weight-bold">
              Calificación máxima {{ ponderacionTotal }} de 100.
            </p>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="pa-0"
            color="indigo darken-1"
            dark
            depressed
            rounded
            text
            block
            @click="cierraVentanaEnviaParams()"
          >
            guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="tienePerfilExistente"
      persistent
      class="rounded-lg"
      scrollable
      height="800"
      width="450"
    >
      <v-card class="rounded-lg">
        <v-card-title style="display: flex; justify-content: center">
          <h3>¡Aviso!</h3>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="text-center my-5">
          <h4 class="font-weight-bold grey--text text--darken-3">
            Éxiste un apego creado para este puesto.
          </h4>
          <br />
          <h3>¿Te gustaría utilizarlo?</h3>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <!-- <div> -->
          <v-row class="my-0 py-0">
            <v-col cols="6" class="py-0">
              <v-btn
                text
                rounded
                depressed
                block
                color="primary"
                @click="tienePerfilExistente = false"
              >
                crear nuevo
              </v-btn>
            </v-col>
            <v-divider class="pa-0" vertical></v-divider>
            <v-col cols="6" class="py-0">
              <v-btn
                text
                rounded
                depressed
                block
                color="primary"
                @click="usarApegoExistente"
              >
                usar
              </v-btn>
            </v-col>
          </v-row>
          <!-- </div> -->
        </v-card-actions>
      </v-card>
    </v-dialog>

    <SnackBar
      v-if="muestraBarra"
      :muestraBarra="muestraBarra"
      :texto="textoBarra"
      :color="colorBarra"
      @cierraBarra="muestraBarra = false"
    />

    <SolicitaNuevoConocimiento
      :openNuevoConocimiento="reqNuevoConocimiento"
      @cierraVentana="addNuevoConocimiento"
    />
  </div>
</template>

<script>
import axios from "axios";
import { deleteToken } from "../../../../../../../store/Funciones/funciones";
import SnackBar from "../../../../MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias";
import SolicitaNuevoConocimiento from "./SolicitaNuevoConocimiento.vue";

export default {
  name: "update-apego-perfil",
  components: { SnackBar, SolicitaNuevoConocimiento },
  props: [
    "muestraEdicionApego",
    "idPuesto",
    "conocimientosPuesto",
    "escolaridadPuestos",
    "apegoEditado",
  ],
  data() {
    return {
      escolaridad: {
        idEscolaridad: 0,
        esc: "",
        carrera: "",
        idCarrera: 0,
        Ponderacion: 0,
      },
      conocimientos: [],
      datosPerfiles: {
        escolaridad: [],
        carreras: [],
        conocimientos: [],
      },
      //   escolaridadSelected: "",
      loadingData: true,
      tienePerfilExistente: false,
      reqNuevoConocimiento: false,
      ponderacionTotal: 0,
      ponderacionEscolaridad: 0,

      muestraBarra: "",
      textoBarra: "",
      colorBarra: "",
    };
  },
  created() {},
  mounted() {
    // if(this.updatePondApego)
    if (
      this.conocimientosPuesto.length > 0 &&
      this.escolaridadPuestos.id != 0 &&
      !this.apegoEditado
    ) {
      this.tienePerfilExistente = true;
    } else if (this.apegoEditado) {
      this.usarApegoExistente();
    }
    this.getDataApegos();
  },
  methods: {
    addConocimiento(opc) {
      switch (opc) {
        case 1: {
          this.reqNuevoConocimiento = true;
          break;
        }
        case 2: {
          this.conocimientos.push({
            id: 0,
            descr: "",
            exp: 0,
            Ponderacion: 0,
          });
          break;
        }
      }
    },
    removeConocimiento() {
      this.conocimientos.pop();
    },
    updatePondApego() {
      let ponderacionConocimientos = 0;

      if (this.conocimientos.length > 0) {
        this.conocimientos.map((c) => {
          ponderacionConocimientos += parseInt(c.Ponderacion);
        });
      }
      this.ponderacionTotal =
        parseInt(this.escolaridad.Ponderacion, 10) + ponderacionConocimientos;
    },
    // getDataApegos() {
    //   const headers = {
    //     Authorization: localStorage.getItem("token"),
    //   };

    //   return new Promise((res) => {
    //     axios
    //       .get(
    //         `${this.$store.getters.getHostNode}/api/get-elementos-apego`,
    //         {
    //           headers: headers,
    //         }
    //       )
    //       .then((response) => {
    //         if (response.data.status == "EXPRESSION") {
    //           deleteToken();
    //         } else if (response.data.status == "OK") {
    //           res(response.data.elementos);
    //           this.datosPerfiles = response.data.elementos;
    //         }
    //       })
    //       .finally(() => {
    //         this.loadingData = false;
    //       });
    //   });
    // },

    getDataApegos() {
      let params = {
        Opcion: 4,
        token: localStorage.getItem("token"),
      };
      axios
        .post(
          `${this.$store.getters.getHost}/Reclutamiento/ws-control-de-puestos.php`,
          params
        )
        .then((response) => {
          if (response.data.status != "EXPSESSION") {
            if (response.data.status == "OK") {
              this.datosPerfiles = response.data.elementos;
              this.loadingData = false;
            } else {
              alert("Algo malió sal");
            }
          } else {
            deleteToken();
          }
        });
    },
    cierraVentanaEnviaParams() {
      let error = "";

      if (this.escolaridad.esc == "") {
        error += "Debes elegir una escolaridad. ";
      } else if (
        (this.escolaridad.esc == "Licenciatura" ||
          this.escolaridad.esc == "Ingeniería" ||
          this.escolaridad.esc == "Técnico") &&
        this.escolaridad.carrera == ""
      ) {
        error += "Debes elegir una carrera. ";
      }
      if (this.conocimientos.length < 4) {
        error += "Debes agregar por lo menos 4 conocimientos. ";
      }
      if (this.ponderacionTotal != 100) {
        error += "La ponderación debe ser obligatoriamente igual a 100. ";
      }
      let rompeCiclo = false;
      let index = 0;
      while (index < this.conocimientos.length && !rompeCiclo) {
        if (
          this.conocimientos[index].descr == "" ||
          this.conocimientos[index].Ponderacion == 0
        ) {
          error +=
            "No puedes dejar conocimientos vacíos ni ponderaciones en cero(0). ";
          rompeCiclo = true;
        }
        index++;
      }

      if (error == "") {
        if (this.escolaridad.idEscolaridad == 0) {
          let buscaEscolaridad = this.datosPerfiles.escolaridad.filter(
            (e) => e.descr == this.escolaridad.esc
          );
          this.escolaridad.idEscolaridad = buscaEscolaridad[0].id;
          if (
            this.escolaridad.esc == "Licenciatura" ||
            this.escolaridad.esc == "Ingeniería" ||
            this.escolaridad.esc == "Técnico"
          ) {
            let buscaCarrera = this.datosPerfiles.carreras.filter(
              (c) => c.descr == this.escolaridad.carrera
            );
            this.escolaridad.idCarrera = buscaCarrera[0].id;
          } else {
            this.escolaridad.idCarrera = 42;
          }
        }
        this.conocimientos.map((c) => {
          if (c.id == 0) {
            let concocBusqueda = this.datosPerfiles.conocimientos.filter(
              (conocimiento) => {
                return conocimiento.descr == c.descr;
              }
            );
            // console.log(concocBusqueda[0])
            c.id = concocBusqueda[0].id;
          }
        });
        // console.log(this.conocimientos)
        this.$emit("cierraVentana", this.escolaridad, this.conocimientos, 1);
      } else {
        this.textoBarra = error;
        this.colorBarra = "pink";
        this.muestraBarra = true;
      }
    },
    cierraVentanaNoEnviaParms() {
      this.$emit(
        "cierraVentana",
        this.escolaridadPuestos,
        this.conocimientosPuesto,
        2
      );
    },
    buscaEscolaridad(value) {
      return value.descr == this.escolaridad.esc;
    },
    buscaCococimiento(value) {
      return value.descr == this.escolaridad.descr;
    },
    usarApegoExistente() {
      this.escolaridad = this.escolaridadPuestos;
      this.escolaridad.Ponderacion = 0;
      console.log(this.conocimientosPuesto);
      this.conocimientosPuesto.map((c) => {
        this.conocimientos.push({
          id: c.id,
          descr: c.descr,
          exp: 0,
          Ponderacion: c.Ponderacion,
        });
      });
      this.updatePondApego();
      this.tienePerfilExistente = false;
    },
    addNuevoConocimiento(nuevoConocimiento) {
      // console.log("nuevoConocimiento: " + nuevoConocimiento)
      if (nuevoConocimiento == "") {
        this.reqNuevoConocimiento = false;
      } else {
        this.reqNuevoConocimiento = false;
        this.conocimientos.push({
          id: -1,
          descr: nuevoConocimiento,
          exp: 0,
          Ponderacion: 0,
        });
      }
    },
  },
};
</script>
