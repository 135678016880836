<template>
  <v-card color="blue-grey lighten-5" class="rounded-lg pa-6">
    <div>
      <label class="overline">Selecciona el periodo</label>
      <v-autocomplete
        v-model="periodoSeleccionado"
        :items="periodos"
        :loading="loadingPeriodos"
        class="rounded-lg"
        item-text="CAL_PRD_ID"
        item-value="CAL_PRD_ID"
        prepend-inner-icon="mdi-magnify"
        dense
        outlined
        placeholder="Ingresa el periodo..."
        style="max-width: 400px;"
        v-on:change="loadingPeriodData = true; getPeriodo();"
      >
      </v-autocomplete>
      <br />
      <div v-for="(error, index) in erroresEnDescarga" :key="index">
        <p class="my-1">{{ error }}</p>
      </div>
      <v-data-table
        dense
        :headers="recibosPeriodoHeaders"
        :items="recibosPeriodo"
        :loading="loadingPeriodData"
        :search="search"
        no-data-text="Selecciona un periodo."
        loading-text="Cargando información de periodo"
        :footer-props="{
          'items-per-page-text': 'Elementos por página',
          pageText: '{0}-{1} de {2}',
        }"
      >
        <template v-slot:top>
          <div
            style="display: flex; justify-content: space-between;"
            class="pa-5"
          >
            <v-text-field
              v-model="search"
              prepend-inner-icon="mdi-magnify"
              class="rounded-lg"
              dense
              outlined
              placeholder="Búsqueda en tabla..."
              :disabled="recibosPeriodo.length == 0 ? true : false"
              style="max-width: 400px;"
            ></v-text-field>
            <v-btn
              :loading="loadingBtn"
              class="rounded-lg"
              @click="loadingBtn = true; getZip();"
              color="primary"
              depressed
              :disabled="recibosPeriodo.length == 0 ? true : false"
            >
              <v-icon left>mdi-folder-zip</v-icon>
              Descargar xml
            </v-btn>
          </div>
        </template>
        <template v-slot:item.fecha="{ item }">
          {{ setFormatDate(item.fecha) }}
        </template>
      </v-data-table>
    </div>
  </v-card>
</template>

<script>
import axios from "axios";
import deleteToken from "../../../../../store/Funciones/funciones";
import JSZip from "jszip";
import FileSaver from "file-saver";

export default {
  components: {},
  data() {
    return {
      periodos: [],
      loadingPeriodos: true,
      loadingPeriodData: false,
      loadingBtn: false,
      periodoSeleccionado: null,
      search: null,
      recibosPeriodo: [],
      erroresEnDescarga: [],
      recibosPeriodoHeaders: [
        { value: "renoe", text: "EMPLID" },
        { value: "fecha", text: "FECHA" },
        { value: "periodo", text: "PERIODO" },
        { value: "CFDI", text: "CFDI" },
      ],
    };
  },
  async mounted() {
    this.periodos = await this.getPeriodos();
  },
  methods: {
    getPeriodos() {
      const headers = {
        Authorization: localStorage.getItem("token"),
      };

      return new Promise((res) => {
        axios
          .get(`${this.$store.getters.getHostNode}/api/recibos-timbrados`, {
            headers,
          })
          .then((response) => {
            if (response.data.status == "EXPSESSION") {
              deleteToken();
            } else {
              res(response.data.data);
            }
          })
          .finally(() => {
            this.loadingPeriodos = false;
          });
      });
    },
    async getPeriodo() {
      this.recibosPeriodo = await this.getPeriodData();
    },
    getPeriodData() {
      const headers = {
        Authorization: localStorage.getItem("token"),
      };

      return new Promise((res) => {
        axios
          .get(
            `${this.$store.getters.getHostNode}/api/recibos-timbrados/${this.periodoSeleccionado}`,
            {
              headers,
            }
          )
          .then((response) => {
            if (response.data.status == "EXPSESSION") {
              deleteToken();
            } else {
              res(response.data.data);
            }
          })
          .finally(() => {
            this.loadingPeriodData = false;
          });
      });
    },
    setFormatDate(date) {
      let fecha = new Date(date);

      let diaUTC = fecha.getUTCDate();
      let mesUTC = fecha.getUTCMonth() + 1;
      let anioUTC = fecha.getUTCFullYear();

      diaUTC = diaUTC < 10 ? "0" + diaUTC : diaUTC;
      mesUTC = mesUTC < 10 ? "0" + mesUTC : mesUTC;

      return anioUTC + "-" + mesUTC + "-" + diaUTC;
    },
    async getZip() {
      //   this.datosNominas = await this.getNominasCFDIs();
      if (this.recibosPeriodo) {
        if (this.recibosPeriodo.length > 0) {
          this.downloadPDFsAsZip(this.recibosPeriodo);
        } else {
          alert("No se encontraron documentos");
        }
      } else {
        alert("No se encontraron documentos");
      }
    },
    downloadPDFsAsZip(pdfFiles) {
      const zip = new JSZip();
      const promises = [];
      pdfFiles.forEach((pdfUrl) => {
        const promise = axios
          .get(pdfUrl.archivo_xml, { responseType: "blob" })
          .then((response) => {
            zip.file(
              pdfUrl.archivo_xml.substring(
                pdfUrl.archivo_xml.lastIndexOf("/") + 1
              ),
              response.data,
              { binary: true }
            );
          })
          .catch(() => {
            this.erroresEnDescarga.push(
              `Error al descargar el XML desde ${pdfUrl.archivo_xml} en periodo ${this.periodoSeleccionado}`
            );
          });

        promises.push(promise);
      });

      // Esperar a que todas las descargas se completen antes de generar el zip
      Promise.all(promises)

        .then(() => {
          zip.generateAsync({ type: "blob" }).then((content) => {
            FileSaver.saveAs(
              content,
              "nominasTimbradas" + "_" + this.periodoSeleccionado + ".zip"
            );
          });
          this.loadingBtn = false;
          if (this.erroresEnDescarga) {
            if (this.erroresEnDescarga.length > 0) {
              this.muestraErrores = true;
            }
          }
        })
        .catch((error) => {
          console.error("Error al descargar uno o más PDFs", error);
          this.loadingBtn = false;
        });
    },
  },
};
</script>
