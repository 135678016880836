import axios from 'axios'
import VueJsonToCsv from "vue-json-to-csv";
import DataTableGeneriec from '../../../../../components/componentsExtra/DataTableGeneric.vue'
import LoadingBar from '../../../DesarrolloOrganizacional/components/loadingBar.vue'
import MuestraDetalles from './components/MuestraDetallesVacaciones.vue'
import {
    deleteToken
} from '../../../../../store/Funciones/funciones'
export default {
    name: 'vacaciones',
    components: {
        DataTableGeneriec,
        LoadingBar,
        MuestraDetalles,
        VueJsonToCsv
    },
    props: [],
    data() {
        return {
            colaboradores: [],
            vac_x_per: [],
            headersPeriodosVac: [{
                    text: 'periodo',
                    value: 'tab_sectab'
                },
                {
                    text: 'inicio',
                    value: 'tab_eleuno'
                },
                {
                    text: 'fin',
                    value: 'tab_eledos'
                },
                {
                    text: 'Dias sindicalizados',
                    value: 'tab_eletre'
                }, {
                    text: 'Díias no sindicalizados',
                    value: 'tab_elecua'
                }
            ],
            headers: [{
                    text: '# Empleado',
                    align: 'start',
                    value: 'emplid',
                },
                {
                    text: 'Proceso',
                    value: 'gp',
                    align: 'center'
                },
                {
                    text: 'Contratación',
                    value: "fecha_contratacion",
                    align: 'center'
                },
                {
                    text: 'Ant.',
                    value: "tiempoLaborando",
                    align: 'center'
                },
                {
                    text: 'Inc. Reg.',
                    value: "dias_solicitados",
                    align: 'center'
                },
                {
                    text: 'Días Aniv.',
                    value: "dias_por_anios_completos",
                    align: 'center'
                },
                {
                    text: 'Días Prop.',
                    value: "dias_proporcionales",
                    align: 'center'
                },
                {
                    text: 'Días Otorg.',
                    value: "dias_totales_otorgados",
                    align: 'center'
                },
                {
                    text: 'Días Disp BD',
                    value: "dias_para_solicitar",
                    align: 'center'
                },
                {
                    text: 'Días Disp DH',
                    value: "dias_otorgados_portal_dh",
                    align: 'center'
                },
                {
                    text: 'Días Aniv DH',
                    value: "dias_otorgados_portal_dh_aniv",
                    align: 'center'
                },
                {
                    text: 'Días Prop DH',
                    value: "dias_otorgados_portal_dh_prop",
                    align: 'center'
                },
                {
                    text: 'Acum. Hist.',
                    value: "acu_historico",
                    align: 'center'
                },
                {
                    text: 'Acum. 2022',
                    value: "acu_actual",
                    align: 'center'
                },
                {
                    text: 'Acum. T.',
                    value: "acu_total",
                    align: 'center'
                },
                {
                    text: 'Incidencias',
                    value: 'actions',
                    align: 'center'
                },
            ],
            headers_to_export: {
                emplid: { title: '# Empleado' },
                gp: { title: 'Proceso' },
                fecha_contratacion: { title: 'Contratación' },
                tiempoLaborando: { title: 'Ant.' },
                dias_solicitados: { title: 'Inc. Registradas' },
                dias_por_anios_completos: { title: 'Días Aniversario' },
                dias_proporcionales: { title: 'Días Proporcionales' },
                dias_totales_otorgados: { title: 'Días otorgados' },
                dias_para_solicitar: { title: 'Días Disp BD' },
                dias_otorgados_portal_dh: { title: 'Días Disp DH' },
                dias_otorgados_portal_dh_aniv: { title: 'Días Aniv DH' },
                dias_otorgados_portal_dh_prop: { title: 'Días Prop DH' },
                acu_historico: { title: 'Acum. Hist.' },
                acu_actual: { title: 'Acum. Año actual' },
                acu_total: { title: 'Acum. Total' },
            },
            search: "",
            loadContent: true,
            muestraPeriodos: false,
            muestraLoadingDialog: false,
            muestraDetallesUsuario: false,
            textLoading: "Cargando información",
            titulo: "Días otorgados por antiguedad",
            key: 0,
            empleado: 0,
            fechaAct: ""
        }
    },
    computed: {

    },
    mounted() {
        //  window.document.title = "DH:URREA - Reporte de vacaciones.";
        this.fechaAct = this.getFecha();
        // console.log(this.fechaAct);
        this.getColaboradores();
    },
    methods: {
        getFecha() {
            const date = new Date();
            const [month, day, year] = [date.getMonth(), date.getDate(), date.getFullYear()];
            const [hour, minutes, seconds] = [date.getHours(), date.getMinutes(), date.getSeconds()];
            // console.log(year)
            return year + month + day + "_" + hour + minutes + seconds;
        },
        getColaboradores() {
            let params = {
                Opcion: 3,
                token: localStorage.getItem('token')
            }
            axios
                .post(`${ this.$store.getters.getHost }/Incidencias/ws-incidencias-vacaciones.php `, params)
                .then((response) => {
                    // this.loadingColabs = false;
                    if (response.data.status == "EXPSESSION") {
                        deleteToken();
                    } else {
                        var data = response.data.reporte;
                        this.colaboradores = data;
                        this.loadContent = false;
                    }
                })
        },
        /* A method that is called when the user clicks on the button "Periodos de vacaciones" */
        getVacXPer() {
            // console.log("hola g")
            let params = {
                Opcion: 4,
                token: localStorage.getItem('token')
            }
            axios
                .post(`${ this.$store.getters.getHost }/Incidencias/ws-incidencias-vacaciones.php `, params)
                .then((response) => {
                    // this.loadingColabs = false;
                    if (response.data.status == "EXPSESSION") {
                        deleteToken();
                    } else {
                        var data = response.data.reporte;
                        this.vac_x_per = data;
                        this.muestraLoadingDialog = false;
                        this.muestraPeriodos = true;
                        // console.log("test" + this.muestraPeriodos)
                    }
                })
        },
        openDetalles(emplid) {
            this.empleado = emplid;
            this.key++;
            this.muestraDetallesUsuario = true;
        }
    }
}