export function formatDateISO(date) {
    if (!date) return null;

    let [day, month, year] = date.split("/");
    switch (month) {
        case "ENE":
            month = "01";
            break;
        case "FEB":
            month = "02";
            break;
        case "MAR":
            month = "03";
            break;
        case "ABR":
            month = "04";
            break;
        case "MAY":
            month = "05";
            break;
        case "JUN":
            month = "06";
            break;
        case "JUL":
            month = "07";
            break;
        case "AGO":
            month = "08";
            break;
        case "SEP":
            month = "09";
            break;
        case "OCT":
            month = "10";
            break;
        case "NOV":
            month = "11";
            break;
        case "DIC":
            month = "12";
            break;
    }
    return `${year}-${month}-${day}`;
}

export function formatDate(date) {
    if (!date) return null;

    let [year, month, day] = date.split("-");
    switch (month) {
        case "01":
            month = "ENE";
            break;
        case "02":
            month = "FEB";
            break;
        case "03":
            month = "MAR";
            break;
        case "04":
            month = "ABR";
            break;
        case "05":
            month = "MAY";
            break;
        case "06":
            month = "JUN";
            break;
        case "07":
            month = "JUL";
            break;
        case "08":
            month = "AGO";
            break;
        case "09":
            month = "SEP";
            break;
        case "10":
            month = "OCT";
            break;
        case "11":
            month = "NOV";
            break;
        case "12":
            month = "DIC";
            break;
    }
    return `${day}/${month}/${year}`;
}