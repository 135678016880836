import axios from 'axios'
import ReporteAsistencias from '../../../../../components/componentsRelacionesLaborales/ReporteAsistencias.vue';
import { formatDate } from './components/funciones';
import { deleteToken } from '../../../../../store/Funciones/funciones';

export default {
    name: 'reporte-asistencias-responsable',
    components: {
        ReporteAsistencias
    },
    props: [],
    data() {
        return {
            dates: [],
            subordinados: [],
            items: [],
            asistencias: [],
            subordinado: "",
            dialog: false,
            loadingData: true,
            loadingAsistencia: true,
            // loaingFiltrar: false,
            muestraTabla: false,
            numResp: null,
            key: 0,
        }
    },
    computed: {
        dateRangeText() {
            let inicio = "";
            let fin = "";
            if (this.dates[0] != null) {
                inicio = formatDate(this.dates[0]);
            }
            if (this.dates[1] != null) {
                fin = formatDate(this.dates[1]);
            }

            if (this.dates.length == 0) {
                return "";
            }
            return "Del " + inicio + " al " + fin //this.dates.join(' al ')
        },
    },
    mounted() {
        //  window.document.title = "DH:URREA - Reporte de asistencias.";
        // this.getSubordinados();
    },
    methods: {
        cierraDialog() {
            if (this.dates.length == 2) {
                this.dialog = false;
            }
        },
        getSubordinados() {
            let params = {
                Opcion: 1,
                token: localStorage.getItem('token')
            }
            axios
                .post(`${this.$store.getters.getHost}/RelacionesLaborales/RelacionEmpleadoResponsable/ws-empleado-responsable.php`, params)
                .then((response) => {
                    if (response.data.status == "EXPSESSION") {
                        deleteToken();
                    } else {
                        var data = response.data.subordinados;
                        this.subordinados = data;
                        this.subordinados.map(subordinado => {
                            this.items.push(subordinado["nombreBusqueda"]);
                        })
                        this.loadingData = false;
                    }
                })
        },

        filtrar() {
            // this.loaingFiltrar = true;
            if (this.dates.length == 2) {
                this.numResp = this.$store.getters.getUser["Folio"];
                if (this.numResp == 15689) { this.numResp = 3962; }
                this.key++;
                this.muestraTabla = true;
            } else {
                alert("error")
            }
        },

        obtenAsistencias() {
            let params = {
                Opcion: 2,
                token: localStorage.getItem('token'),
                fechaInicio: this.dates[0],
                fechaFin: this.dates[1]
            }
            axios
                .post(`${ this.$store.getters.getHost }/RelacionesLaborales/RelacionEmpleadoResponsable/ws-empleado-responsable.php`, params)
                .then((response) => {
                    if (response.data.status == "EXPSESSION") {
                        deleteToken();
                    } else {
                        // this.loaingFiltrar = false;
                        var data = response.data.subordinados;
                        this.asistencias = data;

                        this.loadingAsistencia = false;
                    }
                })
        }


    }
}