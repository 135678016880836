import axios from "axios";
import SnackBarErrorIncidencias from "../../../General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue"
export default {
    name: 'reporte-de-entrevistas',
    props: [],
    data() {
        return {
            // loadingTabla: true,
            dialog: true,
            ingresoRecibir: '',
            tab: null,
            categorias: [],
            nombreCategorias: [],
            preguntasCategoria: [],
            opcionesCerradas: [
                { id: 1, mensaje: 'Si' },
                { id: 2, mensaje: 'No' }
            ],
            rutas: [],
            ruta4: '',
            confirmRuta4: '',
            nombreRutas: [],
            turnos: ['Matutino', 'Vespertino', 'Nocturno'],
            tiempoUEmpleos: ['Más de 3 meses', 'Menos de 3 meses'],
            disponibilidad2doturno: ['Si tengo disponibilidad ', 'No tengo disponibilidad'],
            condicionesDeClima: ['Fresco', 'Clima controlado'],
            medioEnterado: ['Facebook', 'Modulo', 'Recomendado', 'Volanteo', 'Feria', 'SNF', 'Otros'],
            radioGroup: 1,
            date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            menu: false,

            textoBarra: '',
            colorBarra: '',
            muestraBarra: false,

            loading: true,

        }
    },
    components: { SnackBarErrorIncidencias },
    computed: {

    },
    mounted() {
        this.getCaregoriasFormulario();
        this.getRutasUH();
    },
    methods: {
        getCaregoriasFormulario() {
            let params = {
                Opcion: 1,
                token: localStorage.getItem("token"),
            };
            axios.post(`${this.$store.getters.getHost}/OnBoarding/ws-reporteDeEntrevistas.php`, params).then((response) => {
                var data = response.data.data;
                this.categorias = data;
                this.categorias.map((elemento) => {
                    this.nombreCategorias.push(elemento["nombre"]);
                    this.preguntasCategoria.push(elemento['preguntas']);
                });

                this.loading = false;
            });
        },

        getRutasUH() {
            let params = {
                Opcion: 14,
                token: localStorage.getItem("token"),
            };
            axios.post(`${ this.$store.getters.getHost }/General/ws-general.php`, params).then((response) => {
                var data = response.data.rutas;
                this.rutas = data;
                this.rutas.map((elemento) => {
                    this.nombreRutas.push(elemento["DescRuta"]);
                });
            });
            this.nombreRutas.push("Ninguno");

        },

        submit() {
            if (this.confirmRuta4) {
                if (this.confirmRuta4 == 'Ninguno') {
                    this.categorias[11].preguntas[6].respuesta = this.confirmRuta4;
                } else {
                    let id_ruta_conf = this.rutas.filter((dato) => dato['DescRuta'] == this.confirmRuta4);
                    this.categorias[11].preguntas[6].respuesta = id_ruta_conf[0]['id_ruta'];
                }
            }

            if (this.ruta4) {
                if (this.ruta4 == 'Ninguno') {
                    this.categorias[2].preguntas[0].respuesta = this.ruta4;
                } else {
                    let id_ruta = this.rutas.filter((dato) => dato['DescRuta'] == this.ruta4);
                    this.categorias[2].preguntas[0].respuesta = id_ruta[0]['id_ruta'];
                }
            }

            let params = {
                Opcion: 2,
                token: localStorage.getItem('token'),
                respuestas: this.categorias,
                ingresoRecibir: this.ingresoRecibir,
            };
            this.muestraBarra = false;
            axios.post(`${this.$store.getters.getHost}/OnBoarding/ws-reporteDeEntrevistas.php`, params).then((response) => {
                if (response.data.status == 'OK') {
                    this.textoBarra = 'Se ha registrado correctamente';
                    this.colorBarra = 'success';
                    this.muestraBarra = true;
                    // this.ruta4 = '',
                    // this.confirmRuta4 = '',
                    // this.categorias.forEach(element => {
                    //     element.preguntas.forEach(element1 => {
                    //         element1.respuesta = '';
                    //     });
                    // });
                }
                if (response.data.status == 'noCoincideRuta') {
                    this.textoBarra = 'Verifica que coincidan las rutas seleccionadas ';
                    this.colorBarra = 'primary';
                    this.muestraBarra = true;
                }

                if (response.data.status == 'Incompleto') {
                    this.textoBarra = 'Complete el formulario y revise qué campos son numericos';
                    this.colorBarra = 'error';
                    this.muestraBarra = true;
                }

                if (response.data.status == 'error') {
                    this.textoBarra = 'Se ha producido un error';
                    this.colorBarra = 'error';
                    this.muestraBarra = true;
                }

                if (response.data.result == 2) {
                    this.textoBarra = 'Su evaluación no aplica para ser candidato';
                    this.colorBarra = 'error';
                    this.muestraBarra = true;
                }

                if (response.data.result == 1) {
                    this.textoBarra = 'Felicitaciones, es candidato para el puesto';
                    this.colorBarra = 'success';
                    this.muestraBarra = true;
                }

            })

        },

        evaluar() {
            if (this.confirmRuta4) {
                if (this.confirmRuta4 == 'Ninguno') {
                    this.categorias[11].preguntas[6].respuesta = this.confirmRuta4;
                } else {
                    let id_ruta_conf = this.rutas.filter((dato) => dato['DescRuta'] == this.confirmRuta4);
                    this.categorias[11].preguntas[6].respuesta = id_ruta_conf[0]['id_ruta'];
                }
            }

            if (this.ruta4) {
                if (this.ruta4 == 'Ninguno') {
                    this.categorias[2].preguntas[0].respuesta = this.ruta4;
                } else {
                    let id_ruta = this.rutas.filter((dato) => dato['DescRuta'] == this.ruta4);
                    this.categorias[2].preguntas[0].respuesta = id_ruta[0]['id_ruta'];
                }
            }

            let params = {
                Opcion: 3,
                token: localStorage.getItem('token'),
                respuestas: this.categorias,
                ingresoRecibir: this.ingresoRecibir,
            };

            this.muestraBarra = false;
            axios.post(`${this.$store.getters.getHost}/OnBoarding/ws-reporteDeEntrevistas.php`, params).then((response) => {
                if (response.data.result == true) {
                    this.textoBarra = 'Su evaluación no aplica para ser candidato';
                    this.colorBarra = 'error';
                    this.muestraBarra = true;
                } else {
                    this.textoBarra = 'Felicitaciones, es candidato para el puesto';
                    this.colorBarra = 'success';
                    this.muestraBarra = true;
                }
                console.log(response.data);
            })
        },
    }
}