<template>
  <v-container fluid v-if="ruta == 'admon' && !moduloError">
    <v-card>
      <v-data-table
        :headers="headers"
        :items="inconformidades"
        :search="buscar"
        :loading="loadingInfo"
        loading-text="Cargando solicitudes"
        class="elevation-1"
        dense
        mobile-breakpoint="0"
        :footer-props="{ 'items-per-page-text': 'Elementos por página' }"
        no-data-text="No se encontraron inconformidades"
        item-key="id"
      >
        <template v-slot:top>
          <v-toolbar flat class="rounded-lg rounded-b-0 rounded-t-0">
            <v-text-field
              v-model="buscar"
              :label="$t('DH360.findTextBox')"
              class="mt-7 rounded-lg"
              dense
              clearable
              outlined
              solo-inverted
              prepend-inner-icon="mdi-table-search"
            ></v-text-field>
            <v-spacer></v-spacer>
            <vue-json-to-csv
              :json-data="inconformidades"
              :labels="headers_report_to_export"
              csv-title="Reporte de atención a colaboradores"
            >
              <v-btn
                color="green darken-2"
                dark
                small
                class="mb-1 font-weight-bold rounded-lg"
              >
                <v-icon left small>mdi-microsoft-excel</v-icon>
                {{ $t("DH360.export") }}
              </v-btn>
            </vue-json-to-csv>
          </v-toolbar>
        </template>

        <template v-slot:item.nombre="{ item }">
          <div align="left">{{ item.nombre }}</div>
        </template>
        <template v-slot:item.motivo="{ item }">
          <div align="left">{{ item.motivo }}</div>
        </template>
        <template v-slot:item.departamento="{ item }">
          <div align="left">{{ item.departamento }}</div>
        </template>
        <template v-slot:item.diasTranscurridos="{ item }">
          <div v-if="item.diasTranscurridos > 1">
            {{ item.diasTranscurridos }} Días
          </div>
          <div v-else-if="item.diasTranscurridos == 1">
            {{ item.diasTranscurridos }} Día
          </div>
          <div v-else-if="item.diasTranscurridos == 0">Hoy</div>
        </template>
        <template v-slot:item.estatus="{ item }">
          <div v-if="item.estatus == 1" class="d-flex justify-center">
            <v-chip-group column>
              <v-btn x-small rounded elevation="1" color="red" dark>
                Abierta
              </v-btn>
            </v-chip-group>
          </div>
          <div v-else-if="item.estatus == 2" class="d-flex justify-center">
            <v-chip-group column>
              <v-btn
                x-small
                rounded
                elevation="1"
                color="light-green darken-1"
                dark
              >
                Por calificar
              </v-btn>
            </v-chip-group>
          </div>
          <div v-else-if="item.estatus == 3" class="d-flex justify-center">
            <v-chip-group column>
              <v-btn x-small rounded elevation="1" color="teal darken-1" dark>
                Cerrada
              </v-btn>
            </v-chip-group>
          </div>
        </template>
        <template v-slot:item.nivelSatisfaccion="{ item }">
          <div class="d-flex justify-center">
            <v-chip-group column v-if="item.estatus == 2">
              <v-btn
                v-if="
                  item.nivelSatisfaccion == 0 ||
                  item.nivelSatisfaccion == 'Pendiente' ||
                  item.nivelSatisfaccion == null
                "
                x-small
                rounded
                elevation="1"
                color="red"
                dark
              >
                <v-icon x-small>mdi mdi-star-outline</v-icon>
              </v-btn>
              <v-btn
                v-else
                x-small
                rounded
                elevation="1"
                color="teal darken-1"
                dark
              >
                <v-icon x-small>mdi-star-check</v-icon>
                {{ item.nivelSatisfaccion }}
              </v-btn>
            </v-chip-group>
            <v-chip-group column v-else>
              <v-btn x-small rounded elevation="1" color="grey darken-1" dark>
                <v-icon x-small>mdi mdi-star-check</v-icon>
                <div v-if="item.nivelSatisfaccion != 'Pendiente'">
                  {{ item.nivelSatisfaccion }}
                </div>
              </v-btn>
            </v-chip-group>
          </div>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip top color="indigo lighten-4">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="indigo darken-4"
                v-bind="attrs"
                v-on="on"
                @click="dialogDetalles(item)"
              >
                mdi-details
              </v-icon>
            </template>
            <label class="text-uppercase font-weight-bold white--text"
              >Detalles</label
            >
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog
      v-model="openDetalles"
      max-width="600px"
      transition="dialog-top-transition"
      persistent
    >
      <v-card>
        <v-card-title class="text-subtitle-2 primary white--text">
          Detalles de solicitud
          <v-spacer></v-spacer>
          <v-icon
            small
            dark
            @click="
              openDetalles = false;
              e6 = 1;
            "
            >mdi-close</v-icon
          >
        </v-card-title>
        <v-card-text class="pt-5">
          <v-stepper v-model="e6" vertical>
            <v-stepper-step :complete="e6 > 1" step="1" editable>
              Detalles de solicitud
              <small>Consulta para despues responder</small>
            </v-stepper-step>

            <v-stepper-content step="1">
              <v-card class="pa-5 mb-5 elevation-2">
                <v-row>
                  <v-col cols="12" xs="12" sm="12" md="10" lg="10" xl="10">
                    <div>
                      <label class="font-weight-bold">Colaborador:</label>
                      {{ item.EMPLID }}
                    </div>
                    <div>
                      <label class="font-weight-bold">Nombre:</label>
                      {{ item.nombre }}
                    </div>
                    <div>
                      <label class="font-weight-bold">Motivo:</label>
                      {{ item.motivo }}
                      <label
                        v-if="item.estatus == 1"
                        class="font-weight-bold blue--text"
                        style="cursor: pointer"
                        @click="openForm(item)"
                        >Cambiar</label
                      >
                    </div>
                  </v-col>
                  <v-col
                    class="d-flex align-center justify-center"
                    cols="12"
                    xs="12"
                    sm="12"
                    md="2"
                    lg="2"
                    xl="2"
                  >
                    <div>
                      <v-tooltip top color="blue lighten-3">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            color="blue"
                            dark
                            fab
                            @click="openEvidencia(item, 'evidencia')"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon small>mdi-image-search-outline</v-icon>
                          </v-btn>
                        </template>
                        <span class="white---text"> Ver evidencia </span>
                      </v-tooltip>
                    </div>
                  </v-col>
                </v-row>
              </v-card>
              <v-row>
                <v-col
                  cols="12"
                  xs="12"
                  sm="12"
                  md="12"
                  lg="12"
                  xl="12"
                  class="d-flex justify-space-around mb-5"
                >
                  <div align="center">
                    <label class="font-weight-bold">Fecha solicitud:</label
                    ><br />
                    {{ item.fechaInconformidad }}
                  </div>
                  <div align="center">
                    <label class="font-weight-bold">Fecha registro:</label
                    ><br />
                    {{ item.fechaRegistro }}
                  </div>
                </v-col>
              </v-row>
              <v-card class="pa-5 mb-5">
                <v-row>
                  <v-col
                    cols="12"
                    xs="12"
                    sm="12"
                    md="10"
                    lg="10"
                    xl="10"
                    class="text-justify"
                  >
                    <div>
                      <label class="font-weight-bold blue--text"
                        >Comentario:</label
                      >
                      {{ item.comentario }}
                    </div>
                    <div v-if="item.estatus > 1">
                      <label class="font-weight-bold green--text"
                        >Respuesta:</label
                      >
                      {{ item.respuestaAdmon }}
                    </div>
                  </v-col>
                  <v-col
                    class="d-flex align-center justify-center"
                    cols="12"
                    xs="12"
                    sm="12"
                    md="2"
                    lg="2"
                    xl="2"
                  >
                    <div v-if="item.estatus > 1">
                      <v-tooltip top color="green lighten-3">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            color="green"
                            dark
                            fab
                            @click="openEvidencia(item, 'respuesta')"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon small>mdi-image-search-outline</v-icon>
                          </v-btn>
                        </template>
                        <span class="white---text">
                          Ver evidencia respuesta
                        </span>
                      </v-tooltip>
                    </div>
                  </v-col>
                </v-row>
              </v-card>
            </v-stepper-content>

            <v-stepper-step
              :complete="e6 > 2"
              step="2"
              editable
              v-if="item.estatus == 1 && ruta == 'admon'"
            >
              Atender solicitud
            </v-stepper-step>

            <v-stepper-content
              step="2"
              v-if="item.estatus == 1 && ruta == 'admon'"
            >
              <v-card class="mb-5 py-5">
                <v-row>
                  <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-textarea
                      v-model="form.respuesta"
                      label="Escriba su respuesta a la solicitud"
                      maxlength="450"
                      :rules="nameRules"
                      row-height="3"
                      type="text"
                      required
                      outlined
                      counter
                      background-color="grey lighten-2"
                      color="cyan"
                    ></v-textarea>
                  </v-col>
                  <v-col
                    class="pr-0"
                    cols="12"
                    xs="12"
                    sm="3"
                    md="3"
                    lg="3"
                    xl="3"
                  >
                    <div class="pl-5">
                      <div v-if="form.procede == true">¿Procede?: Si</div>
                      <div v-else>¿Procede?: No</div>
                      <v-switch
                        v-model="form.procede"
                        color="primary"
                        hide-details
                      ></v-switch>
                    </div>
                  </v-col>
                  <v-col cols="12" xs="12" sm="9" md="9" lg="9" xl="9">
                    <div class="pl-5">
                      <div>
                        ¿Desea complementar su respuesta con una captura/imagen?
                      </div>
                      <v-expand-transition transition="slide-y-transition">
                        <div v-show="expand">
                          <v-avatar size="50" rounded>
                            <img :src="vistapreviaimg" />
                          </v-avatar>
                          <v-avatar size="50" rounded>
                            <img :src="vistapreviaimg1" />
                          </v-avatar>
                          <v-avatar size="50" rounded>
                            <img :src="vistapreviaimg2" />
                          </v-avatar>
                          <v-avatar size="50" rounded>
                            <img :src="vistapreviaimg3" />
                          </v-avatar>
                          <v-btn
                            v-if="fileBase64.length < 4"
                            class="mx-2"
                            fab
                            dark
                            small
                            color="teal"
                            @click="openFileInput"
                          >
                            <v-icon dark> mdi-plus </v-icon>
                          </v-btn>
                        </div>
                      </v-expand-transition>
                      <v-btn
                        v-if="!expand"
                        class="rounded-lg"
                        small
                        depressed
                        dark
                        color="teal"
                        @click="expand = !expand"
                      >
                        Cargar evidencia
                      </v-btn>
                    </div>
                  </v-col>
                  <v-col cols="12" xl="12" lg="12" md="12" sm="12" xs="12">
                    <v-file-input
                      v-model="evidenciaInconformidad"
                      ref="fileInput"
                      v-on:change="cargarImg"
                      placeholder="Ahora puedes agregar evidencia"
                      show-size
                      accept="image/*"
                      class="rounded-lg"
                      style="display: none"
                      outlined
                      dense
                    ></v-file-input>
                  </v-col>
                </v-row>
              </v-card>
              <div class="d-flex justify-end">
                <v-btn
                  v-if="form.respuesta"
                  :loading="loading"
                  color="teal darken-4"
                  text
                  @click="guardar"
                >
                  Guardar
                </v-btn>
                <v-btn v-else disabled @click="guardar"> Guardar </v-btn>
              </div>
            </v-stepper-content>
          </v-stepper>
        </v-card-text>
      </v-card>
    </v-dialog>

    <SnackBar
      v-if="muestraBarra"
      :muestraBarra="muestraBarra"
      :texto="textoBarra"
      :color="colorBarra"
      @cierraBarra="muestraBarra = false"
    />
    <evaluacion-respuesta-aclaracion
      v-if="evaluarRespuestaAclaracion"
      :dialog="evaluarRespuestaAclaracion"
      :IDAclaracion="this.ID"
      ruta="inconformidad"
      @closed="refrescar"
    ></evaluacion-respuesta-aclaracion>
    <inconformidades-evidencia
      v-if="verEvidencia"
      :dialog="verEvidencia"
      :item="item"
      :tipoEvidencia="tipoEvidencia"
      @cierraDialogEvidencia="cerrarDialog"
    ></inconformidades-evidencia>
    <mi-nomina-form-inconformidades
      v-if="muestraFormInconformidades"
      :dialog="muestraFormInconformidades"
      :item="itemForm"
      @cierraFormInconformidades="closeFormInconformidades"
    ></mi-nomina-form-inconformidades>
  </v-container>
  <v-container v-else-if="moduloError">
    <mantenimiento-modulo></mantenimiento-modulo>
  </v-container>
</template>

<script>
import axios from "axios";
import VueJsonToCsv from "vue-json-to-csv";
import SnackBar from "../../views/generated/General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue";
import { deleteToken } from "../../store/Funciones/funciones";
import EvaluacionRespuestaAclaracion from "../../views/generated/Nominas/Aclaraciones/components/EvaluacionRespuestaAclaracion.vue";
import InconformidadesEvidencia from "../componentsInconformidades/InconformidadesEvidencia.vue";
import MiNominaFormInconformidades from "./MiNominaFormInconformidades.vue";
import MantenimientoModulo from '../MantenimientoModulo.vue';
export default {
  name: "mi-nomina-reporte-inconformidades",
  components: {
    SnackBar,
    EvaluacionRespuestaAclaracion,
    VueJsonToCsv,
    InconformidadesEvidencia,
    MiNominaFormInconformidades,
    MantenimientoModulo,
  },
  props: ["dialog", "ruta"],
  data: () => ({
    textoBarra: "",
    colorBarra: "",
    muestraBarra: false,

    headers: [
      { text: "N.P.", value: "EMPLID", align: "center", sortable: true },
      { text: "Nombre", value: "nombre", align: "center", sortable: true },
      {
        text: "Motivo",
        value: "motivo",
        align: "center",
        sortable: true,
        // filter: this.filterByEAuthvalue,
      },
      {
        text: "Departamento",
        value: "departamento",
        align: "center",
        sortable: true,
      },
      {
        text: "fecha Registro",
        value: "fechaRegistro",
        align: "center",
        sortable: true,
      },
      {
        text: "Dias transcurridos",
        value: "diasTranscurridos",
        align: "center",
        sortable: true,
      },
      {
        text: "Nivel de satisfacción",
        value: "nivelSatisfaccion",
        align: "center",
      },
      {
        text: "Estatus",
        value: "estatus",
        align: "center",
      },
      {
        text: "Detalles",
        value: "actions",
        align: "center",
      },
    ],
    inconformidades: [],
    headers_report_to_export: {
      EMPLID: { title: "N.P." },
      nombre: { title: "Nombre" },
      DeptoColaborador: { title: "Departamento de colaborador" },
      motivo: { title: "Motivo" },
      departamento: { title: "Departamento de inconformidad" },
      comentario: { title: "Comentario" },
      fechaInconformidad: { title: "Fecha Inconformidad" },
      fechaRegistro: { title: "Fecha Registro" },
      diasTranscurridos: { title: "Días transcurridos" },
      fechaRespuesta: { title: "Fecha de respuesta" },
      emplidRespuesta: { title: "N.P. de quien responde" },
      nameEmplidRespuesta: { title: "Nombre de quien responde" },
      DeptoResponde: { title: "Departamento de quien responde" },
      nivelSatisfaccion: { title: "Nivel de satisfacción" },
    },
    buscar: "",
    loadingInfo: true,
    loading: false,
    authFilterValue: null,
    openDetalles: false,
    item: "",
    itemForm: "",
    e6: 1,
    form: {
      respuesta: "",
      procede: false,
    },
    nameRules: [
      (v) => !!v || "Comentario es requerido",
      (v) =>
        v.length < 450 || "Llegaste al limite de caracteres del comentario",
    ],
    evaluarRespuestaAclaracion: false,
    loadingReporte: true,
    verEvidencia: false,
    tipoEvidencia: "",

    vistapreviaimg: "",
    vistapreviaimg1: "",
    vistapreviaimg2: "",
    vistapreviaimg3: "",
    expand: false,
    fileBase64: [],
    evidenciaInconformidad: null,
    nombresFotosEvidencia: [],
    muestraFormInconformidades: false,
    moduloError: false,
  }),
  created() {},
  mounted() {
    this.getInconformidades();
    this.saveMonitoreo();
    this.vistapreviaimg = require("@/assets/img/img-icon.png");
    this.vistapreviaimg1 = require("@/assets/img/img-icon.png");
    this.vistapreviaimg2 = require("@/assets/img/img-icon.png");
    this.vistapreviaimg3 = require("@/assets/img/img-icon.png");
  },
  methods: {
    saveMonitoreo() {
      let params = {
        Opcion: 10,
        pantalla: "Reporte inconformidades",
        token: localStorage.getItem("token"),
      };
      axios
        .post(`${this.$store.getters.getHost}/General/ws-general.php`, params)
        .then((response) => {
          console.log(response.data);
        });
    },
    refrescar(response) {
      if (response == "OK") {
        this.inconformidades = [];
        this.loadingInfo = true;
        this.getInconformidades();
      }
      this.evaluarRespuestaAclaracion = false;
    },
    openEvaluacionDialog(item) {
      this.evaluarRespuestaAclaracion = true;
      this.ID = item.id;
    },
    guardar() {
      let params = {
        Opcion: 7,
        token: localStorage.getItem("token"),
        item: this.item,
        form: this.form,
        evidencia: this.fileBase64,
        evidencia2: [
          this.vistapreviaimg,
          this.vistapreviaimg1,
          this.vistapreviaimg2,
          this.vistapreviaimg3,
        ],
        filename: this.nombresFotosEvidencia,
      };
      this.loading = true;
      axios
        .post(
          `${this.$store.getters.getHost}/Nominas/AdmInconformidades/ws-inconformidades.php`,
          params
        )
        .then((response) => {
          this.loading = false;
          if (response.data.status == "OK") {
            if (response.data.notificar > 0) {
              this.textoBarra = "Respuesta enviada a colaborador.";
            } else {
              this.textoBarra =
                "Respuesta enviada. Falló el envío de notificación a su portal. Favor de notificar a TI.";
            }
            this.colorBarra = "success";
            this.muestraBarra = true;
            this.openDetalles = false;
            this.form["respuesta"] = "";
            this.form["procede"] = false;
            this.inconformidades = [];
            this.loadingInfo = true;
            this.getInconformidades();
          } else if (response.data.status == "ERROR") {
            this.textoBarra = "No se pudo enviar la respuesta.";
            this.colorBarra = "primary";
            this.muestraBarra = true;
          } else if (response.data.status == "EXPRESSION") {
            deleteToken();
          }
        });
    },
    getInconformidades() {
      this.loadingInfo = true;
      this.loadingReporte = true;
      let params = {
        Opcion: 2,
        ruta: this.ruta,
        token: localStorage.getItem("token"),
      };
      axios
        .post(
          `${this.$store.getters.getHost}/Nominas/AdmInconformidades/ws-inconformidades.php`,
          params
        )
        .then((response) => {
          this.loadingReporte = false;
          this.loadingInfo = false;
          if (response.data.status == "OK") {
            this.inconformidades = response.data.inconformidad;
          } else if (response.data.status == "VACIO") {
            this.textoBarra = "Sin registros hasta el momento.";
            this.colorBarra = "primary";
            this.muestraBarra = true;
          } else if (response.data.status == "EXPRESSION") {
            deleteToken();
          } else if (response.data.status == "ERROR" || !response.data.status) {
            this.moduloError = true;
          }
        });
    },
    dialogDetalles(item) {
      this.item = item;
      this.openDetalles = true;
    },
    openEvidencia(item, tipoEvidencia) {
      this.item = item;
      this.verEvidencia = true;
      this.tipoEvidencia = tipoEvidencia;
    },
    cerrarDialog(response) {
      console.log(response);
      this.verEvidencia = false;
    },
    openFileInput() {
      // Obtener la referencia al componente v-file-input
      const fileInput = this.$refs.fileInput;

      // Activar el método click() del componente v-file-input para abrir el selector de archivos
      fileInput.$el.querySelector('input[type="file"]').click();
    },
    cargarImg() {
      if (this.evidenciaInconformidad) {
        let cont = this.fileBase64.length;
        this.nombresFotosEvidencia.push(this.evidenciaInconformidad.name);
        this.fileBase64.push(
          this.readFileAsBase64(this.evidenciaInconformidad, cont)
        );
      }
    },
    async readFileAsBase64(file, cont) {
      return new Promise((resolve, reject) => {
        if (!file) {
          reject(new Error("No file provided."));
          return;
        }

        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = () => {
          if (cont == 0) {
            this.vistapreviaimg = reader.result;
          } else if (cont == 1) {
            this.vistapreviaimg1 = reader.result;
          } else if (cont == 2) {
            this.vistapreviaimg2 = reader.result;
          } else if (cont == 3) {
            this.vistapreviaimg3 = reader.result;
          }
          resolve(reader.result);
        };

        reader.onerror = (error) => {
          reject(error);
        };
      });
    },
    openForm(item) {
      this.itemForm = {
        id: item.id,
        motivo: {
          id: item.idmotivo,
          motivo: item.motivo,
          inicial: item.iniciales,
          departamento: "[" + item.DEPTID + "] " + item.departamento,
        },
        comentario: item.comentario,
        fechaInconformidad: item.fechaRegistroForm,
        EMPLID: item.EMPLID,
      };
      this.muestraFormInconformidades = true;
    },
    closeFormInconformidades(response) {
      this.muestraFormInconformidades = false;
      if (response == "OK") {
        this.openDetalles = false;
        this.inconformidades = [];
        this.loadingInfo = true;
        this.getInconformidades();
      }
    },
  },
};
</script>
