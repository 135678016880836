import GeneracionDePaseDeSalida from "./components/GeneracionDePaseDeSalida.vue"
import PasesDeSalidaCreados from "./components/PasesDeSalidaCreados.vue"

export default {
    name: 'pases-de-salida',
    components: { GeneracionDePaseDeSalida, PasesDeSalidaCreados },
    props: [],
    data() {
        return {
            generaPase: false,
            key: 0
        }
    },
    computed: {

    },
    mounted() {

    },
    methods: {
        cierraGeneraPase(respuesta) {
            this.generaPase = false;
            if (respuesta > 0) {
                this.key++;
            }
        }
    }
}