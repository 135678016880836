<template>
  <div>
    <v-row>
      <v-col cols="12" style="display:flex; justify-content:center;">
        <v-card elevation="1" max-width="650" class="rounded-lg mt-4">
          <v-card-title class="primary white--text subtitle-1 pa-4">
            Selecciona las fechas entre las que se mostrará la asistencia de tu
            equipo.
          </v-card-title>
          <v-card-text
            class="pb-0"
            style="display: flex; justify-content: center;"
          >
            <v-text-field
              v-model="dateRangeText"
              placeholder="Selecciona el rango de fechas"
              style="max-width: 350px;"
              label="Selecciona el rango de fechas"
              prepend-icon="mdi-calendar"
              readonly
              @click="dialog = true"
            ></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              class="rounded-lg font-weight-bold"
              depressed
              color="primary"
              dark
              @click="filtrar()"
            >
              <v-icon left>
                mdi-filter
              </v-icon>
              Filtrar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <v-card-text>
      <div v-if="loadInformation">
        <v-row class="fill-height" align-content="center" justify="center">
          <v-col class="text-subtitle-1 text-center" cols="12">
            Obteniendo información
          </v-col>
          <v-col cols="6">
            <v-progress-linear
              color="blue darken-4"
              indeterminate
              rounded
              height="6"
            ></v-progress-linear>
          </v-col>
        </v-row>
      </div>
      <v-sheet v-else class="rounded-lg elevation-1 pa-4">
        <div
          v-show="report_to_export.length > 0"
          style="display: flex; justify-content: right"
        >
          <vue-json-to-csv
            :json-data="report_to_export"
            :labels="headers_report_to_export"
            csv-title="reporteDeAsistencias"
          >
            <v-btn
              small
              class="rounded-lg"
              elevation="0"
              color="success"
              :loading="loadingReport"
            >
              Exportar
              <v-icon right small> mdi-microsoft-excel </v-icon>
            </v-btn>
          </vue-json-to-csv>
        </div>
        <div v-for="semana in apiAsistencias" :key="semana.week">
          <v-row>
            <v-col cols="4" style="display: flex; align-items: center">
              <h2 style="font-weight: bold">Semana: {{ semana.week }}.</h2>
            </v-col>
            <v-col cols="8">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Buscar..."
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" style="display: flex; align-items: center">
              <h3 class="text-left" style="margin-top: -51px;">
                Del {{ semana.begin }} al {{ semana.end }}
              </h3>
            </v-col>
          </v-row>

          <v-data-table
            :headers="headers"
            :items="semana.data"
            :search="search"
            :items-per-page="itemsPerPage"
            item-key="emplid"
            :footer-props="{
              'items-per-page-options': [5, 10],
              'items-per-page-text': 'Elementos por página',
            }"
            no-data-text="No hay registros"
            :header-props="{
              'short-by-text': 'Ordenar por',
            }"
            class="elevation-1"
            dense
          >
            <template v-slot:[`item.monday`]="{ item }">
              <reporte-asistencias-informacion-de-dia-comite
                :InfoDay="item.asistencias.monday" :empleado="item.emplid" @reloadData="reloadData"
              />
            </template>
            <template v-slot:[`item.tuesday`]="{ item }">
              <reporte-asistencias-informacion-de-dia-comite
                :InfoDay="item.asistencias.tuesday" :empleado="item.emplid" @reloadData="reloadData"
              />
            </template>
            <template v-slot:[`item.wednesday`]="{ item }">
              <reporte-asistencias-informacion-de-dia-comite
                :InfoDay="item.asistencias.wednesday" :empleado="item.emplid" @reloadData="reloadData"
              />
            </template>
            <template v-slot:[`item.thursday`]="{ item }">
              <reporte-asistencias-informacion-de-dia-comite
                :InfoDay="item.asistencias.thursday" :empleado="item.emplid" @reloadData="reloadData"
              />
            </template>
            <template v-slot:[`item.friday`]="{ item }">
              <reporte-asistencias-informacion-de-dia-comite
                :InfoDay="item.asistencias.friday" :empleado="item.emplid" @reloadData="reloadData"
              />
            </template>
            <template v-slot:[`item.saturday`]="{ item }">
              <reporte-asistencias-informacion-de-dia-comite
                :InfoDay="item.asistencias.saturday" :empleado="item.emplid" @reloadData="reloadData"
              />
            </template>
            <template v-slot:[`item.sunday`]="{ item }">
              <reporte-asistencias-informacion-de-dia-comite
                :InfoDay="item.asistencias.sunday" :empleado="item.emplid" @reloadData="reloadData"
              />
            </template>
            <template v-slot:[`item.faltas`]="{ item }">
              {{ item.faltas }}
            </template>
            <template v-slot:[`item.retardos`]="{ item }">
              {{ item.retardos }}
            </template>
            <template v-slot:[`item.hoursWorked`]="{ item }">
              {{ item.hoursWorked.toFixed(2) }} de {{ item.hoursToWork }}
            </template>
          </v-data-table>
        </div>
      </v-sheet>
    </v-card-text>

    <v-dialog v-model="dialog" max-width="450px">
      <v-date-picker
        v-model="dates"
        max-width="450px"
        v-on:change="cierraDialog()"
        selected-items-text="Seleccionados"
        range
        :min="'2024-05-01'"
        :max="maxDate"
        elevation="1"
        locale="es-mx"
      ></v-date-picker>
    </v-dialog>
  </div>
</template>

<script>
import { formatDate } from "../../ReporteAsistenciasResponsable/components/funciones";
import axios from "axios";
import { deleteToken } from "../../../../../../store/Funciones/funciones";
import ReporteAsistenciasInformacionDeDiaComite from "../../../../../../components/componentsRelacionesLaborales/ReporteAsistenciasInformacionDeDiaComite.vue";
import VueJsonToCsv from "vue-json-to-csv";

export default {
  components: { ReporteAsistenciasInformacionDeDiaComite, VueJsonToCsv },
  data() {
    return {
      dates: [],
      apiAsistencias: [],
      report_to_export: [],
      itemsPerPage: 10,
      maxDate: null,
      headers: [
        {
          text: "# empleado",
          align: "start",
          value: "emplid",
        },
        {
          text: "Nombre",
          value: "name",
        },
        {
          text: "Lunes",
          value: "monday",
        },
        {
          text: "Martes",
          value: "tuesday",
        },
        {
          text: "Miércoles",
          value: "wednesday",
        },
        {
          text: "Jueves",
          value: "thursday",
        },
        {
          text: "Viernes",
          value: "friday",
        },
        {
          text: "Sábado",
          value: "saturday",
        },
        {
          text: "Domingo",
          value: "sunday",
        },
        {
          text: "Faltas",
          value: "faltas",
        },
        {
          text: "Retardos",
          value: "retardos",
        },
        {
        text: "Horas trabajadas",
        value: "hoursWorked",
      },
      ],
      headers_report_to_export: {
        semana: { title: "Semana" },
        NumEmplid: { title: "Empleado" },
        Nombre: { title: "Nombre completo" },
        Responsable: { title: "Responsable" },
        Turno: { title: "Turno" },
        Lunes: { title: "Lunes" },
        Martes: { title: "Martes" },
        Miercoles: { title: "Miércoles" },
        Jueves: { title: "Jueves" },
        Viernes: { title: "Viernes" },
        Sabado: { title: "Sábado" },
        Domingo: { title: "Domingo" },
        Faltas: { title: "Faltas" },
        Retardos: { title: "Retardos" },
        // Incapacidades: { title: "Incapacidades" },
      },
      dialog: false,
      loadInformation: true,
      loadingReport: true,
      search: "",
    };
  },
  computed: {
    dateRangeText() {
      let inicio = "";
      let fin = "";
      if (this.dates[0] != null) {
        inicio = formatDate(this.dates[0]);
      }
      if (this.dates[1] != null) {
        fin = formatDate(this.dates[1]);
      }

      if (this.dates.length == 0) {
        return "";
      }
      return "Del " + inicio + " al " + fin; //this.dates.join(' al ')
    },
  },
  async mounted() {
    let ultimoValidado = await this.obtenUltimoValidado();
    this.dates.push(this.setFormatDate(ultimoValidado[0].inicio));
    this.dates.push(this.setFormatDate(ultimoValidado[0].fin));
    this.maxDate = ultimoValidado[0].fin;
    this.apiAsistencias = await this.obtenAsistencias();
    this.createJSONtoExport();
  },
  methods: {
    async filtrar() {
      this.loadInformation = true;
      this.apiAsistencias = await this.obtenAsistencias();
    },
    obtenAsistencias() {
      const headers = {
        Authorization: localStorage.getItem("token"),
      };

      return new Promise((res) => {
        axios
          .get(
            `${this.$store.getters.getHostNode}/api/get-reporte-asistencia-adm/0/${this.dates[0]}/${this.dates[1]}/2`,
            { headers }
          )
          .then((response) => {
            if (response.data.status == "EXPSESSION") {
              deleteToken();
            } else {
              res(response.data.data);
            }
          })
          .finally(() => {
            this.loadInformation = false;
          });
      });
    },
    obtenUltimoValidado() {
      const headers = {
        Authorization: localStorage.getItem("token"),
      };

      return new Promise((res) => {
        axios
          .get(`${this.$store.getters.getHostNode}/api/get-ultimo-validado`, {
            headers,
          })
          .then((response) => {
            if (response.data.status == "EXPSESSION") {
              deleteToken();
            } else {
              res(response.data.data);
            }
          })
          // .finally(() => {
          //   this.loadInformation = false;
          // });
      });
    },
    cierraDialog() {
      console.log("test");
      if (this.dates.length == 2) {
        this.dialog = false;
      }
    },
    setFormatDate(date) {
      let fecha = new Date(date);

      let diaUTC = fecha.getUTCDate();
      let mesUTC = fecha.getUTCMonth() + 1;
      let anioUTC = fecha.getUTCFullYear();

      diaUTC = diaUTC < 10 ? "0" + diaUTC : diaUTC;
      mesUTC = mesUTC < 10 ? "0" + mesUTC : mesUTC;

      return anioUTC + "-" + mesUTC + "-" + diaUTC;
    },
    async createJSONtoExport() {
      const promises = this.apiAsistencias.map((semana) => {
        semana.data.map((asistencia) => {
          let json_to_insert = {
            semana: semana.week,
            NumEmplid: asistencia.emplid,
            Nombre: asistencia.name,
            Responsable: asistencia.lead,
            Turno: asistencia.workShift,
            Lunes: this.getStatusExport(asistencia.asistencias.monday),
            Martes: this.getStatusExport(asistencia.asistencias.tuesday),
            Miercoles: this.getStatusExport(asistencia.asistencias.wednesday),
            Jueves: this.getStatusExport(asistencia.asistencias.thursday),
            Viernes: this.getStatusExport(asistencia.asistencias.friday),
            Sabado: this.getStatusExport(asistencia.asistencias.saturday),
            Domingo: this.getStatusExport(asistencia.asistencias.sunday),
            Faltas: asistencia.faltas,
            Retardos: asistencia.retardos,
            // Incapacidades: asistencia.incapacidades,
          };
          this.report_to_export.push(json_to_insert);
        });
      });

      await Promise.all(promises);
      this.loadingReport = false;
    },
    reloadData(){
      this.$emit('reloadData')
    },
    getStatusExport(item) {
      if (![null, undefined].includes(item)) {
        if (item.falta === 1 && !item.festivo) {
          return "falta";
        } else if (item.falta === 1 && item.festivo) {
          return "día festivo";
        } else {
          if (Object.keys(item.incidencia).length > 0) {
            return item.incidencia.details != "" && ["Salida anticipada","Llegada tarde"].includes(item.incidencia.desc)
              ? item.incidencia.desc + ": " + item.incidencia.details + " (" + item.ingreso + " - " + item.salida + ")"
              : item.incidencia.details && !["Salida anticipada","Llegada tarde"].includes(item.incidencia.desc) 
              ? item.incidencia.desc + ": " + item.incidencia.details
              : item.incidencia.desc;
          } else if (!item.labora) {
            return "descansa";
          } else {
            return item.ingreso + " - " + item.salida;
          }
        }
      } else {
        return "";
      }
    },
  },
};
</script>
