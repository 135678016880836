<template>
  <v-container>
    <div
      style="display: flex; justify-content: start; align-items: start;"
      class="mt-4"
    >
      <v-btn
        class="rounded-pill mr-2 font-weight-bold"
        small
        depressed
        :outlined="tab === 1 ? false : true"
        color="primary"
        @click="
          tab = 1;
          updateTable();
        "
        >Desarrollo humano</v-btn
      >
      <v-btn
        class="rounded-pill mr-2 font-weight-bold"
        small
        depressed
        :outlined="tab === 6 ? false : true"
        color="primary"
        @click="
          tab = 6;
          updateTable();
        "
        >Logística</v-btn
      >
      <v-btn
        class="rounded-pill mr-2 font-weight-bold"
        small
        depressed
        :outlined="tab === 7 ? false : true"
        color="primary"
        @click="
          tab = 7;
          updateTable();
        "
        >Operaciones</v-btn
      >
      <v-btn
        class="rounded-pill mr-2 font-weight-bold"
        small
        depressed
        :outlined="tab === 11 ? false : true"
        color="primary"
        @click="
          tab = 11;
          updateTable();
        "
        >Innovación y competitividad</v-btn
      >
    </div>
    <v-card
      class="mt-4 pa-5 rounded-xl elevation-0"
      color="blue-grey lighten-5"
    >
      <v-data-table
        :items="departamentos"
        :headers="headers"
        :loading="loadingData"
        dense
        style="background-color: transparent;"
        :footer-props="{
          'items-per-page-text': 'Elementos por página',
          pageText: '{0}-{1} de {2}',
        }"
        no-data-text="No hay registros"
        loading-text="Cargando registros..."
      >
        <template v-slot:top>
          <div style="display: flex; justify-content: space-between;">
            <v-text-field
              v-model="search"
              dense
              outlined
              class="rounded-pill"
              prepend-inner-icon="mdi-magnify"
              placeholder="Buscar..."
            ></v-text-field>
            <v-spacer></v-spacer>
            <div>
              <vue-json-to-csv
                :json-data="departamentos"
                :labels="headers_to_export"
                :csv-title="'ReporteAutolizadoresPorDepartamento_' + tab"
              >
                <v-tooltip bottom color="green darken-2">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      depressed
                      outlined
                      fab
                      small
                      v-bind="attrs"
                      v-on="on"
                      class="rounded-pill mr-2"
                      color="green darken-2"
                      dark
                    >
                      <v-icon>mdi-microsoft-excel</v-icon>
                    </v-btn>
                  </template>
                  <span>Exportar CSV</span>
                </v-tooltip>
              </vue-json-to-csv>
              <v-tooltip bottom color="blue darken-2">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    depressed
                    fab
                    small
                    outlined
                    v-bind="attrs"
                    v-on="on"
                    class="mr-2"
                    color="blue darken-2"
                    @click="
                      updateDpto = false;
                      muestraDialogDptoDL = true;
                    "
                  >
                    <v-icon>mdi-office-building-plus-outline</v-icon>
                  </v-btn>
                </template>
                <span>Agregar departamento</span>
              </v-tooltip>
              <v-tooltip bottom color="blue-grey darken-2">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    depressed
                    fab
                    small
                    outlined
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    color="blue-grey darken-2"
                    @click="updateTable"
                  >
                    <v-icon>mdi-reload</v-icon>
                  </v-btn>
                </template>
                <span>Actualizar tabla</span>
              </v-tooltip>
            </div>
          </div>
        </template>
        <template v-slot:item.DESCR="{ item }">
          <label class="text-uppercase font-weight-bold">{{
            item.DESCR
          }}</label>
        </template>
        <template v-slot:item.estatus="{ item }">
          <v-chip :color="item.estatus == 1 ? 'teal' : 'pink'" dark small>
            Activo
          </v-chip>
        </template>
        <template v-slot:item.accion="{ item }">
          <v-icon
            :color="item.estatus == 1 ? 'blue darken-2' : 'pink'"
            dark
            @click="
              updateDpto = true;
              auxDpto = item;
              muestraDialogDptoDL = true;
            "
          >
            mdi-pencil-circle
          </v-icon>
          <v-icon
            class="ml-1"
            color="cyan lighten-1"
            dark
            @click="
              deptidSelected = item.id_departamento_ps;
              idRegistro = item.id;
              muestraSolicitantes = true;
            "
          >
            mdi-dots-horizontal-circle
          </v-icon>
        </template>
      </v-data-table>
    </v-card>
    <MuestraSolicitantesPorDepartamento
      v-if="muestraSolicitantes"
      :muestraSolicitantes="muestraSolicitantes"
      :deptid="deptidSelected"
      :idRegistro="idRegistro"
      @ocultaSolicitantes="muestraSolicitantes = false"
    />
    <ModificaDepartamentoDL
      v-if="muestraDialogDptoDL"
      :muestraDialogDptoDL="muestraDialogDptoDL"
      :update="updateDpto"
      :dataDpto="auxDpto"
      @cierraDialogDptoDL="cierraDialogDptoDL"
    />
    <SnackBar
      v-if="muestraBarra"
      :muestraBarra="muestraBarra"
      :color="colorBarra"
      :texto="textoBarra"
      @cierraBarra="muestraBarra = false"
    />
  </v-container>
</template>

<script>
import axios from "axios";
import { deleteToken } from "../../../../../store/Funciones/funciones";
import MuestraSolicitantesPorDepartamento from "./MuestraSolicitantesPorDepartamento";
import ModificaDepartamentoDL from "./ModificaDepartamentoDL.vue";
import VueJsonToCsv from "vue-json-to-csv";
import SnackBar from "../../../General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue";

export default {
  components: {
    MuestraSolicitantesPorDepartamento,
    VueJsonToCsv,
    ModificaDepartamentoDL,
    SnackBar,
  },
  data() {
    return {
      tab: 6,
      departamentos: [],
      muestraSolicitantes: false,
      deptidSelected: null,
      idRegistro: null,
      auxDpto: null,
      headers: [
        { value: "id", text: "ID" },
        { value: "nombrePrimario", text: "AUT. PRIMARIO" },
        { value: "nombreSecundario", text: "AUT. SECUNDARIO" },
        { value: "DESCR", text: "DEPARTAMENTO" },
        { value: "estatus", text: "ESTATUS" },
        { value: "accion", text: "" },
      ],
      headers_to_export: {
        id: { title: "id" },
        nombrePrimario: { title: "AUT. PRIMARIO" },
        nombreSecundario: { title: "AUT. SECUNDARIO" },
        DESCR: { title: "DEPARTAMENTO" },
        estatus: { title: "ESTATUS" },
      },
      search: "",
      textoBarra: "",
      colorBarra: "",
      muestraBarra: false,
      loadingData: false,
      muestraDialogDptoDL: false,
      updateDpto: false,
    };
  },
  async mounted() {
    await this.updateTable();
  },
  methods: {
    async updateTable() {
      this.loadingData = true;
      this.departamentos = await this.getDatosDepartamentos();
    },
    showSnackBar(texto, color = "error") {
      this.textoBarra = texto;
      this.colorBarra = color;
      this.muestraBarra = true;
    },
    getDatosDepartamentos() {
      const headers = {
        Authorization: localStorage.getItem("token"),
      };

      return new Promise((res) => {
        axios
          .get(
            `${this.$store.getters.getHostNode}/api/descanso-laborado/solicitantes/area/${this.tab}`,
            {
              headers,
            }
          )
          .then((response) => {
            if (response.data.status == "EXPSESSION") {
              deleteToken();
            } else if (response.data.status == "OK") {
              res(response.data.data);
            } else {
              res([]);
            }
          })
          .finally(() => {
            this.loadingData = false;
          });
      });
    },
    async cierraDialogDptoDL(respuesta) {
      this.muestraDialogDptoDL = false;
      if (respuesta !== null) {
        console.log(JSON.stringify(respuesta, null, 2))
        const response = !respuesta.update
          ? await this.setDepartamento(respuesta)
          : await this.updateDepartamento(respuesta);
        if (response.status === "OK" && response.data >= 1) {
          this.showSnackBar("Insertado correctamente.", "success");
          await this.updateTable();
        } else if (response.status === "OK" && response.data == 0) {
          this.showSnackBar("ERROR. Se detectó una posible duplicidad.");
        } else {
          this.showSnackBar(
            "ERROR. No se registro el departamento, intente más tarde."
          );
        }
      }
    },
    setDepartamento(dataNewDepto) {
      const headers = {
        Authorization: localStorage.getItem("token"),
      };
      const params = {
        idArea: this.tab,
        idDpto: dataNewDepto.departamento,
        autPrimario: dataNewDepto.autPrimario,
        autSecundario: dataNewDepto.autSecundario,
      };

      return new Promise((res) => {
        axios
          .post(
            `${this.$store.getters.getHostNode}/api/descanso-laborado/departamentos/registra-departamento`,
            params,
            {
              headers,
            }
          )
          .then(async (response) => {
            if (response.data.status == "EXPSESSION") {
              deleteToken();
            } else if (response.data.status == "OK") {
              res(response.data);
            } else {
              res(response.data);
            }
          });
      });
    },
    updateDepartamento(dataNewDepto) {
      const headers = {
        Authorization: localStorage.getItem("token"),
      };
      const params = {
        idArea: this.tab,
        idDpto: dataNewDepto.departamento,
        autPrimario: dataNewDepto.autPrimario,
        autSecundario: dataNewDepto.autSecundario,
      };

      return new Promise((res) => {
        axios
          .post(
            `${this.$store.getters.getHostNode}/api/descanso-laborado/departamentos/modifica-departamento`,
            params,
            {
              headers,
            }
          )
          .then(async (response) => {
            if (response.data.status == "EXPSESSION") {
              deleteToken();
            } else if (response.data.status == "OK") {
              res(response.data);
            } else {
              res(response.data);
            }
          });
      });
    },
  },
};
</script>
