<template>
  <v-card class="rounded-xl elevation-0 pa-3">
    <v-data-table
      dense
      :headers="headers"
      :search="search"
      :items="personal"
      no-data-text="No hay registros para este estatus"
      loading-text="Cargando personal..."
      :loading="loadingData"
      :expanded.sync="expanded"
      show-expand
    >
      <template v-slot:top>
        <div style="display: flex; justify-content: end;">
          <v-text-field
            v-model="search"
            outlined
            dense
            class="rounded-xl"
            style="max-width: 350px;"
            prepend-inner-icon="mdi-table-search"
            placeholder="Buscar en tabla..."
          ></v-text-field>
          <v-tooltip left color="blue lighten-4">
            <template v-slot:activator="{ on, attrs }">
              <v-btn small fab depressed class="ml-2" v-bind="attrs" v-on="on" @click="loadingData = true; updateTable()">
                <v-icon>mdi-reload</v-icon>
              </v-btn>
            </template>
            <label class="text-uppercase font-weight-bold primary--text"
              >Actualizar tabla</label
            >
          </v-tooltip>
        </div>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          {{ item.comentarios }}
        </td>
      </template>
      <template v-slot:item.fecha_inicial="{ item }">
        {{ fixDate(item.fecha_inicial.date.substr(0, 10)) }}
      </template>
      <template v-slot:item.fecha_aplicacion="{ item }">
        {{ fixDate(item.fecha_aplicacion.date.substr(0, 10)) }}
      </template>
      <template v-slot:item.diasTranscurridos="{ item }">
        <div
          class="text-center font-weight-bold"
          :class="
            getColorDiasTranscurridos(item.diasTranscurridos, item.estatus)
          "
        >
          {{ item.diasTranscurridos }}
        </div>
      </template>
      <template v-slot:item.acciones="{ item }">
        <v-tooltip left color="blue lighten-4" v-if="item.estatus === 1">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              color="blue darken-4"
              v-bind="attrs"
              v-on="on"
              @click="openConfirm(item.id, item.NAME)"
            >
              mdi-account-reactivate-outline
            </v-icon>
          </template>
          <label class="text-uppercase font-weight-bold primary--text"
            >Activar</label
          >
        </v-tooltip>
        <v-tooltip left color="green lighten-4" v-if="item.estatus === 2">
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="green darken-4" v-bind="attrs" v-on="on">
              mdi-file-document-edit-outline
            </v-icon>
          </template>
          <label class="text-uppercase font-weight-bold green--text"
            >Capturar evaluadores</label
          >
        </v-tooltip>
        <v-tooltip left color="cyan lighten-4" v-if="item.estatus === 3">
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="cyan darken-4" v-bind="attrs" v-on="on">
              mdi-file-eye-outline
            </v-icon>
          </template>
          <label class="text-uppercase font-weight-bold cyan--text"
            >Ver proceso</label
          >
        </v-tooltip>
        <v-tooltip
          left
          color="pink lighten-4"
          v-if="[2, 3].includes(item.estatus)"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="pink darken-4" v-bind="attrs" v-on="on">
              mdi-minus-circle-outline
            </v-icon>
          </template>
          <label class="text-uppercase font-weight-bold pink--text"
            >Cancelar</label
          >
        </v-tooltip>
      </template>
    </v-data-table>

    <ConfirmDialogReclutamiento
      :showConfirm="muestraConfirm"
      :question="question"
      @cierraConfirm="cierraConfirm"
    />

    <SnackBar
      v-if="muestraBarra"
      :muestraBarra="muestraBarra"
      :texto="textoBarra"
      :color="colorBarra"
      @cierraBarra="muestraBarra = false"
    />
  </v-card>
</template>

<script>
import axios from "axios";
import { formatDate } from "../../../../../../store/Funciones/funciones";
import ConfirmDialogReclutamiento from "../../../../AdmnVacantes/components/ConfirmDialogReclutamiento.vue";
import SnackBar from "../../../../General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue";
export default {
  name: "muestra-personal-temporal",
  components: { ConfirmDialogReclutamiento, SnackBar },
  data() {
    return {
      loadingData: true,
      muestraConfirm: false,
      muestraBarra: false,
      search: "",
      question: "",
      colorBarra: "",
      textoBarra: "",
      expanded: [],
      personal: [],
      idAux: 0,
      headers: [
        {
          text: "ID",
          value: "id",
          class:
            "text-uppercase font-weight-bold blue-grey--text text--darken-2",
        },
        {
          text: "EMPLID",
          value: "EMPLID",
          class:
            "text-uppercase font-weight-bold blue-grey--text text--darken-2",
        },
        {
          text: "Empleado",
          value: "NAME",
          class:
            "text-uppercase font-weight-bold blue-grey--text text--darken-2",
        },
        {
          text: "Estatus",
          value: "estatusDesc",
          class:
            "text-uppercase font-weight-bold blue-grey--text text--darken-2",
        },
        {
          text: "Fecha inicial",
          value: "fecha_inicial",
          class:
            "text-uppercase font-weight-bold blue-grey--text text--darken-2",
        },
        {
          text: "Fecha aplicación",
          value: "fecha_aplicacion",
          class:
            "text-uppercase font-weight-bold blue-grey--text text--darken-2",
        },
        {
          text: "Días transcurridos",
          value: "diasTranscurridos",
          sortable: false,
          class:
            "text-uppercase font-weight-bold blue-grey--text text--darken-2",
        },
        {
          text: "",
          value: "acciones",
          sortable: false,
          class:
            "text-uppercase font-weight-bold blue-grey--text text--darken-2",
        },
      ],
    };
  },
  async mounted() {
    this.personal = await this.getOfertaById();
  },
  methods: {
    muestraSnackBar(texto, color) {
      this.colorBarra = color;
      this.textoBarra = texto;
      this.muestraBarra = true;
    },
    async getOfertaById() {
      let params = {
        Opcion: 1,
        token: localStorage.getItem("token"),
      };
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${this.$store.getters.getHost}/RelacionesLaborales/PlantasAdministrativas/ws-plantas-administrativas.php`,
            params
          )
          .then((response) => {
            if (response.data.status == "OK") {
              resolve(response.data.personal);
            }
          })
          .catch((error) => {
            reject({
              error: error,
            });
          })
          .finally(() => {
            this.loadingData = false;
          });
      });
    },

    getColorDiasTranscurridos(diasTrans, estatus) {
      if (diasTrans < 75 && estatus < 4) {
        return "teal--text text--darken-3";
      } else if (diasTrans >= 75 && diasTrans <= 89 && estatus < 4) {
        return "yellow--text text--accent-3";
      } else if (diasTrans >= 90 && estatus < 4) {
        return "pink--text text--darken-3";
      } else {
        return "black--text";
      }
    },

    openConfirm(id, nombre) {
      this.muestraConfirm = true;
      (this.idAux = id),
        (this.question =
          "¿Realmente deseas activar la evaluación de " + nombre + "?");
    },

    cierraConfirm(respuesta) {
      if (!respuesta) {
        this.muestraConfirm = false;
      } else {
        this.muestraConfirm = false;
        this.loadingData = true;
        this.activateSurvey();
      }
    },

    async activateSurvey() {
      const params = {
        Opcion: 2,
        token: localStorage.getItem("token"),
        idEvaluado: this.idAux,
      };

      try {
        const response = await axios.post(
          `${this.$store.getters.getHost}/RelacionesLaborales/PlantasAdministrativas/ws-plantas-administrativas.php`,
          params
        );

        if (response.data.status == "OK") {
          if (response.data.respuesta.status_tran == "OK") {
            this.muestraSnackBar(response.data.message, "teal darken-1");
            this.personal.length = 0;
            this.personal = await this.getOfertaById();
          } else {
            this.muestraSnackBar(response.data.message, "pink darken-3");
          }
        } else {
          this.muestraSnackBar("Ocurrió un error.", "pink darken-3");
        }
      } catch (error) {
        this.muestraSnackBar(error, "pink darken-3");
      }
    },

    async updateTable() {
      this.personal.length = 0;
      this.personal = await this.getOfertaById();
    },

    fixDate(date) {
      return formatDate(date);
    },
  },
};
</script>
