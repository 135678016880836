<template>
  <v-dialog v-model="muestraDetallesCandidato" persistent max-width="1000" scrollable content-class="fix-border-dialog">
    <v-card class="rounded-xl" color="transparent">
      <v-card-title class="pa-0 blue darken-1">
        <div class="subtitle-1 pl-4 pt-3 pb-1 font-weight-bold white--text">Perfil del candidato.</div>
        <v-spacer></v-spacer>
        <v-btn icon dark class="mr-2" @click="$emit('cierraDetallesCandidato')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-tabs v-model="tab" :height="isXsSmDisplay ? '90' : '30'" :vertical="isXsSmDisplay" dark background-color="blue darken-1">
        <v-tabs-slider color="white"></v-tabs-slider>
        <v-tab> Información personal </v-tab>
        <v-tab> Información de vacante </v-tab>
        <v-tab> Apego a perfil </v-tab>
        <v-tab> Psicometrias </v-tab>
      </v-tabs>

      <v-card-text class="pa-0">
        <v-tabs-items v-model="tab" class="rounded-lg rounded-t-0" style="background-color: transparent !important;">
          <v-tab-item >
            <InformacionPersonalCandidato :idCandidato="idCandidato"/>
          </v-tab-item>
          <v-tab-item>
            <InformacionVacanteCandidato :idCandidato="idCandidato"/>
          </v-tab-item>
          <v-tab-item>
            <InformacionApegoPerfilCandidato :idCandidato="idCandidato"/>
          </v-tab-item>
          <v-tab-item>
            <InformacionPsicometriasCandidato :idCandidato="idCandidato"/>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import InformacionPersonalCandidato from './componentsCandidato/InformacionPersonalCandidato.vue';
import InformacionVacanteCandidato from './componentsCandidato/InformacionVacanteCandidato.vue';
import InformacionApegoPerfilCandidato from './componentsCandidato/InformacionApegoPerfilCandidato.vue';
import InformacionPsicometriasCandidato from './componentsCandidato/InformacionPsicometriasCandidato.vue';

export default {
  name: "detalles-candidato",
  props: ["muestraDetallesCandidato", "idCandidato"],
  components: {
    InformacionPersonalCandidato,
    InformacionVacanteCandidato,
    InformacionApegoPerfilCandidato,
    InformacionPsicometriasCandidato
  },
  computed: {
    isXsSmDisplay() {
      return this.$vuetify.breakpoint.name == "xs" ||
        this.$vuetify.breakpoint.name == "xs"
        ? true
        : false;
    },
  },
  data() {
    return {
      tab: null,
    };
  },
};
</script>

<style>
</style>
