import axios from 'axios'
import SnackBar from '../../MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue'
import {
    deleteToken
} from '../../../../../store/Funciones/funciones'
export default {
    name: 'prestamo-fondo',
    components: {
        SnackBar
    },
    props: [],
    data() {
        return {
            retiraFondo: false,
            guardaFondo: false,
            loadingAhorrar: false,
            loadingRetirar: false,
            loadingData: true,
            modificar: false,
            retirarTodo: false,
            muestraBarra: false,
            sistemaHabilitado: false,
            cantidadRetirar: 0,
            cantidadAhorrar: 0,
            error: "",
            colorBarra: "",
            textoBarra: "",
            user: {},
            rules: [
                v => /^(\d{1,6})(\.\d{1,2})?$/.test(v) || 'Evita usar caracteres especiales.',
                v => v >= 0 || 'Debe ser un valor positivo.',
            ]
        }
    },
    computed: {

    },
    mounted() {
        this.getFechaActual()
            // this.getDataFondo()
    },
    methods: {
        muestraSnackBar(text, color) {
            this.colorBarra = color;
            this.textoBarra = text;
            this.muestraBarra = true;
        },
        getFechaActual() {
            let params = {
                Opcion: 29,
                token: localStorage.getItem("token"),
            };
            axios
                .post(
                    `${this.$store.getters.getHost}/General/ws-general.php`,
                    params
                )
                .then((response) => {
                    this.loadingData = false;

                    if (response.data.status == "EXPRESSION") {
                        deleteToken();
                    } else if (response.data.status == "OK") {
                        let fechaActual = new Date(response.data.fecha.date);
                        const fechaInicio = new Date(fechaActual.getFullYear(), 5, 14); // Crea la fecha de inicio (19 de junio)
                        const fechaFin = new Date(fechaActual.getFullYear(), 5, 22);
                        if (fechaActual >= fechaInicio && fechaActual <= fechaFin) {
                            this.sistemaHabilitado = true;
                            this.getDataFondo()
                        }
                    }
                }).catch(e => {
                    this.error = e;
                });
        },
        getDataFondo() {
            let params = {
                Opcion: 1,
                token: localStorage.getItem("token"),
            };
            axios
                .post(
                    `${this.$store.getters.getHost}/Compensaciones/ws-prestamo-fondo.php`,
                    params
                )
                .then((response) => {
                    this.loadingData = false;
                    if (response.data.status == "EXPRESSION") {
                        deleteToken();
                    } else if (response.data.status == "OK") {
                        this.user = response.data.fondo;
                    }
                }).catch(e => {
                    this.error = e;
                });
        },
        formatMXN(cadena) {
            return cadena.toLocaleString("es-MX", {
                style: "currency",
                currency: "MXN",
            });
        },
        cambiaValorRetiroDeFondo() {
            if (this.retirarTodo) {
                this.cantidadRetirar = parseFloat(this.user.saldoFondo)
            } else {
                this.cantidadRetirar = 0;
            }
        },
        cambiaValorAhorroEnCaja() {
            if (this.retirarTodo) {
                this.cantidadAhorrar = parseFloat(this.user.saldoFondo);
                this.cantidadRetirar = 0;
            } else {
                this.cantidadAhorrar = 0;
                this.cantidadRetirar = 0;
            }
        },
        cleanData() {
            this.retirarTodo = false;
            this.cantidadRetirar = 0;
            this.cantidadAhorrar = 0;
            this.$refs.formRetiro.resetValidation()
            this.$refs.formRetiro.reset()
            this.$refs.formAhorro.resetValidation()
            this.$refs.formAhorro.reset()
        },
        guardarAhorroEnCaja() {
            if (this.$refs.formAhorro.validate()) {
                let params = {
                    Opcion: 2,
                    token: localStorage.getItem("token"),
                    cantidadAhorrar: this.cantidadAhorrar,
                    cantidadRetirar: this.cantidadRetirar,
                    esModicicacion: this.modificar
                };
                axios
                    .post(
                        `${this.$store.getters.getHost}/Compensaciones/ws-prestamo-fondo.php`,
                        params
                    )
                    .then((response) => {
                        this.loadingAhorrar = false;
                        if (response.data.status == "EXPRESSION") {
                            deleteToken();
                        } else if (response.data.status == "OK") {
                            if (response.data.respuesta.status_tran == "OK") {
                                this.muestraSnackBar(response.data.respuesta.message, "primary");
                                this.cleanData()
                                this.user = {}
                                this.modificar = false;
                                this.retiraFondo = false;
                                this.guardaFondo = false;
                                this.loadingData = true;
                                this.getDataFondo()
                            } else {
                                this.muestraSnackBar(response.data.respuesta.message, "pink darken-3");
                            }
                        }
                    }).catch(e => {
                        this.error = e;
                    });
            } else {
                this.loadingAhorrar = false;
            }
        },
        retirarFondo() {
            if (this.$refs.formRetiro.validate()) {
                let params = {
                    Opcion: 2,
                    token: localStorage.getItem("token"),
                    cantidadRetirar: this.cantidadRetirar,
                    esModicicacion: this.modificar
                };
                axios
                    .post(
                        `${this.$store.getters.getHost}/Compensaciones/ws-prestamo-fondo.php`,
                        params
                    )
                    .then((response) => {
                        this.loadingRetirar = false;
                        if (response.data.status == "EXPRESSION") {
                            deleteToken();
                        } else if (response.data.status == "OK") {
                            if (response.data.respuesta.status_tran == "OK") {
                                this.muestraSnackBar(response.data.respuesta.message, "primary");
                                this.cleanData()
                                this.user = {}
                                this.modificar = false;
                                this.retiraFondo = false;
                                this.guardaFondo = false;
                                this.loadingData = true;
                                this.getDataFondo()
                            } else {
                                this.muestraSnackBar(response.data.respuesta.message, "pink darken-3");
                            }
                        }
                    }).catch(e => {
                        this.error = e;
                    });
            } else {
                this.loadingRetirar = false;
            }
        }
    }
}