import axios from 'axios'
import ReporteOlvidoGafete from "./Components/ReporteOlvidoGafete.vue";
import ReporteSalidasAnticipadas from "./Components/ReporteSalidasAnticipadas.vue";
import ReporteLlegadasTarde from "./Components/ReporteLlegadasTarde.vue";
export default {
  name: 'reporte-incidencias-vigilancia',
  components: { ReporteOlvidoGafete, ReporteSalidasAnticipadas, ReporteLlegadasTarde },
  props: [],
  data() {
    return {
      tab: null,
    }
  },
  computed: {

  },
  mounted() {
    this.saveMonitoreo();
  },
  methods: {
    saveMonitoreo() {
      let params = {
          Opcion: 10,
          pantalla: "Reporte Incidencias vigilancia",
          token: localStorage.getItem('token')
      }
      axios.post(`${this.$store.getters.getHost}/General/ws-general.php`, params).then((response) => {
        console.log(response.data)
      })
    },
  }
}


