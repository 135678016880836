<template>
  <v-dialog
    v-model="dialog"
    max-width="50%"
    transition="dialog-bottom-transition"
    persistent
  >
    <v-card>
      <v-card-title class="text-subtitle-2 primary white--text">
        Puntos colaborador
        <v-spacer></v-spacer>
        <v-icon small dark @click="$emit('cierraForm', 'Cancel')"
          >mdi-close</v-icon
        >
      </v-card-title>
      <v-card-text class="pt-5 d-flex justify-center">
        <v-form class="mt-5" ref="form" v-model="valid" lazy-validation>
          <v-row>
            <v-col
              class="py-0 overline d-flex align-center"
              cols="12"
              xl="12"
              lg="12"
              md="12"
              sm="12"
              xs="12"
            >
              <v-text-field
                v-model="form.cuadrante"
                v-on:change="sumarPuntos"
                type="number"
                min="1"
                :rules="reglaPlan"
                outlined
                dense
                label="Cuadrante"
                hint="Ingresa el numero de cuadrante correcto"
                background-color="grey lighten-2"
                color="cyan"
              >
              </v-text-field>
              <!-- <v-simple-table class="px-5" dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">Puntos Obj</th>
                      <th class="text-left">Puntos Mejora</th>
                      <th class="text-left">Puntos GL</th>
                      <th class="text-left">Puntos Proyectos</th>
                      <th class="text-left">Total Desempeño</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr align="center">
                      <td>
                        <v-text-field
                          v-model="form.puntosObj"
                          v-on:change="sumarPuntos"
                          type="number"
                          min="1"
                          :rules="reglaPlan"
                          outlined
                          dense
                          background-color="grey lighten-2"
                          color="cyan"
                        >
                        </v-text-field>
                      </td>
                      <td>
                        <v-text-field
                          v-model="form.puntosMejora"
                          v-on:change="sumarPuntos"
                          type="number"
                          min="1"
                          :rules="reglaPlan"
                          outlined
                          dense
                          background-color="grey lighten-2"
                          color="cyan"
                        >
                        </v-text-field>
                      </td>
                      <td>
                        <v-text-field
                          v-model="form.puntosGL"
                          v-on:change="sumarPuntos"
                          type="number"
                          min="1"
                          :rules="reglaPlan"
                          outlined
                          dense
                          background-color="grey lighten-2"
                          color="cyan"
                        >
                        </v-text-field>
                      </td>
                      <td>
                        <v-text-field
                          v-model="form.puntosProyectos"
                          v-on:change="sumarPuntos"
                          type="number"
                          min="1"
                          :rules="reglaPlan"
                          outlined
                          dense
                          background-color="grey lighten-2"
                          color="cyan"
                        >
                        </v-text-field>
                      </td>
                      <td>
                        <v-text-field
                          v-model="form.totalDesempenio"
                          type="number"
                          min="1"
                          :rules="reglaPlan"
                          outlined
                          dense
                          background-color="grey lighten-2"
                          color="cyan"
                          disabled
                        >
                        </v-text-field>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
            <v-divider></v-divider>
            <v-col
              class="py-0 overline d-flex align-center"
              cols="12"
              xl="12"
              lg="12"
              md="12"
              sm="12"
              xs="12"
            >
              <v-simple-table class="px-5" dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">GPTW</th>
                      <th class="text-left">Porcentaje Satisfacción</th>
                      <th class="text-left">360</th>
                      <th class="text-left">Cultura</th>
                      <th class="text-left">Puntos Eval.</th>
                      <th class="text-left">Año</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr align="center">
                      <td>
                        <v-text-field
                          v-model="form.gptw"
                          type="number"
                          min="1"
                          :rules="reglaPlan"
                          outlined
                          dense
                          background-color="grey lighten-2"
                          color="cyan"
                        >
                        </v-text-field>
                      </td>

                      <td>
                        <v-text-field
                          v-model="form.porcentajeSatisfaccion"
                          type="number"
                          min="1"
                          :rules="reglaPlan"
                          outlined
                          dense
                          background-color="grey lighten-2"
                          color="cyan"
                        >
                        </v-text-field>
                      </td>

                      <td>
                        <v-text-field
                          v-model="form.puntos360"
                          type="number"
                          min="1"
                          :rules="reglaPlan"
                          outlined
                          dense
                          background-color="grey lighten-2"
                          color="cyan"
                        >
                        </v-text-field>
                      </td>

                      <td>
                        <v-text-field
                          v-model="form.cultura"
                          type="number"
                          min="1"
                          :rules="reglaPlan"
                          outlined
                          dense
                          background-color="grey lighten-2"
                          color="cyan"
                        >
                        </v-text-field>
                      </td>

                      <td>
                        <v-text-field
                          v-model="form.puntosEval"
                          type="number"
                          min="1"
                          :rules="reglaPlan"
                          outlined
                          dense
                          background-color="grey lighten-2"
                          color="cyan"
                        >
                        </v-text-field>
                      </td>

                      <td>
                        <v-text-field
                          v-model="form.anio"
                          type="number"
                          min="1"
                          :rules="reglaPlan"
                          outlined
                          dense
                          background-color="grey lighten-2"
                          color="cyan"
                          disabled
                        >
                        </v-text-field>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table> -->
            </v-col>
          </v-row>
        </v-form>
        <!-- {{ form.puesto }} -->
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <div>
          <v-btn
            v-if="form.cuadrante"
            class="rounded-lg elevation-0"
            color="teal darken-1"
            :loading="loading"
            @click="validar"
            dark
            small
          >
            <v-icon left>mdi-send-variant-outline</v-icon> Guardar
          </v-btn>
          <v-btn class="rounded-lg elevation-0" v-else disabled>
            <v-icon left>mdi-send-variant-outline</v-icon> Guardar
          </v-btn>
          <v-btn
            class="rounded-lg elevation-0"
            color="teal darken-1"
            :loading="loading"
            @click="openDialogCuadrante(item)"
            dark
            small
          >
            <v-icon left>mdi-crop-square</v-icon> Ver cuadrante
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
    <SnackBarErrorIncidencias
      v-if="muestraBarra"
      :muestraBarra="muestraBarra"
      :texto="textoBarra"
      :color="colorBarra"
      @cierraBarra="muestraBarra = false"
    />
    <cuadrante-mapa-de-talento
      v-if="dialogCuadrante"
      :dialog="dialogCuadrante"
      :item="item"
      @cierraCuadrante="cierraCuadrante"
    ></cuadrante-mapa-de-talento>
  </v-dialog>
</template>

<script>
import axios from "axios";
import { deleteToken } from "../../../../../store/Funciones/funciones";
import SnackBarErrorIncidencias from "../../../General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue";
import CuadranteMapaDeTalento from "./CuadranteMapaDeTalento.vue";
export default {
  name: "form-mapa-de-talento",
  components: {
    SnackBarErrorIncidencias,
    CuadranteMapaDeTalento,
  },
  props: ["dialog", "item"],
  data: () => ({
    textoBarra: "",
    colorBarra: "",
    muestraBarra: false,

    valid: true,
    loading: false,

    reglaPlan: [
      (value) => !!value || "Este campo es requerido",
      (value) =>
        /^\d+(\.\d+)?$/.test(value) ||
        "Ingrese solo números o números decimales",
    ],
    form: [],
    dialogCuadrante: false,
  }),
  created() {},
  computed: {},
  mounted() {
    if (this.item) {
      this.form = Object.assign({}, this.item);
    }
  },

  methods: {
    validar() {
      const validar = this.$refs.form.validate();
      if (validar) {
        if (this.form.id) {
          this.editar();
        }
      } else {
        this.textoBarra = "Por favor, complete los datos requeridos";
        this.colorBarra = "error";
        this.muestraBarra = true;
      }
    },

    editar() {
      this.loading = true;
      const headers = { Authorization: localStorage.getItem("token") };
      const params = {
        form: this.form,
        folio: localStorage.getItem("Folio"),
        correo: localStorage.getItem("correo"),
      };
      return new Promise((res) => {
        axios
          .post(
            `${this.$store.getters.getHostNode}/api/update-mapa-de-talento`,
            params,
            { headers }
          )
          .then((response) => {
            if (response.data.status == "OK") {
              res(response.data.data);
              this.textoBarra = response.data.message;
              this.colorBarra = "success";
              this.muestraBarra = true;
              this.clean();
              return new Promise((resolve) => {
                setTimeout(() => {
                  resolve(this.$emit("cierraForm", "OK"));
                }, 1500);
              });
            } else if (response.data.status == "EXPRESSION") {
              deleteToken();
            } else {
              res([]);
            }
          })
          .finally(() => {
            this.loading = false;
          });
      });
    },

    clean() {
      this.$refs.form.reset();
    },

    sumarPuntos() {
      this.form.totalDesempenio =
        Number(this.form.puntosObj) +
        Number(this.form.puntosMejora) +
        Number(this.form.puntosGL) +
        Number(this.form.puntosProyectos);
    },

    openDialogCuadrante(item) {
      this.dialogCuadrante = true;
      this.item = item;
    },

    cierraCuadrante(response) {
      this.dialogCuadrante = false;
      console.log(response);
    },
  },
};
</script>