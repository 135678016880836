<template>
  <v-row justify="center">
    <v-dialog
      v-model="muestraDetalles"
      transition="slide-y-transition"
      persistent
      max-width="500"
    >
      <v-card class="rounded-xl">
        <p class="text-center pb-2 pt-5" style="font-size:1.5em;font-weight:bold;color:rgb(0,120,180);">
          {{titulo}}
        </p>
        <v-card-text>
          <div class="div-princ">
            <v-card elevation="2" class="text-center rounded-lg">
              <v-card-text class="body-1">
                {{texto}}
                <p class="text-center" style="font-weight:bold;">{{subtitle}}</p>
                {{subtext}}
              </v-card-text>
              <div v-show="![null, undefined].includes(table)">
                <v-simple-table dense>
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>EMPLID</th>
                      <th>NOMBRE</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in table" :key="index">
                      <td>{{ item.ID }}</td>
                      <td>{{ item.EMPLID }}</td>
                      <td>{{ item.NOMBRE }}</td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </div>
            </v-card>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn block class="rounded-xl" color="teal" dark elevation="5" @click="$emit('cierraDetalles')">
            aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: ["muestraDetalles", "texto", "titulo", "subtitle", "subtext", "table"],
};
</script>

<style>
.div-princ {
  max-width: 500px;
  max-height: 500px;
  position: relative;
}
</style>
