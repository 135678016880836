import axios from 'axios'
import { deleteToken } from '../../../../../../store/Funciones/funciones'
import SnackBar from "../../../../General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue";
import MantenimientoModulo from "../../../../.././/../components/MantenimientoModulo.vue";
export default {
  name: 'admon-epp',
  components: { SnackBar, MantenimientoModulo },
  props: [],
  data() {
    return {
      textoBarra: '',
      colorBarra: '',
      muestraBarra: false,

      epp: [],
      buscar: '',

      headers: [
        { text: 'Código', value: 'codigo', align: 'center', sortable: true },
        { text: 'Equipo', value: 'equipo', align: 'center', sortable: true },
        { text: 'Medida', value: 'medida', align: 'center', sortable: true },
        { text: 'Días vigencia', value: 'diasVigencia', align: 'center', sortable: true },
        { text: 'Minimos', value: 'Min', align: 'center', sortable: true },
        { text: 'Maximos', value: 'Max', align: 'center', sortable: true },
        { text: 'Inventario', value: 'Inventario', align: 'center', sortable: true },
        { text: 'Costo', value: 'costo', align: 'center', sortable: true },
        { text: 'Estatus', value: 'habilitado', align: 'center', sortable: true },
        { text: 'Acciones', value: 'actions', align: 'center', sortable: true },
      ],
      dialogForm: false,
      editedIndex: -1,
      defaultItem: {
        id: '',
        equipo: '',
        codigo: '',
        medida: '',
        diasVigencia: '',
        Inventario: '',
        minimos: '',
        maximos: '',
      },
      editedItem: {
        id: '',
        equipo: '',
        codigo: '',
        medida: '',
        diasVigencia: '',
        Inventario: '',
        Min: '',
        Max: '',
        costo: '',
      },
      rulesArticulo: {
        required: value => !!value || 'Campo obligatorio.',
        counter: value => value.length <= 100 || 'Max 100 caracteres',
      },
      rulesCodigo: {
        required: value => !!value || 'Campo obligatorio.',
        counter: value => value.length <= 15 || 'Max 15 caracteres',
      },
      rulesInventario: {
        required: value => !!value || 'Ingrese datos numericos.',
      },
      // rulesMedida: {
      //   required: value => !!value || 'Campo obligatorio.',
      //   counter: value => value.length <= 20 || 'Max 20 caracteres',
      // },

      loading: false,
      loadingInfo: false,
      dialogEstatus: false,
      habilitado: '',
      idHabilitado: '',
      moduloError: false,
    }
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'Nuevo articulo' : 'Editar articulo'
    },
  },
  mounted() {
    this.getCatalogoEPP();
    this.saveMonitoreo();
  },
  methods: {
    saveMonitoreo() {
      let params = {
        Opcion: 10,
        pantalla: "Administración de EPP",
        token: localStorage.getItem('token')
      }
      axios.post(`${this.$store.getters.getHost}/General/ws-general.php`, params).then((response) => {
        console.log(response.data)
      })
    },

    validar() {
      if ((parseFloat(this.editedItem.Max) < parseFloat(this.editedItem.Min)) || (parseFloat(this.editedItem.Max) == parseFloat(this.editedItem.Min))) {
        this.textoBarra = 'No pueden ser los minimos más altos o iguales que los maximos';
        this.colorBarra = 'error';
        this.muestraBarra = true;
      } else {
        this.guardar();
      }
    },

    guardar() {
      let opcion = this.editedIndex == -1 ? 3 : 4;
      let params = {
        Opcion: opcion,
        token: localStorage.getItem('token'),
        epp: this.editedItem
      }
      this.loading = true;
      console.log(params);
      console.log(opcion);
      axios
        .post(
          `${this.$store.getters.getHost}/General/EPP/ws-admon-epp.php`,
          params
        )
        .then((response) => {
          this.loadingInfo = false;
          if (response.data.status == "VACIO") {
            console.log("Vacio");
          } else if (response.data.status == "error") {
            this.textoBarra = 'Algo salió mal.';
            this.colorBarra = 'error';
            this.muestraBarra = true;
          } else if (response.data.status == "OK") {
            this.textoBarra = 'Articulo registrado correctamente.';
            this.colorBarra = 'success';
            this.muestraBarra = true;
            this.clean();
            this.getCatalogoEPP();
          } else if (response.data.status == "EXPRESSION") {
            deleteToken();
          } else if (response.data.status == "existente") {
            this.textoBarra = 'Ya se encuentra un articulo registrado con ese código.';
            this.colorBarra = 'primary';
            this.muestraBarra = true;
          }
          this.loading = false;
        })
        .catch(function (e) {
          alert("Ocurrió un error: " + e);
        });
    },

    openEstatusDialog(item) {
      this.dialogEstatus = true;
      this.habilitado = item.habilitado == 1 ? true : false;
      this.idHabilitado = item.id;
      console.log(this.habilitado);
      console.log(this.idHabilitado);
    },

    cambioEstatus() {
      let params = {
        Opcion: 5,
        id: this.idHabilitado,
        habilitado: this.habilitado,
        token: localStorage.getItem('token'),
      }
      this.loading = true;
      axios
        .post(
          `${this.$store.getters.getHost}/General/EPP/ws-admon-epp.php`,
          params
        )
        .then((response) => {
          this.loadingInfo = false;
          if (response.data.status == "error") {
            this.textoBarra = 'Algo salió mal.';
            this.colorBarra = 'error';
            this.muestraBarra = true;
          } else if (response.data.status == "OK") {
            this.textoBarra = 'Estatus modificado correctamente.';
            this.colorBarra = 'success';
            this.muestraBarra = true;
            this.clean();
            this.getCatalogoEPP();
          } else if (response.data.status == "EXPRESSION") {
            deleteToken();
          }
          this.loading = false;
        })
        .catch(function (e) {
          alert("Ocurrió un error: " + e);
        });
    },

    getCatalogoEPP() {
      let params = {
        Opcion: 1,
        token: localStorage.getItem('token'),
      }
      axios
        .post(
          `${this.$store.getters.getHost}/General/EPP/ws-admon-epp.php`,
          params
        )
        .then((response) => {
          this.loadingInfo = false;
          if (response.data.status == "VACIO") {
            console.log("Vacio");
          } else if (response.data.status == "error" || !response.data.status) {
            this.moduloError = true;
          } else if (response.data.status == "OK") {
            this.epp = response.data.catalogo;
          } else if (response.data.status == "EXPRESSION") {
            deleteToken();
          }
        })
        .catch(function (e) {
          alert("Ocurrió un error: " + e);
        });
    },

    editItem(item) {
      this.editedIndex = this.epp.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogForm = true;
    },

    close() {
      this.dialogForm = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    clean() {
      this.epp = [];
      this.loadingInfo = true;
      this.dialogEstatus = false;
      this.idHabilitado = '';
      this.habilitado = '';
      this.close();
    },
  }
}


