var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.evActivas == 0 && _vm.evaluacionesHist.length == 0 && _vm.loadingData)?_c('v-row',{staticClass:"fill-height",attrs:{"align-content":"center","justify":"center"}},[_c('v-col',{staticClass:"text-subtitle-1 text-center",attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(_vm.loadingText)+" ")]),_c('v-col',{attrs:{"cols":"6"}},[_c('v-progress-linear',{attrs:{"color":"deep-purple accent-4","indeterminate":"","rounded":"","height":"6"}})],1)],1):(
      _vm.evActivas == 0 && _vm.evaluacionesHist.length > 0 && !_vm.sinEvaluaciones
    )?_c('div',[_c('v-card',{staticClass:"rounded-xl",attrs:{"elevation":"2"}},[_c('p',{staticClass:"text-center grey--text text--darken-1",staticStyle:{"font-weight":"600","font-size":"1.15rem"}},[_vm._v(" "+_vm._s(_vm.$t("DH360.evalHistoryTitleTable"))+" ")]),_c('v-data-table',{attrs:{"items":_vm.evaluacionesHist,"headers":_vm.headersHist,"hide-default-footer":"","dense":"","no-data-text":"No hay registros","header-props":_vm.headerProps}})],1)],1):(
      _vm.evActivas == 0 && _vm.evaluacionesHist.length == 0 && _vm.sinEvaluaciones
    )?_c('p',{staticClass:"text-center grey--text text--darken-1",staticStyle:{"font-weight":"500","font-size":"1.15rem"}},[_vm._v(" "+_vm._s(_vm.$t("DH360.noCompanyData"))+" ")]):(
      !_vm.loadingData && _vm.evActivas > 0 && _vm.estatusProyecto.ESTATUS == 'INICIO'
    )?_c('v-container',{attrs:{"fluid":""}},[_c('p',{staticClass:"text-center mb-0 font-weight-bold font-italic caption grey--text text--darken-2"},[_vm._v(" "+_vm._s(_vm.$t("DH360.txtNameProyectData"))+": "+_vm._s(_vm.estatusProyecto.DESCRIPCION)+". "+_vm._s(_vm.$t("DH360.txtDateProyectData"))+": "+_vm._s(_vm.estatusProyecto.FECHA_CREACION)+". ")]),_c('AdmonParticipantes',{attrs:{"participantes":_vm.participantes,"estatus":_vm.estatusProyecto.ESTATUS,"idProyecto":_vm.estatusProyecto.ID,"compania":_vm.compania},on:{"updateEstatus":_vm.updateEstatus}})],1):(
      !_vm.loadingData &&
        _vm.evActivas > 0 &&
        _vm.estatusProyecto.ESTATUS == 'RESPONSABLE'
    )?_c('v-container',{attrs:{"fluid":""}},[_c('p',{staticClass:"text-center mb-0 font-weight-bold font-italic caption grey--text text--darken-2"},[_vm._v(" "+_vm._s(_vm.$t("DH360.txtNameProyectData"))+": "+_vm._s(_vm.estatusProyecto.DESCRIPCION)+". "+_vm._s(_vm.$t("DH360.txtDateProyectData"))+": "+_vm._s(_vm.estatusProyecto.FECHA_CREACION)+". ")]),_c('AdmonFamilias360',{attrs:{"participantes":_vm.participantesDefinitivos,"estatus":_vm.estatusProyecto.ESTATUS,"idProyecto":_vm.estatusProyecto.ID,"proyecto":_vm.estatusProyecto,"compania":_vm.compania},on:{"updateEstatus":_vm.updateEstatus}})],1):(
      !_vm.loadingData &&
        _vm.evActivas > 0 &&
        (_vm.estatusProyecto.ESTATUS == 'NOTIFICACION1' ||
          _vm.estatusProyecto.ESTATUS == 'PROCESO')
    )?_c('v-container',{attrs:{"fluid":""}},[_c('p',{staticClass:"text-center mb-0 font-weight-bold font-italic caption grey--text text--darken-2"},[_vm._v(" "+_vm._s(_vm.$t("DH360.txtNameProyectData"))+": "+_vm._s(_vm.estatusProyecto.DESCRIPCION)+". "+_vm._s(_vm.$t("DH360.txtDateProyectData"))+": "+_vm._s(_vm.estatusProyecto.FECHA_CREACION)+". ")]),_c('AdmonReportes360',{attrs:{"idProyecto":_vm.estatusProyecto.ID,"company":_vm.estatusProyecto.COMPANIA,"estatus":_vm.estatusProyecto.ESTATUS},on:{"updateEstatus":_vm.updateEstatus}})],1):(
      !_vm.loadingData && _vm.evActivas > 0 && _vm.estatusProyecto.ESTATUS == 'RANKING'
    )?_c('v-container',{staticClass:"px-0 mx-0",attrs:{"fluid":""}},[_c('p',{staticClass:"text-center mb-0 font-weight-bold font-italic caption grey--text text--darken-2"},[_vm._v(" "+_vm._s(_vm.$t("DH360.txtNameProyectData"))+": "+_vm._s(_vm.estatusProyecto.DESCRIPCION)+". "+_vm._s(_vm.$t("DH360.txtDateProyectData"))+": "+_vm._s(_vm.estatusProyecto.FECHA_CREACION)+". ")]),_c('AdmonRanking',{attrs:{"idProyecto":_vm.estatusProyecto.ID,"evaluacionDesempenio":_vm.rankings},on:{"updateEstatus":_vm.updateEstatus}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }