import axios from 'axios'
import { deleteToken } from '../../../../../store/Funciones/funciones';
import SnackBarError from "../../../General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue";
export default {
  name: 'admon-solicitud-capacitaciones',
  components: { SnackBarError },
  props: [],
  data() {
    return {
      muestraMensaje: false,
      colorMensaje: "",
      textoMensaje: "",
      tab: null,

      dialog: false,
      cargando: true,
      switch1: true,
      buscar: '',

      headers: [
        { text: 'ID', value: 'id', align: 'center', sortable: true },
        // { text: 'DEPTID', value: 'DEPTID', align: 'center', sortable: true },
        // { text: 'Departamento', value: 'DESCR', align: 'center', sortable: true },
        { text: 'Nombre', value: 'nombre', align: 'center', sortable: true },
        { text: 'Nivel', value: 'nivel', align: 'center', sortable: true },
        { text: 'Estado', value: 'estatus', align: 'center', sortable: true },
        { text: 'Acciones', value: 'actions', align: 'center', sortable: true },
      ],
      capacitaciones: [],

      expanded: [],
      solicitudestHeaders: [
        { text: 'solicitud', value: 'id', align: 'center', sortable: true },
        { text: 'Certificación', value: 'Nombre', align: 'center', sortable: true },
        { text: 'Solicitante', value: 'solicitante', align: 'center', sortable: true },
        { text: 'Estatus', value: 'tipoEstatus', align: 'center', sortable: true, filter: this.deptoFilter },
        { text: 'Fecha solicitud', value: 'FechaActualiza', align: 'center', sortable: true },
        { text: 'Acciones', value: 'actions', align: 'center', sortable: true },
        { text: '', value: 'data-table-expand' },
      ],
      solicitudesCapacitaciones: [],
      singleExpand: false,
      editedIndex: -1,
      editedItem: {
        id: '',
        nombre: '',
        estatus: '',
        DEPTID: '',
        nivel: '',
        // estatus: true,
      },
      departamentos: [],
      loading: false,
      defaultItem: {
        id: '',
        nombre: '',
        estatus: true
      },
      confirmDialog: false,
      dialogDelete: false,
      formConfirm: {
        id: '',
        proceso: '',
        comentarioEnProceso: '',
      },
      textConfirm: '',
      loadingProceso: false,
      buscarSolicitudes: '',
      deptoFilterValue: '',
      tiposEstatus: [{ id: 1, DESCR: 'Abierta' }, { id: 2, DESCR: 'En proceso' }, { id: 3, DESCR: 'Cerrada' }]
    }
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'Nueva certificación' : 'Editar certificación'
    },
    switchHabilitado() {
      return this.editedItem.estatus === true ? 'Habilitado' : 'Inhabilitado'
    },
  },
  mounted() {
    this.getDepartamentos();
    this.getCapacitaciones();
    this.getSolicitudesCapacitacion();
  },
  methods: {
    guardar() {
      let params = {
        Opcion: 3,
        capacitacion: this.editedItem,
        token: localStorage.getItem('token')
      }
      this.loading = true;
      axios
        .post(`${this.$store.getters.getHost}/General/SolicitudCapacitaciones/ws-solicitud-capacitaciones.php`, params)
        .then((response) => {
          if (response.data.status == "EXPSESSION") {
            deleteToken();
          } else {
            this.capacitaciones = [];
            this.cargando = true;
            this.textoMensaje = "Solicitud realizada correctamente";
            this.colorMensaje = "success";
            this.muestraMensaje = true;
            this.close();
            this.getCapacitaciones();
          }
        })
      this.loading = false;
    },

    borrar() {
      let params = {
        Opcion: 9,
        idCapacitacion: this.editedItem.id,
        token: localStorage.getItem('token')
      }
      this.loading = true;
      axios
        .post(`${this.$store.getters.getHost}/General/SolicitudCapacitaciones/ws-solicitud-capacitaciones.php`, params)
        .then((response) => {
          if (response.data.status == "EXPSESSION") {
            deleteToken();
          } else {
            this.capacitaciones = [];
            this.cargando = true;
            this.textoMensaje = "Solicitud realizada correctamente";
            this.colorMensaje = "success";
            this.muestraMensaje = true;
            this.close();
            this.getCapacitaciones();
          }
        })
      this.loading = false;
    },

    cambiarEstatus() {
      let params = {
        Opcion: 6,
        estatus: this.formConfirm,
        token: localStorage.getItem('token')
      }
      this.loadingProceso = true;
      axios
        .post(`${this.$store.getters.getHost}/General/SolicitudCapacitaciones/ws-solicitud-capacitaciones.php`, params)
        .then((response) => {
          if (response.data.status == "EXPSESSION") {
            deleteToken();
          } else {
            this.solicitudesCapacitaciones = [];
            this.cargando = true;
            this.textoMensaje = "Solicitud realizada correctamente";
            this.colorMensaje = "success";
            this.muestraMensaje = true;
            this.closeConfirm();
            this.getSolicitudesCapacitacion();
          }
          this.loadingProceso = false;
        })
    },
    getSolicitudesCapacitacion() {
      let params = {
        Opcion: 5,
        admin: 2, // es admin
        token: localStorage.getItem('token')
      }
      axios
        .post(`${this.$store.getters.getHost}/General/SolicitudCapacitaciones/ws-solicitud-capacitaciones.php`, params)
        .then((response) => {
          if (response.data.status == "EXPSESSION") {
            deleteToken();
          } else {
            var data = response.data.respuesta.solicitudes;
            if (data) {
              this.solicitudesCapacitaciones = data;
            }
            this.cargando = false;
          }
        })
    },
    getCapacitaciones() {
      let params = {
        Opcion: 2,
        token: localStorage.getItem('token')
      }
      axios
        .post(`${this.$store.getters.getHost}/General/SolicitudCapacitaciones/ws-solicitud-capacitaciones.php`, params)
        .then((response) => {
          if (response.data.status == "EXPSESSION") {
            deleteToken();
          } else {
            var data = response.data.capacitaciones;
            this.capacitaciones = data;
            this.cargando = false;
          }
        })
    },
    getDepartamentos() {
      let params = {
        Opcion: 8,
        token: localStorage.getItem('token')
      }
      axios
        .post(`${this.$store.getters.getHost}/General/SolicitudCapacitaciones/ws-solicitud-capacitaciones.php`, params)
        .then((response) => {
          if (response.data.status == "EXPSESSION") {
            deleteToken();
          } else {
            var data = response.data.departamentos;
            this.departamentos = data;
          }
        })
    },
    close() {
      this.dialog = false
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    editItem(item) {
      this.editedIndex = this.capacitaciones.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.capacitaciones.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true;
    },

    openConfirm(item) {
      this.confirmDialog = true;
      this.formConfirm.id = item.id;
      this.formConfirm.proceso = item.estatus + 1;
      this.textConfirm = item.estatus == 1 ? "Al confirmar, la solicitud se encontrará en proceso" : "Al confirmar, la solicitud se dará por concluida";
    },
    closeConfirm() {
      this.confirmDialog = false;
      this.formConfirm.id = "";
      this.formConfirm.proceso = "";
      this.formConfirm.comentarioEnProceso = "";
    },

    deptoFilter(value) {
      if (["", null, undefined, "Todos los estatus"].includes(this.deptoFilterValue)) {
        return true;
      }

      let id = this.tiposEstatus.filter((a) => {
        return a.DESCR === this.deptoFilterValue
      })[0]
      return value === id.DESCR;
    },
  }
}


