export default {
    name: 'home',
    components: {},
    props: [],
    data: () => ({
        cards: ['Permisos por aprobar', 'Vacantes pendientes asigandas a tí'],
        drawer: null,
        alu: false,
        links: [
            ['mdi-inbox-arrow-down', 'Inbox'],
            ['mdi-send', 'Send'],
            ['mdi-delete', 'Trash'],
            ['mdi-alert-octagon', 'Spam'],
        ],
    }),
    computed: {

    },
    mounted() {

    },
    methods: {

    }
}