<template>
  <div>
    <v-data-table
      dense
      :headers="headersTurnos"
      :items="turnos"
      :items-per-page="10"
      class="elevation-1"
      :loading="cargando"
      :search="buscar"
      mobile-breakpoint="0"
      :footer-props="{ 'items-per-page-text': 'Elementos por página' }"
      no-data-text="No hay registros"
    >
      <template v-slot:top>
        <v-toolbar
          flat
          color="primary"
          class="rounded-lg rounded-b-0 rounded-t-0"
        >
          <v-text-field
            v-model="buscar"
            :label="$t('DH360.findTextBox')"
            class="mt-7 rounded-lg"
            dense
            clearable
            flat
            solo-inverted
            dark
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialogForm" max-width="750px" persistent>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="teal darken-1"
                dark
                class="mb-2"
                v-bind="attrs"
                v-on="on"
              >
                Nuevo turno
              </v-btn>
            </template>
            <v-card>
              <v-card-title class="text-subtitle-2 primary white--text">
                {{ formTitle }}
                <v-spacer></v-spacer>
                <v-icon small dark @click="close">mdi-close</v-icon>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-data-table
                    :headers="headersHorarios"
                    :items="horariosSemana"
                    hide-default-footer
                    class="elevation-2"
                  >
                    <template v-slot:item.hLunes="props">
                      <v-edit-dialog
                        :return-value.sync="props.item.hLunes"
                        large
                        persistent
                      >
                        {{ props.item.hLunes }}
                        <template v-slot:input>
                          <v-menu
                            ref="menu"
                            v-model="props.item.reloj"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            :return-value.sync="time"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="290px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="props.item.hLunes"
                                label="Selecciona la hora"
                                prepend-icon="mdi-clock-time-four-outline"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-time-picker
                              v-if="props.item.reloj"
                              v-model="props.item.hLunes"
                              full-width
                              ampm-in-title
                              @click:minute="$refs.menu.save(time)"
                            ></v-time-picker>
                          </v-menu>
                        </template>
                      </v-edit-dialog>
                    </template>

                    <template v-slot:item.hMartes="props">
                      <v-edit-dialog
                        :return-value.sync="props.item.hMartes"
                        large
                        persistent
                      >
                        {{ props.item.hMartes }}
                        <template v-slot:input>
                          <v-menu
                            ref="menu"
                            v-model="props.item.reloj2"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            :return-value.sync="time"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="290px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="props.item.hMartes"
                                label="Selecciona la hora"
                                prepend-icon="mdi-clock-time-four-outline"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-time-picker
                              v-if="props.item.reloj2"
                              v-model="props.item.hMartes"
                              full-width
                              ampm-in-title
                              @click:minute="$refs.menu.save(time)"
                            ></v-time-picker>
                          </v-menu>
                        </template>
                      </v-edit-dialog>
                    </template>

                    <template v-slot:item.hMiercoles="props">
                      <v-edit-dialog
                        :return-value.sync="props.item.hMiercoles"
                        large
                        persistent
                      >
                        {{ props.item.hMiercoles }}
                        <template v-slot:input>
                          <v-menu
                            ref="menu"
                            v-model="props.item.reloj3"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            :return-value.sync="time"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="290px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="props.item.hMiercoles"
                                label="Selecciona la hora"
                                prepend-icon="mdi-clock-time-four-outline"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-time-picker
                              v-if="props.item.reloj3"
                              v-model="props.item.hMiercoles"
                              full-width
                              ampm-in-title
                              @click:minute="$refs.menu.save(time)"
                            ></v-time-picker>
                          </v-menu>
                        </template>
                      </v-edit-dialog>
                    </template>

                    <template v-slot:item.hJueves="props">
                      <v-edit-dialog
                        :return-value.sync="props.item.hJueves"
                        large
                        persistent
                      >
                        {{ props.item.hJueves }}
                        <template v-slot:input>
                          <v-menu
                            ref="menu"
                            v-model="props.item.reloj4"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            :return-value.sync="time"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="290px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="props.item.hJueves"
                                label="Selecciona la hora"
                                prepend-icon="mdi-clock-time-four-outline"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-time-picker
                              v-if="props.item.reloj4"
                              v-model="props.item.hJueves"
                              full-width
                              ampm-in-title
                              @click:minute="$refs.menu.save(time)"
                            ></v-time-picker>
                          </v-menu>
                        </template>
                      </v-edit-dialog>
                    </template>

                    <template v-slot:item.hViernes="props">
                      <v-edit-dialog
                        :return-value.sync="props.item.hViernes"
                        large
                        persistent
                      >
                        {{ props.item.hViernes }}
                        <template v-slot:input>
                          <v-menu
                            ref="menu"
                            v-model="props.item.reloj5"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            :return-value.sync="time"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="290px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="props.item.hViernes"
                                label="Selecciona la hora"
                                prepend-icon="mdi-clock-time-four-outline"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-time-picker
                              v-if="props.item.reloj5"
                              v-model="props.item.hViernes"
                              full-width
                              ampm-in-title
                              @click:minute="$refs.menu.save(time)"
                            ></v-time-picker>
                          </v-menu>
                        </template>
                      </v-edit-dialog>
                    </template>

                    <template v-slot:item.hSabado="props">
                      <v-edit-dialog
                        :return-value.sync="props.item.hSabado"
                        large
                        persistent
                      >
                        {{ props.item.hSabado }}
                        <template v-slot:input>
                          <v-menu
                            ref="menu"
                            v-model="props.item.reloj6"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            :return-value.sync="time"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="290px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="props.item.hSabado"
                                label="Selecciona la hora"
                                prepend-icon="mdi-clock-time-four-outline"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-time-picker
                              v-if="props.item.reloj6"
                              v-model="props.item.hSabado"
                              full-width
                              ampm-in-title
                              @click:minute="$refs.menu.save(time)"
                            ></v-time-picker>
                          </v-menu>
                        </template>
                      </v-edit-dialog>
                    </template>

                    <template v-slot:item.hDomingo="props">
                      <v-edit-dialog
                        :return-value.sync="props.item.hDomingo"
                        large
                        persistent
                      >
                        {{ props.item.hDomingo }}
                        <template v-slot:input>
                          <v-menu
                            ref="menu"
                            v-model="props.item.reloj7"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            :return-value.sync="time"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="290px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="props.item.hDomingo"
                                label="Selecciona la hora"
                                prepend-icon="mdi-clock-time-four-outline"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-time-picker
                              v-if="props.item.reloj7"
                              v-model="props.item.hDomingo"
                              full-width
                              ampm-in-title
                              @click:minute="$refs.menu.save(time)"
                            ></v-time-picker>
                          </v-menu>
                        </template>
                      </v-edit-dialog>
                    </template>

                    <template v-slot:item.entradaSalida="{ item }">
                      <div align="left">
                        {{ item.entradaSalida }}
                      </div>
                    </template>
                  </v-data-table>

                  <v-card class="elevation-2 rounded-lg mt-5">
                    <v-card-subtitle
                      >Entradas, salidas y retardos</v-card-subtitle
                    >
                    <v-divider></v-divider>
                    <v-row class="d-flex justify-space-around mx-5 mt-5">
                      <v-col
                        class="py-0"
                        cols="12"
                        xl="12"
                        lg="12"
                        md="12"
                        sm="12"
                        xs="12"
                      >
                        <v-text-field
                          v-model="horarios.Descripcion"
                          label="Nombre del turno / Descripción"
                          filled
                          shaped
                          dense
                        ></v-text-field>
                      </v-col>
                      <v-col
                        class="py-0"
                        cols="12"
                        xl="12"
                        lg="12"
                        md="12"
                        sm="12"
                        xs="12"
                      >
                        <v-select
                          v-model="horarios.diasHabilitados"
                          :items="diasSemana"
                          label="Habilitados"
                          item-text="Dia"
                          item-value="id"
                          filled
                          multiple
                          chips
                          dense
                          persistent-hint
                        ></v-select>
                      </v-col>
                      <v-col
                        class="py-0"
                        cols="12"
                        xl="3"
                        lg="3"
                        md="3"
                        sm="3"
                        xs="3"
                      >
                        <v-text-field
                          v-model="horarios.entradaMin"
                          label="Entrada desde"
                          filled
                          shaped
                          dense
                        ></v-text-field>
                      </v-col>
                      <v-col
                        class="py-0"
                        cols="12"
                        xl="3"
                        lg="3"
                        md="3"
                        sm="3"
                        xs="3"
                      >
                        <v-text-field
                          v-model="horarios.entradaMax"
                          label="Entrada hasta"
                          filled
                          shaped
                          dense
                        ></v-text-field>
                      </v-col>
                      <v-col
                        class="py-0"
                        cols="12"
                        xl="3"
                        lg="3"
                        md="3"
                        sm="3"
                        xs="3"
                      >
                        <v-text-field
                          v-model="horarios.salidaMin"
                          label="Salida desde"
                          filled
                          shaped
                          dense
                        ></v-text-field>
                      </v-col>
                      <v-col
                        class="py-0"
                        cols="12"
                        xl="3"
                        lg="3"
                        md="3"
                        sm="3"
                        xs="3"
                      >
                        <v-text-field
                          v-model="horarios.salidaMax"
                          label="Salida hasta"
                          filled
                          shaped
                          dense
                        ></v-text-field>
                      </v-col>
                      <v-col
                        class="py-0"
                        cols="12"
                        xl="3"
                        lg="3"
                        md="3"
                        sm="3"
                        xs="3"
                      >
                        <v-text-field
                          v-model="horarios.retardo"
                          label="Retardo"
                          filled
                          shaped
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-container>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <div>
                  <v-btn
                    class="rounded-lg elevation-0"
                    color="teal darken-1"
                    dark
                    :loading="loading"
                    @click="validar()"
                  >
                    <v-icon left>mdi-check-circle-outline</v-icon> Guardar
                  </v-btn>
                </div>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogEstatus" max-width="300px" persistent>
            <v-card>
              <v-card-title class="text-subtitle-2 primary white--text">
                Cambiar estatus del articulo
                <v-spacer></v-spacer>
                <v-icon small dark @click="dialogEstatus = false"
                  >mdi mdi-close
                </v-icon>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col
                      class="pb-0"
                      cols="12"
                      xl="8"
                      lg="8"
                      md="8"
                      sm="12"
                      xs="12"
                    >
                      <div v-if="habilitado == false">Inhabilitado</div>
                      <div v-else-if="habilitado == true">Habilitado</div>
                      <v-switch
                        v-model="habilitado"
                        color="primary"
                        hide-details
                      ></v-switch>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  class="rounded-lg elevation-0"
                  color="teal darken-1"
                  dark
                  :loading="loading"
                  @click="cambioEstatus()"
                >
                  <v-icon left>mdi-check-circle-outline</v-icon> Guardar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.Descripcion="{ item }">
        <div align="left">
          {{ item.Descripcion }}
        </div>
      </template>

      <template v-slot:item.habilitado="{ item }">
        <div class="d-flex justify-center">
          <v-chip-group column>
            <v-tooltip v-if="item.habilitado == 1" top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="teal darken-1"
                  v-bind="attrs"
                  v-on="on"
                  @click="openEstatusDialog(item)"
                >
                  <v-icon x-small>mdi mdi-cached</v-icon>
                </v-btn>
              </template>
              <span>Habilitado</span>
            </v-tooltip>
            <v-tooltip v-else top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="red darken-1"
                  v-bind="attrs"
                  v-on="on"
                  @click="openEstatusDialog(item)"
                >
                  <v-icon x-small>mdi mdi-cached</v-icon>
                </v-btn>
              </template>
              <span>Inhabilitado</span>
            </v-tooltip>
          </v-chip-group>
        </div>
      </template>

      <template v-slot:item.actions="{ item }">
        <div class="d-flex justify-center">
          <v-chip-group column>
            <v-btn
              x-small
              rounded
              elevation="1"
              color="teal darken-1"
              dark
              @click="editItem(item)"
            >
              <v-icon x-small>mdi mdi-pencil</v-icon>
            </v-btn>
          </v-chip-group>
        </div>
      </template>
    </v-data-table>

    <SnackBarErrors
      v-if="muestraBarra"
      :muestraBarra="muestraBarra"
      :color="colorBarra"
      :texto="textoBarra"
      @cierraBarra="muestraBarra = false"
    />
  </div>
</template>

<script>
import axios from "axios";
import { deleteToken } from "../../../../../store/Funciones/funciones";
import SnackBarErrors from "../../../General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue";
export default {
  name: "adm-formulario-turnos",
  components: { SnackBarErrors },
  props: [],
  data() {
    return {
      textoBarra: "",
      colorBarra: "",
      muestraBarra: false,
      timeout: 2000,

      cargando: true,
      loading: false,
      buscar: "",
      turnos: [],
      headersTurnos: [
        { text: "iD", value: "Id", align: "center", sortable: true },
        {
          text: "Turno",
          value: "Descripcion",
          align: "center",
          sortable: true,
        },
        {
          text: "Habilitado",
          value: "habilitado",
          align: "center",
          sortable: true,
        },
        {
          text: "Acciones",
          value: "actions",
          align: "center",
          sortable: false,
        },
      ],
      headersHorarios: [
        { text: "", value: "entradaSalida", align: "center", sortable: false },
        { text: "Lunes", value: "hLunes", align: "center", sortable: false },
        { text: "Martes", value: "hMartes", align: "center", sortable: false },
        {
          text: "Miercoles",
          value: "hMiercoles",
          align: "center",
          sortable: false,
        },
        { text: "Jueves", value: "hJueves", align: "center", sortable: false },
        {
          text: "Viernes",
          value: "hViernes",
          align: "center",
          sortable: false,
        },
        { text: "Sabado", value: "hSabado", align: "center", sortable: false },
        {
          text: "Domingo",
          value: "hDomingo",
          align: "center",
          sortable: false,
        },
        {
          text: "Acciones",
          value: "actions",
          align: "center",
          sortable: false,
        },
      ],
      horariosSemana: [
        {
          entradaSalida: "Entrada",
          hLunes: "",
          hMartes: "",
          hMiercoles: "",
          hJueves: "",
          hViernes: "",
          hSabado: "",
          hDomingo: "",
          reloj: false,
          reloj2: false,
          reloj3: false,
          reloj4: false,
          reloj5: false,
          reloj6: false,
          reloj7: false,
        },
        {
          entradaSalida: "Salida",
          hLunes: "",
          hMartes: "",
          hMiercoles: "",
          hJueves: "",
          hViernes: "",
          hSabado: "",
          hDomingo: "",
          reloj: false,
          reloj2: false,
          reloj3: false,
          reloj4: false,
          reloj5: false,
          reloj6: false,
          reloj7: false,
        },
      ],
      dialogForm: false,
      editedIndex: -1,
      time: null,
      menu2: false,
      horarios: {
        id: "",
        Descripcion: "",
        entradaMin: "",
        entradaMax: "",
        salidaMin: "",
        salidaMax: "",
        retardo: "",
        diasHabilitados: [],
      },
      diasSemana: [
        { id: 1, Dia: "Lunes" },
        { id: 2, Dia: "Martes" },
        { id: 3, Dia: "Miercoles" },
        { id: 4, Dia: "Jueves" },
        { id: 5, Dia: "Viernes" },
        { id: 6, Dia: "Sabado" },
        { id: 7, Dia: "Domingo" },
      ],

      dialogEstatus: "",
      habilitado: "",
      idHabilitado: "",
      idTurno: "",
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Nuevo turno" : "Editar turno";
    },
  },
  mounted() {
    this.getTurnos();
  },

  methods: {
    cambioEstatus() {
      this.idHabilitado = this.habilitado === true ? 1 : 0;
      let params = {
        Opcion: 5,
        token: localStorage.getItem("token"),
        idTurno: this.idTurno,
        habilitado: this.idHabilitado,
        habilitadoTF:this.habilitado
      };
      console.log(params);
      this.loading = true;
      axios
        .post(
          `${this.$store.getters.getHost}/nominas/Turnos/ws-turnos.php`,
          params
        )
        .then((response) => {
          if (response.data.status.trim() == "OK") {
            this.textoBarra = "Solicitud realizada correctamente ";
            this.colorBarra = "success";
            this.muestraBarra = true;
            this.dialogForm = false;
            this.cargando = true;
            this.clean();
            this.getTurnos();
          } else if (response.data.status.trim() == "EXPSESSION") {
            deleteToken();
          } else {
            this.textoBarra = "Algo salió mal";
            this.colorBarra = "error";
            this.muestraBarra = true;
          }
          this.loading = false;
        })
        .catch(function (e) {
          let result = e.toString().includes("401");
          if (result) {
            window.location.reload();
          } else {
            alert(e);
          }
        });
    },
    validar() {
      if (
        this.horarios.Descripcion &&
        this.horarios.entradaMin &&
        this.horarios.entradaMax &&
        this.horarios.salidaMin &&
        this.horarios.salidaMax &&
        this.horarios.retardo
      ) {
        this.guardar();
      } else {
        this.textoBarra = "Ni maiz ";
        this.colorBarra = "error";
        this.muestraBarra = true;
      }
    },

    guardar() {
      let params = {
        Opcion: 4,
        token: localStorage.getItem("token"),
        horariosSemana: this.horariosSemana,
        horarios: this.horarios,
      };
      this.loading = true;
      axios
        .post(
          `${this.$store.getters.getHost}/nominas/Turnos/ws-turnos.php`,
          params
        )
        .then((response) => {
          if (response.data.status.trim() == "OK") {
            this.textoBarra = "Solicitud realizada correctamente ";
            this.colorBarra = "success";
            this.muestraBarra = true;
            this.dialogForm = false;
            this.cargando = true;
            this.clean();
            this.getTurnos();
          } else if (response.data.status.trim() == "EXPSESSION") {
            deleteToken();
          } else {
            this.textoBarra = "Algo salió mal";
            this.colorBarra = "error";
            this.muestraBarra = true;
          }
          this.loading = false;
        })
        .catch(function (e) {
          let result = e.toString().includes("401");
          if (result) {
            window.location.reload();
          } else {
            alert(e);
          }
        });
    },
    getTurnos() {
      let params = {
        Opcion: 2,
        token: localStorage.getItem("token"),
      };
      axios
        .post(
          `${this.$store.getters.getHost}/nominas/Turnos/ws-turnos.php`,
          params
        )
        .then((response) => {
          if (response.data.status.trim() == "EXPSESSION") {
            deleteToken();
          } else {
            var data = response.data.data;
            if (data) {
              this.turnos = data;
            }
          }
          this.cargando = false;
        })
        .catch(function (e) {
          let result = e.toString().includes("401");
          if (result) {
            window.location.reload();
          } else {
            alert(e);
          }
        });
    },
    openEstatusDialog(item) {
      console.log(item);
      this.idTurno = item.Id;
      this.dialogEstatus = true;
      this.habilitado = item.habilitado == 1 ? true : false;
      this.idHabilitado = item.habilitado;
    },
    editItem(item) {
      this.editedIndex = this.turnos.indexOf(item);
      this.horarios = Object.assign({}, item);
      this.dialogForm = true;
      this.horariosSemana[0]["hLunes"] = item.heLunes;
      this.horariosSemana[1]["hLunes"] = item.hsLunes;
      this.horariosSemana[0]["hMartes"] = item.heMartes;
      this.horariosSemana[1]["hMartes"] = item.hsMartes;
      this.horariosSemana[0]["hMiercoles"] = item.heMiercoles;
      this.horariosSemana[1]["hMiercoles"] = item.hsMiercoles;
      this.horariosSemana[0]["hJueves"] = item.heJueves;
      this.horariosSemana[1]["hJueves"] = item.hsJueves;
      this.horariosSemana[0]["hViernes"] = item.heViernes;
      this.horariosSemana[1]["hViernes"] = item.hsViernes;
      this.horariosSemana[0]["hSabado"] = item.heSabado;
      this.horariosSemana[1]["hSabado"] = item.hsSabado;
      this.horariosSemana[0]["hDomingo"] = item.heDomingo;
      this.horariosSemana[1]["hDomingo"] = item.hsDomingo;
    },
    clean() {
      this.dialogEstatus = false;
      this.turnos = [];
      this.horarios = {
        id: "",
        Descripcion: "",
        entradaMin: "",
        entradaMax: "",
        salidaMin: "",
        salidaMax: "",
        retardo: "",
        diasHabilitados: [],
      };
      this.horariosSemana = [
        {
          entradaSalida: "Entrada",
          hLunes: "",
          hMartes: "",
          hMiercoles: "",
          hJueves: "",
          hViernes: "",
          hSabado: "",
          hDomingo: "",
          reloj: false,
          reloj2: false,
          reloj3: false,
          reloj4: false,
          reloj5: false,
          reloj6: false,
          reloj7: false,
        },
        {
          entradaSalida: "Salida",
          hLunes: "",
          hMartes: "",
          hMiercoles: "",
          hJueves: "",
          hViernes: "",
          hSabado: "",
          hDomingo: "",
          reloj: false,
          reloj2: false,
          reloj3: false,
          reloj4: false,
          reloj5: false,
          reloj6: false,
          reloj7: false,
        },
      ];
    },
    close() {
      this.dialogForm = false;
      this.$nextTick(() => {
        this.editedIndex = -1;
      });
      this.horarios = {
        id: "",
        Descripcion: "",
        entradaMin: "",
        entradaMax: "",
        salidaMin: "",
        salidaMax: "",
        retardo: "",
        diasHabilitados: [],
      };
      this.horariosSemana = [
        {
          entradaSalida: "Entrada",
          hLunes: "",
          hMartes: "",
          hMiercoles: "",
          hJueves: "",
          hViernes: "",
          hSabado: "",
          hDomingo: "",
          reloj: false,
          reloj2: false,
          reloj3: false,
          reloj4: false,
          reloj5: false,
          reloj6: false,
          reloj7: false,
        },
        {
          entradaSalida: "Salida",
          hLunes: "",
          hMartes: "",
          hMiercoles: "",
          hJueves: "",
          hViernes: "",
          hSabado: "",
          hDomingo: "",
          reloj: false,
          reloj2: false,
          reloj3: false,
          reloj4: false,
          reloj5: false,
          reloj6: false,
          reloj7: false,
        },
      ];
    },
  },
};
</script>
