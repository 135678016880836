import axios from 'axios'
import { deleteToken } from '../../../../../store/Funciones/funciones';
import CargaPoliticas from "../PoliticasUHP/components/CargaPoliticas.vue";
import FormPoliticasReglas from "./components/FormPoliticasReglas.vue";
import FormPoliticasSubReglas from "./components/FormPoliticasSubReglas";
export default {
  name: 'politicas-uhp',
  components: { CargaPoliticas, FormPoliticasReglas, FormPoliticasSubReglas },
  props: [],
  data() {
    return {
      reglasPortal: [],
      expanded: [],
      headers: [
        { text: 'ID', value: 'id', align: 'center', sortable: true },
        { text: 'Regla', value: 'Regla', align: 'center', sortable: true },
        { text: 'Compañia', value: 'compania', align: 'center', sortable: true, filter: this.filterByEAuthvalue },
        { text: '', value: 'data-table-expand' },
      ],
      singleExpand: true,
      cargando: true,
      muestraUpload: false,
      id_parte2: "",
      id_parte1: "",
      nombreDocumento: "",
      nombreCarpeta: "",
      loadingTable: true,
      loading: true,

      model: null,
      nombrePolitica: '',
      fechaVencimiento: '',
      diasFaltantes: '',
      fechaRegistro: '',
      tieneDocumento: false,
      item: '',
      esAdmin: 0,
      compania: '',
      buscar: '',
      dialogFormReglas: false,
      dialogFormSubReglas: false,
      regla: "",
      authFilterValue: null,
      loadingCompanias:true,
      companias:[]
    }
  },
  computed: {

  },
  mounted() {
    this.getCompania();
    this.getCompanias();
    this.saveMonitoreo();
  },
  methods: {
    saveMonitoreo() {
      let params = {
          Opcion: 10,
          pantalla: "Politicas Urrea",
          token: localStorage.getItem('token')
      }
      axios.post(`${this.$store.getters.getHost}/General/ws-general.php`, params).then((response) => {
        console.log(response.data)
      })
    },

    getCompania() {
      let paramsEstatusInc = {
        Opcion: 28,
        token: localStorage.getItem('token')
      };
      axios.post(`${this.$store.getters.getHost}/General/ws-general.php`, paramsEstatusInc)
        .then((response) => {
          if (response.data.status == "EXPSESSION") {
            deleteToken()
          } else {
            this.compania = response.data.compania;
            this.getAdminDocs();
          }
        })

    },

    getCompanias() {
      this.companias.length = 0;
      let params = {
        Opcion: 4,
        token: localStorage.getItem("token"),
      };
      axios
        .post(
          `${this.$store.getters.getHost}/General/AministracionSistema/ws-usuarios.php`,
          params
        )
        .then((response) => {
          this.loadingCompanias = false;
          if (response.data.status == "EXPRESSION") {
            deleteToken();
          } else if (response.data.status == "OK") {
            this.companias = response.data.usuarios;
          }
        });
    },

    getReglasPoliticasUHM() {
      let params = {
        Opcion: 1,
        compania: this.compania,
        esAdmin: this.esAdmin,
        token: localStorage.getItem('token')
      }
      axios
        .post(`${this.$store.getters.getHost}/General/PoliticasUHM/ws-politicasUHM.php`, params)
        .then((response) => {
          if (response.data.status == "EXPSESSION") {
            deleteToken();
          } else {
            var data = response.data.reglas;
            if (data) {
              this.reglasPortal = data;
            }
            this.cargando = false;
            this.loading = false;
          }
        })
    },

    getAdminDocs() {
      let params = {
        Opcion: 4,
        token: localStorage.getItem('token')
      }
      axios
        .post(`${this.$store.getters.getHost}/General/PoliticasUHM/ws-politicasUHM.php`, params)
        .then((response) => {
          if (response.data.status == "EXPSESSION") {
            deleteToken();
          } else {
            var data = response.data.admin;
            if (data) {
              this.esAdmin = data;
            }
            this.cargando = false;
            this.loading = false;
            
            this.getReglasPoliticasUHM();
          }
        })
    },

    saveNombre(item) {
      console.log(item);
      this.id_parte2 = item.id;
      this.id_parte1 = item.id_parte1;
      this.nombreDocumento = item.archivo;
      this.nombreCarpeta = item.nombreCarpeta;
      this.item = item;
      this.nombrePolitica = item.Regla;
      this.fechaVencimiento = item.fechaVencimiento;
      this.diasFaltantes = item.dias_faltantes;
      this.fechaRegistro = item.fechaRegistro;
      this.tieneDocumento = item.nombreDocumento ? true : false;
    },

    openUploadFile() {
      this.muestraUpload = true;
    },

    reloadData() {
      this.muestraUpload = false;
      this.reglasPortal = [];
      this.loadingTable = true;
      this.cargando = true;
      this.loading = true;
      this.model = null;
      this.getReglasPoliticasUHM();
    },

    async openFile() {
      console.log(this.item);
      let params = {
        Opcion: 3,
        id_parte2: this.item.id,
        id_parte1: this.item.id_parte1,
        filename: this.item.nombreDocumento,
        nombreCarpeta: this.item.nombreCarpeta,
        token: localStorage.getItem("token"),
      };
      console.log(params);
      // axios
      //   .post(
      //     `${this.$store.getters.getHost}/General/PoliticasUHM/ws-politicasUHM.php`,
      //     params
      //   )
      //   .then((response) => {
      //     if (response.data.status == "OK") {
      //       // let win = window.open(response.data.file, "Download");
      //       // win.focus();
            
      //     } else if (response.data.status == "EXPRESSION") {
      //       deleteToken();
      //     }
      //   })
      //   .catch(function (e) {
      //     alert("Ocurrió un error: " + e);
      //   });
      try {
        const response = await axios.post(
          `${this.$store.getters.getHost}/General/PoliticasUHM/ws-politicasUHM.php`,
          params
        );

        if (response.data.status === "OK") {
          let arrayDataFile = response.data.file.split(",");
          console.log(arrayDataFile);
          // console.log(arrayDataFile[0]);
          const blob = await this.base64ToBlob(
            arrayDataFile[1],
            arrayDataFile[0]
          );
          const fileUrl = URL.createObjectURL(blob);
          const link = document.createElement("a");
          const type = "pdf";
          console.log(fileUrl);
          if (type != "image") {
            link.href = fileUrl;
            link.download = "documento.pdf";
            link.click();
          }
        } else if (response.data.status === "EXPRESSION") {
          deleteToken();
        } else {
          this.textoBarra =
            "Parece que el archivo está dañado o no existe. Se recomienda reemplazar.";
          this.colorBarra = "pink darken-3";
          this.muestraBarra = true;
        }
      } catch (error) {
        console.error("Ocurrió un error:", error);
        alert("Ocurrió un error al descargar el archivo.");
      }
    },

    async base64ToBlob(base64, contentType = "") {
      // let arrayBase64 = base64.split(",")
      const byteCharacters = atob(base64);
      const byteArrays = [];
      for (let offset = 0; offset < byteCharacters.length; offset += 512) {
        const slice = byteCharacters.slice(offset, offset + 512);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }
      return new Blob(byteArrays, {
        type: contentType,
      });
    },

    openFormReglas(){
      this.dialogFormReglas = true;
    },

    openFormSubpoliticas(item){
      console.log(item);
      this.regla = item;
      this.dialogFormSubReglas = true;
    },

    cierraDialogFormReglas(response){
      if(response.estatus == "OK"){
        console.log("Refrescar");
        this.reloadData();
      }
      this.dialogFormReglas = false;
    },
    
    cierraDialogFormSubReglas(response){
      if(response.estatus == "OK"){
        console.log("Refrescar");
        this.reloadData();
      }
      this.dialogFormSubReglas = false;
    },

    ocultar(){
      this.model = null;
      console.log(this.model);
    },

    filterByEAuthvalue(value) {
      if (!this.authFilterValue) {
          return true;
      }
      return value == this.authFilterValue;
  },
  }
}


