<template>
  <div>
    <v-dialog
      v-model="muestraVacantesDisponibles"
      content-class="fix-border-dialog"
      persistent
      max-width="600"
    >
      <v-card class="rounded-xl">
        <v-card-title class="primary">
          <div class="subtitle-1 white--text">Cambio de vacante postulada.</div>
          <v-spacer></v-spacer>
          <v-icon dark @click="$emit('closeCambioVacante', null)"
            >mdi-close</v-icon
          >
        </v-card-title>
        <v-card-text>
          <v-form ref="form">
            <label class="overline">Vacante a postular </label>
            <v-autocomplete
              v-model="vacanteSeleccionada"
              @change="getDataVacanteSeleccionada"
              :items="nombresVacantes"
              item-text="nombreMostrar"
              :loading="loadingVacantes"
              class="rounded-lg"
              required
              :rules="rules"
              dense
              outlined
              placeholder="Selecciona la vacante..."
            >
            </v-autocomplete>
            <label class="overline">Justifición</label>
            <v-textarea
              v-model="justificacion"
              class="rounded-lg"
              dense
              :rows="2"
              required
              :rules="rules"
              outlined
              placeholder="Escribe un comentario que justifique la acción..."
            >
            </v-textarea>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="pa-0">
          <v-btn
            x-large
            block
            :disabled="
              [0, idVacante].includes(idVacanteSeleccionada) ? true : false
            "
            class="rounded-xl rounded-t-0"
            text
            color="teal darken-2"
            :loading="loadingButton"
            @click="
              loadingButton = true;
              saveCambio();
            "
          >
            guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <SnackBarErrorIncidencias
      v-if="muestraBarra"
      :muestraBarra="muestraBarra"
      :color="colorBarra"
      :texto="textoBarra"
      @cierraBarra="muestraBarra = false"
    />
  </div>
</template>

<script>
import { deleteToken } from "../../../../../store/Funciones/funciones";
import axios from "axios";
import SnackBarErrorIncidencias from "../../../General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue";

export default {
  name: "cambia-vacante-candidato",
  props: [
    "muestraVacantesDisponibles",
    "idVacante",
    "idCandidato",
    "estatusId",
    "tipo"
  ],
  components: { SnackBarErrorIncidencias },
  data() {
    return {
      loadingVacantes: true,
      muestraBarra: false,
      loadingButton: false,
      colorBarra: "",
      textoBarra: "",
      vacanteSeleccionada: "",
      justificacion: "",
      nombresVacantes: [],
      rules: [(v) => !!v || "Campo requerido."],
      idVacanteSeleccionada: 0,
    };
  },
  async mounted() {
    console.log(this.estatusId)
    this.nombresVacantes = await this.getVacantes();
  },
  methods: {
    async getVacantes() {
      const params = {
        Opcion: 45,
        token: localStorage.getItem("token"),
      };

      return new Promise((resolve, reject) => {
        axios
          .post(
            `${this.$store.getters.getHost}/Reclutamiento/ws-admin-reclu.php`,
            params
          )
          .then((response) => {
            this.loadingData = false;
            if (response.data.status == "OK") {
              resolve(response.data.nombresVacantes);
            } else if (response.data.status == "EXPRESSION") {
              deleteToken();
            } else {
              reject([]);
            }
          })
          .catch((e) => {
            alert(e);
          })
          .finally(() => {
            this.loadingVacantes = false;
          });
      });
    },
    getDataVacanteSeleccionada() {
      const vacanteSeleccionadaAux = this.nombresVacantes.find((v) => {
        return v.nombreMostrar == this.vacanteSeleccionada;
      });
      this.idVacanteSeleccionada = vacanteSeleccionadaAux.id;
    },
    saveCambio() {
      if (this.$refs.form.validate()) {
        const params = {
          Opcion: 22,
          token: localStorage.getItem("token"),
          idVacanteActual: this.idVacante,
          idCandidato: this.idCandidato,
          estatus: this.tipo === 2 ? 3 : this.estatusId,
          idVacanteNueva: this.idVacanteSeleccionada,
          comment: this.justificacion,
        };
        axios
          .post(
            `${this.$store.getters.getHost}/Reclutamiento/ws-admin-candidatos.php`,
            params
          )
          .then((response) => {
            if (response.data.status != "EXPSESSION") {
              if (response.data.status == "OK") {
                if (response.data.respuesta.status_tran == "OK") {
                  this.muestraSnackBar(
                    response.data.respuesta.message,
                    "teal darken-1"
                  );
                  this.idVacanteSeleccionada = 0;
                  this.$refs.form.reset();
                  this.$emit("closeCambioVacante", 1);
                } else {
                  this.muestraSnackBar(
                    response.data.respuesta.message,
                    "pink darken-3"
                  );
                }
              } else {
                this.muestraSnackBar("Ocurrio un error", "pink darken-3");
              }
            } else {
              deleteToken();
            }
          })
          .catch((e) => {
            this.muestraSnackBar(e, "pink darken-3");
          })
          .finally(() => {
            this.loadingButton = false;
          });
      } else {
        alert("Hay error");
      }
    },
    muestraSnackBar(text, color) {
      this.colorBarra = color;
      this.textoBarra = text;
      this.muestraBarra = true;
    },
  },
};
</script>
