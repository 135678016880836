<template>
    <v-card elevation="2" class="mb-12" color="blue-grey lighten-4">
      <v-card-title class="text-subtitle-2 primary white--text py-2">
        <v-row>
          <v-col cols="12" xl="9" lg="9" md="9" sm="12" xs="12" class="d-flex align-center">
            <strong class="font-italic white--text"> 
              * {{preguntas[pageCarousel].nombre}}
            </strong>
            <!-- <v-divider vertical></v-divider>
            <v-spacer></v-spacer> -->
          </v-col>
          <v-col cols="12" xl="3" lg="3" md="3" sm="12" xs="12">
            Pregunta: {{pageCarousel+1}} De: {{preguntas.length}} <br>Categoria: {{categoria}}
          </v-col>
        </v-row>
      </v-card-title>
      <v-container class="pa-4 text-center">
        <v-row align="center" justify="center">
          <v-carousel v-model="pageCarousel" :continuous="false" :cycle="cycle" :show-arrows="false" hide-delimiter-background 
            :height="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'xs' ? '100%' : 275"
          >
            <v-carousel-item v-for="(preg) in preguntas" :key="preg.id" class="mb-10">
              <v-sheet color="blue-grey lighten-4" tile class="mt-2">
                <v-alert prominent color="blue darken-1" dark dense elevation="1">
                  Seleccione una de las cuatro medallas para asignar un valor. <br>
                  <v-row justify="space-between">
                    <v-col cols="12" xl="3" lg="3" md="3" sm="6" xs="6">
                      <v-chip small color="grey lighten-4" class="black--text"><v-icon color="brown lighten-1">mdi-medal</v-icon> Bronce: 1 Punto</v-chip>
                    </v-col>
                    <v-col cols="12" xl="3" lg="3" md="3" sm="6" xs="6">
                      <v-chip small color="grey lighten-4" class="black--text"><v-icon color="grey lighten-1">mdi-medal</v-icon> Plata: 2 Puntos</v-chip>
                    </v-col>
                    <v-col cols="12" xl="3" lg="3" md="3" sm="6" xs="6">
                      <v-chip small color="grey lighten-4" class="black--text"><v-icon color="yellow accent-4">mdi-medal</v-icon> Oro: 3 Puntos</v-chip>
                    </v-col>
                    <v-col cols="12" xl="3" lg="3" md="3" sm="6" xs="6">
                      <v-chip small color="grey lighten-4" class="black--text"><v-icon color="grey lighten-2">mdi-medal</v-icon> Platino: 4 Puntos</v-chip>
                    </v-col>
                  </v-row>
                </v-alert>
                <v-row align="center" justify="center">
                  <template v-for="(item, i) in items">
                    <v-col :key="i" cols="6" xl="3" lg="3" md="3" sm="6" xs="6" style="position: relative;">
                      <v-hover v-slot="{ hover }">
                        <v-card :color="preguntas[pageCarousel].respuesta - 1 == i ? 'teal lighten-2':''" class="pb-2" :elevation="hover ? 12 : 2" :class="{ 'on-hover': hover } " @click="seleccionar({'value':item.value,'index':pageCarousel})">
                          <div :class="{ 'view-images': hover } " align="center">
                            <v-card-title class="py-1">
                                    <p class="mb-0 subheading subtitle-1 text-uppercase">
                                      {{ item.title }}
                                    </p>
                              </v-card-title>
                            <v-img :src="require('@/assets/icons/medallas/' + item.img)" max-height="100" max-width="100"></v-img>
                          </div>
                        </v-card>
                      </v-hover>
                      <v-fab-transition class="pt-5">
                        <v-btn v-if="preguntas[pageCarousel].respuesta - 1 == i" v-show="!hidden" color="white" fab dark small absolute right top>
                          <v-icon color="teal darken-2">mdi-check-bold</v-icon>
                        </v-btn>
                      </v-fab-transition>
                    </v-col>
                  </template>
                </v-row>
              </v-sheet>
            </v-carousel-item>
          </v-carousel>
          <v-card-actions class="d-flex justify-end">
            <v-btn v-if="(pageCarousel) < preguntas.length-1" color="primary" @click="pageCarousel ++" small>
              Continuar
            </v-btn>
            <v-btn v-else-if="(pageCarousel) == preguntas.length-1 && step < 5" color="teal darken-2" dark @click="continuarCategoria()" small>
              Continuar a la siguiente categoria
            </v-btn>
            <v-btn v-else-if="(pageCarousel) == preguntas.length-1 && step == 5" color="teal darken-2" dark @click="openComentariosDialog = true;" small>
              Terminar encuesta
            </v-btn>
          </v-card-actions>
        </v-row>
      </v-container>
      <v-dialog v-model="openComentariosDialog" max-width="600px" persistent>
        <v-card>
          <v-card-title class="text-subtitle-2 primary white--text">
            Comentarios finales
            <v-spacer></v-spacer>
            <v-icon small dark @click="openComentariosDialog = false">mdi mdi-close </v-icon>
          </v-card-title>
          <v-card-text>
            <v-alert icon="mdi-file-document-edit-outline" prominent color="blue darken-1" dark dense elevation="1" class="mt-5"> 
                Escriba las fortalezas y oportunidades que identifica al evaluado
            </v-alert>
            <v-container>
              <v-row>
                <v-col class="pb-0" cols="12" xl="12" lg="12" md="12" sm="12" xs="12">
                  <v-textarea v-model="fortaleza" background-color="grey lighten-2" color="cyan" label="fortaleza"
                  rows="3" row-height="6" counter maxlength="1500"></v-textarea>
                </v-col>
                <v-col class="pb-0" cols="12" xl="12" lg="12" md="12" sm="12" xs="12">
                  <v-textarea v-model="oportunidad" background-color="grey lighten-2" color="cyan" label="oportunidad"
                  rows="3" row-height="6" counter maxlength="1500"></v-textarea>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="rounded-lg elevation-0" color="teal darken-1" dark :loading="loading" @click="ValidarRespuestas()">
              <v-icon left>mdi-check</v-icon> Guardar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <SnackBar v-if="muestraBarra" :muestraBarra="muestraBarra" :texto="textoBarra" :color="colorBarra"
      @cierraBarra="muestraBarra = false" />
    </v-card>
</template>

<script>
// import axios from 'axios'
// import { deleteToken } from '../../../../../../store/Funciones/funciones'
import SnackBar from "../../../../General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue"
export default {
  name: "preguntas-con-medallas",
  components: { SnackBar},
  props: ["step","preguntas","categoria"],
  data: () => ({
    textoBarra: '',
    colorBarra: '',
    muestraBarra: false,

    index:0,
    page:1,
    pageCarousel:0,
    icons: ['mdi-rewind', 'mdi-play', 'mdi-fast-forward','mdi-rewind'],
    items: [
      {
        title: 'Bronce',
        text: `It's New Release Friday`,
        subtext: 'Newly released songs.',
        img: 'medallas-bronce-urrea.png',
        value:1,
      },
      {
        title: 'Plata',
        text: 'Greatest Rock Hits',
        subtext: 'Lose yourself in rock tunes.',
        img: 'medallas-plata-urrea.png',
        value:2,
      },
      {
        title: 'Oro',
        text: 'Ambient Bass',
        subtext: 'Chill beats to mellow you out.',
        img: 'medallas-oro-urrea.png',
        value:3,
      },
      {
        title: 'Platino',
        text: 'Ambient Bass',
        subtext: 'Chill beats to mellow you out.',
        img: 'medallas-platino-urrea.png',
        value:4,
      },
    ],
    transparent: 'rgba(255, 255, 255, 0)',
    hidden:false,
    cycle: false,
    respuestas:[],
    openComentariosDialog:false,
    fortaleza:'',
    oportunidad:'',
    loading:false,
  }),
  created() {},
  mounted() {
    
  },

  methods: {
    seleccionar(item){
        this.preguntas[item.index].respuesta = item.value;
    },
    continuarCategoria(){
      let continuar = 0;
      this.preguntas.map((Element) => {
        if(!Element.respuesta){
          this.textoBarra = 'Para continuar a la siguiente categoria asegurece de responder todas las preguntas.';
          this.colorBarra = 'error';
          this.muestraBarra = true;
          continuar = 1;
        }
      });
      if(continuar == 0){
        this.page = 1;
        this.preguntas.map((Element) => {
          this.respuestas.push({id:Element.id,respuesta:Element.respuesta});
        });
        this.$emit('continuaCategoria', {categoriaSiguiente: this.step+1,respuestas:this.respuestas})
      }
    },

    ValidarRespuestas(){
      this.preguntas.map((Element) => {
        this.respuestas.push({id:Element.id,respuesta:Element.respuesta});
      });
      this.openComentariosDialog = false;
      this.$emit('continuaCategoria', {
        categoriaSiguiente: 'Final',
        respuestas:this.respuestas,
        fortaleza:this.fortaleza,
        oportunidad:this.oportunidad
      });
    },
  },
};
</script>
<style scoped>
.view-images {
  transition: opacity .4s ease-in-out;
}

.view-images:not(.on-hover) {
  opacity: 0.6;
 }

.show-btns {
  color: rgba(255, 255, 255, 1) !important;
}
.carousel__controls {
  color: #ff0000; /* Cambia esto al color que desees */
}
</style>