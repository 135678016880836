<template>
  <v-dialog
    v-model="verVistaPrevia"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card color="blue-grey lighten-5">
      <v-toolbar dense color="primary" flat>
        <v-toolbar-title class="subtitle-1 white--text">
          Vista previa
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-scale-transition>
          <v-btn icon @click="$emit('ocultaVistaPrevia')">
            <v-icon color="white">mdi-close</v-icon>
          </v-btn>
        </v-scale-transition>
      </v-toolbar>
      <v-card-text class="center-item-in-div-column">
        <div
          class="mt-6 mb-4"
          style="display: flex; justify-content: space-between;"
        >
          <v-btn-toggle v-model="toggle_exclusive" rounded dense>
            <v-btn
              :disabled="scale >= 0.35 ? true : false"
              @click="scale += 0.05"
            >
              <v-icon>mdi-magnify-plus-outline</v-icon>
            </v-btn>
            <v-btn
              :disabled="scale <= 0.25 ? true : false"
              @click="scale -= 0.05"
            >
              <v-icon>mdi-magnify-minus-outline</v-icon>
            </v-btn>
          </v-btn-toggle>
          <v-btn
            class="ml-2 rounded-lg"
            depressed
            color="blue accent-2"
            dark
            :loading="cargandoHoja"
            @click="cargandoHoja = true; generarPDF();"
          >
            <v-icon left> mdi-cloud-print-outline </v-icon>
            Generar PDF
          </v-btn>
        </div>
        <v-sheet
          v-if="cargandoHoja"
          :height="350"
          :width="widthSheet"
          elevation="12"
          class="px-3 center-item-in-div-column py-5"
        >
          <div class="text-center">
            <label class="overline">Cargando contenido...</label>
          </div>
          <v-progress-circular
            :size="70"
            :width="7"
            color="primary"
            indeterminate
          ></v-progress-circular>
        </v-sheet>
        <v-sheet
          v-else
          
          :width="widthSheet"
          elevation="12"
          class="px-3 py-5"
        >
          <v-img
            :src="require('@/assets/img/membrete_header_urrea.png')"
            width="100%"
          >
          </v-img>
          <div
            class="px-15 mt-1"
            style=" display:flex; justify-content: end; font-size: 0.75rem !important;"
          >
            <p class="mb-1 mt-1 mr-2">El salto, Jalisco</p>
            <input
              v-model="obtenerFechaFormateada"
              type="text"
              style="text-align: end; max-width: 120px;"
            />
          </div>
          <div class="text-justify px-15 mb-0" style="font-size: 0.75rem;">
            <input
              class="font-weight-bold text-capitalize mb-1"
              type="text"
              style="min-width: 200px;"
              v-model="empleado.Empleado"
            />
            <p class="mb-0">
              Por este medio te presentamos la propuesta económica de trabajo,
              para que colabores con nosotros en el puesto de
              {{ empleado.puestoPromocion }}. en el área de
              {{ empleado.Area.toUpperCase() }}, esperemos sea de tu interés y
              conveniencia. <br />
              Quedando de la siguiente forma el ingreso nominal y global (antes
              de impuestos):
            </p>
          </div>
          <div
            v-if="ofertaEconomicaResultados.length > 0"
            class="center-item-in-div pt-4"
          >
            <!-- {{ ofertaEconomicaResultados }} -->
            <v-card class="rounded-lg rounded-0" elevation="0">
              <table>
                <thead>
                  <tr class="rounded-lg">
                    <th
                      class="primary white--text"
                      style="font-size: 0.7rem !important;"
                    ></th>
                    <th
                      class="primary white--text text-center px-1"
                      style="font-size: 0.7rem !important;"
                      :colspan="3"
                    >
                      {{ ["1"].includes(empleado.Tipo) ? "Inicial" : "Actual" }}
                    </th>
                    <th
                      class="primary white--text text-center px-1"
                      style="font-size: 0.7rem !important;"
                      :colspan="3"
                    >
                      {{
                        ["1"].includes(empleado.Tipo) ? "A la planta" : "Nuevo"
                      }}
                    </th>
                    <th
                      v-show="ofertaEconomicaResultados[0].length > 2"
                      class="primary white--text text-center px-1"
                      style="font-size: 0.7rem !important;"
                      :colspan="3"
                    >
                      {{
                        ["1"].includes(empleado.Tipo)
                          ? "Seis meses"
                          : "Tres meses"
                      }}
                    </th>
                    <th
                      v-show="ofertaEconomicaResultados[0].length > 3"
                      class="primary white--text text-center px-1"
                      style="font-size: 0.7rem !important;"
                      :colspan="3"
                    >
                      Seis meses
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(oferta, i) in ofertaEconomicaResultados" :key="i">
                    <td
                      class="primary white--text px-1"
                      style="font-size: 0.7rem; line-height: 1.2;"
                    >
                      {{ oferta[0].data.desc }}
                    </td>
                    <template v-for="(ofertaColumna, j) in oferta">
                      <td
                        class="blue lighten-3 text-center px-1"
                        style="font-size: 0.7rem !important;"
                        :key="`monto_${i}_${j}`"
                      >
                        {{
                          ofertaColumna.data.desc == "Totales"
                            ? "-"
                            : ofertaColumna.data.desc == "Sueldo diario"
                            ? formatMXN(parseFloat(ofertaColumna.data.monto))
                            : ofertaColumna.data.monto == false
                            ? "No aplica"
                            : ofertaColumna.data.monto == true
                            ? "Aplica"
                            : ofertaColumna.data.monto
                        }}
                      </td>
                      <td
                        class="text-center  px-1"
                        :key="`mensual_${i}_${j}`"
                        style="font-size: 0.7rem !important;"
                      >
                        {{
                          ofertaColumna.data.mensual === false
                            ? "N/A"
                            : ofertaColumna.data.mensual === true
                            ? "Aplica"
                            : formatMXN(ofertaColumna.data.mensual)
                        }}
                      </td>
                      <td
                        class="text-center  px-1"
                        :key="`anual_${i}_${j}`"
                        style="font-size: 0.7rem !important;"
                      >
                        {{
                          ofertaColumna.data.anual === false
                            ? "N/A"
                            : ofertaColumna.data.anual === true
                            ? "Aplica"
                            : formatMXN(ofertaColumna.data.anual)
                        }}
                      </td>
                    </template>
                  </tr>
                </tbody>
              </table>
            </v-card>
          </div>
          <div class="text-justify px-15" style="font-size: 0.75rem;">
            <input v-model="agregaAclaracion" type="checkbox" class="mr-2"/>
            <input
              v-model="aclaraciones"
              class="font-weight-bold blue-grey--text text--lighten-2 my-2"
              style="min-width: 600px;"
            />
          </div>
          <!-- {{ mostrarTablaGrantias }} -->
          <v-row dense>
            <v-col class="center-item-in-div"> 
              <input v-model="mostrarTablaGrantias" type="checkbox" disabled>
              <v-btn @click="mostrarTablaGrantias = !mostrarTablaGrantias ? true:false; mostrarTablaGrantiasCalzada = false;" text small depressed color="cyan">
                <label class="caption text-decoration-underline">Incluir tabla de garantías [NO GDL]</label>
              </v-btn>
            </v-col>
            <v-col class="center-item-in-div"> 
              <input v-model="mostrarTablaGrantiasCalzada" type="checkbox" disabled>
              <v-btn @click="mostrarTablaGrantiasCalzada = !mostrarTablaGrantiasCalzada ? true:false; mostrarTablaGrantias = false;" text small depressed color="cyan">
                <label class="caption text-decoration-underline">Incluir tabla de garantías [GDL]</label>
              </v-btn>
            </v-col>
          </v-row>
          <div v-if="![null, undefined, 0, ''].includes(objetivos)">
            <div v-show="objetivos.length > 0"> 
              <label class="font-weight-bold">Los ajustes de sueldo se realizan posterior a concluir los proyectos asignados que se muestran a continuación</label>
              <ul>
                <li v-for="(item, index) in objetivos" :key="index">
                  {{ item.objetivo }}
                </li>
              </ul>
            </div>
          </div>
          <div class="text-center px-15" style="font-size: 0.75rem;">
            <!-- {{empleado.Tipo}} -->
            <div v-show="empleado.Tipo == 6">
              <div class="text-center">Te Invitamos a continuar con tu esfuerzo y dedicación en tu día a día para continuar creciendo en UHP.</div>
              <div class="text-center">¡Ya comenzaste con un paso y faltan muchos más!</div>
            </div>
            <p class="mb-1">
              De igual forma te mostraremos las prestaciones indirectas que
              ofrece la empresa.
              <br />
              <strong>
                Beneficios únicos Urrea
              </strong>
            </p>
          </div>
          <div class="center-item-in-div">
            <table
              v-show="beneficios.length > 0"
              style="border-collapse: collapse;"
            >
              <thead>
                <th
                  class="font-weight-bold caption text-left px-2 text-uppercase blue-grey--text text--darken-1"
                >
                  Aplica
                </th>
                <th
                  class="font-weight-bold caption text-left px-2 text-uppercase blue-grey--text text--darken-1"
                >
                  Beneficio
                </th>
                <th
                  class="font-weight-bold caption text-left px-2 text-uppercase blue-grey--text text--darken-1"
                >
                  Descripción
                </th>
                <th
                  class="font-weight-bold caption text-left px-2 text-uppercase blue-grey--text text--darken-1"
                >
                  Monto
                </th>
              </thead>
              <tbody>
                <tr v-for="(beneficio, index) in beneficios" :key="index">
                  <td
                    class="text-center"
                    style="border-bottom: 1px solid #000000 !important; border-right: 1px solid #000000 !important;"
                  >
                    <input v-model="beneficio.aplica" type="checkbox" />
                  </td>
                  <td
                    class="py-0 px-2"
                    style="font-size: 0.7rem !important; border-bottom: 1px solid #000000 !important;"
                  >
                    <input v-model="beneficio.beneficio" />
                  </td>
                  <td
                    class="py-0 px-2"
                    style="font-size: 0.7rem !important; border-bottom: 1px solid #000000 !important;"
                  >
                    <input
                      v-model="beneficio.descBeneficio"
                      style="min-width: 450px;"
                    />
                  </td>
                  <td
                    class="py-0 px-2 text-center"
                    style="font-size: 0.7rem !important; border-bottom: 1px solid #000000 !important;"
                  >
                    <input
                      v-model="beneficio.montoAyuda"
                      type="number"
                      style="max-width: 60px;"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </v-sheet>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { deleteToken } from "../../../../../../store/Funciones/funciones";
export default {
  name: "dialog-vista-previa-documento",
  props: ["verVistaPrevia", "empleado"],
  data() {
    return {
      scale: 0.35,
      toggle_exclusive: undefined,
      beneficios: [],
      ofertaEconomicaResultados: [],
      objetivos: [],
      datosOferta: [],
      agregaAclaracion: false,
      aclaraciones: "** Vales y Fondo de ahorro se multiplican con tope legal",
      head_styles: {
        halign: "center",
        valign: "middle",
        lineWidth: 0.25,
        lineColor: [255, 255, 255],
        fillColor: [47, 117, 181],
        cellPadding: 0.5,
      },
      head_benefits_styles: {
        halign: "center",
        valign: "middle",
        lineWidth: 0.25,
        lineColor: [255, 255, 255],
        fillColor: [144, 164, 174],
        textColor: [0, 0, 0],
        cellPadding: 0.5,
      },
      body_styles: {
        halign: "center",
        lineWidth: 0.25,
        lineColor: 255,
        cellPadding: 0.5,
      },
      body_benefits_styles: {
        halign: "start",
        lineWidth: 0.25,
        lineColor: 255,
        cellPadding: 0.5,
      },
      cargandoHoja: true,
      mostrarTablaGrantias: false,
      mostrarTablaGrantiasCalzada: false,
    };
  },
  computed: {
    heightSheet() {
      return 11 * this.scale * 300;
    },
    widthSheet() {
      return 8.5 * this.scale * 300;
    },
    obtenerFechaFormateada() {
      const meses = [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
      ];
      let fecha = new Date();

      const dia = fecha.getDate();
      const mes = fecha.getMonth();
      const anio = fecha.getFullYear();

      const fechaFormateada = dia + " de " + meses[mes] + " de " + anio;
      return fechaFormateada;
    },
  },
  async mounted() {
    // console.log(JSON.stringify(this.empleado, null, 2))
    this.datosOferta = await this.getDataOferta();
    if(this.datosOferta){
      this.mostrarTablaGrantiasCalzada = this.datosOferta.es_calzada === 1 ? true : false;
      this.mostrarTablaGrantias = this.datosOferta.no_es_calzada === 1 ? true : false;
    }
    this.ofertaEconomicaResultados = await this.generaOferta();
    this.beneficios = await this.getBeneficios();
    this.objetivos = await this.getObjetivosPlanDesarrollo();
    this.cargandoHoja = false;
    
  },
  methods: {
    fixName(name, nuevoCaracter = " ") {
      let caracterBuscado = /\//g;
      return name.replace(caracterBuscado, nuevoCaracter).toLowerCase();
    },
    formatMXN(cadena) {
      return cadena.toLocaleString("es-MX", {
        style: "currency",
        currency: "MXN",
      });
    },
    capitalizarTexto(cadena) {
      const palabras = cadena.toLowerCase().split(" ");
      const nombreCompleto = palabras
        .map((palabra) => {
          return palabra.charAt(0).toUpperCase() + palabra.slice(1);
        })
        .join(" ");

      return nombreCompleto;
    },
    async getDataOferta() {
      const params = {
        Opcion: 29,
        token: localStorage.getItem("token"),
        idOferta: this.empleado.id
      };

      return new Promise((resolve, reject) => {
        axios
          .post(
            `${this.$store.getters.getHost}/Nominas/Compensaciones/ws-ofertasEconomicas.php`,
            params
          )
          .then((response) => {
            this.loadingOffers = false;
            if (response.data.status == "EXPRESSION") {
              deleteToken();
            } else {
              if (response.data.status == "OK") {
                resolve(response.data.respuesta);
              } else {
                resolve({})
              }
            }
          })
          .catch((error) => {
            reject({ error: error });
          });
      });
    },
    async getBeneficios() {
      const params = {
        Opcion: 21,
        token: localStorage.getItem("token"),
      };

      return new Promise((resolve, reject) => {
        axios
          .post(
            `${this.$store.getters.getHost}/Nominas/Compensaciones/ws-ofertasEconomicas.php`,
            params
          )
          .then((response) => {
            this.loadingOffers = false;
            if (response.data.status == "EXPRESSION") {
              deleteToken();
            } else {
              if (response.data.status == "OK") {
                resolve(response.data.beneficios);
              }
            }
          })
          .catch((error) => {
            reject({ error: error });
          });
      });
    },
    async getRangos() {
      let params = {
        Opcion: 14,
        token: localStorage.getItem("token"),
      };
      return new Promise((resolve) => {
        axios
          .post(
            `${this.$store.getters.getHost}/Nominas/Compensaciones/ws-ofertasEconomicas.php`,
            params
          )
          .then((response) => {
            if (response.data.status == "OK") {
              resolve(response.data.rangos);
            }
          });
      });
    },
    async getObjetivosPlanDesarrollo(){
      let params = {
        Opcion: 28,
        token: localStorage.getItem("token"),
        idOferta: this.empleado.id
      };
      return new Promise((resolve) => {
        axios
          .post(
            `${this.$store.getters.getHost}/Nominas/Compensaciones/ws-ofertasEconomicas.php`,
            params
          )
          .then((response) => {
            if (response.data.status == "OK") {
              resolve(response.data.respuesta);
            } else {
              resolve([])
            }
          });
      });
    },
    async generaOferta() {
      let oferta = [];
      oferta = await this.getTablasDeOfertaDeSueldos();
      const filas = oferta.length;
      const columnas = oferta[0].length;
      this.ofertaEconomicaResultados.length = 0;
      let matriz = [];
      let sumatorias = [];
      sumatorias[0] = [];
      for (let i = 0; i < columnas; i++) {
        matriz[i] = [];
        for (let j = 0; j < filas; j++) {
          matriz[i][j] = {
            data: oferta[j][i],
            position: {
              x: i,
              y: j,
            },
          };
          if (!sumatorias[0][j]) {
            sumatorias[0][j] = {
              mensual: 0,
              anual: 0,
            };
          }

          if (typeof matriz[i][j].data.mensual === "number") {
            sumatorias[0][j].mensual += matriz[i][j].data.mensual;
          }

          if (typeof matriz[i][j].data.anual === "number") {
            sumatorias[0][j].anual += matriz[i][j].data.anual;
          }
        }
      }
      let listaAux = [];
      for (let j = 0; j < filas; j++) {
        listaAux.push({
          data: {
            desc: "Totales",
            monto: 0,
            mensual: sumatorias[0][j].mensual,
            anual: sumatorias[0][j].anual,
          },
          position: {
            x: matriz.length,
            y: j,
          },
        });
      }

      let matrixCleaned = await this.cleanMatrix(matriz);

      // Se añade fila de totales
      matrixCleaned.push(listaAux);

      return matrixCleaned;
    },

    async cleanMatrix(matrix) {
      let matrizAux = [];

      matrix.map((compensacion) => {
        let bandera = false;
        compensacion.map((item) => {
          if (!["", 0, false].includes(item.data.monto)) {
            bandera = true;
          }
        });
        if (bandera) {
          matrizAux.push(compensacion);
        }
      });

      return matrizAux;
    },

    async getTablasDeOfertaDeSueldos() {
      let tabla = [];
      let salarioDiarioAnterior = 0;
      if (this.empleado.Tipo != 1) {
        salarioDiarioAnterior = await this.getSalarioDiario();
      }
      let salarioDiario = (parseFloat(this.empleado.Salario) * 12) / 365;
      if (["1"].includes(this.empleado.Tipo)) {
        if (this.empleado.Reajuste == 0) {
          let actual = await this.generaTabla(
            salarioDiario,
            this.empleado.Tipo
          );
          let inicio = await this.generaTabla(salarioDiario, null);
          tabla.push(actual);
          tabla.push(inicio);
        } else {
          if (this.empleado.SalariosArray.length >= 3) {
            console.log(
              (parseFloat(this.empleado.SalariosArray[0]) * 12) / 365
            );
            console.log(
              (parseFloat(this.empleado.SalariosArray[1]) * 12) / 365
            );
            console.log(salarioDiario);
            let inicio = await this.generaTabla(
              (parseFloat(this.empleado.SalariosArray[0]) * 12) / 365,
              this.empleado.Tipo
            );
            let planta = await this.generaTabla(
              (parseFloat(this.empleado.SalariosArray[1]) * 12) / 365,
              null
            );
            let seisMeses = await this.generaTabla(salarioDiario, null);
            tabla.push(inicio);
            tabla.push(planta);
            tabla.push(seisMeses);
          } else if (this.empleado.SalariosArray.length == 2) {
            let inicio = await this.generaTabla(
              (parseFloat(this.empleado.SalariosArray[0]) * 12) / 365,
              this.empleado.Tipo
            );
            let planta = await this.generaTabla(salarioDiario, null);
            tabla.push(inicio);
            tabla.push(planta);
          } else {
            let inicio = await this.generaTabla(
              salarioDiario,
              this.empleado.Tipo
            );
            let planta = await this.generaTabla(salarioDiario, null);
            tabla.push(inicio);
            tabla.push(planta);
          }
        }
      } else {
        if (this.empleado.Reajuste == 0) {
          let actual = await this.generaTabla(salarioDiarioAnterior, null);
          let inicio = await this.generaTabla(salarioDiario, null);
          tabla.push(actual);
          tabla.push(inicio);
        } else {
          if (this.empleado.SalariosArray.length >= 3) {
            let actual = await this.generaTabla(salarioDiarioAnterior, null);
            let inicio = await this.generaTabla(
              (parseFloat(this.empleado.SalariosArray[0]) * 12) / 365,
              null
            );
            let tresMeses = await this.generaTabla(
              (parseFloat(this.empleado.SalariosArray[1]) * 12) / 365,
              null
            );
            let seisMeses = await this.generaTabla(salarioDiario, null);
            tabla.push(actual);
            tabla.push(inicio);
            tabla.push(tresMeses);
            tabla.push(seisMeses);
          } else if (this.empleado.SalariosArray.length == 2) {
            let actual = await this.generaTabla(salarioDiarioAnterior, null);
            let inicio = await this.generaTabla(
              (parseFloat(this.empleado.SalariosArray[0]) * 12) / 365,
              null
            );
            let tresMeses = await this.generaTabla(salarioDiario, null);
            tabla.push(actual);
            tabla.push(inicio);
            tabla.push(tresMeses);
          } else {
            let actual = await this.generaTabla(salarioDiarioAnterior, null);
            let inicio = await this.generaTabla(salarioDiario, null);
            tabla.push(actual);
            tabla.push(inicio);
          }
        }
      }
      return tabla;
    },
    async generaTabla(sueldo, tipo) {
      let listaPrestaciones = [];
      const porcentajePrestacion = 0.065;
      let diasAguinaldo = 20;
      let vacaciones = 12;
      let primaVacacional = 0.9;
      let rangos = [];
      let rangoFilter = [];

      if (tipo == 1) {
        primaVacacional = 0.25;
        diasAguinaldo = 15;
      }

      if (this.empleado.Rango_asignado > 0 && tipo != 1) {
        rangos = await this.getRangos();
        rangoFilter = rangos.filter((r) => {
          // console.log(r.Rango+" == "+this.empleado.Rango_asignado, r.Rango == this.empleado.Rango_asignado)
          return r.Rango == this.empleado.Rango_asignado;
        });
        diasAguinaldo = rangoFilter[0].Aguinaldo;
        primaVacacional = rangoFilter[0].Prima_vacacional / 100;
      }
      // console.log(rangoFilter)
      if (tipo != 1) vacaciones = await this.getDiasVacaciones();

      if (vacaciones == undefined) {
        vacaciones = 12;
      }

      listaPrestaciones.push({
        desc: "Sueldo diario",
        monto: sueldo,
        mensual: (sueldo * 365) / 12,
        anual: sueldo * 365,
      });
      if (tipo == 1) {
        listaPrestaciones.push({
          desc: "Fondo de ahorro",
          monto: 0,
          mensual: 0,
          anual: 0,
        });
      } else {
        listaPrestaciones.push({
          desc: "Fondo de ahorro",
          monto: porcentajePrestacion * 100,
          mensual: ((sueldo * porcentajePrestacion * 365) / 12) > 4290.69 ? 4290.69 : ((sueldo * porcentajePrestacion * 365) / 12),
          anual: (sueldo * porcentajePrestacion * 365) > 51488.28 ? 51488.28 : (sueldo * porcentajePrestacion * 365),
        });
      }
      listaPrestaciones.push({
        desc: "Vales de despensa",
        monto: porcentajePrestacion * 100,
        mensual: ((sueldo * porcentajePrestacion * 365) / 12) > 3300.53 ? 3300.53 : (sueldo * porcentajePrestacion * 365) / 12,
        anual: (sueldo * porcentajePrestacion * 365 ) > 39606.36 ? 39606.36 : (sueldo * porcentajePrestacion * 365),
      });
      listaPrestaciones.push({
        desc: "Aguinaldo",
        monto: diasAguinaldo,
        mensual: (sueldo * diasAguinaldo) / 12,
        anual: sueldo * diasAguinaldo,
      });
      listaPrestaciones.push({
        desc: "Vacaciones",
        monto: vacaciones,
        mensual: sueldo * (vacaciones / 12),
        anual: sueldo * (vacaciones / 12) * 12,
      });
      listaPrestaciones.push({
        desc: "Prima vacacional",
        monto: (primaVacacional * 100).toString() + '%',
        mensual: sueldo * (vacaciones / 12) * primaVacacional,
        anual: sueldo * (vacaciones / 12) * 12 * primaVacacional,
      });

      if (
        rangoFilter.length > 0 &&
        this.empleado.Rango_asignado > 0 &&
        rangoFilter[0].ovil != "N/A" &&
        tipo != 1 && this.datosOferta.Automovil_prestacion == 1
      ) {
        listaPrestaciones.push({
          desc: "Automóvil",
          monto: true,
          mensual: true,
          anual: true,
        });
      } else {
        listaPrestaciones.push({
          desc: "Automóvil",
          monto: false,
          mensual: false,
          anual: false,
        });
      }

      if (tipo != 1) {
        listaPrestaciones.push({
          desc: "Bono de desempeño",
          monto: true,
          mensual: true,
          anual: true,
        });
      } else {
        listaPrestaciones.push({
          desc: "Bono de desempeño",
          monto: false,
          mensual: false,
          anual: false,
        });
      }

      if (
        rangoFilter.length > 0 &&
        this.empleado.Rango_asignado > 0 &&
        rangoFilter[0].Revision_medica != "N/A" &&
        tipo != 1
      ) {
        listaPrestaciones.push({
          desc: "Revisión médica",
          monto: true,
          mensual: true,
          anual: true,
        });
      } else {
        listaPrestaciones.push({
          desc: "Revisión médica",
          monto: false,
          mensual: false,
          anual: false,
        });
      }

      listaPrestaciones.push({
        desc: "Seguro de vida",
        monto: true,
        mensual: true,
        anual: true,
      });

      if (rangoFilter.length > 0 && this.empleado.Rango_asignado > 0) {
        if (rangoFilter[0].Gastos_medicos > 0) {
          console.log(rangoFilter[0].Gastos_medicos);
          listaPrestaciones.push({
            desc: "Seguro GMM",
            monto: rangoFilter[0].Gastos_medicos,
            mensual: true,
            anual: true,
          });
        } else {
          {
            listaPrestaciones.push({
              desc: "Seguro GMM",
              monto: 0,
              mensual: false,
              anual: false,
            });
          }
        }
      }
      return listaPrestaciones;
    },
    async getNombreDierDH() {
      const params = {
        Opcion: 31,
        token: localStorage.getItem("token"),
      };
      return new Promise((resolve, reject) => {
        axios
          .post(`${this.$store.getters.getHost}/General/ws-general.php`, params)
          .then((response) => {
            if (response.data.status == "EXPRESSION") {
              deleteToken();
            } else {
              if (response.data.status == "OK") {
                resolve(response.data.nombre);
              } else {
                reject("Nombre completo");
              }
            }
          })
          .catch((error) => {
            alert(error);
            reject("Nombre completo");
          });
      });
    },
    async getSalarioDiario() {
      if (this.empleado.Tipo != 1) {
        let empleadoAux = null;

        if (this.empleado.id_empleado == null) {
          empleadoAux = this.empleado.antWay;
        } else {
          empleadoAux = this.empleado.id_empleado;
        }

        const params = {
          Opcion: 13,
          token: localStorage.getItem("token"),
          empleado: empleadoAux,
        };
        return new Promise((resolve, reject) => {
          axios
            .post(
              `${this.$store.getters.getHost}/Nominas/Compensaciones/ws-ofertasEconomicas.php`,
              params
            )
            .then((response) => {
              if (response.data.status == "EXPRESSION") {
                deleteToken();
              } else {
                if (response.data.status == "OK") {
                  resolve(response.data.resultado);
                } else {
                  reject(1);
                }
              }
            })
            .catch((error) => {
              reject(error);
            });
        });
      }
    },
    async getDiasVacaciones() {
      if (this.empleado.Tipo != "1") {
        let empleadoAux = null;

        if (this.empleado.id_empleado == null) {
          empleadoAux = this.empleado.antWay;
        } else {
          empleadoAux = this.empleado.id_empleado;
        }
        const params = {
          Opcion: 12,
          token: localStorage.getItem("token"),
          empleado: empleadoAux,
        };
        return new Promise((resolve, reject) => {
          axios
            .post(
              `${this.$store.getters.getHost}/Nominas/Compensaciones/ws-ofertasEconomicas.php`,
              params
            )
            .then((response) => {
              if (response.data.status == "EXPRESSION") {
                deleteToken();
              } else {
                if (response.data.status == "OK") {
                  resolve(response.data.resultado);
                } else {
                  reject(12);
                }
              }
            })
            .catch((error) => {
              reject(error);
            });
        });
      }
    },

    async generarPDF() {
      let doc = new jsPDF("m", "mm", "a4"),
        margin = 15;
      let tamTextoEnTablas = 8;
      let aumentoMargenVertical = 0;
      const imgData = require("@/assets/img/membrete_header_urrea.png");
      const imgDataFooter = require("@/assets/img/membrete_footer_urrea.png");

      doc.addImage(imgData, "PNG", 8, 2, 185, 35);

      doc.setFont("helvetica");
      doc.setFontSize(8);

      doc.text("El salto, Jalisco " + this.obtenerFechaFormateada, 140, 30);
      doc.setFont("helvetica", "bold");
      doc.setFontSize(9);
      doc.text(
        this.capitalizarTexto(this.fixName(this.empleado.Empleado)),
        margin,
        38
      );
      doc.setFont("helvetica", "normal");
      doc.text(
        "Por este medio te presentamos la propuesta económica de trabajo, para que colabores con nosotros en el puesto de ",
        margin,
        43
      );
      doc.text(
        this.empleado.puestoPromocion +
          ". en el área de " +
          this.empleado.Area.toUpperCase().trim() +
          ", esperemos sea de tu interés",
        margin,
        47
      );
      doc.text("y conveniencia.", margin, 50);

      doc.setFontSize(8);
      doc.text(
        "Quedando de la siguiente forma el ingreso nominal y global (antesde impuestos).",
        margin,
        54
      );

      let columnStyles = {
        cellPadding: 1,
        0: { fillColor: [47, 117, 181], textColor: [255, 255, 255] },
        1: { fillColor: [221, 235, 247], textColor: [0, 0, 0] },
        4: { fillColor: [221, 235, 247], textColor: [0, 0, 0] },
      };

      if (this.ofertaEconomicaResultados[0].length <= 2) {
        aumentoMargenVertical = 8;
      }

      if (this.ofertaEconomicaResultados[0].length >= 3) {
        columnStyles[7] = { fillColor: [221, 235, 247], textColor: [0, 0, 0] };
        tamTextoEnTablas = 6.5;
      }
      if (this.ofertaEconomicaResultados[0].length == 4) {
        columnStyles[10] = { fillColor: [221, 235, 247], textColor: [0, 0, 0] };
      }

      doc.autoTable({
        head: this.getHeaders(this.ofertaEconomicaResultados[0].length),
        body: this.getTableToPrintPDF(this.ofertaEconomicaResultados),
        startY: 55,
        styles: {
          fontSize: tamTextoEnTablas,
        },
        headStyles: this.head_styles,
        bodyStyles: this.body_styles,
        tableWidth:
          this.ofertaEconomicaResultados[0].length === 2 ? "60%" : "100%",
        columnStyles: columnStyles,
      });

      doc.setTextColor(84, 110, 122);
      doc.setFont("helvetica", "bold");
      if(this.agregaAclaracion){
        doc.text(this.aclaraciones, margin, 105 + aumentoMargenVertical);
      } else {
        aumentoMargenVertical -= 5;
      }
      doc.setTextColor(0, 0, 0);
      doc.setFont("helvetica", "normal");

      if(this.mostrarTablaGrantias){
        doc.setFontSize(6);
        doc.setFont("helvetica", "bold");
        doc.text("Esquema de pago de garantías: comisión sujeta a crecimiento de ventas de las sucursales asignadas.", margin, 110 + aumentoMargenVertical);
        doc.setFont("helvetica", "normal");
        doc.text("De 1% a 5.9%.      --- $1,500", margin + 80, 114 + aumentoMargenVertical);
        doc.text("De 6% a 7.9%.      --- $1,800", margin + 80, 117 + aumentoMargenVertical);
        doc.text("De 8% a 9.9%.      --- $2,000", margin + 80, 120 + aumentoMargenVertical);
        doc.text("De 10% a 11.9%.  --- $2,300", margin + 80, 123 + aumentoMargenVertical);
        doc.text("De 12% a 14.9%.  --- $2,500", margin + 80, 126 + aumentoMargenVertical);
        doc.text("De 15% a Más.      --- $3,000", margin + 80, 129 + aumentoMargenVertical);
        aumentoMargenVertical += 20;
      }

      // console.log(this.mostrarTablaGrantiasCalzada)

      if(this.mostrarTablaGrantiasCalzada){
        doc.setFontSize(6);
        doc.text("Esquema de pago de garantías: comisión sujeta a crecimiento de ventas de las sucursales asignadas.", margin, 110 + aumentoMargenVertical);
        doc.text("De 1% a 5.9%.      --- (mayor o igual a 10%) $1,500, (menor a 10%) $0", margin + 50, 114 + aumentoMargenVertical);
        doc.text("De 6% a 7.9%.      --- (mayor o igual a 10%) $1,800, (menor a 10%) $1,000", margin + 50, 117 + aumentoMargenVertical);
        doc.text("De 8% a 9.9%.      --- (mayor o igual a 10%) $2,000, (menor a 10%) $1,500", margin + 50, 120 + aumentoMargenVertical);
        doc.text("De 10% a 11.9%.  --- (mayor o igual a 10%) $2,300, (menor a 10%) $1,800", margin + 50, 123 + aumentoMargenVertical);
        doc.text("De 12% a 14.9%.  --- (mayor o igual a 10%) $2,500, (menor a 10%) $2,300", margin + 50, 126 + aumentoMargenVertical);
        doc.text("De 15% a Más.      --- (mayor o igual a 10%) $3,000, (menor a 10%) $2,800", margin + 50, 129 + aumentoMargenVertical);
        aumentoMargenVertical += 21;
      }

      doc.setFontSize(8);
      
      
      if(this.objetivos.length > 0){
        doc.autoTable({
          head: [
            [{ content: "Los ajustes de sueldo se realizan posterior a concluir los proyectos asignados que se muestran a continuación", colSpan: 1 }],
          ],
          body: this.getTableObjectivesPDF(this.objetivos),
          bodyStyles: this.body_benefits_styles,
          margin: this.margin * 2,
          startY: 110 + aumentoMargenVertical,
          styles: {
            fontSize: tamTextoEnTablas,
          },
          headStyles: this.head_benefits_styles,
        });

        aumentoMargenVertical = aumentoMargenVertical + (8 + (this.objetivos.length * 4))
      }

      // doc.text(
      //   "De igual forma te mostraremos las prestaciones indirectas que ofrece la empresa.",
      //   53,
      //   110 + aumentoMargenVertical
      // );

      if(this.empleado.Tipo == 6){
        doc.text(
          "Te Invitamos a continuar con tu esfuerzo y dedicación en tu día a día para continuar creciendo en UHP.",
          45,
          110 + aumentoMargenVertical
        );
        doc.setFont("helvetica", "bold");
        doc.text(
          "¡Ya comenzaste con un paso y faltan muchos más!",
          70,
          113 + aumentoMargenVertical
        );
        doc.setFont("helvetica", "normal");

        aumentoMargenVertical += 3
        
        
      }

      doc.autoTable({
        head: [
          [{ content: "Beneficios únicos Urrea (prestaciones indirectas que ofrece la empresa)", colSpan: 3 }],
          [
            { content: "Beneficio", colSpan: 1 },
            { content: "Descripción", colSpan: 1 },
            { content: "Apoyo económico", colSpan: 1 },
          ],
        ],
        body: this.getTableBenefitsPDF(this.beneficios),
        bodyStyles: this.body_benefits_styles,
        margin: this.margin * 2,
        startY: 114 + aumentoMargenVertical,
        styles: {
          fontSize: tamTextoEnTablas,
        },
        headStyles: this.head_benefits_styles,
      });
      // doc.text(
      //   "Sin más por el momento, quedamos a la espera de tus comentarios.",
      //   margin,
      //   196 + aumentoMargenVertical
      // );

      doc.text("ATENTAMENTE", margin + 17, 220 + 20);
      doc.text("________________________________", margin + 2, 230 + 20);
      doc.setFont("helvetica", "bold");
      let nombreDir = await this.getNombreDierDH();
      doc.text(this.capitalizarTexto(nombreDir), margin + 8, 234 + 20);
      doc.setFont("helvetica", "normal");
      doc.text("Dirección de desarrollo humano", margin + 7, 239 + 20);

      doc.text("ATENTAMENTE", margin + 145, 220 + 20);
      doc.text("________________________________", margin + 130, 230 + 20);
      doc.setFont("helvetica", "bold");
      doc.text(
        this.capitalizarTexto(this.fixName(this.empleado.Empleado)),
        margin + 136,
        234 + 20
      );
      doc.setFont("helvetica", "normal");
      doc.text(
        this.capitalizarTexto(this.empleado.puestoPromocion),
        margin + 136,
        239 + 20
      );
      doc.text("fecha de firma:____________________", margin + 130, 250 + 20);

      doc.addImage(imgDataFooter, "PNG", 60, 275, 140, 17);
      this.cargandoHoja = false;
      doc.save("oferta-economica.pdf");
    },

    getTableToPrintPDF(matriz) {
      let lista = [];
      matriz.map((m) => {
        let listaAux = [];
        listaAux.push(m[0].data.desc);
        m.map((item) => {
          //   console.log(item);
          if (item.data.desc == "Sueldo diario") {
            listaAux.push(this.formatMXN(parseFloat(item.data.monto)));
          } else if (item.data.desc == "Totales") {
            listaAux.push("");
          } else if (item.data.monto === true) {
            listaAux.push("Aplica");
          } else if (item.data.monto === false) {
            listaAux.push("No aplica");
          } else {
            listaAux.push(item.data.monto);
          }

          if (item.data.mensual == true) {
            listaAux.push("Aplica");
          } else if (item.data.mensual == false) {
            listaAux.push("No aplica");
          } else {
            listaAux.push(this.formatMXN(item.data.mensual));
          }

          if (item.data.anual == true) {
            listaAux.push("Aplica");
          } else if (item.data.anual == false) {
            listaAux.push("No aplica");
          } else {
            listaAux.push(this.formatMXN(item.data.anual));
          }
        });
        lista.push(listaAux);
      });

      return lista;
    },

    getHeaders(longitud) {
      let headers = [];
      let headersAxTitulos = [];
      let headersAxSubTitulos = [];
      headersAxTitulos.push({ content: "" });
      headersAxSubTitulos.push({ content: "" });

      if (longitud == 2) {
        if (this.empleado.Tipo == "1") {
          headersAxTitulos.push({ content: "Al inicio", colSpan: 3 });
        } else {
          headersAxTitulos.push({ content: "Actual", colSpan: 3 });
        }
        if (this.empleado.Tipo == "1") {
          headersAxTitulos.push({ content: "A la planta", colSpan: 3 });
        } else {
          headersAxTitulos.push({ content: "Nuevo", colSpan: 3 });
        }
      }
      if (longitud == 3) {
        if (this.empleado.Tipo == "1") {
          headersAxTitulos.push({ content: "Al inicio", colSpan: 3 });
        } else {
          headersAxTitulos.push({ content: "Actual", colSpan: 3 });
        }
        if (this.empleado.Tipo == "1") {
          headersAxTitulos.push({ content: "A la planta", colSpan: 3 });
        } else if (this.empleado.Tipo == "6") {
          headersAxTitulos.push({ content: "Nuevo", colSpan: 3 });
        } else {
          headersAxTitulos.push({ content: "A los tres meses", colSpan: 3 });
        }

        if (this.empleado.Tipo == "6") {
          headersAxTitulos.push({ content: "Tres meses", colSpan: 3 });
        } else {
          headersAxTitulos.push({ content: "Seis meses", colSpan: 3 });
        }
        
      }
      if (longitud == 4) {
        headersAxTitulos.push({ content: "Actual", colSpan: 3 });
        headersAxTitulos.push({ content: "Nuevo", colSpan: 3 });
        headersAxTitulos.push({ content: "A los tres meses", colSpan: 3 });
        headersAxTitulos.push({ content: "Seis meses", colSpan: 3 });
      }

      for (let i = 0; i < longitud; i++) {
        headersAxSubTitulos.push({ content: "Monto", colSpan: 1 });
        headersAxSubTitulos.push({ content: "Mensual", colSpan: 1 });
        headersAxSubTitulos.push({ content: "Anual", colSpan: 1 });
      }

      headers.push(headersAxTitulos, headersAxSubTitulos);
      // headers.push(headersAxSubTitulos)
      return headers;
    },

    getTableBenefitsPDF(beneficios) {
      let tabla = [];

      beneficios.map((b) => {
        if (b.aplica) {
          let item = [];
          item.push(b.beneficio);
          item.push(b.descBeneficio);
          if (b.montoAyuda > 0) {
            item.push(this.formatMXN(b.montoAyuda));
          } else {
            item.push("Aplica");
          }
          tabla.push(item);
        }
      });

      return tabla;
    },

    getTableObjectivesPDF(objetivos) {
      let tabla = [];

      objetivos.map((b) => {
          let item = [];
          item.push(b.objetivo);
          tabla.push(item);
      });

      // console.log(JSON.stringify(tabla, null, 2))
      return tabla;
    },
  },
};
</script>

