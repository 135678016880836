<template>
  <div>
    <v-dialog
      v-model="muestraAddConocimiento"
      persistent
      max-width="550"
      content-class="fix-border-dialog"
    >
      <v-card class="rounded-xl">
        <v-card-title class="text-subtitle-2 primary white--text">
          Agrega conocimientos.
          <v-spacer></v-spacer>
          <v-icon small dark @click="$emit('cierraDialogConocimientos', null)"
            >mdi-close
          </v-icon>
        </v-card-title>
        <v-card-text class="pt-4">
          <v-form v-model="formulario" ref="formConocimiento"></v-form>
          <v-row>
            <v-col cols="9" class="pr-1 pl-0">
              <div class="caption text-capitalize">conocimiento</div>
              <v-autocomplete
                v-model="conocimientoDesc"
                dense
                outlined
                required
                :items="conocimientos"
                item-text="descr"
                :rules="rules"
                class="rounded-lg"
                placeholder="Busca por conocimiento..."
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="3" class="pl-1 pr-0">
              <div class="caption text-capitalize">ponderación</div>
              <v-text-field
                v-model="ponderacion"
                dense
                type="number"
                outlined
                required
                :rules="rulesNumber"
                class="rounded-lg"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn block small text color="primary" class="font-weight-bold" @click="addKlowledge"
            >guardar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <SnackBar
      v-if="muestraBarra"
      :muestraBarra="muestraBarra"
      :color="colorBarra"
      :texto="textoBarra"
      @cierraBarra="muestraBarra = false"
    />
  </div>
</template>

<script>
import SnackBar from "../../../General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue";
export default {
  name: "agrega-conocimiento",
  components: { SnackBar },
  props: ["muestraAddConocimiento", "conocimientos"],
  data() {
    return {
      ponderacion: 0,
      formulario: true,
      muestraBarra: false,
      textoBarra: "",
      colorBarra: "",
      conocimientoDesc: "",
      rules: [(v) => !!v || "Campo requerido."],
      rulesNumber: [
        (v) => !!v || "Campo requerido.",
        (v) => /^[1-9]\d*$/.test(v) || "Sólo números.",
      ],
    };
  },
  mounted() {},
  methods: {
    muestraSnackBar(texto, color) {
      this.colorBarra = color;
      this.textoBarra = texto;
      this.muestraBarra = true;
    },
    addKlowledge() {
      if (this.$refs.formConocimiento.validate()) {
        if (this.ponderacion <= 0) {
          this.muestraSnackBar(
            "La ponderación debe ser mayor a 0.",
            "pink darken-3"
          );
        } else {
          let findConocimiento = this.conocimientos.filter((c) => {
            return c.descr === this.conocimientoDesc;
          });
          if (findConocimiento.length > 0) {
            this.$emit("cierraDialogConocimientos", {
              id: findConocimiento[0].id,
              descr: findConocimiento[0].descr,
              Ponderacion: this.ponderacion
            });
          }
        }
      }
    },
  },
};
</script>
