var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"rounded-xl elevation-0 pa-3"},[_c('v-data-table',{attrs:{"dense":"","headers":_vm.headers,"search":_vm.search,"items":_vm.beneficios,"no-data-text":"No hay registros de debeficiados","footer-props":{
            'items-per-page-text': 'Elementos por página',
        },"header-props":{ 'short-by-text': 'Ordenar por', },"loading-text":"Cargando contenido...","loading":_vm.loadingBenefits},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticStyle:{"display":"flex","justify-content":"space-between"}},[_c('v-text-field',{staticClass:"rounded-xl",staticStyle:{"max-width":"350px"},attrs:{"outlined":"","dense":"","prepend-inner-icon":"mdi-table-search","placeholder":"Buscar en tabla..."},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('div',[_c('v-btn',{staticClass:"rounded-pill ml-2",attrs:{"depressed":"","color":"teal","dark":""},on:{"click":function($event){return _vm.openAgregaBeneficio(null)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-plus ")]),_vm._v(" Asignación ")],1),_c('v-tooltip',{attrs:{"bottom":"","color":"blue lighten-4"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"small":"","fab":"","depressed":""},on:{"click":function($event){return _vm.colseAgregaBeneficio(1)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-reload")])],1)]}}])},[_c('label',{staticClass:"text-uppercase font-weight-bold primary--text"},[_vm._v("Actualizar tabla")])])],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('v-icon',{attrs:{"color":"cyan darken-2","small":""},on:{"click":function($event){return _vm.openAgregaBeneficio(item)}}},[_vm._v(" mdi-pencil ")])]}}])}),(_vm.muestraAgregaBenficio)?_c('AgregaBeneficio',{attrs:{"muestraAgregaBenficio":_vm.muestraAgregaBenficio,"beneficio":_vm.beneficioAux},on:{"ocultaAgregaBeneficio":_vm.colseAgregaBeneficio}}):_vm._e(),(_vm.muestraBarra)?_c('SnackBar',{attrs:{"muestraBarra":_vm.muestraBarra,"color":_vm.colorBarra,"texto":_vm.textoBarra},on:{"cierraBarra":function($event){_vm.muestraBarra = false}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }