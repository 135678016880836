<template>
  <div>
    <v-row class="px-5 pt-3 mb-0 pb-0" dense>

      <v-col cols="3">
        <v-row class="pa-3">
          <v-text-field
            v-model="candidateFilterValue"
            type="text"
            solo
            dense
            prepend-inner-icon="mdi-filter-outline"
            class="rounded-lg"
            label="Nombre"
          >
          </v-text-field>
        </v-row>
      </v-col>
      <v-col cols="3">
        <v-row class="pa-3">
          <v-text-field
            v-model="positionFilterValue"
            type="text"
            solo
            dense
            prepend-inner-icon="mdi-filter-outline"
            class="rounded-lg"
            label="Vacante"
          >
          </v-text-field>
        </v-row>
      </v-col>
    </v-row>
    <v-data-table
      class="rounded-xl elevation-10"
      :headers="headers"
      :items="candidatos"
      dense
      :loading="loadingData"
      style="border: 0.8px solid #CFD8DC !important;"
    >
      <template v-slot:item.nombre="{ item }">
        <p class="my-1 text-capitalize">{{ item.nombre }}</p>
      </template>
      <template v-slot:item.estatus="{ item }">
        <v-chip :color="getColorStatus(item.estatus)" small dark>{{
          item.estatus
        }}</v-chip>
      </template>
      <template v-slot:item.Acciones="{ item }">
        <v-btn
          small
          class="rounded-pill font-weight-bold"
          color="#c94d94"
          dark
          @click="
            muestraVacantesDisponibles = true;
            idVacanteAux = item.idVacante;
            idCandidatoAux = item.id;
          "
        >
          postular
        </v-btn>
      </template>
    </v-data-table>

    <CambiaVacanteCandidato
      v-if="muestraVacantesDisponibles"
      :muestraVacantesDisponibles="muestraVacantesDisponibles"
      :idVacante="idVacanteAux"
      :idCandidato="idCandidatoAux"
      :estatusId="13"
      :tipo="2"
      @closeCambioVacante="closeCambioVacante"
    />
  </div>
</template>

<script>
import axios from "axios";
import CambiaVacanteCandidato from "./componentsCandidato/CambiaVacanteCandidato";

export default {
  name: "ver-candidatos-portulados",
  props: [],
  components: { CambiaVacanteCandidato },
  data() {
    return {
      candidatos: [],
      muestraVacantesDisponibles: false,
      loadingData: true,
      idVacanteAux: 0,
      idCandidatoAux: 0,
      positionFilterValue: "",
      candidateFilterValue: "",
      headers: [
        {
          value: "id",
          text: "ID",
          align: "center",
          class: "header-rounded-left",
        },
        {
          value: "nombre",
          text: "Candidato",
          filter: this.candidateFilter,
          class: "my-custom-header-class",
        },
        {
          value: "vacante",
          text: "Vacante",
          filter: this.positionFilter,
          class: "my-custom-header-class",
        },
        {
          value: "diasPostulado",
          text: "Tiempo de ciclo",
          class: "my-custom-header-class",
        },
        {
          value: "estatus",
          text: "Estatus",
          align: "center",
          class: "my-custom-header-class",
        },
        {
          value: "Area",
          text: "Área",
          align: "center",
          class: "my-custom-header-class",
        },
        {
          value: "Acciones",
          text: "Acciones",
          align: "center",
          class: "header-rounded-right",
        },
      ],
    };
  },
  mounted() {
    this.getCandidatosPostulados();
  },
  methods: {
    getCandidatosPostulados() {
      let params = {
        Opcion: 12,
        token: localStorage.getItem("token"),
      };
      axios
        .post(
          `${this.$store.getters.getHost}/Reclutamiento/ws-admin-reclu.php`,
          params
        )
        .then((response) => {
          this.loadingData = false;
          if (response.data.status != "EXPSESSION") {
            this.candidatos = response.data.desc;
          }
        });
    },
    closeCambioVacante(respuesta) {
      this.muestraVacantesDisponibles = false;
      if (respuesta === 1) {
        this.loadingData = true;
        this.getCandidatosPostulados();
      }
    },
    positionFilter(value) {
      if (!this.positionFilterValue) {
        return true;
      }
      return value
        .toLowerCase()
        .includes(this.positionFilterValue.toLowerCase());
    },
    candidateFilter(value) {
      if (!this.candidateFilterValue) {
        return true;
      }
      return value
        .toLowerCase()
        .includes(this.candidateFilterValue.toLowerCase());
    },
    getColorStatus(status) {
      switch (status) {
        case "Validacion Jefe directo":
          return "teal darken-1";
        case "Psicometrias y Examenes tecnicos":
          return "teal darken-2";
        case "Entrevista Reclutamiento":
          return "teal darken-1";
        case "Apego perfil":
          return "teal darken-3";
        case "Agenda":
          return "teal darken-3";
        case "Entrevistas":
          return "teal darken-3";
        case "Agenda comite":
          return "teal darken-3";
        case "Espera comité":
          return "teal darken-3";
        case "Comité":
          return "teal darken-3";
        case "Seleccion":
          return "teal darken-4";
        case "Oferta Economica":
          return "teal darken-4";
        case "Completado":
          return "green darken-4";
        case "Bolsa de Trabajo":
          return "pink darken-2";
        case "Rechazado":
          return "pink darken-4";
        default:
          return "blue-grey darken-2";
      }
    },
  },
};
</script>

<style lang="sass">
$border-radius-root: 6px
.my-custom-header-class
    background-color: rgb(0,120,195)
    color: white !important
    font-weight: 700 !important
    text-transform: uppercase


.header-rounded-left
    background-color: rgb(0,120,195) !important
    color: white !important
    font-weight: 700 !important
    text-transform: uppercase
    border-top-left-radius: $border-radius-root * 4

.header-rounded-right
    border-top-right-radius: $border-radius-root * 4
    background-color: rgb(0,120,195)
    color: white !important
    font-weight: 700 !important
    text-transform: uppercase

.background-glass-effect
    background: rgba(100, 163, 217, 0.4) !important
    border-radius: 16px !important
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1)
    backdrop-filter: blur(9.3px)
    -webkit-backdrop-filter: blur(9.3px)
    border: 1px solid rgba(255, 255, 255, 1)
</style>
