<template>
  <v-row justify="center">
    <v-dialog v-model="dialogUbicaciones" scrollable max-width="500px">
      <v-card :loading="cargaInfo">
        <v-card-title>Ubicaciones de ópticas asociadas</v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 400px">
          <v-list three-line>
            <template v-for="(item, index) in items">
              <v-divider
                v-if="item.divider"
                :key="index"
                inset="item.inset"
              ></v-divider>

              <v-list-item v-else :key="item.name">
                <v-list-item-content>
                  <v-list-item-title v-html="item.name"></v-list-item-title>
                  <v-list-item-subtitle
                    v-html="item.domicilio"
                  ></v-list-item-subtitle>
                  <v-btn text color="primary" :href="item.ubicacion" target="_blank"> ubicacion <v-icon right>
                    mdi-map-marker
                  </v-icon> </v-btn>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="blue darken-1" text @click="$emit('cierraDialog')">
            cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>


<script>
import axios from "axios";
export default {
  name: "solicitud-apoyo-lentes",
  data: () => ({
    ubicaciones: [],
    items: [],
    cargaInfo: true,
  }),
  components: {},
  created() {},
  mounted() {
    this.getUbicaciones();
  },
  props: ["dialogUbicaciones"],
  methods: {
    getUbicaciones() {
      let params = {
        Opcion: 5,
        token: localStorage.getItem("token"),
      };
      axios
        .post(`${this.$store.getters.getHost}/compensaciones/ws-ayuda-lentes.php`, params)
        .then((response) => {
          if (response.data.status == "OK") {
            this.ubicaciones = response.data.ubicaciones;
            this.cargaInfo = false;
            this.ubicaciones.map(dato => {
                this.items.push({
                    name : dato['nombre'],
                    domicilio : dato['domicilio'],
                    ubicacion : dato['ubicacion'],
                })
                this.items.push({
                    divider: true, 
                    inset: true
                })
            })

            console.log(this.items)
          }
        });
    },
  },
};
</script>