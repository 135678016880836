<template>
  <div class="pa-4">
    <v-data-table
      :headers="headers"
      :items="conocimientos"
      :search="search"
      :loading="loadingData"
      height="100%"
      loading-text="Cargando contentenido..."
      :footer-props="{
        'items-per-page-options': [10, 20, 30, 100],
        'items-per-page-text': 'Elementos por página',
      }"
      no-data-text="No hay registros"
      :header-props="{
        'short-by-text': 'Ordenar por',
      }"
      class="elevation-10 background-glass-effect-new-positions-request rounded-lg"
      item-key="id"
      dense
    >
      <template v-slot:item.actions="{ item }">
        <v-tooltip top small color="green darken-2">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="mr-1"
              icon
              depressed
              color="green darken-2"
              small
              dark
              v-bind="attrs"
              v-on="on"
              @click="openConfirmDialog(item)"
            >
              <v-icon> mdi-check-bold </v-icon>
            </v-btn>
          </template>
          <p class="text-uppercase mb-0 font-weight-bold body-2">
            Autorizar
          </p>
        </v-tooltip>
        <v-tooltip top small color="red darken-2">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              depressed
              icon
              color="red darken-2"
              small
              v-bind="attrs"
              v-on="on"
              @click="openAddComments(item)"
            >
              <v-icon> mdi-close-thick </v-icon>
            </v-btn>
          </template>
          <p
            class="text-uppercase mb-0 font-weight-bold body-2 pink--text text--lighten-5"
          >
            Rechazar
          </p>
        </v-tooltip>
      </template>
    </v-data-table>

    <v-dialog v-model="commentReject" persistent max-width="600">
      <v-card class="rounded-lg">
        <v-card-title class="pt-2 primary white--text">
          Justifica la acción.
          <v-spacer></v-spacer>
          <v-icon dark @click="commentReject = false"> mdi-close-thick </v-icon>
        </v-card-title>

        <v-card-subtitle
          class="primary pb-2 blue-grey--text text--lighten-3 text-uppercase"
        >
          {{ requestName }} ({{ requestId }})
        </v-card-subtitle>

        <v-card-text class="pt-2 pb-0">
          <v-form ref="formComments" v-model="valid" lazy-validation>
            <v-textarea
              v-model="commentRejectText"
              class="rounded-lg"
              outlined
              :rows="1"
              :rules="commentRejectRules"
              :disabled="loadingBtnRejectRequest"
              required
              placeholder="Escribe el comentario de rechazo."
              prepend-inner-icon="mdi-comment"
              counter="250"
            ></v-textarea>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn
            class="rounded-lg elevation-0"
            block
            color="primary"
            :loading="loadingBtnRejectRequest"
            @click="
              loadingBtnRejectRequest = true;
              updateEstatus();
            "
          >
            Rechazar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <SnackBar
      v-if="muestraBarra"
      :muestraBarra="muestraBarra"
      :color="colorBarra"
      :texto="textoBarra"
      @cierraBarra="muestraBarra = false"
    />

    <ConfirmDialogReclutamiento
      :showConfirm="openConfirm"
      :question="question"
      @cierraConfirm="verifyAnswer"
    />
  </div>
</template>

<script>
import axios from "axios";
import { deleteToken } from "../../../../store/Funciones/funciones";
import ConfirmDialogReclutamiento from "./ConfirmDialogReclutamiento.vue";
import SnackBar from "../../General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue";
export default {
  name: "muestra-nuevos-conocimientos-colicitados",
  components: { ConfirmDialogReclutamiento, SnackBar },
  data() {
    return {
      search: "",
      headers: [
        {
          text: "ID",
          value: "id",
          class: "heder-requests-custom rounded-tl-lg",
        },
        {
          text: "ID SOLICITUD",
          align: "start",
          value: "id_solicitud",
          class: "primary white--text text-uppercase font-weight-bold",
        },
        {
          text: "PUESTO",
          align: "center",
          value: "Puesto",
          class: "primary white--text text-uppercase font-weight-bold",
        },
        {
          text: "CONOCIMIENTO",
          align: "start",
          value: "decrNuevoConocimiento",
          class: "primary white--text text-uppercase font-weight-bold",
        },
        {
          text: "ACCIONES",
          align: "center",
          value: "actions",
          class:
            "primary white--text text-uppercase font-weight-bold rounded-tr-lg",
        },
      ],
      commentRejectRules: [
        (v) => !!v || "Campo requiredo.",
        (v) =>
          (v && v.length <= 250) ||
          "el campo debe tener menos de 250 caracteres.",
      ],
      conocimientos: [],
      requestSelected: {},
      loadingData: true,
      openConfirm: false,
      commentReject: false,
      loadingBtnRejectRequest: false,
      valid: true,
      muestraBarra: false, 
      colorBarra: "",
      textoBarra: "",
      question: "",
      commentRejectText: "",
      requestName: "",
      requestId: 0,
      knowledgeId: 0,
      esNuevoPuesto: false,
    };
  },
  mounted() {
    this.getConocimientosSolicitados();
  },
  methods: {
    muestraSnackBar(text, color) {
      this.colorBarra = color;
      this.textoBarra = text;
      this.muestraBarra = true;
    },
    getConocimientosSolicitados() {
      this.conocimientos.length = 0;
      const params = {
        Opcion: 33,
        token: localStorage.getItem("token"),
      };
      axios
        .post(
          `${this.$store.getters.getHost}/Reclutamiento/ws-admin-reclu.php`,
          params
        )
        .then((response) => {
          this.loadingData = false;
          if (response.data.status == "EXPSESSION") {
            deleteToken();
          } else {
            this.conocimientos = response.data.conocimientos;
          }
        })
        .catch((e) => {
          this.muestraSnackBar(e, "pink darken-3");
        });
    },
    openAddComments(request) {
      this.requestName = request.Puesto;
      this.requestId = request.id_solicitud;
      this.knowledgeId = request.id;
      this.esNuevoPuesto = request.es_nuevopuesto;
      this.commentReject = true;
    },
    openConfirmDialog(item) {
      this.requestSelected = item;
      this.question = "¿Realmente deseas autorizar esta solicitud?";
      this.openConfirm = true;
    },
    verifyAnswer(answer) {
      if (!answer) {
        this.openConfirm = false;
      } else {
        this.openConfirm = false;
        this.loadingData = true;
        this.autorizar();
      }
    },
    updateEstatus() {
      let estatus = 0;
      console.log(this.esNuevoPuesto)
      if (this.esNuevoPuesto) {
        estatus = 8;
      } else {
        estatus = 2;
      }
      if (this.$refs.formComments.validate()) {
        let params = {
          Opcion: 15,
          token: localStorage.getItem("token"),
          solicitud: this.requestId,
          comentario: this.commentRejectText,
          estatus: estatus,
        };
        axios
          .post(
            `${this.$store.getters.getHost}/Reclutamiento/ws-admin-reclu.php`,
            params
          )
          .then((response) => {
            this.loadingBtnRejectRequest = false;
            this.commentReject = false;
            if (response.data.status == "EXPSESSION") {
              deleteToken();
            } else if (response.data.status == "OK") {
              if (response.data.solicitud.modified) {
                this.muestraSnackBar(
                  "Modificado con éxito. c:",
                  "teal darken-1"
                );
                this.loadingData = true;
                this.getConocimientosSolicitados();
              } else {
                this.muestraSnackBar(
                  "Error. No se pudo modificar. :c",
                  "pink darken-3"
                );
              }
            } else {
              this.muestraSnackBar(
                "Error. No se pudo modificar. :c código: 10001",
                "pink darken-3"
              );
            }
          })
          .catch((e) => {
            console.log(e)
            this.muestraSnackBar(e, "pink darken-3");

          });
      }
    },
    autorizar() {
      let params = {
        Opcion: 34,
        token: localStorage.getItem("token"),
        idConocimiento: this.requestSelected.id,
      };
      axios
        .post(
          `${this.$store.getters.getHost}/Reclutamiento/ws-admin-reclu.php`,
          params
        )
        .then((response) => {
          if (response.data.status == "EXPSESSION") {
            deleteToken();
          } else if (response.data.status == "OK") {
            if (response.data.respuesta == 1) {
              this.muestraSnackBar("Modificado con éxito.", "primary");
              this.getConocimientosSolicitados();
            } else {
              this.loadingData = false;
              this.muestraSnackBar(
                "No se pudo modificar.",
                "pink darken-3"
              );
            }
          } else {
            this.muestraSnackBar(
              "Ocurrió un error. No se pudo autorizar. BE",
              "pink darken-3"
            );
          }
        })
        .catch((error) => {
          this.muestraSnackBar(error, "pink darken-3");
        });
    },
  },
};
</script>
