<template>
  <v-dialog v-model="verDataTable" max-width="1000px" persistent scrolleable>
    <v-card class="rounded-lg">
      <v-card-title class="primary white--text subtitle-1 text-uppercase">
        {{ titulo }}
        <v-spacer></v-spacer>
        <v-icon dark @click="$emit('cierraTabla')">
          mdi-close
        </v-icon>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-container v-if="!esApegoPerfil">
          <v-text-field
            v-model="search"
            class="rounded-xl"
            dense
            outlined
            color="grey lighten-2"
            append-icon="mdi-magnify"
            label="Buscar..."
          ></v-text-field>
          <div
            v-show="titulo === 'Entrevistadores.'"
            style="display: flex; justify-content: end;"
          >
            <v-btn
              class="rounded-pill mb-3"
              small
              depressed
              color="teal darken-1"
              dark
              @click="showFindNewMembers = true"
            >
              <v-icon small left> mdi-account-plus </v-icon>
              <div>Agregar entrevistador</div>
            </v-btn>
          </div>
          <v-data-table
            :headers="headers"
            :items="itemsAux"
            :search="search"
            locale="es-MX"
            :loading="loadingTable"
            dense
            class="rounded-lg"
            style="border: 0.8px solid #CFD8DC !important;"
            loading-text="Cargando contentenido..."
            :footer-props="{
              'items-per-page-options': [10, 20, 30, 100],
              'items-per-page-text': 'Elementos por página',
            }"
            no-data-text="No hay registros"
            :header-props="{
              'short-by-text': 'Ordenar por',
            }"
          >
            <template v-slot:item.actions="{item}">
              <v-btn v-show="titulo === 'Entrevistadores.'" small depressed text color="pink darken-3" @click="removeEntrevistadores(item.emplidEntrevisador)">
                  quitar
              </v-btn>
            </template>
        </v-data-table>
        </v-container>

        <v-container v-else>
          <v-row dense>
            <v-col :cols="numberOfColumns">
              <v-sheet class="pa-2">
                <v-card
                  class="rounded-xl"
                  elevation="3"
                  style="height: 100% !important;"
                >
                  <v-card-title class="py-1 primary white--text subtitle-1">
                    Conocimientos
                  </v-card-title>
                  <v-simple-table dense>
                    <thead class="primary text-uppercase">
                      <tr>
                        <th class="font-weight-bold white--text">
                          ID
                        </th>
                        <th class="font-weight-bold white--text">
                          Conocimiento
                        </th>
                        <th class="font-weight-bold white--text">
                          Ponderación
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in items.conocimientos" :key="item.id">
                        <td>{{ item.id }}</td>
                        <td>{{ item.descr }}</td>
                        <td
                          class="text-center font-weight-bold blue--text text--darken-4"
                        >
                          {{ item.Ponderacion }}
                        </td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </v-card>
              </v-sheet>
            </v-col>
            <v-col :cols="numberOfColumns">
              <v-sheet class="pa-2" style="height: 50% !important;">
                <v-card
                  class="rounded-xl"
                  elevation="3"
                  style="height: 100% !important;"
                >
                  <v-card-title class="py-1 primary white--text subtitle-1">
                    Escolaridad
                  </v-card-title>
                  <v-simple-table dense>
                    <thead class="primary text-uppercase">
                      <tr>
                        <th class="font-weight-bold white--text">
                          ID
                        </th>
                        <th class="font-weight-bold white--text">
                          Escolaridad
                        </th>
                        <th class="font-weight-bold white--text">
                          Carrera
                        </th>
                        <!-- <th class="font-weight-bold white--text">
                          Ponderación
                        </th> -->
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{{ items.escolaridad.id }}</td>
                        <td>{{ items.escolaridad.esc }}</td>
                        <td>{{ items.escolaridad.carrera }}</td>
                        <!-- <td
                          class="text-center font-weight-bold blue--text text--darken-4"
                        >
                          {{ items.escolaridad.Ponderacion }}
                        </td> -->
                      </tr>
                    </tbody>
                  </v-simple-table>
                </v-card>
              </v-sheet>
              <v-sheet class="pa-2" style="height: 50% !important;">
                <v-card
                  class="rounded-xl"
                  elevation="10"
                  style="height: 100% !important;"
                >
                  <v-card-title class="py-1 primary white--text subtitle-1">
                    Calificación
                  </v-card-title>
                  <v-sheet class="pa-2 center-item-in-div">
                    <p
                      class="text-h4 font-weight-bold my-0"
                      :class="
                        sumPonderations == 100
                          ? 'green--text text--darken-3'
                          : 'pink--text text--darken-3'
                      "
                    >
                      {{ sumPonderations }}
                    </p>
                  </v-sheet>
                </v-card>
              </v-sheet>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>

    <BuscaColaboradoresActivos
      :showFindNewMembers="showFindNewMembers"
      @returnColaborrador="returnColaborrador"
    />
  </v-dialog>
</template>

<script>
import axios from "axios";
import BuscaColaboradoresActivos from "../../../../components/BuscaColaboradoresActivos.vue";
import { deleteToken } from "../../../../store/Funciones/funciones";

export default {
  name: "tabla-detalles",
  components: { BuscaColaboradoresActivos },
  data: () => ({
    search: "",
    showFindNewMembers: false,
    loadingTable: false,
    itemsAux: [],
  }),
  props: [
    "verDataTable",
    "esApegoPerfil",
    "items",
    "headers",
    "titulo",
    "idVacante",
  ],
  computed: {
    numberOfColumns() {
      return this.$vuetify.breakpoint.name == "xs" ||
        this.$vuetify.breakpoint.name == "sm"
        ? 12
        : 6;
    },
    sumPonderations() {
      let knowledgesPonderations = 0;
      this.items.conocimientos.map((c) => {
        knowledgesPonderations += c.Ponderacion;
      });
      console.log(knowledgesPonderations);
      console.log(this.items.escolaridad.ponderacion);

      return knowledgesPonderations + this.items.escolaridad.Ponderacion;
    },
  },
  mounted() {
    this.itemsAux = this.items;
  },
  methods: {
    returnColaborrador(colaboradorSeleccionado) {
      if (colaboradorSeleccionado === null) {
        this.showFindNewMembers = false;
      } else {
        this.showFindNewMembers = false;
        this.loadingTable = true;
        this.itemsAux.length = 0;
        this.updateEntrevistadores(colaboradorSeleccionado);
      }
    },
    updateEntrevistadores(colaboradorSeleccionado) {
      let params = {
        Opcion: 37,
        token: localStorage.getItem("token"),
        empleado: colaboradorSeleccionado.EMPLID,
        idVacante: this.idVacante,
      };
      axios
        .post(
          `${this.$store.getters.getHost}/Reclutamiento/ws-admin-reclu.php`,
          params
        )
        .then((response) => {
          if(response.data.status == "OK"){
            if(response.data.respuesta.status_tran == 1){
              alert(response.data.respuesta.message)
              this.getEntrevistadores();
            } else {
              alert(response.data.respuesta.message)
            }
          }
        }).catch((error) => {
          alert(error);
        }).finally(() => {
          this.loadingTable = false;
        });
    },
    removeEntrevistadores(colaboradorSeleccionado) {
      this.loadingTable = true;
      this.itemsAux.length = 0;
      let params = {
        Opcion: 43,
        token: localStorage.getItem("token"),
        empleado: colaboradorSeleccionado,
        idVacante: this.idVacante,
      };
      axios
        .post(
          `${this.$store.getters.getHost}/Reclutamiento/ws-admin-reclu.php`,
          params
        )
        .then((response) => {
          if(response.data.status == "OK"){
            if(response.data.respuesta.status_tran == 1){
              alert(response.data.respuesta.message)
              this.getEntrevistadores();
            } else {
              alert(response.data.respuesta.message)
            }
          }
        }).catch((error) => {
          alert(error);
        }).finally(() => {
          this.loadingTable = false;
        });
    },
    getEntrevistadores() {
      this.loadingTable = true;
      let params = {
        Opcion: 20,
        token: localStorage.getItem("token"),
        idVacante: this.idVacante,
      };
      axios
        .post(
          `${this.$store.getters.getHost}/Reclutamiento/ws-admin-reclu.php`,
          params
        )
        .then((response) => {
          if (response.data.status == "EXPSESSION") {
            deleteToken();
          } else {
            response.data.entrevistadores.map((entrevistador) => {
              this.itemsAux.push({
                id: entrevistador.id,
                idSolicitud: entrevistador.id_solicitud,
                entrevistador:
                  entrevistador.EMP_NOMEMP +
                  " (" +
                  entrevistador.entrevistador +
                  "). " +
                  entrevistador.EMP_STATUS +
                  ".",
                tipo: entrevistador.es_comite,
              });
            });
          }
        }).catch((error) => {
          alert(error);
        }).finally(() => {
          this.loadingTable = false;
        });
      }
  },
};
</script>

<style></style>
