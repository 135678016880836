// import AreasEscalerasDeCrecimiento from "./components/AreasEscalerasDeCrecimiento.vue";
import AreasEscalerasCrecimiento from "./components/AreasEscalerasCrecimiento.vue";
import MantenimientoModulo from "../../../../../components/MantenimientoModulo.vue";
export default {
  name: 'escaleras-de-crecimiento',
  components: { AreasEscalerasCrecimiento, MantenimientoModulo },
  props: [],
  data() {
    return {
      moduloError: true,
    }
  },
  computed: {

  },
  mounted() {

  },
  methods: {

  }
}


