<template>
  <v-dialog v-model="dialog" persistent fullscreen>
    <v-card>
      <v-card-title class="text-subtitle-2 primary white--text">
        Captura de clientes y pares
        <v-spacer></v-spacer>
        <v-icon
          small
          dark
          @click="$emit('cierraDialgoCaptura', { result: 'Cancelar' })"
          >mdi-close</v-icon
        >
      </v-card-title>
      <v-card-text class="mt-5">
        <v-row>
          <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
            <v-alert
              icon="mdi-account-group-outline"
              prominent
              color="blue darken-1"
              dark
              dense
            >
              Por favor, selecciona maximo tres colaboradores como sus pares
            </v-alert>
            <v-card shaped>
              <v-data-table
                v-model="paresSeleccionados"
                :headers="headersSelect"
                :items="pares"
                :single-select="singleSelect"
                :search="buscarPar"
                item-key="Emplid"
                show-select
                @input="manejarSeleccionPares"
                class="elevation-1"
                dense
                mobile-breakpoint="0"
                :footer-props="{
                  'items-per-page-options': [5, 10],
                  'items-per-page-text': 'Elementos por página',
                }"
                :items-per-page="itemsPerPage"
                no-data-text="No se encontraron pares registrados"
                loading-text="Buscando pares..."
                :loading="loadingPares"
              >
                <template v-slot:top>
                  <v-toolbar
                    flat
                    color="blue darken-1"
                    class="rounded-lg rounded-b-0 rounded-t-0"
                    dark
                  >
                    <div>Pares ({{ paresSeleccionados.length }}/3)</div>
                    <v-spacer></v-spacer>
                    <v-text-field
                      v-model="buscarPar"
                      :label="$t('DH360.findTextBox')"
                      class="mt-7 rounded-lg"
                      dense
                      clearable
                      flat
                      solo-inverted
                      dark
                      prepend-inner-icon="mdi-magnify"
                    ></v-text-field>
                    <v-spacer></v-spacer>
                    <v-btn
                      v-if="selectClientesBtn"
                      small
                      color="teal darken-4"
                      @click="dialogChange = true"
                    >
                      <v-icon left>mdi-account-check</v-icon> Agregar
                    </v-btn>
                  </v-toolbar>
                </template>
                <template v-slot:item.nombre="{ item }">
                  <div align="left">{{ item.nombre }}</div>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
          <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
            <v-alert
              icon="mdi-account-group-outline"
              prominent
              color="blue darken-1"
              dark
              dense
            >
              Por favor, selecciona maximo tres colaboradores como sus clientes
            </v-alert>
            <v-card shaped>
              <v-data-table
                v-model="clientesSeleccionados"
                :headers="headersSelectClientes"
                :items="colaboradores"
                :single-select="singleSelect"
                :search="buscarCliente"
                item-key="Id_Usuario"
                show-select
                @input="manejarSeleccionClientes"
                class="elevation-1"
                dense
                mobile-breakpoint="0"
                :footer-props="{
                  'items-per-page-options': [5, 10],
                  'items-per-page-text': 'Elementos por página',
                }"
                :items-per-page="itemsPerPage"
                no-data-text="No se encontraron clientes para seleccionar"
                loading-text="Buscando posibles clientes..."
                :loading="loadingClientes"
              >
                <template v-slot:top>
                  <v-toolbar
                    flat
                    color="blue darken-1"
                    class="rounded-lg rounded-b-0 rounded-t-0"
                    dark
                  >
                    <div>Clientes ({{ clientesSeleccionados.length }}/3)</div>
                    <v-spacer></v-spacer>
                    <v-text-field
                      v-model="buscarCliente"
                      :label="$t('DH360.findTextBox')"
                      class="mt-7 rounded-lg"
                      dense
                      clearable
                      flat
                      solo-inverted
                      dark
                      prepend-inner-icon="mdi-magnify"
                    ></v-text-field>
                    <v-spacer></v-spacer>
                  </v-toolbar>
                </template>
                <template v-slot:item.NAME="{ item }">
                  <div align="left">{{ item.NAME }}</div>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          v-if="
            clientesSeleccionados.length == 0 || paresSeleccionados.length == 0
          "
          small
          disabled
        >
          <v-icon left>mdi-check-circle-outline</v-icon> Guardar
        </v-btn>
        <v-btn
          v-else
          class="rounded-lg elevation-0"
          color="teal darken-1"
          dark
          small
          :loading="loading"
          @click="ordenarArrays()"
        >
          <v-icon left>mdi-check-circle-outline</v-icon> Guardar
        </v-btn>
      </v-card-actions>
    </v-card>
    <selecciona-colaborador
      v-if="dialogChange"
      :dialog="dialogChange"
      :idEvaluacion="evaluado.id"
      :infoEncuesta="evaluado"
      :fuente="fuente"
      @cierraDialogSeleccion="closeDialogChange"
    ></selecciona-colaborador>
  </v-dialog>
</template>



<script>
import axios from "axios";
import { deleteToken } from "../../../../../../store/Funciones/funciones";
import seleccionaColaborador from "./seleccionaColaborador.vue";
export default {
  name: "carga-clientes-pares",
  components: { seleccionaColaborador },
  props: ["dialog", "evaluado"],
  data: () => ({
    headersSelect: [
      { text: "Emplid", value: "Emplid", align: "center", sortable: true },
      { text: "Nombre", value: "nombre", align: "center", sortable: true },
    ],
    headersSelectClientes: [
      { text: "Emplid", value: "EMPLID", align: "center", sortable: true },
      { text: "Nombre", value: "NAME", align: "center", sortable: true },
    ],
    paresSeleccionados: [],
    clientesSeleccionados: [],
    findPares: [],
    sendPares: [],
    sendClientes: [],
    pares: [],
    colaboradores: [],
    singleSelect: false,
    buscar: "",
    buscarPar: "",
    buscarCliente: "",
    itemsPerPage: 5,
    loading: false,
    loadingPares: true,
    loadingClientes: true,
    selectClientesBtn: false,
    dialogChange: false,
    fuente: "SeleccionaClientes",
  }),
  created() {},
  mounted() {
    this.getPares();
  },

  methods: {
    manejarSeleccionClientes(selectedItems) {
      // Verificar si se han seleccionado más de tres elementos
      if (selectedItems.length > 3) {
        // Eliminar elementos seleccionados adicionales
        this.clientesSeleccionados = selectedItems.slice(0, 3);
      }
    },

    manejarSeleccionPares(selectedItems) {
      // Verificar si se han seleccionado más de tres elementos
      if (selectedItems.length > 3) {
        // Eliminar elementos seleccionados adicionales
        this.paresSeleccionados = selectedItems.slice(0, 3);
      }
    },

    ordenarArrays() {
      this.sendPares = [];
      this.sendClientes = [];
      this.paresSeleccionados.map((element) => {
        this.sendPares.push(element.Emplid);
      });
      this.clientesSeleccionados.map((element) => {
        this.sendClientes.push(element.EMPLID);
      });
      this.guardar();
    },

    guardar() {
      let params = {
        Opcion: 3,
        token: localStorage.getItem("token"),
        clientes: this.sendClientes,
        pares: this.sendPares,
        item: this.evaluado,
      };
      this.loading = true;
      axios
        .post(
          `${this.$store.getters.getHost}/General/PersonalTemporal/ws-personal-temporal.php`,
          params
        )
        .then((response) => {
          if (response.data.status == "OK") {
            this.textoBarra = "Encustas registradas correctamente";
            this.colorBarra = "success";
            this.muestraBarra = true;
            this.clean();
            this.$emit("cierraDialgoCaptura", { result: "OK" });
          } else if (response.data.status == "ERROR") {
            this.textoBarra = "No se pudo registrar su solicitud";
            this.colorBarra = "error";
            this.muestraBarra = true;
          } else if (response.data.status == "EXPRESSION") {
            deleteToken();
          }
          this.loading = false;
        })
        .catch(function (e) {
          alert("Ocurrió un error: " + e);
        });
    },

    clean() {
      this.sendPares = [];
      this.sendClientes = [];
      this.paresSeleccionados = [];
      this.clientesSeleccionados = [];
    },

    getPares() {
      let params = {
        Opcion: 2,
        emplid: this.evaluado["emplid"],
        token: localStorage.getItem("token"),
      };
      axios
        .post(
          `${this.$store.getters.getHost}/General/PersonalTemporal/ws-personal-temporal.php`,
          params
        )
        .then((response) => {
          this.loadingPares = false;
          if (response.data.status == "OK") {
            this.pares = response.data.pares;
            this.pares.map((element) => {
              this.findPares.push(element.Emplid);
            });
            this.findPares.push(this.evaluado["emplid"]);
            this.getPosiblesClientes();
          } else if (response.data.status == "VACIO") {
            this.selectClientesBtn = true;
            this.loadingClientes = false;
          } else if (response.data.status == "EXPRESSION") {
            deleteToken();
          }
        })
        .catch(function (e) {
          alert("Ocurrió un error: " + e);
        });
    },

    getPosiblesClientes() {
      let params = {
        Opcion: 19,
        pares: this.findPares,
        token: localStorage.getItem("token"),
      };
      axios
        .post(
          `${this.$store.getters.getHost}/General/PersonalTemporal/ws-personal-temporal.php`,
          params
        )
        .then((response) => {
          var data = response.data.colaboradores;
          if (data) {
            this.colaboradores = data;
            this.loadingClientes = false;
          }
        });
    },

    closeDialogChange(item) {
      if (item.estatus == "OK") {
        this.textoBarra = "Se ha cambiado una encuesta a un nuevo colaborador";
        this.colorBarra = "success";
        this.muestraBarra = true;
      } else if (item.estatus == "ParesSeleccionados") {
        this.pares = item.pares;
        this.findPares = item.folios;
        this.textoBarra = "Se han seleccionado pares";
        this.colorBarra = "success";
        this.muestraBarra = true;
        this.selectClientesBtn = false;
        this.getPosiblesClientes();
      }
      this.dialogChange = false;
    },
  },
};
</script>