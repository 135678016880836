import axios from 'axios'
import { deleteToken } from '../../../../../../store/Funciones/funciones'
// import errorIncidencias from "../../../../General/MisIncidencias/components/componentsExtra/errorIncidencias.vue"
import SnackBarErrorIncidencias from "../../../../General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue"

export default {
  name: 'solicitud-de-epp',
  components: { SnackBarErrorIncidencias },
  props: [],
  data() {
    return {

      textoBarra: '',
      colorBarra: '',
      muestraBarra: false,
      timeout: 2000,

      eppSeleccionado: [],
      singleSelect: false,
      epp: [],
      buscar: '',

      headers: [
        { text: 'Código', value: 'codigo', align: 'center', sortable: true },
        { text: 'Equipo', value: 'equipo', align: 'center', sortable: true },
        { text: 'Días vigencia', value: 'diasVigencia', align: 'center', sortable: true },
        { text: 'Inventario', value: 'Inventario', align: 'center', sortable: true },
        { text: 'Dias ult. entrega', value: 'diasPasados', align: 'center', sortable: true },
      ],
      headersSeleccionado: [
        { text: 'Código', value: 'codigo', align: 'center', sortable: true },
        { text: 'Equipo', value: 'equipo', align: 'center', sortable: true },
        { text: 'Cantidad', value: 'cantidad', align: 'center', sortable: true },
        { text: 'Información', value: 'info', align: 'center', sortable: true },
      ],
      colaborador: [],
      colaboradores: [],
      colaboradoresNombre: [],
      loadingColaboradores: true,
      nombre: '',
      // emplid:'',
      // ingreso:'',
      // repuesto:'',
      respuestasRadio: [{ id: 1, mensaje: 'Si' }, { id: 2, mensaje: 'No' }],
      turnosEntrega: [{ id: 1, mensaje: 'Turno 1' }, { id: 2, mensaje: 'Turno 2' }, { id: 3, mensaje: 'Turno 3' }],
      motivosRepuesto: [{ id: 1, mensaje: 'Desgaste natural' }, { id: 2, mensaje: 'Defecto de fabrica' }, { id: 3, mensaje: 'Daño con dolo' }],
      motivosSinRepuesto: [{ id: 3, mensaje: 'Daño con dolo' },{ id: 4, mensaje: 'Extravío' }],
      solicitud: {
        emplid: '',
        turnoEntrega: '',
        ingreso: '',
      },
      otraActividad: {
        otraActividad: '',
        comentarioOtraActividad: '',
      },
      loading: false,
      dialogInfo: false,
      mensaje: '',
      editedIndex: 0,
      openDialogCheck: false,
      sinCertificacion: 0,
    }
  },
  computed: {

  },
  mounted() {
    this.getColaboradores();
    this.saveMonitoreo();
  },
  methods: {
    saveMonitoreo() {
      let params = {
          Opcion: 10,
          pantalla: "Reporte Incidencias vigilancia",
          token: localStorage.getItem('token')
      }
      axios.post(`${this.$store.getters.getHost}/General/ws-general.php`, params).then((response) => {
        console.log(response.data)
      })
    },

    validar() {
      let cantidad = 0;
      let formulario = 0;
      this.eppSeleccionado.map((elemento) => {
        if (elemento.cantidad > 0) {
          cantidad++;
        }
        if (elemento.repuesto && elemento.motivo && elemento.comentario) {
          formulario++;
        }
      });
      if (this.solicitud.emplid && this.solicitud.turnoEntrega && this.solicitud.ingreso) {
        if (this.eppSeleccionado.length > 0 && cantidad == this.eppSeleccionado.length && formulario == this.eppSeleccionado.length) {
          this.guardar();
        } else if (this.eppSeleccionado.length == 0 || cantidad < this.eppSeleccionado.length || formulario < this.eppSeleccionado.length) {
          this.textoBarra = 'Seleccione el EPP que necesite, ingrese la cantidad a solicitar y complete el formulario ubicado en "información" para continuar';
          this.colorBarra = 'error';
          this.muestraBarra = true;
        } else {
          this.textoBarra = 'Se tendrá que cobrar ';
          this.colorBarra = 'error';
          this.muestraBarra = true;
        }
      } else {
        this.textoBarra = 'Complete los campos solicitados';
        this.colorBarra = 'error';
        this.muestraBarra = true;
      }
    },

    guardar() {
      let params = {
        Opcion: 2,
        solicitud: this.solicitud,
        eppIds: this.eppSeleccionado,
        otraActividad: this.otraActividad,
        token: localStorage.getItem('token'),
      }
      this.loading = true;
      axios
        .post(
          `${this.$store.getters.getHost}/General/EPP/ws-admon-epp.php`,
          params
        )
        .then((response) => {
          this.loading = false;
          if (response.data.status == "error") {
            console.log("error");
          } else if (response.data.status == "OK") {
            this.textoBarra = 'Solicitud registrada correctamente';
            this.colorBarra = 'success';
            this.muestraBarra = true;
            this.clean();
          } else if (response.data.status == "sobrePedido") {
            this.textoBarra = 'No se cuenta con la cantidad solicitada de alguno de los articulos seleccionados';
            this.colorBarra = 'error';
            this.muestraBarra = true;
          } else if (response.data.status == "EXPRESSION") {
            deleteToken();
          }
        })
        .catch(function (e) {
          alert("Ocurrió un error: " + e);
        });
    },

    getCatalogoEPP() {
      let params = {
        Opcion: 1,
        emplid: this.solicitud.emplid,
        certificacion: this.solicitud.certificacion,
        sinCertificacion: this.sinCertificacion,
        token: localStorage.getItem('token'),
      }
      axios
        .post(
          `${this.$store.getters.getHost}/General/EPP/ws-admon-epp.php`,
          params
        )
        .then((response) => {
          this.loadingInfo = false;
          if (response.data.status == "VACIO") {
            console.log("Vacio");
          } else if (response.data.status == "error") {
            console.log("error");
          } else if (response.data.status == "OK") {
            this.epp = response.data.catalogo;
          } else if (response.data.status == "EXPRESSION") {
            deleteToken();
          }
        })
        .catch(function (e) {
          alert("Ocurrió un error: " + e);
        });
    },

    getColaboradores() {
      let params = {
        Opcion: 11,
        token: localStorage.getItem("token"),
      };
      axios
        .post(`${this.$store.getters.getHost}/General/ws-general.php`, params)
        .then((response) => {
          var data = response.data.colaboradores;
          if (data) {
            this.colaboradores = data;
            this.colaboradores.map((elemento) => {
              this.colaboradoresNombre.push('[' + elemento["EMPLID"] + '] ' + elemento["NAME"]);
            });
            this.loadingColaboradores = false;
          }
        });
    },

    infoTiempoVida(item) {
      this.dialogInfo = true;
      this.editedIndex = this.eppSeleccionado.indexOf(item);
      this.mensaje = parseFloat(item.diasVigencia) < parseFloat(item.diasPasados) ? "La entrega de este articulo cumple con el tiempo de vida" : "Para continuar con la adquisición se debe de valorar si se tendrá un costo. Recuerde llevar el articulo a reemplazar para que le sea valido";
    },

    clean() {
      this.colaborador = [];
      this.otraActividad = {
        otraActividad: '',
        comentarioOtraActividad: '',
      };
      this.sinCertificacion = 0;
      this.solicitud.emplid = '';
      this.solicitud.ingreso = '';
      this.solicitud.turnoEntrega = '';
      this.nombre = '';
      this.eppSeleccionado = [];
      this.epp = [];
      this.editedIndex = 0;
    },

    buscarNumeroEmpleado() {
      if (this.nombre) {
        let resultdo = this.colaboradores.filter((dato) => '[' + dato["EMPLID"] + '] ' + dato['NAME'] == this.nombre);
        this.solicitud.emplid = resultdo[0]['EMPLID'];
        this.getDataEmpleado(this.solicitud.emplid);
      } else {
        this.clean();

      }
    },

    getDataEmpleado(EMPLID) {
      let paramsData = {
        Opcion: 14,
        token: localStorage.getItem('token'),
        emplid: EMPLID
      };
      axios.post(`${this.$store.getters.getHost}/General/EPP/ws-admon-epp.php`, paramsData).then((responseData) => {
        this.colaborador = responseData.data.colaborador;
        this.solicitud.certificacion = responseData.data.colaborador.certificacion;
        if (!this.colaborador.nombreCertificacion) {
          this.textoBarra = 'No se encuenta registrado una certificación';
          this.colorBarra = 'error';
          this.muestraBarra = true;
          this.epp = [];
          this.openDialogCheck = true;
        } else {
          this.validaCamposUsuario();
        }
      })

    },

    async validaCamposUsuario() {
      if (this.epp.length == 0) {
        if (this.solicitud.emplid && this.solicitud.certificacion) {
          this.getCatalogoEPP();
        }
      }
    },

    buscarEPP() {
      if (this.otraActividad.otraActividad) {
        if (this.otraActividad.otraActividad == 1) {
          if (this.otraActividad.comentarioOtraActividad) {
            this.openDialogCheck = false;
            this.sinCertificacion = 1;
            this.getCatalogoEPP();
          } else {
            this.textoBarra = 'Escriba un comentario para continuar';
            this.colorBarra = 'error';
            this.muestraBarra = true;
          }
        } else {
          this.textoBarra = 'Selecciona si para seleccionar EPP';
          this.colorBarra = 'error';
          this.muestraBarra = true;
        }
      } else {
        this.textoBarra = 'Selecciona una opción para continuar';
        this.colorBarra = 'error';
        this.muestraBarra = true;
      }
    },
  }
}