<template>
  <v-card
    class="mt-3 mx-10 rounded-lg elevation-0"
    style="border: 1px solid #1565C0"
  >
    <v-data-table
      dense
      :items="solicitudes"
      :headers="headers"
      :loading="loadingData"
      :search="search"
      :footer-props="{
        'items-per-page-text': 'Elementos por página',
        pageText: '{0}-{1} de {2}',
      }"
      no-data-text="No hay registros"
      loading-text="Cargando información..."
      :no-results-text="
        'No se encontró ningun resultado que coincida con ' + search
      "
      :header-props="headerProps"
      :mobile-breakpoint="0"
      :single-expand="true"
      :expanded.sync="expanded"
      item-key="id"
      show-expand
    >
      <template v-slot:top>
        <div style="display: flex; justify-content: space-between;" class="primary pa-3 rounded-lg rounded-b-0">
          <v-text-field
            v-model="search"
            dense
            outlined
            dark
            class="rounded-pill"
            prepend-inner-icon="mdi-magnify"
            placeholder="Buscar..."
          ></v-text-field>
          <v-spacer></v-spacer>
          <div>
            <vue-json-to-csv
              :json-data="solicitudes"
              :labels="headers_to_export"
              :csv-title="'ReporteSolicitudes'"
            >
              <v-tooltip bottom color="primary">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    depressed
                    class="rounded-pill mr-2"
                    color="white"
                    dark
                    fab
                    small
                    outlined
                    :loading="loadingData"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>mdi-microsoft-excel</v-icon>
                  </v-btn>
                </template>
                <span>Exportar CSV</span>
              </v-tooltip>
            </vue-json-to-csv>
            <v-tooltip bottom color="primary">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    depressed
                    class="rounded-pill mr-2"
                    color="white"
                    dark
                    fab
                    small
                    outlined
                    :loading="loadingData"
                    v-bind="attrs"
                    v-on="on"
                    @click="actualizarTabla"
                  >
                    <v-icon>mdi-reload</v-icon>
                  </v-btn>
                </template>
                <span>Refrescar</span>
              </v-tooltip>
          </div>
        </div>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <v-card
            class="ma-3 pa-3 rounded-xl elevation-0"
            color="blue-grey lighten-5"
          >
            <v-row dense>
              <v-col cols="6">
                <v-card class="ma-3 pa-3 rounded-lg elevation-0">
                  <label
                    class="overline font-weight-bold blue-grey--text text-darken2"
                  >
                    justificación
                  </label>
                  <div class="body-2">
                    {{ item.justificacion }}
                  </div>
                  <v-divider class="my-2"></v-divider>
                  <v-row dense>
                    <v-col cols="6">
                      <label
                        class="overline font-weight-bold blue-grey--text text-darken2"
                      >
                        motivo
                      </label>
                      <div class="body-2">
                        {{ item.motivoDesc }}
                      </div>
                    </v-col>
                    <v-col cols="6">
                      <div v-show="item.motivo === 4">
                        <label
                          class="overline font-weight-bold blue-grey--text text-darken2"
                        >
                          área
                        </label>
                        <div class="body-2">
                          {{ item.areaDesc }}
                        </div>
                      </div>
                      <div v-show="item.motivo === 2">
                        <label
                          class="overline font-weight-bold blue-grey--text text-darken2"
                        >
                          mínimo - máximo
                        </label>
                        <div class="body-2">
                          {{ item.minimo }} - {{ item.maximo }}
                        </div>
                      </div>
                      <div v-show="item.motivo === 3">
                        <label
                          class="overline font-weight-bold blue-grey--text text-darken2"
                        >
                          monto
                        </label>
                        <div class="body-2">
                          {{ item.monto != null ? formatMXN(item.monto) : "" }}
                        </div>
                      </div>
                    </v-col>
                  </v-row>

                  <div></div>
                </v-card>
              </v-col>
              <v-col cols="6">
                <div class="ma-3">
                  <v-btn
                    block
                    depressed
                    class="rounded-lg"
                    x-large
                    color="primary"
                    @click="
                      idSolicitud = item.id;
                      muestraColaboradores = true;
                    "
                  >
                    <v-icon left>mdi-account-eye</v-icon>
                    ver colaboradores asignados
                  </v-btn>
                  <v-btn
                    v-show="[1, 2].includes(item.motivo)"
                    block
                    depressed
                    outlined
                    class="rounded-lg mt-3"
                    x-large
                    color="primary"
                    @click="
                      idSolicitud = item.id;
                      muestraFiguras = true;
                    "
                  >
                    <v-icon left>mdi-table-eye</v-icon>
                    ver figuras registradas
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-card>
        </td>
      </template>
      <template v-slot:item.fecha="{ item }">
        {{ setFormatDate(item.fecha) }}
      </template>
      <template v-slot:item.estatus="{ item }">
        <v-chip
          small
          depressed
          :color="getColorEstatus(item.estatus)"
          class="font-weight-bold"
        >
          {{ setEstatusText(item.estatus) }}
        </v-chip>
      </template>
      <template v-slot:item.fecha_registro="{ item }">
        {{ setFormatDateTime(item.fecha_registro) }}
      </template>
      <template v-slot:item.costo="{ item }">
        <label class="font-weight-bold font-italic">{{
          formatMXN(item.costo)
        }}</label>
      </template>
    </v-data-table>

    <VerColaboradoresPorSolicitud
      v-if="muestraColaboradores"
      :muestraColaboradores="muestraColaboradores"
      :idSolicitud="idSolicitud"
      @ocultaColaboradores="muestraColaboradores = false"
    />

    <VerFigurasPorSolicitud
      v-if="muestraFiguras"
      :muestraFiguras="muestraFiguras"
      :idSolicitud="idSolicitud"
      @ocultaFiguras="muestraFiguras = false"
    />
  </v-card>
</template>

<script>
import axios from "axios";
import { deleteToken } from "../../../../../store/Funciones/funciones";
import VerColaboradoresPorSolicitud from "./actions/VerColaboradoresPorSolicitud";
import VerFigurasPorSolicitud from "./actions/VerFigurasPorSolicitud.vue";
import VueJsonToCsv from "vue-json-to-csv";

export default {
  name: "tabla-solicitudes-descanso-laborado",
  components: {
    VerColaboradoresPorSolicitud,
    VerFigurasPorSolicitud,
    VueJsonToCsv,
  },
  data() {
    return {
      headers: [
        {
          value: "id",
          text: "ID",
          class:
            "text-uppercase font-weight-bold primary white--text",
        },
        {
          value: "fecha",
          text: "fecha",
          class: "text-uppercase font-weight-bold primary white--text",
        },
        {
          value: "motivo",
          text: "motivo",
          class: "text-uppercase font-weight-bold primary white--text",
        },
        {
          value: "responsable",
          text: "responsable",
          class: "text-uppercase font-weight-bold primary white--text",
        },
        {
          value: "deptoDesc",
          text: "departamento",
          align: "center",
          class: "text-uppercase font-weight-bold primary white--text",
        },
        {
          value: "fecha_registro",
          text: "registro",
          align: "center",
          class: "text-uppercase font-weight-bold primary white--text",
        },
        {
          value: "costo",
          text: "costo",
          align: "center",
          class: "text-uppercase font-weight-bold primary white--text",
        },
        {
          value: "estatus",
          text: "estatus",
          align: "center",
          class: "text-uppercase font-weight-bold primary white--text",
        },
        {
          value: "accion",
          text: "",
          sortable: false,
          class: "text-uppercase font-weight-bold primary white--text",
        },
        {
          text: "",
          value: "data-table-expand",
          class:
            "text-uppercase font-weight-bold primary white--text",
        },
      ],
      headers_to_export: {
        id: { title: "id" },
        fecha: { title: "fecha" },
        responsable: { title: "responsable" },
        deptoDesc: { title: "deptoDesc" },
        maximo: { title: "maximo" },
        minimo: { title: "minimo" },
        monto: { title: "monto" },
        fecha_registro: { title: "fecha_registro" },
        justificacion: { title: "justificacion" },
        estatus: { title: "estatus" },
        costo: { title: "costo" },
      },
      headerProps: {
        sortByText: "Ordenar por",
      },
      solicitudes: [],
      expanded: [],
      emplAux: {},
      search: "",
      idSolicitud: null,
      muestraColaboradores: false,
      muestraFiguras: false,
      loadingData: true,
      muestraBarra: false,
      colorBarra: "",
      textoBarra: "",
    };
  },
  async mounted() {
    this.solicitudes = await this.getSolicitudes();
  },
  methods: {
    showSnackBar(texto, color = "error") {
      this.textoBarra = texto;
      this.colorBarra = color;
      this.muestraBarra = true;
    },
    async actualizarTabla() {
      this.loadingData = true;
      this.solicitudes = await this.getSolicitudes();
    },
    getSolicitudes() {
      const headers = {
        Authorization: localStorage.getItem("token"),
      };

      return new Promise((res) => {
        axios
          .get(
            `${this.$store.getters.getHostNode}/api/descanso-laborado/solicitudes`,
            {
              headers,
            }
          )
          .then((response) => {
            if (response.data.status == "EXPSESSION") {
              deleteToken();
            } else if (response.data.status == "OK")  {
              res(response.data.data);
            } else {
              res([])
            }
          })
          .finally(() => {
            this.loadingData = false;
          });
      });
    },
    setFormatDate(date) {
      let fecha = new Date(date);

      let diaUTC = fecha.getUTCDate();
      let mesUTC = fecha.getUTCMonth() + 1;
      let anioUTC = fecha.getUTCFullYear();

      diaUTC = diaUTC < 10 ? "0" + diaUTC : diaUTC;
      mesUTC = mesUTC < 10 ? "0" + mesUTC : mesUTC;

      return diaUTC + "/" + mesUTC + "/" + anioUTC;
    },
    setFormatDateTime(date) {
      let fecha = new Date(date);

      let diaUTC = fecha.getUTCDate();
      let mesUTC = fecha.getUTCMonth() + 1;
      let anioUTC = fecha.getUTCFullYear();

      let horasUTC = fecha.getUTCHours();
      let minutosUTC = fecha.getUTCMinutes();
      let segundosUTC = fecha.getUTCSeconds();

      // Formateo de día, mes, horas, minutos y segundos con ceros iniciales si es necesario
      diaUTC = diaUTC < 10 ? "0" + diaUTC : diaUTC;
      mesUTC = mesUTC < 10 ? "0" + mesUTC : mesUTC;
      horasUTC = horasUTC < 10 ? "0" + horasUTC : horasUTC;
      minutosUTC = minutosUTC < 10 ? "0" + minutosUTC : minutosUTC;
      segundosUTC = segundosUTC < 10 ? "0" + segundosUTC : segundosUTC;

      return `${diaUTC}/${mesUTC}/${anioUTC} ${horasUTC}:${minutosUTC}:${segundosUTC}`;
    },
    setEstatusText(estatus) {
      switch (estatus) {
        case 0:
          return "Abierta";
        case 2:
          return "Autorizada";
        case 3:
          return "Rechazada";
        default:
          return "No disponible";
      }
    },
    getColorEstatus(estatus) {
      switch (estatus) {
        case 0:
          return "yellow lighten-4";
        case 2:
          return "green lighten-3";
        case 3:
          return "red lighten-3";
        default:
          return "blue-grey lighten-3";
      }
    },
    formatMXN(cadena) {
      return cadena.toLocaleString("es-MX", {
        style: "currency",
        currency: "MXN",
      });
    },
  },
};
</script>
