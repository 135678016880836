<template>
  <Bar
    :chart-options="chartOptions"
    :chart-data="chartData"
    :chart-id="chartId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :width="width"
    :height="height"
  />
</template>

<script>
import { Bar } from "vue-chartjs/legacy";
import axios from "axios";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
);

export default {
  name: "ofertas-creadas-por-mes",
  components: {
    Bar,
  },
  props: {
    chartId: {
      type: String,
      default: "bar-chart",
    },
    datasetIdKey: {
      type: String,
      default: "label",
    },
    width: {
      type: Number,
      default: 600,
    },
    height: {
      type: Number,
      default: 160,
    },
    cssClasses: {
      default: "",
      type: String,
    },
    styles: {
      type: Object,
      default: () => {},
    },
    plugins: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      chartData: {
        labels: [],
        datasets: [],
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
      },
      abiertas: [],
    };
  },

  mounted() {
    this.getIndicadorOfertasCreadas();
  },

  methods: {
    getIndicadorOfertasCreadas() {
      let params = {
        Opcion: 25,
        token: localStorage.getItem("token"),
      };
      axios
        .post(
          `${this.$store.getters.getHost}/Nominas/Compensaciones/ws-ofertasEconomicas.php`,
          params
        )
        .then((response) => {
          if (response.data.status == "OK") {
            this.chartData.labels = Object.keys(response.data.ofertas);
            this.chartData.datasets.push({
              label: "Ofertas creadas por mes",
              backgroundColor: "#0078B4",
              data: Object.values(response.data.ofertas),
            });
          }
        });
    },
    fillDatasets() {
      this.chartData.datasets.push({
        label: "Vacantes abiertas por mes",
        backgroundColor: "#0078B4",
        data: this.abiertas,
      });
      this.chartData.datasets.push({
        label: "Vacantes cerradas por mes",
        backgroundColor: "#787878",
        data: this.cerradas,
      });
    },
  },
};
</script>
