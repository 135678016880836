var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.incidencias,"search":_vm.buscar,"footer-props":{ 'items-per-page-text': 'Elementos por página' },"loading":_vm.loading,"dense":"","mobile-breakpoint":"0","no-data-text":"No se encuentran incidencias para estas fechas","loading-text":"Cargando contentenido..."},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{staticClass:"rounded-lg rounded-b-0 rounded-t-0",attrs:{"flat":"","color":"primary"}},[_c('v-text-field',{staticClass:"mt-7 rounded-lg",attrs:{"label":_vm.$t('DH360.findTextBox'),"dense":"","clearable":"","flat":"","solo-inverted":"","dark":"","prepend-inner-icon":"mdi-magnify"},model:{value:(_vm.buscar),callback:function ($$v) {_vm.buscar=$$v},expression:"buscar"}}),_c('v-spacer'),_c('v-tooltip',{attrs:{"top":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","color":"teal darken-2","dark":""},on:{"click":function($event){return _vm.cambiarPeriodo()}}},'v-btn',attrs,false),on),[_vm._v("Cambiar periodo")])]}}])},[_c('span',{staticClass:"caption center-item-in-div-column"},[_vm._v(" Periodo: "),_c('br'),_c('strong',[_vm._v(_vm._s(_vm.fechaInicial)+" - "+_vm._s(_vm.fechaFinal))])])])],1)]},proxy:true},{key:"item.TipoIncidencia",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.detallesIncidencia(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-account-child-circle")])],1)]}}],null,true)},[_c('span',{staticClass:"caption center-item-in-div-column"},[_vm._v("["+_vm._s(item.TipoIncidencia)+"] Olvido de gafete "),_c('strong',[_vm._v("Ver detalles")])])])]}}],null,true)}),(_vm.dialogDetalles)?_c('detalles-colaborador',{attrs:{"dialog":_vm.dialogDetalles,"item":_vm.item,"TipoIncidencia":4},on:{"cierraDetalle":_vm.refrescar}}):_vm._e(),(_vm.dialogPeriodo)?_c('filtro-reporte',{attrs:{"dialog":_vm.dialogPeriodo,"fecha1":_vm.fechaInicial,"fecha2":_vm.fechaFinal},on:{"cierraFiltro":_vm.actualizaFechas}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }