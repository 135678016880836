import axios from 'axios'
import SnackBarErrors from '../../General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue'
import LoadingBar from '../components/loadingBar.vue'
import { deleteToken } from '../../../../store/Funciones/funciones'

export default {
    name: 'esquemas-de-trabajo',
    components: {
        SnackBarErrors,
        LoadingBar
    },
    props: [],
    data: () => ({
        muestraLoading: false,
        textoCargando: "",
        dialog: false,
        dialogDelete: false,
        dialogCargaArchivo: false,
        dialogAgregaParticipante: false,
        muestraBarra: false,
        colaboradores: [],
        textoBarra: "",
        colorBarra: "blue",
        responseApi: "",
        headers: [{
                text: '# Empleado',
                align: 'start',
                sortable: false,
                value: 'Emplid',
            },
            {
                text: 'Nombre',
                value: 'NAME'
            },
            {
                text: 'Fecha Incio',
                value: 'Fecha_aplicacion'
            },
            {
                text: 'Fecha Fin',
                value: 'Fecha_Fin'
            },
            {
                text: 'Estatus',
                value: 'habilitado'
            },
            {
                text: 'Razón',
                value: 'razon'
            },
            {
                text: 'Actions',
                value: 'actions',
                sortable: false
            },
        ],
        loadingTabla: true,
        loadingBotonGuardar: false,
        file: '',
        respuestaUpdate: "",
        itemsPerPage: 8,
        search: '',
        aQuien: '',
        loadingColaboradores: false,
        colaboradoresNombre: [],
        colaboradoresAutocomplate: [],
        alert: false,
        textAlert: "",
        colorAlert: "",
        iconAlert: 'mdi-alert-circle',
        respuestaAddColaborador: '',
        loadingGuardarColaborador: false,
        // HOME OFFICE --------------------------------------------------------------------------------------------------
        searchHomeOffice: '',
        colaboradoresHomeOffice: [],
        loadingTablaHomeOffice: true,
        dialogCargaArchivoHomeOffice: false,
        aQuienHomeOffice: "",
        dialogAgregaParticipanteHomeOffice: false,
        // FLEX TIME ----------------------------------------------------------------------------------------------------
        searchFlexTime: '',
        colaboradoresFlexTime: [],
        loadingTablaFlexTime: true,
        dialogCargaArchivoFlexTime: false,
        aQuienFlexTime: "",
        dialogAgregaParticipanteFlexTime: false,
    }),
    created() {
        this.saveMonitoreo();
    },
    mounted() {
        this.getColaboradores();
        this.getColaboradoresHomeOffice();
        this.getColaboradoresFlexTime();
    },

    methods: {
        saveMonitoreo() {
            let params = {
                Opcion: 10,
                pantalla: "Esquemas de trabajo",
                token: localStorage.getItem('token')
            }
            axios
                .post(`${this.$store.getters.getHost}/General/ws-general.php`, params)
                .then((response) => {
                    console.log(response.data)
                })
        },
        getColaboradores() {
            let params = {
                Opcion: 1,
                token: localStorage.getItem('token')
            }
            axios
                .post(`${this.$store.getters.getHost}/equemasTrabajo/ws-esquemas_de_trabajo.php`, params)
                .then((response) => {
                    if (response.data.status == "EXPSESSION") {
                        deleteToken();
                    } else {
                        var data = response.data.teletrabajo.map(colaborador => {
                            colaborador['habilitado'] = (colaborador['habilitado']) ? 'Activo' : 'Inactivo';
                            return colaborador;
                        })
                        this.colaboradores = data;
                        this.loadingTabla = false;
                    }

                })
                .catch(function(e) {

                    let result = e.toString().includes("401");
                    if (result) {
                        window.location.reload();
                    } else {
                        alert(e);
                    }
                });
        },
        handleFileUpload() {
            this.file = this.$refs.file.files[0];
        },
        submitFile() {
            this.loadingBotonGuardar = true;
            if (this.file.type == "application/vnd.ms-excel") {
                var reader = new FileReader()
                reader.readAsDataURL(this.file)
                reader.onload = () => {
                    var params = {
                        Opcion: 8,
                        filename: "candidatosPermiso.csv",
                        base64: reader.result,
                        token: localStorage.getItem('token')
                    }
                    axios.post(`${ this.$store.getters.getHost }/General/ws-general.php`, params)
                        .then((response) => {
                            this.responseApi = response.data;
                            this.validaRespuesta()
                        }).catch(function(e) {

                            let result = e.toString().includes("401");
                            if (result) {
                                window.location.reload();
                            } else {
                                alert(e);
                            }
                        });
                };
            } else {
                this.textErrorSub = 'Error. El archivo no es tipo CSV.';
                this.mensajeErrorSub = true;
            }
        },
        validaRespuesta() {
            this.loadingBotonGuardar = false;
            // //(this.responseApi)
            if (this.responseApi.trim() == "candidatosPermiso.csv") {
                this.textoBarra = "Archivo cargado con éxito";
                this.muestraBarra = true;
                this.textoCargando = "Validando información, espera por favor...";
                this.muestraLoading = true;
                this.cargaInformacionArhivo();
            }
        },
        abreCargaArchivo() {
            this.dialogCargaArchivo = true
        },
        cargaInformacionArhivo() {
            this.dialogCargaArchivo = false;
            this.colaboradores.splice(0, this.colaboradores.length);

            let params = {
                Opcion: 2,
                token: localStorage.getItem('token')
            }
            axios
                .post(`${this.$store.getters.getHost }/equemasTrabajo/ws-esquemas_de_trabajo.php`, params)
                .then((response) => {
                    var data = response.data.teletrabajo.map(colaborador => {
                        colaborador['habilitado'] = (colaborador['habilitado']) ? 'Activo' : 'Inactivo';
                        return colaborador;
                    })
                    this.colaboradores = data;
                    this.muestraLoading = false;
                })
                .catch(function(e) {

                    let result = e.toString().includes("401");
                    if (result) {
                        window.location.reload();
                    } else {
                        alert(e);
                    }
                });
        },

        actualizaFechasMasivo() {
            if (window.confirm("¿Deseas renovar las fechas finales de " + this.colaboradores.length + " colaboradores?")) {
                this.loadingTabla = true;
                let params = {
                    Opcion: 4,
                    token: localStorage.getItem('token')
                }
                axios
                    .post(`${this.$store.getters.getHost }/equemasTrabajo/ws-esquemas_de_trabajo.php`, params)
                    .then((response) => {
                        var data = response.data.teletrabajo;
                        this.respuestaUpdate = data;
                        this.validaUpdate(2);
                    })
                    .catch(function(e) {

                        let result = e.toString().includes("401");
                        if (result) {
                            window.location.reload();
                        } else {
                            alert(e);
                        }
                    });
            }
        },

        actualizaEstatusMasivo() {
            if (window.confirm("¿Deseas renovar el estatus de " + this.colaboradores.length + " colaboradores?")) {
                this.loadingTabla = true;
                let params = {
                    Opcion: 5,
                    token: localStorage.getItem('token')
                }
                axios
                    .post(`${this.$store.getters.getHost }/equemasTrabajo/ws-esquemas_de_trabajo.php`, params)
                    .then((response) => {
                        console.log(response.data)
                        var data = response.data.teletrabajo;
                        this.respuestaUpdate = data;
                        this.validaUpdate(3);
                    })
                    .catch(function(e) {

                        let result = e.toString().includes("401");
                        if (result) {
                            window.location.reload();
                        } else {
                            alert(e);
                        }
                    });
            }
        },

        updateEstatus(item) {
            if (window.confirm("¿Deseas editar el estatus de " + item["NAME"] + "?")) {
                // //(item["Emplid"]);
                this.loadingTabla = true;
                let nuevoEstatus = -1;
                if (item["habilitado"] == "Activo") {
                    nuevoEstatus = 0;
                } else {
                    nuevoEstatus = 1
                }

                let params = {
                    Opcion: 3,
                    token: localStorage.getItem('token'),
                    emplid: item["Emplid"],
                    nuevoEstatus: nuevoEstatus
                }
                axios
                    .post(`${this.$store.getters.getHost}/equemasTrabajo/ws-esquemas_de_trabajo.php`, params)
                    .then((response) => {
                        console.log(response.data)
                        var data = response.data.teletrabajo;
                        this.respuestaUpdate = data;

                        this.validaUpdate(1);
                    })
                    .catch(function(e) {

                        let result = e.toString().includes("401");
                        if (result) {
                            window.location.reload();
                        } else {
                            alert(e);
                        }
                    });
            }

        },
        getColaboradoresNombre() {
            let params = {
                Opcion: 11,
                token: localStorage.getItem('token')
            }
            axios
                .post(`${ this.$store.getters.getHost }/General/ws-general.php`, params)
                .then((response) => {
                    var data = response.data.colaboradores;
                    this.colaboradoresAutocomplate = data;
                    this.colaboradoresAutocomplate.map((elemento) => {
                        this.colaboradoresNombre.push(elemento["NAME"]);
                    });
                    this.loadingColaboradores = false;
                })
                .catch(function(e) {

                    let result = e.toString().includes("401");
                    if (result) {
                        window.location.reload();
                    } else {
                        alert(e);
                    }
                });
        },
        validaUpdate(opc) {
            if (opc == 1) {
                if (this.respuestaUpdate == 1) {
                    this.textoBarra = "Registro modificado exitosamente.";
                    this.colorBarra = "success";
                    this.muestraBarra = true;
                    this.getColaboradores();
                } else {
                    this.textoBarra = "Error. Falló la modificación.";
                    this.colorBarra = "error";
                    this.muestraBarra = true;
                }
            }
            if (opc == 2) {
                if (this.respuestaUpdate > 0) {
                    this.textoBarra = "Se modificaron " + this.respuestaUpdate + " exitosamente.";
                    this.colorBarra = "success";
                    this.muestraBarra = true;
                    this.getColaboradores();
                } else {
                    this.textoBarra = "Error. Falló la modificación.";
                    this.colorBarra = "error";
                    this.muestraBarra = true;
                }
            }

            if (opc == 3) {
                if (this.respuestaUpdate > 0) {
                    this.textoBarra = "Se modificaron " + this.respuestaUpdate + " exitosamente.";
                    this.colorBarra = "success";
                    this.muestraBarra = true;
                    this.getColaboradores();
                } else {
                    this.textoBarra = "Error. Falló la modificación.";
                    this.colorBarra = "error";
                    this.muestraBarra = true;
                }
            }

            if (opc == 4) {
                if (this.respuestaUpdate == 1) {
                    this.textoBarra = "Registro modificado exitosamente.";
                    this.colorBarra = "success";
                    this.muestraBarra = true;
                    this.getColaboradoresHomeOffice();
                } else {
                    this.textoBarra = "Error. Falló la modificación.";
                    this.colorBarra = "error";
                    this.muestraBarra = true;
                }
            }

            if (opc == 5) {
                if (this.respuestaUpdate > 0) {
                    this.textoBarra = "Se modificaron " + this.respuestaUpdate + " exitosamente.";
                    this.colorBarra = "success";
                    this.muestraBarra = true;
                    this.getColaboradoresHomeOffice();
                } else {
                    this.textoBarra = "Error. Falló la modificación.";
                    this.colorBarra = "error";
                    this.muestraBarra = true;
                }
            }

            if (opc == 6) {
                if (this.respuestaUpdate > 0) {
                    this.textoBarra = "Se modificaron " + this.respuestaUpdate + " exitosamente.";
                    this.colorBarra = "success";
                    this.muestraBarra = true;
                    this.getColaboradoresHomeOffice();
                } else {
                    this.textoBarra = "Error. Falló la modificación.";
                    this.colorBarra = "error";
                    this.muestraBarra = true;
                }
            }

            if (opc == 7) {
                if (this.respuestaUpdate == 1) {
                    this.textoBarra = "Registro modificado exitosamente.";
                    this.colorBarra = "success";
                    this.muestraBarra = true;
                    this.getColaboradoresFlexTime();
                } else {
                    this.textoBarra = "Error. Falló la modificación.";
                    this.colorBarra = "error";
                    this.muestraBarra = true;
                }
            }

            if (opc == 8) {
                if (this.respuestaUpdate > 0) {
                    this.textoBarra = "Se modificaron " + this.respuestaUpdate + " exitosamente.";
                    this.colorBarra = "success";
                    this.muestraBarra = true;
                    this.getColaboradoresFlexTime();
                } else {
                    this.textoBarra = "Error. Falló la modificación.";
                    this.colorBarra = "error";
                    this.muestraBarra = true;
                }
            }

            if (opc == 9) {
                if (this.respuestaUpdate > 0) {
                    this.textoBarra = "Se modificaron " + this.respuestaUpdate + " exitosamente.";
                    this.colorBarra = "success";
                    this.muestraBarra = true;
                    this.getColaboradoresFlexTime();
                } else {
                    this.textoBarra = "Error. Falló la modificación.";
                    this.colorBarra = "error";
                    this.muestraBarra = true;
                }
            }

        },
        buscaColaborador() {
            this.loadingColaboradores = true;
            this.dialogAgregaParticipante = true;
            this.getColaboradoresNombre();
        },
        validaInformacionAddColaborador() {
            let result = this.colaboradoresAutocomplate.filter(
                (colaboralor) => colaboralor["NAME"] == this.aQuien
            );

            let params = {
                Opcion: 6,
                token: localStorage.getItem('token'),
                folio: result[0]["EMPLID"]
            }
            this.loadingGuardarColaborador = true;
            //(params)
            axios
                .post(`${ this.$store.getters.getHost }/equemasTrabajo/ws-esquemas_de_trabajo.php `, params)
                .then((response) => {
                    console.log(response.data)
                    var data = response.data.teletrabajo;
                    //(data)
                    this.respuestaAddColaborador = data;
                    this.validaRespuestaAddColaborador();

                })
                .catch(function(e) {

                    let result = e.toString().includes("401");
                    if (result) {
                        window.location.reload();
                    } else {
                        alert(e);
                    }
                });
        },

        validaRespuestaAddColaborador() {
            this.loadingGuardarColaborador = false;
            //(this.respuestaAddColaborador)
            if (this.respuestaAddColaborador["response"] == 1) {
                this.textAlert = "¡ERROR!. El o la colaboradora ya cuenta con un permiso aún vigente.";
                this.colorAlert = "pink";
                this.alert = true;
            } else if (this.respuestaAddColaborador["response"] == 2) {
                this.loadingTabla = true;
                this.colaboradores.splice(0, this.colaboradores.length);
                this.dialogAgregaParticipante = false;
                this.getColaboradores();
            } else {
                this.textAlert = "¡ERROR!. Falló el proceso, no se guardó tu nuevo registro.";
                this.colorAlert = "pink";
                this.alert = true;
            }
        },

        //!!!!!!!!! *********************************** HOME OFFICE *********************************************************************
        getColaboradoresHomeOffice() {
            let params = {
                Opcion: 7,
                token: localStorage.getItem('token')
            }
            axios
                .post(`${ this.$store.getters.getHost }/equemasTrabajo/ws-esquemas_de_trabajo.php`, params)
                .then((response) => {
                    // //(response.data);
                    var data = response.data.homeOffice.map(colaborador => {
                        colaborador['habilitado'] = (colaborador['habilitado']) ? 'Activo' : 'Inactivo';
                        return colaborador;
                    })
                    this.colaboradoresHomeOffice = data;
                    this.loadingTablaHomeOffice = false;
                })
                .catch(function(e) {

                    let result = e.toString().includes("401");
                    if (result) {
                        window.location.reload();
                    } else {
                        alert(e);
                    }
                });
        },
        abreCargaArchivoHomeOffice() {
            this.dialogCargaArchivoHomeOffice = true
        },
        submitFileHomeOffice() {
            this.loadingTablaHomeOffice = true;
            this.loadingBotonGuardar = true;
            if (this.file.type == "application/vnd.ms-excel") {
                var reader = new FileReader()
                reader.readAsDataURL(this.file)
                reader.onload = () => {
                    var params = {
                        Opcion: 8,
                        filename: "candidatosPermisoHomeOffice.csv",
                        base64: reader.result,
                        token: localStorage.getItem('token')
                    }
                    axios.post(`${ this.$store.getters.getHost }/General/ws-general.php `, params)
                        .then((response) => {
                            this.responseApi = response.data;
                            this.validaRespuestaSubmitFileHomeOffice()
                        }).catch(function(e) {

                            let result = e.toString().includes("401");
                            if (result) {
                                window.location.reload();
                            } else {
                                alert(e);
                            }
                        });
                };
            } else {
                this.textErrorSub = 'Error. El archivo no es tipo CSV.';
                this.mensajeErrorSub = true;
            }
        },
        validaRespuestaSubmitFileHomeOffice() {
            this.loadingBotonGuardar = false;
            //(this.responseApi.trim())
            // //(this.responseApi)
            if (this.responseApi.trim() == "candidatosPermisoHomeOffice.csv") {
                this.textoBarra = "Archivo cargado con éxito";
                this.muestraBarra = true;
                this.textoCargando = "Validando información, espera por favor...";
                this.muestraLoading = true;
                this.cargaInformacionArhivoHomeOffice();
            }
        },

        cargaInformacionArhivoHomeOffice() {
            this.dialogCargaArchivoHomeOffice = false;
            this.colaboradoresHomeOffice.splice(0, this.colaboradoresHomeOffice.length);

            let params = {
                Opcion: 8,
                token: localStorage.getItem('token')
            }
            axios
                .post(`${this.$store.getters.getHost}/equemasTrabajo/ws-esquemas_de_trabajo.php`, params)
                .then((response) => {
                    var data = response.data.homeOffice.map(colaborador => {
                        colaborador['habilitado'] = (colaborador['habilitado']) ? 'Activo' : 'Inactivo';
                        return colaborador;
                    })
                    this.colaboradoresHomeOffice = data;
                    this.muestraLoading = false;
                    this.getColaboradoresHomeOffice();
                })
                .catch(function(e) {

                    let result = e.toString().includes("401");
                    if (result) {
                        window.location.reload();
                    } else {
                        alert(e);
                    }
                });
        },

        updateEstatusHomeOffice(item) {
            if (window.confirm("¿Deseas editar el estatus de " + item["NAME"] + "?")) {
                // //(item["Emplid"]);
                this.loadingTablaHomeOffice = true;
                let nuevoEstatus = -1;
                if (item["habilitado"] == "Activo") {
                    nuevoEstatus = 0;
                } else {
                    nuevoEstatus = 1
                }

                let params = {
                    Opcion: 9,
                    token: localStorage.getItem('token'),
                    emplid: item["Emplid"],
                    nuevoEstatus: nuevoEstatus
                }
                axios
                    .post(`${ this.$store.getters.getHost }/equemasTrabajo/ws-esquemas_de_trabajo.php`, params)
                    .then((response) => {
                        var data = response.data.homeOffice;
                        this.respuestaUpdate = data;
                        this.validaUpdate(4);
                    })
                    .catch(function(e) {

                        let result = e.toString().includes("401");
                        if (result) {
                            window.location.reload();
                        } else {
                            alert(e);
                        }
                    });
            }

        },

        actualizaFechasMasivoHomeOffice() {
            if (window.confirm("¿Deseas renovar las fechas finales de " + this.colaboradoresHomeOffice.length + " colaboradores?")) {
                this.loadingTablaHomeOffice = true;
                let params = {
                    Opcion: 10,
                    token: localStorage.getItem('token')
                }
                axios
                    .post(`${ this.$store.getters.getHost }/equemasTrabajo/ws-esquemas_de_trabajo.php `, params)
                    .then((response) => {
                        var data = response.data.homeOffice;
                        this.respuestaUpdate = data;
                        this.validaUpdate(5);
                    })
                    .catch(function(e) {

                        let result = e.toString().includes("401");
                        if (result) {
                            window.location.reload();
                        } else {
                            alert(e);
                        }
                    });
            }
        },

        actualizaEstatusMasivoHomeOffice() {
            if (window.confirm("¿Deseas renovar el estatus de " + this.colaboradoresHomeOffice.length + " colaboradores?")) {
                this.loadingTablaHomeOffice = true;
                let params = {
                    Opcion: 11,
                    token: localStorage.getItem('token')
                }
                axios
                    .post(`${ this.$store.getters.getHost }/equemasTrabajo/ws-esquemas_de_trabajo.php`, params)
                    .then((response) => {
                        var data = response.data.homeOffice;
                        this.respuestaUpdate = data;
                        this.validaUpdate(6);
                    })
                    .catch(function(e) {

                        let result = e.toString().includes("401");
                        if (result) {
                            window.location.reload();
                        } else {
                            alert(e);
                        }
                    });
            }
        },

        buscaColaboradorHomeOffice() {
            this.loadingColaboradores = true;
            this.dialogAgregaParticipanteHomeOffice = true;
            this.getColaboradoresNombre();
        },
        validaInformacionAddColaboradorHomeOffice() {
            let result = this.colaboradoresAutocomplate.filter(
                (colaboralor) => colaboralor["NAME"] == this.aQuienHomeOffice
            );
            this.loadingGuardarColaborador = true;
            let params = {
                Opcion: 12,
                token: localStorage.getItem('token'),
                folio: result[0]["EMPLID"]
            }
            axios
                .post(`${this.$store.getters.getHost}/equemasTrabajo/ws-esquemas_de_trabajo.php`, params)
                .then((response) => {
                    var data = response.data.homeOffice;
                    // //(data)
                    this.respuestaAddColaborador = data;

                    this.validaRespuestaAddColaboradorHomeOffice();

                })
                .catch(function(e) {

                    let result = e.toString().includes("401");
                    if (result) {
                        window.location.reload();
                    } else {
                        alert(e);
                    }
                });
        },

        validaRespuestaAddColaboradorHomeOffice() {
            this.loadingGuardarColaborador = false;
            // //(this.respuestaAddColaborador)
            if (this.respuestaAddColaborador["response"] == 1) {
                this.textAlert = "¡ERROR!. El o la colaboradora ya cuenta con un permiso aún vigente.";
                this.colorAlert = "pink";
                this.alert = true;
            } else if (this.respuestaAddColaborador["response"] == 2) {
                this.loadingTablaHomeOffice = true;
                this.colaboradores.splice(0, this.colaboradores.length);
                this.dialogAgregaParticipanteHomeOffice = false;
                this.getColaboradoresHomeOffice();
            } else {
                this.textAlert = "¡ERROR!. Falló el proceso, no se guardó tu nuevo registro.";
                this.colorAlert = "pink";
                this.alert = true;
            }
        },

        //!!!!!!!!! *********************************** FLEX TIME *********************************************************************

        getColaboradoresFlexTime() {
            let params = {
                Opcion: 13,
                token: localStorage.getItem('token')
            }
            axios
                .post(`${ this.$store.getters.getHost }/equemasTrabajo/ws-esquemas_de_trabajo.php`, params)
                .then((response) => {
                    console.log(response.data)
                    var data = response.data.viernesCorto.map(colaborador => {
                        colaborador['habilitado'] = (colaborador['habilitado']) ? 'Activo' : 'Inactivo';
                        return colaborador;
                    })
                    this.colaboradoresFlexTime = data;
                    this.loadingTablaFlexTime = false;
                    //("loadingTablaFlexTime:" + this.loadingTablaFlexTime);
                })
                .catch(function(e) {

                    let result = e.toString().includes("401");
                    if (result) {
                        window.location.reload();
                    } else {
                        alert(e);
                    }
                });
        },
        abreCargaArchivoFlexTime() {
            this.dialogCargaArchivoFlexTime = true
        },
        submitFileFlexTime() {
            this.loadingTablaFlexTime = true;
            this.loadingBotonGuardar = true;
            if (this.file.type == "application/vnd.ms-excel") {
                var reader = new FileReader()
                reader.readAsDataURL(this.file)
                reader.onload = () => {
                    var params = {
                        Opcion: 8,
                        filename: "candidatosPermisoFlexTime.csv",
                        base64: reader.result,
                        token: localStorage.getItem('token')
                    }
                    axios.post(`${ this.$store.getters.getHost }/General/ws-general.php `, params)
                        .then((response) => {
                            this.responseApi = response.data;
                            this.validaRespuestaSubmitFileFlexTime()
                        }).catch(function(e) {

                            let result = e.toString().includes("401");
                            if (result) {
                                window.location.reload();
                            } else {
                                alert(e);
                            }
                        });
                };
            } else {
                this.textErrorSub = 'Error. El archivo no es tipo CSV.';
                this.mensajeErrorSub = true;
            }
        },
        validaRespuestaSubmitFileFlexTime() {
            this.loadingBotonGuardar = false;
            if (this.responseApi.trim() == "candidatosPermisoFlexTime.csv") {
                this.textoBarra = "Archivo cargado con éxito";
                this.muestraBarra = true;
                this.textoCargando = "Validando información, espera por favor...";
                this.muestraLoading = true;
                this.cargaInformacionArhivoFlexTime();
            }
        },

        cargaInformacionArhivoFlexTime() {
            this.dialogCargaArchivoFlexTime = false;
            this.colaboradoresFlexTime.splice(0, this.colaboradoresFlexTime.length);

            let params = {
                Opcion: 14,
                token: localStorage.getItem('token')
            }
            axios
                .post(`${this.$store.getters.getHost }/equemasTrabajo/ws-esquemas_de_trabajo.php `, params)
                .then((response) => {
                    var data = response.data.viernesCorto.map(colaborador => {
                        colaborador['habilitado'] = (colaborador['habilitado']) ? 'Activo' : 'Inactivo';
                        return colaborador;
                    })
                    this.colaboradoresFlexTime = data;
                    this.muestraLoading = false;
                    this.getColaboradoresFlexTime();
                })
                .catch(function(e) {

                    let result = e.toString().includes("401");
                    if (result) {
                        window.location.reload();
                    } else {
                        alert(e);
                    }
                });
        },

        updateEstatusFlexTime(item) {
            if (window.confirm("¿Deseas editar el estatus de " + item["NAME"] + "?")) {
                this.loadingTablaFlexTime = true;
                let nuevoEstatus = -1;
                if (item["habilitado"] == "Activo") {
                    nuevoEstatus = 0;
                } else {
                    nuevoEstatus = 1
                }

                let params = {
                    Opcion: 15,
                    token: localStorage.getItem('token'),
                    emplid: item["Emplid"],
                    nuevoEstatus: nuevoEstatus
                }
                axios
                    .post(`${ this.$store.getters.getHost }/equemasTrabajo/ws-esquemas_de_trabajo.php`, params)
                    .then((response) => {
                        var data = response.data.viernesCorto;
                        this.respuestaUpdate = data;
                        this.validaUpdate(7);
                    })
                    .catch(function(e) {

                        let result = e.toString().includes("401");
                        if (result) {
                            window.location.reload();
                        } else {
                            alert(e);
                        }
                    });
            }

        },

        actualizaFechasMasivoFlexTime() {
            if (window.confirm("¿Deseas renovar las fechas finales de " + this.colaboradoresFlexTime.length + " colaboradores?")) {
                this.loadingTablaFlexTime = true;
                let params = {
                    Opcion: 16,
                    token: localStorage.getItem('token')
                }
                axios
                    .post(`${this.$store.getters.getHost}/equemasTrabajo/ws-esquemas_de_trabajo.php`, params)
                    .then((response) => {
                        var data = response.data.viernesCorto;
                        this.respuestaUpdate = data;
                        this.validaUpdate(8);
                    })
                    .catch(function(e) {

                        let result = e.toString().includes("401");
                        if (result) {
                            window.location.reload();
                        } else {
                            alert(e);
                        }
                    });
            }
        },

        actualizaEstatusMasivoFlexTime() {
            if (window.confirm("¿Deseas renovar el estatus de " + this.colaboradoresFlexTime.length + " colaboradores?")) {
                this.loadingTablaFlexTime = true;
                let params = {
                    Opcion: 17,
                    token: localStorage.getItem('token')
                }
                axios
                    .post(`${ this.$store.getters.getHost }/equemasTrabajo/ws-esquemas_de_trabajo.php`, params)
                    .then((response) => {
                        var data = response.data.viernesCorto;
                        this.respuestaUpdate = data;
                        this.validaUpdate(9);
                    })
                    .catch(function(e) {

                        let result = e.toString().includes("401");
                        if (result) {
                            window.location.reload();
                        } else {
                            alert(e);
                        }
                    });
            }
        },

        buscaColaboradorFlexTime() {
            this.loadingColaboradores = true;
            this.dialogAgregaParticipanteFlexTime = true;
            this.getColaboradoresNombre();
        },

        validaInformacionAddColaboradorFlexTime() {
            let result = this.colaboradoresAutocomplate.filter(
                (colaboralor) => colaboralor["NAME"] == this.aQuienFlexTime
            );
            this.loadingGuardarColaborador = true;
            let params = {
                Opcion: 18,
                token: localStorage.getItem('token'),
                folio: result[0]["EMPLID"]
            }
            axios
                .post(`${ this.$store.getters.getHost }/equemasTrabajo/ws-esquemas_de_trabajo.php `, params)
                .then((response) => {
                    var data = response.data.viernesCorto;
                    this.respuestaAddColaborador = data;

                    this.validaRespuestaAddColaboradorFlexTime();

                })
                .catch(function(e) {

                    let result = e.toString().includes("401");
                    if (result) {
                        window.location.reload();
                    } else {
                        alert(e);
                    }
                });
        },

        validaRespuestaAddColaboradorFlexTime() {
            this.loadingGuardarColaborador = false;
            if (this.respuestaAddColaborador["response"] == 1) {
                this.textAlert = "¡ERROR!. El o la colaboradora ya cuenta con un permiso aún vigente.";
                this.colorAlert = "pink";
                this.alert = true;
            } else if (this.respuestaAddColaborador["response"] == 2) {
                this.loadingTablaFlexTime = true;
                this.colaboradores.splice(0, this.colaboradores.length);
                this.dialogAgregaParticipanteFlexTime = false;
                this.getColaboradoresFlexTime();
            } else {
                this.textAlert = "¡ERROR!. Falló el proceso, no se guardó tu nuevo registro.";
                this.colorAlert = "pink";
                this.alert = true;
            }
        },


    },
}