<template>
  <v-card class="rounded-lg pa-2" elevation="3">
    <div class="mb-2">
      <v-progress-linear
        v-model="Avance"
        class="rounded-pill"
        :color="getColor()"
        height="25"
      >
        <template v-slot:default="{ value }">
          <strong style="color:white">{{ value.toFixed(3) }}%</strong>
        </template>
      </v-progress-linear>
    </div>
    <div>
      <p class="text-center font-weight-bold my-1" :color="getColor()" style="font-size:1.05rem;">
        {{ yaCargaronClientes }} {{ $t("DH360.of") }} {{ participantes.length }}
      </p>
    </div>
    <v-data-table
      :headers="headers"
      :items="participantes"
      :items-per-page="itemsPerPage"
      :footer-props="{
        'items-per-page-options': [5, 10, 50, 100],
        'items-per-page-text': 'Elementos por página',
      }"
      dense
      no-data-text="No hay registros"
      :header-props="headerProps"
      :loading="loadingTabla"
      :search="search"
      item-key="ID_EMPLEADO"
      class="elevation-2"
    >
      <template v-slot:top>
        <v-toolbar flat color="primary" class="rounded-lg rounded-b-0">
          <v-text-field
            v-model="search"
            :label="$t('DH360.findTextBox')"
            class="mt-7"
            dense
            clearable
            flat
            solo-inverted
            dark
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-divider
            class="mx-6"
            inset
            vertical
            style="border-color:lightslategrey"
          ></v-divider>
          <vue-json-to-csv
            :json-data="participantes"
            :labels="headers_report_to_export"
            :csv-title="nameFile"
          >
            <v-btn
              color="green darken-1"
              dark
              class="mb-1 font-weight-bold rounded-lg"
              :loading="loadingTabla"
            >
              <v-icon left>mdi-microsoft-excel</v-icon>
              {{ $t("DH360.export") }}
            </v-btn>
          </vue-json-to-csv>
        </v-toolbar>
      </template>
      <template v-slot:item.ESTATUS="{ item }">
        <p
          class="my-2 font-weight-bold red--text text--darken-3"
          v-if="item.ESTATUS == 0"
        >
          {{ $t("DH360.unregistered") }}
        </p>
        <p class="my-2 font-weight-bold green--text text--darken-3" v-else>
          {{ $t("DH360.registered") }}
        </p>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon small @click="openSendMail(item.Correo)" v-bind="attrs" v-on="on">
              mdi-email-arrow-right-outline
            </v-icon>
          </template>
          <span>{{ $t("DH360.notify") }}</span>
        </v-tooltip>
      </template>
    </v-data-table>

    <PersonaDialogSendMail
      v-if="sendMail"
      :showSendMail="sendMail"
      :defaultMsg="textMsgMail"
      :receptor="mailSelected"
      @hideSendMail="closeSendMail"
    />
  </v-card>
</template>

<script>
import axios from "axios";
import { deleteToken } from "../../../../../../store/Funciones/funciones";
import VueJsonToCsv from "vue-json-to-csv";
import PersonaDialogSendMail from "../../../../../../components/PersonaDialogSendMail.vue";

export default {
  name: "reporte-clientes-cargados-360",
  components: { VueJsonToCsv, PersonaDialogSendMail },
  props: ["idProyecto", "compania"],
  data() {
    return {
      participantes: [],
      headers: [
        { value: "ID_EMPLEADO", text: "EMPLID" },
        { value: "NAME", text: "" },
        { value: "ESTATUS", align: "center", text: "" },
        { value: "actions", align: "center", text: "" },
      ],
      headers_report_to_export: {
        ID_EMPLEADO: {
          title: "EMPLID",
        },
        NAME: {
          title: "Nombre",
        },
        ESTATUS: {
          title: "Puesto",
        },
      },
      headerProps: {
        sortByText: "Ordenar por",
      },
      itemsPerPage: 10,
      Avance: 0,
      yaCargaronClientes: 0,
      noCargaronClientes: 0,
      loadingTabla: true,
      sendMail: false,
      textMsgMail: "",
      mailSelected: "",
      search: "",
      nameFile: "",
    };
  },
  computed: {},
  mounted() {
    this.headers[1].text = this.$t("DH360.nameTextHeader");
    this.headers[2].text = this.$t("DH360.statusTextHeader");
    this.headers[3].text = this.$t("DH360.actionsTextHeader");
    this.textMsgMail = this.$t('DH360.txtMsgMailToChargeClients');
    let ahora = new Date();
    this.nameFile =
      "clientesCargados" +
      ahora.getDate().toString() +
      ahora.getMonth().toString() +
      ahora.getUTCFullYear().toString() +
      ahora.getUTCHours().toString() +
      ahora.getUTCMinutes().toString() +
      ahora.getUTCMilliseconds().toString();
    this.getParticipantes();
  },
  methods: {
    getParticipantes() {
      let params = {
        Opcion: 19,
        token: localStorage.getItem("token"),
        idProyecto: this.idProyecto,
        compania:this.compania
      };
      axios
        .post(
          `${this.$store.getters.getHost}/RelacionesLaborales/AdmonEvaluacionesDesempenio/ws-admon-360.php`,
          params
        )
        .then((response) => {
          if (response.data.status == "EXPRESSION") {
            deleteToken();
          } else if (response.data.status == "OK") {
            this.participantes = response.data.respuesta;
            let result = this.participantes.filter(function condition(element) {
              if (element.ESTATUS > 0) return true;
              return false;
            });
            this.yaCargaronClientes = result.length;
            this.Avance = (result.length / this.participantes.length) * 100;
            this.loadingTabla = false;
          }
        });
    },

    getColor() {
      if (this.Avance == 100) return "green darken-3";
      else if (this.Avance < 100 && this.Avance > 60) return "amber";
      else return "red darken-3";
    },

    openSendMail(mailTo) {
      this.mailSelected = mailTo;
      this.sendMail = true;
    },

    closeSendMail(type) {
      switch (type) {
        case -1: {
          this.sendMail = false;
          break;
        }
        case 0: {
          this.sendMail = false;
          this.colorBarra = "error";
          this.textoBarra = this.$t("DH360.errorMessaggeDB");
          this.muestraBarra = true;
          break;
        }
        case 1: {
          this.sendMail = false;
          this.colorBarra = "success";
          this.textoBarra = "enviado";
          this.muestraBarra = true;
          break;
        }
      }
    },
  },
};
</script>
