<template>
  <v-dialog
    v-model="verDialogCreaPrestamo"
    max-width="700"
    content-class="fix-border-dialog"
    persistent
  >
    <v-card class="rounded-xl">
      <v-toolbar color="primary" dark>
        <label class="overline font-weight-bold">
          Creación de nuevo prestamo
        </label>
        <v-spacer></v-spacer>
        <v-icon dark @click="$emit('cierraDialogCreaPrestamo', false)"
          >mdi-close</v-icon
        >
      </v-toolbar>
      <v-card-text class="pt-5">
        <v-form ref="formNuevoPrestamo">
          <v-row dense>
            <v-col cols="6">
              <label class="overline">monto</label>
              <v-text-field
                v-model="montoPrestamo"
                outlined
                type="number"
                required
                :rules="[floatRule, positiveRule]"
                dense
                class="rounded-lg"
                placeholder="Ingresa el monto"
                prefix="$"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <label class="overline">plazo</label>
              <v-text-field
                v-model="plazoPrestamo"
                outlined
                type="number"
                required
                :rules="[numberRule, rangeRule]"
                dense
                class="rounded-lg"
                placeholder="Ingresa el plazo"
                prefix="#"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <label class="overline">interes</label>
              <v-text-field
                :value="interesCalculado"
                outlined
                disabled
                type="number"
                dense
                class="rounded-lg"
                placeholder="Interes cálculado"
                prefix="#"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <label class="overline">Abono</label>
              <v-text-field
                :value="abonoCalculado"
                outlined
                disabled
                type="number"
                dense
                class="rounded-lg"
                placeholder="Abono cálculado"
                prefix="#"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="pa-0">
        <v-btn
          block
          class="rounded-xl rounded-t-0 font-weight-bold"
          text
          x-large
          color="teal darken-2"
          :loading="loadingBtnSave"
          @click="loadingBtnSave = true; validateForm()"
        >
          <v-icon left>mdi-content-save</v-icon>
          guardar
        </v-btn>
      </v-card-actions>

      <SnackBar v-if="muestraBarra" :muestraBarra="muestraBarra" :texto="textoBarra" :color="colorBarra" @cierraBarra="muestraBarra = false;"/>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios'
import { deleteToken } from '../../../../../../../store/Funciones/funciones';
import SnackBar from '../../../../../General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue'

export default {
  props: ["verDialogCreaPrestamo", "idUser"],
  components: {SnackBar},
  data() {
    return {
      montoPrestamo: null,
      plazoPrestamo: null,
      loadingData: true,
      variables: [],
      numberRule: value => /^\d+$/.test(value) || 'Solo se permiten números enteros',
      rangeRule: value => (value >= 1 && value <= 52) || 'El número debe estar entre 1 y 52',
      floatRule: value => /^-?\d*\.?\d+$/.test(value) || 'Solo se permiten números flotantes',
      positiveRule: value => parseFloat(value) >= 500 || 'El número debe ser mayor a 500',
      tasaDelPeriodo: null,
      loadingBtnSave: false,
      muestraBarra: false,
      textoBarra: "",
      colorBarra: ""
    };
  },
  computed: {
    abonoCalculado() {
      return this.montoPrestamo !== null && this.plazoPrestamo !== null
        ? (this.montoPrestamo / this.plazoPrestamo).toFixed(2)
        : null;
    },
    interesCalculado() {
      return this.tasaDelPeriodo !== null && this.plazoPrestamo !== null
        ? ((this.tasaDelPeriodo / this.plazoPrestamo)).toFixed(2)
        : null;
    },
  },
  async mounted(){
    this.variables = await this.getVariables();
    this.tasaDelPeriodo = this.getTasa() + this.getTiie();
  },
  methods: {
    showSnackBar(texto, color = "error"){
      this.textoBarra = texto;
      this.colorBarra = color;
      this.muestraBarra = true;
    },
    getVariables() {
      const headers = {
        Authorization: localStorage.getItem("token"),
      };

      return new Promise((res) => {
        axios
          .get(`${this.$store.getters.getHostNode}/api/get-variables-caja`, {
            headers,
          })
          .then((response) => {
            if (response.data.status == "EXPSESSION") {
              deleteToken();
            } else {
              res(response.data.data);
            }
          })
          .finally(() => {
            this.loadingData = false;
          });
      });
    },
    getTasa(){
        const index = this.variables.findIndex(item => item.ID === 1);
        return this.variables[index].VALUE;
    },
    getTiie(){
        const index = this.variables.findIndex(item => item.ID === 2);
        return this.variables[index].VALUE;
    },
    async validateForm(){
      if(this.$refs.formNuevoPrestamo.validate()){
        const response = await this.setPrestamoNuevo()
        this.loadingBtnSave = false;
        if (response > 0) {
          this.showSnackBar(
            "Éxito. Se modificó el concepto seleccionado.",
            "success"
          );
          this.$emit('cierraDialogCreaPrestamo', true)
        } else {
          this.showSnackBar(
            "Error. No se pudo crear el nuevo préstamo, intente más tarde."
          );
        }
      } else {
        this.loadingBtnSave = false;
        this.showSnackBar("Error. Hay campos que no se ingresaron correctamente o no se ingresaron.")
      }
    },
    setPrestamoNuevo(){
      const params = {
        emplid: this.idUser,
        monto: this.montoPrestamo,
        interes: this.interesCalculado,
        plazo: this.plazoPrestamo,
        usrRegistra: this.$store.getters.getUser.Folio,
        abono: this.abonoCalculado
      };

      const headers = {
        Authorization: localStorage.getItem("token"),
      };

      return new Promise((res) => {
        axios
          .post(
            `${this.$store.getters.getHostNode}/api/empleados/caja/prestamos/empleados`,
            params,
            {
              headers,
            }
          )
          .then(async (response) => {
            if (response.data.status == "EXPSESSION") {
              deleteToken();
            } else if (response.data.status == "OK") {
              res(response.data.data);
            } else {
              res(response.data.data);
            }
          })
          .finally(() => {
            this.loadingBtnSave = false;
          })
      });
    }
  },
};
</script>
