<template>
  <v-container>
    <v-row>
      <v-col cols="12" xl="10" lg="10" md="10" sm="12" xs="12">
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          class="mb-5"
          label="Busca por nombre, número de empleado o actualización de datos"
          single-line
          hide-details
        ></v-text-field>
      </v-col>
      <v-col
        cols="12"
        xl="2"
        lg="2"
        md="2"
        sm="12"
        xs="12"
        class="d-flex justify-center align-center"
      >
        <v-menu bottom offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="green darken-2"
              dark
              small
              class="mb-1 font-weight-bold rounded-lg"
              v-bind="attrs"
              v-on="on"
              :loading="loadingExcel"
            >
              <v-icon left small>mdi-microsoft-excel</v-icon>
              {{ $t("DH360.export") }}
            </v-btn>
          </template>
          <v-list>
            <vue-json-to-csv
              v-if="infoReporte"
              :json-data="infoReporte"
              :labels="headers_report_to_export"
              :csv-title="nameFile"
            >
              <v-list-item @click="fixName">
                <v-list-item-title>Información colaborador</v-list-item-title>
              </v-list-item>
            </vue-json-to-csv>
            <vue-json-to-csv
              v-if="infoReporteFamilias"
              :json-data="infoReporteFamilias"
              :labels="headers_report_to_export_Families"
              :csv-title="nameFile2"
            >
              <v-list-item @click="fixName2">
                <v-list-item-title>Familias de colaboradores</v-list-item-title>
              </v-list-item>
            </vue-json-to-csv>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>
    <v-spacer></v-spacer>
    <v-data-table
      dense
      :loading="loadingContent"
      :headers="headers"
      :items="colaboradores.datos"
      sort-by-text="Ordenar por"
      no-data-text="No hay registros."
      items-per-page-all-text="Todos"
      items-per-page-text="Elementos por página"
      :show-current-page="false"
      :show-first-last-page="false"
      :search="search"
      loading-text="Cargando contenido..."
      :footer-props="{
        'items-per-page-options': [5, 10, 15, 20, 25],
        'items-per-page-text': 'Elementos por página',
      }"
    >
      <template v-slot:[`item.datosActualizados`]="{ item }">
        <div style="display: grid; place-content: center">
          <v-chip-group column>
            <v-btn
              v-if="item.datosActualizados == 'Pendiente'"
              class="mb-2"
              x-small
              rounded
              elevation="1"
              color="red darken-1"
              dark
            >
              {{ item.datosActualizados }}
            </v-btn>
            <v-btn
              v-else-if="item.datosActualizados == 'Actualizado'"
              class="mb-2"
              x-small
              rounded
              elevation="1"
              color="teal darken-1"
              dark
            >
              {{ item.datosActualizados }}
            </v-btn>
            <v-btn
              v-else
              class="mb-2"
              x-small
              rounded
              elevation="1"
              color="yellow darken-1"
            >
              {{ item.datosActualizados }}
            </v-btn>
          </v-chip-group>
        </div>
      </template>
      <template v-slot:[`item.totalDocs`]="{ item }">
        <div v-if="item.numDocs">{{ item.totalDocs }} / {{ item.numDocs }}</div>
        <div v-else>{{ item.totalDocs }} / {{ colaboradores.maxDocs }}</div>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip top color="cyan lighten-4">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              color="cyan darken-4"
              v-bind="attrs"
              v-on="on"
              @click="openAdmFiles(item)"
            >
              mdi-text-box-multiple-outline
            </v-icon>
          </template>
          <label class="text-uppercase font-weight-bold primary--text"
            >Cargar documentos</label
          >
        </v-tooltip>
      </template>
    </v-data-table>
    <CargaArchivos
      v-if="muestraCargaArchivos"
      :dialogCargaArchivos="muestraCargaArchivos"
      :emplid="emplid"
      :estatusColaborador="1"
      esAdmin="1"
      @cierraVentana="reloadInformation()"
    />

    <!-- 
    <ModificaTiposDocumentos
      v-if="openEdit"
      :openNuevoTipoDocumento="openEdit"
      :opcion="opcionEdit"
      :item="itemEdit"
      @cierraVentana="cierraEdit"
    />

    <SnackBarError
      :muestraBarra="muestraMensaje"
      :texto="textoMensaje"
      :color="colorMensaje"
      @cierraBarra="muestraMensaje = false"
    /> -->
  </v-container>
</template>

<script>
import axios from "axios";
import { deleteToken } from "../../../../../store/Funciones/funciones";
import CargaArchivos from "../../../General/MiEmpresa/MiDocumentacion/components/MuestraArchivosPorCargar.vue";
import VueJsonToCsv from "vue-json-to-csv";
//   import ModificaTiposDocumentos from "./ModificaTiposDocumentos.vue";
//   import SnackBarError from "../../../General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue";
export default {
  name: "adm-exp-colaboradores",
  components: { CargaArchivos, VueJsonToCsv },
  props: [],
  data() {
    return {
      colaboradores: [],
      search: "",
      loadingContent: true,
      loadingExcel: true,
      muestraCargaArchivos: false,
      emplid: 0,
      headers: [
        {
          text: "Emplid",
          align: "center",
          sorteable: false,
          value: "EMPLID",
        },
        {
          text: "Nombre",
          align: "center",
          sorteable: false,
          value: "NAME",
        },
        {
          text: "Actualización de datos",
          align: "center",
          sorteable: false,
          value: "datosActualizados",
        },
        {
          text: "Proceso",
          align: "center",
          sorteable: false,
          value: "proceso",
        },
        {
          text: "Avance",
          align: "center",
          sorteable: false,
          value: "totalDocs",
        },
        {
          text: "Acciones",
          align: "center",
          sorteable: false,
          value: "actions",
        },
      ],

      nameFile: "",
      nameFile2: "",
      headers_report_to_export: {
        Emplid: { title: "EMPLID" },
        nombre: { title: "Nombre" },
        Calle: { title: "Calle" },
        CalleNumero: { title: "Numero" },
        NumeroInt: { title: "Numero interior" },
        Colonia: { title: "Colonia" },
        CP: { title: "Codigo Postal" },
        EntreCalles: { title: "Entre calles" },
        Mun: { title: "Municipio" },
        Edo: { title: "Estado" },
        TiempoDomicilio: { title: "Tiempo en domicilio" },
        Cel: { title: "Celular" },
        Tel: { title: "Telefono" },
        Mail: { title: "Mail" },
        Nacionalidad: { title: "Nacionalidad" },
        FechaNacimiento: { title: "Fecha Nacimiento" },
        LugarNacimiento: { title: "Lugar Nacimiento" },
        estCivil: { title: "Entre calles" },
        NSS: { title: "NSS" },
        Curp: { title: "CURP" },
        RFC: { title: "RFC" },
        licManejoLbl: { title: "Licencia de manejo" },
        INE: { title: "INE" },
        vigenciaINE: { title: "Vigencia INE" },
        escolaridad: { title: "Escolaridad" },
        NombrePlantel: { title: "Nombre Plantel" },
        Habla: { title: "% Habla" },
        Lee: { title: "% Lee" },
        Escribe: { title: "% Escribe" },
        NombreCurso: { title: "Nombre de curso" },
        estatura: { title: "Estatura" },
        peso: { title: "Peso" },
        numeroEmergencia: { title: "Numero de emergencia" },
        nombreNumeroEmergencia: {
          title: "Nombre / Parentezco num. emergencia",
        },
        tHijos: { title: "Tiene hijos" },
        numDocumentosCargados: { title: "Documentos cargados" },
        numDocsTotal: { title: "Total de documentos" },
      },

      headers_report_to_export_Families: {
        emplid: { title: "EMPLID" },
        nombre: { title: "Nombre Colaborador" },
        nombreFamiliar: { title: "Nombre Familiar" },
        parentesco: { title: "Parentesco" },
        ocupacion: { title: "Ocupación" },
        lugarLaborando: { title: "Lugar donde labora" },
      },
      infoReporte: [],
      infoReporteFamilias: [],
    };
  },
  computed: {},
  mounted() {
    this.getTipos();
    this.getInfoReporte();
  },
  methods: {
    getTipos() {
      let params = {
        Opcion: 8,
        token: localStorage.getItem("token"),
      };
      axios
        .post(
          `${this.$store.getters.getHost}/RelacionesLaborales/DocumentacionEmpleados/ws-carga-documentos.php`,
          params
        )
        .then((response) => {
          if (response.data.status == "EXPRESSION") {
            deleteToken();
          } else if (response.data.status == "OK") {
            this.colaboradores = response.data.reporte;
            this.loadingContent = false;
          }
        });
    },

    buscar() {
      console.log("hola0");
      this.colaboradores = [];
      this.loadingContent = true;
      this.getTipos();
    },

    getInfoReporte() {
      let params = {
        Opcion: 23,
        token: localStorage.getItem("token"),
      };
      axios
        .post(
          `${this.$store.getters.getHost}/RelacionesLaborales/DocumentacionEmpleados/ws-carga-documentos.php`,
          params
        )
        .then((response) => {
          if (response.data.status == "EXPRESSION") {
            deleteToken();
          } else if (response.data.status == "OK") {
            this.infoReporte = response.data.data;
            this.infoReporteFamilias = response.data.infoReporteFamilias;
            this.loadingExcel = false;
          }
          console.log(this.infoReporteFamilias.length);
        });
    },

    openAdmFiles(item) {
      console.log(item.EMPLID);
      this.emplid = item.EMPLID;
      this.muestraCargaArchivos = true;
    },
    reloadInformation() {
      this.muestraCargaArchivos = false;
      this.colaboradores.length = 0;
      this.loadingContent = true;
      this.getTipos();
    },
    fixName() {
      let numero_aleatorio =
        Math.floor(Math.random() * (99999 - 10000 + 1)) + 10000;
      this.nameFile = "ExpDeColaboradores" + numero_aleatorio.toString();
    },

    fixName2() {
      let numero_aleatorio =
        Math.floor(Math.random() * (99999 - 10000 + 1)) + 10000;
      this.nameFile2 =
        "ReporteFamiliasColaborador" + numero_aleatorio.toString();
    },
  },
};
</script>
