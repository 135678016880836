<template>
    <v-dialog v-model="dialog" max-width="500" scrollable>
        <v-card class="rounded-lg">
            <v-card-title class="primary white--text subtitle-1">
                Selecciona un periodo
                <v-spacer></v-spacer>
                <v-icon dark @click="$emit('cierraFiltro', {fechaInicial: fecha1,fechaFinal: fecha2});"> mdi-close </v-icon>
            </v-card-title>
            <v-card-text class="pt-4">
                <v-row>
                     <v-col cols="12" class="pa-0" style="display: flex; justify-content:center;">
                        <v-text-field
                            v-model="lblDate"
                            placeholder="Selecciona el periodo a buscar."
                            style="max-width: 450px; "
                            class="rounded-lg mt-5"
                            label="Selecciona el periodo a buscar."
                            prepend-inner-icon="mdi-calendar"
                            readonly
                            dense
                            filled
                            @click="dialogFechas = true"
                        ></v-text-field>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <v-dialog v-model="dialogFechas" max-width="350px">
            <v-card class="rounded-lg">
                <v-card-title class="primary white--text subtitle-2 py-1">
                    <v-row dense>
                        <v-col dense cols="6">
                            <p class="mb-0 font-weight-bold blue--text text--lighten-4">Inicio</p>
                            <p v-if="dates.length >= 1" class="mb-0" style="font-size:1.05rem">{{ fixFormatDate(dates[0]) }}</p>
                        </v-col>
                        <v-divider></v-divider>
                        <v-col dense cols="6">
                            <p class="mb-0 font-weight-bold blue--text text--lighten-4">Fin</p>
                            <p v-if="dates.length == 2" class="mb-0" style="font-size:1.05rem">{{ fixFormatDate(dates[1]) }}</p>
                        </v-col>
                    </v-row>
                </v-card-title>
                <div class="text-center">
                    <v-date-picker v-model="dates" max-width="350px" v-on:change="cierraDialog()" no-title range elevation="0" locale="es-mx">
                        <template #title>
                            <div class="v-picker__title">
                                <div class="v-date-picker-header__value">Hola</div>
                            </div>
                        </template>
                    </v-date-picker>
                </div>
            </v-card>
        </v-dialog>
    </v-dialog>
</template>

<script>
import { formatDate } from "../../../../../../store/Funciones/funciones";
export default {
    name: "filtro-reporte",
    components: { },
    props:["dialog", "fecha1", "fecha2"],
    data() {
        return {
            dialogFechas: false,
            dates: [],
            lblDate:'',
        };
    },
    computed:{ 
        
    },
    mounted() { 
        this.dates[0] = this.fecha1;
        this.dates[1] = this.fecha2;
        this.dateRangeText();
    },
    methods: { 
        cierraDialog() {
            if (this.dates.length == 2) {
                if (this.dates[0] > this.dates[1]) {
                    this.dates.pop();
                    this.colorBarra = "error";
                    this.textoBarra =
                        "La fecha final no puede ser menor a la inicial. :c";
                    this.muestraBarra = true;
                } else {
                    this.dialogFechas = false;
                    // this.$emit(this.dates[0], this.dates[1],'cierraFiltro');
                    this.$emit('cierraFiltro', {
                        fechaInicial: this.dates[0],
                        fechaFinal: this.dates[1]
                    });
                }
                this.dateRangeText();
            }
        },
        
        fixFormatDate(date){
            return formatDate(date)
        },

        dateRangeText() {
            let inicio = "";
            let fin = "";
            if (this.dates[0] != null) {
                inicio = formatDate(this.dates[0]);
            }
            if (this.dates[1] != null) {
                fin = formatDate(this.dates[1]);
            }

            if (this.dates.length == 0) {
                this.lblDate = "";
            }
            this.lblDate = "Del " + inicio + " al " + fin; //this.dates.join(' al ')
        },
    }
}
</script>
