<template>
  <div>
    <v-dialog
      v-model="showSendInvitation"
      max-width="550"
      persistent
      content-class="fix-border-dialog"
    >
      <v-card class="rounded-xl">
        <v-card-title class="primary">
          <div class="subtitle-1 font-weight-bold text-uppercase white--text">
            Selecciona al candidato.
          </div>
          <v-spacer></v-spacer>
          <v-icon dark @click="$emit('closeForm')">mdi-close</v-icon>
        </v-card-title>
        <v-card-text>
          <v-form ref="formSendMailCandidate">
            <v-radio-group v-model="tipoCandidato" row>
              <v-radio label="Externo" :value="1"></v-radio>
              <v-radio label="Interno" :value="2"></v-radio>
            </v-radio-group>
            <div>
              <label class="overline"
                >Colaborador(a) {{ colaboradorSeleccionado }}</label
              >
              <v-autocomplete
                v-model="colaboradorSeleccionado"
                :items="colaboradores"
                item-text="nombreMostrar"
                @change="findMail"
                :loading="loadingMembers"
                :disabled="tipoCandidato === 1 ? true : false"
                class="rounded-lg"
                dense
                outlined
                placeholder="Buscar por nombre o emplid..."
              ></v-autocomplete>
              <label class="overline">Correo</label>
              <v-text-field
                v-model="correoCandidato"
                class="rounded-lg"
                dense
                :rules="emailRules"
                outlined
                placeholder="Ingresa el correo electrónico..."
              ></v-text-field>
            </div>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn
            depressed
            class="rounded-pill"
            color="teal darken-1"
            dark
            block
            :loading="loadingSend"
            @click="
              loadingSend = true;
              sendMail();
            "
          >
            <v-icon left>mdi-receipt-text-send-outline</v-icon>
            Enviar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <SnackBarErrors
      v-if="muestraBarra"
      :muestraBarra="muestraBarra"
      :color="colorBarra"
      :texto="textoBarra"
      @cierraBarra="muestraBarra = false"
    />
  </div>
</template>

<script>
import { deleteToken } from "../../../../store/Funciones/funciones";
import axios from "axios";
import SnackBarErrors from "../../General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue";

export default {
  name: "envia-formulario-candidato-confidencial",
  props: ["showSendInvitation", "idVacante"],
  components: { SnackBarErrors },
  data() {
    return {
      tipoCandidato: 1,
      colaboradores: [],
      loadingMembers: true,
      loadingSend: false,
      muestraBarra: false,
      textoBarra: "",
      colorBarra: "",
      colaboradorSeleccionado: null,
      correoCandidato: null,
      emailRules: [
        (v) => !!v || "Este campo es obligatorio.",
        (v) =>
          /.+@.+\..+/.test(v) ||
          "La dirección de corro electrónico debe ser valida.",
      ],
    };
  },
  async mounted() {
    this.colaboradores = await this.getColaboradores().catch((e) => {
      alert(e);
    });
  },
  methods: {
    async getColaboradores() {
      if (this.colaboradores.length == 0) {
        let params = {
          Opcion: 11,
          token: localStorage.getItem("token"),
        };

        return new Promise((resolve, reject) => {
          axios
            .post(
              `${this.$store.getters.getHost}/General/ws-general.php`,
              params
            )
            .then((response) => {
              if (response.data.status == "EXPRESSION") {
                deleteToken();
              } else if (response.data.status == "OK") {
                resolve(response.data.colaboradores);
              }
            })
            .catch((e) => {
              reject(e);
            })
            .finally(() => {
              this.loadingMembers = false;
            });
        });
      }
    },
    findMail() {
      const selectedColaborador = this.colaboradores.find(
        (colaborador) =>
          colaborador.nombreMostrar === this.colaboradorSeleccionado
      );
      this.correoCandidato = selectedColaborador.correo;
    },
    muestraSnackBar(color, texto) {
      this.colorBarra = color;
      this.textoBarra = texto;
      this.muestraBarra = true;
    },
    sendMail() {
      if (this.$refs.formSendMailCandidate.validate()) {
        let empleado = null
        if(this.tipoCandidato == 2){
          let findEmployee = this.colaboradores.find(
            (colaborador) =>
              colaborador.nombreMostrar === this.colaboradorSeleccionado
          );
          console.log(findEmployee)
          empleado = findEmployee.EMPLID;
        }
        const params = {
          Opcion: 44,
          token: localStorage.getItem("token"),
          correo: this.correoCandidato,
          tipo: this.tipoCandidato,
          idVacante: this.idVacante,
          emplid: empleado
        };

        axios
          .post(
            `${this.$store.getters.getHost}/Reclutamiento/ws-admin-reclu.php`,
            params
          )
          .then((response) => {
            if (response.data.status == "EXPRESSION") {
              deleteToken();
            } else {
              if (response.data.status == "OK") {
                if (response.data.respuesta.status_tran == "OK") {
                  this.muestraSnackBar(
                    "teal darken-1",
                    response.data.respuesta.message
                  );
                  this.$refs.formSendMailCandidate.reset();
                  this.tipoCandidato = 1;
                } else {
                  this.muestraSnackBar(
                    "pink darken-1",
                    response.data.respuesta.message
                  );
                }
              } else {
                this.muestraSnackBar("pink darken-1", "No se envió el correo.");
              }
            }
          })
          .catch((e) => {
            this.muestraSnackBar("teal darken-1", e);
          })
          .finally(() => {
            this.loadingSend = false;
          });
      }
    },
  },
};
</script>
