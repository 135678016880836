import axios from 'axios'
import VueJsonToCsv from 'vue-json-to-csv'
import { deleteToken } from '../../../../../../store/Funciones/funciones'
export default {
  name: 'kardex-colaborador',
  components: { VueJsonToCsv },
  props: [],
  data() {
    return {
      search: '',
      nameFile: '',
      headers: [
        { text: 'Folio', align: 'start', value: 'username', },
        { text: 'Nombre', value: 'nombre' },
        { text: 'Categoria', value: 'categoria' },
        { text: 'Medio Curso', value: 'curso' },
        { text: 'Examen', value: 'examen' },
        { text: 'Fecha', value: 'fecha' },
        { text: 'Calificación', value: 'calificacion', align: 'center' },
      ],
      headers_report_to_export: {
        username: { title: "Folio" },
        nombre: { title: "Nombre" },
        categoria: { title: "Categoria" },
        curso: { title: "Medio Curso" },
        examen: { title: "Examen" },
        fecha: { title: "Fecha" },
        calificacion: { title: "Calificación" },
      },
      cargando: true,
      calificacionesColaborador: [],
      compania: '',
      bloqueo: false,
    }
  },
  computed: {

  },
  mounted() {
    this.getCompania();
  },
  methods: {
    getCompania() {
      let paramsEstatusInc = {
        Opcion: 28,
        token: localStorage.getItem('token')
      };
      axios.post(`${this.$store.getters.getHost}/General/ws-general.php`, paramsEstatusInc)
        .then((response) => {
          if (response.data.status == "EXPSESSION") {
            deleteToken()
          } else {
            this.compania = response.data.compania;
            if (this.compania == '00100') {
              this.consultarCandidatos();
            } else {
              this.bloqueo = true;
            }
          }
        })
        .catch(function (e) {
          let result = e.toString().includes("401");
          if (result) {
            window.location.reload();
          } else {
            alert(e);
          }
        });

    },

    fixName() {
      let numero_aleatorio =
        Math.floor(Math.random() * (99999 - 10000 + 1)) + 10000;
      this.nameFile = "KardexColaborador_" + numero_aleatorio.toString();
    },
    consultarCandidatos() {
      const cadena = this.$route.path;
      const ruta = cadena.split("/");
      let params = {
        Opcion: 1,
        opc: 1,
        ruta: ruta[1],
        token: localStorage.getItem("token"),
      };

      axios.post(`${this.$store.getters.getHost}/UniversidadUrrea/ws-KardexColaborador.php`, params).then((response) => {
        if (response.data.status == 'OK') {
          this.calificacionesColaborador = response.data.data;
        }
        this.cargando = false;
      })
    },
  }
}