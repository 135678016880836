<template>
  <v-dialog
    v-model="dialog"
    max-width="60%"
    transition="slide-x-transition"
    persistent
  >
    <v-card>
      <v-card-title class="text-subtitle-2 primary white--text">
        Subir archivo
        <v-spacer></v-spacer>
        <v-btn color="pink darken-2" rounded small dark>
          <v-icon small dark @click="$emit('cierraDialog', 'Cancelar')">
            mdi-close
          </v-icon>
          <!-- Cerrar -->
        </v-btn>
      </v-card-title>
      <v-card-text class="pt-5">
        <v-row no-gutters>
          <v-col cols="12" style="display: flex; justify-content: center">
            <v-file-input
              v-show="!loadingButtonUploadFile"
              accept=".xlsx, .xls, .xlsm, .xlsb, .csv"
              placeholder="Carga de mapa de talento"
              label="Selecciona archivo"
              prepend-icon="mdi-microsoft-excel"
              v-on:change="handleFileUpload"
              v-model="file"
            ></v-file-input>
            <span v-show="loadingButtonUploadFile"
              >Procesando e insertando información...</span
            >
            <v-progress-linear
              v-show="loadingButtonUploadFile"
              indeterminate
              color="blue darken-4"
              class="mt-2"
            ></v-progress-linear>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn
          v-if="file"
          small
          block
          style="background-color: #16a5b8; color: white"
          elevation="1"
          :loading="loadingButtonUploadFile"
          @click="subirArchivo()"
        >
          subir archivo
          <v-icon right dark> mdi-cloud-upload </v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>

    <SnackBarErrorIncidencias
      v-if="muestraBarra"
      :muestraBarra="muestraBarra"
      :texto="textoBarra"
      :color="colorBarra"
      @cierraBarra="muestraBarra = false"
    />
  </v-dialog>
</template>

<script>
import axios from "axios";
// import { deleteToken } from "../../../../../store/Funciones/funciones";
import SnackBarErrorIncidencias from "../../../General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue";
export default {
  name: "cargar-mapa-de-talento",
  props: ["dialog"],
  components: {
    SnackBarErrorIncidencias,
  },
  data() {
    return {
      textoBarra: "",
      colorBarra: "",
      muestraBarra: false,

      archivoCargadoConExito: false,
      loadingButtonUploadFile: false,
      focusFile: false,
      file: null,
    };
  },
  created() {},
  mounted() {
    // this.getInfoMapaDeTalento();
  },
  methods: {
    handleFileUpload() {
      console.log(this.file);
    },
    subirArchivo() {
      this.loadingButtonUploadFile = true;
      this.cargarArchivo();
    },

    async cargarArchivo() {
      if (!this.file) return;
      const formData = new FormData();
      formData.append("file", this.file);

      try {
        const response = await axios.put(
          `${this.$store.getters.getHostNode}/api/set-mapa-de-talento`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              authorization: `${localStorage.getItem("token")}`,
            },
          }
        );

        if (response.data.status === "OK") {
          this.textoBarra = response.data.message;
          this.colorBarra = "success";
          this.muestraBarra = true;
          return new Promise((resolve) => {
            setTimeout(() => {
              resolve(this.$emit("cierraDialog", "OK"));
            }, 1500);
          });
        } else {
          this.textoBarra = "Error al procesar el archivo"; //response.data.message;
          this.colorBarra = "error";
          this.muestraBarra = true;
        }
      } catch (error) {
        this.textoBarra = "error en la petición";
        this.colorBarra = "error";
        this.muestraBarra = true;
      } finally {
        this.loadingButtonUploadFile = false;
        this.file = null; // Clear the file input
      }
    },
  },
};
</script>