var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"politicas-uhp"},[_c('v-container',[_c('v-card',{staticClass:"rounded-lg"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.reglasPortal,"single-expand":_vm.singleExpand,"expanded":_vm.expanded,"loading":_vm.loading,"search":_vm.buscar,"loading-text":"Cargando... Por favor espere","footer-props":{  'items-per-page-text': 'Elementos por página',},"item-key":"id","show-expand":"","dense":"","mobile-breakpoint":"0","no-data-text":"No ha registrado una solicitud"},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"color":"primary","flat":""}},[_c('v-text-field',{staticClass:"mt-7 rounded-lg",attrs:{"label":_vm.$t('DH360.findTextBox'),"dense":"","clearable":"","flat":"","solo-inverted":"","dark":"","prepend-inner-icon":"mdi-magnify"},model:{value:(_vm.buscar),callback:function ($$v) {_vm.buscar=$$v},expression:"buscar"}}),(_vm.esAdmin > 0)?_c('v-select',{staticClass:"rounded-lg mx-1 mt-7 ",staticStyle:{"max-width":"220px !important"},attrs:{"items":_vm.companias,"item-text":"Compania","item-value":"Compania","clearable":"","placeholder":"Selecciona compañia","flat":"","solo-inverted":"","dark":"","dense":""},model:{value:(_vm.authFilterValue),callback:function ($$v) {_vm.authFilterValue=$$v},expression:"authFilterValue"}}):_vm._e(),_c('v-spacer'),(_vm.esAdmin > 0)?_c('v-btn',{attrs:{"small":"","color":"teal darken-4","dark":""},on:{"click":_vm.openFormReglas}},[_vm._v("Nueva Regla")]):_vm._e()],1)]},proxy:true},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-sheet',{staticClass:"mx-auto mb-5",attrs:{"elevation":"8","max-width":"800"}},[_c('div',{staticClass:"pt-5",attrs:{"align":"center"}},[(_vm.esAdmin > 0)?_c('v-btn',{staticClass:"mx-auto",attrs:{"small":"","color":"teal darken-4","dark":""},on:{"click":function($event){return _vm.openFormSubpoliticas(item)}}},[_vm._v("Cargar subpoliticas")]):_vm._e()],1),_c('v-slide-group',{staticClass:"pa-4",attrs:{"center-active":"","show-arrows":""},model:{value:(item.model),callback:function ($$v) {_vm.$set(item, "model", $$v)},expression:"item.model"}},[_vm._l((item.SubPoliticas),function(n){return _c('v-slide-item',{key:n.id,scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('v-card',{staticClass:"ma-4",attrs:{"color":active ? n.color : 'grey darken-2',"height":"200","width":"100"},on:{"click":toggle}},[_c('v-row',{staticClass:"fill-height px-5",attrs:{"align":"center","justify":"center"},on:{"click":function($event){return _vm.saveNombre(n)}}},[_c('v-scale-transition',[(active)?_c('v-icon',{attrs:{"color":"white","size":"48"},domProps:{"textContent":_vm._s('mdi-check-circle-outline')}}):_c('div',{staticClass:"white--text"},[_vm._v(_vm._s(n.id)),_c('br'),_vm._v(_vm._s(n.Regla))]),_vm._v(" "+_vm._s(n.color)+" ")],1)],1)],1)]}}],null,true)})}),(item.SubPoliticas == null)?_c('div',{staticClass:"font-italic text--disabled mx-auto"},[_vm._v(" No se encuentran subpoliticas registradas por el momento ")]):_vm._e()],2),_c('v-expand-transition',[(item.model != null)?_c('v-sheet',{attrs:{"height":"250","tile":""}},[_c('v-row',{staticClass:"fill-height",attrs:{"align":"center","justify":"center"}},[(_vm.tieneDocumento)?_c('v-col',{staticClass:"d-flex child-flex",attrs:{"cols":"12","xl":"4","lg":"4","md":"4","sm":"6","xs":"12"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"rounded-xl",attrs:{"elevation":hover ? 16 : 2},on:{"click":function($event){return _vm.openFile()}}},[_c('div',{staticClass:"text-center my-2 font-weight-bold text-uppercase blue-grey--text text--darken-1"},[_vm._v(" "+_vm._s(_vm.nombrePolitica)+" ")]),_c('div',[_c('v-img',{staticClass:"my-3",attrs:{"src":require('@/assets/icons/Documentacion/pdf-icon.png'),"contain":"","max-height":"70"}})],1),_c('v-card-title',{staticClass:"py-2 center-item-in-div"},[_c('v-icon',{staticClass:"mx-1",attrs:{"color":"cyan"}},[_vm._v("mdi-eye")]),_c('div',{staticClass:"cyan--text overline"},[_vm._v("ver documento")])],1)],1)]}}],null,true)})],1):_vm._e(),(_vm.tieneDocumento && _vm.esAdmin > 0)?_c('v-col',{staticClass:"d-flex child-flex",attrs:{"cols":"12","xl":"6","lg":"6","md":"6","sm":"6","xs":"12"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"rounded-xl",attrs:{"elevation":hover ? 16 : 2}},[_c('div',{staticClass:"text-center my-2 font-weight-bold text-uppercase blue-grey--text text--darken-1"},[_vm._v(" Más información ")]),_c('v-card-text',[_c('div',[_vm._v("Fecha de registro: "+_vm._s(_vm.fechaRegistro))]),_c('div',[_vm._v("Fecha de vencimiento: "+_vm._s(_vm.fechaVencimiento))]),_c('div',[_vm._v("Faltan "+_vm._s(_vm.diasFaltantes)+" dias para vencer esta politica")])]),_c('v-divider'),_c('v-card-title',{staticClass:"py-2 center-item-in-div"},[_c('v-btn',{attrs:{"block":"","color":"white","elevation":"0"},on:{"click":function($event){return _vm.openUploadFile(item)}}},[_c('v-icon',{staticClass:"mx-1",attrs:{"color":"cyan"}},[_vm._v("mdi-upload")]),_c('div',{staticClass:"cyan--text overline"},[_vm._v("Cargar politica")])],1)],1)],1)]}}],null,true)})],1):(!_vm.tieneDocumento && _vm.esAdmin > 0)?_c('v-col',{staticClass:"d-flex child-flex",attrs:{"cols":"12","xl":"4","lg":"4","md":"4","sm":"6","xs":"12"}},[_c('div',{staticClass:"text-center mt-5",attrs:{"transition":"scroll-y-transition"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-btn',{attrs:{"color":hover ? 'blue lighten-1' : 'primary',"elevation":hover ? 16 : 2,"dark":hover ? false : true,"large":"","rounded":""},on:{"click":function($event){return _vm.openUploadFile(item)}}},[_c('v-icon',{attrs:{"left":"","large":""}},[_vm._v("mdi-upload")]),_vm._v(" cargar Politica ")],1)]}}],null,true)})],1)]):(!_vm.tieneDocumento && _vm.esAdmin == 0)?_c('v-col',{staticClass:"d-flex child-flex",attrs:{"cols":"12","xl":"12","lg":"12","md":"12","sm":"12","xs":"12"}},[_c('div',{staticClass:"text-center mt-5",attrs:{"transition":"scroll-y-transition"}},[_c('v-icon',{attrs:{"left":"","large":""}},[_vm._v("mdi-alert")]),_vm._v("No se encuentra cargada esta politica por el momento ")],1)]):_vm._e()],1)],1):_vm._e()],1)],1)],1)]}},{key:"item.Regla",fn:function(ref){
var item = ref.item;
return [_c('div',{attrs:{"align":"left"}},[_vm._v(_vm._s(item.Regla))])]}}])})],1),(_vm.muestraUpload)?_c('CargaPoliticas',{attrs:{"dialogUpload":_vm.muestraUpload,"id_parte1":_vm.id_parte1,"id_parte2":_vm.id_parte2,"doc":_vm.nombreDocumento,"carpeta":_vm.nombreCarpeta},on:{"cierraUpload":function($event){return _vm.reloadData()}}}):_vm._e(),(_vm.dialogFormReglas)?_c('form-politicas-reglas',{attrs:{"dialog":_vm.dialogFormReglas},on:{"cierraFormReglas":_vm.cierraDialogFormReglas}}):_vm._e(),(_vm.dialogFormSubReglas)?_c('form-politicas-sub-reglas',{attrs:{"dialog":_vm.dialogFormSubReglas,"regla":_vm.regla},on:{"cierraFormSubReglas":_vm.cierraDialogFormSubReglas}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }