<template>
    <v-card class="pa-3 ma-3 rounded-xl elevation-0" color="blue-grey lighten-5">
      <v-row
        v-if="loadingData"
        class="fill-height"
        align-content="center"
        justify="center"
      >
        <v-col
          class="text-subtitle-1 text-center"
          cols="12"
        >
          Cargando información...
        </v-col>
        <v-col cols="6">
          <v-progress-linear
            color="blue accent-4"
            indeterminate
            rounded
            height="6"
          ></v-progress-linear>
        </v-col>
      </v-row>
      <div v-else>
        <!-- {{ datosSolicitud }} -->
        <v-simple-table dense color="transparent">
          <thead>
            <tr>
              <th># SOLICITUD</th>
              <th>COLABORADOR</th>
              <th>FECHA</th>
              <th>FIG. MÁX</th>
              <th>FIG. MÍN</th>
              <th>MONTO</th>
              <th>ÁREA</th>
              <th>HORAS</th>
              <th>REALES</th>
              <th>INGRESO</th>
              <th>SALIDA</th>
              <th>ESTATUS</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(c, index) in datosSolicitud" :key="index">
              <td>{{ c.IDSOLICITUD }}</td>
              <td>{{ c.NOMBRE }}</td>
              <td>{{ c.FECHA_SOLICITUD	 }}</td>
              <td>{{ c.FIG_MAX }}</td>
              <td>{{ c.FIG_MIN }}</td>
              <td>{{ c.MONTO }}</td>
              <td>{{ c.AREA_MANTENIMIENTO }}</td>
              <td>{{ c.HORAS }}</td>
              <td>{{ c.HORAS_REALES }}</td>
              <td>{{ c.INGRESO }}</td>
              <td>{{ c.SALIDA }}</td>
              <td>{{ c.ESTADO }}</td>
            </tr>
          </tbody>
        </v-simple-table>
      </div>
    </v-card>
</template>

<script>
import axios from 'axios'

export default {
  props:["idSolicitud"],
  data() {
    return {
      datosSolicitud: null,
      loadingData: true
    };
  },
  async mounted(){
    this.datosSolicitud = await this.getDataSolicitud()
  },
  methods:{
    getDataSolicitud() {
      let params = {
        Opcion: 20,
        idSolicitud: this.idSolicitud,
        pantalla: 2,
        token: localStorage.getItem("token"),
      };
      return new Promise((res) => {
        axios
          .post(
            `${this.$store.getters.getHost}/general/ws-TiempoExtra.php`,
            params
          )
          .then((response) => {
            var data = response.data.response;
            if (data) {
              res(data);
            } else {
              res([]);
            }
          })
          .finally(() => {
            this.loadingData = false;
          });
      });
    },
  }
};
</script>
