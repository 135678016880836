<template>
  <v-dialog
    v-model="abrirModalTicket"
    persistent
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar dark color="primary">
        <v-toolbar-title class="subtitle-1 font-weight-bold"
          >Encuestas</v-toolbar-title
        >
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-icon dark @click="$emit('cerrarTicket','encFin')">
            mdi-close
          </v-icon>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text>
        <div v-if="!encuestaFinalizada">
          <!-- <p class="text-center" style="font-size: 1.1rem;;">
            Tiempo restante: {{ formattedTime }}
          </p> -->
          <v-row
            v-if="loadingData"
            class="fill-height mt-10"
            align-content="center"
            justify="center"
          >
            <v-col class="subtitle-1 text-center" cols="12">
              Cargando información...
            </v-col>
            <v-col cols="6">
              <v-progress-linear
                color="primary"
                indeterminate
                rounded
                height="6"
              ></v-progress-linear>
            </v-col>
          </v-row>
          <MuestraEvaluados
            v-else-if="preguntas.length > preguntaActual"
            :participantesSel="evaluados"
            :categoria="preguntas[preguntaActual].categoria"
            :pregunta="preguntas[preguntaActual].pregunta"
            :preguntaActual="preguntaActual"
            :total="preguntas.length"
            :encuestaDesc="preguntas[0].tipoEncuesta"
            :reglas="maxAsignacionesMedallas"
            :key="key"
            @cambiaPregunta="cambiaPregunta"
          />

          <MuestraAsignacionDeComentarios
            v-else
            :evaluados="evaluados"
            @comentariosValidados="finalizaEncuesta"
          />
        </div>
        <div v-else>
          <div class="text-center mt-10">
            <p class="subtitle-1" style="font-size: 1.8rem !important;">
              ¡Encuesta <strong class="primary--text">finalizada</strong>!
            </p>
          </div>
          <v-row>
            <v-col sm="12" md="4" class="text-center align-self-center">
              <v-img
                :src="require('@/assets/img/n-logo-urrea.png')"
                max-width="70%"
                class="mt-15 mx-auto"
              ></v-img>
            </v-col>
            <v-col sm="12" md="8">
              <p
                v-if="
                  $vuetify.breakpoint.name == 'xl' ||
                    $vuetify.breakpoint.name == 'lg' ||
                    $vuetify.breakpoint.name == 'md'
                "
                class="subtitle-1 align-self-center"
                style="font-size: 4.2rem !important; margin-top: 25%;"
              >
                ¡Muchas <strong class="primary--text">gracias</strong>!
              </p>
              <p v-else class="text-center" style="font-size: 1.5rem">
                ¡Muchas <strong class="primary--text">gracias</strong>!
              </p>
            </v-col>
          </v-row>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
  </template>

  

<script>
import axios from "axios";
import { deleteToken } from "../../../../../../store/Funciones/funciones";
import MuestraEvaluados from "./MuestraEvaluados.vue";
import MuestraAsignacionDeComentarios from "./MuestraAsignacionDeComentarios.vue";
//import SnackBar from "../../../../General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue";
export default {
  name: "asigna-puntos-por-pregunta",
  components: { MuestraEvaluados, MuestraAsignacionDeComentarios },
  data() {
    return {
      idTipoEevaluacion: '1',
      idTipoEncuesta: 10,
      totalTime: 900000, // tiempo total en milisegundos (15 minutos)
      timeRemaining: 900000, // tiempo restante en milisegundos
      intervalId: null, // ID del intervalo para detener el temporizador
      timerRunning: false, // indica si el temporizador está en ejecución
      preguntas: [],
      evaluados: [],
      maxAsignacionesMedallas: [],
      calificacionesFinalizadas: {},
      preguntaActual: 0,
      key: 0,
      loadingData: true,
      encuestaFinalizada: false,

      
      textoBarra: '',
      colorBarra: '',
      muestraBarra: false,
    };
  },
  props: ["abrirModalTicket", "evaluador","tipoEvaluacion","idEncuesta","idEncuestaLanzada","idProyecto","familia","companiaEncuesta"],
  computed: {
    // formattedTime() {
    //   const minutes = Math.floor((this.timeRemaining / 1000 / 60) % 60).toString().padStart(2, "0");
    //   const seconds = Math.floor((this.timeRemaining / 1000) % 60).toString().padStart(2, "0");
    //   // const milliseconds = Math.floor((this.timeRemaining % 1000) / 10).toString().padStart(2, "0");
    //   return `${minutes}m ${seconds}s`;
    // }
  },
  mounted() {
    // this.startTimer()
    this.getPreguntasPorTipoEncuesta();
  },
  methods: {
    getPreguntasPorTipoEncuesta() {
      const params = {
        Opcion: 1,
        token: localStorage.getItem("token"),
        tipoEncuesta: this.idEncuesta,
      };
      axios
        .post(
          `${this.$store.getters.getHost}/RelacionesLaborales/EvaluacionDesempenio/ws-envaluacion-360.php`,
          params
        )
        .then((response) => {
          if (response.data.status == "EXPRESSION") {
            deleteToken();
          } else if (response.data.status == "OK") {
            this.preguntas = response.data.respuesta;
            this.getEvaluadosPorEncuesta();
          } else {
            alert("error");
            deleteToken();
          }
        })
        .catch((error) => {
          alert(error);
        });
    },
    getEvaluadosPorEncuesta() {
      const params = {
        Opcion: 2,
        token: localStorage.getItem("token"),
        idProyecto: this.idProyecto,
        tipoEncuesta: this.tipoEvaluacion,
        familia: this.familia,
        companiaEncuesta:this.companiaEncuesta
      };
      axios
        .post(
          `${this.$store.getters.getHost}/RelacionesLaborales/EvaluacionDesempenio/ws-envaluacion-360.php`,
          params
        )
        .then((response) => {
          if (response.data.status == "EXPRESSION") {
            deleteToken();
          } else if (response.data.status == "OK") {
            this.evaluados = response.data.respuesta;
            if (this.evaluados.length > 0) {
              this.getMaxAsignacionesPorMedalla();
            } else {
              this.encuestaFinalizada = true;
            }
          } else {
            alert("error");
          }
        })
        .catch((error) => {
          alert(error);
        });
    },
    getMaxAsignacionesPorMedalla() {
      const params = {
        Opcion: 3,
        token: localStorage.getItem("token"),
      };
      axios
        .post(
          `${this.$store.getters.getHost}/RelacionesLaborales/EvaluacionDesempenio/ws-envaluacion-360.php`,
          params
        )
        .then((response) => {
          if (response.data.status == "EXPRESSION") {
            deleteToken();
          } else if (response.data.status == "OK") {
            this.maxAsignacionesMedallas = response.data.respuesta;
            this.loadingData = false;
          } else {
            alert("error");
          }
        })
        .catch((error) => {
          alert(error);
        });
    },

    cambiaPregunta(calificaciones) {
      this.preguntas[this.preguntaActual].calificaciones = calificaciones;
      this.preguntaActual++;
      this.key++;
    },

    finalizaEncuesta(comentariosEvaluados) {
      this.calificacionesFinalizadas = {
        Opcion: 4,
        token: localStorage.getItem("token"),
        tipoEncuesta: this.tipoEvaluacion,
        idEncuesta: this.idEncuesta,
        preguntasRespondidas: this.preguntas,
        comentarios: comentariosEvaluados,
        idEncuestaLanzada:this.idEncuestaLanzada,
        idProyecto:this.idProyecto,
      };
      axios
        .post(
          `${this.$store.getters.getHost}/RelacionesLaborales/EvaluacionDesempenio/ws-envaluacion-360.php`,
          this.calificacionesFinalizadas
        )
        .then((response) => {
          if (response.data.status == "EXPRESSION") {
            deleteToken();
          } else if(response.data.status == "OK") {
            this.encuestaFinalizada = true;
          }else if(response.data.status == "Existe"){
            this.textoBarra = 'Ya se registraron los resultados, refresque la pantalla';
            this.colorBarra = 'error';
            this.muestraBarra = true; 
          }
        })
        .catch((error) => {
          alert(error);
        });
      
      //console.dir(JSON.stringify(this.calificacionesFinalizadas));
    },

    // startTimer() {
    //   this.intervalId = setInterval(() => {
    //     this.timeRemaining -= 1000;
    //     if (this.timeRemaining <= 0) {
    //       this.stopTimer();
    //       this.timeRemaining = 0;
    //     }
    //   }, 1000);
    //   this.timerRunning = true;
    // },

    // stopTimer() {
    //   clearInterval(this.intervalId);
    //   this.timerRunning = false;
    // },

    // resetTimer() {
    //   clearInterval(this.intervalId);
    //   this.timeRemaining = this.totalTime;
    //   this.timerRunning = false;
    // }
  },
};
</script>
