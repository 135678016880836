var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pt-5"},[_c('v-card',{staticClass:"pa-5 backgroun-degraded-reclutamientos elevation-0 rounded-xl"},[_c('v-data-table',{staticStyle:{"background-color":"transparent"},attrs:{"headers":_vm.headers,"items":_vm.usuarios,"search":_vm.search,"loading":_vm.loadingTabla,"no-data-text":"No hay registros","loading-text":"Cargando contenido...","footer-props":{
        'items-per-page-text': 'Elementos por página',
      }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{staticClass:"rounded-xl rounded-b-0",attrs:{"flat":"","color":"transparent"}},[_c('v-text-field',{staticClass:"mt-7",attrs:{"label":_vm.$t('DH360.findTextBox'),"dense":"","clearable":"","flat":"","prepend-inner-icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-divider',{staticClass:"mx-6",staticStyle:{"border-color":"lightslategrey"},attrs:{"inset":"","vertical":""}}),_c('v-btn',{staticClass:"mb-1 font-weight-bold rounded-pill",attrs:{"color":"blue-grey darken-1","dark":""},on:{"click":function($event){_vm.muestraBuscador = true}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" crear usuario ")],1)],1)]},proxy:true},{key:"item.ultimoAcceso",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.fixFormatDate(item.ultimoAcceso.date.substring(0, 10)))+" ")]}},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":"","color":"pink darken-3"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"color":"pink darken-3"},on:{"click":function($event){return _vm.openConfirm(item, 2)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-trash-can ")])]}}],null,true)},[_c('span',[_vm._v("Eliminar")])]),_c('v-tooltip',{attrs:{"top":"","color":"deep-purple accent-2"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"color":"deep-purple accent-2"},on:{"click":function($event){return _vm.openConfirm(item, 1)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-lock-reset ")])]}}],null,true)},[_c('span',[_vm._v("Restablecer contraseña")])]),_c('v-tooltip',{attrs:{"top":"","color":"blue accent-2"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"color":"blue accent-2"},on:{"click":function($event){_vm.emplid = item.Folio; _vm.muestraCargaArchivos = true;}}},'v-icon',attrs,false),on),[_vm._v(" mdi-file-document-multiple-outline ")])]}}],null,true)},[_c('span',[_vm._v("Ver expediente")])])]}}])})],1),(_vm.muestraBuscador)?_c('BuscadorUsuarios',{attrs:{"muestraBuscador":_vm.muestraBuscador},on:{"ocultaBuscador":_vm.cierraBuscador}}):_vm._e(),(_vm.showConfirm)?_c('ConfirmDialogReclutamiento',{attrs:{"showConfirm":_vm.showConfirm,"question":_vm.question},on:{"cierraConfirm":_vm.cierraConfirm}}):_vm._e(),(_vm.muestraBarra)?_c('SnackBar',{attrs:{"muestraBarra":_vm.muestraBarra,"color":_vm.colorBarra,"texto":_vm.textoBarra},on:{"cierraBarra":function($event){_vm.muestraBarra = false}}}):_vm._e(),(_vm.muestraCargaArchivos)?_c('MuestraArchivos',{attrs:{"dialogCargaArchivos":_vm.muestraCargaArchivos,"emplid":_vm.emplid},on:{"cierraVentana":function($event){_vm.muestraCargaArchivos = false}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }