import axios from 'axios'
import { deleteToken } from '../../../../store/Funciones/funciones'
import VueJsonToCsv from "vue-json-to-csv";

export default {
    name: 'admon-prestamo-fondo',
    components: { VueJsonToCsv },
    props: [],
    data() {
        return {
            headers: [
                { value: "ID", text: "ID", class: "font-weight-bold blue-grey--text text--darken-3" },
                { value: "EMPLEADO", text: "EMPLEADO", class: "font-weight-bold blue-grey--text text--darken-3" },
                { value: "NOMBRE", text: "NOMBRE", class: "font-weight-bold blue-grey--text text--darken-3" },
                { value: "PROCESO", text: "PROCESO", class: "font-weight-bold blue-grey--text text--darken-3", align: "center" },
                { value: "CANTIDAD", text: "CANTIDAD", class: "font-weight-bold blue-grey--text text--darken-3", align: "center" },
                { value: "MONTO_AHORRO", text: "AHORRO", class: "font-weight-bold blue-grey--text text--darken-3", align: "center" },
                { value: "TOTAL", text: "TOTAL", class: "font-weight-bold blue-grey--text text--darken-3", align: "center" },
                { value: "CONCEPTO", text: "CONCEPTO", class: "font-weight-bold blue-grey--text text--darken-3", align: "center" },
                { value: "FECHA_REGISTRO", text: "FECHA_REGISTRO", class: "font-weight-bold blue-grey--text text--darken-3" },
                // { value: "ACCIONES", text: "ACCIONES", class: "font-weight-bold blue-grey--text text--darken-3" },
            ],
            registros: [],
            yearsToSelect: [],
            headers_report_to_export: {
                ID: {
                    title: "ID",
                },
                EMPLEADO: {
                    title: "EMPLEADO",
                },
                NOMBRE: {
                    title: "NOMBRE",
                },
                PROCESO: {
                    title: "PROCESO",
                },
                CANTIDAD: {
                    title: "CANTIDAD",
                },
                MONTO_AHORRO: {
                    title: "AHORRO",
                },
                TOTAL: {
                    title: "TOTAL",
                },
                CONCEPTO: {
                    title: "CONCEPTO",
                },
                FECHA_REGISTRO: {
                    title: "FECHA_REGISTRO",
                },
            },
            loadingData: true,
            fechaActual: new Date(),
            year: null,
            error: "",
            search: ""

        }
    },
    created() {
        this.year = this.fechaActual.getFullYear();
        for (let i = 2020; i <= this.year; i++) {
            this.yearsToSelect.push(i);
        }
    },
    computed: {

    },
    mounted() {
        this.getRegistros()
    },
    methods: {
        getRegistros() {
            this.registros.length = 0;
            let params = {
                Opcion: 3,
                token: localStorage.getItem("token"),
                year: this.year,
            };
            axios
                .post(
                    `${this.$store.getters.getHost}/Compensaciones/ws-prestamo-fondo.php`,
                    params
                )
                .then((response) => {
                    this.loadingData = false;
                    if (response.data.status == "EXPRESSION") {
                        deleteToken();
                    } else if (response.data.status == "OK") {
                        // this.registros = response.data.reporte;
                        response.data.reporte.map(r => {
                            this.registros.push({
                                ID: r.ID,
                                EMPLEADO: r.EMPLEADO,
                                NOMBRE: r.NOMBRE,
                                PROCESO: r.PROCESO,
                                CANTIDAD: r.CANTIDAD,
                                MONTO_AHORRO: r.MONTO_AHORRO === null ? '' : r.MONTO_AHORRO,
                                TOTAL: this.getTotal(r.CANTIDAD, r.MONTO_AHORRO, r.CONCEPTO),
                                CONCEPTO: r.CONCEPTO,
                                FECHA_REGISTRO: this.cambiarFormatoFecha(r.FECHA_REGISTRO.date),
                            })
                        })
                    }
                }).catch(e => {
                    this.error = e;
                });
        },
        formatMXN(cadena) {
            return cadena.toLocaleString("es-MX", {
                style: "currency",
                currency: "MXN",
            });
        },
        cambiarFormatoFecha(fecha) {
            const fechaObjeto = new Date(fecha);
            const dia = fechaObjeto.getDate().toString().padStart(2, '0');
            const mes = (fechaObjeto.getMonth() + 1).toString().padStart(2, '0');
            const año = fechaObjeto.getFullYear().toString();
            const hora = fechaObjeto.getHours().toString().padStart(2, '0');
            const minutos = fechaObjeto.getMinutes().toString().padStart(2, '0');
            const segundos = fechaObjeto.getSeconds().toString().padStart(2, '0');

            return `${dia}/${mes}/${año} ${hora}:${minutos}:${segundos}`;
        },
        getTotal(cantidad, ahorro, concepto) {
            if (concepto === 394) {
                if (ahorro != null) {
                    return parseFloat(cantidad) + parseFloat(ahorro)
                } else {
                    return ''
                }
            }
            return ''
        }
    }
}