<template>
  <v-dialog v-model="generaPase" persistent max-width="90%" scrollable>
    <v-card class="rounded-xl">
      <v-card-title class="primary">
        <div class="overline font-weight-bold white--text">
          pase de salida con material o equipo
        </div>
        <v-spacer></v-spacer>
        <v-icon dark @click="$emit('cierraGeneraPase', cambios)">mdi-close</v-icon>
      </v-card-title>
      <v-card-text class="pt-5 blue-grey lighten-5">
        <v-sheet class="rounded-0 elevation-1" min-height="400" width="100%">
          <div class="pa-5">
            <v-img :src="require('@/assets/img/grupo_urrea_azul_gris.png')" max-height="70" width="250"></v-img>
          </div>

          <v-form ref="formPaseDigital">
            <div class="py-o my-0" style="display: flex; justify-content: center; align-items:top;">

              <v-switch v-model="tienePI"
                :label="`Cuentas con un pase de ingreso? ${tieneOT ? 'Sí' : 'No'}`"></v-switch>

            </div>
            <div v-show="tienePI" class="py-o my-0" style="display: flex; justify-content: center; align-items:top;">
              <v-autocomplete v-model="numeroPI" clearable :items="PIsList" class="rounded-pill rounded-r-0" dense
                outlined placeholder="Escribe numero de pase de ingreso..." label="Pase de ingreso" item-text="id"
                hide-no-data item-value="id" style="max-width: 400px" :loading="loadingBuscaPI"></v-autocomplete>
              <v-tooltip right color="primary">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="primary" class="rounded-pill rounded-l-0" depressed dark :loading="loadingBuscaInfoPI"
                    v-bind="attrs" v-on="on" style="height: 40px !important;" @click=" buscaInfoPI();">
                    <v-icon>mdi-magnify</v-icon>
                  </v-btn>
                </template>
                <label class="overline font-weight-bold">Buscar pase ingreso</label>
              </v-tooltip>
            </div>
            <div class="px-5" style="display: flex; justify-content: center;">
              <v-radio-group v-model="tipo" v-on:change="reset()" row>
                <v-radio label="Colaborador@" value="1"></v-radio>
                <v-radio label="Proveedor" value="2"></v-radio>
                <!-- <v-radio label="Cliente" value="3"></v-radio>-->
              </v-radio-group>
            </div>
            <div v-if="tipo == '3'" class="ma-2" style="display: flex; justify-content: start; align-items:top;">
              <v-switch v-model="tienePaseIngreso" class="py-0 mx-0 mb-0 mt-1">
              </v-switch>
              <label class="overline">Tengo un pase de ingreso:
                {{ tienePaseIngreso ? "Sí" : "No" }}</label>
              <v-spacer></v-spacer>
            </div>
            <v-row dense class="px-5" v-show="!tienePaseIngreso">
              <!-- fila uno (1) -->
              <v-col cols="7">
                <v-autocomplete v-if="tipo === '1'" v-model="nombre" class="rounded-lg" counter="180" dense outlined
                  label="Nombre" :items="colaboradores" item-text="nombreMostrar" :loading="loadingColaboradores"
                  :required="tipo === '1' ? true : false" :rules="tipo === '1' ? rulesTextField : []"
                  v-on:change="llenaCampos"></v-autocomplete>

                <v-autocomplete v-else-if="tipo === '2' && visitantesPermitidosProveedor.length > 0" v-model="nombre"
                  outlined dense :items="visitantesPermitidosProveedor" item-text="Nombre"
                  :required="tipo === '2' ? true : false" :rules="tipo === '2' ? rulesTextField : []" class="rounded-lg"
                  label="Nombre" placeholder="Busca el nombre..."
                  :disabled="visitantesPermitidosProveedor.length === 0 ? true : false">
                </v-autocomplete>

                <v-text-field
                  v-else-if="(tipo === '2' || tipo === '3') && (visitantesPermitidosProveedor.length == 0 || [null, undefined].includes(visitantesPermitidosProveedor))"
                  v-model="nombre" outlined dense :required="tipo === '2' ? true : false"
                  :rules="tipo === '2' ? rulesTextField : []" class="rounded-lg" label="Nombre"
                  placeholder="Busca el nombre..." :disabled="compania == '' ? true : false">
                </v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field v-model="numeroPersonal" class="rounded-lg" counter="180" dense outlined
                  label="N° Personal" disabled></v-text-field>
              </v-col>
              <v-col cols="2">
                <v-text-field v-model="proveedor" class="rounded-lg" counter="180" dense outlined disabled
                  label="Proveedor"></v-text-field>
              </v-col>
              <!-- fila dos (2) -->
              <v-col cols="5">
                <v-autocomplete v-if="tipo === '2'" v-model="compania" item-text="NOMBRE" :items="proveedores"
                  item-value="NOMBRE" :rules="[v => !!v || 'Proveedor es requerido']"
                  label="Razón social de la compañía" placeholder="Busca por nombre de compañía" required clearable
                  outlined dense return-object :search-input.sync="search" hide-no-data @change="onChange"
                  :loading="loadingNombreCompania"></v-autocomplete>
                <v-text-field v-else v-model="compania" :disabled="tipo === '1'" counter="180"
                  label="Compañía que representa" required outlined dense></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field v-model="domicilio" class="rounded-lg" counter="180" dense outlined label="Domicilio"
                  :disabled="tipo === '1'"></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field v-model="telefono" class="rounded-lg" counter="15" dense outlined label="Teléfono"
                  :disabled="tipo === '1'"></v-text-field>
              </v-col>
            </v-row>
            <div v-show="tienePaseIngreso" style="display: flex; justify-content: center; align-items:top;">
              <v-text-field v-model="numeroPaseIngreso" clearable class="rounded-pill rounded-r-0" dense outlined
                placeholder="Escribe el número de pase de ingreso..." style="max-width: 400px"></v-text-field>
              <v-tooltip right color="primary">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="primary" class="rounded-pill rounded-l-0" depressed dark :loading="loadingPaseIngreso"
                    v-bind="attrs" v-on="on" style="height: 40px !important;"
                    @click="loadingPaseIngreso = true; buscaRegistroVisita();">
                    <v-icon>mdi-magnify</v-icon>
                  </v-btn>
                </template>
                <label class="overline font-weight-bold">Buscar</label>
              </v-tooltip>
            </div>

            <v-row class="px-5" dense style="display: flex; justify-content: center; align-items:top;">
              <v-data-table v-show="tienePaseIngreso" :headers="headersClientes" :items="clientesPaseIngreso"
                class="elevation-1" dense :loading="loadingPaseIngreso"
                no-data-text="Ningún elemento coincide con el criterio de búsqueda" :footer-props="{
    'items-per-page-text': 'Elementos por página', 'items-per-page-options': [10, 20, 30, { text: 'Todos', value: -1 }],
    'show-current-page': true, 'page-text': 'Página {0} de {1}'
  }" :options.sync="options" :header-props="{ 'short-by-text': 'Ordenar por', }" loading-text="Cargando contenido...">>
              </v-data-table>
            </v-row>

            <v-row class="px-5" dense>
              <v-col cols="5">
                <v-card class="rounded-lg elevation-1" height="100%">
                  <v-card-title class="primary py-1">
                    <div class="overline font-weight-bold white--text">
                      material del área de:<!--  {{ area }} -->
                    </div>
                  </v-card-title>
                  <v-card-text class="center-item-in-div pt-5">
                    <v-sheet v-if="loadingAreas" class="center-item-in-div" height="100%">
                      <v-progress-circular indeterminate color="primary"></v-progress-circular>
                    </v-sheet>

                    <v-select v-else v-model="area" class="rounded-lg" :rules="rulesArea"
                      v-on:change="loadingAcciones = true; getAccionesPorArea();" dense outlined :items="areas"
                      item-text="Area" placeholder="Selecciona el área..."></v-select>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="7">
                <v-card class="rounded-lg elevation-1" height="100%">
                  <v-card-title class="primary py-1">
                    <div class="overline font-weight-bold white--text">
                      Razón:
                    </div>
                  </v-card-title>
                  <v-card-text class="pt-5">
                    <label v-if="acciones.length === 0 && !loadingAcciones" class="text-center overline">
                      Seleccione un área para poder elegir la acción.
                    </label>

                    <v-select v-else-if="acciones.length > 0 && !loadingAcciones" v-model="accion" class="rounded-lg"
                      dense outlined :items="acciones" item-text="descripcion" @change="onAccionChange" item-value="id"
                      placeholder="Selecciona la acción..."></v-select>
                    <v-sheet v-else class="center-item-in-div" height="100%">
                      <v-progress-linear indeterminate></v-progress-linear>
                    </v-sheet>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col clos="5"></v-col>
            </v-row>
            <div class="pa-2">
              <v-switch v-if="accion !== null && accion.id === 43" v-model="tieneOT"
                label="Contiene Ordenes de trabajo de CPT?" color="primary" value="tieneOT" hide-details />
            </div>
            <div>
              <div class="px-5 mb-3">
                <div v-if="tipo == '2'" style="display: flex; justify-content: start; align-items:top;">
                  <v-switch v-model="tienePedido" class="py-0 mx-0 mb-0 mt-1" @click="tieneExpediente = false">
                  </v-switch>
                  <label class="overline">Tengo un número de pedido:
                    {{ tienePedido ? "Sí" : "No" }}</label>
                  <v-spacer></v-spacer>
                  <v-switch v-model="tieneExpediente" class="py-0 mx-0 mb-0 mt-1" @click="tienePedido = false">
                  </v-switch>
                  <label class="overline">Tengo un número de embarque:
                    {{ tieneExpediente ? "Sí" : "No" }}</label>
                </div>
                <div v-show="tienePedido" style="display: flex; justify-content: center; align-items:top;">
                  <v-autocomplete v-model="numeroPedido" chips deletable-chips multiple :items="pedidosList"
                    item-text="SDDOCO" item-value="SDDOCO" class="rounded-pill rounded-r-0" dense outlined
                    placeholder="Escribe el número de pedido..." style="max-width: 400px"></v-autocomplete>
                  <v-tooltip right color="primary">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn color="primary" class="rounded-pill rounded-l-0" depressed dark
                        :loading="loadingBuscaPedido" v-bind="attrs" v-on="on" style="height: 40px !important;"
                        @click="loadingBuscaPedido = true; buscaPedido();">
                        <v-icon>mdi-magnify</v-icon>
                      </v-btn>
                    </template>
                    <label class="overline font-weight-bold">Buscar</label>
                  </v-tooltip>
                </div>
                <div v-show="tieneExpediente" style="display: flex; justify-content: center; align-items:top;">
                  <v-text-field v-model="numeroExpediente" clearable class="rounded-pill rounded-r-0" dense outlined
                    placeholder="Escribe el número de embarque..." style="max-width: 400px"></v-text-field>
                  <v-tooltip right color="primary">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn color="primary" class="rounded-pill rounded-l-0" depressed dark :loading="loadingEmbarques"
                        v-bind="attrs" v-on="on" style="height: 40px !important;"
                        @click="loadingEmbarques = true; buscaEmbarque();">
                        <v-icon>mdi-magnify</v-icon>
                      </v-btn>
                    </template>
                    <label class="overline font-weight-bold">Buscar</label>
                  </v-tooltip>
                </div>
                <div style="display:flex; justify-content: start; align-items: center;" class="py-2">
                  <label class="overline">Unidad de medida seleccionada : </label>
                  <v-select v-model="unidadSeleccionada" :items="unidadesDeMedida" item-text="descripcion" value="id"
                    dense outlined :loading="loadingUnidadesDeMedida" required :rules="rulesRadio"
                    placeholder="Selecciona la unidad de medida..." style="max-height: 42px !important;"></v-select>
                </div>
                <v-simple-table v-show="!tieneExpediente" dense>
                  <thead>
                    <tr class="primary">
                      <th v-if="tieneExpediente" class="overline white--text text-center">Expediente</th>
                      <th class="overline white--text text-center">Pedido</th>
                      <th class="overline white--text text-center">cantidad</th>
                      <th class="overline white--text text-center">artículo</th>
                      <th class="overline white--text text-center">código</th>
                      <th class="overline white--text text-center" cols="2">
                        descripción
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(material, index) in materiales" :key="index">
                      <td v-if="tieneExpediente" style="border: 0.7px solid #A8A8A7; max-width: 100px;">
                        <input v-model="material.expediente" type="text" :readonly="tieneExpediente"
                          style="width: 100%;" />
                      </td>
                      <td style="border: 0.7px solid #A8A8A7; max-width: 100px;">
                        <input v-model="material.pedido" type="text" :readonly="tienePedido || tieneExpediente"
                          style="width: 100%;" />
                      </td>
                      <td style="border: 0.7px solid #A8A8A7; max-width: 60px;">
                        <input v-model="material.cantidad" type="number" :readonly="tienePedido" style="width: 100%;" />
                      </td>
                      <td style="border: 0.7px solid #A8A8A7;">
                        <v-menu v-model="material.menuIsActive" :close-on-content-click="false" offset-y>
                          <template v-slot:activator="{ on }">
                            <input v-model="material.unidad" v-on="on" type="text" :readonly="tienePedido"
                              style="width: 100%;" @input="loadingCatalogo = true; getToolsCatalog(material.unidad);" />
                          </template>
                          <v-card>
                            <div v-if="material.unidad.length >= 3 && loadingCatalogo" class="center-item-in-div py-5">
                              <v-progress-circular indeterminate color="primary"></v-progress-circular>
                            </div>
                            <v-list v-else-if="catalogoHerramientas.length > 0" dense>
                              <v-list-item v-for="(option, indexTool) in catalogoHerramientas" :key="indexTool" link
                                dense @click="setItemIndex(index, option)">
                                <v-list-item-title v-text="option.nombreMostrar"></v-list-item-title>
                              </v-list-item>
                            </v-list>
                            <div
                              v-else-if="!loadingCatalogo && material.unidad.length >= 3 && catalogoHerramientas.length == 0">
                              <p class="text-center caption my-0 py-2">
                                No se encontraron resultados para:
                                {{ material.unidad }}
                              </p>
                            </div>
                          </v-card>
                        </v-menu>
                      </td>
                      <td style="border: 0.7px solid #A8A8A7; max-width: 100px;">
                        <input v-model="material.codigo" type="text" :readonly="tienePedido" style="width: 100%;" />
                      </td>
                      <td cols="2" style="border: 0.7px solid #A8A8A7;">
                        <input v-model="material.descripcion" type="text" :readonly="tienePedido"
                          style="width: 100%;" />
                      </td>
                    </tr>
                  </tbody>
                </v-simple-table>
                <div v-show="tieneExpediente" style="display:flex; justify-content: start; align-items: center;"
                  class="py-2">
                  <label class="overline">Tipo de selección: </label>
                  <v-radio-group v-model="selectedOption" @change="updateItemKey" row>
                    <v-radio label="Por producto" value="producto"></v-radio>
                    <v-radio label="Por pedido" value="pedido"></v-radio>
                  </v-radio-group>
                </div>
                <v-data-table v-show="tieneExpediente" v-model="materialesSeleccionados" :headers="headersEmbarques"
                  :items="materialesEmbarques" :item-key="itemKey" show-select class="elevation-1" dense
                  :loading="loadingEmbarques" no-data-text="Ningún elemento coincide con el criterio de búsqueda"
                  :footer-props="{
    'items-per-page-text': 'Elementos por página', 'items-per-page-options': [10, 20, 30, { text: 'Todos', value: -1 }],
    'show-current-page': true, 'page-text': 'Página {0} de {1}'
  }" :options.sync="options" :header-props="{ 'short-by-text': 'Ordenar por', }" loading-text="Cargando contenido...">>
                </v-data-table>
              </div>
              <div class="text-right px-6 mb-6" v-show="!tienePedido">
                <v-btn v-show="materiales.length > 1" fab x-small class="mx-1 elevation-1" dark color="pink darken-3"
                  @click="materiales.pop()">
                  <v-icon>mdi-minus</v-icon>
                </v-btn>
                <v-btn fab x-small class="mx-1 elevation-1" dark color="teal darken-1" @click="addMaterial">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </div>
              <v-row class="px-5">
                <v-col cols="12">
                  <div class="mt-3 center-item-in-div-column">
                    <div class="overline">¿lo regresará a la compañía?</div>
                    <v-switch v-model="regresa" class="mt-0 pt-0" :label="regresa ? 'Sí' : 'No'"></v-switch>
                    <v-dialog ref="dialog" v-model="dialogDiaRegreso" :return-value.sync="diaRegreso" persistent
                      width="290px">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="diaRegreso" v-show="regresa" dense outlined class="rounded-lg"
                          label="Fecha de regreso" prepend-inner-icon="mdi-calendar" readonly v-bind="attrs"
                          v-on="on"></v-text-field>
                      </template>
                      <v-date-picker v-model="diaRegreso" scrollable @input="$refs.dialog.save(diaRegreso)">
                      </v-date-picker>
                    </v-dialog>
                  </div>
                </v-col>
              </v-row>
            </div>
            <div v-if="(tieneOT && accion !== null && accion.id === 43) || paseIngresoOT" class="px-5 mb-3">
              <v-card class="rounded-lg elevation-1">
                <v-card-title class="primary py-1 overline font-weight-bold white--text">Ordenes de Trabajo del Centro
                  de Servicio
                  Master
                </v-card-title>
                <v-card-text class="pa-1">
                  <div style="display: flex; justify-content: center; align-items:top;">
                    <v-autocomplete v-model="numeroOT" chips deletable-chips multiple :items="OrdenesTrabajoList"
                      class="rounded-pill rounded-r-0" dense outlined item-text="folio" item-value="folio"
                      placeholder="Escribe el número de OT..."></v-autocomplete>
                    <v-btn color="primary" class="rounded-pill rounded-l-0" depressed dark :loading="loadingBuscaOT"
                      @click="buscaOts">
                      <v-icon>mdi-magnify</v-icon>
                    </v-btn>
                  </div>
                  <div>
                    <v-data-table v-show="itemsOTSinfo" dense :headers="headers" :items="itemsOTS"
                      :loading="loadingData" class="elevation-1"
                      no-data-text="Ningún elemento coincide con el criterio de búsqueda"
                      :footer-props="{ 'items-per-page-text': 'Elementos por página', }"
                      :header-props="{ 'short-by-text': 'Ordenar por', }" loading-text="Cargando contenido...">
                      <template v-slot:item.actions="{ item }">
                        <v-tooltip bottom>
                          <template #activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on" @click="removeItem(item)">
                              <v-icon>mdi-delete</v-icon>
                            </v-btn>
                          </template>
                          <span>Eliminar elemento</span>
                        </v-tooltip>
                      </template>
                    </v-data-table>
                  </div>
                </v-card-text>
              </v-card>
            </div>
          </v-form>
        </v-sheet>
      </v-card-text>
      <v-card-actions class="center-item-in-div">
        <v-btn class="rounded-lg" x-large color="teal darken-1" :loading="loadingBtnSavePasse" dark
          @click="loadingBtnSavePasse = true; savePasse();">
          <v-icon left>mdi-text-box-plus</v-icon>
          crear pase
        </v-btn>
      </v-card-actions>

      <SnackBarErrorIncidencias v-if="muestraBarra" :muestraBarra="muestraBarra" :color="colorBarra" :texto="textoBarra"
        @cierraBarra="muestraBarra = false" />
    </v-card>
  </v-dialog>
</template>

<script>
import { deleteToken } from "../../../../../store/Funciones/funciones.js";
import axios from "axios";
import SnackBarErrorIncidencias from "../../../General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue";
export default {
  name: "generacion-de-pase-de-salida",
  props: ["generaPase"],
  components: { SnackBarErrorIncidencias },
  data() {
    return {
      //
      pedidosList: [],
      //
      area: null,
      accion: null,
      materiales: [],
      colaboradores: [],
      areas: [],
      acciones: [],
      catalogoHerramientas: [],
      proveedores: [],
      visitantesPermitidosProveedor: [],
      unidadesDeMedida: [],
      tipo: "1",
      rulesTextField: [
        (v) => !!v || "Este campo es requiredo.",
        (v) =>
          (v && v.length <= 180) ||
          "Este campo no debe tener más de 180 caracteres",
      ],
      rulesArea: [
        (v) => !!v || "Este campo es requiredo.",
      ],
      rulesRadio: [(v) => !!v || "Este campo es requiredo."],
      cambios: 0,
      regresa: false,
      dialogDiaRegreso: false,
      muestraBarra: false,
      loadingBtnSavePasse: false,
      esColaborador: false,
      loadingColaboradores: false,
      loadingCatalog: false,
      menuIsActive: false,
      loadingAreas: true,
      loadingAcciones: false,
      loadingNombreCompania: false,
      tienePedido: false,
      loadingBuscaPedido: false,
      loadingCatalogo: false,
      loadingUnidadesDeMedida: true,
      highlightedIndex: -1,
      colorBarra: "",
      textoBarra: "",
      diaRegreso: "",
      proveedor: "",
      nombre: "",
      numeroPersonal: "",
      compania: null,
      domicilio: "",
      telefono: "",
      observaciones: "",
      numeroPedido: null,
      unidadSeleccionada: null,
      // ordenes de trabajo cpt
      OrdenesTrabajoList: [],
      numeroOT: null,
      loadingBuscaOT: false,
      headers: [
        { text: 'Folio', value: 'folio', class: "text-uppercase font-weight-bold blue-grey--text text--darken-2", },
        { text: 'Id Producto', value: 'Id_ProductoCC', class: "text-uppercase font-weight-bold blue-grey--text text--darken-2", },
        { text: 'Acciones CSA', value: 'AccionesCSA', class: "text-uppercase font-weight-bold blue-grey--text text--darken-2", },
        { text: 'Cliente', value: 'Nombre', class: "text-uppercase font-weight-bold blue-grey--text text--darken-2", },
        { text: 'Acción', value: 'actions', class: "text-uppercase font-weight-bold blue-grey--text text--darken-2" },
      ],
      itemsOTS: [],
      itemsOTSinfo: false,
      tieneOT: false,
      numeroExpediente: null,
      tieneExpediente: null,
      headersEmbarques: [
        { text: 'Pedido', value: 'pedido', class: "primary  text-uppercase font-weight-bold white--text text--darken-2", },
        { text: 'Cantidad', value: 'cantidad', class: "primary  text-uppercase font-weight-bold white--text text--darken-2", },
        { text: 'SKU', value: 'producto', class: "primary  text-uppercase font-weight-bold white--text text--darken-2", },
        { text: 'Descripción', value: 'descripcion', class: "primary  text-uppercase font-weight-bold white--text text--darken-2", },
      ],
      materialesSeleccionados: [],
      materialesEmbarques: [],
      loadingEmbarques: false,
      options: {
        itemsPerPage: -1, // Mostrar todos los elementos por defecto
      },
      selectedOption: 'producto', // Opción seleccionada inicial
      itemKey: 'producto', // Valor inicial de itemKey
      tienePaseIngreso: false,
      loadingPaseIngreso: false,
      headersClientes: [
        { text: 'Cliente', value: 'nombre', class: "primary  text-uppercase font-weight-bold white--text text--darken-2", },
        { text: 'Correo', value: 'correo', class: "primary  text-uppercase font-weight-bold white--text text--darken-2", },
      ],
      clientesPaseIngreso: [
      ],
      numeroPaseIngreso: null,
      //  autocomplete PI
      tienePI: false,
      numeroPI: null,
      PIsList: [],
      loadingBuscaPI: false,
      loadingBuscaInfoPI: false,
      areaid: null,
      paseIngresoOT: false,
      search: '',
      searchText: '',
    };
  },
  async mounted() {
    if (this.materiales.length === 0) {
      this.addMaterial();
    }
    this.buscaPI();
    this.getColaboradores();
    this.getAreas();
    this.getUnidadesDeMedida();
    this.getPedidos();
  },
  watch: {
    search(value) {
      if (value && value.length >= 2) {
        this.searchText = value;
        this.getProveedores();
      }
    },
  },
  methods: {
    openSnackBar(texto, color = "pink darken-3") {
      this.colorBarra = color;
      this.textoBarra = texto;
      this.muestraBarra = true;
    },
    getColaboradores() {
      const params = {
        Opcion: 11,
        token: localStorage.getItem("token"),
      };
      axios
        .post(
          `${this.$store.getters.getHost}/SeguridadPatrimonial/ws-pases-de-salida.php`,
          params
        )
        .then((response) => {
          this.loadingData = false;
          if (response.data.status === "OK") {
            this.colaboradores = response.data.respuesta;
          } else if (response.data.status == "EXPRESSION") {
            deleteToken();
            throw new Error('Token expired');
          } else {
            throw new Error('Failed to load colaboradores');
          }
        })
        .catch((error) => {
          this.openSnackBar(error);
          console.error('Error loading colaboradores:', error);
        })
        .finally(() => {
          this.loadingColaboradores = false;
        });
    },
    getPedidos() {
      const params = {
        Opcion: 18,
        token: localStorage.getItem("token"),
      };
      axios
        .post(
          `${this.$store.getters.getHost}/SeguridadPatrimonial/ws-pases-de-salida.php`,
          params
        )
        .then((response) => {
          this.loadingData = false;
          if (response.data.status == "OK") {
            this.pedidosList = response.data.respuesta;
          } else if (response.data.status == "EXPRESSION") {
            deleteToken();
            throw new Error('Token expired');
          } else {
            throw new Error('Failed to load pedidos');
          }
        })
        .catch((error) => {
          this.openSnackBar(error);
          console.error('Error loading pedidosList:', error);
        })
        .finally(() => {
          this.loadingPedidos = false;
        });
    },
    buscaEmbarque() {
      const params = {
        Opcion: 22,
        token: localStorage.getItem("token"),
        numeroEmbarque: this.numeroExpediente
      };
      axios
        .post(
          `${this.$store.getters.getHost}/SeguridadPatrimonial/ws-pases-de-salida.php`,
          params
        )
        .then((response) => {
          this.loadingData = false;
          if (response.data.status == "OK") {
            this.materialesEmbarques = response.data.respuesta;
          } else if (response.data.status == "EXPRESSION") {
            deleteToken();
            throw new Error('Token expired');
          } else {
            throw new Error('Failed to load embarques');
          }
        })
        .catch((error) => {
          this.openSnackBar(error);
          console.error('Error loading embarques:', error);
        })
        .finally(() => {
          this.loadingEmbarques = false;
        });
    },
    getOTs() {
      const params = {
        Opcion: 20,
        token: localStorage.getItem("token"),
      };
      axios
        .post(
          `${this.$store.getters.getHost}/SeguridadPatrimonial/ws-pases-de-salida.php`,
          params
        )
        .then((response) => {
          this.loadingData = false;
          if (response.data.status == "OK") {
            this.OrdenesTrabajoList = response.data.OrdenesTrabajo;
          } else if (response.data.status == "EXPRESSION") {
            deleteToken();
            throw new Error('Token expired');
          } else {
            throw new Error('Failed to load pedidos');
          }
        })
        .catch((error) => {
          this.openSnackBar(error);
          console.error('Error loading pedidosList:', error);
        })
        .finally(() => {
          this.loadingPedidos = false;
        });
    },
    getUnidadesDeMedida() {
      const params = {
        Opcion: 17,
        token: localStorage.getItem("token"),
      };
      axios
        .post(
          `${this.$store.getters.getHost}/SeguridadPatrimonial/ws-pases-de-salida.php`,
          params
        )
        .then((response) => {
          this.loadingData = false;
          if (response.data.status == "OK") {
            this.unidadesDeMedida = response.data.respuesta;
          } else if (response.data.status == "EXPRESSION") {
            deleteToken();
            throw new Error('Token expired');
          } else {
            throw new Error('Failed to load unidades de medida');
          }
        })
        .catch((error) => {
          this.openSnackBar(error);
          console.error('Error loading unidadesDeMedida:', error);
        })
        .finally(() => {
          this.loadingUnidadesDeMedida = false;
        });
    },
    getAreas() {
      const params = {
        Opcion: 3,
        token: localStorage.getItem("token"),
      };
      axios
        .post(
          `${this.$store.getters.getHost}/SeguridadPatrimonial/ws-pases-de-salida.php`,
          params
        )
        .then((response) => {
          this.loadingData = false;
          if (response.data.status == "OK") {
            this.areas = response.data.areas;
          } else if (response.data.status == "EXPRESSION") {
            deleteToken();
            throw new Error('Token expired');
          } else {
            throw new Error('Failed to load areas');
          }
        })
        .catch((error) => {
          this.openSnackBar(error);
          console.error('Error loading areas:', error);
        })
        .finally(() => {
          this.loadingAreas = false;
        });
    },
    getAccionesPorArea() {
      let areaSelected = this.areas.filter((a) => {
        return a.Area === this.area;
      });
      const idArea = areaSelected && areaSelected[0] && areaSelected[0].id ? areaSelected[0].id : this.areaid;

      const params = {
        Opcion: 14,
        token: localStorage.getItem("token"),
        idArea: idArea,
      };
      axios
        .post(
          `${this.$store.getters.getHost}/SeguridadPatrimonial/ws-pases-de-salida.php`,
          params
        )
        .then((response) => {
          this.loadingData = false;
          if (response.data.status == "OK") {
            this.acciones = response.data.respuesta;
          } else if (response.data.status == "EXPRESSION") {
            deleteToken();
          } else {
            this.acciones = [];
          }
        })
        .catch((e) => {
          this.openSnackBar(e);
        })
        .finally(() => {
          this.loadingAcciones = false;
        });
    },
    addMaterial() {
      this.materiales.push({
        cantidad: 0,
        unidad: "",
        codigo: "",
        descripcion: "",
        menuIsActive: false,
      });
    },
    savePasse() {
      if (this.$refs.formPaseDigital.validate()) {
        if (
          (this.materiales.length === 1 &&
            this.materiales[0].cantidad == 0 &&
            this.materiales[0].unidad == "" &&
            this.materiales[0].codigo == "") && !this.tieneExpediente
        ) {
          this.openSnackBar("En necesario que agregues materiales");
          this.loadingBtnSavePasse = false;
        } else if (this.buscaCantidadesEnCero() && !this.tieneExpediente) {
          this.openSnackBar("Las cantidades de los materiales no pueden ser 0.");
          this.loadingBtnSavePasse = false;
        } else {
          let idArea;
          if (this.areas) {
            idArea = this.areas.filter((a) => a.Area === this.area)[0];
          } else {
            console.error("No se encontraron áreas");
          }
          let idAccion;
          if (this.accion) {
            idAccion = this.accion.id;
          }
          let idUnidad = this.unidadesDeMedida.filter((u) => u.descripcion == this.unidadSeleccionada)[0];

          if (!idArea) {
            console.error("idArea no encontrado");
          }
          if (!idAccion) {
            console.error("idAccion no encontrado");
          }
          if (!idUnidad) {
            console.error("idUnidad no encontrado");
          }

          if (!this.tieneOT) {
            this.itemsOTS = undefined;
          }
          if (!this.tieneExpediente) {
            this.materialesSeleccionados = undefined;
          } const params = {
            Opcion: 2,
            token: localStorage.getItem("token"),
            proveedor: this.proveedor,
            nombre: this.nombre,
            numeroPersonal: this.numeroPersonal,
            compania: this.compania,
            domicilio: this.domicilio,
            telefono: this.telefono,
            idArea: idArea.id,
            idAccion: idAccion,
            observaciones: this.observaciones,
            regresa: this.regresa,
            fechaRegreso: this.diaRegreso,
            materiales: this.materiales,
            materialesEmbarques: this.materialesSeleccionados,
            numeroPedido: this.tienePedido ? this.numeroPedido : 0,
            tipo: this.tipo,
            unidadSeleccionada: idUnidad.id,
            OrdenesTrabajo: this.itemsOTS,
            numeroEmbarque: this.numeroExpediente,
            nombreGrupo: this.clientesPaseIngreso
          };
          axios
            .post(
              `${this.$store.getters.getHost}/SeguridadPatrimonial/ws-pases-de-salida.php`,
              params
            )
            .then((response) => {
              if (response.data.status == "OK") {
                if (response.data.respuesta.status_tran == "OK") {
                  this.openSnackBar(
                    response.data.respuesta.message,
                    "teal darken-1"
                  );
                  this.menuIsActive = false;
                  this.$refs.formPaseDigital.reset();
                  this.cambios++;
                  this.materiales.length = 0;
                  this.addMaterial();
                  this.$emit('cierraGeneraPase', this.cambios);
                } else {
                  this.openSnackBar(response.data.respuesta.message);
                }
              } else {
                this.openSnackBar("Ocurrió un error.");
              }
            })
            .catch((error) => {
              this.openSnackBar(error);
            })
            .finally(() => {
              this.loadingBtnSavePasse = false;
            });
        }
      } else {
        this.loadingBtnSavePasse = false;
        this.openSnackBar('Deben estar llenos todos los campos...');

      }
    },
    buscaCantidadesEnCero() {
      const materialEnCero = this.materiales.find((m) => m.cantidad === 0);

      return materialEnCero;
    },
    llenaCampos() {
      let colaboradorSeleccionado = this.colaboradores.filter((e) => {
        return e.nombreMostrar === this.nombre;
      });

      if (colaboradorSeleccionado.length > 0) {
        this.compania = colaboradorSeleccionado[0].nombreCompania;
        this.telefono = colaboradorSeleccionado[0].telefono;
        this.domicilio = colaboradorSeleccionado[0].domicilio;
        this.numeroPersonal = colaboradorSeleccionado[0].EMP_KEYEMP;
      }
    },
    getToolsCatalog(cadena) {
      this.catalogoHerramientas.length = 0;
      if (cadena.length >= 3) {
        this.loadingCatalog = true;
        const params = {
          Opcion: 36,
          token: localStorage.getItem("token"),
          entrada: cadena,
        };
        axios
          .post(
            `${this.$store.getters.getHost}/SeguridadPatrimonial/ws-RegistroVisitantes.php`,
            params
          )
          .then((response) => {
            if (response.data.status == "OK") {
              this.catalogoHerramientas = response.data.respuesta;
            } else {
              this.catalogoHerramientas = [];
            }
          })
          .catch((error) => {
            this.openSnackBar(error);
          })
          .finally(() => {
            this.loadingCatalogo = false;
          });
      }
    },
    getProveedores() {
      this.loadingNombreCompania = true;
      let params = {
        Opcion: 5,
        token: localStorage.getItem("token"),
        nombre: this.searchText,
      };
      axios
        .post(
          `${this.$store.getters.getHost}/SeguridadPatrimonial/ws-RegistroVisitantes.php`,
          params
        )
        .then((response) => {
          if (response.data.status == "OK") {
            this.proveedores = response.data.proveedores;
          }
        })
        .catch((error) => {
          this.openSnackBar(error);
        })
        .finally(() => {
          this.loadingNombreCompania = false;
        });
    },
    async selectItem(item) {
      this.proveedor = item.NO_PROVEEDOR;
      this.compania = item.NOMBRE;
      this.proveedores = [];
      this.menuIsActive = false;
      this.visitantesPermitidosProveedor = await this.getVisitantesPermitidosProveedores().catch(
        (e) => {
          this.openSnackBar(e);
        }
      );
    },
    getVisitantesPermitidosProveedores() {
      let params = {
        Opcion: 44,
        token: localStorage.getItem("token"),
        proveedor: this.proveedor,
      };
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${this.$store.getters.getHost}/SeguridadPatrimonial/ws-RegistroVisitantes.php`,
            params
          )
          .then((response) => {
            if (response.data.status == "OK") {
              resolve(response.data.respuesta);
            } else {
              resolve([]);
            }
          })
          .catch((error) => {
            reject([{ nombre: error }]);
          });
        // .finally(() => {
        //   this.loadingTipoVisita = false;
        // });
      });
    },
    buscaPedido() {
      const params = {
        Opcion: 13,
        token: localStorage.getItem("token"),
        folioPedido: this.numeroPedido,
      };

      axios
        .post(
          `${this.$store.getters.getHost}/SeguridadPatrimonial/ws-pases-de-salida.php`,
          params
        )
        .then((response) => {
          if (response.data.status == "OK") {
            if (response.data.respuesta.length) {
              this.materiales.length = 0;
              response.data.respuesta.map((item) => {
                this.materiales.push({
                  pedido: item.SDDOCO,
                  cantidad: item.SDUORG,
                  unidad: item.SDDSC1,
                  codigo: item.SDAITM,
                  descripcion: item.SDDSC2,
                });
              });
            } else {
              this.openSnackBar(
                "El número de pedido '" +
                this.numeroPedido +
                "' no fue encontrado verifica la información y vuelve a intentarlo"
              );
            }
          } else {
            this.openSnackBar(
              "El número de pedido '" +
              this.numeroPedido +
              "' no fue encontrado verifica la información y vuelve a intentarlo"
            );
          }
        })
        .catch((error) => {
          this.openSnackBar(error);
        })
        .finally(() => {
          this.loadingBuscaPedido = false;
        });
    },
    setItemIndex(index, option) {
      this.catalogoHerramientas.length = 0;
      console.log(option);
      this.materiales[index].menuIsActive = false;
      this.materiales[index].unidad = option.NombreLargo;
      this.materiales[index].codigo = option.CODIGO;
      this.materiales[index].descripcion = option.DescripcionProducto;
    },
    reset() {
      this.compania = "";
      this.telefono = "";
      this.domicilio = "";
      this.numeroPersonal = "";
      this.nombre = "";
      this.proveedor = "";
      this.materialesEmbarques = [];
      this.unidadSeleccionada = "";
      this.OrdenesTrabajo = "";
      this.numeroEmbarque = "";
    },
    //
    onAccionChange(selectedAccion) {
      this.accion = this.acciones.find(accion => accion.id === selectedAccion);
      if (this.accion.id == 43) {
        this.getOTs();
      }
    },
    buscaOts() {
      this.loadingBuscaOT = true;
      const params = {
        Opcion: 21,
        token: localStorage.getItem("token"),
        numeroOT: this.numeroOT,
      };
      axios
        .post(
          `${this.$store.getters.getHost}/SeguridadPatrimonial/ws-pases-de-salida.php`,
          params
        )
        .then((response) => {
          this.loadingData = false;
          if (response.data.status == "OK") {
            this.itemsOTS = response.data.OrdenesTrabajo;
            this.itemsOTSinfo = true;
          } else if (response.data.status == "EXPRESSION") {
            deleteToken();
            throw new Error('Token expired');
          } else {
            this.itemsOTSinfo = false;
            throw new Error('Failed to load pedidos');
          }
        })
        .catch((error) => {
          this.openSnackBar(error);
          console.error('Error loading pedidosList:', error);
        })
        .finally(() => {
          this.loadingBuscaOT = false;
        });
    },
    updateItemKey() {
      this.itemKey = this.selectedOption;
    },
    buscaRegistroVisita() {
      this.loadingPaseIngreso = true;
      const params = {
        Opcion: 23,
        token: localStorage.getItem("token"),
        numeroVisita: this.numeroPaseIngreso,
      };
      axios
        .post(
          `${this.$store.getters.getHost}/SeguridadPatrimonial/ws-pases-de-salida.php`,
          params
        )
        .then((response) => {
          if (response.data.status == "OK") {
            this.clientesPaseIngreso = response.data.Clientes;
          } else if (response.data.status == "EXPRESSION") {
            deleteToken();
            throw new Error('Token expired');
          } else {
            throw new Error('Failed to load clientes');
          }
        })
        .catch((error) => {
          this.openSnackBar(error);
          console.error('Error loading clientesList:', error);
        })
        .finally(() => {
          this.loadingPaseIngreso = false;
        });
    },
    buscaPI() {
      this.loadingBuscaPI = true;
      const params = {
        Opcion: 24,
        token: localStorage.getItem("token"),
      };

      axios
        .post(
          `${this.$store.getters.getHost}/SeguridadPatrimonial/ws-pases-de-salida.php`,
          params
        )
        .then((response) => {
          this.loadingData = false;
          if (response.data.status == "OK") {
            this.PIsList = response.data.pasesIngreso;
          } else if (response.data.status == "EXPRESSION") {
            deleteToken();
          } else {
            console.error('Error al obtener listado pases de ingreso.')
          }
        })
        .catch((e) => {
          this.openSnackBar(e);
        })
        .finally(() => {
          this.loadingBuscaPI = false;
        });
    },
    buscaInfoPI() {
      const params = {
        Opcion: 25,
        token: localStorage.getItem("token"),
        idPI: this.numeroPI
      };
      this.loadingBuscaInfoPI = true;

      axios
        .post(
          `${this.$store.getters.getHost}/SeguridadPatrimonial/ws-pases-de-salida.php`,
          params
        )
        .then((response) => {
          this.loadingData = false;
          if (response.data.status == "OK") {
            this.tipo = response.data.pasesIngreso[0].tipo.toString();
            this.nombre = response.data.pasesIngreso[0].nombre;
            this.numeroPersonal = response.data.pasesIngreso[0].numero_personal;
            this.proveedor = response.data.pasesIngreso[0].proveedor;
            this.compania = response.data.pasesIngreso[0].compania;
            this.domicilio = response.data.pasesIngreso[0].domicilio;
            this.telefono = response.data.pasesIngreso[0].telefono;
            this.area = response.data.pasesIngreso[0].Area;
            this.idArea = response.data.pasesIngreso[0].id_area_pases
            this.unidadSeleccionada = response.data.pasesIngreso[0].descMedida;
            this.getAccionesPorArea();
            this.materiales = response.data.pasesIngreso[0].material;
            this.itemsOTS = response.data.pasesIngreso[0].materialOT;

            if (this.itemsOTS.length > 0) {
              this.paseIngresoOT = true;
              this.itemsOTSinfo = true;
              this.getOTs();
              this.tieneOT = true;
              this.accion = {
                id: 33,
                descripcion: 'GARANTIAS'
              };
            }
          } else if (response.data.status == "EXPRESSION") {
            deleteToken();
          } else {
            console.error('Error al obtener listado pases de ingreso.')
          }
        })
        .catch((e) => {
          this.openSnackBar(e);
        })
        .finally(() => {
          this.loadingBuscaInfoPI = false;
        });
    },
    removeItem(item) {
      const index = this.itemsOTS.indexOf(item);
      if (index > -1) {
        this.itemsOTS.splice(index, 1);
      }
    },
    onChange(value) {
      if (value && this.tipo === '2') {
        if (value) {
          this.proveedor = value.NO_PROVEEDOR;
          this.compania = value.NOMBRE;
        } else {
          this.proveedor = '';
        }
      }
    },
  },
};
</script>

<style scoped>
.autocomplete {
  position: relative;
}

.autocomplete-list {
  list-style: none;
  padding: 0;
  margin: 0;
  position: absolute;
  z-index: 1;
  border: 1px solid #ccc;
  max-height: 150px;
  overflow-y: auto;
}

.autocomplete-list li {
  padding: 8px;
  cursor: pointer;
}

.autocomplete-list li.highlighted {
  background-color: #f2f2f2;
}
</style>
