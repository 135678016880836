<template>
    <v-card class="rounded-xl elevation-0 pa-3">
        <v-data-table dense :headers="headers" :search="search" :items="beneficios"
            no-data-text="No hay registros de debeficiados" :footer-props="{
            'items-per-page-text': 'Elementos por página',
        }" :header-props="{ 'short-by-text': 'Ordenar por', }" loading-text="Cargando contenido..."
            :loading="loadingBenefits">
            <template v-slot:top>
                <div style="display: flex; justify-content: space-between;">
                    <v-text-field v-model="search" outlined dense class="rounded-xl" style="max-width: 350px;"
                        prepend-inner-icon="mdi-table-search" placeholder="Buscar en tabla..."></v-text-field>
                    <div>
                        <v-btn depressed class="rounded-pill ml-2" color="teal" dark @click="openAgregaBeneficio(null)">
                            <v-icon left> mdi-plus </v-icon>
                            Asignación
                        </v-btn>
                        <v-tooltip bottom color="blue lighten-4">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn small fab depressed class="ml-2" v-bind="attrs" v-on="on"
                                    @click="colseAgregaBeneficio(1)">
                                    <v-icon>mdi-reload</v-icon>
                                </v-btn>
                            </template>
                            <label class="text-uppercase font-weight-bold primary--text">Actualizar tabla</label>
                        </v-tooltip>
                    </div>
                </div>
            </template>

            <template v-slot:item.activo="{ item }">
                <v-chip :color="item.activo ? 'green lighten-3' : 'red lighten-3'" dark>
                    {{ item.activo ? 'Habilitado' : 'Deshabilitado' }}
                </v-chip>
            </template>

            <template v-slot:item.actions="{ item }">
                <v-icon color="cyan darken-2" small @click="openAgregaBeneficio(item)">
                    mdi-pencil
                </v-icon>
            </template>

        </v-data-table>

        <AgregaBeneficio v-if="muestraAgregaBenficio" :muestraAgregaBenficio="muestraAgregaBenficio"
            :beneficio="beneficioAux" @ocultaAgregaBeneficio="colseAgregaBeneficio" />

        <SnackBar v-if="muestraBarra" :muestraBarra="muestraBarra" :color="colorBarra" :texto="textoBarra"
            @cierraBarra="muestraBarra = false" />
    </v-card>
</template>

<script>
import axios from "axios";
import AgregaBeneficio from "./AgregaBeneficiario";
import SnackBar from "../../../General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue";

export default {
    name: "muestra-beneficios",
    components: { AgregaBeneficio, SnackBar },
    data() {
        return {
            headers: [
                {
                    text: "ID",
                    value: "id",
                    class:
                        "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                },
                {
                    text: "Beneficio",
                    value: "beneficio",
                    class:
                        "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                },
                {
                    text: "Descripción",
                    value: "descripcion",
                    class:
                        "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                },
                {
                    text: "Actualización",
                    value: "actualizacion",
                    sortable: false,
                    class:
                        "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                },
                {
                    text: "Activo",
                    value: "activo",
                    sortable: false,
                    class:
                        "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                },
                {
                    text: "Acciones",
                    value: "actions",
                    sortable: false,
                    align: "center",
                    class:
                        "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                },
            ],
            beneficios: [],
            loadingBenefits: true,
            muestraAgregaBenficio: false,
            muestraBarra: false,
            textoBarra: "",
            colorBarra: "",
            search: "",
            beneficioAux: '',
        };
    },
    async mounted() {
        this.beneficios = await this.getBenefits();
    },
    methods: {
        muestraSnackBar(color, texto) {
            this.colorBarra = color;
            this.textoBarra = texto;
            this.muestraBarra = true;
        },
        async getBenefits() {
            let params = {
                Opcion: 1,
                token: localStorage.getItem("token"),
            };
            return new Promise((resolve, reject) => {
                axios
                    .post(
                        `${this.$store.getters.getHost}/Compensaciones/ws-extras-beneficios-flexibles.php`,
                        params
                    )
                    .then((response) => {
                        if (response.data.status == "OK") {
                            resolve(response.data.beneficios);
                        }
                    })
                    .catch((error) => {
                        reject({
                            error: error,
                        });
                    })
                    .finally(() => {
                        this.loadingBenefits = false;
                    });
            });
        },
        openAgregaBeneficio(beneficio) {
            this.beneficioAux = beneficio;
            this.muestraAgregaBenficio = true;
        },
        async colseAgregaBeneficio(type) {
            if (type == 1) {
                this.loadingBenefits = true;
                this.beneficios.length = 0;
                this.beneficios = await this.getBenefits();
            }
            this.muestraAgregaBenficio = false;
        },
    },
};
</script>