<template>
  <div>
    <p
      class="text-uppercase caption mb-0 text-center blue-grey--text text--darken-1 font-weight-bold"
    >
      filtros
    </p>
    <v-divider
      style="border-color: #CFD8DC;"
      class="mx-16 my-0 py-0"
    ></v-divider>
    <v-row v-show="vacante == null" class="px-5 pt-3 mb-0 pb-0" dense>
      <v-col :cols="numberOfColumns">
        <v-row class="pa-3">
          <v-text-field
            v-model="candidateFilterValue"
            type="text"
            solo
            dense
            prepend-inner-icon="mdi-filter-outline"
            class="rounded-lg"
            label="Candidato"
          >
          </v-text-field>
        </v-row>
      </v-col>

      <v-col :cols="numberOfColumns">
        <v-row class="pa-3">
          <v-text-field
            v-model="positionFilterValue"
            type="text"
            solo
            dense
            prepend-inner-icon="mdi-filter-outline"
            class="rounded-lg"
            label="Vacante"
          >
          </v-text-field>
        </v-row>
      </v-col>

      <v-col :cols="numberOfColumns">
        <v-row class="pa-3">
          <v-select
            :items="statusList"
            v-model="statusFilterValue"
            solo
            dense
            prepend-inner-icon="mdi-filter-outline"
            class="rounded-lg"
            label="Estatus"
          >
          </v-select>
        </v-row>
      </v-col>
    </v-row>
    <div style="display: flex; justify-content: space-between;">
      <!-- {{ candidatosDelDia }} -->
      <v-badge color="green" :content="candidatosDelDia" overlap bordered
        ><v-btn color="blue lighten-1" class="rounded-pill" depressed small dark :loading="loadinCandidatesPerDay" @click="loadinCandidatesPerDay = true; getCandidatosPorDia()"> Candidatos postulados el día de hoy</v-btn>
      </v-badge>

      <div>
        <vue-json-to-csv
          :json-data="report_to_export"
          :labels="headers_report_to_export"
          csv-title="Reporte general de candidatos"
        >
          <v-btn
            class="back-btn-update-export-excel font-weight-bold mb-2 mr-2 rounded-pill"
            dark
            small
            depressed
            :loading="loadingReporte"
          >
            <v-icon left> mdi-microsoft-excel </v-icon>
            exportar
          </v-btn>
        </vue-json-to-csv>
        <v-btn
          class="back-btn-update font-weight-bold mb-2 rounded-pill mr-2"
          small
          depressed
          @click="
            loadingData = true;
            getCandidatosPostulados();
          "
        >
          <v-icon left> mdi-update </v-icon>
          actualizar
        </v-btn>
      </div>
    </div>
    <v-data-table
      class="rounded-xl elevation-10"
      :headers="headers"
      :items="candidatos"
      dense
      :loading="loadingData"
      style="border: 0.8px solid #CFD8DC !important;"
    >
      <tempalte v-slot:item.id="{ item }">
        <v-sheet class="font-weight-bold">{{ item.id }}</v-sheet>
      </tempalte>
      <template v-slot:item.nombre="{ item }">
        <v-btn
          small
          text
          color="blue darken-1"
          class="text-decoration-underline text-capitalize"
          @click="openDetallesCandidato(item)"
          >{{ item.nombre }}</v-btn
        >
      </template>
      <template v-slot:item.estatus="{ item }">
        <v-chip :color="getColorStatus(item.estatus)" small dark>{{
          item.estatus
        }}</v-chip>
      </template>
      <template v-slot:item.diasPostulado="{ item }">
        <v-chip :color="getColorCycleTime(item.diasPostulado)" small dark>{{
          item.diasPostulado
        }}</v-chip>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn-toggle
          class="rounded-lg"
          dense
          background-color="blue-grey lighten-5"
        >
          <v-tooltip top color="pink darken-3">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                depressed
                class="pa-1"
                dark
                icon
                v-bind="attrs"
                v-on="on"
                small
                @click="openRejectCandidate(item)"
              >
                <v-icon small color="pink darken-3"
                  >mdi-briefcase-account</v-icon
                >
              </v-btn>
            </template>
            <span>Enviar a bolsa</span>
          </v-tooltip>
          <v-tooltip top color="teal darken-2">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                depressed
                dark
                icon
                v-bind="attrs"
                v-on="on"
                small
                @click="
                  idCandidatoSeleccionado = item.id;
                  idVacanteCandidato = item.idVacante;
                  estatusCandidatoId = item.statusId;
                  muestraVacantesDisponibles = true;
                "
              >
                <v-icon small color="teal darken-2">mdi-autorenew</v-icon>
              </v-btn>
            </template>
            <span>Cambiar postulación</span>
          </v-tooltip>
          <v-tooltip top color="cyan darken-2">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                depressed
                dark
                icon
                v-bind="attrs"
                v-on="on"
                small
                @click="openActionCandidate(item)"
              >
                <v-icon small color="cyan darken-2"
                  >mdi-arrow-right-thin</v-icon
                >
              </v-btn>
            </template>
            <span>Avanzar proceso</span>
          </v-tooltip>
        </v-btn-toggle>
      </template>
    </v-data-table>

    <DetallesCandidato
      v-if="muestraDetallesCandidato"
      :muestraDetallesCandidato="muestraDetallesCandidato"
      :idCandidato="idCandidatoSeleccionado"
      @cierraDetallesCandidato="muestraDetallesCandidato = false"
    />
    <EnviaPsicometrias
      :showSendPsychometrics="enviaPsicometrias"
      :candidato="dataCandidate"
      @hideSendPsychometrics="updateCandidato"
    />
    <CargaPsicometrias
      :showUploadPsychometrics="cargaPsicometrias"
      :idCandidato="idCandidatoSeleccionado"
      @hideUploadPsychometrics="updateCandidato"
    />

    <v-dialog
      v-model="cargaApegoPerfil"
      persistent
      max-width="950"
      content-class="fix-border-dialog"
      scrollable
    >
      <v-card class="rounded-xl">
        <v-card-title class="primary white--text subtitle-2">
          Carga de apego a perfil de candidato.
          <v-spacer></v-spacer>
          <v-icon dark small @click="cargaApegoPerfil = false">
            mdi-close
          </v-icon>
        </v-card-title>
        <v-card-text class="pt-5">
          <CargaApegoPerfilDeCandidato
            v-if="cargaApegoPerfil"
            :esInterno="true"
            :idCandidato="idCandidatoSeleccionado"
            @cambiaEstatus="updateCandidato"
          />
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="enviaCorreoJefe"
      persistent
      max-width="450"
      content-class="fix-border-dialog"
    >
      <v-card class="rounded-xl">
        <v-card-title class="primary white--text subtitle-2">
          Envía correo a jefe directo.
          <v-spacer></v-spacer>
          <v-icon dark small @click="enviaCorreoJefe = false">
            mdi-close
          </v-icon>
        </v-card-title>
        <v-card-text class="pt-4">
          <v-row
            v-if="loadingDataCorreo"
            class="fill-height"
            align-content="center"
            justify="center"
          >
            <v-col
              :class="colorTexto"
              class="subtitle-1 text-center font-weight-bold"
              cols="12"
            >
              Obteniendo correo...
            </v-col>
            <v-col cols="6" class="center-item-in-div">
              <v-progress-circular
                :size="70"
                :width="7"
                color="primary"
                indeterminate
              ></v-progress-circular>
            </v-col>
          </v-row>
          <div v-else>
            <v-form v-model="validCorreo" ref="formCorreoJefe">
              <label class="text-uppercase">Se enviará un correo a:</label>
              <v-text-field
                v-model="correoJefeDirecto"
                class="rounded-lg"
                outlined
                required
                :rules="emailRules"
                dense
                placeholder="Agrega un correo..."
              ></v-text-field>
            </v-form>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            small
            dark
            class="rounded-lg"
            depressed
            color="cyan darken-3"
            :loading="loadingSendEmail"
            @click="
              loadingSendEmail = true;
              sendMail();
            "
          >
            Enviar
            <v-icon right>mdi-send</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <SnackBar
      v-if="muestraBarra"
      :muestraBarra="muestraBarra"
      :color="colorBarra"
      :texto="textoBarra"
      @cierraBarra="muestraBarra = false"
    />

    <ConfirmDialogReclutamiento
      :showConfirm="showConfirm"
      :question="question"
      @cierraConfirm="validaRespuestaConfirm"
    />

    <DialogComentarioRechazo
      v-if="showCommentReject"
      :commentReject="showCommentReject"
      :isCandidate="true"
      @ocultaDialogComentario="closeCommentReject"
    />

    <CambiaVacanteCandidato
      v-if="muestraVacantesDisponibles"
      :muestraVacantesDisponibles="muestraVacantesDisponibles"
      :idVacante="idVacanteCandidato"
      :estatusId="estatusCandidatoId"
      :idCandidato="idCandidatoSeleccionado"
      :tipo="1"
      @closeCambioVacante="closeCambioVacante"
    />
    <DataTableGeneric v-if="showDataTableGneric" :verDataTable="showDataTableGneric" :items="candidatosPorDiaData" :headers="headersCandidatosPorDiaData" titulo="Candidatos del día." @cierraTabla="showDataTableGneric=false"/>
  </div>
</template>

<script>
import axios from "axios";
import DetallesCandidato from "./DetallesCandidato.vue";
import EnviaPsicometrias from "./componentsCandidato/EnviaPsicometrias.vue";
import CargaPsicometrias from "./componentsCandidato/CargaPsicometrias.vue";
import CargaApegoPerfilDeCandidato from "./componentsCandidato/CargaApegoPerfilDeCandidato.vue";
import ConfirmDialogReclutamiento from "./ConfirmDialogReclutamiento.vue";
import SnackBar from "../../General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue";
import DialogComentarioRechazo from "./DialogComentarioRechazo.vue";
import CambiaVacanteCandidato from "./componentsCandidato/CambiaVacanteCandidato.vue";
import { deleteToken } from "../../../../store/Funciones/funciones";
import VueJsonToCsv from "vue-json-to-csv";
import DataTableGeneric from "../../../../components/componentsExtra/DataTableGeneric.vue";

export default {
  name: "ver-candidatos-portulados",
  props: ["vacante"],
  components: {
    DetallesCandidato,
    EnviaPsicometrias,
    CargaPsicometrias,
    CargaApegoPerfilDeCandidato,
    SnackBar,
    ConfirmDialogReclutamiento,
    DialogComentarioRechazo,
    CambiaVacanteCandidato,
    VueJsonToCsv,
    DataTableGeneric
  },
  data() {
    return {
      candidatos: [],
      report_to_export: [],
      candidatosPorDiaData: [],
      headers_report_to_export: {
        id: { title: "ID" },
        Candidato: { title: "CANDIDATO" },
        Tipo: { title: "TIPO ID" },
        tipoDesc: { title: "TIPO" },
        Vacante: { title: "VACANTE" },
        EstatusDesc: { title: "ESTATUS" },
        Postulacion: { title: "POSTULACIÓN" },
        Fecha_AutJefe: { title: "AUT. JEFE" },
        diasAutJefe: { title: "DÍAS AUT. JEFE" },
        Fecha_AutReclu: { title: "AUT. RECLU" },
        diasAutReclu: { title: "DÍAS AUT. RECLU" },
        Fecha_Psicometricos: { title: "PSICOMETRICOS" },
        diasPsicometricos: { title: "DÍAS PSICOMETRICOS" },
        Fecha_ApegoPerfil: { title: "APEGO A PERFIL" },
        diasApego: { title: "DÍAS APEGO" },
        Fecha_Entrevistas: { title: "ENTREVISTAS" },
        diasEntrevistas: { title: "DÍAS ENTREVISTAS" },
        Fecha_TerminoProceso: { title: "TERMINO DE PROCESO" },
        diasFinProceso: { title: "DÍAS TERMINO DE PROCESO" },
      },
      headersCandidatosPorDiaData: [
        {
          value: "id",
          text: "ID",
          align: "center",
        },
        {
          value: "Candidato",
          text: "CANDIDATO",
        },
        {
          value: "Nombre",
          text: "VACANTE",
        },
      ],
      statusList: [
        "Todos los estatus",
        "Validacion Jefe directo",
        "Psicometrias y Examenes tecnicos",
        "Entrevista Reclutamiento",
        "Apego perfil",
        "Agenda",
        "Entrevistas",
        "Agenda comite",
        "Espera comité",
        "Comité",
        "Seleccion",
        "Oferta Economica",
        "Completado",
        "Bolsa de Trabajo",
        "Rechazado",
      ],
      emailRules: [
        (v) => !!v || "Campo requerido",
        (v) => /.+@.+\..+/.test(v) || "El correo debe ser valido.",
      ],
      dataCandidate: {},
      showCommentReject: false,
      loadingData: true,
      showConfirm: false,
      muestraDetallesCandidato: false,
      enviaPsicometrias: false,
      cargaPsicometrias: false,
      cargaApegoPerfil: false,
      enviaCorreoJefe: false,
      loadingDataCorreo: false,
      loadingSendEmail: false,
      muestraBarra: false,
      validCorreo: true,
      muestraVacantesDisponibles: false,
      loadingReporte: true,
      loadinCandidatesPerDay: false,
      showDataTableGneric: false,
      question: "",
      colorBarra: "",
      textoBarra: "",
      positionFilterValue: "",
      candidateFilterValue: "",
      statusFilterValue: "",
      correoJefeDirecto: "",
      idCandidatoSeleccionado: 0,
      idVacanteCandidato: 0,
      estatusCandidatoId: 0,
      candidatosDelDia: 0,
      candidatoTemporal: {},
      headers: [
        {
          value: "id",
          text: "ID",
          align: "center",
          class: "header-rounded-left",
        },
        {
          value: "nombre",
          text: "Candidato",
          filter: this.candidateFilter,
          class: "my-custom-header-class",
        },
        {
          value: "vacante",
          text: "Vacante",
          filter: this.positionFilter,
          class: "my-custom-header-class",
        },
        {
          value: "tipoDesc",
          text: "Tipo",
          align: "center",
          class: "my-custom-header-class",
        },
        {
          value: "diasPostulado",
          text: "Tiempo de ciclo",
          align: "center",
          class: "my-custom-header-class",
        },
        {
          value: "estatus",
          text: "Estatus",
          filter: this.statusFilter,
          align: "center",
          class: "my-custom-header-class",
        },
        {
          value: "Area",
          text: "Área",
          align: "center",
          class: "my-custom-header-class",
        },
        {
          value: "actions",
          text: "",
          align: "center",
          sortable: false,
          class: "header-rounded-right",
        },
      ],
    };
  },
  computed: {
    numberOfColumns() {
      return this.$vuetify.breakpoint.name == "xs" ||
        this.$vuetify.breakpoint.name == "sm"
        ? 12
        : 4;
    },
  },
  async mounted() {
    this.getCandidatosPostulados();
    this.report_to_export = await this.getReporte();
    this.candidatosDelDia = await this.getCandidatosDelDiaCounter();
  },
  methods: {
    muestraSnackBar(text, color) {
      this.colorBarra = color;
      this.textoBarra = text;
      this.muestraBarra = true;
    },
    getCandidatosPostulados() {
      if (this.candidatos.lenght > 0) {
        this.candidatos.lenght = 0;
      }
      let params = {
        Opcion: 11,
        token: localStorage.getItem("token"),
        vacante: this.vacante,
      };
      axios
        .post(
          `${this.$store.getters.getHost}/Reclutamiento/ws-admin-reclu.php`,
          params
        )
        .then((response) => {
          if (response.data.status != "EXPSESSION") {
            this.candidatos = response.data.desc;
          }
        })
        .catch((e) => {
          this.muestraSnackBar(e, "pink darken-3");
        })
        .finally(() => {
          this.loadingData = false;
        });
    },
    getReporte() {
      let params = {
        Opcion: 23,
        token: localStorage.getItem("token"),
      };

      return new Promise((resolve, reject) => {
        axios
          .post(
            `${this.$store.getters.getHost}/Reclutamiento/ws-admin-candidatos.php`,
            params
          )
          .then((response) => {
            if (response.data.status != "EXPSESSION") {
              resolve(response.data.reporte);
            } else {
              resolve([]);
            }
          })
          .catch((e) => {
            reject(e);
          })
          .finally(() => {
            this.loadingReporte = false;
          });
      });
    },
    getCandidatosDelDiaCounter() {
      let params = {
        Opcion: 24,
        token: localStorage.getItem("token"),
      };

      return new Promise((resolve, reject) => {
        axios
          .post(
            `${this.$store.getters.getHost}/Reclutamiento/ws-admin-candidatos.php`,
            params
          )
          .then((response) => {
            if (response.data.status != "EXPSESSION") {
              resolve(response.data.respuesta.total);
            } else {
              resolve(0);
            }
          })
          .catch((e) => {
            reject(e);
          })
          .finally(() => {
            this.loadingReporte = false;
          });
      });
    },
    positionFilter(value) {
      if (!this.positionFilterValue) {
        return true;
      }
      return value
        .toLowerCase()
        .includes(this.positionFilterValue.toLowerCase());
    },
    candidateFilter(value) {
      if (!this.candidateFilterValue) {
        return true;
      }
      return value
        .toLowerCase()
        .includes(this.candidateFilterValue.toLowerCase());
    },
    statusFilter(value) {
      if (
        !this.statusFilterValue ||
        this.statusFilterValue == "Todos los estatus"
      ) {
        return true;
      }
      return value.toLowerCase().includes(this.statusFilterValue.toLowerCase());
    },
    getColorStatus(status) {
      switch (status) {
        case "Validacion Jefe directo":
          return "teal darken-1";
        case "Psicometrias y Examenes tecnicos":
          return "teal darken-2";
        case "Entrevista Reclutamiento":
          return "teal darken-1";
        case "Apego perfil":
          return "teal darken-3";
        case "Agenda":
          return "teal darken-3";
        case "Entrevistas":
          return "teal darken-3";
        case "Agenda comite":
          return "teal darken-3";
        case "Espera comité":
          return "teal darken-3";
        case "Comité":
          return "teal darken-3";
        case "Seleccion":
          return "teal darken-4";
        case "Oferta Economica":
          return "teal darken-4";
        case "Completado":
          return "green darken-4";
        case "Bolsa de Trabajo":
          return "pink darken-2";
        case "Rechazado":
          return "pink darken-4";
        default:
          return "blue-grey darken-2";
      }
    },
    getColorCycleTime(time) {
      if (time > 7 && time < 14) {
        return "teal darken-3";
      } else if (time > 15) {
        return "pink darken-3";
      } else {
        return "teal darken-1";
      }
    },
    openDetallesCandidato(item) {
      this.idCandidatoSeleccionado = item.id;
      this.muestraDetallesCandidato = true;
    },
    openActionCandidate(item) {
      switch (item.statusId) {
        case 2: {
          this.idCandidatoSeleccionado = item.id;
          this.cargaPsicometrias = true;
          break;
        }
        case 1: {
          this.loadingDataCorreo = true;
          this.enviaCorreoJefe = true;
          this.idCandidatoSeleccionado = item.id;
          this.getCorreoJefe(item.id);
          break;
        }
        case 3: {
          this.dataCandidate = item;
          this.enviaPsicometrias = true;
          break;
        }
        case 4: {
          this.idCandidatoSeleccionado = item.id;
          this.cargaApegoPerfil = true;
          break;
        }
        case 6: {
          this.candidatoTemporal = item;
          this.question =
            "¿Realmente deseas enviar nuevamente el correo de retroalimentación a los entrevistadores?";
          this.showConfirm = true;
          break;
        }
        default: {
          console.table(item);
        }
      }
    },
    updateCandidato(candidato, nEstatus) {
      console.log("candidato:" + candidato + ", " + "nEstatus:" + nEstatus);
      if (candidato == null) {
        this.enviaPsicometrias = false;
        this.cargaPsicometrias = false;
      } else {
        if (nEstatus === 2) {
          const index = this.candidatos.indexOf(candidato);
          this.candidatos[index].statusId = nEstatus;
          this.candidatos[index].estatus = "Psicometrias y Examenes tecnicos";
          this.enviaPsicometrias = false;
        } else if (nEstatus === 4) {
          let candidatoAux = this.candidatos.filter((c) => {
            return c.id === candidato;
          });
          const index = this.candidatos.indexOf(candidatoAux[0]);
          this.candidatos[index].statusId = nEstatus;
          this.candidatos[index].estatus = "Apego perfil";
          this.cargaPsicometrias = false;
        } else if (nEstatus === 6) {
          this.cargaApegoPerfil = false;
          console.log("Llegó estatus 6.");
          let candidatoAux = this.candidatos.filter((c) => {
            return c.id === candidato;
          });
          console.log(candidatoAux);
          const index = this.candidatos.indexOf(candidatoAux[0]);
          console.log(index);
          this.candidatos[index].statusId = nEstatus;
          this.candidatos[index].estatus = "Entrevistas";
          this.cargaPsicometrias = false;
        }
      }
    },
    getCorreoJefe(id) {
      let params = {
        Opcion: 11,
        token: localStorage.getItem("token"),
        idCandidato: id,
      };
      axios
        .post(
          `${this.$store.getters.getHost}/Reclutamiento/ws-admin-candidatos.php`,
          params
        )
        .then((response) => {
          this.loadingDataCorreo = false;
          if (response.data.status != "EXPSESSION") {
            if (response.data.status == "OK" && response.data.mail != "") {
              this.correoJefeDirecto = response.data.mail;
            }
          } else {
            deleteToken();
          }
        })
        .catch((e) => {
          alert(e);
        });
    },
    sendMail() {
      if (this.$refs.formCorreoJefe.validate()) {
        const params = {
          Opcion: 12,
          token: localStorage.getItem("token"),
          idCandidato: this.idCandidatoSeleccionado,
          correo: this.correoJefeDirecto,
        };
        axios
          .post(
            `${this.$store.getters.getHost}/Reclutamiento/ws-admin-candidatos.php`,
            params
          )
          .then((response) => {
            this.loadingSendEmail = false;
            if (response.data.status != "EXPSESSION") {
              if (response.data.respuesta.status_tran == "OK") {
                this.muestraSnackBar(
                  response.data.respuesta.message,
                  "primary"
                );
                this.enviaCorreoJefe = false;
              } else {
                this.muestraSnackBar(
                  response.data.respuesta.message,
                  "pink darken-3"
                );
              }
            } else {
              deleteToken();
            }
          })
          .catch((e) => {
            this.muestraSnackBar(e, "pink darken-3");
          });
      } else {
        this.loadingSendEmail = false;
      }
    },
    sendMailToEnterviewers() {
      const params = {
        Opcion: 13,
        token: localStorage.getItem("token"),
        nombre: this.candidatoTemporal.nombre,
        vacante: this.candidatoTemporal.vacante,
        tipoDesc: this.candidatoTemporal.tipoDesc,
        idCandidato: this.candidatoTemporal.id,
      };
      axios
        .post(
          `${this.$store.getters.getHost}/Reclutamiento/ws-admin-candidatos.php`,
          params
        )
        .then((response) => {
          // this.loadingSendEmail = false;
          if (response.data.status != "EXPSESSION") {
            if (response.data.respuesta != "") {
              this.muestraSnackBar(response.data.respuesta, "primary");
              this.enviaCorreoJefe = false;
            } else {
              this.muestraSnackBar(
                "Algo salió mal, no se envió el correo.",
                "pink darken-3"
              );
            }
          } else {
            deleteToken();
          }
        })
        .catch((e) => {
          this.muestraSnackBar(e, "pink darken-3");
        });
    },
    validaRespuestaConfirm(response) {
      if (response) {
        this.showConfirm = false;
        this.sendMailToEnterviewers();
      } else {
        this.showConfirm = false;
      }
    },
    openRejectCandidate(item) {
      this.idCandidatoSeleccionado = item.id;
      this.showCommentReject = true;
    },
    closeCommentReject(comment) {
      this.showCommentReject = false;
      if (comment !== null) {
        const params = {
          Opcion: 21,
          token: localStorage.getItem("token"),
          idCandidato: this.idCandidatoSeleccionado,
          comentario: comment,
        };
        axios
          .post(
            `${this.$store.getters.getHost}/Reclutamiento/ws-admin-candidatos.php`,
            params
          )
          .then((response) => {
            if (response.data.status != "EXPSESSION") {
              if (response.data.status == "OK") {
                if (response.data.respuesta.status_tran == "OK") {
                  this.muestraSnackBar(
                    response.data.respuesta.message,
                    "teal darken-1"
                  );
                  this.loadingData = true;
                  this.getCandidatosPostulados();
                } else {
                  this.muestraSnackBar(
                    response.data.respuesta.message,
                    "pink darken-3"
                  );
                }
              } else {
                this.muestraSnackBar("Ocurrio un error", "pink darken-3");
              }
            } else {
              deleteToken();
            }
          })
          .catch((e) => {
            this.muestraSnackBar(e, "pink darken-3");
          });
      }
    },
    closeCambioVacante(respuesta) {
      this.muestraVacantesDisponibles = false;
      if (respuesta === 1) {
        this.loadingData = true;
        this.getCandidatosPostulados();
      }
    },
    getCandidatosPorDia() {
      let params = {
        Opcion: 25,
        token: localStorage.getItem("token")
      };
      axios
        .post(
          `${this.$store.getters.getHost}/Reclutamiento/ws-admin-candidatos.php`,
          params
        )
        .then((response) => {
          this.loadingDataCorreo = false;
          if (response.data.status != "EXPSESSION") {
            if (response.data.status == "OK") {
              this.candidatosPorDiaData = response.data.respuesta;
              this.showDataTableGneric = true;
            }
          } else {
            deleteToken();
          }
        })
        .catch((e) => {
          alert(e);
        })
        .finally(() => {
          this.loadinCandidatesPerDay = false
        });
    },
  },
};
</script>

<style lang="sass">
$border-radius-root: 6px
.my-custom-header-class
    background-color: rgb(0,120,195)
    color: white !important
    font-weight: 700 !important
    text-transform: uppercase


.header-rounded-left
    background-color: rgb(0,120,195) !important
    color: white !important
    font-weight: 700 !important
    text-transform: uppercase
    border-top-left-radius: $border-radius-root * 4

.header-rounded-right
    border-top-right-radius: $border-radius-root * 4
    background-color: rgb(0,120,195)
    color: white !important
    font-weight: 700 !important
    text-transform: uppercase

.background-glass-effect
    // background: rgba(200,200,200, 0.4) !important
    border-radius: 16px !important
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1)
    backdrop-filter: blur(9.3px)
    -webkit-backdrop-filter: blur(9.3px)
    border: 1px solid rgba(255, 255, 255, 1)
</style>
