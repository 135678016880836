<template>
  <v-dialog v-model="openDatePicker" persistent width="290px">
    <v-time-picker v-model="time" full-width format="24hr" class="rounded-lg">
      <v-spacer></v-spacer>
      <v-btn text color="primary" @click="$emit('closeDatePicker', null)">
        Cancelar
      </v-btn>
      <v-btn text color="primary" @click="saveTime(time)">
        Guardar
      </v-btn>
    </v-time-picker>
  </v-dialog>
</template>

<script>
export default {
  name: "date-picker-dialog",
  props: ["openDatePicker"],
  data() {
    return {
        time: null
    };
  },
  mounted(){
  },
  methods: {
    saveTime(time){
        this.$emit('closeDatePicker', time)
    }
  }
};
</script>
