var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"rounded-xl elevation-0 pa-3"},[_c('v-data-table',{attrs:{"dense":"","headers":_vm.headers,"search":_vm.search,"items":_vm.beneficios,"items-per-page":15,"no-data-text":"No hay registros para este estatus","loading-text":"Cargando contenido...","loading":_vm.loadingBenefits,"show-expand":"","expanded":_vm.expanded},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticStyle:{"display":"flex","justify-content":"space-between"}},[_c('v-text-field',{staticClass:"rounded-xl",staticStyle:{"max-width":"350px"},attrs:{"outlined":"","dense":"","prepend-inner-icon":"mdi-table-search","placeholder":"Buscar en tabla..."},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('div',[_c('v-btn',{staticClass:"rounded-pill ml-2",attrs:{"depressed":"","color":"teal","dark":""},on:{"click":function($event){return _vm.openAgregaBeneficio(null)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-plus ")]),_vm._v(" Añadir beneficio ")],1),_c('v-tooltip',{attrs:{"bottom":"","color":"blue lighten-4"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"small":"","fab":"","depressed":""},on:{"click":function($event){return _vm.colseAgregaBeneficio(1)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-reload")])],1)]}}])},[_c('label',{staticClass:"text-uppercase font-weight-bold primary--text"},[_vm._v("Actualizar tabla")])])],1)],1)]},proxy:true},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"px-5 py-5 blue-grey lighten-5",attrs:{"colspan":headers.length}},[_c('div',{staticClass:"center-item-in-div"},[_c('v-card',{staticClass:"px-4 mx-2 py-1 elevation-0 rounded-xl",staticStyle:{"border":"2px solid #000000"},attrs:{"width":"300"}},[_c('label',{staticClass:"overline"},[_vm._v(" Descripción ")]),_c('p',[_vm._v(_vm._s(item.descBeneficio))])]),_c('v-card',{staticClass:"px-4 mx-2 py-1 elevation-0 rounded-xl",staticStyle:{"border":"2px solid #000000"},attrs:{"width":"300"}},[_c('label',{staticClass:"overline"},[_vm._v(" Grupos de pago participantes ")]),_c('p',[_vm._v(_vm._s(item.beneficiados))])])],1)])]}},{key:"item.montoAyuda",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(parseFloat(item.montoAyuda) == 0 ? "" : _vm.formatMXN(parseFloat(item.montoAyuda)))+" ")]}},{key:"item.manual",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(item.tiene_manual !== 0),expression:"item.tiene_manual !== 0"}],attrs:{"text":"","small":"","color":"cyan darken-1","href":_vm.$store.getters.getHost + '/ArchivosCargadosEnPortal2/Compensaciones/BeneficiosUrrea/' + item.manual,"target":"_blank"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-file-eye-outline ")]),_c('div',{staticClass:"text-decoration-underline font-weight-bold"},[_vm._v(" "+_vm._s(item.manual)+" ")])],1)]}},{key:"item.estatus",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.estatus == 1 ? "Activo" : "Inactivo")+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":"cyan darken-2","small":""},on:{"click":function($event){return _vm.openAgregaBeneficio(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"color":"pink darken-2","small":""},on:{"click":function($event){return _vm.hideBenefit(item.id, item.estatus)}}},[_vm._v(" "+_vm._s(item.estatus == 1 ? 'mdi-eye-off' : 'mdi-eye')+" ")])]}}])}),(_vm.muestraAgregaBenficio)?_c('AgregaBeneficio',{attrs:{"muestraAgregaBenficio":_vm.muestraAgregaBenficio,"beneficio":_vm.beneficioAux},on:{"ocultaAgregaBeneficio":_vm.colseAgregaBeneficio}}):_vm._e(),(_vm.muestraBarra)?_c('SnackBar',{attrs:{"muestraBarra":_vm.muestraBarra,"color":_vm.colorBarra,"texto":_vm.textoBarra},on:{"cierraBarra":function($event){_vm.muestraBarra = false}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }