import axios from 'axios'
import {
    deleteToken,
    formatDate
} from '../../../../../store/Funciones/funciones'
import LoadingBar from '../../../DesarrolloOrganizacional/components/loadingBar.vue'
import SnackBar from '../../MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue'
import DetallesBeneficios from '../BeneficiosUrrea/components/DetallesBeneficios.vue'
import descargaMasivaDeNominas from './components/descargaMasivaDeNominas.vue'

export default {
    name: 'mis-nominas',
    components: {
        LoadingBar,
        SnackBar,
        DetallesBeneficios,
        descargaMasivaDeNominas
    },
    props: [],
    data() {
        return {
            fechasNominas: [],
            dates: [],
            compensaciones: [],
            muestraLoading: true,
            selectDates: false,
            muestraBarra: false,
            loadingBuscar: false,
            muestraDetallesBeneficio: false,
            fechasInvalidas: true,
            muestraDialogNominas: false,
            fechaBloqueo: null,
            textLoading: "Obteniendo tu información...",
            pdfSelected: "",
            xmlSelected: "",
            colorBarra: "",
            textoBarra: "",
            fechaInicial: "",
            compesacionTotal: "",
            compania: '',
            bloqueo: false,
        }
    },
    computed: {
        dateRangeText() {
            let inicio = "";
            let fin = "";
            if (this.dates[0] != null) {
                inicio = formatDate(this.dates[0]);
            }
            if (this.dates[1] != null) {
                fin = formatDate(this.dates[1]);
            }

            if (this.dates.length == 0) {
                return "";
            }
            return "Del " + inicio + " al " + fin;
        },
        validaFechasIngresadas() {
            if (this.dates.length === 2) {
                return false
            }
            return true
        }
    },
    mounted() {
        //  window.document.title = "DH:URREA - Mis nóminas.";
        const today = new Date();
        this.fechaBloqueo = today.toISOString().substring(0, 10);
        this.getCompania();
    },
    methods: {
        getCompania() {
            let paramsEstatusInc = {
                Opcion: 28,
                token: localStorage.getItem('token')
            };
            axios.post(`${this.$store.getters.getHost}/General/ws-general.php`, paramsEstatusInc)
                .then((response) => {
                    if (response.data.status == "EXPSESSION") {
                        deleteToken()
                    } else {
                        this.compania = response.data.compania;
                        if (this.compania == '00100') {
                            this.getFechas();
                        } else {
                            this.bloqueo = true;
                        }
                    }
                })
                .catch(function(e) {
                    let result = e.toString().includes("401");
                    if (result) {
                        window.location.reload();
                    } else {
                        alert(e);
                    }
                });

        },

        getFechas() {
            let params = {
                Opcion: 1,
                token: localStorage.getItem('token')
            }
            axios
                .post(`${this.$store.getters.getHost}/Compensaciones/ws-mi-nomina-timbrada.php`, params)
                .then((response) => {
                    if (response.data.status == 'EXPSESSION') {
                        deleteToken();
                    } else {
                        this.fechasNominas = response.data.fechas;
                        this.muestraLoading = false;
                    }
                })
        },
        abreNomina(opc) {
            if (opc == 1) {
                let pdfAux = this.fechasNominas.filter(this.buscaIdNomPdfSelected);
                window.open(pdfAux[0].pdf_url, '_blank');
                // this.pdfSelected = "";
            } else {
                let xmlAux = this.fechasNominas.filter(this.buscaIdNomXmlSelected);
                window.open(xmlAux[0].xml_url, '_blank');
            }

        },
        buscaIdNomPdfSelected(val) {
            return val.fechaFormat.trim() == this.pdfSelected.trim()
        },
        buscaIdNomXmlSelected(val) {
            return val.fechaFormat.trim() == this.xmlSelected.trim()
        },
        validaFechas() {
            if (this.dates.length == 2) {
                if (this.dates[0] > this.dates[1]) {
                    this.dates.length = 0
                    this.colorBarra = "error";
                    this.textoBarra =
                        "La fecha final no puede ser menor a la inicial. :c";
                    this.muestraBarra = true;
                } else {
                    this.fechasInvalidas = false
                }
            }
        },
        getFechaInicialCompensaciones() {
            let params = {
                Opcion: 4,
                token: localStorage.getItem('token')
            }
            axios
                .post(`${this.$store.getters.getHost}/Compensaciones/ws-beneficios.php`, params)
                .then((response) => {
                    this.muestraLoading = false;
                    this.selectDates = true;
                    if (response.data.status == 'EXPSESSION') {
                        deleteToken();
                    } else {
                        let auxFecha = response.data.fecha
                        this.fechaInicial = auxFecha.date.substring(0, 10);
                    }
                })
        },
        getDataCompensaciones() {
            this.compensaciones.length = 0
            let params = {
                Opcion: 2,
                token: localStorage.getItem('token'),
                fechaInicial: this.dates[0],
                fechaFin: this.dates[1],
            }
            axios.post(`${this.$store.getters.getHost}/Compensaciones/ws-beneficios.php`, params).then(response => {
                this.loadingBuscar = false
                this.fechasInvalidas = true
                if (response.data.status == 'OK') {
                    this.muestraLoading = false;
                    if (response.data.compensacion.compensaciones.length > 0) {
                        let compensacionesAux = response.data.compensacion.compensaciones;
                        compensacionesAux.map(item => {
                            item['importe'] = new Intl.NumberFormat("es-MX", {
                                style: "currency",
                                currency: "MXN",
                            }).format(item['importe']);
                            return item;
                        });
                        this.compesacionTotal = new Intl.NumberFormat("es-MX", {
                            style: "currency",
                            currency: "MXN",
                        }).format(response.data.compensacion.compensacionTotal);
                        this.compensaciones = {
                            compensacionesData: compensacionesAux,
                            compensacionTotal: this.compesacionTotal
                        }
                        this.dates.length = 0
                        this.selectDates = false
                        this.muestraDetallesBeneficio = true
                    } else {
                        this.compensaciones = {
                            compensacionesData: [],
                            compensacionTotal: ""
                        }
                        this.dates.length = 0
                        this.selectDates = false
                        this.muestraDetallesBeneficio = true
                    }
                } else {
                    deleteToken();
                }
            })
        },

    },


}