import axios from 'axios'
export default {
    name: 'conocimientos',
    components: {},
    props: ['text'],

    data: () => ({
        dialog: false,
        dialogCoincidencias: false,
        dialogCarga: false,
        dialogDelete: false,
        conocimientosMatchModel: false,
        dialogCargandoConocimientos: false,
        mensajeError: false,
        mensajeErrorSub: false,
        search: '',
        snackbar: false,
        text: '',
        timeout: 2000,
        file: '',
        loadingCargaConocimientos: false,
        dialogSinCoincidencias: false,
        tablaConocimientos: false,
        loaderpage: true,
        coincidencias: [],
        checkItemConocimiento: [],
        newConocimientosBackEnd: [],
        selectedAllKnowledge: [],
        textoDescSinCoincidencias: '',
        textoDescSinCoincidenciasTitulo: '',
        textLoading: '',
        textError: '',
        textErrorSub: '',
        headers: [{
                text: 'ID',
                align: 'start',
                value: 'id',
            },
            {
                text: 'Conocimiento',
                value: 'descr'
            },
            {
                text: 'Estatus',
                value: 'estatus'
            },
            {
                text: 'Actiones',
                value: 'actions',
                sortable: false
            },
        ],
        conocimientos: [],
        conocimientosMatch: [],
        editedIndex: -1,
        editedItem: {
            descr: '',
            Estatus: 'Activo',
        },
        defaultItem: {
            descr: '',
            Estatus: 'Activo',
        },
    }),

    computed: {
        formTitle() {
            return this.editedIndex === -1 ? 'New Item' : 'Edita conocimiento'
        },
    },

    watch: {
        dialog(val) {
            val || this.close()
        },
        dialogDelete(val) {
            val || this.closeDelete()
        },
    },

    created() {
        this.initialize()
    },

    methods: {
        initialize() {
            this.loading = true;
            const headers = {
                headers: {
                    'X-AUTH-TOKEN': localStorage.getItem('token')
                }
            };
            axios.get(`${this.$store.getters.getHost}/api/conocimientos`, headers)
                .then((response) => {
                    var data = response.data.map(conocimiento => {
                        conocimiento['estatus'] = (conocimiento['Activo']) ? 'Activo' : 'Inactivo';
                        return conocimiento;
                    })
                    this.conocimientos = data;
                    this.loaderpage = false;
                    this.tablaConocimientos = true;
                })
                .catch(function(e) {

                    let result = e.toString().includes("401");
                    if (result) {
                        window.location.reload();
                    } else {
                        alert(e);
                    }
                });
        },

        openDialogCarga() {
            this.dialogCarga = true;
        },

        handleFileUpload() {
            this.file = this.$refs.file.files[0];
        },



        submitFile() {

            if (this.file.type == "application/vnd.ms-excel") {
                var reader = new FileReader()
                reader.readAsDataURL(this.file)
                reader.onload = () => {
                    var params = {
                        filename: "conocimientosDO.csv",
                        base64: reader.result
                    }
                    axios.post(`${this.$store.getters.getHost}/test/upload_private_files`, params)
                        .then((response) => {
                            if (response.data == "conocimientosDO.csv") {
                                this.text = 'Documento cargado con éxito.';
                                this.snackbar = true;
                                this.dialogCarga = false;
                                this.textLoading = 'Cargando conocimientos espere por favor';
                                this.dialogCargandoConocimientos = true;
                                this.loadingCargaConocimientos = true;
                                // this.dialogCarga.loading = true;
                                axios.post(`${this.$store.getters.getHost}/api/matchConocimientos`).then((response) => {
                                        var data = response.data.map(conocimiento => {
                                            return conocimiento;
                                        })

                                        this.conocimientosMatch = data;
                                        if (this.conocimientosMatch.length == 0) {
                                            this.dialogCargandoConocimientos = false;
                                            this.textLoading = '';
                                            this.loadingCargaConocimientos = false;
                                            this.dialogSinCoincidencias = true;
                                            this.textoDescSinCoincidencias = 'El documento cargado no contiene conocimientos diferentes a los que ya se encuentran cargados en el sistema de crecimiento. Si consideras que esto no es correcto contacta a soporte técnico.';
                                            this.textoDescSinCoincidenciasTitulo = 'Sin coincidencias encontradas';
                                        } else {
                                            this.dialogCargandoConocimientos = false;
                                            this.loadingCargaConocimientos = false;
                                            this.dialogCoincidencias = true;
                                            this.conocimientosMatchModel = true;
                                        }


                                    })
                                    .catch(function(e) {

                                        let result = e.toString().includes("401");
                                        if (result) {
                                            window.location.reload();
                                        } else {
                                            alert(e);
                                        }
                                    });
                            }
                        });
                };
            } else {
                this.textErrorSub = 'Error. El archivo no es tipo CSV.';
                this.mensajeErrorSub = true;
            }
        },

        editItem(item) {
            this.editedIndex = this.conocimientos.indexOf(item);
            this.editedItem = Object.assign({}, item)
            this.dialog = true
        },

        deleteItem(item) {
            this.editedIndex = this.conocimientos.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialogDelete = true
        },

        saveKnowledge(conocimientosNuevos) {

            if (this.checkItemConocimiento.includes(conocimientosNuevos)) {
                this.checkItemConocimiento.splice(this.checkItemConocimiento.indexOf(conocimientosNuevos), 1)
            } else {
                this.checkItemConocimiento.push(conocimientosNuevos)
            }

        },

        deleteItemConfirm() {
            const headers = {
                headers: {
                    'X-AUTH-TOKEN': localStorage.getItem('token')
                }
            };
            const params = {
                id: this.editedItem.id
            };
            axios.post(`${this.$store.getters.getHost}/api/deactivate_conocimiento`, params, headers)
                .then((response) => {
                    if (response.data == true) {
                        this.snackbar = true;
                        this.text = 'Estatus editado con exito.';
                        this.initialize();
                    } else {
                        this.snackbar = true;
                        this.text = 'Error. Los cambios no se guardaron.';
                    }
                })
                .catch(function(e) {

                    let result = e.toString().includes("401");
                    if (result) {
                        window.location.reload();
                    } else {
                        alert(e);
                    }
                });

            this.closeDelete();
        },

        close() {
            this.dialog = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },

        closeDelete() {
            this.dialogDelete = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },

        save() {
            if (this.editedIndex > -1) {
                Object.assign(this.conocimientos[this.editedIndex], this.editedItem)
                const headers = {
                    headers: {
                        'X-AUTH-TOKEN': localStorage.getItem('token')
                    }
                };
                const params = {
                    id: this.editedItem.id,
                    descr: this.editedItem.descr
                }
                axios.post(`${this.$store.getters.getHost}/api/conocimientos_edit`, params, headers)
                    .then((response) => {
                        if (response.data == "true") {
                            this.snackbar = true;
                            this.text = 'Registro editado con exito.';
                        } else {
                            this.color = "red darken-2";
                            this.snackbar = true;
                            this.text = 'Error. Los cambios no se guardaron.';
                        }
                    })
                    .catch(function(e) {

                        let result = e.toString().includes("401");
                        if (result) {
                            window.location.reload();
                        } else {
                            alert(e);
                        }
                    });

            } else {
                this.conocimientos.push(this.editedItem)
            }
            this.close()
        },

        newKnowledge() {

            if (this.checkItemConocimiento.length > 0) {
                var params = {
                    arrConocimientos: this.checkItemConocimiento
                }

                axios.post(`${this.$store.getters.getHost}/api/setConocimientos`, params).then((response) => {
                        if (response.data) {
                            this.initialize();
                            this.dialogCoincidencias = false;
                            this.text = 'Conocimientos insertados con éxito.';
                            this.snackbar = true;
                            this.checkItemConocimiento = [];
                        } else {
                            this.text = 'Ocurrio un error.';
                            this.snackbar = true;
                        }
                    })
                    .catch(function(e) {

                        let result = e.toString().includes("401");
                        if (result) {
                            window.location.reload();
                        } else {
                            alert(e);
                        }
                    });
            } else {
                this.mensajeError = true;
                this.textError = 'Error. No hay conocimientos seleccionados.';
            }

        },

        newKnowledgeAll() {

            this.selectedAllKnowledge = this.conocimientosMatch.map(conocimiento => conocimiento['descr']);
            this.dialogCoincidencias = false;
            this.textLoading = 'Insertando conocimientos en el sistema de crecimiento. Espere por favor.'
            this.dialogCargandoConocimientos = true;

            var params = {
                arrConocimientos: this.selectedAllKnowledge
            }

            axios.post(`${this.$store.getters.getHost}/api/setConocimientos`, params).then((response) => {
                    if (response.data) {
                        this.initialize();
                        this.dialogCargandoConocimientos = false;
                        this.textLoading = '';
                        this.text = '';
                        this.text = 'Conocimientos insertados con éxito.';
                        this.snackbar = true;
                        this.checkItemConocimiento = null;
                    } else {
                        this.text = 'Ocurrio un error.';
                        this.snackbar = true;
                    }
                })
                .catch(function(e) {

                    let result = e.toString().includes("401");
                    if (result) {
                        window.location.reload();
                    } else {
                        alert(e);
                    }
                });

        },
    },
}