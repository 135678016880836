<template>
  <v-sheet max-height="390" color="white" class="rounded-xl pa-5">
    <div v-if="loadingData" class="text-center">
    <v-progress-circular
      :size="50"
      color="primary"
      indeterminate
    ></v-progress-circular>
    </div>
    <!-- <v-row v-else>
      <v-col cols="4" class="center-item-in-div-column"> -->
        <div v-else class="center-item-in-div-column">
        <div class="center-item-in-div">
            <v-chip class="ma-1 caption overline" small color="white">
                <v-icon left color="#00C853">mdi-circle-medium</v-icon>
                semanal
            </v-chip>
            <v-chip class="ma-1 caption overline" small color="white">
                <v-icon left color="#0091EA">mdi-circle-medium</v-icon>
                quincenal
            </v-chip>
        </div>
        <v-date-picker
          :events="functionEvents"
          locale="es-mx"
          no-title
          multiple
          readonly
          class="rounded-lg"
        ></v-date-picker>
    </div>
      <!-- </v-col>
      <v-col cols="8"> -->
        <!-- <div class="py-5">
            <div v-if="diasParaPrenomina.weekly > 0">
                <strong>{{ diasParaPrenomina.weekly }}</strong> días para la prenómina semanal
            </div>
            <div v-if="diasParaPrenomina.biweekly > 0">
                <strong>{{ diasParaPrenomina.biweekly }}</strong> días para la prenómina quincenal
            </div>
        </div> -->
      <!-- </v-col> -->
    <!-- </v-row> -->
  </v-sheet>
</template>

<script>
import axios from "axios";
import { deleteToken } from "../../../../../../store/Funciones/funciones";

export default {
  name: "calendario-prenomina",
  data() {
    return {
      fechasPrenom: [],
      diasParaPrenomina: {},
      loadingData: true
    };
  },
  async mounted() {
    this.fechasPrenom = await this.getPrenominas();
    this.diasParaPrenomina = await this.getParaPrenominas();
    this.loadingData = false
  },
  methods: {
    functionEvents(date) {
      let day = this.fechasPrenom.filter((d) => {
        return d.fecha == date;
      });
      
      if (day.length > 0) {
        if (day[0].type === "Quincenal") {
          return "#0091EA";
        } else if (day[0].type === "Semanal") {
          return "#00C853";
        }
      }
    },
    getPrenominas() {
      const headers = {
        Authorization: localStorage.getItem("token"),
      };

      return new Promise((res) => {
        axios
          .get(`${this.$store.getters.getHostNode}/api/get-prenominas`, {
            headers,
          })
          .then((response) => {
            if (response.data.status == "EXPSESSION") {
              deleteToken();
            } else {
              res(response.data.data);

              // this.createJSONtoExport();
            }
          })
          .finally(() => {
            this.loadInformation = false;
          });
      });
    },
    getParaPrenominas() {
      const headers = {
        Authorization: localStorage.getItem("token"),
      };

      return new Promise((res) => {
        axios
          .get(`${this.$store.getters.getHostNode}/api/get-dias-para-prenominas`, {
            headers,
          })
          .then((response) => {
            if (response.data.status == "EXPSESSION") {
              deleteToken();
            } else {
              res(response.data.data);

              // this.createJSONtoExport();
            }
          })
          .finally(() => {
            this.loadInformation = false;
          });
      });
    },
  },
};
</script>
