var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"reporte-lista-asistencia"},[_c('v-container',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.loadingInfo),expression:"loadingInfo"}],staticClass:"text-center"},[_c('h2',{staticClass:"text-center blue-grey--text text--lighten-2 mb-5"},[_vm._v("Cargando contenido...")]),_c('v-progress-circular',{attrs:{"color":"primary","indeterminate":"","size":70,"width":7}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loadingInfo),expression:"!loadingInfo"}]},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.cursos,"single-expand":_vm.singleExpand,"expanded":_vm.expanded,"item-key":"id","show-expand":"","loadign":_vm.cargando,"mobile-breakpoint":"0","search":_vm.buscar,"footer-props":{
          'items-per-page-options': [5, 10],
          'items-per-page-text': 'Elementos por página',
        },"no-data-text":"No se encuentran cursos registrados"},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"primary"}},[_c('v-text-field',{attrs:{"flat":"","solo-inverted":"","dark":"","prepend-inner-icon":"mdi-magnify","label":"Buscar","single-line":"","hide-details":"","clearable":"","dense":""},model:{value:(_vm.buscar),callback:function ($$v) {_vm.buscar=$$v},expression:"buscar"}}),_c('v-divider'),_c('vue-json-to-csv',{attrs:{"json-data":_vm.asistentesPorCurso,"csv-title":_vm.nameFileGeneral,"labels":_vm.headers_report_to_export_general}},[_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"color":"teal darken-2","elevation":"3","dark":""},on:{"click":function($event){return _vm.fixNameGeneral()}}},[_vm._v(" Exportar "),_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-file-excel")])],1)],1)],1)],1)]},proxy:true},{key:"expanded-item",fn:function(ref){
        var headers = ref.headers;
        var item = ref.item;
return [_c('td',{staticClass:"px-10 py-5",attrs:{"colspan":headers.length}},[(item.asistentes)?_c('v-row',_vm._l((item.asistentes),function(i){return _c('v-col',{key:i.id,staticClass:"d-flex justify-center align-center",attrs:{"cols":"12","xl":"3","lg":"3","md":"3","sm":"12","xs":"12"}},[_c('v-card',{staticClass:"rounded-xl pa-5 card-effect d-flex justify-center align-center",attrs:{"width":"200","max-height":"60","color":"blue-grey lighten-4","elevation":"2"}},[_c('p',{staticClass:"text-center font-weight-bold"},[_vm._v(" "+_vm._s(i.nombre_integrante)+" ")])])],1)}),1):_c('v-row',{staticClass:"pa-0"},[_c('v-col',{attrs:{"cols":"12","color":"blue-grey lighten-4 pa-0"}},[_c('v-card',[_c('v-card-text',{staticClass:"d-flex justify-center subtitle-1 font-italic"},[_vm._v("No se encuentran cursos registrados")])],1)],1)],1)],1)]}},{key:"item.nombre",fn:function(ref){
        var item = ref.item;
return [_c('div',{attrs:{"align":"left"}},[_vm._v(" "+_vm._s(item.nombre)+" ")])]}},{key:"item.instructor",fn:function(ref){
        var item = ref.item;
return [_c('div',{attrs:{"align":"left"}},[_vm._v(" "+_vm._s(item.instructor)+" ")])]}},{key:"item.duracion",fn:function(ref){
        var item = ref.item;
return [_c('div',{attrs:{"align":"right"}},[_vm._v(" "+_vm._s(item.duracion)+" Min ")])]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('div',{attrs:{"align":"center"}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.exportarExcel(item)}}},[_vm._v(" mdi-file-excel ")])],1)]}}])}),[_c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"persistent":"","scrollable":"","max-width":"400px"},model:{value:(_vm.openDialogExport),callback:function ($$v) {_vm.openDialogExport=$$v},expression:"openDialogExport"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-subtitle-2 primary white--text"},[_vm._v(" Descargar curso "),_c('v-spacer'),_c('v-icon',{attrs:{"small":"","dark":""},on:{"click":function($event){return _vm.closeDialogExport()}}},[_vm._v("mdi-close")])],1),_c('v-card-text',[_vm._v(" Al descargar por curso podrás revisar los asistentes que contenga ")]),_c('v-divider'),_c('v-card-actions',{staticClass:"justify-end"},[_c('vue-json-to-csv',{attrs:{"json-data":_vm.saveItem,"csv-title":_vm.nameFile,"labels":_vm.headers_report_to_export}},[_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"color":"teal darken-2","elevation":"3","dark":"","small":""}},[_vm._v(" Exportar "),_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-file-excel")])],1)],1)],1)],1)],1)],1)],1)]],2)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }