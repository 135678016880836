<template>
  <v-container>
    <div class="text-right">
      <v-btn
        class="mb-4"
        depressed
        outlined
        rounded
        color="teal"
        small
        @click="openEdicion(1)"
      >
        <v-icon left> mdi-plus-circle-outline </v-icon>
        agregar documento
      </v-btn>
    </div>
    <v-data-table
      dense
      :loading="loadingContent"
      :headers="headers"
      :items="tiposDocs"
      sort-by-text="Ordenar por"
      no-data-text="No hay registros."
      items-per-page-all-text="Todos"
      items-per-page-text="Elementos por página"
      :show-current-page="true"
      :show-first-last-page="true"
      loading-text="Cargando contenido..."
      :footer-props="{
        'items-per-page-options': [5, 10, 15, 20, 25],
        'items-per-page-text': 'Elementos por página',
      }"
    >
      <template v-slot:item.documento="{ item }">
        <div align="left">{{ item.documento }}</div>
      </template>
      <template v-slot:item.usrModifica="{ item }">
        <div align="left">{{ item.usrModifica }}</div>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip top color="cyan lighten-4">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              color="cyan darken-4"
              v-bind="attrs"
              v-on="on"
              @click="openEdicion(2, item)"
            >
              mdi-pencil-outline
            </v-icon>
          </template>
          <label class="text-uppercase font-weight-bold primary--text"
            >Editar tipo documento</label
          >
        </v-tooltip>
        <v-tooltip top color="pink lighten-4">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              color="pink darken-4"
              v-bind="attrs"
              v-on="on"
              @click="editaItem(item)"
            >
              mdi-delete-outline
            </v-icon>
          </template>
          <label class="text-uppercase font-weight-bold primary--text"
            >Remover tipo documento</label
          >
        </v-tooltip>
      </template>
    </v-data-table>

    <ModificaTiposDocumentos
      v-if="openEdit"
      :openNuevoTipoDocumento="openEdit"
      :opcion="opcionEdit"
      :item="itemEdit"
      @cierraVentana="cierraEdit"
    />

    <SnackBarError
      :muestraBarra="muestraMensaje"
      :texto="textoMensaje"
      :color="colorMensaje"
      @cierraBarra="muestraMensaje = false"
    />
  </v-container>
</template>

<script>
import axios from "axios";
import { deleteToken } from "../../../../../store/Funciones/funciones";
import ModificaTiposDocumentos from "./ModificaTiposDocumentos.vue";
import SnackBarError from "../../../General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue";
export default {
  name: "adm-tipos-documentos",
  components: { ModificaTiposDocumentos, SnackBarError },
  props: [],
  data() {
    return {
      tiposDocs: [],
      loadingContent: true,
      openEdit: false,
      muestraMensaje: false,
      colorMensaje: "",
      textoMensaje: "",
      opcionEdit: 0,
      itemEdit: {},
      headers: [
        {
          text: "ID",
          align: "center",
          sorteable: false,
          value: "id",
        },
        {
          text: "Documento",
          align: "center",
          sorteable: false,
          value: "documento",
        },
        {
          text: "Emp. que modificó",
          align: "center",
          sorteable: false,
          value: "usrModifica",
        },
        {
          text: "Solicitado para ",
          align: "center",
          sorteable: false,
          value: "tipoDocumento",
        },
        {
          text: "Fecha de edición",
          align: "center",
          sorteable: false,
          value: "fchModifica",
        },
        {
          text: "Acciones",
          align: "center",
          sorteable: false,
          value: "actions",
        },
      ],
    };
  },
  computed: {},
  mounted() {
    this.getTipos();
  },
  methods: {
    getTipos() {
      let params = {
        Opcion: 4,
        token: localStorage.getItem("token"),
      };
      axios
        .post(
          `${this.$store.getters.getHost}/RelacionesLaborales/DocumentacionEmpleados/ws-carga-documentos.php`,
          params
        )
        .then((response) => {
          if (response.data.status == "EXPRESSION") {
            deleteToken();
          } else if (response.data.status == "OK") {
            this.tiposDocs = response.data.tipos;
            this.loadingContent = false;
          }
        });
    },

    buscar() {
      console.log("hola1");
      this.tiposDocs = [];
      this.loadingContent = true;
      this.getTipos();
    },

    editaItem(item) {
      console.log(item);
    },
    openEdicion(opc, item = {}) {
      this.opcionEdit = opc;
      this.itemEdit = item;
      console.log(this.itemEdit);
      this.openEdit = true;
    },
    cierraEdit(esAdd, mensaje) {
      this.openEdit = false;
      this.textoMensaje = mensaje;
      if (mensaje != "") {
        this.muestraMensaje = true;
      }
      if (esAdd) {
        this.colorMensaje = "success";
        this.muestraMensaje = true;
        this.tiposDocs.length = 0;
        this.getTipos();
      } else {
        this.colorMensaje = "pink darken-2";
        this.muestraMensaje = true;
      }
    },
  },
};
</script>
