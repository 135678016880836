<template>
  <v-container fluid>
    <v-card v-if="loading">
      <h2 class="text-center blue-grey--text text--lighten-2 mb-5">
        Cargando contenido...
      </h2>
      <div class="mx-auto" align="center">
        <v-progress-circular
          color="primary"
          indeterminate
          :size="70"
          :width="7"
        ></v-progress-circular>
      </div>
    </v-card>
    <v-card v-else>
      <v-card-text>
        <v-autocomplete
          v-model="area"
          :items="areas"
          label="Selecciona un area"
          hide-selected
          outlined
          dense
          item-value="id"
          item-text="Area"
          color="cyan"
          clearable
          v-on:change="getDepartamentos"
        >
        </v-autocomplete>
        <v-autocomplete
          v-model="depto"
          :items="departamentos"
          :loading="loadingInfo"
          label="Selecciona un departamento"
          hide-selected
          outlined
          dense
          item-value="id"
          item-text="depto"
          color="cyan"
          clearable
        >
        </v-autocomplete>
      </v-card-text>
      <v-card-actions>
        <v-btn
          v-if="area && depto"
          class="rounded-lg elevation-0"
          color="teal darken-1"
          :loading="loading"
          @click="handleConsultar"
          dark
          block
          small
        >
          <v-icon left>mdi-stairs</v-icon> Consultar
        </v-btn>
        <v-btn class="rounded-lg elevation-0" v-else disabled block>
          <v-icon left>mdi-stairs</v-icon> Consultar
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-card class="mt-5" v-if="validar && area && depto">
      <familias-escaleras-crecimiento
        ref="familiasComponent"
        :item="area"
        :depto="depto"
      ></familias-escaleras-crecimiento>
    </v-card>
    <!-- <v-card v-else class="rounded-xl" elevation="5">
      <div v-if="moduloError">
        <mantenimiento-modulo></mantenimiento-modulo>
      </div>
      <div v-else>
        <v-toolbar flat color="cyan" dark>
          <v-toolbar-title>Escaleras de crecimiento</v-toolbar-title>
        </v-toolbar>
        <v-tabs
          v-model="tab"
          v-if="areas.length > 0"
          background-color="cyan"
          dark
          next-icon="mdi-arrow-right-bold-box-outline"
          prev-icon="mdi-arrow-left-bold-box-outline"
          show-arrows
        >
          <v-tab
            :key="index"
            v-for="(i, index) in areas"
            class="d-flex justify-start"
          >
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                  <v-icon left> mdi-office-building </v-icon>
                  {{ i.Area }}
                </div>
              </template>
              <span> {{ i.Area }}</span>
            </v-tooltip>
          </v-tab>

          <v-tab-item :key="index" v-for="(i, index) in areas">
            <v-card flat>
              <familias-escaleras-crecimiento
                :item="areas[tab]"
                :tab="tab"
              ></familias-escaleras-crecimiento>
            </v-card>
          </v-tab-item>
        </v-tabs>
      </div>
    </v-card> -->
  </v-container>
</template>

<script>
import axios from "axios";
import { deleteToken } from "../../../../../../store/Funciones/funciones";
import FamiliasEscalerasCrecimiento from "./FamiliasEscalerasCrecimiento.vue";
// import MantenimientoModulo from "../../../../../../components/MantenimientoModulo.vue";
export default {
  name: "areas-escaleras-crecimiento",
  props: [],
  components: {
    FamiliasEscalerasCrecimiento,
    // MantenimientoModulo,
  },
  data() {
    return {
      textoBarra: "",
      colorBarra: "",
      muestraMensaje: false,

      tab: null,
      areas: [],
      loading: true,
      moduloError: false,
      area: "",
      depto: "",
      departamentos: [],
      estatus: "",
      loadingInfo: false,
      validar: false,
    };
  },
  created() {},
  mounted() {
    this.getAreas();
  },
  methods: {
    getAreas() {
      const headers = { Authorization: localStorage.getItem("token") };

      return new Promise((res) => {
        axios
          .get(`${this.$store.getters.getHostNode}/api/get-areas`, {
            headers: headers,
          })
          .then((response) => {
            if (response.data.status == "OK") {
              res(response.data.data);
              this.areas = response.data.data;
              this.estatus = response.data.status;
            } else if (
              response.data.status == "ERROR" &&
              !response.data.status
            ) {
              this.estatus = response.data.status;
              this.moduloError = true;
            } else if (response.data.status == "EXPRESSION") {
              deleteToken();
            } else {
              res([]);
            }
          })
          .finally(() => {
            this.loadingInfo = false;
            this.loading = false;
          });
      });
    },
    getDepartamentos() {
      this.depto = "";
      this.loadingInfo = true;
      const headers = { Authorization: localStorage.getItem("token") };
      return new Promise((res) => {
        axios
          .get(
            `${this.$store.getters.getHostNode}/api/get-departamentos-zentric/${this.area}`,
            { headers }
          )
          .then((response) => {
            if (response.data.status == "OK") {
              res(response.data.data);
              this.departamentos = response.data.data;
            } else if (response.data.status == "EXPRESSION") {
              deleteToken();
            } else {
              res([]);
            }
          })
          .finally(() => {
            this.loadingInfo = false;
          });
      });
    },
     handleConsultar() {
      this.validar = true;
      // Verifica si el componente ya está montado
      this.$nextTick(() => {
        this.$refs.familiasComponent.getNiveles();
      });
    },
  },
};
</script>
