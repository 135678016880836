<template>
  <v-card elevation="1" class="rounded-lg">
    <v-card-text>
      <div v-if="loadInformation">
        <v-row class="fill-height" align-content="center" justify="center">
          <v-col class="text-subtitle-1 text-center" cols="12">
            Obteniendo información
          </v-col>
          <v-col cols="6">
            <v-progress-linear
              color="blue darken-4"
              indeterminate
              rounded
              height="6"
            ></v-progress-linear>
          </v-col>
        </v-row>
      </div>
      <div v-else>
        {{ fecha_inicio }} {{ fecha_fin }}
        <div
          v-show="report_to_export.length > 0"
          style="display: flex; justify-content: right"
        >
          <vue-json-to-csv
            :json-data="report_to_export"
            :labels="headers_report_to_export"
            csv-title="reporteDeAsistencias"
          >
            <v-btn small elevation="2" color="success" :loading="loadingReport">
              Exportar
              <v-icon right small> mdi-microsoft-excel </v-icon>
            </v-btn>
          </vue-json-to-csv>
        </div>
        <div v-for="semana in apiAsistencias" :key="semana.week">
          <!-- {{ formattedItems(semana.data) }} -->
          <v-row>
            <v-col cols="4" style="display: flex; align-items: center">
              <h2 style="font-weight: bold">Semana: {{ semana.week }}.</h2>
            </v-col>
            <v-col cols="8">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Buscar..."
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" style="display: flex; align-items: center">
              <h3 class="text-left" style="margin-top: -51px;">
              </h3>
            </v-col>
          </v-row>

          <v-data-table
            :headers="headers"
            :items="semana.data"
            :search="search"
            :items-per-page="itemsPerPage"
            :single-expand="singleExpand"
            :expanded.sync="expanded"
            item-key="emplid"
            show-expand
            :footer-props="{
              'items-per-page-options': [5, 10],
              'items-per-page-text': 'Elementos por página',
            }"
            no-data-text="No hay registros"
            :header-props="{
              'short-by-text': 'Ordenar por',
            }"
            class="elevation-1"
            dense
          >
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length" v-if="item.isLead === 1">
                <!-- <v-sheet> -->
                <reporte-asistencias
                  :folio="item.emplid"
                  :fecha_inicio="fecha_inicio"
                  :fecha_fin="fecha_fin"
                ></reporte-asistencias>
                <!-- </v-sheet> -->
              </td>
              <td :colspan="headers.length" v-else>
                No hay información disponible
              </td>
            </template>

            <template v-slot:[`item.emplid`]="{ item }">
              {{ item.emplid }}
            </template>

            <template v-slot:[`item.name`]="{ item }">
              <div v-if="item.isLead == 0">
                <p>{{ item.name }}</p>
              </div>
              <div v-else>
                <p class="my-1">
                  {{ item.name }}
                  <v-tooltip top color="primary">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon right color="primary" v-bind="attrs" v-on="on">
                        mdi-account-multiple
                      </v-icon>
                    </template>
                    <span>Líder de equipo</span>
                  </v-tooltip>
                </p>
              </div>
            </template>
            <template v-slot:[`item.monday`]="{ item }">
              <!-- {{item.asistencias.monday}} -->
              <reporte-asistencias-informacion-de-dia
                :InfoDay="item.asistencias.monday"
              />
            </template>
            <template v-slot:[`item.tuesday`]="{ item }">
              <reporte-asistencias-informacion-de-dia
                :InfoDay="item.asistencias.tuesday"
              />
            </template>
            <template v-slot:[`item.wednesday`]="{ item }">
              <reporte-asistencias-informacion-de-dia
                :InfoDay="item.asistencias.wednesday"
              />
            </template>
            <template v-slot:[`item.thursday`]="{ item }">
              <reporte-asistencias-informacion-de-dia
                :InfoDay="item.asistencias.thursday"
              />
            </template>
            <template v-slot:[`item.friday`]="{ item }">
              <reporte-asistencias-informacion-de-dia
                :InfoDay="item.asistencias.friday"
              />
            </template>
            <template v-slot:[`item.saturday`]="{ item }">
              <reporte-asistencias-informacion-de-dia
                :InfoDay="item.asistencias.saturday"
              />
            </template>
            <template v-slot:[`item.sunday`]="{ item }">
              <reporte-asistencias-informacion-de-dia
                :InfoDay="item.asistencias.sunday"
              />
            </template>
            <template v-slot:[`item.faltas`]="{ item }">
              {{ item.faltas }}
            </template>
            <template v-slot:[`item.retardos`]="{ item }">
              {{ item.retardos }}
            </template>
          </v-data-table>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import axios from "axios";
import VueJsonToCsv from "vue-json-to-csv";
import ReporteAsistencias from "./ReporteAsistencias.vue";
import ReporteAsistenciasInformacionDeDia from "./ReporteAsistenciasInformacionDeDia.vue";
import { formatDateISO } from "../../store/Funciones/funciones";
import { deleteToken } from "../../store/Funciones/funciones";

export default {
  name: "reporte-asistencias",
  data: () => ({
    search: "",
    // headers: [],
    apiAsistencias: [],
    page: 1,
    pageCount: 0,
    itemsPerPage: 5,
    expanded: [],
    singleExpand: false,
    loadInformation: true,
    loadingReport: true,
    report_to_export: [],
    headers: [
      {
        text: "# empleado",
        align: "start",
        value: "emplid",
      },
      {
        text: "Nombre",
        value: "name",
      },
      {
        text: "Lunes",
        value: "monday",
      },
      {
        text: "Martes",
        value: "tuesday",
      },
      {
        text: "Miércoles",
        value: "wednesday",
      },
      {
        text: "Jueves",
        value: "thursday",
      },
      {
        text: "Viernes",
        value: "friday",
      },
      {
        text: "Sábado",
        value: "saturday",
      },
      {
        text: "Domingo",
        value: "sunday",
      },
      {
        text: "Faltas",
        value: "faltas",
      },
      {
        text: "Retardos",
        value: "retardos",
      },
    ],
    headers_report_to_export: {
      semana: { title: "Semana" },
      NumEmplid: { title: "Empleado" },
      Nombre: { title: "Nombre completo" },
      Responsable: { title: "Responsable" },
      Turno: { title: "Turno" },
      Lunes: { title: "Lunes" },
      Martes: { title: "Martes" },
      Miercoles: { title: "Miércoles" },
      Jueves: { title: "Jueves" },
      Viernes: { title: "Viernes" },
      Sabado: { title: "Sábado" },
      Domingo: { title: "Domingo" },
      Faltas: { title: "Faltas" },
      Retardos: { title: "Retardos" },
      // Incapacidades: { title: "Incapacidades" },
    },
  }),
  components: {
    ReporteAsistencias,
    ReporteAsistenciasInformacionDeDia,
    VueJsonToCsv,
  },
  props: ["folio", "fecha_inicio", "fecha_fin"],
  async mounted() {
    // console.log(this.fecha_inicio);
    // this.headers = await this.defineHeaders();
    this.apiAsistencias = await this.obtenAsistencias();
    this.createJSONtoExport();
  },
  methods: {
    findLastDate(item) {
      if (item.Domingo.fecha == null) {
        if (item.Sabado.fecha != null) {
          return item.Sabado.fecha;
        } else {
          return item.Viernes.fecha;
        }
      } else {
        return item.Domingo.fecha;
      }
    },
    obtenAsistencias() {
      const headers = {
        Authorization: localStorage.getItem("token"),
      };

      return new Promise((res) => {
        axios
          .get(
            `${this.$store.getters.getHostNode}/api/get-reporte-asistencia-adm/${this.folio}/${this.fecha_inicio}/${this.fecha_fin}/2`,
            { headers }
          )
          .then((response) => {
            if (response.data.status == "EXPSESSION") {
              deleteToken();
            } else {
              res(response.data.data);

              // this.createJSONtoExport();
            }
          })
          .finally(() => {
            this.loadInformation = false;
          });
      });
    },
    setFecha(fecha) {
      return formatDateISO(fecha);
    },
    async createJSONtoExport() {
      const promises = this.apiAsistencias.map((semana) => {
        semana.data.map((asistencia) => {
          let json_to_insert = {
            semana: semana.week,
            NumEmplid: asistencia.emplid,
            Nombre: asistencia.name,
            Responsable: asistencia.lead,
            Turno: asistencia.workShift,
            Lunes: this.getStatusExport(asistencia.asistencias.monday),
            Martes: this.getStatusExport(asistencia.asistencias.tuesday),
            Miercoles: this.getStatusExport(asistencia.asistencias.wednesday),
            Jueves: this.getStatusExport(asistencia.asistencias.thursday),
            Viernes: this.getStatusExport(asistencia.asistencias.friday),
            Sabado: this.getStatusExport(asistencia.asistencias.saturday),
            Domingo: this.getStatusExport(asistencia.asistencias.sunday),
            Faltas: asistencia.faltas,
            Retardos: asistencia.retardos,
            // Incapacidades: asistencia.incapacidades,
          };
          this.report_to_export.push(json_to_insert);
        });
      });

      await Promise.all(promises);
      this.loadingReport = false;
    },
    getStatusExport(item) {
      if (item.falta === 1) {
        return "falta";
      } else {
        if (Object.keys(item.incidencia).length > 0) {
          return item.incidencia.details != ""
            ? item.incidencia.desc + ": " + item.incidencia.details
            : item.incidencia.desc;
        } else if (!item.labora) {
          return "descansa";
        } else {
          return item.ingreso + " - " + item.salida;
        }
      }
    },

    setFormatDate(date) {
      let fecha = new Date(date);

      let diaUTC = fecha.getUTCDate();
      let mesUTC = fecha.getUTCMonth() + 1;
      let anioUTC = fecha.getUTCFullYear();

      diaUTC = diaUTC < 10 ? "0" + diaUTC : diaUTC;
      mesUTC = mesUTC < 10 ? "0" + mesUTC : mesUTC;

      return anioUTC + "-" + mesUTC + "-" + diaUTC;
    },
  },
};
</script>
