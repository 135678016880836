import axios from 'axios'
// import { mapState } from "vuex";
// import { mapActions } from "vuex";
import SimuladorMuestraComparacion from '../SimuladorPrestaciones/components/SimuladorMuestraComparacion.vue'
import SnackBarErrorIncidencias from '../../MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue'
import {
    deleteToken
} from '../../../../../store/Funciones/funciones'

export default {
    name: 'simulador-prestaciones',
    components: {
        SimuladorMuestraComparacion,
        SnackBarErrorIncidencias
    },
    props: [],
    data() {
        return {
            dataEmpleado: [{
                    id: 1,
                    name: "Sueldo Diario",
                    valor: 0
                },
                {
                    id: 2,
                    name: "Aguinaldo",
                    valor: 0
                },
                {
                    id: 3,
                    name: "Vacaciones",
                    valor: 0
                },
                {
                    id: 4,
                    name: "Prima Vacacional",
                    valor: 0
                },
                {
                    id: 5,
                    name: "Gratificación 3er turno",
                    valor: 0
                },
                {
                    id: 6,
                    name: "Vales de despensa",
                    valor: 0
                },
                {
                    id: 7,
                    name: "Fondo de ahorro",
                    valor: 0
                },
                {
                    id: 8,
                    name: "Premio de asistencia",
                    valor: 0
                },
                {
                    id: 9,
                    name: "Bono de productividad",
                    valor: 0
                },
                {
                    id: 10,
                    name: "Bono turno especial",
                    valor: 0
                },
                {
                    id: 11,
                    name: "Premio asistencia anual",
                    valor: 0
                },
                {
                    id: 12,
                    name: "Ayuda para lentes",
                    valor: "65% del total"
                },
                {
                    id: 13,
                    name: "Días festivos",
                    valor: "3 por año"
                },
                {
                    id: 14,
                    name: "Sueldo Diario Más Prestaciones",
                    valor: 0
                },
                {
                    id: 15,
                    name: "Diferencia",
                    valor: 0
                },
                {
                    id: 16,
                    name: "Incremento",
                    valor: 0
                }
            ],
            datosSueldo: [],
            dataComparacion: [],
            prestacionesExtraIngresadas: [],
            periodos: ['Diario', 'Semanal', 'Mensual'],
            periodosAguinaldo: ['Días', 'Monto anual'],
            tiposVales: ['Porcentual', 'Monto en efectivo'],
            loadingButton: false,
            muestraComparacion: false,
            otrasPrestaciones: false,
            cargandoInfoSueldo: false,
            valid: true,
            segundaOfertaExtra: false,
            terceraOfertaExtra: false,
            cargandoComparacion: false,
            nombreEmpresa: "",
            prestacionesExtra: "",
            selectAguinaldo: "",
            selectTipoVales: "",
            selectTipoFondo: "",
            placeholderAguinaldo: "",
            prefixAguinaldo: "",
            placeholderVales: "",
            placeholderFondo: "",
            prefixVales: "",
            prefixFondo: "",
            periodoSueldo: "",
            periodoVales: "",
            periodoFondo: "",
            periodoBonos: "",
            sueldoOferta: null,
            aguinaldoOferta: null,
            vacacionesOferta: null,
            primaOferta: null,
            valesOferta: null,
            fondoOferta: null,
            bonosOferta: null,

            sueldoOfertaEnviado: 0,
            aguinaldoOfertaEnviado: 0,
            valesOfertaEnviado: 0,
            fondoOfertaEnviado: 0,
            periodoBonosEnviado: 0,

            selectPeriodoPrestacionExtraUno: "",
            selectPeriodoPrestacionExtraDos: "",
            selectPeriodoPrestacionExtraTres: "",

            selectTipoPrestacionExtraUno: "",
            selectTipoPrestacionExtraDos: "",
            selectTipoPrestacionExtraTres: "",

            valorPrestacionExtraUno: null,
            valorPrestacionExtraDos: null,
            valorPrestacionExtraTres: null,

            placeholderPrestacionExtraUno: "",
            placeholderPrestacionExtraDos: "",
            placeholderPrestacionExtraTres: "",
            prefixPrestacionExtraUno: "",
            prefixPrestacionExtraDos: "",
            prefixPrestacionExtraTres: "",
            prestacionesExtraDos: "",
            prestacionesExtraTres: "",

            conocesPrestacionAguinaldo: false,
            conocesPrestacionDiasVacaciones: false,
            conocesPrestacionPrimaVacaciones: false,
            conocesPrestacionVales: false,
            conocesPrestacionFondoAhorro: false,
            conocesPrestacionBonos: false,
            // reglas de campos
            campoRequerido: [
                v => !!v || 'Este campo es requerido',
            ],

            muestraBarra: false,
            textoBarra: "",
            colorBarra: ""

        }
    },
    created() {
        this.getDataEmplid();
    },
    computed: {

    },
    mounted() {
        //  window.document.title = "DH:URREA - Simulador de prestaciones.";
        this.saveMonitoreo();
    },
    methods: {
        cambiaTextFieldVales() {
            if (this.selectTipoVales == this.tiposVales[0]) {
                this.placeholderVales = "Ejemplo: 5"
                this.prefixVales = "%"
            } else if (this.selectTipoVales == this.tiposVales[1]) {
                this.placeholderVales = "Ejemplo: 300"
                this.prefixVales = "$"
            } else {
                this.placeholderVales = ""
                this.prefixVales = ""
            }
        },
        cambiaTextFieldFondo() {
            if (this.selectTipoFondo == this.tiposVales[0]) {
                this.placeholderFondo = "Ejemplo: 5"
                this.prefixFondo = "%"
            } else if (this.selectTipoFondo == this.tiposVales[1]) {
                this.placeholderFondo = "Ejemplo: 300"
                this.prefixFondo = "$"
            } else {
                this.placeholderFondo = ""
                this.prefixFondo = ""
            }
        },
        cambiaTextFieldAguinaldo() {
            if (this.selectAguinaldo == "Días") {
                this.placeholderAguinaldo = "Ejemplo: 15"
                this.prefixAguinaldo = ""
            } else if (this.selectAguinaldo == "Monto anual") {
                this.placeholderAguinaldo = "Ejemplo: 1200"
                this.prefixAguinaldo = "$"
            } else {
                this.placeholderAguinaldo = ""
                this.prefixAguinaldo = ""
            }
        },
        cambiaTextFieldPrestacionExtraUno() {
            if (this.selectTipoPrestacionExtraUno == this.tiposVales[0]) {
                this.placeholderPrestacionExtraUno = "Ejemplo: 5"
                this.prefixPrestacionExtraUno = "%"
            } else if (this.selectTipoPrestacionExtraUno == this.tiposVales[1]) {
                this.placeholderPrestacionExtraUno = "Ejemplo: 300"
                this.prefixPrestacionExtraUno = "$"
            } else {
                this.placeholderPrestacionExtraUno = ""
                this.prefixPrestacionExtraUno = ""
            }
        },
        cambiaTextFieldPrestacionExtraDos() {
            if (this.selectTipoPrestacionExtraDos == this.tiposVales[0]) {
                this.placeholderPrestacionExtraDos = "Ejemplo: 5"
                this.prefixPrestacionExtraDos = "%"
            } else if (this.selectTipoPrestacionExtraDos == this.tiposVales[1]) {
                this.placeholderPrestacionExtraDos = "Ejemplo: 300"
                this.prefixPrestacionExtraDos = "$"
            } else {
                this.placeholderPrestacionExtraDos = ""
                this.prefixPrestacionExtraDos = ""
            }
        },
        cambiaTextFieldPrestacionExtraTres() {
            if (this.selectTipoPrestacionExtraTres == this.tiposVales[0]) {
                this.placeholderPrestacionExtraTres = "Ejemplo: 5"
                this.prefixPrestacionExtraTres = "%"
            } else if (this.selectTipoPrestacionExtraTres == this.tiposVales[1]) {
                this.placeholderPrestacionExtraTres = "Ejemplo: 300"
                this.prefixPrestacionExtraTres = "$"
            } else {
                this.placeholderPrestacionExtraTres = ""
                this.prefixPrestacionExtraTres = ""
            }
        },
        saveMonitoreo() {
            let params = {
                Opcion: 10,
                pantalla: "Simulador de sueldo",
                token: localStorage.getItem('token')
            }
            axios
                .post(`${this.$store.getters.getHost}/General/ws-general.php`, params)
                .then((response) => {
                    console.log(response.data)
                })
        },
        getDataEmplid() {
            let params = {
                Opcion: 1,
                token: localStorage.getItem('token')
            }
            axios
                .post(`${this.$store.getters.getHost}/Compensaciones/ws-simulador-de-prestaciones.php`, params)
                .then((response) => {
                    this.cargandoInfoSueldo = true;
                    if (response.data.status == "EXPSESSION") {
                        deleteToken();
                    } else {
                        var data = response.data.datosEmpleado;
                        // console.log(response)
                        this.datosSueldo = data;
                        this.dataEmpleado[0].valor = this.datosSueldo.sueldoDiario
                        this.dataEmpleado[1].valor = this.datosSueldo.aguinaldo
                        this.dataEmpleado[2].valor = this.datosSueldo.vacaciones
                        this.dataEmpleado[3].valor = this.datosSueldo.Prima_vacacional
                        this.dataEmpleado[4].valor = this.datosSueldo.gratificacion_tercer_turno
                        this.dataEmpleado[5].valor = this.datosSueldo.vales_despensa
                        this.dataEmpleado[6].valor = this.datosSueldo.fondo_ahorro
                        this.dataEmpleado[7].valor = this.datosSueldo.premio_asistencia
                        this.dataEmpleado[8].valor = this.datosSueldo.bono_productividad
                        this.dataEmpleado[9].valor = this.datosSueldo.bono_turno_especial
                        this.dataEmpleado[10].valor = this.datosSueldo.premio_asistencia_anual
                        this.dataEmpleado[13].valor = this.datosSueldo.sueldo_diario_integrado
                        this.dataEmpleado[14].valor = this.datosSueldo.diferencia
                        this.dataEmpleado[15].valor = this.datosSueldo.incremento
                            // console.log(this.dataEmpleado);
                    }

                })
        },
        limpiaVariables() {
            this.sueldoOferta = null;
            this.aguinaldoOferta = null;
            this.vacacionesOferta = null;
            this.primaOferta = null;
            this.valesOferta = null;
            this.fondoOferta = null;
            this.bonosOferta = null;
        },
        /* Converting the values of the fields to a common denominator. */
        convierteCampos() {
            if (this.periodoSueldo == this.periodos[0]) {
                this.sueldoOfertaEnviado = ((parseFloat(this.sueldoOferta) * 365) / 52.1429).toFixed(2);
            } else if (this.periodoSueldo == this.periodos[2]) {
                this.sueldoOfertaEnviado = ((parseFloat(this.sueldoOferta) * 12) / 52.1429).toFixed(2);
            } else {
                this.sueldoOfertaEnviado = parseFloat(this.sueldoOferta).toFixed(2);
            }

            if (!this.conocesPrestacionAguinaldo) {
                if (this.selectAguinaldo == this.periodosAguinaldo[0]) {
                    this.aguinaldoOfertaEnviado = parseFloat(this.aguinaldoOferta);
                } else if (this.selectAguinaldo == this.periodosAguinaldo[1]) {
                    this.aguinaldoOfertaEnviado = (parseFloat(this.aguinaldoOferta) / this.sueldoOfertaEnviado).toFixed(0);
                }
            } else {
                this.aguinaldoOfertaEnviado = 15;
            }

            if (!this.conocesPrestacionVales) {
                if (this.selectTipoVales == this.tiposVales[0]) {
                    this.valesOfertaEnviado = parseFloat(this.valesOferta);
                } else if (this.selectTipoVales == this.tiposVales[1]) {
                    if (this.periodoVales == this.periodos[0]) {
                        if (this.periodoSueldo == this.periodos[0]) {
                            this.valesOfertaEnviado = parseFloat(this.valesOferta) / parseFloat(this.sueldoOferta);
                        } else if (this.periodoSueldo == this.periodos[1]) {
                            this.valesOfertaEnviado = parseFloat(this.valesOferta) / (parseFloat(this.sueldoOferta) / 7);
                        } else if (this.periodoSueldo == this.periodos[2]) {
                            this.valesOfertaEnviado = parseFloat(this.valesOferta) / ((parseFloat(this.sueldoOferta) * 12) / 365);
                        }
                    } else if (this.periodoVales == this.periodos[1]) {
                        // console.log("valor: " + this.periodoVales + " " + this.periodoSueldo)
                        if (this.periodoSueldo == this.periodos[0]) {
                            this.valesOfertaEnviado = (parseFloat(this.valesOferta) / 7) / parseFloat(this.sueldoOferta);
                            // console.log("operacion: (" + this.valesOferta + "/ 7) / " + parseFloat(this.sueldoOferta))
                        } else if (this.periodoSueldo == this.periodos[1]) {
                            this.valesOfertaEnviado = parseFloat(this.valesOferta) / parseFloat(this.sueldoOferta);
                        } else if (this.periodoSueldo == this.periodos[2]) {
                            this.valesOfertaEnviado = parseFloat(this.valesOferta) / ((parseFloat(this.sueldoOferta) * 12) / 52.1429);
                        }
                    } else if (this.periodoVales == this.periodos[2]) {
                        if (this.periodoSueldo == this.periodos[0]) {
                            this.valesOfertaEnviado = parseFloat(this.valesOferta) / ((parseFloat(this.sueldoOferta) * 365) / 12);
                        } else if (this.periodoSueldo == this.periodos[1]) {
                            this.valesOfertaEnviado = parseFloat(this.valesOferta) / ((parseFloat(this.sueldoOferta) * 52.1429) / 12);
                        } else if (this.periodoSueldo == this.periodos[2]) {
                            this.valesOfertaEnviado = parseFloat(this.valesOferta) / parseFloat(this.sueldoOferta);
                        }
                    }

                    this.valesOfertaEnviado = (this.valesOfertaEnviado * 100).toFixed(2)
                }
            } else {
                this.valesOfertaEnviado = 0;
            }

            if (!this.conocesPrestacionFondoAhorro) {
                if (this.selectTipoFondo == this.tiposVales[0]) {
                    this.fondoOfertaEnviado = parseFloat(this.fondoOferta);
                } else if (this.selectTipoFondo == this.tiposVales[1]) {
                    if (this.periodoFondo == this.periodos[0]) {
                        if (this.periodoSueldo == this.periodos[0]) {
                            this.fondoOfertaEnviado = parseFloat(this.fondoOferta) / parseFloat(this.sueldoOferta);
                        } else if (this.periodoSueldo == this.periodos[1]) {
                            this.fondoOfertaEnviado = parseFloat(this.fondoOferta) / (parseFloat(this.sueldoOferta) / 7);
                        } else if (this.periodoSueldo == this.periodos[2]) {
                            this.fondoOfertaEnviado = parseFloat(this.fondoOferta) / ((parseFloat(this.sueldoOferta) * 12) / 365);
                        }
                    } else if (this.periodoFondo == this.periodos[1]) {
                        // console.log("valor: " + this.periodoFondo + " " + this.periodoSueldo)
                        if (this.periodoSueldo == this.periodos[0]) {
                            this.fondoOfertaEnviado = (parseFloat(this.fondoOferta) / 7) / parseFloat(this.sueldoOferta);
                            // console.log("operacion: (" + this.fondoOferta + "/ 7) / " + parseFloat(this.sueldoOferta))
                        } else if (this.periodoSueldo == this.periodos[1]) {
                            this.fondoOfertaEnviado = parseFloat(this.fondoOferta) / parseFloat(this.sueldoOferta);
                        } else if (this.periodoSueldo == this.periodos[2]) {
                            this.fondoOfertaEnviado = parseFloat(this.fondoOferta) / ((parseFloat(this.sueldoOferta) * 12) / 52.1429);
                        }
                    } else if (this.periodoFondo == this.periodos[2]) {
                        if (this.periodoSueldo == this.periodos[0]) {
                            this.fondoOfertaEnviado = parseFloat(this.fondoOferta) / ((parseFloat(this.sueldoOferta) * 365) / 12);
                        } else if (this.periodoSueldo == this.periodos[1]) {
                            this.fondoOfertaEnviado = parseFloat(this.fondoOferta) / ((parseFloat(this.sueldoOferta) * 52.1429) / 12);
                        } else if (this.periodoSueldo == this.periodos[2]) {
                            this.fondoOfertaEnviado = parseFloat(this.fondoOferta) / parseFloat(this.sueldoOferta);
                        }
                    }

                    this.fondoOfertaEnviado = (this.fondoOfertaEnviado * 100).toFixed(2)
                }
            } else {
                this.fondoOfertaEnviado = 0;
            }

            if (!this.conocesPrestacionBonos) {
                if (this.periodoBonos == this.periodos[0]) {
                    this.bonosOfertaEnviado = (parseFloat(this.bonosOferta) * 7).toFixed(2);
                } else if (this.periodoBonos == this.periodos[1]) {
                    this.bonosOfertaEnviado = (parseFloat(this.bonosOferta)).toFixed(2);
                } else if (this.periodoBonos == this.periodos[2]) {
                    this.bonosOfertaEnviado = ((parseFloat(this.bonosOferta) * 12) / 52.1429).toFixed(2);
                }
            } else {
                this.bonosOfertaEnviado = 0;
            }

            this.saveData();
        },
        validaCampos() {
            if (
                this.nombreEmpresa != "" &&
                this.sueldoOferta != null &&
                (this.aguinaldoOferta != null || this.conocesPrestacionAguinaldo) &&
                (this.vacacionesOferta != null || this.conocesPrestacionDiasVacaciones) &&
                (this.primaOferta != null || this.conocesPrestacionPrimaVacaciones) &&
                (this.valesOferta != null || this.conocesPrestacionVales) &&
                (this.fondoOferta != null || this.conocesPrestacionFondoAhorro) &&
                (this.bonosOferta != null || this.conocesPrestacionBonos)
            ) {
                this.cargandoComparacion = true;
                this.convierteCampos();
            } else {
                alert("error")
                this.textoBarra = "Es necesario que llenes los campos no marcados como desconocidos.";
                this.colorBarra = "error";
                this.muestraBarra = true;
            }
        },
        saveData() {

            if (this.conocesPrestacionDiasVacaciones) this.vacacionesOferta = 6;
            if (this.conocesPrestacionFondoAhorro) this.fondoOferta = 0;
            if (this.conocesPrestacionPrimaVacaciones) this.primaOferta = 20;

            if (this.prestacionesExtraIngresadas.length > 0) {
                this.otrasPrestaciones = true;
            }

            let params = {
                Opcion: 2,
                token: localStorage.getItem('token'),
                datosSueldo: this.datosSueldo,
                nombreEmpresa: this.nombreEmpresa,
                sueldoOferta: this.sueldoOfertaEnviado,
                aguinaldoOferta: this.aguinaldoOfertaEnviado,
                vacacionesOferta: this.vacacionesOferta,
                primaOferta: this.primaOferta,
                valesOferta: this.valesOfertaEnviado,
                fondoOferta: this.fondoOferta,
                bonosOferta: this.bonosOfertaEnviado,
                otrasPrestaciones: this.otrasPrestaciones,
                prestacionesExtra: this.prestacionesExtraIngresadas
            }

            this.guardaComparacion(params);
        },

        /* Making a post request to a php file. */
        guardaComparacion(params) {
            // 
            axios
                .post(`${ this.$store.getters.getHost }/Compensaciones/ws-simulador-de-prestaciones.php`, params)
                .then((response) => {
                    if (response.data.status == "EXPSESSION") {
                        deleteToken();
                        this.cargandoComparacion = false;
                    } else {
                        var data = response.data.datosComparacion;
                        // console.log(data);
                        this.detenLoading();
                        this.comparacion = data;
                        this.cargandoComparacion = false;
                        this.muestraComparacion = true;
                    }

                })
        },
        detenLoading() {
            this.loadingButton = false;
        },
        validate() {
            this.$refs.form.validate()
        },
        reset() {
            this.$refs.form.reset()
        },
        resetValidation() {
            this.$refs.form.resetValidation()
        },
        updateExtraBenefit() {
            this.prestacionesExtraIngresadas.push({
                "descripcion": "",
                "periodo": "",
                "tipoMonto": "",
                "cantidad": ""
            })
        }
    }
}