<template>
    <v-dialog
    v-model="openNuevoTipoDocumento"
    class="rounded-lg"
    max-width="450"
    persistent
  >
    <v-card class="rounded-lg">
      <div class="text-right">
        <v-btn class="mt-2 mr-2" fab x-small color="pink" dark @click="$emit('cierraVentana', false, '')"
          ><v-icon small>mdi-close</v-icon></v-btn
        >
      </div>
      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-text-field
            dense
            v-model="nombreTipoDoc"
            :label="labelField"
            :placeholder="placeholderField"
            :value="nombreTipoDoc"
            required
            :counter="200"
            :rules="nameRules"
          ></v-text-field>
          <v-radio-group v-if="opcion == 1" v-model="tipoDocumento" 
              class="mx-5">
              <template v-slot:label>
                  <div>Solicitado para colaboradores  </div>
              </template>
              <v-radio label="Activos" value="1"></v-radio>
              <v-radio label="Bajas" value="2"></v-radio>
          </v-radio-group>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn depressed color="success" small rounded :loading="loadingButton" @click="validate">
          aceptar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios'
import { deleteToken } from '../../../../../store/Funciones/funciones';
export default {
  name: "modifica-tipos-documentos",
  components: {},
  props: ["openNuevoTipoDocumento", "opcion", "item"],
  data() {
    return {
      valid: true,
      loadingButton: false,
      nuevoConocimiento: "",
      nombreTipoDoc: "",
      tipoDocumento: "",
      labelField: "",
      placeholderField: "",
      nameRules: [
        (v) => !!v || "El campo es requerido.",
        (v) =>
          (v && v.length <= 200) || "Name must be less than 200 characters",
      ],
    };
  },
  mounted() {
    // console.log("hola")
    // console.log("hola: " + this.opcion)
    // console.log(this.item.documento)
    if(this.opcion == 1){
        this.placeholderField = "Agrega un nuevo documento."
        this.labelField = "Nuevo tipo de documento"
    } else {
        this.placeholderField = "Ingresa el nuevo titulo de documento."
        this.labelField = "Editando tipo de documento"
        this.nombreTipoDoc = this.item.documento
        this.tipoDocumento = this.item.estatusColaborador
        console.log(this.tipoDocumento);
    }
  },
  methods: {
    validate() {
      if (this.$refs.form.validate()) {
        // this.$emit("cierraVentana", this.nuevoConocimiento);
        this.loadingButton = true
        let params = null
        if(this.opcion == 1){
            params = {
                Opcion: 5,
                tipoDocumento:this.tipoDocumento,
                token: localStorage.getItem('token'),
                nuevoDocumento: this.nombreTipoDoc
            }
            this.saveData(params)
        } else {
            params = {
                Opcion: 6,
                token: localStorage.getItem('token'),
                nuevoNombre: this.nombreTipoDoc,
                id: this.item.id
            }
            this.saveData(params)
        }
      }
    },

    saveData(params){
        axios
        .post(
          `${this.$store.getters.getHost}/RelacionesLaborales/DocumentacionEmpleados/ws-carga-documentos.php`,
          params
        )
        .then((response) => {
            this.loadingButton = false
            console.log(response.data)
          if (response.data.status == "EXPRESSION") {
            deleteToken();
          } else if (response.data.status == "OK") {
            this.$emit("cierraVentana", true, response.data.detalles);
          } else {
            this.$emit("cierraVentana", false, response.data.detalles);
          }
        });
    }
  },
};
</script>
