export default {
    name: 'acumulados-vacaciones',
    components: {},
    props: [],
    data() {
        return {

        }
    },
    computed: {

    },
    mounted() {
        //  window.document.title = "DH:URREA - Reporte: acumulados de vacaciones.";
    },
    methods: {

    }
}