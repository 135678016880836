var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mt-3 mx-10 rounded-lg elevation-0",staticStyle:{"border":"1px solid #1565C0"}},[_c('v-data-table',{attrs:{"dense":"","items":_vm.solicitudes,"headers":_vm.headers,"loading":_vm.loadingData,"search":_vm.search,"footer-props":{
      'items-per-page-text': 'Elementos por página',
      pageText: '{0}-{1} de {2}',
    },"no-data-text":"No hay registros","loading-text":"Cargando información...","no-results-text":'No se encontró ningun resultado que coincida con ' + _vm.search,"header-props":_vm.headerProps,"mobile-breakpoint":0,"single-expand":true,"expanded":_vm.expanded,"item-key":"id","show-expand":""},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"primary pa-3 rounded-lg rounded-b-0",staticStyle:{"display":"flex","justify-content":"space-between"}},[_c('v-text-field',{staticClass:"rounded-pill",attrs:{"dense":"","outlined":"","dark":"","prepend-inner-icon":"mdi-magnify","placeholder":"Buscar..."},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('div',[_c('vue-json-to-csv',{attrs:{"json-data":_vm.solicitudes,"labels":_vm.headers_to_export,"csv-title":'ReporteSolicitudes'}},[_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"rounded-pill mr-2",attrs:{"depressed":"","color":"white","dark":"","fab":"","small":"","outlined":"","loading":_vm.loadingData}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-microsoft-excel")])],1)]}}])},[_c('span',[_vm._v("Exportar CSV")])])],1),_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"rounded-pill mr-2",attrs:{"depressed":"","color":"white","dark":"","fab":"","small":"","outlined":"","loading":_vm.loadingData},on:{"click":_vm.actualizarTabla}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-reload")])],1)]}}])},[_c('span',[_vm._v("Refrescar")])])],1)],1)]},proxy:true},{key:"expanded-item",fn:function(ref){
    var headers = ref.headers;
    var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-card',{staticClass:"ma-3 pa-3 rounded-xl elevation-0",attrs:{"color":"blue-grey lighten-5"}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-card',{staticClass:"ma-3 pa-3 rounded-lg elevation-0"},[_c('label',{staticClass:"overline font-weight-bold blue-grey--text text-darken2"},[_vm._v(" justificación ")]),_c('div',{staticClass:"body-2"},[_vm._v(" "+_vm._s(item.justificacion)+" ")]),_c('v-divider',{staticClass:"my-2"}),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c('label',{staticClass:"overline font-weight-bold blue-grey--text text-darken2"},[_vm._v(" motivo ")]),_c('div',{staticClass:"body-2"},[_vm._v(" "+_vm._s(item.motivoDesc)+" ")])]),_c('v-col',{attrs:{"cols":"6"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(item.motivo === 4),expression:"item.motivo === 4"}]},[_c('label',{staticClass:"overline font-weight-bold blue-grey--text text-darken2"},[_vm._v(" área ")]),_c('div',{staticClass:"body-2"},[_vm._v(" "+_vm._s(item.areaDesc)+" ")])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(item.motivo === 2),expression:"item.motivo === 2"}]},[_c('label',{staticClass:"overline font-weight-bold blue-grey--text text-darken2"},[_vm._v(" mínimo - máximo ")]),_c('div',{staticClass:"body-2"},[_vm._v(" "+_vm._s(item.minimo)+" - "+_vm._s(item.maximo)+" ")])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(item.motivo === 3),expression:"item.motivo === 3"}]},[_c('label',{staticClass:"overline font-weight-bold blue-grey--text text-darken2"},[_vm._v(" monto ")]),_c('div',{staticClass:"body-2"},[_vm._v(" "+_vm._s(item.monto != null ? _vm.formatMXN(item.monto) : "")+" ")])])])],1),_c('div')],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"ma-3"},[_c('v-btn',{staticClass:"rounded-lg",attrs:{"block":"","depressed":"","x-large":"","color":"primary"},on:{"click":function($event){_vm.idSolicitud = item.id;
                    _vm.muestraColaboradores = true;}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-account-eye")]),_vm._v(" ver colaboradores asignados ")],1),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:([1, 2].includes(item.motivo)),expression:"[1, 2].includes(item.motivo)"}],staticClass:"rounded-lg mt-3",attrs:{"block":"","depressed":"","outlined":"","x-large":"","color":"primary"},on:{"click":function($event){_vm.idSolicitud = item.id;
                    _vm.muestraFiguras = true;}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-table-eye")]),_vm._v(" ver figuras registradas ")],1)],1)])],1)],1)],1)]}},{key:"item.fecha",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.setFormatDate(item.fecha))+" ")]}},{key:"item.estatus",fn:function(ref){
                    var item = ref.item;
return [_c('v-chip',{staticClass:"font-weight-bold",attrs:{"small":"","depressed":"","color":_vm.getColorEstatus(item.estatus)}},[_vm._v(" "+_vm._s(_vm.setEstatusText(item.estatus))+" ")])]}},{key:"item.fecha_registro",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.setFormatDateTime(item.fecha_registro))+" ")]}},{key:"item.costo",fn:function(ref){
                    var item = ref.item;
return [_c('label',{staticClass:"font-weight-bold font-italic"},[_vm._v(_vm._s(_vm.formatMXN(item.costo)))])]}}])}),(_vm.muestraColaboradores)?_c('VerColaboradoresPorSolicitud',{attrs:{"muestraColaboradores":_vm.muestraColaboradores,"idSolicitud":_vm.idSolicitud},on:{"ocultaColaboradores":function($event){_vm.muestraColaboradores = false}}}):_vm._e(),(_vm.muestraFiguras)?_c('VerFigurasPorSolicitud',{attrs:{"muestraFiguras":_vm.muestraFiguras,"idSolicitud":_vm.idSolicitud},on:{"ocultaFiguras":function($event){_vm.muestraFiguras = false}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }