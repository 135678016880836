<template>
  <v-dialog v-model="showFindNewMembers" persistent max-width="550" content-class="fix-border-dialog">
    <v-card class="rounded-xl">
      <v-card-title class="overline font-weight-bold primary white--text">
        Colaboradores activos.
        <v-spacer></v-spacer>
        <v-icon small dark @click="$emit('returnColaborrador', null)">mdi-close</v-icon>
      </v-card-title>
      <v-card-text class="pt-5">
        <v-form
          ref="formGetEmployee"
          v-model="valid"
          lazy-validation
        >
          <v-autocomplete
            v-model="newMember"
            required
            class="rounded-lg"
            outlined
            dense
            :rules="nameRules"
            :loading="loadingMembers"
            :items="colaboradores"
            item-text="nombreMostrar"
            counter="200"
            label="Busca por nombre o número de empleado..."
          ></v-autocomplete>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="pa-0">
        <v-btn
          class="rounded-xl rounded-t-0 primary--text white font-weight-bold"
          x-large
          block
          text
          depressed
          @click="returnColaborador"
        >
         <v-icon left>mdi-content-save</v-icon>
          {{ $t("DH360.save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios'
import { deleteToken } from '../store/Funciones/funciones';
export default {
  name: "busca-colaboradores-activos",
  components: {  },
  props: ["showFindNewMembers"],
  data() {
    return {
        colaboradores:[],
        valid: true,
        loadingMembers: true,
        newMember: "",
        nameRules: [(v) => !!v || "Campo requerido."],
    };
  },
  computed: {},
  mounted() {
    this.getColaboradores()
  },
  methods: {
    getColaboradores() {
      if (this.colaboradores.length == 0) {
        let params = {
          Opcion: 11,
          token: localStorage.getItem("token"),
        };
        axios
          .post(`${this.$store.getters.getHost}/General/ws-general.php`, params)
          .then((response) => {
            this.loadingMembers = false;
            if (response.data.status == "EXPRESSION") {
              deleteToken();
            } else if (response.data.status == "OK") {
              this.colaboradores = response.data.colaboradores;
            }
          });
      }
    },
    returnColaborador(){
        if(this.$refs.formGetEmployee.validate()){
          console.log("if");
          let buscaColaboradorSeleccionado = this.colaboradores.filter((e) => {
            return e.nombreMostrar === this.newMember
          })
          this.$emit('returnColaborrador', buscaColaboradorSeleccionado[0])
        }else{
          console.log("else");
        }
        
    }
  },
};
</script>
