<template>
  <div>
  <v-dialog
    v-model="dialogSeleccionaNomina"
    width="500"
    content-class="fix-border-dialog"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        color="primary"
        class="rounded-pill elevation-0 mb-3"
        block
      >
        <v-icon left> mdi-abacus </v-icon>
        resumen de periodo
      </v-btn>
    </template>

    <v-card class="rounded-xl">
          <v-card-title class="overline font-weight-bold white--text primary">
            Selecciona el tipo de nómina
          </v-card-title>

          <v-card-text class="pt-5">
            <div class="center-item-in-div-column">
              <v-btn block color="primary" class="rounded-lg mb-3" x-large @click="tipoNomina = 'semana'; muestraDialogCalculos = true;">semanal</v-btn>
              <v-btn block color="primary" class="rounded-lg mb-3" x-large @click="tipoNomina = 'quincena'; muestraDialogCalculos = true;">quincenal</v-btn>
            </div>
          </v-card-text>
        </v-card>
  </v-dialog>
  
  <CalculaMovimientos v-if="muestraDialogCalculos" :muestraDialogCalculos="muestraDialogCalculos" :tipoNomina="tipoNomina" @cancelaCalculo="muestraDialogCalculos = false"/>

  </div>
</template>

<script>
import CalculaMovimientos from '../calculaMovimientos'
export default {
  components: {CalculaMovimientos},
  data() {
    return {
      dialogSeleccionaNomina: false,
      muestraDialogCalculos: false,
      tipoNomina: null,
    };
  },
};
</script>
