<template>
  <Doughnut
    :chart-options="chartOptions"
    :chart-data="chartData"
    :chart-id="chartId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :width="width"
    :height="height"
  />
</template>

<script>
import axios from "axios";
import { Doughnut } from "vue-chartjs/legacy";
import { deleteToken } from "../../../../../../store/Funciones/funciones";

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale,
} from "chart.js";

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale);

export default {
  name: "ofertas-por-estatus",
  components: {
    Doughnut,
  },
  props: {
    chartId: {
      type: String,
      default: "doughnut-chart",
    },
    datasetIdKey: {
      type: String,
      default: "label",
    },
    width: {
      type: Number,
      default: 350,
    },
    height: {
      type: Number,
      default: 160,
    },
    cssClasses: {
      default: "",
      type: String,
    },
    styles: {
      type: Object,
      default: () => {},
    },
    plugins: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      chartData: {
        labels: [],
        datasets: [
          {
            backgroundColor: [
              "#42A5F5",
              "#90A4AE",
              "#66BB6A",
              "#C2185B",
              "#6A1B9A",
            ],
            data: [],
          },
        ],
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: "bottom",
          },
          title: {
            display: false,
            text: "",
          },
          labels: {
            font: {
              family: "Titillium",
              size: 14,
            },
          },
        },
      },
    };
  },

  mounted() {
    this.getDataReclu();
  },

  methods: {
    getDataReclu() {
      let params = {
        Opcion: 26,
        token: localStorage.getItem("token"),
      };
      axios
        .post(
          `${this.$store.getters.getHost}/Nominas/Compensaciones/ws-ofertasEconomicas.php`,
          params
        )
        .then((response) => {
          if (response.data.status != "EXPSESSION") {
            this.chartData.datasets[0].data = Object.values(response.data.ofertas);
            this.chartData.labels = Object.keys(response.data.ofertas);
          } else {
            deleteToken();
          }
        });
    },
  },
};
</script>
