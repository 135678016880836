import axios from 'axios'
import { deleteToken } from '../../../../../store/Funciones/funciones'
import SnackBar from "../../../General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue";
import admonRelacionUPACertificaciones from "../Components/admonRelacionUPACertificaciones.vue";
export default {
  name: 'admon-up-as',
  components: { SnackBar, admonRelacionUPACertificaciones},
  props: [],
  data() {
    return {
      textoBarra: '',
      colorBarra: '',
      muestraBarra: false,

      upas: [],
      buscar: '',

      headers: [
        { text: 'ID', value: 'id', align: 'center', sortable: true },
        { text: 'UPA', value: 'Descripcion', align: 'center', sortable: true },
        { text: 'Estatus', value: 'habilitado', align: 'center', sortable: true },
        { text: 'Acciones', value: 'actions', align: 'center', sortable: true },
      ],
      dialogForm: false,
      loading: false,
      loadingInfo: true,
      editedIndex: -1,
      editedItem: {
        id: '',
        Descripcion: '',
        habilitado: '',
      },
      defaultItem: {
        id: '',
        Descripcion: '',
        habilitado: '',
      },
      rulesArticulo: {
        required: value => !!value || 'Campo obligatorio.',
        counter: value => value.length <= 50 || 'Max 50 caracteres',
      },
      dialogEstatus: false,
      habilitado: false,
      idHabilitado: '',

      tab: null,
    }
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'Nueva UPA' : 'Editar UPA'
    },
  },
  mounted() {
    this.getUpas();
  },
  methods: {
    validar() {
      let validador = 0;
      if (this.editedItem.id) {
        this.upas.map((element) => {
          if (element.Descripcion == this.editedItem.Descripcion && element.id != this.editedItem.id) {
            this.textoBarra = 'Ya existe una UPA con ese nombre registrado.';
            this.colorBarra = 'error';
            this.muestraBarra = true;
            validador = 1;
          }
        });
      } else {
        this.upas.map((element) => {
          if (element.Descripcion == this.editedItem.Descripcion) {
            this.textoBarra = 'Ya existe una UPA con ese nombre registrado.';
            this.colorBarra = 'error';
            this.muestraBarra = true;
            validador = 1;
          }
        });
      }
      console.log(validador);
      validador == 0 ? this.guardar() : '';
    },

    guardar() {
      let opcion = this.editedIndex == -1 ? 2 : 3;
      let params = {
        Opcion: opcion,
        token: localStorage.getItem('token'),
        upa: this.editedItem
      }
      this.loading = true;
      axios.post(`${this.$store.getters.getHost}/SeguridadMedioAmbiental/UPA/ws-admonUpas.php`, params).then((response) => {
        this.loadingInfo = false;
        if (response.data.status == "OK") {
          this.textoBarra = 'Articulo registrado correctamente.';
          this.colorBarra = 'success';
          this.muestraBarra = true;
          this.clean();
          this.getUpas();
        } else if (response.data.status == "ERROR") {
          this.textoBarra = 'Algo salió mal.';
          this.colorBarra = 'error';
          this.muestraBarra = true;
        } else if (response.data.status == "EXPRESSION") {
          deleteToken();
        }
        this.loading = false;
      })
        .catch(function (e) {
          alert("Ocurrió un error: " + e);
        });
    },

    cambioEstatus() {
      let habilitado = this.habilitado == false ? 0 : 1;
      let params = {
        Opcion: 4,
        id: this.idHabilitado,
        habilitado: habilitado,
        token: localStorage.getItem('token'),
      }
      this.loading = true;
      axios.post(`${this.$store.getters.getHost}/SeguridadMedioAmbiental/UPA/ws-admonUpas.php`, params).then((response) => {
        this.loadingInfo = false;
        if (response.data.status == "OK") {
          this.textoBarra = 'Estatus modificado correctamente.';
          this.colorBarra = 'success';
          this.muestraBarra = true;
          this.clean();
          this.getUpas();
        } else if (response.data.status == "error") {
          this.textoBarra = 'Algo salió mal.';
          this.colorBarra = 'error';
          this.muestraBarra = true;
        } else if (response.data.status == "EXPRESSION") {
          deleteToken();
        }
        this.loading = false;
      })
        .catch(function (e) {
          alert("Ocurrió un error: " + e);
        });
    },

    getUpas() {
      let params = {
        Opcion: 1,
        token: localStorage.getItem('token'),
      }
      axios
        .post(
          `${this.$store.getters.getHost}/SeguridadMedioAmbiental/UPA/ws-admonUpas.php`,
          params
        )
        .then((response) => {
          this.loadingInfo = false;
          if (response.data.status == "OK") {
            this.upas = response.data.upas;
          } else if (response.data.status == "VACIO") {
            this.textoBarra = 'No se encontraron registros.';
            this.colorBarra = 'info';
            this.muestraBarra = true;
          } else if (response.data.status == "EXPRESSION") {
            deleteToken();
          }
        })
        .catch(function (e) {
          alert("Ocurrió un error: " + e);
        });
    },

    openEstatusDialog(item) {
      this.dialogEstatus = true;
      this.habilitado = item.habilitado == 1 ? true : false;
      this.idHabilitado = item.id;
      console.log(this.habilitado);
      console.log(this.idHabilitado);
    },

    editItem(item) {
      this.editedIndex = this.upas.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogForm = true;
    },

    close() {
      this.dialogForm = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    clean() {
      this.upas = [];
      this.loadingInfo = true;
      this.dialogEstatus = false;
      this.idHabilitado = '';
      this.habilitado = '';
      this.close();
    },
  }
}


