var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"max-width":"600","persistent":"","scrollable":"","content-class":"fix-border-dialog"},model:{value:(_vm.verEmpleados),callback:function ($$v) {_vm.verEmpleados=$$v},expression:"verEmpleados"}},[_c('v-card',[_c('v-card-title',{staticClass:"primary"},[_c('div',{staticClass:"white--text overline font-weight-bold"},[_vm._v(" Colaboradores a cargo ")]),_c('v-spacer'),_c('v-icon',{attrs:{"dark":""},on:{"click":function($event){return _vm.$emit('cierraVerSubordinados', _vm.acciones)}}},[_vm._v(" mdi-close ")])],1),_c('v-sheet',{staticClass:"py-2 center-item-in-div"},[_c('v-btn',{staticClass:"body-2 mx-1",attrs:{"color":"primary rounded-lg","dark":"","x-large":"","loading":_vm.loadingBtnMasivo},on:{"click":function($event){_vm.typeAction = 2;
            _vm.loadingBtnMasivo = true;
            _vm.showFindNewMembers = true;}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-account-group")]),_vm._v(" Asignar a otro "),_c('br'),_vm._v(" responsable ")],1),_c('v-btn',{staticClass:"body-2 mx-1",attrs:{"color":"primary rounded-lg","dark":"","x-large":"","loading":_vm.loadingAddSub},on:{"click":function($event){_vm.typeAction = 3;
            _vm.loadingAddSub = true;
            _vm.showFindNewMembers = true;}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-account-plus")]),_vm._v(" Añadir persona "),_c('br'),_vm._v(" a cargo ")],1)],1),_c('v-data-table',{attrs:{"dense":"","loading":_vm.loadingEmployees,"headers":_vm.headers,"items":_vm.empleados},scopedSlots:_vm._u([{key:"item.EMP_STATUS",fn:function(ref){
            var item = ref.item;
return [_c('v-chip',{attrs:{"color":item.EMP_STATUS === 'A'
                ? 'teal darken-1'
                : item.EMP_STATUS === null
                ? 'grey'
                : 'pink darken-3',"small":"","dark":""}},[_vm._v(" "+_vm._s(item.EMP_STATUS === "A" ? "Activo" : item.EMP_STATUS === null ? "Desconocido" : "Inactivo")+" ")])]}},{key:"item.Actions",fn:function(ref){
                var item = ref.item;
return [_c('v-icon',{staticClass:"mx-1",attrs:{"color":"cyan darken-3"},on:{"click":function($event){_vm.employeeAux = item.Empleado;
              _vm.typeAction = 1;
              _vm.showFindNewMembers = true;}}},[_vm._v("mdi-pencil")]),_c('v-icon',{staticClass:"mx-1",attrs:{"color":"pink darken-3"},on:{"click":function($event){_vm.employeeAux = item.Empleado;
              _vm.showConfirm = true;}}},[_vm._v("mdi-delete")])]}}])})],1),(_vm.showFindNewMembers)?_c('BuscaColaboradoresActivos',{attrs:{"showFindNewMembers":_vm.showFindNewMembers},on:{"returnColaborrador":_vm.getNewResponsable}}):_vm._e(),(_vm.showConfirm)?_c('ConfirmDialog',{attrs:{"showConfirm":_vm.showConfirm,"question":_vm.question},on:{"cierraConfirm":_vm.validaRespuesta}}):_vm._e()],1),(_vm.muestraBarra)?_c('SnackBar',{attrs:{"muestraBarra":_vm.muestraBarra,"color":_vm.colorBarra,"texto":_vm.textoBarra},on:{"cierraBarra":function($event){_vm.muestraBarra = false}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }