<template>
  <v-dialog v-model="dialog" class="mx-auto" max-width="90%" persistent>
    <v-card>
      <v-card-title class="text-subtitle-2 primary white--text">
        Beneficios Flexibles
        <v-spacer></v-spacer>
        <v-icon small dark @click="$emit('cierraFormularioAuto', { estatus: 'Cancelar' })">mdi-close</v-icon>
      </v-card-title>
      <v-card-text v-if="!cargandoInfo" class="mt-5">
        <v-row>
          <v-col v-if="pagoDisponible && !beneficioDisponible" cols="12" xl="12" lg="12" md="12" sm="12" xs="12">
            <v-card class="mx-auto" color="grey lighten-4">
              <v-card-title class="text-subtitle-2 primary white--text">
                Mi información
                <v-spacer></v-spacer>
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col class="d-flex align-center" align="left" xl="4" lg="4" md="4" sm="12" xs="12">
                    <v-avatar size="150" tile>
                      <img v-if="colaborador.Foto" alt="" :src="colaborador.Foto" aling="absmiddle" width="200px" />
                      <img v-else alt=""
                        :src="'https://community.f5.com/legacyfs/online/avatars/0051T000008uOPOQA2.png'"
                        aling="absmiddle" width="200px" />
                    </v-avatar>
                  </v-col>
                  <v-col class="d-flex align-center black--text" align="left" xl="8" lg="8" md="8" sm="12" xs="12">
                    <v-card-text class="pt-0">
                      <b>EMPLID</b> {{ colaborador.Folio }} <br />
                      <b>Nombre</b> {{ colaborador.Nombre }} <br />
                      <b>Puesto</b> {{ colaborador.Puesto }} <br />
                      <b>Departamento</b> {{ colaborador.Departamento }}
                    </v-card-text>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col v-if="beneficioDisponible" cols="12" xl="6" lg="6" md="6" sm="6" xs="12">
            <v-card class="mx-auto" color="grey lighten-4">
              <v-card-title class="text-subtitle-2 primary white--text">
                Mi información
                <v-spacer></v-spacer>
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col class="d-flex align-center" align="left" xl="4" lg="4" md="4" sm="12" xs="12">
                    <v-avatar size="150" tile max-width="200px">
                      <img v-if="colaborador.Foto" alt="" :src="colaborador.Foto" aling="absmiddle" />
                      <img v-else alt=""
                        :src="'https://community.f5.com/legacyfs/online/avatars/0051T000008uOPOQA2.png'"
                        aling="absmiddle" />
                    </v-avatar>
                  </v-col>
                  <v-col class="d-flex align-center black--text" align="left" xl="8" lg="8" md="8" sm="12" xs="12">
                    <v-card-text class="pt-0">
                      <b>EMPLID</b> {{ colaborador.Folio }} <br />
                      <b>Nombre</b> {{ colaborador.Nombre }} <br />
                      <b>Puesto</b> {{ colaborador.Puesto }} <br />
                      <b>Departamento</b> {{ colaborador.Departamento }}
                    </v-card-text>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col v-if="beneficioDisponible" cols="12" xl="6" lg="6" md="6" sm="6" xs="12">
            <v-card class="mx-auto" color="grey lighten-4">
              <v-card-title class="text-subtitle-2 primary white--text">
                Automovil asignado
                <v-spacer></v-spacer>
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col class="d-flex align-center black--text" align="left" xl="6" lg="6" md="6" sm="12" xs="12">
                    <v-card-text class="pt-0">
                      <b>Modelo</b> {{ beneficio.modelo }} <br />
                      <b>Serie</b> {{ beneficio.serie }} <br />
                      <b>Fecha asignación</b> {{ beneficio.fechaAsignacion }} <br />
                      <b>Fecha Renovación</b> {{ beneficio.fechaRenovacion }}
                    </v-card-text>
                  </v-col>
                  <v-col class="d-flex align-center" align="left" xl="6" lg="6" md="6" sm="12" xs="12">
                    <v-avatar size="150" tile max-width="200px">
                      <v-img alt="" :src="imageUrl" contain></v-img>
                    </v-avatar>
                  </v-col>
                </v-row>
              </v-card-text>

            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text v-if="cargandoInfo">
        <b>Cargando información... <v-progress-linear indeterminate></v-progress-linear></b>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions v-if="!cargandoInfo">
        <v-spacer></v-spacer>

        <v-btn v-if="pagoDisponible" class="rounded-lg mx-1 elevation-3" @click="dialogPagos = true"
          color="lime darken-1" dark small>
          <v-icon left>mdi-check-circle-outline</v-icon> Pago Semanal
        </v-btn>
        <v-dialog v-model="dialogPagos" max-width="400px">
          <v-card>
            <v-card-title class="text-subtitle-2 primary white--text">
              Cambio a nomina semanal
              <v-spacer></v-spacer>
              <v-icon small dark @click="dialogPagos = false">mdi-close</v-icon>
            </v-card-title>
            <v-card-text class="caption">
              <p>El proceso de cambio de pago quincenal a semanal se validará, en caso de ser candidato se notificará y
                se contactarán para continuar con el proceso.</p>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn class="rounded-lg mx-1 elevation-3" :loading="loadingPago" @click="CambioPago"
                color="green darken-1" dark small>
                <v-icon left>mdi-check-circle-outline</v-icon> Confirmar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-btn v-if="beneficioDisponible" class="rounded-lg mx-1 elevation-3" @click="dialogBeneficios = true"
          color="cyan darken-1" dark small>
          <v-icon left>mdi-check-circle-outline</v-icon> Ver beneficios flexibles
        </v-btn>
        <v-dialog v-model="dialogBeneficios" max-width="400px">
          <v-card>
            <v-card-title class="text-subtitle-2 primary white--text">
              Beneficios flexibles
              <v-spacer></v-spacer>
              <v-icon small dark @click="dialogBeneficios = false">mdi-close</v-icon>
            </v-card-title>
            <v-card-text class="caption">
              <v-list rounded disabled>
                <v-list-item-group color="primary">
                  <v-list-item v-for="(item, i) in itemsBeneficios" :key="i">
                    <v-list-item-content>
                      <v-list-item-title v-text="item.beneficio"></v-list-item-title>
                      <v-list-item-subtitle v-text="item.descripcion"></v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-card-text>
          </v-card>
        </v-dialog>

        <v-btn v-if="beneficioDisponible" class="rounded-lg mx-1 elevation-3" @click="dialogTabulador = true"
          color="indigo darken-1" dark small>
          <v-icon left>mdi-check-circle-outline</v-icon> Ver tabulador
        </v-btn>
        <v-dialog v-model="dialogTabulador" max-width="500px">
          <v-card>
            <v-card-title class="text-subtitle-2 primary white--text">
              Tabulador beneficios flexibles
              <v-spacer></v-spacer>
              <v-icon small dark @click="dialogTabulador = false">mdi-close</v-icon>
            </v-card-title>
            <v-card-text class="caption">
              <v-data-table :headers="headersTabulador" dense no-data-text="No hay registros de tabulador"
                :footer-props="{
    'items-per-page-text': 'Elementos por página',
  }" :header-props="{ 'short-by-text': 'Ordenar por', }" loading-text="Cargando contenido..." :items="itemsTabulador"
                hide-default-footer>
                <template v-slot:item.monto="{ item }">
                  {{
    parseFloat(item.monto) == 0
      ? ""
      : formatMXN(parseFloat(item.monto))
  }}
                </template>
                <template v-slot:item.empresa="{ item }">
                  {{
    parseFloat(item.empresa) == 0
      ? ""
      : formatMXN(parseFloat(item.empresa))
  }}
                </template>
                <template v-slot:item.empleado="{ item }">
                  {{
    parseFloat(item.empleado) == 0
      ? ""
      : formatMXN(parseFloat(item.empleado))
  }}
                </template></v-data-table>
            </v-card-text>
          </v-card>
        </v-dialog>

        <v-btn v-if="beneficioDisponible" class="rounded-lg mx-1 elevation-3" @click="dialogSolicitar = true"
          color="teal darken-1" dark small>
          <v-icon left>mdi-check-circle-outline</v-icon> Solicitar beneficio flexible
        </v-btn>
        <v-dialog v-model="dialogSolicitar" max-width="600px">
          <v-card>
            <v-card-title class="text-subtitle-2 primary white--text">
              Solicitud de beneficio flexible
              <v-spacer></v-spacer>
              <v-icon small dark @click="dialogSolicitar = false">mdi-close</v-icon>
            </v-card-title>
            <v-card-text>
              <v-form ref="formAddBeneficio">
                <v-row dense>
                  <v-col :cols="['xs', 'sm'].includes($vuetify.breakpoint.name) ? 6 : 6">
                    <label class="overline"> Rango </label>
                    <v-select v-model="selectedItem" :rules="[v => !!v || 'Item is required']" :items="itemsBeneficios"
                      dense item-text="beneficio" item-value="id" outlined required
                      label="Selecciona un beneficio"></v-select>
                  </v-col>
                  <v-col :cols="['xs', 'sm'].includes($vuetify.breakpoint.name) ? 6 : 6">
                    <label class="overline"> Duración </label>
                    <v-menu v-model="menu" ref="menu" :close-on-content-click="false" :return-value.sync="date"
                      transition="scale-transition" offset-y min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="date" label="Seleccionar rango de fechas" dense outlined
                          :rules="[v => !!v || 'Item is required']" prepend-icon="mdi-calendar-range" readonly
                          v-bind="attrs" v-on="on"></v-text-field>
                      </template>
                      <v-date-picker v-model="date" no-title scrollable :min="minDate" range elevation="15"
                        locale="es-mx">
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="menu = false">
                          Cancelar
                        </v-btn>
                        <v-btn text color="primary" @click="$refs.menu.save(date)">
                          Guardar
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col :cols="['xs', 'sm'].includes($vuetify.breakpoint.name) ? 6 : 6">
                    <label class="overline"> Monto </label>
                    <v-text-field :rules="[v => !!v || 'Item is required']" prefix="$" type="number" v-model="monto"
                      dense class="rounded-lg" :rows="2" required outlined
                      placeholder="Ingresa el monto..."></v-text-field>
                  </v-col>
                  <v-col :cols="['xs', 'sm'].includes($vuetify.breakpoint.name) ? 3 : 3">
                    <label class="overline"> % Prestación </label>
                    <v-text-field v-model="prestacion" type="number" dense class="rounded-lg" required readonly outlined
                      placeholder="% de prestación..." item-text="text" value="value"></v-text-field>
                  </v-col>
                  <v-col :cols="['xs', 'sm'].includes($vuetify.breakpoint.name) ? 3 : 3">
                    <label class="overline"> No. Prestación </label>
                    <v-text-field v-model="noPrestacion" type="number" dense class="rounded-lg" outlined required
                      readonly placeholder="Prestación..."></v-text-field>
                  </v-col>
                  <v-col :cols="['xs', 'sm'].includes($vuetify.breakpoint.name) ? 6 : 6">
                    <label class="overline"> Empresa </label>
                    <v-text-field prefix="$" type="number" v-model="empresa" dense class="rounded-lg" required outlined
                      placeholder="Monto de la empresa..." item-text="text" readonly value="value"></v-text-field>
                  </v-col>
                  <v-col :cols="['xs', 'sm'].includes($vuetify.breakpoint.name) ? 6 : 6">
                    <label class="overline"> Empleado </label>
                    <v-text-field prefix="$" type="number" v-model="empleado" dense class="rounded-lg" required outlined
                      maxlength="50" placeholder="Monto paga el usuario al 4to año..." item-text="text" readonly
                      value="value"></v-text-field>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions class="pa-0">
              <v-btn class="rounded-xl rounded-t-0" block text color="teal darken-1" x-large :loading="loadingSave"
                @click="loadingSave = true; setSolicitud()">
                guardar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-snackbar v-model="snackbar" :timeout="timeout" :color="color">
          {{ text }}
          <template v-slot:action="{ attrs }">
            <v-btn text v-bind="attrs" @click="snackbar = false">
              Close
            </v-btn>
          </template>
        </v-snackbar>
      </v-card-actions>
    </v-card>

  </v-dialog>
</template>



<script>
import axios from "axios";
export default {
  name: "form-renovacion-auto",
  components: {},
  props: ["dialog"],
  data: () => ({
    dialogPagos: false,
    grupoPago: 3,
    //grupoPago: localStorage.getItem("token"),
    pagoQuincenal: [2, 3, 4, 5, 6, 7],
    colaborador: [],
    beneficio: [],
    imageUrl: false,
    //validar que card mostrar
    beneficioDisponible: false,
    pagoDisponible: false,
    cargandoInfo: true,
    //tabulador
    dialogTabulador: false,
    itemsTabulador: [],
    idrango: null,
    headersTabulador: [
      { text: 'No. Prestación', value: 'noPrestacion' },
      { text: 'Monto', value: 'monto' },
      { text: ' % Prestación', value: 'prestacion' },
      { text: 'Empresa', value: 'empresa' },
      { text: 'Empleado', value: 'empleado' }
    ],
    // otros BF
    dialogBeneficios: false,
    itemsBeneficios: [],
    // solicitar
    dialogSolicitar: false,
    selectedItem: '',
    noPrestacion: '',
    monto: null,
    prestacion: null,
    empresa: null,
    empleado: null,
    loadingSave: false,
    loadingPago: false,
    menu: false,
    date: null,
    snackbar: false,
    color: 'gray',
    text: 'Solicitud registrada con exito!',
    timeout: 3000,

  }),
  created() { },
  mounted() {
    this.getPerfilColaborador();
    if (this.pagoQuincenal.includes(this.grupoPago)) {
      this.pagoDisponible = true;
    }
  },
  computed: {
    minDate() {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, '0');
      const day = String(today.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    },
  },
  watch: {
    monto: function (newMonto) {
      // Convierte prestacion a decimal y calcula los montos
      const prestacionDecimal = this.prestacion / 100;
      this.empresa = (newMonto * prestacionDecimal).toFixed(2);
      this.empleado = (newMonto - this.empresa).toFixed(2);
    },
    prestacion: function (newPrestacion) {
      // Convierte prestacion a decimal y recalcula los montos
      const prestacionDecimal = newPrestacion / 100;
      this.empresa = (this.monto * prestacionDecimal).toFixed(2);
      this.empleado = (this.monto - this.empresa).toFixed(2);
    },

  },
  methods: {
    getPerfilColaborador() {
      let params = {
        Opcion: 3,
        token: localStorage.getItem("token"),
      };
      axios
        .post(`${this.$store.getters.getHost}/Login/ws-login.php`, params)
        .then((response) => {
          if (response.data.status == "OK") {
            this.getAsignacionColaborador();
            this.colaborador = response.data.datosLogin[0];
          }
        });
    },
    getAsignacionColaborador() {
      this.getAsignacionFoto();
      this.cargandoInfo = true;
      let params = {
        Opcion: 1,
        token: localStorage.getItem("token"),
      };
      axios
        .post(`${this.$store.getters.getHost}/Compensaciones/ws-beneficios-flexibles.php`, params)
        .then((response) => {
          console.log(this.pagoQuincenal.includes(this.grupoPago));
          if ((response.data.status == "OK" && response.data.beneficios.length > 0)) {
            this.beneficioDisponible = true;
            this.cargandoInfo = false;
            this.beneficio = response.data.beneficios[0];
            this.idrango = response.data.beneficios[0].rango;
            this.noPrestacion = response.data.beneficios[0].noPrestacion;
            this.getTabulador();
            this.getbeneficios();
          } else if (this.pagoQuincenal.includes(this.grupoPago)) {
            this.pagoDisponible = true;
            this.cargandoInfo = false;
          }
          else {
            this.$emit('cierraFormularioAuto', { estatus: 'Cancelar' });
            setTimeout(() => {
              alert('No hay beneficios disponibles');
            }, 110);
            this.beneficioDisponible = false;
            this.cargandoInfo = false;
          }
        });
    },
    getAsignacionFoto() {
      let params = {
        Opcion: 2,
        token: localStorage.getItem("token"),
      };
      axios
        .post(`${this.$store.getters.getHost}/Compensaciones/ws-beneficios-flexibles.php`, params, {
          responseType: 'arraybuffer'
        })
        .then(response => {
          this.imageUrl = `data:${response.headers['content-type']};base64,${btoa(
            new Uint8Array(response.data).reduce((data, byte) => data + String.fromCharCode(byte), '')
          )}`;
        })
        .catch(error => {
          console.error('Error al obtener la imagen:', error);
        });
    },
    getTabulador() {
      let params = {
        Opcion: 7,
        token: localStorage.getItem("token"),
        rango: this.idrango
      };
      axios
        .post(`${this.$store.getters.getHost}/Compensaciones/ws-beneficios-flexibles.php`, params)
        .then((response) => {
          if (response.data.status == "OK") {
            this.itemsTabulador = response.data.tabulador;
            this.prestacion = response.data.tabulador[this.noPrestacion - 1].prestacion;


          }
        });
    },
    getbeneficios() {
      let params = {
        Opcion: 11,
        token: localStorage.getItem("token"),
      };
      axios
        .post(`${this.$store.getters.getHost}/Compensaciones/ws-beneficios-flexibles.php`, params)
        .then((response) => {
          if (response.data.status == "OK") {
            this.itemsBeneficios = response.data.beneficios;
          }
        });
    },
    setSolicitud() {
      this.loadingSave = true;
      let params = {
        Opcion: 12,
        prestacion: this.selectedItem,
        noPrestacion: this.noPrestacion,
        monto: this.monto,
        date: this.date,
        token: localStorage.getItem("token"),
      };
      axios
        .post(`${this.$store.getters.getHost}/Compensaciones/ws-beneficios-flexibles.php`, params)
        .then((response) => {
          if (response.data.status == "OK") {
            this.loadingSave = false;
            this.snackbar = true;
            this.color = 'success';
            this.dialogSolicitar = false;
            this.$refs.form.reset();
            this.$refs.form.resetValidation();
          } else {
            this.snackbar = true;
            this.loadingSave = false;
            this.color = 'red accent-2';
            this.text = 'Error en solicitud!';
          }
        });
    },
    CambioPago() {
      this.loadingPago = true;
      const date = new Date();
      const arrayFecha = [date, date];
      let params = {
        Opcion: 12,
        prestacion: 8,
        noPrestacion: 0,
        monto: 0,
        date: arrayFecha,
        token: localStorage.getItem("token"),
      };
      axios
        .post(`${this.$store.getters.getHost}/Compensaciones/ws-beneficios-flexibles.php`, params)
        .then((response) => {
          this.loadingPago = false;
          if (response.data.status == "OK") {
            this.snackbar = true;
            this.color = 'success';
            this.dialogPagos = false;
          } else {
            this.snackbar = true;
            this.color = 'red accent-2';
            this.text = 'Error en solicitud!';
          }
        });
    },
    //ux
    formatMXN(cadena) {
      return cadena.toLocaleString("es-MX", {
        style: "currency",
        currency: "MXN",
      });
    },

  },
};
</script>