<template>
  <v-card>
    <v-card-text>
      <v-data-table
        dense
        :headers="headers"
        :items="mapaDeTalento"
        :loading="loadingData"
        :items-per-page="10"
        :search="search"
        :footer-props="{
          'items-per-page-text': 'Elementos por página',
          pageText: '{0}-{1} de {2}',
        }"
        :expanded.sync="expanded"
        item-key="id"
        show-expand
        single-expand
        no-data-text="No hay registros"
        loading-text="Cargando información..."
        class="elevation-0"
        style="background-color: transparent"
      >
        <template v-slot:top>
          <div style="display: flex; justify-content: space-between">
            <v-text-field
              v-model="search"
              dense
              outlined
              class="rounded-pill"
              prepend-inner-icon="mdi-magnify"
              placeholder="Buscar..."
            ></v-text-field>
            <v-spacer></v-spacer>
            <div>
              <vue-json-to-csv
                :json-data="mapaDeTalento"
                :labels="headers_to_export"
                csv-title="ReporteMapaDeTalento"
              >
                <v-tooltip bottom color="green darken-2">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      depressed
                      outlined
                      fab
                      small
                      v-bind="attrs"
                      v-on="on"
                      class="rounded-pill mr-2"
                      color="green darken-2"
                      dark
                      :loading="loadingData"
                    >
                      <v-icon>mdi-microsoft-excel</v-icon>
                    </v-btn>
                  </template>
                  <span>Exportar CSV</span>
                </v-tooltip>
              </vue-json-to-csv>
              <v-tooltip bottom color="blue-grey darken-2">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    depressed
                    fab
                    small
                    outlined
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    color="blue-grey darken-2"
                    :loading="loadingData"
                    @click="actualizarTabla"
                  >
                    <v-icon>mdi-reload</v-icon>
                  </v-btn>
                </template>
                <span>Actualizar tabla</span>
              </v-tooltip>
              <v-tooltip bottom color="cyan darken-2">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    depressed
                    fab
                    small
                    outlined
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    color="cyan darken-2"
                    :loading="loadingData"
                    @click="dialogCargarArchivo"
                  >
                    <v-icon>mdi-upload</v-icon>
                  </v-btn>
                </template>
                <span>Subir archivo</span>
              </v-tooltip>
            </div>
          </div>
        </template>
        <template v-slot:item.talento="{ item }">
          <v-tooltip top color="teal darken-2">
            <template v-slot:activator="{ on, attrs }">
              <div
                style="cursor: pointer"
                v-bind="attrs"
                v-on="on"
                @click="openDialogCuadrante(item)"
              >
                {{ item.talento }}
                <v-icon color="teal darken-4"> mdi-crop-square </v-icon>
              </div>
            </template>
            <label class="text-uppercase font-weight-bold white--text"
              >Ver Cuadrante</label
            >
          </v-tooltip>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip left color="blue lighten-4">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="blue darken-4"
                v-bind="attrs"
                v-on="on"
                @click="abrirFormulario(item)"
              >
                mdi-pencil-box-outline
              </v-icon>
            </template>
            <label class="text-uppercase font-weight-bold primary--text"
              >Editar</label
            >
          </v-tooltip>
          <v-tooltip top color="pink lighten-4">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="pink darken-4"
                v-bind="attrs"
                v-on="on"
                @click="openDialogRemove(item)"
              >
                mdi-delete-empty-outline
              </v-icon>
            </template>
            <label class="text-uppercase font-weight-bold pink--text"
              >Remover</label
            >
          </v-tooltip>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <v-simple-table class="px-5">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">GPTW</th>
                    <th class="text-left">Porcentaje Satisfacción</th>
                    <th class="text-left">360</th>
                    <th class="text-left">Cultura</th>
                    <th class="text-left">Puntos Eval.</th>
                    <!-- <th class="text-left">Nivel Obj.</th>
                    <th class="text-left">Nivel Cultura</th> -->
                    <th class="text-left">Año</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{{ item.gptw }}</td>
                    <td>{{ item.porcentajeSatisfaccion }}</td>
                    <td>{{ item.puntos360 }}</td>
                    <td>{{ item.cultura }}</td>
                    <td>{{ item.puntosEval }}</td>
                    <!-- <td>{{ item.nivelObj }}</td>
                    <td>{{ item.nivelCult }}</td> -->
                    <td>{{ item.anio }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </td>
        </template>
      </v-data-table>

      <cargar-mapa-de-talento
        v-if="openCargaArchivo"
        :dialog="openCargaArchivo"
        @cierraDialog="cierraDialog"
      ></cargar-mapa-de-talento>
    </v-card-text>
    <confirm-dialog-reclutamiento
      :showConfirm="dialogRemove"
      :question="question"
      @cierraConfirm="cierraConfirm"
    >
    </confirm-dialog-reclutamiento>
    <form-mapa-de-talento
      v-if="dialogForm"
      :dialog="dialogForm"
      :item="item"
      @cierraForm="cierraForm"
    ></form-mapa-de-talento>
    <cuadrante-mapa-de-talento
      v-if="dialogCuadrante"
      :dialog="dialogCuadrante"
      :item="item"
      @cierraCuadrante="cierraCuadrante"
    ></cuadrante-mapa-de-talento>
    <SnackBarErrorIncidencias
      v-if="muestraBarra"
      :muestraBarra="muestraBarra"
      :texto="textoBarra"
      :color="colorBarra"
      @cierraBarra="muestraBarra = false"
    />
  </v-card>
</template>

<script>
import axios from "axios";
import VueJsonToCsv from "vue-json-to-csv";
import { deleteToken } from "../../../../../store/Funciones/funciones";
import CargarMapaDeTalento from "./CargarMapaDeTalento.vue";
import ConfirmDialogReclutamiento from "../../../AdmnVacantes/components/ConfirmDialogReclutamiento.vue";
import SnackBarErrorIncidencias from "../../../General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue";
import FormMapaDeTalento from "./FormMapaDeTalento.vue";
import CuadranteMapaDeTalento from "./CuadranteMapaDeTalento.vue";
export default {
  name: "lista-mapa-de-talento",
  props: [],
  components: {
    CargarMapaDeTalento,
    VueJsonToCsv,
    ConfirmDialogReclutamiento,
    SnackBarErrorIncidencias,
    FormMapaDeTalento,
    CuadranteMapaDeTalento,
  },
  data() {
    return {
      textoBarra: "",
      colorBarra: "",
      muestraBarra: false,

      search: "",
      loadingData: true,
      mapaDeTalento: [],
      headers: [
        { value: "emplid", text: "Numero Colaborador" },
        { value: "EMP_NOMEMP", text: "Nombre" },
        { value: "puestoDesc", text: "Descripcion puesto" },
        { value: "talento", text: "Cuadrante" },
        { value: "puntosObj", text: "Puntos Obj.", align: "end" },
        { value: "puntosMejora", text: "Puntos Mejora", align: "end" },
        { value: "puntosGL", text: "Puntos GL", align: "end" },
        { value: "puntosProyectos", text: "Puntos Proyectos", align: "end" },
        { value: "totalDesempenio", text: "Total Desempeño", align: "end" },
        { value: "actions", text: "Acciones", align: "center" },
        { text: "", value: "data-table-expand" },
      ],
      expanded: [],
      headers_to_export: {
        emplid: { title: "emplid" },
        EMP_NOMEMP: { title: "Nombre" },
        puestoDesc: { title: "Descripción" },
        puntosObj: { title: "Obj." },
        puntosMejora: { title: "Mejora" },
        puntosGL: { title: "GL" },
        puntosProyectos: { title: "Proyectos" },
        totalDesempenio: { title: "Total Desempeño" },
        gptw: { title: "GPTW" },
        porcentajeSatisfaccion: { title: "% Satisfacción" },
        puntos360: { title: "360" },
        cultura: { title: "Cultura" },
        puntosEval: { title: "Puntos Eval" },
        nivelObj: { title: "nivel Obj" },
        nivelCult: { title: "Nivel Cultura" },
        cuadrante: { title: "Cuadrante" },
        anio: { title: "Año" },
      },
      openCargaArchivo: false,
      dialogRemove: false,
      item: [],
      question: "",
      dialogForm: false,
      dialogCuadrante: false,
    };
  },
  created() {},
  mounted() {
    this.getInfoMapaDeTalento();
  },
  methods: {
    getInfoMapaDeTalento() {
      const headers = { Authorization: localStorage.getItem("token") };
      return new Promise((res) => {
        axios
          .get(
            `${this.$store.getters.getHostNode}/api/get-info-mapa-de-talento`,
            { headers }
          )
          .then((response) => {
            if (response.data.status == "OK") {
              res(response.data.data);
              this.mapaDeTalento = response.data.data;
            } else if (response.data.status == "EXPRESSION") {
              deleteToken();
            } else {
              res([]);
            }
          })
          .finally(() => {
            this.loadingData = false;
          });
      });
    },

    async actualizarTabla() {
      this.loadingData = true;
      this.mapaDeTalento = [];
      await this.getInfoMapaDeTalento();
    },
    dialogCargarArchivo() {
      this.openCargaArchivo = true;
    },

    openDialogRemove(item) {
      this.dialogRemove = true;
      this.question =
        "¿Está seguro de remover los datos del colaborador [" +
        item.emplid +
        "] " +
        item.EMP_NOMEMP +
        "?";
      this.item = item;
    },

    cierraConfirm(response) {
      if (response) {
        this.loadingData = true;
        this.borrar();
      }
      this.dialogRemove = false;
    },

    borrar() {
      const headers = {
        Authorization: localStorage.getItem("token"),
      };
      const params = {
        folio: localStorage.getItem("Folio"),
        item: this.item,
      };
      return new Promise((res) => {
        axios
          .post(
            `${this.$store.getters.getHostNode}/api/delete-data-mapa-de-talento`,
            params,
            {
              headers: headers,
            }
          )
          .then((response) => {
            this.loadingData = false;
            if (response.data.status == "OK") {
              res(response.data);
              this.textoBarra = response.data.message;
              this.colorBarra = "success";
              this.muestraBarra = true;
              this.clean();
              this.loadingData = true;
              this.getInfoMapaDeTalento();
            } else if (response.data.status == "ERROR") {
              res(response.data);
              this.textoBarra = response.data.message;
              this.colorBarra = "error";
              this.muestraBarra = true;
            } else if (response.data.status == "EXPRESSION") {
              // deleteToken();
              console.log("deleteToken");
            }
          });
      });
    },
    clean() {
      this.item = [];
      this.mapaDeTalento = [];
    },

    abrirFormulario(item) {
      this.item = item;
      this.dialogForm = true;
    },

    cierraForm(response) {
      this.item = "";
      this.dialogForm = false;
      if (response == "OK") {
        this.mapaDeTalento = [];
        this.loadingData = true;
        this.getInfoMapaDeTalento();
      }
    },
    
    cierraDialog(response) {
      this.item = "";
      this.openCargaArchivo = false;
      if (response == "OK") {
        this.mapaDeTalento = [];
        this.loadingData = true;
        this.getInfoMapaDeTalento();
      }
    },

    openDialogCuadrante(item) {
      this.dialogCuadrante = true;
      this.item = item;
    },

    cierraCuadrante(response) {
      this.item = "";
      this.dialogCuadrante = false;
      console.log(response);
      // if (response == "OK") {
      //   this.mapaDeTalento = [];
      //   this.loadingData = true;
      //   this.getInfoMapaDeTalento();
      // }
    },
  },
};
</script>