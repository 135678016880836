<template>
  <div>
    <v-sheet v-if="loadingData">
      <v-row class="fill-height py-5" align-content="center" justify="center">
        <v-col class="subtitle-1 text-center" cols="12">
          Obteninendo información...
        </v-col>
        <v-col cols="6" class="center-item-in-div">
          <v-progress-circular
            :size="70"
            :width="8"
            color="primary"
            indeterminate
          ></v-progress-circular>
        </v-col>
      </v-row>
    </v-sheet>
    <v-card class="backgroud-color-card-reclutamiento rounded-lg rounded-t-0 pa-5" v-else>
      <v-sheet
        min-height="50"
        width="100%"
        class="neumorphism-card-reclutamiento center-item-in-div-column rounded-xl pa-4"
      >
      <v-row v-if="candidato" style="width: 100% !important;">
        <v-col :cols="isSmallDisplay ? 12 : 6">
          <div class="blue-grey--text text--lighten-2 text-center">Vacante</div>
          <v-simple-table dense class="rounded-xl px-2 neumorphism-card-reclutamiento" style="background-color: transparent; width: 100% !important;">
            <tbody>
              <tr><th class="font-weight-bold blue-grey--text text--darken-2">Nombre</th><td>{{ candidato.nombre }} ({{ candidato.Jobcode }})</td></tr>
              <tr><th class="font-weight-bold blue-grey--text text--darken-2">Departamento</th><td>{{ candidato.departamento }}</td></tr>
              <tr><th class="font-weight-bold blue-grey--text text--darken-2">Área</th><td>{{ candidato.area }}</td></tr>
              <tr><th class="font-weight-bold blue-grey--text text--darken-2">Requisitor</th><td>{{ candidato.requisitor }}</td></tr>
              <tr><th class="font-weight-bold blue-grey--text text--darken-2">Sueldo de contratación</th><td>{{ formatMXN(parseFloat(candidato.contratacion)) }}</td></tr>
              <tr><th class="font-weight-bold blue-grey--text text--darken-2">Sueldo Máximo</th><td>{{ formatMXN(parseFloat(candidato.maximo)) }}</td></tr>
              <tr><th class="font-weight-bold blue-grey--text text--darken-2">Sueldo Mínimo</th><td>{{ formatMXN(parseFloat(candidato.minimo)) }}</td></tr>
              <tr><th class="font-weight-bold blue-grey--text text--darken-2">Razón</th><td>{{ candidato.razon }}</td></tr>
              <tr><th class="font-weight-bold blue-grey--text text--darken-2">Tipo</th><td>{{ candidato.tipo }}</td></tr>
            </tbody>
          </v-simple-table>
        </v-col>
        <v-col :cols="isSmallDisplay ? 12 : 6">
          <!-- {{ candidato }} -->
          <v-row dense>
            <v-col cols="12">
              <div class="blue-grey--text text--lighten-2 text-center">Entrevistadores</div>
                <v-simple-table dense class="rounded-xl px-2 neumorphism-card-reclutamiento" style="background-color: transparent; width: 100% !important;">
                  <tbody>
                    <tr v-for="(entrevistador, index) in candidato.entrevistadores" :key="index">
                      <td class="text-center">{{ entrevistador.EMP_NOMEMP }} </td>
                    </tr>
                  </tbody>
                </v-simple-table>
            </v-col>
            <v-col v-show="candidato.comision === 1">
              <v-row dense>
                <v-col cols="4">
                  <div class="blue-grey--text text--darken-3 text-center">Comisión</div>
                  <v-card class="rounded-pill text-center neumorphism-card-reclutamiento py-2 px-3">
                    <div class="body-1 blue-grey--text text--darken-3">{{ formatMXN(candidato.comisionval)}}</div>
                  </v-card>
                </v-col>
                <v-col cols="4">
                  <div class="blue-grey--text text--darken-3 text-center">Garantía</div>
                  <v-card class="rounded-pill text-center neumorphism-card-reclutamiento py-2 px-3">
                    <div class="body-1 blue-grey--text text--darken-3">{{ formatMXN(candidato.comisiongar)}}</div>
                  </v-card>
                </v-col>
                <v-col cols="4">
                  <div class="blue-grey--text text--darken-3 text-center">Meses</div>
                  <v-card class="rounded-pill text-center neumorphism-card-reclutamiento py-2 px-3">
                    <div class="body-1 blue-grey--text text--darken-3">{{ candidato.comisionmes }}</div>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          
        </v-col>
      </v-row>
    </v-sheet>
    </v-card>
    <SnackBar
      :muestaBarra="muestraBarra"
      :texto="textoBarra"
      :color="colorBarra"
      @cierraBarra="muestraBarra = false"
    />
  </div>
</template>

<script>
import axios from "axios";
import { deleteToken } from "../../../../../store/Funciones/funciones";
import SnackBar from "../../../General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias";
export default {
  name: "informacion-vacante-candidato",
  props: ["idCandidato"],
  components: {SnackBar},
  data() {
    return {
      loadingData: true,
      candidato: {},
      muestraBarra: false,
      textoBarra: "",
      colorBarra: "",
    };
  },
  computed: {
    isSmallDisplay() {
      return this.$vuetify.breakpoint.name == "xs" ||
        this.$vuetify.breakpoint.name == "xs"
        ? true
        : false;
    },
  },
  created() {
    this.getDetalleVacanteCandidato();
  },
  methods: {
    getDetalleVacanteCandidato() {
      let params = {
        Opcion: 4,
        token: localStorage.getItem("token"),
        idCandidato: this.idCandidato,
      };
      axios
        .post(
          `${this.$store.getters.getHost}/Reclutamiento/ws-admin-candidatos.php`,
          params
        )
        .then((response) => {
          this.loadingData = false;
          if (response.data.status != "EXPSESSION") {
            this.candidato = response.data.candidato;
          } else {
            deleteToken();
          }
        })
        .catch((e) => {
          this.colorBarra = "error";
          this.textoBarra = e;
          this.muestaBarra = true;
        });
    },

    formatMXN(cadena){
      return cadena.toLocaleString("es-MX", { style: "currency", currency: "MXN",})
    }
  },
};
</script>
