<template>
  <Bar
    :chart-options="chartOptions"
    :chart-data="chartData"
    :chart-id="chartId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :width="width"
    :height="height"
  />
</template>

<script>
import { Bar } from "vue-chartjs/legacy";
import axios from "axios";
// import { METHODS } from "http";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
);

export default {
  name: "BarChart",
  components: {
    Bar,
  },
  props: {
    chartId: {
      type: String,
      default: "bar-chart",
    },
    datasetIdKey: {
      type: String,
      default: "label",
    },
    width: {
      type: Number,
      default: 400,
    },
    height: {
      type: Number,
      default: 160,
    },
    cssClasses: {
      default: "",
      type: String,
    },
    styles: {
      type: Object,
      default: () => {},
    },
    plugins: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      chartData: {
        labels: [],
        datasets: [],
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
      },
      abiertas: [],
      cerradas: [],
    };
  },

  mounted() {
    this.getDataReclu();
  },

  methods: {
    getDataReclu() {
      const headers = {
        Authorization: localStorage.getItem("token"),
      };

      return new Promise((res) => {
        axios
          .get(`${this.$store.getters.getHostNode}/api/get-data-reclu`, {
            headers: headers,
          })
          .then((response) => {
            res(response.data.abiertas);
            if (response.data.status != "EXPSESSION") {
              // this.abiertas = response.data.abiertas;

              response.data.abiertas.map((item) => {
                this.chartData.labels.push(item.mes);
                this.abiertas.push(item.total);
              });

              response.data.cerradas.map((item) => {
                this.cerradas.push(item.total);
              });

              this.fillDatasets();

              // this.cerradas = response.data.abiertas;
              // this.loadInformationVacantes = false;
              // this.createJSONtoExport();
            }
          })
          .finally(() => {
            console.log("finally function reporte");
          });
      });
    },

    // getDataReclu() {
    //   let params = {
    //     Opcion: 2,
    //     token: localStorage.getItem("token"),
    //   };
    //   axios
    //     .post(
    //       `${this.$store.getters.getHost}/Reclutamiento/ws-admin-reclu.php`,
    //       params
    //     )
    //     .then((response) => {
    //       if (response.data.status != "EXPSESSION") {
    //         // this.abiertas = response.data.abiertas;

    //         response.data.abiertas.map((item) => {
    //           this.chartData.labels.push(item.mes);
    //           this.abiertas.push(item.total);
    //         });

    //         response.data.cerradas.map((item) => {
    //           this.cerradas.push(item.total);
    //         });

    //         this.fillDatasets();

    //         // this.cerradas = response.data.abiertas;
    //         // this.loadInformationVacantes = false;
    //         // this.createJSONtoExport();
    //       }
    //     });
    // },
    fillDatasets() {
      this.chartData.datasets.push({
        label: "Vacantes abiertas por mes",
        backgroundColor: "#0078B4",
        data: this.abiertas,
      });
      this.chartData.datasets.push({
        label: "Vacantes cerradas por mes",
        backgroundColor: "#787878",
        data: this.cerradas,
      });
    },
  },
};
</script>
