import MuestraComisionistas from './Componentes/MuestraComisionistas.vue'

export default {
  name: 'comisionistas',
  components: { MuestraComisionistas },

  props: [],
  data () {
    return {

    }
  },
  computed: {

  },
  mounted () {

  },
  methods: {

  }
}


