<template>
    <v-dialog v-model="updateUsers" persistent max-width="800px">
        <v-card class="rounded-lg">
            <v-card-title class="primary white--text subtitle-1">
                <v-spacer></v-spacer>
                <v-icon dark @click="closeDialog">mdi-close</v-icon>
            </v-card-title>
            <v-card-text class="pt-4">
                <v-form ref="form" lazy-validation>
                    <v-row dense>
                        <v-col cols="6">

                            <v-autocomplete v-if="isAgregar" v-model="selectedProveedor" :items="localProveedores"
                                item-text="NOMBRE" item-value="NO_PROVEEDOR" prepend-inner-icon="mdi-account" dense
                                outlined class="rounded-lg py-0 my-0" label="Selecciona Proveedor">
                            </v-autocomplete>


                            <v-text-field v-else v-model="nombre" prepend-inner-icon="mdi-account" dense outlined
                                class="rounded-lg py-0 my-0" label="Nombre" counter="60">
                            </v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field v-model="correo" prepend-inner-icon="mdi-at" dense outlined
                                class="rounded-lg py-0 my-0" label="Correo" counter="50">
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row dense>
                        <v-col cols="5">
                            <v-text-field v-model="nickname" prepend-inner-icon="mdi-account" dense outlined
                                class="rounded-lg py-0 my-0" label="NickName" counter="20">
                            </v-text-field>
                        </v-col>
                        <v-col cols="5">
                            <v-text-field v-model="password" prepend-inner-icon="mdi-account-key" dense outlined
                                class="rounded-lg py-0 my-0" label="Contraseña" counter="50" required>
                            </v-text-field>
                        </v-col>
                        <v-col cols="2">
                            <v-btn class="rounded-lg" dark block color="primary" @click="generateText">Generar</v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn class="rounded-lg" dark color="teal darken-1" depressed @click="saveData" :loading="loadingSave">
                    Guardar
                </v-btn>
                
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import axios from 'axios';
export default {
    props: {
        proveedoreshijo: {
            type: Array,
            default: () => []
        },
        showDialog: {
            type: Boolean,
            required: true
        },
        providerData: {
            type: Object,
            default: () => ({})
        },
        isAgregar: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            updateUsers: this.showDialog,
            localProveedores: [],
            selectedProveedor: null,
            nombre: this.providerData.Nombre || '',
            correo: this.providerData.Correo || '',
            nickname: this.providerData.Nickname || '',
            password: this.providerData.Password || '',
            loadingSave: false 
        };
    },
    watch: {
        providerData: {
            immediate: true,
            deep: true,
            handler(newVal) {

                if (newVal && Object.keys(newVal).length > 0) {
                    this.nombre = newVal.Nombre || '';
                    this.correo = newVal.Correo || '';
                    this.nickname = newVal.Nickname || `UR${newVal.No_proveedor}` || '';
                }
            }
        },
        proveedoreshijo: {
            immediate: true,
            handler(newVal) {
                this.localProveedores = [...newVal];
            }
        },
        showDialog(val) {
            this.updateUsers = val;
        },
        updateUsers(val) {
            this.$emit('update:showDialog', val);
        },
        selectedProveedor(val) {
            if (val) {
                const proveedor = this.localProveedores.find(item => item.NO_PROVEEDOR === val);
                if (proveedor) {
                    this.nombre = proveedor.NOMBRE;
                    this.correo = proveedor.CORREO;
                    this.nickname = `UR${proveedor.NO_PROVEEDOR}`;
                    this.rfc = proveedor.RFC;
                    this.generateText();
                }
            }
        }

    },
    methods: {
        generateText() {
            let text = '';
            let hasSpecialChar = false;
            let hasNumber = false;
            const minLength = 8;
            const validChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789@$*%#';

            while (text.length < minLength || !hasSpecialChar || !hasNumber || !/^[a-zA-Z]/.test(text)) {
                text = '';
                hasSpecialChar = false;
                hasNumber = false;
                for (let i = 0; i < minLength; i++) {
                    const char = validChars.charAt(Math.floor(Math.random() * validChars.length));
                    text += char;
                    if (!hasSpecialChar && '@$*%#'.includes(char)) {
                        hasSpecialChar = true;
                    }
                    if (!hasNumber && '0123456789'.includes(char)) {
                        hasNumber = true;
                    }
                }
            }

            this.password = text;
        },
        resetFields() {
            this.selectedProveedor = null;
            this.nombre = '';
            this.correo = '';
            this.nickname = '';
            this.password = '';
        },
        closeDialog() {
            this.updateUsers = false;
            this.resetFields();
        },
        async saveData() {
            if (!this.nombre || !this.correo || !this.nickname || !this.password || !this.nombre) {
        this.$emit('showSnackbar', 'Por favor, complete todos los campos.');
        return; 
    }
        this.loadingSave = true;  
        const usuario = {
                nombre: this.nombre,
                correo: this.correo,
                nickname: this.nickname,
                password: this.password,
                noProveedor: this.selectedProveedor || this.providerData.No_proveedor, 
                Opcion: 1,
                token: localStorage.getItem('token')
            };
            try {
                const response = await axios.post(`${this.$store.getters.getHost}/Login/ws-usuariosProveedores.php`, usuario );    
            if (response.data.status == 'OK') {
                this.$emit('showSnackbar', response.data.data.comentario);
                this.$emit('reloadProveedores');
                this.closeDialog();
            }else {
                this.$emit('showSnackbar', 'El usuario no se guardó correctamente.');
                this.closeDialog();
            }
        } catch (error) {
            console.error("Error en la solicitud:", error);
        } finally {
            this.loadingSave = false;
        }
    },
        async getProveedoresJD() {
            try {
                const response = await axios.get(`${this.$store.getters.getHostNode}/api/getProveedoresJD`, {
                    headers: {
                        Authorization: localStorage.getItem("token"),
                    },
                });
                if (Array.isArray(response.data.data)) {
                    this.localProveedores = response.data.data.map(item => ({
                        NOMBRE: item.NOMBRE,
                        NO_PROVEEDOR: item.NO_PROVEEDOR,
                        CORREO: item.CORREO,
                        RFC: item.RFC
                    }));
                } else {
                    console.error('Error: La data no es un array');
                }
            } catch (error) {
                console.error('Error al cargar proveedores:', error);
            }
        }
    },
    mounted() {
        this.getProveedoresJD();
    }
};
</script>
