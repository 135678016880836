<template>
  <div>
    <v-dialog
      v-model="verEmpleados"
      max-width="600"
      persistent
      scrollable
      content-class="fix-border-dialog"
    >
      <v-card>
        <v-card-title class="primary">
          <div class="white--text overline font-weight-bold">
            Colaboradores a cargo
          </div>
          <v-spacer></v-spacer>
          <v-icon dark @click="$emit('cierraVerSubordinados', acciones)">
            mdi-close
          </v-icon>
        </v-card-title>
        <v-sheet class="py-2 center-item-in-div">
          <v-btn
            color="primary rounded-lg"
            class="body-2 mx-1"
            dark
            x-large
            :loading="loadingBtnMasivo"
            @click="
              typeAction = 2;
              loadingBtnMasivo = true;
              showFindNewMembers = true;
            "
          >
            <v-icon left>mdi-account-group</v-icon>
            Asignar a otro <br> responsable
          </v-btn>
          <v-btn
            color="primary rounded-lg"
            class="body-2 mx-1"
            dark
            x-large
            :loading="loadingAddSub"
            @click="
              typeAction = 3;
              loadingAddSub = true;
              showFindNewMembers = true;
            "
          >
            <v-icon left>mdi-account-plus</v-icon>
            Añadir persona <br> a cargo
          </v-btn>
        </v-sheet>
        <v-data-table
          dense
          :loading="loadingEmployees"
          :headers="headers"
          :items="empleados"
        >
          <template v-slot:item.EMP_STATUS="{ item }">
            <v-chip
              :color="
                item.EMP_STATUS === 'A'
                  ? 'teal darken-1'
                  : item.EMP_STATUS === null
                  ? 'grey'
                  : 'pink darken-3'
              "
              small
              dark
            >
              {{
                item.EMP_STATUS === "A"
                  ? "Activo"
                  : item.EMP_STATUS === null
                  ? "Desconocido"
                  : "Inactivo"
              }}
            </v-chip>
          </template>
          <template v-slot:item.Actions="{ item }">
            <v-icon
              color="cyan darken-3"
              class="mx-1"
              @click="
                employeeAux = item.Empleado;
                typeAction = 1;
                showFindNewMembers = true;
              "
              >mdi-pencil</v-icon
            >
            <v-icon
              color="pink darken-3"
              class="mx-1"
              @click="
                employeeAux = item.Empleado;
                showConfirm = true;
              "
              >mdi-delete</v-icon
            >
          </template>
        </v-data-table>
      </v-card>

      <BuscaColaboradoresActivos
        v-if="showFindNewMembers"
        :showFindNewMembers="showFindNewMembers"
        @returnColaborrador="getNewResponsable"
      />

      <ConfirmDialog
        v-if="showConfirm"
        :showConfirm="showConfirm"
        :question="question"
        @cierraConfirm="validaRespuesta"
      />
    </v-dialog>

    <SnackBar
      v-if="muestraBarra"
      :muestraBarra="muestraBarra"
      :color="colorBarra"
      :texto="textoBarra"
      @cierraBarra="muestraBarra = false"
    />
  </div>
</template>

<script>
import axios from "axios";
import BuscaColaboradoresActivos from "../../../../../components/BuscaColaboradoresActivos.vue";
import SnackBar from "../../../General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias";
import ConfirmDialog from "../../../AdmnVacantes/components/ConfirmDialogReclutamiento.vue";

export default {
  name: "ver-empleados-por-responsable",
  props: ["verEmpleados", "idEmpleado"],
  components: { BuscaColaboradoresActivos, SnackBar, ConfirmDialog },
  data() {
    return {
      empleados: [],
      headers: [
        {
          text: "EMPLID",
          value: "Empleado",
          class:
            "text-uppercase font-weight-bold blue-grey--text text--darken-2",
        },
        {
          text: "NOMBRE",
          value: "EMP_NOMEMP",
          class:
            "text-uppercase font-weight-bold blue-grey--text text--darken-2",
        },
        {
          text: "ESTATUS",
          value: "EMP_STATUS",
          sortable: false,
          align: "center",
          class:
            "text-uppercase font-weight-bold blue-grey--text text--darken-2",
        },
        {
          text: "ACIONES",
          value: "Actions",
          sortable: false,
          align: "center",
          class:
            "text-uppercase font-weight-bold blue-grey--text text--darken-2",
        },
      ],
      employeeAux: -1,
      acciones: 0,
      typeAction: 0,
      textoBarra: "",
      colorBarra: "",
      question: "¿Realmente deseas eliminar este registro?",
      muestraBarra: false,
      loadingEmployees: true,
      showFindNewMembers: false,
      showConfirm: false,
      loadingBtnMasivo: false,
      loadingAddSub: false,
    };
  },
  async mounted() {
    this.empleados = await this.getColaboradores();
  },
  methods: {
    muestrSnackBar(texto, color) {
      this.textoBarra = texto;
      this.colorBarra = color;
      this.muestraBarra = true;
    },
    getColaboradores() {
      const params = {
        Opcion: 11,
        token: localStorage.getItem("token"),
        responsable: this.idEmpleado,
      };

      return new Promise((resolve, reject) => {
        axios
          .post(
            `${this.$store.getters.getHost}/RelacionesLaborales/RelacionEmpleadoResponsable/ws-empleado-responsable.php`,
            params
          )
          .then((response) => {
            if (response.data.status === "OK") {
              resolve(response.data.respuesta);
            } else {
              resolve([]);
            }
          })
          .catch((e) => {
            reject(e);
          })
          .finally(() => {
            this.loadingEmployees = false;
          });
      });
    },
    getNewResponsable(newResponsable) {
      if (newResponsable === null) {
        this.showFindNewMembers = false;
      } else {
        this.showFindNewMembers = false;
        this.loadingEmployees = true;
        if (this.typeAction === 1) {
          this.editResponsable(newResponsable.EMPLID);
        } else if (this.typeAction === 2) {
          this.editResponsableMasivo(newResponsable.EMPLID);
        } else if (this.typeAction === 3) {
          this.addSuborninado(newResponsable.EMPLID);
        } else {
            this.loadingAddSub = false;
            this.loadingBtnMasivo = false;
        }
      }
    },
    editResponsable(newResponsableId) {
      const params = {
        Opcion: 14,
        token: localStorage.getItem("token"),
        empleado: this.employeeAux,
        responsableActual: this.idEmpleado,
        responsableNuevo: newResponsableId,
      };

      axios
        .post(
          `${this.$store.getters.getHost}/RelacionesLaborales/RelacionEmpleadoResponsable/ws-empleado-responsable.php`,
          params
        )
        .then(async (response) => {
          if (response.data.status === "OK") {
            if (response.data.status == "OK") {
              if (response.data.respuesta.status_tran == "OK") {
                this.muestrSnackBar(
                  response.data.respuesta.message,
                  "teal darken-1"
                );
                this.acciones++;
                this.empleados = await this.getColaboradores();
              } else {
                this.muestrSnackBar(
                  response.data.respuesta.message,
                  "pink darken-3"
                );
              }
            }
          } else {
            this.muestrSnackBar(
              "Ocurrio un error. No se modificó el registro",
              "pink darken-3"
            );
          }
        })
        .catch((e) => {
          this.muestrSnackBar(e, "pink darken-3");
        })
        .finally(() => {
          this.loadingEmployees = false;
        });
    },
    validaRespuesta(respuesta) {
      if (!respuesta) {
        this.showConfirm = false;
      } else {
        this.showConfirm = false;
        this.loadingEmployees = true;
        this.deleteEmployee();
      }
    },
    deleteEmployee() {
      const params = {
        Opcion: 15,
        token: localStorage.getItem("token"),
        empleado: this.employeeAux,
      };

      axios
        .post(
          `${this.$store.getters.getHost}/RelacionesLaborales/RelacionEmpleadoResponsable/ws-empleado-responsable.php`,
          params
        )
        .then(async (response) => {
          if (response.data.status === "OK") {
            if (response.data.status == "OK") {
              if (response.data.respuesta.status_tran == "OK") {
                this.muestrSnackBar(
                  response.data.respuesta.message,
                  "teal darken-1"
                );
                this.acciones++;
                this.empleados = await this.getColaboradores();
              } else {
                this.muestrSnackBar(
                  response.data.respuesta.message,
                  "pink darken-3"
                );
              }
            }
          } else {
            this.muestrSnackBar(
              "Ocurrio un error. No se modificó el registro",
              "pink darken-3"
            );
          }
        })
        .catch((e) => {
          this.muestrSnackBar(e, "pink darken-3");
        })
        .finally(() => {
          this.loadingEmployees = false;
        });
    },
    editResponsableMasivo(newResponsableId) {
      const params = {
        Opcion: 16,
        token: localStorage.getItem("token"),
        responsableActual: this.idEmpleado,
        responsableNuevo: newResponsableId,
      };

      axios
        .post(
          `${this.$store.getters.getHost}/RelacionesLaborales/RelacionEmpleadoResponsable/ws-empleado-responsable.php`,
          params
        )
        .then(async (response) => {
          if (response.data.status === "OK") {
            if (response.data.status == "OK") {
              if (response.data.respuesta.status_tran == "OK") {
                this.muestrSnackBar(
                  response.data.respuesta.message,
                  "teal darken-1"
                );
                this.acciones++;
                this.empleados = await this.getColaboradores();
              } else {
                this.muestrSnackBar(
                  response.data.respuesta.message,
                  "pink darken-3"
                );
              }
            }
          } else {
            this.muestrSnackBar(
              "Ocurrio un error. No se modificó el registro",
              "pink darken-3"
            );
          }
        })
        .catch((e) => {
          this.muestrSnackBar(e, "pink darken-3");
        })
        .finally(() => {
          this.loadingEmployees = false;
          this.loadingBtnMasivo = false;
        });
    },
    addSuborninado(newEmployeeId) {
      const params = {
        Opcion: 17,
        token: localStorage.getItem("token"),
        responsable: this.idEmpleado,
        empleado: newEmployeeId,
      };

      axios
        .post(
          `${this.$store.getters.getHost}/RelacionesLaborales/RelacionEmpleadoResponsable/ws-empleado-responsable.php`,
          params
        )
        .then(async (response) => {
          if (response.data.status === "OK") {
            if (response.data.status == "OK") {
              if (response.data.respuesta.status_tran == "OK") {
                this.muestrSnackBar(
                  response.data.respuesta.message,
                  "teal darken-1"
                );
                this.acciones++;
                this.empleados = await this.getColaboradores();
              } else {
                this.muestrSnackBar(
                  response.data.respuesta.message,
                  "pink darken-3"
                );
              }
            }
          } else {
            this.muestrSnackBar(
              "Ocurrio un error. No se modificó el registro",
              "pink darken-3"
            );
          }
        })
        .catch((e) => {
          this.muestrSnackBar(e, "pink darken-3");
        })
        .finally(() => {
            this.loadingAddSub = false;
        });
    },
  },
};
</script>
