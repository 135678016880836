<template>
  <v-dialog
    v-model="muestraSolicitantes"
    persistent
    content-class="fix-border-dialog"
    max-width="880"
  >
    <v-card class="rounded-xl">
      <v-toolbar dense color="primary rounded-xl rounded-b-0">
        <label class="overline font-weight-bold white--text"
          >Solicitantes por departamento [{{ deptid }}]: {{ idRegistro }}</label
        >
        <v-spacer></v-spacer>
        <v-icon dark @click="$emit('ocultaSolicitantes')">mdi-close</v-icon>
      </v-toolbar>
      <v-card-text class="pt-5">
        <v-data-table
          :headers="headers"
          :items="solicitantes"
          dense
          :footer-props="{
            'items-per-page-text': 'Elementos por página',
            pageText: '{0}-{1} de {2}',
          }"
          no-data-text="No hay registros"
          loading-text="Cargando registros..."
          :loading="loadingData"
        >
          <template v-slot:top>
            <div style="display: flex; justify-content: space-between;">
              <v-text-field
                v-model="search"
                dense
                outlined
                class="rounded-pill"
                prepend-inner-icon="mdi-magnify"
                placeholder="Buscar..."
              ></v-text-field>
              <v-spacer></v-spacer>
              <div>
                <v-tooltip bottom color="blue darken-2">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      depressed
                      fab
                      small
                      outlined
                      v-bind="attrs"
                      v-on="on"
                      class=" mr-2"
                      color="blue darken-2"
                      @click="showFindNewMembers = true"
                    >
                      <v-icon>mdi-account-plus</v-icon>
                    </v-btn>
                  </template>
                  <span>Agregar solicitante</span>
                </v-tooltip>
                <v-tooltip bottom color="blue-grey darken-2">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      depressed
                      fab
                      small
                      outlined
                      class="mr-2"
                      v-bind="attrs"
                      v-on="on"
                      color="blue-grey darken-2"
                      @click="updateTable"
                    >
                      <v-icon>mdi-reload</v-icon>
                    </v-btn>
                  </template>
                  <span>Actualizar tabla</span>
                </v-tooltip>
              </div>
            </div>
          </template>
          <template v-slot:item.EMP_STATUS="{ item }">
            <label
              :class="item.EMP_STATUS == 'A' ? 'teal--text' : 'pink--text'"
              class="overline caption font-weight-bold"
            >
              {{ item.EMP_STATUS == "A" ? "Activo" : "Baja" }}
            </label>
          </template>
          <template v-slot:item.action="{ item }">
            <v-tooltip bottom color="error">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="error"
                  x-small
                  depressed
                  class="rounded-pill"
                  v-bind="attrs"
                  v-on="on"
                  @click="idUsuarioAux = item.id; showConfirm = true;"
                >
                  <v-icon>mdi-minus-thick</v-icon>
                </v-btn>
              </template>
              <span>Quitar permisos</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card-text>

      <SnackBar v-if="muestraBarra"
      :muestraBarra="muestraBarra"
      :color="colorBarra"
      :texto="textoBarra"
      @cierraBarra="muestraBarra = false"/>

      <Confirm :showConfirm="showConfirm" question="¿Realmente deseas eliminar al usuario?" @cierraConfirm="validateDeleteUser"/>

      <BuscaColaboradoresActivos
        v-if="showFindNewMembers"
        :showFindNewMembers="showFindNewMembers"
        @returnColaborrador="returnColaborrador"
      />
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
import { deleteToken } from "../../../../../store/Funciones/funciones";
import BuscaColaboradoresActivos from "../../../../../components/BuscaColaboradoresActivos.vue";
import SnackBar from "../../../General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue"
import Confirm from "../../../AdmnVacantes/components/ConfirmDialogReclutamiento.vue"

export default {
  props: ["muestraSolicitantes", "deptid", "idRegistro"],
  components: { BuscaColaboradoresActivos, SnackBar, Confirm },
  data() {
    return {
      solicitantes: [],
      headers: [
        { value: "id", text: "id", class: "overline" },
        {
          value: "solicitante",
          text: "solicitante",
          align: "center",
          class: "overline",
        },
        { value: "EMP_NOMEMP", text: "nombre", class: "overline" },
        {
          value: "EMP_STATUS",
          text: "estatus",
          align: "center",
          class: "overline",
        },
        { value: "action", text: "", class: "overline" },
      ],
      loadingData: false,
      idUsuarioAux: null,
      showFindNewMembers: false,
      showConfirm: false,
      muestraBarra: false,
      colorBarra: "",
      textoBarra: "",
      search: "",
    };
  },
  async mounted() {
    await this.updateTable();
  },
  methods: {
    showSnackBar(texto, color = "error"){
      this.textoBarra = texto;
      this.colorBarra = color;
      this.muestraBarra = true;
    },
    async updateTable() {
      this.loadingData = true;
      this.solicitantes = await this.getDatosSolicitantesDepartamentos();
    },
    getDatosSolicitantesDepartamentos() {
      const headers = {
        Authorization: localStorage.getItem("token"),
      };

      return new Promise((res) => {
        axios
          .get(
            `${this.$store.getters.getHostNode}/api/descanso-laborado/solicitantes/departamento/${this.idRegistro}`,
            {
              headers,
            }
          )
          .then((response) => {
            if (response.data.status == "EXPSESSION") {
              deleteToken();
            } else if (response.data.status == "OK") {
              res(response.data.data);
            } else {
              res([]);
            }
          })
          .finally(() => {
            this.loadingData = false;
          });
      });
    },
    async returnColaborrador(colaborador) {
      this.showFindNewMembers = false;
      if (colaborador !== null) {
        const response = await this.setUser(colaborador.EMPLID);
        if(response.status === "OK" && response.data >= 1){
          this.showSnackBar("Insertado correctamente.", "success")
          await this.updateTable();
        } else if(response.status === "OK" && response.data == 0){
          this.showSnackBar("El colaborador ya es parte de caja de ahorro.")
        } else {
          this.showSnackBar("ERROR. No se inscribió al colaborador, intente más tarde.")
        }
      }
    },
    setUser(emplid) {
      const headers = {
        Authorization: localStorage.getItem("token"),
      };
      const params = {
        solicitante: emplid,
        idDepartamento: this.idRegistro
      };

      return new Promise((res) => {
        axios
          .post(
            `${this.$store.getters.getHostNode}/api/descanso-laborado/solicitantes/departamento`,
            params,
            {
              headers,
            }
          )
          .then(async (response) => {
            if (response.data.status == "EXPSESSION") {
              deleteToken();
            } else if (response.data.status == "OK") {
              res(response.data);
            } else {
              res(response.data);
            }
          });
      });
    },
    async validateDeleteUser(confirmacion) {
      this.showConfirm = false;
      if (confirmacion) {
        const response = await this.deleteUser();
        if (response.data > 0) {
          this.showSnackBar(
            "Éxito. Se eliminó al usuario seleccionado.",
            "success"
          );
          await this.updateTable()
        } else {
          this.showSnackBar(
            "Error. No se pudo eliminar al usuario, intente más tarde."
          );
        }
      }
    },
    deleteUser() {
      const headers = {
        Authorization: localStorage.getItem("token"),
      };
      const params = {
        idRegistro: this.idUsuarioAux
      };

      return new Promise((res) => {
        axios
          .delete(
            `${this.$store.getters.getHostNode}/api/descanso-laborado/solicitantes/departamento`,
            {
              data: params,
              headers: headers,
            }
          )
          .then(async (response) => {
            if (response.data.status == "EXPSESSION") {
              deleteToken();
            } else if (response.data.status == "OK") {
              res(response.data);
            } else {
              res(response.data);
            }
          });
      });
    },
  },
};
</script>
