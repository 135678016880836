var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"pa-3 rounded-xl elevation-0",attrs:{"color":"blue-grey lighten-5"}},[_c('v-data-table',{staticClass:"rounded-xl",attrs:{"headers":_vm.headers,"items":_vm.pases,"search":_vm.search,"no-data-text":"No se han registrado pases de ingreso","loading-text":"Cargando información...","loading":_vm.loadingPasses,"footer-props":{
        'items-per-page-text': 'Elementos por página',
        'pageText': '{0}-{1} de {2}',
    },"single-expand":true,"expanded":_vm.expanded,"show-expand":""},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"pa-2",staticStyle:{"display":"flex","justify-content":"space-between"}},[_c('v-text-field',{staticClass:"rounded-xl",staticStyle:{"max-width":"300px"},attrs:{"outlined":"","dense":"","clearable":"","prepend-inner-icon":"mdi-table-search","placeholder":"Buscar pase..."},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":"","color":"blue lighten-4"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"color":"blue lighten-4","small":"","fab":"","depressed":""},on:{"click":function($event){return _vm.getPasesPorEmpleado()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-reload")])],1)]}}])},[_c('label',{staticClass:"text-uppercase font-weight-bold primary--text"},[_vm._v("Actualizar tabla")])]),_c('v-btn',{staticClass:"rounded-xl",attrs:{"color":"primary"},on:{"click":_vm.addIngreso}},[_vm._v("Agregar")])],1)]},proxy:true},{key:"item.fecha_solicitud",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(![null, undefined].includes(item.fecha_solicitud) ? _vm.fixDate(item.fecha_solicitud.date.substring(0, 10)) + ' ' + item.fecha_solicitud.date.substring(11, 16) : '')+" ")]}},{key:"item.estatus",fn:function(ref){
    var item = ref.item;
return [_c('v-chip',{class:_vm.getClass(item.estatus),attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.getEstatusText(item.estatus))+" ")])]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":"","color":"cyan darken-1"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [(item.estatus === 0)?_c('v-btn',_vm._g(_vm._b({staticClass:"rounded-pill",attrs:{"small":"","color":"cyan darken-1","dark":"","loading":_vm.loadingBtnNotify[item.id] || false},on:{"click":function($event){return _vm.notify(item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-bell-ring ")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Reenviar notificación")])])]}},{key:"expanded-item",fn:function(ref){
    var item = ref.item;
return [_c('td',{attrs:{"colspan":_vm.headers.length}},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('strong',[_vm._v("Domicilio:")]),_vm._v(" "+_vm._s(item.domicilio)+" ")]),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('strong',[_vm._v("Área de Pases:")]),_vm._v(" "+_vm._s(item.Area)+" ")]),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('strong',[_vm._v("Acción de Pases:")]),_vm._v(" "+_vm._s(item.accion)+" ")]),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('strong',[_vm._v("Autoriza:")]),_vm._v(" "+_vm._s(item.nombre_auto)+" ")]),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('strong',[_vm._v("Unidad de Medida:")]),_vm._v(" "+_vm._s(item.descMedida)+" ")]),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('strong',[_vm._v("Modelo del Auto:")]),_vm._v(" "+_vm._s(item.modelo_auto)+" ")]),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('strong',[_vm._v("Placa del Auto:")]),_vm._v(" "+_vm._s(item.placa_auto)+" ")])],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.materialHeaders,"items":item.material,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.cantidad",fn:function(ref){
    var item = ref.item;
return [_c('v-chip',[_vm._v(_vm._s(item.cantidad)+" "+_vm._s(item.descMedida))])]}},{key:"item.fecha_modificacion",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(![null, undefined].includes(item.fecha_modificacion) ? _vm.fixDate(item.fecha_modificacion.date.substring(0, 10)) + ' ' + item.fecha_modificacion.date.substring(11, 16) : '')+" ")]}}],null,true)})],1)],1)],1)]}}],null,true)}),_c('NuevoPaseIngreso',{attrs:{"dialog":_vm.dialog},on:{"save":_vm.handleSave,"close":function($event){_vm.dialog = false}}}),(_vm.muestraBarra)?_c('SnackBarErrorIncidencias',{attrs:{"muestraBarra":_vm.muestraBarra,"color":_vm.colorBarra,"texto":_vm.textoBarra},on:{"cierraBarra":function($event){_vm.muestraBarra = false}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }