<template>
  <v-dialog max-width="600" v-model="dialogUpload" persistent>
    <v-card>
      <v-toolbar color="primary" dark>
        Cargar documento
        <v-spacer></v-spacer>
        <v-icon dark @click="$emit('cierraUpload')">mdi-close</v-icon>
      </v-toolbar>
      <v-card-text>
        <v-file-input
          v-model="file"
          show-size
          accept=".pdf, .png, .jpg, .jpeg"
          label="Entrada de archivo"
        ></v-file-input>
      </v-card-text>
      
      <v-card-actions class="justify-end">
        <v-btn
          color="primary"
          elevation="2"
          @click="submitFile()"
          :loading="loadingBotonGuardar"
          >Cargar
        </v-btn>
      </v-card-actions>
    </v-card>
    <SnackBar
      :muestraBarra="muestraBarra"
      :texto="textoBarra"
      :color="colorBarra"
      @cierraBarra="
        muestraBarra = false;
        $emit('cierraUpload');
      "
    />
  </v-dialog>
</template>

<script>
import axios from "axios";
import { deleteToken } from "../../../../../../store/Funciones/funciones";
import SnackBar from "../../../../General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue";

export default {
  name: "carga-politicas",
  components: { SnackBar },
  //   props: ["dialogUpload"],
  props: ["dialogUpload", "id_parte1", "id_parte2", "doc", "carpeta"],
  data() {
    return {
      file: null,
      loadingBotonGuardar: false,
      errors: [],
      muestraBarra: false,
      textoBarra: "",
      colorBarra: "",
    };
  },
  computed: {},
  mounted() {
    console.log(this.dialogUpload);
    console.log(this.id_parte1);
    console.log(this.id_parte2);
    console.log(this.doc);
    console.log(this.carpeta);
  },
  methods: {
    submitFile() {
      // this.loadingBotonGuardar = true;
      let ext = this.file.type.split("/");
      console.log(this.file);
      let reader = new FileReader();
      
      reader.readAsDataURL(this.file);
      reader.onload = () => {
        let params = {
          Opcion: 2,
          token: localStorage.getItem("token"),
          id_parte2: this.id_parte2,
          id_parte1: this.id_parte1,
          tipoArchivo: ext[0],
          filename: this.doc + "." + ext[1],
          nombreArchivo: this.file.name,
          archB64: reader.result,
          carpeta: this.carpeta,
        };
        axios
          .post(
            `${this.$store.getters.getHost}/General/PoliticasUHM/ws-politicasUHM.php`,
            params
          )
          .then((response) => {
            this.loadingInfo = false;
            console.log(response.data);
            if (response.data.status == "error") {
              this.errors = response.data.detalles;
              this.validaRespuesta(1, this.errors[0].message);
            } else if (response.data.status == "OK") {
              this.response = response.data.detalles.detalles;
              this.validaRespuesta(2, this.response);
            } else if (response.data.status == "EXPRESSION") {
              deleteToken();
            }
          })
          .catch(function (e) {
            alert("Ocurrió un error: " + e);
          });
      };
    },
    validaRespuesta(opc, texto) {
      this.loadingBotonGuardar = false;
      switch (opc) {
        case 1: {
          this.colorBarra = "error";
          this.textoBarra = texto;
          this.muestraBarra = true;
          this.fakePromise();
          break;
        }
        case 2: {
          this.colorBarra = "success";
          this.textoBarra = texto;
          this.muestraBarra = true;
          this.fakePromise();
          break;
        }
      }
    },
    fakePromise() {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(this.$emit("cierraUpload"));
        }, 2500);
      });
    },
  },
};
</script>
