
import MiNominaReporteInconformidades from "../../../../components/componentsNomina/MiNominaReporteInconformidades.vue";
export default {
  name: 'admon-inconformidades',
  components: { MiNominaReporteInconformidades },
  props: [],
  data() {
    return {
      consultarReporte: true,
    }
  },
  computed: {

  },
  mounted() {

  },
  methods: {
    cerrarReporte(response) {
      console.log(response);
      this.consultarReporte = false;
    },
  }
}


