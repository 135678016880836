<template>
  <v-dialog v-model="muestraResultados" persistent max-width="850px" scrollable>
    <v-card class="rounded-lg">
      <v-card-title class="primary white--text subtitle-1">
        {{$t("DH360.titleMyAssessment")}}
        <v-spacer></v-spacer>
        <v-icon dark @click="$emit('cierraResultados')">mdi-close</v-icon>
      </v-card-title>
      <v-card-text class="pb-5 px-2">
        <p
          v-if="
            $vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm'
          "
          class="text-center subtitle-2"
        >
          {{$t("DH360.txtResultsOf")}}
          <strong class="primary--text">{{$t("DH360.txtYourAssessment")}}</strong> 360.
        </p>
        <v-row dense v-else>
          <v-col
            cols="3"
            style="display:flex;justify-content: center; align-items: center;"
          >
            <v-img
              :src="require('@/assets/img/n-logo-urrea.png')"
              class="my-3"
              max-width="110px"
            ></v-img>
          </v-col>
          <v-col
            cols="9"
            style="display:flex;justify-content: start; align-items: center;"
          >
            <p class="text-h5">
              {{$t("DH360.txtResultsOf")}}
              <strong class="primary--text">{{$t("DH360.txtYourAssessment")}}</strong> 360.
            </p>
          </v-col>
        </v-row>

        <v-expansion-panels v-model="panel" multiple>
          <v-expansion-panel>
            <v-expansion-panel-header class="primary--text font-weight-bold">
              {{$t("DH360.subtitleRanking")}}.
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row
                v-if="loadingNivel"
                class="fill-height"
                align-content="center"
                justify="center"
              >
                <v-col class="subtitle-1 text-center" cols="12">
                  {{$t("DH360.txtLoadingInformation")}}...
                </v-col>
                <v-col cols="6">
                  <v-progress-linear
                    color="blue accent-4"
                    indeterminate
                    rounded
                    height="6"
                  ></v-progress-linear>
                </v-col>
              </v-row>
              <v-row dense v-else>
                <v-col
                  :cols="clumnaMuestaPosicionRanking"
                  style="display: flex; justify-content: center; align-items: center;"
                >
                  <v-sheet
                    v-if="clumnaMuestaPosicionRanking == 6"
                    class="rounded-xl elevation-3"
                    color="teal darken-1"
                    dark
                    style="min-width: 60%;"
                  >
                    <p class="text-center mb-0 text-h5">
                      {{$t("DH360.txtPosition")}} <strong>{{ posicionRanking }}</strong>
                    </p>
                    <p class="text-center mb-0 subtitle-2 text-uppercase">
                      {{$t("DH360.txtRankingGeneral")}}
                    </p>
                  </v-sheet>
                  <v-sheet
                    v-else
                    class="rounded-lg elevation-3"
                    color="teal darken-1"
                    dark
                    style="min-width: 80%;"
                  >
                  <p class="text-center mb-0 subtitle-2">
                      {{$t("DH360.txtPosition")}} <strong>{{ posicionRanking }}</strong>
                    </p>
                    <p class="text-center mb-0 caption text-uppercase">
                      {{$t("DH360.txtRankingGeneral")}}
                    </p>
                  </v-sheet>
                </v-col>
                <v-col :cols="clumnaMuestaPosicionRanking">
                  <v-row dense>
                    <v-col cols="12">
                      <p class="text-center mb-0 subtitle-2 text-uppercase">
                        {{$t("DH360.txtRankingGeneral")}}
                      </p>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col
                      cols="12"
                      style="display: flex; justify-content: center; align-items: center;"
                    >
                      <v-simple-table
                        class="elevation-3 fondo-tabla-nivel"
                        dense
                      >
                        <thead>
                          <tr>
                            <th class="text-center font-weight-bold subtitle-2">
                              1
                            </th>
                            <th class="text-center font-weight-bold subtitle-2">
                              2
                            </th>
                            <th class="text-center font-weight-bold subtitle-2">
                              3
                            </th>
                            <th class="text-center font-weight-bold subtitle-2">
                              4
                            </th>
                            <th class="text-center font-weight-bold subtitle-2">
                              5
                            </th>
                            <th class="text-center font-weight-bold subtitle-2">
                              6
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td class="text-center" v-for="i in 6" :key="i">
                              <v-avatar
                                v-if="i == nivel"
                                size="35"
                                class="my-2 elevation-5"
                              >
                                <v-img
                                  :src="$store.getters.getUser['Foto']"
                                ></v-img>
                              </v-avatar>
                            </td>
                          </tr>
                        </tbody>
                      </v-simple-table>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header class="primary--text font-weight-bold">
              {{$t("DH360.txtOverView")}}.
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <RadarChart360 :idProyecto="idProyecto" />
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel
            @click="
              loadingComentarios = true;
              getComentarios();
            "
          >
            <v-expansion-panel-header class="primary--text font-weight-bold">
              {{$t("DH360.txtComments")}}.
            </v-expansion-panel-header>
            <v-expansion-panel-content class="scroll-comentarios">
              <v-row
                v-if="loadingComentarios"
                class="fill-height"
                align-content="center"
                justify="center"
              >
                <v-col class="subtitle-1 text-center" cols="12">
                  {{$t("DH360.txtLoading")}} {{$t("DH360.txtComments")}}...
                </v-col>
                <v-col cols="6">
                  <v-progress-linear
                    color="blue accent-4"
                    indeterminate
                    rounded
                    height="6"
                  ></v-progress-linear>
                </v-col>
              </v-row>
              <v-row dense v-else>
                <v-col
                  cols="12"
                  style="display: flex; justify-content: center; align-items: center;"
                >
                  <v-card
                    class="elevation-0 rounded-lg"
                    style="border: 2px solid #01579B;"
                    width="100%"
                  >
                    <v-card-title
                      class="light-blue--text text--darken-4 font-weight-bold subtitle-1 pt-1"
                    >
                      {{$t("DH360.txtStrengths")}}.
                    </v-card-title>
                    <v-card-text>
                      <p
                        class="caption text-uppercase text-justify blue--text text--accent-3 mb-0"
                        v-for="(fortaleza, index) in fortalezas"
                        :key="index"
                      >
                        <v-icon left small color="blue darken-4"
                          >mdi-hand-pointing-right</v-icon
                        >
                        {{ fortaleza.respuesta }}
                      </p>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col
                  cols="12"
                  style="display: flex; justify-content: center; align-items: center;"
                >
                  <v-card
                    class="elevation-0 rounded-lg"
                    style="border: 2px solid #01579B;"
                    width="100%"
                  >
                    <v-card-title
                      class="light-blue--text text--darken-4 font-weight-bold subtitle-1 pt-1"
                    >
                      {{$t("DH360.txtOpportunities")}}.
                    </v-card-title>
                    <v-card-text>
                      <p
                        class="caption text-uppercase text-justify blue--text text--accent-3 mb-0"
                        v-for="(oportunidad, index) in oportunidades"
                        :key="index"
                      >
                        <v-icon left small color="blue darken-4"
                          >mdi-hand-pointing-right</v-icon
                        >
                        {{ oportunidad.respuesta }}
                      </p>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { deleteToken } from "../../../../../../store/Funciones/funciones";
import axios from "axios";
import RadarChart360 from "./RadarChart360.vue";

export default {
  name: "muestra-resultados-360",
  components: {
    RadarChart360,
  },
  props: ["muestraResultados", "idProyecto"],
  data() {
    return {
      fortalezas: [],
      oportunidades: [],
      panel:[0],
      nivel: 0,
      posicionRanking: 0,
      loadingComentarios: false,
      loadingNivel: true,
    };
  },
  computed: {
    clumnaMuestaPosicionRanking() {
      if (this.$vuetify.breakpoint.name == "xs") {
        return 12;
      } else {
        return 6;
      }
    },
  },
  mounted() {
    this.getPosiciones360()
  },
  methods: {
    getComentarios() {
      if (this.fortalezas.length == 0 && this.oportunidades.length == 0) {
        let params = {
          Opcion: 6,
          token: localStorage.getItem("token"),
          idProyecto: this.idProyecto,
        };
        axios
          .post(
            `${this.$store.getters.getHost}/RelacionesLaborales/EvaluacionDesempenio/ws-envaluacion-360.php`,
            params
          )
          .then((response) => {
            this.loadingComentarios = false;
            if (response.data.status != "EXPSESSION") {
              response.data.respuesta.map((comentario) => {
                if (comentario.idpregunta == 1) {
                  this.fortalezas.push(comentario);
                } else {
                  this.oportunidades.push(comentario);
                }
              });
            } else {
              deleteToken();
            }
          })
          .catch((e) => {
            alert(e);
            deleteToken()
          });
      } else {
        this.loadingComentarios = false;
      }
    },
    getPosiciones360() {
      if (this.nivel == 0 && this.posicionRanking == 0) {
        let params = {
          Opcion: 7,
          token: localStorage.getItem("token"),
          idProyecto: this.idProyecto,
        };
        axios
          .post(
            `${this.$store.getters.getHost}/RelacionesLaborales/EvaluacionDesempenio/ws-envaluacion-360.php`,
            params
          )
          .then((response) => {
            this.loadingNivel = false;
            if (response.data.status != "EXPSESSION") {
              this.nivel = response.data.respuesta[0].Nivel;
              this.posicionRanking = response.data.respuesta[0].RankingGeneral;
            } else {
              deleteToken();
            }
          })
          .catch((e) => {
            alert(e);
            deleteToken()
          });
      } else {
        this.loadingNivel = false;
      }
    },
  },
};
</script>

<style>
.fondo-tabla-nivel {
  background: rgb(254, 0, 0);
  background: linear-gradient(
    270deg,
    rgba(254, 0, 0, 1) 0%,
    rgba(255, 243, 65, 1) 47%,
    rgba(100, 255, 90, 1) 100%
  );
}

.scroll-comentarios {
  height: 280px !important;
  overflow-y: scroll;
  background-color: transparent;
  border-radius: 10px !important;
  overflow-y: auto;
}

.scroll-comentarios::-webkit-scrollbar {
  width: 10px;
  background-color: blue;
}

.scroll-comentarios::-webkit-scrollbar-thumb {
  background-color: red;
  border-radius: 10px;
}

.scroll-comentarios::-moz-scrollbar {
  width: 10px;
  background-color: rgba(240, 240, 240, 0.5);
}

.scroll-comentarios::-moz-scrollbar-thumb {
  background-color: red;
  border-radius: 10px;
}

.scroll-comentarios::scrollbar {
  width: 10px;
  background-color: blue;
}

.scroll-comentarios::scrollbar-thumb {
  background-color: red;
  border-radius: 10px;
}
</style>
