var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[(!_vm.moduloError)?_c('v-card',[_c('v-data-table',{staticClass:"elevation-2",attrs:{"headers":_vm.headers,"items":_vm.motivos,"search":_vm.buscar,"loading":_vm.loadingInfo,"dense":"","mobile-breakpoint":"0","footer-props":{ 'items-per-page-text': 'Elementos por página' },"no-data-text":"No se encontraron motivos","item-key":"id"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{staticClass:"rounded-lg rounded-b-0 rounded-t-0",attrs:{"flat":""}},[_c('v-text-field',{staticClass:"mt-7 rounded-lg",attrs:{"label":_vm.$t('DH360.findTextBox'),"dense":"","clearable":"","outlined":"","solo-inverted":"","prepend-inner-icon":"mdi-table-search"},model:{value:(_vm.buscar),callback:function ($$v) {_vm.buscar=$$v},expression:"buscar"}}),_c('v-spacer'),_c('v-btn',{staticClass:"rounded-lg elevation-0",attrs:{"color":"teal darken-1","small":"","dark":""},on:{"click":_vm.openFormMotivos}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" Nuevo motivo ")],1)],1)]},proxy:true},{key:"item.motivo",fn:function(ref){
var item = ref.item;
return [_c('div',{attrs:{"align":"left"}},[_vm._v(_vm._s(item.motivo))])]}},{key:"item.departamento",fn:function(ref){
var item = ref.item;
return [(item.departamento == '[] ')?_c('div',{attrs:{"align":"left"}},[_vm._v(" Sin departamento ")]):_c('div',{attrs:{"align":"left"}},[_vm._v(_vm._s(item.departamento))])]}},{key:"item.estatus",fn:function(ref){
var item = ref.item;
return [(item.estatus == 1)?_c('v-tooltip',{attrs:{"top":"","color":"green darken-2"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticStyle:{"cursor":"pointer"},attrs:{"color":"green darken-4","large":""},on:{"click":function($event){return _vm.confirmarCambioEstatusInactivo(item.id)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-menu-up ")])]}}],null,true)},[_c('label',{staticClass:"text-uppercase text-center font-weight-bold white--text"},[_vm._v("Activo "),_c('br'),_vm._v(" Cambiar estatus ")])]):(item.estatus == 0)?_c('v-tooltip',{attrs:{"top":"","color":"red darken-2"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticStyle:{"cursor":"pointer"},attrs:{"color":"red darken-4","large":""},on:{"click":function($event){return _vm.confirmarCambioEstatus(item.id)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-menu-down ")])]}}],null,true)},[_c('label',{staticClass:"text-uppercase text-center font-weight-bold white--text"},[_vm._v("Inactivo "),_c('br'),_vm._v(" Cambiar estatus")])]):_vm._e()]}}],null,false,3860735683)}),(_vm.muestraBarra)?_c('SnackBar',{attrs:{"muestraBarra":_vm.muestraBarra,"texto":_vm.textoBarra,"color":_vm.colorBarra},on:{"cierraBarra":function($event){_vm.muestraBarra = false}}}):_vm._e(),(_vm.agregarMotivo)?_c('inconformidades-form-motivos',{attrs:{"dialog":_vm.agregarMotivo},on:{"cierraFormMotivosInconformidades":_vm.closeFormMotivos}}):_vm._e()],1):_c('div',[_c('mantenimiento-modulo')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }