var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"rounded-lg",attrs:{"max-height":"750"}},[_c('v-toolbar',{attrs:{"color":"primary","dark":""}},[_c('v-row',[_c('v-col',{staticClass:"pt-7",attrs:{"cols":"3"}},[_c('v-img',{attrs:{"src":require('@/assets/img/chat.png'),"height":"41px","width":"40px"}})],1),_c('v-col',{staticStyle:{"margin-top":"5px"},attrs:{"cols":"9"}},[_c('h3',[_vm._v("¡Hola!")]),_c('p',[_vm._v("Chatea con nosotros")])])],1),_c('v-spacer'),_c('v-btn',{attrs:{"text":"","icon":"","dark":""},on:{"click":function($event){return _vm.$emit('cierraCA')}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',{ref:"chatArea",staticClass:"containerChat pt-2 px-1"},[(_vm.loadingData)?_c('v-sheet',[_c('div',{staticClass:"center-item-in-div-column py-5"},[_c('div',{staticClass:"subtitle-1 text-center",attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(_vm.mensajeConcatenado)+" ")]),_c('div',{staticClass:"center-item-in-div mt-5"},[_c('v-progress-circular',{attrs:{"size":70,"width":8,"color":"primary","indeterminate":""}})],1)])]):_vm._l((_vm.messages),function(message,index){return _c('div',{key:index,ref:"contentRef",refInFor:true,staticClass:"content"},[_c('transition',{attrs:{"name":"slide-fade"}},[(message.type == 1)?_c('div',{staticClass:"px-4 pb-1",staticStyle:{"display":"flex","justify-content":"left"}},[_c('v-card',{staticClass:"elevation-0 rounded-xl",class:{
              'my-card-enter-active': index === _vm.messages.length - 1,
            },attrs:{"color":"blue-grey lighten-5","min-width":"40%"}},[_c('v-card-title',{staticClass:"font-weight-bold pt-1 pb-0 subtitle-2"},[_vm._v(" "+_vm._s(message.title)+" ")]),_c('v-card-text',{staticClass:"pb-2 pt-0"},[_vm._v(" "+_vm._s(message.message)+" ")]),(message.haveOptions)?_c('div',{staticClass:"pb-2 px-2"},_vm._l((message.options),function(button,index){return _c('v-btn',{key:index,staticClass:"mr-1 elevation-0 rounded-pill font-weight-bold mb-1 mi-boton",staticStyle:{"max-width":"100%"},attrs:{"small":"","block":"","disabled":message.disabled},on:{"click":function (event) { return button.onClick({
                      type: 2,
                      message: button.text,
                      haveOptions: false,
                      options: null,
                      item: message,
                    }); }}},[_c('span',{staticClass:"d-inline-block text-truncate text-decoration-underline blue-grey--text text--darken-1",staticStyle:{"max-width":"285px !important"}},[_vm._v(" "+_vm._s(button.text)+" ")])])}),1):_vm._e()],1)],1):(message.type == 3)?_c('div',{staticClass:"px-4 pb-1",staticStyle:{"display":"flex","justify-content":"left"}},[_c('v-card',{staticClass:"elevation-0 rounded-xl rounded-bl-0 ",attrs:{"color":"blue-grey lighten-5","min-width":"40%"}},[_c('v-card-title',{staticClass:"font-weight-bold pt-1 pb-0 subtitle-2"},[_vm._v(" "+_vm._s(message.title)+" ")]),_c('v-card-text',{staticClass:"pb-2 pt-0"},[_c('div',{staticClass:"subtitle-1 primary--text"},[_vm._v(" "+_vm._s(message.subtitle)+" ")]),_c('div',{staticClass:"caption blue-grey--text text--lighten-1"},[_vm._v(" "+_vm._s(message.subtitle2)+" ")]),_c('br'),_c('div',{staticClass:"text-justify"},[_c('strong',[_vm._v("Conocimientos requeridos: ")]),_vm._v(_vm._s(message.message)+" ")])])],1)],1):(message.type == 2)?_c('div',{staticClass:"px-4 pb-1",staticStyle:{"display":"flex","justify-content":"right"}},[_c('v-card',{staticClass:"elevation-0 rounded-xl",class:{
              'my-card-enter-active': index === _vm.messages.length - 1,
            },attrs:{"color":"primary","min-width":"30%"}},[_c('v-card-text',{staticClass:"py-2 bt-0 white--text"},[_vm._v(" "+_vm._s(message.message)+" ")])],1)],1):(message.type == 4)?_c('div',{staticClass:"px-4 pb-1",staticStyle:{"display":"flex","justify-content":"left"}},[_c('v-card',{staticClass:"elevation-0 rounded-xl",attrs:{"color":"blue-grey lighten-5","min-width":"40%"}},[_c('v-card-title',{staticClass:"font-weight-bold pt-1 pb-0 subtitle-2"},[_vm._v(" "+_vm._s(message.title)+" ")]),_c('v-card-text',{staticClass:"pb-2 pt-0"},[_vm._v(" "+_vm._s(message.message)+" ")]),(message.haveOptions)?_c('div',{staticClass:"pb-2 px-2"},_vm._l((message.options),function(button,index){return _c('v-btn',{key:index,staticClass:"mr-1 elevation-0 rounded-pill font-weight-bold mb-1 mi-boton",staticStyle:{"max-width":"100%"},attrs:{"small":"","text":""},on:{"click":function($event){return _vm.getDocument(button.text)}}},[_c('span',{staticClass:"d-inline-block text-truncate text-decoration-underline light-blue--text text--accent-3",staticStyle:{"max-width":"285px !important"}},[_vm._v(" "+_vm._s(button.text)+" ")])])}),1):_vm._e()],1)],1):_vm._e()])],1)})],2),_c('v-divider'),_c('v-card-actions',{staticClass:"pt-0 pb-1 pr-0",staticStyle:{"display":"flex","justify-content":"space-between"}},[_c('v-text-field',{attrs:{"placeholder":"Escribe un mensaje...","dense":"","disabled":!_vm.respuestaAbierta},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.sendMessage.apply(null, arguments)}},model:{value:(_vm.msgUser),callback:function ($$v) {_vm.msgUser=$$v},expression:"msgUser"}}),_c('v-btn',{attrs:{"text":"","fab":"","disabled":!_vm.respuestaAbierta},on:{"click":function($event){_vm.insertMessage(2, _vm.msgUser, false, null, null);
        _vm.msgUser = '';}}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-send ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }