<template>
  <v-card
    class="rounded-lg elevation-0"
    height="100%"
    width="100%"
    color="blue-grey lighten-5"
  >
    <v-card-title
      class="py-1 center-item-in-div primary rounded-lg rounded-b-0"
    >
      <label class="overline font-weight-bold white--text"
        >bajas en la relación</label
      >
    </v-card-title>
    <div class="pa-5">
      <div v-if="estatusBajas" class="center-item-in-div">
        <v-card
          v-if="estatusBajas.lideres"
          class="px-4 pt-1 pb-2 elevation-0 rounded-lg mx-2"
          color="cyan lighten-3"
          width="50%"
        >
          <label class="overline font-weight-bold">Líderes</label>
          <v-row v-if="estatusBajas.lideres.length > 0" dense>
            <v-col :cols="12 / estatusBajas.lideres.length" class="mx-0"> 
                <v-card class="py-2 px-1 elevation-0 rounded-lg center-item-in-div-column" color="cyan lighten-4">
                    <div class="text-h6 font-weight-bold">{{estatusBajas.lideres[0].total}}</div>
                    <div class="caption text-uppercase font-weight-bold">
                        {{estatusBajas.lideres[0].EMP_STATUS === "I" ? 'Bajas' : 'N/R'}}
                    </div>
                </v-card>
            </v-col>
            <v-col :cols="12 / estatusBajas.lideres.length" class="mx-0" v-if="estatusBajas.lideres.length > 1"> 
                <v-card class="py-2 px-1 elevation-0 rounded-lg center-item-in-div-column" color="cyan lighten-4">
                    <div class="text-h6 font-weight-bold">{{estatusBajas.lideres[1].total}}</div>
                    <div class="caption text-uppercase font-weight-bold">
                        {{estatusBajas.lideres[1].EMP_STATUS === "I" ? 'Bajas' : 'N/R'}}
                    </div>
                </v-card>
            </v-col> 
          </v-row>
        </v-card>
        <v-card
          v-if="estatusBajas.empleados"
          class="px-4 pt-1 pb-2 elevation-0 rounded-lg mx-2"
          color="#c94e94"
          width="50%"
        >
          <label class="overline white--text font-weight-bold">Empleados</label>
          <v-row v-if="estatusBajas.empleados.length > 0" dense>
            <v-col :cols="12 / estatusBajas.empleados.length"> 
                <v-card class="pa-2 elevation-0 rounded-lg center-item-in-div-column" color="pink lighten-4">
                    <div class="text-h6 font-weight-bold">{{estatusBajas.empleados[0].total}}</div>
                    <div class="caption text-uppercase font-weight-bold">
                        {{estatusBajas.empleados[0].EMP_STATUS === "I" ? 'Bajas' : 'N/R'}}
                    </div>
                </v-card>
            </v-col>
            <v-col :cols="12 / estatusBajas.empleados.length" v-if="estatusBajas.empleados.length > 1"> 
                <v-card class="pa-2 elevation-0 rounded-lg center-item-in-div-column" color="pink lighten-4">
                    <div class="text-h6 font-weight-bold">{{estatusBajas.empleados[1].total}}</div>
                    <div class="caption text-uppercase font-weight-bold">
                        {{estatusBajas.empleados[1].EMP_STATUS === "I" ? 'Bajas' : 'N/R'}}
                    </div>
                </v-card>
            </v-col> 
          </v-row>
        </v-card>
      </div>
    </div>
  </v-card>
</template>

<script>

import axios from "axios";
export default {
  name: "ver-bajas-emp-resp",
  data() {
    return {
      estatusBajas: {},
    };
  },
  async mounted() {
    this.estatusBajas = await this.getBajasRelacionEmpleadoResponsable()
  },
  methods: {
    async getBajasRelacionEmpleadoResponsable() {
      const params = {
        Opcion: 12,
        token: localStorage.getItem("token"),
      };
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${this.$store.getters.getHost}/RelacionesLaborales/RelacionEmpleadoResponsable/ws-empleado-responsable.php`,
            params
          )
          .then((response) => {
            if (response.data.status == "OK") {
              resolve(response.data.respuesta);
            }
          })
          .catch((error) => {
            reject(error);
          });
        // .finally(() => {
        //   this.loadingAcciones = false;
        // });
      });
    },
  },
};
</script>
