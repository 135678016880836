import axios from 'axios'
import SnackBarErrorIncidencias from "../../../General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue"
export default {
    name: 'reporte-candidatos',
    components: { SnackBarErrorIncidencias },
    props: [],
    data() {
        return {

            search: '',
            headers: [
                { text: 'Fecha ', align: 'start', sortable: false, value: 'fecha', },
                { text: 'Nombre', value: 'nombre' },
                { text: 'Teléfono', value: 'celularColaborador' },
                { text: 'Medio reclutamiento', value: 'medioReclutamiento' },
                { text: 'persona recomienda', value: 'personaRecomienda' },
                { text: 'Documentos', value: 'documentos' },
                { text: 'Acciones', value: 'actions' },
            ],

            cargando: true,
            nuevosCandidatos: [],
            dialog: false,

            ultimoRegistro: false,
            textoBarra: '',
            colorBarra: '',
            muestraBarra: false,
            valid: true,
            dialogLista: false,
            id: '',
            fecha: '',
            nombre: '',
            reclutamiento: '',
            sindicato: '',
            supervisorEntrevista: '',
            medico: '',
            habilidad: '',
            documentos: '',
            fechaIngreso: '',
            comentario: '',
            planta: '',
            departamento: '',
            turno: '',
            supervisor: '',
            telefono: '',
            area: '',
            comentarioMedico: '',
            medioReclutamiento: '',
            comentarioSindicato: '',
            comentarioSupervisor: '',
            personaQueRecomienda: '',
            comentarioReclutamiento: '',

            plantas: [],
            medioReclutamientoRules: [
                'Facebook',
                'Modulo',
                'Recomendado',
                'Volanteo',
                'Feria',
                'SNF',
                'Otros'
            ],

            areas: [
                'CD', 'MFA',
                'DH', 'MKT',
                'VN', 'FI'
            ],

            turnos: [
                'Turno 1',
                'Turno 2',
                'Turno 3',
                'Grupo 1',
                'Grupo 2',
                'Grupo 3',
                'Grupo 4',
                'Grupo especial',
                'Turno mixto',
                'Turno mixto especial'
            ],

            reclutamientos: [
                'Contratable',
                'No contratable'
            ],
            sindicatos: [
                'Contratable',
                'No contratable'
            ],
            supervisorEntrevistas: [
                'Contratable',
                'No contratable'
            ],
            medicos: [
                'Contratable',
                'No contratable'
            ],
            habilidads: [
                'Grupo 1',
                'Grupo 2',
                'Grupo 3',
                'N/A'
            ],
            documentoss: [
                'Sin documentos',
                'En proceso',
                'Completados'
            ],

            nameRules: [
                v => !!v || 'Nombre es requerido',
                v => v.length <= 50 || 'El nombre debe tener menos de 50 caracteres',
            ],
            telefonoRules: [
                v => !!v || 'Teléfono es requerido',
                v => v.length <= 10 || 'El teléfono debe tener menos de 10 caracteres',
            ],
            personaQueRecomiendaRules: [
                v => !!v || 'Nombre es requerido',
                v => v.length <= 50 || 'El nombre debe tener menos de 50 caracteres',
            ],
            departamentoRules: [
                v => !!v || 'Departamento es requerido',
                v => v.length <= 20 || 'El departamento debe tener menos de 20 caracteres',
            ],
            supervisorRules: [
                v => !!v || 'Supervisor es requerido',
                v => v.length <= 50 || 'El supervisor debe tener menos de 50 caracteres',
            ],
            comentarioRules: [
                v => !!v || 'Comentario es requerido',
                v => v.length <= 100 || 'El comentario debe tener menos de 100 caracteres',
            ],
            comentarioReclutamientoRules: [
                v => !!v || 'Comentario es requerido',
                v => v.length <= 100 || 'El comentario debe tener menos de 100 caracteres',
            ],
            comentarioSindicatoRules: [
                v => !!v || 'Comentario es requerido',
                v => v.length <= 100 || 'El comentario debe tener menos de 100 caracteres',
            ],
            comentarioSupervisorRules: [
                v => !!v || 'Comentario es requerido',
                v => v.length <= 100 || 'El comentario debe tener menos de 100 caracteres',
            ],
            comentarioMedicoRules: [
                v => !!v || 'Comentario es requerido',
                v => v.length <= 100 || 'El comentario debe tener menos de 100 caracteres',
            ],
        }
    },
    computed: {

    },
    mounted() {
        this.consultarCandidatos();
    },
    methods: {
        consultarCandidatos() {
            let params = {
                Opcion: 1,
                opc: 1, // para el SP de AltaCandidatos, me traerá la informacion 
            };

            axios.post(`${this.$store.getters.getHost}/OnBoarding/ws-OnBoarding.php`, params).then((response) => {
                if (response.data.status == 'OK') {
                    this.nuevosCandidatos = response.data.data;
                }
                this.cargando = false;
            })
        },

        openFormularioCandidatos(item) {
            this.dialog = true;
            this.plantasPorArea(item.area);
            this.id = item.id;

            this.nombre = item.nombre;
            this.telefono = item.celularColaborador;
            this.medioReclutamiento = item.medioReclutamiento;
            this.personaQueRecomienda = item.personaRecomienda;
            this.fecha = item.fecha;

            this.planta = item.planta;
            this.area = item.area;
            this.turno = item.turno;
            this.supervisor = item.supervisor;
            this.departamento = item.departamento;

            this.reclutamiento = item.reclutamiento;
            this.sindicato = item.sindicato;
            this.supervisorEntrevista = item.supervisorEntrevista;
            this.medico = item.medico;

            this.comentarioReclutamiento = item.comentarioReclutamiento;
            this.comentarioSindicato = item.comentarioSindicato;
            this.comentarioSupervisor = item.comentarioSindicato;
            this.comentarioMedico = item.comentarioMedico;

            this.habilidad = item.habilidad;
            this.documentos = item.documentos;
            this.fechaIngreso = item.fechaIngreso;
            this.comentario = item.comentario;

        },

        plantasPorArea() {
            if (this.area == 'CD') {
                this.plantas = ['Embarques', 'Surtido', 'exportaciones'];
            } else if (this.area == 'MFA') {
                this.plantas = ['Planta 1', 'Planta 2', 'Planta 3', 'Soporte'];
            } else {
                this.plantas = ['Planta 1', 'Planta 2', 'Planta 3', 'Planta 4', 'Planta 5', 'Almacen MFA'];
            }
        },

        editar() {
            let params = {
                Opcion: 1,
                opc: 3,
                id: this.id,
                fecha: this.fecha,
                nombre: this.nombre,
                telefono: this.telefono,
                medioReclutamiento: this.medioReclutamiento,
                personaQueRecomienda: this.personaQueRecomienda,
                area: this.area,
                planta: this.planta,
                departamento: this.departamento,
                turno: this.turno,
                supervisor: this.supervisor,
                reclutamiento: this.reclutamiento,
                sindicato: this.sindicato,
                supervisorEntrevista: this.supervisorEntrevista,
                medico: this.medico,
                habilidad: this.habilidad,
                documentos: this.documentos,
                fechaIngreso: this.fechaIngreso,
                comentario: this.comentario,
                comentarioReclutamiento: this.comentarioReclutamiento,
                comentarioSindicato: this.comentarioSindicato,
                comentarioSupervisor: this.comentarioSupervisor,
                comentarioMedico: this.comentarioMedico,
            };

            if (params.nombre == '' || params.fecha == '' || params.telefono == '' || params.medioReclutamiento == '' ||
                params.personaQueRecomienda == '' || params.area == '' || params.planta == '' || params.departamento == '' ||
                params.turno == '' || params.supervisor == '' || params.reclutamiento == '' || params.sindicato == '' ||
                params.supervisorEntrevista == '' || params.medico == '' || params.habilidad == '' || params.documentos == '' ||
                params.fechaIngreso == '' || params.comentario == '' || params.comentarioReclutamiento == '' ||
                params.comentarioSindicato == '' || params.comentarioSupervisor == '' || params.comentarioMedico == '') {

                this.textoBarra = "Para continuar con el registro, por favor complete el formulario de nuevos candidatos";
                this.colorBarra = 'primary';
                this.muestraBarra = true;
            } else {

                axios.post(`${this.$store.getters.getHost}/OnBoarding/ws-OnBoarding.php`, params).then((response) => {
                    if (response.data.status == 'OK') {
                        this.textoBarra = "Se ha registrado el candidato correctamente.";
                        this.colorBarra = 'success';
                        this.muestraBarra = true;
                        this.dialog = false;
                    }

                    if (response.data.status == 'noNumero') { // revisar despues        
                        this.textoBarra = "El campo de telefono unicamente acepta caracteres numericos";
                        this.colorBarra = 'primary';
                        this.muestraBarra = true;
                    }

                    if (response.data.status == 'error') {
                        this.textoBarra = "Para continuar con el registro, por favor complete con el formulario de nuevos candidatos";
                        this.colorBarra = 'primary';
                        this.muestraBarra = true;
                    }
                })
            }
        },
    }
}