import { render, staticRenderFns } from "./DescargaPrerecibosZentric.vue?vue&type=template&id=35c026b5&"
import script from "./DescargaPrerecibosZentric.vue?vue&type=script&lang=js&"
export * from "./DescargaPrerecibosZentric.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSelect } from 'vuetify/lib/components/VSelect';
installComponents(component, {VAutocomplete,VBtn,VCard,VIcon,VSelect})
