<template>
  <div>
    <v-row dense>
      <v-col :cols="columns" v-for="(medalla ,index) in medallas" :key="index">
        <v-card
          class="pa-1 ma-1 rounded-lg card-mod"
          elevation="10"
          :class="{ 'no-drop': medalla.isDraggable }"
        >
          <v-img
            :src="
              require('@/assets/icons/medallas/' + medalla.backgroundImageUrl)
            "
            aspect-ratio="0"
            class="card-background-img"
          ></v-img>
          <v-card-title
            class="py-0 subtitle-2 font-weight-bold"
            style="display:flex; justify-content:right;"
            color="blue"
          >
            {{ medalla.titulo }}
          </v-card-title>
          <v-card-subtitle
            class="pb-0 mb-1"
            v-if="$vuetify.breakpoint.name == 'xs'"
            style="display:flex; justify-content:right;"
          >
            <v-chip
              x-small
              :color="getColor(medalla.espaciosDisponibles)"
              dark
              class="elevation-2 mt-3"
            >
              {{ $t("DH360.txtAvailability") }}: {{ medalla.espaciosDisponibles }}
            </v-chip>
          </v-card-subtitle>
          <v-card-subtitle class="pl-0 text-center" v-else>
            <v-chip
              small
              :color="getColor(medalla.espaciosDisponibles)"
              dark
              class="elevation-2"
            >
              {{ $t("DH360.txtAvailability") }}: {{ medalla.espaciosDisponibles }}
            </v-chip>
          </v-card-subtitle>
          <div class="scroll-container-medallas">
            <draggable
              v-model="medalla.items"
              group="items"
              class="drag-enter"
              v-on:change="validaCambio(medalla)"
            >
              <div
                v-for="(item, index) in medalla.items"
                :key="index"
                wrap
                class="text-center"
              >
                <v-card
                  class="rounded-lg my-2 mx-2"
                  color="#CFD8DC"
                  style="box-shadow: 0.1em 0.1em 0.3em #00000015 !important;"
                  height="30"
                  width="90%"
                >
                  <v-row dense>
                    <v-col cols="2" class="pa-0">
                      <v-avatar
                        class="my-1 ml-2"
                        size="22px"
                        rounded="circle"
                        color="pink darken-3"
                      >
                        <span
                          class="white--text caption"
                          v-if="item.foto == null"
                        >
                          {{ item.Acronimo }}
                        </span>
                        <img
                          v-else
                          :src="
                            $store.getters.getHost +
                              '/ArchivosCargadosEnPortal2/FotosPerfilUsuarios/' +
                              item.foto
                          "
                        />
                      </v-avatar>
                    </v-col>
                    <v-col cols="10">
                      <p
                        class="black--text font-weight-bold text-center caption"
                      >
                        {{ item.NombreCorto }}
                      </p>
                    </v-col>
                  </v-row>
                </v-card>
              </div>
            </draggable>
          </div>
        </v-card>
        <div
          v-show="medalla.espaciosDisponibles < 0"
          style="display: flex; justify-content: center;"
        >
          <v-alert
            class="rounded-lg caption"
            dense
            color="error"
            style="width: 95%;"
            dark
            icon="mdi-alert"
            border="left"
          >
            Límite <strong>({{ medalla.espaciosAsignados }})</strong> rebasado. No
            puedes asignar a más personas en esta medalla.
          </v-alert>
        </div>
      </v-col>
    </v-row>
    <v-card class="rounded-lg mt-2" elevation="15">
      <v-card-title class="font-weight-bold subtitle-1 primary white--text">{{
        encuestaDesc
      }} | {{ $t("DH360.titleSliceParticipants") }}</v-card-title>
      <v-card-text class="mt-4">
        <v-row>
          <v-col
            cols="12"
            xs="12"
            sm="6"
            md="4"
            class="scroll-container-evaluados"
          >
            <draggable
              v-model="participantes"
              group="items"
              class="drag-enter-participantes"
            >
              <v-row v-for="(item, index) in participantes" :key="index" dense>
                <v-col cols="12">
                  <v-tooltip top class="rounded-pill">
                    <template v-slot:activator="{ on, attrs }">
                      <v-card
                        class="rounded-lg my-1"
                        color="#E8EAF6"
                        height="40"
                        width="100%"
                        v-bind="attrs"
                        v-on="on"
                        transition="scroll-x-reverse-transition"
                      >
                        <v-row dense>
                          <v-col cols="2" class="pa-0">
                            <v-avatar
                              class="my-1 ml-2"
                              size="32px"
                              rounded="circle"
                              color="pink darken-3"
                            >
                              <span
                                class="white--text subtitle-2"
                                v-if="item.foto == null"
                              >
                                {{ item.Acronimo }}
                              </span>
                              <img
                                v-else
                                :src="
                                  $store.getters.getHost +
                                    '/ArchivosCargadosEnPortal2/FotosPerfilUsuarios/' +
                                    item.foto
                                "
                              />
                            </v-avatar>
                          </v-col>
                          <!-- <v-card-text> -->
                          <v-col cols="10">
                            <p
                              class="black--text font-weight-bold text-left my-1"
                            >
                              {{ item.NombreCorto }}
                            </p>
                          </v-col>
                        </v-row>
                        <!-- </v-card-text> -->
                      </v-card>
                    </template>
                    <span
                      class="scale-transition transition-fast-in-fast-out"
                      >{{ item.Nombre }}</span
                    >
                  </v-tooltip>
                </v-col>
              </v-row>
            </draggable>
          </v-col>
          <v-col cols="12" xs="12" sm="6" md="8">
            <v-card class="rounded-0" elevation="10" width="100%" >
              <v-card-title
                class="white--text my-2 subtitle-1 py-1 font-weight-bold"
                :style="{ backgroundColor: getColorCategoria(categoria) }"
              >
                {{ categoria }}
              </v-card-title>
              <v-card-text>
                <v-list-item class="pl-0">
                  <v-list-item-avatar color="white">
                    <v-img
                      :src="
                        require('@/assets/icons/' + getValor(categoria))
                      "
                    ></v-img>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title
                      ><p class="font-weight-bold grey--text text--darken-2 my-2">
                        {{ $t("DH360.txtQuestion") }} {{ preguntaActual + 1 }} {{ $t("DH360.of") }} {{ total }}
                      </p></v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>

                <p class="text-justify">
                  {{ pregunta }} 
                </p>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  v-show="participantes.length == 0 && !validaAsignaciones()"
                  :color="getColorCategoria(categoria)"
                  depressed
                  class="rounded-0 animated-btn-sig font-weight-bold"
                  dark
                  @click="guardaCalificaciones()"
                >
                  {{ $t("DH360.textBtnNext") }}
                  <v-icon right>mdi-arrow-right</v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
            
                <p class="pt-5"><b>{{ $t("DH360.txtNote") }}:</b> {{ $t("DH360.txtNoteComplete") }}</p>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import draggable from "vuedraggable";
export default {
  name: "muestra-evaluados",
  components: { draggable },
  data() {
    return {
      medallas: [
        {
          titulo: this.$t('DH360.bronzeMedal'),
          items: [],
          backgroundImageUrl: "medallas-bronce-urrea.png",
          espaciosAsignados: 0,
          espaciosDisponibles: 0,
          isDraggable: false,
        },
        {
          titulo: this.$t('DH360.silverMedal'),
          items: [],
          backgroundImageUrl: "medallas-plata-urrea.png",
          espaciosAsignados: 0,
          espaciosDisponibles: 0,
          isDraggable: false,
        },
        {
          titulo: this.$t('DH360.goldMedal'),
          items: [],
          backgroundImageUrl: "medallas-oro-urrea.png",
          espaciosAsignados: 0,
          espaciosDisponibles: 0,
          isDraggable: false,
        },
        {
          titulo: this.$t('DH360.platinumMedal'),
          items: [],
          backgroundImageUrl: "medallas-platino-urrea.png",
          espaciosAsignados: 0,
          espaciosDisponibles: 0,
          isDraggable: false,
        },
      ],
      maxAsignaciones: {
        id: 0,
        empleados: 0,
        bronce: 0,
        plata: 0,
        oro: 0,
        platino: 0,
      },
      participantes: [],
      test: "#0078be",
    };
  },
  props: [
    "participantesSel",
    "categoria",
    "pregunta",
    "preguntaActual",
    "total",
    "encuestaDesc",
    "reglas",
  ],
  computed: {
    columns() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 6;
        case "sm":
          return 6;
        case "md":
          return 3;
        case "lg":
          return 3;
        case "xl":
          return 3;
        default:
          return 12;
      }
    },
  },
  mounted() {
    this.participantes = this.participantesSel;
    this.maxAsignaciones = this.reglas.filter((r) => {
      return r.empleados === this.participantesSel.length;
    });

    this.medallas[0].espaciosDisponibles = this.medallas[0].espaciosAsignados = this.maxAsignaciones[0].bronce;
    this.medallas[1].espaciosDisponibles = this.medallas[1].espaciosAsignados = this.maxAsignaciones[0].plata;
    this.medallas[2].espaciosDisponibles = this.medallas[2].espaciosAsignados = this.maxAsignaciones[0].oro;
    this.medallas[3].espaciosDisponibles = this.medallas[3].espaciosAsignados = this.maxAsignaciones[0].platino;
  },
  methods: {
    resetPuntosPregunta() {
      this.medallas.map((medalla) => {
        for (let i = 0; i < medalla.items.length; i++) {
          this.participantes.push(medalla.items.pop());
        }
      });
    },
    getColor(espacios) {
      if (espacios <= 0) {
        return "error";
      } else return "success";
    },
    validaCambio(medalla) {
      let indexMedalla = this.medallas.indexOf(medalla);
      if (this.medallas[indexMedalla].espaciosDisponibles <= 0) {
        this.medallas[indexMedalla].espaciosDisponibles =
          this.medallas[indexMedalla].espaciosAsignados -
          this.medallas[indexMedalla].items.length;
      } else {
        this.medallas[indexMedalla].espaciosDisponibles =
          this.medallas[indexMedalla].espaciosAsignados -
          this.medallas[indexMedalla].items.length;
      }
    },

    guardaCalificaciones() {
      let calificaciones = [];
      const promesas = this.medallas.map(async(med) => {
        let puntosPorMedalla = [];
        let puntos = 0;
        if (med.titulo == "BRONCE" || med.titulo == "BRONZE") {
          puntos = 1;
        } else if (med.titulo == "PLATA" || med.titulo == "SILVER") {
          puntos = 2;
        } else if (med.titulo == "ORO" || med.titulo == "GOLD") {
          puntos = 3;
        } else if (med.titulo == "PLATINO" || med.titulo == "PLATINUM") {
          puntos = 4;
        }
        med.items.map((pts) => {
          puntosPorMedalla.push({
            Evaluado: pts.Evaluado,
            Puntos: puntos,
          });
        });
        calificaciones.push(puntosPorMedalla);
      });

      // this.resetPuntosPregunta();
      Promise.all(promesas).then(()=>{
        this.$emit("cambiaPregunta", calificaciones);
      })
    },

    validaAsignaciones() {
      let errorEnmcontrado = false;
      this.medallas.map((medalla) => {
        if (medalla.espaciosDisponibles < 0) {
          errorEnmcontrado = true;
        }
      });

      return errorEnmcontrado;
    },

    getColorCategoria(categoria) {
      if (categoria == "Integridad") {
        return "#e7d333";
      } else if (categoria == "Pasion") {
        return "#ff153a";
      } else if (categoria == "Calidad") {
        return "#7030a0";
      } else if (categoria == "Liderazgo") {
        return "#00af4f";
      } else if (categoria == "Conocimiento del Trabajo") {
        return "#00afef";
      } else {
        return "#144975";
      }
    },

    getValor(categoria) {
      if (categoria == "Integridad") {
        return "valores-integridad-sinFondo.png";
      } else if (categoria == "Pasion") {
        return "valores-pasion-sinFondo.png";
      } else if (categoria == "Calidad") {
        return "valores-calidad.png";
      } else if (categoria == "Liderazgo") {
        return "valores-liderazgo.png";
      } else if (categoria == "Conocimiento del Trabajo") {
        return "valores-conocimiento.png";
      } else {
        return "valores-respeto-sinFondo.png";
      }
    },
  },
};
</script>

<style lang="sass">
.dnd-container
  background-color: lightgray
  padding: 20px
  cursor: move

.drag-enter
  height: 170px
  background-color: rgba(200, 200, 200, 0.2)

.drag-enter-participantes
  height: 300px


.card-mod
  min-height: 200px


@media (max-width: 480px)
  .card-mod
    min-height: 100px !important


  .drag-enter
    height: 70px !important


  .scroll-container-medallas
    height: 70px !important


  .drag-enter-participantes
    height: 170px !important


  .card-background-img
    width: 50px !important
    height: 60px !important


  .scroll-container-evaluados
    height: 150px !important


.scroll-container-evaluados
  height: 250px
  overflow-y: scroll
  background-color: transparent
  border-radius: 10px !important
  overflow-y: auto


.scroll-container-evaluados::-webkit-scrollbar
  width: 10px
  background-color: blue

.scroll-container-evaluados::-webkit-scrollbar-thumb
  background-color: red
  border-radius: 10px

.scroll-container-evaluados::-moz-scrollbar
  width: 10px
  background-color: rgba(240, 240, 240, 0.5)

.scroll-container-evaluados::-moz-scrollbar-thumb
  background-color: red
  border-radius: 10px

.scroll-container-evaluados::scrollbar
  width: 10px
  background-color: blue

.scroll-container-evaluados::scrollbar-thumb
  background-color: red
  border-radius: 10px


.card-background-img
  position: absolute
  width: 128px
  height: 150px
  top: 0
  left: 0
  opacity: 0.5

.no-drop
  cursor: not-allowed


.scroll-container-medallas
  height: 180px
  overflow-y: scroll
  background-color: transparent
  border-radius: 10px !important
  overflow-y: auto

  &.xs
    .scroll-container-medallas
    height: 70px !important
    overflow-y: scroll
    background-color: transparent
    border-radius: 10px !important
    overflow-y: auto



.scroll-container-medallas::-webkit-scrollbar
  width: 10px
  background-color: blue

.scroll-container-medallas::-webkit-scrollbar-thumb
  background-color: red
  border-radius: 10px

.scroll-container-medallas::-moz-scrollbar
  width: 10px
  background-color: rgba(240, 240, 240, 0.5)

.scroll-container-medallas::-moz-scrollbar-thumb
  background-color: red
  border-radius: 10px

.scroll-container-medallas::scrollbar
  width: 10px
  background-color: blue

.scroll-container-medallas::scrollbar-thumb
  background-color: red
  border-radius: 10px

.no-drop
  cursor: not-allowed

.animated-btn-sig
  transition: all 0.3s ease

.animated-btn-sig:hover
  transform: scale(1.05)
</style>
