<template>
  <v-card class="rounded-lg pa-2 elevation-0">
    <div style="display: flex; justify-content: space-between;">
      <label class="text-h6 font-weight-black blue-grey--text text--lighten-1">
        Mis visitas registradas
      </label>
      <v-text-field
        v-model="search"
        outlined
        dense
        prepend-inner-icon="mdi-magnify"
        label="Buscar..."
        class="rounded-lg"
        style="max-width: 350px;"
      ></v-text-field>
    </div>
    <v-data-table
      dense
      :headers="headers"
      :items="visitas"
      :search="search"
      :items-per-page="5"
      no-data-text="No se han registrado visitas"
      loading-text="Cargando información..."
      :loading="loadingData"
      :footer-props="{
        'items-per-page-text': 'Elementos por página',
        pageText: '{0}-{1} de {2}',
      }"
      :single-expand="true"
      :expanded.sync="expanded"
      item-key="id"
      show-expand
    >
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length" style="max-width: 100% !important;">
          <div class="text-center pt-3 pb-0">
            <label
              class="text-h6 font-weight-black blue-grey--text text--lighten-3"
            >
              Información completa de la visita
            </label>
          </div>
          <v-card
            class="py-3 px-4 mx-2 mb-2 mt-0 rounded-xl elevation-0"
            color="blue-grey lighten-2"
            style=" display: flex; justify-content: space-between; flex-wrap: wrap;"
          >
            <div
              class="ma-1"
              v-for="(element, index) in Object.keys(item)"
              :key="index"
            >
              <v-chip
                class="rounded-lg"
                v-show="![null, undefined, ''].includes(item[element])"
              >
                <strong
                  class="text-uppercase overline blue-grey--text text--darken-2"
                  >{{ element }}: </strong
                >{{ getData(element, item[element]) }}
                <!-- {{ item[element] }} -->
              </v-chip>
            </div>
          </v-card>
          <v-card
            v-if="expanded.includes(item)"
            class="py-2 px-4 mx-2 mb-2 mt-1 rounded-xl elevation-0"
            color="blue-grey lighten-2"
          >
            <MuestraVisitantesPorGrupo :idVisita="item.id" />
          </v-card>
        </td>
      </template>
      <template v-slot:item.id="{ item }">
        <label class="caption font-weight-bold">{{ item.id }}</label>
      </template>
      <template v-slot:item.fechas="{ item }">
        {{ getCadenaFechas(item) }}
      </template>
      <template v-slot:item.duracion="{ item }">
        <label class="caption font-weight-bold">
          {{
            item.Entrada.date.substring(11, 16) +
              " a " +
              item.Salida.date.substring(11, 16)
          }}
        </label>
      </template>
      <template v-slot:item.Nombre="{ item }">
        {{ item.Nombre + " " + item.Paterno + " " + item.Materno }}
      </template>
      <template v-slot:item.id_tipo="{ item }">
        <label class="caption"> {{ getTipo(item.id_tipo) }}</label>
      </template>
      <template v-slot:item.estatus="{ item }">
        <v-chip small :class="getClass(item.estatus)">{{
          getTextEstatus(item.estatus)
        }}</v-chip>
      </template>
      <template v-slot:item.grupo="{ item }">
        <v-chip small dark :class="item.grupo === 1 ? 'cyan' : 'purple'">
          {{ item.grupo === 1 ? "Individual" : "Grupal" }}
        </v-chip>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import axios from "axios";
import MuestraVisitantesPorGrupo from "../../AdmonVisitas/components/MuestraVisitantesPorGrupo";
import {
  deleteToken,
  formatDate,
} from "../../../../../store/Funciones/funciones.js";
export default {
  name: "muestra-visitas-registradas-por-usuario",
  components: { MuestraVisitantesPorGrupo },
  data() {
    return {
      expanded: [],
      visitas: [],
      tipoVisitas: [],
      headers: [
        {
          value: "id",
          text: "id",
          class:
            "font-weight-bold blue-grey--text text--darken-2 text-uppercase",
        },
        {
          value: "fechas",
          text: "fechas",
          class:
            "font-weight-bold blue-grey--text text--darken-2 text-uppercase",
        },
        {
          value: "duracion",
          text: "duración",
          class:
            "font-weight-bold blue-grey--text text--darken-2 text-uppercase",
        },
        {
          value: "Nombre",
          text: "nombre",
          class:
            "font-weight-bold blue-grey--text text--darken-2 text-uppercase",
        },
        {
          value: "id_tipo",
          text: "tipo",
          class:
            "font-weight-bold blue-grey--text text--darken-2 text-uppercase",
          align: "center",
        },
        {
          value: "Razon",
          text: "razón",
          class:
            "font-weight-bold blue-grey--text text--darken-2 text-uppercase",
        },
        {
          value: "Ubicacion",
          text: "ubicación",
          class:
            "font-weight-bold blue-grey--text text--darken-2 text-uppercase",
          align: "center",
        },
        {
          value: "estatus",
          text: "estatus",
          class:
            "font-weight-bold blue-grey--text text--darken-2 text-uppercase",
          align: "center",
        },
        {
          value: "grupo",
          text: "grupo",
          class:
            "font-weight-bold blue-grey--text text--darken-2 text-uppercase",
          align: "center",
        },
        { text: "", value: "data-table-expand" },
      ],
      loadingData: true,
      search: "",
    };
  },
  async mounted() {
    this.tipoVisitas = await this.getTiposVisitantes();
    this.visitas = await this.getRegistros();
  },
  methods: {
    getRegistros() {
      let params = {
        Opcion: 54,
        token: localStorage.getItem("token"),
      };
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${this.$store.getters.getHost}/SeguridadPatrimonial/ws-RegistroVisitantes.php`,
            params
          )
          .then((response) => {
            if (response.data.status == "OK") {
              resolve(response.data.respuesta);
            } else if (response.data.status == "EXPSESSION") {
              deleteToken();
            } else {
              resolve([]);
            }
          })
          .catch((error) => {
            reject([{ nombre: error }]);
          })
          .finally(() => {
            this.loadingData = false;
          });
      });
    },
    async getTiposVisitantes() {
      let params = {
        Opcion: 1,
        token: localStorage.getItem("token"),
      };
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${this.$store.getters.getHost}/SeguridadPatrimonial/ws-RegistroVisitantes.php`,
            params
          )
          .then((response) => {
            if (response.data.status == "OK") {
              resolve(response.data.tipos);
            } else if (response.data.status == "EXPSESSION") {
              deleteToken();
            }
          })
          .catch((error) => {
            reject([{ nombre: error }]);
          })
          .finally(() => {
            this.loadingTipoVisita = false;
          });
      });
    },
    getTipo(id_tipo) {
      let findTipo = this.tipoVisitas.filter((t) => {
        return t.id == id_tipo;
      })[0];

      return findTipo.descr;
    },
    fixDate(date) {
      return formatDate(date);
    },
    getClass(estatus) {
      if (estatus === 0) {
        return "amber black--text font-weight-bold";
      } else if (estatus === 1) {
        return "teal darken-1 white--text font-weight-bold";
      } else if (estatus === 2) {
        return "pink darken-3 white--text font-weight-bold";
      } else if (estatus === 3) {
        return "new_primary white--text font-weight-bold";
      } else if (estatus === 4) {
        return "primary white--text font-weight-bold";
      }
    },
    getTextEstatus(estatus) {
      if (estatus === 0) {
        return "Pendiente";
      } else if (estatus === 1) {
        return "Autorizada";
      } else if (estatus === 2) {
        return "Rechazada";
      } else if (estatus === 3) {
        return "En planta";
      } else if (estatus === 4) {
        return "Concluida";
      }
    },
    getCadenaFechas(item) {
      return item.DiaFin === null
        ? formatDate(item.Dia.date.substring(0, 10))
        : item.Dia.date.substring(0, 10) === item.DiaFin.date.substring(0, 10)
        ? formatDate(item.Dia.date.substring(0, 10))
        : formatDate(item.Dia.date.substring(0, 10)) +
          " a " +
          formatDate(item.DiaFin.date.substring(0, 10));
    },
    validaFecha(key, element) {
      if (["Entrada", "Salida"].includes(key)) {
        return element.date.substring(11, 16);
      } else {
        return element.date.substring(0, 10);
      }
    },
    getData(key, element) {
      if (typeof element === "object" && element !== null) {
        if ("date" in element) {
          return this.validaFecha(key, element);
        } else {
          return "N/D";
        }
      } else {
        return element;
      }
    },
  },
};
</script>
