<template>
  <v-container>
    <!-- <v-card> -->
    <v-card-text class="pt-5">
      <v-data-table
        :headers="headers"
        :items="plantas"
        :search="buscar"
        :loading="loading"
        single-expand
        :expanded.sync="expanded"
        show-expand
        item-key="id"
        loading-text="Cargando plantas"
        class="elevation-0"
        dense
        mobile-breakpoint="0"
        :footer-props="{ 'items-per-page-text': 'Elementos por página' }"
        no-data-text="No se encontraron registros"
      >
        <template v-slot:top>
          <v-toolbar flat class="rounded-lg rounded-b-0 rounded-t-0">
            <v-text-field
              v-model="buscar"
              :label="$t('DH360.findTextBox')"
              class="mt-7 rounded-lg"
              dense
              clearable
              outlined
              solo-inverted
              prepend-inner-icon="mdi-table-search"
            ></v-text-field>
            <v-spacer></v-spacer>
            <v-btn
              color="teal darken-2"
              elevation="2"
              class="rounded-lg"
              dark
              small
              @click="openDialgoForm('Nuevo')"
              >Nuevo</v-btn
            >
          </v-toolbar>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <div v-if="item.departamentos">
              <v-card
                class="scrollable-div rounded-xl ma-5"
                v-if="item.departamentos.length > 0"
                elevation="1"
              >
                <v-card-title
                  class="text-subtitle-2 grey lighten-1 white--text"
                >
                  Departamentos seleccionados:
                  {{ item.departamentos.length }}
                </v-card-title>
                <v-btn
                  v-for="(i, index) in item.departamentos"
                  :key="index"
                  rounded
                  color="grey lighten-3"
                  class="pa-2 ma-1 truncate-text"
                  small
                >
                  {{ i.descripcionMostrar }}
                </v-btn>
              </v-card>
              <div v-else>Nada</div>
            </div>
            <div v-else>
              <v-card class="scrollable-div rounded-xl ma-5" elevation="1">
                <v-card-title
                  class="text-subtitle-2 grey lighten-1 white--text"
                >
                  Departamentos seleccionados: 0
                </v-card-title>
                <v-card-text class="disabled-text d-flex justify-center align-center">
                  <v-icon>mdi-alert</v-icon>
                  Aún no cuenta con departamentos relacionados
                </v-card-text>
              </v-card>
            </div>
          </td>
        </template>
        <template v-slot:item.habilitado="{ item }">
          <v-btn
            v-if="item.habilitado == 1"
            x-small
            rounded
            elevation="1"
            color="teal darken-2"
            dark
          >
            Habilitado
          </v-btn>
          <v-btn v-else x-small rounded elevation="1" color="pink" dark>
            Inhabilitado
          </v-btn>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip top color="blue lighten-4">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="blue darken-4"
                v-bind="attrs"
                v-on="on"
                @click="openDialgoForm(item)"
              >
                mdi-pencil-box-outline
              </v-icon>
            </template>
            <label class="text-uppercase font-weight-bold primary--text"
              >Editar</label
            >
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card-text>
    <!-- </v-card> -->
    <form-admon-plantas
      v-if="dialogForm"
      :dialog="dialogForm"
      :item="item"
      @cierraForm="closeForm"
    ></form-admon-plantas>
    <!-- <SnackBarErrorIncidencias
      v-if="muestraBarra"
      :muestraBarra="muestraBarra"
      :texto="textoBarra"
      :color="colorBarra"
      @cierraBarra="muestraBarra = false"
    /> -->
  </v-container>
</template>

<script>
import axios from "axios";
import { deleteToken } from "../../../../../store/Funciones/funciones";
import FormAdmonPlantas from "./FormAdmonPlantas.vue";

export default {
  name: "lista-admon-plantas",
  props: [],
  components: {
    FormAdmonPlantas,
  },
  data() {
    return {
      textoBarra: "",
      colorBarra: "",
      muestraBarra: false,

      headers: [
        { text: "ID", value: "id", align: "center", sortable: true },
        {
          text: "Planta",
          value: "descripcion",
          align: "start",
          sortable: true,
        },
        {
          text: "Estatus",
          value: "habilitado",
          align: "center",
          sortable: true,
        },
        { text: "Acciones", value: "actions", align: "center", sortable: true },
      ],
      buscar: "",
      plantas: [],
      loading: true,
      dialogForm: false,
      expanded: [],
    };
  },
  created() {},
  computed: {},
  mounted() {
    this.getPlantas();
  },
  methods: {
    getPlantas() {
      const headers = { Authorization: localStorage.getItem("token") };
      return new Promise((res) => {
        axios
          .get(`${this.$store.getters.getHostNode}/api/get-plantas`, {
            headers,
          })
          .then((response) => {
            if (response.data.status == "OK") {
              res(response.data.data);
              this.plantas = response.data.data;
            } else if (response.data.status == "EXPRESSION") {
              deleteToken();
            } else {
              res([]);
            }
          })
          .finally(() => {
            this.loading = false;
          });
      });
    },

    openDialgoForm(item) {
      this.dialogForm = true;
      this.item = item;
    },

    closeForm(response) {
      this.dialogForm = false;
      if (response == "OK") {
        this.clean();
        this.getPlantas();
      }
    },

    clean() {
      this.plantas = [];
      this.loading = true;
    },
  },
};
</script>

<style scoped>
.scrollable-div {
  /* width: 300px; Ancho fijo */
  height: 150px; /* Alto fijo */
  overflow-y: auto; /* Permitir scroll vertical si es necesario */
  overflow-x: hidden; /* Ocultar el scroll horizontal */
  border: 1px solid #ccc; /* Solo para visualización */
  /* padding: 10px; */
}
</style>