<template>
  <div>
    <div class="text-center">
      <label class="overline font-weight-bold blue-grey--text text--darken-2"
        >Acciones</label
      >
      <v-divider class="mt-1 mb-3 mx-5"></v-divider>

      <EditaVariablesDeCaja />

      <CerrarCajaPorPeriodo />

    </div>
  </div>
</template>

<script>

import EditaVariablesDeCaja from "./actions/EditaVariablesDeCaja"
import CerrarCajaPorPeriodo from "./actions/CerrarCajaPorPeriodo.vue"

export default {
  components: {EditaVariablesDeCaja, CerrarCajaPorPeriodo},
  data() {
    return {
    };
  },
  async mounted() {},
  methods: {
  },
};
</script>

<style>
.sdw-btn {
  box-shadow: 11px 10px 15px -3px rgba(0, 0, 0, 0.1) !important;
}
</style>
