<template>
  <v-container>
    <v-dialog v-model="dialog" max-width="700px" persistent>
      <v-card>
        <v-card-title class="text-subtitle-2 primary white--text">
          Carga masiva de EPP - Certificaciones
          <v-spacer></v-spacer>
          <v-icon small dark @click="$emit('cerrarTicket', 0)"
            >mdi mdi-close
          </v-icon>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                class="pb-0"
                cols="12"
                xl="12"
                lg="12"
                md="12"
                sm="12"
                xs="12"
              >
                <v-autocomplete
                  :items="epp"
                  item-text="equipo"
                  item-value="id"
                  v-model="eppAsignado"
                  multiple
                  filled
                  chips
                  deletable-chips
                  small-chips
                  dense
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            v-if="eppAsignado.length == 0"
            class="rounded-lg elevation-0"
            disabled
          >
            <v-icon left>mdi-check-circle-outline</v-icon> Guardar
          </v-btn>
          <v-btn
            v-else
            class="rounded-lg elevation-0"
            color="teal darken-1"
            dark
            :loading="loading"
            @click="guardarAsignaciones()"
          >
            <v-icon left>mdi-check-circle-outline</v-icon> Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <SnackBarError
      v-if="muestraBarra"
      :muestraBarra="muestraBarra"
      :texto="textoBarra"
      :color="colorBarra"
      @cierraBarra="muestraBarra = false"
    />
  </v-container>
</template>

<script>
import axios from "axios";
import { deleteToken } from "../../../../../store/Funciones/funciones";
import SnackBarError from "../../../General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue";
export default {
  name: "form-carga-epp-por-certificaciones",
  components: { SnackBarError },
  props: ["dialog", "item", "tipo"],
  data() {
    return {
      textoBarra: "",
      colorBarra: "",
      muestraBarra: false,

      eppAsignado: [],
      epp: [],
      loading: false,
    };
  },
  computed: {},
  mounted() {
    this.getCatalogoEPP();
      console.log(this.item);
  },
  methods: {
    guardarAsignaciones() {
      let params = {
        Opcion: 10,
        tipo: this.tipo, // indivudual
        item: this.item, //mandar item.idCertificacion
        eppAsignado: this.eppAsignado,
        token: localStorage.getItem("token"),
      };
      console.log(params);
      axios
        .post(
          `${this.$store.getters.getHost}/General/EPP/ws-admon-epp.php`,
          params
        )
        .then((response) => {
          if (response.data.status == "OK") {
            this.textoBarra ="Solicitud registrada correctamente.";
            this.colorBarra = "success";
            this.muestraBarra = true;
            this.$emit("cerrarTicket", response.data.response);
          } else if (response.data.status == "ERROR") {
            this.textoBarra = "Algo salió mal.";
            this.colorBarra = "error";
            this.muestraBarra = true;
          }
        });
    },

    getCatalogoEPP() {
      let params = {
        Opcion: 13,
        item: this.item.epp,
        tipo: this.tipo,
        token: localStorage.getItem("token"),
      };
      axios
        .post(
          `${this.$store.getters.getHost}/General/EPP/ws-admon-epp.php`,
          params
        )
        .then((response) => {
          this.loadingInfo = false;
          if (response.data.status == "VACIO") {
            console.log("Vacio");
          } else if (response.data.status == "error") {
            console.log("error");
          } else if (response.data.status == "OK") {
            this.epp = response.data.catalogo;
          } else if (response.data.status == "EXPRESSION") {
            deleteToken();
          }
        })
        .catch(function (e) {
          alert("Ocurrió un error: " + e);
        });
    },
  },
};
</script>
