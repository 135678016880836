var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"rounded-lg pa-2",attrs:{"elevation":"3"}},[_c('div',{staticClass:"mb-2"},[_c('v-progress-linear',{staticClass:"rounded-pill",attrs:{"color":_vm.getColor(),"height":"25"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var value = ref.value;
return [_c('strong',{staticStyle:{"color":"white"}},[_vm._v(_vm._s(value.toFixed(2))+"%")])]}}]),model:{value:(_vm.porcentajeAvance),callback:function ($$v) {_vm.porcentajeAvance=$$v},expression:"porcentajeAvance"}})],1),_c('div',[_c('p',{staticClass:"text-center font-weight-bold my-1",staticStyle:{"font-size":"1.05rem"},attrs:{"color":_vm.getColor()}},[_vm._v(" "+_vm._s(_vm.avance)+" "+_vm._s(_vm.$t("DH360.of"))+" "+_vm._s(_vm.avance + _vm.faltantes)+" ")])]),_c('v-data-table',{staticClass:"elevation-2",attrs:{"headers":_vm.headers,"items":_vm.participantes,"items-per-page":_vm.itemsPerPage,"footer-props":{
      'items-per-page-options': [5, 10, 50, 100],
      'items-per-page-text': 'Elementos por página',
    },"dense":"","no-data-text":"No hay registros","header-props":_vm.headerProps,"loading":_vm.loadingTabla,"search":_vm.search,"item-key":"Evaluador","single-expand":_vm.singleExpand,"expanded":_vm.expanded,"show-expand":""},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{staticClass:"rounded-lg rounded-b-0",attrs:{"flat":"","color":"primary"}},[_c('v-text-field',{staticClass:"mt-7",attrs:{"label":_vm.$t('DH360.findTextBox'),"loading":_vm.loadingTabla,"dense":"","clearable":"","flat":"","solo-inverted":"","dark":"","prepend-inner-icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-divider',{staticClass:"mx-6",staticStyle:{"border-color":"lightslategrey"},attrs:{"inset":"","vertical":""}}),_c('v-btn',{staticClass:"mb-1 mr-1 font-weight-bold rounded-lg",attrs:{"text":"","color":"white","dark":"","loading":_vm.loadingTabla},on:{"click":function($event){_vm.showSurveysPostDate = true}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-clipboard-text-multiple-outline")]),_vm._v(" "+_vm._s(_vm.$t("DH360.uploadClients"))+" ")],1),_c('vue-json-to-csv',{attrs:{"json-data":_vm.participantes,"labels":_vm.headers_report_to_export,"csv-title":_vm.nameFile}},[_c('v-btn',{staticClass:"mb-1 font-weight-bold rounded-lg",attrs:{"color":"green darken-1","dark":"","loading":_vm.loadingTabla}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-microsoft-excel")]),_vm._v(" "+_vm._s(_vm.$t("DH360.export"))+" ")],1)],1)],1)]},proxy:true},{key:"expanded-item",fn:function(ref){
    var headers = ref.headers;
    var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('MuestraEvaluacionesPendientesDetalle',{attrs:{"idProyecto":_vm.idProyecto,"empleado":item.Evaluador}})],1)]}},{key:"item.ESTATUS",fn:function(ref){
    var item = ref.item;
return [_c('p',[_vm._v(_vm._s(item.Contestadas)+" "+_vm._s(_vm.$t("DH360.of"))+" "+_vm._s(item.Totales))])]}},{key:"item.ACTIONS",fn:function(ref){
    var item = ref.item;
return [_c('v-icon',{staticClass:"ml-1",attrs:{"small":""},on:{"click":function($event){return _vm.openSendMail(item.Correo)}}},[_vm._v(" mdi-email ")])]}}])}),(_vm.sendMail)?_c('PersonaDialogSendMail',{attrs:{"showSendMail":_vm.sendMail,"defaultMsg":_vm.textMsgMail,"receptor":_vm.mailSelected},on:{"hideSendMail":_vm.closeSendMail}}):_vm._e(),_c('SnackBar',{attrs:{"muestraBarra":_vm.muestraBarra,"texto":_vm.textoBarra,"color":_vm.colorBarra},on:{"cierraBarra":function($event){_vm.muestraBarra = false}}}),(_vm.showSurveysPostDate)?_c('AdmonEncuestasClientesPorGenerar',{attrs:{"showSurveysPostDate":_vm.showSurveysPostDate,"idProyecto":_vm.idProyecto},on:{"hideSurveysPostDate":_vm.cierraEncuestasPorGenerar}}):_vm._e(),(_vm.modalTicket)?_c('AsignaPuntosPorPregunta',{attrs:{"abrirModalTicket":_vm.modalTicket},on:{"cerrarTicket":function($event){_vm.modalTicket = false}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }