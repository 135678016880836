var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"rounded-xl mt-5 elevation-0 pa-10",attrs:{"width":"100%","color":"blue-grey lighten-5"}},[(_vm.loadingAreas)?_c('div',{staticClass:"center-item-in-div-column"},[_c('v-progress-circular',{attrs:{"size":180,"width":12,"color":"purple","indeterminate":""}}),_c('div',{staticClass:"overline"},[_vm._v("cargando áreas...")])],1):_c('div',[_c('div',{staticClass:"text-center mb-3 text-h5 text-uppercase blue-grey--text text--darken-2 font-weight-black"},[_vm._v(" Selecciona un área ")]),_c('div',{staticClass:"container-cards"},_vm._l((_vm.areas),function(area,index){return _c('v-card',{key:index,staticClass:"rounded-xl pa-5",attrs:{"color":index % 2 === 0 ? 'primary' : '#a8a8a7',"width":_vm.widthCards,"height":"100"},on:{"click":function($event){_vm.loadingBtn = true;
  _vm.indexAux = index;
  _vm.areaAux = area.id;
  _vm.getAutorizadores();}}},[_c('v-sheet',{staticClass:"center-item-in-div text-uppercase font-weight-bold white--text",class:['xs', 'sm'].includes(_vm.$vuetify.breakpoint.name)
      ? 'subtitle-1'
      : 'text-xs',attrs:{"height":"100%","color":"transparent"}},[(_vm.loadingBtn && index == _vm.indexAux)?_c('div',[_c('v-progress-circular',{attrs:{"size":50,"width":8,"color":"white","indeterminate":""}})],1):_c('div',[_vm._v(_vm._s(area.Area))])])],1)}),1)]),_c('v-dialog',{attrs:{"max-width":"750","persistent":""},model:{value:(_vm.muestraAutorizadores),callback:function ($$v) {_vm.muestraAutorizadores=$$v},expression:"muestraAutorizadores"}},[_c('v-card',{staticClass:"rounded-lg"},[_c('v-card-title',{staticClass:"primary white--text overline font-weight-bold"},[_vm._v(" autorizadores. "),_c('v-spacer'),_c('v-icon',{attrs:{"dark":""},on:{"click":function($event){_vm.muestraAutorizadores = false}}},[_vm._v("mdi-close")])],1),_c('v-card-text',[_c('v-data-table',{attrs:{"dense":"","locale":"es-MX","headers":_vm.headers,"items":_vm.autorizadores,"no-data-text":"No se han registrado autorizadores en esta área.","loading-text":"Cargando información...","loading":_vm.loadingAuth,"footer-props":{
    'items-per-page-text': 'Elementos por página',
    'pageText': '{0}-{1} de {2}',
  }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"my-2",staticStyle:{"display":"flex","justify-content":"end"}},[_c('v-btn',{staticClass:"rounded-lg",attrs:{"dark":"","depressed":"","small":"","color":"teal darken-1"},on:{"click":function($event){_vm.showFindNewMembers = true}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-account-plus")]),_vm._v(" Añadir autorizador ")],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
  var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"rounded-lg pa-0",attrs:{"depressed":"","x-small":"","color":"pink darken-3","dark":""},on:{"click":function($event){_vm.autorizadorAux = item.id;
  _vm.showConfirm = true;}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-minus")])],1)]}}],null,true)},[_c('span',[_vm._v("Eliminar")])])]}}])})],1)],1),(_vm.showFindNewMembers)?_c('BuscaColaboradoresActivos',{attrs:{"showFindNewMembers":_vm.showFindNewMembers},on:{"returnColaborrador":_vm.validaColaborador}}):_vm._e(),(_vm.showConfirm)?_c('ConfirmDialog',{attrs:{"showConfirm":_vm.showConfirm,"question":"¿Realmente deseas eliminar este registro?"},on:{"cierraConfirm":_vm.validaConfirm}}):_vm._e(),(_vm.muestraBarra)?_c('SnackBarErrorIncidencias',{attrs:{"muestraBarra":_vm.muestraBarra,"color":_vm.colorBarra,"texto":_vm.textoBarra},on:{"cierraBarra":function($event){_vm.muestraBarra = false}}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }