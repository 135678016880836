<template>
  <v-dialog v-model="dialog" persistent fullscreen>
    <v-card>
      <v-card-title class="text-subtitle-2 primary white--text">
        Encuesta personal temporal
        <v-spacer></v-spacer>
        <v-icon small dark @click="$emit('cierraDialgoEncuesta', {result: 'Cancelar'})">mdi-close</v-icon>
      </v-card-title>
      <v-card-text class="mt-5">
        <v-row>
          <v-col cols="12" xl="7" lg="7" md="7" sm="12" xs="12">
            <v-hover v-slot="{ hover }">
              <v-card
                class="mx-auto"
                color="grey lighten-4"
                max-width="600"
              >
                <div>
                  <v-card-title class="text-subtitle-2 primary white--text">
                    Información del evaluado 
                    <v-spacer></v-spacer>
                  </v-card-title>
                  <v-expand-transition>
                    <div
                      v-if="hover"
                      class="d-flex transition-fast-in-fast-out blue-grey lighten-5 v-card--reveal text-h2 white--text"
                      style="height: 100%;"
                    >
                      <v-card-text>
                        --{{evaluado['foto']}}
                        <v-row>
                          <v-col class="d-flex align-center" align="left" xl="4" lg="4" md="4" sm="12" xs="12">
                              <v-avatar size="150" tile>
                              <img
                                  v-if="evaluado['foto']"
                                  alt=""
                                  :src="'https://apidh.urrea.com//ArchivosCargadosEnPortal2/FotosPerfilUsuarios/' + evaluado['foto']"
                                  aling="absmiddle"
                                  width="200px"
                              />
                              <img
                                  v-else
                                  alt=""
                                  :src="'https://community.f5.com/legacyfs/online/avatars/0051T000008uOPOQA2.png'"
                                  aling="absmiddle"
                                  width="200px"
                              />
                              </v-avatar>
                          </v-col>
                          <v-col class="d-flex align-center black--text" align="left" xl="8" lg="8" md="8" sm="12" xs="12">
                              <v-card-text class="pt-0">
                              <div align="center">
                                  <img
                                  alt=""
                                  :src="require('@/assets/img/grupo-urrea-logo-bn.png')"
                                  aling="absmiddle"
                                  width="200px"
                                  />
                              </div>
                              <b>EMPLID</b> {{ evaluado.emplid }} <br />
                              <b>Nombre</b> {{ evaluado.nombre }} <br />
                              <b>Puesto</b> {{ evaluado.DESCR }} <br />
                              <b>Departamento</b> {{ evaluado.descr1 }}
                              </v-card-text>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </div>
                  </v-expand-transition>
                  <v-card-text class="pt-6" style="position: relative;">
                    <v-btn absolute color="primary" class="white--text" fab large right top>
                      <v-icon>mdi-account</v-icon>
                    </v-btn>
                  </v-card-text>
                </div>
              </v-card>
            </v-hover>
          </v-col>
            <v-col cols="12" xl="5" lg="5" md="5" sm="12" xs="12">
                <v-card class="rounded-lg">
                    <v-alert icon="mdi-file-document-edit-outline" prominent color="blue darken-1" dark dense elevation="1">
                      Por favor <b>responda las preguntas</b> que aparecen a continuación <b>marcado con un (*) </b>
                  </v-alert>
                </v-card>
            </v-col>
        </v-row>
        <v-card class="rounded-lg mt-5" v-if="loadingPreguntas">
          <v-row class="fill-height" align-content="center" justify="center">
            <v-col class="text-subtitle-1 text-center" cols="12">
              Cargando Evaluación
            </v-col>
            <v-col cols="8">
              <v-progress-linear color="deep-purple accent-4" indeterminate rounded height="8"></v-progress-linear>
            </v-col>
          </v-row>
        </v-card>
        <v-card class="rounded-lg mt-5" v-else>
          <v-stepper v-model="step">
            <v-stepper-header>
              <div v-for="cualidad in cualidades" :key="cualidad.id">
                <v-stepper-step :complete="step > cualidad.id" :step="cualidad.id">
                  <div class="caption">{{ cualidad.nombre }}</div>
                </v-stepper-step>
              </div>
            </v-stepper-header>

            <v-stepper-items>
              <v-stepper-content step="1">
                <preguntas-con-medallas :step="1" :preguntas="cualidades[0].preguntas" :categoria="cualidades[0].nombre" @continuaCategoria="cierraDialogEncuestas"></preguntas-con-medallas>
              </v-stepper-content>

              <v-stepper-content step="2">
                <preguntas-con-medallas :step="2" :preguntas="cualidades[1].preguntas" :categoria="cualidades[0].nombre" @continuaCategoria="cierraDialogEncuestas"></preguntas-con-medallas>
              </v-stepper-content>

              <v-stepper-content step="3">
                <preguntas-con-medallas :step="3" :preguntas="cualidades[2].preguntas" :categoria="cualidades[0].nombre" @continuaCategoria="cierraDialogEncuestas"></preguntas-con-medallas>
              </v-stepper-content>

              <v-stepper-content step="4">
                <preguntas-con-medallas :step="4" :preguntas="cualidades[3].preguntas" :categoria="cualidades[0].nombre" @continuaCategoria="cierraDialogEncuestas"></preguntas-con-medallas>
              </v-stepper-content>

              <v-stepper-content step="5">
                <preguntas-con-medallas :step="5" :preguntas="cualidades[4].preguntas" :categoria="cualidades[0].nombre" @continuaCategoria="cierraDialogEncuestas"></preguntas-con-medallas>
              </v-stepper-content>

            </v-stepper-items>
          </v-stepper>
        </v-card>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
    <SnackBar v-if="muestraBarra" :muestraBarra="muestraBarra" :texto="textoBarra" :color="colorBarra"
      @cierraBarra="muestraBarra = false" />
  </v-dialog>
</template>



<script>
import axios from 'axios'
import { deleteToken } from '../../../../../../store/Funciones/funciones'
import PreguntasConMedallas from './PreguntasConMedallas.vue';
import SnackBar from "../../../../General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue"
export default {
  name: "encuestas-personal-temporal",
  components: { PreguntasConMedallas,SnackBar},
  props: ["dialog", "evaluado"],
  data: () => ({
      textoBarra: '',
      colorBarra: '',
      muestraBarra: false,
      
      step: 1,
      idEncuesta:'',
      cualidades:[],
      resultados:[],
      loadingPreguntas:true,
      openConfirmDialog:false,
      fortaleza:'',
      oportunidad:'',
  }),
  created() {},
  mounted() {
    this.getCategorias();
  },

  methods: {

    getCategorias() {
      let params = {
        Opcion: 5,
        idEncuesta:this.evaluado["id"],
        idTipo:this.evaluado["id_tipo"],
        token: localStorage.getItem('token'),
      }
      axios.post(`${this.$store.getters.getHost}/General/PersonalTemporal/ws-personal-temporal.php`, params).then((response) => {
        this.loadingPreguntas = false;
        if (response.data.status == "OK") {
          this.cualidades = response.data.categorias;
          this.idEncuesta = response.data.encuestador["id"];
        } else if (response.data.status == "VACIO") {
          console.log("Vacio");
        } else if (response.data.status == "EXPRESSION") {
          deleteToken();
        }
      })
    },

    cierraDialogEncuestas(step){
      this.resultados.push(step.respuestas);
      if(step.categoriaSiguiente == 'Final'){
        this.fortaleza = step.fortaleza;
        this.oportunidad = step.oportunidad;
        this.guardar();
      } else if(step.categoriaSiguiente == 6){
        this.openConfirmDialog = true;
      }else{
        this.step = step.categoriaSiguiente;
      }
    },
    
    guardar() {
      let params = {
        Opcion: 6,
        idEncuesta:this.idEncuesta,
        resultados:this.resultados,
        fortaleza:this.fortaleza,
        oportunidad:this.oportunidad,
        idEvaluacion:this.evaluado["id"],
        token: localStorage.getItem('token'),
      }
      axios.post(`${this.$store.getters.getHost}/General/PersonalTemporal/ws-personal-temporal.php`, params).then((response) => {
        this.loadingPreguntas = false;
        if (response.data.status == "OK") {
          this.$emit("cierraDialgoEncuesta", {result: "OK"})
        } else if (response.data.status == "ERROR") {
          console.log(response.data.status);
        } else if (response.data.status == "EXPRESSION") {
          deleteToken();
        }
      })
    },
  },
};
</script>