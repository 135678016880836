<template>
  <div>
    <v-dialog v-model="muestraAgregaBenficio" content-class="fix-border-dialog" max-width="700" scrollable>
      <v-card class="rounded-xl">
        <v-card-title class="primary rounded-xl rounded-b-0 white--text subtitle-1 font-weight-bold">
          Aministrar beneficio
          <v-spacer></v-spacer>
          <v-btn icon dark @click="$emit('ocultaAgregaBeneficio', 0)">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-form ref="formAddBeneficio">
            <v-row dense>
              <v-col :cols="['xs', 'sm'].includes($vuetify.breakpoint.name) ? 12 : 12">
                <label class="overline"> Beneficio </label>
                <v-text-field v-model="namebeneficio" dense class="rounded-lg" outlined :rules="namebeneficioRules"
                  counter maxlength="255" placeholder="Beneficio..."></v-text-field>
              </v-col>
              <v-col :cols="['xs', 'sm'].includes($vuetify.breakpoint.name) ? 12 : 12">
                <label class="overline"> Descripción </label>
                <v-textarea rows="3" v-model="descripcion" auto-grow dense class="rounded-lg" outlined
                  :rules="descripcionRules" counter maxlength="255"
                  placeholder="Descripción del beneficio..."></v-textarea>
              </v-col>
              <v-col :cols="['xs', 'sm'].includes($vuetify.breakpoint.name) ? 12 : 12">
                <label class="overline"> Beneficio disponible </label>
                <v-switch v-model="switch1" inset :label="switch1 ? 'Habilitado' : 'Deshabilitado'"></v-switch>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="pa-0">
          <v-btn class="rounded-xl rounded-t-0" block text color="teal darken-1" x-large :loading="loadingSave"
            @click="saveBeneficio">
            guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <SnackBar v-if="muestraBarra" :muestraBarra="muestraBarra" :color="colorBarra" :texto="textoBarra"
      @cierraBarra="muestraBarra = false" />
  </div>
</template>

<script>
import axios from "axios";
import SnackBar from "../../../General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue";

export default {
  name: "agrega-beneficio",
  props: ["muestraAgregaBenficio", "beneficio"],
  components: { SnackBar },
  data() {
    return {
      namebeneficio: '',
      descripcion: '',
      loadingSave: false,
      muestraBarra: false,
      textoBarra: "",
      colorBarra: "",
      namebeneficioRules: [
        value => !!value || 'El beneficio es requerido',
      ],
      descripcionRules: [
        value => !!value || 'La descripción es requerida',
      ],
      switch1: true,
    };
  },
  mounted() {
    if (this.beneficio != null) {
      this.descripcion = this.beneficio.descripcion;
      this.namebeneficio = this.beneficio.beneficio;
      this.switch1 = this.beneficio.activo;
    }
  },
  methods: {
    muestraSnackBar(color, texto) {
      this.colorBarra = color;
      this.textoBarra = texto;
      this.muestraBarra = true;
    },
    async saveBeneficio() {
      if (this.$refs.formAddBeneficio.validate()) {
        this.loadingSave = true;
        let opc = this.beneficio === null ? 2 : 3;
        const params = {
          Opcion: opc,
          token: localStorage.getItem("token"),
          descripcion: this.descripcion,
          beneficio: this.namebeneficio,
          activo: this.switch1,
          idBeneficio: this.beneficio != null ? this.beneficio.id : 0,
        };
        axios
          .post(
            `${this.$store.getters.getHost}/Compensaciones/ws-extras-beneficios-flexibles.php`,
            params
          )
          .then((response) => {
            if (response.data.status == "OK") {
              this.$refs.formAddBeneficio.reset();
              this.$refs.formAddBeneficio.resetValidation();
              this.muestraSnackBar(
                "teal darken-1",
                response.data.message
              );
              this.$emit('ocultaAgregaBeneficio', 1)
            } else {
              this.muestraSnackBar(
                "pink darken-3",
                response.data.message
              );
            }
          })
          .catch((error) => {
            this.muestraSnackBar("pink darken-3", error);
          })
          .finally(() => {
            this.loadingSave = false;
          });
      } else {
        console.log('Formulario inválido. Corrija los campos resaltados.');
      }
    },

  },
};
</script>