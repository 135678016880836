<template>
  <v-dialog
    v-model="openNuevoConocimiento"
    class="rounded-lg"
    max-width="450"
    persistent
  >
    <v-card class="rounded-lg">
      <div class="text-right">
        <v-btn class="mt-2 mr-2" fab x-small color="pink" dark @click="$emit('cierraVentana', '')"
          ><v-icon small>mdi-close</v-icon></v-btn
        >
      </div>
      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-text-field
            dense
            v-model="nuevoConocimiento"
            label="Nuevo conocimiento."
            placeholder="Ingresa un nuevo conocimiento"
            required
            :counter="200"
            :rules="nameRules"
          ></v-text-field>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn depressed color="success" small rounded @click="validate">
          aceptar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "update-apego-perfil",
  components: {},
  props: ["openNuevoConocimiento"],
  data() {
    return {
      valid: true,
      nuevoConocimiento: "",
      nameRules: [
        (v) => !!v || "El campo es requerido.",
        (v) =>
          (v && v.length <= 200) || "Name must be less than 200 characters",
      ],
    };
  },
  mounted() {
  },
  methods: {
    validate() {
      if (this.$refs.form.validate()) {
        this.$emit("cierraVentana", this.nuevoConocimiento);
      }
    },
  },
};
</script>
