<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        xl="7"
        lg="7"
        md="7"
        sm="7"
        xs="12"
        v-if="$store.getters.getUser['compania'] == '00100'"
      >
        <div class="rounded-lg">
          <v-card-title
            class="text-subtitle-2 font-italic primary white--text d-flex justify-center"
          >
            Información colaborador
          </v-card-title>
          <v-card>
            <v-card-text>
              <v-row>
                <v-col
                  class="d-flex align-center"
                  align="left"
                  xl="4"
                  lg="4"
                  md="4"
                  sm="12"
                  xs="12"
                >
                  <v-hover v-slot="{ hover }">
                    <v-avatar size="150" rounded>
                      <v-img
                        margin="auto"
                        class="rounded-circle"
                        :src="fotoPerfil"
                      >
                        <v-expand-transition bottom>
                          <div
                            v-if="hover"
                            class="fondo-imagen d-flex transition-fast-in-fast-out white--text"
                            style="
                              display: flex;
                              justify-content: center;
                              height: 100%;
                              width: 100%;
                            "
                          >
                            <v-chip
                              class="fondo-chip"
                              style="margin-top: 45%"
                              @click="abrirModal = true"
                            >
                              <v-icon>mdi-camera</v-icon> Editar foto</v-chip
                            >
                          </div>
                        </v-expand-transition>
                      </v-img>
                    </v-avatar>
                  </v-hover>
                </v-col>
                <v-col
                  class="d-flex align-center black--text"
                  align="left"
                  xl="8"
                  lg="8"
                  md="8"
                  sm="12"
                  xs="12"
                >
                  <v-card-text class="pt-0">
                    <div align="center">
                      <img
                        alt=""
                        :src="require('@/assets/img/grupo-urrea-logo-bn.png')"
                        aling="absmiddle"
                        width="200px"
                      />
                    </div>
                    <b>EMPLID</b> {{ colaborador.EMPLID }} <br />
                    <b>Nombre</b> {{ colaborador.NAME }} <br />
                    <b>Puesto</b> {{ colaborador.DESCR }} <br />
                    <b>Departamento</b> {{ colaborador.DESCR1 }}
                  </v-card-text>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <v-card-text class="pt-6" style="position: relative">
            <v-btn
              absolute
              color="primary"
              class="white--text"
              fab
              large
              right
              top
            >
              <v-icon>mdi-account</v-icon>
            </v-btn>
          </v-card-text>
        </div>
      </v-col>
      <v-col cols="12" xl="12" lg="12" md="12" sm="12" xs="12" v-else>
        <v-card>
          <v-card-title style="display: flex; justify-content: center">
            <v-hover v-slot="{ hover }">
              <v-avatar size="150" rounded>
                <v-img margin="auto" class="rounded-circle" :src="fotoPerfil">
                  <v-expand-transition bottom>
                    <div
                      v-if="hover"
                      class="fondo-imagen d-flex transition-fast-in-fast-out white--text"
                      style="
                        display: flex;
                        justify-content: center;
                        height: 100%;
                        width: 100%;
                      "
                    >
                      <v-chip
                        class="fondo-chip"
                        style="margin-top: 45%"
                        @click="abrirModal = true"
                      >
                        <v-icon>mdi-camera</v-icon> Editar foto</v-chip
                      >
                    </div>
                  </v-expand-transition>
                </v-img>
              </v-avatar>
            </v-hover>
          </v-card-title>
          <v-card-text>
            <center>
              <h2>{{ colaborador["NAME"] }}</h2>
              <h3>{{ colaborador["EMPLID"] }}</h3>
            </center>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        xl="5"
        lg="5"
        md="5"
        sm="5"
        xs="12"
        v-if="$store.getters.getUser['compania'] == '00100'"
      >
        <div class="rounded-lg">
          <v-card-title
            class="text-subtitle-2 font-italic primary white--text d-flex justify-center"
          >
            Generalista Asignado
          </v-card-title>
          <v-card>
            <v-card-text>
              <v-row>
                <v-col
                  class="d-flex align-center"
                  align="left"
                  xl="4"
                  lg="4"
                  md="4"
                  sm="12"
                  xs="12"
                >
                  <v-avatar size="150" rounded>
                    <v-img
                      margin="auto"
                      class="rounded-circle"
                      :src="colaborador['linkCompletoGeneralista']"
                    >
                    </v-img>
                  </v-avatar>
                </v-col>
                <v-col
                  class="d-flex align-center black--text"
                  align="left"
                  xl="8"
                  lg="8"
                  md="8"
                  sm="12"
                  xs="12"
                >
                  <v-card-text>
                    <b>Nombre</b> {{ colaborador.nombreGeneralista }} <br />
                    <b>Telefono</b> {{ colaborador.telefonoGeneralista }} <br />
                    <b>Correo</b> {{ colaborador.correoGeneralista }}
                  </v-card-text>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <v-card-text class="pt-6" style="position: relative">
            <v-btn
              absolute
              color="primary"
              class="white--text"
              fab
              large
              right
              top
            >
              <v-icon>mdi-account-hard-hat</v-icon>
            </v-btn>
          </v-card-text>
        </div>
      </v-col>
    </v-row>
    <v-divider style="margin-top: 20px; margin-bottom: 20px"></v-divider>
    <v-row v-if="colaborador['EMP_STATUS'] == 'Activo'">
      <v-col
        class="d-flex justify-space-around"
        xl="12"
        lg="12"
        md="12"
        xs="12"
        sm="12"
        v-if="!ubicacion"
      >
        <v-btn
          rounded
          color="error"
          dark
          v-if="esAdminTI > 0 || esAdminRL > 0"
          @click="openDialogBajas()"
          >Solicitar baja
        </v-btn>

        <v-btn
          v-if="esAdminTI > 0 || perfil > 0"
          rounded
          color="teal darken-2"
          dark
          @click="imprimirGafete()"
          >Imprimir Gafete</v-btn
        >
      </v-col>

      <v-col cols="12" xl="6" lg="6" md="6" sm="6" xs="12">
        <v-card class="mx-auto" color="grey lighten-4" max-width="600">
          <div>
            <v-card-title
              class="text-subtitle-2 font-italic primary white--text d-flex justify-center"
            >
              Mi información en urrea
              <v-btn
                absolute
                color="cyan darken-2"
                class="white--text"
                fab
                small
                right
                top
                @click="showInfoUrrea = !showInfoUrrea"
              >
                <v-icon>mdi-alpha-u</v-icon>
              </v-btn>
            </v-card-title>
            <v-expand-transition>
              <v-card-text v-show="showInfoUrrea" align="left">
                <b>Estatus:</b> {{ colaborador.EMP_STATUS }} <br />
                <b>Ingreso</b> {{ colaborador.HIRE_DT }} <br />
                <b>Puesto</b> {{ colaborador.DESCR }} <br />
                <b>Departamento</b> {{ colaborador.DESCR1 }} <br />
                <b>Antiguedad</b> {{ colaborador.antiguedad }} Año(s)<br />
                <div
                  v-if="colaborador['NAME_RESP'] == 'Responsable no asignado'"
                >
                  <v-btn x-small rounded elevation="1" column color="red" dark>
                    {{ colaborador["NAME_RESP"] }}
                  </v-btn>
                </div>
                <div v-else>
                  <b> Responsable: </b>{{ colaborador["NAME_RESP"] }}
                </div>
                <div v-if="colaborador['turno'] == 'Turno no asignado'">
                  <b>Horarios:</b>
                  <v-btn x-small rounded elevation="1" column color="red" dark>
                    {{ colaborador["turno"] }}
                  </v-btn>
                </div>
                <div v-else><b>Horarios: </b>{{ colaborador["turno"] }}</div>
                <b>JOBCODE</b> {{ colaborador.JOBCODE }} <br />
                <b>Correo</b> {{ colaborador.Correo }} <br />
                <b>Proceso</b> {{ colaborador.UR_GP_PAYGROUP }}
              </v-card-text>
            </v-expand-transition>
            <v-card-text
              v-show="!showInfoUrrea"
              class="pt-6 overline d-flex justify-center"
              style="position: relative"
            >
              Click en
              <v-btn color="cyan darken-2" class="white--text" fab x-small>
                <v-icon>mdi-alpha-u</v-icon>
              </v-btn>
              para mostrar información de urrea
            </v-card-text>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" xl="6" lg="6" md="6" sm="6" xs="12">
        <v-card class="mx-auto" color="grey lighten-4" max-width="600">
          <div>
            <v-card-title
              class="text-subtitle-2 font-italic primary white--text d-flex justify-center"
            >
              Mi información personal
              <v-btn
                absolute
                color="cyan darken-2"
                class="white--text"
                fab
                small
                right
                top
                @click="showInfoPersonal = !showInfoPersonal"
              >
                <v-icon>mdi-alpha-i</v-icon>
              </v-btn>
            </v-card-title>
            <v-expand-transition>
              <v-card-text
                v-show="showInfoPersonal"
                align="left"
                v-if="$store.getters.getUser['compania'] == '00100'"
              >
                <b>IMSS:</b> {{ colaborador.imss }} <br />
                <b>CURP</b> {{ colaborador.curp }} <br />
                <b>RFC</b> {{ colaborador.rfc }} <br />
                <b>Dirección</b> {{ colaborador.direccion }} <br />
                <b>Colonia</b> {{ colaborador.colonia }} <br />
                <b>Municipio</b> {{ colaborador.municipio }} <br />
                <b>Municipio</b> {{ colaborador.postal }} <br />
                <b>Proceso</b> {{ colaborador.estado }}
              </v-card-text>
              <v-card-text
                v-show="showInfoPersonal"
                align="left"
                v-else-if="$store.getters.getUser['compania'] == '01000'"
              >
                <b>Cedula de ciudadania</b> {{ colaborador.rfc }} <br />
                <b>Dirección</b> {{ colaborador.direccion }} <br />
                <b>Colonia</b> {{ colaborador.colonia }} <br />
                <b>Municipio</b> {{ colaborador.municipio }} <br />
                <b>Municipio</b> {{ colaborador.postal }} <br />
                <b>Proceso</b> {{ colaborador.estado }}
              </v-card-text>
            </v-expand-transition>
            <v-card-text
              v-show="!showInfoPersonal"
              class="pt-6 overline d-flex justify-center"
              style="position: relative"
            >
              Click en
              <v-btn color="cyan darken-2" class="white--text" fab x-small>
                <v-icon>mdi-alpha-i</v-icon>
              </v-btn>
              para mostrar su información personal
            </v-card-text>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" xl="6" lg="6" md="6" sm="6" xs="12">
        <v-card class="mx-auto" color="grey lighten-4" max-width="600">
          <div>
            <v-card-title
              class="text-subtitle-2 font-italic primary white--text d-flex justify-center"
            >
              Mi Puesto de trabajo
              <v-btn
                absolute
                color="cyan darken-2"
                class="white--text"
                fab
                small
                right
                top
                @click="showInfoPuesto = !showInfoPuesto"
              >
                <v-icon>mdi-alpha-p</v-icon>
              </v-btn>
            </v-card-title>
            <v-expand-transition>
              <v-card-text
                v-show="showInfoPuesto"
                align="left"
                v-if="$store.getters.getUser['compania'] == '00100'"
              >
                <p
                  v-if="muestraDesc == false"
                  class="d-inline-block text-truncate text-justify"
                  style="max-width: 350px; cursor: pointer"
                  @click="mostrarMas"
                >
                  <b>Descripción:</b> {{ colaborador.descripcionPuesto }}
                </p>
                <p
                  v-else
                  @click="mostrarMas"
                  style="cursor: pointer"
                  class="text-justify"
                >
                  <b>Descripción:</b> {{ colaborador["descripcionPuesto"] }}
                </p>
                <br />
                <b>Escolaridad</b> {{ colaborador.puesto }} <br />
                <b>Conocimientos</b>
                <div
                  v-for="(item, index) in colaborador['conocimiento']"
                  :key="index"
                >
                  {{ index + 1 }}.- {{ item }}
                </div>
                <br />
              </v-card-text>
            </v-expand-transition>
            <v-card-text
              v-show="!showInfoPuesto"
              class="pt-6 overline d-flex justify-center"
              style="position: relative"
            >
              Click en
              <v-btn color="cyan darken-2" class="white--text" fab x-small>
                <v-icon>mdi-alpha-p</v-icon>
              </v-btn>
              para mostrar mi puesto de trabajo
            </v-card-text>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" xl="6" lg="6" md="6" sm="6" xs="12">
        <v-card class="mx-auto" color="grey lighten-4" max-width="600">
          <div>
            <v-card-title
              class="text-subtitle-2 font-italic primary white--text d-flex justify-center"
            >
              Objetivos y ponderaciones
              <v-btn
                absolute
                color="cyan darken-2"
                class="white--text"
                fab
                small
                right
                top
                @click="showInfoObjetivos = !showInfoObjetivos"
              >
                <v-icon>mdi-alpha-o</v-icon>
              </v-btn>
            </v-card-title>
            <v-expand-transition>
              <v-card-text
                v-show="showInfoObjetivos"
                align="left"
                v-if="$store.getters.getUser['compania'] == '00100'"
              >
                <b>Objetivos</b>
                <div
                  v-for="(item, index) in colaborador['objetivos']"
                  :key="index"
                >
                  {{ item }}
                </div>
                <br />
              </v-card-text>
            </v-expand-transition>
            <v-card-text
              v-show="!showInfoObjetivos"
              class="pt-6 overline d-flex justify-center"
              style="position: relative"
            >
              Click en
              <v-btn color="cyan darken-2" class="white--text" fab x-small>
                <v-icon>mdi-alpha-o</v-icon>
              </v-btn>
              para mostrar sus objetivos y ponderaciones
            </v-card-text>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col xl="12" lg="12" md="12" xs="12" sm="12">
        <v-card class="mx-auto">
          <v-card-text>
            <v-row no-gutters>
              <v-col cols="4">
                <v-subheader>Estatus:</v-subheader>
              </v-col>
              <v-col cols="8">
                <v-subheader>{{ colaborador["EMP_STATUS"] }}</v-subheader>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="4">
                <v-subheader>Puesto:</v-subheader>
              </v-col>
              <v-col cols="8">
                <v-subheader>{{ colaborador["DESCR"] }}</v-subheader>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="4">
                <v-subheader>Departamento:</v-subheader>
              </v-col>
              <v-col cols="8">
                <v-subheader>{{ colaborador["DESCR1"] }}</v-subheader>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <CargarFotoPerfil
      v-if="abrirModal"
      :openDialogSubirImg="abrirModal"
      @cerrarModal="cierraModalPerfil"
      :folio="colaborador['EMPLID']"
      :ruta="fotoPerfil"
    >
    </CargarFotoPerfil>
    <gafeteColaborador
      v-if="dialogImprimirGafete"
      :dialog="dialogImprimirGafete"
      :colaborador="colaborador"
      @cierraImpresionGafete="dialogImprimirGafete = false"
    />
    <form-bajas-de-empleados
      v-if="dialogBajas"
      :dialog="dialogBajas"
      :colaborador="colaborador"
      @cierraDialogBajas="cierraDialog"
    ></form-bajas-de-empleados>
  </v-container>
</template>



<script>
import axios from "axios";
import CargarFotoPerfil from "../components/CargaFotoPerfil.vue";
import gafeteColaborador from "../views/generated/RelacionesLaborales/BajasDeEmpleados/EmpleadosActivos/components/GafeteColaborador.vue";
import FormBajasDeEmpleados from "../views/generated/RelacionesLaborales/BajasDeEmpleados/EmpleadosActivos/components/FormBajasDeEmpleados.vue";
export default {
  name: "persona-perfil",
  components: { CargarFotoPerfil, gafeteColaborador, FormBajasDeEmpleados },
  props: ["colaborador", "perfil", "esAdminTI", "esAdminRL"],
  data: () => ({
    muestraDesc: false,
    url: "",
    ubicacion: false,
    hover: false,
    abrirModal: false,
    dialogImprimirGafete: false,
    dialogBajas: false,
    showInfoUrrea: false,
    showInfoPersonal: false,
    showInfoPuesto: false,
    showInfoObjetivos: false,
    fotoPerfil: "",
    folio: "",
  }),
  created() {},
  mounted() {
    this.url = window.location.hash;
    if (this.url.includes("MiPersona")) {
      this.ubicacion = true;
      this.getPerfilColaborador(this.colaborador["EMPLID"]);
    }
  },

  methods: {
    getPerfilColaborador(EMPLID) {
      let params = {
        Opcion: 19,
        NPcliente1: EMPLID,
        token: localStorage.getItem("token"),
      };
      axios
        .post(`${this.$store.getters.getHost}/General/ws-general.php`, params)
        .then((response) => {
          if (response.data.status == "OK") {
            this.fotoPerfil =
              // this.$store.getters.getHost +
              "https://apidh.urrea.com/ArchivosCargadosEnPortal2/FotosPerfilUsuarios/" +
              response.data.data["foto"];
            this.folio = response.data.data["EMPLID"];
            this.loading = false;
          }
        });
    },

    cierraModalPerfil(response) {
      this.abrirModal = false;
      if (response == "OK") {
        this.fotoPerfil = "";
        this.getPerfilColaborador();
      }
    },
    mostrarMas() {
      if (this.muestraDesc == true) {
        this.muestraDesc = false;
      } else {
        this.muestraDesc = true;
      }
    },

    cierraDialog(estatus) {
      this.dialogBajas = false;
      if (estatus.estatus == "reload") {
        window.location.reload();
      }
    },

    imprimirGafete() {
      this.dialogImprimirGafete = true;
    },

    openDialogBajas() {
      this.dialogBajas = true;
    },
  },
};
</script>
<style lang="sass" scoped>
.fondo-imagen
  background: rgb(232,239,237)
  background: radial-gradient(circle, rgba(232,239,237,1) 0%, rgba(113,142,187,0.7203081916360294) 100%)
  .fondo-chip
    background: rgb(240,244,246)
    background: radial-gradient(circle, rgba(240,244,246,1) 4%, rgba(255,255,255,1) 100%)
</style>