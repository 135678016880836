<template>
  <div>
    <v-dialog v-model="dialog" max-width="800px" persistent scrollable content-class="fix-border-dialog">
      <v-card class="rounded-xl" :loading="cargandoInformacion">
        <v-card-title class="primary white--text subtitle-1">
          Registro de Vacaciones
          <v-spacer></v-spacer>
          <v-icon dark @click="$emit('cierra', 0)">mdi-close</v-icon>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <div v-if="workingOn">
            <div class="text-center">
              <p
                class="font-weight-bold pink--text text--darken-3"
                style="font-size: 1.15rem;"
              >
                Solicitud de vacaciones no disponible. <br />
                Estamos trabajando en el recálculo de vacaciones por corte anual. <br />
                Sentimos las molestias que esto pueda ocasionarte.
              </p>
            </div>
          </div>
          <v-row v-if="!loadingPeriodos" dense>
            <v-col
              cols="12"
              class="pa-0"
              style="display: flex; justify-content:center;"
            >
              <v-text-field
                v-model="dateRangeText"
                placeholder="Selecciona las fechas de inicio y fin de tus vacaciones."
                style="max-width: 450px; "
                class="rounded-lg mt-5"
                label="Selecciona las fechas de inicio y fin de tus vacaciones."
                prepend-inner-icon="mdi-calendar"
                readonly
                dense
                filled
                @click="dialogFechas = true"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <v-skeleton-loader
                v-if="loadingPeriodos"
                class="mx-auto rounded-xl"
                max-width="100%"
                type="image"
              ></v-skeleton-loader>
              <v-card
                class="rounded-xl elevation-0 pa-5"
                color="#f5f5f5"
                whidth="100%"
                min-height="200px"
                v-else
              >
              <div class="text-center mb-1">
                <label class="overline">Días otorgados por año: <strong> {{ vacaciones.diasCorrespondientes }}</strong></label>
                <br>
                <label class="caption">Antigüedad: <strong> {{ vacaciones.antiguedad.toFixed(1) }}</strong> año(s)</label>
              </div>
              <div class="mb-3 pb-3" style="display: flex;justify-content: center;">
                <v-btn class="rounded-lg" x-small color="primary" @click="muestraLoadingDialog = true; verHistorialDeEmpleado();">
                  <v-icon left small>mdi-history</v-icon>
                  historial
                </v-btn>
              </div>
                <v-row>
                  <v-col
                    cols="12"
                    xs="12"
                    md="4"
                    lg="4"
                    style="display:flex; justify-content:space-around;"
                    class="pt-0 mt-0"
                  >
                    <div
                      class="neumorphism"
                      color="#f5f5f5"
                      style="width:85%; min-height:50px;"
                    >
                      <p
                        class="text-center mb-0 text-uppercase mt-2 font-weight-bold primary--text"
                      >
                        Días otorgados
                      </p>
                      <label class="center-item-in-div text-h6 font-weight-bold blue-grey--text text--darken-2">
                        {{ (vacaciones.diasOtorgadosViejaLey +  vacaciones.diasOtorgadosNuevaLey).toFixed(2)}} 
                      </label>
                    </div>
                  </v-col>
                  <v-col
                    cols="12"
                    xs="12"
                    md="4"
                    lg="4"
                    style="display:flex; justify-content:space-around;"
                    class="pt-0 mt-0"
                  >
                    <div
                      class="neumorphism"
                      color="#f5f5f5"
                      style="width:85%; min-height:50px;"
                    >
                      <p
                      class="text-center mb-0 text-uppercase mt-2 font-weight-bold primary--text"
                      >
                        Días solicitados
                      </p>
                      <label
                        class="center-item-in-div text-h6 font-weight-bold blue-grey--text text--darken-2"
                      >
                        {{ (vacaciones.diasDevengadosViejaLey +  vacaciones.diasDevengadosNuevaLey).toFixed(2)}} 
                      </label>
                    </div>
                  </v-col>
                  <v-col
                    cols="12"
                    xs="12"
                    md="4"
                    lg="4"
                    style="display:flex; justify-content:space-around;"
                    class="pt-0 mt-0"
                  >
                    <div
                      class="neumorphism"
                      color="#f5f5f5"
                      style="width:85%; min-height:50px;"
                    >
                      <p class="text-center mb-0 text-uppercase mt-2 font-weight-bold primary--text">
                        Días disponibles
                      </p>
                      <label class="center-item-in-div text-h6 font-weight-bold blue-grey--text text--darken-2">
                        {{ vacaciones.diasDisponibles.toFixed(2) }} 
                      </label>
                    </div>
                  </v-col>
                </v-row>
                <div class="center-item-in-div pt-5">
                  <div
                      class="neumorphism my-2"
                      color="#f5f5f5"
                      style="max-width:90%; width: 280px; min-height:50px;"
                    >
                    <p class="text-center mb-0 text-uppercase mt-2 font-weight-bold primary--text">
                        Días para solicitar
                      </p>

                      <label class="center-item-in-div text-h4 font-weight-bold teal--text text--lighten-1">
                        {{ vacaciones.diasParaDevengar}} 
                      </label>
                  </div>
                </div>
              </v-card>
            </v-col>
          </v-row>

          <!-- <v-row class="mt-5" v-show="!loadingPeriodos" dense>
            <v-col cols="12" md="4" sm="6" xs="12" class="text-center pa-0">
              <v-btn class="button" @click="verHistorialDeEmpleado()" large>
                Ver mi historial <br />
                al 31/DIC/2022
              </v-btn>
            </v-col>
            <v-col cols="12" md="4" sm="6" xs="12" class="text-center pa-0">
              <v-btn
                class="button"
                @click="verHistorialDeEmpleadoNuevaLey()"
                large
              >
                Historial
                {{ year }}
              </v-btn>
            </v-col>
            <v-col cols="12" md="4" sm="6" xs="12" class="text-center pa-0">
              <v-btn
                class="button"
                @click.stop="dialogCalculoVacacionesExp = true"
                large
              >
                ¿Cómo se cálculan <br />
                mis vacaciones?
              </v-btn>
            </v-col>
          </v-row> -->
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="pa-0">
          <v-btn
            color="teal darken-1"
            text
            block
            x-large
            class="rounded-xl rounded-t-0 font-weight-bold"
            depressed
            :disabled="!workingOn && puedeSolicitar ? false : true"
            :dark="!workingOn && puedeSolicitar ? true : false"
            :loading="cargandoGuardar"
            @click="validarInformacion()"
          >
          <v-icon left> mdi-content-save-all </v-icon>
            {{ textBotonValidar }} 
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-sheet id="muestra-incidencias-cargadas">
      <muestra-incidencias-cargadas
        v-if="muestraDetalles"
        :muestraDetalles="muestraDetalles"
        :datosTabla="respuestaApiPermisos.datos"
        :alertErrorDosDias="alertErrorDosDias"
        @cierraTabla="muestraDetalles = false"
      />
    </v-sheet>

    <v-sheet id="snack-bar-error-incidencias">
      <snack-bar-error-incidencias
        v-if="muestraBarra"
        :muestraBarra="muestraBarra"
        :texto="textoBarra"
        :color="colorBarra"
        @cierraBarra="muestraBarra = false"
      />
    </v-sheet>

    <v-sheet id="muestra-calendario-dias-seleccionados">
      <muestra-calendario-dias-seleccionados
        :muestraCalendario="muestraCalendario"
        :fechas="arrayDias"
        @cierraCalendario="muestraCalendario = false"
      />
    </v-sheet>

    <loadingBarVue :dialog="muestraLoadingDialog" :text="textDialogLoading" />

    <DataTableGeneric
      :verDataTable="verHistorial"
      :items="historial"
      :headers="headersHistorial"
      :titulo="tituloTablaHistorial"
      @cierraTabla="verHistorial = false"
    />

    <v-dialog v-model="dialogFechas" max-width="350px">
      <v-card class="rounded-lg">
        <v-card-title class="primary white--text subtitle-2 py-1">
          <v-row dense>
            <v-col dense cols="6">
              <p class="mb-0 font-weight-bold blue--text text--lighten-4">Inicio</p>
              <p v-if="dates.length >= 1" class="mb-0" style="font-size:1.05rem">{{ fixFormatDate(dates[0]) }}</p>
            </v-col>
            <v-divider></v-divider>
            <v-col dense cols="6">
              <p class="mb-0 font-weight-bold blue--text text--lighten-4">Fin</p>
              <p v-if="dates.length == 2" class="mb-0" style="font-size:1.05rem">{{ fixFormatDate(dates[1]) }}</p>
            </v-col>
          </v-row>
        </v-card-title>
        <div class="text-center">
        <v-date-picker
          v-model="dates"
          max-width="350px"
          v-on:change="cierraDialog()"
          :min="fechaBloqueo"
          no-title
          range
          elevation="0"
          locale="es-mx"
        >
          <template #title>
            <div class="v-picker__title">
              <div class="v-date-picker-header__value">Hola</div>
            </div>
          </template>
        </v-date-picker>
      </div>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogCalculoVacacionesExp" max-width="600">
      <v-card class="rounded-xl fondo-card-muestra-calculo">
        <v-card-title></v-card-title>
        <v-card-text>
          <p
            class="text-justify grey--text text--darken-3"
            style="font-weight:500; font-size: 1.1rem;"
          >
            Las Vacaciones ya generadas
            <strong style="color:black">
              se pagan de conformidad a lo estipulado en la Ley Federal del
              Trabajo al 31 de diciembre 2022</strong
            >
            y las vacaciones que se generen
            <strong style="color:black"
              >a partir del 01 de enero del 2023, serán de conformidad a lo
              establecido a los artículos vigentes en la Ley Federal del
              Trabajo,</strong
            >
            en su parte proporcional al cumplir el año laborado.
            <br />
            <br />
            <strong>Ejemplo:</strong> Un colaborador con fecha de ingreso del 1
            de julio de 2022, cuando cumpla 1 año el 1 de julio de 2023, tendrá
            derecho a 9 días de vacaciones por el primer año (3 días del 2022
            reforma anterior y 6 días reforma vigente)
          </p>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import SnackBarErrorIncidencias from "./componentsExtra/SnackBarErrorIncidencias.vue";
import MuestraIncidenciasCargadas from "./componentsExtra/MuestraIncidenciasCargadas.vue";
import MuestraCalendarioDiasSeleccionados from "./componentsExtra/MuestraCalendarioDiasSeleccionados.vue";
import {
  deleteToken,
  formatDate,
} from "../../../../../store/Funciones/funciones";
import loadingBarVue from "../../../DesarrolloOrganizacional/components/loadingBar.vue";
import DataTableGeneric from "../../../../../../src/components/componentsExtra/DataTableGeneric.vue";

export default {
  name: "incidencia-vacaciones",
  data: () => ({
    cargandoGuardar: false,
    cargandoInformacion: false,
    muestraMensajeError: false,
    workingOn: false,
    muestraLoadingDialog: false,
    verHistorial: false,
    dialogCalculoVacacionesExp: false,
    textDialogLoading: "",
    respuestaApiPermisos: [],
    respuestaApiCountDias: null,
    minDate: new Date(),
    periodos: [],
    dates: [],
    dialogFechas: false,
    fechaInicio: "",
    textBotonValidar: "guardar",
    textoDiasSeleccionados: "",
    modalFechaInicio: false,
    modalFechaFin: false,
    vacacionesCierre2022: [],
    vacacionesDisp: [],
    vacaciones: {},
    textoBotonAlert: "",
    alert: false,
    colorAlert: "pink darken-3",
    iconAlert: "mdi-alert-circle",
    muestraBarra: false,
    alertErrorDosDias: false,
    muestraCalendario: false,
    puedeSolicitar: false,
    loadingPeriodos: true,
    fechaBloqueo: null,
    colorBarra: "red accent-2",
    textoBarra: "",
    muestraDetalles: false,
    arrayDias: [],
    historial: [],
    headersHistorial: [],
    tituloTablaHistorial: "",
    columsDivNewVac: 4,
    fechaDeIngreso: "",
    antiguedad: 0.0,
    totalDisponibles: 0.0,
    diasDispParaDevengar: "",
    muestraRestantes2022: true,
    diasCorrespondientesAntes: 0,
    diasCorrespondientesAhora: 0,
    year: 0,
  }),
  components: {
    SnackBarErrorIncidencias,
    MuestraIncidenciasCargadas,
    MuestraCalendarioDiasSeleccionados,
    loadingBarVue,
    DataTableGeneric,
  },
  computed: {
    dateRangeText() {
      let inicio = "";
      let fin = "";
      if (this.dates[0] != null) {
        inicio = formatDate(this.dates[0]);
      }
      if (this.dates[1] != null) {
        fin = formatDate(this.dates[1]);
      }

      if (this.dates.length == 0) {
        return "";
      }
      return "Del " + inicio + " al " + fin; //this.dates.join(' al ')
    },
  },
  async mounted() {
    if (!this.workingOn) {
      this.fechaInicioS = this.setFechaManana();

      this.vacaciones = await this.getVacacionesDisponibles();

      if(this.vacaciones.estatus){
        if(this.vacaciones.estatus === 'A'){
          this.puedeSolicitar = true;
        } else {
          this.vacaciones.diasParaDevengar = 0
        }
      }
    }
  },
  props: ["dialog", "esAdmin", "empleado"],
  methods: {
    setFechaManana() {
      const today = new Date();
      let tomorrow = new Date(today);
      tomorrow.setDate(tomorrow.getDate() + 1);

      if (this.esAdmin != true) {
        this.fechaBloqueo = tomorrow.toISOString().substring(0, 10);
      }

      return formatDate(tomorrow.toISOString().substring(0, 10));
    },
    funcionamientoBotonAlert() {
      if (this.textoBotonAlert == "Ver más") {
        this.alert = false;
        this.muestraDetalles = true;
      } else if (this.textoBotonAlert == "Ver detalles") {
        this.alert = false;

        this.respuestaApiPermisos.datos.forEach((elemento) => {
          this.arrayDias.push(elemento);
        });

        this.muestraCalendarioFechas();
      } else {
        this.alert = false;
      }
    },
    cierraDialog() {
      if (this.dates.length == 2) {
        if (this.dates[0] > this.dates[1]) {
          this.dates.pop();
          this.colorBarra = "error";
          this.textoBarra =
            "La fecha final no puede ser menor a la inicial. :c";
          this.muestraBarra = true;
        } else {
          this.dialogFechas = false;
        }
      }
    },
    getVacacionesDisponibles() {
      let today = new Date();
      this.year = today.getFullYear();
      this.vacacionesCierre2022.length = 0;
      this.vacacionesDisp.length = 0;

      let params = {
        Opcion: 9,
        token: localStorage.getItem("token"),
        esAdmin: this.esAdmin,
        empleado: this.empleado,
      };
      return new Promise ((res, rej) => {
        axios
        .post(
          `${this.$store.getters.getHost}/Incidencias/ws-incidencias-vacaciones.php`,
          params
        )
        .then((response) => {
          if (response.data.status != "EXPRESSION") {
            res(response.data.periodos)
          }
        })
        .catch(function(e) {
          rej(e)
        })
        .finally(() => {
          this.loadingPeriodos = false
        })
      })
    },
    validarInformacion() {
      this.cargandoGuardar = true;
      if (this.dates.length < 2) {
        this.textoBarra = "Selecciona las fechas de inicio y fin.";
        this.muestraBarra = true;
        this.cargandoGuardar = false;
      } else {
        var params = {
          Opcion: 2,
          token: localStorage.getItem("token"),
          fechaInicio: this.dates[0],
          fechaFin: this.dates[1],
          diasDisfrutar: this.vacaciones.diasParaDevengar,
          esAdmin: this.esAdmin,
          empleado: this.empleado,
        };
        axios
          .post(
            `${this.$store.getters.getHost}/Incidencias/ws-incidencias-vacaciones.php`,
            params
          )
          .then(async (response) => {
            var data = response.data.vacaciones;
            this.respuestaApiPermisos = await data;
            this.validaRespuesta();
            this.loadingPeriodos = true;
            this.vacaciones = await this.getVacacionesDisponibles();
          })
          .catch(function(e) {
            let result = e.toString().includes("401");
            if (result) {
              window.location.reload();
            } else {
              alert(e);
            }
          })
          .finally(() => {
            this.cargandoGuardar = false;
          })
      }
    },
    validaRespuesta() {
      this.cargandoGuardar = false;
      if (
        this.respuestaApiPermisos["opc"] != 5 &&
        this.respuestaApiPermisos["opc"] != 2
      ) {
        this.textoBotonAlert = "Cerrar";
        this.colorAlert = "pink darken-3";
        this.alert = true;
      } else if (this.respuestaApiPermisos["opc"] == 2) {
        this.textoBotonAlert = "Ver más";
        this.colorAlert = "pink darken-3";
        this.alert = true;
      } else if (this.respuestaApiPermisos["opc"] == 5) {
        this.colorBarra = "success";
        this.textoBarra = this.respuestaApiPermisos.mensaje
        this.muestraBarra = true
        this.dates.length = 0

        this.fechaDeIngreso = "";
        this.antiguedad = 0;
        this.totalDisponibles = 0;
        this.diasDispParaDevengar = 0;
        this.diasCorrespondientesAntes = 0;
        this.diasCorrespondientesAhora = 0;

        this.loadingPeriodos = true;
        this.getVacacionesDisponibles();
        this.alert = true;
      }
    },
    verHistorialDeEmpleado() {
      this.historial.length = 0;
      this.textDialogLoading =
        "Cargando historial del " + this.fechaDeIngreso + " al 31/12/2022.";
      this.muestraLoadingDialog = true;

      let params = {
        Opcion: 10,
        token: localStorage.getItem("token"),
        esAdmin: this.esAdmin,
        empleado: this.empleado,
      };
      axios
        .post(
          `${this.$store.getters.getHost}/Incidencias/ws-incidencias-vacaciones.php`,
          params
        )
        .then((response) => {
          if (response.data.status != "EXPRESSION") {
            this.historial = response.data.historial;

            this.insertaTotales();
          } else {
            deleteToken();
          }
        });
    },

    verHistorialDeEmpleadoNuevaLey() {
      this.historial.length = 0;
      this.textDialogLoading = "Cargando historial del actual";
      this.muestraLoadingDialog = true;

      let params = {
        Opcion: 11,
        token: localStorage.getItem("token"),
        esAdmin: this.esAdmin,
        empleado: this.empleado,
      };
      axios
        .post(
          `${this.$store.getters.getHost}/Incidencias/ws-incidencias-vacaciones.php`,
          params
        )
        .then((response) => {
          if (response.data.status != "EXPRESSION") {
            this.historial = response.data.historial;

            this.openTable(2);
          } else {
            deleteToken();
          }
        });
    },

    insertaTotales() {
      let sumatoriaOtorgados = 0;
      let sumatoriaSolicitados = 0;

      this.historial.map((e) => {
        e.InicioAniversario = formatDate(e.InicioAniversario.date.substring(0, 10))
        e.finAniversario = formatDate(e.finAniversario.date.substring(0, 10))
        sumatoriaOtorgados = sumatoriaOtorgados + e.diasOtorgados;
        sumatoriaSolicitados = sumatoriaSolicitados + e.diasSolicitados;
      });

      this.historial.push({
        empleado: this.historial[0].empleado,
        InicioAniversario: "TOTAL",
        finAniversario: "",
        antiguedad: "",
        diasOtorgados: sumatoriaOtorgados.toFixed(2),
        diasSolicitados: sumatoriaSolicitados.toFixed(2),
      });

      this.openTable(1);
    },

    openTable(opcion) {
      this.headersHistorial.length = 0;
      switch (opcion) {
        case 1: {
          this.headersHistorial.push({
            text: "Inicio periodo",
            value: "InicioAniversario",
            class: "text-uppercase",
            align: "center",
          });
          this.headersHistorial.push({
            text: "Fin periodo",
            value: "finAniversario",
            class: "text-uppercase",
            align: "center",
          });
          this.headersHistorial.push({
            text: "Antigüedad",
            value: "antiguedad",
            class: "text-uppercase",
            align: "center",
          });
          this.headersHistorial.push({
            text: "días otorgados",
            value: "diasOtorgados",
            class: "text-uppercase",
            align: "center",
          });
          this.headersHistorial.push({
            text: "días solicitados",
            value: "diasSolicitados",
            class: "text-uppercase",
            align: "center",
          });
          break;
        }
        case 2: {
          this.headersHistorial.push({
            text: "Inicio periodo",
            value: "inicioPer",
            align: "center",
          });
          this.headersHistorial.push({
            text: "Fin periodo",
            value: "finPer",
            align: "center",
          });
          this.headersHistorial.push({
            text: "Años",
            value: "anios",
            align: "center",
          });
          this.headersHistorial.push({
            text: "Meses",
            value: "meses",
            align: "center",
          });
          this.headersHistorial.push({
            text: "días solicitados",
            value: "diasSolicitados",
            align: "center",
          });
          this.headersHistorial.push({
            text: "Proporcional",
            value: "proporcional",
            align: "center",
          });
          this.headersHistorial.push({
            text: "Correspondientes",
            value: "diasOtorgados",
            align: "center",
          });
          break;
        }
      }

      this.muestraLoadingDialog = false;
      this.tituloTablaHistorial = "Historial de vacaciones";
      this.verHistorial = true;
    },

    myRound(num, dec) {
      var exp = Math.pow(10, dec || 2); // 2 decimales por defecto
      return parseInt(num * exp, 10) / exp;
    },

    allowedDates(date) {
      // Deshabilita las fechas anteriores al 1 de marzo de 2023
      const today = new Date();
      today.setHours(0, 0, 0, 0); // Establece la hora a la medianoche
      return date < today;
    },

    fixFormatDate(date){
      return formatDate(date)
    }
  },
};
</script>

<style>
.neumorphism {
  border-radius: 12px !important;
  background: #f5f5f5;
  box-shadow: 8px 8px 16px #dddddd, -8px -8px 16px #ffffff;
}

.fondo-card-muestra-calculo {
  background: rgb(234, 230, 230);
  background: linear-gradient(
    47deg,
    rgba(234, 230, 230, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
}

.button {
  color: white !important;
  font-weight: bolder !important;
  text-transform: uppercase;
  padding: 7.5px 7.5px;
  border-radius: 8px;
  border: 0px solid #42a5f5;
  background: rgb(0, 120, 195) !important;
  box-shadow: 3px 3px #42a5f5;
  cursor: pointer;
}

.button:active {
  box-shadow: none;
  transform: translate(3px, 3px);
}
</style>
