<template>
  <v-card class="elevation-0 rounded-lg" color="deep-purple darken-3" dark>
    <v-tabs v-model="tabsCompensaciones" background-color="transparent" grow dense>
      <v-tab> Nuevos puestos </v-tab>
      <v-tab> Nuevos conocimientos </v-tab>
      <v-tab> Ofertas económicas pendientes </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tabsCompensaciones">
      <v-tab-item calss="pt-4"  style="background-color: transparent !important;">
        <NuevosPuestos />
      </v-tab-item>
      <v-tab-item calss="pt-4"  style="background-color: transparent !important;">
        <NuevosConocimientos />
      </v-tab-item>
      <v-tab-item calss="pt-4"  style="background-color: transparent !important;">
        <MuestraCandidatosPendientesDeOferta />
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import NuevosPuestos from './MuestraSolicitudesNuevosPuestos.vue'
import NuevosConocimientos from './MuestraNuevosConocimientosSolicitados.vue'
import MuestraCandidatosPendientesDeOferta from './MuestraCandidatosPendientesDeOferta.vue'
export default {
  name: "adm-compensaciones",
  components: {NuevosPuestos, NuevosConocimientos, MuestraCandidatosPendientesDeOferta},
  data() {
    return {
      tabsCompensaciones: null,
    }
  },
};
</script>
