<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <v-card>
      <v-card-title class="text-subtitle-2 primary white--text">
        {{ titleDialog }}
        <v-spacer></v-spacer>
        <v-icon
          small
          dark
          @click="$emit('cierraDialogSeleccion', { estatus: 'Cancelar' })"
          >mdi-close</v-icon
        >
      </v-card-title>
      <v-card-text class="mt-5">
        <v-row>
          <v-col cols="12" xl="12" lg="12" md="12" sm="12" xs="12">
            <v-autocomplete
              v-model="nombre"
              v-on:change="buscarNumeroEmpleado()"
              item-text="nombreMostrar"
              item-value="EMPLID"
              :multiple="esMultiple"
              :items="colaboradores"
              :loading="loadingColaboradores"
              :disabled="disabledColaboradores"
              placeholder="Buscar nombre de empleado"
              filled
              rounded
              solo
              chips
              deletable-chips
              dense
            ></v-autocomplete>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn v-if="!emplid" small disabled>
          <v-icon left>mdi-check-circle-outline</v-icon> Guardar
        </v-btn>
        <v-btn
          v-else-if="emplid && fuente != 'SeleccionaClientes'"
          class="rounded-lg elevation-0"
          color="teal darken-1"
          dark
          small
          :loading="loading"
          @click="getDataEmpleado"
        >
          <v-icon left>mdi-check-circle-outline</v-icon> Guardar
        </v-btn>
        <v-btn
          v-else-if="emplid && fuente == 'SeleccionaClientes'"
          class="rounded-lg elevation-0"
          color="teal darken-1"
          dark
          small
          :loading="loading"
          @click="regresarArreglo"
        >
          <v-icon left>mdi-check-circle-outline</v-icon> Hecho
        </v-btn>
        <v-btn
          v-if="emplid && fuente == 'SeleccionaClientes'"
          class="rounded-lg elevation-0"
          color="pink"
          dark
          small
          @click="limpiar"
        >
          <v-icon left>mdi-check-circle-outline</v-icon> Limpiar
        </v-btn>
      </v-card-actions>
    </v-card>
    <SnackBar
      v-if="muestraBarra"
      :muestraBarra="muestraBarra"
      :texto="textoBarra"
      :color="colorBarra"
      @cierraBarra="muestraBarra = false"
    />
  </v-dialog>
</template>



<script>
import axios from "axios";
import SnackBar from "../../../MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue";
export default {
  name: "selecciona-colaborador",
  components: { SnackBar },
  props: ["dialog", "idEvaluacion", "infoEncuesta", "fuente"],
  data: () => ({
    textoBarra: "",
    colorBarra: "",
    muestraBarra: false,

    nombre: "",
    emplid: "",
    loading: false,
    colaboradores: [],
    loadingColaboradores: true,
    disabledColaboradores: true,
    esMultiple: false,
  }),
  created() {},
  mounted() {
    this.getColaboradores();
    this.revisarFuente();
  },

  computed: {
    titleDialog() {
      return this.fuente === "SeleccionaClientes"
        ? "Seleccionar colaboradores"
        : "Cambiar colaborador";
    },
  },

  methods: {
    revisarFuente() {
      if (this.fuente == "SeleccionaClientes") {
        this.esMultiple = true;
      } else {
        this.esMultiple = false;
      }
    },

    getColaboradores() {
      let params = {
        Opcion: 11,
        token: localStorage.getItem("token"),
      };
      axios
        .post(`${this.$store.getters.getHost}/General/ws-general.php`, params)
        .then((response) => {
          var data = response.data.colaboradores;
          if (data) {
            this.colaboradores = data;
            this.loadingColaboradores = false;
            this.disabledColaboradores = false;
          }
        });
    },

    buscarNumeroEmpleado() {
      if (this.fuente != "SeleccionaClientes") {
        if (this.nombre) {
          this.emplid = this.nombre;
        } else {
          this.clean();
        }
      } else {
        if (this.nombre) {
          if (this.nombre.length < 4) {
            this.emplid = this.nombre;
            if (this.nombre.length == 3) {
              this.disabledColaboradores = true;
            }
          } else {
            this.textoBarra = "Como maximo tres colaboradores";
            this.colorBarra = "error";
            this.muestraBarra = true;
          }
        } else {
          this.limpiar();
        }
      }
    },

    getDataEmpleado() {
      this.loading = true;
      let paramsData = {
        Opcion: 13,
        token: localStorage.getItem("token"),
        emplid: this.emplid,
        idEvaluacion: this.idEvaluacion,
        idEncuesta: this.infoEncuesta["id"],
        infoEncuesta: this.infoEncuesta,
      };
      axios
        .post(
          `${this.$store.getters.getHost}/General/PersonalTemporal/ws-personal-temporal.php`,
          paramsData
        )
        .then((responseData) => {
          this.loading = false;
          if (responseData.data.status == "EXISTE") {
            this.textoBarra =
              "El colaborador seleccionado ya se encuentra registrado en las encuestas. Seleccione uno diferente";
            this.colorBarra = "error";
            this.muestraBarra = true;
          } else if (responseData.data.status == "OK") {
            this.$emit("cierraDialogSeleccion", { estatus: "OK" });
          } else if (responseData.data.status == "ERROR") {
            this.textoBarra = "Ha ocurrido un error";
            this.colorBarra = "error";
            this.muestraBarra = true;
          }
        });
    },

    regresarArreglo() {
      this.loading = true;
      let params = {
        Opcion: 20,
        token: localStorage.getItem("token"),
        pares: this.emplid,
      };
      axios
        .post(
          `${this.$store.getters.getHost}/General/PersonalTemporal/ws-personal-temporal.php`,
          params
        )
        .then((response) => {
          this.loading = false;
          if (response.data.status == "OK") {
            this.$emit("cierraDialogSeleccion", {
              estatus: "ParesSeleccionados",
              pares: response.data.pares,
              folios: response.data.folios,
            });
          } else if (response.data.status == "ERROR") {
            this.textoBarra = "Ha ocurrido un error";
            this.colorBarra = "error";
            this.muestraBarra = true;
          }
        });
    },

    clean() {
      this.colaborador = [];
      this.nombre = "";
      this.emplid = "";
    },

    limpiar() {
      this.nombre = "";
      this.emplid = "";
      this.disabledColaboradores = false;
    },
  },
};
</script>