import {
    formatDate
} from './../../../General/MiEquipo/ReporteAsistenciasResponsable/components/funciones';
import axios from 'axios'
import SnackBarErrorIncidencias from '../../../General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue'
import ReporteAsistenciasDataTable from '../../../../../components/componentsRelacionesLaborales/ReporteAsistenciasDataTable.vue'
import ReporteAsistencias from '../../../../../components/componentsRelacionesLaborales/ReporteAsistencias.vue'
import ReporteAsistenciasComite from '../../../../../components/componentsRelacionesLaborales/ReporteAsistenciasComite.vue'
import {
    deleteToken
} from '../../../../../store/Funciones/funciones';

export default {
    name: 'reporte-asistencias-admin',
    components: {
        SnackBarErrorIncidencias,
        ReporteAsistenciasDataTable,
        ReporteAsistencias,
        ReporteAsistenciasComite
    },
    props: [],
    data() {
        return {
            tipoBusqueda: null,
            dates: [],
            areas: [],
            departamentos: [],
            colaboradores: [],
            lideres: [],
            colaboradoresAsist: [],
            textoBarra: "",
            colorBarra: "",
            areaSelected: "",
            respSelected: "",
            deptIdSelected: "",
            emplSelected: "",
            muestraBarra: false,
            dialog: false,
            loadingFiltro: false,
            clicked: false,
            loadingResp: true,
            loadingAreas: true,
            loadingDeptos: true,
            loadingColabs: true,
            accesoRepComite: false,
            numResponsable: null,
            key: 0,

        }
    },
    computed: {
        dateRangeText() {
            let inicio = "";
            let fin = "";
            if (this.dates[0] != null) {
                inicio = formatDate(this.dates[0]);
            }
            if (this.dates[1] != null) {
                fin = formatDate(this.dates[1]);
            }

            if (this.dates.length == 0) {
                return "";
            }
            return "Del " + inicio + " al " + fin //this.dates.join(' al ')
        },
    },
    mounted() {
        this.getDeptos();
        this.getAccesRepComite();
    },
    methods: {
        cierraDialog() {
            if (this.dates.length == 2) {
                this.dialog = false;
            }
        },
        getAccesRepComite() {
            let params = {
                Opcion: 4,
                token: localStorage.getItem('token')
            }
            axios
                .post(`${this.$store.getters.getHost}/RelacionesLaborales/RelacionEmpleadoResponsable/ws-empleado-responsable.php`, params)
                .then((response) => {
                    if (response.data.status == "EXPSESSION") {
                        deleteToken();
                    } else {
                        this.accesoRepComite = response.data.respuesta;
                    }
                })
        },
        getDeptos() {
            let params = {
                Opcion: 2,
                token: localStorage.getItem('token')
            }
            axios
                .post(`${this.$store.getters.getHost}/RelacionesLaborales/Reportes/ws-reporte-asistencias.php`, params)
                .then((response) => {
                    this.loadingDeptos = false;
                    if (response.data.status == "EXPSESSION") {
                        deleteToken();
                        console.log("error")
                    } else {
                        var data = response.data.departamentos;
                        this.departamentos = data;
                        this.getAreas();
                    }
                })
        },
        getAreas() {
            let params = {
                Opcion: 1,
                token: localStorage.getItem('token')
            }
            axios
                .post(`${ this.$store.getters.getHost }/RelacionesLaborales/Reportes/ws-reporte-asistencias.php`, params)
                .then((response) => {
                    this.loadingAreas = false;
                    if (response.data.status == "EXPSESSION") {
                        deleteToken();
                        console.log("error")
                    } else {
                        var data = response.data.areas;
                        this.areas = data;
                        this.getColaboradores()
                    }
                })
        },
        getColaboradores() {
            let params = {
                Opcion: 3,
                token: localStorage.getItem('token')
            }
            axios
                .post(`${ this.$store.getters.getHost }/RelacionesLaborales/Reportes/ws-reporte-asistencias.php `, params)
                .then((response) => {
                    this.loadingColabs = false;
                    if (response.data.status == "EXPSESSION") {
                        deleteToken();
                        console.log("error")
                    } else {
                        var data = response.data.colaboradores;
                        this.colaboradores = data;

                        this.getLideres()
                    }
                })
        },
        getLideres() {
            let params = {
                Opcion: 4,
                token: localStorage.getItem('token')
            }
            axios
                .post(`${ this.$store.getters.getHost }/RelacionesLaborales/Reportes/ws-reporte-asistencias.php`, params)
                .then((response) => {
                    this.loadingResp = false;
                    if (response.data.status == "EXPSESSION") {
                        deleteToken();
                        console.log("error")
                    } else {
                        var data = response.data.lideres;
                        this.lideres = data;
                    }
                })
        },
        async filtrar(tipo) {
            this.loadingFiltro = true;
            this.clicked = true;

            if (tipo == null) {
                this.textoBarra = "Error. Selecciona un tipo de búsqueda.";
                this.colorBarra = "error";
                this.muestraBarra = true;
            } else if (this.dates.length < 2) {
                this.textoBarra = "Error. Selecciona el rango de fechas.";
                this.colorBarra = "error";
                this.muestraBarra = true;
            } else {
                switch (tipo) {

                    case 'resp':
                        {
                            this.validaAsistenciasResponsable();
                            break;
                        }
                    case 'area':
                    case 'dept':
                    case 'empl':
                        {
                            let typeQuery = tipo === 'empl' ? 1 : tipo === 'dept' ? 3 : 4;
                            let condition = tipo === 'empl' ? this.emplSelected : tipo === 'dept' ? this.deptIdSelected : this.areaSelected;

                            this.colaboradoresAsist = await this.muestraResultados(typeQuery, condition, this.dates[0], this.dates[1])
                            break;
                        }
                    case 'comt':
                        {
                            this.colaboradoresAsist = await this.muestraResultadosComite(this.dates[0], this.dates[1])
                            break;
                        }
                }


            }
        },
        buscaIdAreaSelected(val) {
            return val.Area.trim() == this.areaSelected.trim()
        },
        buscaIdRespSelected(val) {
            return val.nombreMostrar.trim() == this.respSelected.trim()
        },
        buscaIdDeptoSelected(val) {
            return val.deptoMostrar.trim() == this.deptIdSelected.trim()
        },
        buscaIdEmplSelected(val) {
            return val.nombreMostrar.trim() == this.emplSelected.trim()
        },
        stopLoading() {
            this.loadingFiltro = false;
        },
        validaAsistenciasResponsable() {
            if (this.clicked) {
                this.clicked = false;
                this.muestraAsistenciasResponsable()
            }

        },
        muestraAsistenciasResponsable() {
            this.clicked = true;
            let respAux = this.lideres.filter(this.buscaIdRespSelected);
            this.numResponsable = respAux[0].EMPLID;
            this.key++;
            this.stopLoading();
        },
        muestraResultados(type, condition, begin, end) {
            if (this.colaboradoresAsist.length > 0) {
                this.colaboradoresAsist.length = 0;
            }

            const headers = { Authorization: localStorage.getItem('token') }

            return new Promise((res) => {
                axios
                    .get(`${this.$store.getters.getHostNode}/api/get-reporte-asistencia-adm/${condition}/${begin}/${end}/${type}`, { headers })
                    .then((response) => {
                        if (response.data.status == "EXPSESSION") {
                            deleteToken();
                        } else {
                            res(response.data.data);
                        }
                    })
                    .finally(() => {
                        this.loadingFiltro = false;
                    })
            })

        },
        muestraResultadosComite(begin, end) {
            const headers = { Authorization: localStorage.getItem('token') }

            return new Promise((res) => {
                axios
                    .get(`${this.$store.getters.getHostNode}/api/get-reporte-asistencia-adm/1/${begin}/${end}/5`, { headers })
                    .then((response) => {
                        if (response.data.status == "EXPSESSION") {
                            deleteToken();
                        } else {
                            res(response.data.data);
                        }
                    })
                    .finally(() => {
                        this.loadingFiltro = false;
                    })
            })
        },
        getColorButton(type) {
            switch (type) {
                case 'resp':
                    {
                        if (this.tipoBusqueda == 'resp') {
                            return 'primary'
                        } else {
                            return 'grey darken-3'
                        }
                    }
                case 'area':
                    {
                        if (this.tipoBusqueda == 'area') {
                            return 'primary'
                        } else {
                            return 'grey darken-3'
                        }
                    }
                case 'dept':
                    {
                        if (this.tipoBusqueda == 'dept') {
                            return 'primary'
                        } else {
                            return 'grey darken-3'
                        }
                    }
                case 'empl':
                    {
                        if (this.tipoBusqueda == 'empl') {
                            return 'primary'
                        } else {
                            return 'grey darken-3'
                        }
                    }
                case 'comt':
                    {
                        if (this.tipoBusqueda == 'comt') {
                            return 'primary'
                        } else {
                            return 'grey darken-3'
                        }
                    }
                default:
                    {
                        return 'grey darken-3'
                    }
            }
        },
        reloadData() {
            this.colaboradoresAsist.length = 0;
            this.filtrar('comt');
        }

    }
}