var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-card-text',{staticClass:"pt-5"},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.plantas,"search":_vm.buscar,"loading":_vm.loading,"single-expand":"","expanded":_vm.expanded,"show-expand":"","item-key":"id","loading-text":"Cargando plantas","dense":"","mobile-breakpoint":"0","footer-props":{ 'items-per-page-text': 'Elementos por página' },"no-data-text":"No se encontraron registros"},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{staticClass:"rounded-lg rounded-b-0 rounded-t-0",attrs:{"flat":""}},[_c('v-text-field',{staticClass:"mt-7 rounded-lg",attrs:{"label":_vm.$t('DH360.findTextBox'),"dense":"","clearable":"","outlined":"","solo-inverted":"","prepend-inner-icon":"mdi-table-search"},model:{value:(_vm.buscar),callback:function ($$v) {_vm.buscar=$$v},expression:"buscar"}}),_c('v-spacer'),_c('v-btn',{staticClass:"rounded-lg",attrs:{"color":"teal darken-2","elevation":"2","dark":"","small":""},on:{"click":function($event){return _vm.openDialgoForm('Nuevo')}}},[_vm._v("Nuevo")])],1)]},proxy:true},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[(item.departamentos)?_c('div',[(item.departamentos.length > 0)?_c('v-card',{staticClass:"scrollable-div rounded-xl ma-5",attrs:{"elevation":"1"}},[_c('v-card-title',{staticClass:"text-subtitle-2 grey lighten-1 white--text"},[_vm._v(" Departamentos seleccionados: "+_vm._s(item.departamentos.length)+" ")]),_vm._l((item.departamentos),function(i,index){return _c('v-btn',{key:index,staticClass:"pa-2 ma-1 truncate-text",attrs:{"rounded":"","color":"grey lighten-3","small":""}},[_vm._v(" "+_vm._s(i.descripcionMostrar)+" ")])})],2):_c('div',[_vm._v("Nada")])],1):_c('div',[_c('v-card',{staticClass:"scrollable-div rounded-xl ma-5",attrs:{"elevation":"1"}},[_c('v-card-title',{staticClass:"text-subtitle-2 grey lighten-1 white--text"},[_vm._v(" Departamentos seleccionados: 0 ")]),_c('v-card-text',{staticClass:"disabled-text d-flex justify-center align-center"},[_c('v-icon',[_vm._v("mdi-alert")]),_vm._v(" Aún no cuenta con departamentos relacionados ")],1)],1)],1)])]}},{key:"item.habilitado",fn:function(ref){
var item = ref.item;
return [(item.habilitado == 1)?_c('v-btn',{attrs:{"x-small":"","rounded":"","elevation":"1","color":"teal darken-2","dark":""}},[_vm._v(" Habilitado ")]):_c('v-btn',{attrs:{"x-small":"","rounded":"","elevation":"1","color":"pink","dark":""}},[_vm._v(" Inhabilitado ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":"","color":"blue lighten-4"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"blue darken-4"},on:{"click":function($event){return _vm.openDialgoForm(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil-box-outline ")])]}}],null,true)},[_c('label',{staticClass:"text-uppercase font-weight-bold primary--text"},[_vm._v("Editar")])])]}}])})],1),(_vm.dialogForm)?_c('form-admon-plantas',{attrs:{"dialog":_vm.dialogForm,"item":_vm.item},on:{"cierraForm":_vm.closeForm}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }