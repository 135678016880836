import axios from "axios";
import VueJsonToCsv from 'vue-json-to-csv'
import { formatDate } from "../../../../../store/Funciones/funciones";
import SnackBarErrorIncidencias from "../../../General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue"
import FiltroReporte from "../ReporteIncidenciasVigilancia/Components/FiltroReporte.vue";
import ReporteBajasDetalles from "./components/ReporteBajasDetalles.vue";
export default {
  name: 'reporte-bajas',
  components: { SnackBarErrorIncidencias, VueJsonToCsv, FiltroReporte, ReporteBajasDetalles },
  props: [],
  data() {
    return {
      textoBarra: '',
      colorBarra: '',
      muestraBarra: false,
      buscar: '',
      cargando: true,
      openDialogDetalles: false,
      formatoFecha1: '', //v-text-field
      formatoFecha2: '',
      nameFile: '',
      bajas: [],
      fechaInicio: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      fechaFin: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      dialogPeriodo: false,
      headers: [
        { text: 'NP', align: 'center', value: 'NPBaja' },
        { text: 'Nombre', value: 'Nombre', align: 'center', },
        { text: 'Fecha ingreso', value: 'FechaIngreso', align: 'center', },
        { text: 'Fecha de Baja', value: 'FechaEfectivaBaja', align: 'center', },
        { text: 'Motivo General', value: 'MotivoBajaNominas', align: 'center', },
        { text: 'Estatus', value: 'Recontratable', align: 'center', },
        { text: 'Tipo colaborador', value: 'tipoColaborador', align: 'center', },
        { text: 'Antiguedad', value: 'Antiguedad', align: 'center', },
        { text: 'Mes de baja', value: 'MesBaja', align: 'center', },
        { text: 'Acciones', value: 'actions', sortable: false, align: 'center', },
      ],
      headers_report_to_export: {
        NPBaja: { title: "N.P." },
        Nombre: { title: "Nombre" },
        Puesto: { title: "Puesto" },
        Departamento: { title: "Departamento" },
        Area: { title: "Area" },
        FechaIngreso: { title: "Fecha de Ingreso" },
        FechaEfectivaBaja: { title: "Fecha de Baja" },
        Rango: { title: "Rangos de antiguedad" },
        MotivoBajaNominas: { title: "Motivo General" },
        DetalleMotivoBaja: { title: "Motivo 1" },
        DetalleMotivoBaja2: { title: "Motivo 2" },
        Recontratable: { title: "Estatus" },
        Jornada: { title: "Jornada" },
        Turno: { title: "Turno" },
        ComentarioBaja: { title: "Comentarios de Baja" },
        folioJefeDir: { title: "N. P. Supervisor" },
        nombreJefeDir: { title: "Supervisor" },
        tipoColaborador: { title: "Tipo de Colaborador" },
        Antiguedad: { title: "Antiguedad" },
        MesBaja: { title: "Mes de Baja" },
        cuartos: { title: "Cuartos" },
        AnioBaja: { title: "Año" },
        NombreSolicitante: { title: "Solicitante de baja" },
      },
    }
  },
  computed: {

  },
  mounted() {
    this.getFechasIniciales();
    this.getBajas();
  },
  methods: {
    fixDate(date) {
      return formatDate(date);
    },

    getFechasIniciales() {
      let fechaActual = new Date();

      // Restar una semana (7 días) a la fecha actual
      let fechaHaceUnaSemana = new Date(fechaActual);
      fechaHaceUnaSemana.setDate(fechaActual.getDate() - 7);

      let anio = fechaHaceUnaSemana.getFullYear();
      let mes = String(fechaHaceUnaSemana.getMonth() + 1).padStart(2, '0');
      let dia = String(fechaHaceUnaSemana.getDate()).padStart(2, '0');

      // Formatear la fecha en formato "YYYY-mm-dd"
      this.fechaInicio = `${anio}-${mes}-${dia}`;
    },

    getBajas() {
      let params = {
        Opcion: 1,
        fechaInicio: this.fechaInicio,
        fechaFin: this.fechaFin,
        token: localStorage.getItem("token")
      };
      axios.post(`${this.$store.getters.getHost}/RelacionesLaborales/Reportes/ws-reporteBajas.php`, params).then((response) => {
        if (response.data.status == 'OK' && response.data.data) {
          this.bajas = response.data.data;
        } else {
          this.textoBarra = 'No se encontraron datos';
          this.colorBarra = 'primary';
          this.muestraBarra = true;
        }
        this.cargando = false;
      })
    },

    openDetalles(item) {
      this.item = item;
      this.openDialogDetalles = true;
    },

    closeDialog(response) {
      this.openDialogDetalles = false;
      if (response == "OK") {
        this.clean();
        this.getBajas();
      }
    },

    actualizaFechas(nuevasFechas) {
      console.log(nuevasFechas);
      if (this.fechaInicio != nuevasFechas.fechaInicial || this.fechaFin != nuevasFechas.fechaFinal) {
        this.fechaInicio = nuevasFechas.fechaInicial;
        this.fechaFin = nuevasFechas.fechaFinal;
        this.clean();
        this.getBajas();
      }
      this.dialogPeriodo = false;
    },

    clean() {
      this.bajas = [];
      this.cargando = true;
    },

    fixName() {
      let numero_aleatorio =
        Math.floor(Math.random() * (99999 - 10000 + 1)) + 10000;
      this.nameFile = "ReporteBajas_" + numero_aleatorio.toString();
    },
  }
}


