var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-0",staticStyle:{"background-color":"transparent"},attrs:{"dense":"","headers":_vm.headers,"items":_vm.empleados,"loading":_vm.loadingData,"items-per-page":10,"search":_vm.search,"footer-props":{
      'items-per-page-text': 'Elementos por página',
      pageText: '{0}-{1} de {2}',
    },"no-data-text":"No hay registros","loading-text":"Cargando información..."},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"py-3",staticStyle:{"display":"flex","justify-content":"start"}},[_c('v-btn',{staticClass:"rounded-pill mr-2",attrs:{"depressed":"","small":"","color":_vm.opcSelected === 1 ? 'primary' : 'blue-grey lighten-5'},on:{"click":function($event){_vm.opcSelected = 1}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-account-group")]),_vm._v(" activos ")],1),_c('v-btn',{staticClass:"rounded-pill mr-2",attrs:{"depressed":"","small":"","color":_vm.opcSelected === 2 ? 'primary' : 'blue-grey lighten-5'},on:{"click":function($event){_vm.opcSelected = 2}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-account-multiple-minus")]),_vm._v(" inactivos ")],1)],1),_c('div',{staticStyle:{"display":"flex","justify-content":"space-between"}},[_c('v-text-field',{staticClass:"rounded-pill",attrs:{"dense":"","outlined":"","prepend-inner-icon":"mdi-magnify","placeholder":"Buscar..."},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('div',[_c('vue-json-to-csv',{attrs:{"json-data":_vm.empleados,"labels":_vm.headers_to_export,"csv-title":"ReporteEmpleadosCajaAhorro"}},[_c('v-tooltip',{attrs:{"bottom":"","color":"green darken-2"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"rounded-pill mr-2",attrs:{"depressed":"","outlined":"","fab":"","small":"","color":"green darken-2","dark":"","loading":_vm.loadingData}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-microsoft-excel")])],1)]}}])},[_c('span',[_vm._v("Exportar CSV")])])],1),_c('v-tooltip',{attrs:{"bottom":"","color":"blue darken-2"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:" mr-2",attrs:{"depressed":"","fab":"","small":"","outlined":"","color":"blue darken-2","loading":_vm.loadingData},on:{"click":function($event){_vm.showFindNewMembers = true}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-account-plus")])],1)]}}])},[_c('span',[_vm._v("Inscribir colaborador")])]),_c('v-tooltip',{attrs:{"bottom":"","color":"blue-grey darken-2"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"depressed":"","fab":"","small":"","outlined":"","color":"blue-grey darken-2","loading":_vm.loadingData},on:{"click":_vm.actualizarTabla}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-reload")])],1)]}}])},[_c('span',[_vm._v("Actualizar tabla")])])],1)],1)]},proxy:true},{key:"item.monto",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.monto != null ? _vm.formatMXN(item.monto) : _vm.formatMXN(0))+" ")]}},{key:"item.saldo",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.saldo != null ? _vm.formatMXN(item.saldo) : _vm.formatMXN(0))+" ")]}},{key:"item.prestamo",fn:function(ref){
    var item = ref.item;
return [_c('v-chip',{staticClass:"elevation-0",attrs:{"x-small":"","dark":"","color":item.prestamo == 0 ? 'pink darken-3' : 'teal darken-1'}},[_vm._v(" "+_vm._s(item.prestamo == 0 ? "Inactivo" : "Activo")+" ")])]}},{key:"item.accion",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"center-item-in-div"},[_c('v-icon',{attrs:{"small":"","color":"cyan darken-1"},on:{"click":function($event){return _vm.muestraDetalles(item.emplid)}}},[_vm._v("mdi-information")]),_c('v-icon',{attrs:{"small":"","color":"purple darken-2"},on:{"click":function($event){_vm.employee = item.emplid;
            _vm.emplAux = item;
            _vm.showDataEmployee = true;}}},[_vm._v("mdi-eye-circle")]),_c('v-icon',{attrs:{"small":"","color":"primary"},on:{"click":function($event){_vm.employee = item.emplid;
            _vm.mostrarAlu = true;}}},[_vm._v("mdi-chat")])],1)]}}])}),(_vm.verDerallesPorUsuario)?_c('DetallesPorUsuario',{attrs:{"verDeralles":_vm.verDerallesPorUsuario,"idUser":_vm.emplidSelected},on:{"cierraDialogDetalles":function($event){_vm.verDerallesPorUsuario = false}}}):_vm._e(),(_vm.showDataEmployee)?_c('DetalleGeneralCajaColaborador',{attrs:{"showDataEmployee":_vm.showDataEmployee,"empleado":_vm.employee,"dataEmpl":_vm.emplAux},on:{"hideDataEmployee":function($event){_vm.showDataEmployee = false}}}):_vm._e(),_c('v-dialog',{attrs:{"max-width":"500"},model:{value:(_vm.mostrarAlu),callback:function ($$v) {_vm.mostrarAlu=$$v},expression:"mostrarAlu"}},[(_vm.mostrarAlu)?_c('Alu',{attrs:{"admin":true,"empleado":_vm.employee},on:{"cierraCA":function($event){_vm.mostrarAlu = false}}}):_vm._e()],1),(_vm.showFindNewMembers)?_c('BuscaColaboradoresActivos',{attrs:{"showFindNewMembers":_vm.showFindNewMembers},on:{"returnColaborrador":_vm.returnColaborrador}}):_vm._e(),(_vm.muestraBarra)?_c('SnackBarErrorIncidencias',{attrs:{"muestraBarra":_vm.muestraBarra,"color":_vm.colorBarra,"texto":_vm.textoBarra},on:{"cierraBarra":function($event){_vm.muestraBarra = false}}}):_vm._e(),_c('IngresaCantidad',{attrs:{"muestraIngresaCantidad":_vm.muestraIngresaCantidad,"titulo":'Ingresa la cantidad',"tipo":"monto"},on:{"retornaCantidad":_vm.retornaCantidad}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }