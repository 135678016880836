<template>
  <Radar
    :chart-options="chartOptions"
    :chart-data="chartData"
    :chart-id="chartId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :width="width"
    :height="height"
  />
</template>

<script>
import axios from 'axios'
import { Radar } from "vue-chartjs/legacy";
import { deleteToken } from '../../../../../../store/Funciones/funciones';

import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
} from 'chart.js'

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
)
export default {
  name: "RadarChart",
  components: {
    Radar,
  },
  props: {
    idProyecto:{
      type: Number,
      idProyecto:"idProyecto"},
    chartId: {
      type: String,
      default: "radar-chart",
    },
    datasetIdKey: {
      type: String,
      default: "label",
    },
    width: {
      type: Number,
      default: 100,
    },
    height: {
      type: Number,
      default: 500,
    },
    cssClasses: {
      default: "",
      type: String,
    },
    styles: {
      type: Object,
      default: () => {},
    },
    plugins: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      chartData: {
        labels: [],
        datasets: [
          {
            label: this.$t("DH360.txtCurrentAssesment"),
            data: [],
            fill: true,
            backgroundColor: "rgba(0,120,195, 0.2)",
            borderColor: "rgb(0,120,195)",
            pointBackgroundColor: "rgb(0,120,195)",
            pointBorderColor: "#fff",
            pointHoverBackgroundColor: "#fff",
            pointHoverBorderColor: "rgb(0,120,195)",
          },
        ],
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          r: {
            suggestedMin: 0,
            suggestedMax: 100
          }
        }
      },
    };
  },

  mounted() {
    this.getCalificaciones();
    console.log(this.idProyecto);
  },

  methods: {
    getCalificaciones(){
      // let idProyecto = this.$emit('getIdProyecto')
        let params = {
        Opcion: 5,
        token: localStorage.getItem("token"),
        idProyecto: this.idProyecto
      };
      axios
        .post(
          `${this.$store.getters.getHost}/RelacionesLaborales/EvaluacionDesempenio/ws-envaluacion-360.php`,
          params
        )
        .then((response) => {
          if (response.data.status != "EXPSESSION") {
            // console.log(response.data.respuesta)
            response.data.respuesta.map(calificacion => {
                this.chartData.labels.push(calificacion.CategoriaDesc)
                this.chartData.datasets[0].data.push(calificacion.Calificacion.toFixed(2))
            })
          } else {
            deleteToken()
          }
        }).catch((e) => {
            alert(e);
            // deleteToken()
        });
    }
  },
};
</script>
