<template>
  <v-dialog
    v-model="showSurveysPostDate"
    persistent
    scrollable
    max-width="700"
    v-resize="onResize"
  >
    <v-card class="rounded-lg">
      <v-card-title class="text-subtitle-2 primary white--text">
        {{ $t("DH360.txtTitleGenSurvsClients") }}
        <v-spacer></v-spacer>
        <v-icon small dark @click="$emit('hideSurveysPostDate', -1)"
          >mdi-close</v-icon
        >
      </v-card-title>
      <v-card-text>
        <div v-if="loadingTabla" class="text-center mt-5">
          <v-progress-circular
            :size="70"
            color="primary"
            indeterminate
          ></v-progress-circular>
        </div>
        <v-simple-table
          v-else-if="!loadingTabla && participantes.length > 0"
          dense
        >
          <thead>
            <tr>
              <th class="text-center">EMPLID</th>
              <th class="text-center">{{ $t("DH360.nameTextHeader") }}</th>
              <th class="text-center">{{ $t("DH360.actionsTextHeader") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in participantes" :key="item.emplid">
              <td class="text-center">{{ item.emplid }}</td>
              <td class="pa-0 text-center">{{ item.NOMBRE }}</td>
              <td class="text-center">
                <v-btn
                  small
                  v-if="windowSize.x > 600"
                  color="teal darken-2"
                  dark
                  depressed
                  class="rounded-pill"
                  @click="
                    loadingTabla = true;
                    generateSurveys(item);
                  "
                >
                  <v-icon left>mdi-account-multiple-plus</v-icon>
                  {{ $t("DH360.txtBtnGenSurvsClients") }}
                </v-btn>
                <v-tooltip v-else bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      small
                      icon
                      v-bind="attrs"
                      v-on="on"
                      color="teal darken-2"
                      @click="
                        loadingTabla = true;
                        generateSurveys(item);
                      "
                    >
                      <v-icon>mdi-account-multiple-plus</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("DH360.txtBtnGenSurvsClients") }}</span>
                </v-tooltip>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
        <div v-else>
          <p
            class="mt-5 text-center font-weight-bold blue-grey--text text--lighten-1"
            style="font-size: 1.5rem;"
          >
            {{ $t("DH360.noDataText") }}
          </p>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
import { deleteToken } from "../../../../../../store/Funciones/funciones";
export default {
  name: "admon-encuestas-clientes-por-generar",
  components: {},
  props: ["showSurveysPostDate", "idProyecto"],
  data() {
    return {
      participantes: [],
      loadingTabla: true,
      windowSize: {
        x: 0,
        y: 0,
      },
    };
  },
  computed: {},
  mounted() {
    this.onResize();
    this.getEncuestasPorGenerarPendientes();
  },
  methods: {
    onResize() {
      this.windowSize = {
        x: window.innerWidth,
        y: window.innerHeight,
      };
    },
    getEncuestasPorGenerarPendientes() {
      this.participantes.length = 0;
      let params = {
        Opcion: 34,
        token: localStorage.getItem("token"),
        idProyecto: this.idProyecto,
      };
      axios
        .post(
          `${this.$store.getters.getHost}/RelacionesLaborales/AdmonEvaluacionesDesempenio/ws-admon-360.php`,
          params
        )
        .then((response) => {
          this.loadingTabla = false;
          if (response.data.status == "EXPRESSION") {
            deleteToken();
          } else if (response.data.status == "OK") {
            this.participantes = response.data.respuesta;
          }
        });
    },
    generateSurveys(item) {
      let params = {
        Opcion: 35,
        token: localStorage.getItem("token"),
        idProyecto: this.idProyecto,
        empleado: item.emplid,
      };
      axios
        .post(
          `${this.$store.getters.getHost}/RelacionesLaborales/AdmonEvaluacionesDesempenio/ws-admon-360.php`,
          params
        )
        .then((response) => {
          this.loadingTabla = false;
          if (response.data.status == "EXPRESSION") {
            deleteToken();
          } else if (response.data.status == "OK") {
            this.$emit("hideSurveysPostDate", response.data.respuesta);
          }
        })
        .catch((e) => {
          console.log(e);
          deleteToken();
        });
    },
  },
};
</script>
