<template>
  <v-card class="rounded-lg" elevation="10" transition="scroll-x-transition">
    <div class="text-center pa-2">
      <v-btn
        class="rounded-lg mx-1 font-weight-bold"
        depressed
        large
        color="red darken-3"
        dark
        @click="openShowFam = true"
      >
        <v-icon left>mdi-eye</v-icon>
        {{ $t("DH360.textBtnShowFamilies") }}
      </v-btn>
      <v-btn
        class="rounded-lg mx-1 blue--text text--darken-4 font-weight-bold"
        depressed
        large
        color="#f2e200"
        @click="confirmReloadEmpLead = true"
      >
        <v-icon left>mdi-reload</v-icon>
        {{ $t("DH360.textBtnReloadRelEmpResp1") }}
        <br />
        {{ $t("DH360.textBtnReloadRelEmpResp2") }}
      </v-btn>
      <v-btn
        v-if="avance < 100"
        class="rounded-lg mx-1 font-weight-bold"
        depressed
        disabled
        large
        color="primary"
      >
        <v-icon left>mdi-email-fast-outline</v-icon>
        {{ $t("DH360.textBtnNotifyClientLoad") }}
      </v-btn>
      <v-btn
        v-if="avance == 100"
        class="rounded-lg mx-1 font-weight-bold"
        depressed
        large
        color="primary"
        dark
        @click="confirmSendEmailClients = true"
      >
        <v-icon left>mdi-email-fast-outline</v-icon>
        {{ $t("DH360.textBtnNotifyClientLoad") }}
      </v-btn>
    </div>
    <div style="display:flex; justify-content:center;">
      <v-progress-linear
        class="my-5 text-center"
        v-model="avance"
        color="primary"
        rounded
        height="25"
        style="max-width:700px;"
      >
        <strong style="color:white"
          >{{ $t("DH360.progress") }} {{ $t("DH360.creating").toLowerCase() }}
          {{ $t("DH360.families").toLowerCase() }}
          {{ avance.toFixed(1) }}%</strong
        >
      </v-progress-linear>
    </div>
    <v-divider class="mx-2"></v-divider>
    <v-text-field
      class="mx-2"
      v-model="search"
      append-icon="mdi-magnify"
      :label="$t('DH360.findTextBox')"
      single-line
      hide-details
    ></v-text-field>
    <v-data-table
      class="mx-2 mb-5"
      :headers="headers"
      :items="participantesEnFamilias"
      :items-per-page="itemsPerPage"
      :footer-props="{ 'items-per-page-text': 'Elementos por página' }"
      no-data-text="No hay registros"
      :header-props="headerProps"
      :search="search"
      :loading="loadingData"
      :loading-text="$t('DH360.gettingParticipants')"
      :expanded.sync="expanded"
      :single-expand="singleExpand"
      item-key="ID_EMPLEADO"
      show-expand
      dense
    >
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length" class="px-0 mx-0">
          <v-row dense>
            <v-col cols="12" xs="6" sm="6" md="3">
              <v-subheader
                class="tam-subheader py-0 mt-0 blue-grey--text text--darken-1 font-weight-bold"
              >
                {{ $t("DH360.positionTextHeader") }}
              </v-subheader>
              <v-subheader class="tam-subheader py-0 mt-0">
                {{ item.PUESTO }}
              </v-subheader>
            </v-col>
            <v-col cols="12" xs="6" sm="6" md="3">
              <v-subheader
                class="tam-subheader py-0 mt-0 blue-grey--text text--darken-1 font-weight-bold"
              >
                {{ $t("DH360.depTextHeader") }}
              </v-subheader>
              <v-subheader class="tam-subheader py-0 mt-0">
                {{ item.DEPTO }}
              </v-subheader>
            </v-col>
            <v-col cols="12" xs="6" sm="6" md="3">
              <v-subheader
                class="tam-subheader py-0 mt-0 blue-grey--text text--darken-1 font-weight-bold"
              >
                {{ $t("DH360.areaTextHeader") }}
              </v-subheader>
              <v-subheader class="tam-subheader py-0 mt-0">
                {{ item.Area }}
              </v-subheader>
            </v-col>
            <v-col cols="12" xs="6" sm="6" md="3">
              <v-subheader
                class="tam-subheader py-0 mt-0 blue-grey--text text--darken-1 font-weight-bold"
              >
                {{ $t("DH360.statusTextHeader") }} EMP.
              </v-subheader>
              <v-subheader class="tam-subheader py-0 mt-0">
                {{ item.EMP_STATUS }}
              </v-subheader>
            </v-col>
          </v-row>
        </td>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="openDelete(item)">
          mdi-minus-circle
        </v-icon>
      </template>

      <template v-slot:item.Status="{ item }">
        <p
          class="font-weight-bold text-center green--text text--darken-3 my-2 pa-0"
          v-if="item.Status == 1"
        >
          {{ $t("DH360.assigned") }}
        </p>
        <p
          class="font-weight-bold text-center red--text text--darken-3 my-2 pa-0"
          v-else
        >
          {{ $t("DH360.dontAssigned") }}
        </p>
      </template>
    </v-data-table>

    <v-dialog
      v-model="confirmReloadEmpLead"
      max-width="450"
      scrollable
      persistent
    >
      <v-card class="rounded-lg">
        <p
          class="text-center pt-4 px-2"
          style="font-size: 1.1rem; font-weight:600; color:darkslategray"
        >
          {{ $t("DH360.textTitleDialogConfirmReload") }} <br />
          {{ $t("DH360.textTitleDialogConfirmReloadQuestion") }}
        </p>
        <v-card-actions style="display:flex; justify-content: space-around;">
          <v-btn
            class="rounded-lg"
            large
            depressed
            color="pink darken-3"
            width="180"
            :disabled="loadButtonReload"
            dark
            @click="confirmReloadEmpLead = false"
          >
            {{ $t("DH360.cancel") }}
          </v-btn>
          <v-btn
            class="rounded-lg"
            large
            depressed
            color="teal"
            width="180"
            :loading="loadButtonReload"
            dark
            @click="
              loadButtonReload = true;
              reloadRelEmpLead();
            "
          >
            {{ $t("DH360.confirm") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="confirmDelete" max-width="450" scrollable persistent>
      <v-card class="rounded-lg">
        <p
          class="text-center pt-4 px-2"
          style="font-size: 1.1rem; font-weight:600; color:darkslategray"
        >
          {{ $t("DH360.confirmTextDeleteParticipant") }}
          {{ participantToDelete }} ?
        </p>
        <v-card-actions style="display:flex; justify-content: space-around;">
          <v-btn
            class="rounded-lg"
            large
            depressed
            color="pink darken-3"
            width="180"
            :disabled="loadButtonDelPart"
            dark
            @click="
              confirmDelete = false;
              participantToDelete = '';
            "
          >
            {{ $t("DH360.cancel") }}
          </v-btn>
          <v-btn
            class="rounded-lg"
            large
            depressed
            color="teal"
            width="180"
            :loading="loadButtonDelPart"
            dark
            @click="
              loadButtonDelPart = true;
              delParticipant(itemToDelete);
            "
          >
            {{ $t("DH360.confirm") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="confirmSendEmailClients" max-width="450" scrollable persistent>
      <v-card class="rounded-lg">
        <p
          class="text-center pt-4 px-2"
          style="font-size: 1.1rem; font-weight:600; color:darkslategray"
        >
          {{ $t("DH360.textTitleDialogConfirmReloadQuestion") }}
        </p>
        <v-card-actions style="display:flex; justify-content: space-around;">
          <v-btn
            class="rounded-lg"
            large
            depressed
            color="pink darken-3"
            width="180"
            :disabled="loadButtonSenMail"
            dark
            @click="
              confirmSendEmailClients = false
            "
          >
            {{ $t("DH360.cancel") }}
          </v-btn>
          <v-btn
            class="rounded-lg"
            large
            depressed
            color="teal"
            width="180"
            :loading="loadButtonSenMail"
            dark
            @click="
              loadButtonSenMail = true;
              sendAddClientes();
            "
          >
            {{ $t("DH360.confirm") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <SnackBarErrorIncidencias
      :muestraBarra="muestraBarra"
      :texto="textoBarra"
      :color="colorBarra"
      @cierraBarra="muestraBarra = false"
    />

    <MostrarFamilias360
      :verFamilias="openShowFam"
      :idProyecto="idProyecto"
      :compania="compania"
      @cerrarVerFamilias="cerraVerFamilias"
    />
  </v-card>
</template>

<script>
import axios from "axios";
import SnackBarErrorIncidencias from "../../../../General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue";
import { deleteToken } from "../../../../../../store/Funciones/funciones";
import MostrarFamilias360 from "./MostrarFamilias360.vue";

export default {
  name: "admon-familias-360",
  components: { SnackBarErrorIncidencias, MostrarFamilias360 },
  props: ["participantes", "estatus", "idProyecto", "proyecto", "compania"],
  data() {
    return {
      headers: [
        { value: "ID_EMPLEADO", text: "EMPLID" },
        { value: "NAME", text: "" },
        { value: "Status", text: "", align: "center" },
        { value: "actions", text: "", align: "center" },
      ],
      expanded: [],
      headerProps: {
        sortByText: "Ordenar por",
      },
      itemToDelete: {
        ID_EMPLEADO: 0,
        NAME: "",
        PUESTO: "",
        DEPTO: "",
        Area: "",
        EMP_STATUS: "",
        NombreMostrar: "",
        Status: 0,
      },
      itemsPerPage: 15,
      avance: 12,
      search: "",
      textoBarra: "",
      colorBarra: "",
      participantToDelete: "",
      singleExpand: true,
      confirmReloadEmpLead: false,
      loadButtonReload: false,
      loadButtonDelPart: false,
      showSuccessMessage: false,
      muestraBarra: false,
      openShowFam: false,
      confirmDelete: false,
      loadingData: false,
      confirmSendEmailClients: false,
      loadButtonSenMail: false
    };
  },
  created() {
    this.participantesEnFamilias = this.participantes;
  },
  computed: {},
  mounted() {
    this.headers[1].text = this.$t("DH360.nameTextHeader");
    this.headers[2].text = this.$t("DH360.statusTextHeader");
    this.headers[3].text = this.$t("DH360.actionsTextHeader");
    this.updateProgress();
  },
  methods: {
    reloadRelEmpLead() {
      let params = {
        Opcion: 12,
        token: localStorage.getItem("token"),
        idProyecto: this.idProyecto,
      };
      axios
        .post(
          `${this.$store.getters.getHost}/RelacionesLaborales/AdmonEvaluacionesDesempenio/ws-admon-360.php`,
          params
        )
        .then((response) => {
          this.loadButtonReload = false;
          this.confirmReloadEmpLead = false;
          if (response.data.status == "EXPRESSION") {
            deleteToken();
          } else if (response.data.status == "OK") {
            this.colorBarra = "success";
            this.textoBarra =
              this.$t("DH360.successfullyMessageAddParticipants") +
              response.data.resultado +
              this.$t("DH360.relations") +
              ". :)";
            this.muestraBarra = true;
          } else {
            this.colorBarra = "pink darken-3";
            this.textoBarra = this.$t("DH360.errorMessaggeDB") + ". :)";
            this.muestraBarra = true;
          }
        });
    },

    openDelete(item) {
      this.participantToDelete = item.NAME;
      this.itemToDelete = item;
      this.confirmDelete = true;
    },

    delParticipant(item) {
      let editedIndex = this.participantesEnFamilias.indexOf(item);
      this.participantesEnFamilias.splice(editedIndex, 1);

      let params = {
        Opcion: 16,
        token: localStorage.getItem("token"),
        idProyecto: this.idProyecto,
        empleado: item.ID_EMPLEADO,
        status: item.Status,
      };
      axios
        .post(
          `${this.$store.getters.getHost}/RelacionesLaborales/AdmonEvaluacionesDesempenio/ws-admon-360.php`,
          params
        )
        .then((response) => {
          this.loadButtonDelPart = false;
          this.confirmDelete = false;
          if (response.data.status == "EXPRESSION") {
            deleteToken();
          } else if (response.data.status == "OK") {
            this.colorBarra = "success";
            this.textoBarra =
              this.$t("DH360.successfullyMessageDeleteParticipants") + " ";
            response.data.resultado;
            (". :)");
            this.muestraBarra = true;
          } else {
            this.colorBarra = "pink darken-3";
            this.textoBarra = this.$t("DH360.errorMessaggeDB") + ". :(";
            this.muestraBarra = true;
          }
        });
    },

    cerraVerFamilias() {
      this.openShowFam = false;
      this.loadingData = true;
      this.getParticipantes360();
    },

    getParticipantes360() {
      this.participantesEnFamilias.length = 0;
      let params = {
        Opcion: 11,
        token: localStorage.getItem("token"),
        idProyecto: this.idProyecto,
        editarRelaciones: 1,
      };
      axios
        .post(
          `${this.$store.getters.getHost}/RelacionesLaborales/AdmonEvaluacionesDesempenio/ws-admon-360.php`,
          params
        )
        .then((response) => {
          if (response.data.status == "EXPRESSION") {
            deleteToken();
          } else if (response.data.status == "OK") {
            this.participantesEnFamilias = response.data.participantes;
            this.loadingData = false;
            this.updateProgress();
          }
        });
    },

    updateProgress() {
      let arrayAvance = this.participantesEnFamilias.filter(function condition(
        element
      ) {
        if (element.Status === 1) return true;
        return false;
      });

      this.avance =
        (arrayAvance.length / this.participantesEnFamilias.length) * 100;
    },

    sendAddClientes() {
      let params = {
        Opcion: 18,
        token: localStorage.getItem("token"),
        idProyecto: this.idProyecto,
      };

      axios
        .post(
          `${this.$store.getters.getHost}/RelacionesLaborales/AdmonEvaluacionesDesempenio/ws-admon-360.php`,
          params
        )
        .then((response) => {
          this.loadButtonSenMail = false;
          if (response.data.status == "EXPRESSION") {
            deleteToken();
          }
          else if(response.data.status == "OK"){
            if(response.data.respuesta == 1){
              this.confirmSendEmailClients = false;
              this.$emit('updateEstatus', 'NOTIFICACION1')
            } else {
              alert("error")
            }
          }
        });
    },
  },
};
</script>

<style>
.tam-subheader {
  height: 25px;
  max-height: 75px;
}
</style>
