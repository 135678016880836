import AdmTiposDocumentos from './components/AdmTiposDocumentos.vue'
import AdmExpColaboradores from './components/AdmExpColaboradores.vue'
import AdmExpColaboradoresBajas from './components/AdmExpColaboradoresBajas.vue'
import AdmExpColaboradoresBajaDemanda from './components/AdmExpColaboradoresBajaDemanda.vue'
export default {
    name: 'adm-expedientes',
    components: { AdmTiposDocumentos, AdmExpColaboradores, AdmExpColaboradoresBajas, AdmExpColaboradoresBajaDemanda },
    props: [],
    data() {
        return {
            tab: null,
        }
    },
    computed: {

    },
    mounted() {
        //  window.document.title = "DH:URREA - Admón. de expedientes.";
    },
    methods: {
        cambiarTab() {
            console.log(this.tab);
            // Este método se ejecutará cada vez que se cambie de tab
            if (this.tab === 0) {
                if (this.$refs.AdmExpColaboradores) {
                    this.$refs.AdmExpColaboradores.buscar();
                }
            } else if (this.tab === 1) {
                if (this.$refs.AdmTiposDocumentos) {
                    this.$refs.AdmTiposDocumentos.buscar();
                }
            } else if (this.tab === 2) {
                if (this.$refs.AdmExpColaboradoresBajas) {
                    this.$refs.AdmExpColaboradoresBajas.buscar();
                }
            } else if (this.tab === 3) {
                if (this.$refs.AdmExpColaboradoresBajaDemanda) {
                    this.$refs.AdmExpColaboradoresBajaDemanda.buscar();
                }
            }
        },
    }
}