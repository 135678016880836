<template>
  <div>
    <v-dialog
      v-model="verDialogHistorialMovimientos"
      transition="dialog-bottom-transition"
      content-class="fix-border-dialog"
      max-width="1250"
      persistent
      scrollable
    >
      <v-card class="rounded-xl">
        <v-toolbar color="primary" dark>
          <label class="overline font-weight-bold">
            Historial de movimientos en ALU [{{ emplid }}]
          </label>
          <v-spacer></v-spacer>
          <v-icon dark @click="$emit('cierraDialogHistorialMovimientos')"
            >mdi-close</v-icon
          >
        </v-toolbar>
        <v-card-text class="pt-3">
          <v-data-table
            dense
            :items="movimientos"
            :headers="headers"
            :search="search"
            :footer-props="{
              'items-per-page-text': 'Elementos por página',
              pageText: '{0}-{1} de {2}',
            }"
            :loading="loadingData"
            loading-text="Cargando montos..."
            no-data-text="No hay registros"
          >
            <template v-slot:top>
              <div style="display: flex; justify-content: space-between;">
                <v-text-field
                  v-model="search"
                  dense
                  outlined
                  class="rounded-pill"
                  prepend-inner-icon="mdi-magnify"
                  placeholder="Buscar..."
                ></v-text-field>
                <v-spacer></v-spacer>
                <div>
                  <vue-json-to-csv
                    :json-data="movimientos"
                    :labels="headers_to_export"
                    :csv-title="'ReporteHistorialAlu_' + emplid"
                  >
                    <v-tooltip bottom color="green darken-2">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          depressed
                          class="rounded-pill mr-2"
                          color="green darken-2"
                          dark
                          fab
                          small
                          outlined
                          :loading="loadingData"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon>mdi-microsoft-excel</v-icon>
                        </v-btn>
                      </template>
                      <span>Exportar CSV</span>
                    </v-tooltip>
                  </vue-json-to-csv>
                </div>
              </div>
            </template>

            <template v-slot:item.FECHA_ACTUAL="{ item }">
              {{ setFormatDate(item.FECHA_ACTUAL) }}
            </template>
            <template v-slot:item.FECHA_PRENOM="{ item }">
              {{ setFormatDate(item.FECHA_PRENOM) }}
            </template>
            <template v-slot:item.FECHA_NOM="{ item }">
              {{ setFormatDate(item.FECHA_NOM) }}
            </template>
            <template v-slot:item.FECHA_INICIO_PERIODO="{ item }">
              {{ setFormatDate(item.FECHA_INICIO_PERIODO) }}
            </template>
            <template v-slot:item.CANTIDAD="{ item }">
                {{ formatMXN(item.CANTIDAD) }}
            </template>
            <template v-slot:item.INTERES_ACTUAL="{ item }">
                {{ item.INTERES_ACTUAL !== null ? parseFloat(item.INTERES_ACTUAL).toFixed(2) : null }}
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import { deleteToken } from "../../../../../../../store/Funciones/funciones";
import VueJsonToCsv from "vue-json-to-csv";


export default {
  props: ["verDialogHistorialMovimientos", "emplid"],
  components: {VueJsonToCsv},
  data() {
    return {
      movimientos: [],
      headers: [
        {value: "ID", text: "ID"},
        {value: "EMPLID", text: "EMPLID"},
        {value: "CONCEPTO", text: "CONCEPTO"},
        {value: "NOMBRE", text: "NOMBRE", class:"fix-width-column"},
        {value: "FECHA_ACTUAL", text: "FECHA ACTUAL"},
        {value: "FECHA_PRENOM", text: "FECHA PRENOM"},
        {value: "FECHA_NOM", text: "FECHA NOM"},
        {value: "GRUPO_PAGO", text: "GRUPO"},
        {value: "CANTIDAD", text: "CANTIDAD"},
        {value: "TIPO_MOVIMIENTO", text: "TIPO", class:"fix-width-column"},
        {value: "RAZON_MOVIMIENTO", text: "RAZON"},
        {value: "DESCRIPCION", text: "DESCRIPCIÓN"},
        {value: "FECHA_INICIO_PERIODO", text: "INICIO"},
        {value: "PLAZO", text: "PLAZO"},
        {value: "IMPORTE", text: "IMPORTE"},
        {value: "PERIODO_SIGUIENTE", text: "PERIODO SIG"},
        {value: "IMPORTE_PRESTAMO", text: "IMPORTE PRESTAMO"},
        {value: "INTERES_ACTUAL", text: "INTERES"},
        {value: "MOV_CANCELADO", text: "ESTATUS"},
        {value: "FECHA_DETAL", text: "FECHA REG"},
      ],
      headers_to_export: {
        ID: {title: "ID"},
        EMPLID: {title: "EMPLID"},
        CONCEPTO: {title: "CONCEPTO"},
        NOMBRE: {title: "NOMBRE"},
        FECHA_ACTUAL: {title: "FECHA_ACTUAL"},
        FECHA_PRENOM: {title: "FECHA_PRENOM"},
        FECHA_NOM: {title: "FECHA_NOM"},
        GRUPO_PAGO: {title: "GRUPO_PAGO"},
        CANTIDAD: {title: "CANTIDAD"},
        TIPO_MOVIMIENTO: {title: "TIPO_MOVIMIENTO"},
        RAZON_MOVIMIENTO: {title: "RAZON_MOVIMIENTO"},
        DESCRIPCION: {title: "DESCRIPCION"},
        FECHA_INICIO_PERIODO: {title: "FECHA_INICIO_PERIODO"},
        PLAZO: {title: "PLAZO"},
        IMPORTE: {title: "IMPORTE"},
        PERIODO_SIGUIENTE: {title: "PERIODO_SIGUIENTE"},
        IMPORTE_PRESTAMO: {title: "IMPORTE_PRESTAMO"},
        INTERES_ACTUAL: {title: "INTERES_ACTUAL"},
        MOV_CANCELADO: {title: "MOV_CANCELADO"},
        FECHA_DETAL: {title: "FECHA_DETAL"},
      },
      search: '',
      loadingData: true
    };
  },
  computed: {},
  async mounted() {
    this.movimientos = await this.getMovimientosPorEmpleado();
  },
  methods: {
    getMovimientosPorEmpleado() {
      const headers = {
        Authorization: localStorage.getItem("token"),
      };

      return new Promise((res) => {
        axios
          .get(
            `${this.$store.getters.getHostNode}/api/empleados/caja/alu/datos-movimientos-historico/${this.emplid}`,
            {
              headers,
            }
          )
          .then((response) => {
            if (response.data.status == "EXPSESSION") {
              deleteToken();
            } else if (response.data.status == "OK") {
              res(response.data.data);
            } else {
              res([]);
            }
          })
          .finally(() => {
            this.loadingData = false;
          });
      });
    },
    setFormatDate(date) {
      let fecha = new Date(date);

      let diaUTC = fecha.getUTCDate();
      let mesUTC = fecha.getUTCMonth() + 1;
      let anioUTC = fecha.getUTCFullYear();

      diaUTC = diaUTC < 10 ? "0" + diaUTC : diaUTC;
      mesUTC = mesUTC < 10 ? "0" + mesUTC : mesUTC;

      return anioUTC + "-" + mesUTC + "-" + diaUTC;
    },
    formatMXN(cadena) {
      return cadena.toLocaleString("es-MX", {
        style: "currency",
        currency: "MXN",
      });
    },
  },
};
</script>

<style>
.fix-width-column{
    min-width: 250px !important;
}
</style>