<template>
  <v-card class="rounded-lg elevation-0" width="100%">
    <v-data-table :headers="headers" :items="pases" :search="search" no-data-text="No se han registrado pases de salida"
      loading-text="Cargando información..." :loading="loadingPasses" :footer-props="{
      'items-per-page-text': 'Elementos por página',
      'pageText': '{0}-{1} de {2}',
    }" style="background-color:#ECEFF1" :single-expand="true" :expanded.sync="expanded" show-expand>
      <template v-slot:top>
        <div style="display: flex; justify-content: space-between;">
          <v-text-field v-model="search" outlined dense clearable class="rounded-xl" style="max-width: 300px;"
            prepend-inner-icon="mdi-table-search" placeholder="Buscar pase..."></v-text-field>
          <div>
            <v-tooltip bottom color="blue lighten-4">
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="blue lighten-4" small fab depressed class="ml-2" v-bind="attrs" v-on="on"
                  @click="getPasesPorEmpleado()">
                  <v-icon>mdi-reload</v-icon>
                </v-btn>
              </template>
              <label class="text-uppercase font-weight-bold primary--text">Actualizar tabla</label>
            </v-tooltip>
          </div>
        </div>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <!-- {{ item.material }} -->
          <v-card class="rounded-lg pa-5 my-2">
            <div class="pt-1">
              <label class="overline"> <strong>unidad de medida: </strong> {{ item.descMedida }}</label>
            </div>
            <v-simple-table dense>
              <thead>
                <tr>
                  <th class="header-material-table overline font-weight-bold white--text">cantidad</th>
                  <th class="header-material-table overline font-weight-bold white--text">unidad</th>
                  <th class="header-material-table overline font-weight-bold white--text">codigo</th>
                  <th class="header-material-table overline font-weight-bold white--text">descripción</th>
                  <th class="header-material-table overline font-weight-bold white--text">referencia</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(m, index) in item.material" :key="index">
                  <td>{{ m.cantidad }}</td>
                  <td>{{ m.unidad }}</td>
                  <td>{{ m.codigo }}</td>
                  <td>{{ m.descripcion }}</td>
                  <td>{{ m.num_referencia_OT_WMS }}</td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card>
        </td>
      </template>
      <template v-slot:item.estatus="{ item }">
        <v-chip small :class="getClass(item.estatus)">
          {{ getEstatusText(item.estatus) }}
        </v-chip>
      </template>
      <template v-slot:item.fecha_solicitud="{ item }">
        {{ ![null, undefined].includes(item.fecha_solicitud) ? fixDate(item.fecha_solicitud.date.substring(0, 10)) + ' '
      +
      item.fecha_solicitud.date.substring(11, 16) : '' }}
      </template>
      <template v-slot:item.actions="{ item }">
        <v-tooltip top color="cyan darken-1">
          <template v-slot:activator="{ on, attrs }">
            <v-btn small class="rounded-pill" v-if="item.estatus === 0" color="cyan darken-1" dark
              :loading="item.id == idAux && loadingBtnNofify ? true : false" v-bind="attrs" v-on="on"
              @click="idAux = item.id; loadingBtnNofify = true; sendMail(item.id)">
              <v-icon left small>
                mdi-bell-ring
              </v-icon>
              Notificar
            </v-btn>
          </template>
          <span>Reenviar notificación</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import axios from "axios";
import { formatDate } from "../../../../../store/Funciones/funciones";
export default {
  name: "pases-de-salida-creados",
  data() {
    return {
      headers: [
        {
          text: "ID",
          value: "id",
          class:
            "overline font-weight-bold primary white--text rounded-left-top-table-lg",
        },
        {
          text: "Nombre",
          value: "nombre",
          class: "overline font-weight-bold primary white--text",
        },
        {
          text: "Compañía",
          value: "compania",
          class: "overline font-weight-bold primary white--text",
        },
        {
          text: "Teléfono",
          value: "telefono",
          class: "overline font-weight-bold primary white--text",
        },
        {
          text: "Solicitud",
          value: "fecha_solicitud",
          class: "overline font-weight-bold primary white--text",
        },
        {
          text: "Estatus",
          value: "estatus",
          align: "center",
          class: "overline font-weight-bold primary white--text",
        },
        {
          text: "Acciones",
          value: "actions",
          align: "center",
          class: "overline font-weight-bold primary white--text",
        },
        {
          text: "",
          value: "data-table-expand",
          class:
            "overline font-weight-bold primary white--text rounded-right-top-table-lg",
        },
      ],
      pases: [],
      expanded: [],
      loadingPasses: true,
      loadingBtnNofify: false,
      idAux: 0,
      search: null,
    };
  },
  async mounted() {
    this.getPasesPorEmpleado();
  },
  methods: {
    async getPasesPorEmpleado() {
      this.loadingPasses = true;
      this.pases = [];

      let params = {
        Opcion: 1,
        token: localStorage.getItem("token"),
      };
      axios
        .post(
          `${this.$store.getters.getHost}/SeguridadPatrimonial/ws-pases-de-salida.php`,
          params
        )
        .then((response) => {
          if (response.data.status == "OK") {
            this.pases = response.data.passes;
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.loadingPasses = false;
        });
    },
    getClass(estatus) {
      if (estatus === 0) {
        return "yellow accent-4 black--text";
      } else if (estatus === 1) {
        return "teal darken-1 white--text";
      } else if (estatus === 2) {
        return "pink darken-1 white--text";
      } else if (estatus === 3) {
        return "light-green darken-1 white--text";
      } else if (estatus === 4) {
        return "indigo darken-1 white--text";
      } else {
        return "grey";
      }
    },
    getEstatusText(estatus) {
      if (estatus === 0) {
        return "Pendiente";
      } else if (estatus === 1) {
        return "Autorizado";
      } else if (estatus === 2) {
        return "Rechazado";
      } else if (estatus === 3) {
        return "Liberado";
      } else if (estatus === 4) {
        return "En espera";
      } else {
        return "No definido";
      }
    },

    sendMail(idPase) {
      let params = {
        Opcion: 7,
        token: localStorage.getItem("token"),
        idPase: idPase
      };
      axios
        .post(
          `${this.$store.getters.getHost}/SeguridadPatrimonial/ws-pases-de-salida.php`,
          params
        )
        .then((response) => {
          if (response.data.status == "OK") {
            if (response.data.respuesta.status_tran == "OK") {
              alert(response.data.respuesta.message)
            } else {
              alert(response.data.respuesta.message)
            }
          }
        })
        .catch((error) => {
          alert(error);
        })
        .finally(() => {
          this.loadingBtnNofify = false;
        });
    },
    fixDate(date) {
      return formatDate(date)
    },
  },
};
</script>

<style>
.rounded-left-top-table-lg {
  border-top-left-radius: 8px;
}

.rounded-right-top-table-lg {
  border-top-right-radius: 8px;
}

.header-material-table {
  background-color: #A8A8A7;
}
</style>
