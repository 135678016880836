<template>
  <v-dialog
    v-model="showCreatePositionDialog"
    class="dialog-to-left-bottom"
    persistent
    max-width="400"
    content-class="fix-border-dialog"
    style=""
  >
    <v-card class="neumorphism-card-reclutamiento">
      <v-card-title class="text-subtitle-2 primary white--text">
        Crear un nuevo puesto.
        <v-spacer></v-spacer>
        <v-icon small dark @click="$emit('hideCreatePositionDialog', 0)"
          >mdi-close
        </v-icon>
      </v-card-title>
      <v-card-text class="pt-2 pb-0">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-text-field
            v-model="jobcode"
            class="rounded-lg"
            :disabled="loadingCreation"
            dense
            required
            :rules="rules"
            outlined
            label="Jobcode"
          ></v-text-field>
          <v-text-field
            v-model="idProductivity"
            class="rounded-lg"
            :disabled="loadingCreation"
            dense
            required
            :rules="rules"
            outlined
            label="ID Productivity"
          ></v-text-field>
          <v-text-field
            v-model="idMercer"
            class="rounded-lg"
            :disabled="loadingCreation"
            dense
            required
            :rules="rules"
            outlined
            label="ID Mercer"
          ></v-text-field>
          <v-text-field
            v-model="sueldoContratacion"
            class="rounded-lg"
            :disabled="loadingCreation"
            dense
            required
            :rules="rolesMoneda"
            outlined
            label="Salario de contratación"
          ></v-text-field>
          <v-text-field
            v-model="sueldoMinimo"
            class="rounded-lg"
            :disabled="loadingCreation"
            dense
            required
            :rules="rolesMoneda"
            outlined
            label="Salario mínimo"
          ></v-text-field>
          <v-text-field
            v-model="sueldoMaximo"
            class="rounded-lg"
            :disabled="loadingCreation"
            dense
            required
            :rules="rolesMoneda"
            outlined
            label="Salario máximo"
          ></v-text-field>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn
          class="rounded-pill"
          block
          depressed
          color="teal darken-1"
          dark
          :loading="loadingCreation"
          @click="
            loadingCreation = true;
            setPositionDataAndCreateNewPosition();
          "
          >guardar</v-btn
        >
      </v-card-actions>
    </v-card>
    <SnackBar
      :muestraBarra="muestraBarra"
      :color="colorBarra"
      :texto="textoBarra"
      @cierraBarra="muestraBarra = false"
    />
  </v-dialog>
</template>

<script>
import axios from "axios";
import SnackBar from "../../General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue";
import { deleteToken } from "../../../../store/Funciones/funciones";

export default {
  name: "crea-puesto-dialog",
  props: ["showCreatePositionDialog", "idNuevoPuesto"],
  components: { SnackBar },
  data() {
    return {
      valid: true,
      muestraBarra: false,
      loadingCreation: false,
      colorBarra: "",
      textoBarra: "",
      jobcode: "",
      idProductivity: "",
      idMercer: "",
      sueldoContratacion: "",
      sueldoMaximo: "",
      sueldoMinimo: "",
      rules: [(v) => !!v || "Campo requiredo"],
      rolesMoneda: [
        (v) => !!v || "Campo requiredo",
        (v) =>
          /^(\$)?(\d{1,3}((,\d{3})+|\d*)(\.\d{2})?)$/.test(v) ||
          "Formato invalido",
      ],
    };
  },
  methods: {
    setPositionDataAndCreateNewPosition() {
      let params = {
        Opcion: 26,
        token: localStorage.getItem("token"),
        idNuevoPuesto: this.idNuevoPuesto,
        jobcode: this.jobcode,
        idProductivity: this.idProductivity,
        idMercer: this.idMercer,
        sueldoContratacion: this.sueldoContratacion,
        sueldoMaximo: this.sueldoMaximo,
        sueldoMinimo: this.sueldoMinimo,
      };
      if (this.$refs.form.validate()) {
        axios
          .post(
            `${this.$store.getters.getHost}/Reclutamiento/ws-admin-reclu.php`,
            params
          )
          .then((response) => {
            this.muestraLoading = false;
            if (response.data.status == "EXPSESSION") {
              deleteToken();
            } else if (response.data.status == "OK") {
              this.muestraSnackBar(
                response.data.respuesta.detalles,
                "teal darken-1"
              );
            } else {
              this.muestraSnackBar(
                "Ocurrió un error. No se pudo crear. BE",
                "pink darken-3"
              );
            }
          })
          .catch((error) => {
            this.muestraSnackBar(error, "pink darken-3");
          });
      }
    },
    muestraSnackBar(text, color) {
      this.muestraBarra = true;
      this.colorBarra = color;
      this.textoBarra = text;
    },
  },
};
</script>
