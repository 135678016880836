<template>
  <v-dialog
    v-model="muestraBuscador"
    max-width="800"
    content-class="fix-border-dialog"
    persistent
  >
    <v-card class="rounded-lg backgroun-degraded-reclutamientos">
      <v-card-title class="primary white--text subtitle-2">
        Creación de usuarios para candidatos.
        <v-spacer></v-spacer>
        <v-icon dark @click="$emit('ocultaBuscador', modificaciones)">
          mdi-close
        </v-icon>
      </v-card-title>
      <v-card-text>
        <div class="pt-4 center-item-in-div">
          <v-autocomplete
            v-model="candidateSelected"
            class="rounded-xl"
            outlined
            flat
            :items="usuarios"
            item-text="Candidato"
            placeholder="Busca por nombre del candidato..."
            v-on:change="selectUser"
            :loading="loadingUsers"
          >
          </v-autocomplete>
        </div>
        <v-alert
          v-model="muestraBarra"
          outlined
          class="rounded-lg"
          dense
          :type="colorAlert"
          text
        >
          {{ msg }}
        </v-alert>
        <div v-if="usuarioSeleccionado" class="center-item-in-div">
          <!-- {{ usuarioSeleccionado }} -->
          <v-card
            class="backgroun-degraded-reclutamientos rounded-xl"
            elevation="15"
            width="350"
          >
            <v-card-subtitle class="pb-0">{{
              usuarioSeleccionado.Candidato
            }}</v-card-subtitle>
            <v-card-title class="subtitle-1 pt-0 font-weight-bold">{{
              usuarioSeleccionado.Vacante
            }}</v-card-title>
            <v-card-text class="center-item-in-div-column">
              <div>
                {{ usuarioSeleccionado.Mail }}
              </div>
              <div>
                <strong>Estatus:</strong>
                {{ usuarioSeleccionado.EstatusDesc.replace("_", " ") }}
              </div>
              <div>
                <strong>Termino de proceso:</strong>
                {{
                  fixFormatDate(
                    usuarioSeleccionado.Fecha_TerminoProceso.date.substring(
                      0,
                      10
                    )
                  )
                }}
              </div>
            </v-card-text>
            <v-card-text>
              <v-btn
                dark
                block
                depressed
                color="teal darken-1"
                class="rounded-lg"
                :loading="loadingCreateUser"
                @click="
                  loadingCreateUser = true;
                  saveUser();
                "
              >
                Crear usuario
              </v-btn>
            </v-card-text>
          </v-card>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
import {
  deleteToken,
  formatDate,
} from "../../../../../store/Funciones/funciones";

export default {
  name: "buscador-candidatos-usuarios-reclu",
  props: ["muestraBuscador"],
  data() {
    return {
      usuarios: [],
      loadingUsers: true,
      loadingCreateUser: false,
      muestraBarra: false,
      modificaciones: false,
      colorAlert: "error",
      msg: "",
      search: "",
      candidateSelected: "",
      usuarioSeleccionado: null,
    };
  },
  mounted() {
    this.getUsuarios();
  },
  methods: {
    getUsuarios() {
      this.usuarios.length = 0;
      let params = {
        Opcion: 16,
        token: localStorage.getItem("token"),
      };
      axios
        .post(
          `${this.$store.getters.getHost}/Reclutamiento/ws-admin-candidatos.php`,
          params
        )
        .then((response) => {
          if (response.data.status == "EXPRESSION") {
            deleteToken();
          } else if (response.data.status == "OK") {
            this.usuarios = response.data.users;
            this.loadingUsers = false;
          }
        });
    },
    selectUser() {
      this.usuarioSeleccionado =
        this.usuarios.find((u) => u.Candidato === this.candidateSelected) ||
        null;
    },
    saveUser() {
      let params = {
        Opcion: 17,
        token: localStorage.getItem("token"),
        nombre: this.usuarioSeleccionado.Candidato,
        correo: this.usuarioSeleccionado.Mail,
        idCandidato: this.usuarioSeleccionado.id,
      };
      axios
        .post(
          `${this.$store.getters.getHost}/Reclutamiento/ws-admin-candidatos.php`,
          params
        )
        .then((response) => {
          this.loadingCreateUser = false;
          if (response.data.status == "EXPRESSION") {
            deleteToken();
          } else if (response.data.status == "OK") {
            if (
              response.data.users.tipo == 0 ||
              response.data.users.tipo == 1
            ) {
              this.colorAlert = "error";
              this.msg = response.data.users.mensaje;
              this.muestraBarra = true;
            } else {
              this.colorAlert = "success";
              this.msg = response.data.users.mensaje;
              this.muestraBarra = true;
              this.usuarioSeleccionado = null;
              this.modificaciones = true;
            }
          }
        })
        .catch((e) => {
          this.colorAlert = "error";
          this.msg = e;
          this.muestraBarra = true;
        });
    },
    fixFormatDate(date) {
      return formatDate(date);
    },
  },
};
</script>
