<template>
  <div>
    <v-dialog
      v-model="dialog"
      max-width="500px"
      persistent
      scrollable
      :loading="loadingBotonGuardar"
    >
      <v-card class="rounded-lg">
        <v-card-title class="primary white--text subtitle-2">
          Registro de Tiempo por Tiempo
          <v-spacer></v-spacer>
          <v-icon dark @click="$emit('cierra', 0)">mdi-close</v-icon>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-container fluid>
            <p class="text-center" style="color: darkred">
              Recuerda que el tiempo por tiempo solo aplica para jornadas
              completas.
            </p>
            <v-alert
              :value="alert"
              :color="colorAlert"
              dark
              border="top"
              :icon="iconAlert"
              transition="scale-transition"
            >
              <v-row align="center">
                <v-col class="grow">
                  {{ respuestaData.mensaje }}
                </v-col>
                <v-col class="shrink">
                  <v-row align="center">
                    <v-col cols="12" sm="6">
                      <v-btn small dark @click="respuestaApiValidacion()">
                        {{ textoBotonAlert }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-alert>

            <v-row>
              <v-col cols="12">
                <p class="font-weight-bold">Selecciona el día a descansar:</p>
                <v-menu
                  ref="menu"
                  v-model="fechaDescansar"
                  :close-on-content-click="false"
                  :return-value.sync="textFechaDescansar"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="textFechaDescansarS"
                      label="Selecciona una fecha."
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="textFechaDescansar"
                    scrollable
                    :disabled="loadingBotonGuardar"
                  >
                    <v-btn text color="primary" @click="fechaDescansar = false"
                      >Cancelar</v-btn
                    >
                    <v-btn
                      text
                      color="primary"
                      @click="formatoFechaDescansar()"
                    >
                      Guardar
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <p class="font-weight-bold">
                  Selecciona el día que vas a trabajar:
                </p>
                <!-- <v-menu
              v-model="fechaTrabajar"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="textFechaTrabajar"
                  label="Día a trabajar"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  :disabled="loadingBotonGuardar"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="textFechaTrabajar"
                @input="fechaTrabajar = false"
              ></v-date-picker>
            </v-menu> -->
                <v-menu
                  ref="menu"
                  v-model="fechaTrabajar"
                  :close-on-content-click="false"
                  :return-value.sync="textFechaTrabajar"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="textFechaTrabajarS"
                      label="Selecciona una fecha."
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="textFechaTrabajar"
                    scrollable
                    :disabled="loadingBotonGuardar"
                  >
                    <v-btn text color="primary" @click="fechaTrabajar = false"
                      >Cancelar</v-btn
                    >
                    <v-btn text color="primary" @click="formatoFechaTrabajar()">
                      Guardar
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="rounded-lg"
            color="teal darken-1"
            dark
            elevation="2"
            :loading="loadingBotonGuardar"
            @click="verifyData()"
          >
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-sheet id="muestra-incidencias-cargadas">
      <muestra-incidencias-cargadas
        :muestraDetalles="muestraDetalles"
        :datosTabla="respuestaData.data"
        :alertErrorDosDias="alertErrorDosDias"
        @cierraTabla="muestraDetalles = false"
      />
    </v-sheet>

    <v-sheet id="snack-bar-error-incidencias">
      <snack-bar-error-incidencias
        :muestraBarra="muestraBarra"
        :texto="textoBarra"
        :color="colorBarra"
        @cierraBarra="muestraBarra = false"
      />
    </v-sheet>
  </div>
</template>

<script>
import axios from "axios";
import MuestraIncidenciasCargadas from "./componentsExtra/MuestraIncidenciasCargadas.vue";
import SnackBarErrorIncidencias from "./componentsExtra/SnackBarErrorIncidencias.vue";

export default {
  name: "incidencia-tiempo-por-tiempo",
  data: () => ({
    textFechaDescansar: "",
    fechaDescansar: false,
    textFechaTrabajar: "",
    fechaTrabajar: false,
    respuestaData: [],
    alert: false,
    colorAlert: "pink",
    iconAlert: "mdi-alert-circle",
    loadingBotonGuardar: false,
    alertErrorDosDias: false,
    textoBotonAlert: "",
    muestraDetalles: false,
    muestraBarra: false,
    textoBarra: "",
    colorBarra: "error",
    textFechaDescansarS: "",
    textFechaTrabajarS: "",
  }),
  components: {
    MuestraIncidenciasCargadas,
    SnackBarErrorIncidencias,
  },
  props: ["dialog", "esAdmin", "empleado"],
  created() {
    this.textFechaDescansarS = this.setFechaManana();
    this.textFechaTrabajarS = this.setFechaManana();
  },
  methods: {
    formatoFechaDescansar() {
      this.textFechaDescansarS = this.formatDate(this.textFechaDescansar);
      this.fechaDescansar = false;
    },
    formatoFechaTrabajar() {
      this.textFechaTrabajarS = this.formatDate(this.textFechaTrabajar);
      this.fechaTrabajar = false;
    },
    formatDate(date) {
      if (!date) return null;

      let [year, month, day] = date.split("-");
      switch (month) {
        case "01":
          month = "ENE";
          break;
        case "02":
          month = "FEB";
          break;
        case "03":
          month = "MAR";
          break;
        case "04":
          month = "ABR";
          break;
        case "05":
          month = "MAY";
          break;
        case "06":
          month = "JUN";
          break;
        case "07":
          month = "JUL";
          break;
        case "08":
          month = "AGO";
          break;
        case "09":
          month = "SEP";
          break;
        case "10":
          month = "OCT";
          break;
        case "11":
          month = "NOV";
          break;
        case "12":
          month = "DIC";
          break;
      }
      return `${day}/${month}/${year}`;
    },
    formatDateISO(date) {
      if (!date) return null;

      let [day, month, year] = date.split("/");
      switch (month) {
        case "ENE":
          month = "01";
          break;
        case "FEB":
          month = "02";
          break;
        case "MAR":
          month = "03";
          break;
        case "ABR":
          month = "04";
          break;
        case "MAY":
          month = "05";
          break;
        case "JUN":
          month = "06";
          break;
        case "JUL":
          month = "07";
          break;
        case "AGO":
          month = "08";
          break;
        case "SEP":
          month = "09";
          break;
        case "OCT":
          month = "10";
          break;
        case "NOV":
          month = "11";
          break;
        case "DIC":
          month = "12";
          break;
      }
      return `${year}-${month}-${day}`;
    },
    setFechaManana() {
      const today = new Date();
      let tomorrow = new Date(today);
      tomorrow.setDate(tomorrow.getDate() + 1);
      return this.formatDate(tomorrow.toISOString().substring(0, 10));
    },
    respuestaApiValidacion() {
      if (this.textoBotonAlert == "Ver más") {
        this.alert = false;
        this.muestraDetalles = true;
      } else {
        this.alert = false;
      }
      this.loadingBotonGuardar = false;
    },
    verifyData() {
      this.loadingBotonGuardar = true;
      let empleado = null;
      if(this.esAdmin === true){
        empleado = this.empleado;
      }

      const params = {
        Opcion: 1,
        token: localStorage.getItem("token"),
        descanso: this.formatDateISO(this.textFechaDescansarS),
        trabajo: this.formatDateISO(this.textFechaTrabajarS),
        esAdmin: this.esAdmin,
        empleado: empleado
      };

      // console.table(params)

      axios
        .post(
          `${this.$store.getters.getHost}/Incidencias/ws-incidencias-tiempo-por-tiempo.php`,
          params
        )
        .then((response) => {
          if (response.data.status == "OK") {
            var data = response.data.tiempoPorTiempo;
            this.respuestaData = data;
            //(this.respuestaData);
            this.validaRespuesta();
          } else {
            this.loadingBotonGuardar = false;
            alert(response.data.status);
          }
        })
        .catch((error) => {
          this.textoBarra = "Ocurrió un error." + error;
          this.muestraBarra = true;
          // //("hola " + error);
          location.reload();
        });
    },
    validaRespuesta() {
      if (this.respuestaData["opc"] != 4) {
        this.colorAlert = "pink";
        if (this.respuestaData["opc"] == 2) {
          this.textoBotonAlert = "Ver más";
        } else {
          this.textoBotonAlert = "Cerrar";
        }
        this.alert = true;
      } else {
        this.colorAlert = "success";
        this.textoBotonAlert = "Cerrar";
        this.alert = true;
        this.textFechaDescansarS = this.setFechaManana();
        this.textFechaTrabajarS = this.setFechaManana();
        // this.textoBarra = "Incidencia registrada con éxito";
        // this.colorBarra = "primary";
        this.$emit('cierra', 1);
        // this.muestraBarra = true;
      }
    },
  },
};
</script>
