import calendarioPrenomina from './components/calendarioPrenomina.vue'
import generaReportesCaja from './components/generaReportesCaja.vue'
import diasParaPrenomina from './components/diasParaPrenomina.vue'
import tablaEmpleadosCaja from './components/tablaEmpleadosCaja.vue'
import accionesDeAdministracionDeCaja from './components/accionesDeAdministracionDeCaja.vue'
export default {
    name: 'adm-caja-ahorro-general',
    components: { calendarioPrenomina, generaReportesCaja, diasParaPrenomina, tablaEmpleadosCaja, accionesDeAdministracionDeCaja },
    props: [],
    data() {
        return {

        }
    },
    computed: {

    },
    mounted() {

    },
    methods: {

    }
}