var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-4"},[_c('v-data-table',{staticClass:"elevation-10 background-glass-effect-new-positions-request rounded-lg",attrs:{"headers":_vm.headers,"items":_vm.ofertasPendientes,"search":_vm.search,"loading":_vm.loadingData,"height":"100%","loading-text":"Cargando contentenido...","footer-props":{
      'items-per-page-options': [10, 20, 30, 100],
      'items-per-page-text': 'Elementos por página',
    },"no-data-text":"No hay registros","header-props":{
      'short-by-text': 'Ordenar por',
    },"item-key":"id","dense":""},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":"","small":"","color":"green darken-2"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"depressed":"","color":"cyan darken-2","small":"","dark":"","to":"../Nominas/Compensaciones/ofertasEconomicas"}},'v-btn',attrs,false),on),[_vm._v(" crear oferta ["+_vm._s(item.id)+"] "),_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-arrow-right ")])],1)]}}],null,true)},[_c('p',{staticClass:"text-uppercase mb-0 font-weight-bold body-2"},[_vm._v(" ir ")])])]}}])}),(_vm.muestraBarra)?_c('SnackBar',{attrs:{"muestraBarra":_vm.muestraBarra,"color":_vm.colorBarra,"texto":_vm.textoBarra},on:{"cierraBarra":function($event){_vm.muestraBarra = false}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }