<template>
  <v-dialog v-model="verHistorico" persistent content-class="fix-border-dialog" scrollable max-width="880px">
    <v-card class="rounded-xl">
      <v-card-title class="primary">
        <label class="overline font-weight-bold white--text">
          Historico de la relación empleado - responsable
        </label>
        <v-spacer></v-spacer>
        <v-icon dark @click="$emit('ocultaHistorico')">mdi-close</v-icon>
      </v-card-title>
      <v-card-text>
        <v-data-table
          dense
          :items="relaciones"
          :headers="headers"
          :search="search"
          no-data-text="No se han registrado relaciones."
          class="rounded-lg rounded-t-0"
          loading-text="Cargando información..."
          :loading="loadingHistoric"
          :footer-props="{
            'items-per-page-text': 'Elementos por página',
            pageText: '{0}-{1} de {2}',
          }"
          style="background-color:#ECEFF1"
        >
        <template v-slot:top>
            <div class="py-2 px-5">
            <v-text-field v-model="search" class="rounded-lg" dense placeholder="Buscar..." outlined prepend-inner-icon="mdi-magnify"></v-text-field>
        </div>
        </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios'
export default {
  name: "ver-historico",
  props: ["verHistorico"],
  data() {
    return {
        loadingHistoric: true,
        headers:[
            {value:"numResponsable",text:"# Responsable",class:"text-uppercase font-weight-bold blue-grey--text text--darken-2"},
            {value:"nombreResponsable",text:"Responsable",class:"text-uppercase font-weight-bold blue-grey--text text--darken-2"},
            {value:"numEmpleado",text:"# Empleado",class:"text-uppercase font-weight-bold blue-grey--text text--darken-2"},
            {value:"nombreEmpleado",text:"Empleado",class:"text-uppercase font-weight-bold blue-grey--text text--darken-2"},
        ],
        relaciones: [],
        search: ""
    };
  },
  async mounted() {
    this.relaciones = await this.getHistorico()
  },
  methods: {
    async getHistorico() {
      const params = {
        Opcion: 19,
        token: localStorage.getItem("token"),
      };
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${this.$store.getters.getHost}/RelacionesLaborales/RelacionEmpleadoResponsable/ws-empleado-responsable.php`,
            params
          )
          .then((response) => {
            if (response.data.status == "OK") {
              resolve(response.data.respuesta);
            }
          })
          .catch((error) => {
            reject(error);
          })
          .finally(() => {
            this.loadingHistoric = false;
          });
      });
    },
  },
};
</script>
