<template>
  <v-data-table
    :items="solicitudes"
    :headers="headerSolisitudes"
    :loading="loadingTable"
    class="elevation-0 rounded-lg"
    no-data-text="No hay reporte."
    loading-text="Cargando información..."
    :footer-props="{
      'items-per-page-text': 'Elementos por página',
      pageText: '{0}-{1} de {2}',
    }"
    :single-expand="false"
    :expanded.sync="expanded"
    :search="search"
    item-key="ID"
    show-expand
  >
    <template v-slot:top>
      <v-sheet class="pt-3 px-2 rounded-lg rounded-b-0 primary" color="primary">
        <v-text-field
          v-model="search"
          placeholder="Buscar..."
          prepend-inner-icon="mdi-magnify"
          dense
          solo-inverted
          dark
          flat
        >
        </v-text-field>
      </v-sheet>
    </template>
    <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length" v-if="expanded.length">
        <MuestraInfoSolicitudTE :idSolicitud="item.ID" />
      </td>
    </template>
  </v-data-table>
</template>

<script>
import axios from "axios";
import MuestraInfoSolicitudTE from "./MuestraInfoSolicitudTE";

export default {
  props: ["tipoFiltro", "semana", "mes", "anio"],
  components: { MuestraInfoSolicitudTE },
  data() {
    return {
      solicitudes: [],
      expanded: [],
      headerSolisitudes: [
        { text: "", value: "data-table-expand", class: "primary" },
        {
          text: "ID",
          value: "ID",
          align: "center",
          class: "primary white--text overline font-weight-bold",
        },
        {
          text: "MOTIVO",
          value: "JUSTIFICACION",
          align: "center",
          class: "primary white--text overline font-weight-bold",
        },
        {
          text: "FECHA SOLICITUD",
          value: "FECHA_CREACION",
          align: "center",
          class: "primary white--text overline font-weight-bold",
        },
        {
          text: "HORAS SOLICITUD",
          value: "HORA_CREACION",
          align: "center",
          class: "primary white--text overline font-weight-bold",
        },
        {
          text: "# SOLICITANTE",
          value: "USUARIO_CREACION",
          align: "center",
          class: "primary white--text overline font-weight-bold",
        },
        {
          text: "HORAS",
          value: "HORAS_TOTALES",
          align: "center",
          class: "primary white--text overline font-weight-bold",
        },
        {
          text: "ESTATUS",
          value: "ESTATUS",
          align: "center",
          class: "primary white--text overline font-weight-bold",
        },
      ],
      loadingTable: true,
      search: "",
    };
  },
  async mounted() {
    this.solicitudes = await this.getSolicitudes();
  },
  watch: {
    async tipoFiltro() {
      this.loadingTable = true;
      this.solicitudes = await this.getSolicitudes();
    },
    async semana() {
      this.loadingTable = true;
      this.solicitudes = await this.getSolicitudes();
    },
    async mes() {
      this.loadingTable = true;
      this.solicitudes = await this.getSolicitudes();
    },
  },
  methods: {
    getSolicitudes() {
      let params = {
        Opcion: 5,
        semana: this.tipoFiltro === "semana" ? this.semana : null,
        mes: this.tipoFiltro === "mes" ? this.mes : null,
        anio: this.anio,
        pantalla: 2,
        token: localStorage.getItem("token"),
      };
      return new Promise((res) => {
        axios
          .post(
            `${this.$store.getters.getHost}/general/ws-TiempoExtra.php`,
            params
          )
          .then((response) => {
            var data = response.data.infosolis;
            if (data) {
              res(data);
            } else {
              res([]);
            }
          })
          .finally(() => {
            this.loadingTable = false;
          });
      });
    },
  },
};
</script>
