import MuestraBeneficios from './components/MuestraBeneficios.vue'

export default {
  name: 'solicitudes-beneficios-flexibles',
  components: { MuestraBeneficios },
  props: [],
  data() {
    return {

    }
  },
  computed: {

  },
  mounted() {

  },
  methods: {

  }
}


