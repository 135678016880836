<template>
  <v-card color="blue-grey lighten-5" class="rounded-lg px-6 pb-6 pt-3 mb-5">
    <div>
      <label
        class="subtitle-1 text-uppercase font-weight-bold blue-grey--text text--darken-2"
      >
        Recibos zentric
      </label>
    </div>
    <div class="center-item-in-div">
      <div style="width: 33%;" class="px-2">
        <label class="overline">Nómina</label>
        <v-select
          v-model="nominaSeleccionada"
          dense
          :items="nominas"
          v-on:change="changePeriodos"
          item-value="value"
          item-text="text"
          class="rounded-lg"
          outlined
          placeholder="Selecciona la nómina..."
        ></v-select>
      </div>
      <div style="width: 33%;" class="px-2">
        <label class="overline">Periodo</label>
        <v-select
          v-model="periodoSeleccionado"
          :items="periodos"
          :loading="loadingPeriodos"
          item-value="period"
          item-text="period"
          dense
          class="rounded-lg"
          outlined
          :disabled="periodos.length === 0 ? true : false"
          placeholder="Selecciona el periodo..."
        ></v-select>
      </div>
      <div style="width: 33%;" class="px-2">
        <label class="overline">Emplid</label>
        <v-autocomplete
          v-model="pks"
          dense
          small-chips
          :items="empleados"
          item-value="idZentric"
          item-text="EMP_NOMEMP"
          multiple
          class="rounded-lg"
          outlined
          :loading="loadingEmpleados"
          placeholder="Ingresa el emplid ..."
        ></v-autocomplete>
      </div>
    </div>
    <div style="display: flex; justify-content: end;">
      <v-btn
        color="primary"
        depressed
        class="rounded-lg"
        :disabled="!descargaPosible"
        @click="muestraLoading = true; getRecibosZentric();"
      >
        <v-icon left>mdi-file-download</v-icon>
        descargar prerecibos
      </v-btn>
    </div>

    <loadingBar text="Cargando..." :dialog="muestraLoading"/>
  </v-card>
</template>

<script>
import { deleteToken } from "../../../../../store/Funciones/funciones";
import axios from "axios";
import loadingBar from "../../../DesarrolloOrganizacional/components/loadingBar.vue" 
export default {

  components: {loadingBar},
  data() {
    return {
      nominas: [
        { value: 5704, text: "Semanal" },
        { value: 5709, text: "Quincenal" },
        { value: 8240, text: "Comisionistas" },
      ],
      payroll: null,
      period: null,
      year: 2024,
      pks: [],
      empleados: [],
      periodos: [],
      muestraLoading: false,
      loadingEmpleados: true,
      loadingPeriodos: false,
      periodoSeleccionado: null,
      nominaSeleccionada: null,
      tokenZentric: null,
    };
  },
  computed: {
    descargaPosible() {
      return (
        this.nominaSeleccionada != null &&
        this.periodoSeleccionado != null &&
        this.pks.length > 0
      );
    },
  },
  async mounted() {
    this.empleados = await this.getEmpleados();
  },
  methods: {
    getEmpleados() {
      const headers = {
        Authorization: localStorage.getItem("token"),
      };

      return new Promise((res) => {
        axios
          .get(`${this.$store.getters.getHostNode}/api/empleados-recibos`, {
            headers,
          })
          .then((response) => {
            if (response.data.status == "EXPSESSION") {
              deleteToken();
            } else {
              res(response.data.data);
            }
          })
          .finally(() => {
            this.loadingEmpleados = false;
          });
      });
    },
    async changePeriodos() {
      this.loadingPeriodos = true;
      this.periodos = await this.getPeriodosZentric();
    },
    getPeriodosZentric() {
      const params = {
        grupoPago: this.nominaSeleccionada,
      };

      return new Promise((res) => {
        axios
          .post(`${this.$store.getters.getHostNode}/api/getPeriodosGp`, params)
          .then((response) => {
            if (
              response.data.status == "EXPSESSION" ||
              response.data.message === "Token inválido"
            ) {
              deleteToken();
            } else {
              res(response.data.Periodos);
            }
          })
          .finally(() => {
            this.loadingPeriodos = false;
          });
      });
    },
    async getRecibosZentric() {
      this.tokenZentric = await this.getTokenZentric();
      const headers = {
        Authorization: this.tokenZentric,
      };
      const params = {
        payroll: this.nominaSeleccionada,
        year: 2024,
        period: this.periodoSeleccionado,
        pks: this.pks,
      };
      axios({
        url: "https://api.zentric.mx/stampings/generate-prereceipt/",
        method: "POST",
        headers: headers,
        responseType: "arraybuffer",
        data: params,
      })
        .then((response) => {
          const blob = new Blob([response.data], { type: this.pks.length > 1 ? "application/zip" : "application/pdf" });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          let name = "nomina-" + this.periodoSeleccionado;// + this.pks.length > 1 ? ".zip" : ".pdf"
          a.download = name;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
          
        })
        .catch((error) => {
          alert("Error en descarga, verifica que los empleados agregados pertenezcan al periodo seleccionado.");
          console.error("Error al obtener el documento:", error);
        })
        .finally(() => {
            this.muestraLoading = false;
        });
    },
    getTokenZentric() {
      const headers = {
        Authorization: localStorage.getItem("token"),
      };

      return new Promise((res) => {
        axios
          .get(`${this.$store.getters.getHostNode}/api/tokenZentric`, {
            headers,
          })
          .then((response) => {
            if (response.data.status == "EXPSESSION") {
              deleteToken();
            } else {
              res(response.data.token);
            }
          })
          .finally(() => {
            this.loadingEmpleados = false;
          });
      });
    },
  },
};
</script>
