import RepsePrincipal from './components/RepsePrincipal.vue'
export default {
  name: 'repse-usuarios',
  components: {RepsePrincipal},
  props: [],
  data () {
    return {

    }
  },
  computed: {

  },
  mounted () {

  },
  methods: {
    

  }
  
}


