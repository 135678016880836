<template>
  <div>
    <v-dialog v-model="dialog" max-width="450px" persistent scrollable>
      <v-card class="rounded-lg">
        <v-card-title class="primary white--text subtitle-1">
          <span>Registro de Flex Time</span>
          <v-spacer></v-spacer>
          <v-icon dark @click="$emit('cierra', 0)">mdi-close</v-icon>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-alert
            :value="alert"
            :color="colorAlert"
            dark
            border="top"
            :icon="iconAlert"
            transition="scale-transition"
          >
            <v-row align="center">
              <v-col class="grow">
                {{ respuestaTurno.mensaje }}
              </v-col>
              <v-col class="shrink">
                <v-row align="center">
                  <v-col cols="12" sm="6">
                    <v-btn small @click="getAccionAlert()">
                      {{ textoBotonAlert }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-alert>
          <p class="font-weight-bold mt-1 mb-0">Turno:</p>
          <v-select
            :items="horariosFlexTime"
            :loading="loadingWindow"
            label="Selecciona un turno"
            item-text="descripcion"
            item-value="IdNuevoTurno"
            dense
            outlined
            v-model="valSelect"
          ></v-select>

          <v-dialog
            ref="dialog"
            v-model="modalFechaInicio"
            :return-value.sync="fechaInicio"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="fechaInicioS"
                label="Día para comenzar el cambio de turno."
                prepend-icon="mdi-calendar"
                readonly
                dense
                outlined
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="fechaInicio" scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="modalFechaInicio = false">
                cancelar
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="cierraVentanaIngresaHoraInicio()"
              >
                guardar
              </v-btn>
            </v-date-picker>
          </v-dialog>

          <v-dialog
            ref="dialog"
            v-model="modalFechaFin"
            :return-value.sync="fechaFin"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="fechaFinS"
                label="Día para terminar el cambio de turno."
                prepend-icon="mdi-calendar"
                readonly
                dense
                outlined
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="fechaFin" scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="modalFechaFin = false">
                cancelar
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="cierraVentanaIngresaHoraFin()"
              >
                guardar
              </v-btn>
            </v-date-picker>
          </v-dialog>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="teal darken-1"
            class="rounded-lg"
            dark
            elevation="2"
            :loading="loadingBotonGuardar"
            @click="validaDatos()"
          >
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-sheet id="muestra-incidencias-cargadas">
      <muestra-incidencias-cargadas
        :muestraDetalles="muestraDetalles"
        :datosTabla="respuestaTurno.data"
        :alertErrorDosDias="alertErrorDosDias"
        @cierraTabla="muestraDetalles = false"
      />
    </v-sheet>

    <v-sheet id="snack-bar-error-incidencias">
      <snack-bar-error-incidencias
        :muestraBarra="muestraBarra"
        :texto="textoBarra"
        :color="colorBarra"
        @cierraBarra="muestraBarra = false"
      />
    </v-sheet>

    <v-sheet id="muestra-calendario-dias-seleccionados">
      <muestra-calendario-dias-seleccionados
        :muestraCalendario="muestraCalendario"
        :fechas="respuestaTurno.data"
        @cierraCalendario="muestraCalendario = false"
      />
    </v-sheet>
  </div>
</template>

<script>
import axios from "axios";
import MuestraIncidenciasCargadas from "./componentsExtra/MuestraIncidenciasCargadas.vue";
import SnackBarErrorIncidencias from "./componentsExtra/SnackBarErrorIncidencias.vue";
import MuestraCalendarioDiasSeleccionados from "./componentsExtra/MuestraCalendarioDiasSeleccionados.vue";

export default {
  name: "incidencia-flex-time",
  data: () => ({
    descTurno: [],
    horariosFlexTime: [],
    turno_desc: "",
    loadingBotonGuardar: false,
    fechaInicio: "",
    fechaInicioS: "",
    fechaFin: "",
    fechaFinS: "",
    modalFechaInicio: false,
    modalFechaFin: false,
    valSelect: 0,
    semanaSelect: "",
    proxSemanas: [],
    limitesSemanas: [],
    loadingWindow: true,
    fechaEnviar: "",
    respuestaTurno: [],
    respuestaValidaPermiso: "",
    // dates: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
    menu: false,
    alert: false,
    iconAlert: "mdi-alert-circle",
    colorAlert: "pink",
    textoBotonAlert: "",
    muestraDetalles: false,
    muestraBarra: false,
    textoBarra: "",
    colorBarra: "error",
    alertErrorDosDias: false,
    muestraCalendario: false,
  }),
  components: {
    MuestraIncidenciasCargadas,
    MuestraCalendarioDiasSeleccionados,
    SnackBarErrorIncidencias,
  },
  props: ["dialog", "empleado", "esAdmin"],
  created() {
    this.getHorariosFlexTime();
  },
  methods: {
    formatDate(date) {
      if (!date) return null;

      let [year, month, day] = date.split("-");
      switch (month) {
        case "01":
          month = "ENE";
          break;
        case "02":
          month = "FEB";
          break;
        case "03":
          month = "MAR";
          break;
        case "04":
          month = "ABR";
          break;
        case "05":
          month = "MAY";
          break;
        case "06":
          month = "JUN";
          break;
        case "07":
          month = "JUL";
          break;
        case "08":
          month = "AGO";
          break;
        case "09":
          month = "SEP";
          break;
        case "10":
          month = "OCT";
          break;
        case "11":
          month = "NOV";
          break;
        case "12":
          month = "DIC";
          break;
      }
      return `${day}/${month}/${year}`;
    },
    formatDateISO(date) {
      if (!date) return null;

      let [day, month, year] = date.split("/");
      switch (month) {
        case "ENE":
          month = "01";
          break;
        case "FEB":
          month = "02";
          break;
        case "MAR":
          month = "03";
          break;
        case "ABR":
          month = "04";
          break;
        case "MAY":
          month = "05";
          break;
        case "JUN":
          month = "06";
          break;
        case "JUL":
          month = "07";
          break;
        case "AGO":
          month = "08";
          break;
        case "SEP":
          month = "09";
          break;
        case "OCT":
          month = "10";
          break;
        case "NOV":
          month = "11";
          break;
        case "DIC":
          month = "12";
          break;
      }
      return `${year}-${month}-${day}`;
    },
    cierraVentanaIngresaHoraInicio() {
      this.fechaInicioS = this.formatDate(this.fechaInicio);
      this.modalFechaInicio = false;
    },
    cierraVentanaIngresaHoraFin() {
      this.fechaFinS = this.formatDate(this.fechaFin);
      this.modalFechaFin = false;
    },
    getTurnoEmpleado() {
      var params = {
        Opcion: 6,
        token: localStorage.getItem("token"),
      };
      axios
        .post(
          `${this.$store.getters.getHost}/Incidencias/ws-incidencias-calendario.php`,
          params
        )
        .then((response) => {
          var data = response.data.turnoEmpleado;
          this.descTurno = data;
          this.turno_desc = this.descTurno[0]["descripcion"];
        })
        .catch(function(e) {
          let result = e.toString().includes("401");
          if (result) {
            window.location.reload();
          } else {
            alert(e);
          }
        });
    },

    getHorariosFlexTime() {
      let params = {
        Opcion: 2,
        token: localStorage.getItem("token"),
      };
      axios
        .post(
          `${this.$store.getters.getHost}/Incidencias/ws-incidencias-flex-time.php`,
          params
        )
        .then((response) => {
          var data = response.data.horarios;
          this.horariosFlexTime = data;
          this.loadingWindow = false;
        })
        .catch(function(e) {
          let result = e.toString().includes("401");
          if (result) {
            window.location.reload();
          } else {
            alert(e);
          }
        });
    },

    setFechaManana() {
      const today = new Date();
      let tomorrow = new Date(today);
      tomorrow.setDate(tomorrow.getDate() + 1);
      return tomorrow.toISOString().substring(0, 10);
    },

    validaDatos() {
      var error = 0;
      if (this.valSelect >= 0) {
        if (this.valSelect > 0 && this.valSelect < 4) {
          if (this.fechaInicioS == "" || this.fechaFinS == "") {
            error = 1;
          }
        } else if (this.valSelect <= 4) {
          if (this.semanaSelect == "") {
            error = 1;
          }
        }
      } else {
        error = 1;
      }
      if (error == 0) {
        this.loadingBotonGuardar = true;
        if (this.valSelect <= 4) {
          this.fechaEnviar =
            this.formatDateISO(this.fechaInicioS) +
            "," +
            this.formatDateISO(this.fechaFinS);
        } else {
          this.fechaEnviar =
            this.proxSemanas[0].inicio + "," + this.proxSemanas[0].fin;
        }

        const params = {
          Opcion: 1,
          token: localStorage.getItem("token"),
          fecha: this.fechaEnviar,
          idTurno: this.valSelect,
          empleado: this.empleado,
          esAdmin: this.esAdmin,
        };
        axios
          .post(
            `${this.$store.getters.getHost}/Incidencias/ws-incidencias-flex-time.php`,
            params
          )
          .then((response) => {
            var data = response.data.flexTime;
            this.respuestaTurno = data;
            this.validaRespuesta();
          })
          .catch(function(e) {
            let result = e.toString().includes("401");
            if (result) {
              window.location.reload();
            } else {
              alert(e);
            }
          });
      }
    },
    validaRespuesta() {
      this.loadingBotonGuardar = false;
      if (this.respuestaTurno["opc"] != 5) {
        if (this.respuestaTurno["opc"] == 2) {
          this.colorAlert = "pink";
          this.iconAlert = "mdi-alert-circle";
          this.textoBotonAlert = "Ver más";
          this.alert = true;
        } else {
          this.colorAlert = "pink";
          this.iconAlert = "mdi-alert-circle";
          this.textoBotonAlert = "Cerrar";
          this.alert = true;
        }
      } else {
        this.textoBotonAlert = "Ver detalles";
        this.colorAlert = "success";
        this.iconAlert = "mdi-content-save-check-outline";
        this.valSelect = 0;
        this.fechaInicioS = "";
        this.fechaFinS = "";
        this.alert = true;
        this.$emit("cierra", 1);
      }
    },

    getAccionAlert() {
      if (this.textoBotonAlert == "Ver más") {
        this.alert = false;
        this.muestraDetalles = true;
      } else if (this.textoBotonAlert == "Ver detalles") {
        this.alert = false;
        this.muestraCalendario = true;
      } else {
        this.alert = false;
      }
    },
  },
};
</script>
