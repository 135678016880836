var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"v-lazy pa-4",staticStyle:{"min-height":"52px"}},[(!_vm.mostrarFormulario)?[_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Buscar","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-btn',{attrs:{"outlined":"","color":"primary"},on:{"click":_vm.agregarColaborador}},[_vm._v("Agregar")])],1),_c('v-data-table',{attrs:{"no-results-text":'No se encontraron registros coincidentes',"no-data-text":'No hay registros',"headers":_vm.headers,"items":_vm.colaboradores,"search":_vm.search,"footer-props":{
    'items-per-page-text': 'Elementos por página',
    'page-text': '{0}-{1} de {2}'
  }},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
  var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('v-btn',{staticClass:"mr-1",attrs:{"color":"primary","fab":"","x-small":"","dark":"","outlined":""},on:{"click":function($event){return _vm.editColaborador(item)}}},[_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Editar")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('v-btn',{staticClass:"mr-1",attrs:{"color":"cyan","fab":"","x-small":"","dark":"","outlined":""},on:{"click":function($event){return _vm.viewColaborador(item)}}},[_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-eye")])],1)]}}],null,true)},[_c('span',[_vm._v("Ver Información")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('v-btn',{staticClass:"mr-1",attrs:{"color":"red","fab":"","x-small":"","dark":"","outlined":""},on:{"click":function($event){return _vm.confirmDeleteColaborador(item)}}},[_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Eliminar")])])]}}],null,true)})]:[_c('FormularioColaboradores',{attrs:{"colaborador":_vm.colaboradorEdit,"colaboradorId":_vm.colaboradorEdit.id,"isEdit":_vm.isEdit,"isView":_vm.isView},on:{"update:isEdit":function($event){_vm.isEdit=$event},"update:is-edit":function($event){_vm.isEdit=$event},"update:isView":function($event){_vm.isView=$event},"update:is-view":function($event){_vm.isView=$event},"volver":function($event){_vm.mostrarFormulario = false}}})],_c('ConfirmDialogReclutamiento',{attrs:{"showConfirm":_vm.showConfirm,"question":_vm.deleteQuestion},on:{"cierraConfirm":_vm.dialogConfirm}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }