<template>
  <v-dialog
    v-model="muestraIngresaCantidad"
    transition="dialog-top-transition"
    max-width="400"
    content-class="fix-border-dialog"
  >
    <v-card class="rounded-xl">
      <v-toolbar color="primary" dark>
        <label class="overline font-weight-bold">{{ titulo }}</label>
        <v-spacer></v-spacer>
        <v-icon dark @click="$emit('retornaCantidad', null)">mdi-close</v-icon>
      </v-toolbar>
      <v-card-text class="pt-5">
        <v-form ref="cantidadForm">
          <v-text-field
            v-model="cantidad"
            class="rounded-lg"
            outlined
            dense
            type="number"
            :prefix="tipo === 'variable' ? '#' :'$'"
            placeholder="Ingresa la cantidad"
            required
            :rules="tipo === 'variable' ? fieldRulesVariable : fieldRules"
          ></v-text-field>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn text block class="rounded-xl rounded-t-0 font-weight-bold" color="teal darken-2" @click="validaCantidad">
          <v-icon left> mdi-content-save </v-icon>
          Guardar</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ["muestraIngresaCantidad", "titulo", "tipo"],
  data() {
    return {
      cantidad: null,
      fieldRules: [
        (v) => !!v || "Ingresa una cantidad valida.",
        (v) =>
          v >= 50 ||
          "Ningun colaborador puede ahorrar menos de $50.00 por nómina.",
        (v) =>
          /^[0-9]+(?:\.[0-9]+)?$/.test(v) || "Ingresa una cantidad valida.",
      ],
      fieldRulesVariable: [
        (v) => !!v || "Ingresa una cantidad valida.",
        (v) =>
          v >= 0 ||
          "Ningun colaborador puede ahorrar menos de $50.00 por nómina.",
      ],
    };
  },
  methods: {
    validaCantidad(){
        if(this.$refs.cantidadForm.validate()){
            this.$emit('retornaCantidad', this.cantidad)
        } 
    }
  }
};
</script>
