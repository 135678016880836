<template>
  <div>
    <v-dialog
      v-model="muestraAddEscolaridad"
      persistent
      max-width="550"
      content-class="fix-border-dialog"
    >
      <v-card class="rounded-xl">
        <v-card-title class="text-subtitle-2 primary white--text">
          Modificar escolaridad.
          <v-spacer></v-spacer>
          <v-icon small dark @click="$emit('cierraDialogEscolaridad', null)"
            >mdi-close
          </v-icon>
        </v-card-title>
        <v-card-text class="pt-4">
          <v-form v-model="formulario" ref="formConocimiento"></v-form>
          <v-row>
            <v-col cols="12" class="pr-1 pl-0">
              <div class="caption text-capitalize">escolaridad</div>
              <v-autocomplete
                v-model="escolaridadDesc"
                dense
                outlined
                required
                :items="escolaridad"
                item-text="descr"
                :rules="rules"
                class="rounded-lg"
                placeholder="Busca por escolaridad..."
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="12" class="pr-1 pl-0">
              <div class="caption text-capitalize">carreras</div>
              <v-autocomplete
                v-model="carreraDesc"
                dense
                outlined
                required
                :items="carreras"
                item-text="descr"
                :rules="rules"
                class="rounded-lg"
                placeholder="Busca por carrera..."
              >
              </v-autocomplete>
            </v-col>
            <!-- <v-col cols="3" class="pl-1 pr-0">
              <div class="caption text-capitalize">ponderación</div>
              <v-text-field
                v-model="ponderacion"
                dense
                type="number"
                outlined
                required
                :rules="rulesNumber"
                class="rounded-lg"
              >
              </v-text-field>
            </v-col> -->
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            block
            small
            text
            color="primary"
            class="font-weight-bold"
            @click="addSchooling"
            >guardar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <SnackBar
      v-if="muestraBarra"
      :muestraBarra="muestraBarra"
      :color="colorBarra"
      :texto="textoBarra"
      @cierraBarra="muestraBarra = false"
    />
  </div>
</template>

<script>
import SnackBar from "../../../General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue";
export default {
  name: "agrega-escolaridad",
  components: { SnackBar },
  props: ["idEscolaridad", "muestraAddEscolaridad", "escolaridad", "carreras"],
  data() {
    return {
      ponderacion: 0,
      formulario: true,
      muestraBarra: false,
      textoBarra: "",
      colorBarra: "",
      escolaridadDesc: "",
      carreraDesc: "",
      rules: [(v) => !!v || "Campo requerido."],
      rulesNumber: [
        (v) => !!v || "Campo requerido.",
        (v) => /^[1-9]\d*$/.test(v) || "Sólo números.",
      ],
    };
  },
  mounted() {},
  methods: {
    muestraSnackBar(texto, color) {
      this.colorBarra = color;
      this.textoBarra = texto;
      this.muestraBarra = true;
    },
    addSchooling() {
      if (this.$refs.formConocimiento.validate()) {
        let findEscolaridad = this.escolaridad.filter((e) => {
          return e.descr === this.escolaridadDesc;
        });
        let findCarrera = this.carreras.filter((c) => {
          return c.descr === this.carreraDesc;
        });
        if (findEscolaridad.length > 0 && findCarrera.length > 0) {
          this.$emit("cierraDialogEscolaridad", {
            id: this.idEscolaridad,
            esc: findEscolaridad[0].descr,
            carrera: findCarrera[0].descr,
            idCarrera: findCarrera[0].id,
            idEscolaridad: findEscolaridad[0].id,
            Ponderacion: this.ponderacion,
          });
        }
      }
    },
  },
};
</script>
