<template>
  <div>
    <v-dialog v-model="muestraAgregaBenficio" content-class="fix-border-dialog" max-width="700" scrollable>
      <v-card class="rounded-xl">
        <v-card-title class="primary rounded-xl rounded-b-0 white--text subtitle-1 font-weight-bold">
          Aministrar beneficio
          <v-spacer></v-spacer>
          <v-btn icon dark @click="$emit('ocultaAgregaBeneficio', 0)">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-form ref="formAddBeneficio">
            <v-row dense>
              <v-col :cols="['xs', 'sm'].includes($vuetify.breakpoint.name) ? 12 : 12">
                <label class="overline"> Colaborador </label>
                <v-autocomplete v-if="!beneficio" v-model="selectedItem" :items="itemscolaboradores" dense
                  class="rounded-lg" required item-text="NAME" :item-value="item => `${item.NAME} - ${item.EMPLID}`"
                  outlined hide-no-data :loading="loadingnames" placeholder="Nombre del colaborador..."></v-autocomplete>

                <v-text-field v-else v-model="selectedItem" dense class="rounded-lg" outlined readonly
                  label="Nombre del colaborador"></v-text-field>
              </v-col>
              <v-col :cols="['xs', 'sm'].includes($vuetify.breakpoint.name) ? 12 : 7">
                <label class="overline"> Modelo </label>
                <v-text-field v-model="modelo" dense class="rounded-lg" outlined required counter maxlength="50"
                  placeholder="Modelo del auto..."></v-text-field>
              </v-col>
              <v-col :cols="['xs', 'sm'].includes($vuetify.breakpoint.name) ? 12 : 5">
                <label class="overline"> Serie </label>
                <v-text-field v-model="serie" dense class="rounded-lg" outlined required counter maxlength="50"
                  placeholder="Numero de serie del auto..."></v-text-field>
              </v-col>
              <v-col :cols="['xs', 'sm'].includes($vuetify.breakpoint.name) ? 12 : 6">
                <label class="overline"> Arrendadora </label>
                <v-text-field v-model="arrendadora" dense class="rounded-lg" :rows="2" required outlined counter maxlength="50"
                  placeholder="Ingresa la arrendadora..."></v-text-field>
              </v-col>
              <v-col :cols="['xs', 'sm'].includes($vuetify.breakpoint.name) ? 12 : 6">
                <label class="overline"> No. Contrato </label>
                <v-text-field v-model="contrato" dense class="rounded-lg" required outlined counter maxlength="50"
                  placeholder="Ingresa la contrato..." item-text="text" value="value"></v-text-field>
              </v-col>
              <v-col :cols="['xs', 'sm'].includes($vuetify.breakpoint.name) ? 12 : 12">
                <label class="overline">
                  Selecciona foto del auto
                </label>
                <v-file-input v-model="file" class="rounded-lg" prepend-icon="mdi-camera"
                  placeholder="Selecciona la imagen..." outlined dense accept="image/*"></v-file-input>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="pa-0">
          <v-btn class="rounded-xl rounded-t-0" block text color="teal darken-1" x-large :loading="loadingSave"
            @click="loadingSave = true; saveBeneficio()">
            guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <SnackBar v-if="muestraBarra" :muestraBarra="muestraBarra" :color="colorBarra" :texto="textoBarra"
      @cierraBarra="muestraBarra = false" />
  </div>
</template>
  
<script>
import axios from "axios";
import SnackBar from "../../../General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue";

export default {
  name: "agrega-beneficio",
  props: ["muestraAgregaBenficio", "beneficio"],
  components: { SnackBar },
  data() {
    return {
      itemscolaboradores: [],
      selectedItem: null,
      modelo: "",
      serie: "",
      arrendadora: "",
      contrato: "",
      search: "",
      foliocolaborador: null,
      loadingnames: false,
      loadingSave: false,
      muestraBarra: false,
      textoBarra: "",
      colorBarra: "",
      file: null,
      rules: [v => v.length <= 50 || 'Máximo 50 caracteres'],
    };
  },
  mounted() {
    if (this.beneficio != null) {
      console.log(this.beneficio);
      this.selectedItem = this.beneficio.colaborador;
      this.modelo = this.beneficio.modelo;
      this.serie = this.beneficio.serie;
      this.arrendadora = this.beneficio.arrendadora;
      this.contrato = this.beneficio.noContrato;
    } else {
      this.getcolaboradores();
    }
  },
  methods: {
    muestraSnackBar(color, texto) {
      this.colorBarra = color;
      this.textoBarra = texto;
      this.muestraBarra = true;
    },
    async saveBeneficio() {
      if (this.$refs.formAddBeneficio.validate()) {
        let opc = this.beneficio === null ? 4 : 5;
        let fileBase64 = "";

        if (this.file != null) {
          fileBase64 = await this.readFileAsBase64(this.file);
        }
        const parts = this.selectedItem.split(' - ');
        const nombre = parts[0]; // Obtener el nombre
        this.foliocolaborador = this.beneficio != null ? this.beneficio.emplid : parts[1];// Obtener el ID del colaborador
        const params = {
          Opcion: opc,
          token: localStorage.getItem("token"),
          nombre: nombre,
          idcolaborador: this.foliocolaborador,
          modelo: this.modelo,
          serie: this.serie,
          arrendadora: this.arrendadora,
          contrato: this.contrato,
          idBeneficio: this.beneficio != null ? this.beneficio.id : 0,
          file: fileBase64,
        };
        axios
          .post(
            `${this.$store.getters.getHost}/Compensaciones/ws-beneficios-flexibles.php`,
            params
          )
          .then((response) => {
            if (response.data.status == "OK") {
              this.$refs.formAddBeneficio.reset();
              this.$refs.formAddBeneficio.resetValidation();
              this.muestraSnackBar(
                "teal darken-1",
                response.data.message
              );
              this.$emit('ocultaAgregaBeneficio', 1)
            } else {
              this.muestraSnackBar(
                "pink darken-3",
                response.data.message
              );
            }
          })
          .catch((error) => {
            this.muestraSnackBar("pink darken-3", error);
          })
          .finally(() => {
            this.loadingSave = false;
          });
      }
    },
    async readFileAsBase64(file) {
      return new Promise((resolve, reject) => {
        if (!file) {
          reject(new Error("No file provided."));
          return;
        }

        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = () => {
          resolve(reader.result);
        };

        reader.onerror = (error) => {
          reject(error);
        };
      });
    },
    getcolaboradores() {
      this.loadingnames = true;
      const params = {
        Opcion: 11,
        token: localStorage.getItem("token"),
      }
      axios
        .post(
          `${this.$store.getters.getHost}/General/ws-general.php`,
          params
        )
        .then((response) => {
          if (response.data.status == "OK") {
            this.itemscolaboradores = response.data.colaboradores;
          }
        })
        .catch((error) => {
          this.muestraSnackBar("pink darken-3", error);
        })
        .finally(() => {
          this.loadingnames = false;
        });
    }
  },
};
</script>
  