import { render, staticRenderFns } from "./MiPersona.html?vue&type=template&id=6ae8cb5c&scoped=true&"
import script from "./MiPersona.js?vue&type=script&lang=js&"
export * from "./MiPersona.js?vue&type=script&lang=js&"
import style0 from "./MiPersona.scss?vue&type=style&index=0&id=6ae8cb5c&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ae8cb5c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSheet } from 'vuetify/lib/components/VSheet';
installComponents(component, {VIcon,VProgressCircular,VSheet})
