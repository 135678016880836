<template>
  <v-container>
    <v-row>
      <v-col cols="12" xl="10" lg="10" md="10" sm="12" xs="12">
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          class="mb-5"
          label="Busca por nombre o número de empleado"
          single-line
          hide-details
        ></v-text-field>
      </v-col>
      <v-col
        cols="12"
        xl="2"
        lg="2"
        md="2"
        sm="12"
        xs="12"
        class="d-flex justify-center align-center"
      >
        <vue-json-to-csv
          v-if="colaboradores.datos"
          :json-data="colaboradores.datos"
          :labels="headers_report_to_export"
          :csv-title="nameFile"
        >
          <v-btn
            color="green darken-2"
            dark
            small
            class="mb-1 font-weight-bold rounded-lg"
            @click="fixName"
          >
            <v-icon left small>mdi-microsoft-excel</v-icon>
            {{ $t("DH360.export") }}
          </v-btn>
        </vue-json-to-csv>
      </v-col>
    </v-row>
    <v-data-table
      dense
      :loading="loadingContent"
      :headers="headers"
      :items="colaboradores.datos"
      sort-by-text="Ordenar por"
      no-data-text="No hay registros."
      items-per-page-all-text="Todos"
      items-per-page-text="Elementos por página"
      :show-current-page="false"
      :show-first-last-page="false"
      :search="search"
      loading-text="Cargando contenido..."
      :footer-props="{
        'items-per-page-options': [5, 10, 15, 20, 25],
        'items-per-page-text': 'Elementos por página',
      }"
    >
      <template v-slot:[`item.NAME`]="{ item }">
        <div align="left">
          {{ item.NAME }}
        </div>
      </template>
      <template v-slot:[`item.totalDocs`]="{ item }">
        {{ item.totalDocs }} / {{ colaboradores.maxDocs }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip top color="cyan lighten-4">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              color="cyan darken-4"
              v-bind="attrs"
              v-on="on"
              @click="openAdmFiles(item)"
            >
              mdi-text-box-multiple-outline
            </v-icon>
          </template>
          <label class="text-uppercase font-weight-bold primary--text"
            >Cargar documentos</label
          >
        </v-tooltip>
        <v-tooltip top color="pink lighten-4">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              color="pink darken-4"
              v-bind="attrs"
              v-on="on"
              @click="cambiarEstatus(item)"
            >
              mdi-file-document-alert-outline
            </v-icon>
          </template>
          <label class="text-uppercase font-weight-bold primary--text"
            >Proceder a demanda</label
          >
        </v-tooltip>
        <v-tooltip top color="blue lighten-4">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              color="primary"
              v-bind="attrs"
              v-on="on"
              @click="
                idEmpleadoAux = item.EMPLID;
                muestraDialogNominas = true;
              "
            >
              mdi-download-multiple
            </v-icon>
          </template>
          <label class="text-uppercase font-weight-bold primary--text"
            >Descargar nominas</label
          >
        </v-tooltip>
        <descargaMasivaDeNominas
          v-if="muestraDialogNominas"
          :activador="muestraDialogNominas"
          :emplid="idEmpleadoAux"
          @cierraDialog="muestraDialogNominas = false"
        />
      </template>
    </v-data-table>

    <v-dialog v-model="muestraCambioEstatus" persistent max-width="400">
      <v-card>
        <v-card-title class="text-subtitle-2 primary white--text">
          Proceder cambio de estatus
          <v-spacer></v-spacer>
          <v-icon
            small
            dark
            @click="
              muestraCambioEstatus = false;
              emplid = '';
              demanda = '';
              comentario = '';
            "
            >mdi-close</v-icon
          >
        </v-card-title>
        <v-card-text class="mt-5">
          <v-select
            :items="tiposDemandas"
            item-text="Estatus"
            item-value="id"
            v-model="demanda"
            label="Seleccionar una opción"
            dense
          >
          </v-select>
          <v-text-field
            v-model="comentario"
            label="Escribe un comentario"
            required
          ></v-text-field>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            v-if="demanda && comentario"
            class="rounded-lg elevation-0"
            small
            color="teal darken-1"
            dark
            @click="updateEstatus()"
          >
            <v-icon left>mdi-check-circle-outline</v-icon> Confirmar
          </v-btn>
          <v-btn v-else class="rounded-lg elevation-0" disabled>
            <v-icon left>mdi-check-circle-outline</v-icon> Confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <CargaArchivos
      v-if="muestraCargaArchivos"
      :dialogCargaArchivos="muestraCargaArchivos"
      :emplid="emplid"
      :estatusColaborador="2"
      esAdmin="1"
      @cierraVentana="reloadInformation()"
    />

    <!-- 
    <ModificaTiposDocumentos
      v-if="openEdit"
      :openNuevoTipoDocumento="openEdit"
      :opcion="opcionEdit"
      :item="itemEdit"
      @cierraVentana="cierraEdit"
    />  -->

    <SnackBarError
      :muestraBarra="muestraMensaje"
      :texto="textoMensaje"
      :color="colorMensaje"
      @cierraBarra="muestraMensaje = false"
    />
  </v-container>
</template>

<script>
import axios from "axios";
import { deleteToken } from "../../../../../store/Funciones/funciones";
import CargaArchivos from "../../../General/MiEmpresa/MiDocumentacion/components/MuestraArchivosPorCargar.vue";
//   import ModificaTiposDocumentos fromf "./ModificaTiposDocumentos.vue";
import SnackBarError from "../../../General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue";
import VueJsonToCsv from "vue-json-to-csv";
import descargaMasivaDeNominas from "../../../General/MiCompensacion/MisNominas/components/descargaMasivaDeNominas.vue";

export default {
  name: "adm-exp-colaboradores-bajas",
  components: {
    CargaArchivos,
    SnackBarError,
    VueJsonToCsv,
    descargaMasivaDeNominas,
  },
  props: [],
  data() {
    return {
      colaboradores: [],
      tiposDemandas: [],
      demanda: "",
      comentario: "",
      muestraMensaje: false,
      colorMensaje: "",
      textoMensaje: "",
      search: "",
      idEmpleadoAux: null,
      muestraDialogNominas: false,
      loadingContent: true,
      muestraCargaArchivos: false,
      muestraCambioEstatus: false,
      emplid: 0,
      headers: [
        {
          text: "Emplid",
          align: "center",
          sorteable: false,
          value: "EMPLID",
        },
        {
          text: "Fecha Baja",
          align: "center",
          sorteable: false,
          value: "fechaBaja",
        },
        {
          text: "Nombre",
          align: "center",
          sorteable: false,
          value: "NAME",
        },
        {
          text: "Estatus",
          align: "center",
          sorteable: false,
          value: "Estatus",
        },
        {
          text: "Proceso",
          align: "center",
          sorteable: false,
          value: "proceso",
        },
        {
          text: "Nuevo",
          align: "center",
          sorteable: false,
          value: "totalDocs",
        },
        {
          text: "Acciones",
          align: "center",
          sorteable: false,
          value: "actions",
        },
      ],

      nameFile: "",
      headers_report_to_export: {
        EMPLID: { title: "Emplid" },
        fechaBaja: { title: "Fecha Baja" },
        NAME: { title: "Nombre" },
        Estatus: { title: "Estatus" },
        proceso: { title: "Porceso" },
        totalDocs: { title: "Nuevo" },
      },
    };
  },
  computed: {},
  mounted() {
    this.getTipos();
  },
  methods: {
    getTipos() {
      this.colaboradores = [];
      let params = {
        Opcion: 13,
        token: localStorage.getItem("token"),
      };
      axios
        .post(
          `${this.$store.getters.getHost}/RelacionesLaborales/DocumentacionEmpleados/ws-carga-documentos.php`,
          params
        )
        .then((response) => {
          if (response.data.status == "EXPRESSION") {
            deleteToken();
          } else if (response.data.status == "OK") {
            this.colaboradores = response.data.reporte;
            this.loadingContent = false;
          }
        });
    },
    buscar() {
      console.log("hola2");
      this.loadingContent = true;
      this.getTipos();
    },
    getEstatusDemandas() {
      this.tiposDemandas = [];
      let params = {
        Opcion: 15,
        token: localStorage.getItem("token"),
      };
      axios
        .post(
          `${this.$store.getters.getHost}/RelacionesLaborales/DocumentacionEmpleados/ws-carga-documentos.php`,
          params
        )
        .then((response) => {
          if (response.data.status == "EXPRESSION") {
            deleteToken();
          } else if (response.data.status == "OK") {
            this.tiposDemandas = response.data.estatusDemanda;
            this.loadingContent = false;
            console.log(response.data.estatusDemanda);
          }
        });
    },

    updateEstatus() {
      let params = {
        Opcion: 16,
        comentario: this.comentario,
        estatus: this.demanda,
        emplid: this.emplid,
        token: localStorage.getItem("token"),
      };
      axios
        .post(
          `${this.$store.getters.getHost}/RelacionesLaborales/DocumentacionEmpleados/ws-carga-documentos.php`,
          params
        )
        .then((response) => {
          if (response.data.status == "EXPRESSION") {
            deleteToken();
          } else if (response.data.status == "OK") {
            this.getTipos();
            this.textoMensaje = "Cambio de estatus a demanda realizado";
            this.colorMensaje = "success";
            this.muestraMensaje = true;
            this.loadingContent = true;
            this.muestraCambioEstatus = false;
            console.log(params);
          }
        });
    },
    openAdmFiles(item) {
      console.log(item.EMPLID);
      this.emplid = item.EMPLID;
      this.muestraCargaArchivos = true;
    },
    cambiarEstatus(item) {
      console.log(item.EMPLID);
      if (this.tiposDemandas.length == 0) {
        this.getEstatusDemandas();
      }
      this.emplid = item.EMPLID;
      this.muestraCambioEstatus = true;
    },
    reloadInformation() {
      this.muestraCargaArchivos = false;
      this.colaboradores.length = 0;
      this.loadingContent = true;
      this.getTipos();
    },

    fixName() {
      let numero_aleatorio =
        Math.floor(Math.random() * (99999 - 10000 + 1)) + 10000;
      this.nameFile = "ExpDeColaboradoresBajas" + numero_aleatorio.toString();
    },
  },
};
</script>
