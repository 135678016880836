import MuestraUsuarios from "./components/MuestraUsuariosPrivilegiosVisitas.vue"
export default {
    name: 'admon-uruarios-seg-patrimonial',
    components: { MuestraUsuarios },
    props: [],
    data() {
        return {

        }
    },
    computed: {

    },
    mounted() {

    },
    methods: {

    }
}