import axios from 'axios'
import VueJsonToCsv from "vue-json-to-csv";
import {
    deleteToken,
    saveMonitoreo
} from '../../../../../store/Funciones/funciones'

export default {
    name: 'reporte-activos',
    components: { VueJsonToCsv },
    props: [],
    data() {
        return {
            itemsPerPage: 15,
            loadingTabla: true,
            singleExpand: true,
            ahora: null,
            fecha: "",
            search: '',
            headerProps: {
                sortByText: "Ordenar por",
            },
            expanded: [],
            colaboradores: [],
            headers: [{
                    text: "# Empleado",
                    align: "center",
                    value: "EMPLID"
                },
                {
                    text: "Nombre",
                    align: "center",
                    value: "Nombre"
                },
                {
                    text: "ID Puesto",
                    align: "center",
                    value: "JOBCODE"
                },
                {
                    text: "Puesto",
                    align: "center",
                    value: "Puesto"
                },
            ],
            headers_report_to_export: {
                EMPLID: {
                    title: "EMPLID"
                },
                Nombre: {
                    title: "Nombre"
                },
                Correo: {
                    title: "Correo"
                },
                EntidadFederativa: {
                    title: "EntidadFederativa"
                },
                Genero: {
                    title: "Genero"
                },
                CURP: {
                    title: "CURP"
                },
                RFC: {
                    title: "RFC"
                },
                CRTCOST: {
                    title: "Centro de costos"
                },
                JOBCODE: {
                    title: "JOBCODE"
                },
                Puesto: {
                    title: "Puesto"
                },
                Depto: {
                    title: "Departamento"
                },
                Area: {
                    title: "Área"
                },
                Contratacion: {
                    title: "Contratacion"
                },
                TipoPago: {
                    title: "TipoPago"
                },
                Proceso: {
                    title: "Proceso"
                },
                Lider: {
                    title: "LÍder"
                },
            },
        }
    },
    computed: {

    },
    mounted() {
        saveMonitoreo()
        this.ahora = new Date()
        this.fecha = "reporteActivos" +
            this.ahora.getDate().toString() + this.ahora.getMonth().toString() +
            this.ahora.getUTCFullYear().toString() + this.ahora.getUTCHours().toString() +
            this.ahora.getUTCMinutes().toString() + this.ahora.getUTCMilliseconds().toString();
        this.getActivos()
    },
    methods: {
        getActivos() {
            let params = {
                Opcion: 16,
                token: localStorage.getItem("token"),
            };

            axios
                .post(
                    `${this.$store.getters.getHost}/General/ws-general.php`,
                    params
                )
                .then((response) => {
                    if (response.data.status != 'EXPRESSION') {
                        let data = response.data.colaboradores;
                        this.colaboradores = data;
                        this.loadingTabla = false
                    } else {
                        deleteToken()
                    }

                })
                .catch(function(e) {
                    let result = e.toString().includes("401");
                    if (result) {
                        window.location.reload();
                    } else {
                        alert(e);
                    }
                });
        },
        // createJSONtoExport() {
        //     this.colaboradores.map((semana) => {
        //         let json_to_insert = {
        //             semana: semana.semana,
        //             NumEmplid: asistencia.NumEmp,
        //             Nombre: asistencia.NomEmp,
        //             Puesto: asistencia.Puesto,
        //             Depto: asistencia.Depto,
        //             Responsable: asistencia.NomResp,
        //             Turno: asistencia.Turno,
        //             Lunes: this.getStatusExport(asistencia.Lunes),
        //             Martes: this.getStatusExport(asistencia.Martes),
        //             Miercoles: this.getStatusExport(asistencia.Miercoles),
        //             Jueves: this.getStatusExport(asistencia.Jueves),
        //             Viernes: this.getStatusExport(asistencia.Viernes),
        //             Sabado: this.getStatusExport(asistencia.Sabado),
        //             Domingo: this.getStatusExport(asistencia.Domingo),
        //             Faltas: asistencia.faltas,
        //             Retardos: asistencia.retardos,
        //             Incapacidades: asistencia.incapacidades,
        //         };
        //         this.report_to_export.push(json_to_insert);
        //     });
        // },
    }
}