var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"reporte-bajas"},[_c('v-card',{staticClass:"mx-auto",staticStyle:{"display":"grid","place-content":"center","padding-bottom":"1.5em"},attrs:{"elevation":"2","max-width":"85%"}},[_c('h2',{staticStyle:{"text-align":"center","font-weight":"bold","color":"rgb(0, 120, 180)"}},[_vm._v(_vm._s(_vm.fixDate(_vm.fechaInicio))+" AL "+_vm._s(_vm.fixDate(_vm.fechaFin)))]),_c('br'),_c('p',{staticStyle:{"text-align":"center"}},[_vm._v("Para cambiar el periodo, click en "),_c('b',[_vm._v("Filtrar")])])]),_c('v-container',{attrs:{"fluid":""}},[_c('v-card',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","headers":_vm.headers,"items":_vm.bajas,"items-per-page":10,"loading":_vm.cargando,"search":_vm.buscar,"footer-props":{  'items-per-page-text': 'Elementos por página',
        },"no-data-text":"No hay registros","loading-text":"Cargando reporte...","mobile-breakpoint":"0"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{staticClass:"rounded-lg rounded-b-0 rounded-t-0",attrs:{"flat":"","color":"primary"}},[_c('v-text-field',{staticClass:"mt-7 rounded-lg",attrs:{"label":_vm.$t('DH360.findTextBox'),"dense":"","clearable":"","flat":"","solo-inverted":"","dark":"","prepend-inner-icon":"mdi-magnify"},model:{value:(_vm.buscar),callback:function ($$v) {_vm.buscar=$$v},expression:"buscar"}}),_vm._v(" "),_c('br'),_c('v-spacer'),_c('v-btn',{staticClass:"mb-1 font-weight-bold rounded-lg",attrs:{"color":"white","loading":_vm.cargando,"text":""},on:{"click":function($event){_vm.dialogPeriodo = true}}},[_c('v-icon',[_vm._v("mdi-filter")]),(_vm.$vuetify.breakpoint.name != 'xs')?_c('div',[_vm._v(" Filtrar")]):_vm._e()],1),_c('v-divider',{staticClass:"mx-6",staticStyle:{"border-color":"lightslategrey"},attrs:{"inset":"","vertical":""}}),_c('vue-json-to-csv',{attrs:{"json-data":_vm.bajas,"labels":_vm.headers_report_to_export,"csv-title":_vm.nameFile}},[_c('v-btn',{staticClass:"mb-1 font-weight-bold rounded-lg",attrs:{"color":"white","text":"","small":"","loading":_vm.cargando},on:{"click":_vm.fixName}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v("mdi-microsoft-excel")]),(_vm.$vuetify.breakpoint.name != 'xs')?_c('div',[_vm._v(" "+_vm._s(_vm.$t("DH360.export")))]):_vm._e()],1)],1)],1)]},proxy:true},{key:"item.Nombre",fn:function(ref){
        var item = ref.item;
return [_c('div',{attrs:{"align":"left"}},[_vm._v(" "+_vm._s(item.Nombre)+" ")])]}},{key:"item.MotivoBajaNominas",fn:function(ref){
        var item = ref.item;
return [_c('div',{attrs:{"align":"left"}},[_vm._v(" "+_vm._s(item.MotivoBajaNominas)+" ")])]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('div',{attrs:{"align":"center"}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.openDetalles(item)}}},[_vm._v(" mdi-reorder-horizontal ")])],1)]}},{key:"item.Recontratable",fn:function(ref){
        var item = ref.item;
return [(item.Recontratable == 'Verde')?_c('v-tooltip',{attrs:{"top":"","color":"green lighten-4"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"green darken-4"}},'v-icon',attrs,false),on),[_vm._v(" mdi-circle ")])]}}],null,true)},[_c('label',{staticClass:"text-uppercase font-weight-bold green--text"},[_vm._v("Verde")])]):(item.Recontratable == 'Amarillo')?_c('v-tooltip',{attrs:{"top":"","color":"yellow lighten-4"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"yellow"}},'v-icon',attrs,false),on),[_vm._v(" mdi-circle ")])]}}],null,true)},[_c('label',{staticClass:"text-uppercase font-weight-bold black--text"},[_vm._v("Amarillo")])]):(item.Recontratable == 'Rojo')?_c('v-tooltip',{attrs:{"top":"","color":"red lighten-4"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"red darken-4"}},'v-icon',attrs,false),on),[_vm._v(" mdi-circle ")])]}}],null,true)},[_c('label',{staticClass:"text-uppercase font-weight-bold white--text"},[_vm._v("Rojo")])]):(item.Recontratable == 'Act.')?_c('v-tooltip',{attrs:{"top":"","color":"blue lighten-4"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"blue darken-4"}},'v-icon',attrs,false),on),[_vm._v(" mdi-circle ")])]}}],null,true)},[_c('label',{staticClass:"text-uppercase font-weight-bold white--text"},[_vm._v("Rojo")])]):_vm._e()]}}])})],1),_c('v-sheet',{attrs:{"id":"snack-bar-error-incidencias"}},[_c('snack-bar-error-incidencias',{attrs:{"muestraBarra":_vm.muestraBarra,"texto":_vm.textoBarra,"color":_vm.colorBarra},on:{"cierraBarra":function($event){_vm.muestraBarra = false}}})],1)],1),(_vm.openDialogDetalles)?_c('reporte-bajas-detalles',{attrs:{"dialog":_vm.openDialogDetalles,"item":_vm.item},on:{"cierraDialog":_vm.closeDialog}}):_vm._e(),(_vm.dialogPeriodo)?_c('filtro-reporte',{attrs:{"dialog":_vm.dialogPeriodo,"fecha1":_vm.fechaInicio,"fecha2":_vm.fechaFin},on:{"cierraFiltro":_vm.actualizaFechas}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }