<template>
  <v-dialog max-width="600" v-model="dialog" persistent class="rounded-xxl">
    <v-card>
      <v-toolbar color="primary" dark dense>
        Gafete de colaborador
        <v-spacer></v-spacer>
        <v-icon dark @click="$emit('cierraImpresionGafete')">mdi-close</v-icon>
      </v-toolbar>

      <v-card-text class="pt-5 pb-0">
        <v-card elevation="2" outlined shaped>
          <v-card-text>
            <v-row>
              <v-col
                class="d-flex align-center"
                align="left"
                xl="4"
                lg="4"
                md="4"
                sm="12"
                xs="12"
              >
                <v-avatar size="150" tile>
                  <img
                    alt=""
                    :src="colaborador['linkCompleto']"
                    aling="absmiddle"
                    width="200px"
                  />
                </v-avatar>
              </v-col>
              <v-col
                class="d-flex align-center"
                align="left"
                xl="8"
                lg="8"
                md="8"
                sm="12"
                xs="12"
              >
                <v-card-text class="pt-0">
                  <div align="center">
                    <img
                      alt=""
                      :src="require('@/assets/img/grupo-urrea-logo-bn.png')"
                      aling="absmiddle"
                      width="200px"
                    />
                  </div>
                  <b>EMPLID</b> {{ colaborador.EMPLID }} <br />
                  <b>Nombre</b> {{ colaborador.NAME }} <br />
                  <b>Puesto</b> {{ colaborador.DESCR }} <br />
                  <b>Departamento</b> {{ colaborador.DESCR1 }}
                </v-card-text>
              </v-col>
              <v-col
                class="d-flex align-center"
                align="left"
                xl="12"
                lg="12"
                md="12"
                sm="12"
                xs="12"
              >
              </v-col>
            </v-row>
          </v-card-text>
          <div id="impresion" style="display: none">
            <center>
              <img
                alt=""
                :src="require('@/assets/img/grupo-urrea-logo-bn.png')"
                aling="absmiddle"
                width="200px"
              />
            </center>
            <div
              id="results"
              style="float: left; width: 30%; margin-bottom: 18px"
            >
              <img
                alt=""
                :src="colaborador['linkCompleto']"
                aling="absmiddle"
                width="165px"
              />
            </div>
            <div style="float: left; width: 70%">
              <div style="font-family: Arial, sans-serif">
                <strong>EMPLID: </strong>{{ colaborador.EMPLID }}
              </div>
              <div style="font-family: Arial, sans-serif">
                <strong>Nombre: </strong>{{ colaborador.NAME }}
              </div>
              <div style="font-family: Arial, sans-serif">
                <strong>Puesto: </strong>{{ colaborador.DESCR }}
              </div>
              <div style="font-family: Arial, sans-serif">
                <strong>Departamento: </strong>{{ colaborador.DESCR1 }}
              </div>
            </div>
            <br />
          </div>
        </v-card>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="teal darken-2"
              elevation="2"
              dark
              @click="saveIncidencia()"
              :loading="loading"
              v-bind="attrs"
              v-on="on"
              >Imprimir y crear incidencia
            </v-btn>
          </template>
          <span
            >Al dar clic se registrará la incidencia de olvido de gafete</span
          >
        </v-tooltip>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
export default {
  name: "gafete-colaborador",
  components: {},
  props: ["dialog", "colaborador"],
  data() {
    return {
      loading: false,
    };
  },
  computed: {},
  mounted() {},
  methods: {
    saveIncidencia() {
      var params = {
        Opcion: 5,
        empleado: this.colaborador.EMPLID,
      };
      console.log(params);
      this.loading = true;
      axios
        .post(
          `${this.$store.getters.getHost}/Incidencias/ws-incidencias-permiso-especial.php`,
          params
        )
        .then((response) => {
          var data = response.data.result;
          console.log(data);
          this.loading = false;
          this.printGaffete();

          this.$emit("cierraImpresionGafete");
        });
    },

    printGaffete() {
      let ficha = document.getElementById("impresion");
      var ventanaImpresion = window.open(" ", "popUp");
      ventanaImpresion.document.write(
        "<html><head><link href='estilos/bootstrap.min.css' rel='stylesheet'><style>.col-sm-2 {width: 16.66666667%;}.col-sm-6 {width: 50%;max-width: 50% }.col{float:left; position: relative;min-height: 1px; padding-right: 15px;padding-left: 15px;}.imgfoto{width: 300px!important;height: 325px!important;}.foto{margin-left: 15px;width: 270px;} center {display: block;text-align: -webkit-center;}img{width:300Px;text-align: -webkit-center;}body{width:1100px;font-size: 46px}</style></head>"
      );
      ventanaImpresion.document.write("<body>");
      ventanaImpresion.document.write(ficha.innerHTML);
      ventanaImpresion.document.write("</body>");
      ventanaImpresion.document.write("</html>");
      ventanaImpresion.document.close();
      ventanaImpresion.print();
      ventanaImpresion.close();
    },
  },
};
</script>
