<template>
  <div>

    <!-- <v-row>
      <v-col align="center" cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
        <v-card elevation="2" color="teal lighten-3" class="py-5 mx-auto">
          <h1 class="font-weight-bold white--text">
            {{ formatMXN(presupuesto) }}
          </h1>
          <br />
          <v-divider class="white-divider"></v-divider>
          <div class="font-weight-bold white--text">Presupuesto</div>
        </v-card>
      </v-col>
      <v-col align="center" cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
        <v-card elevation="2" color="teal lighten-3" class="py-5 mx-auto">
          <h1 class="font-weight-bold white--text">
            {{ formatMXN(presupuestoReal) }}
          </h1>
          <br />
          <v-divider class="white-divider"></v-divider>
          <div class="font-weight-bold white--text">Presupuesto Real</div>
        </v-card>
      </v-col>
    </v-row>
    <br /> -->
    <v-row class="px-5 align-center" v-for="item in niveles" :key="item.id">
      <label
        class="pl-5 pb-13 ma-0 font-weight-bold text--disabled align-start"
        style="position: absolute"
        >Nivel:{{ item.nivel }}</label
      >
      <v-col
        v-for="item2 in nivelesReverse"
        :key="item2.id"
        :cols="medidasCols"
        class="pa-0"
      >
        <!-- Nivel: {{ item.nivel }} | {{ item2.nivel }} <br /> -->
        <v-card
          class="pb-1"
          v-if="item.nivel == item2.nivel && item2.id_area"
          elevation="3"
        >
          <div class="color-bar"></div>
          <p class="my-1 px-2 text-center">
            Nivel {{ item.nivel }}
            <v-tooltip v-if="rol" top color="pink lighten-2">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  color="pink"
                  text
                  @click="confirmItem(item)"
                  v-bind="attrs"
                  v-on="on"
                  >mdi-delete-empty-outline</v-icon
                >
              </template>
              <span> Borrar nivel</span>
            </v-tooltip>
          </p>
          <v-divider></v-divider>
          <div
            class="mx-5 px-3 mb-3 pb-2 scroll-card"
            @click="openListaPuestos(item)"
            style="cursor: pointer"
          >
            <div v-if="item.puestos">
              <li
                v-for="(i, index) in item.puestos"
                :key="index"
                style="font-size: 0.8em"
              >
                {{ i.puesto }} {{ i.numPuestosReal }} / {{ i.numPuestosPlan }}
              </li>
            </div>
            <div v-else>Vacio</div>
          </div>
        </v-card>
        <v-card v-else-if="item.nivel == item2.nivel + 1" elevation="0" class="mx-5">
          <v-tooltip v-if="rol" top color="blue lighten-2">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mx-5"
                color="primary"
                text
                block
                @click="confirmNivelBetween(item)"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon
                  v-if="
                    $vuetify.breakpoint.name == 'xs' ||
                    $vuetify.breakpoint.name == 'sm'
                  "
                  >mdi-arrow-up</v-icon
                >
                <v-icon v-else>mdi-arrow-up-right</v-icon>
              </v-btn>
            </template>
            <span> Añadir nivel</span>
          </v-tooltip>
        </v-card>
      </v-col>
      <!-- <v-divider></v-divider> -->
    </v-row>
    <v-row>
      <v-btn v-if="rol" color="cyan" text block :loading="loading" @click="nuevoNivel">
        Nuevo Nivel
      </v-btn>
    </v-row>

    <lista-perfil-puestos
      v-if="openDetalles"
      :dialog="openDetalles"
      :item="item"
      :depto="depto"
      @cierraDialog="close"
    >
    </lista-perfil-puestos>
    <ConfirmDialogReclutamientoVue
      v-if="showConfirm"
      :showConfirm="showConfirm"
      :question="txtQuestion"
      @cierraConfirm="deleteItem"
    >
    </ConfirmDialogReclutamientoVue>
    <ConfirmDialogReclutamientoVue
      v-if="showConfirmAdd"
      :showConfirm="showConfirmAdd"
      :question="txtQuestion"
      @cierraConfirm="addNivelBetween"
    >
    </ConfirmDialogReclutamientoVue>
    <SnackBarErrorIncidencias
      v-if="muestraBarra"
      :muestraBarra="muestraBarra"
      :texto="textoBarra"
      :color="colorBarra"
      @cierraBarra="muestraBarra = false"
    />
  </div>
</template>

<script>
// import OrganizationChart from "vue-organization-chart";
import axios from "axios";
import { deleteToken } from "../../../../../../store/Funciones/funciones";
import ListaPerfilPuestos from "../componentsPerfilPuestos/ListaPerfilPuestos.vue";
import SnackBarErrorIncidencias from "../../../MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue";
// import "vue-organization-chart/dist/orgchart.css";
import ConfirmDialogReclutamientoVue from "../../../../AdmnVacantes/components/ConfirmDialogReclutamiento.vue";
export default {
  name: "organigrama-escaleras-crecimiento",
  props: ["niveles", "presupuesto", "presupuestoReal","depto"],
  components: {
    ListaPerfilPuestos,
    SnackBarErrorIncidencias,
    ConfirmDialogReclutamientoVue,
  },
  data() {
    return {
      textoBarra: "",
      colorBarra: "",
      muestraBarra: false,

      item: [],
      openDetalles: false,

      medidaCol: 12,
      loading: false,
      maxNivel: "",
      nivelesReverse: [],
      showConfirm: false,
      showConfirmAdd: false,
      txtQuestion: "",
      params: {},
      rol: false,
    };
  },
  created() {},
  mounted() {
    this.revertirNiveles();
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(this.getRoles());
      }, 1500);
    });
    
  },
  watch: {
    niveles: function (newVal, oldVal) {
      // Lógica que se ejecuta cuando 'niveles' cambia
      console.log("niveles ha cambiado:", newVal);
      console.log("niveles anteriores:", oldVal);
      setTimeout(() => {
        this.revertirNiveles();
      }, 500);
    },
  },
  computed: {
    medidasCols() {
      if (
        this.$vuetify.breakpoint.name == "xs" ||
        this.$vuetify.breakpoint.name == "sm"
      ) {
        return 12;
      } else {
        if (this.niveles.length > 6) {
          return 1;
        } else if (this.niveles.length == 6 || this.niveles.length == 5) {
          return 2;
        } else if (this.niveles.length == 4) {
          return 3;
        } else if (this.niveles.length == 3) {
          return 4;
        } else if (this.niveles.length < 3) {
          return 6;
        }
      }
      return 12;
    },
  },
  methods: {
    getRoles() {
      let correo = localStorage.getItem("correo");
      // let correo = "jagutierrez@urrea.net";
      // console.log(correo);
      const headers = { Authorization: localStorage.getItem("token") };
      return new Promise((res) => {
        axios
          .get(
            `${this.$store.getters.getHostNode}/api/get-roles/${correo}`,
            { headers }
          )
          .then((response) => {
            if (response.data.status == "OK") {
              res(response.data.data);
              this.rol = response.data.data;
            } else if (response.data.status == "EXPRESSION") {
              deleteToken();
            } else {
              res([]);
            }
          })
          .finally(() => {
            this.loadingForm = false;
          });
      });
    },

    formatMXN(cadena) {
      return cadena.toLocaleString("es-MX", {
        style: "currency",
        currency: "MXN",
      });
    },
    revertirNiveles() {
      this.nivelesReverse = [...this.niveles].reverse();
      setTimeout(() => {
        this.maxNivel = this.niveles[this.niveles.length - 1].nivel;
      }, 500);
      let cols = 12;
      if (this.niveles.length > 6) {
        cols = 12 - this.niveles.length;
        for (let a = 0; a < cols; a++) {
          this.nivelesReverse.unshift({ nivel: this.niveles.length });
        }
      } else if (this.niveles.length == 5) {
        this.nivelesReverse.unshift({ nivel: this.niveles.length });
      } else if (this.niveles.length == 1) {
        this.nivelesReverse.unshift({ nivel: this.niveles.length });
      }
    },

    openListaPuestos(item) {
      this.openDetalles = true;
      this.item = item;
    },

    close(response) {
      this.openDetalles = false;
      if (response == "OK") {
        this.$emit("cierraOrganigrama", "OK");
      }
    },

    nuevoNivel() {
      this.nivelesReverse = [];
      this.$emit("crearNivel", this.maxNivel); // Emite un evento personalizado
    },

    confirmItem(item) {
      this.params = {
        id: item,
        correo: localStorage.getItem("correo"),
      };
      this.showConfirm = true;
      this.txtQuestion =
        "¿Desea remover todo el nivel? \n Incluyendo los puestos registrados en ellas";
    },

    deleteItem(respuesta) {
      if (respuesta) {
        const headers = { Authorization: localStorage.getItem("token") };
        return new Promise((res) => {
          axios
            .post(
              `${this.$store.getters.getHostNode}/api/delete-puestos-por-niveles`,
              this.params,
              { headers }
            )
            .then((response) => {
              if (response.data.status == "OK") {
                res(response.data.data);
                this.textoBarra = response.data.message;
                this.colorBarra = "success";
                this.muestraBarra = true;
                return new Promise((resolve) => {
                  setTimeout(() => {
                    resolve(this.$emit("cierraOrganigrama", "OK"));
                  }, 1500);
                });
              } else if (response.data.status == "EXPRESSION") {
                deleteToken();
              } else {
                res([]);
              }
            })
            .finally(() => {
              this.showConfirm = false;
            });
        });
      } else {
        this.params = {};
      }
      this.showConfirm = false;
    },

    confirmNivelBetween(item) {
      this.params = {
        id: item,
        correo: localStorage.getItem("correo"),
      };
      this.showConfirmAdd = true;
      this.txtQuestion =
        "¿Estas seguro de añadir un nuevo nivel? \n " +
        "Los niveles serán reorganizados junto con sus puestos. ";
    },

    addNivelBetween(respuesta) {
      if (respuesta) {
        this.verLoadingBar = true;
        const headers = { Authorization: localStorage.getItem("token") };

        return new Promise((res) => {
          axios
            .post(
              `${this.$store.getters.getHostNode}/api/set-escalera-crecimiento-entre-niveles`,
              this.params,
              { headers }
            )
            .then((response) => {
              if (response.data.status == "OK") {
                res(response.data.data);
                this.textoBarra = response.data.message;
                this.colorBarra = "success";
                this.muestraBarra = true;
                return new Promise((resolve) => {
                  setTimeout(() => {
                    resolve(this.$emit("cierraOrganigrama", "OK"));
                  }, 1500);
                });
              } else if (response.data.status == "EXPRESSION") {
                deleteToken();
              } else {
                res([]);
              }
            })
            .finally(() => {
              this.loading = false;
              this.showConfirmAdd = false;
              this.verLoadingBar = false;
            });
        });
      } else {
        this.showConfirmAdd = false;
      }
    },
  },
};
</script>
<style scoped>
.my-card {
  display: flex;
  position: relative;
}

.scroll-card {
  height: 70px;
  overflow-y: scroll;
  overflow-x: scroll;
  background-color: #f4f4f4;
  border-radius: 10px !important;
  overflow-y: auto;
}

.color-bar {
  width: 4px; /* Ancho de la barra de color */
  background-color: #42a5f5; /* Color de la barra */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}

.white-divider {
  background-color: white; /* Cambia "white" al color que desees */
}
</style>