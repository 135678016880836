import VerObjetivos from "./components/VerObjetivos.vue"
export default {
    name: 'mis-objetivos',
    components: {
        VerObjetivos
    },
    props: [],
    data() {
        return {

        }
    },
    computed: {

    },
    mounted() {

    },
    methods: {

    }
}