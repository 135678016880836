<template>
    <v-card class="rounded-xl elevation-0 pa-3">
      <div style="display: flex; justify-content: space-between; ">
        <div class="ml-2 ">
          <label class="overline deep-purple--text text--lighten-3">selecciona el día de pre-nómina</label>
          <v-dialog
            ref="dialogFechaPrenomina"
            v-model="modalFechaPrenom"
            :return-value.sync="fechaSelected"
            width="290px"
            content-class="fix-border-dialog"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="fechaSelected"
                label="Día de prenomina"
                prepend-inner-icon="mdi-calendar"
                style="max-width: 350px"
                placeholder="Selecciona la fecha de prenomina..."
                v-on:change="loadingRepGeneral = true; updateReporte()"
                required
                dense
                outlined
                class="rounded-pill rounded-tl-lg"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="fechaSelected"
              class="rounded-xl"
              scrollable
              @input="
                $refs.dialogFechaPrenomina.save(fechaSelected);
                modalFechaVisita = false;
                loadingRepGeneral = true; 
                updateReporte();
              "
            >
            </v-date-picker>
          </v-dialog>
        </div>
        <div>
            <vue-json-to-csv
          :json-data="report_to_export"
          :labels="headers_report_to_export"
          :csv-title="'reporte-prestamos-' + fechaInicial"
        >
          <v-btn
            color="green darken-4"
            class="rounded-xl mr-1"
            depressed
            dark
          >
            <v-icon left> mdi-microsoft-excel </v-icon>
            Exportar
          </v-btn>
        </vue-json-to-csv>
          <v-tooltip left color="blue lighten-4">
            <template v-slot:activator="{ on, attrs }">
              <v-btn small fab depressed class="ml-2" v-bind="attrs" v-on="on" @click=" loadingData = true; updateReporte();">
                <v-icon>mdi-reload</v-icon>
              </v-btn>
            </template>
            <label class="text-uppercase font-weight-bold primary--text"
              >Actualizar tabla</label
            >
          </v-tooltip>
        </div>
      </div>
      <v-data-table
        dense
        :headers="headers"
        :items="reporte"
        :loading="loadingData"
        :search="search"
      >
        <template v-slot:top>
          <div style="display: flex; justify-content: end;">
            <v-text-field
              v-model="search"
              outlined
              dense
              class="rounded-xl"
              style="max-width: 350px;"
              prepend-inner-icon="mdi-table-search"
              placeholder="Buscar en tabla..."
            ></v-text-field>
          </div>
        </template>
        <template v-slot:item.FECHA_INICIO_PERIODO="{ item }">
          {{ ![null,undefined,''].includes(item.FECHA_INICIO_PERIODO) ? fixDate(item.FECHA_INICIO_PERIODO.date.substring(0,10)) : '' }}
        </template>
        <template v-slot:item.CANTIDAD="{ item }">
          {{ formatMXN(parseFloat(item.CANTIDAD)) }}
        </template>
      </v-data-table>
  
    </v-card>
  </template>
  
  <script>
  import {formatDate} from "../../../../../../store/Funciones/funciones.js"
  import axios from "axios";
  import VueJsonToCsv from "vue-json-to-csv";

  export default {
    name: "ofertas-economicas-pendientes",
    props: ["estatus"],
    components: {VueJsonToCsv},
    data() {
      return {
        fechasPrenomina: [],
        reporte: [],
        headers: [
          { value: "EMPLID", text: "EMPLID", align: "center" },
          { value: "CONCEPTO", text: "CONCEPTO", align: "center" },
          { value: "DESCRIPCION", text: "DESCRIPCIÓN", align: "center" },
          { value: "FECHA_INICIO_PERIODO", text: "FECHA_INICIO_PERIODO", align: "center" },
          { value: "PLAZO", text: "PLAZO", align: "center" },
          { value: "PERDIODO_DESC", text: "PERDIODO DESC.", align: "center" },
          { value: "CANTIDAD", text: "CANTIDAD", align: "center" },
          { value: "INTERES_ACTUAL", text: "INTÉRES", align: "center" },
        ],
        report_to_export: [],
      headers_report_to_export: {
        EMPLID: {title: "EMPLID"},
        SIEMPRE_0: {title: "SIEMPRE_0"},
        CONCEPTO: {title: "CONCEPTO"},
        DESCRIPCION: {title: "DESCRIPCION"},
        enBlanco: {title: "enBlanco"},
        FECHA_INICIO_PERIODO: {title: "FECHA_INICIO_PERIODO"},
        PLAZO: {title: "PLAZO"},
        IMP: {title: "IMP"},
        PERDIODO_DESC: {title: "PERDIODO_DESC"},
        CANTIDAD: {title: "CANTIDAD"},
        INTERES_ACTUAL: {title: "INTERES_ACTUAL"}
      },
        loadingData: true,
        modalFechaPrenom: false,
        fechaInicial: "",
        search: "",
        fechaSelected: "",
      };
    },
    computed: {
      smallScreen() {
        return ["xs", "sm"].includes(this.$vuetify.breakpoint.name)
          ? true
          : false;
      },
    },
    async mounted() {
      this.fechasPrenomina = await this.getFechasPrenomina();
      this.fechaInicial = this.fechasPrenomina[0].FCH_NOM.date.substring(0, 10);
      this.reporte = await this.getReporteRetiros(
        this.fechaInicial
      );
    },
    methods: {
      async getFechasPrenomina() {
        let params = {
          Opcion: 2,
          token: localStorage.getItem("token"),
        };
        return new Promise((resolve, reject) => {
          axios
            .post(
              `${this.$store.getters.getHost}/Nominas/AdmCajaAhorro/ws-reportes.php`,
              params
            )
            .then((response) => {
              if (response.data.status == "OK") {
                resolve(response.data.fechas);
              }
            })
            .catch((error) => {
              reject({
                error: error,
              });
            })
            .finally(() => {
              this.loadingDates = false;
            });
        });
      },
      async getReporteRetiros(fecha) {
        let params = {
          Opcion: 7,
          token: localStorage.getItem("token"),
          fechaPrenomina: fecha,
        };
        return new Promise((resolve, reject) => {
          axios
            .post(
              `${this.$store.getters.getHost}/Nominas/AdmCajaAhorro/ws-reportes.php`,
              params
            )
            .then((response) => {
              if (response.data.status == "OK") {
                resolve(response.data.reporte);
              }
            })
            .catch((error) => {
              reject({
                error: error,
              });
            })
            .finally(() => {
              this.loadingData = false;
              this.getReportToExport()
            });
        });
      },
      formatMXN(cadena) {
        return cadena.toLocaleString("es-MX", {
          style: "currency",
          currency: "MXN",
        });
      },
      fixDate(date){
        return formatDate(date)
      },
      async updateReporte() {
        this.reporte = await this.getReporteRetiros(
          this.fechaSelected
        );
      },
      getReportToExport() {
      if (this.reporte.length) {
        this.report_to_export.length = 0;
        this.reporte.map((r) => {
          this.report_to_export.push({
            EMPLID: r.EMPLID,
            SIEMPRE_0: r.SIEMPRE_0,
            CONCEPTO: r.CONCEPTO,
            DESCRIPCION: r.DESCRIPCION,
            enBlanco: r.enBlanco,
            FECHA_INICIO_PERIODO: r.FECHA_INICIO_PERIODO === null ? '' : r.FECHA_INICIO_PERIODO.date.substring(0,10),
            PLAZO: r.PLAZO,
            IMP: r.IMP,
            PERDIODO_DESC: r.PERDIODO_DESC,
            CANTIDAD: r.CANTIDAD,
            INTERES_ACTUAL: r.INTERES_ACTUAL,
          });
        });
      }
    },
    },
  };
  </script>
  