<template>
  <div>
    <v-dialog
      v-model="showDataEmployee"
      max-width="650"
      content-class="fix-border-dialog"
    >
      <v-card class="rounded-xl">
        <v-card-title class="pt-2 pb-3 pr-3 primary">
          <div class="subtitle-1 font-weight-bold white--text">
            {{ dataEmployee.name }}
          </div>
          <v-spacer></v-spacer>
          <v-icon dark @click="$emit('hideDataEmployee')">
            mdi-close
          </v-icon>
        </v-card-title>
        <v-card-text class="pt-5">
          <!-- {{ dataEmployee }} -->
          <div v-if="loadingDataEmployee" class="center-item-in-div py-5">
            <v-progress-circular
              :size="100"
              :width="10"
              color="primary"
              indeterminate
            ></v-progress-circular>
          </div>

          <div
            v-else-if="
              ![undefined, null].includes(dataEmployee) && !loadingDataEmployee
            "
          >
            <v-row dense>
              <v-col cols="4">
                <div class="overline">Ahorro nóminal</div>
                <div class="body-1">
                  {{
                    dataEmployee.monto > 0 && dataEmployee.monto !== null
                      ? formatMXN(dataEmployee.monto)
                      : formatMXN(0)
                  }}
                </div>
              </v-col>
              <v-col cols="4">
                <div class="overline">Saldo en caja</div>
                <div class="body-1">
                  {{
                    dataEmployee.total > 0 && dataEmployee.total !== null
                      ? formatMXN(dataEmployee.total)
                      : formatMXN(0)
                  }}
                </div>
              </v-col>
              <v-col cols="4">
                <div class="overline">Parte social</div>
                <div class="body-1">
                  {{
                    dataEmployee.pS > 0 && dataEmployee.pS !== null
                      ? formatMXN(dataEmployee.pS)
                      : formatMXN(0)
                  }}
                </div>
              </v-col>
              <v-col cols="6">
                <div class="overline">Adeudo</div>
                <div class="body-1">
                  {{
                    dataEmployee.restante > 0 && dataEmployee.restante !== null
                      ? formatMXN(dataEmployee.restante)
                      : formatMXN(0)
                  }}
                </div>
              </v-col>
              <v-col cols="6">
                <div class="overline">Monto máximo a prestar</div>
                <div class="body-1">
                  {{
                    dataEmployee.cantidadPrestamo > 0 &&
                    dataEmployee.cantidadPrestamo !== null
                      ? formatMXN(dataEmployee.cantidadPrestamo)
                      : formatMXN(0)
                  }}
                </div>
              </v-col>
            </v-row>
            <div class="center-item-in-div pt-5">
              <v-btn
                class="rounded-pill mx-1"
                depressed
                outlined
                color="primary"
                small
              >
                <v-icon left>mdi-cash-plus</v-icon>
                otorgar préstamo
              </v-btn>
              <v-btn
                class="rounded-pill mx-1"
                depressed
                outlined
                color="primary"
                small
                :loading="loadingChangeSavingAmount"
                @click="muestraIngresaCantidad = true"
              >
                <v-icon left>mdi-account-cash-outline</v-icon>
                modificar ahorro
              </v-btn>
              <v-btn
                class="rounded-pill mx-1"
                depressed
                outlined
                color="primary"
                small
                @click="showConfirm = true"
              >
                <v-icon left>mdi-minus-circle</v-icon>
                dar de baja
              </v-btn>
            </div>
          </div>

          <div v-else>
            <p class="text-center my-4">
              No se encontró información.
            </p>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <IngresaCantidad
      v-if="muestraIngresaCantidad"
      :muestraIngresaCantidad="muestraIngresaCantidad"
      :titulo="'Cambio de cantidad a ahorrar'"
      :tipo="'ahorro'"
      @retornaCantidad="getCantidad"
    />

    <SnackBar
      v-if="muestraBarra"
      :muestraBarra="muestraBarra"
      :texto="textoBarra"
      :color="colorBarra"
      @cierraBarra="muestraBarra = false"
    />

    <ConfirmDialog
      :showConfirm="showConfirm"
      question="¿Realmente deseas dar de baja a este colaborador de caja de ahorro?"
      @cierraConfirm="cierraConfirm"
    />
  </div>
</template>

<script>
import axios from "axios";
import IngresaCantidad from "./IngresaCantidad.vue";
import SnackBar from "../../../../../General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue";
import { deleteToken } from "../../../../../../../store/Funciones/funciones";
import ConfirmDialog from "../../../../../AdmnVacantes/components/ConfirmDialogReclutamiento.vue";

export default {
  props: ["showDataEmployee", "empleado", "dataEmpl"],
  components: { IngresaCantidad, SnackBar, ConfirmDialog },
  data() {
    return {
      dataEmployee: {},
      loadingDataEmployee: true,
      muestraIngresaCantidad: false,
      loadingChangeSavingAmount: false,
      muestraBarra: false,
      showConfirm: false,
      colorBarra: "",
      textoBarra: "",
    };
  },
  async mounted() {
    this.dataEmployee = await this.getDataEmployee();
  },
  methods: {
    showSnackBar(texto, color = "pink darken-3") {
      this.textoBarra = texto;
      this.colorBarra = color;
      this.muestraBarra = true;
    },
    getDataEmployee() {
      const headers = {
        Authorization: localStorage.getItem("token"),
      };

      return new Promise((res) => {
        axios
          .get(
            `${this.$store.getters.getHostNode}/api/empleados/caja/datos-por-empleado/${this.empleado}`,
            {
              headers,
            }
          )
          .then((response) => {
            if (response.data.status == "EXPSESSION") {
              deleteToken();
            } else if (response.data.status == "OK") {
              res(response.data.data);
            } else {
              res([]);
            }
          })
          .finally(() => {
            this.loadingDataEmployee = false;
          });
      });
    },
    formatMXN(cadena) {
      return cadena.toLocaleString("es-MX", {
        style: "currency",
        currency: "MXN",
      });
    },
    async getCantidad(cantidad) {
      if (cantidad !== null) {
        this.loadingChangeSavingAmount = true;
        let response = await this.putCantidad(cantidad).catch((error) => {
          this.showSnackBar(error);
        });

        response.status == "OK"
          ? this.showSnackBar(response.message, "teal darken-1")
          : this.showSnackBar(response.message);
      }
      this.muestraIngresaCantidad = false;
    },
    putCantidad(cantidad) {
      const params = {
        monto: cantidad,
        montoAnt: this.dataEmpl.monto,
        userMod: this.$store.getters.getUser["Folio"],
        type: 17,
      };

      const headers = {
        Authorization: localStorage.getItem("token"),
      };

      return new Promise((res) => {
        axios
          .put(
            `${this.$store.getters.getHostNode}/api/empleados/caja/ahorro/${this.empleado}`,
            params,
            {
              headers,
            }
          )
          .then((response) => {
            if (response.data.status == "EXPSESSION") {
              deleteToken();
            } else {
              res(response.data);
            }
          })
          .finally(() => {
            this.loadingChangeSavingAmount = false;
          });
      });
    },
    cierraConfirm(respuesta) {
      if (respuesta) {
        this.putEstatus();
      }

      this.showConfirm = false;
    },
    putEstatus() {
      const params = {
        userMod: this.$store.getters.getUser["Folio"],
      };

      const headers = {
        Authorization: localStorage.getItem("token"),
      };

      return new Promise((res) => {
        axios
          .put(
            `${this.$store.getters.getHostNode}/api/empleados/caja/estatus/${this.empleado}`,
            params,
            {
              headers,
            }
          )
          .then(async (response) => {
            if (response.data.status == "EXPSESSION") {
              deleteToken();
            } else if (response.data.status == "OK") {
              this.showSnackBar(response.data.message, "success");
              this.loadingDataEmployee = true;
              this.dataEmployee = await this.getDataEmployee();
              res(response.data);
            } else {
              this.showSnackBar(response.message);
              res([]);
            }
          })
          .finally(() => {
            this.loadingChangeSavingAmount = false;
          });
      });
    },
  },
};
</script>
