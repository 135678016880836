var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"rounded-lg pa-2",attrs:{"elevation":"3"}},[_c('div',{staticClass:"mb-2"},[_c('v-progress-linear',{staticClass:"rounded-pill",attrs:{"color":_vm.getColor(),"height":"25"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var value = ref.value;
return [_c('strong',{staticStyle:{"color":"white"}},[_vm._v(_vm._s(value.toFixed(3))+"%")])]}}]),model:{value:(_vm.Avance),callback:function ($$v) {_vm.Avance=$$v},expression:"Avance"}})],1),_c('div',[_c('p',{staticClass:"text-center font-weight-bold my-1",staticStyle:{"font-size":"1.05rem"},attrs:{"color":_vm.getColor()}},[_vm._v(" "+_vm._s(_vm.yaAutorizaronClientes)+" "+_vm._s(_vm.$t("DH360.of"))+" "+_vm._s(_vm.participantes.length)+" ")])]),_c('v-data-table',{staticClass:"elevation-2",attrs:{"headers":_vm.headers,"items":_vm.participantes,"items-per-page":_vm.itemsPerPage,"footer-props":{
      'items-per-page-options': [5, 10, 50, 100],
      'items-per-page-text': 'Elementos por página',
    },"dense":"","no-data-text":"No hay registros","header-props":_vm.headerProps,"loading":_vm.loadingTabla,"search":_vm.search,"item-key":"ID_EMPLEADO"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{staticClass:"rounded-lg rounded-b-0",attrs:{"flat":"","color":"primary"}},[_c('v-text-field',{staticClass:"mt-7",attrs:{"label":_vm.$t('DH360.findTextBox'),"dense":"","clearable":"","flat":"","solo-inverted":"","dark":"","prepend-inner-icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-divider',{staticClass:"mx-6",staticStyle:{"border-color":"lightslategrey"},attrs:{"inset":"","vertical":""}}),_c('vue-json-to-csv',{attrs:{"json-data":_vm.participantes,"labels":_vm.headers_report_to_export,"csv-title":_vm.nameFile}},[_c('v-btn',{staticClass:"mb-1 font-weight-bold rounded-lg",attrs:{"color":"green darken-1","dark":"","loading":_vm.loadingTabla}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-microsoft-excel")]),_vm._v(" "+_vm._s(_vm.$t("DH360.export"))+" ")],1)],1)],1)]},proxy:true},{key:"item.ESTATUS",fn:function(ref){
    var item = ref.item;
return [(item.Avance == 0)?_c('p',{staticClass:"my-2 font-weight-bold red--text text--darken-3"},[_vm._v(" "+_vm._s(item.Avance)+" "+_vm._s(_vm.$t("DH360.of"))+" "+_vm._s(item.Subordinados)+" ")]):(item.Avance > 0 && item.Subordinados > item.Avance)?_c('p',{staticClass:"my-2 font-weight-bold yellow--text text--darken-4"},[_vm._v(" "+_vm._s(item.Avance)+" "+_vm._s(_vm.$t("DH360.of"))+" "+_vm._s(item.Subordinados)+" ")]):_c('p',{staticClass:"my-2 font-weight-bold green--text text--darken-4"},[_vm._v(" "+_vm._s(item.Avance)+" "+_vm._s(_vm.$t("DH360.of"))+" "+_vm._s(item.Subordinados)+" ")])]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":""},on:{"click":function($event){return _vm.openSendMail(item.Correo)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-email-arrow-right-outline ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('DH360.notify')))])])]}}])}),(_vm.sendMail)?_c('PersonaDialogSendMail',{attrs:{"showSendMail":_vm.sendMail,"defaultMsg":_vm.textMsgMail,"receptor":_vm.mailSelected},on:{"hideSendMail":_vm.closeSendMail}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }