<template>
  <v-expansion-panels popout>
    <v-expansion-panel>
      <v-expansion-panel-header>
        <template v-slot:default="{ open }">
          <v-row no-gutters>
            <v-col cols="4"> Descuento vía nómina </v-col>
            <v-col cols="8" class="text--secondary">
              <v-fade-transition leave-absolute>
                <span v-if="open" key="0"> ¿Cómo será tu descuento? </span>
              </v-fade-transition>
            </v-col>
          </v-row>
        </template>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-radio-group v-model="TipoDescuento" row>
          <v-radio label="Descuento vía nómina" value="nomina"></v-radio>
          <v-radio label="Ya tengo mi cotización" value="cotizacion"></v-radio>
        </v-radio-group>
        <!-- <label >{{ TipoDescuento }}</label> -->

        <div v-if="TipoDescuento == 'nomina'">
          <p style="text-align: justify">
            Las <strong style="color: rgb(0, 120, 180)">ópticas</strong> con las
            que puedes asistir a realizarte el examen de la vista y pedir tu
            <strong style="color: rgb(0, 120, 180)">cotización</strong> son las
            siguientes:
          </p>

          <ul>
            <li>
              <v-icon small>mdi-chevron-right-circle</v-icon> Ópticas
              <strong style="color: rgb(0, 120, 180)">Polo</strong>
            </li>
            <li>
              <v-icon small>mdi-chevron-right-circle</v-icon> Ópticas
              <strong style="color: rgb(0, 120, 180)">Calpini</strong>
            </li>
            <li>
              <v-icon small>mdi-chevron-right-circle</v-icon> Ópticas
              <strong style="color: rgb(0, 120, 180)">Stigma</strong>
            </li>
          </ul>
          <br />
          <div>
            <v-btn block color="success" @click="muestraUbicaciones = true">
              Ver ubicaciones <v-icon right> mdi-map-marker </v-icon></v-btn
            >
          </div>
        </div>
        <div v-else>
          <p style="text-align: justify">
            Muy bien, por favor indica el
            <strong style="color: rgb(0, 120, 180)">importe total</strong> del
            costo de tus lentes.
            <strong style="color: rgb(120, 120, 120)"
              >Es importante escribir la información correcta para brindarte la
              información correcta.</strong
            >
          </p>
          <v-row no-gutters>
            <v-col cols="8">
              <v-text-field
                v-model="costo"
                type="number"
                label="Ingresa el monto"
                prefix="$"
              ></v-text-field>
            </v-col>
            <v-col
              cols="4"
              style="
                display: flex;
                justify-content: center;
                align-items: center;
              "
            >
              <v-btn
                color="success"
                :loading="loadingButton"
                :disabled="desabilitaBoton"
                @click="validaInfoBoton()"
              >
                Guardar
              </v-btn>
            </v-col>
          </v-row>
          <p
            v-if="desabilitaBoton"
            style="text-align: center; font-weight: bold; color: darkred"
          >
            Tienes una solicitud en curso, debes esperar la respuesta del
            departamento de nóminas y compensaciones. Agradecemos tu paciencia.
            En el apartado de seguimiento veras el estatus de tu solicitud
          </p>
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>

    <v-expansion-panel>
      <v-expansion-panel-header v-slot="{ open }">
        <v-row no-gutters>
          <v-col cols="4"> Reembolso </v-col>
          <v-col cols="8" class="text--secondary">
            <v-fade-transition leave-absolute>
              <span v-if="open" key="0">
                Ingresa la información necesaria
              </span>
              <span v-else key="1"> </span>
            </v-fade-transition>
          </v-col>
        </v-row>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <p
          v-if="!desabilitaBoton"
          style="text-align: center; font-weight: bold; color: darkred"
        >
          Ahora deberás cargar una imagen/fotografía de la factura que muestre
          el costo de tus lentes y que este a tu nombre o a nombre de grupo
          Urrea División Herramientas.
        </p>
        <p v-else style="text-align: center; font-weight: bold; color: darkred">
          Tienes una solicitud en curso, debes esperar la respuesta del
          departamento de nóminas y compensaciones. Agradecemos tu paciencia. En
          el apartado de seguimiento veras el estatus de tu solicitud
        </p>
        <v-row no-gutters>
          <v-col cols="12" style="display: flex; justify-content: center">
            <v-file-input
              accept="image/*"
              label="Evidencia."
              placeholder="Agrega la foto de tu factura..."
              @change="handleFileUpload"
              style="max-width: 90%"
              :disabled="desabilitaBoton"
            ></v-file-input>
          </v-col>
          <v-col cols="12" style="display: flex; justify-content: center">
            <v-text-field
              label="Cantidad"
              v-model="cantidadReembolso"
              prepend-icon="mdi-currency-usd"
              type="number"
              placeholder="Ingresa el precio de tus lentes..."
              style="max-width: 90%"
              :rules="rulesReembolso"
              :disabled="desabilitaBoton"
              clearable
            ></v-text-field>
          </v-col>
        </v-row>
        <p
          v-show="!desabilitaBoton"
          style="text-align: center; font-weight: bold; color: darkslategray"
        >
          NOTA: Si la fotografía adjunta no cumple con lo antes mencionado la
          solicitud no podrá ser procesada no verás tu reembolso.
        </p>
        <v-row
          no-gutters
          align-content="right"
          v-show="file != '' && cantidadReembolso != null"
        >
          <v-col cols="12" style="display: flex">
            <v-spacer></v-spacer>
            <v-btn
              dark
              color="primary"
              center
              rounded
              elevation="1"
              :loading="loadingButtonUploadFile"
              @click="subirArchivo()"
            >
              Guardar solicitud
            </v-btn>
          </v-col>
        </v-row>
      </v-expansion-panel-content>
    </v-expansion-panel>

    <v-expansion-panel v-show="desabilitaBoton">
      <v-expansion-panel-header v-slot="{ open }">
        <v-row no-gutters>
          <v-col cols="4"> Seguimiento </v-col>
          <v-col cols="8" class="text--secondary">
            <v-fade-transition leave-absolute>
              <span v-if="open" key="0"> Tus solicitudes realizadas </span>
              <span v-else key="1"> </span>
            </v-fade-transition>
          </v-col>
        </v-row>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-data-table
          :headers="headers"
          :items="solicitudes"
          :expanded.sync="expanded"
          :footer-props="{
            'items-per-page-options': [5, 10],
            'items-per-page-text': 'Elementos por página',
          }"
          no-data-text="No hay registros"
          :header-props="{
            'short-by-text': 'Ordenar por',
          }"
          class="elevation-2"
          item-key="id"
          show-expand
        >
          <template v-slot:[`item.estatus`]="{ item }">
            <div
              v-if="item.estatus == 1">
              <p style="font-weight: bold; color: rgb(0, 120, 180)">
                En proceso
              </p>
            </div>
            <div
              v-else-if="item.estatus == 2">
              <p style="font-weight: bold; color: darkred">Rechazada</p>
            </div>
            <div
              v-else-if="item.estatus == 3">
              <p style="font-weight: bold; color: darkgreen">Autorizada</p>
            </div>
          </template>

          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length" v-if="item.tipo == 2">
              <v-row>
                <v-col
                  cols="12"
                  xl="4"
                  lg="4"
                  md="4"
                  sm="12"
                  xs="12"
                  style="display: flex; justify-content: center"
                >
                  <div style="margin-top: 10px">
                    <v-hover v-slot="{ hover }">
                      <v-img
                        :src="
                          host +
                          '/ArchivosCargadosEnPortal2/Nominas/AyudaLentes/' +
                          item.descripcion
                        "
                        max-height="150"
                        max-width="250"
                        elevation="2"
                      >
                        <v-expand-transition>
                          <div
                            v-if="hover"
                            class="
                              d-flex
                              transition-fast-in-fast-out
                              white--text
                            "
                            style="
                              height: 100%;
                              display: flex;
                              justify-content: center;
                              vertical-align: center;
                              background-color: rgba(0, 120, 180, 0.7);
                            "
                            @click="
                              muestraImagen(
                                host +
                                  '/ArchivosCargadosEnPortal2/Nominas/AyudaLentes/' +
                                  item.descripcion
                              )
                            "
                          >
                            <p style="margin-top: 10%">Expandir</p>
                          </div>
                        </v-expand-transition>
                      </v-img>
                    </v-hover>
                  </div>
                  <!-- <p>http://localhons:99/ArchivosCargadosEnPortal2/Nominas/AyudaLentes/{{item.descripcion}}</p> -->
                </v-col>
                <v-col cols="12" xl="8" lg="8" md="8" sm="12" xs="12">
                  <div>
                    <p><strong>Concepto:</strong> {{ item.concepnto }}</p>
                    <p>
                      <strong>Fecha Inicio Periodo:</strong>
                      {{ item.fecha_inicio }}
                    </p>
                    <p><strong>Periodo:</strong> {{ item.periodo }}</p>
                    <p><strong>Importe:</strong> {{ item.importe }}</p>
                    <p>
                      <strong>Fecha de solicitud:</strong>
                      {{ item.fecha_solicitud }}
                    </p>
                    <p v-if="item.estatus == 2">
                      <strong>Comentario de DH:</strong>{{ item.comentario }}
                    </p>
                  </div>
                </v-col>
              </v-row>
            </td>
            <td :colspan="headers.length" v-else>
              <v-row>
                <v-col cols="12" xl="6" lg="6" md="6" sm="12" xs="12">
                  <p><strong>Descripcion:</strong> {{ item.descripcion }}</p>
                  <p><strong>Concepto:</strong> {{ item.concepnto }}</p>
                  <p>
                    <strong>Fecha Inicio Periodo:</strong>
                    {{ item.fecha_inicio }}
                  </p>
                  <p><strong>Periodo:</strong> {{ item.periodo }}</p>
                </v-col>
                <v-col cols="12" xl="6" lg="6" md="6" sm="12" xs="12">
                  <p><strong>Importe:</strong> {{ item.importe }}</p>
                  <p>
                    <strong>Fecha de solicitud:</strong>
                    {{ item.fecha_solicitud }}
                  </p>
                  <p><strong>Plazo:</strong> {{ item.plazo }}</p>
                  <p v-if="item.estatus == 2">
                    <strong>Comentario de DH:</strong>{{ item.comentario }}
                  </p>
                </v-col>
              </v-row>
            </td>
          </template>
        </v-data-table>
      </v-expansion-panel-content>
    </v-expansion-panel>

    <v-dialog v-model="comfirm" width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Guardar solicitud
        </v-card-title>

        <v-card-text>
          <p style="margin-top: 20px; font-size: medium">
            {{ mensajeConfirmacion }}
          </p>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            text
            @click="cancel()"
            :disabled="cargandoGuardar"
            >Rechazar</v-btn
          >
          <v-btn
            color="primary"
            text
            @click="agree()"
            :loading="cargandoGuardar"
            >Guardar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-sheet>
      <muestra-ubicaciones
        v-if="muestraUbicaciones"
        :dialogUbicaciones="muestraUbicaciones"
        @cierraDialog="muestraUbicaciones = false"
      />
    </v-sheet>
    <v-sheet id="snack-bar-error-incidencias">
      <snack-bar-error-incidencias
        :muestraBarra="muestraBarra"
        :texto="textoBarra"
        :color="colorBarra"
        @cierraBarra="muestraBarra = false"
      />
    </v-sheet>
    <loadingBarVue :dialog="muestraLoading" :text="textoLoading"/>
  </v-expansion-panels>
</template>


<script>
import axios from "axios";
import SnackBarErrorIncidencias from "../../../MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue";
import MuestraUbicaciones from "./MuestraUbicaciones.vue";
import loadingBarVue from "../../../../DesarrolloOrganizacional/components/loadingBar.vue";
import { genRandonString } from "../../../../../../store/Funciones/funciones";
export default {
  name: "solicitud-apoyo-lentes",
  data: () => ({
    TipoDescuento: "nomina",
    costo: "",
    abonoString: "",
    mensajeConfirmacion: "",
    textoBarra: "",
    colorBarra: "",
    file: "",
    textoLoading: "cargando contenido...",
    cantidadReembolso: null,
    muestraBarra: false,
    comfirm: false,
    cargandoGuardar: false,
    muestraMensaje: false,
    validaPrestacionEnCurso: false,
    loadingButton: false,
    desabilitaBoton: false,
    muestraUbicaciones: false,
    loadingButtonUploadFile: false,
    loadingInfoTablaSolicitudesColab: true,
    muestraLoading: true,
    montoAyuda: 0,
    longitudCadenaAleatoria: 16,
    rulesReembolso: [
      (value) =>
        !!value ||
        'En nesesario que ingreses el monto. Ingresa una cantidad númerica, evita "$" y ",".',
      // value => (typeof(value) != 'number')  || 'Ingresa una cantidad númerica, evita "$" y ",".',
    ],
    solicitudes: [],
    expanded: [],
    headers: [
      {
        text: "Emplid",
        sortable: false,
        value: "emplid",
      },
      {
        text: "Nombre",
        sortable: false,
        value: "NAME",
      },
      {
        text: "Estatus",
        sortable: false,
        value: "estatus",
      },
    ],
  }),
  components: {
    SnackBarErrorIncidencias,
    MuestraUbicaciones,
    loadingBarVue
  },
  created() {},
  mounted() {
    this.validaSolicitudHecha();
  },
  props: [],
  methods: {
    validaSolicitudHecha() {
      let params = {
        Opcion: 10,
        token: localStorage.getItem("token"),
      };
      axios
        .post(
          `${this.$store.getters.getHost}/Compensaciones/ws-ayuda-lentes.php`,
          params
        )
        .then((response) => {
          if (response.data.status == "OK") {
            if (response.data.ayudaLentes > 0) {
              this.desabilitaBoton = true;
              this.getSolicitudesColab();
            } else {
              this.muestraLoading = false;
            }
          } else {
            this.muestraLoading = false;
          }
        });
    },
    validaInfoBoton() {
      if (this.costo <= 0 || this.costo == "") {
        alert("error");
      } else {
        this.loadingButton = true;
        this.getGrupo();
      }
    },
    getGrupo() {
      let params = {
        Opcion: 3,
        token: localStorage.getItem("token"),
      };
      axios
        .post(
          `${this.$store.getters.getHost}/Compensaciones/ws-ayuda-lentes.php`,
          params
        )
        .then((response) => {
          if (response.data.status == "OK") {
            this.calculaPrestacionLentes(response.data.ayudaLentes);
          }
        });
    },
    calculaPrestacionLentes(grupo) {
      this.loadingButton = false;
      this.costo = this.costo.replace(",", "");
      this.costo = this.costo.replace("$", "");

      let costoPagadoUrrea = parseFloat(this.costo) * 0.65;
      let costoFinal = 0;
      let abono = 0;
      let abonos = 0;

      if (costoPagadoUrrea > 2700) {
        costoFinal = parseFloat(this.costo) - 2700;
        this.montoAyuda = parseFloat(2700);
      } else {
        costoFinal = parseFloat(this.costo) - costoPagadoUrrea;
        this.montoAyuda = parseFloat(costoPagadoUrrea);
      }

      if (grupo == 1 || grupo == 8 || grupo == 10) {
        abonos = 12;
        abono = costoFinal / abonos;
      } else {
        abonos = 6;
        abono = costoFinal / abonos;
      }

      this.abonoString = new Intl.NumberFormat("es-MX", {
        style: "currency",
        currency: "MXN",
      }).format(abono);

      this.mensajeConfirmacion =
        "Tu descuento se relizaría en " +
        abonos +
        " nóminas con abonos de " +
        this.abonoString +
        ". ¿Estás de acuerdo?";
      this.comfirm = true;
    },
    agree() {
      this.cargandoGuardar = true;
      this.guardarSolicitud();
    },
    cancel() {
      this.comfirm = false;
    },
    guardarSolicitud() {
      let params = {
        Opcion: 4,
        token: localStorage.getItem("token"),
        monto: this.montoAyuda,
      };
      axios
        .post(
          `${this.$store.getters.getHost}/Compensaciones/ws-ayuda-lentes.php`,
          params
        )
        .then((response) => {
          if (response.data.status == "OK") {
            this.comfirm = false;
            if (response.data.ayudaLentes >= 1) {
              this.textoBarra = "Solicitud registrada con éxito.";
              this.colorBarra = "success";
              this.muestraBarra = true;
            } else {
              this.textoBarra = "No se pudo registrar la solicitud.";
              this.colorBarra = "error";
              this.muestraBarra = true;
            }
            // alert("guardado");
          } else {
            this.textoBarra = "No se pudo registrar la solicitud.";
            this.colorBarra = "error";
            this.muestraBarra = true;
          }
        });
    },
    handleFileUpload(file) {
      this.file = file;
    },
    subirArchivo() {
      this.loadingButtonUploadFile = true;
      this.cargarArchivo();
    },
    cargarArchivo() {
      var reader = new FileReader();
      reader.readAsDataURL(this.file);
      reader.onload = () => {
        var params = {
          Opcion: 6,
          filename: this.creaNombreAleatorio(this.file.name),
          base64: reader.result,
          token: localStorage.getItem("token"),
        };
        axios
          .post(
            `${this.$store.getters.getHost}/Compensaciones/ws-ayuda-lentes.php`,
            params
          )
          .then((response) => {
            if (response.data.respuesta.trim() == params.filename.trim()) {
              console.log("archivo cargado");
              this.guardaSolicitudReembolso(response.data.respuesta);
            }
          });
      };
    },
    guardaSolicitudReembolso(archivo) {
      let params = {
        Opcion: 7,
        token: localStorage.getItem("token"),
        monto: this.cantidadReembolso,
        file: archivo,
      };
      axios
        .post(
          `${this.$store.getters.getHost}/Compensaciones/ws-ayuda-lentes.php`,
          params
        )
        .then((response) => {
          if (response.data.status == "OK") {
            this.loadingButtonUploadFile = false;
            if (response.data.ayudaLentes >= 1) {
              this.file = null;
              this.cantidadReembolso = null;
              this.textoBarra = "Solicitud registrada con éxito.";
              this.colorBarra = "success";
              this.muestraBarra = true;
            } else {
              this.textoBarra = "No se pudo registrar la solicitud.";
              this.colorBarra = "error";
              this.muestraBarra = true;
            }
            // alert("guardado");
          } else {
            this.textoBarra = "No se pudo registrar la solicitud.";
            this.colorBarra = "error";
            this.muestraBarra = true;
          }
        });
    },
    creaNombreAleatorio(nombre) {
      // obtenemos la extensión
      let invertida = nombre.split("").reverse().join("");
      let indice = invertida.indexOf(".");
      let extraida = invertida.substring(0, indice);
      let extension = extraida.split("").reverse().join("");

      let noEmp = this.$store.getters.getUser["Folio"];
      let cadenaAleatoria = genRandonString(this.longitudCadenaAleatoria);
      let nombreArchivo = noEmp + "_" + cadenaAleatoria + "." + extension;
      return nombreArchivo;
    },

    getSolicitudesColab() {
      let params = {
        Opcion: 11,
        token: localStorage.getItem("token"),
      };
      axios
        .post(
          `${this.$store.getters.getHost}/Compensaciones/ws-ayuda-lentes.php`,
          params
        )
        .then((response) => {
          this.loadingInfoTablaSolicitudesColab = false;
          if (response.data.status == "OK") {
            this.solicitudes = response.data.ayudaLentes;
            this.muestraLoading = false;
          }
        });
    },
  },
};
</script>