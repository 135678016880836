import MuestraAreas from "./components/muestraArea.vue"
import MuestraPases from "./components/muestraPases.vue"
import axios from 'axios'
import { deleteToken } from "../../../../store/Funciones/funciones"
export default {
  name: 'admon-pases-de-ingreso',
  components: { MuestraAreas, MuestraPases },
  props: [],
  data() {
    return {
      seleccion: 2,
      accesoAdministracionAutorizadores: false,
      loadingData: false
    }
  },
  computed: {

  },
  async mounted() {
    const perfil = await this.getValidacionDeAccesos();
    if (["ADMINTI", "ADMINGEN"].includes(perfil)) {
      this.accesoAdministracionAutorizadores = true;
    }
  },
  methods: {
    getValidacionDeAccesos() {
      const params = {
        Opcion: 53,
        token: localStorage.getItem("token"),
      };

      return new Promise((resolve, reject) => {
        axios
          .post(
            `${this.$store.getters.getHost}/SeguridadPatrimonial/ws-RegistroVisitantes.php`,
            params
          )
          .then((response) => {
            this.loadingData = false;
            if (response.data.status == "OK") {
              resolve(response.data.respuesta);
            } else if (response.data.status == "EXPRESSION") {
              deleteToken();
            } else {
              resolve("");
            }
          })
          .catch((e) => {
            // this.openSnackBar(e);
            reject(e);
          })
          .finally(() => {
            this.loadingData = false;
          });
      });
    },
  }
}