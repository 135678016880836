import AdmonProveedores from "../RepseUsuarios/components/AdmonProveedores.vue"
export default {
  name: 'administracion-repse',
  components: {AdmonProveedores},
  props: [],
  data () {
    return {

    }
  },
  computed: {

  },
  mounted () {

  },
  methods: {

  }
}


