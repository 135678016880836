<template>
  <v-dialog
    v-model="dialog"
    :max-width="
      $vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm'
        ? '80%'
        : '50%'
    "
    transition="dialog-bottom-transition"
    persistent
  >
    <v-card>
      <v-card-title class="text-subtitle-2 primary white--text">
        Puntos colaborador
        <v-spacer></v-spacer>
        <v-icon small dark @click="$emit('cierraCuadrante', 'Cancel')"
          >mdi-close</v-icon
        >
      </v-card-title>
      <v-card-text class="pt-5 d-flex justify-center">
        <div v-show="loadingData" class="text-center">
          <h2 class="text-center blue-grey--text text--lighten-2 mb-5">
            Cargando contenido...
          </h2>
          <v-progress-circular
            color="primary"
            indeterminate
            :size="70"
            :width="7"
          ></v-progress-circular>
        </div>
        <v-row v-show="!loadingData">
          <v-col
            class="py-0 d-flex justify-center"
            cols="12"
            xl="4"
            lg="4"
            md="4"
            sm="12"
            xs="12"
            v-for="(item, index) in cuadrante"
            :key="index"
          >
            <v-card
              :dark="item.dark == 1 ? true : false"
              :color="item.color"
              width="160"
              height="160"
              class="rounded-xxl pa-2 mt-2"
            >
              <v-tooltip top :color="item.color">
                <template v-slot:activator="{ on, attrs }">
                  <p class="truncate-text ma-0" v-bind="attrs" v-on="on">
                    {{ item.id }}
                    {{ item.talento }}
                  </p>
                </template>
                <span style="color: black" v-if="item.dark == 0">{{
                  item.talento
                }}</span>
                <span style="color: white" v-else>{{ item.talento }}</span>
              </v-tooltip>
              <v-divider
                style="border-color: #000000"
                v-if="item.dark == 0"
              ></v-divider>
              <v-divider style="border-color: #ffffff" v-else></v-divider>
              -{{ item.desempenioNom }} <br />
              -{{ item.potencialNom }} <br />

              <div
                class="font-italic"
                align="center"
                v-if="item.id == idCuadrante"
              >
                <v-avatar size="50">
                  <img :src="foto" alt="Avatar" />
                </v-avatar>
                <br />
                Estás aquí
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <SnackBarErrorIncidencias
      v-if="muestraBarra"
      :muestraBarra="muestraBarra"
      :texto="textoBarra"
      :color="colorBarra"
      @cierraBarra="muestraBarra = false"
    />
  </v-dialog>
</template>

<script>
import axios from "axios";
import { deleteToken } from "../../../../../store/Funciones/funciones";
import SnackBarErrorIncidencias from "../../../General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue";
export default {
  name: "cuadrante-mapa-de-talento",
  components: {
    SnackBarErrorIncidencias,
  },
  props: ["dialog", "item"],
  data: () => ({
    textoBarra: "",
    colorBarra: "",
    muestraBarra: false,

    loadingData: true,
    cuadrante: [],
    idCuadrante: "",
    foto: "https://community.f5.com/legacyfs/online/avatars/0051T000008uOPOQA2.png",
  }),
  created() {},
  computed: {},
  mounted() {
    this.getDataCuadrante();
    this.idCuadrante = this.item.cuadrante;
    if (this.item.foto) {
      this.foto =
        "https://apidh.urrea.com/ArchivosCargadosEnPortal2/FotosPerfilUsuarios/" +
        this.item.foto;
    }
  },

  methods: {
    getDataCuadrante() {
      const headers = { Authorization: localStorage.getItem("token") };
      return new Promise((res) => {
        axios
          .get(`${this.$store.getters.getHostNode}/api/get-data-cuadrante`, {
            headers,
          })
          .then((response) => {
            if (response.data.status == "OK") {
              res(response.data.data);
              this.cuadrante = response.data.data;
            } else if (response.data.status == "EXPRESSION") {
              deleteToken();
            } else {
              res([]);
            }
          })
          .finally(() => {
            this.loadingData = false;
          });
      });
    },
  },
};
</script>

<style scoped>
.truncate-text {
  white-space: nowrap; /* Evita el salto de línea */
  overflow: hidden; /* Oculta el contenido que se desborda */
  text-overflow: ellipsis; /* Añade "..." al final del texto recortado */
  cursor: pointer;
}
</style>