<template>
  <div>
    <v-dialog
      v-model="muestraAgregaBenficio"
      content-class="fix-border-dialog"
      max-width="600"
      scrollable
    >
      <v-card class="rounded-xl">
        <v-card-title
          class="primary rounded-xl rounded-b-0 white--text subtitle-1 font-weight-bold"
        >
          Aministrar beneficio
          <v-spacer></v-spacer>
          <v-btn icon dark @click="$emit('ocultaAgregaBeneficio', 0)">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-form ref="formAddBeneficio">
            <v-row dense>
              <v-col
                :cols="['xs', 'sm'].includes($vuetify.breakpoint.name) ? 12 : 7"
              >
                <label class="overline"> nombre del beneficio </label>
                <v-text-field
                  v-model="nombre"
                  dense
                  class="rounded-lg"
                  required
                  :rules="rulesName"
                  counter="50"
                  outlined
                  placeholder="Escribe el nombre del beneficio..."
                ></v-text-field>
              </v-col>
              <v-col
                :cols="['xs', 'sm'].includes($vuetify.breakpoint.name) ? 12 : 5"
              >
                <label class="overline"> Monto de ayuda </label>
                <v-text-field
                  v-model="montoAyuda"
                  type="number"
                  dense
                  class="rounded-lg"
                  prefix="$"
                  outlined
                  placeholder="Ingresa el monto de ayuda..."
                ></v-text-field>
              </v-col>
              <v-col
                :cols="
                  ['xs', 'sm'].includes($vuetify.breakpoint.name) ? 12 : 12
                "
              >
                <label class="overline"> Descripcion </label>
                <v-textarea
                  v-model="descripcion"
                  dense
                  class="rounded-lg"
                  :rows="2"
                  required
                  :rules="rulesDesc"
                  counter="100"
                  outlined
                  placeholder="Ingresa la descripción del beneficio..."
                ></v-textarea>
              </v-col>
              <v-col
                :cols="['xs', 'sm'].includes($vuetify.breakpoint.name) ? 12 : 6"
              >
                <label class="overline"> Personal beneficiado </label>
                <v-select
                  v-model="personalBeneficiado"
                  dense
                  class="rounded-lg"
                  required
                  :rules="rulesSelect"
                  outlined
                  placeholder="Selecciona a los beneficiados..."
                  :items="tiposPersonal"
                  item-text="text"
                  value="value"
                ></v-select>
              </v-col>
              <v-col
                :cols="['xs', 'sm'].includes($vuetify.breakpoint.name) ? 12 : 6"
              >
                <label class="overline"> ¿Se solicita desde el portal? </label>
                <div class="center-item-in-div">
                  <v-switch
                    v-model="seSolicitaDesdeDH"
                    dense
                    class="my-1"
                  ></v-switch>
                </div>
              </v-col>
              <v-col
                :cols="['xs', 'sm'].includes($vuetify.breakpoint.name) ? 12 : 4"
              >
                <label class="overline"> ¿Tiene manual? </label>
                <div class="center-item-in-div">
                  <v-switch v-model="tieneManual" dense class="my-1"></v-switch>
                </div>
              </v-col>
              <v-col
                :cols="['xs', 'sm'].includes($vuetify.breakpoint.name) ? 12 : 8"
              >
                <label class="overline">
                  Selecciona el documento (opcional)
                </label>
                <v-file-input
                  v-model="file"
                  class="rounded-lg"
                  :disabled="!tieneManual"
                  placeholder="Selecciona el archivo..."
                  outlined
                  dense
                  accept=".pdf"
                ></v-file-input>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="pa-0">
          <v-btn
            class="rounded-xl rounded-t-0"
            block
            text
            color="teal darken-1"
            x-large
            :loading="loadingSave"
            @click="loadingSave = true; saveBeneficio()"
          >
            guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <SnackBar
      v-if="muestraBarra"
      :muestraBarra="muestraBarra"
      :color="colorBarra"
      :texto="textoBarra"
      @cierraBarra="muestraBarra = false"
    />
  </div>
</template>

<script>
import axios from "axios";
import SnackBar from "../../../General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue";

export default {
  name: "agrega-beneficio",
  props: ["muestraAgregaBenficio", "beneficio"],
  components: { SnackBar },
  data() {
    return {
      tiposPersonal: [
        { value: "1,2,3,4,5,6,7,8,9,10", text: "Todo el personal" },
        { value: "1,8,9,10", text: "Personal operativo" },
        { value: "2,3,4,5,6,7", text: "Personal administrativo" },
      ],
      rulesName: [
        (v) => !!v || "Campo requerido.",
        (v) =>
          (v && v.length <= 50) || "El campo debe tener menos de 50 caracteres",
      ],
      rulesDesc: [
        (v) => !!v || "Campo requerido.",
        (v) =>
          (v && v.length <= 100) ||
          "El campo debe tener menos de 100 caracteres",
      ],
      rulesSelect: [(v) => !!v || "Es necesaio que selecciones una opción.."],
      tieneManual: false,
      seSolicitaDesdeDH: false,
      loadingSave: false,
      muestraBarra: false,
      textoBarra: "",
      colorBarra: "",
      nombre: "",
      descripcion: "",
      file: null,
      personalBeneficiado: "",
      montoAyuda: 0,
    };
  },
  mounted() {
    if (this.beneficio != null) {
      this.nombre = this.beneficio.beneficio;
      this.montoAyuda = this.beneficio.montoAyuda;
      this.descripcion = this.beneficio.descBeneficio;
      this.personalBeneficiado = this.beneficio.beneficiados;
      this.tieneManual = this.beneficio.tiene_manual;
      this.seSolicitaDesdeDH = this.beneficio.posible_solicitud;
    }
  },
  methods: {
    muestraSnackBar(color, texto) {
      this.colorBarra = color;
      this.textoBarra = texto;
      this.muestraBarra = true;
    },
    async saveBeneficio() {
      if (this.$refs.formAddBeneficio.validate()) {
        let opc = this.beneficio === null ? 6 : 7;
        let fileBase64 = "";

        if (this.file != null) {
          fileBase64 = await this.readFileAsBase64(this.file);
        }

        const params = {
          Opcion: opc,
          token: localStorage.getItem("token"),
          nombre: this.nombre,
          montoAyuda: this.montoAyuda,
          descripcion: this.descripcion,
          personalBeneficiado: this.personalBeneficiado,
          tieneManual: this.tieneManual,
          seSolicitaDesdeDH: this.seSolicitaDesdeDH,
          idBeneficio: this.beneficio != null ? this.beneficio.id : 0,
          file: fileBase64,
        };
        axios
          .post(
            `${this.$store.getters.getHost}/Compensaciones/ws-beneficios.php`,
            params
          )
          .then((response) => {
            if (response.data.status == "OK") {
              if (response.data.respuesta.status_tran == "OK") {
                this.$refs.formAddBeneficio.reset()
                this.$refs.formAddBeneficio.resetValidation()
                this.muestraSnackBar(
                  "teal darken-1",
                  response.data.respuesta.message
                );
                this.$emit('ocultaAgregaBeneficio', 1)
              } else {
                this.muestraSnackBar(
                  "pink darken-3",
                  response.data.respuesta.message
                );
              }
            }
          })
          .catch((error) => {
            this.muestraSnackBar("pink darken-3", error);
          })
          .finally(() => {
            this.loadingSave = false;
          });
      }
    },
    async readFileAsBase64(file) {
      return new Promise((resolve, reject) => {
        if (!file) {
          reject(new Error("No file provided."));
          return;
        }

        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = () => {
          resolve(reader.result);
        };

        reader.onerror = (error) => {
          reject(error);
        };
      });
    },
  },
};
</script>
