<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-data-table :headers="headers" :items="files" item-key="id" class="elevation-1" :search="search"
          :footer-props="{
          'items-per-page-text': 'Elementos por página',
          'page-text': '{0}-{1} de {2}',
        }" no-data-text="No hay registros" :no-results-text="'No se encontraron coincidencias para: ' + search">
          <template v-slot:top>
            <div style="display: flex; justify-content: space-between;"
              class="primary pt-4 rounded-l-t-header-pases-admin rounded-r-t-header-pases-admin rounded-lg rounded-b-0">
              <v-text-field v-model="search" label="Buscar..." dense flat solo-inverted dark clearable
                append-icon="mdi-magnify" style="max-height: 42px; max-width: 400px;"
                class="mx-4 rounded-lg"></v-text-field>
            </div>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn class="mr-1" outlined icon color="green"
                  @click="validar(item.id, item.documento, item.numeroProv)">
                  <v-icon v-bind="attrs" v-on="on">mdi-check</v-icon>
                </v-btn>
              </template>
              <span>Validar</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn class="mr-1" outlined icon color="blue" @click="viewFile(item.nombreOriginal)">
                  <v-icon v-bind="attrs" v-on="on">mdi-eye</v-icon>
                </v-btn>
              </template>
              <span>Ver Documento</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn class="mr-1" outlined icon color="red"
                  @click="rechazar(item.id, item.documento, item.numeroProv)">
                  <v-icon v-bind="attrs" v-on="on">mdi-close</v-icon>
                </v-btn>
              </template>
              <span>Rechazar</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      search: '',
      headers: [
        { text: 'Proveedor', value: 'proveedor', class: 'font-weight-bold primary white--text' },
        { text: 'Documento', value: 'documento', class: 'font-weight-bold primary white--text' },
        { text: 'Fecha de Carga', value: 'fecha_subida', class: 'font-weight-bold primary white--text' },
        { text: 'Acciones', value: 'actions', sortable: false, class: 'font-weight-bold primary white--text' },
      ],
      files: [],
    };
  },
  methods: {
    mapearNombreArchivo(nombreArchivo) {
      const nombre = nombreArchivo.split('_')[1];
      const mapa = {
        domicilio: 'Comprobante de domicilio',
        stps: 'Permiso de REPSE',
        sat: 'Formato de Autorización ante el SAT',
        contrato: 'Contrato laboral',
        ine: 'INE Representante Legal',
        acta: 'Acta Constitutiva',
        imss: 'Registro Patronal ante el IMSS',
      };

      return mapa[nombre] || nombreArchivo;
    },
    async llenarTabla() {
      try {
        const response = await axios.get(`${this.$store.getters.getHostNode}/api/archivosPendientesLegal`, {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        });
        this.files = response.data.data.map((archivo) => ({
          id: archivo.id,
          proveedor: archivo.Nombre,
          documento: this.mapearNombreArchivo(archivo.Archivo),
          fecha_subida: archivo.Fecha_subida.split('T')[0],
          nombreOriginal: archivo.Archivo,
          numeroProv: archivo.No_proveedor
        }));
      } catch (error) {
        console.error('Error al obtener archivos pendientes:', error);
      }
    },
    async rechazar(id, nombreOriginal, numProv) {
      try {
        const datos = {
          ID: id,
          nombre: nombreOriginal,
          numero: numProv
        }
        const response = await axios.post(`${this.$store.getters.getHostNode}/api/rechazarArchivo`, datos, {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        });
        if (response.data.status) {
          this.showSnackbar('Validado Correctamente');
          this.llenarTabla();
        } else {
          this.showSnackbar('Ocurrió un error');
        }

        if (response.status) {
          this.llenarTabla();
        } else {
          console.error('Error al rechazar el archivo:', response.message);
        }
      } catch (error) {
        console.error('Error al rechazar el archivo:', error);
      }
    },

    async viewFile(nombre) {
      try {

        const response = await axios.get(
          `${this.$store.getters.getHostNode}/api/descargarArchivo/${nombre}/0`,
          {
            headers: {
              Authorization: localStorage.getItem('token'),
            },
          }
        );
        const { metadata, file: base64File } = response.data;
        const iv = new Uint8Array(JSON.parse(metadata.iv));
        const key = await this.importKey(JSON.parse(metadata.key));
        const encryptedData = Uint8Array.from(atob(base64File), (c) => c.charCodeAt(0)).buffer;
        const decryptedArrayBuffer = await crypto.subtle.decrypt(
          {
            name: 'AES-GCM',
            iv: iv,
          },
          key,
          encryptedData
        );
        const decryptedBlob = new Blob([new Uint8Array(decryptedArrayBuffer)], { type: 'application/pdf' });
        const decryptedUrl = URL.createObjectURL(decryptedBlob);
        window.open(decryptedUrl);
      } catch (error) {
        this.showSnackbar('Primero carga un archivo');
      }
    },
    async validar(id, nombreOriginal, numProv) {
      try {
        const datos = {
          ID: id,
          nombre: nombreOriginal,
          numero: numProv
        }
        const response = await axios.post(`${this.$store.getters.getHostNode}/api/validarLegal`, datos, {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        });
        if (response.data.status) {
          this.showSnackbar('Validado Correctamente');
          this.llenarTabla();
        } else {
          this.showSnackbar('Ocurrió un error');
        }
      } catch (error) {
        console.error('Error al validar el archivo', error);
        this.showSnackbar('Error al validar el archivo');
      }
    },
    async importKey(jwk) {
      const key = await crypto.subtle.importKey(
        'jwk',
        jwk,
        {
          name: 'AES-GCM',
        },
        true,
        ['encrypt', 'decrypt']
      );
      return key;
    },
    showSnackbar(message) {
      this.text = message;
      this.snackbar = true;
    },
  },
  mounted() {
    this.llenarTabla();
  },
};
</script>
