import VueNumber from 'vue-number-animation'
import Detalles from './components/DialogDetails.vue'
import MuestraDetallesVac from '../../../RelacionesLaborales/Incidencias/Vacaciones/components/MuestraDetallesVacaciones.vue'
import Vacaciones from '../../MisIncidencias/components/IncidenciaVacaciones.vue'
import DataTableGeneriec from '../../../../../components/componentsExtra/DataTableGeneric.vue'
import axios from 'axios'

export default {
    name: 'mis-vacaciones',
    components: {
        VueNumber,
        Detalles,
        MuestraDetallesVac,
        Vacaciones,
        DataTableGeneriec
    },
    props: [],
    data() {
        return {
            muestraDetalles: false,
            muestraDetallesUsuario: false,
            muestraVacaciones: false,
            muestraPeriodos: false,
            text: "",
            tituloDialog: "",
            titulo: "Días otorgados por antigüedad",
            key: 0,
            keyVacaciones: 0,
            opcionTab: 0,
            emplid: null,
            empleado: {
                fecha_contratacion: "",
                fecha_ultimo_periodo: "",
                subtitle: "",
                text2: "",
                tiempoLaborando: 0,
                dias_otorgados_portal_dh_aniv: 0,
                dias_otorgados_portal_dh_prop: 0,
                dias_otorgados_portal_dh: 0,
                dias_por_anios_completos: 0,
                dias_solicitados: 0,
                acu_total: 0,
                dias_periodo_actual: 0
            },
            vac_x_per: [],
            headersPeriodosVac: [{
                text: 'periodo (años de antigüedad)',
                align: 'center',
                class: 'text-uppercase font-weight-bold blue-grey--text text--lighten-2',
                value: 'anioAntiguedad'
            }, {
                text: 'Días correspondientes',
                align: 'center',
                class: 'text-uppercase font-weight-bold blue-grey--text text--lighten-2',
                value: 'diasCorresp'
            }],
            windowSize: {
                x: 0,
                y: 0,
            },
        }
    },
    computed: {

    },
    mounted() {
        this.onResize();
        this.getDataEmployee();
    },
    methods: {
        onResize() {
            this.windowSize = {
                x: window.innerWidth,
                y: window.innerHeight,
            };
        },
        MostrarTablaVac() {
            this.vac_x_per.length = 0;
            let params = {
                Opcion: 4,
                token: localStorage.getItem('token')
            }
            axios
                .post(`${ this.$store.getters.getHost }/Incidencias/ws-incidencias-vacaciones.php `, params)
                .then((response) => {
                    if (response.data.status != "EXPSESSION") {
                        this.vac_x_per = response.data.reporte
                            // response.data.reporte.map(item => {
                            //     item.tab_sectab--;
                            //     item.tab_eleuno = (parseFloat(item.tab_eleuno) - 1).toFixed(2);
                            //     item.tab_eledos = (parseFloat(item.tab_eledos) - 1).toFixed(2);
                            //     item.tab_eletre = parseFloat(item.tab_eletre);
                            //     item.tab_elecua = parseFloat(item.tab_elecua);
                            //     if (item.tab_sectab > 0) {
                            //         this.vac_x_per.push(item);
                            //     }
                            // });
                        this.muestraPeriodos = true;
                    }
                })
        },
        SolicitarVac() {
            this.keyVacaciones++;
            this.muestraVacaciones = true;
        },
        getDataVac(opc) {
            this.emplid = this.$store.getters.getUser['Folio'];
            this.opcionTab = opc;
            console.log(this.emplid)
            this.key++;
            this.muestraDetallesUsuario = true;
        },
        showDetails(opc) {
            switch (opc) {
                case 1:
                    {
                        this.tituloDialog = "Días por aniversario"
                        this.text =
                        "Son todos aquellos dias que se te han otorgado desde que entraste " +
                        "a la empresa hasta la fecha de tu último aniversario. Es decir la suma " +
                        "de días de vacaciones, desde " + this.empleado.fecha_contratacion + " hasta " + this.empleado.fecha_ultimo_periodo +
                        ". A estos días le restamos los días que ya has solicitado.";
                        this.subtitle = "Formula";
                        this.text2 = "Días otorgados - Días solicitados = Días diponibles por aniversario."
                        break;
                    }
                case 2:
                    {
                        const meses = 12 / parseInt(this.empleado.dias_periodo_actual, 10);
                        this.tituloDialog = "Días proporcionales"
                        this.text =
                        "Son todos aquellos dias que se te otorgan después de tu último aviversario. " +
                        "Son días que se entregan proporcionalmente durante el año en curso según tu antigüedad. Es decir, " +
                        "Tu cuentas con una antigüedad de " + this.empleado.tiempoLaborando + " eso quiere decir que al final de este periodo tendrás " +
                        parseInt(this.empleado.dias_periodo_actual, 10).toFixed(0) + " los cuales se te irán otorgando cada " + meses.toFixed(2) + " meses.";
                        this.subtitle = "Formula";
                        this.text2 = "(12 meses del año / Días del petiodo actual) - días solicitados = Días proporcionales."
                        break;
                    }
            }
            this.muestraDetalles = true;
        },
        getDataEmployee() {
            let params = {
                Opcion: 8,
                token: localStorage.getItem('token')
            }
            axios.post(`
            ${ this.$store.getters.getHost }/Incidencias/ws-incidencias-vacaciones.php`, params).then(response => {
                if (response.data.status == 'OK') {
                    // this.muestraLoad = false;
                    let data = response.data.reporte;
                    this.empleado = data;
                }
            })
        }
    }
}