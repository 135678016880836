<template>
  <v-card class="rounded-xl elevation-0 pa-3">
    <v-card-title
      class="text-h5 pt-0  text-uppercase blue-grey--text text--darken-2"
    >
      {{ mes }}
    </v-card-title>
    <v-row>
      <v-col cols="4"
        ><v-card
          class="rounded-xl elevation-0"
          style="border: 1.8px solid #5E35B1 !important;"
          color="indigo lighten-5"
          @click="
            muestraIngresos = true;
            getIngresosPorMes();
          "
        >
          <v-card-title>
            <v-icon left large>mdi-cash-plus</v-icon>
            <div
              class="font-weight-bold subtitle-1 text-uppercase blue-grey--text text--darken-2"
            >
              Total de ingresos
            </div>
          </v-card-title>
          <v-card-text>
            <div
              v-if="loadingIngresosBtn"
              class="text-center text-h5 font-weight-bold"
            >
              <v-progress-circular
                :width="5"
                color="blue-grey darken-2"
                indeterminate
              ></v-progress-circular>
            </div>
            <div v-else class="text-center text-h5 font-weight-bold">
              {{ formatMXN(parseFloat(cuadre.ingresos)) }}
            </div>
          </v-card-text>
        </v-card></v-col
      >
      <v-col cols="4"
        ><v-card
          class="rounded-xl elevation-0"
          style="border: 1.8px solid #5E35B1 !important;"
          color="indigo lighten-5"
          @click="
            muestraEgresos = true;
            getEgresosPorMes();
          "
        >
          <v-card-title>
            <v-icon left large>mdi-cash-minus</v-icon>
            <div
              class="font-weight-bold subtitle-1 text-uppercase blue-grey--text text--darken-2"
            >
              Total de egresos
            </div>
          </v-card-title>
          <v-card-text>
            <div
              v-if="loadingEgresosBtn"
              class="text-center text-h5 font-weight-bold"
            >
              <v-progress-circular
                :width="5"
                color="blue-grey darken-2"
                indeterminate
              ></v-progress-circular>
            </div>
            <div v-else class="text-center text-h5 font-weight-bold">
              {{ formatMXN(parseFloat(cuadre.egresos)) }}
            </div>
          </v-card-text>
        </v-card></v-col
      >
      <v-col cols="4"
        ><v-card
          class="rounded-xl elevation-0"
          style="border: 1.8px solid #5E35B1 !important;"
          color="indigo lighten-5"
        >
          <v-card-title>
            <v-icon left large>mdi-cash-check</v-icon>
            <div
              class="font-weight-bold subtitle-1 text-uppercase blue-grey--text text--darken-2"
            >
              Total rendimiento
            </div>
          </v-card-title>
          <v-card-text>
            <div v-if="cuadre" class="text-center text-h5 font-weight-bold">
              {{ formatMXN(parseFloat(cuadre.ingresos - cuadre.egresos)) }}
            </div>
          </v-card-text>
        </v-card></v-col
      >
    </v-row>

    <div
      v-if="(ingresos.length == 0 && muestraIngresos) || (egresos.length == 0 && muestraEgresos)"
      class="text-center text-h5 font-weight-bold py-4"
    >
      <v-progress-circular
        :width="15"
        :size="70"
        color="blue-grey darken-2"
        indeterminate
      ></v-progress-circular>
    </div>

    <v-row v-if="ingresos.length > 0 && muestraIngresos">
      <v-col v-for="(item, index) in ingresos" :key="index">
        <v-card
          class="rounded-xl elevation-0"
          style="border: 1.8px solid #000000 !important;"
          color="blue-grey lighten-5"
        >
          <v-card-title>
            <div
              class="font-weight-bold subtitle-2 text-uppercase blue-grey--text text--darken-2"
            >
              {{ item.DESCR }}
            </div>
          </v-card-title>
          <v-card-text>
            <div
              class="text-center text-h6 font-weight-bold"
            >
              {{ formatMXN(parseFloat(item.total)) }}
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="egresos.length > 0 && muestraEgresos">
      <v-col v-for="(item, index) in egresos" :key="index">
        <v-card
          class="rounded-xl elevation-0"
          style="border: 1.8px solid #000000 !important;"
          color="blue-grey lighten-5"
        >
          <v-card-title>
            <div
              class="font-weight-bold subtitle-2 text-uppercase blue-grey--text text--darken-2"
            >
              {{ item.DESCR }}
            </div>
          </v-card-title>
          <v-card-text>
            <div v-if="cuadre" class="text-center text-h6 font-weight-bold">
              {{ formatMXN(parseFloat(item.total)) }}
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
// import { formatDate } from "../../../../../../store/Funciones/funciones.js";
import axios from "axios";
export default {
  name: "ofertas-economicas-pendientes",
  props: ["estatus"],
  components: {},
  data() {
    return {
      mes: "",
      numMes: 0,
      cuadre: {
        ingresos: 0,
        egresos: 0,
      },
      ingresos: [],
      egresos: [],
      loadingData: false,
      loadingIngresos: false,
      loadingEgresos: false,
      loadingIngresosBtn: true,
      loadingEgresosBtn: true,
      muestraIngresos: false,
      muestraEgresos: false,
    };
  },
  async mounted() {
    this.mes = await this.getMes();
    this.cuadre.ingresos = await this.getCuadrePorMesIngresos();
    this.cuadre.egresos = await this.getCuadrePorMesEgresos();
  },
  methods: {
    async getMes() {
      const meses = [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
      ];
      const fechaActual = new Date();
      fechaActual.setMonth(fechaActual.getMonth() - 1);
      this.numMes = fechaActual.getMonth() + 1;
      const nombreMesPasado = meses[fechaActual.getMonth()];

      return nombreMesPasado;
    },
    async getCuadrePorMesIngresos() {
      let params = {
        Opcion: 3,
        token: localStorage.getItem("token"),
        mes: this.numMes,
      };
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${this.$store.getters.getHost}/Nominas/AdmCajaAhorro/ws-reportes.php`,
            params
          )
          .then((response) => {
            if (response.data.status == "OK") {
              resolve(response.data.ingresos);
            }
          })
          .catch((error) => {
            reject({
              error: error,
            });
          })
          .finally(() => {
            this.loadingIngresosBtn = false;
          });
      });
    },
    async getCuadrePorMesEgresos() {
      let params = {
        Opcion: 9,
        token: localStorage.getItem("token"),
        mes: this.numMes,
      };
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${this.$store.getters.getHost}/Nominas/AdmCajaAhorro/ws-reportes.php`,
            params
          )
          .then((response) => {
            if (response.data.status == "OK") {
              resolve(response.data.egresos);
            }
          })
          .catch((error) => {
            reject({
              error: error,
            });
          })
          .finally(() => {
            this.loadingEgresosBtn = false;
          });
      });
    },
    getIngresosPorMes() {
      this.muestraEgresos = false;
      this.ingresos.length = 0;
      let params = {
        Opcion: 4,
        token: localStorage.getItem("token"),
        mes: this.numMes,
      };
      axios
        .post(
          `${this.$store.getters.getHost}/Nominas/AdmCajaAhorro/ws-reportes.php`,
          params
        )
        .then((response) => {
          if (response.data.status == "OK") {
            this.ingresos = response.data.ingresos;
          }
        })
        .catch((error) => {
          alert({
            error: error,
          });
        })
        .finally(() => {
          this.loadingIngresos = false;
        });
    },
    getEgresosPorMes() {
      this.muestraIngresos = false;
      this.egresos.length = 0;
      let params = {
        Opcion: 5,
        token: localStorage.getItem("token"),
        mes: this.numMes,
      };
      axios
        .post(
          `${this.$store.getters.getHost}/Nominas/AdmCajaAhorro/ws-reportes.php`,
          params
        )
        .then((response) => {
          if (response.data.status == "OK") {
            this.egresos = response.data.egresos;
          }
        })
        .catch((error) => {
          alert({
            error: error,
          });
        })
        .finally(() => {
          this.loadingEgresos = false;
        });
    },
    formatMXN(cadena) {
      return cadena.toLocaleString("es-MX", {
        style: "currency",
        currency: "MXN",
      });
    },
  },
};
</script>
