<template>
  <div>
    <v-row
      v-if="evActivas == 0 && evaluacionesHist.length == 0 && loadingData"
      class="fill-height"
      align-content="center"
      justify="center"
    >
      <v-col class="text-subtitle-1 text-center" cols="12">
        {{ loadingText }}
      </v-col>
      <v-col cols="6">
        <v-progress-linear
          color="deep-purple accent-4"
          indeterminate
          rounded
          height="6"
        ></v-progress-linear>
      </v-col>
    </v-row>
    <div
      v-else-if="
        evActivas == 0 && evaluacionesHist.length > 0 && !sinEvaluaciones
      "
    >
      <v-card class="rounded-xl" elevation="2">
        <p
          class="text-center grey--text text--darken-1"
          style="font-weight: 600; font-size:1.15rem;"
        >
          {{ $t("DH360.evalHistoryTitleTable") }}
        </p>
        <v-data-table
          :items="evaluacionesHist"
          :headers="headersHist"
          hide-default-footer
          dense
          no-data-text="No hay registros"
          :header-props="headerProps"
        >
        </v-data-table>
      </v-card>
    </div>
    <p
      v-else-if="
        evActivas == 0 && evaluacionesHist.length == 0 && sinEvaluaciones
      "
      class="text-center grey--text text--darken-1"
      style="font-weight: 500; font-size:1.15rem;"
    >
      {{ $t("DH360.noCompanyData") }}
    </p>
    <v-container
      fluid
      v-else-if="
        !loadingData && evActivas > 0 && estatusProyecto.ESTATUS == 'INICIO'
      "
    >
      <p
        class="text-center mb-0 font-weight-bold font-italic caption grey--text text--darken-2"
      >
        {{ $t("DH360.txtNameProyectData") }}: {{ estatusProyecto.DESCRIPCION }}.
        {{ $t("DH360.txtDateProyectData") }}:
        {{ estatusProyecto.FECHA_CREACION }}.
      </p>
      <AdmonParticipantes
        :participantes="participantes"
        :estatus="estatusProyecto.ESTATUS"
        :idProyecto="estatusProyecto.ID"
        :compania="compania"
        @updateEstatus="updateEstatus"
      />
    </v-container>
    <v-container
      fluid
      v-else-if="
        !loadingData &&
          evActivas > 0 &&
          estatusProyecto.ESTATUS == 'RESPONSABLE'
      "
    >
      <p
        class="text-center mb-0 font-weight-bold font-italic caption grey--text text--darken-2"
      >
        {{ $t("DH360.txtNameProyectData") }}: {{ estatusProyecto.DESCRIPCION }}.
        {{ $t("DH360.txtDateProyectData") }}:
        {{ estatusProyecto.FECHA_CREACION }}.
      </p>
      <AdmonFamilias360
        :participantes="participantesDefinitivos"
        :estatus="estatusProyecto.ESTATUS"
        :idProyecto="estatusProyecto.ID"
        :proyecto="estatusProyecto"
        :compania="compania"
        @updateEstatus="updateEstatus"
      />
    </v-container>
    <v-container
      fluid
      v-else-if="
        !loadingData &&
          evActivas > 0 &&
          (estatusProyecto.ESTATUS == 'NOTIFICACION1' ||
            estatusProyecto.ESTATUS == 'PROCESO')
      "
    >
      <p
        class="text-center mb-0 font-weight-bold font-italic caption grey--text text--darken-2"
      >
        {{ $t("DH360.txtNameProyectData") }}: {{ estatusProyecto.DESCRIPCION }}.
        {{ $t("DH360.txtDateProyectData") }}:
        {{ estatusProyecto.FECHA_CREACION }}.
      </p>
      <AdmonReportes360
        :idProyecto="estatusProyecto.ID"
        :company="estatusProyecto.COMPANIA"
        :estatus="estatusProyecto.ESTATUS"
        @updateEstatus="updateEstatus"
      />
    </v-container>
    <v-container
      fluid
      class="px-0 mx-0"
      v-else-if="
        !loadingData && evActivas > 0 && estatusProyecto.ESTATUS == 'RANKING'
      "
    >
      <p
        class="text-center mb-0 font-weight-bold font-italic caption grey--text text--darken-2"
      >
        {{ $t("DH360.txtNameProyectData") }}: {{ estatusProyecto.DESCRIPCION }}.
        {{ $t("DH360.txtDateProyectData") }}:
        {{ estatusProyecto.FECHA_CREACION }}.
      </p>
      <AdmonRanking
        :idProyecto="estatusProyecto.ID"
        :evaluacionDesempenio="rankings"
        @updateEstatus="updateEstatus"
      />
    </v-container>
  </div>
</template>

<script>
import { deleteToken } from "../../../../../../store/Funciones/funciones";
import { formatDate } from "../../../../../../store/Funciones/funciones";
import axios from "axios";
import AdmonParticipantes from "./AdmonParticipantes.vue";
import AdmonFamilias360 from "./AdmonFamilias360.vue";
import AdmonReportes360 from "./AdmonReportes360.vue";
import AdmonRanking from "./AdmonRanking.vue";

export default {
  name: "muestra-evaluaciones",
  components: {
    AdmonParticipantes,
    AdmonFamilias360,
    AdmonReportes360,
    AdmonRanking,
  },
  props: ["compania"],
  data() {
    return {
      loadingText: "",
      evActivas: 0,
      sinEvaluaciones: true,
      loadingData: true,
      evaluacionesHist: [],
      participantes: [],
      participantesDefinitivos: [],
      rankings: [],
      headersHist: [
        { value: "ID", text: "ID" },
        { value: "DESCRIPCION", text: "Descripción" },
        { value: "ESTATUS", text: "Estatus" },
        { value: "FECHA_CREACION", text: "Inicio" },
        { value: "FECHA_TERMINADO", text: "Fin" },
      ],
      headerProps: {
        sortByText: "Ordenar por",
      },
      estatusProyecto: {
        ID: 0,
        DESCRIPCION: "",
        FAMILIA: 0,
        ESTATUS: "",
        FECHA_CREACION: "",
        FECHA_TERMINADO: null,
        COMPANIA: "",
        CREADO_POR: 0,
        NOMBRE: "",
      },
    };
  },
  computed: {},
  mounted() {
    this.loadingText = this.$t("DH360.findEvalActive");
    this.getCountEncuestasActivas();
  },
  methods: {
    getCountEncuestasActivas() {
      let params = {
        Opcion: 3,
        token: localStorage.getItem("token"),
        compania: this.compania,
      };
      axios
        .post(
          `${this.$store.getters.getHost}/RelacionesLaborales/AdmonEvaluacionesDesempenio/ws-admon-360.php`,
          params
        )
        .then((response) => {
          if (response.data.status == "EXPRESSION") {
            deleteToken();
          } else if (response.data.status == "OK") {
            if (response.data.total == 0) {
              this.getHistorialEvaluaciones();
            } else if (response.data.total > 0) {
              this.getEstatus();
            }
          } else {
            alert("error");
          }
        });
    },

    getHistorialEvaluaciones() {
      this.loadingText = this.$t("DH360.getHistEval");
      let params = {
        Opcion: 4,
        token: localStorage.getItem("token"),
        compania: this.compania,
      };
      axios
        .post(
          `${this.$store.getters.getHost}/RelacionesLaborales/AdmonEvaluacionesDesempenio/ws-admon-360.php`,
          params
        )
        .then((response) => {
          this.loadingData = false;
          if (response.data.status == "EXPRESSION") {
            deleteToken();
          } else if (response.data.status == "OK") {
            this.evaluacionesHist = response.data.historial;
            if (this.evaluacionesHist.length > 0) {
              this.sinEvaluaciones = false;
            } else {
              this.sinEvaluaciones = true;
            }
          } else {
            this.sinEvaluaciones = true;
          }
        });
    },

    getEstatus() {
      this.loadingText = this.$t("DH360.loadingGetStatusProject");
      let params = {
        Opcion: 6,
        token: localStorage.getItem("token"),
        compania: this.compania,
      };
      axios
        .post(
          `${this.$store.getters.getHost}/RelacionesLaborales/AdmonEvaluacionesDesempenio/ws-admon-360.php`,
          params
        )
        .then((response) => {
          if (response.data.status == "EXPRESSION") {
            deleteToken();
          } else if (response.data.status == "OK") {
            this.estatusProyecto = response.data.estatusProyecto;
            if (this.estatusProyecto.ESTATUS === "INICIO") {
              this.getPosiblesParticipantes();
            } else if (this.estatusProyecto.ESTATUS === "RESPONSABLE") {
              this.getParticipantes360();
            } else if (
              this.estatusProyecto.ESTATUS === "NOTIFICACION1" ||
              this.estatusProyecto.ESTATUS === "PROCESO"
            ) {
              this.getReportes();
            } else if (this.estatusProyecto.ESTATUS === "RANKING") {
              this.getRankings();
            }
          }
        });
    },

    getPosiblesParticipantes() {
      this.loadingText = this.$t("DH360.gettingParticipants");
      let params = {
        Opcion: 7,
        compania: this.compania,
        token: localStorage.getItem("token"),
      };
      axios
        .post(
          `${this.$store.getters.getHost}/RelacionesLaborales/AdmonEvaluacionesDesempenio/ws-admon-360.php`,
          params
        )
        .then((response) => {
          if (response.data.status == "EXPRESSION") {
            deleteToken();
          } else if (response.data.status == "OK") {
            this.participantes = response.data.participantes;
            this.loadingData = false;
            this.evActivas = 1;
          }
        });
    },

    getParticipantes360() {
      let today = new Date();
      this.loadingText =
        this.$t("DH360.textLoadingParticipants360") + today.getFullYear();
      let params = {
        Opcion: 11,
        token: localStorage.getItem("token"),
        idProyecto: this.estatusProyecto.ID,
        compania:this.compania,
        editarRelaciones: 1,
      };
      console.log("Hola");
      axios
        .post(
          `${this.$store.getters.getHost}/RelacionesLaborales/AdmonEvaluacionesDesempenio/ws-admon-360.php`,
          params
        )
        .then((response) => {
          if (response.data.status == "EXPRESSION") {
            deleteToken();
          } else if (response.data.status == "OK") {
            this.participantesDefinitivos = response.data.participantes;
            this.loadingData = false;
            this.evActivas = 1;
          }
        });
    },

    updateEstatus(estatus) {
      this.loadingText = this.$t("DH360.gettingParticipants");
      let params = {
        Opcion: 10,
        token: localStorage.getItem("token"),
        estatus: estatus,
        idProyecto: this.estatusProyecto.ID,
      };
      axios
        .post(
          `${this.$store.getters.getHost}/RelacionesLaborales/AdmonEvaluacionesDesempenio/ws-admon-360.php`,
          params
        )
        .then((response) => {
          if (response.data.status == "EXPRESSION") {
            deleteToken();
          } else if (response.data.status == "OK") {
            this.respApiUpdateStatus = response.data.resultado;
            if (this.respApiUpdateStatus > 0) {
              this.getEstatus();
            }
          }
        });
    },

    getReportes() {
      this.loadingData = false;
      this.evActivas = 1;
    },

    getRankings() {
      this.loadingText = this.$t("DH360.txtLoadingRankings") + "...";
      let params = {
        Opcion: 39,
        token: localStorage.getItem("token"),
        idProyecto: this.estatusProyecto.ID,
      };
      axios
        .post(
          `${this.$store.getters.getHost}/RelacionesLaborales/AdmonEvaluacionesDesempenio/ws-admon-360.php`,
          params
        )
        .then((response) => {
          if (response.data.status == "EXPRESSION") {
            deleteToken();
          } else if (response.data.status == "OK") {
            response.data.respuesta.map((r) => {
              this.rankings.push({
                NumeroPersonal: r.NumeroPersonal,
                Nombre: r.Nombre,
                Estatus: r.Estatus,
                FechaActualizacion: this.fixFormatDate(
                  r.FechaActualizacion.date
                ),
                PuntosEval: r.PuntosEval,
                PuntosObjetivos: r.PuntosObjetivos,
                PuntosLogros: r.PuntosLogros,
                Puntos360: r.Puntos360,
                PuntosMejoras: r.PuntosMejoras,
                AnioEvaluacion: r.AnioEvaluacion,
                PorcentajeIncremento: r.PorcentajeIncremento,
                Ranking: r.Ranking,
                RankingFinal: r.RankingFinal,
                Categoria: r.Categoria,
              });
            });
            this.loadingData = false;
            this.evActivas = 1;
          }
        });
    },

    fixFormatDate(fecha) {
      if (fecha != null || fecha != "") {
        return formatDate(fecha.substring(0, 10));
      }

      return "";
    },
  },
};
</script>
