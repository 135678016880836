<template>
  <v-dialog v-model="showConfirm" persistent max-width="350">
    <v-card class="rounded-lg">
      <v-card-text class="pt-5">
        <div style="display: flex; justify-content: center;">
          <v-img
            class="text-center"
            :src="require('@/assets/icons/advertencia.png')"
            max-width="80"
          ></v-img>
        </div>
        <p class="subtitle-2 text-uppercase font-weight-bold text-center mb-3">¡Advertencia!</p>
        <p class="subtitle-2 text-uppercase  text-center mb-3">
          ¿Realmente deseas eliminar a {{ nombre }}?
        </p>
      </v-card-text>
      <v-card-actions style="display: flex; justify-content: space-around;">
        <v-btn
          depressed
          class="rounded-lg"
          style="width:45%"
          dark
          color="pink darken-3"
          @click="$emit('cierraConfirm', false)"
          >cancelar</v-btn
        >
        <v-btn
          depressed
          class="rounded-lg"
          style="width:45%"
          dark
          color="teal darken-1"
          @click="$emit('cierraConfirm', true)"
          >confirmar</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "confirm-dialog",
  components: {},
  props: ["showConfirm", "nombre"],
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {},
};
</script>
