<template>
  <v-row justify="center">
    <v-dialog
      v-model="muestraDetallesVacante"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark :color="colorPrincipal">
          <v-toolbar-title>
            {{ detalleVacante.Nombre }} ({{ idVacanteInfo }})
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn icon fab dark @click="$emit('cierraDetallesVacante')">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <div v-if="loadingData">
          <v-row
            class="fill-height"
            style="margin-top:10%;"
            align-content="center"
            justify="center"
          >
            <v-col class="text-center" cols="12">
              <h2>Obteniento información...</h2>
            </v-col>
            <v-col cols="6">
              <v-progress-linear
                :color="colorPrincipal"
                indeterminate
                rounded
                height="6"
              ></v-progress-linear>
            </v-col>
          </v-row>
        </div>
        <div v-else>
          <h3
            class="text-center blue-grey--text text--lighten-2 text-uppercase mt-3"
          >
            Información general
          </h3>
          <v-container>
            <v-sheet
              class="subtitle-1 blue-grey--text text--darken-2 font-weight-bold"
            >
              Descripción de actividades.
            </v-sheet>
            <p
              class="text-justify"
              style="font-size:0.9em;"
              v-if="
                detalleVacante.mercer == '' || detalleVacante.mercer == null
              "
            >
              {{ detalleVacante.productivity }}
            </p>
            <p class="text-justify" style="font-size:0.9em;" v-else>
              {{ detalleVacante.mercer }}
            </p>
            <v-row>
              <v-col
                :cols="
                  numberOfColums == 6 ? numberOfColums - 2 : numberOfColums
                "
              >
                <v-card class="rounded-xl elevation-0 outlined-custom-card">
                  <v-card-title
                    class="pt-0 pb-1 subtitle-2 text-uppercase font-weight-bold blue-grey--text text--darken-3"
                  >
                    Información salarial.
                  </v-card-title>
                  <v-card-text>
                    <v-row>
                      <v-col cols="3"> </v-col>
                      <v-col cols="6">
                        <v-card class="rounded-lg outlined-custom-card">
                          <div
                            class="pl-4 caption text-uppercase font-weight-bold blue-grey--text text--darken-3"
                          >
                            Contratación
                          </div>
                          <div
                            class="pl-4 subtitle-1 text-uppercase blue-grey--text text--darken-3"
                          >
                            {{
                              formatMXN(parseFloat(detalleVacante.Contratacion))
                            }}
                          </div>
                        </v-card>
                      </v-col>
                      <v-col cols="3"> </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col
                :cols="
                  numberOfColums == 6 ? numberOfColums + 2 : numberOfColums
                "
                v-if="detalleVacante.equipo_trabajo.req_laptop"
              >
                <v-card
                  class="rounded-xl elevation-0 outlined-custom-card"
                  height="100%"
                >
                  <v-card-title
                    class="pt-0 pb-1 subtitle-2 text-uppercase font-weight-bold blue-grey--text text--darken-3"
                  >
                    Equipo de trabajo.
                  </v-card-title>
                  <v-row class="px-4 pt-2 center-item-in-div">
                    <v-col cols="4">
                      <v-chip
                        class="rounded-lg elevation-1 font-weight-bold"
                        outlined
                        :color="
                          detalleVacante.equipo_trabajo.req_laptop === 1
                            ? 'teal darken-2'
                            : 'pink darken-3'
                        "
                        style="width: 100%;"
                      >
                        <v-icon left>
                          {{
                            detalleVacante.equipo_trabajo.req_laptop === 1
                              ? "mdi-check-circle-outline"
                              : "mdi-close-circle-outline"
                          }}
                        </v-icon>
                        Laptop
                      </v-chip>
                    </v-col>
                    <v-col cols="4">
                      <v-chip
                        class="rounded-lg elevation-1 font-weight-bold"
                        outlined
                        :color="
                          detalleVacante.equipo_trabajo.req_celular === 1
                            ? 'teal darken-2'
                            : 'pink darken-3'
                        "
                        style="width: 100%;"
                      >
                        <v-icon left>
                          {{
                            detalleVacante.equipo_trabajo.req_celular === 1
                              ? "mdi-check-circle-outline"
                              : "mdi-close-circle-outline"
                          }}
                        </v-icon>
                        Celular
                      </v-chip>
                    </v-col>
                    <v-col cols="4">
                      <v-chip
                        class="rounded-lg elevation-1 font-weight-bold"
                        outlined
                        :color="
                          detalleVacante.equipo_trabajo.req_epp === 1
                            ? 'teal darken-2'
                            : 'pink darken-3'
                        "
                        style="width: 100%;"
                      >
                        <v-icon left>
                          {{
                            detalleVacante.equipo_trabajo.req_epp === 1
                              ? "mdi-check-circle-outline"
                              : "mdi-close-circle-outline"
                          }}
                        </v-icon>
                        EPP
                      </v-chip>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
            <div class="pb-4 pt-2 center-item-in-div">
                <v-card class="rounded-lg mx-2 outlined-custom-card" width="190px">
                  <div
                    class="caption text-uppercase text-center font-weight-bold blue-grey--text text--darken-3"
                  >
                    Posiciones solicitadas
                  </div>
                  <div
                    class="body-1 text-uppercase blue-grey--text text--darken-3 font-weight-black text-center"
                  >
                    {{ detalleVacante.num_vacantes }}
                  </div>
                </v-card>
                <!-- {{detalleVacante}} -->
                <v-card v-if="detalleVacante.comision == 1" class="rounded-lg mx-2 outlined-custom-card" width="130px">
                  <div
                    class="caption text-uppercase text-center font-weight-bold blue-grey--text text--darken-3"
                  >
                    Comisión
                  </div>
                  <div
                    class="body-1 text-uppercase blue-grey--text text--darken-3 font-weight-black text-center"
                  >
                    {{ formatMXN(parseFloat(detalleVacante.comisionval ))}}
                  </div>
                </v-card>
                <v-card v-if="detalleVacante.comision == 1" class="rounded-lg mx-2 outlined-custom-card" width="130px">
                  <div
                    class="caption text-uppercase text-center font-weight-bold blue-grey--text text--darken-3"
                  >
                    Garantría
                  </div>
                  <div
                    class="body-1 text-uppercase blue-grey--text text--darken-3 font-weight-black text-center"
                  >
                  {{ formatMXN(parseFloat(detalleVacante.comisiongar ))}}
                  </div>
                </v-card>
                <v-card v-if="detalleVacante.comision == 1" class="rounded-lg mx-2 outlined-custom-card" width="130px">
                  <div
                    class="caption text-uppercase text-center font-weight-bold blue-grey--text text--darken-3"
                  >
                    Meses
                  </div>
                  <div
                    class="body-1 text-uppercase blue-grey--text text--darken-3 font-weight-black text-center"
                  >
                    {{ detalleVacante.comisionmes }}
                  </div>
                </v-card>
            </div>
            <v-divider></v-divider>

            <v-row class="px-5 mt-2">
              <v-col :cols="numberOfColums">
                <v-card
                  :elevation="isRequester ? 5 : 0"
                  class="rounded-xl outlined-custom-card v-card--transition slide-y-transition"
                  height="100%"
                >
                  <v-toolbar dense elevation="0">
                    <v-toolbar-title
                      class="font-weight-bold subtitle-1 text-uppercase blue--text text--darken-1"
                    >
                      Información de solicitante
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn
                      small
                      outlined
                      class="rounded-pill"
                      :color="isRequester ? 'pink darken-3' : 'blue darken-1'"
                      @click="
                        selectFunctionToEdit(1);
                        loadingAreas = true;
                        getAreas();
                      "
                    >
                      {{ isRequester ? "cancelar" : "editar" }}
                      <v-icon small right>{{
                        isRequester ? "mdi-close-thick" : "mdi-pencil"
                      }}</v-icon>
                    </v-btn>
                  </v-toolbar>
                  <v-card-text>
                    <div v-if="!isRequester">
                      <div
                        class="font-weight-bold subtitle-2 text-uppercase blue--text text--darken-1"
                      >
                        Área
                      </div>
                      <div
                        class="text-uppercase caption blue-grey--text text--darken-1"
                      >
                        {{ detalleVacante.Area }}
                      </div>
                      <div
                        class="font-weight-bold subtitle-2 text-uppercase blue--text text--darken-1"
                      >
                        Departamento
                      </div>
                      <div
                        class="text-uppercase caption blue-grey--text text--darken-1"
                      >
                        {{ detalleVacante.depto }}
                      </div>
                      <div
                        class="font-weight-bold subtitle-2 text-uppercase blue--text text--darken-1"
                      >
                        Requisitor
                      </div>
                      <div
                        class="text-uppercase caption blue-grey--text text--darken-1"
                      >
                        {{ detalleVacante.EMP_NOMEMP }}
                      </div>
                    </div>
                    <div v-else>
                      <v-select
                        v-model="areaSelected"
                        class="rounded-lg"
                        dense
                        outlined
                        :loading="loadingAreas"
                        :items="areas"
                        @change="
                          loadingDeptos = true;
                          getDepartamentos();
                        "
                        item-text="Area"
                        label="Área"
                      ></v-select>
                      <v-select
                        v-model="deptoSelected"
                        class="rounded-lg"
                        dense
                        outlined
                        :loading="loadingDeptos"
                        :items="departamentos"
                        item-text="descMostrar"
                        label="Departamento"
                      ></v-select>
                      <v-autocomplete
                        v-model="colaboradorSelected"
                        class="rounded-lg"
                        dense
                        outlined
                        :loading="loadingColaboradores"
                        label="Requisitor"
                        :items="colaboradores"
                        item-text="nombreMostrar"
                      ></v-autocomplete>
                    </div>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn
                      small
                      class="rounded-pill"
                      depressed
                      outlined
                      v-if="isRequester"
                      block
                      color="teal darken-2"
                      :loading="loadingModifyDataRequester"
                      @click="
                        loadingModifyDataRequester = true;
                        editRequesterData();
                      "
                    >
                      <v-icon left>mdi-content-save</v-icon>
                      Guardar
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
              <v-col :cols="numberOfColums">
                <v-card
                  :elevation="isRequestData ? 5 : 0"
                  class="rounded-xl outlined-custom-card"
                  height="100%"
                >
                  <v-toolbar dense elevation="0">
                    <v-toolbar-title
                      class="font-weight-bold subtitle-1 text-uppercase blue--text text--darken-1"
                    >
                      Motivo de solicitud
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn
                      small
                      outlined
                      class="rounded-pill"
                      :color="isRequestData ? 'pink darken-3' : 'blue darken-1'"
                      @click="
                        selectFunctionToEdit(2);
                        loadingTipos = true;
                        getRazones();
                      "
                    >
                      {{ isRequestData ? "cancelar" : "editar" }}
                      <v-icon small right>{{
                        isRequestData ? "mdi-close-thick" : "mdi-pencil"
                      }}</v-icon>
                    </v-btn>
                  </v-toolbar>
                  <v-card-text>
                    <div v-if="!isRequestData">
                      <div
                        class="font-weight-bold subtitle-2 text-uppercase blue--text text--darken-1"
                      >
                        Tipo de solicitud
                      </div>
                      <div
                        class="text-uppercase caption blue-grey--text text--darken-1"
                      >
                        {{ detalleVacante.tipo }}
                      </div>
                      <div
                        class="font-weight-bold subtitle-2 text-uppercase blue--text text--darken-1"
                      >
                        Razón de la solicitud
                      </div>
                      <div
                        class="text-uppercase caption blue-grey--text text--darken-1"
                      >
                        {{ detalleVacante.razon }}
                      </div>
                      <div
                        class="font-weight-bold subtitle-2 text-uppercase blue--text text--darken-1"
                      >
                        Complemento
                      </div>
                      <div
                        class="text-uppercase caption blue-grey--text text--darken-1"
                        v-if="detalleVacante.complementoDesc != ''"
                      >
                        {{ detalleVacante.complementoDesc }}
                      </div>
                      <div
                        class="text-uppercase caption blue-grey--text text--darken-1"
                        v-else
                      >
                        <div
                          v-show="detalleVacante.complemento.comentario != null"
                        >
                          {{ detalleVacante.complemento.comentario }}
                        </div>
                        <div
                          v-show="
                            detalleVacante.complemento
                              .colaborador_involucrado != null
                          "
                        >
                          {{ detalleVacante.complemento.emp_nomemp }}
                        </div>
                      </div>
                    </div>
                    <div v-else>
                      <v-select
                        v-model="tipoSelected"
                        class="rounded-lg"
                        dense
                        outlined
                        :loading="loadingTipos"
                        :disabled="loadingModifyRequestType"
                        label="Tipo"
                        :items="tipos"
                        item-text="descripcion"
                      ></v-select>
                      <v-select
                        v-model="razonSelected"
                        class="rounded-lg"
                        dense
                        outlined
                        :loading="loadingTipos"
                        :disabled="loadingModifyRequestType"
                        :items="razones"
                        v-on:change="changeComplementType"
                        item-text="descripcion"
                        label="Razón"
                      ></v-select>
                      <v-autocomplete
                        v-if="!isComentario"
                        v-model="complementoColaboradorInvolucrado"
                        class="rounded-lg"
                        dense
                        outlined
                        :loading="loadingComplemento"
                        :disabled="loadingModifyRequestType"
                        :items="colaboradores"
                        item-text="nombreMostrar"
                        label="Colaborador involucrado."
                      ></v-autocomplete>
                      <v-textarea
                        v-model="complementoComentario"
                        v-else
                        :loading="loadingComplemento"
                        :disabled="loadingModifyRequestType"
                        class="rounded-lg"
                        dense
                        outlined
                        label="Razón complementaria."
                        :rows="2"
                      >
                      </v-textarea>
                    </div>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn
                      small
                      class="rounded-pill"
                      depressed
                      outlined
                      v-if="isRequestData"
                      block
                      :loading="loadingModifyRequestType"
                      color="teal darken-2"
                      @click="
                        loadingModifyRequestType = true;
                        editRequestTypeData();
                      "
                    >
                      <v-icon left>mdi-content-save</v-icon>
                      Guardar
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
          <v-divider class="my-2 mx-5"></v-divider>
          <div class="center-item-in-div">
            <v-card class="px-5 py-2 overline elevation-0" color="blue-grey lighten-5">
              <strong>Curso asignado:</strong> {{ ![null, '', undefined].includes(detalleVacante.nombre_curso) ? detalleVacante.nombre_curso : 'No registrado' }}
            </v-card>
          </div>
          <v-row class="my-2 mx-5">
            <v-col cols="12" xl="4" lg="4" md="4" sm="6" xs="12">
              <v-skeleton-loader
                v-if="loadingApego"
                class="rounded-xl elevation-2"
                min-height="350"
                type="card-heading, list-item-three-line"
              ></v-skeleton-loader>
              <v-card v-else class="rounded-xl" elevation="2" height="355px">
                <v-toolbar class="text-center" :color="colorPrincipal" dark
                  >Apego a perfil
                  <v-spacer></v-spacer>
                  <div>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          fab
                          dark
                          v-show="editApego"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon small @click="muestraAddConocimiento = true"
                            >mdi-briefcase-plus</v-icon
                          >
                        </v-btn>
                      </template>
                      <span>Agregar conocimiento</span>
                    </v-tooltip>
                    <v-btn icon fab dark>
                      <v-icon small @click="editApego = !editApego">{{
                        !editApego ? "mdi-pencil" : "mdi-close"
                      }}</v-icon>
                    </v-btn>
                  </div>
                </v-toolbar>
                <div class="scroll-container-card-text-detalle-vacante">
                  <v-simple-table dense>
                    <thead class="blue lighten-5">
                      <tr>
                        <th class="text-left" style="font-weight:bolder;">
                          Escolaridad
                        </th>
                        <!-- <th class="text-right" style="font-weight:bolder;">
                          Ponderación
                        </th> -->
                        <th v-show="editApego"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td class="text-left">
                          {{ apegoPerfil.escolaridad.esc }} ({{
                            apegoPerfil.escolaridad.carrera
                          }})
                        </td>
                        <!-- <td>
                          <div v-if="!editApego" class="text-right">{{ apegoPerfil.escolaridad.Ponderacion }}%</div>
                          <div v-else style="display: flex; justify-content: end;"> 
                            <v-text-field v-model="apegoPerfil.escolaridad.Ponderacion" class="pa-0 ma-0" style="max-width: 60px; max-height: 40px;" prefix="%">
                            </v-text-field>
                          </div>
                        </td> -->
                        <td v-show="editApego" class="text-right">
                          <v-icon
                            color="pink darken-3"
                            small
                            @click="muestraAddEscolaridad = true"
                            >mdi-pencil</v-icon
                          >
                        </td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                  <v-divider style="border-color: rgb(0,120,195);"></v-divider>
                  <v-simple-table dense>
                    <thead class="blue lighten-5">
                      <tr>
                        <th class="text-left" style="font-weight:bolder;">
                          Conocimiento
                        </th>
                        <th class="text-right" style="font-weight:bolder;">
                          Ponderación
                        </th>
                        <th v-show="editApego"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="item in apegoPerfil.conocimientos"
                        :key="item.id"
                      >
                        <td class="text-left">
                          {{ item.descr }}
                        </td>
                        <td>
                          <div v-if="!editApego" class="text-right">
                            {{ item.Ponderacion }}%
                          </div>
                          <div
                            v-else
                            style="display: flex; justify-content: end;"
                          >
                            <v-text-field
                              v-model="item.Ponderacion"
                              v-on:change="getCalificacionApego()"
                              class="pa-0 ma-0"
                              style="max-width: 60px; max-height: 40px;"
                              prefix="%"
                            >
                            </v-text-field>
                          </div>
                        </td>
                        <td v-show="editApego">
                          <v-icon
                            color="pink darken-3"
                            small
                            @click="deleteKnowledge(item)"
                            >mdi-close</v-icon
                          >
                        </td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                  <v-divider style="border-color: rgb(0,120,195);"></v-divider>
                  <v-simple-table dense>
                    <thead class="blue lighten-5">
                      <tr>
                        <th
                          class="text-center font-weight-bold"
                          style="font-weight:bolder;"
                        >
                          TOTAL
                        </th>
                        <th
                          class="text-right font-weight-bold"
                          style="font-weight:bolder;"
                        >
                          {{ getCalificacionApego() }}%
                        </th>
                      </tr>
                    </thead>
                  </v-simple-table>
                </div>
                <v-card-actions v-show="editApego" class="pb-2">
                  <v-btn
                    class="rounded-pill mb-2"
                    small
                    block
                    text
                    :color="colorPrincipal"
                    :disabled="getCalificacionApego() != 100 ? true : false"
                    @click="
                      loadingApego = true;
                      updateApegoAPerfil();
                    "
                  >
                    guardar
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
            <v-col cols="12" xl="4" lg="4" md="4" sm="6" xs="12">
              <v-skeleton-loader
                v-if="loadingPreguntas"
                class="rounded-xl elevation-2"
                min-height="350"
                type="card-heading, list-item-three-line"
              ></v-skeleton-loader>
              <v-card
                v-else
                class="rounded-xl"
                elevation="2"
                height="355px"
                scrolleable
              >
                <v-toolbar :color="colorPrincipal" dark>
                  Preguntas clave
                  <v-spacer></v-spacer>
                  <v-btn icon fab dark>
                    <v-icon small @click="editPreguntas = !editPreguntas">{{
                      !editPreguntas ? "mdi-pencil" : "mdi-close"
                    }}</v-icon>
                  </v-btn>
                </v-toolbar>
                <div class="scroll-container-card-text-detalle-vacante">
                  <div v-if="!editPreguntas">
                    <div v-for="item in preguntasClave" :key="item.id">
                      <p
                        class="text-justify grey--text text--darken-3 my-0 mx-5"
                        style="font-weight:bold;font-size:0.9em;"
                      >
                        {{ item.pregunta }}
                      </p>
                      <!-- <v-text-field v-else v-model="item.pregunta" class="pa-0 ma-0" style="max-height: 40px;" prefix="P: "></v-text-field> -->
                      <p
                        class="text-justify grey--text text--lighten-1 my-0 mx-5"
                        style="font-weight:lighter;font-size:0.9em;"
                      >
                        {{ item.Respuesta }}
                      </p>
                    </div>
                  </div>
                  <div v-else class="px-4">
                    <v-form v-model="formPreguntas" ref="preguntasClaveEdit">
                      <div
                        v-for="(item, index) in preguntasClave"
                        :key="item.id"
                      >
                        <div class="caption text-capitalize">
                          pregunta {{ index + 1 }}
                        </div>
                        <v-text-field
                          v-model="item.pregunta"
                          dense
                          outlined
                          required
                          :rules="rules"
                          class="px-0 pt-0 pb-1 ma-0 rounded-lg"
                          style="max-height: 54px;"
                          prefix="P: "
                        ></v-text-field>
                        <div class="caption text-capitalize">respuesta</div>
                        <v-textarea
                          v-model="item.Respuesta"
                          dense
                          outlined
                          required
                          :rules="rules"
                          class="px-0 pt-0 pb-1 ma-0 rounded-lg"
                          style="max-height: 84px;"
                          prefix="R: "
                          :rows="2"
                        ></v-textarea>
                      </div>
                    </v-form>
                  </div>
                </div>
                <v-card-actions v-show="editPreguntas" class="pb-2">
                  <v-btn
                    class="rounded-pill mb-2"
                    small
                    block
                    text
                    :color="colorPrincipal"
                    @click="
                      loadingPreguntas = true;
                      updatePreguntasClave();
                    "
                  >
                    guardar
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
            <v-col cols="12" xl="4" lg="4" md="4" sm="12" xs="12">
              <v-card
                class="rounded-xl"
                elevation="2"
                height="355px"
                scrolleable
              >
                <v-toolbar :color="colorPrincipal" dark>Acciones</v-toolbar>
                <div class="text-center my-2">
                  <h3 style="font-weight:bolder;">
                    Estatus
                  </h3>
                </div>
                <div class="center-item-in-div-column">
                  <p class="mb-1">{{ detalleVacante.estatusDesc }}</p>
                  <p v-if="[8,11].includes(detalleVacante.estatusId) && ![null, undefined, ''].includes(detalleVacante.comentarios)" class="caption font-weight-bold blue-grey--text">
                    {{ detalleVacante.comentarios }}
                  </p>
                  <v-btn
                    v-if="detalleVacante.estatusId < 5"
                    depressed
                    :color="colorPrincipal"
                    :loading="loadingEmailData"
                    dark
                    class="rounded-xl mb-1"
                    small
                    @click="
                      loadingEmailData = true;
                      getMailAutorizador();
                    "
                  >
                    Notificar
                    <v-icon right small dark> mdi-email </v-icon>
                  </v-btn>
                  <v-btn
                    v-if="
                      detalleVacante.estatusId <= 6 &&
                        ['ADMINGEN', 'ADMINTI'].includes(rol)
                    "
                    depressed
                    color="pink darken-3"
                    dark
                    class="rounded-xl mb-1"
                    small
                    :loading="loadingRechazo"
                    @click="estatusAux = 8; muestraDialogComentarioRechazo = true"
                  >
                    Rechazar
                    <v-icon right small dark> mdi-close-octagon </v-icon>
                  </v-btn>
                  <v-btn
                    v-if="
                      detalleVacante.estatusId === 5 &&
                        ['ADMINGEN', 'ADMINTI'].includes(rol)
                    "
                    depressed
                    color="green darken-3"
                    dark
                    class="rounded-xl mb-1"
                    small
                    :loading="loadingRechazo"
                    @click="estatusAux = 7; muestraDialogComentarioRechazo = true"
                  >
                    Completar
                    <v-icon right small dark> mdi-close-octagon </v-icon>
                  </v-btn>
                  <v-btn
                    v-if="[1,2,3,4,5].includes(detalleVacante.estatusId) && ['', null, undefined].includes(detalleVacante.nombre_curso)"
                    depressed
                    color="#c2aac7"
                    dark
                    class="rounded-xl mb-1"
                    small
                    :loading="loadingRechazo"
                    @click="estatusAux = 11; muestraDialogComentarioRechazo = true"
                  >
                    Pausar
                    <v-icon right small dark> mdi-pause </v-icon>
                  </v-btn>
                  <v-btn
                    v-if="[7, 8, 11].includes(detalleVacante.estatusId)"
                    depressed
                    color="success"
                    dark
                    class="rounded-xl mb-1"
                    :loading="loadingBtnReact"
                    @click="showConfirm = true"
                    small
                  >
                    Reactivar
                    <v-icon right small dark> mdi-play </v-icon>
                  </v-btn>
                </div>
                <v-divider class="my-5"></v-divider>
                <div class="text-center">
                  <h3 style="font-weight:bolder;">
                    Entrevistadores
                  </h3>
                </div>
                <div class="text-center">
                  <v-btn
                    class="my-0"
                    small
                    text
                    :color="colorPrincipal"
                    dark
                    :loading="loadEntrevistadores"
                    @click="
                      loadEntrevistadores = true;
                      getEntrevistadores();
                    "
                  >
                    <div class="text-decoration-underline font-weight-bold">
                      Ver entrevistadores
                    </div>
                    <v-icon right small dark> mdi-account-group </v-icon>
                  </v-btn>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </div>
      </v-card>

      <TablaDetalles
        :verDataTable="verEntrevisatdores"
        :esApegoPerfil="false"
        :items="entrevistadores"
        :headers="headersTable"
        :titulo="tituloTabla"
        :idVacante="idVacanteInfo"
        @cierraTabla="
          headersTable.length = 0;
          verEntrevisatdores = false;
        "
      />

      <SnackBar
        :muestraBarra="muestraBarra"
        :texto="textoBarra"
        :color="colorBarra"
        @cierraBarra="muestraBarra = false"
      />

      <SendMail
        v-if="showDialogSenMail"
        :showSendMail="showDialogSenMail"
        :defaultMsg="emailBody"
        :receptor="emailAuth"
        :title="emailTitle"
        @hideSendMail="closeEmailDialog"
      />

      <AgregaConocimiento
        v-if="datosPerfiles"
        :muestraAddConocimiento="muestraAddConocimiento"
        :conocimientos="datosPerfiles.conocimientos"
        @cierraDialogConocimientos="cierraDialogConocimientos"
      />

      <AgregaEscolaridad
        v-if="datosPerfiles && apegoPerfil.escolaridad"
        :muestraAddEscolaridad="muestraAddEscolaridad"
        :id="apegoPerfil.escolaridad.id"
        :escolaridad="datosPerfiles.escolaridad"
        :carreras="datosPerfiles.carreras"
        @cierraDialogEscolaridad="cierraDialogEscolaridad"
      />

      <DialogComentarioRechazo
        :commentReject="muestraDialogComentarioRechazo"
        @ocultaDialogComentario="ocultaDialogComentarioRechazo"
      />

      <ConfirmDialogReclutamiento v-if="showConfirm" :showConfirm="showConfirm" :question="'¿Realmente deseas reactivar la vacante con folio ' + detalleVacante.id + '?' " @cierraConfirm="cierraConfirm"/>
    </v-dialog>
  </v-row>
</template>

<script>
import axios from "axios";
import TablaDetalles from "./TablaDetalles.vue";
import { deleteToken } from "../../../../store/Funciones/funciones";
import SnackBar from "../../General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue";
import SendMail from "../../../../components/PersonaDialogSendMail.vue";
import AgregaConocimiento from "./componentsAPP/AgregaConocimiento.vue";
import AgregaEscolaridad from "./componentsAPP/AgregaEscolaridad.vue";
import DialogComentarioRechazo from "./DialogComentarioRechazo.vue";
import ConfirmDialogReclutamiento from "./ConfirmDialogReclutamiento.vue"

export default {
  name: "detalles-vacante",
  props: ["muestraDetallesVacante", "idVacanteInfo"],
  components: {
    TablaDetalles,
    SnackBar,
    SendMail,
    AgregaConocimiento,
    AgregaEscolaridad,
    DialogComentarioRechazo,
    ConfirmDialogReclutamiento
  },
  data() {
    return {
      loadingData: true,
      loadingApego: true,
      loadingPreguntas: true,
      verEntrevisatdores: false,
      loadEntrevistadores: false,
      editActivated: false,
      isRequester: false,
      isRequestData: false,
      loadingAreas: false,
      loadingDeptos: false,
      loadingColaboradores: true,
      loadingTipos: true,
      loadingComplemento: true,
      isComentario: false,
      muestraBarra: false,
      loadingModifyDataRequester: false,
      loadingModifyRequestType: false,
      showDialogSenMail: false,
      loadingEmailData: false,
      editApego: false,
      muestraAddConocimiento: false,
      muestraAddEscolaridad: false,
      editPreguntas: false,
      formPreguntas: false,
      loadingRechazo: false,
      muestraDialogComentarioRechazo: false,
      showConfirm: false,
      loadingBtnReact: false,
      // colorPrincipal: "purple darken-3",
      colorPrincipal: "blue darken-1",
      datosPerfiles: [],
      apegoPerfil: [],
      preguntasClave: [],
      entrevistadores: [],
      headersTable: [],
      areas: [],
      departamentos: [],
      colaboradores: [],
      tipos: [],
      razones: [],
      autorizadores: [],
      rules: [(v) => !!v || "Campo requerido."],
      detalleVacante: {
        id: 0,
        Nombre: "",
        Area: "",
        depto: "",
        EMP_NOMEMP: "",
        EMP_STATUS: "",
        tipo: "",
        razon: "",
        comentarios: "",
        productivity: "",
        mercer: "",
        complemento: "",
        complementoDesc: "",
      },
      tituloTabla: "",
      areaSelected: "",
      deptoSelected: "",
      colaboradorSelected: "",
      razonSelected: "",
      tipoSelected: "",
      complementoComentario: "",
      complementoColaboradorInvolucrado: "",
      colorBarra: "",
      textoBarra: "",
      emailAuth: "",
      emailTitle: "",
      emailBody: "",
      idValidation: "",
      key: "",
      requisitorId: -1,
      rol: "",
      estatusAux: null
    };
  },
  mounted() {
    this.rol = localStorage.getItem("rol");
    this.getDetalleVacante();
  },
  computed: {
    numberOfColums() {
      if (
        this.$vuetify.breakpoint.name == "xs" ||
        this.$vuetify.breakpoint.name == "sm"
      ) {
        return 12;
      } else {
        return 6;
      }
    },
  },
  methods: {
    getDetalleVacante() {
      let params = {
        Opcion: 4,
        token: localStorage.getItem("token"),
        id: this.idVacanteInfo,
      };
      axios
        .post(
          `${this.$store.getters.getHost}/Reclutamiento/ws-admin-reclu.php`,
          params
        )
        .then((response) => {
          if (response.data.status != "EXPSESSION") {
            this.detalleVacante = response.data.detalle;
            this.loadingData = false;
            this.getApegoPerfilVacante();
            this.getColaboradores();
            this.getDataApegos();
          }
        });
    },
    getDataApegos() {
      let params = {
        Opcion: 4,
        token: localStorage.getItem("token"),
      };
      axios
        .post(
          `${this.$store.getters.getHost}/Reclutamiento/ws-control-de-puestos.php`,
          params
        )
        .then((response) => {
          if (response.data.status != "EXPSESSION") {
            if (response.data.status == "OK") {
              this.datosPerfiles = response.data.elementos;
              // this.loadingData = false;
            } else {
              alert("Algo malió sal");
            }
          } else {
            deleteToken();
          }
        });
    },
    getApegoPerfilVacante() {
      let params = {
        Opcion: 18,
        token: localStorage.getItem("token"),
        idVacante: this.idVacanteInfo,
      };
      axios
        .post(
          `${this.$store.getters.getHost}/Reclutamiento/ws-admin-reclu.php`,
          params
        )
        .then((response) => {
          if (response.data.status != "EXPSESSION") {
            this.apegoPerfil = response.data.apego;
            this.loadingApego = false;
            this.getPreguntasClaveVacante();
          }
        });
    },
    getPreguntasClaveVacante() {
      let params = {
        Opcion: 19,
        token: localStorage.getItem("token"),
        idVacante: this.idVacanteInfo,
      };
      axios
        .post(
          `${this.$store.getters.getHost}/Reclutamiento/ws-admin-reclu.php`,
          params
        )
        .then((response) => {
          if (response.data.status != "EXPSESSION") {
            this.preguntasClave = response.data.preguntas;
            this.loadingPreguntas = false;
          }
        });
    },
    getEntrevistadores() {
      this.entrevistadores.length = 0;
      let params = {
        Opcion: 20,
        token: localStorage.getItem("token"),
        idVacante: this.idVacanteInfo,
      };
      axios
        .post(
          `${this.$store.getters.getHost}/Reclutamiento/ws-admin-reclu.php`,
          params
        )
        .then((response) => {
          if (response.data.status == "EXPSESSION") {
            deleteToken();
          } else if (response.data.status == "OK") {
            this.loadEntrevistadores = false;
            response.data.entrevistadores.map((entrevistador) => {
              this.entrevistadores.push({
                id: entrevistador.id,
                idSolicitud: entrevistador.id_solicitud,
                emplidEntrevisador: entrevistador.entrevistador,
                entrevistador:
                  entrevistador.EMP_NOMEMP +
                  " (" +
                  entrevistador.entrevistador +
                  "). " +
                  entrevistador.EMP_STATUS +
                  ".",
                tipo: entrevistador.es_comite,
              });
            });
            this.itemsDataRequest = this.entrevistadores;
            this.headersTable.push({
              text: "ID",
              align: "center",
              value: "id",
              class:
                "primary white--text text-uppercase font-weight-bold rounded-tl-lg dark",
            });
            this.headersTable.push({
              text: "ID Solicitud",
              align: "center",
              value: "idSolicitud",
              class: "primary white--text text-uppercase font-weight-bold dark",
            });
            this.headersTable.push({
              text: "Entrevistador",
              align: "center",
              value: "entrevistador",
              class: "primary white--text text-uppercase font-weight-bold dark",
            });
            this.headersTable.push({
              text: "Tipo",
              align: "center",
              value: "tipo",
              class: "primary white--text text-uppercase font-weight-bold dark",
            });
            this.headersTable.push({
              text: "Acciones",
              align: "center",
              value: "actions",
              sortable: false,
              class:
                "primary white--text text-uppercase font-weight-bold dark rounded-tr-lg",
            });
            this.tituloTabla = "Entrevistadores.";
            this.verEntrevisatdores = true;
          }
        })
        .catch((e) => {
          alert(e);
          deleteToken();
        });
    },
    getAreas() {
      let params = {
        Opcion: 25,
        token: localStorage.getItem("token"),
      };
      axios
        .post(`${this.$store.getters.getHost}/General/ws-general.php`, params)
        .then((response) => {
          this.loadingAreas = false;
          if (response.data.status == "EXPSESSION") {
            deleteToken();
          } else {
            this.areas = response.data.areas;
            let findArea = this.areas.filter((a) => {
              return a.Area == this.detalleVacante.Area;
            });
            this.areaSelected = findArea[0].Area;
            this.getDepartamentos();
          }
        })
        .catch((e) => {
          alert(e);
          deleteToken();
        });
    },
    getDepartamentos() {
      this.departamentos.length = 0;
      let idArea = this.areas.filter((a) => {
        return a.Area == this.areaSelected;
      });
      let params = {
        Opcion: 26,
        token: localStorage.getItem("token"),
        idArea: idArea[0].id,
      };
      axios
        .post(`${this.$store.getters.getHost}/General/ws-general.php`, params)
        .then((response) => {
          this.loadingDeptos = false;
          if (response.data.status == "EXPSESSION") {
            deleteToken();
          } else {
            this.departamentos = response.data.deptos;
            let findDepartamento = this.departamentos.filter((d) => {
              return d.DEPTID == this.detalleVacante.deptid;
            });
            if (findDepartamento.length > 0)
              this.deptoSelected = findDepartamento[0].descMostrar;
          }
        })
        .catch((e) => {
          alert(e);
          // deleteToken();
        });
    },
    getColaboradores() {
      let params = {
        Opcion: 32,
        token: localStorage.getItem("token"),
      };
      axios
        .post(`${this.$store.getters.getHost}/General/ws-general.php`, params)
        .then((response) => {
          this.loadingColaboradores = false;
          if (response.data.status == "EXPSESSION") {
            deleteToken();
          } else {
            this.colaboradores = response.data.colaboradores;
            let findColaborador = this.colaboradores.filter((c) => {
              return c.EMPLID == this.detalleVacante.Requisitor;
            });
            if (findColaborador.length > 0)
              this.colaboradorSelected = findColaborador[0].nombreMostrar;
          }
        })
        .catch((e) => {
          alert(e);
          deleteToken();
        });
    },
    getRazones() {
      let params = {
        Opcion: 3,
        token: localStorage.getItem("token"),
      };
      (async () => {
        try {
          const response = await axios.post(
            `${this.$store.getters.getHost}/Reclutamiento/ws-control-de-puestos.php`,
            params
          );
          this.loadingTipos = false;
          if (response.data.status != "EXPSESSION") {
            if (response.data.razones.length > 0) {
              await Promise.all(
                response.data.razones.map(async (tipo) => {
                  if (tipo.Tipo == 1) {
                    this.razones.push(tipo);
                  } else {
                    this.tipos.push(tipo);
                  }
                })
              );

              let findReason = this.razones.filter((r) => {
                return r.descripcion === this.detalleVacante.razon;
              });
              if (findReason.length > 0)
                this.razonSelected = findReason[0].descripcion;
              let findType = this.tipos.filter((r) => {
                return r.descripcion === this.detalleVacante.tipo;
              });
              if (findType.length > 0)
                this.tipoSelected = findType[0].descripcion;

              if (
                this.detalleVacante.razon == "Inc de plantilla" ||
                this.detalleVacante.razon == "Reestructura"
              ) {
                this.isComentario = true;
                let complemento = await this.getComplementoVacante();
                this.complementoComentario = complemento;
                this.loadingComplemento = false;
              } else {
                this.isComentario = false;
                let complemento = await this.getComplementoVacante();
                let findColabComp = this.colaboradores.filter((c) => {
                  // console.log(c.EMPLID+" == "+complemento,c.EMPLID == complemento)
                  return c.EMPLID == complemento;
                });
                this.complementoColaboradorInvolucrado =
                  findColabComp[0].nombreMostrar;
                this.loadingComplemento = false;
              }
            }
          }
        } catch (e) {
          alert(e);
          // deleteToken();
        }
      })();
    },
    changeComplementType() {
      if (
        this.razonSelected == "Inc de plantilla" ||
        this.razonSelected == "Reestructura"
      ) {
        this.isComentario = true;
      } else {
        this.isComentario = false;
      }
    },
    async getComplementoVacante() {
      let params = {
        Opcion: 21,
        token: localStorage.getItem("token"),
        idVacante: this.idVacanteInfo,
      };
      const response = await axios.post(
        `${this.$store.getters.getHost}/Reclutamiento/ws-admin-reclu.php`,
        params
      );
      if (
        response.data &&
        response.data.complemento &&
        response.data.complemento.complemento
      ) {
        return response.data.complemento.complemento;
      } else {
        throw new Error(
          'La respuesta de la API no incluye el campo "complemento"'
        );
      }
    },
    activeEdition(type) {
      if (type == 1) {
        this.isRequester = true;
        this.isRequestData = false;
      } else if (type == 2) {
        this.isRequestData = true;
        this.isRequester = false;
      }
    },
    closeEdition(type) {
      if (type == 1) {
        this.isRequester = false;
        this.editActivated = false;
      } else if (type == 2) {
        this.isRequestData = false;
        this.editActivated = false;
      }
    },
    selectFunctionToEdit(type) {
      if (
        (type == 1 && !this.isRequester) ||
        (type == 2 && !this.isRequestData)
      ) {
        this.activeEdition(type);
      } else {
        this.closeEdition(type);
      }
    },
    formatMXN(cadena) {
      return cadena.toLocaleString("es-MX", {
        style: "currency",
        currency: "MXN",
      });
    },
    editRequesterData() {
      let findColaborador = this.colaboradores.filter((c) => {
        return c.nombreMostrar == this.colaboradorSelected;
      });
      let findArea = this.areas.filter((a) => {
        return a.Area == this.areaSelected;
      });
      let findDepto = this.departamentos.filter((d) => {
        return d.descMostrar == this.deptoSelected;
      });

      if (
        findColaborador.length > 0 &&
        findArea.length > 0 &&
        findDepto.length > 0
      ) {
        let params = {
          Opcion: 22,
          token: localStorage.getItem("token"),
          idVacante: this.idVacanteInfo,
          requisitor: findColaborador[0].EMPLID,
          idArea: findArea[0].id,
          idDepto: findDepto[0].DEPTID,
        };
        axios
          .post(
            `${this.$store.getters.getHost}/Reclutamiento/ws-admin-reclu.php`,
            params
          )
          .then((response) => {
            this.loadingModifyDataRequester = false;
            if (response.data.status == "EXPSESSION") {
              deleteToken();
            } else {
              if (response.data.status == "OK" && response.data.respuesta > 0) {
                this.areaSelected = findArea[0].Area;
                this.deptoSelected = findDepto[0].descMostrar;
                this.colaboradorSelected = findColaborador[0].nombreMostrar;
                this.muestraSnackBar(
                  true,
                  "Modificado con éxito",
                  "teal darken-1"
                );
              } else {
                this.muestraSnackBar(true, "Ocurrió un error", "pink darken-3");
              }
            }
          })
          .catch((e) => {
            this.muestraSnackBar(true, e, "pink darken-3");
          });
      } else {
        this.muestraSnackBar(
          true,
          "Error en los datos ingresados...",
          "pink darken-3"
        );
      }
    },
    muestraSnackBar(valor, texto, color) {
      this.colorBarra = color;
      this.textoBarra = texto;
      this.muestraBarra = valor;
    },
    editRequestTypeData() {
      let findType = this.tipos.filter((c) => {
        return c.descripcion == this.tipoSelected;
      });
      let findReason = this.razones.filter((a) => {
        return a.descripcion == this.razonSelected;
      });
      let complemento = "";
      if (findReason.length > 0) {
        if (findReason[0].TipoValor != 5 && findReason[0].TipoValor != 1) {
          let findColaborador = this.colaboradores.filter((c) => {
            return c.nombreMostrar == this.complementoColaboradorInvolucrado;
          });
          complemento = findColaborador[0].EMPLID;
        } else {
          complemento = this.complementoComentario;
        }
      } else {
        this.muestraSnackBar(
          true,
          "Error en los datos ingresados...",
          "pink darken-3"
        );
      }
      if (complemento != "" && findReason.length > 0 && findType.length > 0) {
        let params = {
          Opcion: 23,
          token: localStorage.getItem("token"),
          idVacante: this.idVacanteInfo,
          tipo: findType[0].TipoValor,
          razon: findReason[0].TipoValor,
          complemento: complemento,
        };
        axios
          .post(
            `${this.$store.getters.getHost}/Reclutamiento/ws-admin-reclu.php`,
            params
          )
          .then((response) => {
            this.loadingModifyRequestType = false;
            if (response.data.status == "EXPSESSION") {
              deleteToken();
            } else {
              if (response.data.status == "OK" && response.data.respuesta > 0) {
                this.tipoSelected = findType[0].descripcion;
                this.razonSelected = findReason[0].descripcion;
                this.muestraSnackBar(
                  true,
                  "Modificado con éxito",
                  "teal darken-1"
                );
              } else {
                this.muestraSnackBar(true, "Ocurrió un error", "pink darken-3");
              }
            }
          })
          .catch((e) => {
            this.muestraSnackBar(true, e, "pink darken-3");
          });
      } else {
        this.muestraSnackBar(
          true,
          "Error en los datos ingresados...",
          "pink darken-3"
        );
      }
    },
    async getMailAutorizador() {
      let params = {
        Opcion: 24,
        token: localStorage.getItem("token"),
        estatus: this.detalleVacante.estatusId,
        requisitor: this.detalleVacante.Requisitor,
      };

      try {
        const response = await axios.post(
          `${this.$store.getters.getHost}/Reclutamiento/ws-admin-reclu.php`,
          params
        );

        this.key = await this.generaTokenVacante();
        this.loadingEmailData = false;
        if (response.data.status != "EXPSESSION") {
          if (response.data.correos.length > 0) {
            this.autorizadores = response.data.correos;
            response.data.correos.map((c) => {
              this.emailAuth += c.correo + ",";
            });

            this.emailBody =
              localStorage.getItem("lang") == "es"
                ? "Esperando esté teniendo un buen día. El departamento de reclutamiento solicita de su apoyo para avancar el proceso de la vacante <strong>" +
                  this.detalleVacante.Nombre +
                  "</strong> solicitada por <strong>" +
                  this.detalleVacante.EMP_NOMEMP +
                  "</strong>  para autorizar vaya a " +
                  "<a href='" +
                  "https://desarrollohumano.urrea.com/#/AutorizaVacantes/" +
                  this.key +
                  "/" +
                  this.idVacanteInfo +
                  "''> Autorización de vacantes </a>."
                : "We hope you are having a good day. The recruitment department requests your support to advance the process of the vacancy <strong>" +
                  this.detalleVacante.Nombre +
                  "</strong> requested by <strong>" +
                  this.detalleVacante.EMP_NOMEMP +
                  "</strong>  to authorize go to " +
                  "<a href='" +
                  "https://desarrollohumano.urrea.com/#/AutorizaVacantes/" +
                  this.key +
                  "/" +
                  this.idVacanteInfo +
                  "''> Autorización de vacantes </a>.";
            this.emailTitle =
              localStorage.getItem("lang") == "es"
                ? "Autorización de vacantes."
                : "Vacancy authorization";
            this.showDialogSenMail = true;
          }
        }
      } catch (error) {
        this.muestraSnackBar(true, error, "pink darken-3");
      }
    },
    closeEmailDialog(result) {
      // console.log("Resultado de envio email: " + result);
      if (result == 1) {
        this.muestraSnackBar(true, "Eviado con éxito", "teal darken-1");
      } else if (result == 1) {
        this.muestraSnackBar(
          true,
          "Ocurrió un error. No se pudo enviar el correo",
          "pink darken-3"
        );
      }
      this.showDialogSenMail = false;
    },
    setTokenToAuthorization(key, autorizador) {
      let params = {
        Opcion: 25,
        token: localStorage.getItem("token"),
        idVacante: this.idVacanteInfo,
        estatusVac: this.detalleVacante.estatusId,
        requisitor: this.detalleVacante.Requisitor,
        autorizador: autorizador,
        key: key,
      };
      axios
        .post(
          `${this.$store.getters.getHost}/Reclutamiento/ws-admin-reclu.php`,
          params
        )
        .then((response) => {
          if (response.data.status == "EXPSESSION") {
            deleteToken();
          } else {
            if (response.data.status == "OK") {
              this.muestraSnackBar(
                true,
                "Token creado con éxito. " + autorizador,
                "teal darken-1"
              );
            } else {
              this.muestraSnackBar(
                true,
                "Ocurrió un error. No se creo el token",
                "pink darken-3"
              );
            }
          }
        });
    },
    async generaTokenVacante() {
      let params = {
        Opcion: 28,
        token: localStorage.getItem("token"),
        id: this.idVacanteInfo,
      };

      return new Promise((resolve, reject) => {
        axios
          .post(
            `${this.$store.getters.getHost}/Reclutamiento/ws-admin-reclu.php`,
            params
          )
          .then((response) => {
            resolve(response.data.respuesta);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    getCalificacionApego() {
      let sumConocimientos = 0;
      this.apegoPerfil.conocimientos.map((c) => {
        sumConocimientos += parseInt(c.Ponderacion);
      });

      return (
        sumConocimientos + parseInt(this.apegoPerfil.escolaridad.Ponderacion)
      );
    },
    deleteKnowledge(item) {
      let index = this.apegoPerfil.conocimientos.indexOf(item);
      if (index !== -1) {
        this.apegoPerfil.conocimientos.splice(index, 1);
      }
    },
    cierraDialogConocimientos(nuevoConocimiento) {
      if (nuevoConocimiento === null) {
        this.muestraAddConocimiento = false;
      } else {
        this.apegoPerfil.conocimientos.push(nuevoConocimiento);
        this.muestraAddConocimiento = false;
      }
    },
    cierraDialogEscolaridad(nuevoEscolaridad) {
      if (nuevoEscolaridad === null) {
        this.muestraAddEscolaridad = false;
      } else {
        this.apegoPerfil.escolaridad = nuevoEscolaridad;
        this.muestraAddEscolaridad = false;
      }
    },
    updatePreguntasClave() {
      if (this.$refs.preguntasClaveEdit.validate()) {
        this.editPreguntas = false;
        let params = {
          Opcion: 30,
          token: localStorage.getItem("token"),
          idVacante: this.idVacanteInfo,
          preguntas: this.preguntasClave,
        };
        axios
          .post(
            `${this.$store.getters.getHost}/Reclutamiento/ws-admin-reclu.php`,
            params
          )
          .then((response) => {
            this.loadingPreguntas = false;
            if (response.data.status != "EXPSESSION") {
              if (response.data.status === "OK") {
                this.muestraSnackBar(
                  true,
                  "Actualizados con éxito:" +
                    response.data.respuesta.exitos +
                    ". Sin éxito: " +
                    response.data.respuesta.errores,
                  "primary"
                );
              } else {
                this.muestraSnackBar(
                  true,
                  "Ocurrió un error. No se actualizó la información.",
                  "pink darken-3"
                );
              }
            } else {
              deleteToken();
            }
          })
          .catch((e) => {
            this.muestraSnackBar(true, e, "pink darken-3");
          });
      } else {
        alert("error");
      }
    },
    updateApegoAPerfil() {
      this.editApego = false;
      let params = {
        Opcion: 31,
        token: localStorage.getItem("token"),
        idVacante: this.idVacanteInfo,
        jobcode: this.detalleVacante.Jobcode,
        escolaridad: this.apegoPerfil.escolaridad,
        conocimientos: this.apegoPerfil.conocimientos,
      };
      axios
        .post(
          `${this.$store.getters.getHost}/Reclutamiento/ws-admin-reclu.php`,
          params
        )
        .then((response) => {
          this.loadingApego = false;
          if (response.data.status != "EXPSESSION") {
            if (response.data.status === "OK") {
              this.muestraSnackBar(true, response.data.respuesta, "primary");
            } else {
              this.muestraSnackBar(
                true,
                "Ocurrió un error. No se actualizó la información.",
                "pink darken-3"
              );
            }
          } else {
            deleteToken();
          }
        })
        .catch((e) => {
          this.muestraSnackBar(true, e, "pink darken-3");
        });
    },

    ocultaDialogComentarioRechazo(comentario) {
      this.muestraDialogComentarioRechazo = false;
      this.loadingRechazo = true;

      if (comentario != null) {
        const params = {
          Opcion: 38,
          token: localStorage.getItem("token"),
          status: this.estatusAux,
          idVacante: this.idVacanteInfo,
          comentarios: comentario,
          currentStatus: this.detalleVacante.estatusId
        };

        axios
          .post(
            `${this.$store.getters.getHost}/Reclutamiento/ws-admin-reclu.php`,
            params
          )
          .then((response) => {
            if (response.data.status != "EXPSESSION") {
              if (response.data.status === "OK") {
                if (response.data.respuesta.status_tran == "OK") {
                  this.muestraSnackBar(
                    true,
                    response.data.respuesta.message,
                    "teal darken-1"
                  );
                  this.estatusAux = null;
                  this.loadingData = true;
                  this.getDetalleVacante();
                } else {
                  this.muestraSnackBar(
                    true,
                    response.data.respuesta.message,
                    "pink darken-3"
                  );
                }
              } else {
                this.muestraSnackBar(
                  true,
                  "Ocurrió un error. No se actualizó la información.",
                  "pink darken-3"
                );
              }
            } else {
              deleteToken();
            }
          })
          .catch((e) => {
            this.muestraSnackBar(true, e, "pink darken-3");
          })
          .finally(() => {
            this.loadingRechazo = false;
          });
      }
    },

    cierraConfirm(respuesta){
      if(respuesta){
        this.loadingBtnReact = true;
        this.reactivate()
      }

      this.showConfirm = false
    },

    reactivate() {
      const params = {
        Opcion: 48,
        token: localStorage.getItem("token"),
        estatusAct: this.detalleVacante.estatusId,
        idVacante: this.detalleVacante.id
      };

      axios
        .post(
          `${this.$store.getters.getHost}/Reclutamiento/ws-admin-reclu.php`,
          params
        )
        .then((response) => {
          if (response.data.status != "EXPSESSION") {
            if (response.data.status === "OK") {
              console.log(response.data.logs)
              this.muestraSnackBar(
                true,
                response.data.message,
                "teal darken-1"
              );
              this.loadingData = true;
              this.getDetalleVacante();
            } else {
              console.log(response.data.logs)
              this.muestraSnackBar(
                true,
                response.data.message,
                "pink darken-3"
              );
            }
          } else {
            deleteToken();
          }
        })
        .catch((e) => {
          this.muestraSnackBar(true, e, "pink darken-3");
        })
        .finally(() => {
          this.loadingBtnReact = false;
        });
    },
  },
};
</script>

<style>
.scroll-container-card-text-detalle-vacante {
  height: 250px !important;
  overflow-y: scroll;
  background-color: transparent;
  border-radius: 10px !important;
  overflow-y: auto;
}

.scroll-container-card-text-detalle-vacante::-webkit-scrollbar {
  width: 10px;
  background-color: #304d6d;
}

.scroll-container-card-text-detalle-vacante::-webkit-scrollbar-thumb {
  background-color: #e7e6f7;
  border-radius: 10px;
}

.scroll-container-card-text-detalle-vacante::-moz-scrollbar {
  width: 10px;
  background-color: rgba(240, 240, 240, 0.5);
}

.scroll-container-card-text-detalle-vacante::-moz-scrollbar-thumb {
  background-color: #e7e6f7;
  border-radius: 10px;
}

.scroll-container-card-text-detalle-vacante::scrollbar {
  width: 10px;
  background-color: #304d6d;
}

.scroll-container-card-text-detalle-vacante::scrollbar-thumb {
  background-color: #e7e6f7;
  border-radius: 10px;
}

.outlined-custom-card {
  border: 1px solid #1565c0 !important;
}
</style>
