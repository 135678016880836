<template>
    <v-card class="rounded-lg" max-height="750">
      <v-toolbar color="primary" dark>
        <v-row>
          <v-col cols="3" class="pt-7">
            <v-img
            :src="require('@/assets/img/chat.png')"
            height="41px"
            width="40px"
            ></v-img>
          </v-col>
          <v-col cols="9" style="margin-top: 5px">
            <h3>¡Hola!</h3>
            <p>Chatea con nosotros</p>
          </v-col>
        </v-row>
        <v-spacer> </v-spacer>
        <v-btn text icon dark @click="$emit('cierraCA')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text ref="chatArea" class="containerChat pt-2 px-1">
        <v-sheet v-if="loadingData">
          <div class="center-item-in-div-column py-5">
            <div class="subtitle-1 text-center" cols="12">
              {{ mensajeConcatenado }}
            </div>
            <div class="center-item-in-div mt-5">
              <v-progress-circular
                :size="70"
                :width="8"
                color="primary"
                indeterminate
              ></v-progress-circular>
            </div>
          </div>
        </v-sheet>
        <div
          v-else
          v-for="(message, index) in messages"
          :key="index"
          class="content"
          ref="contentRef"
        >
          <transition name="slide-fade">
            <div
              class="px-4 pb-1"
              v-if="message.type == 1"
              style="display:flex; justify-content: left;"
            >
              <v-card
                class="elevation-0 rounded-xl"
                :class="{
                  'my-card-enter-active': index === messages.length - 1,
                }"
                color="blue-grey lighten-5"
                min-width="40%"
              >
                <v-card-title class="font-weight-bold pt-1 pb-0 subtitle-2">
                  {{ message.title }}
                </v-card-title>
                <v-card-text class="pb-2 pt-0">
                  {{ message.message }}
                </v-card-text>
                <div class="pb-2 px-2" v-if="message.haveOptions">
                  <v-btn
                    v-for="(button, index) in message.options"
                    :key="index"
                    small
                    block
                    class="mr-1 elevation-0 rounded-pill font-weight-bold mb-1 mi-boton"
                    style="max-width: 100%;"
                    :disabled="message.disabled"
                    @click="
                      (event) =>
                        button.onClick({
                          type: 2,
                          message: button.text,
                          haveOptions: false,
                          options: null,
                          item: message,
                        })
                    "
                  >
                    <span
                      class="d-inline-block text-truncate text-decoration-underline blue-grey--text text--darken-1"
                      style="max-width: 285px !important;"
                    >
                      {{ button.text }}
                    </span>
                  </v-btn>
                </div>
              </v-card>
            </div>
            <div
              class="px-4 pb-1"
              v-else-if="message.type == 3"
              style="display:flex; justify-content: left;"
            >
              <v-card
                class="elevation-0 rounded-xl rounded-bl-0 "
                color="blue-grey lighten-5"
                min-width="40%"
              >
                <v-card-title class="font-weight-bold pt-1 pb-0 subtitle-2">
                  {{ message.title }}
                </v-card-title>
                <v-card-text class="pb-2 pt-0">
                  <!-- {{ message }} -->
                  <div class="subtitle-1 primary--text">
                    {{ message.subtitle }}
                  </div>
                  <div class="caption blue-grey--text text--lighten-1">
                    {{ message.subtitle2 }}
                  </div>
                  <br />
                  <div class="text-justify">
                    <strong>Conocimientos requeridos: </strong
                    >{{ message.message }}
                  </div>
                </v-card-text>
              </v-card>
            </div>
            <div
              class="px-4 pb-1"
              v-else-if="message.type == 2"
              style="display:flex; justify-content: right;"
            >
              <v-card
                class="elevation-0 rounded-xl"
                :class="{
                  'my-card-enter-active': index === messages.length - 1,
                }"
                color="primary"
                min-width="30%"
              >
                <v-card-text class="py-2 bt-0 white--text">
                  {{ message.message }}
                </v-card-text>
              </v-card>
            </div>
            <div
              class="px-4 pb-1"
              v-else-if="message.type == 4"
              style="display:flex; justify-content: left;"
            >
              <v-card
                class="elevation-0 rounded-xl"
                color="blue-grey lighten-5"
                min-width="40%"
              >
                <v-card-title class="font-weight-bold pt-1 pb-0 subtitle-2">
                  {{ message.title }}
                </v-card-title>
                <v-card-text class="pb-2 pt-0">
                  {{ message.message }}
                </v-card-text>
                <div class="pb-2 px-2" v-if="message.haveOptions">
                  <v-btn
                    v-for="(button, index) in message.options"
                    :key="index"
                    small
                    text
                    class="mr-1 elevation-0 rounded-pill font-weight-bold mb-1 mi-boton"
                    style="max-width: 100%;"
                    @click="getDocument(button.text)"
                  >
                    <span
                      class="d-inline-block text-truncate text-decoration-underline light-blue--text text--accent-3"
                      style="max-width: 285px !important;"
                    >
                      {{ button.text }}
                    </span>
                  </v-btn>
                </div>
              </v-card>
            </div>
          </transition>
        </div>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions
        class="pt-0 pb-1 pr-0"
        style="display: flex; justify-content: space-between;"
      >
        <v-text-field
          v-model="msgUser"
          placeholder="Escribe un mensaje..."
          dense
          :disabled="!respuestaAbierta"
          @keyup.enter="sendMessage"
        ></v-text-field>
        <v-btn
          text
          fab
          @click="
            insertMessage(2, msgUser, false, null, null);
            msgUser = '';
          "
          :disabled="!respuestaAbierta"
        >
          <v-icon color="primary"> mdi-send </v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
</template>

<script>
import axios from "axios";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { deleteToken, formatDate } from "../../../../../../../store/Funciones/funciones";
// import {menuPrincipal} from "./componentsAluDialogs/componentVariablesMenus"
export default {
  name: "persona-caja-de-ahorro",
  props: ["verCajaDeAhorro", "admin", "empleado"],
  data() {
    return {
      messages: [],
      areas: [],
      areasOptions: [],
      preguntasBase: [],
      vacantes: [],
      idArea: null,
      indexPreguntas: 0,
      memMonto: 0,
      montoSolicitadoPrestamo: 0,
      periodoPrestamo: 0,
      tasaDelPeriodo: 0,
      abonoFijoPrestamo: 0,
      montoAbonoMaximo: 0,
      abonoDePrestamoAregistrar: 0,
      auxCantidadRetiro: 0,
      user: {},
      userName: "",
      loadingMessage: "",
      memOpcActual: "",
      memMovimientoCancelar: "",
      msgUser: "",
      mensajes: [],
      respuestaAbierta: false,
      esReclutamiento: false,
      scrollInterval: null, // intervalo para desplazar el scroll hacia abajo
      loadingData: true,
      head_styles: {
        halign: "center",
        valign: "middle",
        lineWidth: 0.25,
        lineColor: 200,
      },
      body_styles: {
        halign: "center",
        lineWidth: 0.25,
        lineColor: 200,
      },
      headerTablaAmortizacion: [
        ["Abono", "Restante", "Monto abono", "Interes", "Total abono"],
      ],
      menuPrincipal: [
        {
          text: "Ir a mi caja de ahorro",
          onClick: (params) =>
            this.insertMessage(
              params.type,
              params.message,
              params.haveOptions,
              params.options,
              params.item
            ),
        },
        {
          text: "Postularme a una vacante",
          onClick: (params) =>
            this.insertMessage(
              params.type,
              params.message,
              params.haveOptions,
              params.options,
              params.item
            ),
        },
      ],
      menuPrincipalOptionsCajaAhorro: [
        {
          text: "Mi caja de ahorro",
          onClick: (params) =>
            this.insertMessage(
              params.type,
              params.message,
              params.haveOptions,
              params.options,
              params.item
            ),
        },
        {
          text: "Mis saldos",
          onClick: (params) =>
            this.insertMessage(
              params.type,
              params.message,
              params.haveOptions,
              params.options,
              params.item
            ),
        },
        {
          text: "Préstamos",
          onClick: (params) =>
            this.insertMessage(
              params.type,
              params.message,
              params.haveOptions,
              params.options,
              params.item
            ),
        },
        {
          text: "Mis movimientos",
          onClick: (params) =>
            this.insertMessage(
              params.type,
              params.message,
              params.haveOptions,
              params.options,
              params.item
            ),
        },
      ],
      menuMisSaldos: [
        {
          text: "Mi saldo de ahorro",
          onClick: (params) =>
            this.insertMessage(
              params.type,
              params.message,
              params.haveOptions,
              params.options,
              params.item
            ),
        },
        {
          text: "Mi saldo de préstamo",
          onClick: (params) =>
            this.insertMessage(
              params.type,
              params.message,
              params.haveOptions,
              params.options,
              params.item
            ),
        },
      ],
      menuNoInscrito: [
        {
          text: "Quiero inscribirme",
          onClick: (params) =>
            this.insertMessage(
              params.type,
              params.message,
              params.haveOptions,
              params.options,
              params.item
            ),
        },
        {
          text: "No me interesa",
          onClick: (params) =>
            this.insertMessage(
              params.type,
              params.message,
              params.haveOptions,
              params.options,
              params.item
            ),
        },
      ],
      menuCloseOptions: [
        {
          text: "Sí",
          onClick: (params) =>
            this.insertMessage(
              params.type,
              params.message,
              params.haveOptions,
              params.options,
              params.item
            ),
        },
        {
          text: "No",
          onClick: (params) =>
            this.insertMessage(
              params.type,
              params.message,
              params.haveOptions,
              params.options,
              params.item
            ),
        },
      ],
      menuMiCajaDeAhorro: [
        {
          text: "Quiero inscribirme",
          onClick: (params) =>
            this.insertMessage(
              params.type,
              params.message,
              params.haveOptions,
              params.options,
              params.item
            ),
        },
        {
          text: "Cambiar la cantidad que ahorro",
          onClick: (params) =>
            this.insertMessage(
              params.type,
              params.message,
              params.haveOptions,
              params.options,
              params.item
            ),
        },
        {
          text: "Retirar de mi ahorro",
          onClick: (params) =>
            this.insertMessage(
              params.type,
              params.message,
              params.haveOptions,
              params.options,
              params.item
            ),
        },
        {
          text: "Realizar un depósito externo",
          onClick: (params) =>
            this.insertMessage(
              params.type,
              params.message,
              params.haveOptions,
              params.options,
              params.item
            ),
        },
        {
          text: "Darme de baja",
          onClick: (params) =>
            this.insertMessage(
              params.type,
              params.message,
              params.haveOptions,
              params.options,
              params.item
            ),
        },
        {
          text: "Regresar al menú principal",
          onClick: (params) =>
            this.insertMessage(
              params.type,
              params.message,
              params.haveOptions,
              params.options,
              params.item
            ),
        },
      ],
      menuEndConversation: [
        {
          text: "Reiniciar conversación",
          onClick: (params) =>
            this.insertMessage(
              params.type,
              params.message,
              params.haveOptions,
              params.options,
              params.item
            ),
        },
        {
          text: "Terminar conversación",
          onClick: (params) =>
            this.insertMessage(
              params.type,
              params.message,
              params.haveOptions,
              params.options,
              params.item
            ),
        },
      ],
      menuOpcionesMontosQuincenal: [
        {
          text: 100,
          onClick: (params) =>
            this.insertMessage(
              params.type,
              params.message,
              params.haveOptions,
              params.options,
              params.item
            ),
        },
        {
          text: 200,
          onClick: (params) =>
            this.insertMessage(
              params.type,
              params.message,
              params.haveOptions,
              params.options,
              params.item
            ),
        },
        {
          text: 500,
          onClick: (params) =>
            this.insertMessage(
              params.type,
              params.message,
              params.haveOptions,
              params.options,
              params.item
            ),
        },
        {
          text: 1000,
          onClick: (params) =>
            this.insertMessage(
              params.type,
              params.message,
              params.haveOptions,
              params.options,
              params.item
            ),
        },
        {
          text: 1500,
          onClick: (params) =>
            this.insertMessage(
              params.type,
              params.message,
              params.haveOptions,
              params.options,
              params.item
            ),
        },
        {
          text: 2000,
          onClick: (params) =>
            this.insertMessage(
              params.type,
              params.message,
              params.haveOptions,
              params.options,
              params.item
            ),
        },
        {
          text: "otra",
          onClick: (params) =>
            this.insertMessage(
              params.type,
              params.message,
              params.haveOptions,
              params.options,
              params.item
            ),
        },
      ],
      menuOpcionesMontosSemanal: [
        {
          text: 50,
          onClick: (params) =>
            this.insertMessage(
              params.type,
              params.message,
              params.haveOptions,
              params.options,
              params.item
            ),
        },
        {
          text: 100,
          onClick: (params) =>
            this.insertMessage(
              params.type,
              params.message,
              params.haveOptions,
              params.options,
              params.item
            ),
        },
        {
          text: 250,
          onClick: (params) =>
            this.insertMessage(
              params.type,
              params.message,
              params.haveOptions,
              params.options,
              params.item
            ),
        },
        {
          text: 500,
          onClick: (params) =>
            this.insertMessage(
              params.type,
              params.message,
              params.haveOptions,
              params.options,
              params.item
            ),
        },
        {
          text: 750,
          onClick: (params) =>
            this.insertMessage(
              params.type,
              params.message,
              params.haveOptions,
              params.options,
              params.item
            ),
        },
        {
          text: 1000,
          onClick: (params) =>
            this.insertMessage(
              params.type,
              params.message,
              params.haveOptions,
              params.options,
              params.item
            ),
        },
        {
          text: "otra",
          onClick: (params) =>
            this.insertMessage(
              params.type,
              params.message,
              params.haveOptions,
              params.options,
              params.item
            ),
        },
      ],
      menuOpcionesConfirmar: [
        {
          text: "confirmar",
          onClick: (params) =>
            this.insertMessage(
              params.type,
              params.message,
              params.haveOptions,
              params.options,
              params.item
            ),
        },
        {
          text: "cancelar",
          onClick: (params) =>
            this.insertMessage(
              params.type,
              params.message,
              params.haveOptions,
              params.options,
              params.item
            ),
        },
      ],
      menuOpcionesAbonarAprestamo: [
        {
          text: "Abonar a mi préstamo",
          onClick: (params) =>
            this.insertMessage(
              params.type,
              params.message,
              params.haveOptions,
              params.options,
              params.item
            ),
        },
        {
          text: "cancelar",
          onClick: (params) =>
            this.insertMessage(
              params.type,
              params.message,
              params.haveOptions,
              params.options,
              params.item
            ),
        },
      ],
      menuOpcionesTipoDeAbonoAprestamo: [
        {
          text: "Abonar la cantidad máxima",
          onClick: (params) =>
            this.insertMessage(
              params.type,
              params.message,
              params.haveOptions,
              params.options,
              params.item
            ),
        },
        {
          text: "Abonar otra cantidad",
          onClick: (params) =>
            this.insertMessage(
              params.type,
              params.message,
              params.haveOptions,
              params.options,
              params.item
            ),
        },
        {
          text: "Regresar al menú principal",
          onClick: (params) =>
            this.insertMessage(
              params.type,
              params.message,
              params.haveOptions,
              params.options,
              params.item
            ),
        },
      ],
      menuInvitacionAsolicitarUnPrestamo: [
        {
          text: "Solicitar un préstamo",
          onClick: (params) =>
            this.insertMessage(
              params.type,
              params.message,
              params.haveOptions,
              params.options,
              params.item
            ),
        },
        {
          text: "cancelar",
          onClick: (params) =>
            this.insertMessage(
              params.type,
              params.message,
              params.haveOptions,
              params.options,
              params.item
            ),
        },
      ],
      menuOpcionesPrestamos: [
        {
          text: "¿Cuánto me puedes prestar?",
          onClick: (params) =>
            this.insertMessage(
              params.type,
              params.message,
              params.haveOptions,
              params.options,
              params.item
            ),
        },
        {
          text: "Solicitar un préstamo",
          onClick: (params) =>
            this.insertMessage(
              params.type,
              params.message,
              params.haveOptions,
              params.options,
              params.item
            ),
        },
        {
          text: "Pagar préstamo con ahorro",
          onClick: (params) =>
            this.insertMessage(
              params.type,
              params.message,
              params.haveOptions,
              params.options,
              params.item
            ),
        },
        {
          text: "Abonar a mi préstamo",
          onClick: (params) =>
            this.insertMessage(
              params.type,
              params.message,
              params.haveOptions,
              params.options,
              params.item
            ),
        },
        {
          text: "Regresar al menú principal",
          onClick: (params) =>
            this.insertMessage(
              params.type,
              params.message,
              params.haveOptions,
              params.options,
              params.item
            ),
        },
      ],
      menuOpcionesPagarPrestamo: [
        {
          text: "Pagar préstamo con ahorro",
          onClick: (params) =>
            this.insertMessage(
              params.type,
              params.message,
              params.haveOptions,
              params.options,
              params.item
            ),
        },
        {
          text: "Realizar un depósito externo",
          onClick: (params) =>
            this.insertMessage(
              params.type,
              params.message,
              params.haveOptions,
              params.options,
              params.item
            ),
        },
        {
          text: "cancelar",
          onClick: (params) =>
            this.insertMessage(
              params.type,
              params.message,
              params.haveOptions,
              params.options,
              params.item
            ),
        },
      ],
      menuOpcionesCantidadSolicitarPrestamo: [
        {
          text: "Tomar cantidad máxima",
          onClick: (params) =>
            this.insertMessage(
              params.type,
              params.message,
              params.haveOptions,
              params.options,
              params.item
            ),
        },
        {
          text: "Ingresar otra cantidad",
          onClick: (params) =>
            this.insertMessage(
              params.type,
              params.message,
              params.haveOptions,
              params.options,
              params.item
            ),
        },
      ],
      menuOpcionesPlazosQuincenal: [
        {
          text: "12 quincenas",
          onClick: (params) =>
            this.insertMessage(
              params.type,
              params.message,
              params.haveOptions,
              params.options,
              params.item
            ),
        },
        {
          text: "24 quincenas",
          onClick: (params) =>
            this.insertMessage(
              params.type,
              params.message,
              params.haveOptions,
              params.options,
              params.item
            ),
        },
      ],
      menuOpcionesPlazosSemanal: [
        {
          text: "26 semanas",
          onClick: (params) =>
            this.insertMessage(
              params.type,
              params.message,
              params.haveOptions,
              params.options,
              params.item
            ),
        },
        {
          text: "52 semanas",
          onClick: (params) =>
            this.insertMessage(
              params.type,
              params.message,
              params.haveOptions,
              params.options,
              params.item
            ),
        },
      ],
      menuOpcionesDetallesSolicitudPrestamo: [
        {
          text: "Tabla de amortización",
          onClick: (params) =>
            this.insertMessage(
              params.type,
              params.message,
              params.haveOptions,
              params.options,
              params.item
            ),
        },
        {
          text: "Términos y condiciones",
          onClick: (params) =>
            this.insertMessage(
              params.type,
              params.message,
              params.haveOptions,
              params.options,
              params.item
            ),
        },
      ],
      menuRetiroDeAhorroEnConsultaDeSaldo: [
        {
          text: "Retirar de mi ahorro",
          onClick: (params) =>
            this.insertMessage(
              params.type,
              params.message,
              params.haveOptions,
              params.options,
              params.item
            ),
        },
        {
          text: "cancelar",
          onClick: (params) =>
            this.insertMessage(
              params.type,
              params.message,
              params.haveOptions,
              params.options,
              params.item
            ),
        },
      ],
      menuRetiroDeAhorroEnConsultaDeSaldoEnPrestamo: [
        {
          text: "Pagar préstamo con ahorro",
          onClick: (params) =>
            this.insertMessage(
              params.type,
              params.message,
              params.haveOptions,
              params.options,
              params.item
            ),
        },
        {
          text: "Abonar a mi préstamo",
          onClick: (params) =>
            this.insertMessage(
              params.type,
              params.message,
              params.haveOptions,
              params.options,
              params.item
            ),
        },
        {
          text: "Realizar un depósito externo",
          onClick: (params) =>
            this.insertMessage(
              params.type,
              params.message,
              params.haveOptions,
              params.options,
              params.item
            ),
        },
        {
          text: "cancelar",
          onClick: (params) =>
            this.insertMessage(
              params.type,
              params.message,
              params.haveOptions,
              params.options,
              params.item
            ),
        },
      ],
      menuOpcionesMisMovimientos: [
        {
          text: "Movimientos en proceso",
          onClick: (params) =>
            this.insertMessage(
              params.type,
              params.message,
              params.haveOptions,
              params.options,
              params.item
            ),
        },
        {
          text: "cancelar",
          onClick: (params) =>
            this.insertMessage(
              params.type,
              params.message,
              params.haveOptions,
              params.options,
              params.item
            ),
        },
      ],
      menuCancelarMovimiento: [
        {
          text: "Cancelar movimiento",
          onClick: (params) =>
            this.insertMessage(
              params.type,
              params.message,
              params.haveOptions,
              params.options,
              params.item
            ),
        },
        {
          text: "Regresar al menú principal",
          onClick: (params) =>
            this.insertMessage(
              params.type,
              params.message,
              params.haveOptions,
              params.options,
              params.item
            ),
        },
      ],
      movimientosEnProceso: [],
    };
  },
  computed: {
    mensajeConcatenado() {
      return this.mensajes.join(" ");
    },
  },
  mounted() {
    // this.mostrarMensajes();
    for (let i = 0; i < 3; i++) {
      setTimeout(() => {
        if (i == 0) {
          this.mensajes.push("Espera un poco...");
        } else if (i == 1) {
          this.mensajes.push("Un poquito más...");
        } else {
          if (i == 2) {
            this.mensajes.push(
              "Para llevarte mi felicidaaaaad..." +
                this.addEmoji(0x1f3b6) +
                this.addEmoji(0x1f3a4)
            );
          }
        }
      }, i * 500);
    }
    if(this.admin){
        console.log(this.empleado)
        this.findMenuSelected("Ir a mi caja de ahorro");
    } else {
        this.startConversation();
    }
  },
  methods: {
    startConversation() {
      this.loadingData = false;
      this.insertMessage(1, "¡Hola!", false, null, null);
      this.insertMessage(
        1,
        "¿Qué deseas hacer hoy?",
        true,
        this.menuPrincipal,
        null
      );
    },
    getDataEmployee() {
      const headers = {
        Authorization: localStorage.getItem("token"),
      };

      const emplid = this.admin ? this.empleado : this.$store.getters.getUser['Folio'];

      return new Promise((res) => {
        axios
          .get(
            `${this.$store.getters.getHostNode}/api/empleados/caja/datos-por-empleado/${emplid}`,
            {
              headers,
            }
          )
          .then((response) => {
            if (response.data.status == "EXPSESSION") {
              deleteToken();
            } else if (response.data.status == "OK") {
              res(response.data.data);
            } else {
              res([]);
            }
          })
          .finally(() => {
            this.loadingData = false;
          });
      });
    },
    getDataVariables() {
      let params = {
        Opcion: 7,
        token: localStorage.getItem("token"),
      };
      axios
        .post(
          `${this.$store.getters.getHost}/Nominas/AdmCajaAhorro/ws-caja-alu.php`,
          params
        )
        .then((response) => {
          this.loadingData = false;
          if (response.data.status == "EXPSESSION") {
            deleteToken();
          } else {
            this.variables = response.data.variables;
          }
        })
        .catch(function(e) {
          console.log(e);
        });
    },
    getAreas() {
      let params = {
        Opcion: 2,
        token: localStorage.getItem("token"),
      };
      axios
        .post(
          `${this.$store.getters.getHost}/Nominas/AdmCajaAhorro/ws-caja-alu.php`,
          params
        )
        .then((response) => {
          this.loadingData = false;
          if (response.data.status == "EXPSESSION") {
            deleteToken();
          } else {
            this.areas = response.data.areas;
            this.areas.map((a) => {
              this.areasOptions.push({
                text: a.Area,
                onClick: (params) =>
                  this.insertMessage(
                    params.type,
                    params.message,
                    params.haveOptions,
                    params.options,
                    params.item
                  ),
              });
            });
            this.insertMessage(
              1,
              "¿En que área estas interesado o interesada?",
              true,
              this.areasOptions,
              null
            );
          }
        });
    },
    getPreguntas() {
      let params = {
        Opcion: 3,
        idVacante: this.idVacante,
      };
      axios
        .post(`${this.$store.getters.getHost}/Login/ws-alu.php`, params)
        .then((response) => {
          if (response.data.status == "OK") {
            response.data.preguntas.map((p) => {
              this.preguntasBase.push({
                id: p.id,
                pregunta: p.pregunta,
                respuesta: "",
              });
            });
            this.insertMessage(
              1,
              this.preguntasBase[this.indexPreguntas].pregunta,
              false,
              null,
              null
            );
          }
        });
    },
    async insertMessage(
      type,
      message,
      haveOptions,
      options = null,
      item = null,
      subtitle = null,
      subtitle2 = null
    ) {
      if (item != null) {
        this.disabledButtons(item);
      }
      if (type === 1) {
        this.messages.push({
          type: 1,
          title: "ALU 2.0",
          message: message,
          actions: {},
          haveOptions: haveOptions,
          options: options,
          disabled: false,
        });
      } else if (type === 3) {
        this.messages.push({
          type: 3,
          title: "ALU",
          subtitle: subtitle,
          subtitle2: subtitle2,
          message: message,
          actions: {},
          haveOptions: haveOptions,
          options: options,
          disabled: false,
        });
      } else if (type === 4) {
        this.messages.push({
          type: 4,
          title: "ALU 2.0",
          message: message,
          actions: {},
          haveOptions: haveOptions,
          options: options,
          disabled: false,
        });
      } else {
        this.messages.push({
          type: 2,
          title: this.userName,
          message: message,
          actions: {},
          haveOptions: haveOptions,
          options: options,
          disabled: false,
        });
        if (this.respuestaAbierta) {
          if (this.esReclutamiento) await this.saveRespuestaReclu(message);
          this.findMenuSelected(message);
        } else {
          this.findMenuSelected(message);
        }
      }
      this.$nextTick(() => {
        let messageDisplay = this.$refs.chatArea;
        messageDisplay.scrollTop = messageDisplay.scrollHeight;
      });
    },
    async findMenuSelected(message) {
      switch (message.toString().trim()) {
        // Opciones caja de ahorro
        //  ************************** CAJA DE AHORRO **************************
        // Opciones de menú principal ------------------------------------------
        // A partir de aquí se ordena alfabeticamente --------------------------
        case "12 quincenas":
        case "24 quincenas":
        case "26 semanas":
        case "52 semanas": {
          const abonos = parseInt(message.trim().substring(0, 2));
          this.periodoPrestamo = abonos;
          let cantidad =
            parseFloat(this.montoSolicitadoPrestamo) +
            this.getInteresesPrestamo(this.montoSolicitadoPrestamo, abonos);
          this.insertMessage(
            4,
            "Ahora deberás confirmar tu solicitud para que yo la guarde. Al confirmar tu solicitud estarás " +
              "aceptando qué se te haga el deposito de la cantidad de " +
              this.formatMXN(this.montoSolicitadoPrestamo) +
              ". Tambien estas aceptando el cobro" +
              " de la cantidad de " +
              this.formatMXN(cantidad) +
              ", que se te cobraran en un total de " +
              abonos +
              " abonos. Consulta términos y condiciones aquí.",
            true,
            this.menuOpcionesDetallesSolicitudPrestamo
          );
          this.insertMessage(
            1,
            "¡Aviso! no podrás disponer de tu dinero hasta que termines de pagar tu prestamo o tengas ahorrado más de lo que adeudas",
            false
          );
          this.memOpcActual = "Acepta terminos";
          this.insertMessage(
            1,
            "¿Confirmas aceptar los términos y condiciones?",
            true,
            this.menuOpcionesConfirmar
          );
          break;
        }
        case "¿Cuánto me puedes prestar?": {
          this.insertMessage(
            1,
            "Según mis cálculos puedes solicitar hasta " +
              this.user.cantidadMostrar +
              " " +
              this.addEmoji(0x1f913),
            false
          );
          this.memOpcActual = message.trim();
          if (this.user.restante === null) {
            this.insertMessage(
              1,
              "¿Deseas solicitar un préstamo? " + this.addEmoji(0x1f9d0),
              true,
              this.menuOpcionesConfirmar
            );
          } else {
            this.insertMessage(
              1,
              "¿Deseas renovar un préstamo? " + this.addEmoji(0x1f9d0),
              true,
              this.menuOpcionesConfirmar
            );
          }
          break;
        }
        case "Abonar a mi préstamo": {
          if(this.user.enRetiro === 1){
            this.insertMessage(1, "No puedes realizar este movimiento porque ya tienes uno similar en proceso, de necesitar un cambio primero deberás cancelar el actual. " + this.addEmoji(0x1F601), false);
            this.insertMessage(1, "¿Qué deseas hacer?", true, this.menuEndConversation)
          } else {
            if(this.user.restante != null){
              this.montoAbonoMaximo =
                parseFloat(this.user.total) - parseFloat(this.user.pS);
                
              if (this.montoAbonoMaximo > parseFloat(this.user.restante)) {
                this.montoAbonoMaximo = parseFloat(this.user.restante);
              }
              if (this.montoAbonoMaximo < 0) {
                this.insertMessage(
                  1,
                  "Esta acción no se puede realizar, pues no cuentas con el saldo suficiente en tu ahorro, lo siento. " +
                    "El equipo de nóminas y yo agradecemos tu comprensión.",
                  false
                );
                this.insertMessage(
                1,
                "¿Qué deseas hacer?",
                true,
                this.menuEndConversation
              );
              } else {
                this.montoAbonoMaximo += parseFloat(this.user.abono);
                this.insertMessage(
                  1,
                  "La cantida máxima que puedes abonar a tu préstamo es " +
                    this.formatMXN(this.montoAbonoMaximo),
                  false
                );
                this.insertMessage(
                  1,
                  "¿Qué deseas hacer?",
                  true,
                  this.menuOpcionesTipoDeAbonoAprestamo
                );
              }
            } else {
              this.insertMessage(1, "No tienes aún registrado algún préstamo al cual realizar abonos. " + this.addEmoji(0x1F913), false);
              this.insertMessage(1, "¿Qué deseas hacer?", true, this.menuEndConversation)
            }
          }
          break;
        }
        case "Abonar la cantidad máxima": {
          this.memOpcActual = message.trim();
          this.memMonto = this.montoAbonoMaximo;
          this.insertMessage(
            1,
            "¡Muy bien! A manera de resumen cabe recordarte que, con el abono de " +
              this.formatMXN(this.montoAbonoMaximo) +
              " tu nuevo saldo será de " +
              this.formatMXN(this.user.restante - this.montoAbonoMaximo),
            false
          );
          this.insertMessage(
            1,
            "¿Deseas confirmar este movimiento?",
            true,
            this.menuOpcionesConfirmar
          );
          break;
        }
        case "Abonar otra cantidad": {
          this.memOpcActual = message.trim();
          this.insertMessage(
            1,
            "¡Muy bien! Recuerda que la cantidad no puede ser menor a " +
              this.formatMXN(this.montoAbonoMaximo) +
              ". Utiliza solo números. Ejem. 1000",
            false
          );
          this.respuestaAbierta = true;
          break;
        }
        case "Cancelar movimiento": {
          this.memOpcActual = message.trim();
          this.insertMessage(
            1,
            "¿Realmente deseas cancelar el movimiento '" +
              this.memMovimientoCancelar +
              "'?",
            true,
            this.menuOpcionesConfirmar
          );
          break;
        }
        case "cancelar": {
          if (this.memOpcActual === "Darme de baja") {
            this.insertMessage(
              1,
              "¡OK! " + this.addEmoji(0x1f973) + this.addEmoji(0x1f973),
              false
            );
          } else {
            this.insertMessage(1, "¡OK! " + this.addEmoji(0x1f97a), false);
          }
          this.insertMessage(
            1,
            "¿Qué deseas hacer?",
            true,
            this.menuEndConversation
          );
          break;
        }
        case "confirmar": {
          if (this.memOpcActual != "¿Cuánto me puedes prestar?") {
            this.insertMessage(
              1,
              "¡Muy bien! Solo dame un momento para guardar tu movimiento. " +
                this.addEmoji(0x1faf6),
              false
            );
          }
          if (this.memOpcActual === "Quiero inscribirme") {
            this.saveUserMovement(4);
          } else if (this.memOpcActual === "Cambiar la cantidad que ahorro") {
            this.saveUserMovement(5);
          } else if (this.memOpcActual === "Retirar de mi ahorro") {
            this.saveUserMovement(6);
          } else if (this.memOpcActual === "Acepta terminos") {
            this.saveUserLoanRequest();
          } else if (this.memOpcActual === "Deposito para prestamo") {
            this.saveUserMovement(9);
          } else if (this.memOpcActual === "Pagar préstamo con ahorro") {
            this.saveUserMovement(10);
          } else if (this.memOpcActual === "Darme de baja") {
            this.saveDropOutUser();
          } else if (this.memOpcActual === "Cancelar movimiento") {
            this.cancelUserMovement();
          } else if (this.memOpcActual === "¿Cuánto me puedes prestar?") {
            this.findMenuSelected("Solicitar un préstamo");
          } else if (this.memOpcActual === "deposito_por_parte_social") {
            this.saveUserMovement(9);
          } else if (
            ["Abonar otra cantidad", "Abonar la cantidad máxima"].includes(
              this.memOpcActual
            )
          ) {
            this.saveUserMovementAbono(13);
          }
          break;
        }
        case "Cambiar la cantidad que ahorro": {
          if (this.user.existe === 0) {
            this.findMenuSelected("No es parte de caja");
          } else {
            if (this.validaConcepto394() === 1) {
              break;
            }
            if (this.user.baja === 1) {
              this.verificaBaja();
              break;
            }
            this.insertMessage(
              1,
              "Muy bien yo puedo ayudarte con este movimiento, solo para ponerte al tanto actualmente ahorras " +
                this.formatMXN(this.user.monto) +
                " en cada una de tus nóminas…",
              false
            );
            this.memOpcActual = message.trim();
            let tipoPago = "";
            let menu = [];
            if ([1, 8, 9, 10].includes(this.user.gp)) {
              tipoPago = "semanal";
              menu = this.menuOpcionesMontosSemanal;
            } else {
              tipoPago = "quincenal";
              menu = this.menuOpcionesMontosQuincenal;
            }
            this.insertMessage(
              1,
              "Según veo, tupago es " +
                tipoPago +
                ". Ahora solo tienes que decidir el monto que ahorraras en cada nómina. Estas son las opciones que tengo para ti.",
              true,
              menu,
              null
            );
          }
          break;
        }
        case "Darme de baja": {
          this.memOpcActual = message.trim();
          if (this.user.existe === 0) {
            this.insertMessage(
              1,
              "Igual ni eras parte de la caja " + this.addEmoji(0x1f937),
              false
            );
            this.insertMessage(
              1,
              "¿Quieres inscribirte?" + this.addEmoji(0x1f481),
              true,
              this.menuNoInscrito
            );
          } else {
            if (this.user.baja === 1) {
              this.verificaBaja();
              break;
            }
            if (
              this.user.restante != null &&
              this.user.restante > this.user.total
            ) {
              this.insertMessage(
                1,
                "Sorry my friend, no te puedes ir pues tienes cuentas pendientes conmigo " +
                  this.addEmoji(0x1f469),
                false
              );
              this.insertMessage(
                1,
                "Revisando tu información me doy cuenta que tienes un saldo pendiente de " +
                  this.formatMXN(this.user.restante) +
                  " en préstamo. Si quieres retirarte antes deberás liquidarlo. Gracias por tu comprensión " +
                  this.addEmoji(0x1f601),
                false
              );
              this.insertMessage(
                1,
                "Pero puedes abonar a tu préstamo ¿Te gustaría intentarlo? ",
                true,
                this.menuOpcionesAbonarAprestamo
              );
            } else if (
              this.user.restante != null &&
              this.user.restante <= this.user.total
            ) {
              this.insertMessage(1, this.addEmoji(0x1f494), false);
              this.insertMessage(
                1,
                "¡Adiós vaquer@! Para retirarte se deberá liquidar tu préstamo pendiente. Esto no es problema pues " +
                  "tu ahorro es mayor si confirmas esta acción recibirás " +
                  this.formatMXN(this.user.total - this.user.restante) +
                  " en tu nómina del día " +
                  this.user.nom +
                  ", ¿Confirmas esta acción?",
                true,
                this.menuOpcionesConfirmar
              );
            } else {
              this.insertMessage(1, this.addEmoji(0x1f494), false);
              this.insertMessage(
                1,
                "¡Adiós vaquer@! Si confirmas esta acción recibirás " +
                  this.formatMXN(this.user.total) +
                  " en tu nómina del día " +
                  this.user.nom +
                  ", ¿Confirmas esta acción?",
                true,
                this.menuOpcionesConfirmar
              );
            }
          }
          break;
        }
        case "Ingresar otra cantidad": {
          this.insertMessage(
            1,
            "Muy bien, recuerda que no puedes solicitar más de " +
              this.user.cantidadMostrar +
              " y que solo puedes ingresar valores númericos como por ejemplo 10000 ó 7500",
            false
          );
          this.memOpcActual = "prestamoIngresaCantidad";
          this.respuestaAbierta = true;
          break;
        }
        case "Mi caja de ahorro": {
          this.insertMessage(
            1,
            "Tengo estas opciones para ti. " + this.addEmoji(0x1f917),
            true,
            this.menuMiCajaDeAhorro,
            null
          );
          break;
        }
        case "Mi saldo de ahorro": {
          this.insertMessage(
            1,
            "Actualmente cuentas con " +
              this.formatMXN(this.user.total) +
              " en tu cuenta de caja de ahorro",
            false,
            null,
            null
          );
          this.insertMessage(
            1,
            "Sabes, puedo ayudarte a realizar un retiro de tu ahorro.",
            false,
            null,
            null
          );
          this.insertMessage(
            1,
            "¿Te gustaría que revise si puedo hacerlo?",
            true,
            this.menuRetiroDeAhorroEnConsultaDeSaldo
          );
          break;
        }
        case "Mi saldo de préstamo": {
          if (this.user.restante != null && this.user.existe === 1) {
            this.insertMessage(
              1,
              "Aún te restan: " +
                this.formatMXN(this.user.restante) +
                ". Abonaste: " +
                this.formatMXN(this.user.acumulado) +
                ". De un préstamo de " +
                this.formatMXN(this.user.total_prestamo),
              false,
              null,
              null
            );
            this.insertMessage(
              1,
              "Sabes " +
                this.userName +
                ", sobre tu saldo en préstamo tengo algunas opciones, mira.",
              false,
              null,
              null
            );
            this.insertMessage(
              1,
              "Podemos intentar realizar alguna de estas acciones",
              true,
              this.menuRetiroDeAhorroEnConsultaDeSaldoEnPrestamo
            );
          } else if (
            (this.user.restante == null || this.user.restante == 0) &&
            this.user.existe === 1
          ) {
            this.insertMessage(
              1,
              "No tienes ningún prestamo activo. " + this.addEmoji(0x1f62c),
              false
            );
            this.insertMessage(
              1,
              "¿Te interesa solicitar uno?",
              true,
              this.menuInvitacionAsolicitarUnPrestamo
            );
          } else {
            this.findMenuSelected("No es parte de caja");
          }
          break;
        }
        case "Mis saldos": {
          if (this.user.existe === 0) {
            this.findMenuSelected("No es parte de caja");
          } else {
            this.insertMessage(
              1,
              "¿Qué saldo te gustaría consultar?",
              true,
              this.menuMisSaldos,
              null
            );
          }
          break;
        }
        case "Mis movimientos": {
          this.loadingData = true;
          this.getMovimientos();
          break;
        }
        case "Movimientos en proceso": {
          this.memOpcActual = message.trim();
          let movsToShow = [];
          const promises = this.movimientosEnProceso.map((a) => {
            return new Promise((resolve) => {
              const vacante = {
                text: a.TIPO_MOVIMIENTO,
                onClick: (params) =>
                  this.insertMessage(
                    params.type,
                    params.message,
                    params.haveOptions,
                    params.options,
                    params.item
                  ),
              };
              resolve(vacante);
            });
          });

          Promise.all(promises).then((movimientos) => {
            movsToShow = movimientos;
            this.insertMessage(
              1,
              "Estos son los movimientos que tienes en proceso. (clic para más detalles)" +
                this.addEmoji(0x1f642),
              true,
              movsToShow,
              null
            );
          });
          break;
        }
        case "No es parte de caja": {
          this.insertMessage(
            1,
            "¡Alto ahí! " +
              this.addEmoji(0x1f46e) +
              " No eres parte de la caja de ahorro.",
            false,
            null,
            null
          );
          this.insertMessage(
            1,
            "¿Te gustaría entrar?",
            true,
            this.menuNoInscrito,
            null
          );
          break;
        }
        case "No me interesa": {
          this.insertMessage(
            1,
            "¡Ok! Tu te lo pierdes. " + this.addEmoji(0x1f937),
            false
          );
          this.insertMessage(
            1,
            "¿Te gustaría realizar algo más?",
            true,
            this.menuEndConversation
          );
          break;
        }
        case "Solicitar un préstamo": {
          if (this.user.existe === 0) {
            this.findMenuSelected("No es parte de caja");
            break;
          }
          if (this.user.c295 === 1) {
            this.insertMessage(
              1,
              "Actualmente ya te encuentras en proceso de aprobación por un préstamo." +
                this.addEmoji(0x1f4aa),
              false
            );
            this.insertMessage(
              1,
              "¿Qué deseas hacer?",
              true,
              this.menuEndConversation
            );
            break;
          }
          if (this.user.baja === 1) {
            this.verificaBaja();
            break;
          }
          // validaciones de antigüedad
          if (this.user.antiguedad == 0) {
            this.insertMessage(
              1,
              "Lo siento, pero aun no cumples con la antigüedad necesaria (3 meses) en caja de ahorro para solicitar un préstamo.",
              false
            );
            this.insertMessage(
              1,
              "¿Qué deseas hacer?",
              true,
              this.menuEndConversation
            );
            break;
          }

          // validaciones de ahorro vs parte social
          if (this.user.pS > this.user.total) {
            this.memOpcActual = "deposito_por_parte_social";
            this.memMonto =
              parseFloat(this.user.pS) - parseFloat(this.user.total);
            this.insertMessage(
              1,
              "Parece que aun no cumples con tu parte social (" +
                this.formatMXN(this.user.pS) +
                ").",
              false
            );
            this.insertMessage(
              1,
              "Para completarla es necesario que realices un depósito por " +
                this.formatMXN(
                  parseFloat(this.user.pS) - parseFloat(this.user.total)
                ),
              false
            );
            this.insertMessage(
              1,
              "¿Deseas realizar el depósito? Puedo registrarlo por ti para que solicites tu préstamo. Solo recuerda que debes enviar el comprobante " +
                "del depósito o captura de transferencia a nóminas antes del " +
                this.user.prenom +
                " para que tu solicitud pueda ser procesada.",
              true,
              this.menuOpcionesConfirmar
            );
            break;
          }

          if (
            this.user.restante != null &&
            parseFloat(this.user.restante) > 0
          ) {
            if (
              parseFloat(this.user.restante) >
              parseFloat(this.user.total) - parseFloat(this.user.pS)
            ) {
              this.insertMessage(
                1,
                "¡Oh no! " +
                  this.addEmoji(0x1f494) +
                  " debido a que cuentas con un saldo vigente de " +
                  this.formatMXN(this.user.restante) +
                  " no puedo ayudarte " +
                  "solicitando otro. Deberás liquidarlo para poder solicitar un nuevo préstamo.",
                false
              );
              this.insertMessage(
                1,
                "Pero ¡Ey! No todo son malas noticias.",
                false
              );
              this.insertMessage(
                1,
                "También tengo estas opciones pata ti.",
                true,
                this.menuOpcionesPagarPrestamo
              );
            } else {
              this.insertMessage(
                1,
                "Parece ser que tienes un adeudo pendiente por " +
                  this.formatMXN(this.user.restante) +
                  ". Para renovar tu préstamo deberás saldar la deuda.",
                false
              );
              this.insertMessage(
                1,
                "Puedes hacerlo con tu ahorro.",
                true,
                this.menuOpcionesPagarPrestamo
              );
            }
          } else {
            this.insertMessage(
              1,
              "¡Excelentes noticias! cumples con todos los requisitos para solicitar un préstamo. " +
                this.addEmoji(0x1f973),
              false
            );
            this.insertMessage(
              1,
              "La cantidad máxima que puedes solicitar es de " +
                this.formatMXN(this.user.cantidadMostrar),
              false
            );
            this.insertMessage(
              1,
              "¿Qué te gustaría hacer?",
              true,
              this.menuOpcionesCantidadSolicitarPrestamo
            );
          }
          break;
        }
        case "Pagar préstamo con ahorro": {
          if (this.validaExistencia()) {
            break;
          }
          if(!this.validaConcepto294()){
            if (this.user.baja === 1) {
              this.verificaBaja();
              break;
            }
            if (this.user.restante === null) {
              this.insertMessage(
                1,
                "No tienes ningún prestamo activo. " + this.addEmoji(0x1f62c),
                false
              );
              this.insertMessage(
                1,
                "¿Te interesa solicitar uno?",
                true,
                this.menuInvitacionAsolicitarUnPrestamo
              );
            } else if (
              this.user.restante != null &&
              this.user.restante > this.user.total
            ) {
              this.memOpcActual = "Deposito para prestamo";
              this.memMonto =
                parseFloat(this.user.restante) +
                parseFloat(this.user.pS) -
                parseFloat(this.user.total);
              this.insertMessage(
                1,
                "¡Oh no! Parece que tu ahorro (" +
                  this.formatMXN(this.user.total) +
                  ") es menor a tu deuda en préstamos. (" +
                  this.formatMXN(this.user.restante) +
                  ") " +
                  this.addEmoji(0x1f915),
                false
              );
              this.insertMessage(
                1,
                "Pero... Puedes realizar un depósito por " +
                  this.formatMXN(
                    this.user.restante + this.user.pS - this.user.total
                  )
              );
              this.insertMessage(
                1,
                "Si haces tu depósito antes del " +
                  this.user.prenom +
                  " tu movimiento procederá, de no ser así este movimiento no será tomado en cuenta. ¿Confirmas el compromiso?",
                true,
                this.menuOpcionesConfirmar
              );
            } else {
              this.memOpcActual = message.trim();
              this.memMonto = parseFloat(this.user.restante);
              this.insertMessage(
                1,
                "Muy bien, con la liquidez que buscas realizar con este movimiento tu nuevo saldo en caja de ahorro será de " +
                  this.formatMXN(this.user.total - this.user.restante),
                false
              );
              this.insertMessage(
                1,
                "¿Deseas confirmar este movimiento?",
                true,
                this.menuOpcionesConfirmar
              );
            }
          } else {
            this.insertMessage(1, "¿Qué deseas hacer?", true, this.menuEndConversation)
          }
          break;
        }
        case "Préstamos": {
          this.insertMessage(
            1,
            "En préstamos tengo estas opciones para ti",
            true,
            this.menuOpcionesPrestamos
          );
          break;
        }
        case "Quiero inscribirme": {
          if (this.user.existe === 1) {
            this.findMenuSelected("Ya es parte de caja");
          } else {
            if (this.user.procIns === 1) {
              this.insertMessage(
                1,
                "Ya te encuentras en proceso de inscripción, se paciente. " +
                  this.addEmoji(0x1f60e)
              );
              this.insertMessage(
                1,
                "¿Te puedo ayudar en algo más?",
                true,
                this.menuEndConversation
              );
              break;
            }
            this.memOpcActual = message.trim();
            let tipoPago = "";
            let menu = [];
            if ([1, 8, 9, 10].includes(this.user.gp)) {
              tipoPago = "semanal";
              menu = this.menuOpcionesMontosSemanal;
            } else {
              tipoPago = "quincenal";
              menu = this.menuOpcionesMontosQuincenal;
            }
            this.insertMessage(
              1,
              "Bienvenid@ al primer día del resto de tu vida. ",
              false,
              null,
              null
            );
            this.insertMessage(
              1,
              "Según veo, tupago es " +
                tipoPago +
                ". Ahora solo tienes que decidir el monto que ahorraras en cada nómina. Estas son las opciones que tengo para ti.",
              true,
              menu,
              null
            );
          }
          break;
        }
        case "Retirar de mi ahorro": {
          this.validaConcepto294();
          if (this.user.baja === 1) {
            this.verificaBaja();
            break;
          }
          if (
            this.user.restante != null &&
            this.user.total <= this.user.restante
          ) {
            this.insertMessage(
              1,
              "Lamento informarte que no puedes realizar un retiro debido a que aún tienes un saldo vigente de " +
                this.formatMXN(this.user.restante) +
                " mayor a tu saldo en ahorro de " +
                this.formatMXN(this.user.total) +
                ". El equipo de DH y yo agradecemos tu comprensión." +
                this.addEmoji(0x1f609),
              false
            );
            this.insertMessage(
              1,
              "¿Qué deseas hacer?",
              true,
              this.menuEndConversation
            );
          } else if (
            this.user.restante != null &&
            this.user.total > this.user.restante
          ) {
            this.insertMessage(
              1,
              "Al tener un préstamo vigente solo puedes retirar hasta " +
                this.formatMXN(this.user.total - this.user.restante) +
                this.addEmoji(0x1f911) +
                " ¿Cuánto deseas retirar?",
              false
            );
            this.memOpcActual = message.trim();
            this.respuestaAbierta = true;
          } else {
            this.insertMessage(
              1,
              "Puedes retirar hasta " +
                this.formatMXN(this.user.total) +
                this.addEmoji(0x1f911) +
                this.addEmoji(0x1f911) +
                this.addEmoji(0x1f911) +
                " ¿Cuánto deseas retirar?",
              false
            );
            this.memOpcActual = message.trim();
            this.respuestaAbierta = true;
          }
          break;
        }
        case "Realizar un depósito externo": {
          this.insertMessage(
            1,
            "Para realizar un deposito externo deberás hacer lo siguiente: Realizar el depósito exacto a la sucursal: 7016. Número de cuenta: 5784756. En Banamex al nombre de URREA HERRAMIENTAS PROFESIONALES, S.A. DE C.V. Clabe interbancaria: 002320701657847567.",
            false
          );
          this.insertMessage(
            1,
            "Después deberás mostrar el comprobante de pago lo pudes hacer vía WhatsApp a los numeros: 3321843701. O vía correo electrónico a: nominas@urrea.net",
            false
          );
          this.insertMessage(
            1,
            "Si presentas tu comprobante de pago antes de la prenomina que es el " +
              this.user.prenom +
              " tu depósito lo podrás consultar en tu saldo de ahorro después de ese día.",
            false
          );
          this.insertMessage(1, "Gracias. " + this.addEmoji(0x1f604), false);
          this.insertMessage(
            1,
            "¿Qué deseas hacer?",
            true,
            this.menuEndConversation
          );
          break;
        }
        case "Regresar al menú principal": {
          this.findMenuSelected("Reiniciar conversación");
          break;
        }
        case "Tomar cantidad máxima": {
          let menu = [];
          this.montoSolicitadoPrestamo = this.user.cantidadPrestamo;
          if ([2, 3, 4, 5, 6, 7].includes(this.user.gp)) {
            menu = this.menuOpcionesPlazosQuincenal;
          } else {
            menu = this.menuOpcionesPlazosSemanal;
          }
          this.insertMessage(
            1,
            "Muy bien, ¿En qué plazo te gustaría pagar tu préstamo?",
            true,
            menu
          );
          break;
        }
        case "Ya es parte de caja": {
          this.insertMessage(
            1,
            "No trates de engañarme, yo se bien que ya perteneces a la caja de ahorro. " +
              this.addEmoji(0x1f970),
            false,
            null,
            null
          );
          this.insertMessage(
            1,
            "¿Te gustaría hacer algo más?",
            true,
            this.menuEndConversation,
            null
          );
          break;
        }
        case "otra": {
          this.respuestaAbierta = true;
          this.insertMessage(
            1,
            "Así que mis opciones no te gustaron. No te preocupes, tu decides. Solo recuerda que si tu pago es semanal tu ahorro no deberá ser menor a 50 pesos y si es quincenal no deberá ser menor a 100 pesos. Por favor dime que cantidad deseas ahorrar. ¡IMPORTANTE! Ingresa sólo numeros.",
            false
          );
          break;
        }
        case "Ir a mi caja de ahorro": {
          this.loadingData = true;
          this.user = await this.getDataEmployee()
          let arrayName = this.user.name.split("/");
          this.userName = this.capitalizeString(arrayName[2]);
          this.insertMessage(1, "Hola " + this.userName, false, null, null);
          this.insertMessage(
            1,
            "¿Qué deseas hacer hoy?",
            true,
            this.menuPrincipalOptionsCajaAhorro,
            null
          );
          this.getDataVariables();
          // this.getDataUser();
          break;
        }
        case "Postularme a una vacante": {
          this.esReclutamiento = true;
          this.loadingData = true;
          this.getAreas();
          break;
        }
        case "Reiniciar conversación": {
          this.reloadConversation();
          break;
        }

        case "Terminar conversación": {
          this.endConversation();
          break;
        }
        //  ************************** RECLUTAMIENTO **************************
        //! Área seleccionada
        case "GUDH": {
          this.insertMessage(
            1,
            "No hay vacantes disponibles en este momento.",
            false
          );
          this.insertMessage(
            1,
            "¿Qué deseas hacer?",
            true,
            this.menuEndConversation
          );
          break;
        }
        case "Desarrollo Humano": {
          this.insertMessage(
            1,
            "Muy bien, dame un momento estoy buscando las vacantes que tenemos disponibles... " +
              this.addEmoji(0x1f9d0),
            false,
            null,
            null
          );
          this.idArea = 1;
          this.getVacantes();
          break;
        }
        case "Administración y Finanzas": {
          this.insertMessage(
            1,
            "Muy bien, dame un momento estoy buscando las vacantes que tenemos disponibles... " +
              this.addEmoji(0x1f9d0),
            false,
            null,
            null
          );
          this.idArea = 2;
          this.getVacantes();
          break;
        }
        case "Logistica": {
          this.insertMessage(
            1,
            "Muy bien, dame un momento estoy buscando las vacantes que tenemos disponibles... " +
              this.addEmoji(0x1f9d0),
            false,
            null,
            null
          );
          this.idArea = 6;
          this.getVacantes();
          break;
        }
        case "Operaciones": {
          this.insertMessage(
            1,
            "Muy bien, dame un momento estoy buscando las vacantes que tenemos disponibles... " +
              this.addEmoji(0x1f9d0),
            false,
            null,
            null
          );
          this.idArea = 7;
          this.getVacantes();
          break;
        }
        case "Mercadotecnia": {
          this.insertMessage(
            1,
            "Muy bien, dame un momento estoy buscando las vacantes que tenemos disponibles... " +
              this.addEmoji(0x1f9d0),
            false,
            null,
            null
          );
          this.idArea = 10;
          this.getVacantes();
          break;
        }
        case "Innovacion y competitividad": {
          this.insertMessage(
            1,
            "Muy bien, dame un momento estoy buscando las vacantes que tenemos disponibles... " +
              this.addEmoji(0x1f9d0),
            false,
            null,
            null
          );
          this.idArea = 11;
          this.getVacantes();
          break;
        }
        case "Ventas nacionales": {
          this.insertMessage(
            1,
            "Muy bien, dame un momento estoy buscando las vacantes que tenemos disponibles... " +
              this.addEmoji(0x1f9d0),
            false,
            null,
            null
          );
          this.idArea = 9;
          this.getVacantes();
          break;
        }
        case "Ventas Internacional": {
          this.insertMessage(
            1,
            "Muy bien, dame un momento estoy buscando las vacantes que tenemos disponibles... " +
              this.addEmoji(0x1f9d0),
            false,
            null,
            null
          );
          this.idArea = 12;
          this.getVacantes();
          break;
        }

        case "No postularme a ninguna vacante": {
          this.insertMessage(
            1,
            "Está bien, es tu desición. ¿Qué deseas hacer?",
            true,
            this.menuEndConversation
          );
          break;
        }
        // Opciones cerradas reclutamiento
        case "no":
        case "NO":
        case "No":
        case "nO": {
          if (!this.respuestaAbierta) {
            this.insertMessage(
              1,
              "¿Qué te gustaría hacer?" + this.addEmoji(0x1f47d),
              true,
              this.menuEndConversation
            );
          } else if (
            this.respuestaAbierta &&
            this.indexPreguntas < this.preguntasBase.length
          ) {
            this.insertMessage(
              1,
              this.preguntasBase[this.indexPreguntas].pregunta,
              false,
              null,
              null
            );
          } else if (
            this.respuestaAbierta &&
            this.indexPreguntas == this.preguntasBase.length
          ) {
            this.respuestaAbierta = false;
            this.insertMessage(
              1,
              "¡Casi listo! Ahora solo dame un momento para enviar tu información a nuestro equipo de reclutamiento... " +
                this.addEmoji(0x1f44c),
              false,
              null,
              null
            );
            this.saveCandidate();
          }
          break;
        }
        case "SÍ":
        case "Sí":
        case "Si":
        case "SI":
        case "si":
        case "sí": {
          if (!this.respuestaAbierta) {
            this.insertMessage(
              1,
              "¡Excelente! Dame un momento, voy por las preguntas... " +
                this.addEmoji(0x1f6b6),
              false,
              null,
              null
            );
            this.respuestaAbierta = true;
            this.getPreguntas();
          } else if (
            this.respuestaAbierta &&
            this.indexPreguntas < this.preguntasBase.length
          ) {
            this.insertMessage(
              1,
              this.preguntasBase[this.indexPreguntas].pregunta,
              false,
              null,
              null
            );
          } else if (
            this.respuestaAbierta &&
            this.indexPreguntas == this.preguntasBase.length
          ) {
            this.respuestaAbierta = false;
            this.insertMessage(
              1,
              "¡Casi listo! Ahora solo dame un momento para enviar tu información a nuestro equipo de reclutamiento... " +
                this.addEmoji(0x1f44c),
              false,
              null,
              null
            );
            this.saveCandidate();
          }
          break;
        }
        default: {
          if (this.esReclutamiento) {
            let filterVacante = this.vacantes.filter((v) => {
              return v.Nombre === message;
            });

            if (filterVacante.length > 0 && !this.respuestaAbierta) {
              this.idVacante = filterVacante[0].id;
              let conocmimientos = filterVacante[0].app.conocimientos
                .map((obj) => obj.descr)
                .join(", ");
              this.insertMessage(
                3,
                conocmimientos,
                false,
                null,
                null,
                filterVacante[0].Nombre,
                filterVacante[0].app.escolaridad.carrera
              );
              this.insertMessage(
                1,
                "¿Te gustaría postularte?",
                true,
                this.menuCloseOptions
              );
            } else if (
              this.respuestaAbierta &&
              this.indexPreguntas < this.preguntasBase.length
            ) {
              this.insertMessage(
                1,
                this.preguntasBase[this.indexPreguntas].pregunta,
                false,
                null,
                null
              );
            } else if (
              this.respuestaAbierta &&
              this.indexPreguntas == this.preguntasBase.length
            ) {
              this.respuestaAbierta = false;
              this.insertMessage(
                1,
                "¡Casi listo! Ahora solo dame un momento para enviar tu información a nuestro equipo de reclutamiento... " +
                  this.addEmoji(0x1f44c),
                false,
                null,
                null
              );
              this.saveCandidate();
            } else {
              this.insertMessage(
                1,
                "Lo siento, no puedo entenderte. " + this.addEmoji(0x1f915),
                false,
                null,
                null
              );
              this.insertMessage(
                1,
                "¿Qué deseas hacer hoy?",
                true,
                this.menuEndConversation,
                null
              );
            }
          } else {
            let msgCleaned = this.limpiarCadena(message.toString().trim());
            if (
              typeof parseFloat(msgCleaned) === "number" &&
              this.memOpcActual === "Quiero inscribirme"
            ) {
              if (
                ([1, 8, 9, 10].includes(this.user.gp) &&
                  parseFloat(msgCleaned) >= 50) ||
                ([2, 3, 4, 5, 6, 7].includes(this.user.gp) &&
                  parseFloat(msgCleaned) >= 100)
              ) {
                this.memMonto = parseFloat(msgCleaned);
                this.insertMessage(
                  1,
                  "¡Muy bien! Entonces, ¿Confirmas la opción de entrar a la caja de ahorro con " +
                    this.formatMXN(msgCleaned) +
                    "?",
                  true,
                  this.menuOpcionesConfirmar
                );
                this.respuestaAbierta = false;
              } else {
                this.insertMessage(
                  1,
                  "La cantidad ingresada es incorrecta. " +
                    this.addEmoji(0x1f622),
                  false
                );
                this.findMenuSelected("otra");
              }
            } else if (
              typeof parseFloat(msgCleaned) === "number" &&
              this.memOpcActual === "Cambiar la cantidad que ahorro"
            ) {
              if (
                ([1, 8, 9, 10].includes(this.user.gp) &&
                  parseFloat(msgCleaned) >= 50) ||
                ([2, 3, 4, 5, 6, 7].includes(this.user.gp) &&
                  parseFloat(msgCleaned) >= 100)
              ) {
                this.memMonto = parseFloat(msgCleaned);
                this.insertMessage(
                  1,
                  "¡Muy bien! Entonces, ¿Confirmas la opción cambio de monto a ahorrar de " +
                    this.formatMXN(this.user.monto) +
                    " a " +
                    this.formatMXN(msgCleaned) +
                    "?",
                  true,
                  this.menuOpcionesConfirmar
                );
                this.respuestaAbierta = false;
              } else {
                this.insertMessage(
                  1,
                  "La cantidad ingresada es incorrecta. " +
                    this.addEmoji(0x1f622),
                  false
                );
                this.findMenuSelected("otra");
              }
            } else if (
              typeof parseFloat(msgCleaned) === "number" &&
              this.memOpcActual === "Retirar de mi ahorro"
            ) {
              if (
                (this.user.restante == null &&
                  parseFloat(msgCleaned) <= this.user.total) ||
                (this.user.restante != null &&
                  parseFloat(msgCleaned) <=
                    this.user.total - this.user.restante)
              ) {
                this.memMonto = parseFloat(msgCleaned);
                this.insertMessage(
                  1,
                  "¡Muy bien! Entonces, ¿Confirmas la opción cambio retirar la cantidad de " +
                    this.formatMXN(msgCleaned) +
                    "?",
                  true,
                  this.menuOpcionesConfirmar
                );
                this.respuestaAbierta = false;
              } else {
                this.insertMessage(
                  1,
                  "La cantidad ingresada es incorrecta. " +
                    this.addEmoji(0x1f622),
                  false
                );
                this.findMenuSelected("otra");
              }
            } else if (
              typeof parseFloat(msgCleaned) === "number" &&
              this.memOpcActual === "prestamoIngresaCantidad"
            ) {
              if (msgCleaned <= parseFloat(this.user.cantidadPrestamo)) {
                let menu = [];
                this.montoSolicitadoPrestamo = msgCleaned;
                if ([2, 3, 4, 5, 6, 7].includes(this.user.gp)) {
                  menu = this.menuOpcionesPlazosQuincenal;
                } else {
                  menu = this.menuOpcionesPlazosSemanal;
                }
                this.insertMessage(
                  1,
                  "Muy bien, ¿En qué plazo te gustaría pagar tu préstamo?",
                  true,
                  menu
                );
              } else {
                this.insertMessage(
                  1,
                  "¡Recuerda que no puedo préstarte más de " +
                    this.user.cantidadMostrar +
                    "!",
                  false
                );
                this.findMenuSelected("Ingresar otra cantidad");
              }
            } else if (
              typeof parseFloat(msgCleaned) === "number" &&
              this.memOpcActual === "Abonar otra cantidad"
            ) {
              if (parseFloat(msgCleaned) <= parseFloat(this.montoAbonoMaximo)) {
                this.memMonto = msgCleaned;
                this.insertMessage(
                  1,
                  "¡Muy bien! A manera de resumen cabe recordarte que, con el abono de " +
                    this.formatMXN(msgCleaned) +
                    " tu nuevo saldo será de " +
                    this.formatMXN(
                      this.user.restante - (msgCleaned + this.user.abono)
                    ),
                  false
                );
                this.insertMessage(
                  1,
                  "¿Deseas confirmar este movimiento?",
                  true,
                  this.menuOpcionesConfirmar
                );
                this.respuestaAbierta = false;
              } else {
                this.insertMessage(
                  1,
                  "¡Recuerda que no puedes abonar más de " +
                    this.user.montoAbonoMaximo +
                    "!",
                  false
                );
                this.respuestaAbierta = false;
                this.findMenuSelected("Abonar otra cantidad");
              }
            } else if (this.memOpcActual === "Movimientos en proceso") {
              let detalleOpcion = this.movimientosEnProceso.filter((m) => {
                return m.TIPO_MOVIMIENTO === message;
              });
              this.memMovimientoCancelar = message.trim();
              this.insertMessage(
                1,
                "El día " +
                  detalleOpcion[0].fActual.trim() +
                  " realizazte el movimiento '" +
                  message +
                  "' que verás reflejado en tu nómina del día " +
                  detalleOpcion[0].fNomina.trim() +
                  this.addEmoji(0x1f913),
                true,
                this.menuCancelarMovimiento
              );
            } else {
              this.insertMessage(
                1,
                "Lo siento, no puedo entenderte. " + this.addEmoji(0x1f915),
                false,
                null,
                null
              );
              this.insertMessage(
                1,
                "¿Qué deseas hacer hoy?",
                true,
                this.menuPrincipalOptions,
                null
              );
            }
          }
          break;
        }
      }
    },
    validaConcepto294() {
      if (this.user.c294 === 1) {
        this.insertMessage(
          1,
          "Veo que estás en proceso de retirar de tu ahorro, esto puede afectar tus movimientos futuros, sobre todo si quieres solicitar un préstamo.",
          false
        );
        return true;
      }
      return false;
    },
    verificaBaja() {
      this.insertMessage(
        1,
        "No puedo continuar esta acción pues estas en proceso de darte de baja. " +
          this.addEmoji(0x1f615),
        false
      );
      this.insertMessage(
        1,
        "¿Qué deseas hacer?",
        true,
        this.menuEndConversation
      );
    },
    validaConcepto394() {
      if (this.user.c394 === 1) {
        this.insertMessage(
          1,
          "Intentaste duplicar una solicitud de movimiento, que tiene que ver con realizar cambios en tu ahorro o inscripción." +
            this.addEmoji(0x1f610),
          false
        );
        return true;
      }
      return false;
    },
    validaExistencia() {
      if (this.user.existe === 0) {
        this.findMenuSelected("No es parte de caja");
        return true;
      }
      return false;
    },
    getVacantes() {
      let params = {
        Opcion: 2,
        area: this.idArea,
      };
      axios
        .post(`${this.$store.getters.getHost}/Login/ws-alu.php`, params)
        .then((response) => {
          if (response.data.status == "EXPSESSION") {
            deleteToken();
          } else {
            let vacantesToShow = [];
            this.vacantes = response.data.vacantes;
            const promises = response.data.vacantes.map((a) => {
              return new Promise((resolve) => {
                const vacante = {
                  text: a.Nombre,
                  onClick: (params) =>
                    this.insertMessage(
                      params.type,
                      params.message,
                      params.haveOptions,
                      params.options,
                      params.item
                    ),
                };
                resolve(vacante);
              });
            });

            Promise.all(promises).then((vacantes) => {
              vacantesToShow = vacantes;
              vacantesToShow.push({
                text: "No postularme a ninguna vacante",
                onClick: (params) =>
                  this.insertMessage(
                    params.type,
                    params.message,
                    params.haveOptions,
                    params.options,
                    params.item
                  ),
              });
              this.insertMessage(
                1,
                "Estas son las vacantes disponibles. Si te interesa alguna da clic sobre el nombre. " +
                  this.addEmoji(0x1f642),
                true,
                vacantesToShow,
                null
              );
            });
          }
        })
        .catch(function(e) {
          console.log(e);
        });
    },
    getMovimientos() {
      let params = {
        Opcion: 11,
        token: localStorage.getItem("token"),
      };
      axios
        .post(
          `${this.$store.getters.getHost}/Nominas/AdmCajaAhorro/ws-caja-alu.php`,
          params
        )
        .then((response) => {
          this.loadingData = false;
          if (response.data.status == "EXPSESSION") {
            deleteToken();
          } else {
            if (response.data.status == "ERROR") {
              this.insertMessage(
                1,
                "No tienes movimientos en proceso en este periodo. " +
                  this.addEmoji(0x1f920),
                false
              );
              this.insertMessage(
                1,
                "¿Te puedo ayudar con algo más?",
                true,
                this.menuEndConversation
              );
            } else if (response.data.status == "OK") {
              this.movimientosEnProceso = response.data.caja;
              this.insertMessage(
                1,
                "Selecciona una opción:",
                true,
                this.menuOpcionesMisMovimientos
              );
            }
          }
        })
        .catch(function(e) {
          this.insertMessage(1, e, false);
        });
    },
    saveRespuestaReclu(message) {
      return new Promise((resolve) => {
        this.preguntasBase[this.indexPreguntas].respuesta = message;
        this.indexPreguntas++;

        resolve();
      });
    },
    disabledButtons(item) {
      let index = this.messages.indexOf(item);
      this.messages[index].disabled = true;
    },
    capitalizeString(str) {
      return str
        .toLowerCase()
        .replace(/(^|\s)\S/g, (match) => match.toUpperCase());
    },
    formatMXN(cadena) {
      return cadena.toLocaleString("es-MX", {
        style: "currency",
        currency: "MXN",
      });
    },
    addEmoji(codigo) {
      return String.fromCodePoint(codigo);
    },
    sendMessage() {
      if (this.msgUser != "") {
        this.insertMessage(2, this.msgUser, false, null, null);
        this.msgUser = "";
      }
    },
    saveCandidate() {
      const params = {
        Opcion: 3,
        idVacante: this.idVacante,
        datos: JSON.stringify(this.preguntasBase),
        token: localStorage.getItem("token"),
      };
      axios
        .post(
          `${this.$store.getters.getHost}/Nominas/AdmCajaAhorro/ws-caja-alu.php`,
          params
        )
        .then((response) => {
          if (response.data.status == "OK") {
            if (response.data.respuesta.status_tran == "OK") {
              this.insertMessage(
                1,
                "¡Listo! Tu información se guardo correctamente. Espera una pronta respuesta de nuestro departamento de reclutamiento" +
                  this.addEmoji(0x1f389) +
                  this.addEmoji(0x1f389) +
                  this.addEmoji(0x1f389) +
                  this.addEmoji(0x1f389),
                true,
                this.menuEndConversation
              );
            } else {
              this.insertMessage(
                1,
                "¡Oh no! Tu información no pudo guardarse. Si el problema persiste puedes contactar a reclutamiento@urrea.net. " +
                  this.addEmoji(0x1f625),
                true,
                this.menuEndConversation
              );
            }
          } else {
            this.insertMessage(
              1,
              "¡Oh no! Tu información no pudo guardarse. Si el problema persiste puedes contactar a reclutamiento@urrea.net. " +
                this.addEmoji(0x1f625),
              true,
              this.menuEndConversation
            );
          }
        })
        .catch((e) => {
          this.insertMessage(1, e + this.addEmoji(0x1f916), false, null);
        });
    },
    saveUserMovementAbono(opcion) {
      const params = {
        Opcion: opcion,
        token: localStorage.getItem("token"),
        fecha_prenom: this.setFormatDate(this.user.short_prenom),
        fecha_nom: this.setFormatDate(this.user.short_nom),
        grupoPago: this.user.gp,
        monto: this.memMonto,
        montoAhorro: this.user.monto,
      };
      axios
        .post(
          `${this.$store.getters.getHost}/Nominas/AdmCajaAhorro/ws-caja-alu.php`,
          params
        )
        .then((response) => {
          this.loadingData = false;
          if (response.data.status == "EXPSESSION") {
            deleteToken();
          } else if (response.data.status == "OK") {
            if (response.data.respuesta == 1) {
              this.insertMessage(
                1,
                "¡Listo! Pude registrar tu movimiento con éxito. A partir de la nómina del día " +
                  formatDate(this.setFormatDate(this.user.short_nom)) +
                  " verás reflejado el movimiento." +
                  this.addEmoji(0x1f60e),
                false
              );
              this.insertMessage(
                1,
                "¿Te puedo ayudar con algo más?",
                true,
                this.menuEndConversation
              );
            } else {
              this.insertMessage(
                1,
                "¡Oh no! Ocurrió un error. Lo siento... " +
                  this.addEmoji(0x1f62b),
                false
              );
              this.insertMessage(
                1,
                "¿Qué deseas hacer?",
                true,
                this.menuEndConversation
              );
            }
          } else {
            this.insertMessage(
              1,
              "¡Oh no! Ocurrió un error. Lo siento... " +
                this.addEmoji(0x1f62b),
              false
            );
            this.insertMessage(
              1,
              "¿Qué deseas hacer?",
              true,
              this.menuEndConversation
            );
          }
        })
        .catch(function(e) {
          this.insertMessage(1, e + this.addEmoji(0x1f916), false);
          this.insertMessage(
            1,
            "¿Qué deseas hacer?",
            true,
            this.menuEndConversation
          );
        });
    },
    saveDropOutUser() {
      const params = {
        Opcion: 14,
        token: localStorage.getItem("token"),
        fecha_prenom: this.setFormatDate(this.user.short_prenom),
        fecha_nom: this.setFormatDate(this.user.short_nom),
        grupoPago: this.user.gp,
        montoPrestamo: this.user.restante,
        montoAhorro: this.user.total,
      };
      axios
        .post(
          `${this.$store.getters.getHost}/Nominas/AdmCajaAhorro/ws-caja-alu.php`,
          params
        )
        .then((response) => {
          this.loadingData = false;
          if (response.data.status == "EXPSESSION") {
            deleteToken();
          } else if (response.data.status == "OK") {
            if (response.data.respuesta == 1) {
              this.insertMessage(
                1,
                "¡Listo! Pude registrar tu movimiento con éxito. A partir de la nómina del día " +
                  formatDate(this.setFormatDate(this.user.short_nom)) +
                  " verás reflejado el movimiento." +
                  this.addEmoji(0x1f60e),
                false
              );
              this.insertMessage(
                1,
                "¿Te puedo ayudar con algo más?",
                true,
                this.menuEndConversation
              );
            } else {
              this.insertMessage(
                1,
                "¡Oh no! Ocurrió un error. Lo siento... " +
                  this.addEmoji(0x1f62b),
                false
              );
              this.insertMessage(
                1,
                "¿Qué deseas hacer?",
                true,
                this.menuEndConversation
              );
            }
          } else {
            this.insertMessage(
              1,
              "¡Oh no! Ocurrió un error. Lo siento... " +
                this.addEmoji(0x1f62b),
              false
            );
            this.insertMessage(
              1,
              "¿Qué deseas hacer?",
              true,
              this.menuEndConversation
            );
          }
        })
        .catch(function(e) {
          this.insertMessage(1, e + this.addEmoji(0x1f916), false);
          this.insertMessage(
            1,
            "¿Qué deseas hacer?",
            true,
            this.menuEndConversation
          );
        });
    },
    saveUserMovement(opcion) {
      const params = {
        Opcion: opcion,
        token: localStorage.getItem("token"),
        fecha_prenom: this.setFormatDate(this.user.short_prenom),
        fecha_nom: this.setFormatDate(this.user.short_nom),
        grupoPago: this.user.gp,
        monto: this.memMonto,
      };
      axios
        .post(
          `${this.$store.getters.getHost}/Nominas/AdmCajaAhorro/ws-caja-alu.php`,
          params
        )
        .then((response) => {
          this.loadingData = false;
          if (response.data.status == "EXPSESSION") {
            deleteToken();
          } else if (response.data.status == "OK") {
            if (response.data.respuesta == 1) {
              this.insertMessage(
                1,
                "¡Listo! Pude registrar tu movimiento con éxito. A partir de la nómina del día " +
                  formatDate(this.setFormatDate(this.user.short_nom)) +
                  " verás reflejado el movimiento." +
                  this.addEmoji(0x1f60e),
                false
              );
              this.insertMessage(
                1,
                "¿Te puedo ayudar con algo más?",
                true,
                this.menuEndConversation
              );
            } else {
              this.insertMessage(
                1,
                "¡Oh no! Ocurrió un error. Lo siento... " +
                  this.addEmoji(0x1f62b),
                false
              );
              this.insertMessage(
                1,
                "¿Qué deseas hacer?",
                true,
                this.menuEndConversation
              );
            }
          } else {
            this.insertMessage(
              1,
              "¡Oh no! Ocurrió un error. Lo siento... " +
                this.addEmoji(0x1f62b),
              false
            );
            this.insertMessage(
              1,
              "¿Qué deseas hacer?",
              true,
              this.menuEndConversation
            );
          }
        })
        .catch(function(e) {
          this.insertMessage(1, e + this.addEmoji(0x1f916), false);
          this.insertMessage(
            1,
            "¿Qué deseas hacer?",
            true,
            this.menuEndConversation
          );
        });
    },
    cancelUserMovement() {
      const params = {
        Opcion: 12,
        token: localStorage.getItem("token"),
        movimiento: this.memMovimientoCancelar,
      };
      axios
        .post(
          `${this.$store.getters.getHost}/Nominas/AdmCajaAhorro/ws-caja-alu.php`,
          params
        )
        .then((response) => {
          if (response.data.status == "EXPSESSION") {
            deleteToken();
          } else if (response.data.status == "OK") {
            if (response.data.respuesta == 1) {
              this.insertMessage(
                1,
                "¡Listo! Pude registrar tu movimiento con éxito. A partir de la nómina del día " +
                  formatDate(this.setFormatDate(this.user.short_nom)) +
                  " verás reflejado el movimiento." +
                  this.addEmoji(0x1f60e),
                false
              );
              this.insertMessage(
                1,
                "¿Te puedo ayudar con algo más?",
                true,
                this.menuEndConversation
              );
            } else if (response.data.respuesta == 3) {
              this.insertMessage(
                1,
                "No puedo cancelar ese movimiento porque tienes una solitud de préstamo aprobada, para eso primero cancela tu solicitud de préstamo" +
                  this.addEmoji(0x1f62b),
                false
              );
              this.insertMessage(
                1,
                "¿Qué deseas hacer?",
                true,
                this.menuEndConversation
              );
            } else {
              this.insertMessage(
                1,
                "¡Oh no! Ocurrió un error. Lo siento... " +
                  this.addEmoji(0x1f62b),
                false
              );
              this.insertMessage(
                1,
                "¿Qué deseas hacer?",
                true,
                this.menuEndConversation
              );
            }
          } else {
            this.insertMessage(
              1,
              "¡Oh no! Ocurrió un error. Lo siento... " +
                this.addEmoji(0x1f62b),
              false
            );
            this.insertMessage(
              1,
              "¿Qué deseas hacer?",
              true,
              this.menuEndConversation
            );
          }
        })
        .catch(function(e) {
          this.insertMessage(1, e + this.addEmoji(0x1f916), false);
          this.insertMessage(
            1,
            "¿Qué deseas hacer?",
            true,
            this.menuEndConversation
          );
        });
    },
    saveUserLoanRequest() {
      const params = {
        Opcion: 8,
        token: localStorage.getItem("token"),
        fecha_prenom: this.setFormatDate(this.user.short_prenom),
        fecha_nom: this.setFormatDate(this.user.short_nom),
        grupoPago: this.user.gp,
        monto: this.montoSolicitadoPrestamo,
        semana: this.user.numSemana,
        quincena: this.user.numQuincena,
        inicio_nomina: this.setFormatDate(this.user.inicNomina),
        plazo: this.periodoPrestamo,
        abono: this.abonoFijoPrestamo,
        prdo: this.getPeriodoSiguiente(),
        interes: this.tasaDelPeriodo,
      };
      axios
        .post(
          `${this.$store.getters.getHost}/Nominas/AdmCajaAhorro/ws-caja-alu.php`,
          params
        )
        .then((response) => {
          this.loadingData = false;
          if (response.data.status == "EXPSESSION") {
            deleteToken();
          } else if (response.data.status == "OK") {
            if (response.data.respuesta == 1) {
              this.insertMessage(
                1,
                "¡Listo! Pude registrar tu movimiento con éxito. A partir de la nómina del día " +
                  formatDate(this.setFormatDate(this.user.short_nom)) +
                  " verás reflejado el movimiento." +
                  this.addEmoji(0x1f60e),
                false
              );
              this.insertMessage(
                1,
                "¿Te puedo ayudar con algo más?",
                true,
                this.menuEndConversation
              );
            } else {
              this.insertMessage(
                1,
                "¡Oh no! Ocurrió un error. Lo siento... " +
                  this.addEmoji(0x1f62b),
                false
              );
              this.insertMessage(
                1,
                "¿Qué deseas hacer?",
                true,
                this.menuEndConversation
              );
            }
          } else {
            this.insertMessage(
              1,
              "¡Oh no! Ocurrió un error. Lo siento... " +
                this.addEmoji(0x1f62b),
              false
            );
            this.insertMessage(
              1,
              "¿Qué deseas hacer?",
              true,
              this.menuEndConversation
            );
          }
        })
        .catch(function(e) {
          this.insertMessage(1, e + this.addEmoji(0x1f916), false);
          this.insertMessage(
            1,
            "¿Qué deseas hacer?",
            true,
            this.menuEndConversation
          );
        });
    },
    limpiarCadena(cadena) {
      const limpiada = cadena.replace(/[^0-9.]/g, "");
      const numero = parseFloat(limpiada);
      return numero;
    },
    reloadConversation() {
      this.preguntasBase.length = 0;
      this.messages.length = 0;
      this.areas.length = 0;
      this.areasOptions.length = 0;
      this.loadingData = true;
      this.esReclutamiento = false;
      this.user = {};
      this.startConversation();
    },
    endConversation() {
      this.reloadConversation();
      this.$emit("cierraCA");
    },
    getPeriodoSiguiente() {
      if ([1, 8, 10].includes(this.user.gp)) {
        return this.user.numSemana + 1;
      } else {
        return this.user.numQuincena + 1;
      }
    },
    getInteresesPrestamo(cantidad, abonos) {
      const tasa_caja = this.variables.tiie + this.variables.tasa;
      let periodoPorGrupo = 0;
      if ([1, 8, 10].includes(this.user.gp)) {
        periodoPorGrupo = 52;
      } else {
        periodoPorGrupo = 24;
      }

      this.tasaDelPeriodo = tasa_caja / periodoPorGrupo;

      let nCantidad = cantidad;
      const abono = cantidad / abonos;
      this.abonoFijoPrestamo = abono;
      let interesFinal = 0;

      for (let i = 0; i < abonos; i++) {
        let interesAbono = (nCantidad * this.tasaDelPeriodo) / 100;
        interesFinal += interesAbono;
        nCantidad -= abono;
      }

      return interesFinal;
    },
    getInteresesPrestamoPDF() {
      let listaAbonos = [];
      const tasa_caja = this.variables.tiie + this.variables.tasa;
      let periodoPorGrupo = 0;
      if ([1, 8, 10].includes(this.user.gp)) {
        periodoPorGrupo = 52;
      } else {
        periodoPorGrupo = 24;
      }

      const tasaDelPeriodo = tasa_caja / periodoPorGrupo;
      let nCantidad = parseFloat(this.montoSolicitadoPrestamo);
      const abono =
        parseFloat(this.montoSolicitadoPrestamo) / this.periodoPrestamo;
      let interesFinal = 0;
      let numAbono = 1;

      for (let i = 0; i < this.periodoPrestamo; i++) {
        let interesAbono = (nCantidad * tasaDelPeriodo) / 100;
        interesFinal += interesAbono;
        let abonoFinal = abono + interesAbono;
        let listaAux = [
          "No. " + numAbono,
          this.formatMXN(nCantidad),
          this.formatMXN(abono),
          this.formatMXN(interesAbono),
          this.formatMXN(abonoFinal),
        ];
        nCantidad -= abono;
        numAbono++;
        listaAbonos.push(listaAux);
      }
      listaAbonos.push([
        "TOTALES",
        this.formatMXN(0),
        this.formatMXN(0),
        this.formatMXN(interesFinal),
        this.formatMXN(parseFloat(this.montoSolicitadoPrestamo) + interesFinal),
      ]);
      return listaAbonos;
    },
    getDocument(document) {
      if (document === "Términos y condiciones") {
        const url =
          this.$store.getters.getHost +
          "/Nominas/AdmCajaAhorro/Términos y condiciones CAJA DE AHORRO.pdf";
        window.open(url, "_blank");
      } else {
        this.getPDF();
      }
    },
    getPDF() {
      let doc = new jsPDF({
        format: "a4",
        unit: "mm",
      });
      const imgData = require("@/assets/img/logo-urre-surtek-lock.png"); // Ruta relativa a la imagen en la carpeta de assets
      doc.addImage(imgData, "PNG", 50, 10, 120, 8); // Ajusta las coordenadas y dimensiones según tus necesidades

      doc.setFontSize(10);
      doc.setFont("helvetica", "bold");
      doc.setTextColor(0, 120, 195);
      doc.text(10, 25, "Tabla de amortización " + this.user.prenom);
      doc.setFontSize(8);
      doc.setFont("helvetica", "bold");
      doc.setTextColor(255, 0, 0);
      doc.text(
        10,
        30,
        "*Recibo informativo, no cuenta como documento oficial."
      );
      doc.autoTable({
        head: this.headerTablaAmortizacion,
        body: this.getInteresesPrestamoPDF(),
        startY: 35,
        styles: {
          fontSize: 8,
        },
        headStyles: this.head_styles,
        bodyStyles: this.body_styles,
      });
      doc.save("tabla-amortizacion-prestamo.pdf");
      doc = null;
    },
    setFormatDate(date) {
      let fecha = new Date(date);

      let diaUTC = fecha.getUTCDate();
      let mesUTC = fecha.getUTCMonth() + 1;
      let anioUTC = fecha.getUTCFullYear();

      diaUTC = diaUTC < 10 ? "0" + diaUTC : diaUTC;
      mesUTC = mesUTC < 10 ? "0" + mesUTC : mesUTC;

      return anioUTC + "-" + mesUTC + "-" + diaUTC;
    },
  },
};
</script>

<style scoped>
.containerChat {
  background: white;
  height: 65vh;
  overflow: auto;
  transition: all 1.5s;
}

.v-dialog__content {
  align-items: end !important;
  display: flex;
  height: 100%;
  justify-content: end !important;
  left: 0;
  pointer-events: none;
  position: fixed;
  top: 0;
  transition: 0.2s cubic-bezier(0.25, 0.8, 0.25, 1), z-index 1ms;
  width: 100%;
  z-index: 6;
  outline: none;
}

.mi-boton .v-btn__content {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 768px) {
  .containerChat {
    background: white;
    height: calc(100vh - 120px);
    overflow: auto;
  }
}
</style>