import axios from "axios";
export default {
  name: 'administracion-de-encuestas',
  components: {},
  props: [],
  data() {
    return {
      preguntas: [],
      encuestas: [],
      tituloencuesta: "",
      descencuesta: "",
      tabs: null,
      rules: [v => v.length <= 249 || 'Maximo 250 caracteres'],
      search: '',
      loading: false,
      dencuestadetalles: false,
      selectedItem: null,
      encinfo:[],

      headerInfo: [
        { text: '# PREGUNTA', value: 'NUM_PREGUNTA', align: 'center', class: 'primary white--text overline font-weight-bold' },
        { text: 'PREGUNTA', value: 'PREGUNTA', align: 'center', class: 'primary white--text overline font-weight-bold' },
        { text: 'TIPO DE PREGUNTA', value: 'TIPO_PREGUNTA', align: 'center', class: 'primary white--text overline font-weight-bold' },
        { text: 'RESPUESTA', value: 'RESPUESTA', align: 'center', class: 'primary white--text overline font-weight-bold' },
        { text: 'USUARIO REGISTRO', value: 'USUARIO_REGISTRO', align: 'center', class: 'primary white--text overline font-weight-bold' },
        { text: 'FEHA DE REGISTRO', value: 'FECHA_ACTUALIZA', align: 'center', class: 'primary white--text overline font-weight-bold' },
      ],

      encselec: [],

      headerReporteEncuestas: [

        { text: 'ID', value: 'ID', align: 'center', class: 'primary white--text overline font-weight-bold' },
        { text: 'ENCUESTA', value: 'NOMBREENCUESTA', align: 'center', class: 'primary white--text overline font-weight-bold' },
        { text: 'USUARIO REGISTRO', value: 'Nombre', align: 'center', class: 'primary white--text overline font-weight-bold' },
        { text: 'DESCRIPCION', value: 'DESCRIPCION', align: 'center', class: 'primary white--text overline font-weight-bold' },
        { text: 'ESTADO', value: 'ESTADO', align: 'center', class: 'primary white--text overline font-weight-bold' },
        { text: 'FEHA DE REGISTRO', value: 'FECHA_ACTUALIZA', align: 'center', class: 'primary white--text overline font-weight-bold' },
        { text: 'ACCIONES', value: 'actions', align: 'center', class: 'primary white--text overline font-weight-bold' },
      ],

      // ----------------------- SNACKBAR------------------------
      textoBarra: '',
      colorBarra: '',
      muestraBarra: false,
      timeout: 2000,

    }
  },
  computed: {

  },
  mounted() {

  },
  methods: {

    agregarPreguntaCerrada() {
      const numOpciones = prompt("¿Cuántas opciones necesita la pregunta cerrada?");
      if (numOpciones) {
        const opciones = [];
        for (let i = 0; i < numOpciones; i++) {
          const opcion = prompt(`Ingrese la opción ${i + 1}`);
          if (opcion) {
            opciones.push(opcion);
          }
        }
        this.preguntas.push({ id: this.preguntas.length + 1, tipo: 'Pregunta Cerrada', texto: '', opciones: opciones });
      }
    },
    agregarPreguntaAbierta() {
      this.preguntas.push({ id: this.preguntas.length + 1, tipo: 'Pregunta Abierta', texto: '', opciones: '' });
    },
    agregarPreguntaMultiple() {
      const numOpciones = prompt("¿Cuántas opciones necesita la pregunta de opción multiple?");
      if (numOpciones) {
        const opciones = [];
        for (let i = 0; i < numOpciones; i++) {
          const opcion = prompt(`Ingrese la opción ${i + 1}`);
          if (opcion) {
            opciones.push(opcion);
          }
        }
        this.preguntas.push({ id: this.preguntas.length + 1, tipo: 'Pregunta de Opción Múltiple', texto: '', opciones: opciones });
      }

    },
    saveEncuesta() {
      if (this.tituloencuesta == "" || this.descencuesta == "") {
        this.textoBarra = 'No se puede registrar la encuesta sin un titulo o descripción.';
        this.colorBarra = 'error';
        this.muestraBarra = true;
      } else {
        if (this.preguntas.length > 0) {
          var cont = 0;
          for (let i = 0; i < this.preguntas.length; i++) {
            let pregunta = this.preguntas[i];
            // console.log("ID: " + pregunta.id);
            // console.log("Tipo: " + pregunta.tipo);
            // console.log("Texto: " + pregunta.texto);
            if (pregunta.texto == "") {
              cont++;
            }
          }

          if (cont >= 1) {
            this.textoBarra = 'No se puede registrar la encuesta por que hay campos vacios.';
            this.colorBarra = 'error';
            this.muestraBarra = true;
          } else {

            let params = {
              Opcion: 1,
              encuesta: this.preguntas,
              nombre: this.tituloencuesta,
              descripcion: this.descencuesta,
              token: localStorage.getItem('token'),
            }
            axios
              .post(
                `${this.$store.getters.getHost}/general/ws-AdministradorDeEncuestas.php`, params
              )
              .then((response) => {
                // console.log(response.data);
                if (response.data.status == "ERROR") {
                  console.log("error");
                  this.textoBarra = 'No se registro la encuesta.';
                  this.colorBarra = 'error';
                  this.muestraBarra = true;
                  this.preguntas = [];
                } else if (response.data.status == "OK") {
                  console.log(response.data.status);
                  this.textoBarra = 'Encuesta ' + this.tituloencuesta + 'registrado correctamente';
                  this.colorBarra = 'success';
                  this.muestraBarra = true;
                  this.preguntas = [];
                }

              })
              .catch(function (e) {
                alert("Ocurrió un error: " + e);
              });
          }
        } else {
          this.textoBarra = 'No se puede registrar la encuesta sin preguntas.';
          this.colorBarra = 'error';
          this.muestraBarra = true;
        }
      }
    },

    getEncuestas() {
      this.loading = true;
      let params = {
        Opcion: 2,
        token: localStorage.getItem("token"),
      };
      axios.post(`${this.$store.getters.getHost}/general/ws-AdministradorDeEncuestas.php`, params).then((response) => {
        if (response.data.status == "ERROR") {
          console.log("error");
          this.textoBarra = 'No se encontarón encuestas.';
          this.colorBarra = 'error';
          this.muestraBarra = true;
        } else if (response.data.status == "OK") {
          this.encuestas = response.data.Encuestas;
          console.log(response.data.status);
          this.textoBarra = 'Encuestas encontradas: ' + this.encuestas.length;
          this.colorBarra = 'success';
          this.muestraBarra = true;
          this.loading = false;
          this.getSelectEncuesta();
        }
      })
        .catch(function (e) {
          let result = e.toString().includes("401");
          if (result) {
            window.location.reload();
          } else {
            alert(e);
          }
        });
    },
    Clean() {
      this.preguntas = [];
      this.tituloencuesta = '';
      this.descencuesta = '';
    },
    EstadoEncuesta(item) {
      console.log("a" + item.ID);
      if (item.ESTADO == "Innactivo") {
        let encuestaActiva = false;

        this.encuestas.forEach((encuesta) => {
          if (encuesta.ESTADO === "Activa") {
            encuestaActiva = true;
          }
        });

        if (encuestaActiva) {
          this.textoBarra = 'No se puede activar la encuesta ' + item.NOMBREENCUESTA + ' por que hay otra encuesta activa';
          this.colorBarra = 'error';
          this.muestraBarra = true;
        } else {
          console.log("No hay encuestas activas");
          this.ActualizaEstado(item.ID, 1);
        }
      } else {
        this.textoBarra = 'La encuesta esta activa y se va a desactivar.';
        this.colorBarra = 'error';
        this.muestraBarra = true;
        this.ActualizaEstado(item.ID, 0);
      }
    },
    ActualizaEstado(idencuesta, estado) {
      let params = {
        Opcion: 3,
        ID_ENCUESTA: idencuesta,
        ESTADO: estado,
        token: localStorage.getItem("token"),
      };
      axios
        .post(
          `${this.$store.getters.getHost}/general/ws-AdministradorDeEncuestas.php`,
          params
        )
        .then((response) => {
          if (response.data.status != "EXPSESSION") {
            if (response.data.status == "OK") {
              console.log("Actualizado");
              this.getEncuestas();
            }

            if (response.data.status == "ERROR") {
              console.log("error");
            }
          }
        });
    },
    getSelectEncuesta() {
      let params = {
        Opcion: 8,
        token: localStorage.getItem("token"),
      };
      axios.post(`${this.$store.getters.getHost}/general/ws-AdministradorDeEncuestas.php`, params).then((response) => {
        if (response.data.status == "ERROR") {
          console.log("error");
          this.textoBarra = 'No se encontarón encuestas.';
          this.colorBarra = 'error';
          this.muestraBarra = true;
        } else if (response.data.status == "OK") {
          this.encselec = response.data.Respuesta;
          console.log(response.data.status);
        }
      })
        .catch(function (e) {
          let result = e.toString().includes("401");
          if (result) {
            window.location.reload();
          } else {
            alert(e);
          }
        });
    },
    opendetalles(){
      this.selectedItem = "";
      this.encinfo=[];
      this.dencuestadetalles = true;
    },
    getEncInfo() {
      let params = {
        ID_ENCUESTA: this.selectedItem,
        Opcion: 9,
        token: localStorage.getItem("token"),
      };
      axios.post(`${this.$store.getters.getHost}/general/ws-AdministradorDeEncuestas.php`, params).then((response) => {
        if (response.data.status == "ERROR") {
          console.log("error");
          this.textoBarra = 'No se encontarón encuestas.';
          this.colorBarra = 'error';
          this.muestraBarra = true;
        } else if (response.data.status == "OK") {
          this.encinfo = response.data.Respuesta;
          console.log(response.data.status);
        }
      })
        .catch(function (e) {
          let result = e.toString().includes("401");
          if (result) {
            window.location.reload();
          } else {
            alert(e);
          }
        });
    },
  }
}


