var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"adm-depositos"},[_c('v-container',{attrs:{"fluid":""}},[_c('v-card',{staticClass:"mx-auto",staticStyle:{"display":"grid","place-content":"center","padding-bottom":"1.5em","margin-bottom":"1.5em"},attrs:{"elevation":"2","max-width":"95%"}},[_c('h2',{staticStyle:{"text-align":"center","font-weight":"bold","color":"rgb(0, 120, 180)"}},[_vm._v("Listado de depositos")]),_c('br'),_c('p',{staticStyle:{"text-align":"center"}},[_vm._v(" Simbologia de estatus de los pagos ")]),_c('v-chip-group',{attrs:{"column":""}},_vm._l((_vm.estatusPagos),function(item,index){return _c('v-btn',{key:index,attrs:{"x-small":"","rounded":"","elevation":"1","color":_vm.colorsTema[index].color,"dark":_vm.colorsTema[index].tema}},[_vm._v(" "+_vm._s(_vm.estatusPagos[index]))])}),1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers1,"items":_vm.depositos,"items-per-page":10,"loading":_vm.cargando,"search":_vm.buscar},scopedSlots:_vm._u([{key:"item.estatus",fn:function(ref){
var item = ref.item;
return [(item.estatus == 1)?_c('div',[_c('v-chip-group',{attrs:{"column":""}},[_c('v-btn',{attrs:{"x-small":"","rounded":"","elevation":"1","color":_vm.colorsTema[0].color,"dark":_vm.colorsTema[0].tema}})],1)],1):(item.estatus == 2)?_c('div',[_c('v-chip-group',{attrs:{"column":""}},[_c('v-btn',{attrs:{"x-small":"","rounded":"","elevation":"1","color":_vm.colorsTema[1].color,"dark":_vm.colorsTema[1].tema}})],1)],1):_c('div',[_c('v-chip-group',{attrs:{"column":""}},[_c('v-btn',{attrs:{"x-small":"","rounded":"","elevation":"1","color":_vm.colorsTema[2].color,"dark":_vm.colorsTema[2].tema}})],1)],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(!item.estatus)?_c('div',{staticClass:"text-center"},[_c('v-btn',{staticClass:"mx-2",attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.openComentarioDialgo({
              accion:1,
              id_movimiento:item.ID,
              fechaFinal:item.FECHA_PRENOM,
              usuarioMovimiento:item.EMPLID
            })}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(" mdi-check ")])],1),_c('v-btn',{staticClass:"mx-2",attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.openComentarioDialgo({
              accion:2,
              id_movimiento:item.ID,
              fechaFinal:item.FECHA_PRENOM,
              usuarioMovimiento:item.EMPLID
            })}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(" mdi-close ")])],1)],1):_vm._e()]}}])}),[_c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"scrollable":"","max-width":"400px"},model:{value:(_vm.openComentarioDialg),callback:function ($$v) {_vm.openComentarioDialg=$$v},expression:"openComentarioDialg"}},[_c('v-card',[_c('v-card-title',[_vm._v("Escribir comentario")]),_c('v-divider'),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-text-field',{attrs:{"label":"Comentario","required":""},model:{value:(_vm.comentario),callback:function ($$v) {_vm.comentario=$$v},expression:"comentario"}})],1)],1)],1)],1),_c('v-divider'),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"color":"error","dark":""},on:{"click":function($event){_vm.openComentarioDialg = false}}},[_vm._v(" cerrar ")]),(_vm.comentario)?_c('v-btn',{attrs:{"color":"success","dark":""},on:{"click":_vm.acciones}},[_vm._v(" Guardar ")]):_c('v-btn',{attrs:{"depressed":"","disabled":""}},[_vm._v(" Guardar ")])],1)],1)],1)],1)],_c('v-sheet',{attrs:{"id":"snack-bar-error-incidencias"}},[_c('snack-bar-error-incidencias',{attrs:{"muestraBarra":_vm.muestraBarra,"texto":_vm.textoBarra,"color":_vm.colorBarra},on:{"cierraBarra":function($event){_vm.muestraBarra = false}}})],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }