<template>
  <v-container>
    <v-dialog v-model="dialogConsultaEPP" persistent>
      <v-card>
        <v-card-title class="text-subtitle-2 primary white--text">
          Certificaciones
          <v-spacer></v-spacer>
          <v-icon small dark @click="$emit('cierraModal', 0)">mdi-close</v-icon>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" xl="12" lg="12" md="12" sm="12" xs="12">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                class="mb-5"
                label="Buscar"
                single-line
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
          <v-spacer></v-spacer>
          <v-data-table
            dense
            :loading="loadingContent"
            :headers="headers"
            :items="eppPorCertificaciones"
            sort-by-text="Ordenar por"
            no-data-text="No hay registros."
            items-per-page-all-text="Todos"
            items-per-page-text="Elementos por página"
            :show-current-page="false"
            :show-first-last-page="false"
            :search="search"
            loading-text="Cargando contenido..."
            :footer-props="{
              'items-per-page-options': [5, 10, 15, 20, 25],
              'items-per-page-text': 'Elementos por página',
            }"
            :single-expand="singleExpand"
            :expanded.sync="expanded"
            item-key="id"
            show-expand
            class="elevation-1"
          >
            <template v-slot:item.nombre="{ item }">
              <div align="left">
                {{ item.nombre }}
              </div>
            </template>

            <template v-slot:item.actions="{ item }">
              <div class="d-flex justify-center">
                <v-tooltip top color="teal lighten-2">
                  <template v-slot:activator="{ on, attrs }">
                    <v-chip-group column>
                      <v-btn
                        x-small
                        rounded
                        elevation="1"
                        color="teal darken-1"
                        dark
                        @click="editItem(item)"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon x-small>mdi mdi-plus</v-icon>
                      </v-btn>
                    </v-chip-group>
                  </template>
                  <span>Añadir</span>
                </v-tooltip>
              </div>
            </template>

            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length" class="px-10 py-5">
                <div v-if="item.epp">
                  <v-data-iterator
                    :items="item.epp"
                    hide-default-footer
                    :items-per-page.sync="item.epp.length"
                    color="grey"
                  >
                    <v-card>
                      <v-card-title class="subheading font-weight-bold">
                        Equipo De protección personal asignado
                      </v-card-title>
                      <v-divider></v-divider>
                      <v-list dense>
                        <v-list-item v-for="(item, i) in item.epp" :key="i.id">
                          <v-list-item-content>Nombre EPP:</v-list-item-content>
                          <v-list-item-content class="align-end">
                            [{{ item.codigo }}] {{ item.nombreEPP }}
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-tooltip top color="pink lighten-2">
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  icon
                                  v-bind="attrs"
                                  v-on="on"
                                  @click="openDialogConfirm(item)"
                                >
                                  <v-icon color="pink lighten-1">
                                    mdi-close-circle-outline
                                  </v-icon>
                                </v-btn>
                              </template>
                              <span>Remover</span>
                            </v-tooltip>
                          </v-list-item-action>
                        </v-list-item>
                      </v-list>
                    </v-card>
                  </v-data-iterator>
                </div>
                <div v-else>
                  <v-card-text
                    class="d-flex justify-center subtitle-1 font-italic"
                    >Sin contenido</v-card-text
                  >
                </div>
              </td>
            </template>
          </v-data-table>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="rounded-lg elevation-0"
            color="teal darken-1"
            dark
            :loading="loading"
            @click="validar()"
          >
            <v-icon left>mdi-check-circle-outline</v-icon> Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogConfirm" persistent width="50%">
      <v-card>
        <v-card-title class="text-subtitle-2 primary white--text">
          Remover Epp
          <v-spacer></v-spacer>
          <v-icon small dark @click="dialogConfirm = false">mdi-close</v-icon>
        </v-card-title>
        <v-card-text>
          <v-card class="ma-5 pa-5 rodunded-xxl" elevation="5">
            ¿Estas seguro de remover éste articulo de una certificación? <br />
            EPP:{{ editedItem.nombreEPP }}
          </v-card>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="rounded-lg elevation-0"
            color="teal darken-1"
            dark
            :loading="loading"
            @click="borrarItem()"
          >
            <v-icon left>mdi-check-circle-outline</v-icon> Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <FormCargaEppPorCertificaciones
      v-if="dialogAsignacion"
      :dialog="dialogAsignacion"
      :item="editedItem"
      :tipo="tipo"
      @cerrarTicket="dialogAsignacion = false; getUpas();"
    />
    <SnackBarError
      v-if="muestraBarra"
      :muestraBarra="muestraBarra"
      :texto="textoBarra"
      :color="colorBarra"
      @cierraBarra="muestraBarra = false"
    />
  </v-container>
</template>

<script>
import axios from "axios";
import { deleteToken } from "../../../../../store/Funciones/funciones";
import SnackBarError from "../../../General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue";
import FormCargaEppPorCertificaciones from "../Components/FormCargaEppPorCertificaciones.vue";
export default {
  name: "admon-epp-por-certificaciones",
  components: { SnackBarError,FormCargaEppPorCertificaciones },
  props: ["dialogConsultaEPP", "idUpa"],
  data() {
    return {
      textoBarra: "",
      colorBarra: "",
      muestraBarra: false,

      eppPorCertificaciones: [],
      search: "",
      loadingContent: true,
      loading: false,
      expanded: [],
      singleExpand: true,
      editedIndex: -1,
      headers: [
        {
          text: "ID Certificación",
          value: "idCertificacion",
          align: "center",
          sortable: true,
        },
        {
          text: "Certificación",
          value: "nombre",
          align: "center",
          sortable: true,
        },
        {
          text: "Acciones",
          value: "actions",
          align: "center",
          sortable: true,
        },
        { text: "", value: "data-table-expand" },
      ],
      defaultItem: {
        id: "",
        idEPP: "",
        idSeccion: "",
        idOperacion: "",
        nombreEPP: "",
        tipoAsignacion: "",
      },
      editedItem: {
        id: "",
        idEPP: "",
        idSeccion: "",
        idOperacion: "",
        nombreEPP: "",
        tipoAsignacion: "",
      },
      dialogAsignacion: false,
      dialogConfirm: false,
      //   idCertificacion: "",
      //   idUpa: "",
      //   tipo: "",
        tipo: "individual",
    };
  },
  computed: {},
  mounted() {
    this.getUpas();
  },
  methods: {
    getUpas() {
      this.eppPorCertificaciones = [];
      let params = {
        Opcion: 11,
        upas: this.idUpa,
        token: localStorage.getItem("token"),
      };
      this.loadingContent = true;
      axios
        .post(
          `${this.$store.getters.getHost}/General/EPP/ws-admon-epp.php`,
          params
        )
        .then((response) => {
          if (response.data.status == "OK") {
            this.eppPorCertificaciones = response.data.eppCert;
          } else if (response.data.status == "VACIO") {
            this.textoBarra = "No se encontraron registros.";
            this.colorBarra = "info";
            this.muestraBarra = true;
          } else if (response.data.status == "EXPRESSION") {
            deleteToken();
          }
        })
        .catch(function (e) {
          alert("Ocurrió un error: " + e);
        });
      this.loadingContent = false;
    },

    editItem(item) {
      this.editedIndex = this.eppPorCertificaciones.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogAsignacion = true;
    },

    borrarItem() {
      let params = {
        Opcion: 12,
        tipo:"individual",
        item: this.editedItem,
        token: localStorage.getItem("token"),
      };
      console.log(params);
      axios
        .post(
          `${this.$store.getters.getHost}/General/EPP/ws-admon-epp.php`,
          params
        )
        .then((response) => {
          this.loading = false;
          if (response.data.status == "OK") {
            this.textoBarra = "Certificación removida correctamente.";
            this.colorBarra = "success";
            this.muestraBarra = true;
            this.close();
            this.getUpas();
          } else if (response.data.status == "ERROR") {
            this.textoBarra = "Algo salió mal.";
            this.colorBarra = "error";
            this.muestraBarra = true;
          } else if (response.data.status == "EXPRESSION") {
            deleteToken();
          }
          this.loading = false;
        })
        .catch(function (e) {
          alert("Ocurrió un error: " + e);
        });
      this.loadingContent = false;
    },

    openDialogConfirm(item) {
      this.dialogConfirm = true;
      this.editedItem = item;
      console.log(this.editedItem);
    },

    close() {
      this.dialogConfirm = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
  },
};
</script>