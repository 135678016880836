var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"transition":"dialog-bottom-transition","content-class":"fix-border-dialog","max-width":"1250","persistent":"","scrollable":""},model:{value:(_vm.verDialogHistorialMovimientos),callback:function ($$v) {_vm.verDialogHistorialMovimientos=$$v},expression:"verDialogHistorialMovimientos"}},[_c('v-card',{staticClass:"rounded-xl"},[_c('v-toolbar',{attrs:{"color":"primary","dark":""}},[_c('label',{staticClass:"overline font-weight-bold"},[_vm._v(" Historial de movimientos en ALU ["+_vm._s(_vm.emplid)+"] ")]),_c('v-spacer'),_c('v-icon',{attrs:{"dark":""},on:{"click":function($event){return _vm.$emit('cierraDialogHistorialMovimientos')}}},[_vm._v("mdi-close")])],1),_c('v-card-text',{staticClass:"pt-3"},[_c('v-data-table',{attrs:{"dense":"","items":_vm.movimientos,"headers":_vm.headers,"search":_vm.search,"footer-props":{
            'items-per-page-text': 'Elementos por página',
            pageText: '{0}-{1} de {2}',
          },"loading":_vm.loadingData,"loading-text":"Cargando montos...","no-data-text":"No hay registros"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticStyle:{"display":"flex","justify-content":"space-between"}},[_c('v-text-field',{staticClass:"rounded-pill",attrs:{"dense":"","outlined":"","prepend-inner-icon":"mdi-magnify","placeholder":"Buscar..."},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('div',[_c('vue-json-to-csv',{attrs:{"json-data":_vm.movimientos,"labels":_vm.headers_to_export,"csv-title":'ReporteHistorialAlu_' + _vm.emplid}},[_c('v-tooltip',{attrs:{"bottom":"","color":"green darken-2"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"rounded-pill mr-2",attrs:{"depressed":"","color":"green darken-2","dark":"","fab":"","small":"","outlined":"","loading":_vm.loadingData}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-microsoft-excel")])],1)]}}])},[_c('span',[_vm._v("Exportar CSV")])])],1)],1)],1)]},proxy:true},{key:"item.FECHA_ACTUAL",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.setFormatDate(item.FECHA_ACTUAL))+" ")]}},{key:"item.FECHA_PRENOM",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.setFormatDate(item.FECHA_PRENOM))+" ")]}},{key:"item.FECHA_NOM",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.setFormatDate(item.FECHA_NOM))+" ")]}},{key:"item.FECHA_INICIO_PERIODO",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.setFormatDate(item.FECHA_INICIO_PERIODO))+" ")]}},{key:"item.CANTIDAD",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatMXN(item.CANTIDAD))+" ")]}},{key:"item.INTERES_ACTUAL",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.INTERES_ACTUAL !== null ? parseFloat(item.INTERES_ACTUAL).toFixed(2) : null)+" ")]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }