var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"rounded-lg"},[_c('v-data-table',{attrs:{"dense":"","items":_vm.usuarios,"headers":_vm.headers,"loading":_vm.loadingData,"search":_vm.search,"loading-text":"Cargando...","no-data-text":"No hay registros","no-results-text":'No se encontraron coincidencias para: ' + _vm.search,"footer-props":{
      'items-per-page-text': 'Elementos por página',
      pageText: '{0}-{1} de {2}',
    }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"primary pt-4  rounded-l-t-header-pases-admin  rounded-r-t-header-pases-admin rounded-lg rounded-b-0",staticStyle:{"display":"flex","justify-content":"space-between"}},[_c('v-text-field',{staticClass:"mx-4 rounded-lg",staticStyle:{"max-height":"42px","max-width":"400px"},attrs:{"label":"Buscar...","dense":"","flat":"","solo-inverted":"","dark":"","clearable":"","append-icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-btn',{staticClass:"rounded-lg mr-3",attrs:{"color":"green lighten-1","loading":_vm.loadingBtnAdd,"dark":""},on:{"click":function($event){_vm.showFindNewMembers = true}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" agregar ")],1)],1)]},proxy:true},{key:"item.fecha_modificacion",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.fixDate(item.fecha_modificacion.date.substring(0, 10)) + " " + item.fecha_modificacion.date.substring(11, 16))+" ")]}},{key:"item.acciones",fn:function(ref){
    var item = ref.item;
return [_c('v-btn',{staticClass:"rounded-lg",attrs:{"x-small":"","color":"pink darken-3","dark":""},on:{"click":function($event){_vm.idAux = item.id;
          _vm.showConfirm = true;}}},[_c('v-icon',[_vm._v("mdi-minus-thick")])],1)]}}])}),(_vm.showFindNewMembers)?_c('BuscaColaboradoresActivos',{attrs:{"showFindNewMembers":_vm.showFindNewMembers},on:{"returnColaborrador":_vm.returnColaborrador}}):_vm._e(),(_vm.showConfirm)?_c('Confirm',{attrs:{"showConfirm":_vm.showConfirm,"question":"¿Realmente deseas ejecutar está acción?"},on:{"cierraConfirm":_vm.validaRespuesta}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }