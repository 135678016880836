<template>
  <v-dialog v-model="muestraDetallesPermiso" max-width="780px">
    <v-card class="rounded-lg">
      <v-card-title class="primary white--text subtitle-2">
        <span class="text-h5">Información sobre permiso seleccionado</span>
        <v-spacer></v-spacer>
        <v-icon dark @click="$emit('cierraVentanaDetallePermiso')">mdi-close</v-icon>
      </v-card-title>
      <v-card-text>
        <v-container>
          <p class="text-justify font-weight-bold">
            {{messagge}}
          </p>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "muestra-detalle-permiso",
  data: () => ({
  }),
  props: ["muestraDetallesPermiso", "messagge"],
  mounted(){
    // this.test();
    // this.items = Object.values(this.datosTabla);
  },
  methods: {
    // test(){
    //   //(this.datosTabla['FLARGA']);
    // },
  },
};
</script>