import MuestraComunicados from './Componentes/MostrarComunicados.vue'

export default {
  name: 'comunicados-internos',
  components: { MuestraComunicados },

  props: [],
  data() {
    return {

    }
  },
  computed: {

  },
  mounted() {

  },
  methods: {

  }
}


