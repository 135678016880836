import { render, staticRenderFns } from "./KardexColaborador.html?vue&type=template&id=7696d460&scoped=true&"
import script from "./KardexColaborador.js?vue&type=script&lang=js&"
export * from "./KardexColaborador.js?vue&type=script&lang=js&"
import style0 from "./KardexColaborador.scss?vue&type=style&index=0&id=7696d460&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7696d460",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VBtn,VContainer,VDataTable,VIcon,VSpacer,VTextField,VToolbar})
