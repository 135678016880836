var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"px-5 pt-3 mb-0 pb-0",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"3"}},[_c('v-row',{staticClass:"pa-3"},[_c('v-text-field',{staticClass:"rounded-lg",attrs:{"type":"text","solo":"","dense":"","prepend-inner-icon":"mdi-filter-outline","label":"Nombre"},model:{value:(_vm.candidateFilterValue),callback:function ($$v) {_vm.candidateFilterValue=$$v},expression:"candidateFilterValue"}})],1)],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-row',{staticClass:"pa-3"},[_c('v-text-field',{staticClass:"rounded-lg",attrs:{"type":"text","solo":"","dense":"","prepend-inner-icon":"mdi-filter-outline","label":"Vacante"},model:{value:(_vm.positionFilterValue),callback:function ($$v) {_vm.positionFilterValue=$$v},expression:"positionFilterValue"}})],1)],1)],1),_c('v-data-table',{staticClass:"rounded-xl elevation-10",staticStyle:{"border":"0.8px solid #CFD8DC !important"},attrs:{"headers":_vm.headers,"items":_vm.candidatos,"dense":"","loading":_vm.loadingData},scopedSlots:_vm._u([{key:"item.nombre",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"my-1 text-capitalize"},[_vm._v(_vm._s(item.nombre))])]}},{key:"item.estatus",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getColorStatus(item.estatus),"small":"","dark":""}},[_vm._v(_vm._s(item.estatus))])]}},{key:"item.Acciones",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"rounded-pill font-weight-bold",attrs:{"small":"","color":"#c94d94","dark":""},on:{"click":function($event){_vm.muestraVacantesDisponibles = true;
          _vm.idVacanteAux = item.idVacante;
          _vm.idCandidatoAux = item.id;}}},[_vm._v(" postular ")])]}}])}),(_vm.muestraVacantesDisponibles)?_c('CambiaVacanteCandidato',{attrs:{"muestraVacantesDisponibles":_vm.muestraVacantesDisponibles,"idVacante":_vm.idVacanteAux,"idCandidato":_vm.idCandidatoAux,"estatusId":13,"tipo":2},on:{"closeCambioVacante":_vm.closeCambioVacante}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }