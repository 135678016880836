import MuestraSolicitud from "./components/MuestraSolicitudesDescansoLaborado.vue"
import TablaSolicitudesDescansoLaborado from "./components/TablaSolicitudesDescansoLaborado.vue"
export default {
    name: 'solicitud-descanso-laborado',
    components: {
        MuestraSolicitud,
        TablaSolicitudesDescansoLaborado
    },
    props: [],
    data() {
        return {
            key: 0,
        }
    },
    computed: {

    },
    mounted() {},
    methods: {
        actualizaSolicitudes() {
            console.log("llega a función actualizaSolicitudes")
            this.key += 1;
        }
    }
}