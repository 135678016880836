import VerRelacionEmpleadoResponsable from "./components/VerRelacionEmpleadoResponsable.vue"
import VerBitacoraDeCambiosRelacionEmpleadoResponsable from "./components/VerBitacoraDeCambiosRelacionEmpleadoResponsable.vue"
import VerBajasRelacionEmpResp from "./components/VerBajasRelacionEmpResp.vue"
import VerHistorico from "./components/VerHistorico.vue"

export default {
    name: 'relacion-empleado-responsable',
    components: {
        VerRelacionEmpleadoResponsable,
        VerBitacoraDeCambiosRelacionEmpleadoResponsable,
        VerBajasRelacionEmpResp,
        VerHistorico
    },
    props: [],
    data() {
        return {
            key: 0,
            verHistorico: false
        }
    },
    computed: {

    },
    mounted() {

    },
    methods: {
        updateBitacora(test) {
            if (test === 1) {
                this.key += test
            }
        }
    }
}