<template>
    <v-sheet color="transparent">
      <div v-if="loadingData" class="text-center">
      <v-progress-circular
        :size="50"
        color="primary"
        indeterminate
      ></v-progress-circular>
      </div>
      <v-row v-else>
        <v-col cols="6"> 
          <v-sheet max-height="390" color="white" class="rounded-xl py-5 pl-8 pr-2 elevation-0" height="100%">
              <div v-if="diasParaPrenomina.weekly > 0">
                <div class="overline blue-grey--text text--lighten-2">
                  <strong class="text-h4 blue--text text--darken-3 font-weight-bold">{{ diasParaPrenomina.weekly }}</strong> 
                  días para la prenómina semanal
                </div>
              </div>
              <div v-else class="text-center">
                <p class="overline my-1 font-weight-bold primary--text">
                  Hoy es día de prenómina semanal
                </p>
              </div>
            </v-sheet>
        </v-col>
        <v-col cols="6"> 
          <v-sheet max-height="390" color="white" class="rounded-xl py-5 pl-8 pr-2 elevation-0" height="100%">
              <div v-if="diasParaPrenomina.biweekly > 0">
                <div class="overline blue-grey--text text--lighten-2">
                  <strong class="text-h4 blue--text text--darken-3 font-weight-bold">{{ diasParaPrenomina.biweekly }}</strong> 
                  días para la prenómina quincenal
                </div>
              </div>
              <div v-else class="text-center">
                <p class="overline my-1 font-weight-bold primary--text">
                  Hoy es día de prenómina quincenal
                </p>
              </div>
          </v-sheet>
        </v-col>
      </v-row>
    </v-sheet>
  </template>
  
  <script>
  import axios from "axios";
  import { deleteToken } from "../../../../../../store/Funciones/funciones";
  
  export default {
    name: "dias-para-prenomina",
    data() {
      return {
        fechasPrenom: [],
        diasParaPrenomina: {},
        loadingData: true
      };
    },
    async mounted() {
      this.diasParaPrenomina = await this.getParaPrenominas();
      this.loadingData = false
    },
    methods: {
      getParaPrenominas() {
        const headers = {
          Authorization: localStorage.getItem("token"),
        };
  
        return new Promise((res) => {
          axios
            .get(`${this.$store.getters.getHostNode}/api/get-dias-para-prenominas`, {
              headers,
            })
            .then((response) => {
              if (response.data.status == "EXPSESSION") {
                deleteToken();
              } else {
                res(response.data.data);
  
                // this.createJSONtoExport();
              }
            })
            .finally(() => {
              this.loadInformation = false;
            });
        });
      },
    },
  };
  </script>
  