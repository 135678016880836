var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","content-class":"fix-border-dialog","max-width":"880"},model:{value:(_vm.muestraSolicitantes),callback:function ($$v) {_vm.muestraSolicitantes=$$v},expression:"muestraSolicitantes"}},[_c('v-card',{staticClass:"rounded-xl"},[_c('v-toolbar',{attrs:{"dense":"","color":"primary rounded-xl rounded-b-0"}},[_c('label',{staticClass:"overline font-weight-bold white--text"},[_vm._v("Solicitantes por departamento ["+_vm._s(_vm.deptid)+"]: "+_vm._s(_vm.idRegistro))]),_c('v-spacer'),_c('v-icon',{attrs:{"dark":""},on:{"click":function($event){return _vm.$emit('ocultaSolicitantes')}}},[_vm._v("mdi-close")])],1),_c('v-card-text',{staticClass:"pt-5"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.solicitantes,"dense":"","footer-props":{
          'items-per-page-text': 'Elementos por página',
          pageText: '{0}-{1} de {2}',
        },"no-data-text":"No hay registros","loading-text":"Cargando registros...","loading":_vm.loadingData},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticStyle:{"display":"flex","justify-content":"space-between"}},[_c('v-text-field',{staticClass:"rounded-pill",attrs:{"dense":"","outlined":"","prepend-inner-icon":"mdi-magnify","placeholder":"Buscar..."},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('div',[_c('v-tooltip',{attrs:{"bottom":"","color":"blue darken-2"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:" mr-2",attrs:{"depressed":"","fab":"","small":"","outlined":"","color":"blue darken-2"},on:{"click":function($event){_vm.showFindNewMembers = true}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-account-plus")])],1)]}}])},[_c('span',[_vm._v("Agregar solicitante")])]),_c('v-tooltip',{attrs:{"bottom":"","color":"blue-grey darken-2"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"depressed":"","fab":"","small":"","outlined":"","color":"blue-grey darken-2"},on:{"click":_vm.updateTable}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-reload")])],1)]}}])},[_c('span',[_vm._v("Actualizar tabla")])])],1)],1)]},proxy:true},{key:"item.EMP_STATUS",fn:function(ref){
        var item = ref.item;
return [_c('label',{staticClass:"overline caption font-weight-bold",class:item.EMP_STATUS == 'A' ? 'teal--text' : 'pink--text'},[_vm._v(" "+_vm._s(item.EMP_STATUS == "A" ? "Activo" : "Baja")+" ")])]}},{key:"item.action",fn:function(ref){
        var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","color":"error"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"rounded-pill",attrs:{"color":"error","x-small":"","depressed":""},on:{"click":function($event){_vm.idUsuarioAux = item.id; _vm.showConfirm = true;}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-minus-thick")])],1)]}}],null,true)},[_c('span',[_vm._v("Quitar permisos")])])]}}])})],1),(_vm.muestraBarra)?_c('SnackBar',{attrs:{"muestraBarra":_vm.muestraBarra,"color":_vm.colorBarra,"texto":_vm.textoBarra},on:{"cierraBarra":function($event){_vm.muestraBarra = false}}}):_vm._e(),_c('Confirm',{attrs:{"showConfirm":_vm.showConfirm,"question":"¿Realmente deseas eliminar al usuario?"},on:{"cierraConfirm":_vm.validateDeleteUser}}),(_vm.showFindNewMembers)?_c('BuscaColaboradoresActivos',{attrs:{"showFindNewMembers":_vm.showFindNewMembers},on:{"returnColaborrador":_vm.returnColaborrador}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }