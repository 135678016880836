<template>
  <v-card
    class="rounded-lg"
    min-height="200px"
    elevation="12"
    v-resize="onResize"
  >
    <div class="text-center" v-if="windowSize.x > 600">
      <v-btn
        class="rounded-lg mx-1 my-3 font-weight-bold"
        color="red darken-3"
        dark
        x-large
        @click="openShowFam = true"
      >
        <v-icon left>mdi-eye</v-icon>
        {{ $t("DH360.textBtnShowFamilies") }}</v-btn
      >
      <v-btn
        v-if="estatus == 'NOTIFICACION1'"
        class="rounded-lg mx-1 my-3 font-weight-bold blue--text text--darken-4"
        :loading="loadSendAssasment"
        color="#F1E549"
        x-large
        @click="
          loadSendAssasment = true;
          startAssasment();
        "
      >
        <v-icon left color="blue darken-4">mdi-clipboard-text</v-icon>
        {{ $t("DH360.textBtnStartAssasment") }}
      </v-btn>
      <v-btn
        v-if="estatus == 'PROCESO'"
        class="rounded-lg mx-1 my-3 font-weight-bold blue--text text--darken-4"
        color="#F1E549"
        x-large
        :loading="loadSendAssasment"
        @click="
          loadSendAssasment = true;
          generateRanking();
        "
      >
        <v-icon left color="blue darken-4">mdi-clipboard-text</v-icon>
        {{ $t("DH360.btnGenerarRanking") }}
      </v-btn>
      <v-btn
        v-if="estatus == 'NOTIFICACION1' || estatus == 'PROCESO'"
        class="rounded-lg mx-1 my-3 font-weight-bold"
        color="primary"
        dark
        x-large
        @click="
          showFindNewMembers = true;
          getColaboradores();
        "
      >
        <v-icon left>mdi-account-plus</v-icon>
        {{ $t("DH360.btnTextAddParticipant") }}
      </v-btn>
    </div>
    <div v-else>
      <v-row dense>
        <v-col cols="4" class="text-center mt-3">
          <v-btn x-small fab color="red darken-3" dark>
            <v-icon small>mdi-eye</v-icon>
          </v-btn>
          <p class="caption red--text text--darken-3">
            {{ $t("DH360.textBtnShowFamilies") }}
          </p>
        </v-col>
        <v-col cols="4" class="text-center mt-3">
          <v-btn
            v-if="estatus == 'NOTIFICACION1'"
            x-small
            fab
            color="#F1E549"
            class="blue--text text--darken-4"
            :loading="loadSendAssasment"
            @click="
              loadSendAssasment = true;
              startAssasment();
            "
          >
            <v-icon color="blue darken-4">mdi-clipboard-text</v-icon>
          </v-btn>
          <v-btn
            v-if="estatus == 'PROCESO'"
            x-small
            fab
            color="#F1E549"
            class="blue--text text--darken-4"
            :loading="loadSendAssasment"
            @click="
              loadSendAssasment = true;
              generateRanking();
            "
          >
            <v-icon color="blue darken-4">mdi-clipboard-text</v-icon>
          </v-btn>
          <p
            class="caption blue--text text--darken-3"
            v-if="estatus == 'NOTIFICACION1'"
          >
            {{ $t("DH360.textBtnStartAssasment") }}
          </p>
          <p
            class="caption blue--text text--darken-3"
            v-if="estatus == 'PROCESO'"
          >
            {{ $t("DH360.btnGenerarRanking") }}
          </p>
        </v-col>
        <v-col cols="4" class="text-center mt-3">
          <v-btn
            v-if="estatus == 'NOTIFICACION1' || estatus == 'PROCESO'"
            x-small
            fab
            color="primary"
            dark
          >
            <v-icon small>mdi-account-plus</v-icon>
          </v-btn>
          <p class="caption primary--text">
            {{ $t("DH360.btnTextAddParticipant") }}
          </p>
        </v-col>
      </v-row>
    </div>
    <v-tabs
      v-model="tabsContent"
      class="rounded-lg rounded-t-0 rounded-b-0 elevation-2"
      center-active
      background-color="#0078C3"
      show-arrows
      dark
    >
      <v-tabs-slider
        color="#F1E549"
        style="height: 5px !important;"
      ></v-tabs-slider>
      <v-tab>{{ $t("DH360.participants") }}</v-tab>
      <v-tab>{{ $t("DH360.clientsCharged") }}</v-tab>
      <v-tab>{{ $t("DH360.clientsAuthorized") }}</v-tab>
      <v-tab v-show="estatus == 'PROCESO' || estatus == 'RANKING'">{{
        $t("DH360.assasmentsFinished")
      }}</v-tab>
      <v-tab v-show="estatus == 'RANKING'">{{
        $t("DH360.assasmentAcepted")
      }}</v-tab>
    </v-tabs>

    <v-tabs-items v-model="tabsContent">
      <v-tab-item>
        <ReporteParticipantes360
          :idProyecto="idProyecto"
          :compania="company"
          :key="keyToUpdateComponent"
        />
      </v-tab-item>
      <v-tab-item>
        <ReporteClientesCargados
          :idProyecto="idProyecto"
          :compania="company"
          :key="keyToUpdateComponent"
        />
      </v-tab-item>
      <v-tab-item>
        <ReporteClientesAutorizados
          :idProyecto="idProyecto"
          :key="keyToUpdateComponent"
        />
      </v-tab-item>
      <v-tab-item>
        <ReporteEvaluacionesRespondidas
          :idProyecto="idProyecto"
          :compania="company"
          :key="keyToUpdateComponent"
        />
      </v-tab-item>
    </v-tabs-items>

    <MostrarFamilias360
      :verFamilias="openShowFam"
      :idProyecto="idProyecto"
      @cerrarVerFamilias="openShowFam = false"
    />

    <SnackBar
      :muestraBarra="muestraBarra"
      :texto="textoBarra"
      :color="colorBarra"
      @cierraBarra="muestraBarra = false"
    />

    <v-dialog v-model="showFindNewMembers" persistent max-width="550">
      <v-card class="rounded-lg">
        <v-card-title class="text-subtitle-2 primary white--text">
          {{ $t("DH360.btnTextAddParticipant") }}
          <v-spacer></v-spacer>
          <v-icon small dark @click="closeFindMembers">mdi-close</v-icon>
        </v-card-title>
        <v-card-text>
          <v-form
            v-if="estatus == 'NOTIFICACION1'"
            ref="formAddParticipantNot"
            v-model="validAddNot"
            lazy-validation
          >
            <v-autocomplete
              v-model="newMember"
              required
              :rules="nameRules"
              :disabled="loadingGuardar"
              :loading="loadingMembers"
              :items="colaboradores"
              item-text="nombreMostrar"
              counter="200"
              :label="$t('DH360.inputNameFamilyText')"
            ></v-autocomplete>
          </v-form>
          <v-form
            v-else-if="estatus == 'PROCESO'"
            ref="fromAddParticipantPro"
            v-model="validAddPro"
            lazy-validation
          >
            <v-autocomplete
              v-model="newMember"
              class="mt-3"
              required
              dense
              outlined
              :disabled="loadingGuardar"
              :loading="loadingMembers"
              :items="colaboradores"
              :rules="nameRules"
              item-text="nombreMostrar"
              counter="200"
              :label="$t('DH360.inputNameFamilyText')"
            ></v-autocomplete>
            <v-switch
              v-model="familiaExiste"
              inset
              dense
              small
              class="mt-0"
              color="blue accent-1"
              label="La familia existe?"
              v-on:change="changeValueFamEstatus"
            ></v-switch>
            <v-autocomplete
              v-if="familiaExiste"
              v-model="newFamily"
              required
              dense
              outlined
              :disabled="loadingGuardar"
              :loading="loadingFamilies"
              :items="familias"
              :rules="nameRules"
              item-text="Descripcion"
              counter="200"
              :label="$t('DH360.inputFamilyText')"
            ></v-autocomplete>
            <v-text-field
              v-else
              v-model="newFamily"
              required
              dense
              outlined
              :disabled="loadingGuardar"
              :loading="loadingFamilies"
              :rules="nameRules"
              counter="200"
              :label="$t('DH360.familyName')"
            ></v-text-field>

            <div
              class="mt-3"
              v-show="miembrosNewFam.length > 0 && !familiaExiste"
            >
              <v-divider class="mx-3 my-2"></v-divider>
              <p class="my-1 font-weight-bold">{{ $t("DH360.family") }}</p>
              <v-row dense>
                <v-col cols="10">
                  <p class="my-1">{{ miembSelectedName }}</p>
                </v-col>
                <v-col cols="2" class="text-center">
                  <v-checkbox
                    v-model="miembSelectedIsResp"
                    class="pa-0 mt-2 ml-2"
                    hide-details
                  ></v-checkbox>
                </v-col>
              </v-row>
              <v-row dense v-for="(item, index) in miembrosNewFam" :key="index">
                <v-col cols="10">
                  <v-autocomplete
                    v-model="item.Nombre"
                    value="item.Nombre"
                    dense
                    outlined
                    :items="colaboradores"
                    item-text="nombreMostrar"
                    counter="200"
                    :label="$t('DH360.inputNameFamilyText')"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="1" class="text-center">
                  <v-checkbox
                    v-model="item.IsResponsable"
                    :value="item.IsResponsable"
                    class="pa-0 mt-2 ml-2"
                    hide-details
                  ></v-checkbox>
                </v-col>
                <v-col cols="1" class="text-center">
                  <v-icon
                    class="pa-0 mt-2 ml-2"
                    @click="deleteSpaceMember(item)"
                    >mdi-close</v-icon
                  >
                </v-col>
              </v-row>
            </div>

            <div class="text-center" v-if="newMember != '' && !familiaExiste">
              <v-btn
                class="rounded-pill"
                small
                outlined
                color="primary"
                @click="addSpaceToMember()"
              >
                <v-icon left>mdi-plus</v-icon>
                {{ $t("DH360.add") }} {{ $t("DH360.members") }}
              </v-btn>

              <v-divider class="mx-3 my-2"></v-divider>
            </div>

            <div
              v-show="
                (newMember != '' && newFamily != '') ||
                  (newMember != '' &&
                    !familiaExiste &&
                    miembrosNewFam.length > 0)
              "
              class="text-center"
            >
              <v-btn
                class="rounded-pill mt-2"
                large
                elevation="2"
                dark
                color="teal"
                :loading="loadingGeneration"
                @click="
                  generateSurveys();
                  loadingGeneration = true;
                "
              >
                <v-icon left>mdi-clipboard-text-outline</v-icon>
                {{ $t("DH360.generate") }} {{ $t("DH360.assasments") }}
              </v-btn>
            </div>
          </v-form>
        </v-card-text>
        <!-- <v-divider class="white"> </v-divider> -->
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="rounded-lg primary--text white"
            v-show="
              estatus == 'NOTIFICACION1' ||
                (estatus == 'PROCESO' && muestraSave)
            "
            small
            depressed
            :loading="loadingGuardar"
            @click="
              loadingGuardar = true;
              addParticipant();
            "
          >
            {{ $t("DH360.save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import axios from "axios";
import { deleteToken } from "../../../../../../store/Funciones/funciones";
import SnackBar from "../../../../General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue";

import ReporteParticipantes360 from "./ReporteParticipantes360.vue";
import ReporteClientesCargados from "./ReporteClientesCargados.vue";
import ReporteClientesAutorizados from "./ReporteClientesAutorizados.vue";
import MostrarFamilias360 from "./MostrarFamilias360.vue";
import ReporteEvaluacionesRespondidas from "./ReporteEvaluacionesRespondidas.vue";

// import { mkdtemp } from "fs";

export default {
  name: "admon-reportes-360",
  components: {
    ReporteParticipantes360,
    ReporteClientesCargados,
    ReporteClientesAutorizados,
    ReporteEvaluacionesRespondidas,
    MostrarFamilias360,
    SnackBar,
  },
  props: ["idProyecto", "company", "estatus"],
  data() {
    return {
      tabsContent: null,
      formAddParticipantNot: null,
      openShowFam: false,
      showFindNewMembers: false,
      loadingMembers: false,
      loadingFamilies: false,
      loadingGuardar: false,
      muestraBarra: false,
      loadSendAssasment: false,
      validAddPro: true,
      validAddNot: true,
      muestraSave: false,
      familiaExiste: true,
      miembSelectedIsResp: false,
      loadingGeneration: false,
      keyToUpdateComponent: 0,
      miembSelectedFolio: 0,
      miembSelectedName: "",
      newMember: "",
      newFamily: "",
      colorBarra: "",
      textoBarra: "",
      colaboradores: [],
      familias: [],
      miembrosNewFam: [],
      nameRules: [(v) => !!v || this.$t("DH360.emptyFieldValidationRule")],
      windowSize: {
        x: 0,
        y: 0,
      },
    };
  },
  computed: {},
  mounted() {
    this.onResize();
    this.formAddParticipantNot = this.$refs.formAddParticipantNot;
  },
  methods: {
    onResize() {
      this.windowSize = {
        x: window.innerWidth,
        y: window.innerHeight,
      };
    },
    addSpaceToMember() {
      this.miembrosNewFam.push({
        Nombre: "",
        Folio: -1,
        IsResponsable: false,
      });
    },

    deleteSpaceMember(item) {
      let editedIndex = this.miembrosNewFam.indexOf(item);
      this.miembrosNewFam.splice(editedIndex, 1);
    },

    changeValueFamEstatus() {
      if (!this.familiaExiste && this.miembrosNewFam.length == 0) {
        let result = this.colaboradores.filter((colaborador) => {
          return colaborador.nombreMostrar === this.newMember;
        });
        this.miembrosNewFam.push({
          Nombre: "",
          Folio: -1,
          IsResponsable: false,
        });
        this.miembSelectedIsResp = true;
        this.miembSelectedName = result[0].NAME;
        this.miembSelectedFolio = result[0].EMPLID;
      }
    },

    closeFindMembers() {
      this.miembrosNewFam.length = 0;
      this.familiaExiste = true;
      this.showFindNewMembers = false;
    },

    generateSurveys() {
      if (this.familiaExiste) {
        if (this.$refs.fromAddParticipantPro.validate()) {
          let familia = this.familias.filter((f) => {
            return f.Descripcion === this.newFamily;
          });
          let participante = this.colaboradores.filter((p) => {
            return p.nombreMostrar === this.newMember;
          });
          let params = {
            Opcion: 33,
            token: localStorage.getItem("token"),
            descFamilia: this.newFamily,
            miembros: this.miembrosNewFam,
            empleado: {
              Nombre: participante[0].NAME,
              Folio: participante[0].EMPLID,
              IsResponsable: false,
            },
            existeFamilia: this.familiaExiste,
            idProyecto: this.idProyecto,
            idFamilia: familia[0].id,
          };
          axios
            .post(
              `${this.$store.getters.getHost}/RelacionesLaborales/AdmonEvaluacionesDesempenio/ws-admon-360.php`,
              params
            )
            .then((response) => {
              this.loadingGeneration = false;
              this.showFindNewMembers = false;
              if (response.data.status == "EXPRESSION") {
                deleteToken();
              } else if (response.data.status == "OK") {
                this.textoBarra =
                  this.$t("DH360.successfullyMessageAddParticipants") +
                  " " +
                  response.data.respuesta[0].Total +
                  " " +
                  this.$t("DH360.assasments");
                this.colorBarra = "success";
                this.muestraBarra = true;
              } else {
                this.textoBarra =
                  this.$t("DH360.successfullyMessageAddParticipants") +
                  " " +
                  response.data.respuesta.codError;
                this.colorBarra = "pink darken-3";
                this.muestraBarra = true;
              }
            })
            .catch((e) => {
              alert(e);
              deleteToken();
            });
        } else {
          console.log("error");
        }
      } else {
        if (this.$refs.fromAddParticipantPro.validate()) {
          if (
            this.miembrosNewFam.length > 0 &&
            this.miembrosNewFam[0].Nombre != ""
          ) {
            let findResp = this.miembrosNewFam.filter((memb) => {
              return memb.IsResponsable === true;
            });

            if (
              (findResp.length > 0 && this.miembSelectedIsResp) ||
              (findResp.length == 0 && !this.miembSelectedIsResp)
            ) {
              this.colorBarra = "error";
              this.textoBarra = this.$t("DH360.txtErrorMsgRespAdded");
              this.muestraBarra = true;
            } else {
              let params = {
                Opcion: 33,
                token: localStorage.getItem("token"),
                descFamilia: this.newFamily,
                miembros: this.miembrosNewFam,
                empleado: {
                  Nombre: this.miembSelectedName,
                  Folio: this.miembSelectedFolio,
                  IsResponsable: this.miembSelectedIsResp,
                },
                existeFamilia: this.existeFamilia,
                idProyecto: this.idProyecto,
              };
              axios
                .post(
                  `${this.$store.getters.getHost}/RelacionesLaborales/AdmonEvaluacionesDesempenio/ws-admon-360.php`,
                  params
                )
                .then((response) => {
                  this.loadingGeneration = false;
                  this.showFindNewMembers = false;
                  if (response.data.status == "EXPRESSION") {
                    deleteToken();
                  } else if (response.data.status == "OK") {
                    this.textoBarra =
                      this.$t("DH360.successfullyMessageAddParticipants") +
                      " " +
                      response.data.respuesta[0].Total +
                      " " +
                      this.$t("DH360.assasments");
                    this.colorBarra = "success";
                    this.muestraBarra = true;
                  } else {
                    this.textoBarra =
                      this.$t("DH360.successfullyMessageAddParticipants") +
                      " " +
                      response.data.respuesta.codError;
                    this.colorBarra = "pink darken-3";
                    this.muestraBarra = true;
                  }
                })
                .catch((e) => {
                  alert(e);
                  deleteToken();
                });
            }
          } else {
            this.colorBarra = "error";
            this.textoBarra = this.$t("DH360.txtErrorMsgMembersAdded");
            this.muestraBarra = true;
          }
        }
      }
    },

    getColaboradores() {
      if (this.colaboradores.length == 0 && this.company == "00100") {
        let params = {
          Opcion: 11,
          token: localStorage.getItem("token"),
          idProyecto: this.idProyecto,
        };
        axios
          .post(`${this.$store.getters.getHost}/General/ws-general.php`, params)
          .then((response) => {
            if (response.data.status == "EXPRESSION") {
              deleteToken();
            } else if (response.data.status == "OK") {
              this.colaboradores = response.data.colaboradores;
              if (this.estatus == "PROCESO" && this.familias.length == 0) {
                this.getFamilias();
              }
            }
          });
      } else if (this.company == '01000' || this.company == "00700"){
        let params = {
          Opcion: 11,
          token: localStorage.getItem("token"),
          idProyecto: this.idProyecto,
          compania: this.company,
        };
        axios
          .post(`${this.$store.getters.getHost}/General/ws-general.php`, params)
          .then((response) => {
            if (response.data.status == "EXPRESSION") {
              deleteToken();
            } else if (response.data.status == "OK") {
              this.colaboradores = response.data.colaboradores;
              if (this.estatus == "PROCESO" && this.familias.length == 0) {
                this.getFamilias();
              }
            }
          });
      }
      this.loadingMembers = false;
    },

    getFamilias() {
      if (this.familias.length == 0) {
        let params = {
          Opcion: 32,
          token: localStorage.getItem("token"),
          idProyecto: this.idProyecto,
        };
        axios
          .post(
            `${this.$store.getters.getHost}/RelacionesLaborales/AdmonEvaluacionesDesempenio/ws-admon-360.php`,
            params
          )
          .then((response) => {
            if (response.data.status == "EXPRESSION") {
              deleteToken();
            } else if (response.data.status == "OK") {
              this.familias = response.data.respuesta;
              this.loadingFamilies = false;
            }
          });
      }
    },

    addParticipant() {
      let selectedUser = this.colaboradores.filter(
        (p) => p.nombreMostrar === this.newMember
      );
      let params = {
        Opcion: 25,
        token: localStorage.getItem("token"),
        idProyecto: this.idProyecto,
        participante: selectedUser[0].EMPLID,
      };
      axios
        .post(
          `${this.$store.getters.getHost}/RelacionesLaborales/AdmonEvaluacionesDesempenio/ws-admon-360.php`,
          params
        )
        .then((response) => {
          this.loadingGuardar = false;
          if (response.data.status == "EXPRESSION") {
            deleteToken();
          } else if (response.data.status == "OK") {
            if (response.data.respuesta == 1) {
              this.colorBarra = "success";
              this.textoBarra = this.$t("DH360.successInsertMessage");
              this.keyToUpdateComponent++;
            } else if (response.data.respuesta == 0) {
              this.colorBarra = "error";
              this.textoBarra = this.$t("DH360.errorMessaggeDB");
            } else if (response.data.respuesta == 2) {
              this.colorBarra = "error";
              this.textoBarra = this.$t("DH360.errorUserDuplicated");
            }
            this.muestraBarra = true;
          }
        })
        .catch(function(e) {
          alert(e);
        });
    },

    async startAssasment() {
      let dataToValidate = await this.getValitationClientsCreated();

      if (dataToValidate != null) {
        if (dataToValidate.participantes <= dataToValidate.clientesCargados) {
          if (
            dataToValidate.clientesCargados ==
            dataToValidate.clientesAutorizados
          ) {
            let params = {
              Opcion: 28,
              token: localStorage.getItem("token"),
              idProyecto: this.idProyecto,
            };
            axios
              .post(
                `${this.$store.getters.getHost}/RelacionesLaborales/AdmonEvaluacionesDesempenio/ws-admon-360.php`,
                params
              )
              .then((response) => {
                this.loadSendAssasment = false;
                if (response.data.status == "EXPRESSION") {
                  deleteToken();
                } else if (response.data.status == "OK") {
                  if (response.data.respuesta > 0) {
                    this.colorBarra = "success";
                    this.textoBarra = this.$t("DH360.successInsertMessage");
                    this.$emit("updateEstatus", "PROCESO");
                    // this.keyToUpdateComponent++;
                  } else if (response.data.respuesta <= 0) {
                    this.colorBarra = "error";
                    this.textoBarra = this.$t("DH360.errorMessaggeDB");
                  }
                  this.muestraBarra = true;
                } else if (response.data.status == "ERROR") {
                  console.log(response.data);
                }
              })
              .catch(function(e) {
                alert(e);
              });
          } else {
            this.loadSendAssasment = false;
            this.colorBarra = "error";
            this.textoBarra = this.$t("DH360.errorClientsPerAuth");
            this.muestraBarra = true;
          }
        } else {
          this.loadSendAssasment = false;
          this.colorBarra = "error";
          this.textoBarra = this.$t("DH360.errorClientsPerLoad");
          this.muestraBarra = true;
        }
      } else {
        this.loadSendAssasment = false;
        this.colorBarra = "error";
        this.textoBarra = this.$t("DH360.errorMessaggeDB") + ". 10001";
        this.muestraBarra = true;
      }
    },

    async getValitationClientsCreated() {
      let params = {
        Opcion: 29,
        token: localStorage.getItem("token"),
        idProyecto: this.idProyecto,
      };

      return new Promise((resolve, reject) => {
        axios
          .post(
            `${this.$store.getters.getHost}/RelacionesLaborales/AdmonEvaluacionesDesempenio/ws-admon-360.php`,
            params
          )
          .then((response) => {
            if (response.data.status == "EXPRESSION") {
              deleteToken();
            } else if (response.data.status == "OK") {
              resolve(response.data.respuesta);
            } else if (response.data.status == "ERROR") {
              reject(null);
            }
          })
          .catch(function(e) {
            alert(e);
            reject(null);
          });
      });
    },

    async generateRanking() {
      let avance = await this.muestraAvance();
      this.loadSendAssasment = false;
      console.log(avance);
      if (avance == 100) {
        let params = {
          Opcion: 38,
          token: localStorage.getItem("token"),
          idProyecto: this.idProyecto,
          compania:this.company
        };
        axios
          .post(
            `${this.$store.getters.getHost}/RelacionesLaborales/AdmonEvaluacionesDesempenio/ws-admon-360.php`,
            params
          )
          .then((response) => {
            this.loadSendAssasment = false;
            if (response.data.status == "EXPRESSION") {
              deleteToken();
            } else if (response.data.status == "OK") {
              if (response.data.respuesta.length == 4) {
                this.colorBarra = "success";
                this.textoBarra = this.$t("DH360.successInsertMessage");
                this.$emit("updateEstatus", "RANKING");
                // this.keyToUpdateComponent++;
              } else if (response.data.respuesta <= 0) {
                this.colorBarra = "error";
                this.textoBarra = this.$t("DH360.errorMessaggeDB");
              }
              this.muestraBarra = true;
            } else if (response.data.status == "ERROR") {
              console.log(response.data);
            }
          });
      } else {
        this.colorBarra = "error";
        this.textoBarra = this.$t("DH360.errorGenRanking");
        this.muestraBarra = true;
      }
    },

    muestraAvance() {
      let params = {
        Opcion: 37,
        token: localStorage.getItem("token"),
        idProyecto: this.idProyecto,
      };

      return new Promise((resolve, reject) => {
        axios
          .post(
            `${this.$store.getters.getHost}/RelacionesLaborales/AdmonEvaluacionesDesempenio/ws-admon-360.php`,
            params
          )
          .then((response) => {
            if (response.data.status == "EXPRESSION") {
              deleteToken();
            } else if (response.data.status == "OK") {
              resolve(
                (response.data.respuesta.contestadas * 100) /
                  (response.data.respuesta.contestadas +
                    response.data.respuesta.faltantes)
              );
            } else {
              reject(0);
            }
          });
      });
    },
  },
};
</script>
