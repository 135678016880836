<template>
  <v-dialog v-model="muestraDialogCalculos" persistent fullscreen>
    <v-card class="rounded-0">
      <v-card-title class="overline font-weight-bold white--text primary">
        Resumen del último periodo {{ tipoNomina }}
        <v-spacer></v-spacer>
        <v-icon dark @click="$emit('cancelaCalculo')">mdi-close</v-icon>
      </v-card-title>
      <v-card-text class="pt-5">
        <v-stepper v-model="pasoSeleccionado">
          <v-stepper-header>
            <v-stepper-step
              step="0"
              @click="pasoSeleccionado = 0"
              style="cursor: pointer;"
            >
              Validación de depósitos
            </v-stepper-step>

            <v-stepper-step
              step="1"
              @click="cambiaPasoActual(1)"
              :style="
                depositosVadidados ? 'cursor: pointer;' : 'cursor: not-allowed;'
              "
            >
              Solicitudes de préstamo
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step
              step="2"
              @click="cambiaPasoActual(2)"
              :style="
                depositosVadidados ? 'cursor: pointer;' : 'cursor: not-allowed;'
              "
            >
              Abonos
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step
              step="3"
              @click="cambiaPasoActual(3)"
              :style="
                depositosVadidados ? 'cursor: pointer;' : 'cursor: not-allowed;'
              "
            >
              Retiros
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step
              step="4"
              @click="cambiaPasoActual(4)"
              :style="
                depositosVadidados ? 'cursor: pointer;' : 'cursor: not-allowed;'
              "
            >
              Depósitos
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step
              step="5"
              @click="cambiaPasoActual(5)"
              :style="
                depositosVadidados ? 'cursor: pointer;' : 'cursor: not-allowed;'
              "
            >
              Generación de reporte
            </v-stepper-step>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content step="0">
              <v-data-table
                dense
                :items="depositosExternos"
                :headers="headersDepositosExternos"
                :search="searchDepositosExternos"
                :footer-props="{
                  'items-per-page-text': 'Elementos por página',
                  pageText: '{0}-{1} de {2}',
                }"
                :loading="loadingDepositosExternos"
                loading-text="Cargando depósitos externos..."
                no-data-text="No hay registros"
                item-key="ID"
              >
                <template v-slot:top>
                  <v-text-field
                    v-model="searchDepositosExternos"
                    prepend-inner-icon="mdi-magnify"
                    label="Buscar..."
                    class="mx-4"
                  ></v-text-field>
                </template>

                <template v-slot:item.CANTIDAD="{ item }">
                  {{ formatMXN(item.CANTIDAD) }}
                </template>
                <template v-slot:item.FECHA_PRENOM="{ item }">
                  {{ setFormatDate(item.FECHA_PRENOM) }}
                </template>
                <template v-slot:item.ACCIONES="{ item }">
                  <div class="center-item-in-div">
                    <input
                      type="checkbox"
                      :checked="item.MOV_CANCELADO == 0"
                      style="cursor: pointer;"
                      @change="
                        cambiaEstatusDeposito(item.ID, item.MOV_CANCELADO)
                      "
                    />
                  </div>
                </template>
              </v-data-table>
              <div style="display:flex; justify-content: end;" class="mt-4">
                <v-btn
                  color="primary"
                  :disabled="depositosExternos.length > 0 ? false : true"
                  @click="
                    loadingDepositosExternosValidacion = true;
                    updateDepositosExternos();
                  "
                >
                  <v-icon left> mdi-check </v-icon>
                  confirmar depósitos
                </v-btn>
              </div>
            </v-stepper-content>

            <v-stepper-content step="1">
              <v-data-table
                v-model="solicitudesPrestamosSelected"
                dense
                :items="solicitudesPrestamos"
                :headers="headersSolicitudesPrestamos"
                :search="searchSolicitudesPrestamos"
                :single-select="false"
                :footer-props="{
                  'items-per-page-text': 'Elementos por página',
                  pageText: '{0}-{1} de {2}',
                }"
                :loading="loadingPrestamos"
                loading-text="Cargando solicitudes de préstamo..."
                no-data-text="No hay registros"
                item-key="ID"
                show-select
              >
                <template v-slot:top>
                  <v-text-field
                    v-model="searchSolicitudesPrestamos"
                    prepend-inner-icon="mdi-magnify"
                    label="Buscar..."
                    class="mx-4"
                  ></v-text-field>
                </template>
                <template v-slot:item.CANTIDAD="{ item }">
                  <label
                    :class="
                      !sumaFavor(item)
                        ? 'red--text text--darken-2 font-weight-bold'
                        : ''
                    "
                  >
                    {{ formatMXN(item.CANTIDAD) }}
                  </label>
                </template>
                <template v-slot:item.PAGA_PRESTAMO="{ item }">
                  {{ formatMXN(item.PAGA_PRESTAMO) }}
                </template>
                <template v-slot:item.DEPOSITA="{ item }">
                  {{
                    item.DEPOSITA == null
                      ? formatMXN(0)
                      : formatMXN(item.DEPOSITA)
                  }}
                </template>
                <template v-slot:item.AHORRO="{ item }">
                  <label class="font-weight-bold">{{
                    formatMXN(item.AHORRO).toString()
                  }}</label
                  >{{ "/" + formatMXN(item.P_SOCIAL) }}
                </template>
                <template v-slot:item.accion="{ item }">
                  <div v-if="!sumaFavor(item)">
                    <v-icon
                      small
                      color="error"
                      @click="showDetails(verDetalle(item))"
                    >
                      mdi-alert-octagon
                    </v-icon>
                  </div>
                </template>
              </v-data-table>
              <div style="display:flex; justify-content: end;" class="mt-4">
                <v-btn
                  color="primary"
                  :disabled="
                    solicitudesPrestamosSelected.length > 0 ? false : true
                  "
                  @click="
                    loadingPrestamos = true;
                    setPrestamos();
                  "
                >
                  <v-icon left> mdi-check </v-icon>
                  confirmar movimientos
                </v-btn>
              </div>
            </v-stepper-content>

            <v-stepper-content step="2">
              <v-data-table
                v-model="abonosPrestamosSelected"
                dense
                :items="abonosPrestamos"
                :headers="headersAbonosPrestamos"
                :search="searchAbonosPrestamos"
                :single-select="false"
                item-key="id"
                show-select
              >
                <template v-slot:top>
                  <v-text-field
                    v-model="searchAbonosPrestamos"
                    prepend-inner-icon="mdi-magnify"
                    label="Buscar..."
                    class="mx-4"
                  ></v-text-field>
                </template>
                <template v-slot:item.monto="{ item }">
                  {{ formatMXN(item.monto) }}
                </template>
                <template v-slot:item.abono_normal="{ item }">
                  {{ formatMXN(item.abono_normal) }}
                </template>
                <template v-slot:item.abonado="{ item }">
                  {{ formatMXN(item.abonado) }}
                </template>
                <template v-slot:item.restante="{ item }">
                  {{ formatMXN(item.restante) }}
                </template>
                <template v-slot:item.INTERES_PERIODO="{ item }">
                  {{ formatMXN(item.INTERES_PERIODO) }}
                </template>
              </v-data-table>
              <div style="display:flex; justify-content: end;" class="mt-4">
                <v-btn
                  color="primary"
                  :disabled="abonosPrestamosSelected.length > 0 ? false : true"
                  @click="
                    loadingAbonosPrestamos = true;
                    setAbonos();
                  "
                >
                  <v-icon left> mdi-check </v-icon>
                  confirmar movimientos
                </v-btn>
              </div>
            </v-stepper-content>

            <v-stepper-content step="3">
              <v-data-table
                v-model="retirosSelected"
                dense
                :items="retirosCaja"
                :headers="headersRetirosCaja"
                :search="searchRetirosCaja"
                :single-select="false"
                item-key="ID"
                show-select
              >
                <template v-slot:top>
                  <v-text-field
                    v-model="searchRetirosCaja"
                    prepend-inner-icon="mdi-magnify"
                    label="Buscar..."
                    class="mx-4"
                  ></v-text-field>
                </template>
                <template v-slot:item.CANTIDAD="{ item }">
                  {{ formatMXN(item.CANTIDAD) }}
                </template>
              </v-data-table>
              <div style="display:flex; justify-content: end;" class="mt-4">
                <v-btn
                  color="primary"
                  :disabled="retirosSelected.length > 0 ? false : true"
                  @click="
                    loadingRetirosCaja = true;
                    setRetiros();
                  "
                >
                  <v-icon left> mdi-check </v-icon>
                  confirmar movimientos
                </v-btn>
              </div>
            </v-stepper-content>

            <v-stepper-content step="4">
              <v-data-table
                v-model="depositosSelected"
                dense
                :items="depositos"
                :headers="headersDepositos"
                :search="searchDepositos"
                :single-select="false"
                item-key="id"
                show-select
              >
                <template v-slot:top>
                  <div style="display: flex; justify-content: space-between;">
                    <v-text-field
                      v-model="searchDepositos"
                      prepend-inner-icon="mdi-magnify"
                      style="max-width: 450px;"
                      label="Buscar..."
                      class="mx-4"
                    ></v-text-field>
                    <v-btn
                      depressed
                      dark
                      color="teal darken-1"
                      @click="insertDeposito = true"
                    >
                      <v-icon>mdi-plus</v-icon>
                      Agregar depósito
                    </v-btn>
                  </div>
                </template>
                <template v-slot:item.monto="{ item }">
                  {{ formatMXN(item.monto) }}
                </template>
              </v-data-table>
              <div style="display:flex; justify-content: end;" class="mt-4">
                <v-btn
                  color="primary"
                  :disabled="depositosSelected.length > 0 ? false : true"
                  @click="
                    loadingDepositosCaja = true;
                    setDepositos();
                  "
                >
                  <v-icon left> mdi-check </v-icon>
                  confirmar movimientos
                </v-btn>
              </div>
            </v-stepper-content>

            <v-stepper-content step="5">
              <v-data-table
                dense
                :items="reporteFinal"
                :headers="headersReporteFinal"
                :search="searchReporteFinal"
                :no-data-text="'No se han seleccionado valores.'"
                :single-select="false"
                show-select
              >
                <template v-slot:top>
                  <v-text-field
                    v-model="searchReporteFinal"
                    prepend-inner-icon="mdi-magnify"
                    label="Buscar..."
                    class="mx-4"
                  ></v-text-field>
                </template>
              </v-data-table>
              <div style="display:flex; justify-content: end;" class="mt-4">
                <vue-json-to-csv
                  :json-data="reporteFinal"
                  :labels="headers_report_to_export"
                  csv-title="Reporte final caja"
                >
                  <v-btn
                    color="green darken-3"
                    dark
                    :disabled="reporteFinal.length > 0 ? false : true"
                  >
                    <v-icon left> mdi-microsoft-excel </v-icon>
                    exportar
                  </v-btn>
                </vue-json-to-csv>
                <v-btn
                  color="primary"
                  class="ml-2"
                  dark
                  :disabled="reporteFinal.length > 0 ? false : true"
                  @click="showConfirm = true"
                >
                  <v-icon left> mdi-format-list-checks </v-icon>
                  Guardar este periodo
                </v-btn>
              </div>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card-text>
    </v-card>

    <SnackBarErrorIncidencias
      v-if="muestraBarra"
      :muestraBarra="muestraBarra"
      :texto="textoBarra"
      :color="colorBarra"
      @cierraBarra="muestraBarra = false"
    />

    <Detalles
      :muestraDetalles="muestraDetalles"
      :texto="textoDetalles"
      :titulo="'Detalle de movimiento.'"
      :subtitle="''"
      :subtext="''"
      :table="tablaErrores"
      @cierraDetalles="muestraDetalles = false"
    />

    <ConfirmDialogReclutamiento
      :showConfirm="showConfirm"
      :question="
        '¿Realmente deseas guardar los ' +
          this.reporteFinal.length +
          ' movimientos?'
      "
      @cierraConfirm="cierraConfirm"
    />

    <LoadingDialog :text="textLoading" :dialog="muestraLoading" />

    <AgregaDeposito
      :insertDeposito="insertDeposito"
      @hideInsertDoposit="validaCantidad"
    />
  </v-dialog>
</template>

<script>
import axios from "axios";
import { deleteToken } from "../../../../../../store/Funciones/funciones";
import VueJsonToCsv from "vue-json-to-csv";
import SnackBarErrorIncidencias from "../../../../General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue";
import Detalles from "../../../../General/MiEmpresa/MisVacaciones/components/DialogDetails.vue";
import ConfirmDialogReclutamiento from "../../../../AdmnVacantes/components/ConfirmDialogReclutamiento.vue";
import LoadingDialog from "../../../../DesarrolloOrganizacional/components/loadingBar.vue";
import AgregaDeposito from "./actions/AgregaDeposito.vue";

export default {
  name: "calcula-movimientos",
  props: ["muestraDialogCalculos", "tipoNomina"],
  components: {
    VueJsonToCsv,
    SnackBarErrorIncidencias,
    Detalles,
    ConfirmDialogReclutamiento,
    LoadingDialog,
    AgregaDeposito,
  },
  data() {
    return {
      pasoSeleccionado: 0,
      searchSolicitudesPrestamos: "",
      searchAbonosPrestamos: "",
      searchRetirosCaja: "",
      searchDepositos: "",
      searchReporteFinal: "",
      searchDepositosExternos: "",
      textLoading: "",
      loadingPrestamos: true,
      loadingAbonosPrestamos: false,
      loadingRetirosCaja: false,
      loadingDepositosCaja: false,
      showConfirm: false,
      muestraLoading: false,
      depositosVadidados: false,
      loadingDepositosExternos: true,
      loadingDepositosExternosValidacion: false,
      insertDeposito: false,
      solicitudesPrestamos: [],
      abonosPrestamos: [],
      retirosCaja: [],
      depositos: [],
      solicitudesPrestamosSelected: [],
      abonosPrestamosSelected: [],
      retirosSelected: [],
      depositosSelected: [],
      selected: [],
      depositosExternos: [],
      tablaErrores: [],
      headersSolicitudesPrestamos: [
        { text: "ID", value: "ID" },
        { text: "EMPLID", value: "EMPLID" },
        { text: "NOMBRE", value: "NOMBRE" },
        { text: "CANTIDAD SOLICITADA", value: "CANTIDAD" },
        { text: "PAGA PRÉSTAMO", value: "PAGA_PRESTAMO" },
        { text: "DEPÓSITO", value: "DEPOSITA" },
        { text: "AHORRO/P. SOCIAL", value: "AHORRO" },
        { text: "", value: "accion" },
      ],
      headersAbonosPrestamos: [
        { text: "ID", value: "id" },
        { text: "EMPLID", value: "emplid" },
        { text: "MONTO", value: "monto" },
        { text: "INTÉRES", value: "interes" },
        { text: "ABONO NÓMINAL", value: "abono_normal" },
        { text: "TIPO ABONO", value: "tipo_abono" },
        { text: "ABONADO", value: "abonado" },
        { text: "RESTANTE", value: "restante" },
        { text: "ABONO", value: "num_abono" },
        { text: "INTÉRES ABONO", value: "INTERES_PERIODO" },
      ],
      headersRetirosCaja: [
        { text: "ID", value: "ID" },
        { text: "EMPLID", value: "EMPLID" },
        { text: "CANTIDAD", value: "CANTIDAD" },
        { text: "TIPO MOVIMIENTO", value: "TIPO_MOVIMIENTO" },
        { text: "VALIDA DEPÓSITO", value: "VALIDACION" },
      ],
      headersDepositos: [
        { text: "ID", value: "id" },
        { text: "EMPLID", value: "emplid" },
        { text: "CANTIDAD", value: "monto" },
        { text: "TIPO MOVIMIENTO", value: "tipo_movimiento" },
      ],
      headersReporteFinal: [
        { text: "Trabajador", value: "Trabajador" },
        { text: "Concepto", value: "Concepto" },
        { text: "Nomina", value: "Nomina" },
        { text: "Fecha", value: "Fecha" },
        { text: "Monto", value: "Monto" },
        { text: "Dato", value: "Dato" },
        { text: "Aplicado", value: "Aplicado" },
        { text: "Descripción", value: "Desc" },
      ],
      reporteFinal: [],
      headers_report_to_export: {
        Trabajador: { title: "Trabajador" },
        Concepto: { title: "Concepto" },
        Nomina: { title: "Nomina" },
        Fecha: { title: "Fecha" },
        Monto: { title: "Monto" },
        Dato: { title: "Dato" },
        Aplicado: { title: "Aplicado" },
        Desc: { title: "Descripción" },
      },
      headersDepositosExternos: [
        { text: "ID", value: "ID" },
        { text: "EMPLID", value: "EMPLID" },
        { text: "NOMBRE", value: "NOMBRE" },
        { text: "CANTIDAD", value: "CANTIDAD" },
        { text: "FECHA_PRENOM", value: "FECHA_PRENOM" },
        {
          text: "ACCIONES",
          value: "ACCIONES",
          align: "center",
          sortable: false,
        },
      ],

      muestraBarra: false,
      colorBarra: "",
      textoBarra: "",

      muestraDetalles: false,
      textoDetalles: "",
    };
  },
  async mounted() {
    this.depositosExternos = await this.getDepositosExternos();
  },
  methods: {
    showSnackBar(texto, color = "pink darken-3") {
      this.textoBarra = texto;
      this.colorBarra = color;
      this.muestraBarra = true;
    },
    showDetails(texto) {
      this.textoDetalles = texto;
      this.muestraDetalles = true;
    },
    getDepositosExternos() {
      const headers = {
        Authorization: localStorage.getItem("token"),
      };

      return new Promise((res) => {
        axios
          .get(
            `${this.$store.getters.getHostNode}/api/empleados/caja/calculos/depositos-externos/${this.tipoNomina}`,
            {
              headers,
            }
          )
          .then((response) => {
            if (
              response.data.status == "EXPSESSION" ||
              response.data.message === "Token inválido"
            ) {
              deleteToken();
            } else {
              res(response.data.data);
            }
          })
          .finally(() => {
            this.loadingDepositosExternos = false;
          });
      });
    },
    getSolicitudesPrestamos() {
      const headers = {
        Authorization: localStorage.getItem("token"),
      };

      return new Promise((res) => {
        axios
          .get(
            `${this.$store.getters.getHostNode}/api/empleados/caja/prestamos/solicitudes-periodo/${this.tipoNomina}`,
            {
              headers,
            }
          )
          .then((response) => {
            if (
              response.data.status == "EXPSESSION" ||
              response.data.message === "Token inválido"
            ) {
              deleteToken();
            } else {
              res(response.data.data);
            }
          })
          .finally(() => {
            this.loadingPrestamos = false;
          });
      });
    },
    getAbonosPrestamos() {
      const headers = {
        Authorization: localStorage.getItem("token"),
      };

      return new Promise((res) => {
        axios
          .get(
            `${this.$store.getters.getHostNode}/api/empleados/caja/prestamos/abonos-periodo/${this.tipoNomina}`,
            {
              headers,
            }
          )
          .then((response) => {
            if (response.data.status == "EXPSESSION") {
              deleteToken();
            } else {
              res(response.data.data);
            }
          })
          .finally(() => {
            this.loadingData = false;
          });
      });
    },
    getRetirosCaja() {
      const headers = {
        Authorization: localStorage.getItem("token"),
      };

      return new Promise((res) => {
        axios
          .get(
            `${this.$store.getters.getHostNode}/api/empleados/caja/prestamos/retiros-periodo/${this.tipoNomina}`,
            {
              headers,
            }
          )
          .then((response) => {
            if (response.data.status == "EXPSESSION") {
              deleteToken();
            } else {
              res(response.data.data);
            }
          })
          .finally(() => {
            this.loadingData = false;
          });
      });
    },
    getDepositosCaja() {
      const headers = {
        Authorization: localStorage.getItem("token"),
      };

      return new Promise((res) => {
        axios
          .get(
            `${this.$store.getters.getHostNode}/api/empleados/caja/prestamos/depositos-periodo/${this.tipoNomina}`,
            {
              headers,
            }
          )
          .then((response) => {
            if (response.data.status == "EXPSESSION") {
              deleteToken();
            } else {
              res(response.data.data);
            }
          })
          .finally(() => {
            this.loadingData = false;
          });
      });
    },
    setPrestamos() {
      const promises = this.solicitudesPrestamosSelected.map((p) => {
        this.reporteFinal.push({
          Trabajador: p.EMPLID,
          Concepto: "O82",
          Nomina: "Semanal - Normal",
          Fecha: "2024-05-06",
          Monto: p.CANTIDAD,
          Dato: 0,
          Aplicado: "No",
          Desc: p.TIPO_MOVIMIENTO,
          idPrestamo: null,
        });
      });

      Promise.all(promises).finally(() => {
        this.loadingPrestamos = false;
        this.pasoSeleccionado++;
      });
    },
    setAbonos() {
      const promises = this.abonosPrestamosSelected.map((p) => {
        if (p.tipo_abono == "PAGAR PRESTAMO CON AHORRO" && p.abono_normal > 0) {
          this.reporteFinal.push({
            Trabajador: p.emplid,
            Concepto: "D107",
            Nomina: "Semanal - Normal",
            Fecha: "2024-05-06",
            Monto: p.abono_normal,
            Dato: 0,
            Aplicado: "No",
            Desc: p.tipo_abono,
            idPrestamo: p.id,
          });
        } else if (p.tipo_abono == "ABONO A PRESTAMO" && p.abono_normal > 0) {
          this.reporteFinal.push({
            Trabajador: p.emplid,
            Concepto: "D107",
            Nomina: "Semanal - Normal",
            Fecha: "2024-05-06",
            Monto: p.abono_normal,
            Dato: 0,
            Aplicado: "No",
            Desc: p.tipo_abono,
            idPrestamo: p.id,
          });
        } else {
          this.reporteFinal.push({
            Trabajador: p.emplid,
            Concepto: "D107",
            Nomina: "Semanal - Normal",
            Fecha: "2024-05-06",
            Monto: p.abono_normal,
            Dato: 0,
            Aplicado: "No",
            Desc: p.tipo_abono,
            idPrestamo: p.id,
          });
        }
        this.reporteFinal.push({
          Trabajador: p.emplid,
          Concepto: "D103",
          Nomina: "Semanal - Normal",
          Fecha: "2024-05-06",
          Monto: ((p.restante * p.INTERES_PERIODO) / 100).toFixed(2),
          Dato: 0,
          Aplicado: "No",
          Desc: p.tipo_abono,
          idPrestamo: p.id,
        });
      });

      Promise.all(promises).finally(() => {
        this.loadingAbonosPrestamos = false;
        this.pasoSeleccionado++;
      });
    },
    setRetiros() {
      const promises = this.retirosSelected.map((p) => {
        if (
          p.TIPO_MOVIMIENTO === "PAGAR PRESTAMO CON AHORRO" &&
          p.CANTIDAD != 0
        ) {
          this.reporteFinal.push({
            Trabajador: p.EMPLID,
            Concepto: "O83",
            Nomina: "Semanal - Normal",
            Fecha: "2024-05-06",
            Monto: p.CANTIDAD,
            Dato: 0,
            Aplicado: "No",
            Desc: p.TIPO_MOVIMIENTO,
            idPrestamo: null,
          });
        } else if (
          p.TIPO_MOVIMIENTO === "ABONO A PRESTAMO" &&
          p.CANTIDAD != 0
        ) {
          this.reporteFinal.push({
            Trabajador: p.EMPLID,
            Concepto: "O83",
            Nomina: "Semanal - Normal",
            Fecha: "2024-05-06",
            Monto: p.CANTIDAD,
            Dato: 0,
            Aplicado: "No",
            Desc: p.TIPO_MOVIMIENTO,
            idPrestamo: null,
          });
        } else if (
          p.TIPO_MOVIMIENTO === "RETIRO DE AHORRO" &&
          p.CANTIDAD != 0
        ) {
          this.reporteFinal.push({
            Trabajador: p.EMPLID,
            Concepto: "O83",
            Nomina: "Semanal - Normal",
            Fecha: "2024-05-06",
            Monto: p.CANTIDAD,
            Dato: 0,
            Aplicado: "No",
            Desc: p.TIPO_MOVIMIENTO,
            idPrestamo: null,
          });
        } else {
          this.reporteFinal.push({
            Trabajador: p.EMPLID,
            Concepto: "P107",
            Nomina: "Semanal - Normal",
            Fecha: "2024-05-06",
            Monto: p.CANTIDAD,
            Dato: 0,
            Aplicado: "No",
            Desc: p.TIPO_MOVIMIENTO,
            idPrestamo: null,
          });
        }
      });

      Promise.all(promises).finally(() => {
        this.loadingRetirosCaja = false;
        this.pasoSeleccionado++;
      });
    },
    setDepositos() {
      const promises = this.depositosSelected.map((p) => {
        if (
          ["NORMAL", "CAMBIO DE CANTIDAD A AHORRAR", "INSCRIPCION"].includes(
            p.tipo_movimiento
          ) &&
          p.monto != 0
        ) {
          this.reporteFinal.push({
            Trabajador: p.emplid,
            Concepto: "D106",
            Nomina: "Semanal - Normal",
            Fecha: "2024-05-06",
            Monto: p.monto,
            Dato: 0,
            Aplicado: "No",
            Desc: p.tipo_movimiento,
            idPrestamo: null,
          });
        } else if (
          ["DEPOSITO S/D"].includes(p.tipo_movimiento) &&
          p.monto != 0
        ) {
          this.reporteFinal.push({
            Trabajador: p.emplid,
            Concepto: "P124",
            Nomina: "Semanal - Normal",
            Fecha: "2024-05-06",
            Monto: p.monto,
            Dato: 0,
            Aplicado: "No",
            Desc: p.tipo_movimiento,
            idPrestamo: null,
          });
        } else {
          this.reporteFinal.push({
            Trabajador: p.emplid,
            Concepto: "P124",
            Nomina: "Semanal - Normal",
            Fecha: "2024-05-06",
            Monto: p.monto,
            Dato: 0,
            Aplicado: "No",
            Desc: p.tipo_movimiento,
            idPrestamo: null,
          });
          this.reporteFinal.push({
            Trabajador: p.emplid,
            Concepto: "D108",
            Nomina: "Semanal - Normal",
            Fecha: "2024-05-06",
            Monto: p.monto,
            Dato: 0,
            Aplicado: "No",
            Desc: p.tipo_movimiento,
            idPrestamo: null,
          });
        }
      });

      Promise.all(promises).finally(() => {
        this.loadingDepositosCaja = false;
        this.pasoSeleccionado++;
      });
    },
    formatMXN(cadena) {
      return cadena == null
        ? ""
        : cadena.toLocaleString("es-MX", {
            style: "currency",
            currency: "MXN",
          });
    },
    sumaFavor(item) {
      return item.PRESTAMO_ACTUAL > item.PAGA_PRESTAMO + 0.1 ||
        item.AHORRO + item.DEPOSITA + 0.1 < item.P_SOCIAL
        ? false
        : true;
    },
    verDetalle(item) {
      let respuesta = "";
      if (item.PRESTAMO_ACTUAL > item.PAGA_PRESTAMO + 0.1) {
        respuesta +=
          "El monto para pagar el prestamo " +
          this.formatMXN(item.PAGA_PRESTAMO) +
          " es menor al adeudo actual " +
          this.formatMXN(item.PRESTAMO_ACTUAL) +
          ". ";
      }

      if (item.AHORRO + item.DEPOSITA + 0.01 < item.P_SOCIAL) {
        respuesta +=
          "El saldo de ahorro en caja " +
          this.formatMXN(item.AHORRO + item.DEPOSITA) +
          " es menor que la parte social " +
          this.formatMXN(item.P_SOCIAL) +
          ". ";
      }

      return respuesta;
    },
    async cierraConfirm(respuesta) {
      if (respuesta) {
        this.muestraLoading = true;
        const movimientosPorEmpleado = await this.groupByTrabajador();
        // console.log(JSON.stringify(movimientosPorEmpleado, null, 2))
        this.cierraPeriodo(movimientosPorEmpleado);
      }

      this.showConfirm = false;
    },
    groupByTrabajador() {
      return new Promise((resolve, reject) => {
        try {
          const groupedByTrabajador = this.reporteFinal.reduce((acc, curr) => {
            let trabajador = acc.find((item) => item.id === curr.Trabajador);

            if (!trabajador) {
              trabajador = { id: curr.Trabajador, data: [] };
              acc.push(trabajador);
            }

            trabajador.data.push(curr);
            return acc;
          }, []);
          resolve(groupedByTrabajador);
        } catch (error) {
          reject(error);
        }
      });
    },
    async cierraPeriodo(movimientosPorEmpleado) {
      let errores = [];
      let exitos = [];
      const promises = movimientosPorEmpleado.map(async (empleado) => {
        let response = await this.setMovimientosEmpleado(empleado).catch(
          (error) => {
            errores.push({
              empleado: empleado.id,
              movs: empleado.data,
              detalles: error,
            });
          }
        );

        if (response.status == "OK") {
          exitos.push(response.data);
        }
        this.textLoading =
          "Cargando movimientos... Total: " +
          movimientosPorEmpleado.length +
          ". Errores: " +
          errores.length +
          ". Éxitos: " +
          exitos.length +
          ".";
      });

      await Promise.all(promises);
      this.muestraLoading = false;
      this.textoDetalles =
        "Se procesaron movimientos de " +
        movimientosPorEmpleado.length +
        " empleados. Correctos: " +
        exitos.length +
        ". Errores: " +
        errores.length +
        ".";
      this.muestraDetalles = true;
    },

    setMovimientosEmpleado(movimientos) {
      const headers = {
        Authorization: localStorage.getItem("token"),
      };
      const params = {
        emplid: movimientos.id,
        movimientos: movimientos.data,
      };
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${this.$store.getters.getHostNode}/api/empleados/caja/movimientos-periodo/${this.$store.getters.getUser["Folio"]}/${this.tipoNomina}`,
            params,
            {
              headers,
            }
          )
          .then((response) => {
            if (response.data.status == "EXPSESSION") {
              deleteToken();
            } else {
              resolve(response.data);
            }
          })
          .catch((e) => {
            reject(e);
          })
          .finally(() => {
            this.loadingData = false;
          });
      });
    },
    setFormatDate(date) {
      let fecha = new Date(date);

      let diaUTC = fecha.getUTCDate();
      let mesUTC = fecha.getUTCMonth() + 1;
      let anioUTC = fecha.getUTCFullYear();

      diaUTC = diaUTC < 10 ? "0" + diaUTC : diaUTC;
      mesUTC = mesUTC < 10 ? "0" + mesUTC : mesUTC;

      return diaUTC + "/" + mesUTC + "/" + anioUTC;
    },
    cambiaEstatusDeposito(id, estatus) {
      let index = this.depositosExternos.findIndex((obj) => obj.ID === id);
      let nuevoEstatus = estatus === 0 ? 1 : 0;

      if (index !== -1) {
        this.depositosExternos[index].MOV_CANCELADO = nuevoEstatus;
      }
    },
    cambiaPasoActual(paso) {
      this.pasoSeleccionado = !this.depositosVadidados
        ? this.pasoSeleccionado
        : paso;
    },
    async updateDepositosExternos() {
      let exitos = 0;
      let errores = 0;
      const rechazados = this.depositosExternos.map(async (elemento) => {
        if (elemento.MOV_CANCELADO == 1) {
          let response = await this.putDeposito(elemento);
          if (response.data > 0) {
            exitos++;
          } else {
            errores++;
            this.tablaErrores.push(errores);
          }
        }
      }, []);

      Promise.all(rechazados).finally(async () => {
        this.textoDetalles =
          "Se revisaron un total de " +
          rechazados.length +
          " depósitos externos. Cancelados: " +
          exitos +
          ". Errores: " +
          errores;
        this.muestraDetalles = true;
        this.loadingDepositosExternosValidacion = false;
        this.depositosVadidados = true;
        this.pasoSeleccionado++;
        this.solicitudesPrestamos = await this.getSolicitudesPrestamos().catch(
          (e) => {
            alert("Error:", e);
          }
        );
        this.abonosPrestamos = await this.getAbonosPrestamos();
        this.retirosCaja = await this.getRetirosCaja();
        this.depositos = await this.getDepositosCaja();
      });
    },
    putDeposito(elemento) {
      const headers = {
        Authorization: localStorage.getItem("token"),
      };

      return new Promise((resolve, reject) => {
        axios
          .put(
            `${this.$store.getters.getHostNode}/api/empleados/caja/calculos/depositos-externos/${elemento.ID}`,
            {},
            {
              headers,
            }
          )
          .then((response) => {
            if (response.data.status == "EXPSESSION") {
              deleteToken();
            } else {
              resolve(response.data);
            }
          })
          .catch((e) => {
            reject(e);
          })
          .finally(() => {
            this.loadingData = false;
          });
      });
    },
    validaCantidad(datos) {
      if (datos !== null) {
        this.depositos.push({
          id: datos.id,
          emplid: datos.empleado,
          monto: datos.monto,
          GRUPO_PAGO: -1,
          tipo_movimiento: "DEPOSITO S/D",
        });
      }

      this.insertDeposito = false;
    },
  },
};
</script>
