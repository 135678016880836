import axios from 'axios'
import DetallesBeneficios from '../BeneficiosUrrea/components/DetallesBeneficios.vue'
import LoadingBar from '../../../DesarrolloOrganizacional/components/loadingBar.vue'
import FormRenovacionAuto from './components/FormRenovacionAuto.vue'
import { deleteToken } from '../../../../../store/Funciones/funciones'
// import { saveMonitoreo } from '../../../../../store/Funciones/funciones'
import Convenios from './components/VerConvenios.vue'

export default {
    name: 'beneficios-urrea',
    components: {
        DetallesBeneficios,
        LoadingBar,
        Convenios,
        FormRenovacionAuto
    },
    props: [],
    data() {
        return {
            compensaciones: [],
            beneficios: [],
            itemsPerPage: 5,
            headers: [{
                text: 'Beneficio',
                sortable: false,
                value: 'beneficio'
            },
            {
                text: 'Acciones',
                sortable: false,
                value: 'actions'
            }
            ],
            compesacionTotal: "",
            search: "",
            mensajeDetalle: "",
            cargaInfo: false,
            muestraDetallesBeneficio: false,
            muestraLoad: false,
            muestraConvenios: false,
            textoLoad: "",
            host: this.$store.getters.getHost,
            opcion: 0,
            compania: '',
            bloqueo: false,
            openFormBeneficiosFlexibles: false,
        }
    },
    computed: {},
    mounted() {
        //  window.document.title = "DH:URREA - Mis beneficios.";
        this.getCompania();
        // saveMonitoreo();
    },
    methods: {
        getCompania() {
            let paramsEstatusInc = {
                Opcion: 28,
                token: localStorage.getItem('token')
            };
            axios.post(`${this.$store.getters.getHost}/General/ws-general.php`, paramsEstatusInc)
                .then((response) => {
                    if (response.data.status == "EXPSESSION") {
                        deleteToken()
                    } else {
                        this.compania = response.data.compania;
                        if (this.compania == '00100') {
                            this.getDataCompensaciones();
                        } else {
                            this.bloqueo = true;
                        }
                    }
                })
                .catch(function (e) {
                    let result = e.toString().includes("401");
                    if (result) {
                        window.location.reload();
                    } else {
                        alert(e);
                    }
                });

        },

        getDataCompensaciones() {
            this.cargaInfo = true;
            let params = {
                Opcion: 2,
                token: localStorage.getItem('token')
            }
            axios.post(`${this.$store.getters.getHost}/Compensaciones/ws-beneficios.php`, params).then(response => {
                if (response.data.status == 'OK') {
                    this.compensaciones = response.data.compensacion.compensaciones;
                    this.compensaciones.map(item => {
                        item['importe'] = new Intl.NumberFormat("es-MX", {
                            style: "currency",
                            currency: "MXN",
                        }).format(item['importe']);
                        return item;
                    });
                    this.compesacionTotal = new Intl.NumberFormat("es-MX", {
                        style: "currency",
                        currency: "MXN",
                    }).format(response.data.compensacion.compensacionTotal);
                    this.getDataBeneficios();
                } else {
                    deleteToken();
                }
            })
        },
        getDataBeneficios() {
            let params = {
                Opcion: 1,
                token: localStorage.getItem('token')
            }
            axios.post(`${this.$store.getters.getHost}/Compensaciones/ws-beneficios.php`, params).then(response => {
                if (response.data.status == 'OK') {
                    this.beneficios = response.data.beneficios;
                    this.itemsPerPage = this.beneficios.length;
                }
            })
            this.cargaInfo = false;
        },
        getAyudaLentes() {
            let params = {
                Opcion: 1,
                token: localStorage.getItem('token')
            }
            axios.post(`
                ${this.$store.getters.getHost}/Compensaciones/ws-ayuda-lentes.php `, params).then(response => {
                if (response.data.status == 'OK') {
                    this.muestraLoad = false;
                    if (response.data.ayudaLentes >= 1) {
                        this.getFechaProximaAyudaLentes();
                    } else {
                        this.mensajeDetalle = "Disponible"
                        this.opcion = 3
                        this.muestraDetallesBeneficio = true;
                    }
                }
            })
        },
        getFechaProximaAyudaLentes() {
            let params = {
                Opcion: 2,
                token: localStorage.getItem('token')
            }
            axios.post(`${this.$store.getters.getHost}/Compensaciones/ws-ayuda-lentes.php`, params).then(response => {
                if (response.data.status == 'OK') {
                    this.mensajeDetalle = "Esta prestación estará disponible para ti después del día: " + response.data.ayudaLentes;
                    this.opcion = 3
                    this.muestraDetallesBeneficio = true;
                }
            })
        },
        muestraDetalle(mensaje) {
            this.mensajeDetalle = mensaje;
            this.opcion = 1;
            this.muestraLoad = false;
            this.muestraDetallesBeneficio = true;
        },
        muestraCompensaciones() {
            this.mensajeDetalle = this.compensaciones;
            this.opcion = 2;
            this.muestraLoad = false;
            this.muestraDetallesBeneficio = true;
        },
        muestraVentanaDetalles(opc, data) {
            this.textoLoad = "cargando contenido...";
            this.muestraLoad = true;
            switch (opc) {
                case 1:
                    this.muestraDetalle(data);
                    break;
                case 2:
                    this.muestraCompensaciones();
                    break;
                case 3:
                    if (data == 9) {
                        this.getAyudaLentes();
                    } else if (data == 21){
                        this.openFormBeneficiosFlexibles = true;
                        this.muestraLoad = false;
                    }
                    break;

            }
        },

        cerrarForm(response){
            console.log(response);
            this.openFormBeneficiosFlexibles = false;
        },
    }
}