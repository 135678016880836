export default {
    name: 'adm-reclutamiento',
    components: {},
    props: [],
    data() {
        return {

        }
    },
    computed: {

    },
    mounted() {
        //  window.document.title = "DH:URREA - Admón de reclutamiento.";
    },
    methods: {

    }
}