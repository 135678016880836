<template>
  <v-card>
    <v-data-table
      :headers="headers"
      :items="incidencias"
      :search="buscar"
      :footer-props="{ 'items-per-page-text': 'Elementos por página' }"
      :loading="loading"
      class="elevation-1"
      dense
      mobile-breakpoint="0"
      no-data-text="No se encuentran incidencias para estas fechas"
      loading-text="Cargando contentenido..."
    >
      <template v-slot:top>
        <v-toolbar
          flat
          color="primary"
          class="rounded-lg rounded-b-0 rounded-t-0"
        >
          <v-text-field
            v-model="buscar"
            :label="$t('DH360.findTextBox')"
            class="mt-7 rounded-lg"
            dense
            clearable
            flat
            solo-inverted
            dark
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-tooltip top color="primary">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                small
                color="teal darken-2"
                dark
                @click="cambiarPeriodo()"
                >Cambiar periodo</v-btn
              >
            </template>
            <span class="caption center-item-in-div-column">
              Periodo: <br />
              <strong>{{ fechaInicial }} - {{ fechaFinal }}</strong>
            </span>
          </v-tooltip>
        </v-toolbar>
      </template>
      <template v-slot:[`item.TipoIncidencia`]="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              small
              v-bind="attrs"
              v-on="on"
              @click="detallesIncidencia(item)"
            >
              <v-icon color="primary">mdi-account-child-circle</v-icon>
            </v-btn>
          </template>
          <span class="caption center-item-in-div-column"
            >[{{ item.TipoIncidencia }}] Olvido de gafete
            <strong>Ver detalles</strong>
          </span>
        </v-tooltip>
      </template>
    </v-data-table>

    <detalles-colaborador
      v-if="dialogDetalles"
      :dialog="dialogDetalles"
      :item="item"
      :TipoIncidencia="4"
      @cierraDetalle="refrescar"
    ></detalles-colaborador>
    <filtro-reporte
      v-if="dialogPeriodo"
      :dialog="dialogPeriodo"
      :fecha1="fechaInicial"
      :fecha2="fechaFinal"
      @cierraFiltro="actualizaFechas"
    ></filtro-reporte>
  </v-card>
</template>
<script>
import axios from "axios";
import { deleteToken } from "../../../../../../store/Funciones/funciones";
import DetallesColaborador from "./DetallesColaborador.vue";
import FiltroReporte from "./FiltroReporte.vue";
export default {
  name: "reporte-olvido-gafete",
  components: { DetallesColaborador, FiltroReporte },
  props: [],
  data() {
    return {
      buscar: "",
      loading: true,
      incidencias: [],
      headers: [
        { text: "Folio", value: "NumeroPersonal" },
        { text: "Colaborador ", value: "Nombre" },
        { text: "Fecha Inicio", value: "FechaInicio", align: "center" },
        { text: "Fecha Final", value: "FechaFinal", align: "center" },
        { text: "Usuario Autoriza", value: "UsuarioAutoriza" },
        {
          text: "Fecha Autorización ",
          value: "FechaAutoriza",
          align: "center",
        },
        { text: "Estatus", value: "EstatusDesc" },
        { text: "Acciones ", value: "TipoIncidencia", align: "center" },
      ],
      dialogDetalles: false,
      item: [],
      fechaFinal: "",
      fechaInicial: "",
      dialogPeriodo: false,
    };
  },
  computed: {},
  mounted() {
    this.getFechas();
    this.getIncidenciasOlvidoGafete();
  },
  methods: {
    getIncidenciasOlvidoGafete() {
      let params = {
        Opcion: 5,
        token: localStorage.getItem("token"),
        fechaInicial: this.fechaInicial,
        fechaFinal: this.fechaFinal,
        tipoReporte: 1,
      };
      axios
        .post(
          `${this.$store.getters.getHost}/RelacionesLaborales/Reportes/ws-IncidenciasPorFechas.php`,
          params
        )
        .then(async (response) => {
          if (response.data.status == "OK") {
            this.loading = false;
            this.incidencias = response.data.incidencias;
          } else if (response.data.status == "VACIO") {
            this.loading = false;
          } else if (response.data.status == "EXPSESSION") {
            deleteToken();
          }
        });
    },

    detallesIncidencia(item) {
      this.item = item;
      this.dialogDetalles = true;
    },

    getFechas() {
      var fechaActual = new Date();

      // Obtener los componentes de la fecha
      var year = fechaActual.getFullYear();
      var month = String(fechaActual.getMonth() + 1).padStart(2, "0");
      var day = String(fechaActual.getDate()).padStart(2, "0");

      this.fechaFinal = `${year}-${month}-${day}`;

      // Obtener la fecha de hace un mes
      var fechaHaceUnMes = new Date();
      fechaHaceUnMes.setMonth(fechaHaceUnMes.getMonth() - 1);

      // Obtener los componentes de la fecha de hace un mes
      var yearMesAnterior = fechaHaceUnMes.getFullYear();
      var monthMesAnterior = String(fechaHaceUnMes.getMonth() + 1).padStart(
        2,
        "0"
      );
      var dayMesAnterior = String(fechaHaceUnMes.getDate()).padStart(2, "0");

      this.fechaInicial = `${yearMesAnterior}-${monthMesAnterior}-${dayMesAnterior}`;
    },

    cambiarPeriodo() {
      this.dialogPeriodo = true;
    },

    clean() {
      this.loading = true;
      this.incidencias = [];
    },

    actualizaFechas(nuevasFechas) {
      // Maneja las nuevas fechas aquí
      if (
        this.fechaInicial != nuevasFechas.fechaInicial ||
        this.fechaFinal != nuevasFechas.fechaFinal
      ) {
        this.fechaInicial = nuevasFechas.fechaInicial;
        this.fechaFinal = nuevasFechas.fechaFinal;
        this.clean();
        this.getIncidenciasOlvidoGafete();
      }
      this.dialogPeriodo = false;
    },

    refrescar(response) {
      this.dialogDetalles = false;
      if (response == "OK") {
        this.clean();
        this.getIncidenciasOlvidoGafete();
      }
    },
  },
};
</script>
