import axios from 'axios'
import VueJsonToCsv from 'vue-json-to-csv'
import { deleteToken } from '../../../../../../store/Funciones/funciones'
import MantenimientoModulo from "../../../../../../components/MantenimientoModulo.vue";
export default {
  name: 'reporte-solicitud-epp',
  components: { VueJsonToCsv, MantenimientoModulo },
  props: [],
  data() {
    return {
      expanded: [],
      Headers: [
        { text: 'idSolicitud', value: 'id', align: 'center', sortable: true },
        { text: 'Solicitante', value: 'nombre', align: 'center', sortable: true },
        { text: 'Turno solicitado', value: 'turno', align: 'center', sortable: true },
        { text: 'Nuevo ingreso', value: 'NuevoIngreso', align: 'center', sortable: true },
        { text: '¿Cambió de Actividad?', value: 'otraActividad', align: 'center', sortable: true },
        { text: 'Fecha', value: 'fechaRegistro', align: 'center', sortable: true },
        { text: '', value: 'data-table-expand' },
      ],
      solicitudes: [],

      headers_report_to_export: {
        idSolicitud: { title: "Id Solicitud" },
        emplid: { title: "emplid" },
        nombre: { title: "Solicitante" },
        turnoSolicitado: { title: "Turno solicitado" },
        nuevoIngreso: { title: "Nuevo ingreso" },
        fechaRegistro: { title: "Fecha de registro" },
        codigo: { title: "Código EPP" },
        equipo: { title: "Nombre EPP" },
        repuesto: { title: "Repuesto" },
        enTiempo: { title: "Entrega en tiempo" },
        motivo: { title: "Motivo de cambio" },
        comentario: { title: "Comentario" },
        otraActividad: { title: "Cambio de actividad" },
        comentarioOtraActividad: { title: "Comentario Cambio de actividad" },
      },
      artXsolicitudes: [],
      nameFile: '',
      cargando: true,
      moduloError: false,
    }
  },
  computed: {

  },
  mounted() {
    this.getSolicitudes();
  },
  methods: {
    getSolicitudes() {
      let params = {
        Opcion: 8,
        token: localStorage.getItem('token'),
      }
      axios
        .post(
          `${this.$store.getters.getHost}/General/EPP/ws-admon-epp.php`,
          params
        )
        .then((response) => {
          this.cargando = false;
          if (response.data.status == "VACIO") {
            console.log("Vacio");
          } else if (response.data.status == "error" || !response.data.status) {
            this.moduloError = true;
          } else if (response.data.status == "OK") {
            this.solicitudes = response.data.solicitudes;
            this.artXsolicitudes = response.data.artXsolicitudes;
          } else if (response.data.status == "EXPRESSION") {
            deleteToken();
          }
        })
        .catch(function (e) {
          alert("Ocurrió un error: " + e);
        });
    },

    fixName() {
      let numero_aleatorio =
        Math.floor(Math.random() * (99999 - 10000 + 1)) + 10000;
      this.nameFile = "ReporteSolicitudesEPP_" + numero_aleatorio.toString();
    },
  }
}


