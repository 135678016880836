import MuestraReporteDeCostosTE from "./components/MuestraReporteDeCostosTE.vue"
//import PersonaMotivoTE from "../../../General/Inicio/components/PersonaMotivoTE.vue";
export default {
    name: 'reporte-costo-de-tiempo-extra',
    components: {
        MuestraReporteDeCostosTE
    },
    props: [],
    data() {
        return {}
    },
    computed: {

    },
    mounted() {},
    methods: {}
}